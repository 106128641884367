import * as types from '../../types/workplans';

export const startFetchingWorkplans = (filters = {}) => ({
  type: types.WORKPLANS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingWorkplans = (entities, order, operatorId, organizationId) => ({
  type: types.WORKPLANS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingWorkplans = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.WORKPLANS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingWorkplans = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.WORKPLANS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingWorkplans = (entities, order, operatorId, organizationId) => ({
  type: types.WORKPLANS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingWorkplans = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const completeFetchingWorkplanLocations = (workplanId, locations) => ({
  type: types.WORKPLAN_LOCATIONS_FETCH_COMPLETED,
  payload: {
    workplanId,
    locations
  }
});
export const failFetchingWorkplanLocations = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLAN_LOCATIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingWorkplan = (workplan) => ({
  type: types.WORKPLAN_ADD_STARTED,
  payload: workplan
});
export const completeAddingWorkplan = (oldId, workplan) => ({
  type: types.WORKPLAN_ADD_COMPLETED,
  payload: { oldId, workplan }
});
export const failAddingWorkplan = (oldId, error) => ({
  type: types.WORKPLAN_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingWorkplan = (workplan) => ({
  type: types.WORKPLAN_EDIT_STARTED,
  payload: workplan
});
export const completeEditingWorkplan = (workplan) => ({
  type: types.WORKPLAN_EDIT_COMPLETED,
  payload: workplan
});
export const failEditingWorkplan = (oldWorkplan, error) => ({
  type: types.WORKPLAN_EDIT_FAILED,
  payload: {
    oldWorkplan,
    error
  }
});

export const startRemovingWorkplan = (workplanId) => ({
  type: types.WORKPLAN_REMOVE_STARTED,
  payload: {
    workplanId
  }
});
export const completeRemovingWorkplan = (workplanId) => ({
  type: types.WORKPLAN_REMOVE_COMPLETED,
  payload: {
    workplanId
  }
});
export const failRemovingWorkplan = (workplanId, error) => ({
  type: types.WORKPLAN_REMOVE_FAILED,
  payload: {
    workplanId,
    error
  }
});

export const selectWorkplan = (workplanId) => ({
  type: types.WORKPLAN_SELECTED,
  payload: workplanId
});

export const selectAllWorkplans = (
  workplanIds = [] //List of WorkplanIds
) => ({
  type: types.WORKPLANS_ALL_SELECTED,
  payload: workplanIds
});

export const deselectWorkplan = (workplanId) => ({
  type: types.WORKPLAN_DESELECTED,
  payload: workplanId
});

export const deselectAllWorkplans = (
  workplanIds = [] //List of WorkplanIds
) => ({
  type: types.WORKPLANS_ALL_DESELECTED,
  payload: workplanIds
});

export const startCopyWorkplan = (
  workplanId,
  newWorkplanName,
  organizationId,
  divisionId,
  subdivisionId
) => ({
  type: types.WORKPLAN_COPY_STARTED,
  payload: { workplanId, newWorkplanName, organizationId, divisionId, subdivisionId }
});
export const completeCopyWorkplan = (
  workplanId,
  newWorkplanId,
  newWorkplanName,
  organizationId,
  divisionId,
  subdivisionId
) => ({
  type: types.WORKPLAN_COPY_COMPLETED,
  payload: { workplanId, newWorkplanId, newWorkplanName, organizationId, divisionId, subdivisionId }
});
export const failCopyWorkplan = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.WORKPLAN_COPY_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAssignOriginGeofenceWorkplan = (
  workplanIds,
  originGeofenceId,
  originExitWindow,
  originValidationWindow
) => ({
  type: types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED,
  payload: { workplanIds, originGeofenceId, originExitWindow, originValidationWindow }
});
export const completeAssignOriginGeofenceWorkplan = (
  workplanIds,
  originGeofenceId,
  originGeofenceName,
  originExitWindow,
  originValidationWindow
) => ({
  type: types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED,
  payload: {
    workplanIds,
    originGeofenceId,
    originGeofenceName,
    originExitWindow,
    originValidationWindow
  }
});
export const failAssignOriginGeofenceWorkplan = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAssignWorkGeofenceWorkplan = (
  workplanIds,
  workGeofenceId,
  workGeofenceArriveWindow,
  workGeofenceMaxTime
) => ({
  type: types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED,
  payload: {
    workplanIds,
    workGeofenceId,
    workGeofenceArriveWindow,
    workGeofenceMaxTime
  }
});
export const completeAssignWorkGeofenceWorkplan = (
  workplanIds,
  workGeofenceId,
  workGeofenceName,
  workGeofenceArriveWindow,
  workGeofenceMaxTime
) => ({
  type: types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED,
  payload: {
    workplanIds,
    workGeofenceId,
    workGeofenceName,
    workGeofenceArriveWindow,
    workGeofenceMaxTime
  }
});
export const failAssignWorkGeofenceWorkplan = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAssignDestinationGeofenceWorkplan = (workplanIds, destinationGeofenceId) => ({
  type: types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED,
  payload: { workplanIds, destinationGeofenceId }
});
export const completeAssignDestinationGeofenceWorkplan = (
  workplanIds,
  destinationGeofenceId,
  destinationGeofenceName
) => ({
  type: types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED,
  payload: { workplanIds, destinationGeofenceId, destinationGeofenceName }
});
export const failAssignDestinationGeofenceWorkplan = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
