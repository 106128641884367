import { FormattedMessage } from 'react-intl';
import { TabContent, TabPane, Card, CardBody } from 'reactstrap';
import * as React from 'react';

/* -------------------------------------------------------------------------- */
/*                               Tabs Content                                 */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* Use this with a Tabs.js component to render the content of your tabs       */
/* 
  const tabsContent = [
    { tabId: 1, component: <GeneralTabContent /> }, //Example of Component in Profile GeneralInformationTabsContent
    { tabId: 2, component: <PasswordTabContent /> },
    {
      tabId: 3,
      component: <MapOptionsTabContent />,
    },
  ];
    
  Tabs is a list of the tabs you want to render
  Active Tab the current active tab
  SetActiveTab your function to toggle de active tab                               
                                                                              */
/* -------------------------------------------------------------------------- */
const TabsContent = ({ activeTab, tabsContent = [] }) => {
  return (
    <TabContent activeTab={activeTab}>
      {tabsContent.map((tabContent) => {
        return (
          <TabPane key={`tabContent${tabContent.tabId}`} tabId={tabContent.tabId}>
            {tabContent.component}
          </TabPane>
        );
      })}
    </TabContent>
  );
};

export default TabsContent;
