// @ts-nocheck
import { useState } from 'react';
import { Download, X, Edit2 } from 'react-feather';
import Lightbox from 'react-image-lightbox';
import { Button, Card, CardBody, CardImg } from 'reactstrap';
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  EffectFade,
  Lazy,
  Navigation,
  Pagination,
  Thumbs,
  Virtual
} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Icon from 'utility/icomoon';
import Toolbar from '../Toolbar';
import SlidingPaneEditFileName from './SlidingPaneEditFileName';
import DefaultButton from '../DefaultButton';
import { appIntl } from 'utility/context/IntlGlobalProvider';

SwiperCore.use([
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  Autoplay,
  Lazy,
  Virtual,
  Thumbs
]);

const defaultGalleryParams = {
  spaceBetween: 10,
  slidesPerView: 1,
  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    700: {
      spaceBetween: 20,
      slidesPerView: 3
    }
  },
  loop: false,
  navigation: true,
  pagination: {
    clickable: true
  }
};

interface FileGalleryProps {
  isRtl: boolean;
  files: File[];
  className: string;
  loop?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  onSlideChange?: Function;
}

interface File {
  fileUrl: string;
  fileName: string;
  fileId: any;
  fileExtension: string;
}

const FileGallery = (props: FileGalleryProps) => {
  const { isRtl, files, className, loop, navigation, pagination, onSlideChange } = props;

  const galleryOptions = {
    ...defaultGalleryParams,

    loop: loop != null ? loop : defaultGalleryParams.loop,
    navigation: navigation != null ? navigation : defaultGalleryParams.navigation,
    pagination: pagination != null ? pagination : defaultGalleryParams.pagination
  };

  return (
    <Swiper
      dir={isRtl ? 'rtl' : 'ltr'}
      {...galleryOptions}
      className={`${className}`}
      onSlideChange={(swiper) => {
        if (onSlideChange)
          onSlideChange(swiper.activeIndex > 0 ? (swiper.activeIndex - 1) % files.length : 0);
      }}
    >
      {files.map((file, index) => {
        return (
          <SwiperSlide key={index}>
            <FilePreview file={file} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

FileGallery.defaultProps = {
  isRtl: false,
  files: [],
  className: '',
  loop: null,
  navigation: null,
  pagination: null,
  onSlideChange: (activeSlideId) => null
};

interface PropsFilePreview {
  deleteFile?: any;
  editFileName?: any;
  file: File;
  onClickImage?: Function;
}
export const FilePreview = (props: PropsFilePreview) => {
  const { file, onClickImage, deleteFile, editFileName } = props;
  const isImg = ['png', 'jpg', 'gif', 'jpeg', 'jfif'].includes(file.fileExtension);
  const [editFileNameSlideOpen, openEditFileNameSlide] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  return (
    <div className="image-container-with-buttons">
      <Card className="webtrack file-preview">
        <div>
          {isImg && (
            <CardImg
              //width="100%"
              style={{ width: '100%', height: '150px', objectFit: 'contain' }}
              src={file.fileUrl}
              onClick={() => {
                setIsLightboxOpen(true);
                if (onClickImage) onClickImage();
              }}
            />
          )}

          {!isImg && (
            <CardBody className="text-center" style={{ height: '85px' }}>
              <div>
                <div className={`avatar p-50 m-0 ${'bg-light-primary'}`}>
                  <div className="avatar-content">
                    <Icon
                      icon={
                        file.fileExtension
                          ? `File${file.fileExtension?.toUpperCase()}`
                          : 'FileGeneric'
                      }
                    />
                  </div>
                </div>
              </div>
            </CardBody>
          )}
        </div>
        <hr
          style={{
            marginTop: '3px',
            marginBottom: '0px',
            paddingTop: '0px',
            paddingBottom: '0px'
          }}
        />
        <CardBody className="text-left" style={{}}>
          <div
            className="font-weight-bolder"
            style={{
              display: 'block',
              textOverflow: 'ellipsis',
              wordWrap: 'break-word',
              overflow: 'hidden',
              maxHeight: '2.2em',
              lineHeight: '1.0em'
            }}
          >
            {file.fileName.length > 20
              ? `${file.fileName.substring(0, 12)}...${file.fileName.substring(
                  file.fileName.length - 6,
                  file.fileName.length.length
                )}`
              : file.fileName}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              position: 'absolute',
              top: '50%',
              left:
                file.fileUrl && typeof file.fileUrl === 'string' && deleteFile && editFileName
                  ? '50%'
                  : file.fileUrl && typeof file.fileUrl === 'string' && (deleteFile || editFileName)
                  ? '60%'
                  : '70%'
            }}
            className="file-download-button"
          >
            {file.fileUrl && typeof file.fileUrl === 'string' ? (
              <>
                <Button.Ripple className="btn-icon btn-file" color="primary">
                  <Download
                    size={14}
                    onClick={() => {
                      window.open(`${file.fileUrl}`);
                    }}
                  />
                </Button.Ripple>
                {editFileName && (
                  <Button.Ripple
                    className="btn-icon btn-file"
                    color="primary"
                    style={{ marginLeft: '5px' }}
                  >
                    <Edit2
                      size={14}
                      onClick={() => {
                        openEditFileNameSlide(true);
                      }}
                    />
                  </Button.Ripple>
                )}
                {deleteFile && (
                  <Button.Ripple
                    className="btn-icon btn-file"
                    color="primary"
                    style={{ marginLeft: '5px' }}
                  >
                    <X
                      size={14}
                      onClick={() => {
                        deleteFile(file.fileId);
                      }}
                    />
                  </Button.Ripple>
                )}
              </>
            ) : (
              deleteFile && (
                <Button.Ripple className="btn-icon btn-file" color="primary">
                  <X
                    size={14}
                    onClick={() => {
                      deleteFile();
                    }}
                  />
                </Button.Ripple>
              )
            )}
          </div>
        </CardBody>
      </Card>
      <SlidingPaneEditFileName
        file={file}
        isOpenSlidingPane={editFileNameSlideOpen}
        isImg={isImg}
        closeSlidingPaneEditFileName={() => {
          openEditFileNameSlide(false);
        }}
        editFileName={editFileName}
      />
      {isLightboxOpen && (
        <Lightbox
          discourageDownloads={false}
          reactModalStyle={{ overlay: { zIndex: 20000 } }}
          imageTitle={file?.fileName}
          mainSrc={file?.fileUrl}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
    </div>
  );
};

export default FileGallery;
