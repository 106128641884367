import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/catalogs/workmanship';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/workmanship';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/catalogs/workmanship';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteWorkmanshipCategory,
  getWorkmanshipsList,
  saveWorkmanshipCategory
} from 'views/screens/vehicleMaintenance/services/workmanshipService';
import { PayloadAction } from '@reduxjs/toolkit';
import { SaveWorkmanshipCategoryDTO } from 'views/screens/vehicleMaintenance/types';
/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchWorkmanshipsList(action) {
  try {
    const jsonResult = yield call(getWorkmanshipsList);
    const resultData = jsonResult.map((result) => camelcaseKeys(result));
    const {
      entities: { workmanships },
      result //order
    } = normalize(resultData, schemas.workmanships); //normalize data to byId and order
    yield put(actions.completeFetchingWorkmanships(workmanships, result));
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingWorkmanships(error));
  }
}

export function* watchFetchWorkmanshipsList() {
  yield takeEvery(types.WORKMANSHIPS_FETCH_STARTED, fetchWorkmanshipsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchWorkmanshipsList(action) {}

export function* watchPartialFetchWorkmanshipsList() {
  yield takeEvery(types.WORKMANSHIPS_PARTIAL_FETCH_STARTED, partialFetchWorkmanshipsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addWorkmanship(action: PayloadAction<SaveWorkmanshipCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveWorkmanshipCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingWorkmanship(addedData.workmanshipId, request.tempId, {
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failAddingWorkmanship(request.tempId, e));
  }
}

export function* watchAddWorkmanshipsStarted() {
  yield takeEvery(types.WORKMANSHIP_ADD_STARTED, addWorkmanship);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editWorkmanship(action: PayloadAction<SaveWorkmanshipCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveWorkmanshipCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingWorkmanship({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failEditingWorkmanship(request.workmanshipId, e));
  }
}

export function* watchEditWorkmanshipsStarted() {
  yield takeEvery(types.WORKMANSHIP_EDIT_STARTED, editWorkmanship);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeWorkmanship(action: PayloadAction<{ workmanshipId: number; description: string }>) {
  const request = action.payload;
  try {
    yield call(deleteWorkmanshipCategory, request);
    yield put(actions.completeRemovingWorkmanship(request.workmanshipId));
  } catch (e) {
    yield put(actions.failRemovingWorkmanship(request.workmanshipId, e));
  }
}

export function* watchRemoveWorkmanshipStarted() {
  yield takeEvery(types.WORKMANSHIP_REMOVE_STARTED, removeWorkmanship);
}
