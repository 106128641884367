import { UnitLiveStatus } from './UnitLiveStatusTypes';

export const UNITS_FETCH_STARTED = 'UNITS_FETCH_STARTED';
export const UNITS_FETCH_COMPLETED = 'UNITS_FETCH_COMPLETED';
export const UNITS_FETCH_FAILED = 'UNITS_FETCH_FAILED';

export const UNITS_PARTIAL_FETCH_STARTED = 'UNITS_PARTIAL_FETCH_STARTED';
export const UNITS_PARTIAL_FETCH_COMPLETED = 'UNITS_PARTIAL_FETCH_COMPLETED';
export const UNITS_PARTIAL_FETCH_FAILED = 'UNITS_PARTIAL_FETCH_FAILED';

export const UNIT_ADD_TO_STATE = 'UNIT_ADD_TO_STATE';

export const UNITS_LIVE_STATUS_FETCH_STARTED = 'UNITS_LIVE_STATUS_FETCH_STARTED';
export const UNITS_LIVE_STATUS_FETCH_COMPLETED = 'UNITS_LIVE_STATUS_FETCH_COMPLETED';
export const UNITS_LIVE_STATUS_FETCH_FAILED = 'UNITS_LIVE_STATUS_FETCH_FAILED';

export const UNIT_INFORMATION_FETCH_STARTED = 'UNIT_INFORMATION_FETCH_STARTED';
export const UNIT_INFORMATION_FETCH_COMPLETED = 'UNIT_INFORMATION_FETCH_COMPLETED';
export const UNIT_INFORMATION_FETCH_FAILED = 'UNIT_INFORMATION_FETCH_FAILED';

export const UNIT_POSITIONS_FETCH_STARTED = 'UNIT_POSITIONS_FETCH_STARTED';
export const UNIT_POSITIONS_FETCH_COMPLETED = 'UNIT_POSITIONS_FETCH_COMPLETED';
export const UNIT_POSITIONS_FETCH_FAILED = 'UNIT_POSITIONS_FETCH_FAILED';
export const UNIT_POSITIONS_RESET_IS_FETCHED = 'UNIT_POSITIONS_RESET_IS_FETCHED';

export const UNIT_EXECUTE_DEVICE_ACTION = 'UNIT_EXECUTE_DEVICE_ACTION';

export const UNIT_ADD_STARTED = 'UNIT_ADD_STARTED';
export const UNIT_ADD_COMPLETED = 'UNIT_ADD_COMPLETED';
export const UNIT_ADD_FAILED = 'UNIT_ADD_FAILED';

export const UNIT_EDIT_STARTED = 'UNIT_EDIT_STARTED';
export const UNIT_EDIT_COMPLETED = 'UNIT_EDIT_COMPLETED';
export const UNIT_EDIT_FAILED = 'UNIT_EDIT_FAILED';

export const UNIT_ACKNOWLEDGE_STARTED = 'UNIT_ACKNOWLEDGE_STARTED';
export const UNIT_ACKNOWLEDGE_COMPLETED = 'UNIT_ACKNOWLEDGE_COMPLETED';
export const UNIT_ACKNOWLEDGE_FAILED = 'UNIT_ACKNOWLEDGE_FAILED';

export const UNIT_REMOVE_STARTED = 'UNIT_REMOVE_STARTED';
export const UNIT_REMOVE_COMPLETED = 'UNIT_REMOVE_COMPLETED';
export const UNIT_REMOVE_FAILED = 'UNIT_REMOVE_FAILED';

export const UNIT_SELECTED = 'UNIT_SELECTED';
export const UNITS_ALL_SELECTED = 'UNITS_ALL_SELECTED';
export const UNIT_DESELECTED = 'UNIT_DESELECTED';
export const UNITS_ALL_DESELECTED = 'UNITS_ALL_DESELECTED';

export const UNIT_SHOW_IN_MAP = 'UNIT_SHOW_IN_MAP';
export const UNITS_ALL_SHOW_IN_MAP = 'UNITS_ALL_SHOW_IN_MAP';
export const UNIT_HIDE_IN_MAP = 'UNIT_HIDE_IN_MAP';
export const UNITS_ALL_HIDE_IN_MAP = 'UNITS_ALL_HIDE_IN_MAP';

export const UNIT_FETCH_INTERVAL = 30000;
export const UNIT_IDLE_SPEED = 5;

export const SET_DATE_RANGE = 'SET_DATE_RANGE';

export const UNITS_ACTIVE_STARTED = 'UNITS_ACTIVE_STARTED';
export const UNITS_ACTIVE_COMPLETED = 'UNITS_ACTIVE_COMPLETED';
export const UNITS_ACTIVE_FAILED = 'UNITS_ACTIVE_FAILED';

export const UNITS_ASSIGN_DRIVER_STARTED = 'UNITS_ASSIGN_DRIVER_STARTED';
export const UNITS_ASSIGN_DRIVER_COMPLETED = 'UNITS_ASSIGN_DRIVER_COMPLETED';

export const UNIT_CREATE_SHARE_LINK_STARTED = 'UNIT_CREATE_SHARE_LINK_STARTED';
export const UNIT_CREATE_SHARE_LINK_COMPLETED = 'UNIT_CREATE_SHARE_LINK_COMPLETED';
export const UNIT_CREATE_SHARE_LINK_FAILED = 'UNIT_CREATE_SHARE_LINK_FAILED';

export const UNIT_CLEAR_SHARE_LINK = 'UNIT_CLEAR_SHARE_LINK';

export const UNIT_CHANGE_STATUS_STARTED = 'UNIT_CHANGE_STATUS_STARTED';
export const UNIT_CHANGE_STATUS_COMPLETED = 'UNIT_CHANGE_STATUS_COMPLETED';

export const UNIT_RESTORE_STATUS_STARTED = 'UNIT_RESTORE_STATUS_STARTED';
export const UNIT_RESTORE_STATUS_COMPLETED = 'UNIT_RESTORE_STATUS_COMPLETED';
export const UNIT_RESTORE_STATUS_FAILED = 'UNIT_RESTORE_STATUS_FAILED';

export const UNIT_START_DOWNTIME_STARTED = 'UNIT_START_DOWNTIME_STARTED';
export const UNIT_START_DOWNTIME_COMPLETED = 'UNIT_START_DOWNTIME_COMPLETED';
export const UNIT_START_DOWNTIME_FAILED = 'UNIT_START_DOWNTIME_FAILED';

export const UNIT_END_DOWNTIME_STARTED = 'UNIT_END_DOWNTIME_STARTED';
export const UNIT_END_DOWNTIME_COMPLETED = 'UNIT_END_DOWNTIME_COMPLETED';
export const UNIT_END_DOWNTIME_FAILED = 'UNIT_END_DOWNTIME_FAILED';

export const UNIT_UPDATE_DEVICE_STARTED = 'UNIT_DEVICE_ASSIGNMENT_STARTED';
export const UNIT_UPDATE_DEVICE_COMPLETED = 'UNIT_DEVICE_ASSIGNMENT_COMPLETED';
export const UNIT_UPDATE_DEVICE_FAILED = 'UNIT_DEVICE_ASSIGNMENT_FAILED';

export const UNIT_UPDATE_SIM_STARTED = 'UNIT_UPDATE_SIM_STARTED';
export const UNIT_UPDATE_SIM_COMPLETED = 'UNIT_UPDATE_SIM_COMPLETED';
export const UNIT_UPDATE_SIM_FAILED = 'UNIT_UPDATE_SIM_FAILED';

// Interfaces
export interface UnitMainState {
  id: number;
  intl: string;
  color: string;
  textColor: string;
  tooltip: string;
  shortIntl: string;
}
export interface UnitsMainState {
  [key: string]: UnitMainState;
}

export interface ReduxUnit extends Omit<Partial<UnitLiveStatus>, 'isCharging'> {
  isSelected: boolean;
  isShownInMap: boolean;
  unitId: number;
  unitPublicId: string;
  unitStatus: number;
  unitStatusName: string;
  unitTypeId: number;
  unitName: string;
  alias: string;
  operatorId: number;
  organizationId: number;
  organizationName: string;
  divisionId?: number;
  subdivisionId?: number;
  deviceId: number;
  propertyTypeId: number;
  propertyTypeDescription: string;
  deviceIdentifier: string;
  serialNumber: string;
  brandId: number;
  brandName: string;
  deviceModelId: number;
  deviceModelName: string;
  deviceConfigurationId: number;
  deviceConfigurationName: string;
  driverId: number;
  vehicleId: number;
  vehicleName: string;
  licensePlate: string;
  simCardId: number;
  imsi: string;
  cellularNumber: string | number;
  mobileOperatorId: number;
  mobileOperatorName: string;
  comments: string;
  contractDate: string;
  contractNumber: string;
  lastPaymentDate: string;
  periodicity: number | string;
  instalationDate: string;
  privateCode: string;
  privateCode2: string;
  themeId: number;
  serviceClassId: number;
  serviceClassName: string;
  membershipCode: number | string;
  applicationIdentity: string | number;
  scheduleId?: number;
  scheduleName: string;
  countryId: number;
  mainStateTypeId: number;
  idleSpeed: number;
  assets: string;
  offlineTime: number;
  startedTime: number;
  stoppedTime: number;
  idleTime: number;
  utcZoneId: number;
  downtimeReasonId: number;
  downtimeReasonDescription: string;
  lastCommunicationDate: string;
  isConfirmed: boolean;
  operatorName: string;
  divisionName: string;
  subdivisionName: string;
  mainVoltage: number;
  battery: number;
  backupBattery: any;
  isCharging: number | boolean;
  doorState: any;
  coverTamperState: any;
  padlockState: any;
  satellites: number;
  plmn: number;
  mcc: number;
  mnc: number;
  cellNumber: number;
  iccid: number;
  trailerId: number;
  appVersion: any;
  padlockAuthorizationType: any;
  historicalPositions: any;
}

export interface PartialUnit extends Partial<ReduxUnit> {}

export interface GroupUnit
  extends Pick<ReduxUnit, 'unitId' | 'unitName' | 'themeId' | 'privateCode' | 'organizationName'> {
  groupName: string;
}
