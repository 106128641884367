import SlidingPane from 'react-sliding-pane';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import { useEffect, FC, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useGetUnitScriptHistory from '../../hooks/useGetUnitScriptHistory';
import DateInput from 'views/formComponents/DateInput';
import moment from 'moment';
import { Row, Col, Spinner, Button, Badge } from 'reactstrap';
import Table from 'views/components/Table';
import Toolbar from 'views/components/Toolbar';
import ReportViewerButton from 'views/components/ReportViewerButton';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import * as authSelectors from '../../../../../redux/reducers/auth';
import { BroadcastChannel } from 'broadcast-channel';
import { useRerender } from 'hooks/useRerender';
import { TableColumn } from 'utility/types';
import { renderToString } from 'react-dom/server';
import Icon from 'utility/icomoon';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { isNotNullOrUndefined } from 'utility/Utils';
import { getStatusStyles } from 'views/screens/Reports/views/byUnit/reportScriptHistory/utils';

/* -------------------------------------------------------------------------- */
/*                 SlidingPaneUnitHistoricalScriptsReport                   */
/* -------------------------------------------------------------------------- */

//Columnas predefinidas para la tabla
const columns: TableColumn[] = [
  {
    fieldName: 'scriptDate',
    headerText: 'common.date',
    type: 'dateTime',
    formatColumn: 'yyyy-MM-dd HH:mm'
  },
  {
    fieldName: 'sentDirection',
    headerText: 'units.sentDirection',
    textAlign: 'Center',
    headerTemplate: renderToString(
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '5px'
        }}
      >
        <Icon icon={'Actividad-de-Vehculo'} size={23} />
      </span>
    ),
    template: (script) => (
      <span key={script.scriptId}>
        <Icon icon={'Database'} size={20} style={{ marginRight: 10 }} />
        <Icon
          icon={script.acknowledge === '1' ? 'ArrowLeft' : 'ArrowRight'}
          size={20}
          style={{ marginRight: 10 }}
        />
        <Icon
          icon={appIntl().formatMessage({ id: `units.theme${script.themeId}.icon` })}
          size={27}
        />
      </span>
    )
  },
  {
    fieldName: 'scriptTypeDescription',
    headerText: 'forms.type'
  },
  {
    fieldName: 'scriptText',
    headerText: 'common.text'
  },
  {
    fieldName: 'deviceActionDescription',
    headerText: 'units.action'
  },
  {
    fieldName: 'userName',
    headerText: 'users.user'
  },
  {
    fieldName: 'status',
    headerText: 'units.status',
    template: (script) => {
      const styles = getStatusStyles(script.isProcessed);
      if (styles === null) {
        return <></>;
      }

      const { color, icon, text } = styles;

      return (
        <Badge pill color={`light-${color}`} style={{ display: 'inline-block', fontSize: '13px' }}>
          <Icon icon={icon} className={'mr-25'} size={15} color={color} />
          {text}
        </Badge>
      );
    }
  },
  {
    fieldName: 'sentDate',
    headerText: 'units.sentDate',
    type: 'dateTime',
    formatColumn: 'yyyy-MM-dd HH:mm'
  }
];

interface Props {
  closeSlidingPane: Function;
  isSlidingPaneOpen: boolean;
  title: string;
  subtitle: string;
  unit: any;
}

const SlidingPaneUnitHistoricalScriptsReport: FC<Props> = ({
  closeSlidingPane,
  isSlidingPaneOpen,
  title,
  subtitle,
  unit
}) => {
  //HOOKS
  const { control, watch, handleSubmit } = useForm<{ dateRange: [string, string] }>({
    defaultValues: {
      dateRange: [
        moment().startOf('day').format('YYYY-MM-DD HH:mm'),
        moment().endOf('day').format('YYYY-MM-DD HH:mm')
      ]
    }
  });
  const intl: IntlShape = useIntl();

  //VARIABLES
  const { dateRange } = watch();
  const [startDate, endDate] = dateRange;

  //HOOK CONSULTA A LA API
  const { scriptHistory, isFetchingScriptHistory, startRefresh } = useGetUnitScriptHistory(
    startDate,
    endDate,
    unit?.unitId,
    () => isSlidingPaneOpen
  );

  const { rerender } = useRerender([dateRange]);

  const { organizationName } = useSelector((state) => {
    const userOrganizationId = authSelectors.getAuthUserOrganizationId(state);
    const organizationName =
      organizationSelectors.getOrganization(state, userOrganizationId)?.organizationName ?? '';

    return { organizationName };
  });

  const scriptHistoryRpt = useMemo(() => {
    return scriptHistory.map((sh) => {
      return {
        ...sh,
        scriptDate: isNotNullOrUndefined(sh.scriptDate)
          ? moment(sh.scriptDate).format('YYYY-MM-DD HH:mm')
          : '',
        sentDate: isNotNullOrUndefined(sh.sentDate)
          ? moment(sh.sentDate).format('YYYY-MM-DD HH:mm')
          : '',
        status:
          sh.isProcessed === 0
            ? intl.formatMessage({ id: 'units.pending' })
            : sh.isProcessed === 1
            ? intl.formatMessage({ id: 'units.sent' })
            : sh.isProcessed === 2
            ? intl.formatMessage({ id: 'units.cancelled' })
            : '',
        deviceActionDescription: sh.deviceActionId
          ? intl.formatMessage({ id: `units.deviceActionId.${sh.deviceActionId}` })
          : ''
      };
    });
  }, [scriptHistory, intl]);

  const reportDataSource = {
    title: intl.formatMessage({ id: 'reports.historyOfScripts' }),
    subtitle: intl.formatMessage({ id: 'jobs.reports.organization' }) + (organizationName ?? ''),
    filters: startDate + ' - ' + endDate,
    dataSets: [
      {
        value: scriptHistoryRpt,
        name: 'scriptHistory'
      }
    ]
  };

  useEffect(() => {
    if (isSlidingPaneOpen && !isFetchingScriptHistory) {
      startRefresh();
    }
  }, [isSlidingPaneOpen]);

  return (
    <>
      <SlidingPane
        className="custom-sliding-pane"
        overlayClassName={'custom-overlay-sliding-pane-map'}
        isOpen={isSlidingPaneOpen}
        title={title}
        subtitle={subtitle}
        width={'52rem'}
        onRequestClose={() => {
          if (closeSlidingPane) closeSlidingPane();
        }}
      >
        <>
          <Row>
            <Col sm="10">
              <Controller
                control={control}
                name="dateRange"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DateInput
                    field={field}
                    fieldstate={fieldState}
                    required
                    maxDate={moment().endOf('day').format('YYYY-MM-DD HH:mm')}
                    isRange
                  />
                )}
              />
            </Col>
            <Col sm="2" className="mt-25">
              <Button.Ripple
                className="mt-1"
                color="primary"
                /*type="submit"*/
                onClick={handleSubmit(() => {
                  startRefresh();
                })}
              >
                <FormattedMessage id="common.search" />
              </Button.Ripple>
            </Col>
          </Row>
          {isFetchingScriptHistory ? (
            <Row>
              <Col sm="12" className="grid-spinner">
                <Spinner color="primary" size="lg" />
              </Col>
            </Row>
          ) : (
            <>
              <Toolbar
                buttons={[
                  <ReportViewerButton
                    tooltipMessage={'reports.seeReport'}
                    channelName={'report_ScriptsHistoricalData'}
                    handleClick={() => {}}
                    reportDataSource={reportDataSource}
                    useEmbbededChannel={true}
                    reportPath={'ScriptsHistoricalData.rdl'}
                  />
                ]}
              />
              <Table
                id={`repotScriptHistoryTable`}
                columns={columns}
                data={scriptHistory.map((script) => ({
                  ...script,
                  scriptDate: moment(new Date(script.scriptDate)).format('YYYY-MM-DD HH:mm'),
                  status:
                    script.isProcessed === 0
                      ? intl.formatMessage({ id: 'units.pending' })
                      : script.isProcessed === 1
                      ? intl.formatMessage({ id: 'units.sent' })
                      : script.isProcessed === 2
                      ? intl.formatMessage({ id: 'units.cancelled' })
                      : '',
                  deviceActionDescription: script.deviceActionId
                    ? intl.formatMessage({ id: `units.deviceActionId.${script.deviceActionId}` })
                    : ''
                }))}
                enablePersistence={false}
                rerender={rerender}
              />
            </>
          )}
        </>
      </SlidingPane>
    </>
  );
};

SlidingPaneUnitHistoricalScriptsReport.defaultProps = {
  closeSlidingPane: () => null,
  isSlidingPaneOpen: false,
  //showHistoricalPositions: { show: false },
  title: '',
  unit: {}
};

export default SlidingPaneUnitHistoricalScriptsReport;
