import Icon from '../../utility/icomoon';
import paths from 'views/screens/TrackingUnits/paths';
import permissions from 'views/screens/TrackingUnits/permissions';
import unitPermissions from 'views/screens/Units/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'trackingUnits',
    title: 'units.units',
    icon: <Icon size={20} icon="Unidades" />,
    permissions: [permissions.trackingUnitsList, unitPermissions.listFleet],
    iconName: 'Unidades',
    navLink: paths.listTrackingUnits,
    screenId: screens.TRACKING_UNITS,
    children: [
      {
        id: 'trackingUnitsCatalog',
        title: 'sidebar.catalog',
        icon: <Icon size={20} icon="Unidades" />,
        permissions: [permissions.trackingUnitsList],
        iconName: 'Unidades',
        navLink: paths.listTrackingUnits,
        screenId: screens.TRACKING_UNITS
      },
      {
        id: 'trackingUnitsMonitor',
        title: 'sidebar.monitor',
        icon: <Icon size={20} icon="Unidades" />,
        permissions: [unitPermissions.listFleet],
        iconName: 'Unidades',
        navLink: paths.listFleet,
        screenId: screens.FLEET
      }
    ]
  }
];
