import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import routesComments from '../routesComments';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routesExecutions';
import permissions from '../../permissions';
import { getRouteExecutionInformation } from '../../utils';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_EXECUTIONS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }
    case types.ROUTE_EXECUTION_EDIT_COMPLETED: {
      const routeExecution = action.payload;
      const newState = { ...state };
      newState[routeExecution.routeAssignmentId] = {
        isSelected: false,
        ...state[routeExecution.routeAssignmentId],
        ...routeExecution,
        isConfirmed: true
      };
      return newState;
    }
    case types.ROUTES_EXECUTION_CANCEL_COMPLETED: {
      const { visitId } = action.payload;
      return omit(state, visitId);
    }
    case types.ROUTES_EXECUTION_FINALIZE_COMPLETED: {
      const { visitId } = action.payload;
      const newState = { ...state };
      newState[visitId] = {
        isSelected: false,
        ...state[visitId],
        executionStatus: 5,
        isConfirmed: true
      };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.ROUTES_EXECUTIONS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }
    case types.ROUTES_EXECUTION_CANCEL_COMPLETED: {
      const { visitId } = action.payload;
      return state.filter((id) => id !== visitId);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const indicators = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_EXECUTIONS_FETCH_COMPLETED: {
      const { indicators } = action.payload;
      const newState = { ...indicators };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingExecutions = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTIONS_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTIONS_FETCH_COMPLETED:
    case types.ROUTES_EXECUTIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCancelingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_CANCEL_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_CANCEL_COMPLETED:
    case types.ROUTES_EXECUTION_CANCEL_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_EXECUTION_EDIT_STARTED: {
      return true;
    }
    case types.ROUTE_EXECUTION_EDIT_COMPLETED:
    case types.ROUTE_EXECUTION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFinalizingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_FINALIZE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_FINALIZE_COMPLETED:
    case types.ROUTES_EXECUTION_FINALIZE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCompletingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_COMPLETE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_COMPLETE_COMPLETED:
    case types.ROUTES_EXECUTION_COMPLETE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemovingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_REMOVE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_REMOVE_COMPLETED:
    case types.ROUTES_EXECUTION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPausingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_PAUSE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_PAUSE_COMPLETED:
    case types.ROUTES_EXECUTION_PAUSE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isStartingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_START_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_START_COMPLETED:
    case types.ROUTES_EXECUTION_START_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingManualTransitExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_COMPLETED:
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isSharingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_SHARE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_SHARE_COMPLETED:
    case types.ROUTES_EXECUTION_SHARE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isChangingToAlternateRoute = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED: {
      return true;
    }
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_COMPLETED:
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingExecutions = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTIONS_FETCH_STARTED:
    case types.ROUTES_EXECUTIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTE_EXECUTION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_EXECUTION_EDIT_STARTED:
    case types.ROUTE_EXECUTION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCancelingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_CANCEL_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_CANCEL_STARTED:
    case types.ROUTES_EXECUTION_CANCEL_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFinalizingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_FINALIZE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_FINALIZE_STARTED:
    case types.ROUTES_EXECUTION_FINALIZE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCompletingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_COMPLETE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_COMPLETE_STARTED:
    case types.ROUTES_EXECUTION_COMPLETE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemovingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_REMOVE_STARTED:
    case types.ROUTES_EXECUTION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPausingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_PAUSE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_PAUSE_STARTED:
    case types.ROUTES_EXECUTION_PAUSE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorStartingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_START_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_START_STARTED:
    case types.ROUTES_EXECUTION_START_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorUpdatingManualTransitExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED:
    case types.ROUTES_EXECUTION_MANUAL_TRANSIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorSharingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_SHARE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_SHARE_STARTED:
    case types.ROUTES_EXECUTION_SHARE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorChangingToAlternateRoute = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED:
    case types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const routesExecutions = combineReducers({
  byId,
  order,
  routesComments,
  indicators,
  isFetchingExecutions,
  isEditingExecution,
  isCancelingExecution,
  isFinalizingExecution,
  isCompletingExecution,
  isRemovingExecution,
  isSharingExecution,
  isPausingExecution,
  isStartingExecution,
  isUpdatingManualTransitExecution,
  isChangingToAlternateRoute,
  errorFetchingExecutions,
  errorEditingExecution,
  errorCancelingExecution,
  errorFinalizingExecution,
  errorCompletingExecution,
  errorRemovingExecution,
  errorPausingExecution,
  errorStartingExecution,
  errorUpdatingManualTransitExecution,
  errorSharingExecution,
  errorChangingToAlternateRoute
});

export default routesExecutions;

//Own state
export const getOwnState = (state) => state.routes.routesExecutions;

//Information
export const getRoutesExecution = (state, visitId) => {
  const routeExecution = getOwnState(state).byId[visitId];
  return getRouteExecutionInformation(state, routeExecution);
};
export const getRoutesExecutionsList = (state) =>
  getOwnState(state).order.map((id) => getRoutesExecution(state, id));
export const getIndicatorsInformation = (state) => {
  const indicators = getOwnState(state).indicators;
  const correctRunning =
    indicators.onOrigin + indicators.onDestination + indicators.onRoute + indicators.atCheckpoint;
  const incorrectRunning =
    indicators.paused + indicators.offRoute + indicators.eao + indicators.unauthorizedReturn;
  const running = correctRunning + incorrectRunning;
  const total =
    indicators.scheduled +
    indicators.scheduledBySchedule +
    indicators.finalized +
    indicators.canceled +
    running;
  return {
    ...indicators,
    total,
    correctRunning,
    incorrectRunning,
    running
  };
};
export const getTotalNotShown = (state) => getOwnState(state).indicators.totalNotShown ?? 0;
export const getIndicators = (state) => {
  const intl = appIntl();
  const indicators = getIndicatorsInformation(state);
  return [
    {
      title: intl.formatMessage({
        id: 'routes.total'
      }),
      permissions: [permissions.viewTotalIndicator, permissions.viewScheduledIndicator],
      bigIndicator: indicators.total || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'routes.onTime'
            }) +
            ': ' +
            parseInt(indicators.onTime || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.late'
            }) +
            ': ' +
            parseInt(indicators.late || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.canceled'
            }) +
            ': ' +
            parseInt(indicators.canceled || 0)
        },
        {
          id: 'scheduled',
          popoverTitle: intl.formatMessage({
            id: 'routes.scheduled'
          }),
          popoverBody:
            intl.formatMessage({
              id: 'routes.assignmentByDate'
            }) +
            ' | ' +
            intl.formatMessage({
              id: 'routes.assignmentBySchedule'
            }),
          info:
            intl.formatMessage({
              id: 'routes.scheduled'
            }) +
            ': ' +
            parseInt(indicators.scheduled || 0) +
            ' | ' +
            parseInt(indicators.scheduledBySchedule || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'routes.correctExecution'
      }),
      permissions: [permissions.viewRunningIndicator],
      bigIndicator: indicators.correctRunning || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'routes.atOrigin'
            }) +
            ': ' +
            parseInt(indicators.onOrigin || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.onRoute'
            }) +
            ': ' +
            parseInt(indicators.onRoute || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.atCheckpoint'
            }) +
            ': ' +
            parseInt(indicators.atCheckpoint || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.atDestination'
            }) +
            ': ' +
            parseInt(indicators.onDestination || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'routes.incorrectExecution'
      }),
      permissions: [permissions.viewRunningIndicator],
      bigIndicator: indicators.incorrectRunning || 0,
      details: [
        {
          popoverTitle: intl.formatMessage({
            id: 'routes.eao'
          }),
          popoverBody: intl.formatMessage({
            id: 'routes.eaoInfo'
          }),
          info:
            intl.formatMessage({
              id: 'routes.eao'
            }) +
            ': ' +
            parseInt(indicators.eao || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.paused'
            }) +
            ': ' +
            parseInt(indicators.paused || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.offRoute'
            }) +
            ': ' +
            parseInt(indicators.offRoute || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'routes.unauthorizedReturn'
            }) +
            ': ' +
            parseInt(indicators.unauthorizedReturn || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'routes.finalized'
      }),
      bigIndicator: indicators.finalized || 0
    }
  ];
};
export const getExecutionsStatus = (state) => {
  const intl = appIntl();
  const indicators = getIndicatorsInformation(state);
  var labels: any[] = [];
  var series: any[] = [];
  if (
    indicators.scheduled > 0 ||
    indicators.finalized > 0 ||
    indicators.canceled > 0 ||
    indicators.running > 0
  ) {
    labels = [
      intl.formatMessage({
        id: 'routes.scheduled'
      }),
      intl.formatMessage({
        id: 'routes.finalized'
      }),
      intl.formatMessage({
        id: 'routes.canceled'
      }),
      intl.formatMessage({
        id: 'routes.running'
      })
    ];
    series = [indicators.scheduled, indicators.finalized, indicators.canceled, indicators.running];
  }
  return {
    title: intl.formatMessage({
      id: 'routes.routeStatus'
    }),
    permissions: [permissions.viewRouteByStatusGraph],
    labels: labels,
    values: [[18], [6], [8], [1, 2, 3, 5, 7, 10, 15, 16]],
    series: series
  };
};
export const getRunningStatus = (state) => {
  const intl = appIntl();
  const indicators = getIndicatorsInformation(state);
  const colors = [
    '#44ABFF', // on origin
    '#00D57E', // on destination
    '#00EAD5', // on route
    '#e3e3e4', // old color '#FF7A00', // paused
    '#FF4459', // off route
    '#7669E8', // at checkpoint
    '#490002', // eao
    '#BA0000' // Unauthorized return
  ];
  var labels: any[] = [];
  var series: any[] = [];
  if (
    indicators.onOrigin > 0 ||
    indicators.onDestination > 0 ||
    indicators.onRoute > 0 ||
    indicators.paused > 0 ||
    indicators.offRoute > 0 ||
    indicators.atCheckpoint > 0 ||
    indicators.eao > 0 ||
    indicators.unauthorizedReturn > 0
  ) {
    labels = [
      intl.formatMessage({
        id: 'routes.atOrigin'
      }),
      intl.formatMessage({
        id: 'routes.atDestination'
      }),
      intl.formatMessage({
        id: 'routes.onRoute'
      }),
      intl.formatMessage({
        id: 'routes.paused'
      }),
      intl.formatMessage({
        id: 'routes.offRoute'
      }),
      intl.formatMessage({
        id: 'routes.atCheckpoint'
      }),
      intl.formatMessage({
        id: 'routes.eao'
      }),
      intl.formatMessage({
        id: 'routes.unauthorizedReturn'
      })
    ];
    series = [
      indicators.onOrigin,
      indicators.onDestination,
      indicators.onRoute,
      indicators.paused,
      indicators.offRoute,
      indicators.atCheckpoint,
      indicators.eao,
      indicators.unauthorizedReturn
    ];
  }
  return {
    title: intl.formatMessage({
      id: 'routes.runningStatus'
    }),
    permissions: [permissions.viewRouteRunningGraph],
    labels: labels,
    colors,
    values: [1, 2, 3, 5, 7, 10, 15, 16],
    series: series
  };
};
//Status of sagas
export const isFetchingRoutesExecutions = (state) => getOwnState(state).isFetchingExecutions;
export const isEditingRouteExecution = (state) => getOwnState(state).isEditingExecution;
export const isCancelingRoutesExecution = (state) => getOwnState(state).isCancelingExecution;
export const isFinalizingRoutesExecution = (state) => getOwnState(state).isFinalizingExecution;
export const isCompletingRoutesExecution = (state) => getOwnState(state).isCompletingExecution;
export const isRemovingRoutesExecution = (state) => getOwnState(state).isRemovingExecution;
export const isPausingRoutesExecution = (state) => getOwnState(state).isPausingExecution;
export const isStartingRoutesExecution = (state) => getOwnState(state).isStartingExecution;
export const isUpdatingManualTransitRoutesExecution = (state) =>
  getOwnState(state).isUpdatingManualTransitExecution;
export const isSharingRoutesExecution = (state) => getOwnState(state).isSharingExecution;
export const isChangingToAlternateRouteExecution = (state) =>
  getOwnState(state).isChangingToAlternateRoute;

//Errors
export const getFetchingRoutesExecutionsErrors = (state) =>
  getOwnState(state).errorFetchingExecutions;
export const getEditingRouteExecutionErrors = (state) => getOwnState(state).errorEditingExecution;
export const getCancelingRoutesExecutionErrors = (state) =>
  getOwnState(state).errorCancelingExecution;
export const getFinalizingRoutesExecutionErrors = (state) =>
  getOwnState(state).errorFinalizingExecution;
export const getCompletingRoutesExecutionErrors = (state) =>
  getOwnState(state).errorCompletingExecution;
export const getRemovingRoutesExecutionErrors = (state) =>
  getOwnState(state).errorRemovingExecution;
export const getPausingRoutesExecutionErrors = (state) => getOwnState(state).errorPausingExecution;
export const getStartingRoutesExecutionErrors = (state) =>
  getOwnState(state).errorStartingExecution;
export const getUpdatingManualTransitRoutesExecutionErrors = (state) =>
  getOwnState(state).errorUpdatingManualTransitExecution;
export const getSharingRoutesExecutionErrors = (state) => getOwnState(state).errorSharingExecution;
export const getChangingToAlternateRouteErrors = (state) =>
  getOwnState(state).errorChangingToAlternateRoute;
