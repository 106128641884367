import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as authActions from '../../../../redux/actions/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import * as entitySelectors from '../../Entities/reducers';
import { getUsersListService, saveUserService, deleteUserService } from '../services';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchMonitoringUsersList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getUsersListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { monitoringUsers },
          result //order
        } = normalize(resultData, schemas.monitoringUsers); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingMonitoringUsers(
            monitoringUsers,
            result,
            operatorId,
            organizationId
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingMonitoringUsers(error));
      }
    }
  );
}

export function* watchFetchMonitoringUsersList() {
  yield takeEvery(types.MONITORING_USERS_FETCH_STARTED, fetchMonitoringUsersList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchMonitoringUsersList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getUsersListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { monitoringUsers },
          result //order
        } = normalize(resultData, schemas.monitoringUsers); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingMonitoringUsers(
            monitoringUsers,
            result,
            operatorId,
            organizationId
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingMonitoringUsers(error));
      }
    }
  );
}

export function* watchPartialFetchMonitoringUsersList() {
  yield takeEvery(types.MONITORING_USERS_PARTIAL_FETCH_STARTED, partialFetchMonitoringUsersList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addMonitoringUser(action) {
  const monitoringUser = action.payload;
  saveUserService(
    { ...monitoringUser },
    {
      successAction: (response) => {
        const addedMonitoringUser = camelcaseKeys(response?.data[0]);
        store.dispatch(
          actions.completeAddingMonitoringUser(monitoringUser.userName, addedMonitoringUser)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingMonitoringUser(monitoringUser.userName, error));
      }
    }
  );
}

export function* watchAddMonitoringUsersStarted() {
  yield takeEvery(types.MONITORING_USER_ADD_STARTED, addMonitoringUser);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editMonitoringUser(action) {
  const monitoringUser = action.payload;
  const oldMonitoringUser = yield select((state) =>
    selectors.getMonitoringUser(state, monitoringUser.oldUserName)
  );
  saveUserService(
    { ...monitoringUser },
    {
      successAction: (response) => {
        const editedMonitoringUser = camelcaseKeys(response?.data[0]);
        store.dispatch(
          actions.completeEditingMonitoringUser(monitoringUser.oldUserName, editedMonitoringUser)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingMonitoringUser(oldMonitoringUser, error));
      }
    }
  );
}

export function* watchEditMonitoringUsersStarted() {
  yield takeEvery(types.MONITORING_USER_EDIT_STARTED, editMonitoringUser);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeMonitoringUser(action) {
  const { users, usersName } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/user/delete`, {
        method: 'POST',
        body: JSON.stringify({ users }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingMonitoringUser(users, usersName));

        //verificar si en la lista de usuario eliminados esta incluido el usuario logeado
        const loggedUsername = yield select(authSelectors.getAuthUsername);
        //si esta en la lista, forzamos un logout
        if (users.includes(loggedUsername)) yield put(authActions.logout());
        else
          alerts.showSuccessDeletedMessage({
            object: appIntl().formatMessage({
              id: users.length > 1 ? 'users.users' : 'users.user'
            }),
            multiple: users.length > 1,
            name: usersName
          });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingMonitoringUser(users, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingMonitoringUser(users, error));
  }
}

export function* watchRemoveMonitoringUserStarted() {
  yield takeEvery(types.MONITORING_USER_REMOVE_STARTED, removeMonitoringUser);
}

/* -------------------------------------------------------------------------- */
/*                          UPDATE USER PASSWORD                              */
/* -------------------------------------------------------------------------- */
function* updateMonitoringUserPassword(action) {
  const values = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/user/password`, {
        method: 'POST',
        body: JSON.stringify({
          ...values
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // alerts.closeLoading();
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.changePassword.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingMonitoringUserPassword(values));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.changePassword.errorMessageGeneral'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingMonitoringUserPassword(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.changePassword.errorMessageGeneral'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingMonitoringUserPassword(error));
  }
}

export function* watchUpdateMonitoringUserPasswordStarted() {
  yield takeEvery(types.MONITORING_USER_UPDATE_PASSWORD_STARTED, updateMonitoringUserPassword);
}

/* -------------------------------------------------------------------------- */
/*                        SAVE ROLES AND PERMISSIONS                          */
/* -------------------------------------------------------------------------- */
function* saveRolesAndPermissions(action) {
  const rolesAndPermissions = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/user/saveRolesAndPermissions`, {
        method: 'POST',
        body: JSON.stringify(rolesAndPermissions),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeSavingMonitoringUserRolesAndPermissions(rolesAndPermissions));
        alerts.showSuccessRolesAndPermissionsSavedMessage({
          name: rolesAndPermissions.userName
        });
        const userInSession = yield select(authSelectors.getAuthUser);
        if (rolesAndPermissions.userName === userInSession.username) {
          yield put(authActions.startForceRefreshToken());
        }
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchSaveMonitoringUserRolesAndPermissionsStarted() {
  yield takeEvery(types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_STARTED, saveRolesAndPermissions);
}

/* -------------------------------------------------------------------------- */
/*                                ASSIGN SCHEDULE                             */
/* -------------------------------------------------------------------------- */
function* assignSchedule(action) {
  const userAssignSchedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/user/AssignSchedule`, {
        method: 'POST',
        body: JSON.stringify(userAssignSchedule),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeAssigningScheduleUsers(userAssignSchedule));
        alerts.showSuccessAssignedMessage({
          object: appIntl().formatMessage({
            id: 'common.schedule'
          }),
          name: userAssignSchedule.scheduleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchAssignScheduleUsersStarted() {
  yield takeEvery(types.USERS_ASSIGN_SCHEDULE_STARTED, assignSchedule);
}

/* -------------------------------------------------------------------------- */
/*                              UNASSIGN SCHEDULE                             */
/* -------------------------------------------------------------------------- */
function* unassignSchedule(action) {
  const userAssignSchedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/user/UnassignSchedule`, {
        method: 'POST',
        body: JSON.stringify(userAssignSchedule),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeUnassigningScheduleUsers(userAssignSchedule));
        alerts.showSuccessUnassignedMessage({
          object: appIntl().formatMessage({
            id: 'common.schedule'
          })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchUnassignScheduleUsersStarted() {
  yield takeEvery(types.USERS_UNASSIGN_SCHEDULE_STARTED, unassignSchedule);
}
