export const JOBS_BACKLOG_FETCH_STARTED = 'JOBS_BACKLOG_FETCH_STARTED';
export const JOBS_BACKLOG_FETCH_COMPLETED = 'JOBS_BACKLOG_FETCH_COMPLETED';
export const JOBS_BACKLOG_FETCH_FAILED = 'JOBS_BACKLOG_FETCH_FAILED';

export const JOBS_BACKLOG_PARTIAL_FETCH_STARTED = 'JOBS_BACKLOG_PARTIAL_FETCH_STARTED';
export const JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED = 'JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED';
export const JOBS_BACKLOG_PARTIAL_FETCH_FAILED = 'JOBS_BACKLOG_PARTIAL_FETCH_FAILED';

export const JOB_BACKLOG_ADD_STARTED = 'JOB_BACKLOG_ADD_STARTED';
export const JOB_BACKLOG_ADD_COMPLETED = 'JOB_BACKLOG_ADD_COMPLETED';
export const JOB_BACKLOG_ADD_FAILED = 'JOB_BACKLOG_ADD_FAILED';

export const JOB_BACKLOG_EDIT_STARTED = 'JOB_BACKLOG_EDIT_STARTED';
export const JOB_BACKLOG_EDIT_COMPLETED = 'JOB_BACKLOG_EDIT_COMPLETED';
export const JOB_BACKLOG_EDIT_FAILED = 'JOB_BACKLOG_EDIT_FAILED';

export const JOB_BACKLOG_SELECTED = 'JOB_BACKLOG_SELECTED';
export const JOBS_BACKLOG_ALL_SELECTED = 'JOBS_BACKLOG_ALL_SELECTED';
export const JOB_BACKLOG_DESELECTED = 'JOB_BACKLOG_DESELECTED';
export const JOBS_BACKLOG_ALL_DESELECTED = 'JOBS_BACKLOG_ALL_DESELECTED';

export const JOBS_BACKLOG_REMOVE_STARTED = 'JOBS_BACKLOG_REMOVE_STARTED';
export const JOBS_BACKLOG_REMOVE_COMPLETED = 'JOBS_BACKLOG_REMOVE_COMPLETED';
export const JOBS_BACKLOG_REMOVE_FAILED = 'JOBS_BACKLOG_REMOVE_FAILED';

export const JOBS_BACKLOG_CHANGE_STATUS_STARTED = 'JOBS_BACKLOG_CHANGE_STATUS_STARTED';
export const JOBS_BACKLOG_CHANGE_STATUS_COMPLETED = 'JOBS_BACKLOG_CHANGE_STATUS_COMPLETED';
export const JOBS_BACKLOG_CHANGE_STATUS_FAILED = 'JOBS_BACKLOG_CHANGE_STATUS_FAILED';

export const JOBS_BACKLOG_CHANGE_PRIORITY_STARTED = 'JOBS_BACKLOG_CHANGE_PRIORITY_STARTED';
export const JOBS_BACKLOG_CHANGE_PRIORITY_COMPLETED = 'JOBS_BACKLOG_CHANGE_PRIORITY_COMPLETED';
export const JOBS_BACKLOG_CHANGE_PRIORITY_FAILED = 'JOBS_BACKLOG_CHANGE_PRIORITY_FAILED';

export const JOBS_BACKLOG_CHANGE_JOB_TYPE_STARTED = 'JOBS_BACKLOG_CHANGE_JOB_TYPE_STARTED';
export const JOBS_BACKLOG_CHANGE_JOB_TYPE_COMPLETED = 'JOBS_BACKLOG_CHANGE_JOB_TYPE_COMPLETED';
export const JOBS_BACKLOG_CHANGE_JOB_TYPE_FAILED = 'JOBS_BACKLOG_CHANGE_JOB_TYPE_FAILED';

export const JOBS_BACKLOG_CHANGE_START_DATE_STARTED = 'JOBS_BACKLOG_CHANGE_START_DATE_STARTED';
export const JOBS_BACKLOG_CHANGE_START_DATE_COMPLETED = 'JOBS_BACKLOG_CHANGE_START_DATE_COMPLETED';
export const JOBS_BACKLOG_CHANGE_START_DATE_FAILED = 'JOBS_BACKLOG_CHANGE_START_DATE_FAILED';

export const JOBS_BACKLOG_CHANGE_DUE_DATE_STARTED = 'JOBS_BACKLOG_CHANGE_DUE_DATE_STARTED';
export const JOBS_BACKLOG_CHANGE_DUE_DATE_COMPLETED = 'JOBS_BACKLOG_CHANGE_DUE_DATE_COMPLETED';
export const JOBS_BACKLOG_CHANGE_DUE_DATE_FAILED = 'JOBS_BACKLOG_CHANGE_DUE_DATE_FAILED';

export const JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_STARTED = 'JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_STARTED';
export const JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_COMPLETED =
  'JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_COMPLETED';
export const JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_FAILED = 'JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_FAILED';

export const JOBS_BACKLOG_ASSIGN_USERS_STARTED = 'JOBS_BACKLOG_ASSIGN_USERS_STARTED';
export const JOBS_BACKLOG_ASSIGN_USERS_COMPLETED = 'JOBS_BACKLOG_ASSIGN_USERS_COMPLETED';
export const JOBS_BACKLOG_ASSIGN_USERS_FAILED = 'JOBS_BACKLOG_ASSIGN_USERS_FAILED';

export const JOBS_BACKLOG_ASSIGN_FORMS_STARTED = 'JOBS_BACKLOG_ASSIGN_FORMS_STARTED';
export const JOBS_BACKLOG_ASSIGN_FORMS_COMPLETED = 'JOBS_BACKLOG_ASSIGN_FORMS_COMPLETED';
export const JOBS_BACKLOG_ASSIGN_FORMS_FAILED = 'JOBS_BACKLOG_ASSIGN_FORMS_FAILED';

export const JOBS_BACKLOG_ASSIGN_FILES_STARTED = 'JOBS_BACKLOG_ASSIGN_FILES_STARTED';
export const JOBS_BACKLOG_ASSIGN_FILES_COMPLETED = 'JOBS_BACKLOG_ASSIGN_FILES_COMPLETED';
export const JOBS_BACKLOG_ASSIGN_FILES_FAILED = 'JOBS_BACKLOG_ASSIGN_FILES_FAILED';

export const JOBS_BACKLOG_RESCHEDULE_JOBS_STARTED = 'JOBS_BACKLOG_RESCHEDULE_JOBS_STARTED';
export const JOBS_BACKLOG_RESCHEDULE_JOBS_COMPLETED = 'JOBS_BACKLOG_RESCHEDULE_JOBS_COMPLETED';
export const JOBS_BACKLOG_RESCHEDULE_JOBS_FAILED = 'JOBS_BACKLOG_RESCHEDULE_JOBS_FAILED';

export const JOBS_BACKLOG_IMPORT_STARTED = 'JOBS_BACKLOG_IMPORT_STARTED';
export const JOBS_BACKLOG_IMPORT_COMPLETED = 'JOBS_BACKLOG_IMPORT_COMPLETED';
export const JOBS_BACKLOG_IMPORT_FAILED = 'JOBS_BACKLOG_IMPORT_FAILED';
