import L from 'leaflet';
import { createPathComponent } from '@react-leaflet/core';
import { renderToString } from 'react-dom/server';
import 'leaflet.markercluster';
import { PathOptions } from 'leaflet';
import { ReactNode } from 'react';

type EventHandler = (...args: any[]) => void;

interface ClusterProps {
  spiderLegPolylineOptions?: PathOptions;
  removeOutsideVisibleBounds?: boolean;
  chunkedLoading?: boolean;
  iconCreateFunction?: (cluster: any) => any; // Replace 'any' with the actual types if known
}

interface Props extends ClusterProps {
  children?: ReactNode;
  icon?: any; // Replace 'any' with the actual type if known
}

const MarkerClusterGroup = createPathComponent(
  ({ children: _c, icon = null, ...props }: Props, ctx) => {
    const clusterProps = {
      spiderLegPolylineOptions: { pmIgnore: true, weight: 1.5, color: '#0d6efd', opacity: 0.5 },
      removeOutsideVisibleBounds: true,
      chunkedLoading: true,
      iconCreateFunction: function (cluster) {
        var childCount = cluster.getChildCount();

        var c = ' marker-cluster-';
        if (childCount < 10) {
          c += 'small';
        } else if (childCount < 100) {
          c += 'medium';
        } else {
          c += 'large';
        }

        return new L.DivIcon({
          html: icon
            ? renderToString(
                <div
                  style={{ fontSize: '10px' }}
                  className={'d-flex justify-content-center align-items-center'}
                >
                  <span>{childCount}</span>
                  {icon}
                </div>
              )
            : renderToString(
                <div style={{ fontSize: '10px' }}>
                  <span>{childCount}</span>
                </div>
              ),
          className: 'marker-cluster' + c,
          iconSize: new L.Point(40, 40)
        });
      }
    };
    const clusterEvents = {};

    // Splitting props and events to different objects
    Object.entries(props).forEach(([propName, prop]) =>
      propName.startsWith('on') ? (clusterEvents[propName] = prop) : (clusterProps[propName] = prop)
    );

    // Creating markerClusterGroup Leaflet element
    const markerClusterGroup = new L.markerClusterGroup(clusterProps);
    markerClusterGroup._getExpandedVisibleBounds = function () {
      return markerClusterGroup._map.getBounds();
    };

    // Initializing event listeners
    Object.entries(clusterEvents).forEach(([eventAsProp, callback]) => {
      const clusterEvent = `cluster${eventAsProp.substring(2).toLowerCase()}`;
      markerClusterGroup.on(clusterEvent, callback);
    });

    return {
      instance: markerClusterGroup,
      context: { ...ctx, layerContainer: markerClusterGroup }
    };
  }
);

export default MarkerClusterGroup;
