import { Key, useCallback, useState, useEffect } from 'react';

const useRerender = (dependencies: any[] = []) => {
  const [rerender, setRerender] = useState<Key>(new Date().getTime());

  const startRerender = useCallback(() => {
    setRerender(new Date().getTime());
  }, []);

  useEffect(() => {
    startRerender();
  }, dependencies);

  return { rerender, startRerender };
};

export { useRerender };
