/* -------------------------------------------------------------------------- */
/*                                Schema Groups                               */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const group = new schema.Entity('groups', {}, { idAttribute: 'groupId' });
export const groups = new schema.Array(group);

export const user = new schema.Entity('users', {}, { idAttribute: 'userName' });
export const users = new schema.Array(user);

export const location = new schema.Entity('locations', {}, { idAttribute: 'locationId' });
export const locations = new schema.Array(location);

export const workplan = new schema.Entity('workplans', {}, { idAttribute: 'workPlanId' });
export const workplans = new schema.Array(workplan);

export const unit = new schema.Entity('units', {}, { idAttribute: 'unitId' });
export const units = new schema.Array(unit);

export const geofence = new schema.Entity('geofences', {}, { idAttribute: 'geofenceId' });
export const geofences = new schema.Array(geofence);

export const driver = new schema.Entity('drivers', {}, { idAttribute: 'driverId' });
export const drivers = new schema.Array(driver);
