/*                         Tipos del reductor Entities                        */
/* -------------------------------------------------------------------------- */

export const ENTITIES_FETCH_STARTED = 'ENTITIES_FETCH_STARTED';
export const ENTITIES_FETCH_COMPLETED = 'ENTITIES_FETCH_COMPLETED';
export const ENTITIES_FETCH_FAILED = 'ENTITIES_FETCH_FAILED';
export const ENTITIES_RESET_IS_FETCHED = 'ENTITIES_RESET_IS_FETCHED';

export const ENTITIES_UPDATE_STARTED = 'ENTITIES_UPDATE_STARTED';
export const ENTITIES_UPDATE_COMPLETED = 'ENTITIES_UPDATE_COMPLETED';
export const ENTITIES_UPDATE_FAILED = 'ENTITIES_UPDATE_FAILED';
