import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import importedLocations, * as importedLocationsSelectors from './importedLocations';
import * as officeSelectors from 'views/screens/Offices/reducers';
import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.LOCATIONS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((locationId) => {
        newState[locationId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[locationId],
          ...entities[locationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.LOCATIONS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((locationId) => {
        newState[locationId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[locationId],
          ...entities[locationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.LOCATION_ADD_COMPLETED: {
      const { oldId, location } = action.payload;
      const newState = omit(state, oldId);
      newState[location.locationId] = {
        isShownInMap: false,
        ...state[oldId],
        ...location,
        isConfirmed: true
      };
      return newState;
    }

    case types.LOCATION_EDIT_COMPLETED: {
      const location = action.payload;
      return {
        ...state,
        [location.locationId]: {
          isShownInMap: false,
          ...omit(state[location.locationId], ['oldLocation']),
          ...omit(location, ['oldLocation']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.LOCATION_ADD_STARTED: {
      const location = action.payload;
      const newState = { ...state };
      newState[location.locationId] = {
        isShownInMap: false,
        isSelected: false,
        ...location,
        isConfirmed: false
      };
      return newState;
    }
    case types.LOCATION_EDIT_STARTED: {
      const location = action.payload;
      return {
        ...state,
        [location.locationId]: {
          isShownInMap: false,
          ...state[location.locationId],
          oldLocation: state[location.locationId],
          ...omit(location, 'isShownInMap'),
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.LOCATION_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.LOCATION_EDIT_FAILED: {
      const { oldLocation } = action.payload;
      return {
        ...state,
        [oldLocation.locationId]: {
          isShownInMap: false,
          ...omit(state[oldLocation.locationId], ['oldLocation']),
          ...oldLocation,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.LOCATION_REMOVE_COMPLETED: {
      const { locationId } = action.payload;
      return omit(state, locationId);
    }

    //TYPES SHOW AND HIDE LOCATIONS IN MAP
    case types.LOCATION_SHOW_IN_MAP: {
      const locationId = action.payload;
      const newState = {
        ...state,
        [locationId]: {
          ...state[locationId],
          isShownInMap: true
        }
      };
      return newState;
    }

    case types.LOCATION_HIDE_IN_MAP: {
      const locationId = action.payload;
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          isShownInMap: false
        }
      };
    }

    case types.LOCATIONS_ALL_SHOW_IN_MAP: {
      const locationIds = action.payload;
      const newState = { ...state };
      if (locationIds.length == 0) {
        forEach(state, (location: any, locationId) => {
          newState[locationId] = {
            ...location,
            isShownInMap: true
          };
        });
      } else {
        locationIds.forEach((locationId) => {
          newState[locationId] = {
            ...state[locationId],
            isShownInMap: true
          };
        });
      }

      return newState;
    }

    case types.LOCATIONS_ALL_HIDE_IN_MAP: {
      const locationIds = action.payload;
      const newState = { ...state };
      if (locationIds.length == 0) {
        forEach(state, (location: any, locationId) => {
          newState[locationId] = {
            ...location,
            isShownInMap: false
          };
        });
      } else {
        locationIds.forEach((locationId) => {
          newState[locationId] = {
            ...state[locationId],
            isShownInMap: false
          };
        });
      }

      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.LOCATION_SELECTED: {
      const locationId = action.payload;
      const newState = {
        ...state,
        [locationId]: {
          ...state[locationId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.LOCATION_DESELECTED: {
      const locationId = action.payload;
      return {
        ...state,
        [locationId]: {
          ...state[locationId],
          isSelected: false
        }
      };
    }

    case types.LOCATIONS_ALL_SELECTED: {
      const locationIds = action.payload;
      const newState = { ...state };
      if (locationIds.length == 0) {
        forEach(state, (location: any, locationId) => {
          newState[locationId] = {
            ...location,
            isSelected: true
          };
        });
      } else {
        locationIds.forEach((locationId) => {
          newState[locationId] = {
            ...state[locationId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.LOCATIONS_ALL_DESELECTED: {
      const locationIds = action.payload;
      const newState = { ...state };
      if (locationIds.length == 0) {
        forEach(state, (location: any, locationId) => {
          newState[locationId] = {
            ...location,
            isSelected: false
          };
        });
      } else {
        locationIds.forEach((locationId) => {
          newState[locationId] = {
            ...state[locationId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.LOCATIONS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.LOCATIONS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.LOCATION_ADD_COMPLETED: {
      const { oldId, location } = action.payload;
      return state.map((locationId) => (locationId === oldId ? location.locationId : locationId));
    }

    case types.LOCATION_REMOVE_COMPLETED: {
      const { locationId } = action.payload;
      return state.filter((locationIdState) => locationIdState !== locationId);
    }

    //CASE ADD STARTED
    case types.LOCATION_ADD_STARTED: {
      const location = action.payload;
      return [...state, location.locationId];
    }

    //CASE ADD FAILED
    case types.LOCATION_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((locationIdState) => locationIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATIONS_FETCH_STARTED: {
      return true;
    }
    case types.LOCATIONS_FETCH_COMPLETED:
    case types.LOCATIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATIONS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.LOCATIONS_PARTIAL_FETCH_COMPLETED:
    case types.LOCATIONS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATION_ADD_STARTED: {
      return true;
    }
    case types.LOCATION_ADD_COMPLETED:
    case types.LOCATION_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATION_EDIT_STARTED: {
      return true;
    }
    case types.LOCATION_EDIT_COMPLETED:
    case types.LOCATION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATION_REMOVE_STARTED: {
      return true;
    }
    case types.LOCATION_REMOVE_COMPLETED:
    case types.LOCATION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.LOCATIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATIONS_FETCH_STARTED:
    case types.LOCATIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.LOCATIONS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATIONS_PARTIAL_FETCH_STARTED:
    case types.LOCATIONS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.LOCATION_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATION_ADD_STARTED:
    case types.LOCATION_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.LOCATION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATION_EDIT_STARTED:
    case types.LOCATION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.LOCATION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATION_REMOVE_STARTED:
    case types.LOCATION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.LOCATIONS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const locations: any = combineReducers({
  byId,
  order,
  importedLocations,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

const locationsPersistedConfig = {
  key: 'locations',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'importedLocations',
    'isAdding',
    'isEditing',
    'isRemoving',
    'isFetchingList',
    'isPartialFetchingList'
  ],
  whitelist: ['byId', 'order']
  // blackList: [],
};

export default persistReducer(locationsPersistedConfig, locations);
// export default locations;

//Own state
export const getOwnState = (state) => state.locations;

//Information
export const getLocation = (state, locationId) => {
  return getOwnState(state).byId[locationId];
};

export const getLocationsList = (state) =>
  getOwnState(state)
    .order.map((id) => getLocation(state, id))
    .filter((location) => location.locationStatus > 0);

export const getLocationByPrivateCode = (state, privateCode) => {
  const location = getOwnState(state)
    .order.map((id) => getLocation(state, id))
    .find((location) => location.privateCode === privateCode);

  return location || undefined;
};

export const getLocationByPrivateCodeByMembership = (
  state,
  privateCode: string,
  membership: DefaultFilters
) => {
  return getLocationsListByMembership(state, membership).find(
    (location) => location?.privateCode === privateCode
  );
};

export const getLocationByGooglePlaceId = (state, googlePlaceId) => {
  if (googlePlaceId) {
    const locationsList = getLocationsList(state).filter(
      (location) => location?.googleLocation?.googlePlaceId == googlePlaceId
    );
    if (locationsList.length === 1) return locationsList[0];
  }
  return undefined;
};

export const getLocationsListByOrganization = (state, organizationId) =>
  organizationId != null
    ? getLocationsList(state).filter((location) => location.organizationId == organizationId)
    : getLocationsList(state);

export const getLocationsListByLocationClassId = (state, locationClassId) =>
  locationClassId != null
    ? getLocationsList(state).filter((location) => location.locationClassId == locationClassId)
    : getLocationsList(state);

export const getLocationsListByMembership = (state, membership) => {
  //Se filtra por membership
  return getLocationsList(state).filter(
    (location) =>
      membership &&
      (!membership.operatorId || membership.operatorId === location.operatorId) &&
      (!membership.organizationId || membership.organizationId === location.organizationId) &&
      (!membership.divisionId || membership.divisionId === location.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === location.subdivisionId) &&
      (!membership.groupId ||
        location.groups?.split(',').includes(String(membership.groupId)) ||
        membership.groupId + '' === location.groups)
  );
};

export const getLocationOffice = (state, locationId, useOnlyOffices) => {
  const location = getOwnState(state).byId[locationId];
  if (location) {
    const office = officeSelectors.getOffice(state, location.officeId) ?? {};
    if (!useOnlyOffices || office?.officeId)
      return {
        ...location,
        ...omit(office, ['privateCode', 'isConfirmed', 'isSelected']),
        officePrivateCode: office?.privateCode
      };
  } else return undefined;
};

export const getLocationsOfficesList = (state, useOnlyOffices) =>
  getOwnState(state)
    .order.map((id) => getLocationOffice(state, id, useOnlyOffices))
    .filter((location) => location && location.locationStatus > 0);

export const getLocationsOfficesListByMembership = (state, membership, useOnlyOffices) => {
  //Se filtra por membership
  return getLocationsOfficesList(state, useOnlyOffices).filter(
    (location) =>
      membership &&
      (!membership.operatorId || membership.operatorId === location.operatorId) &&
      (!membership.organizationId || membership.organizationId === location.organizationId) &&
      (!membership.divisionId || membership.divisionId === location.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === location.subdivisionId)
  );
};
export const getSelectedLocations = (state) => {
  const selectedLocations = getLocationsList(state).filter((location) => location.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedLocations.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedLocations.length > 0) return selectedLocations;
};

export const getSelectedLocation = (state) => {
  const selectedLocations = getLocationsList(state).filter((location) => location.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedLocations.length === 1) return selectedLocations[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListLocations = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListLocations = (state) => getOwnState(state).isFetchingList;
export const haveLocationsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingLocation = (state) => getOwnState(state).isAdding;
export const isEditingLocation = (state) => getOwnState(state).isEditing;
export const isRemovingLocation = (state) => getOwnState(state).isRemoving;
export const isCopyingLocation = (state) => getOwnState(state).isCopying;
export const isAssigningGeofenceLocation = (state) => getOwnState(state).isAssigningGeofence;

//Errors
export const getPartialFetchingListLocationsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListLocationsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingLocationErrors = (state) => getOwnState(state).errorAdding;
export const getEditingLocationErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingLocationErrors = (state) => getOwnState(state).errorRemoving;

/* -------------------------------------------------------------------------- */
/*                              IMPORTED LOCATIONS                            */
/* -------------------------------------------------------------------------- */
//Information
export const getImportedLocationsList = (state) =>
  importedLocationsSelectors.getLocationsList(state);

export const getImportedLocation = (state, locationId) =>
  importedLocationsSelectors.getLocation(state, locationId);

export const getImportedLocationsByPrivateCode = (state, privateCode) => {
  const importedLocations = getImportedLocationsList(state);
  return importedLocations.find((location) => location?.privateCode === privateCode);
};

//Status of sagas
export const isImportingLocations = (state) =>
  importedLocationsSelectors.isImportingLocations(state);

//Errors
export const getImportingLocationsErrors = (state) =>
  importedLocationsSelectors.getImportingLocationsErrors(state);
