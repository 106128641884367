import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as operatorSelectors from 'views/screens/Operators/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SUBDIVISIONS_FETCH_COMPLETED:
    case types.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((subdivisionId) => {
        newState[subdivisionId] = {
          isSelected: false,
          ...state[subdivisionId],
          ...entities[subdivisionId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SUBDIVISION_ADD_COMPLETED: {
      const { oldId, subdivision } = action.payload;
      const newState = omit(state, oldId);
      newState[subdivision.subdivisionId] = {
        ...state[oldId],
        ...subdivision,
        isConfirmed: true
      };
      return newState;
    }

    case types.SUBDIVISION_EDIT_COMPLETED: {
      const subdivision = action.payload;
      return {
        ...state,
        [subdivision.subdivisionId]: {
          ...omit(state[subdivision.subdivisionId], ['oldSubdivision']),
          ...omit(subdivision, ['oldSubdivision']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.SUBDIVISION_ADD_STARTED: {
      const subdivision = action.payload;
      const newState = { ...state };
      newState[subdivision.subdivisionId] = {
        isSelected: false,
        ...subdivision,
        isConfirmed: false
      };
      return newState;
    }
    case types.SUBDIVISION_EDIT_STARTED: {
      const subdivision = action.payload;
      return {
        ...state,
        [subdivision.subdivisionId]: {
          ...state[subdivision.subdivisionId],
          oldSubdivision: state[subdivision.subdivisionId],
          ...subdivision,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.SUBDIVISION_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.SUBDIVISION_EDIT_FAILED: {
      const { oldSubdivision } = action.payload;
      return {
        ...state,
        [oldSubdivision.subdivisionId]: {
          ...omit(state[oldSubdivision.subdivisionId], ['oldSubdivision']),
          ...oldSubdivision,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SUBDIVISION_REMOVE_COMPLETED: {
      const { subdivisionId } = action.payload;
      return omit(state, subdivisionId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.SUBDIVISION_SELECTED: {
      const subdivisionId = action.payload;
      const newState = {
        ...state,
        [subdivisionId]: {
          ...state[subdivisionId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.SUBDIVISION_DESELECTED: {
      const subdivisionId = action.payload;
      return {
        ...state,
        [subdivisionId]: {
          ...state[subdivisionId],
          isSelected: false
        }
      };
    }

    case types.SUBDIVISIONS_ALL_SELECTED: {
      const subdivisionIds = action.payload;
      const newState = { ...state };
      if (subdivisionIds.length == 0) {
        forEach(state, (subdivision: any, subdivisionId) => {
          newState[subdivisionId] = {
            ...subdivision,
            isSelected: true
          };
        });
      } else {
        subdivisionIds.forEach((subdivisionId) => {
          newState[subdivisionId] = {
            ...state[subdivisionId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.SUBDIVISIONS_ALL_DESELECTED: {
      const subdivisionIds = action.payload;
      const newState = { ...state };
      if (subdivisionIds.length == 0) {
        forEach(state, (subdivision: any, subdivisionId) => {
          newState[subdivisionId] = {
            ...subdivision,
            isSelected: false
          };
        });
      } else {
        subdivisionIds.forEach((subdivisionId) => {
          newState[subdivisionId] = {
            ...state[subdivisionId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SUBDIVISIONS_FETCH_COMPLETED:
    case types.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.SUBDIVISION_ADD_COMPLETED: {
      const { oldId, subdivision } = action.payload;
      return state.map((subdivisionId) =>
        subdivisionId === oldId ? subdivision.subdivisionId : subdivisionId
      );
    }

    case types.SUBDIVISION_REMOVE_COMPLETED: {
      const { subdivisionId } = action.payload;
      return state.filter((subdivisionIdState) => subdivisionIdState !== subdivisionId);
    }

    //CASE ADD STARTED
    case types.SUBDIVISION_ADD_STARTED: {
      const subdivision = action.payload;
      return [...state, subdivision.subdivisionId];
    }

    //CASE ADD FAILED
    case types.SUBDIVISION_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((subdivisionIdState) => subdivisionIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SUBDIVISIONS_FETCH_STARTED: {
      return true;
    }
    case types.SUBDIVISIONS_FETCH_COMPLETED:
    case types.SUBDIVISIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SUBDIVISIONS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED:
    case types.SUBDIVISIONS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SUBDIVISION_ADD_STARTED: {
      return true;
    }
    case types.SUBDIVISION_ADD_COMPLETED:
    case types.SUBDIVISION_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SUBDIVISION_EDIT_STARTED: {
      return true;
    }
    case types.SUBDIVISION_EDIT_COMPLETED:
    case types.SUBDIVISION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SUBDIVISION_REMOVE_STARTED: {
      return true;
    }
    case types.SUBDIVISION_REMOVE_COMPLETED:
    case types.SUBDIVISION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SUBDIVISIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SUBDIVISIONS_FETCH_STARTED:
    case types.SUBDIVISIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SUBDIVISIONS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SUBDIVISIONS_PARTIAL_FETCH_STARTED:
    case types.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SUBDIVISION_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SUBDIVISION_ADD_STARTED:
    case types.SUBDIVISION_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SUBDIVISION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SUBDIVISION_EDIT_STARTED:
    case types.SUBDIVISION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SUBDIVISION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SUBDIVISION_REMOVE_STARTED:
    case types.SUBDIVISION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const subdivisions = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default subdivisions;

//Own state
export const getOwnState = (state) => state.subdivisions;

//Information
export const getSubdivision = (state, subdivisionId) => {
  const subdivision = getOwnState(state).byId[subdivisionId];
  if (subdivision) {
    const operator = subdivision.operatorId
      ? operatorSelectors.getOperator(state, subdivision.operatorId)
      : undefined;
    const organization = subdivision.organizationId
      ? organizationSelectors.getOrganization(state, subdivision.organizationId)
      : undefined;
    const division = subdivision.divisionId
      ? divisionSelectors.getDivision(state, subdivision.divisionId)
      : undefined;
    return {
      ...subdivision,
      operatorName: operator ? operator.operatorName : '',
      organizationName: organization ? organization.organizationName : '',
      divisionName: division ? division.divisionName : ''
    };
  } else return undefined;
};
export const getSubdivisionsList = (state) =>
  getOwnState(state)
    .order.map((id) => getSubdivision(state, id))
    .filter((subdivision) => subdivision.subdivisionStatus > 0);
export const getSubdivisionsListByMembership = (state, membership) => {
  //Se filtra por divisionId
  if (membership.operatorId) {
    if (membership.organizationId) {
      if (membership.divisionId) {
        return getSubdivisionsList(state).filter(
          (subdivision) =>
            subdivision.operatorId === membership.operatorId &&
            subdivision.organizationId === membership.organizationId &&
            subdivision.divisionId === membership.divisionId
        );
      }
      return getSubdivisionsList(state).filter(
        (subdivision) =>
          subdivision.operatorId === membership.operatorId &&
          subdivision.organizationId === membership.organizationId
      );
    }
    return getSubdivisionsList(state).filter(
      (subdivision) => subdivision.operatorId === membership.operatorId
    );
  }
  //Si no se ha seleccionado una división se devuelve la lista completa
  else return getSubdivisionsList(state);
};
export const getSubdivisionsListBySelectedDivision = (state, membership) => {
  //Se filtra por divisionId
  if (membership.divisionId) {
    return getSubdivisionsList(state).filter(
      (subdivision) => subdivision.divisionId === membership.divisionId
    );
  }
  //Si no se ha seleccionado una división se devuelve una lista vacia
  else return [];
};

export const getSelectedSubdivisions = (state) => {
  const selectedSubdivisions = getSubdivisionsList(state).filter(
    (subdivision) => subdivision.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedSubdivisions.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedSubdivisions.length > 0) return selectedSubdivisions;
};

export const getSelectedSubdivision = (state) => {
  const selectedSubdivisions = getSubdivisionsList(state).filter(
    (subdivision) => subdivision.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedSubdivisions.length === 1) return selectedSubdivisions[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListSubdivisions = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListSubdivisions = (state) => getOwnState(state).isFetchingList;
export const isAddingSubdivision = (state) => getOwnState(state).isAdding;
export const isEditingSubdivision = (state) => getOwnState(state).isEditing;
export const isRemovingSubdivision = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListSubdivisionsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListSubdivisionsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingSubdivisionErrors = (state) => getOwnState(state).errorAdding;
export const getEditingSubdivisionErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingSubdivisionErrors = (state) => getOwnState(state).errorRemoving;
