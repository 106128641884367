import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/workmanship';
import { WorkmanshipDTO } from 'views/screens/vehicleMaintenance/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.WORKMANSHIPS_FETCH_COMPLETED:
    case types.WORKMANSHIPS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((workmanshipId) => {
        newState[workmanshipId] = {
          isSelected: false,
          ...state[workmanshipId],
          ...entities[workmanshipId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.WORKMANSHIP_ADD_COMPLETED: {
      const { id, oldId, workmanship } = action.payload;
      const newState = omit(state, oldId);
      newState[id] = {
        ...workmanship,
        isConfirmed: true,
        isSelected: false
      };
      return newState;
    }

    case types.WORKMANSHIP_EDIT_COMPLETED: {
      const workmanship = action.payload;
      const id = workmanship.workmanshipId;
      const newState = {
        ...state,
        [id]: {
          ...omit(state[id], ['oldWorkmanship']),
          ...omit(workmanship, ['oldWorkmanship']),
          isConfirmed: true
        }
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.WORKMANSHIP_ADD_STARTED: {
      const workmanship = action.payload;
      const newState = { ...state };
      newState[workmanship.tempId] = {
        isSelected: false,
        ...workmanship,
        isConfirmed: false
      };
      return newState;
    }

    case types.WORKMANSHIP_EDIT_STARTED: {
      const workmanship = action.payload;
      const id = workmanship.workmanshipId;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          oldWorkmanship: state[id],
          isConfirmed: false,
          ...workmanship
        }
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.WORKMANSHIP_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.WORKMANSHIP_EDIT_FAILED: {
      const { oldWorkmanship } = action.payload;
      return {
        ...state,
        [oldWorkmanship.workmanshipId]: {
          ...omit(state[oldWorkmanship.workmanshipId], ['oldWorkmanship']),
          ...oldWorkmanship,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.WORKMANSHIP_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return omit(state, id);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.WORKMANSHIPS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.WORKMANSHIPS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.WORKMANSHIP_ADD_COMPLETED: {
      const { id, oldId } = action.payload;
      return state.map((workmanshipId) => (workmanshipId === oldId ? id : workmanshipId));
    }

    case types.WORKMANSHIP_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return state.filter((workmanshipIdState) => workmanshipIdState !== id);
    }

    //CASE ADD STARTED
    case types.WORKMANSHIP_ADD_STARTED: {
      const workmanship = action.payload;
      return [...state, workmanship.tempId];
    }

    //CASE ADD FAILED
    case types.WORKMANSHIP_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((workmanshipIdState) => workmanshipIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIPS_FETCH_STARTED: {
      return true;
    }
    case types.WORKMANSHIPS_FETCH_COMPLETED:
    case types.WORKMANSHIPS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIPS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.WORKMANSHIPS_PARTIAL_FETCH_COMPLETED:
    case types.WORKMANSHIPS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_ADD_STARTED: {
      return true;
    }
    case types.WORKMANSHIP_ADD_COMPLETED:
    case types.WORKMANSHIP_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_EDIT_STARTED: {
      return true;
    }
    case types.WORKMANSHIP_EDIT_COMPLETED:
    case types.WORKMANSHIP_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_REMOVE_STARTED: {
      return true;
    }
    case types.WORKMANSHIP_REMOVE_COMPLETED:
    case types.WORKMANSHIP_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.WORKMANSHIPS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKMANSHIPS_FETCH_STARTED:
    case types.WORKMANSHIPS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.WORKMANSHIPS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKMANSHIPS_PARTIAL_FETCH_STARTED:
    case types.WORKMANSHIPS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKMANSHIP_ADD_STARTED:
    case types.WORKMANSHIP_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKMANSHIP_EDIT_STARTED:
    case types.WORKMANSHIP_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.WORKMANSHIP_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKMANSHIP_REMOVE_STARTED:
    case types.WORKMANSHIP_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.WORKMANSHIPS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const workmanship = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

export default workmanship;

//Own state
export const getOwnState = (state) => state.workmanship;

//Information
export const getWorkmanship = (state, id): types.Workmanship => {
  const workmanship = getOwnState(state).byId[id];

  return {
    ...workmanship
  };
};

export const getWorkmanshipsList = (state): types.Workmanship[] =>
  getOwnState(state).order?.map((id) => getWorkmanship(state, id));

export const getWorkmanshipsListByMembership = (state, membership) => {
  //Se filtra por divisionId
  if (membership.organizationId) {
    if (membership.divisionId) {
      if (membership.subdivisionId) {
        return getWorkmanshipsList(state).filter(
          (workmanship) =>
            workmanship.organizationId === membership.organizationId &&
            workmanship.divisionId === membership.divisionId &&
            workmanship.subdivisionId === membership.subdivisionId
        );
      }
      return getWorkmanshipsList(state).filter(
        (workmanship) =>
          workmanship.organizationId === membership.organizationId &&
          workmanship.divisionId === membership.divisionId
      );
    }
    return getWorkmanshipsList(state).filter(
      (workmanship) => workmanship.organizationId === membership.organizationId
    );
  }
  //Si no se ha seleccionado una división se devuelve la lista completa
  else return getWorkmanshipsList(state);
};

//Status of sagas
export const isPartialFetchingListWorkmanships = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListWorkmanships = (state) => getOwnState(state).isFetchingList;
export const haveWorkmanshipsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingWorkmanship = (state) => getOwnState(state).isAdding;
export const isEditingWorkmanship = (state) => getOwnState(state).isEditing;
export const isRemovingWorkmanship = (state) => getOwnState(state).isRemoving;
//Errors
export const getPartialFetchingListWorkmanshipsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListWorkmanshipsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingWorkmanshipErrors = (state) => getOwnState(state).errorAdding;
export const getEditingWorkmanshipErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingWorkmanshipErrors = (state) => getOwnState(state).errorRemoving;
