import { useEffect, useState } from 'react';
import { getDowntimeReasons } from '../services/index';
import camelcaseKeys from 'camelcase-keys';

const useGetDowntimeReasons = (
  organizationId: number | undefined,
  update?: boolean
): {
  downtimeReasons: any;
  isFetchingDowntimeReasons: boolean;
} => {
  const [downtimeReasons, setData] = useState<any>([]);
  const [isFetchingDowntimeReasons, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (organizationId !== undefined && organizationId !== null) {
      setIsFetching(true);
      getDowntimeReasons(
        {
          organizationId
        },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response && response?.data && response?.data?.length > 0) {
              setData(
                response.data.map((reason) => {
                  return camelcaseKeys({
                    ...reason
                  });
                })
              );
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [organizationId, update]);
  return { downtimeReasons, isFetchingDowntimeReasons };
};

export default useGetDowntimeReasons;
