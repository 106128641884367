import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { getSimsListService } from '../services';
import * as simSelectors from '../reducers';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchSimsList(action) {
  const {
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId,
    allowNullOrganizationId
  } = action.payload.filters;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getSimsListService(
    {
      operatorId,
      organizationId:
        allowNullOrganizationId && filterOrganizationId === null ? null : organizationId
    },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sims },
          result //order
        } = normalize(resultData, schemas.sims); //normalize data to byId and order
        store.dispatch(actions.completeFetchingSims(sims, result, operatorId, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingSims(error));
      }
    }
  );
}

export function* watchFetchSimsList() {
  yield takeEvery(types.SIMS_FETCH_STARTED, fetchSimsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchSimsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId,
    allowNullOrganizationId
  } = action.payload;

  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getSimsListService(
    {
      userFetchDate: date,
      operatorId,
      organizationId:
        allowNullOrganizationId && filterOrganizationId === null ? null : organizationId
    },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sims },
          result //order
        } = normalize(resultData, schemas.sims); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingSims(sims, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingSims(error));
      }
    }
  );
}

export function* watchPartialFetchSimsList() {
  yield takeEvery(types.SIMS_PARTIAL_FETCH_STARTED, partialFetchSimsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addSim(action) {
  const sim = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/sim/save`, {
        method: 'POST',
        body: JSON.stringify({ ...sim, simId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedSim = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingSim(sim.simId, addedSim));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'sims.sim'
          }),
          name: sim.iccid
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingSim(sim.simId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingSim(sim.simId, error));
  }
}

export function* watchAddSimsStarted() {
  yield takeEvery(types.SIM_ADD_STARTED, addSim);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editSim(action) {
  const sim = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/sim/save`, {
        method: 'POST',
        body: JSON.stringify(sim),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedSim = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingSim(editedSim));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'sims.sim'
          }),
          name: editedSim.iccid
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldSim } = yield select((state) => selectors.getSim(state.sims, sim.simId));
        yield put(actions.failEditingSim(oldSim, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldSim } = yield select((state) => selectors.getSim(state.sims, sim.simId));
    yield put(actions.failEditingSim(oldSim, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditSimsStarted() {
  yield takeEvery(types.SIM_EDIT_STARTED, editSim);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeSim(action) {
  const { simId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedSim = yield select((state) => simSelectors.getSim(state, simId));
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/sim/delete`, {
        method: 'POST',
        body: JSON.stringify({ simId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingSim(simId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'sims.sim'
          }),
          name: deletedSim.iccid
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingSim(simId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingSim(simId, error));
  }
}

export function* watchRemoveSimStarted() {
  yield takeEvery(types.SIM_REMOVE_STARTED, removeSim);
}
