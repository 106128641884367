import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/backlog';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import importedJobs, * as importedJobsSelectors from '../importedJobs';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.JOBS_BACKLOG_FETCH_COMPLETED:
    case types.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((jobId) => {
        newState[jobId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[jobId],
          ...entities[jobId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.JOB_BACKLOG_ADD_COMPLETED: {
      const { oldId, jobBacklog } = action.payload;
      const newState = omit(state, oldId);
      newState[jobBacklog.jobId] = {
        isShowInMap: false,
        ...state[oldId],
        ...jobBacklog,
        isConfirmed: true
      };
      return newState;
    }

    case types.JOB_BACKLOG_EDIT_COMPLETED: {
      const jobBacklog = action.payload;
      return {
        ...state,
        [jobBacklog.jobId]: {
          isShowInMap: false,
          ...omit(state[jobBacklog.jobId], ['oldJobBacklog']),
          ...omit(jobBacklog, ['oldJobBacklog']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.JOB_BACKLOG_ADD_STARTED: {
      const jobBacklog = action.payload;
      const newState = { ...state };
      newState[jobBacklog.jobId] = {
        isShowInMap: false,
        isSelected: false,
        ...jobBacklog,
        isConfirmed: false
      };
      return newState;
    }
    case types.JOB_BACKLOG_EDIT_STARTED: {
      const jobBacklog = action.payload;
      return {
        ...state,
        [jobBacklog.jobId]: {
          isShowInMap: false,
          ...state[jobBacklog.jobId],
          oldJobBacklog: state[jobBacklog.jobId],
          ...jobBacklog,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.JOB_BACKLOG_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.JOB_BACKLOG_EDIT_FAILED: {
      const { oldJobBacklog } = action.payload;
      return {
        ...state,
        [oldJobBacklog.jobId]: {
          isShowInMap: false,
          ...omit(state[oldJobBacklog.jobId], ['oldJobBacklog']),
          ...oldJobBacklog,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.JOBS_BACKLOG_REMOVE_COMPLETED: {
      const { jobs } = action.payload;
      return omit(state, jobs.split('^'));
    }

    //TYPES SELECTED AND DESELECTED
    case types.JOB_BACKLOG_SELECTED: {
      const jobId = action.payload;
      const newState = {
        ...state,
        [jobId]: {
          ...state[jobId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.JOB_BACKLOG_DESELECTED: {
      const jobId = action.payload;
      return {
        ...state,
        [jobId]: {
          ...state[jobId],
          isSelected: false
        }
      };
    }

    case types.JOBS_BACKLOG_ALL_SELECTED: {
      const jobIds = action.payload;
      const newState = { ...state };
      if (jobIds.length == 0) {
        forEach(state, (jobBacklog: any, jobId) => {
          newState[jobId] = {
            ...jobBacklog,
            isSelected: true
          };
        });
      } else {
        jobIds.forEach((jobId) => {
          newState[jobId] = {
            ...state[jobId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.JOBS_BACKLOG_ALL_DESELECTED: {
      const jobIds = action.payload;
      const newState = { ...state };
      if (jobIds.length == 0) {
        forEach(state, (jobBacklog: any, jobId) => {
          newState[jobId] = {
            ...jobBacklog,
            isSelected: false
          };
        });
      } else {
        jobIds.forEach((jobId) => {
          newState[jobId] = {
            ...state[jobId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //CASE TYPE JOBS CHANGE STATUS
    case types.JOBS_BACKLOG_CHANGE_STATUS_COMPLETED: {
      const { jobs, jobStatus } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          jobStatus: jobStatus
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE PRIORITY
    case types.JOBS_BACKLOG_CHANGE_PRIORITY_COMPLETED: {
      const { jobs, jobPriority } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          jobPriority: jobPriority
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE JOB_TYPE
    case types.JOBS_BACKLOG_CHANGE_JOB_TYPE_COMPLETED: {
      const { jobs, jobTypeId } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          jobTypeId: jobTypeId
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE START_DATE
    case types.JOBS_BACKLOG_CHANGE_START_DATE_COMPLETED: {
      const { jobs, startDate } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          startDate: startDate
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE DUE_DATE
    case types.JOBS_BACKLOG_CHANGE_DUE_DATE_COMPLETED: {
      const { jobs, dueDate } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          dueDate: dueDate
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE MIN_MAX_TIME
    case types.JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_COMPLETED: {
      const { jobs, maxTime } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          maxTime: maxTime,
          estimatedDuration: maxTime
        };
      });
      return newState;
    }

    //CASE TYPE JOBS ASSIGN USERS
    case types.JOBS_BACKLOG_ASSIGN_USERS_COMPLETED: {
      const { jobs, users } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          users: users
        };
      });
      return newState;
    }

    //CASE TYPE JOBS ASSIGN FORMS
    case types.JOBS_BACKLOG_ASSIGN_FORMS_COMPLETED: {
      const { jobs, forms } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          forms: forms
        };
      });
      return newState;
    }

    //CASE TYPE JOBS ASSIGN FILES
    case types.JOBS_BACKLOG_ASSIGN_FILES_COMPLETED: {
      const { jobs, order } = action.payload;
      const newState = { ...state };
      order.forEach((jobId) => {
        newState[jobId] = {
          ...newState[jobId],
          ...jobs[jobId]
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE STATUS
    case types.JOBS_BACKLOG_RESCHEDULE_JOBS_COMPLETED: {
      const { jobs, startDate, dueDate, comment } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          jobStatus: 6,
          startDate: startDate,
          dueDate: dueDate
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.JOBS_BACKLOG_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.JOB_BACKLOG_ADD_COMPLETED: {
      const { oldId, jobBacklog } = action.payload;
      return state.map((jobId) => (jobId === oldId ? jobBacklog.jobId : jobId));
    }

    case types.JOB_BACKLOG_ADD_STARTED: {
      const jobBacklog = action.payload;
      return [...state, jobBacklog.jobId];
    }

    case types.JOBS_BACKLOG_REMOVE_COMPLETED: {
      const { jobs } = action.payload;
      return state.filter((jobIdState) => !jobs.split('^').includes(jobIdState));
    }

    //CASE ADD STARTED
    case types.JOB_BACKLOG_ADD_STARTED: {
      const jobBacklog = action.payload;
      return [...state, jobBacklog.jobId];
    }

    //CASE ADD FAILED
    case types.JOB_BACKLOG_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((jobIdState) => jobIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BACKLOG_FETCH_STARTED: {
      return true;
    }
    case types.JOBS_BACKLOG_FETCH_COMPLETED:
    case types.JOBS_BACKLOG_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BACKLOG_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED:
    case types.JOBS_BACKLOG_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOB_BACKLOG_ADD_STARTED: {
      return true;
    }
    case types.JOB_BACKLOG_ADD_COMPLETED:
    case types.JOB_BACKLOG_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOB_BACKLOG_EDIT_STARTED: {
      return true;
    }
    case types.JOB_BACKLOG_EDIT_COMPLETED:
    case types.JOB_BACKLOG_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BACKLOG_REMOVE_STARTED: {
      return true;
    }
    case types.JOBS_BACKLOG_REMOVE_COMPLETED:
    case types.JOBS_BACKLOG_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRescheduling = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BACKLOG_RESCHEDULE_JOBS_STARTED: {
      return true;
    }
    case types.JOBS_BACKLOG_RESCHEDULE_JOBS_COMPLETED:
    case types.JOBS_BACKLOG_RESCHEDULE_JOBS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_BACKLOG_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_BACKLOG_FETCH_STARTED:
    case types.JOBS_BACKLOG_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_BACKLOG_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_BACKLOG_PARTIAL_FETCH_STARTED:
    case types.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOB_BACKLOG_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOB_BACKLOG_ADD_STARTED:
    case types.JOB_BACKLOG_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOB_BACKLOG_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOB_BACKLOG_EDIT_STARTED:
    case types.JOB_BACKLOG_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_BACKLOG_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_BACKLOG_REMOVE_STARTED:
    case types.JOBS_BACKLOG_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const jobsBacklog: any = combineReducers({
  byId,
  order,
  importedJobs,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  isRescheduling,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

const jobsBacklogPersistedConfig = {
  key: 'jobsBacklog',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'importedJobs',
    'isAdding',
    'isEditing',
    'isRemoving',
    'isRescheduling',
    'isFetchingList',
    'isPartialFetchingList'
  ],
  whitelist: ['byId', 'order']
};

export default persistReducer(jobsBacklogPersistedConfig, jobsBacklog);

//Own state
export const getOwnState = (state) => state.jobs.jobsBacklog;

//Information
export const getJobBacklog = (state, jobId) => {
  const jobBacklog = getOwnState(state).byId[jobId];
  if (jobBacklog) {
    const organization = jobBacklog.organizationId
      ? organizationSelectors.getOrganization(state, jobBacklog.organizationId)
      : undefined;
    const division = jobBacklog.divisionId
      ? divisionSelectors.getDivision(state, jobBacklog.divisionId)
      : undefined;
    const subdivision = jobBacklog.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, jobBacklog.subdivisionId)
      : undefined;
    return {
      ...jobBacklog,
      organizationName: organization ? organization.organizationName : '',
      divisionName: division ? division.divisionName : '',
      subdivisionName: subdivision ? subdivision.subdivisionName : ''
    };
  } else return undefined;
};
export const getJobsBacklogList = (state) =>
  getOwnState(state)
    .order.map((id) => getJobBacklog(state, id))
    .filter((jobBacklog) => jobBacklog && jobBacklog.jobStatus > 0);
export const getJobsBacklogListByOrganization = (state, organizationId) =>
  organizationId != null
    ? getJobsBacklogList(state).filter((jobBacklog) => jobBacklog.organizationId == organizationId)
    : getJobsBacklogList(state);
export const getJobsBacklogListByMembership = (state, membership) => {
  return getJobsBacklogList(state).filter(
    (jobBacklog) =>
      membership &&
      (!membership.operatorId || membership.operatorId === jobBacklog.operatorId) &&
      (!membership.organizationId || membership.organizationId === jobBacklog.organizationId) &&
      (!membership.divisionId || membership.divisionId === jobBacklog.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === jobBacklog.subdivisionId)
  );
};
export const getSelectedJobsBacklog = (state) => {
  const selectedJobsBacklog = getJobsBacklogList(state).filter(
    (jobBacklog) => jobBacklog.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedJobsBacklog.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedJobsBacklog.length > 0) return selectedJobsBacklog;
};

export const getSelectedJobBacklog = (state) => {
  const selectedJobsBacklog = getJobsBacklogList(state).filter(
    (jobBacklog) => jobBacklog.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedJobsBacklog.length === 1) return selectedJobsBacklog[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListJobsBacklog = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListJobsBacklog = (state) => getOwnState(state).isFetchingList;
export const isAddingJobBacklog = (state) => getOwnState(state).isAdding;
export const isEditingJobBacklog = (state) => getOwnState(state).isEditing;
export const isRemovingJobBacklog = (state) => getOwnState(state).isRemoving;
export const isReschedulingJobBacklog = (state) => getOwnState(state).isRescheduling;

//Errors
export const getPartialFetchingListJobsBacklogErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListJobsBacklogErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingJobBacklogErrors = (state) => getOwnState(state).errorAdding;
export const getEditingJobBacklogErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingJobBacklogErrors = (state) => getOwnState(state).errorRemoving;

/* -------------------------------------------------------------------------- */
/*                              IMPORTED JOBS                                 */
/* -------------------------------------------------------------------------- */
//Information
export const getImportedJobsList = (state) => importedJobsSelectors.getJobsList(state);
export const getImportedJob = (state, jobId) => importedJobsSelectors.getJob(state, jobId);

//Status of sagas
export const isImportingJobs = (state) => importedJobsSelectors.isImportingJobs(state);

//Errors
export const getImportingJobsErrors = (state) =>
  importedJobsSelectors.getImportingJobsErrors(state);
