import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SERVICECLASSES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((serviceClassId) => {
        newState[serviceClassId] = {
          isSelected: false,
          ...entities[serviceClassId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SERVICECLASS_FETCH_COMPLETED: {
      const serviceClass = action.payload;
      const newState = { ...state };
      newState[serviceClass.serviceClassId] = {
        isSelected: false,
        ...state[serviceClass.serviceClassId],
        ...serviceClass,
        isConfirmed: true
      };
      return newState;
    }

    case types.SERVICECLASS_ADD_COMPLETED: {
      const { oldId, serviceClass } = action.payload;
      const newState = omit(state, oldId);
      newState[serviceClass.serviceClassId] = {
        ...state[oldId],
        ...serviceClass,
        isConfirmed: true
      };
      return newState;
    }

    case types.SERVICECLASS_EDIT_COMPLETED: {
      const serviceClass = action.payload;
      return {
        ...state,
        [serviceClass.serviceClassId]: {
          ...state[serviceClass.serviceClassId],
          ...serviceClass,
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.SERVICECLASS_ADD_STARTED: {
      const serviceClass = action.payload;
      state[serviceClass.serviceClassId] = {
        isSelected: false,
        ...serviceClass,
        isConfirmed: false
      };
      return state;
    }
    case types.SERVICECLASS_EDIT_STARTED: {
      const serviceClass = action.payload;
      return {
        ...state,
        [serviceClass.serviceClassId]: {
          ...state[serviceClass.serviceClassId],
          ...serviceClass,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.SERVICECLASS_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.SERVICECLASS_EDIT_FAILED: {
      const { oldServiceClass } = action.payload;
      return {
        ...state,
        [oldServiceClass.serviceClassId]: {
          ...state[oldServiceClass.serviceClassId],
          ...oldServiceClass,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SERVICECLASS_REMOVE_COMPLETED: {
      const serviceClassId = action.payload;
      return omit(state, serviceClassId.serviceClassId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.SERVICECLASS_SELECTED: {
      const serviceClassId = action.payload;
      return {
        ...state,
        [serviceClassId]: {
          ...state[serviceClassId],
          isSelected: true
        }
      };
    }

    case types.SERVICECLASS_DESELECTED: {
      const serviceClassId = action.payload;
      return {
        ...state,
        [serviceClassId]: {
          ...state[serviceClassId],
          isSelected: false
        }
      };
    }

    case types.SERVICECLASSES_ALL_SELECTED: {
      const serviceClassIds = action.payload;
      const newState = { ...state };
      if (serviceClassIds.length == 0) {
        forEach(state, (serviceClass: any, serviceClassId) => {
          newState[serviceClassId] = {
            ...serviceClass,
            isSelected: true
          };
        });
      } else {
        serviceClassIds.forEach((serviceClassId) => {
          newState[serviceClassId] = {
            ...state[serviceClassId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.SERVICECLASSES_ALL_DESELECTED: {
      const serviceClassIds = action.payload;
      const newState = { ...state };
      if (serviceClassIds.length == 0) {
        forEach(state, (serviceClass: any, serviceClassId) => {
          newState[serviceClassId] = {
            ...serviceClass,
            isSelected: false
          };
        });
      } else {
        serviceClassIds.forEach((serviceClassId) => {
          newState[serviceClassId] = {
            ...state[serviceClassId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SERVICECLASSES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.SERVICECLASS_FETCH_COMPLETED: {
      const serviceClass = action.payload;
      return uniq([...state, serviceClass.serviceClassId]);
    }

    case types.SERVICECLASS_ADD_COMPLETED: {
      const { oldId, serviceClass } = action.payload;
      return state.map((serviceClassId) =>
        serviceClassId === oldId ? serviceClass.serviceClassId : serviceClassId
      );
    }

    case types.SERVICECLASS_REMOVE_COMPLETED: {
      const serviceClassId = action.payload;
      return state.filter(
        (serviceClassIdState) => serviceClassIdState !== serviceClassId.serviceClassId
      );
    }

    //CASE ADD STARTED
    case types.SERVICECLASS_ADD_STARTED: {
      const serviceClass = action.payload;
      return [...state, serviceClass.serviceClassId];
    }

    //CASE ADD FAILED
    case types.SERVICECLASS_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((serviceClassIdState) => serviceClassIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SERVICECLASSES_FETCH_STARTED: {
      return true;
    }
    case types.SERVICECLASSES_FETCH_COMPLETED:
    case types.SERVICECLASSES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.SERVICECLASS_FETCH_STARTED: {
      return true;
    }
    case types.SERVICECLASS_FETCH_COMPLETED:
    case types.SERVICECLASS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SERVICECLASS_ADD_STARTED: {
      return true;
    }
    case types.SERVICECLASS_ADD_COMPLETED:
    case types.SERVICECLASS_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SERVICECLASS_EDIT_STARTED: {
      return true;
    }
    case types.SERVICECLASS_EDIT_COMPLETED:
    case types.SERVICECLASS_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SERVICECLASS_REMOVE_STARTED: {
      return true;
    }
    case types.SERVICECLASS_REMOVE_COMPLETED:
    case types.SERVICECLASS_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SERVICECLASSES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SERVICECLASSES_FETCH_STARTED:
    case types.SERVICECLASSES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.SERVICECLASS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SERVICECLASS_FETCH_STARTED:
    case types.SERVICECLASS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SERVICECLASS_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SERVICECLASS_ADD_STARTED:
    case types.SERVICECLASS_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SERVICECLASS_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SERVICECLASS_EDIT_STARTED:
    case types.SERVICECLASS_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SERVICECLASS_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SERVICECLASS_REMOVE_STARTED:
    case types.SERVICECLASS_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const serviceClasses = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default serviceClasses;

export const getOwnState = (state) => state.serviceClasses;

export const getServiceClass = (state, serviceClassId) => {
  return getOwnState(state).byId[serviceClassId];
};
export const getServiceClasssList = (state) =>
  getOwnState(state).order.map((id) => getOwnState(state).byId[id]);

export const getSelectedServiceClass = (state) => {
  const selectedServiceClasses = getServiceClasssList(state).filter(
    (serviceClass) => serviceClass.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedServiceClasses.length === 1) return selectedServiceClasses[0];
  //De lo contrario se devuelve null
  else return null;
};

//status of sagas
export const isFetchingListServiceClasses = (state) => getOwnState(state).isFetchingList;
