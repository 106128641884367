export const SCHEDULES_FETCH_STARTED = 'SCHEDULES_FETCH_STARTED';
export const SCHEDULES_FETCH_COMPLETED = 'SCHEDULES_FETCH_COMPLETED';
export const SCHEDULES_FETCH_FAILED = 'SCHEDULES_FETCH_FAILED';

export const SCHEDULES_PARTIAL_FETCH_STARTED = 'SCHEDULES_PARTIAL_FETCH_STARTED';
export const SCHEDULES_PARTIAL_FETCH_COMPLETED = 'SCHEDULES_PARTIAL_FETCH_COMPLETED';
export const SCHEDULES_PARTIAL_FETCH_FAILED = 'SCHEDULES_PARTIAL_FETCH_FAILED';

export const SCHEDULE_ADD_STARTED = 'SCHEDULE_ADD_STARTED';
export const SCHEDULE_ADD_COMPLETED = 'SCHEDULE_ADD_COMPLETED';
export const SCHEDULE_ADD_FAILED = 'SCHEDULE_ADD_FAILED';

export const SCHEDULE_SHIFT_ADD_STARTED = 'SCHEDULE_SHIFT_ADD_STARTED';
export const SCHEDULE_SHIFT_ADD_COMPLETED = 'SCHEDULE_SHIFT_ADD_COMPLETED';
export const SCHEDULE_SHIFT_ADD_FAILED = 'SCHEDULE_SHIFT_ADD_FAILED';

export const SCHEDULE_EDIT_STARTED = 'SCHEDULE_EDIT_STARTED';
export const SCHEDULE_EDIT_COMPLETED = 'SCHEDULE_EDIT_COMPLETED';
export const SCHEDULE_EDIT_FAILED = 'SCHEDULE_EDIT_FAILED';

export const SCHEDULE_SHIFT_EDIT_STARTED = 'SCHEDULE_SHIFT_EDIT_STARTED';
export const SCHEDULE_SHIFT_EDIT_COMPLETED = 'SCHEDULE_SHIFT_EDIT_COMPLETED';
export const SCHEDULE_SHIFT_EDIT_FAILED = 'SCHEDULE_SHIFT_EDIT_FAILED';

export const SCHEDULE_REMOVE_STARTED = 'SCHEDULE_REMOVE_STARTED';
export const SCHEDULE_REMOVE_COMPLETED = 'SCHEDULE_REMOVE_COMPLETED';
export const SCHEDULE_REMOVE_FAILED = 'SCHEDULE_REMOVE_FAILED';

export const SCHEDULE_SELECTED = 'SCHEDULE_SELECTED';
export const SCHEDULES_ALL_SELECTED = 'SCHEDULES_ALL_SELECTED';
export const SCHEDULE_DESELECTED = 'SCHEDULE_DESELECTED';
export const SCHEDULES_ALL_DESELECTED = 'SCHEDULES_ALL_DESELECTED';
