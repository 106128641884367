/* -------------------------------------------------------------------------- */
/*                              Schema Currencies                             */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const currency = new schema.Entity(
  "currencies",
  {},
  { idAttribute: "currencyId" }
);
export const currencies = new schema.Array(currency);
