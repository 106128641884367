import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'contacts',
    title: 'contacts.contacts2',
    permissions: ['CO001'],
    iconName: 'Contacts',
    navLink: paths.listContact,
    screenId: screens.CONTACTS
  }
];
