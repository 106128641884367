import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { deviceMetricsEntities, deviceMetricsOrder } = action.payload;
      const newState = { ...state };
      deviceMetricsOrder.forEach((deviceMetricId) => {
        newState[deviceMetricId] = {
          ...state[deviceMetricId],
          ...deviceMetricsEntities[deviceMetricId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { deviceMetricsOrder } = action.payload;
      return uniq([...state, ...deviceMetricsOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const deviceMetrics = combineReducers({
  byId,
  order
});

export default deviceMetrics;

//Own state
export const getOwnState = (state) => state.units.deviceMetrics;

//Information
export const getDeviceMetric = (state, deviceMetricId) => {
  const deviceMetric = getOwnState(state).byId[deviceMetricId];
  if (deviceMetric) {
    return {
      ...deviceMetric
    };
  } else return undefined;
};
export const getDeviceMetricsList = (state) =>
  getOwnState(state).order.map((id) => getDeviceMetric(state, id));
