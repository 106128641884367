import { HttpWebtrack } from 'utility/http';
import { SaveWorkmanshipCategoryDTO, DeleteWorkmanshipCategoryDTO } from '../types';
import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/catalogs';

export const saveWorkmanshipCategory = (request: SaveWorkmanshipCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.description}`,
    object: 'vehicleMaintenance.workmanship',
    action: `${request.workmanshipId ? 'Edit' : 'Create'}`,
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/workmanship`, request);
};

export const deleteWorkmanshipCategory = (request: DeleteWorkmanshipCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.description}`,
    object: 'vehicleMaintenance.workmanship',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.get(`${baseUrl}/deleteWorkmanship/${request.workmanshipId}`);
};

export const getWorkmanshipsList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/workmanship`);
};
