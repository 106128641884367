import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routesReport';
import * as authSelectors from '../../../../../redux/reducers/auth';
import { getDatesDifferenceString } from 'views/screens/Units/utils';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const indicators = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { indicators } = action.payload;
      const newState = { ...indicators };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const unitsById = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { unitsEntities, unitsOrder } = action.payload;
      const newState = { ...state };
      unitsOrder.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          ...state[unitId],
          ...unitsEntities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const unitsOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { unitsOrder } = action.payload;
      return union(unitsOrder);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const routesById = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { routesEntities, routesOrder } = action.payload;
      const newState = { ...state };
      routesOrder.forEach((routeId) => {
        newState[routeId] = {
          isSelected: false,
          ...state[routeId],
          ...routesEntities[routeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const routesOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      const { routesOrder } = action.payload;
      return union(routesOrder);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingReport = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_REPORT_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_REPORT_FETCH_COMPLETED:
    case types.ROUTES_REPORT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingReport = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_REPORT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_REPORT_FETCH_STARTED:
    case types.ROUTES_REPORT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const routesReport = combineReducers({
  byId,
  order,
  indicators,
  unitsById,
  unitsOrder,
  routesById,
  routesOrder,
  isFetchingReport,
  errorFetchingReport
});

export default routesReport;

//Own state
export const getOwnState = (state) => state.routes.routesReport;

//Information
export const getRoutesExecution = (state, routeAssignmentId) => {
  const intl = appIntl();
  const routeExecution = getOwnState(state).byId[routeAssignmentId];
  const parsers = authSelectors.getParsers(state);
  const {
    convertKilometersPerHourToAuthUserSpeedSystem,
    convertKilometersToAuthUserDistanceSystem,
    convertMetersToAuthUserDistanceSystem,
    convertLitersToAuthUserCapacitySystem,
    convertKilometersPerLiterToAuthUserFuelSystem
  } = parsers;
  return {
    ...routeExecution,
    realDistanceLabel: authSelectors
      .getParsers(state)
      .convertKilometersToAuthUserDistanceSystem(routeExecution.realDistance || 0),
    calculatedDistanceLabel: authSelectors
      .getParsers(state)
      .convertMetersToAuthUserDistanceSystem(routeExecution.calculatedDistance || 0),
    effectivenessLabel: (routeExecution.effectiveness || 0).toString() + '%',
    durationLabel: routeExecution.startDate
      ? getDatesDifferenceString(
          routeExecution.startDate,
          routeExecution.completionDate ?? routeExecution.cancelDate ?? new Date(),
          intl
        )
      : 'N/A',
    transitDurationLabel: routeExecution.transitDate
      ? getDatesDifferenceString(
          routeExecution.transitDate,
          routeExecution.completionDate ?? routeExecution.cancelDate ?? new Date(),
          intl
        )
      : 'N/A',
    fuelConsumption: convertLitersToAuthUserCapacitySystem(routeExecution.fuelConsumption || 0),
    expectedPerformance: convertKilometersPerLiterToAuthUserFuelSystem(
      routeExecution.expectedPerformance || 0
    ),
    performanceOnRoute: convertKilometersPerLiterToAuthUserFuelSystem(
      routeExecution.performanceOnRoute || 0
    ),
    distanceProgress: convertKilometersToAuthUserDistanceSystem(
      routeExecution.distanceProgress || 0
    )
  };
};
export const getRoutesExecutionsList = (state) =>
  getOwnState(state).order.map((id) => getRoutesExecution(state, id));
export const getRoutesUnit = (state, unitId) => {
  const unit = getOwnState(state).unitsById[unitId];
  const parsers = authSelectors.getParsers(state);
  const {
    convertKilometersToAuthUserDistanceSystem,
    convertMetersToAuthUserDistanceSystem,
    convertLitersToAuthUserCapacitySystem,
    convertKilometersPerLiterToAuthUserFuelSystem
  } = parsers;
  return {
    ...unit,
    realDistanceLabel: convertKilometersToAuthUserDistanceSystem(unit.realDistance || 0),
    calculatedDistanceLabel: convertMetersToAuthUserDistanceSystem(unit.calculatedDistance || 0),
    effectivenessLabel: (unit.effectiveness || 0).toString() + '%',
    fuelConsumption: convertLitersToAuthUserCapacitySystem(unit.fuelConsumption || 0),
    expectedPerformance: convertKilometersPerLiterToAuthUserFuelSystem(
      unit.expectedPerformance || 0
    ),
    performanceOnRoute: convertKilometersPerLiterToAuthUserFuelSystem(unit.performanceOnRoute || 0)
  };
};
export const getRoutesUnitsList = (state) =>
  getOwnState(state).unitsOrder.map((id) => getRoutesUnit(state, id));
export const getRoutesRoute = (state, routeId) => {
  const route = getOwnState(state).routesById[routeId];
  return {
    ...route,
    realDistanceLabel: authSelectors
      .getParsers(state)
      .convertMetersToAuthUserDistanceSystem(route.realDistance || 0),
    calculatedDistanceLabel: authSelectors
      .getParsers(state)
      .convertMetersToAuthUserDistanceSystem(route.calculatedDistance || 0),
    fuelConsumptionLabel: authSelectors
      .getParsers(state)
      .convertLitersToAuthUserCapacitySystem(route.fuelConsumption || 0),
    effectivenessLabel: (route.effectiveness || 0).toString() + '%'
  };
};
export const getRoutesRoutesList = (state) =>
  getOwnState(state).routesOrder.map((id) => getRoutesRoute(state, id));
export const getIndicators = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  return [
    {
      title: intl.formatMessage({
        id: 'routes.total'
      }),
      bigIndicator: indicators.total || 0
    },
    {
      title: intl.formatMessage({
        id: 'routes.finalized'
      }),
      bigIndicator: indicators.finalized || 0
    },
    {
      title: intl.formatMessage({
        id: 'routes.canceled'
      }),
      bigIndicator: indicators.canceled || 0
    },
    {
      title: intl.formatMessage({
        id: 'routes.running'
      }),
      bigIndicator: indicators.running || 0
    }
  ];
};

//Status of sagas
export const isFetchingRoutesReport = (state) => getOwnState(state).isFetchingReport;

//Errors
export const getFetchingRoutesReportErrors = (state) => getOwnState(state).errorFetchingReport;
