import * as types from '../../../types/static-catalogs/brands';

export const startFetchingBrands = (filters = {}) => ({
  type: types.BRANDS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingBrands = (entities, order) => ({
  type: types.BRANDS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingBrands = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.BRANDS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
