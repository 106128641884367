export const ROUTES_FETCH_STARTED = 'ROUTES_FETCH_STARTED';
export const ROUTES_FETCH_COMPLETED = 'ROUTES_FETCH_COMPLETED';
export const ROUTES_FETCH_FAILED = 'ROUTES_FETCH_FAILED';

export const ROUTES_PARTIAL_FETCH_STARTED = 'ROUTES_PARTIAL_FETCH_STARTED';
export const ROUTES_PARTIAL_FETCH_COMPLETED = 'ROUTES_PARTIAL_FETCH_COMPLETED';
export const ROUTES_PARTIAL_FETCH_FAILED = 'ROUTES_PARTIAL_FETCH_FAILED';

export const ROUTE_ADD_STARTED = 'ROUTE_ADD_STARTED';
export const ROUTE_ADD_COMPLETED = 'ROUTE_ADD_COMPLETED';
export const ROUTE_ADD_FAILED = 'ROUTE_ADD_FAILED';

export const ROUTE_EDIT_STARTED = 'ROUTE_EDIT_STARTED';
export const ROUTE_EDIT_COMPLETED = 'ROUTE_EDIT_COMPLETED';
export const ROUTE_EDIT_FAILED = 'ROUTE_EDIT_FAILED';

export const ROUTE_REMOVE_STARTED = 'ROUTE_REMOVE_STARTED';
export const ROUTE_REMOVE_COMPLETED = 'ROUTE_REMOVE_COMPLETED';
export const ROUTE_REMOVE_FAILED = 'ROUTE_REMOVE_FAILED';

export const ROUTES_REMOVE_STARTED = 'ROUTES_REMOVE_STARTED';
export const ROUTES_REMOVE_COMPLETED = 'ROUTES_REMOVE_COMPLETED';
export const ROUTES_REMOVE_FAILED = 'ROUTES_REMOVE_FAILED';

export const ROUTE_SELECTED = 'ROUTE_SELECTED';
export const ROUTES_ALL_SELECTED = 'ROUTES_ALL_SELECTED';
export const ROUTE_DESELECTED = 'ROUTE_DESELECTED';
export const ROUTES_ALL_DESELECTED = 'ROUTES_ALL_DESELECTED';

export const ROUTE_COPY_STARTED = 'ROUTE_COPY_STARTED';
export const ROUTE_COPY_COMPLETED = 'ROUTE_COPY_COMPLETED';
export const ROUTE_COPY_FAILED = 'ROUTE_COPY_FAILED';

export const ROUTE_SHOW_IN_MAP = 'ROUTE_SHOW_IN_MAP';
export const ROUTE_HIDE_IN_MAP = 'ROUTE_HIDE_IN_MAP';
export const ROUTES_ALL_SHOW_IN_MAP = 'ROUTES_ALL_SHOW_IN_MAP';
export const ROUTES_ALL_HIDE_IN_MAP = 'ROUTES_ALL_HIDE_IN_MAP';

export interface Route {
  isShownInMap: boolean;
  isSelected: boolean;
  routeId: number;
  routeName: string;
  abbreviation: string;
  distance: number;
  sourceName: string;
  targetName: string;
  privateCode: null | string;
  globalNotificationEmails: null | string;
  globalNotificationFrec: null | string;
  notificationEmails: null | string;
  notificationEvents: null | string;
  operatorId: number;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: string | null;
  subdivisionId: number | null;
  subdivisionName: string | null;
  completionTimeByUser: number;
  speedLimit: number | null;
  startTimeWindow: number | null;
  routeGroupId: number | null;
  checkPoints: number;
  routeStatus: number;
  customerId: number | null;
  customerName: null | string;
  isConfirmed: boolean;
  checkpoints: any[];
  distanceFormat: string;
  speedLimitFormat: number;
}
