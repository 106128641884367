import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../settings/apibaseurl';
import * as actions from '../../actions/models';
import * as types from '../../types/models';
import * as schemas from '../../schemas/models';
import * as authSelectors from '../../reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../utils/alerts';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchModelsList(action) {
  const { brandId } = action.payload;
  if (brandId) {
    try {
      const isAuth = yield select(authSelectors.isAuthenticated);
      if (isAuth) {
        const token = yield select(authSelectors.getAuthToken);
        const response = yield call(fetch, `${API_BASE_URL}/model/${brandId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });
        const jsonResult = yield response.json();
        if (response.status <= 300 && jsonResult.success) {
          const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
          const {
            entities: { models },
            result //order
          } = normalize(resultData, schemas.models); //normalize data to byId and order
          yield put(actions.completeFetchingModels(models, result));
        } else {
          const error = {
            errorMessage: jsonResult.message,
            errorNumber: jsonResult.number
          };
          // alerts.showErrorAlertFailChanges({ error });
          yield put(actions.failFetchingModels(error));
        }
      }
    } catch (err) {
      const error = {
        errorMessage: err.toString(),
        errorNumber: -1
      };
      // alerts.showErrorAlertFailChanges({ error });
      yield put(actions.failFetchingModels(error));
    }
  }
}

export function* watchFetchModelsList() {
  yield takeEvery(types.MODELS_FETCH_STARTED, fetchModelsList);
}
