//** React Imports
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { ENTITY_OPERATORS, ENTITY_ORGANIZATIONS } from 'views/screens/Entities/constants';
import * as AuthSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../../screens/Entities/reducers';

export interface Membership {
  operatorId: number | undefined;
  organizationId: number | undefined;
  divisionId: number | undefined;
  subdivisionId: number | undefined;
  groupId: number | undefined;
}

export interface DefaultOptions {
  // Tamaño de los inputs
  colSize?: any;
  // Permitir deseleccionar dropdowns
  showAllOperator?: boolean;
  showAllOrganization?: boolean;
  showAllAgent?: boolean;
  showAllDivision?: boolean;
  showAllSubdivision?: boolean;
  showAllGroup?: boolean;
  // Usar la membreship del usuario como valor por defecto
  useUserOperator?: boolean;
  useUserOrganization?: boolean;
  useUserAgent?: boolean;
  useUserDivision?: boolean;
  useUserSubdivision?: boolean;
  useUserGroup?: boolean;
  // Setear la membreship del usuario como valor por defecto aunque el usuario sea de nivel menor (Se usa para crear/editar una entidad)
  setUserOperator?: boolean;
  setUserOrganization?: boolean;
  setUserAgent?: boolean;
  setUserDivision?: boolean;
  setUserSubdivision?: boolean;
  setUserGroup?: boolean;
  // Filtrar por organizacion
  filterOrganization?: number;
  // Mostrar/ocultar un dropdown
  useOperator?: boolean;
  useOrganization?: boolean;
  useAgent?: boolean;
  useDivision?: boolean;
  useSubdivision?: boolean;
  useGroup?: boolean;
  // Habilitar/Deshabilitar un dropdown
  isOperatorDisabled?: boolean;
  isOrganizationDisabled?: boolean;
  isAgentDisabled?: boolean;
  isDivisionDisabled?: boolean;
  isSubdivisionDisabled?: boolean;
  isGroupDisabled?: boolean;
  // Placeholder de un dropdown
  subdivisionPlaceholder?: any;
}

const defaultOptions = {
  colSize: '3',
  showAllOperator: false,
  showAllOrganization: false,
  showAllAgent: false,
  showAllDivision: false,
  showAllSubdivision: false,
  showAllGroup: false,
  useUserOperator: false,
  useUserOrganization: false,
  useUserAgent: false,
  useUserDivision: false,
  useUserSubdivision: false,
  useUserGroup: false,
  setUserOperator: true,
  setUserOrganization: true,
  setUserAgent: false,
  setUserDivision: true,
  setUserSubdivision: true,
  setUserGroup: true,
  useOperator: true,
  useOrganization: true,
  useAgent: false,
  useDivision: true,
  useSubdivision: true,
  useGroup: false,
  isOperatorDisabled: false,
  isOrganizationDisabled: false,
  isAgentDisabled: false,
  isDivisionDisabled: false,
  isSubdivisionDisabled: false,
  isGroupDisabled: false
};

export const useMembership = (options, model) => {
  //Initialize State
  const [isInitialize, setIsInitialize] = useState<boolean>(false);
  //State Model
  const [membershipDefaultValue, setMembershipDefaultValue] = useState(model);
  //State Options
  const [membershipOptions, setMembershipOptions] = useState<DefaultOptions>({
    ...defaultOptions,
    ...options
  });

  //Al cambiar las opciones
  useDeepCompareEffect(() => {
    updateOptions(options);
  }, [options]);

  //Al cargar por primera vez el componente
  useEffect(() => {
    membershipAccessLevel();
    updateOptions({
      ...membershipOptions,
      ...membershipAccessLevel()
    });
  }, []);

  //Selectors
  const { user, areAllEntitiesUpdated } = useSelector((state) => ({
    user: AuthSelectors.getAuthUserForMembership(state),
    areAllEntitiesUpdated: entitySelectors.areAllEntitiesUpdated(state, [
      ENTITY_OPERATORS,
      ENTITY_ORGANIZATIONS
    ])
  }));

  //Al terminar de hacer update de las entities
  useEffect(() => {
    if (areAllEntitiesUpdated) {
      updateUserMembership({
        ...membershipOptions,
        ...membershipAccessLevel()
      });
      setIsInitialize(true);
    }
  }, [areAllEntitiesUpdated]);

  //Se restringe dependiendo del nivel de acceso del usuario
  const membershipAccessLevel = () => {
    var useOperator = membershipOptions.useOperator;
    var useOrganization = membershipOptions.useOrganization;
    var useDivision = membershipOptions.useDivision;
    var useSubdivision = membershipOptions.useSubdivision;
    if (user.accessLevelId == 11 || user.accessLevelId == 13) {
      useOperator = false;
    }
    if (user.accessLevelId == 7) {
      useOperator = false;
      useOrganization = false;
    }
    if (user.accessLevelId == 4) {
      useOperator = false;
      useOrganization = false;
      useDivision = false;
    }
    if (user.accessLevelId == 2 || user.accessLevelId == 20) {
      useOperator = false;
      useOrganization = false;
      useDivision = false;
      useSubdivision = false;
    }
    return {
      useOperator,
      useOrganization,
      useDivision,
      useSubdivision
    };
  };

  //Actualiza el modelo de defaultValues segun el membership del usuario
  const updateUserMembership = (membershipOptions) => {
    //Se cargan las membership del usuario
    var userMembership: any = membershipDefaultValue;
    //Se setea el operador del usuario en sesión
    if (
      ((membershipOptions.useOperator && membershipOptions.useUserOperator) ||
        (membershipOptions.setUserOperator && ![9, 15].includes(parseInt(user.accessLevelId)))) &&
      (!membershipDefaultValue || !membershipDefaultValue.operatorId)
    ) {
      userMembership.operatorId = parseInt(user.operatorId);
      userMembership.operatorName = user.operatorName;
    }
    //Se setea la organización del usuario en sesión
    if (
      ((membershipOptions.useOrganization && membershipOptions.useUserOrganization) ||
        (membershipOptions.setUserOrganization &&
          ![9, 11, 13, 15].includes(parseInt(user.accessLevelId)))) &&
      (!membershipDefaultValue || !membershipDefaultValue.organizationId)
    ) {
      userMembership.organizationId = parseInt(user.organizationId);
      userMembership.organizationName = user.organizationName;
    }
    //Se setea la división del usuario en sesión
    if (
      ((membershipOptions.useDivision && membershipOptions.useUserDivision) ||
        (membershipOptions.setUserDivision &&
          ![7, 9, 11, 13, 15].includes(parseInt(user.accessLevelId)))) &&
      (!membershipDefaultValue || !membershipDefaultValue.divisionId)
    ) {
      userMembership.divisionId = parseInt(user.divisionId);
      userMembership.divisionName = user.divisionName;
    }
    //Se setea la subdivisión del usuario en sesión
    if (
      ((membershipOptions.useSubdivision && membershipOptions.useUserSubdivision) ||
        (membershipOptions.setUserSubdivision &&
          ![4, 7, 9, 11, 13, 15].includes(parseInt(user.accessLevelId)))) &&
      (!membershipDefaultValue || !membershipDefaultValue.subdivisionId)
    ) {
      userMembership.subdivisionId = parseInt(user.subdivisionId);
      userMembership.subdivisionName = user.subdivisionName;
    }
    updateModel(userMembership);
  };

  //Se actualiza todo el modelo
  const updateModel = (model) => {
    setMembershipDefaultValue({
      ...membershipDefaultValue,
      ...model
    });
  };

  //Se actualiza todas las opciones
  const updateOptions = (options) => {
    setMembershipOptions({
      ...membershipOptions,
      ...options
    });
  };

  return [membershipDefaultValue, membershipOptions, isInitialize];
};
