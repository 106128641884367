/* -------------------------------------------------------------------------- */
/*                          Componente DropdownInput                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownInput genérico que se utiliza en muchas pantallas de la aplicación.

import _ from 'lodash';
import { CSSProperties, Component, FC, useEffect } from 'react';
import { Control, ControllerFieldState, ControllerRenderProps, Field } from 'react-hook-form';
import { FormattedMessage, IntlShape } from 'react-intl';
import Select, { components, GroupBase, Props as SelectProps } from 'react-select';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { FormGroup, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { isNotNullOrUndefined } from 'utility/Utils';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const { Option, SingleValue, NoOptionsMessage } = components;
//Filter option needed to work with formatted messages
const filterOption = (candidate, input) => {
  let showOptionFilter = false;
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data.label &&
        _.deburr(candidate.data.label.toLowerCase()).includes(_.deburr(input.toLowerCase())));
  } catch (err) {}
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data.label_intl &&
        _.deburr(candidate.data.label_intl.toLowerCase()).includes(_.deburr(input.toLowerCase())));
  } catch (err) {}

  return showOptionFilter;
};

const IconOption = (props) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {props.data.iconSVG && (
          <img
            src={props.data.iconSVG}
            style={{
              display: 'inline-block',
              width: '1em',
              height: '1em',
              marginRight: '0.5rem',
              marginTop: '0.25rem'
            }}
          />
        )}
        {props.data.label}
      </div>
    </Option>
  );
};
const IconSingleValue = (props) => {
  return (
    <SingleValue {...props}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {props.data.iconSVG && (
          <img
            src={props.data.iconSVG}
            style={{
              display: 'inline-block',
              width: '1em',
              height: '1em',
              marginRight: '0.5rem',
              marginTop: '0.25rem'
            }}
          />
        )}
        {props.data.label}
      </div>
    </SingleValue>
  );
};

const cache = new CellMeasurerCache({
  defaultHeight: 60,
  fixedWidth: true
});

const MenuList = (props) => {
  const rows = props.children;
  const rowRenderer = ({ key, index, parent, style }) => (
    <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
      {({ measure, registerChild }) => (
        <div ref={registerChild} key={key} style={style}>
          {rows[index]}
        </div>
      )}
    </CellMeasurer>
  );

  return rows.length > 0 ? (
    <AutoSizer disableWidth>
      {({ height }) => (
        <List
          containerStyle={{
            width: '100%',
            backgroundColor: '#ffffff',
            maxWidth: '100%'
          }}
          style={{
            width: '100%'
          }}
          width={1}
          height={250}
          rowCount={rows.length || 0}
          rowRenderer={rowRenderer}
          rowHeight={(ref) => {
            const height = cache.rowHeight(ref);
            return height + height * 0.2;
          }}
        />
      )}
    </AutoSizer>
  ) : (
    <NoOptionsMessage {...props} />
  );
};

export type Options = {
  value: string | number;
  id?: string | number;
  label_intl?: string;
  label?: string | JSX.Element;
}[];
interface Props extends SelectProps {
  id: string;
  field: ControllerRenderProps | any;
  fieldstate: ControllerFieldState | any;
  hasPlaceholder?: boolean;
  options: Options;
  disabled?: boolean;
  label?: string | JSX.Element | null;
  placeholder?: string | Element | JSX.Element;
  isClearable?: boolean;
  intl?: IntlShape;
  isInModal?: boolean;
  errorMessage?: string;
  required?: boolean;
  forceinitialvalue?: string | number | null;
  onClear?: () => void;
  onSelect?: (event) => void;
  additionalonchangefunction?: (value) => void;
  isDisabled?: boolean;
  menuIsOpen?: boolean;
  onInputChange?: (value) => void;
  minWidth?: number;
  prependcomponent?: string | JSX.Element;
  appendcomponent?: string | JSX.Element;
  style?: CSSProperties;
  onFocus?: () => void;
  isLoading?: boolean;
}

export type DropdownProps = Props;

const DropdownInput: FC<Props> = (props) => {
  //const [touched, setTouched] = useState(false);
  const {
    field,
    fieldstate,
    hasPlaceholder = true,
    options = [],
    intl,
    isInModal = false,
    errorMessage,
    required,
    forceinitialvalue,
    onClear,
    additionalonchangefunction = (value) => null,
    isDisabled = false,
    menuIsOpen,
    onInputChange,
    minWidth,
    onFocus,
    ...inputProps
  } = props;
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  useEffect(() => {
    if (forceinitialvalue !== undefined) onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);

  useEffect(() => {
    // if options are not an array, return void
    if (!Array.isArray(options)) {
      return;
    }
    const isInArray = options.find((o) => o?.value == forceinitialvalue);

    // if element is found in array
    // set as initial value, this code only excecutes itself when
    if (isNotNullOrUndefined(isInArray)) {
      if (forceinitialvalue !== undefined) onChangeFinal(forceinitialvalue);
      return;
    }

    // forceinitialvalue has a value
    // is not in the array and is defined
    if (isNotNullOrUndefined(forceinitialvalue)) {
      onChangeFinal(null);
    }
  }, [options?.length]);

  const onChangeFinal = (value) => {
    field.onChange(value);

    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  return (
    <FormGroup>
      {inputProps.label && (
        <Label className="form-label" for={inputProps.id}>
          {inputProps.label}
          {required && <span className="required">&nbsp;*</span>}
        </Label>
      )}
      <InputGroup>
        <InputGroupAddon addonType="prepend">{props.prependcomponent}</InputGroupAddon>
        <Select
          {...field}
          {...inputProps}
          // Se comentó al hacer cambio a .tsx ya que está repetido
          // menuPortalTarget={document.getElementById('portal-target')}
          styles={{
            menuPortal: (styles) => ({ ...styles, zIndex: 91000 }),
            input: (provided, state) => ({
              minWidth: minWidth
            }),
            control: (provided, state) => {
              return {
                ...provided,

                boxShadow: state.isFocused ? 'none!important' : 'none!important',
                border: state.isFocused
                  ? `1px solid ${primary} !important`
                  : '1px solid #d8d6de !important'
              };
            },
            singleValue: (provided, state) => {
              return {
                ...provided,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.45,
                color: '#6e6b7b',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
              };
            },
            menuList: (provided, state) => {
              return {
                ...provided,

                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.45,
                color: '#6e6b7b',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
              };
            }
          }}
          id={inputProps.id}
          components={{ Option: IconOption, SingleValue: IconSingleValue, MenuList }}
          placeholder={
            inputProps.placeholder ?? appIntl().formatMessage({ id: 'common.selectOption' })
          }
          className={
            fieldstate.error != null ? 'has-error custom-dropdown-input' : 'custom-dropdown-input'
          }
          onChange={(event, triggeredAction) => {
            if (triggeredAction.action === 'clear' && onClear) onClear();
            onChangeFinal(event ? event.value : null);
            if (inputProps.onSelect) inputProps.onSelect(event);
          }}
          onBlur={field.onBlur}
          onFocus={onFocus}
          options={options}
          filterOption={filterOption}
          isClearable={inputProps.isClearable}
          isDisabled={isDisabled}
          value={
            options.filter((option) => {
              const fieldValue = String(field.value);
              const optValue = String(option.value);
              const selectedValue = /^\d+$/.test(fieldValue) ? parseInt(field.value) : field.value;
              const optionValue = /^\d+$/.test(optValue) ? parseInt(optValue) : option.value;
              return optionValue === selectedValue;
            })[0] || null
          }
          menuIsOpen={menuIsOpen}
          noOptionsMessage={() => appIntl().formatMessage({ id: 'common.noOptions' })}
          maxMenuHeight={250}
          menuPortalTarget={isInModal ? undefined : document.querySelector('body')} // fix issue https://github.com/JedWatson/react-select/issues/3263
        />
        <InputGroupAddon addonType="append">{props.appendcomponent}</InputGroupAddon>
      </InputGroup>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

export default DropdownInput;
