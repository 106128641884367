import * as types from '../../../types/static-catalogs/sensor-brands';

export const startFetchingSensorBrands = (filters = {}) => ({
  type: types.SENSOR_BRANDS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingSensorBrands = (entities, order) => ({
  type: types.SENSOR_BRANDS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingSensorBrands = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SENSOR_BRANDS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
