import Icon from '../../utility/icomoon';
import paths from 'views/screens/Users/paths';
import permissions from 'views/screens/Users/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'users',
    title: 'users.users',
    icon: <Icon size={20} icon="noun_User_2905483" />,
    permissions: [permissions.listUsers],
    iconName: 'noun_User_2905483',
    navLink: paths.listUsers,
    screenId: screens.USERS
  }
];
