/* -------------------------------------------------------------------------- */
/*                       Schema Insurance Agents                              */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const insuranceAgent = new schema.Entity(
  'insuranceAgents',
  {},
  { idAttribute: 'insuranceAgentId' }
);
export const insuranceAgents = new schema.Array(insuranceAgent);
