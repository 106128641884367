import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip, Spinner } from 'reactstrap';
import Icon from 'utility/icomoon';
import * as authSelectors from '../../../redux/reducers/auth';

interface Props {
  id: string;
  icon: string;
  permissions?: any[];
  isDisabled?: boolean;
  handleClick: any;
  className?: string;
  tooltipMessage?: any;
  visible?: boolean;
  color?: string;
  iconSize?: number;
  isLoading?: boolean;
}

const DefaultButton = (props: Props) => {
  const store = useSelector((state) => state);
  const {
    isDisabled,
    isLoading = false,
    handleClick,
    tooltipMessage,
    icon,
    id,
    permissions,
    color,
    className,
    iconSize = 16,
    visible = true
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (!permissions || authSelectors.getAuthUserHasPermissionsAny(store, permissions)) &&
    visible ? (
    <Button.Ripple
      id={id}
      className={`btn-icon ${isDisabled ? 'disabled' : ''} ${className}`}
      color={color ?? 'secondary'}
      disabled={isDisabled}
      onClick={handleClick}
    >
      {isLoading ? (
        <>
          <Spinner as="span" animation="border" size={'sm'} role="status" aria-hidden="true" />
        </>
      ) : (
        <Icon size={iconSize} icon={icon} />
      )}

      {!isDisabled && tooltipMessage && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={id}
          toggle={toggle}
          trigger="click hover"
        >
          {tooltipMessage}
        </Tooltip>
      )}
    </Button.Ripple>
  ) : null;
};

DefaultButton.defaultProps = {
  id: 'DefaultButton',
  icon: 'Plus',
  isDisabled: false,
  className: ''
};

export default DefaultButton;
