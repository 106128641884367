import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../types/configurations';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CONFIGURATIONS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((configurationId) => {
        newState[configurationId] = {
          isSelected: false,
          ...entities[configurationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CONFIGURATIONS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CONFIGURATIONS_FETCH_STARTED: {
      return true;
    }
    case types.CONFIGURATIONS_FETCH_COMPLETED:
    case types.CONFIGURATIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CONFIGURATIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONFIGURATIONS_FETCH_STARTED:
    case types.CONFIGURATIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const configurations = combineReducers({
  byId,
  order,
  isFetchingList,
  errorFetchingList
});

export default configurations;

//Own state
export const getOwnState = (state) => state.staticCatalogs.brands.models.configurations;

//Information
export const getConfiguration = (state, configurationId) =>
  getOwnState(state).byId[configurationId];
export const getConfigurationsList = (state) =>
  getOwnState(state).order.map((id) => getConfiguration(state, id));

export const getConfigurationsFromBrand = (state, brandId) =>
  getConfigurationsList(state).filter((configuration) => configuration.brandId === brandId);

//Status of sagas
export const isFetchingListConfigurations = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingListConfigurationsErrors = (state) => getOwnState(state).errorFetchingList;
