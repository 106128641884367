/* -------------------------------------------------------------------------- */
/*                    Componente SlidingPaneAddEditContact                    */
/* -------------------------------------------------------------------------- */
// Este componente permite crear o editar contactos

import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import * as contactActions from 'views/screens/Contacts/actions';
import * as contactSelectors from 'views/screens/Contacts/reducers';
import TextInput from '../../formComponents/TextInput';
import DateInput from 'views/formComponents/DateInput';
import moment from 'moment';
import SwitchInput from 'views/formComponents/SwitchInput';
import * as authSelectors from '../../../redux/reducers/auth';
import * as alerts from '../../../redux/utils/alerts';

interface Props {
  userContact?: boolean;
  operatorContact?: boolean;
  organizationContact?: boolean;
  setContactsIn?: (value) => void;
  contactsIn?: any;
  selectedContact?: any;
  isOpenSlidingPane: any;
  closeSlidingPaneAddEditContact: any;
  setNewContact?: (value) => void;
  defaultJobTitle?: string;
  operatorId?: number;
  organizationId?: number;
  canCreate?: boolean;
  isSelectedOnCreate?: boolean;
  isEditingFromMulti?: boolean;
}

const SlidingPaneAddEditContact = (props: Props) => {
  const store = useSelector((state) => state);

  const {
    isOpenSlidingPane,
    closeSlidingPaneAddEditContact,
    userContact = true,
    operatorContact = false,
    organizationContact = false,
    setContactsIn = undefined,
    contactsIn = [],
    selectedContact,
    setNewContact = undefined,
    defaultJobTitle = '',
    operatorId = authSelectors.getAuthUserOperatorId(store),
    organizationId = authSelectors.getAuthUserOrganizationId(store),
    canCreate = true,
    isSelectedOnCreate = false,
    isEditingFromMulti = false
  } = props;

  const { control, handleSubmit, watch, reset } = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { isNew, initialValues, isEditingContact, isAddingContact, initialValuesRedux } =
    useSelector((state) => ({
      isNew: contactSelectors.getSelectedContact(state) ? false : selectedContact ? false : true,
      initialValues: contactSelectors.getSelectedContact(state) ?? selectedContact,
      initialValuesRedux: contactSelectors.getSelectedContact(state),
      isEditingContact: contactSelectors.isEditingContact(state),
      isAddingContact: contactSelectors.isAddingContact(state)
    }));

  const { reduxContacts, unfilteredContacts } = useSelector((state) => ({
    reduxContacts: contactSelectors
      .getContactsList(state)
      .filter((contact) => contact.contactStatus > 0)
      .filter((contact) =>
        operatorContact === true || initialValues?.contactTypeId === 2
          ? contact.operatorId == operatorId
          : organizationContact === true || initialValues?.contactTypeId === 3
          ? contact.organizationId == organizationId
          : contact.operatorId === null && contact.organizationId === null
      ),
    unfilteredContacts: contactSelectors.getContactsList(state)
  }));

  const formValues = watch();

  const editContact = (values) => {
    if (userContact) {
      if (
        reduxContacts?.filter(
          (contact) =>
            contact.email === values.email && contact.contactId !== initialValues?.contactId
        ).length > 0
      ) {
        alerts.showErrorAlertWarning({
          error: { errorMessage: intl.formatMessage({ id: 'contacts.addContactExistWaring' }) }
        });
        return;
      }
      if (canCreate)
        dispatch(
          contactActions.startEditingContact({
            ...values,
            operatorId: null,
            organizationId: null,
            contactId: initialValues?.contactId
          })
        );
      dispatch(contactActions.deselectAllContacts());
    } else if (
      operatorContact ||
      organizationContact ||
      initialValues?.contactTypeId === 2 ||
      initialValues?.contactTypeId === 3
    ) {
      if (
        reduxContacts.filter(
          (contact) =>
            contact.email === values.email &&
            (isNew ? true : contact.contactId !== initialValues?.contactId)
        ).length > 0 ||
        contactsIn.filter(
          (contact) =>
            contact.email === values.email &&
            (isNew ? true : contact.contactId !== initialValues?.contactId)
        ).length > 0
      ) {
        alerts.showErrorAlertWarning({
          error: { errorMessage: intl.formatMessage({ id: 'contacts.addContactExistWaring' }) }
        });
        return;
      }
      if (canCreate)
        dispatch(
          contactActions.startEditingContact({
            ...values,
            isShared: values.isShared === true ? false : true,
            operatorId: initialValues?.operatorId,
            organizationId: initialValues?.organizationId,
            contactId: initialValues?.contactId
          })
        );
      if (setContactsIn)
        setContactsIn(
          contactsIn.map((contactIn) =>
            contactIn.contactId === initialValues?.contactId
              ? {
                  ...contactIn,
                  contactName: values.contactName,
                  email: values.email,
                  phoneNumber: values.phoneNumber,
                  identification: values.identification,
                  jobTitle: values.jobTitle,
                  isShared: values.isShared ?? false,
                  birthDate: values.birthDate,
                  nacionality: values.nacionality,
                  homeAddress: values.homeAddress
                }
              : contactIn
          )
        );
      dispatch(contactActions.deselectAllContacts());
    }

    closeSlidingPaneAddEditContact();
    reset();
  };

  const createContact = (values) => {
    const contactIdAux = uuidv4();
    if (userContact) {
      // Array unfilteredContacts contains all user contacts
      // This changes is because of changes in database
      if (unfilteredContacts.filter((contact) => contact.email === values.email).length > 0) {
        alerts.showErrorAlertWarning({
          error: { errorMessage: intl.formatMessage({ id: 'contacts.addContactExistWaring' }) }
        });
        return;
      }
      if (canCreate)
        dispatch(
          contactActions.startAddingContact({
            ...values,
            isSelected: isSelectedOnCreate,
            operatorId: null,
            organizationId: null,
            contactId: contactIdAux
          })
        );
    } else if (operatorContact || organizationContact) {
      if (
        reduxContacts.filter(
          (contact) =>
            contact.email === values.email &&
            (isNew ? true : contact.contactId !== initialValues?.contactId)
        ).length > 0 ||
        contactsIn.filter(
          (contact) =>
            contact.email === values.email &&
            (isNew ? true : contact.contactId !== initialValues?.contactId)
        ).length > 0
      ) {
        alerts.showErrorAlertWarning({
          error: { errorMessage: intl.formatMessage({ id: 'contacts.addContactExistWaring' }) }
        });
        return;
      }
      if (canCreate)
        dispatch(
          contactActions.startAddingContact({
            ...values,
            isShared: values.isShared === true ? false : true,
            isSelected: isSelectedOnCreate,
            operatorId: operatorContact ? operatorId : null,
            organizationId: organizationContact ? organizationId : null,
            contactId: contactIdAux
          })
        );
      if (setContactsIn)
        setContactsIn(
          contactsIn.concat([
            { ...values, isShared: values.isShared ?? false, contactId: contactIdAux }
          ])
        );
    }
    if (setNewContact)
      setNewContact({ ...values, isShared: values.isShared ?? false, contactId: contactIdAux });
    closeSlidingPaneAddEditContact();
    reset();
  };

  return isEditingContact ||
    isAddingContact ||
    (isEditingFromMulti === true && (initialValues === undefined || initialValues === null)) ? (
    <></>
  ) : (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: isNew ? 'contacts.create' : 'contacts.edit' })}
      subtitle={isNew ? undefined : initialValues?.contactName}
      width={'35rem'}
      onRequestClose={() => {
        dispatch(contactActions.deselectAllContacts());
        closeSlidingPaneAddEditContact();
        reset();
      }}
    >
      <>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              name="contactName"
              defaultValue={initialValues?.contactName}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'contactNameInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.contactName'
                  })}
                  required
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="email"
              defaultValue={initialValues?.email}
              rules={{ required: true, pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/ }}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'emailInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.contacts'
                  })}
                  type={'email'}
                  required
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue={initialValues?.phoneNumber}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'phoneNumberInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.phoneNumber'
                  })}
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="birthDate"
              defaultValue={initialValues?.birthDate}
              render={({ field, fieldState }) => (
                <DateInput
                  field={field}
                  fieldstate={fieldState}
                  maxDate={moment().endOf('day').format('YYYY-MM-DD')}
                  label={intl.formatMessage({
                    id: 'contacts.birthDate'
                  })}
                  showTime={false}
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="jobTitle"
              defaultValue={isNew ? defaultJobTitle : initialValues?.jobTitle}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'jobTitleInput'}
                  label={intl.formatMessage({
                    id: 'contacts.jobTitle'
                  })}
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="identification"
              defaultValue={initialValues?.identification}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'identificationInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.identificationNumber'
                  })}
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="nacionality"
              defaultValue={initialValues?.nacionality}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'nacionalityInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.nacionality'
                  })}
                />
              )}
            />
          </Col>
          <Col sm="12">
            <Controller
              control={control}
              name="homeAddress"
              defaultValue={initialValues?.homeAddress}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'homeAddressInput'}
                  autocompleteinput={'cc-csc'}
                  label={intl.formatMessage({
                    id: 'contacts.homeAddress'
                  })}
                />
              )}
            />
          </Col>
          {(organizationContact ||
            operatorContact ||
            initialValues?.contactTypeId === 2 ||
            initialValues?.contactTypeId === 3) && (
            <Col sm="12">
              <Controller
                control={control}
                name={'isShared'}
                defaultValue={
                  selectedContact === null || selectedContact === undefined
                    ? !initialValues?.isShared
                    : initialValues?.isShared
                }
                render={({ field }) => (
                  <SwitchInput
                    field={field}
                    label={intl.formatMessage({ id: 'contacts.private' })}
                  />
                )}
              />
            </Col>
          )}
        </Row>
        <br />
        <br />
        <Row className={'footer-slide-pane'}>
          <Col sm="12">
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              id = "saveContactsBsutton"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit((values) =>
                isNew ? createContact(values) : editContact(values)
              )}
            >
              <FormattedMessage
                id={`${
                  (operatorContact || organizationContact) && isNew ? 'common.add' : 'common.save'
                }`}
              />
            </Button.Ripple>
            <Button.Ripple
              className="mt-50 mb-50"
              color="light"
              onClick={() => {
                dispatch(contactActions.deselectAllContacts());
                closeSlidingPaneAddEditContact();
                reset();
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          </Col>
        </Row>
      </>
    </SlidingPane>
  );
};

SlidingPaneAddEditContact.defaultProps = {};

export default SlidingPaneAddEditContact;
