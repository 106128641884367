import paths from './paths';
import permissions from './permissions';
import Icon from 'utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'telemetryUnits',
    title: 'units.units',
    icon: <Icon size={20} icon="Unidades" />,
    iconName: 'Unidades',
    permissions: [permissions.telemtryUnits],
    navLink: paths.telemetryUnits,
    screenId: screens.TELEMETRY_UNITS
  },
  {
    id: 'temperatureTelemetry',
    title: 'telemetry.temperature',
    iconName: 'Thermometer',
    permissions: [permissions.temperatureCatalog],
    navLink: paths.telemetryTemperature,
    screenId: screens.TEMPERATURE_TELEMETRY
  },
  {
    id: 'fuelTelemetry',
    title: 'telemetry.fuel',
    iconName: 'Fuel',
    permissions: [permissions.fuelCatalog, permissions.fuelReport],
    children: [
      {
        id: 'fuelTelemetryMonitor',
        title: 'telemetry.fuelMonitor',
        iconName: 'Fuel',
        permissions: [permissions.fuelCatalog],
        navLink: paths.telemetryFuelMonitor,
        screenId: screens.FUEL_TELEMETRY_MONITOR
      },
      {
        id: 'fuelTelemetryReport',
        title: 'telemetry.fuelReport',
        iconName: 'Fuel',
        permissions: [permissions.fuelReport],
        children: [
          {
            id: 'fuelTelemetryReport',
            title: 'assets.assets',
            iconName: 'Fuel',
            permissions: [permissions.fuelReport],
            navLink: paths.telemetryFuelReport,
            screenId: screens.FUEL_TELEMETRY_REPORT
          },
          {
            id: 'fuelTelemetryReportByVehicles',
            title: 'vehicles.vehicles',
            iconName: 'Fuel',
            permissions: [permissions.fuelReport],
            navLink: paths.telemetryFuelReportByVehicles,
            screenId: screens.FUEL_TELEMETRY_REPORT_BY_VEHICLES
          }
        ]
      },
    ]
  },
  {
    id: 'telemetryOBD2',
    title: 'telemetry.obd2',
    icon: <Icon size={20} icon="Cpu" />,
    iconName: 'Cpu',
    permissions: [permissions.obd2Monitor],
    navLink: paths.telemetryOBD2,
    screenId: screens.TELEMETRY_OBD2
  }
];
