import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SIMS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((simId) => {
        newState[simId] = {
          isSelected: false,
          ...state[simId],
          ...entities[simId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SIMS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((simId) => {
        newState[simId] = {
          isSelected: false,
          ...state[simId],
          ...entities[simId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SIM_ADD_COMPLETED: {
      const { oldId, sim } = action.payload;
      const newState = omit(state, oldId);
      newState[sim.simCardId] = {
        ...state[oldId],
        ...sim,
        isConfirmed: true
      };
      return newState;
    }

    case types.SIM_EDIT_COMPLETED: {
      const sim = action.payload;
      return {
        ...state,
        [sim.simId]: {
          ...omit(state[sim.simCardId], ['oldSim']),
          ...omit(sim, ['oldSim']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.SIM_ADD_STARTED: {
      const sim = action.payload;
      const newState = { ...state };
      newState[sim.simId] = {
        isSelected: false,
        ...sim,
        isConfirmed: false
      };
      return newState;
    }
    case types.SIM_EDIT_STARTED: {
      const sim = action.payload;
      return {
        ...state,
        [sim.simId]: {
          ...state[sim.simId],
          oldSim: state[sim.simId],
          ...sim,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.SIM_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.SIM_EDIT_FAILED: {
      const { oldSim } = action.payload;
      return {
        ...state,
        [oldSim.simId]: {
          ...omit(state[oldSim.simId], ['oldSim']),
          ...oldSim,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SIM_REMOVE_COMPLETED: {
      const { simId } = action.payload;
      return omit(state, simId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.SIM_SELECTED: {
      const simId = action.payload;
      const newState = {
        ...state,
        [simId]: {
          ...state[simId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.SIM_DESELECTED: {
      const simId = action.payload;
      return {
        ...state,
        [simId]: {
          ...state[simId],
          isSelected: false
        }
      };
    }

    case types.SIMS_ALL_SELECTED: {
      const simIds = action.payload;
      const newState = { ...state };
      if (simIds.length == 0) {
        forEach(state, (sim: any, simId) => {
          newState[simId] = {
            ...sim,
            isSelected: true
          };
        });
      } else {
        simIds.forEach((simId) => {
          newState[simId] = {
            ...state[simId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.SIMS_ALL_DESELECTED: {
      const simIds = action.payload;
      const newState = { ...state };
      if (simIds.length == 0) {
        forEach(state, (sim: any, simId) => {
          newState[simId] = {
            ...sim,
            isSelected: false
          };
        });
      } else {
        simIds.forEach((simId) => {
          newState[simId] = {
            ...state[simId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SIMS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.SIMS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.SIM_ADD_COMPLETED: {
      const { oldId, sim } = action.payload;
      return state.map((simId) => (simId === oldId ? sim.simCardId : simId));
    }

    case types.SIM_REMOVE_COMPLETED: {
      const { simId } = action.payload;
      return state.filter((simIdState) => simIdState !== simId);
    }

    //CASE ADD STARTED
    case types.SIM_ADD_STARTED: {
      const sim = action.payload;
      return [...state, sim.simId];
    }

    //CASE ADD FAILED
    case types.SIM_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((simIdState) => simIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SIMS_FETCH_STARTED: {
      return true;
    }
    case types.SIMS_FETCH_COMPLETED:
    case types.SIMS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SIMS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.SIMS_PARTIAL_FETCH_COMPLETED:
    case types.SIMS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SIM_ADD_STARTED: {
      return true;
    }
    case types.SIM_ADD_COMPLETED:
    case types.SIM_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SIM_EDIT_STARTED: {
      return true;
    }
    case types.SIM_EDIT_COMPLETED:
    case types.SIM_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SIM_REMOVE_STARTED: {
      return true;
    }
    case types.SIM_REMOVE_COMPLETED:
    case types.SIM_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SIMS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SIMS_FETCH_STARTED:
    case types.SIMS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SIMS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SIMS_PARTIAL_FETCH_STARTED:
    case types.SIMS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SIM_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SIM_ADD_STARTED:
    case types.SIM_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SIM_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SIM_EDIT_STARTED:
    case types.SIM_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SIM_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SIM_REMOVE_STARTED:
    case types.SIM_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const sims = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default sims;

//Own state
export const getOwnState = (state) => state.sims;

//Information
export const getSim = (state, simId) => getOwnState(state).byId[simId];
export const getSimsList = (state) =>
  getOwnState(state)
    .order.map((id) => getSim(state, id))
    .filter((sim) => sim.simCardStatus > 0);
export const getSimsListAll = (state) => getOwnState(state).order.map((id) => getSim(state, id));
export const getSimsListByUnit = (state, unitId) => {
  if (unitId || unitId === 0) {
    return getSimsList(state).filter((sim) => sim.unitId === unitId);
  }
  return getSimsList(state);
};
export const getSimsListByCountry = (state, countryId, providerId, unitId) => {
  if (countryId || countryId === 0) {
    if (providerId || providerId === 0) {
      return getSimsListByUnit(state, unitId).filter(
        (sim) => sim.countryId === countryId && sim.mobileOperatorId === providerId
      );
    }
    return getSimsListByUnit(state, unitId).filter((sim) => sim.countryId === countryId);
  }
  return getSimsListByUnit(state, unitId);
};

export const getSimsListByMembership = (state, membership) =>
  getSimsList(state).filter(
    (sim) =>
      membership &&
      (!membership.operatorId || membership.operatorId === sim.operatorId) &&
      (!membership.organizationId || membership.organizationId === sim.organizationId) &&
      (!membership.divisionId || membership.divisionId === sim.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === sim.subdivisionId)
  );
export const getSelectedSims = (state) => {
  const selectedSims = getSimsList(state).filter((sim) => sim.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedSims.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedSims.length > 0) return selectedSims;
};

export const getSelectedSim = (state) => {
  const selectedSims = getSimsList(state).filter((sim) => sim.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedSims.length === 1) return selectedSims[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListSims = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListSims = (state) => getOwnState(state).isFetchingList;
export const isAddingSim = (state) => getOwnState(state).isAdding;
export const isEditingSim = (state) => getOwnState(state).isEditing;
export const isRemovingSim = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListSimsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListSimsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingSimErrors = (state) => getOwnState(state).errorAdding;
export const getEditingSimErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingSimErrors = (state) => getOwnState(state).errorRemoving;
