export const MODULES_FETCH_STARTED = 'MODULES_FETCH_STARTED';
export const MODULES_FETCH_COMPLETED = 'MODULES_FETCH_COMPLETED';
export const MODULES_FETCH_FAILED = 'MODULES_FETCH_FAILED';

export const MODULES_PARTIAL_FETCH_STARTED = 'MODULES_PARTIAL_FETCH_STARTED';
export const MODULES_PARTIAL_FETCH_COMPLETED = 'MODULES_PARTIAL_FETCH_COMPLETED';
export const MODULES_PARTIAL_FETCH_FAILED = 'MODULES_PARTIAL_FETCH_FAILED';

export const MODULE_FETCH_STARTED = 'MODULE_FETCH_STARTED';
export const MODULE_FETCH_COMPLETED = 'MODULE_FETCH_COMPLETED';
export const MODULE_FETCH_FAILED = 'MODULE_FETCH_FAILED';

export const MODULE_ADD_STARTED = 'MODULE_ADD_STARTED';
export const MODULE_ADD_COMPLETED = 'MODULE_ADD_COMPLETED';
export const MODULE_ADD_FAILED = 'MODULE_ADD_FAILED';

export const MODULE_EDIT_STARTED = 'MODULE_EDIT_STARTED';
export const MODULE_EDIT_COMPLETED = 'MODULE_EDIT_COMPLETED';
export const MODULE_EDIT_FAILED = 'MODULE_EDIT_FAILED';

export const MODULE_REMOVE_STARTED = 'MODULE_REMOVE_STARTED';
export const MODULE_REMOVE_COMPLETED = 'MODULE_REMOVE_COMPLETED';
export const MODULE_REMOVE_FAILED = 'MODULE_REMOVE_FAILED';

export const MODULE_SELECTED = 'MODULE_SELECTED';
export const MODULES_ALL_SELECTED = 'MODULES_ALL_SELECTED';
export const MODULE_DESELECTED = 'MODULE_DESELECTED';
export const MODULES_ALL_DESELECTED = 'MODULES_ALL_DESELECTED';

export const MODULE_DIRTY = 'MODULE_DIRTY';
export const MODULE_UNDIRTY = 'MODULE_UNDIRTY';

export interface Module {
  isSelected: boolean;
  deviceId: number;
  identifier: string;
  devicePublicId: string;
  serialNumber: null | string;
  imei: null | string;
  propertyTypeId: number;
  brandId: number;
  brandName: string;
  deviceModelId: number;
  deviceModelName: string;
  deviceConfigurationId: number;
  deviceConfigurationName: string;
  deviceStatus: number;
  locationStatus: number;
  operatorPublicId: string;
  operatorId: number;
  operatorName: string;
  organizationId: number;
  divisionId: number | null;
  subdivisionId: number | null;
  unitId: number;
  unitPublicId: string;
  unitName: string;
  devicePassword: null | string;
  warrantyProviderTypeId: number;
  warrantyProviderId: number | null;
  warrantyProviderName: string | null;
  warrantyProviderOperatorId: number | null;
  warrantyProviderOrganizationId: number | null;
  warrantyProviderDivisionId: number | null;
  isConfirmed: boolean;
  locationStatusText?: string;
  locationStatusDescription?: string;
  deviceStatusText?: string;
  deviceStatusDescription?: string;
  propertyTypeText?: string;
  propertyTypeDescription?: string;
  warrantyProviderText?: string;
  warrantyProviderDescription?: string;
}

export interface PartialModule extends Partial<Module> {}
