import { User } from 'react-feather';
export default [
  {
    id: 'accountSettings',
    title: 'profile.profile',
    icon: <User size={20} />,
    permissions: [],
    iconName: 'Users',
    navLink: '/account-settings'
  }
];
