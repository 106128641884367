import * as types from '../types';

export const startFetchingSchedules = (filters = {}) => ({
  type: types.SCHEDULES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingSchedules = (entities, order) => ({
  type: types.SCHEDULES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingSchedules = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SCHEDULES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingSchedules = (entityTypeId) => ({
  type: types.SCHEDULES_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});
export const completePartialFetchingSchedules = (entities, order) => ({
  type: types.SCHEDULES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingSchedules = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SCHEDULES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingSchedule = (schedule) => ({
  type: types.SCHEDULE_ADD_STARTED,
  payload: schedule
});
export const completeAddingSchedule = (oldId, schedule) => ({
  type: types.SCHEDULE_ADD_COMPLETED,
  payload: { oldId, schedule }
});
export const failAddingSchedule = (oldId, error) => ({
  type: types.SCHEDULE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startAddingShift = (schedule) => ({
  type: types.SCHEDULE_SHIFT_ADD_STARTED,
  payload: schedule
});
export const completeAddingShift = (oldId, schedule) => ({
  type: types.SCHEDULE_SHIFT_ADD_COMPLETED,
  payload: { oldId, schedule }
});
export const failAddingShift = (oldId, error) => ({
  type: types.SCHEDULE_SHIFT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingSchedule = (schedule) => ({
  type: types.SCHEDULE_EDIT_STARTED,
  payload: schedule
});
export const completeEditingSchedule = (schedule) => ({
  type: types.SCHEDULE_EDIT_COMPLETED,
  payload: schedule
});
export const failEditingSchedule = (oldSchedule, error) => ({
  type: types.SCHEDULE_EDIT_FAILED,
  payload: {
    oldSchedule,
    error
  }
});
export const startEditingShift = (schedule) => ({
  type: types.SCHEDULE_SHIFT_EDIT_STARTED,
  payload: schedule
});
export const completeEditingShift = (schedule) => ({
  type: types.SCHEDULE_SHIFT_EDIT_COMPLETED,
  payload: schedule
});
export const failEditingShift = (oldSchedule, error) => ({
  type: types.SCHEDULE_SHIFT_EDIT_FAILED,
  payload: {
    oldSchedule,
    error
  }
});

export const startRemovingSchedule = (scheduleId) => ({
  type: types.SCHEDULE_REMOVE_STARTED,
  payload: {
    scheduleId
  }
});
export const completeRemovingSchedule = (scheduleId) => ({
  type: types.SCHEDULE_REMOVE_COMPLETED,
  payload: {
    scheduleId
  }
});
export const failRemovingSchedule = (scheduleId, error) => ({
  type: types.SCHEDULE_REMOVE_FAILED,
  payload: {
    scheduleId,
    error
  }
});

export const selectSchedule = (scheduleId) => ({
  type: types.SCHEDULE_SELECTED,
  payload: scheduleId
});

export const selectAllSchedules = (
  scheduleIds = [] //List of ScheduleIds
) => ({
  type: types.SCHEDULES_ALL_SELECTED,
  payload: scheduleIds
});

export const deselectSchedule = (scheduleId) => ({
  type: types.SCHEDULE_DESELECTED,
  payload: scheduleId
});

export const deselectAllSchedules = (
  scheduleIds = [] //List of ScheduleIds
) => ({
  type: types.SCHEDULES_ALL_DESELECTED,
  payload: scheduleIds
});
