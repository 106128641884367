import { call, put, select, takeEvery } from 'redux-saga/effects';

import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import * as authSelectors from '../../../../redux/reducers/auth';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as entitySelectors from '../../Entities/reducers';
import * as actions from '../actions';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import {
  deleteSensorService,
  fuelCalibrationFinalizeService,
  fuelCalibrationStartService,
  getSensorsListService,
  saveSensorService
} from '../services/sensors';
import * as types from '../types';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchSensorsList(action) {
  getSensorsListService(
    {},
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sensors },
          result //order
        } = normalize(resultData, schemas.sensors); //normalize data to byId and order
        store.dispatch(actions.completeFetchingSensors(sensors, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingSensors(error));
      }
    }
  );
}

export function* watchFetchSensorsList() {
  yield takeEvery(types.SENSORS_FETCH_STARTED, fetchSensorsList);
}

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* partialFetchSensorsList(action) {
  const entityTypeId = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  getSensorsListService(
    { userFetchDate: date },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sensors },
          result //order
        } = normalize(resultData, schemas.sensors); //normalize data to byId and order
        store.dispatch(actions.completePartialFetchingSensors(sensors, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingSensors(error));
      }
    }
  );
}

export function* watchPartialFetchSensorsList() {
  yield takeEvery(types.SENSORS_PARTIAL_FETCH_STARTED, partialFetchSensorsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addSensor(action) {
  const sensor = action.payload;
  saveSensorService(
    { ...sensor, sensorId: null },
    {
      successAction: (response) => {
        const addedSensor = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingSensor(sensor.sensorId, addedSensor));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingSensor(sensor.sensorId, error));
      }
    }
  );
}

export function* watchAddSensorsStarted() {
  yield takeEvery(types.SENSOR_ADD_STARTED, addSensor);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editSensor(action) {
  const sensor = action.payload;
  const oldSensor = yield select((state) => selectors.getSensor(state, sensor.sensorId));
  saveSensorService(
    { ...sensor },
    {
      successAction: (response) => {
        const editedSensor = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingSensor(editedSensor));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingSensor(oldSensor, error));
      }
    }
  );
}

export function* watchEditSensorsStarted() {
  yield takeEvery(types.SENSOR_EDIT_STARTED, editSensor);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeSensor(action) {
  const { sensorId } = action.payload;
  const sensor = yield select((state) => selectors.getSensor(state, sensorId));
  deleteSensorService(
    { ...sensor },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingSensor(sensorId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingSensor(sensorId, error));
      }
    }
  );
}

export function* watchRemoveSensorStarted() {
  yield takeEvery(types.SENSOR_REMOVE_STARTED, removeSensor);
}

/* -------------------------------------------------------------------------- */
/*                              IDENTIFIER CHECK                              */
/* -------------------------------------------------------------------------- */
function* checkRegistrationCode(action) {
  const { sensorId, identifier } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/sensor/validateIdentifier`, {
        method: 'POST',
        body: JSON.stringify({ identifier, sensorId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const validCode = jsonResult.data.UniqueIdentifierStatus === 1;
        yield put(actions.completeCheckingCode(validCode));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchSensorIdentifierCheckStarted() {
  yield takeEvery(types.IDENTIFIER_CHECK_STARTED, checkRegistrationCode);
}

/* -------------------------------------------------------------------------- */
/*                             START FUEL CALIBRATION                         */
/* -------------------------------------------------------------------------- */
function* sensorCalibrationStarted(action) {
  const { sensorId } = action.payload;
  const sensor = yield select((state) => selectors.getSensor(state, sensorId));
  fuelCalibrationStartService(
    { ...sensor },
    {
      successAction: (response) => {
        store.dispatch(actions.sensorCalibrationStartCompleted(sensorId));
      },
      errorAction: (error) => {
        store.dispatch(actions.sensorCalibrationStartFail(sensorId, error));
      }
    }
  );
}

export function* watchSensorCalibrationStartStarted() {
  yield takeEvery(types.START_SENSOR_CALIBRATION_STARTED, sensorCalibrationStarted);
}

/* -------------------------------------------------------------------------- */
/*                             FINALIZE FUEL CALIBRATION                      */
/* -------------------------------------------------------------------------- */
function* sensorCalibrationFinalized(action) {
  const { sensorId } = action.payload;
  const sensor = yield select((state) => selectors.getSensor(state, sensorId));
  fuelCalibrationFinalizeService(
    { ...sensor },
    {
      successAction: (response) => {
        store.dispatch(actions.sensorCalibrationFinalizeCompleted(sensorId));
      },
      errorAction: (error) => {
        store.dispatch(actions.sensorCalibrationFinalizeFail(sensorId, error));
      }
    }
  );
}

export function* watchSensorCalibrationFinalizeStarted() {
  yield takeEvery(types.FINALIZE_SENSOR_CALIBRATION_STARTED, sensorCalibrationFinalized);
}
