import { HttpWebtrack } from 'utility/http';
import { SaveCategoryDTO, DeleteCategoryDTO } from '../types';
import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/catalogs';

export const saveCategory = (request: SaveCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.categoryName}`,
    object: 'vehicleMaintenance.category',
    action: `${request.categoryId ? 'Edit' : 'Create'}`,
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/categories`, request);
};

export const deleteCategory = (request: DeleteCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.categoryName}`,
    object: 'vehicleMaintenance.category',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.get(`${baseUrl}/deleteCategory/${request.categoryId}`);
};

export const getCategoryList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/categories`);
};
