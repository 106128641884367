import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';
import { isNullOrUndefined } from 'utility/Utils';

export interface MaintenancePlansFormatted
  extends Omit<
    types.MaintenancePlans,
    'maintenanceRangesByOdometer' | 'maintenanceRangesByHourMeter'
  > {
  maintenanceRangesByOdometer: {
    odometer: number;
    title: string;
    description: string;
    maintenanceRangeId: number;
    maintenanceLineId: number;
  }[];
  maintenanceRangesByHourMeter: {
    odometer: number;
    title: string;
    description: string;
    maintenanceRangeId: number;
    maintenanceLineId: number;
  }[];
}

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.MAINTENANCE_LINES_FETCH_COMPLETED:
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((maintenanceLineId) => {
        newState[maintenanceLineId] = {
          isSelected: false,
          ...state[maintenanceLineId],
          ...entities[maintenanceLineId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.MAINTENANCE_LINE_ADD_COMPLETED: {
      const { oldId, maintenanceLine } = action.payload;
      const newState = omit(state, oldId);
      newState[maintenanceLine.maintenanceLineId] = {
        ...state[oldId],
        ...maintenanceLine,
        isConfirmed: true
      };
      return newState;
    }

    case types.MAINTENANCE_LINE_EDIT_COMPLETED: {
      const maintenanceLine = action.payload;
      return {
        ...state,
        [maintenanceLine.maintenanceLineId]: {
          ...omit(state[maintenanceLine.maintenanceLineId], ['oldMaintenanceLine']),
          ...omit(maintenanceLine, ['oldMaintenanceLine']),
          isConfirmed: true
        }
      };
    }

    case types.MAINTENANCE_LINE_COPY_COMPLETED: {
      const maintenanceLine = action.payload;
      const newState = { ...state };
      newState[maintenanceLine.maintenanceLineId] = {
        isSelected: false,
        ...maintenanceLine,
        isConfirmed: true
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.MAINTENANCE_LINE_ADD_STARTED: {
      const maintenanceLine = action.payload;
      const newState = { ...state };
      newState[maintenanceLine.maintenanceLineId] = {
        isSelected: false,
        ...maintenanceLine,
        isConfirmed: false
      };
      return newState;
    }
    case types.MAINTENANCE_LINE_EDIT_STARTED: {
      const maintenanceLine = action.payload;
      return {
        ...state,
        [maintenanceLine.maintenanceLineId]: {
          ...state[maintenanceLine.maintenanceLineId],
          oldMaintenanceLine: state[maintenanceLine.maintenanceLineId],
          ...maintenanceLine,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.MAINTENANCE_LINE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.MAINTENANCE_LINE_EDIT_FAILED: {
      const { oldMaintenanceLine } = action.payload;
      return {
        ...state,
        [oldMaintenanceLine.maintenanceLineId]: {
          ...omit(state[oldMaintenanceLine.maintenanceLineId], ['oldMaintenanceLine']),
          ...oldMaintenanceLine,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.MAINTENANCE_LINE_REMOVE_COMPLETED: {
      const { maintenanceLineId } = action.payload;
      return omit(state, maintenanceLineId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.MAINTENANCE_LINE_SELECTED: {
      const maintenanceLineId = action.payload;
      const newState = {
        ...state,
        [maintenanceLineId]: {
          ...state[maintenanceLineId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.MAINTENANCE_LINE_DESELECTED: {
      const maintenanceLineId = action.payload;
      return {
        ...state,
        [maintenanceLineId]: {
          ...state[maintenanceLineId],
          isSelected: false
        }
      };
    }

    case types.MAINTENANCE_LINES_ALL_SELECTED: {
      const maintenanceLineIds = action.payload;
      const newState = { ...state };
      if (maintenanceLineIds.length == 0) {
        forEach(state, (maintenanceLine: any, maintenanceLineId) => {
          newState[maintenanceLineId] = {
            ...maintenanceLine,
            isSelected: true
          };
        });
      } else {
        maintenanceLineIds.forEach((maintenanceLineId) => {
          newState[maintenanceLineId] = {
            ...state[maintenanceLineId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.MAINTENANCE_LINES_ALL_DESELECTED: {
      const maintenanceLineIds = action.payload;
      const newState = { ...state };
      if (maintenanceLineIds.length == 0) {
        forEach(state, (maintenanceLine: any, maintenanceLineId) => {
          newState[maintenanceLineId] = {
            ...maintenanceLine,
            isSelected: false
          };
        });
      } else {
        maintenanceLineIds.forEach((maintenanceLineId) => {
          newState[maintenanceLineId] = {
            ...state[maintenanceLineId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.MAINTENANCE_LINES_FETCH_COMPLETED:
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.MAINTENANCE_LINE_ADD_COMPLETED: {
      const { oldId, maintenanceLine } = action.payload;
      return state.map((maintenanceLineId) =>
        maintenanceLineId === oldId ? maintenanceLine.maintenanceLineId : maintenanceLineId
      );
    }

    case types.MAINTENANCE_LINE_REMOVE_COMPLETED: {
      const { maintenanceLineId } = action.payload;
      return state.filter((maintenanceLineIdState) => maintenanceLineIdState !== maintenanceLineId);
    }

    //CASE ADD STARTED
    case types.MAINTENANCE_LINE_COPY_COMPLETED:
    case types.MAINTENANCE_LINE_ADD_STARTED: {
      const maintenanceLine = action.payload;
      return [...state, maintenanceLine.maintenanceLineId];
    }

    //CASE ADD FAILED
    case types.MAINTENANCE_LINE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((maintenanceLineIdState) => maintenanceLineIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINES_FETCH_STARTED: {
      return true;
    }
    case types.MAINTENANCE_LINES_FETCH_COMPLETED:
    case types.MAINTENANCE_LINES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED:
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_ADD_STARTED: {
      return true;
    }
    case types.MAINTENANCE_LINE_ADD_COMPLETED:
    case types.MAINTENANCE_LINE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_EDIT_STARTED: {
      return true;
    }
    case types.MAINTENANCE_LINE_EDIT_COMPLETED:
    case types.MAINTENANCE_LINE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_REMOVE_STARTED: {
      return true;
    }
    case types.MAINTENANCE_LINE_REMOVE_COMPLETED:
    case types.MAINTENANCE_LINE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MAINTENANCE_LINES_FETCH_STARTED:
    case types.MAINTENANCE_LINES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_STARTED:
    case types.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MAINTENANCE_LINE_ADD_STARTED:
    case types.MAINTENANCE_LINE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MAINTENANCE_LINE_EDIT_STARTED:
    case types.MAINTENANCE_LINE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.MAINTENANCE_LINE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MAINTENANCE_LINE_REMOVE_STARTED:
    case types.MAINTENANCE_LINE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const maintenanceLines = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default maintenanceLines;

//Own state
export const getOwnState = (state) => state.maintenanceLines;

//Information
export const getMaintenanceLine = (
  state,
  maintenanceLineId
): MaintenancePlansFormatted | undefined => {
  const maintenanceLine = getOwnState(state).byId[maintenanceLineId];
  if (maintenanceLine) return maintenanceLine;
  else return undefined;
};
export const getMaintenanceLinesList = (state): MaintenancePlansFormatted[] =>
  getOwnState(state)
    .order.map((id) => getMaintenanceLine(state, id))
    .filter((maintenanceLine) => maintenanceLine.maintenanceLineStatus > 0);

export const getMaintenanceLinesListByMembership = (
  state: any,
  membership: DefaultFilters | undefined,
  typeId: types.MaintenanceNoticeTypes = types.MaintenanceNoticeTypes.MaintenanceLinesByOdometer
) => {
  //Se filtra por membership
  return getMaintenanceLinesList(state).filter(
    (maintenanceLine) =>
      membership &&
      (!membership.operatorId || membership.operatorId === maintenanceLine.operatorId) &&
      (!membership.organizationId ||
        membership.organizationId === maintenanceLine.organizationId) &&
      (!membership.divisionId || membership.divisionId === maintenanceLine.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === maintenanceLine.subdivisionId) &&
      (isNullOrUndefined(maintenanceLine?.maintenanceNoticeTypeId)
        ? true
        : maintenanceLine.maintenanceNoticeTypeId === typeId)
  );
};

export const getMaintenanceLinesListBySelectedDivision = (state, membership) => {
  //Se filtra por divisionId
  if (membership.divisionId) {
    return getMaintenanceLinesList(state).filter(
      (maintenanceLine) => maintenanceLine.divisionId === membership.divisionId
    );
  }
  //Si no se ha seleccionado una división se devuelve una lista vacia
  else return [];
};

export const getSelectedMaintenanceLines = (state) => {
  const selectedMaintenanceLines = getMaintenanceLinesList(state).filter(
    (maintenanceLine) => maintenanceLine.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedMaintenanceLines.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedMaintenanceLines.length > 0) return selectedMaintenanceLines;
};

export const getSelectedMaintenanceLine = (state) => {
  const selectedMaintenanceLines = getMaintenanceLinesList(state).filter(
    (maintenanceLine) => maintenanceLine.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedMaintenanceLines.length === 1) return selectedMaintenanceLines[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListMaintenanceLines = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListMaintenanceLines = (state) => getOwnState(state).isFetchingList;
export const isAddingMaintenanceLine = (state) => getOwnState(state).isAdding;
export const isEditingMaintenanceLine = (state) => getOwnState(state).isEditing;
export const isRemovingMaintenanceLine = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListMaintenanceLinesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListMaintenanceLinesErrors = (state) =>
  getOwnState(state).errorFetchingList;
export const getAddingMaintenanceLineErrors = (state) => getOwnState(state).errorAdding;
export const getEditingMaintenanceLineErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingMaintenanceLineErrors = (state) => getOwnState(state).errorRemoving;
