import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'workplans',
    title: 'workplans.workplan',
    permissions: [
      permissions.viewWorkplanCatalog,
      permissions.viewWorkplanMonitor,
      permissions.viewWorkplanReport,
      permissions.assignWorkplan
    ],
    iconName: 'Workplan',
    children: [
      {
        id: 'workplansListScreen',
        title: 'sidebar.catalog',
        permissions: [permissions.viewWorkplanCatalog],
        iconName: 'WorkplanCatalog',
        navLink: paths.workplanList,
        screenId: screens.WORK_PLAN_CATALOG
      },
      {
        id: 'workplansMonitorScreen',
        title: 'sidebar.monitor',
        permissions: [permissions.viewWorkplanMonitor],
        iconName: 'WorkplanMonitor',
        navLink: paths.workplanMonitor,
        screenId: screens.WORK_PLAN_MONITOR
      },
      {
        id: 'workplansReportScreen',
        title: 'sidebar.report',
        permissions: [permissions.viewWorkplanReport],
        iconName: 'WorkplanReport',
        navLink: paths.workplanReport,
        screenId: screens.WORK_PLAN_REPORT
      },
      {
        id: 'scheduledWorkplanCatalogScreen',
        title: 'workplans.scheduledPlans',
        permissions: [permissions.scheduledWorkplanList],
        iconName: 'WorkplanReport',
        navLink: paths.scheduledWorkplan,
        screenId: screens.SCHEDULED_WORKPLANS
      },
      {
        id: 'workplansAssignUnitScreen',
        title: 'sidebar.assignWorkplan',
        permissions: [permissions.viewWorkplanModule, permissions.assignWorkplan],
        iconName: 'PlusSquare',
        navLink: paths.workplanAssignUnit,
        screenId: screens.ASSIGN_WORK_PLAN
      }
      /* {
        id: 'WorkplanImport',
        title: 'common.import',
        permissions: [],
        iconName: 'UploadCloud',
        navLink: paths.workplanImport,
        screenId: screens.WORKPLAN_IMPORT
      } */
    ]
  }
];
