/* -------------------------------------------------------------------------- */
/*                          LocationClassesDropdown                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un LocationClassesDropdown

import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as locationClassesActions from '../../../redux/actions/static-catalogs/location-classes';
import * as locationClassesSelectors from '../../../redux/reducers/static-catalogs/location-classes';
import DropdownInput from '../DropdownInput';
import Icon from '../../../utility/icomoon';

const LocationClassesDropdown = (props) => {
  const { forceinitialvalue = 0, field, additionalonchangefunction = (value) => null } = props;
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const intl = useIntl();
  const locationClasses = locationClassesSelectors.getLocationClassesList(store);

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  useEffect(() => {
    if (field.value == null || field.value == undefined || field.value.length == 0) {
      field.onChange(forceinitialvalue);
    }
  }, [field.value]);

  //Fetch locationClasses if they have not been fetched yet
  useEffect(() => {
    if (!locationClassesSelectors.haveLocationClassesBeenFetched(store)) {
      dispatch(locationClassesActions.startFetchingLocationClasses());
    }
  }, []);

  //Update reactselect values of  locationClasses when fetched is finished
  useEffect(() => {
    if (!locationClassesSelectors.isFetchingListLocationClasses(store)) {
      resetDropdown();
    }
  }, [locationClassesSelectors.isFetchingListLocationClasses(store)]);

  useEffect(() => {
    if (forceinitialvalue) {
      onChangeFinal(forceinitialvalue);
    }
  }, [locationClassesSelectors.isFetchingListLocationClasses(store), forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  return (
    <DropdownInput
      {...props}
      name="locationClassId"
      id={'locationClassId'}
      key={resetValue}
      isLoading={locationClassesSelectors.isFetchingListLocationClasses(store)}
      label={<FormattedMessage id="locations.locationClass" />}
      options={locationClasses.map((lc) => {
        return {
          ...lc,
          id: lc.locationClassId,
          value: lc.locationClassId,
          icon: (
            <Icon
              icon={lc.locationClassIconName}
              color={lc.locationClassDefaultColor ?? undefined}
            />
          ),
          label: <FormattedMessage id={`locations.locationClass.${lc.locationClassId}`} />,
          label_intl: intl.formatMessage({ id: 'locations.locationClass.' + lc.locationClassId })
        };
      })}
    />
  );
};

export default injectIntl(LocationClassesDropdown);
