const permissions = {
  addGeofence: 'GF002',
  editGeofence: 'GF004',
  removeGeofence: 'GF003',
  listGeofencesOnMap: 'GF001',
  listGeofences: 'GF005',
  listAsignedGeofences: 'GF006',
  asignGeofenceToUnit: 'GF007',
  unasignGeofenceToUnit: 'GF008'
};

export default permissions;
