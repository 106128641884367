import contracts from './contracts';
import trackingUnits from './trackingUnits';

export default [
  {
    header: 'sidebar.general',
    iconName: 'List',
    children: [...contracts, ...trackingUnits] //Only to check permission in children inside
  },
  ...contracts,
  ...trackingUnits
];
