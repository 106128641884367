import { Fragment, useEffect, FC } from 'react';
import { Controller, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, Spinner } from 'reactstrap';
import * as groupsSelectors from 'views/screens/Groups/reducers';
import * as agentsSelectors from 'views/screens/Agents/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import FetchEntities, { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';
import * as operatorSelectors from 'views/screens/Operators/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import DropdownInput from '../DropdownInput';
import { DefaultOptions, useMembership } from './hooks/useMembership';
import { group } from 'console';

/* -------------------------------------------------------------------------- */
/*                            COMPONENTE MEMBERSHIP                           */
/* -------------------------------------------------------------------------- */
// Lista de opciones que se le puede enviar al componente
// dentro del prop "options":
//   ----------------------------------------
//  | Option                 | Default Value |
//   ----------------------------------------
//  | colSize                |      "3"      |
//  | showAllOperator        |     false     |
//  | showAllOrganization    |     false     |
//  | showAllAgent           |     false     |
//  | showAllDivision        |     false     |
//  | showAllSubdivision     |     false     |
//  | useUserOperator        |     false     |
//  | useUserOrganization    |     false     |
//  | useUserAgent           |     false     |
//  | useUserDivision        |     false     |
//  | useUserSubdivision     |     false     |
//  | useOperator            |     true      |
//  | useOrganization        |     true      |
//  | useAgent               |     false     |
//  | useDivision            |     true      |
//  | useSubdivision         |     true      |
//  | isOperatorDisabled     |     false     |
//  | isOrganizationDisabled |     false     |
//  | isDivisionDisabled     |     false     |
//  | isSubdivisionDisabled  |     false     |
//  | isAgentDisabled        |     false     |
//  | operatorPlaceholder    |     null      |
//  | organizationPlaceholder|     null      |
//  | agentPlaceholder       |     null      |
//  | divisionPlaceholder    |     null      |
//  | subdivisionPlaceholder |     null      |
//   ----------------------------------------

interface PropsDropDowns {
  name: any;
  control: any;
  shouldUnregister?: boolean;
  isInModal?: boolean;
  additionalonchangefunction?: (
    newMembership: DefaultFilters,
    options: DefaultOptions | MembershipOptions
  ) => void;
  membershipDefaultValue: any;
  membershipOptions: MembershipOptions;
}

interface Props extends Omit<PropsDropDowns, 'membershipDefaultValue' | 'membershipOptions'> {
  options: DefaultOptions;
  defaultValue?: any;
  fetchEntities?: any;
}

interface MembershipOptions {
  useOperator: boolean;
  useOrganization: boolean;
  useDivision: boolean;
  useSubdivision: boolean;
  useAgent: boolean;
  useGroup: boolean;
  showAllOperator: boolean;
  showAllOrganization: boolean;
  showAllDivision: boolean;
  showAllSubdivision: boolean;
  showAllAgent: boolean;
  showAllGroup: boolean;
  filterOperator: boolean;
  filterOrganization: boolean;
  filterDivision: boolean;
  filterSubdivision: boolean;
  filterGroup: boolean;
  isOperatorDisabled: boolean;
  isOrganizationDisabled: boolean;
  isDivisionDisabled: boolean;
  isSubdivisionDisabled: boolean;
  isAgentDisabled: boolean;
  isGroupDisabled: boolean;
  //
  colSize: number;
  subdivisionPlaceholder: string;
}

const Dropdowns: FC<PropsDropDowns> = (props) => {
  const {
    name,
    control,
    shouldUnregister = false,
    isInModal = false,
    additionalonchangefunction = (newMembership, options) => null,
    membershipDefaultValue,
    membershipOptions
  } = props;

  const { field: operatorField, fieldState: operatorFieldState } = useController({
    name: name + '.operatorId',
    control: control,
    shouldUnregister: shouldUnregister,
    defaultValue: membershipDefaultValue?.operatorId,
    rules: { required: membershipOptions.useOperator && !membershipOptions.showAllOperator }
  });

  const { field: organizationField, fieldState: organizationFieldState } = useController({
    name: name + '.organizationId',
    control: control,
    shouldUnregister: shouldUnregister,
    defaultValue: membershipDefaultValue?.organizationId,
    rules: { required: membershipOptions.useOrganization && !membershipOptions.showAllOrganization }
  });

  const { field: divisionField, fieldState: divisionFieldState } = useController({
    name: name + '.divisionId',
    control: control,
    shouldUnregister: shouldUnregister,
    defaultValue: membershipDefaultValue?.divisionId,
    rules: { required: membershipOptions.useDivision && !membershipOptions.showAllDivision }
  });

  const { field: subdivisionField, fieldState: subdivisionFieldState } = useController({
    name: name + '.subdivisionId',
    control: control,
    shouldUnregister: shouldUnregister,
    defaultValue: membershipDefaultValue?.subdivisionId,
    rules: { required: membershipOptions.useSubdivision && !membershipOptions.showAllSubdivision }
  });

  const { field: groupField, fieldState: groupFieldState } = useController({
    name: name + '.groupId',
    control: control,
    shouldUnregister: shouldUnregister,
    defaultValue: membershipDefaultValue?.groupId,
    rules: { required: membershipOptions.useGroup && !membershipOptions.showAllGroup }
  });

  const intl = useIntl();
  //Selectors de las listas para los dropdowns
  const {
    operatorsList,
    organizationsList,
    divisionsList,
    subdivisionsList,
    agentsList,
    groupList
  } = useSelector((state) => ({
    operatorsList: operatorSelectors.getOperatorsList(state).map((operator) => ({
      value: operator.operatorId,
      label: operator.operatorName
    })),
    organizationsList: membershipOptions.useOperator
      ? organizationSelectors
          .getOrganizationsListBySelectedOperator(state, {
            operatorId: operatorField.value
          })
          .map((organization) => ({
            value: organization.organizationId,
            label: organization.organizationName
          }))
      : organizationSelectors.getOrganizationsList(state).map((organization) => ({
          value: organization.organizationId,
          label: organization.organizationName
        })),
    divisionsList:
      membershipOptions.useOrganization || membershipOptions.filterOrganization
        ? divisionSelectors
            .getDivisionsListBySelectedOrganization(state, {
              operatorId: operatorField.value,
              organizationId: organizationField.value ?? membershipOptions.filterOrganization
            })
            .map((division) => ({
              value: division.divisionId,
              label: division.divisionName
            }))
        : divisionSelectors.getDivisionsList(state).map((division) => ({
            value: division.divisionId,
            label: division.divisionName
          })),
    subdivisionsList: membershipOptions.useDivision
      ? subdivisionSelectors
          .getSubdivisionsListBySelectedDivision(state, {
            operatorId: operatorField.value,
            organizationId: organizationField.value,
            divisionId: divisionField.value
          })
          .map((subdivision) => ({
            value: subdivision.subdivisionId,
            label: subdivision.subdivisionName
          }))
      : subdivisionSelectors.getSubdivisionsList(state).map((subdivision) => ({
          value: subdivision.subdivisionId,
          label: subdivision.subdivisionName
        })),
    agentsList: membershipOptions.useAgent
      ? agentsSelectors
          .getAgentsListByMembership(state, {
            operatorId: operatorField.value
          })
          .map((agent) => ({
            value: agent.agentId,
            label: agent.agentName
          }))
      : agentsSelectors.getAgentsList(state).map((agent) => ({
          value: agent.agentId,
          label: agent.agentName
        })),
    groupList: groupsSelectors.getGroupsList(state).map((group) => ({
      value: group.groupId,
      label: group.groupName
    }))
  }));

  //Actualizar el modelo que tiene el padre del componente
  useEffect(() => {
    finalOnChange({
      operatorId: operatorField.value,
      organizationId: organizationField.value,
      divisionId: divisionField.value,
      subdivisionId: subdivisionField.value,
      groupId: groupField.value
    });
  }, [
    operatorField.value,
    organizationField.value,
    divisionField.value,
    subdivisionField.value,
    groupField.value
  ]);

  const finalOnChange = (values) => {
    additionalonchangefunction(values, membershipOptions);
  };
  return (
    <Fragment>
      <FetchEntities
        useDivisions
        divisionsFilters={{
          operatorId: operatorField.value,
          organizationId: organizationField.value ?? membershipOptions.filterOrganization
        }}
        useSubdivisions
        subdivisionsFilters={{
          operatorId: operatorField.value,
          organizationId: organizationField.value ?? membershipOptions.filterOrganization
        }}
        useGroups={membershipOptions.useGroup}
        groupsFilters={{
          operatorId: operatorField.value,
          organizationId: organizationField.value ?? membershipOptions.filterOrganization
        }}
      />
      {membershipOptions.useOperator && (
        <Col sm={membershipOptions.colSize}>
          <DropdownInput
            field={{
              ...operatorField,
              onChange: (value) => {
                if (operatorField.value !== value) {
                  operatorField.onChange(value);
                  organizationField.onChange(null);
                  divisionField.onChange(null);
                  subdivisionField.onChange(null);
                  groupField.onChange(null);
                }
              }
            }}
            fieldstate={operatorFieldState}
            forceinitialvalue={membershipDefaultValue.operatorId}
            required={!membershipOptions.showAllOperator}
            isClearable={membershipOptions.showAllOperator}
            onClear={() => {
              operatorField.onChange(null);
              organizationField.onChange(null);
              divisionField.onChange(null);
              subdivisionField.onChange(null);
              groupField.onChange(null);
            }}
            id={'operatorInput'}
            isInModal={isInModal}
            isDisabled={membershipOptions.isOperatorDisabled}
            options={operatorsList}
            label={intl.formatMessage({
              id: 'operators.operator'
            })}
            placeholder={intl.formatMessage({
              id: membershipOptions.showAllOperator ? 'common.all' : 'operators.selectOperator'
            })}
          />
        </Col>
      )}
      {membershipOptions.useOrganization && (
        <Col sm={membershipOptions.colSize}>
          <DropdownInput
            field={{
              ...organizationField,
              onChange: (value) => {
                if (organizationField.value !== value) {
                  organizationField.onChange(value);
                  divisionField.onChange(null);
                  subdivisionField.onChange(null);
                  groupField.onChange(null);
                }
              }
            }}
            fieldstate={organizationFieldState}
            forceinitialvalue={membershipDefaultValue.organizationId}
            required={!membershipOptions.showAllOrganization}
            isClearable={membershipOptions.showAllOrganization}
            onClear={() => {
              organizationField.onChange(null);
              divisionField.onChange(null);
              subdivisionField.onChange(null);
              groupField.onChange(null);
            }}
            id={'organizationInput'}
            isInModal={isInModal}
            isDisabled={membershipOptions.isOrganizationDisabled}
            options={organizationsList}
            label={intl.formatMessage({
              id: 'organizations.organization'
            })}
            placeholder={intl.formatMessage({
              id: membershipOptions.showAllOrganization
                ? 'common.all'
                : 'organizations.selectOrganization'
            })}
          />
        </Col>
      )}
      {membershipOptions.useDivision && (
        <Col sm={membershipOptions.colSize}>
          <DropdownInput
            field={{
              ...divisionField,
              onChange: (value) => {
                if (divisionField.value !== value) {
                  divisionField.onChange(value);
                  subdivisionField.onChange(null);
                  groupField.onChange(null);
                }
              }
            }}
            fieldstate={divisionFieldState}
            forceinitialvalue={membershipDefaultValue.divisionId}
            required={!membershipOptions.showAllDivision}
            isClearable={membershipOptions.showAllDivision}
            onClear={() => {
              divisionField.onChange(null);
              subdivisionField.onChange(null);
              groupField.onChange(null);
            }}
            id={'divisionInput'}
            isInModal={isInModal}
            isDisabled={membershipOptions.isDivisionDisabled}
            options={divisionsList}
            label={intl.formatMessage({
              id: 'divisions.division'
            })}
            placeholder={intl.formatMessage({
              id: membershipOptions.showAllDivision ? 'common.all' : 'divisions.selectDivision'
            })}
          />
        </Col>
      )}
      {membershipOptions.useSubdivision && (
        <Col sm={membershipOptions.colSize}>
          <DropdownInput
            field={subdivisionField}
            fieldstate={subdivisionFieldState}
            forceinitialvalue={membershipDefaultValue.subdivisionId}
            required={!membershipOptions.showAllSubdivision}
            isClearable={membershipOptions.showAllSubdivision}
            id={'subdivisionInput'}
            isInModal={isInModal}
            isDisabled={membershipOptions.isSubdivisionDisabled}
            options={subdivisionsList}
            label={intl.formatMessage({
              id: 'subdivisions.subdivision'
            })}
            placeholder={
              membershipOptions.subdivisionPlaceholder ??
              intl.formatMessage({
                id: membershipOptions.showAllSubdivision
                  ? 'common.all'
                  : 'subdivisions.selectSubdivision'
              })
            }
          />
        </Col>
      )}
      {membershipOptions.useAgent && (
        <Col sm={membershipOptions.colSize}>
          <Controller
            control={control}
            shouldUnregister={shouldUnregister}
            name={name + '.agentId'}
            defaultValue={membershipDefaultValue?.agentId}
            rules={{ required: !membershipOptions.showAllAgent }}
            render={({ field, fieldState }) => (
              <DropdownInput
                field={field}
                fieldstate={fieldState}
                forceinitialvalue={membershipDefaultValue.agentId}
                required={!membershipOptions.showAllAgent}
                isClearable={membershipOptions.showAllAgent}
                id={'agentInput'}
                isInModal={isInModal}
                isDisabled={membershipOptions.isAgentDisabled}
                options={agentsList}
                label={intl.formatMessage({
                  id: 'agents.agent'
                })}
                placeholder={intl.formatMessage({
                  id: membershipOptions.showAllAgent ? 'common.all' : 'agents.selectAgent'
                })}
              />
            )}
          />
        </Col>
      )}
      {membershipOptions.useGroup && (
        <Col sm={membershipOptions.colSize}>
          <DropdownInput
            field={{
              ...groupField,
              onChange: (value) => {
                if (groupField.value !== value) {
                  groupField.onChange(value);
                }
              }
            }}
            fieldstate={groupFieldState}
            forceinitialvalue={membershipDefaultValue.groupId}
            required={!membershipOptions.showAllGroup}
            isClearable={membershipOptions.showAllGroup}
            onClear={() => {
              groupField.onChange(null);
            }}
            id={'groupInput'}
            isInModal={isInModal}
            isDisabled={membershipOptions.isGroupDisabled}
            options={groupList}
            label={intl.formatMessage({
              id: 'groups.group'
            })}
            placeholder={intl.formatMessage({
              id: membershipOptions.showAllGroup ? 'common.all' : 'users.selectGroup'
            })}
          />
        </Col>
      )}
    </Fragment>
  );
};

const Membership: FC<Props> = (props) => {
  const {
    options = {},
    defaultValue = {
      groupId: null,
      agentId: null,
      operatorId: null,
      organizationId: null,
      divisionId: null,
      subdivisionId: null,
      operatorName: '',
      organizationName: '',
      divisionName: '',
      subdivisionName: '',
      agentName: '',
      groupName: ''
    },
    fetchEntities = true,
    additionalonchangefunction
  } = props;
  //Se inicializa model y options
  const [membershipDefaultValue, membershipOptions, isInitialize] = useMembership(
    options,
    defaultValue
  );

  return (
    <Fragment>
      {fetchEntities && <FetchEntities useOperators useOrganizations />}
      {isInitialize ? (
        <Dropdowns
          {...props}
          membershipDefaultValue={membershipDefaultValue}
          membershipOptions={membershipOptions}
        />
      ) : (
        <Col sm={membershipOptions.colSize} className="d-flex justify-content-center mt-1 mb-1">
          <Spinner color="primary" size="lg" />
        </Col>
      )}
    </Fragment>
  );
};

export default Membership;
