import { AxiosResponse } from 'axios';
import { HttpWebtrack } from 'utility/http';
import { handleError, handleSuccess } from 'utility/http/http-events';
import { loadingOverlyaInterceptor } from 'utility/http/interceptors/loading-overlay-interceptor';
import { authTokenInterceptor } from './auth-token-interceptor';

const ConfigureWebtrackInterceptor = (store: any, httpWebtrack: HttpWebtrack) => {
  loadingOverlyaInterceptor(store);
  httpWebtrack.service.interceptors.request.use(
    (conf: any) => {
      const session = store.getState()?.auth;
      return authTokenInterceptor(conf, session);
    },
    (error: any) => {
      return Promise.reject(error);
    }
  );
  httpWebtrack.service.interceptors.response.use(
    (response: AxiosResponse<any>): AxiosResponse<any> => {
      return handleSuccess(response, httpWebtrack.configMessage, httpWebtrack.configAction);
    },
    (error) => {
      return handleError(error, httpWebtrack.configMessage, httpWebtrack.configAction);
    }
  );
};

export default {
  ConfigureWebtrackInterceptor
};
