export const OPERATORS_FETCH_STARTED = 'OPERATORS_FETCH_STARTED';
export const OPERATORS_FETCH_COMPLETED = 'OPERATORS_FETCH_COMPLETED';
export const OPERATORS_FETCH_FAILED = 'OPERATORS_FETCH_FAILED';

export const OPERATORS_PARTIAL_FETCH_STARTED = 'OPERATORS_PARTIAL_FETCH_STARTED';
export const OPERATORS_PARTIAL_FETCH_COMPLETED = 'OPERATORS_PARTIAL_FETCH_COMPLETED';
export const OPERATORS_PARTIAL_FETCH_FAILED = 'OPERATORS_PARTIAL_FETCH_FAILED';

export const OPERATOR_ADD_STARTED = 'OPERATOR_ADD_STARTED';
export const OPERATOR_ADD_COMPLETED = 'OPERATOR_ADD_COMPLETED';
export const OPERATOR_ADD_FAILED = 'OPERATOR_ADD_FAILED';

export const OPERATOR_EDIT_STARTED = 'OPERATOR_EDIT_STARTED';
export const OPERATOR_EDIT_COMPLETED = 'OPERATOR_EDIT_COMPLETED';
export const OPERATOR_EDIT_FAILED = 'OPERATOR_EDIT_FAILED';

export const OPERATOR_REMOVE_STARTED = 'OPERATOR_REMOVE_STARTED';
export const OPERATOR_REMOVE_COMPLETED = 'OPERATOR_REMOVE_COMPLETED';
export const OPERATOR_REMOVE_FAILED = 'OPERATOR_REMOVE_FAILED';

export const OPERATOR_SELECTED = 'OPERATOR_SELECTED';
export const OPERATOR_DESELECTED = 'OPERATOR_DESELECTED';
export const OPERATORS_ALL_SELECTED = 'OPERATORS_ALL_SELECTED';
export const OPERATORS_ALL_DESELECTED = 'OPERATORS_ALL_DESELECTED';

export const OPERATOR_EDIT_STANDARD_PRICES_STARTED = 'OPERATOR_EDIT_STANDARD_PRICES_STARTED';
export const OPERATOR_EDIT_STANDARD_PRICES_FAILED = 'OPERATOR_EDIT_STANDARD_PRICES_FAILED';
export const OPERATOR_EDIT_STANDARD_PRICES_COMPLETED = 'OPERATOR_EDIT_STANDARD_PRICES_COMPLETED';

export interface ReduxOperator {
  isSelected: boolean;
  operatorId: number;
  operatorName: string;
  operatorPhone: string;
  operatorAddress: string;
  comments?: string;
  contactName?: string;
  contactPhone?: string;
  taxNumber?: string;
  contractNumber?: number;
  operatorStatus: number;
  legalRepresentativeId?: number;
  legalRepresentativeName?: string;
  legalRepresentativeIdentification?: string;
  legalRepresentativeAge: number;
  legalRepresentativeNacionality?: string;
  legalRepresentativeAddress?: string;
  recordNumber?: number;
  recordSheet?: number;
  recordBook?: number;
  recordDate?: string;
  utcZoneId?: number;
  quotationValidity?: number;
  isConfirmed: boolean;
}
