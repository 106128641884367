import * as types from '../types';

export const startFetchingUnits = (filters = {}) => ({
  type: types.UNITS_FETCH_STARTED,
  payload: filters
});

export const completeFetchingUnits = (entities, order, operatorId, organizationId) => ({
  type: types.UNITS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});

export const failFetchingUnits = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNITS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingUnits = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.UNITS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingUnits = (entities, order, operatorId, organizationId) => ({
  type: types.UNITS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingUnits = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNITS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const addingUnitToState = (unit) => ({
  type: types.UNIT_ADD_TO_STATE,
  payload: unit
});

export const startFetchingUnitsLiveStatus = () => ({
  type: types.UNITS_LIVE_STATUS_FETCH_STARTED,
  payload: {}
});

export const completeFetchingUnitsLiveStatus = (entities, order, organizationId) => ({
  type: types.UNITS_LIVE_STATUS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    organizationId
  }
});

export const failFetchingUnitsLiveStatus = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.UNITS_LIVE_STATUS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingUnitInformation = (unitId) => ({
  type: types.UNIT_INFORMATION_FETCH_STARTED,
  payload: { unitId }
});

export const completeFetchingUnitInformation = (
  unitId,
  unitData,
  deviceMetricsEntities,
  deviceMetricsOrder,
  deviceActionsEntities,
  deviceActionsOrder,
  groupsEntities,
  groupsOrder,
  reportsEntities,
  reportsOrder,
  imagesEntities,
  imagesOrder,
  sensorsEntities,
  sensorsOrder
) => ({
  type: types.UNIT_INFORMATION_FETCH_COMPLETED,
  payload: {
    unitId,
    unitData,
    deviceMetricsEntities,
    deviceMetricsOrder,
    deviceActionsEntities,
    deviceActionsOrder,
    groupsEntities,
    groupsOrder,
    reportsEntities,
    reportsOrder,
    imagesEntities,
    imagesOrder,
    sensorsEntities,
    sensorsOrder
  }
});

export const failFetchingUnitInformation = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.UNIT_INFORMATION_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingUnitPositions = (unitId, reportType, startDate, endDate, idleSpeed) => ({
  type: types.UNIT_POSITIONS_FETCH_STARTED,
  payload: { unitId, reportType, startDate, endDate, idleSpeed }
});

export const completeFetchingUnitPositions = (
  unitId,
  distance,
  parkedPositions,
  polyline,
  positions
) => ({
  type: types.UNIT_POSITIONS_FETCH_COMPLETED,
  payload: {
    unitId,
    distance,
    parkedPositions,
    polyline,
    positions
  }
});

export const failFetchingUnitPositions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNIT_POSITIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const resetIsFetchedUnitPosition = () => ({
  type: types.UNIT_POSITIONS_RESET_IS_FETCHED,
  payload: {}
});

export const executeDeviceAction = (unitId, deviceActionId, parameters = null) => ({
  type: types.UNIT_EXECUTE_DEVICE_ACTION,
  payload: {
    unitId,
    deviceActionId,
    parameters
  }
});

export const startAddingUnit = (unit) => ({
  type: types.UNIT_ADD_STARTED,
  payload: unit
});
export const completeAddingUnit = (oldId, unit) => ({
  type: types.UNIT_ADD_COMPLETED,
  payload: { oldId, unit }
});
export const failAddingUnit = (oldId, error) => ({
  type: types.UNIT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export type UpdateUnitDeviceRequest = { unitId: number; deviceId: number | null };
type UpdateUnitResponse = {
  unitId: number;
  deviceId: number | null;
};
export const startUpdateDevice = (
  data: UpdateUnitDeviceRequest & { additionalOnSuccess?: (() => void) | undefined }
) => ({
  type: types.UNIT_UPDATE_DEVICE_STARTED,
  payload: data
});
export const completeUpdateDevice = (data: UpdateUnitResponse) => ({
  type: types.UNIT_UPDATE_DEVICE_COMPLETED,
  payload: data
});
export const failUpdateDevice = (oldId: number, error: Error) => ({
  type: types.UNIT_UPDATE_DEVICE_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingUnit = (unit) => ({
  type: types.UNIT_EDIT_STARTED,
  payload: unit
});
export const completeEditingUnit = (unit) => ({
  type: types.UNIT_EDIT_COMPLETED,
  payload: unit
});
export const failEditingUnit = (oldUnit, error) => ({
  type: types.UNIT_EDIT_FAILED,
  payload: {
    oldUnit,
    error
  }
});

export const startAcknowledgeUnitEvent = (unitId) => ({
  type: types.UNIT_ACKNOWLEDGE_STARTED,
  payload: { unitId }
});
export const completeAcknowledgeUnitEvent = (unitId) => ({
  type: types.UNIT_ACKNOWLEDGE_COMPLETED,
  payload: { unitId }
});
export const failAcknowledgeUnitEvent = (error) => ({
  type: types.UNIT_ACKNOWLEDGE_FAILED,
  payload: {
    error
  }
});

export const startAssigningDriverUnits = (units) => ({
  type: types.UNITS_ASSIGN_DRIVER_STARTED,
  payload: units
});
export const completeAssigningDriverUnits = (units) => ({
  type: types.UNITS_ASSIGN_DRIVER_COMPLETED,
  payload: units
});

export const startEndDowntimeUnit = (unitId) => ({
  type: types.UNIT_END_DOWNTIME_STARTED,
  payload: {
    unitId
  }
});
export const completeEndDowntimeUnit = (unitId, mainStateId, mainStateDate) => ({
  type: types.UNIT_END_DOWNTIME_COMPLETED,
  payload: {
    unitId,
    mainStateId,
    mainStateDate
  }
});
export const failEndDowntimeUnit = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNIT_END_DOWNTIME_FAILED,
  payload: {
    error
  }
});

export const startStartDowntimeUnit = (unitId, reasonId) => ({
  type: types.UNIT_START_DOWNTIME_STARTED,
  payload: {
    unitId,
    reasonId
  }
});
export const completeStartDowntimeUnit = (unitId, reasonId, reasonDescription) => ({
  type: types.UNIT_START_DOWNTIME_COMPLETED,
  payload: {
    unitId,
    reasonId,
    reasonDescription
  }
});
export const failStartDowntimeUnit = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNIT_START_DOWNTIME_FAILED,
  payload: {
    error
  }
});

export const startActivingUnits = (units) => ({
  type: types.UNITS_ACTIVE_STARTED,
  payload: units
});

export const completeActivingUnits = (units) => ({
  type: types.UNITS_ACTIVE_COMPLETED,
  payload: units
});
export const failActivingUnits = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.UNITS_ACTIVE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startRemovingUnit = (units, unitsName) => ({
  type: types.UNIT_REMOVE_STARTED,
  payload: {
    units,
    unitsName
  }
});
export const completeRemovingUnit = (units, unitsName) => ({
  type: types.UNIT_REMOVE_COMPLETED,
  payload: {
    units,
    unitsName
  }
});
export const failRemovingUnit = (units, error) => ({
  type: types.UNIT_REMOVE_FAILED,
  payload: {
    units,
    error
  }
});

export const setDateRange = (unitId, dateRange) => ({
  type: types.SET_DATE_RANGE,
  payload: { unitId, dateRange }
});

export const selectUnit = (unitId) => ({
  type: types.UNIT_SELECTED,
  payload: unitId
});

export const selectAllUnits = (
  unitIds: any[] = [] //List of UnitIds
) => ({
  type: types.UNITS_ALL_SELECTED,
  payload: unitIds
});

export const deselectUnit = (unitId) => ({
  type: types.UNIT_DESELECTED,
  payload: unitId
});

export const deselectAllUnits = (
  unitIds: any[] = [] //List of UnitIds
) => ({
  type: types.UNITS_ALL_DESELECTED,
  payload: unitIds
});

export const showUnitInMap = (unitId) => ({
  type: types.UNIT_SHOW_IN_MAP,
  payload: unitId
});

export const showAllUnitsInMap = (
  unitIds: any[] = [] //List of UnitIds
) => ({
  type: types.UNITS_ALL_SHOW_IN_MAP,
  payload: unitIds
});

export const hideUnitInMap = (unitId) => ({
  type: types.UNIT_HIDE_IN_MAP,
  payload: unitId
});

export const hideAllUnitsInMap = (
  unitIds: any[] = [] //List of UnitIds
) => ({
  type: types.UNITS_ALL_HIDE_IN_MAP,
  payload: unitIds
});

export const startCreatingShareLink = (unitId, unitName, emails, ttl) => ({
  type: types.UNIT_CREATE_SHARE_LINK_STARTED,
  payload: {
    unitId,
    unitName,
    emails,
    ttl
  }
});
export const completeCreatingShareLink = (result) => ({
  type: types.UNIT_CREATE_SHARE_LINK_COMPLETED,
  payload: result
});
export const failCreatingShareLink = (error) => ({
  type: types.UNIT_CREATE_SHARE_LINK_FAILED,
  payload: {
    error
  }
});

export const clearSharedLink = () => ({
  type: types.UNIT_CLEAR_SHARE_LINK,
  payload: {}
});

export const startUpdateSim = ({
  unitId,
  simCardId
}: {
  unitId: number;
  simCardId: number | null;
  additionalOnSuccess?: () => void;
}) => ({
  type: types.UNIT_UPDATE_SIM_STARTED,
  payload: { unitId, simCardId }
});

export const failUpdateSim = (oldUnitId: number, error: string) => ({
  type: types.UNIT_UPDATE_SIM_FAILED,
  payload: { error }
});

export const completeUpdateSim = (changes: Partial<types.ReduxUnit>) => ({
  type: types.UNIT_UPDATE_SIM_COMPLETED,
  payload: { changes }
});
