import * as types from '../../types/workplansExecutions';

export const startFetchingWorkplansExecutions = (filters = {}) => ({
  type: types.WORKPLANS_EXECUTIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingWorkplansExecutions = (
  entities,
  order,
  indicators,
  executionStatus,
  geographicStatus
) => ({
  type: types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    indicators,
    executionStatus,
    geographicStatus
  }
});
export const failFetchingWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingWorkplanExecution = (visitId) => ({
  type: types.WORKPLAN_EXECUTION_FETCH_STARTED,
  payload: {
    visitId
  }
});
export const completeFetchingWorkplanExecution = (
  workplanExecution,
  workplanExecutionLocationsEntities,
  workplanExecutionLocationsOrder
) => ({
  type: types.WORKPLAN_EXECUTION_FETCH_COMPLETED,
  payload: {
    workplanExecution,
    workplanExecutionLocationsEntities,
    workplanExecutionLocationsOrder
  }
});
export const failFetchingWorkplanExecution = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLAN_EXECUTION_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startCancelWorkplansExecutions = (visitId, unitId, assignmentId?: number) => ({
  type: types.WORKPLANS_EXECUTION_CANCEL_STARTED,
  payload: { visitId, unitId, assignmentId }
});
export const completeCancelWorkplansExecutions = (visitId, unitId) => ({
  type: types.WORKPLANS_EXECUTION_CANCEL_COMPLETED,
  payload: { visitId, unitId }
});
export const failCancelWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTION_CANCEL_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFinalizeWorkplansExecutions = (visitId, unitId, assignmentId?: number) => ({
  type: types.WORKPLANS_EXECUTION_FINALIZE_STARTED,
  payload: { visitId, unitId, assignmentId }
});
export const completeFinalizeWorkplansExecutions = (visitId, unitId) => ({
  type: types.WORKPLANS_EXECUTION_FINALIZE_COMPLETED,
  payload: { visitId, unitId }
});
export const failFinalizeWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTION_FINALIZE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startManualTransitWorkplansExecutions = (visitId, unitId, reason) => ({
  type: types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_STARTED,
  payload: { visitId, unitId, reason }
});
export const completeManualTransitWorkplansExecutions = (visitId, unitId) => ({
  type: types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_COMPLETED,
  payload: { visitId, unitId }
});
export const failManualTransitWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startChangeUnitWorkplansExecutions = (visitId, unitId) => ({
  type: types.WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED,
  payload: { visitId, unitId }
});
export const completeChangeUnitWorkplansExecutions = (visitId, unitId) => ({
  type: types.WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED,
  payload: { visitId, unitId }
});
export const failChangeUnitWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTION_CHANGE_UNIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAssignUnitWorkplansExecutions = (assignation) => ({
  type: types.WORKPLANS_EXECUTION_ASSIGN_UNIT_STARTED,
  payload: assignation
});
export const completeAssignUnitWorkplansExecutions = (assignation) => ({
  type: types.WORKPLANS_EXECUTION_ASSIGN_UNIT_COMPLETED,
  payload: assignation
});
export const failAssignUnitWorkplansExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_EXECUTION_ASSIGN_UNIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
