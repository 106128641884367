import Slider from '@material-ui/core/Slider';
import { getOrientation } from 'get-orientation/browser';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-easy-crop';
import { RotateCcw, RotateCw, Upload, ZoomIn, ZoomOut } from 'react-feather';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button, Col, FormGroup, Spinner } from 'reactstrap';
import * as authSelectors from '../../../redux/reducers/auth';
import API_BASE_URL from '../../../redux/sagas/settings/apibaseurl';
import WebtrackCustomInput from '../WebtrackCustomInput';
import { getCroppedImg, getRotatedImage } from './ImgUtils';
import { useForm } from 'react-hook-form';

//read a file function

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90
};

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

/* -------------------------------------------------------------------------- */
/*                                ImageUploader                               */
/* -------------------------------------------------------------------------- */
const ImageUploader = ({
  onChangeImage,
  closeSlidepanel = () => {},
  cropShape,
  aspect,
  showGrid,
  filesPath,
  onSuccessChangeFunction,
  onFailureChangeFunction,
  functionAfterUpload,
  showPreview = false,
  disabled = false,
  otherValues = {},
  inputs = [],
  buttonConfirmTextId = 'common.save',
  buttonCancelTextId = 'common.cancel',
  dragndropTextId = 'image.dragndropImage',
  uploadTextId = 'image.uploadImage',
  fileTypes = 'image/*'
}) => {
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    accept: fileTypes,
    maxFiles: 1
  });

  const { control, handleSubmit } = useForm();

  useEffect(() => {
    if (acceptedFiles.length == 1) {
      onFileChange(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  //On File Change to place it into crop
  const onFileChange = async (file) => {
    let imageDataUrl = await readFile(file);
    // apply rotation if needed
    const orientation = await getOrientation(file);
    const rotation = ORIENTATION_TO_ANGLE[orientation];
    if (rotation) {
      imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
    }
    setCroppedImage(null);
    setImageSrc(imageDataUrl);
    setFileType(file.type);
    setFileName(file.name);
  };

  //Reset Image Upload
  const resetImages = () => {
    setCroppedImage(null);
    setImageSrc(null);
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  //variables to handle image, types and cropped image
  const [imageSrc, setImageSrc] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isLoadingImage, setIsLoadingImage] = useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  //Upload Image Function
  const store = useSelector((state) => state);
  const token = authSelectors.getAuthToken(store);
  const uploadImage = async (values) => {
    let error = false;
    try {
      setIsLoadingImage(true);
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation,
        fileName,
        fileType
      );
      var headers = new Headers();
      headers.append('Authorization', `Bearer ${token}`);
      var data = new FormData();
      data.append('file', croppedImage.file);
      data.append('path', filesPath);
      data.append('saveInDatabase', true);
      const response = await fetch(API_BASE_URL + '/Files/saveFile', {
        method: 'POST',
        headers: headers,
        body: data
      });

      if (response.status <= 300) {
        const jsonResponse = await response.json();
        if (jsonResponse.success) {
          const { fileName, fileUrl } = jsonResponse.data;
          const responseAfterUpload = await functionAfterUpload({
            fileName,
            fileUrl,
            imageName: fileName,
            fileType: fileType,
            imageTypeId: 1,
            imageUrl: fileUrl,
            ...otherValues,
            ...values
          });
          if (responseAfterUpload.status <= 300) {
            const responseAfterUploadJson = responseAfterUpload.success
              ? responseAfterUpload
              : await responseAfterUpload.json();
            if (responseAfterUploadJson.success) {
              onSuccessChangeFunction({
                imageName: fileName,
                fileType: fileType,
                imageUrl: fileUrl,
                imageTypeId: 1,
                ...otherValues,
                ...values
              });
            } else {
              error = true;
            }
          } else {
            error = true;
          }
          onChangeImage({
            imageName: fileName,
            fileType: fileType,
            imageUrl: fileUrl,
            imageTypeId: 1,
            ...otherValues,
            ...values
          });
        } else {
          error = true;
        }
      } else {
        error = true;
      }
    } catch (e) {
      console.error(e);
      error = true;
    }
    if (error) {
      onFailureChangeFunction();
    } else {
      closeSlidepanel();
    }
    setIsLoadingImage(false);
  };

  //Component for uploading images
  return (
    <>
      <section className="container">
        {imageSrc == null && (
          <>
            <div {...getRootProps({ className: 'dropzone', style })}>
              <input {...getInputProps()} />

              <Upload size={30}></Upload>
              <p>
                <FormattedMessage id={dragndropTextId} />
              </p>
            </div>
            <div className="text-center">
              <Button.Ripple
                disabled={disabled}
                className="mr-1 mt-1"
                color="primary"
                onClick={() => {
                  open();
                  setCroppedImage(null);
                }}
              >
                <FormattedMessage id={uploadTextId} />
              </Button.Ripple>
            </div>
          </>
        )}
        {imageSrc && croppedImage == null && (
          <React.Fragment>
            <div className={'crop-container'}>
              <Cropper
                image={imageSrc}
                crop={crop}
                cropShape={cropShape}
                aspect={aspect}
                showGrid={showGrid}
                rotation={rotation}
                zoom={zoom}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={'controls'}>
              <div className="d-flex flex-row">
                <ZoomOut size={30} className={'mr-1'} />
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  className={'slider'}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
                <ZoomIn size={30} className={'ml-1'} />
              </div>
              <div className="d-flex flex-row">
                <RotateCcw size={30} className={'mr-1'} />
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  className={'slider'}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
                <RotateCw size={30} className={'ml-1'} />
              </div>
            </div>
            <br />
            {inputs.map((input, i) => (
              <WebtrackCustomInput
                key={i}
                control={control}
                input={{ ...input, name: input.name || 'input-' + i, id: i.toString() }}
              />
            ))}
            <Col sm="12 d-flex justify-content-center">
              <FormGroup className="d-flex mb-2">
                <Button.Ripple
                  disabled={isLoadingImage}
                  className="mr-1 mt-1"
                  color="primary"
                  onClick={handleSubmit((values) => {
                    uploadImage(values);
                  })}
                >
                  {isLoadingImage && <Spinner color="white" size="sm" />}
                  <span className="ml-50">
                    <FormattedMessage id={buttonConfirmTextId} />
                  </span>
                </Button.Ripple>
                <Button.Ripple
                  disabled={isLoadingImage}
                  color="light"
                  className="mt-1"
                  onClick={() => {
                    resetImages();
                  }}
                >
                  <FormattedMessage id={buttonCancelTextId} />
                </Button.Ripple>
              </FormGroup>
            </Col>{' '}
          </React.Fragment>
        )}
      </section>
    </>
  );
};

export default ImageUploader;
