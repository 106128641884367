const permissions = {
  listFleet: 'BA002',
  deviceAction1: 'UA001',
  deviceAction2: 'UA002',
  deviceAction3: 'UA026',
  deviceAction4: 'UA034',
  deviceAction5: 'UA035',
  deviceAction6: 'UA043',
  deviceAction8: 'UA048',
  deviceAction9: 'UA051',
  deviceAction11: 'UA023',
  deviceAction12: 'UA024',
  deviceAction13: 'UA055',
  deviceAction20: 'UA003',
  deviceAction21: 'UA004',
  deviceAction22: 'UA005',
  deviceAction23: 'UA006',
  deviceAction30: 'UA027',
  deviceAction31: 'UA028',
  deviceAction32: 'UA029',
  deviceAction33: 'UA030',
  deviceAction34: 'UA031',
  deviceAction35: 'UA032',
  deviceAction36: 'UA033',
  deviceAction50: 'UA007',
  deviceAction51: 'UA036',
  deviceAction52: 'UA037',
  deviceAction53: 'UA038',
  deviceAction54: 'UA039',
  deviceAction55: 'UA040',
  deviceAction56: 'UA041',
  deviceAction57: 'UA042',
  deviceAction60: 'UA044',
  deviceAction61: 'UA045',
  deviceAction70: 'UA046',
  deviceAction71: 'UA047',
  deviceAction80: 'UA049',
  deviceAction81: 'UA050',
  deviceAction90: 'UA008',
  deviceAction91: 'UA052',
  deviceAction92: 'UA053',
  deviceAction93: 'UA054',
  deviceAction100: 'UA013',
  deviceAction1000: 'UA009',
  deviceAction1001: 'UA014',
  deviceAction1002: 'UA010',
  deviceAction1003: 'UA015',
  deviceAction1004: 'UA016',
  deviceAction1005: 'UA017',
  deviceAction1006: 'UA018',
  deviceAction1007: 'UA019',
  deviceAction1008: 'UA020',
  deviceAction1009: 'UA011',
  deviceAction1010: 'UA021',
  deviceAction1011: 'UA022',
  deviceAction1200: 'UA025',
  downtime: 'UP027'
};

export default permissions;
