import camelcaseKeys from 'camelcase-keys';
import { select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as types from '../types';
import * as actions from '../actions';
import { getJobService } from '../services/jobs';

export * from './backlog';
export * from './board';
export * from './report';

/* -------------------------------------------------------------------------- */
/*                                 FETCH JOB                                  */
/* -------------------------------------------------------------------------- */
function* fetchJob(action) {
  const { jobId } = action.payload;
  getJobService(
    { jobId },
    {
      successAction: (response) => {
        const job = camelcaseKeys(response.data);
        store.dispatch(actions.completeFetchingJob(job));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingJob(error));
      }
    }
  );
}

export function* watchFetchJob() {
  yield takeEvery(types.JOB_FETCH_STARTED, fetchJob);
}
