const permissions = {
  listContract: 'CC001',
  editContract: 'CC003',
  addContract: 'CC002',
  deleteContract: 'CC004',
  listTemplate: 'CC001',
  editTemplate: 'CC003',
  addTemplate: 'CC002',
  deleteTemplate: 'CC004',
  listQuote: 'CC001',
  editQuote: 'CC003',
  addQuote: 'CC002',
  deleteQuote: 'CC004'
};

export default permissions;
