import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import * as agentSelectors from '../reducers';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchAgentsList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/agent/get`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { agents },
          result //order
        } = normalize(resultData, schemas.agents); //normalize data to byId and order
        yield put(actions.completeFetchingAgents(agents, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingAgents(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingAgents(error));
  }
}

export function* watchFetchAgentsList() {
  yield takeEvery(types.AGENTS_FETCH_STARTED, fetchAgentsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchAgentsList(action) {
  try {
    const entityTypeId = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/agent/GET`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { agents },
          result //order
        } = normalize(resultData, schemas.agents); //normalize data to byId and order
        yield put(actions.completePartialFetchingAgents(agents, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingAgents(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingAgents(error));
  }
}

export function* watchPartialFetchAgentsList() {
  yield takeEvery(types.AGENTS_PARTIAL_FETCH_STARTED, partialFetchAgentsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addAgent(action) {
  const agent = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/agent/save`, {
        method: 'POST',
        body: JSON.stringify({ ...agent, agentId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedAgent = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingAgent(agent.agentId, addedAgent));
        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'agents.agent'
          }),
          name: agent.agentName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingAgent(agent.agentId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingAgent(agent.agentId, error));
  }
}

export function* watchAddAgentsStarted() {
  yield takeEvery(types.AGENT_ADD_STARTED, addAgent);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editAgent(action) {
  const agent = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/agent/save`, {
        method: 'POST',
        body: JSON.stringify({
          ...agent
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedAgent = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingAgent(editedAgent));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'agents.agent'
          }),
          name: editedAgent.agentName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldAgent } = yield select((state) => selectors.getAgent(state, agent.agentId));
        yield put(actions.failEditingAgent(oldAgent, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldAgent } = yield select((state) => selectors.getAgent(state, agent.agentId));
    yield put(actions.failEditingAgent(oldAgent, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditAgentsStarted() {
  yield takeEvery(types.AGENT_EDIT_STARTED, editAgent);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeAgent(action) {
  const { agentId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedAgent = yield select((state) => agentSelectors.getAgent(state, agentId));
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/agent/delete`, {
        method: 'POST',
        body: JSON.stringify({ agentId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingAgent(agentId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'agents.agent'
          }),
          name: deletedAgent.agentName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingAgent(agentId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingAgent(agentId, error));
  }
}

export function* watchRemoveAgentStarted() {
  yield takeEvery(types.AGENT_REMOVE_STARTED, removeAgent);
}

/* -------------------------------------------------------------------------- */
/*                               FETCH AGENT                               */
/* -------------------------------------------------------------------------- */
function* fetchAgent(action) {
  const agentId = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/agent/${agentId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const agent = camelcaseKeys(jsonResult.data);
        yield put(actions.completeFetchingAgent(agent));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };

        yield put(actions.failFetchingAgent(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    yield put(actions.failFetchingAgent(error));
  }
}

export function* watchAgentFetchStarted() {
  yield takeEvery(types.AGENT_FETCH_STARTED, fetchAgent);
}
