import { all, call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import * as entityTypes from '../../../screens/Entities/constants';
//Se importan las actions de las entities que se quieren actualizar
import * as operatorsActions from 'views/screens/Operators/actions';
import * as organizationsActions from 'views/screens/Organizations/actions';
import * as divisionsActions from 'views/screens/Divisions/actions';
import * as subdivisionsActions from 'views/screens/Subdivisions/actions';
import * as maintenanceLinesActions from 'views/screens/MaintenanceLines/actions';
import * as unitsActions from '../../Units/actions';
import * as geofencesActions from 'views/screens/Geofences/actions';
import * as workplansActions from 'views/screens/Workplans/actions/workplans';

import * as routesActions from 'views/screens/Routes/actions/routes';
import * as serviceClassesActions from 'views/screens/ServiceClasses/actions';
import * as locationActions from '../../Locations/actions';
import * as officeActions from '../../Offices/actions';
import * as driverActions from '../../Drivers/actions';
import * as schedulesActions from '../../Schedules/actions';
import * as brandsActions from '../../../../redux/actions/static-catalogs/brands';
import * as modelsActions from '../../../../redux/actions/models';
import * as contactsActions from 'views/screens/Contacts/actions';
import * as monitoringUsers from '../../Users/actions';
import * as agentActions from 'views/screens/Agents/actions';
import * as modulesActions from '../../Modules/actions';
import * as simActions from '../../SIMs/actions';
import * as countriesActions from '../../../../redux/actions/static-catalogs/countries';
import * as vehicleActions from '../../Vehicles/actions/index';
import * as vehicleMakerActions from '../../../../redux/actions/static-catalogs/vehicle-makers';
import * as assetsActions from 'views/screens/Assets/actions';
import * as sensorsActions from 'views/screens/Sensors/actions';
import * as jobActions from 'views/screens/Jobs/actions';
import * as groupsActions from 'views/screens/Groups/actions';
import * as formsActions from 'views/screens/Forms/actions';
import * as organizationContractsActions from 'views/screens/Contracts/actions';
import * as contractTemplatesActions from 'views/screens/ContractTemplates/actions';
import * as customersActions from 'views/screens/Customers/actions';
import * as protocolsActions from 'views/screens/Protocols/actions';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchEntitiesList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/account/entities-datetimes`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { entities },
          result //order
        } = normalize(resultData, schemas.entities); //normalize data to byId and order
        yield put(actions.completeFetchingEntities(entities, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingEntities(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingEntities(error));
  }
}

export function* watchFetchEntitiesList() {
  yield takeEvery(types.ENTITIES_FETCH_STARTED, fetchEntitiesList);
}

/* -------------------------------------------------------------------------- */
/*                               UPDATE ENTITIES                              */
/* -------------------------------------------------------------------------- */
function* updateEntities(action) {
  const isFetchedListEntities = yield select(selectors.isFetchedListEntities);
  if (isFetchedListEntities) {
    try {
      const entitiesToUpdate = action.payload;
      const isEntityUpdatedFunction = yield select(selectors.isEntityUpdated);
      const isEntityUpdated = isEntityUpdatedFunction(entitiesToUpdate);
      yield all(
        entitiesToUpdate.map((entity, index) => {
          //Si no esta actualizada se actualiza con la acción fetch de la propia entidad
          const entityTypeId = entity?.entityTypeId || entity;
          if (isEntityUpdated[index] !== true) {
            switch (parseInt(entityTypeId)) {
              case entityTypes.ENTITY_UNITS:
                if (isEntityUpdated[index] === null)
                  return put(
                    unitsActions.startFetchingUnits({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    unitsActions.startPartialFetchingUnits(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_SUBDIVISONS:
                if (isEntityUpdated[index] === null)
                  return put(
                    subdivisionsActions.startFetchingSubdivisions({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    subdivisionsActions.startPartialFetchingSubdivisions(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_MAINTENANCE_LINES:
                if (isEntityUpdated[index] === null)
                  return put(
                    maintenanceLinesActions.startFetchingMaintenanceLines({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    maintenanceLinesActions.startPartialFetchingMaintenanceLines(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_VEHICLES:
                if (isEntityUpdated[index] === null)
                  return put(
                    vehicleActions.startFetchingVehicles({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    vehicleActions.startPartialFetchingVehicles(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_MODULES:
                if (isEntityUpdated[index] === null)
                  return put(
                    modulesActions.startFetchingModules({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId,
                      allowNullOrganizationId: entity.allowNullOrganizationId
                    })
                  );
                else
                  return put(
                    modulesActions.startPartialFetchingModules(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId,
                      entity.allowNullOrganizationId
                    )
                  );
              case entityTypes.ENTITY_AGENTS:
                if (isEntityUpdated[index] === null) return put(agentActions.startFetchingAgents());
                else return put(agentActions.startPartialFetchingAgents(parseInt(entityTypeId)));
              case entityTypes.ENTITY_SERVICE_CLASSES:
                if (isEntityUpdated[index] === null)
                  return put(
                    serviceClassesActions.startFetchingServiceClasses({}, entity.isDropdown)
                  );
                else
                  return put(
                    serviceClassesActions.startPartialFetchingServiceClasses(parseInt(entityTypeId))
                  );
              case entityTypes.ENTITY_OPERATORS:
                if (isEntityUpdated[index] === null)
                  return put(operatorsActions.startFetchingOperators());
                else
                  return put(
                    operatorsActions.startPartialFetchingOperators(parseInt(entityTypeId))
                  );
              case entityTypes.ENTITY_DIVISIONS:
                if (isEntityUpdated[index] === null)
                  return put(
                    divisionsActions.startFetchingDivisions({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    divisionsActions.startPartialFetchingDivisions(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_FORMS:
                if (isEntityUpdated[index] === null) return put(formsActions.startFetchingForms());
                else return put(formsActions.startPartialFetchingForms(parseInt(entityTypeId)));
              case entityTypes.ENTITY_ORGANIZATIONS:
                if (isEntityUpdated[index] === null)
                  return put(organizationsActions.startFetchingOrganizations());
                else
                  return put(
                    organizationsActions.startPartialFetchingOrganizations(parseInt(entityTypeId))
                  );
              case entityTypes.ENTITY_USERS:
                if (isEntityUpdated[index] === null)
                  return put(
                    monitoringUsers.startFetchingMonitoringUsers({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    monitoringUsers.startPartialFetchingMonitoringUsers(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_ORGANIZATION_CONTRACTS:
                if (isEntityUpdated[index] === null)
                  return put(
                    organizationContractsActions.startFetchingOrganizationContracts({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    organizationContractsActions.startPartialFetchingOrganizationContracts(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId,
                      entity.allowNullOrganizationId
                    )
                  );
              case entityTypes.ENTITY_CONTRACT_TEMPLATES:
                // console.log('Se actualiza plantillas de contratos');
                if (isEntityUpdated[index] === null)
                  return put(
                    contractTemplatesActions.startFetchingContractTemplates({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId,
                      allowNullOrganizationId: entity.allowNullOrganizationId
                    })
                  );
                else
                  return put(
                    contractTemplatesActions.startPartialFetchingContractTemplates(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_CUSTOMERS:
                if (isEntityUpdated[index] === null)
                  return put(
                    customersActions.startFetchingCustomers({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    customersActions.startPartialFetchingCustomers(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_CONTACTS:
                if (isEntityUpdated[index] === null)
                  return put(contactsActions.startFetchingContacts());
                else
                  return put(contactsActions.startPartialFetchingContacts(parseInt(entityTypeId)));
              case entityTypes.ENTITY_WORKPLANS:
                if (isEntityUpdated[index] === null)
                  return put(
                    workplansActions.startFetchingWorkplans({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    workplansActions.startPartialFetchingWorkplans(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_GEOFENCES:
                if (isEntityUpdated[index] === null)
                  return put(
                    geofencesActions.startFetchingGeofences({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    geofencesActions.startPartialFetchingGeofences(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_SCHEDULES:
                if (isEntityUpdated[index] === null)
                  return put(schedulesActions.startFetchingSchedules());
                else
                  return put(
                    schedulesActions.startPartialFetchingSchedules(parseInt(entityTypeId))
                  );
              case entityTypes.ENTITY_ROUTES:
                if (isEntityUpdated[index] === null)
                  return put(
                    routesActions.startFetchingRoutes({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    routesActions.startPartialFetchingRoutes(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_SIM_CARDS:
                if (isEntityUpdated[index] === null)
                  return put(
                    simActions.startFetchingSims({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId,
                      allowNullOrganizationId: entity.allowNullOrganizationId
                    })
                  );
                else
                  return put(
                    simActions.startPartialFetchingSims(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId,
                      null,
                      null,
                      entity.allowNullOrganizationId
                    )
                  );
              case entityTypes.ENTITY_COUNTRIES:
                if (isEntityUpdated[index] === null)
                  return put(countriesActions.startFetchingCountries());
                else
                  return put(
                    countriesActions.startPartialFetchingCountries(parseInt(entityTypeId))
                  );
              case entityTypes.ENTITY_LOCATIONS:
                if (isEntityUpdated[index] === null)
                  return put(
                    locationActions.startFetchingLocations({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    locationActions.startPartialFetchingLocations(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_OFFICES:
                if (isEntityUpdated[index] === null)
                  return put(
                    officeActions.startFetchingOffices({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    officeActions.startPartialFetchingOffices(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_DRIVERS:
                if (isEntityUpdated[index] === null)
                  return put(
                    driverActions.startFetchingDrivers({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    driverActions.startPartialFetchingDrivers(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_ASSETS:
                if (isEntityUpdated[index] === null)
                  return put(
                    assetsActions.startFetchingAssets({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    assetsActions.startPartialFetchingAssets(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_SENSORS:
                if (isEntityUpdated[index] === null)
                  return put(sensorsActions.startFetchingSensors());
                else return put(sensorsActions.startPartialFetchingSensors(parseInt(entityTypeId)));
              case entityTypes.ENTITY_JOBS_BACKLOG:
                if (isEntityUpdated[index] === null)
                  return put(jobActions.startFetchingJobsBacklog());
                else return put(jobActions.startPartialFetchingJobsBacklog(parseInt(entityTypeId)));
              case entityTypes.ENTITY_GROUPS:
                if (isEntityUpdated[index] === null)
                  return put(
                    groupsActions.startFetchingGroups({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    groupsActions.startPartialFetchingGroups(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              case entityTypes.ENTITY_PROTOCOLS:
                if (isEntityUpdated[index] === null)
                  return put(
                    protocolsActions.startFetchingProtocols({
                      operatorId: entity.operatorId,
                      organizationId: entity.organizationId
                    })
                  );
                else
                  return put(
                    protocolsActions.startPartialFetchingProtocols(
                      parseInt(entityTypeId),
                      entity.operatorId,
                      entity.organizationId
                    )
                  );
              default:
                break;
            }
          }
        })
      );
      yield put(actions.completeUpdatingEntities());
    } catch (err) {
      const error = {
        errorMessage: err?.toString(),
        errorNumber: -1
      };
      // alerts.showErrorAlertFailChanges({ error });
      yield put(actions.failUpdatingEntities(error));
    }
  }
}

export function* watchUpdateEntities() {
  yield takeEvery(types.ENTITIES_UPDATE_STARTED, updateEntities);
}
