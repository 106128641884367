import * as types from '../../types/routes';

export const startFetchingRoutes = (filters = {}) => ({
  type: types.ROUTES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingRoutes = (entities, order, operatorId, organizationId) => ({
  type: types.ROUTES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingRoutes = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingRoutes = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.ROUTES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingRoutes = (entities, order, operatorId, organizationId) => ({
  type: types.ROUTES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingRoutes = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingRoute = (route) => ({
  type: types.ROUTE_ADD_STARTED,
  payload: route
});
export const completeAddingRoute = (oldId, routeInformation) => ({
  type: types.ROUTE_ADD_COMPLETED,
  payload: { oldId, routeInformation }
});
export const failAddingRoute = (oldId, error) => ({
  type: types.ROUTE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingRoute = (route) => ({
  type: types.ROUTE_EDIT_STARTED,
  payload: route
});
export const completeEditingRoute = (routeInformation) => ({
  type: types.ROUTE_EDIT_COMPLETED,
  payload: routeInformation
});
export const failEditingRoute = (oldRoute, error) => ({
  type: types.ROUTE_EDIT_FAILED,
  payload: {
    oldRoute,
    error
  }
});

export const startRemovingRoute = (routeId) => ({
  type: types.ROUTE_REMOVE_STARTED,
  payload: {
    routeId
  }
});
export const completeRemovingRoute = (routeId) => ({
  type: types.ROUTE_REMOVE_COMPLETED,
  payload: {
    routeId
  }
});
export const failRemovingRoute = (routeId, error) => ({
  type: types.ROUTE_REMOVE_FAILED,
  payload: {
    routeId,
    error
  }
});

export const startRemovingRoutes = (routeIds) => ({
  type: types.ROUTES_REMOVE_STARTED,
  payload: {
    routeIds
  }
});
export const completeRemovingRoutes = (routeIds) => ({
  type: types.ROUTES_REMOVE_COMPLETED,
  payload: {
    routeIds
  }
});
export const failRemovingRoutes = (routeIds, error) => ({
  type: types.ROUTES_REMOVE_FAILED,
  payload: {
    routeIds,
    error
  }
});

export const selectRoute = (routeId) => ({
  type: types.ROUTE_SELECTED,
  payload: routeId
});

export const selectAllRoutes = (
  routeIds: any[] = [] //List of RouteIds
) => ({
  type: types.ROUTES_ALL_SELECTED,
  payload: routeIds
});

export const deselectRoute = (routeId) => ({
  type: types.ROUTE_DESELECTED,
  payload: routeId
});

export const deselectAllRoutes = (
  routeIds: any[] = [] //List of RouteIds
) => ({
  type: types.ROUTES_ALL_DESELECTED,
  payload: routeIds
});

export const startCopyRoute = (
  routeId,
  newRouteName,
  organizationId,
  divisionId,
  subdivisionId
) => ({
  type: types.ROUTE_COPY_STARTED,
  payload: { routeId, newRouteName, organizationId, divisionId, subdivisionId }
});
export const completeCopyRoute = (
  routeId,
  newRouteId,
  newRouteName,
  organizationId,
  divisionId,
  subdivisionId
) => ({
  type: types.ROUTE_COPY_COMPLETED,
  payload: { routeId, newRouteId, newRouteName, organizationId, divisionId, subdivisionId }
});
export const failCopyRoute = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTE_COPY_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const showRouteInMap = (routeId) => ({
  type: types.ROUTE_SHOW_IN_MAP,
  payload: routeId
});

export const showAllRoutesInMap = (
  routeIds: any[] = [] //List of RouteIds
) => ({
  type: types.ROUTES_ALL_SHOW_IN_MAP,
  payload: routeIds
});

export const hideRouteInMap = (routeId) => ({
  type: types.ROUTE_HIDE_IN_MAP,
  payload: routeId
});

export const hideAllRoutesInMap = (
  routeIds: any[] = [] //List of RouteIds
) => ({
  type: types.ROUTES_ALL_HIDE_IN_MAP,
  payload: routeIds
});
