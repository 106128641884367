export const OFFICES_FETCH_STARTED = 'OFFICES_FETCH_STARTED';
export const OFFICES_FETCH_COMPLETED = 'OFFICES_FETCH_COMPLETED';
export const OFFICES_FETCH_FAILED = 'OFFICES_FETCH_FAILED';

export const OFFICES_PARTIAL_FETCH_STARTED = 'OFFICES_PARTIAL_FETCH_STARTED';
export const OFFICES_PARTIAL_FETCH_COMPLETED = 'OFFICES_PARTIAL_FETCH_COMPLETED';
export const OFFICES_PARTIAL_FETCH_FAILED = 'OFFICES_PARTIAL_FETCH_FAILED';

export const OFFICE_ADD_STARTED = 'OFFICE_ADD_STARTED';
export const OFFICE_ADD_COMPLETED = 'OFFICE_ADD_COMPLETED';
export const OFFICE_ADD_FAILED = 'OFFICE_ADD_FAILED';

export const OFFICE_EDIT_STARTED = 'OFFICE_EDIT_STARTED';
export const OFFICE_EDIT_COMPLETED = 'OFFICE_EDIT_COMPLETED';
export const OFFICE_EDIT_FAILED = 'OFFICE_EDIT_FAILED';

export const OFFICE_REMOVE_STARTED = 'OFFICE_REMOVE_STARTED';
export const OFFICE_REMOVE_COMPLETED = 'OFFICE_REMOVE_COMPLETED';
export const OFFICE_REMOVE_FAILED = 'OFFICE_REMOVE_FAILED';

export const OFFICE_SELECTED = 'OFFICE_SELECTED';
export const OFFICES_ALL_SELECTED = 'OFFICES_ALL_SELECTED';
export const OFFICE_DESELECTED = 'OFFICE_DESELECTED';
export const OFFICES_ALL_DESELECTED = 'OFFICES_ALL_DESELECTED';
