import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../../types/static-catalogs/vehicle-makers';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.VEHICLE_MAKERS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((vehicleMakerId) => {
        newState[vehicleMakerId] = {
          isSelected: false,
          ...entities[vehicleMakerId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.VEHICLE_MAKERS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLE_MAKERS_FETCH_STARTED: {
      return true;
    }
    case types.VEHICLE_MAKERS_FETCH_COMPLETED:
    case types.VEHICLE_MAKERS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLE_MAKERS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLE_MAKERS_FETCH_STARTED:
    case types.VEHICLE_MAKERS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const vehicleMakers = combineReducers({
  byId,
  order,
  isFetchingList,
  errorFetchingList
});

export default vehicleMakers;

//Own state
export const getOwnState = (state) => state.staticCatalogs.vehicleMakers;

//Information
export const getVehicleMaker = (state, vehicleMakerId) => getOwnState(state).byId[vehicleMakerId];
export const getVehicleMakersList = (state) =>
  getOwnState(state).order.map((id) => getVehicleMaker(state, id));
export const getSelectedVehicleMakers = (state) => {
  const selectedVehicleMakers = getVehicleMakersList(state).filter(
    (vehicleMaker) => vehicleMaker.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedVehicleMakers.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedVehicleMakers.length > 0) return selectedVehicleMakers;
};

export const getSelectedVehicleMaker = (state) => {
  const selectedVehicleMakers = getVehicleMakersList(state).filter(
    (vehicleMaker) => vehicleMaker.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedVehicleMakers.length === 1) return selectedVehicleMakers[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingVehicleMaker = (state) => getOwnState(state).isFetching;
export const isFetchingListVehicleMakers = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingVehicleMakerErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListVehicleMakersErrors = (state) => getOwnState(state).errorFetchingList;
