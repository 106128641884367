import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { deviceActionsEntities, deviceActionsOrder } = action.payload;
      const newState = { ...state };
      deviceActionsOrder.forEach((deviceActionId) => {
        newState[deviceActionId] = {
          ...state[deviceActionId],
          ...deviceActionsEntities[deviceActionId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { deviceActionsOrder } = action.payload;
      return uniq([...state, ...deviceActionsOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const deviceActions = combineReducers({
  byId,
  order
});

export default deviceActions;

//Own state
export const getOwnState = (state) => state.units.deviceActions;

//Information
export const getDeviceAction = (state, deviceActionId) => {
  const deviceAction = getOwnState(state).byId[deviceActionId];
  if (deviceAction) {
    return {
      ...deviceAction
    };
  } else return undefined;
};
export const getDeviceActionsList = (state) =>
  getOwnState(state).order.map((id) => getDeviceAction(state, id));
