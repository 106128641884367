import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import flattenDeep from 'lodash/flattenDeep';
import { DateTime } from 'luxon';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

import * as types from '../types';
import * as geofenceSelectors from '../../Geofences/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SCHEDULES_FETCH_COMPLETED:
    case types.SCHEDULES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((scheduleId) => {
        newState[scheduleId] = {
          isSelected: false,
          ...state[scheduleId],
          ...entities[scheduleId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SCHEDULE_SHIFT_ADD_COMPLETED:
    case types.SCHEDULE_ADD_COMPLETED: {
      const { oldId, schedule } = action.payload;
      const newState = omit(state, oldId);
      newState[schedule.scheduleId] = {
        ...state[oldId],
        ...schedule,
        isConfirmed: true
      };
      return newState;
    }

    case types.SCHEDULE_SHIFT_EDIT_COMPLETED:
    case types.SCHEDULE_EDIT_COMPLETED: {
      const schedule = action.payload;
      return {
        ...state,
        [schedule.scheduleId]: {
          ...omit(state[schedule.scheduleId], ['oldSchedule']),
          ...omit(schedule, ['oldSchedule']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.SCHEDULE_SHIFT_ADD_STARTED:
    case types.SCHEDULE_ADD_STARTED: {
      const schedule = action.payload;
      const newState = { ...state };
      newState[schedule.scheduleId] = {
        isSelected: false,
        ...schedule,
        isConfirmed: false
      };
      return newState;
    }
    case types.SCHEDULE_SHIFT_EDIT_STARTED:
    case types.SCHEDULE_EDIT_STARTED: {
      const schedule = action.payload;
      return {
        ...state,
        [schedule.scheduleId]: {
          ...state[schedule.scheduleId],
          oldSchedule: state[schedule.scheduleId],
          ...schedule,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.SCHEDULE_SHIFT_ADD_FAILED:
    case types.SCHEDULE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.SCHEDULE_SHIFT_EDIT_FAILED:
    case types.SCHEDULE_EDIT_FAILED: {
      const { oldSchedule } = action.payload;
      return {
        ...state,
        [oldSchedule.scheduleId]: {
          ...omit(state[oldSchedule.scheduleId], ['oldSchedule']),
          ...oldSchedule,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SCHEDULE_REMOVE_COMPLETED: {
      const { scheduleId } = action.payload;
      return omit(state, scheduleId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.SCHEDULE_SELECTED: {
      const scheduleId = action.payload;
      const newState = {
        ...state,
        [scheduleId]: {
          ...state[scheduleId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.SCHEDULE_DESELECTED: {
      const scheduleId = action.payload;
      return {
        ...state,
        [scheduleId]: {
          ...state[scheduleId],
          isSelected: false
        }
      };
    }

    case types.SCHEDULES_ALL_SELECTED: {
      const scheduleIds = action.payload;
      const newState = { ...state };
      if (scheduleIds.length == 0) {
        forEach(state, (schedule: any, scheduleId) => {
          newState[scheduleId] = {
            ...schedule,
            isSelected: true
          };
        });
      } else {
        scheduleIds.forEach((scheduleId) => {
          newState[scheduleId] = {
            ...state[scheduleId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.SCHEDULES_ALL_DESELECTED: {
      const scheduleIds = action.payload;
      const newState = { ...state };
      if (scheduleIds.length == 0) {
        forEach(state, (schedule: any, scheduleId) => {
          newState[scheduleId] = {
            ...schedule,
            isSelected: false
          };
        });
      } else {
        scheduleIds.forEach((scheduleId) => {
          newState[scheduleId] = {
            ...state[scheduleId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SCHEDULES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.SCHEDULES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.SCHEDULE_SHIFT_ADD_COMPLETED:
    case types.SCHEDULE_ADD_COMPLETED: {
      const { oldId, schedule } = action.payload;
      return state.map((scheduleId) => (scheduleId === oldId ? schedule.scheduleId : scheduleId));
    }

    case types.SCHEDULE_REMOVE_COMPLETED: {
      const { scheduleId } = action.payload;
      return state.filter((scheduleIdState) => scheduleIdState !== scheduleId);
    }

    //CASE ADD STARTED
    case types.SCHEDULE_SHIFT_ADD_STARTED:
    case types.SCHEDULE_ADD_STARTED: {
      const schedule = action.payload;
      return [...state, schedule.scheduleId];
    }

    //CASE ADD FAILED
    case types.SCHEDULE_SHIFT_ADD_FAILED:
    case types.SCHEDULE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((scheduleIdState) => scheduleIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SCHEDULES_FETCH_STARTED: {
      return true;
    }
    case types.SCHEDULES_FETCH_COMPLETED:
    case types.SCHEDULES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SCHEDULES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.SCHEDULES_PARTIAL_FETCH_COMPLETED:
    case types.SCHEDULES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SCHEDULE_ADD_STARTED: {
      return true;
    }
    case types.SCHEDULE_ADD_COMPLETED:
    case types.SCHEDULE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SCHEDULE_EDIT_STARTED: {
      return true;
    }
    case types.SCHEDULE_EDIT_COMPLETED:
    case types.SCHEDULE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SCHEDULE_REMOVE_STARTED: {
      return true;
    }
    case types.SCHEDULE_REMOVE_COMPLETED:
    case types.SCHEDULE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SCHEDULES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SCHEDULES_FETCH_STARTED:
    case types.SCHEDULES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SCHEDULES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SCHEDULES_PARTIAL_FETCH_STARTED:
    case types.SCHEDULES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SCHEDULE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SCHEDULE_ADD_STARTED:
    case types.SCHEDULE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SCHEDULE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SCHEDULE_EDIT_STARTED:
    case types.SCHEDULE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SCHEDULE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SCHEDULE_REMOVE_STARTED:
    case types.SCHEDULE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const schedules = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default schedules;

//Own state
export const getOwnState = (state) => state.schedules;

//Information
export const getSchedule = (state, scheduleId) => {
  const intl = appIntl();
  const schedule = getOwnState(state).byId[scheduleId];
  if (schedule) {
    const originGeofence = schedule.originGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.originGeofenceId)
      : undefined;
    const workGeofence = schedule.workGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.workGeofenceId)
      : undefined;
    const destinationGeofence = schedule.destinationGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.destinationGeofenceId)
      : undefined;
    const organization = schedule.organizationId
      ? organizationSelectors.getOrganization(state, schedule.organizationId)
      : undefined;
    const division = schedule.divisionId
      ? divisionSelectors.getDivision(state, schedule.divisionId)
      : undefined;
    const subdivision = schedule.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, schedule.subdivisionId)
      : undefined;
    return {
      ...schedule,
      originGeofenceName: originGeofence?.geofenceName || '',
      workGeofenceName: workGeofence?.geofenceName || '',
      destinationGeofenceName: destinationGeofence?.geofenceName || '',
      organizationName: organization?.organizationName || '',
      divisionName: division?.divisionName || '',
      subdivisionName: subdivision?.subdivisionName || '',
      comments: schedule.comments || '',
      checkSequenceLabel: intl.formatMessage({
        id: schedule.checkSequence ? 'common.yes' : 'common.no'
      })
    };
  } else return undefined;
};

export const getScheduleByPrivateCode = (state, privateCode) => {
  const intl = appIntl();
  const schedules = getSchedulesList(state);
  const schedule = schedules.find((schedule) => schedule.privateCode === privateCode);
  if (schedule) {
    const originGeofence = schedule.originGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.originGeofenceId)
      : undefined;
    const workGeofence = schedule.workGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.workGeofenceId)
      : undefined;
    const destinationGeofence = schedule.destinationGeofenceId
      ? geofenceSelectors.getGeofence(state, schedule.destinationGeofenceId)
      : undefined;
    const organization = schedule.organizationId
      ? organizationSelectors.getOrganization(state, schedule.organizationId)
      : undefined;
    const division = schedule.divisionId
      ? divisionSelectors.getDivision(state, schedule.divisionId)
      : undefined;
    const subdivision = schedule.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, schedule.subdivisionId)
      : undefined;
    return {
      ...schedule,
      originGeofenceName: originGeofence?.geofenceName || '',
      workGeofenceName: workGeofence?.geofenceName || '',
      destinationGeofenceName: destinationGeofence?.geofenceName || '',
      organizationName: organization?.organizationName || '',
      divisionName: division?.divisionName || '',
      subdivisionName: subdivision?.subdivisionName || '',
      comments: schedule.comments || '',
      checkSequenceLabel: intl.formatMessage({
        id: schedule.checkSequence ? 'common.yes' : 'common.no'
      })
    };
  } else return undefined;
};

export const getScheduleByPrivateCodeByMembership = (
  state,
  privateCode: string,
  membership: DefaultFilters
) => {
  const intl = appIntl();
  const schedules = getScheduleListByMembership(state, membership);
  const schedule = schedules.find((schedule) => schedule.privateCode === privateCode);

  if (!schedule) return undefined;

  const originGeofence = schedule.originGeofenceId
    ? geofenceSelectors.getGeofence(state, schedule.originGeofenceId)
    : undefined;
  const workGeofence = schedule.workGeofenceId
    ? geofenceSelectors.getGeofence(state, schedule.workGeofenceId)
    : undefined;
  const destinationGeofence = schedule.destinationGeofenceId
    ? geofenceSelectors.getGeofence(state, schedule.destinationGeofenceId)
    : undefined;
  const organization = schedule.organizationId
    ? organizationSelectors.getOrganization(state, schedule.organizationId)
    : undefined;
  const division = schedule.divisionId
    ? divisionSelectors.getDivision(state, schedule.divisionId)
    : undefined;
  const subdivision = schedule.subdivisionId
    ? subdivisionSelectors.getSubdivision(state, schedule.subdivisionId)
    : undefined;
  return {
    ...schedule,
    originGeofenceName: originGeofence?.geofenceName || '',
    workGeofenceName: workGeofence?.geofenceName || '',
    destinationGeofenceName: destinationGeofence?.geofenceName || '',
    organizationName: organization?.organizationName || '',
    divisionName: division?.divisionName || '',
    subdivisionName: subdivision?.subdivisionName || '',
    comments: schedule.comments || '',
    checkSequenceLabel: intl.formatMessage({
      id: schedule.checkSequence ? 'common.yes' : 'common.no'
    })
  };
};

export const getSchedulesList = (state) =>
  getOwnState(state)
    .order.map((id) => getSchedule(state, id))
    .filter((schedule) => schedule.scheduleStatus > 0);

export const getScheduleListByMembership = (state, membership: DefaultFilters) =>
  getSchedulesList(state).filter(
    (schedule) =>
      membership &&
      (!membership.operatorId || membership.operatorId === schedule.operatorId) &&
      (!membership.organizationId || membership.organizationId === schedule.organizationId) &&
      (!membership.divisionId || membership.divisionId === schedule.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === schedule.subdivisionId)
  );

export const getSelectedSchedules = (state) => {
  const selectedSchedules = getSchedulesList(state).filter((schedule) => schedule.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedSchedules.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedSchedules.length > 0) return selectedSchedules;
};
export const getTriggersList = (state) =>
  getSchedulesList(state).filter((schedule) => schedule.scheduleType === 1);
export const getTriggersListByMembership = (state, membership) =>
  getTriggersList(state).filter(
    (schedule) =>
      membership &&
      (!membership.operatorId || membership.operatorId === schedule.operatorId) &&
      (!membership.organizationId || membership.organizationId === schedule.organizationId) &&
      (!membership.divisionId || membership.divisionId === schedule.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === schedule.subdivisionId)
  );

export const getShiftsList = (state) =>
  getSchedulesList(state).filter((schedule) => schedule.scheduleType === 2);

export const getShiftsListByMembership = (state, membership) =>
  getShiftsList(state).filter(
    (schedule) =>
      membership &&
      (!membership.operatorId || membership.operatorId === schedule.operatorId) &&
      (!membership.organizationId || membership.organizationId === schedule.organizationId) &&
      (!membership.divisionId || membership.divisionId === schedule.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === schedule.subdivisionId)
  );

export const getShiftListByMembershipDetail = (state, membership) =>
  getShiftsListByMembership(state, membership).map((shift) => {
    const shiftTimes = flattenDeep(
      ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map(
        (day, index) =>
          shift[day]
            ?.split(' | ')
            .map((time) => ({
              day: index,
              startTime: time.split('-')[0],
              endTime: time.split('-')[1]
            }))
            .filter((day) => day.endTime)
      )
    );
    return { ...shift, shiftTimes };
  });

export const getSelectedSchedule = (state) => {
  const selectedSchedules = getSchedulesList(state).filter((schedule) => schedule.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedSchedules.length === 1) return selectedSchedules[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListSchedules = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListSchedules = (state) => getOwnState(state).isFetchingList;
export const isAddingSchedule = (state) => getOwnState(state).isAdding;
export const isEditingSchedule = (state) => getOwnState(state).isEditing;
export const isRemovingSchedule = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListSchedulesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListSchedulesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingScheduleErrors = (state) => getOwnState(state).errorAdding;
export const getEditingScheduleErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingScheduleErrors = (state) => getOwnState(state).errorRemoving;
