import * as types from '../types';

export const startFetchingModules = (filters = {}) => ({
  type: types.MODULES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingModules = (entities, order, operatorId, organizationId) => ({
  type: types.MODULES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingModules = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.MODULES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingModules = (
  entityTypeId,
  operatorId = null,
  organizationId = null,
  allowNullOrganizationId
) => ({
  type: types.MODULES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId, allowNullOrganizationId }
});
export const completePartialFetchingModules = (entities, order, operatorId, organizationId) => ({
  type: types.MODULES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingModules = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.MODULES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingModule = (moduleId) => ({
  type: types.MODULE_FETCH_STARTED,
  payload: moduleId
});

export const completeFetchingModule = (module) => ({
  type: types.MODULE_FETCH_COMPLETED,
  payload: module
});

export const failFetchingModule = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.MODULE_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingModule = (module) => ({
  type: types.MODULE_ADD_STARTED,
  payload: module
});
export const completeAddingModule = (oldId, module) => ({
  type: types.MODULE_ADD_COMPLETED,
  payload: { oldId, module }
});
export const failAddingModule = (oldId, error) => ({
  type: types.MODULE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingModule = (module) => ({
  type: types.MODULE_EDIT_STARTED,
  payload: module
});
export const completeEditingModule = (module) => ({
  type: types.MODULE_EDIT_COMPLETED,
  payload: module
});
export const failEditingModule = (oldModule, error) => ({
  type: types.MODULE_EDIT_FAILED,
  payload: {
    oldModule,
    error
  }
});

export const startRemovingModule = (moduleId) => ({
  type: types.MODULE_REMOVE_STARTED,
  payload: {
    moduleId
  }
});
export const completeRemovingModule = (moduleId) => ({
  type: types.MODULE_REMOVE_COMPLETED,
  payload: {
    moduleId
  }
});
export const failRemovingModule = (moduleId, error) => ({
  type: types.MODULE_REMOVE_FAILED,
  payload: {
    moduleId,
    error
  }
});

export const selectModule = (moduleId) => ({
  type: types.MODULE_SELECTED,
  payload: moduleId
});

export const selectAllModules = (
  moduleIds = [] //List of ModuleIds
) => ({
  type: types.MODULES_ALL_SELECTED,
  payload: moduleIds
});

export const deselectModule = (moduleId) => ({
  type: types.MODULE_DESELECTED,
  payload: moduleId
});

export const deselectAllModules = (
  moduleIds = [] //List of ModuleIds
) => ({
  type: types.MODULES_ALL_DESELECTED,
  payload: moduleIds
});

export const dirtyModule = (isDirty) => ({
  type: types.MODULE_DIRTY,
  payload: isDirty
});
