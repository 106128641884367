const paths = {
  //Paths Catalogs
  sparepartsCatalog: '/vehicleMaintenance/catalog/sparepart',
  workshopCatalog: '/vehicleMaintenance/catalog/workshop',
  categoriesCatalog: '/vehicleMaintenance/catalog/categories',
  providersCatalog: '/vehicleMaintenance/catalog/providers',

  //reportes
  generalReport: '/vehicleMaintenance/report/general',
  byCategoryReport: '/vehicleMaintenance/report/byCategory',
  byUnitReport: '/vehicleMaintenance/report/byUnit',
  byWorkShopReport: '/vehicleMaintenance/report/byWorkshop',

  //tickets
  importTicket: '/vehicleMaintenance/ticket/import',

  //preventive notices
  preventiveNotices: '/vehicleMaintenance/preventiveNotices',

  //maintenanceLines
  maintenanceLineList: '/vehicleMaintenance/maintenanceLines/list',
  maintenanceLineEdit: '/vehicleMaintenance/maintenanceLines/edit',

  // workshop orders
  addWorkshopOrders: '/vehicleMaintenance/workshopOrders/add'
};

export default paths;
