import * as types from '../types';

export const startFetchingForms = (filters = {}) => ({
  type: types.FORMS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingForms = (entities, order) => ({
  type: types.FORMS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingForms = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.FORMS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingForms = (filters = {}) => ({
  type: types.FORMS_PARTIAL_FETCH_STARTED,
  payload: filters
});
export const completePartialFetchingForms = (entities, order) => ({
  type: types.FORMS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingForms = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.FORMS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingFormSections = (formId) => ({
  type: types.FORM_SECTIONS_FETCH_STARTED,
  payload: formId
});

export const completeFetchingFormSections = (entities, order, formId) => ({
  type: types.FORM_SECTIONS_FETCH_COMPLETED,
  payload: { entities, order, formId }
});

export const failFetchingFormSections = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.FORM_SECTIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingFormQuestions = (formId) => ({
  type: types.FORM_QUESTIONS_FETCH_STARTED,
  payload: formId
});

export const completeFetchingFormQuestions = (entities, order, formId) => ({
  type: types.FORM_QUESTIONS_FETCH_COMPLETED,
  payload: { entities, order, formId }
});

export const failFetchingFormQuestions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.FORM_QUESTIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingForm = (
  formName: string,
  formDescription: string,
  organizationFormCategoryId: number,
  useBarCodes: boolean = false,
  requireSignature: boolean = false,
  copyToCustomer: boolean = false,
  formTypeId: number,
  organizationId: number,
  sections: any[],
  formUrl: string = '',
  organizationFormId?: number,
  divisionId?: number,
  subdivisionId?: number,
  notificationEmails?: string
) => ({
  type: types.FORM_ADD_STARTED,
  payload: {
    organizationFormId,
    organizationId,
    divisionId,
    subdivisionId,
    formName,
    formDescription,
    notificationEmails,
    organizationFormCategoryId,
    formUrl,
    useBarCodes,
    requireSignature,
    copyToCustomer,
    formTypeId,
    sections
  }
});
export const completeAddingForm = (oldId, form) => ({
  type: types.FORM_ADD_COMPLETED,
  payload: { oldId, form }
});
export const failAddingForm = (oldId, error) => ({
  type: types.FORM_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startAddingExternal = (
  formName: string,
  formDescription: string,
  organizationFormCategoryId: number,
  useBarCodes: boolean = false,
  requireSignature: boolean = false,
  copyToCustomer: boolean,
  formTypeId: number,
  organizationId: number,
  sections?: any[],
  formUrl: string = '',
  organizationFormId?: any,
  divisionId?: number,
  subdivisionId?: number,
  notificationEmails?: string
) => ({
  type: types.FORM_EXTERNAL_ADD_STARTED,
  payload: {
    organizationFormId,
    organizationId,
    divisionId,
    subdivisionId,
    formName,
    formDescription,
    notificationEmails,
    organizationFormCategoryId,
    formUrl,
    useBarCodes,
    requireSignature,
    copyToCustomer,
    formTypeId,
    sections
  }
});

export const startEditingExternal = (
  formName: string,
  formDescription: string,
  organizationFormCategoryId: number,
  useBarCodes: boolean = false,
  requireSignature: boolean = false,
  copyToCustomer: boolean,
  formTypeId: number,
  organizationId: number,
  sections?: any[],
  formUrl: string = '',
  organizationFormId?: number,
  divisionId?: number,
  subdivisionId?: number,
  notificationEmails?: string
) => ({
  type: types.FORM_EXTERNAL_EDIT_STARTED,
  payload: {
    organizationFormId,
    organizationId,
    divisionId,
    subdivisionId,
    formName,
    formDescription,
    notificationEmails,
    organizationFormCategoryId,
    formUrl,
    useBarCodes,
    requireSignature,
    copyToCustomer,
    formTypeId,
    sections
  }
});

export const startEditingForm = (
  formName: string,
  formDescription: string,
  organizationFormCategoryId: number,
  useBarCodes: boolean = false,
  requireSignature: boolean = false,
  copyToCustomer: boolean = false,
  formTypeId: number,
  organizationId: number,
  sections: any[],
  formUrl: string = '',
  organizationFormId?: number,
  divisionId?: number,
  subdivisionId?: number,
  notificationEmails?: string
) => ({
  type: types.FORM_EDIT_STARTED,
  payload: {
    organizationFormId,
    organizationId,
    divisionId,
    subdivisionId,
    formName,
    formDescription,
    notificationEmails,
    organizationFormCategoryId,
    formUrl,
    useBarCodes,
    requireSignature,
    copyToCustomer,
    formTypeId,
    sections
  }
});
export const completeEditingForm = (form) => ({
  type: types.FORM_EDIT_COMPLETED,
  payload: form
});
export const failEditingForm = (oldForm, error) => ({
  type: types.FORM_EDIT_FAILED,
  payload: {
    oldForm,
    error
  }
});

export const startAddingSection = (sections: any[]) => ({
  type: types.FORM_SECTION_ADD_STARTED,
  payload: { sections }
});

export const startRemovingForm = (formId) => ({
  type: types.FORM_REMOVE_STARTED,
  payload: {
    formId
  }
});
export const completeRemovingForm = (formId) => ({
  type: types.FORM_REMOVE_COMPLETED,
  payload: {
    formId
  }
});
export const failRemovingForm = (formId, error) => ({
  type: types.FORM_REMOVE_FAILED,
  payload: {
    formId,
    error
  }
});

export const selectForm = (formId) => ({
  type: types.FORM_SELECTED,
  payload: formId
});

export const selectAllForms = (
  formIds = [] //List of FormIds
) => ({
  type: types.FORMS_ALL_SELECTED,
  payload: formIds
});

export const deselectForm = (formId) => ({
  type: types.FORM_DESELECTED,
  payload: formId
});

export const deselectAllForms = (
  formIds = [] //List of FormIds
) => ({
  type: types.FORMS_ALL_DESELECTED,
  payload: formIds
});

export const startAddingResponse = (
  organizationFormId,
  responses,
  tableResponses,
  responseDate
) => ({
  type: types.FORM_RESPONSE_SAVE_STARTED,
  payload: {
    organizationFormId,
    responses,
    tableResponses,
    responseDate
  }
});

export const completeAddingResponse = () => ({
  type: types.FORM_RESPONSE_SAVE_COMPLETED,
  payload: {}
});

export const failAddingResponse = () => ({
  type: types.FORM_RESPONSE_SAVE_FAILED,
  payload: {}
});

export const addSection = (sectionType, position, sectionId) => ({
  type: types.FORM_SECTION_ADDED,
  payload: { sectionType, position, sectionId }
});

export const changeSectionCollapsed = (sectionId) => ({
  type: types.FORM_SECTION_CHANGE_COLLAPSED,
  payload: { sectionId }
});

export const moveSection = (startingPosition, endingPosition) => ({
  type: types.FORM_SECTION_MOVED,
  payload: { startingPosition, endingPosition }
});

export const editSectionName = (sectionId, sectionName) => ({
  type: types.FORM_SECTION_NAME_EDITED,
  payload: { sectionId, sectionName }
});

export const deleteSection = (sectionId) => ({
  type: types.FORM_SECTION_DELETED,
  payload: sectionId
});

export const copySection = (sectionId, newSectionId, newSection) => ({
  type: types.FORM_SECTION_COPIED,
  payload: { sectionId, newSectionId, newSection }
});

export const setSections = (sectionsOrder, sectionsId) => ({
  type: types.FORM_SECTIONS_SET,
  payload: { sectionsOrder, sectionsId }
});

export const addRow = (sectionId, rowId, name) => ({
  type: types.FORM_ROW_ADDED,
  payload: { sectionId, rowId, name }
});

export const moveRow = (sectionId, startingPosition, endingPosition) => ({
  type: types.FORM_ROW_MOVED,
  payload: { sectionId, startingPosition, endingPosition }
});

export const editRowName = (sectionId, rowId, name) => ({
  type: types.FORM_ROW_NAME_EDITED,
  payload: { sectionId, rowId, name }
});

export const deleteRow = (sectionId, rowId) => ({
  type: types.FORM_ROW_DELETED,
  payload: { sectionId, rowId }
});

export const addQuestion = (sectionId, position, questionType, questionId) => ({
  type: types.FORM_QUESTION_ADDED,
  payload: { sectionId, position, questionType, questionId }
});

export const moveQuestion = (sectionId, startingPosition, endingPosition) => ({
  type: types.FORM_QUESTION_MOVED,
  payload: { sectionId, startingPosition, endingPosition }
});

export const deleteQuestion = (sectionId, questionId) => ({
  type: types.FORM_QUESTION_DELETED,
  payload: { sectionId, questionId }
});

export const copyQuestion = (sectionId, questionId, newQuestion) => ({
  type: types.FORM_QUESTION_COPIED,
  payload: { sectionId, questionId, newQuestion }
});

export const setQuestion = (sectionId, question) => ({
  type: types.FORM_QUESTION_SET,
  payload: { sectionId, question }
});

export const editQuestionName = (sectionId, questionId, questionName) => ({
  type: types.FORM_QUESTION_NAME_EDITED,
  payload: { sectionId, questionId, questionName }
});

export const editQuestionHeight = (sectionId, questionId, height) => ({
  type: types.FORM_QUESTION_HEIGHT_EDITED,
  payload: { sectionId, questionId, height }
});

export const changeQuestionCollapsed = (sectionId, questionId) => ({
  type: types.FORM_QUESTION_CHANGE_COLLAPSED,
  payload: { sectionId, questionId }
});

export const startFetchingResponse = (organizationFormId, startDate, endDate) => ({
  type: types.FORM_RESPONSE_FETCH_STARTED,
  payload: { organizationFormId, startDate, endDate }
});

export const completeFetchingResponse = (entities, order, formId) => ({
  type: types.FORM_RESPONSE_FETCH_COMPLETED,
  payload: { entities, order, formId }
});

export const failFetchingResponse = (formId) => ({
  type: types.FORM_RESPONSE_FETCH_FAILED,
  payload: formId
});

export const startResendingResponse = (organizationFormId, responseIds) => ({
  type: types.FORM_RESPONSE_RESEND_STARTED,
  payload: { organizationFormId, responseIds }
});

export const completeResendingResponse = (organizationFormId, responseIds) => ({
  type: types.FORM_RESPONSE_RESEND_COMPLETED,
  payload: { organizationFormId, responseIds }
});

export const failResendingResponse = (organizationFormId, responseIds) => ({
  type: types.FORM_RESPONSE_RESEND_FAILED,
  payload: { organizationFormId, responseIds }
});

export const selectAnswer = (responseId) => ({
  type: types.FORM_ANSWER_SELECTED,
  payload: responseId
});

export const deselectAnswer = () => ({
  type: types.FORM_ANSWER_DESELECTED,
  payload: {}
});

export const moveAnswer = (sectionId, questionId, startingPosition, endingPosition) => ({
  type: types.FORM_ANSWER_MOVED,
  payload: { sectionId, questionId, startingPosition, endingPosition }
});

export const addAnswer = (sectionId, questionId, answerId, answer) => ({
  type: types.FORM_ANSWER_ADDED,
  payload: { sectionId, questionId, answerId, answer }
});

export const deleteAnswer = (sectionId, questionId, position) => ({
  type: types.FORM_ANSWER_DELETED,
  payload: { sectionId, questionId, position }
});

export const editAnswer = (sectionId, questionId, position, answer) => ({
  type: types.FORM_ANSWER_EDITED,
  payload: { sectionId, questionId, position, answer }
});

export const clearResponses = (formId) => ({
  type: types.FORM_RESPONSES_CLEARED,
  payload: formId
});

export const clearQuestions = (formId) => ({
  type: types.FORM_QUESTIONS_CLEARED,
  payload: formId
});

export const clearAllFormQuestions = (
  formIds = [] //List of FormIds
) => ({
  type: types.FORMS_ALL_QUESTIONS_CLEARED,
  payload: formIds
});
