// @ts-nocheck
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet.icon.glyph';
import { useEffect, useRef } from 'react';
import { Marker, Popup, Rectangle, useMap } from 'react-leaflet';
import { getRectBoundsFromLatLng } from '../../utils';

export interface MapLocationEditOptions {
  usePopovers?: boolean;
  location: any;
  onLocationDragend?: Function;
  showLocation: boolean;
  useRectangleBounds: boolean;
  isMapFullScreen?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                 MapLocationEdit to edit a marker in map                    */
/* -------------------------------------------------------------------------- */
const MapLocationEdit = (props: MapLocationEditOptions) => {
  const map = useMap();
  const {
    showLocation,
    location,
    useRectangleBounds = true,
    useSlidingpanelLocationInformation,
    onLocationDragend,
    usePopovers,
    isMapFullScreen = false
  } = props;

  const zoomMapToLocation = (latLng) => {
    const maxZoom = Math.max(15, map.getZoom());
    setTimeout(function () {
      var ll;
      if (latLng instanceof L.LatLng) {
        ll = latLng;
      } else {
        ll = L.latLng(latLng);
      }
      map.flyToBounds(
        [ll],
        isMapFullScreen
          ? {
              paddingBottomRight: [550, 0],
              paddingTopLeft: [0, 70],
              maxZoom,
              animate: 15 > map.getZoom()
            }
          : {
              maxZoom,
              animate: 15 > map.getZoom()
            }
      );
    }, 500);
  };

  useEffect(() => {
    try {
      markerRef.current.pm.enable({ preventMarkerRemoval: true });
      if (usePopovers) {
        markerRef.current.openPopup();
      }
    } catch {}
    if (location.latitude != null && location.longitude != null) {
      zoomMapToLocation(L.latLng(location.latitude, location.longitude));
    }
  }, [location]);

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const markerRef = useRef(null);
  const popupRef = useRef(null);

  return (
    <>
      {showLocation && location?.latitude != null && location?.longitude != null && (
        <div>
          <Marker
            ref={markerRef}
            draggable={onLocationDragend ? true : false}
            icon={L.divIcon({
              className: 'custom-div-icon',
              html: `<div  class='marker-pin' style='background-color:${
                location.locationClassDefaultColor == null
                  ? '$marker-color'
                  : location.locationClassDefaultColor
              }'></div><i class='icon-${
                location.locationClassIconName ?? 'Marker1'
              }' style='width: 20px;
                  font-size: 20px;color:white;'></i>`,
              iconSize: [30, 42],
              iconAnchor: [15, 42],
              popupAnchor: [0, -35]
            })}
            position={[location.latitude, location.longitude]}
            eventHandlers={{
              click: (e) => {
                zoomMapToLocation(e.latlng);
              },
              'pm:dragend': (e) => {
                if (onLocationDragend) onLocationDragend(e.target.getLatLng());
              }
            }}
          >
            {usePopovers && (
              <Popup ref={popupRef}>
                <b className="text-center" style={{ fontSize: '14px' }}>
                  {location.locationName}
                </b>
              </Popup>
            )}
          </Marker>

          {useRectangleBounds && (
            <Rectangle
              bounds={getRectBoundsFromLatLng(
                location.latitude,
                location.longitude,
                location.radius ?? 10
              )}
              pathOptions={{ color: primary }}
            />
          )}
        </div>
      )}
      )
    </>
  );
};

MapLocationEdit.defaultProps = {
  location: {},
  showLocation: false,
  useRectangleBounds: true,
  isMapFullScreen: false,
  usePopovers: true
};

export default MapLocationEdit;
