import { combineReducers } from 'redux';

import moment from 'moment';
import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import * as authSelectors from 'redux/reducers/auth';

import * as types from '../types';
import * as customersTypes from '../../Customers/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.OFFICES_FETCH_COMPLETED:
    case types.OFFICES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((officeId) => {
        newState[officeId] = {
          isSelected: false,
          ...state[officeId],
          ...entities[officeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.OFFICE_ADD_COMPLETED: {
      const { oldId, office } = action.payload;
      const newState = omit(state, oldId);
      newState[office.officeId] = {
        ...state[oldId],
        ...office,
        isConfirmed: true
      };
      return newState;
    }

    case types.OFFICE_EDIT_COMPLETED: {
      const { office } = action.payload;
      return {
        ...state,
        [office.officeId]: {
          ...omit(state[office.officeId], ['oldOffice']),
          ...omit(office, ['oldOffice']),
          isConfirmed: true
        }
      };
    }

    case customersTypes.CUSTOMER_ADD_COMPLETED:
    case customersTypes.CUSTOMER_EDIT_COMPLETED: {
      const { office } = action.payload;
      const newState = { ...state };
      newState[office.officeId] = {
        isSelected: false,
        ...(newState[office.officeId] ?? {}),
        ...office,
        isConfirmed: true
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.OFFICE_ADD_STARTED: {
      const office = action.payload;
      const newState = { ...state };
      newState[office.officeId] = {
        isSelected: false,
        ...office,
        isConfirmed: false
      };
      return newState;
    }
    case types.OFFICE_EDIT_STARTED: {
      const office = action.payload;
      return {
        ...state,
        [office.officeId]: {
          ...state[office.officeId],
          oldOffice: state[office.officeId],
          ...office,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.OFFICE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.OFFICE_EDIT_FAILED: {
      const { oldOffice } = action.payload;
      return {
        ...state,
        [oldOffice.officeId]: {
          ...omit(state[oldOffice.officeId], ['oldOffice']),
          ...oldOffice,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.OFFICE_REMOVE_COMPLETED: {
      const { officeId } = action.payload;
      return omit(state, officeId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.OFFICE_SELECTED: {
      const officeId = action.payload;
      const newState = {
        ...state,
        [officeId]: {
          ...state[officeId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.OFFICE_DESELECTED: {
      const officeId = action.payload;
      return {
        ...state,
        [officeId]: {
          ...state[officeId],
          isSelected: false
        }
      };
    }

    case types.OFFICES_ALL_SELECTED: {
      const officeIds = action.payload;
      const newState = { ...state };
      if (officeIds.length == 0) {
        forEach(state, (office: any, officeId) => {
          newState[officeId] = {
            ...office,
            isSelected: true
          };
        });
      } else {
        officeIds.forEach((officeId) => {
          newState[officeId] = {
            ...state[officeId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.OFFICES_ALL_DESELECTED: {
      const officeIds = action.payload;
      const newState = { ...state };
      if (officeIds.length == 0) {
        forEach(state, (office: any, officeId) => {
          newState[officeId] = {
            ...office,
            isSelected: false
          };
        });
      } else {
        officeIds.forEach((officeId) => {
          newState[officeId] = {
            ...state[officeId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.OFFICES_FETCH_COMPLETED:
    case types.OFFICES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.OFFICE_ADD_COMPLETED: {
      const { oldId, office } = action.payload;
      return state.map((officeId) => (officeId === oldId ? office.officeId : officeId));
    }

    case customersTypes.CUSTOMER_ADD_COMPLETED:
    case customersTypes.CUSTOMER_EDIT_COMPLETED: {
      const { office } = action.payload;
      return uniq([...state, office.officeId]);
    }

    case types.OFFICE_REMOVE_COMPLETED: {
      const { officeId } = action.payload;
      return state.filter((officeIdState) => officeIdState !== officeId);
    }

    //CASE ADD STARTED
    case types.OFFICE_ADD_STARTED: {
      const office = action.payload;
      return [...state, office.officeId];
    }

    //CASE ADD FAILED
    case types.OFFICE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((officeIdState) => officeIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.OFFICES_FETCH_STARTED: {
      return true;
    }
    case types.OFFICES_FETCH_COMPLETED:
    case types.OFFICES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.OFFICES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.OFFICES_PARTIAL_FETCH_COMPLETED:
    case types.OFFICES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.OFFICE_ADD_STARTED: {
      return true;
    }
    case types.OFFICE_ADD_COMPLETED:
    case types.OFFICE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.OFFICE_EDIT_STARTED: {
      return true;
    }
    case types.OFFICE_EDIT_COMPLETED:
    case types.OFFICE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.OFFICE_REMOVE_STARTED: {
      return true;
    }
    case types.OFFICE_REMOVE_COMPLETED:
    case types.OFFICE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.OFFICES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OFFICES_FETCH_STARTED:
    case types.OFFICES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.OFFICES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OFFICES_PARTIAL_FETCH_STARTED:
    case types.OFFICES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.OFFICE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OFFICE_ADD_STARTED:
    case types.OFFICE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.OFFICE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OFFICE_EDIT_STARTED:
    case types.OFFICE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.OFFICE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OFFICE_REMOVE_STARTED:
    case types.OFFICE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const offices = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default offices;

//Own state
export const getOwnState = (state) => state.offices;

//Information
export const getOffice = (state, officeId) => {
  const office = getOwnState(state).byId[officeId];
  if (office) {
    const startTime = office.startTime ? office.startTime.split(':') : undefined;
    const endTime = office.endTime ? office.endTime.split(':') : undefined;
    const parsers = authSelectors.getParsers(state);
    const authSystems = authSelectors.getAuthSystems(state);
    return {
      ...office,
      startTime: startTime
        ? moment(new Date().setHours(startTime[0], startTime[1], startTime[2])).format(
            'YYYY-MM-DD HH:mm'
          )
        : undefined,
      endTime: endTime
        ? moment(new Date().setHours(endTime[0], endTime[1], endTime[2])).format('YYYY-MM-DD HH:mm')
        : undefined,
      averageCapacityFormat: office.averageCapacity
        ? `${parsers.convertCubicMetersToAuthUserVolumeSystem(office.averageCapacity).toFixed(2)} ${
            authSystems.volume
          }`
        : undefined,
      minCapacityFormat: office.minCapacity
        ? `${parsers.convertCubicMetersToAuthUserVolumeSystem(office.minCapacity).toFixed(2)} ${
            authSystems.volume
          }`
        : undefined,
      maxCapacityFormat: office.maxCapacity
        ? `${parsers.convertCubicMetersToAuthUserVolumeSystem(office.maxCapacity).toFixed(2)} ${
            authSystems.volume
          }`
        : undefined
    };
  } else return undefined;
};
export const getOfficesList = (state) =>
  getOwnState(state)
    .order.map((id) => getOffice(state, id))
    .filter((office) => office.officeStatus > 0);

export const getOfficesListByMembership = (state, membership) => {
  //Se filtra por membership
  return getOfficesList(state).filter(
    (office) =>
      membership &&
      (!membership.organizationId || membership.organizationId === office.organizationId) &&
      (!membership.divisionId || membership.divisionId === office.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === office.subdivisionId)
  );
};

export const getNotAssignedOfficesListByCustomer = (state, locationId, customerId) =>
  getOfficesList(state).filter(
    (office) =>
      office.customerId === customerId &&
      (!office.locationId || (locationId && office.locationId === locationId))
  );

export const getMainOffice = (state, customerId) =>
  getOfficesList(state).filter(
    (office) => customerId === office.customerId && office.isMainOffice > 0
  )[0];

export const getSelectedOffices = (state) => {
  const selectedOffices = getOfficesList(state).filter((office) => office.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedOffices.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedOffices.length > 0) return selectedOffices;
};

export const getSelectedOffice = (state) => {
  const selectedOffices = getOfficesList(state).filter((office) => office.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedOffices.length === 1) return selectedOffices[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListOffices = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListOffices = (state) => getOwnState(state).isFetchingList;
export const isAddingOffice = (state) => getOwnState(state).isAdding;
export const isEditingOffice = (state) => getOwnState(state).isEditing;
export const isRemovingOffice = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListOfficesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListOfficesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingOfficeErrors = (state) => getOwnState(state).errorAdding;
export const getEditingOfficeErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingOfficeErrors = (state) => getOwnState(state).errorRemoving;
