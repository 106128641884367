/* -------------------------------------------------------------------------- */
/*                          DropdownIcons                            */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un DropdownIcons

import React, { useState } from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import DropdownInput from 'views/components/DropdownInput';
import DynamicIcon from './dynamicIcon';
import CatalogIconsOptions from './options';
import { Field } from 'redux-form';
import Icon from 'utility/icomoon';

interface Props {
  defaultValue?: any;
  name?: string;
  id?: string;
}

const DropdownIcons = (props) => {
  const { name, id } = props;
  const intl = useIntl();
  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  return (
    <DropdownInput
      name={name ?? 'dropIcons'}
      id={id ?? 'dropIcons'}
      key={resetValue}
      label={<FormattedMessage id="common.icon" />}
      options={CatalogIconsOptions.map((option) => {
        return {
          id: option.optionId,
          value: option,
          icon: <Icon icon={option.iconName} />,
          label: intl.formatMessage({ id: option.label }),
          label_intl: intl.formatMessage({ id: option.label })
        };
      })}
      {...props}
    />
  );
};

const DropdownCategoriesIcons = (props: Props) => {
  return (
    <Field
      name="dropIcons"
      id={'dropIcons'}
      required={true}
      isClearable={false}
      component={DropdownIcons}
      type={'select'}
      defaultValue={props.defaultValue}
    />
  );
};

export default DropdownCategoriesIcons;
