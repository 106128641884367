/* -------------------------------------------------------------------------- */
/*                         SlidingPaneStartUnitDowntime                       */
/* -------------------------------------------------------------------------- */
// Componente que permite iniciar el estado downtime a una unidad seleccionada

import { FC, Fragment } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import NumericalInput from '../NumericalInput';
import { useWebtrackServiceOnAction } from 'hooks/useWebtrackServiceOnAction';
import * as alerts from 'redux/utils/alerts';
import { updateHourmeter } from 'views/screens/Units/services';
import { useDispatch } from 'react-redux';
import * as unitActions from 'views/screens/Units/actions';
import { parseHoursToSeconds, parseSecondsToHours } from 'utility/Utils';

interface Props {
  close: () => void;
  isOpen: boolean;
  unitId: number;
  defaultValue?: number;
  additionalOnSuccess?: () => void;
}

const SlidingPaneManualHourmeterAdjustment: FC<Props> = ({
  isOpen,
  close,
  defaultValue = 0,
  unitId,
  additionalOnSuccess
}) => {
  //HOOKS
  const { control, handleSubmit, reset } = useForm<{ hourMeter: number }>();
  const intl = useIntl();
  const { handleAction } = useWebtrackServiceOnAction(updateHourmeter, {
    additionalOnSuccess: () => {
      dispatch(unitActions.startFetchingUnitsLiveStatus());
      if (typeof additionalOnSuccess === 'function') {
        additionalOnSuccess();
      }
    }
  });
  const dispatch = useDispatch();
  const onSubmit = (values: { hourMeter: number }) => {
    alerts.showConfirmationMessage({
      message: intl.formatMessage({ id: 'common.editConfirmationMessage' }),
      action: () => {
        const newHourMeter = parseHoursToSeconds(values.hourMeter);
        handleAction({ unitId, newHourmeter: newHourMeter });
        close();
      },
      actionNot: () => {
        close();
      }
    });
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'units.hourMeter' })}
      subtitle={''}
      width={'35rem'}
      onRequestClose={() => {
        close();
      }}
    >
      <Fragment>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              name="hourMeter"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <NumericalInput
                  field={field}
                  fieldstate={fieldState}
                  id={'hourMeterInput'}
                  label={intl.formatMessage({ id: 'units.hourMeter' })}
                  forceinitialvalue={parseSecondsToHours(defaultValue ?? 0)}
                  required
                  allowZero
                  allowDecimals
                  appendcomponent={'hr'}
                />
              )}
            />
          </Col>
        </Row>
        <Row className={'footer-slide-pane'}>
          <Col sm="12">
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage id="common.save" />
            </Button.Ripple>
            <Button.Ripple
              className="mt-50 mb-50"
              color="light"
              onClick={() => {
                reset();
                close();
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          </Col>
        </Row>
      </Fragment>
    </SlidingPane>
  );
};

export default SlidingPaneManualHourmeterAdjustment;
