import { combineReducers } from 'redux';

import union from 'lodash/union';

import * as types from '../../../types/static-catalogs';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SENSOR_BRANDS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((sensorBrandId) => {
        newState[sensorBrandId] = {
          isSelected: false,
          ...state[sensorBrandId],
          ...entities[sensorBrandId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SENSOR_BRANDS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_BRANDS_FETCH_STARTED: {
      return true;
    }
    case types.SENSOR_BRANDS_FETCH_COMPLETED:
    case types.SENSOR_BRANDS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_BRANDS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_BRANDS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_BRANDS_FETCH_STARTED:
    case types.SENSOR_BRANDS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const sensorBrands = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList
});

export default sensorBrands;

//Own state
export const getOwnState = (state) => state.staticCatalogs.sensorBrands;

//Information
export const getSensorBrand = (state, sensorBrandId) => getOwnState(state).byId[sensorBrandId];
export const getSensorBrandsList = (state) =>
  getOwnState(state).order.map((id) => getSensorBrand(state, id));
export const getSensorBrandDropdownOption = (state, sensorBrandId) => {
  let tz = getSensorBrand(state, sensorBrandId);
  return tz != undefined
    ? {
        ...tz,
        id: tz.brandId,
        value: tz.brandId,
        label: tz.brandName
      }
    : null;
};
//Status of sagas
export const isFetchingListSensorBrands = (state) => getOwnState(state).isFetchingList;
export const haveSensorbrandsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
//Errors
export const getFetchingListSensorBrandsErrors = (state) => getOwnState(state).errorFetchingList;
