/* -------------------------------------------------------------------------- */
/*                          GeofenceShapeDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un GeofenceShapeDropdownInput

import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '../../../utility/icomoon';
import DropdownInput from '../DropdownInput';

const GeofenceShapeDropdownInput = (props) => {
  const {
    forceinitialvalue = 'C',
    field,
    additionalonchangefunction = (value) => null,
    disabled
  } = props;
  const intl = useIntl();
  const geofenceShapes = [
    { id: 'C', value: 'C', label: 'geofences.shape.C', iconName: 'Circle' },
    { id: 'R', value: 'R', label: 'geofences.shape.R', iconName: 'Square' },
    { id: 'P', value: 'P', label: 'geofences.shape.P', iconName: 'Hexagon' },
    { id: 'U', value: 'U', label: 'geofences.shape.U', iconName: 'TrendingUp' }
  ];

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  useEffect(() => {
    if (field.value == null || field.value == undefined || field.value.length == 0) {
      field.onChange(forceinitialvalue);
    }
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue) {
      onChangeFinal(forceinitialvalue);
    }
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  return (
    <DropdownInput
      {...omit(props, ['forceinitialvalue'])}
      name="shape"
      id={'shape'}
      key={resetValue}
      isDisabled={disabled}
      label={<FormattedMessage id="geofences.shape" />}
      options={geofenceShapes.map((gs) => {
        return {
          ...gs,
          icon: <Icon icon={gs.iconName} />,
          label: <FormattedMessage id={`geofences.shape.${gs.value}`} />,
          label_intl: intl.formatMessage({ id: 'geofences.shape.' + gs.value })
        };
      })}
    />
  );
};

export default GeofenceShapeDropdownInput;
