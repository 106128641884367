import SlidingPane from 'react-sliding-pane';
import { useEffect, useState, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Spinner,
  Button,
  Collapse,
  InputGroupText,
  UncontrolledTooltip
} from 'reactstrap';
import DropdownInput from 'views/formComponents/DropdownInput';
import ContactsDropdown from 'views/formComponents/ContactsDropdown';
import TextInput from 'views/formComponents/TextInput';
import Icon from '../../../../../utility/icomoon';
import * as unitSelectors from '../../../Units/reducers';
import * as unitActions from '../../../Units/actions';

/* -------------------------------------------------------------------------- */
/*                       SlidingPaneShareUnitLocation                         */
/* -------------------------------------------------------------------------- */

interface Props {
  closeSlidingPane: Function;
  isSlidingPaneOpen: boolean;
  unit: any;
}

const SlidingPaneShareUnitLocation = (props: Props) => {
  const { closeSlidingPane, isSlidingPaneOpen, unit } = props;

  //HOOKS
  const { control, watch, handleSubmit, reset } = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const formValues = watch();
  const [isLinkCreated, setIsLinkCreated] = useState(false);

  const { isCreatingSharedLink, sharedLinkUnit } = useSelector((state) => ({
    sharedLinkUnit: unitSelectors.sharedLinkUnit(state),
    isCreatingSharedLink: unitSelectors.isCreatingSharedLinkUnit(state)
  }));

  //TOPS
  const durations = [
    {
      id: 1,
      value: 1,
      label: `1 ${intl.formatMessage({ id: 'common.hour' })}`,
      label_intl: `1 ${intl.formatMessage({ id: 'common.hour' })}`
    },
    {
      id: 8,
      value: 8,
      label: `8 ${intl.formatMessage({ id: 'common.hours2' })}`,
      label_intl: `8 ${intl.formatMessage({ id: 'common.hours2' })}`
    },
    {
      id: 12,
      value: 12,
      label: `12 ${intl.formatMessage({ id: 'common.hours2' })}`,
      label_intl: `12 ${intl.formatMessage({ id: 'common.hours2' })}`
    },
    {
      id: 24,
      value: 24,
      label: `24 ${intl.formatMessage({ id: 'common.hours2' })}`,
      label_intl: `24 ${intl.formatMessage({ id: 'common.hours2' })}`
    }
  ];

  const onSubmit = () => {
    dispatch(
      unitActions.startCreatingShareLink(
        unit?.unitId,
        unit?.unitName,
        formValues?.receivers,
        formValues?.durationId * 3600
      )
    );
  };

  useEffect(() => {
    setIsLinkCreated(false);
    if (sharedLinkUnit !== '' && sharedLinkUnit !== undefined && sharedLinkUnit !== null) {
      setIsLinkCreated(true);
      reset({
        ...formValues,
        sharedLink: sharedLinkUnit
      });
    }
  }, [sharedLinkUnit]);

  return (
    unit && (
      <Fragment>
        <SlidingPane
          className="custom-sliding-pane"
          overlayClassName={'custom-overlay-sliding-pane-map'}
          isOpen={isSlidingPaneOpen}
          title={intl.formatMessage({ id: 'units.shareLinkWithUnitLocation' })}
          subtitle={
            <>
              <div style={{ textAlign: 'center' }}>
                <span>{unit?.unitName}</span>
                <br />
                <span>{unit?.locationName}</span>
              </div>
            </>
          }
          width={'40rem'}
          onRequestClose={() => {
            if (closeSlidingPane) closeSlidingPane();
            dispatch(unitActions.clearSharedLink());
          }}
        >
          <Fragment>
            <Row>
              <Col sm="12">
                <Controller
                  control={control}
                  name="durationId"
                  defaultValue={1}
                  render={({ field, fieldState }) => (
                    <DropdownInput
                      field={field}
                      fieldstate={fieldState}
                      id={'durationDropdown'}
                      label={intl.formatMessage({ id: 'units.linkDuration' })}
                      options={durations}
                    />
                  )}
                />
              </Col>
              <Col sm="12">
                <ContactsDropdown
                  control={control}
                  id={'contactEmailInput'}
                  name={'receivers'}
                  isInModal
                  shouldUnregister={true}
                  required
                />
              </Col>
            </Row>
            {isCreatingSharedLink ? (
              <Row>
                <Col sm="12" className="grid-spinner">
                  <Spinner color="primary" size="lg" />
                </Col>
              </Row>
            ) : (
              <Collapse isOpen={isLinkCreated}>
                <Row>
                  <Col sm="12">
                    <Controller
                      control={control}
                      name="sharedLink"
                      render={({ field, fieldState }) => (
                        <TextInput
                          field={field}
                          fieldstate={fieldState}
                          id={'sharedLinkInput'}
                          label={intl.formatMessage({
                            id: 'common.link'
                          })}
                          disabled
                          prependcomponent={
                            <>
                              <UncontrolledTooltip placement={'top'} target={'copyClipboardButton'}>
                                {intl.formatMessage({ id: 'common.copyClipboard' })}
                              </UncontrolledTooltip>
                              <Icon
                                size={20}
                                icon="Clipboard"
                                id={'copyClipboardButton'}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  navigator.clipboard.writeText(formValues?.sharedLink)
                                }
                              />
                            </>
                          }
                        />
                      )}
                    />
                  </Col>
                </Row>
              </Collapse>
            )}
            <Row className={'footer-slide-pane'}>
              <Col sm="12">
                <Button.Ripple
                  className="mr-1 mt-50 mb-50"
                  color="primary"
                  /*type="submit"*/
                  onClick={handleSubmit((values) => onSubmit())}
                >
                  <FormattedMessage id="common.send" />
                </Button.Ripple>
                <Button.Ripple
                  className="mt-50 mb-50"
                  color="light"
                  onClick={() => {
                    reset({ durationId: 1 });
                    dispatch(unitActions.clearSharedLink());
                  }}
                >
                  <FormattedMessage id="common.clean" />
                </Button.Ripple>
              </Col>
            </Row>
          </Fragment>
        </SlidingPane>
      </Fragment>
    )
  );
};

SlidingPaneShareUnitLocation.defaultProps = {
  closeSlidingPane: () => null,
  isSlidingPaneOpen: false,
  unit: {}
};

export default SlidingPaneShareUnitLocation;
