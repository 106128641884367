import * as types from '../types';

export const startFetchingQuotations = (filters = {}) => ({
  type: types.QUOTATIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingQuotations = (entities, order, operatorId, organizationId) => ({
  type: types.QUOTATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingQuotations = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.QUOTATIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingQuotations = (
  entityTypeId,
  operatorId = null,
  organizationId = null,
  allowNullOrganizationId
) => ({
  type: types.QUOTATIONS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId, allowNullOrganizationId }
});
export const completePartialFetchingQuotations = (entities, order, operatorId, organizationId) => ({
  type: types.QUOTATIONS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingQuotations = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.QUOTATIONS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingQuotation = (quotation, isSelected = false) => ({
  type: types.QUOTATIONS_ADD_STARTED,
  payload: { quotation, isSelected }
});
export const completeAddingQuotation = (oldId, quotation) => ({
  type: types.QUOTATIONS_ADD_COMPLETED,
  payload: { oldId, quotation }
});
export const failAddingQuotation = (oldId, error) => ({
  type: types.QUOTATIONS_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingQuotation = (quotation) => ({
  type: types.QUOTATIONS_EDIT_STARTED,
  payload: quotation
});
export const completeEditingQuotation = (quotation) => ({
  type: types.QUOTATIONS_EDIT_COMPLETED,
  payload: quotation
});
export const failEditingQuotation = (oldQuotation, error) => ({
  type: types.QUOTATIONS_EDIT_FAILED,
  payload: {
    oldQuotation,
    error
  }
});

export const startSchedulingQuotation = (quotation) => ({
  type: types.QUOTATIONS_SCHEDULE_STARTED,
  payload: quotation
});
export const completeSchedulingQuotation = (quotation) => ({
  type: types.QUOTATIONS_SCHEDULE_COMPLETED,
  payload: quotation
});
export const failSchedulingQuotation = (error) => ({
  type: types.QUOTATIONS_EDIT_FAILED,
  payload: {
    error
  }
});

export const startCheckingQuotationScheduling = (quotationId, quotationLink) => ({
  type: types.QUOTATIONS_CHECK_SCHEDULING_STARTED,
  payload: { quotationId, quotationLink }
});
export const completeCheckingQuotationScheduling = (quotation) => ({
  type: types.QUOTATIONS_CHECK_SCHEDULING_COMPLETED,
  payload: quotation
});
export const failCheckingQuotationScheduling = (error) => ({
  type: types.QUOTATIONS_CHECK_SCHEDULING_FAILED,
  payload: { error }
});

export const startApprovingDenyingQuotationManagement = (quotation) => ({
  type: types.QUOTATION_APPROVE_DENY_MANAGEMENT_STARTED,
  payload: quotation
});
export const completeApprovingDenyingQuotationManagement = (quotation) => ({
  type: types.QUOTATION_APPROVE_DENY_MANAGEMENT_COMPLETED,
  payload: quotation
});
export const failApprovingDenyingQuotationManagement = (error) => ({
  type: types.QUOTATION_APPROVE_DENY_MANAGEMENT_FAILED,
  payload: {
    error
  }
});

export const startAcceptingDenyingQuotationCustomer = (quotation) => ({
  type: types.QUOTATION_ACCEPT_DENY_CUSTOMER_STARTED,
  payload: quotation
});
export const completeAcceptingDenyingQuotationCustomer = (quotation) => ({
  type: types.QUOTATION_ACCEPT_DENY_CUSTOMER_COMPLETED,
  payload: quotation
});
export const failAcceptingDenyingQuotationCustomer = (error) => ({
  type: types.QUOTATION_ACCEPT_DENY_CUSTOMER_FAILED,
  payload: {
    error
  }
});

export const startSettingTimesupQuotation = (quotation, showAlerts = true) => ({
  type: types.QUOTATIONS_SET_TIMESUP_STARTED,
  payload: { quotation, showAlerts }
});
export const completeSettingTimesupQuotation = (quotation) => ({
  type: types.QUOTATIONS_SET_TIMESUP_COMPLETED,
  payload: quotation
});
export const failSettingTimesupQuotation = (error) => ({
  type: types.QUOTATIONS_SET_TIMESUP_FAILED,
  payload: {
    error
  }
});

export const startRemovingQuotation = (quotationId) => ({
  type: types.QUOTATIONS_REMOVE_STARTED,
  payload: {
    quotationId
  }
});
export const completeRemovingQuotation = (quotationId) => ({
  type: types.QUOTATIONS_REMOVE_COMPLETED,
  payload: {
    quotationId
  }
});
export const failRemovingQuotation = (quotationId, error) => ({
  type: types.QUOTATIONS_REMOVE_FAILED,
  payload: {
    quotationId,
    error
  }
});

export const changeQuotationOrganization = (quotation, organization) => ({
  type: types.QUOTATIONS_SELECTED,
  payload: { quotation, organization }
});

export const selectQuotation = (quotationId) => ({
  type: types.QUOTATIONS_SELECTED,
  payload: quotationId
});

export const selectAllQuotations = (
  quotationIds = [] //List of quotationIds
) => ({
  type: types.QUOTATIONS_ALL_SELECTED,
  payload: quotationIds
});

export const deselectQuotation = (quotationId) => ({
  type: types.QUOTATIONS_DESELECTED,
  payload: quotationId
});

export const deselectAllQuotations = (
  quotationIds = [] //List of quotationIds
) => ({
  type: types.QUOTATIONS_ALL_DESELECTED,
  payload: quotationIds
});

export const startCopyingQuotation = (quotation) => ({
  type: types.QUOTATION_COPY_STARTED,
  payload: { quotation }
});
export const completeCopyingQuotation = (quotation) => ({
  type: types.QUOTATION_COPY_COMPLETED,
  payload: { quotation }
});
export const failCopyingQuotation = (quotationId, error) => ({
  type: types.QUOTATION_COPY_FAILED,
  payload: {
    quotationId,
    error
  }
});
