import camelcaseKeys from 'camelcase-keys';
import { useEffect, useState } from 'react';
import { getAlternateRoutesService } from '../services/routes';

const useGetAlternateRoutes = (
  routeId: number | null | undefined
): {
  data: any[];
  isFetching: boolean;
} => {
  const [data, setData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const getExecution = (routeId) => {
    getAlternateRoutesService(
      { routeId },
      {
        errorAction: () => {
          setData([]);
          setIsFetching(false);
        },
        successAction: (response) => {
          if (response?.success) {
            setData(response.data.map((route) => camelcaseKeys(route)));
          } else setData([]);
          setIsFetching(false);
        }
      }
    );
  };

  useEffect(() => {
    if (routeId) {
      setIsFetching(true);
      getExecution(routeId);
    } else {
      setData([]);
      setIsFetching(false);
    }
  }, [routeId]);
  return { data, isFetching };
};

export default useGetAlternateRoutes;
