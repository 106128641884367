import Icon from '../../utility/icomoon';
import paths from '../../views/screens/Console/paths';
import permissions from '../../views/screens/Console/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'console',
    title: 'console.console',
    icon: <Icon size={20} icon="Monitor" />,
    permissions: [permissions.console],
    iconName: 'Monitor',
    navLink: paths.console,
    screenId: screens.CONSOLE_NOTIFICATIONS
  }
];
