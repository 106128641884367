/* -------------------------------------------------------------------------- */
/*                            Schema Agents                                   */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const model = new schema.Entity(
  "models",
  {},
  { idAttribute: "deviceModelId" }
);
export const models = new schema.Array(model);
