/* -------------------------------------------------------------------------- */
/*                        Componente TimeRangeInput                           */
/* -------------------------------------------------------------------------- */
// Este componente contiene un daterange genérico que se utiliza en muchas pantallas de la aplicación.

import { Label, FormGroup, Row, Col } from 'reactstrap';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { english } from 'flatpickr/dist/l10n/default';
import { FormattedMessage } from 'react-intl';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import { XSquare } from 'react-feather';
import { createDateWithHoursAndMinutes } from 'utility/Utils';
import { useEffect } from 'react';

interface Props {
  field: any;
  fieldstate: any;
  errorMessage?: any;
  required?: boolean;
  defaultHour?: number;
  defaultMinute?: number;
  maxTime?: any;
  minTime?: any;
  showTime?: boolean;
  enableTime?: boolean;
  label?: any;
  disabled?: boolean;
  allowNulls?: boolean;
  inFormat?: boolean;
}

const TimeInput = (props: Props) => {
  const {
    field,
    fieldstate,
    required = false,
    maxTime,
    errorMessage,
    minTime,
    label,
    disabled,
    allowNulls = false,
    inFormat = false,
    defaultHour = 0,
    defaultMinute = 0
  } = props;

  const defaultDate: Date = createDateWithHoursAndMinutes(defaultHour, defaultMinute);

  useEffect(() => {
    if (defaultDate && !field?.value) field.onChange(defaultDate);
  }, []);

  return (
    <FormGroup>
      <Label for={field.name}>
        {label || <FormattedMessage id={'common.time'} />}
        {required && <span className="required">&nbsp;*</span>}
      </Label>
      <Row>
        <Col sm={allowNulls ? '10' : undefined}>
          <Flatpickr
            disabled={disabled}
            id={field.name}
            className={
              fieldstate.error != null
                ? 'form-control flatpickr-input has-error'
                : 'form-control flatpickr-input'
            }
            options={{
              locale: appIntl().locale === 'es' ? Spanish : english,
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              defaultHour: defaultHour,
              defaultMinute: defaultMinute,
              time_24hr: true,
              minTime: minTime,
              maxTime: maxTime
            }}
            value={field.value}
            onChange={(value: Array<Date>) => {
              const dateValue: Date = value[0];
              if (value.length > 0) {
                if (inFormat) field.onChange(moment(dateValue).format('HH:mm'));
                else field.onChange(dateValue);
              }
            }}
            onClose={(value) => {
              if (value.length === 0) field.onBlur(null);
            }}
          />
        </Col>
        {allowNulls && (
          <Col sm="1" style={{ cursor: 'pointer' }} className="mt-50">
            <XSquare
              onClick={() => {
                field.onChange(null);
              }}
            />
          </Col>
        )}
      </Row>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

TimeInput.defaultProps = {
  required: false,
  enableTime: false,
  showTime: true,
  disabled: false
};

export default TimeInput;
