import sidebarOptions, * as sidebar from '../../../navigation/vertical';
import outsideSidebarOptions from '../../../navigation/outside-sidebar';

import * as navbarTypes from '../../types/navbar';
// ** Initial State
const initialState = {
  suggestions: [
    ...sidebar.getOptions(sidebarOptions),
    ...sidebar.getOptions(outsideSidebarOptions)
  ],
  bookmarks: [],
  query: '',
  isFetchingBookmarks: false
};

const navbar = (state = initialState, action) => {
  switch (action.type) {
    case navbarTypes.HANDLE_SEARCH_QUERY:
      return { ...state, query: action.payload };
    case navbarTypes.GET_BOOKMARKS_FETCH_COMPLETED:
      return {
        ...state,
        bookmarks: action.payload.bookmarks,
        suggestions: action.payload.suggestions,
        isFetchingBookmarks: false
      };
    case navbarTypes.GET_BOOKMARKS_FETCH_FAILED:
      return {
        ...state,
        isFetchingBookmarks: false
      };
    case navbarTypes.GET_BOOKMARKS_FETCH_STARTED:
      return {
        ...state,
        isFetchingBookmarks: true
      };
    case navbarTypes.UPDATE_BOOKMARKED_STARTED:
      let objectToUpdate;

      // ** find & update object
      state.suggestions.find((item) => {
        if (item.id === action.payload.id) {
          item.isBookmarked = !item.isBookmarked;
          objectToUpdate = item;
        }
      });

      // ** Get index to add or remove bookmark from array
      const bookmarkIndex = state.bookmarks.findIndex((x) => x.id === action.payload.id);

      if (bookmarkIndex === -1) {
        state.bookmarks.push(objectToUpdate);
      } else {
        state.bookmarks.splice(bookmarkIndex, 1);
      }

      return { ...state };
    default:
      return state;
  }
};

export default navbar;

//Own state
export const getOwnState = (state) => state.navbar;

// Get User Bookmarks
export const getUserBookmarks = (state) => getOwnState(state).bookmarks;
//Status of sagas
export const isFetchingBookmarks = (state) => getOwnState(state).isFetchingBookmarks;
