import Icon from '../../utility/icomoon';
import paths from '../../views/screens/Protocols/paths';
import permissions from '../../views/screens/Protocols/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'protocol',
    title: 'protocol.protocol',
    icon: <Icon size={20} icon="List" />,
    permissions: [
      permissions.listProtocol,
      permissions.createProtocol,
      permissions.editProtocol,
      permissions.deleteProtocol
    ],
    iconName: 'List',
    navLink: paths.protocolsList,
    screenId: screens.PROTOCOL_CATALOG
  }
];
