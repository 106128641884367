import { appIntl } from 'utility/context/IntlGlobalProvider';
import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/preventiveNotices/noticesByOdometer';
import * as types from 'views/screens/vehicleMaintenance/types/preventiveNotices/noticesByOdometer';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/preventiveNotices/notices';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteNotice,
  getNoticeByOdometerList,
  renewNoticeByOdometer,
  saveNoticeByOdometer
} from 'views/screens/vehicleMaintenance/services/noticeService';
import { PayloadAction } from '@reduxjs/toolkit';
import { NoticeByOdometerRequest } from 'views/screens/vehicleMaintenance/types';
import { getPreventiveNoticesByOdometerFormattedData } from 'views/screens/vehicleMaintenance/utils/getPreventiveNoticesByOdometerIndicators';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchNoticesList(action) {
  try {
    const intl = appIntl();
    const jsonResult = yield call(getNoticeByOdometerList);
    const resultData = jsonResult.map((result) => camelcaseKeys(result));

    // SE CALCULA EL PROGRESO Y EL LABEL PARA CADA UNO DE LOS AVISOS PREVENTIVOS
    let entitiesCop = getPreventiveNoticesByOdometerFormattedData(resultData, intl);

    const {
      entities: { notices },
      result //order
    } = normalize(entitiesCop, schemas.notices); //normalize data to byId and order
    yield put(actions.completeFetchingNotices(notices, result));
  } catch (err) {
    const error = {
      errorMessage: err?.toString() ?? '',
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingNotices(error));
  }
}

export function* watchFetchNoticesList() {
  yield takeEvery(types.NOTICES_BY_ODOMETER_FETCH_STARTED, fetchNoticesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchNoticesList(action) {}

export function* watchPartialFetchNoticesList() {
  yield takeEvery(types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_STARTED, partialFetchNoticesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addNotice(action: PayloadAction<NoticeByOdometerRequest>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveNoticeByOdometer, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingNotice(`${addedData.unitId}-${addedData.noticeId}`, request.tempId, {
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failAddingNotice(request.tempId, e));
  }
}

export function* watchAddNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_ODOMETER_ADD_STARTED, addNotice);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editNotice(action: PayloadAction<NoticeByOdometerRequest>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveNoticeByOdometer, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingNotice({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failEditingNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchEditNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_ODOMETER_EDIT_STARTED, editNotice);
}

/* -------------------------------------------------------------------------- */
/*                                    RENEW                                    */
/* -------------------------------------------------------------------------- */
function* renewNotice(action: PayloadAction<NoticeByOdometerRequest>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(renewNoticeByOdometer, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeRenewNotice({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failRenewNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchRenewNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_ODOMETER_RENEW_STARTED, renewNotice);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeNotice(action: PayloadAction<{ noticeId: number; unitId: number }>) {
  const request = action.payload;
  try {
    yield call(deleteNotice, request);
    yield put(actions.completeRemovingNotice(`${request.unitId}-${request.noticeId}`));
  } catch (e) {
    yield put(actions.failRemovingNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchRemoveNoticeStarted() {
  yield takeEvery(types.NOTICE_BY_ODOMETER_REMOVE_STARTED, removeNotice);
}
