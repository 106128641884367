import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchDivisionsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/division/get`, {
        method: 'POST',
        body: JSON.stringify({
          operatorId,
          organizationId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { divisions },
          result //order
        } = normalize(resultData, schemas.divisions); //normalize data to byId and order
        yield put(actions.completeFetchingDivisions(divisions, result, operatorId, organizationId));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingDivisions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingDivisions(error));
  }
}

export function* watchFetchDivisionsList() {
  yield takeEvery(types.DIVISIONS_FETCH_STARTED, fetchDivisionsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchDivisionsList(action) {
  try {
    const {
      entityTypeId,
      operatorId: filterOperatorId,
      organizationId: filterOrganizationId
    } = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const { operatorId, organizationId } = yield select(
      authSelectors.getAuthUserMembershipFilters,
      {
        filterOperatorId,
        filterOrganizationId
      }
    );

    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/division/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date, operatorId, organizationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { divisions },
          result //order
        } = normalize(resultData, schemas.divisions); //normalize data to byId and order
        yield put(
          actions.completePartialFetchingDivisions(divisions, result, operatorId, organizationId)
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingDivisions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingDivisions(error));
  }
}

export function* watchPartialFetchDivisionsList() {
  yield takeEvery(types.DIVISIONS_PARTIAL_FETCH_STARTED, partialFetchDivisionsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addDivision(action) {
  const division = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/division/save`, {
        method: 'POST',
        body: JSON.stringify({ ...division, divisionId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedDivision = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingDivision(division.divisionId, addedDivision));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'divisions.division'
          }),
          name: division.divisionName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingDivision(division.divisionId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingDivision(division.divisionId, error));
  }
}

export function* watchAddDivisionsStarted() {
  yield takeEvery(types.DIVISION_ADD_STARTED, addDivision);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editDivision(action) {
  const division = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/division/save`, {
        method: 'POST',
        body: JSON.stringify(division),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedDivision = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingDivision(editedDivision));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'divisions.division'
          }),
          name: editedDivision.divisionName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldDivision } = yield select((state) =>
          selectors.getDivision(state.divisions, division.divisionId)
        );
        yield put(actions.failEditingDivision(oldDivision, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const oldDivision = yield select((state) => selectors.getDivision(state, division.divisionId));
    yield put(actions.failEditingDivision(oldDivision, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditDivisionsStarted() {
  yield takeEvery(types.DIVISION_EDIT_STARTED, editDivision);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeDivision(action) {
  const { divisionId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedDivision = yield select((state) => selectors.getDivision(state, divisionId));
      const response = yield call(fetch, `${API_BASE_URL}/division/delete`, {
        method: 'POST',
        body: JSON.stringify({ divisionId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingDivision(divisionId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'divisions.division'
          }),
          name: deletedDivision.divisionName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingDivision(divisionId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingDivision(divisionId, error));
  }
}

export function* watchRemoveDivisionStarted() {
  yield takeEvery(types.DIVISION_REMOVE_STARTED, removeDivision);
}
