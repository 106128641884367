export const WORKPLANS_EXECUTION_LOCATION_ADD_STARTED = 'WORKPLANS_EXECUTION_LOCATION_ADD_STARTED';
export const WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED =
  'WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED';
export const WORKPLANS_EXECUTION_LOCATION_ADD_FAILED = 'WORKPLANS_EXECUTION_LOCATION_ADD_FAILED';

export const WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED =
  'WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED';
export const WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED =
  'WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED';
export const WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED = 'WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED';

export const WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED =
  'WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED';
export const WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED =
  'WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED';
export const WORKPLANS_EXECUTION_LOCATION_REMOVE_FAILED =
  'WORKPLANS_EXECUTION_LOCATION_REMOVE_FAILED';
