import { useEffect, useState } from 'react';
import { getContactsService } from 'views/screens/Organizations/services';
import camelcaseKeys from 'camelcase-keys';

const useGetContacts: any = (organizationId: number) => {
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    setIsFetching(true);
    getContactsService(
      {
        organizationId
      },
      {
        successAction: (response: any) => {
          const resultData = response?.data?.map((result) => camelcaseKeys(result));
          // If there's data set it on Contacts
          if (resultData?.length > 0) setContacts(resultData);
          setIsFetching(false);
        },
        errorAction: (error: any) => {
          setIsFetching(false);
        }
      }
    );
  }, []);

  return { contacts, isFetching };
};

export { useGetContacts };
