import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as schemasImportedLocations from '../schemas/importLocations';
import * as entitySelectors from '../../Entities/reducers';
import * as entityActions from '../../Entities/actions';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import {
  getLocationsListService,
  saveLocationService,
  deleteLocationService,
  importLocationsService
} from '../services/locations';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchLocationsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getLocationsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { locations },
          result //order
        } = normalize(resultData, schemas.locations); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingLocations(locations, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingLocations(error));
      }
    }
  );
}

export function* watchFetchLocationsList() {
  yield takeEvery(types.LOCATIONS_FETCH_STARTED, fetchLocationsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchLocationsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getLocationsListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { locations },
          result //order
        } = normalize(resultData, schemas.locations); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingLocations(locations, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingLocations(error));
      }
    }
  );
}

export function* watchPartialFetchLocationsList() {
  yield takeEvery(types.LOCATIONS_PARTIAL_FETCH_STARTED, partialFetchLocationsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addLocation(action) {
  const location = action.payload;
  saveLocationService(
    { ...location, locationId: null },
    {
      successAction: (response) => {
        const addedLocation = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingLocation(location.locationId, addedLocation));
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingLocation(location.locationId, error));
      }
    }
  );
}

export function* watchAddLocationsStarted() {
  yield takeEvery(types.LOCATION_ADD_STARTED, addLocation);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editLocation(action) {
  const location = action.payload;
  const { oldLocation } = yield select((state) =>
    selectors.getLocation(state, location.locationId)
  );
  saveLocationService(
    { ...location },
    {
      successAction: (response) => {
        const editedLocation = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingLocation(editedLocation));
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingLocation(oldLocation, error));
      }
    }
  );
}

export function* watchEditLocationsStarted() {
  yield takeEvery(types.LOCATION_EDIT_STARTED, editLocation);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeLocation(action) {
  const { locationId } = action.payload;
  const location = yield select((state) => selectors.getLocation(state, locationId));
  deleteLocationService(
    { ...location },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingLocation(locationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingLocation(locationId, error));
      }
    }
  );
}

export function* watchRemoveLocationStarted() {
  yield takeEvery(types.LOCATION_REMOVE_STARTED, removeLocation);
}

/* -------------------------------------------------------------------------- */
/*                                    IMPORT                                  */
/* -------------------------------------------------------------------------- */
function* importLocations(action) {
  const locations = action.payload;
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });
  importLocationsService(
    { ...locations },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        // Fetch locations
        store.dispatch(actions.startFetchingLocations({ operatorId, organizationId }));
        const {
          entities: { importedLocations },
          result //order
        } = normalize(resultData, schemasImportedLocations.importedLocations); //normalize data to byId and order
        // Complete import locations
        store.dispatch(actions.completeImportingLocations(importedLocations, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failImportingLocations(error));
      }
    }
  );
}

export function* watchImportLocationsStarted() {
  yield takeEvery(types.LOCATIONS_IMPORT_STARTED, importLocations);
}
