import { useBoolean } from 'hooks/useBoolean';
import * as authSelectors from 'redux/reducers/auth';
import { useSelector } from 'react-redux';
import { FC, Fragment } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Icon from 'utility/icomoon';
import DefaultButton from 'views/components/DefaultButton';
import SlidingPaneManualOdometerAdjustment from '../SlidingPaneManualOdometerAdjustment';

export enum ButtonTypes {
  Icon = 1,
  Button = 2
}

interface Props {
  unitId: number;
  size?: number;
  buttonType?: ButtonTypes;
  defaultValue?: number; // raw odometer in meters
  isDisabled?: boolean;
  additionalOnSuccess?: () => void;
}

const ManualOdometerAdjustment: FC<Props> = ({
  size = 16,
  buttonType = ButtonTypes.Button,
  defaultValue = 0, // raw odometer in meters
  isDisabled = false,
  unitId,
  additionalOnSuccess
}) => {
  // validate permission
  const hasPermission = useSelector((store) =>
    authSelectors.getAuthUserHasPermissionsAny(store, ['OP004'])
  );
  if (!hasPermission) return <></>;
  // validate permission
  const intl: IntlShape = useIntl();
  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();
  const onClickIcon = (event) => {
    open();
  };

  return (
    <Fragment>
      {buttonType === ButtonTypes.Icon ? (
        <Icon icon="Edit2" size={size} onClick={onClickIcon} style={{ cursor: 'pointer' }} />
      ) : (
        <DefaultButton
          color="light"
          handleClick={() => {
            open();
          }}
          icon={'Speedometer'}
          tooltipMessage={intl.formatMessage({ id: 'units.editOdometer' })}
          isDisabled={isDisabled}
        />
      )}
      <SlidingPaneManualOdometerAdjustment
        isOpen={isOpen}
        close={close}
        defaultValue={defaultValue}
        unitId={unitId}
        additionalOnSuccess={additionalOnSuccess}
      />
    </Fragment>
  );
};

export default ManualOdometerAdjustment;