import React, { Fragment } from 'react';
import { ButtonToolbar } from 'reactstrap';

interface Props {
  buttons: any[];
  className?: string;
  position?: 'right' | 'left';
}
const Toolbar = (props: Props) => {
  const { buttons, className = '', position = 'right' } = props;
  return (
    <ButtonToolbar
      className={`${
        position == 'right' ? 'justify-content-end' : 'justify-content-start'
      } ${className}`}
    >
      {buttons.map((child, index) => (
        <Fragment key={index}>{child}</Fragment>
      ))}
    </ButtonToolbar>
  );
};

export default Toolbar;
