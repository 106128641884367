import affiliations from './affiliations';
import inventories from './inventories';
import schedules from './schedules';
import groups from './groups';
import catalogs from './catalogs';
import notifications from './notifications';
import forms from 'views/screens/Forms/sidebarLayout';

export default [
  {
    header: 'sidebar.administration',
    iconName: 'Sliders',
    children: [
      ...affiliations,
      ...groups,
      ...schedules,
      ...catalogs,
      ...forms,
      ...inventories,
      ...notifications
    ] //Only to check permission in children inside
  },
  ...affiliations,
  ...groups,
  ...schedules,
  ...catalogs,
  ...forms,
  ...inventories,
  ...notifications
];
