/* -------------------------------------------------------------------------- */
/*                      Schema Insurance Companies                            */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const insuranceCompany = new schema.Entity(
  'insuranceCompanies',
  {},
  { idAttribute: 'insuranceCompanyId' }
);
export const insuranceCompanies = new schema.Array(insuranceCompany);
