import * as types from '../../types/board';

export const startFetchingJobsBoard = (filters = {}) => ({
  type: types.JOBS_BOARD_FETCH_STARTED,
  payload: filters
});
export const completeFetchingJobsBoard = (entities, order) => ({
  type: types.JOBS_BOARD_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingJobsBoard = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.JOBS_BOARD_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startChangeStatusJobsBoard = (jobs, jobStatus, comment) => ({
  type: types.JOBS_BOARD_CHANGE_STATUS_STARTED,
  payload: { jobs, jobStatus, comment }
});
export const completeChangeStatusJobsBoard = (jobs, jobStatus, comment) => ({
  type: types.JOBS_BOARD_CHANGE_STATUS_COMPLETED,
  payload: { jobs, jobStatus, comment }
});
export const failChangeStatusJobsBoard = (oldJobs, error) => ({
  type: types.JOBS_BOARD_CHANGE_STATUS_FAILED,
  payload: {
    oldJobs,
    error
  }
});
