import * as types from '../../../types/static-catalogs/sensor-measures';

export const startFetchingSensorMeasures = (sensorId, sensorModelId) => ({
  type: types.SENSOR_MEASURES_FETCH_STARTED,
  payload: { sensorId, sensorModelId }
});
export const completeFetchingSensorMeasures = (entities, order) => ({
  type: types.SENSOR_MEASURES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingSensorMeasures = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SENSOR_MEASURES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
export const clearSensorMeasures = () => ({
  type: types.SENSOR_MEASURES_CLEAR,
  payload: {}
});
