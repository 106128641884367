// @ts-nocheck
import { useEffect, useState } from 'react';
import L from 'leaflet';
import 'leaflet-toolbar';
import { useLeafletContext } from '@react-leaflet/core';
import { useMap } from 'react-leaflet';

import * as authActions from '../../../../../redux/actions/auth';
import { useDispatch } from 'react-redux';
export interface HomeToolbarOptions {
  useToolbar: boolean;
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  listenMapControlChanges?: any[];
}

import ReactDOMServer from 'react-dom/server';

import { Home } from 'react-feather';
import { useIntl } from 'react-intl';
import useDeepCompareEffect from 'use-deep-compare-effect';
/* -------------------------------------------------------------------------- */
/*                             ToolbarHomeControl                             */
/* -------------------------------------------------------------------------- */
const ToolbarHomeControl = (props: HomeToolbarOptions) => {
  const { position = 'topleft', listenMapControlChanges } = props;
  const context = useLeafletContext();
  const dispatch = useDispatch(); // to handle home region function
  const intl = useIntl();
  // Be aware of changes in map layer
  const map = useMap();
  var selectedLayer = 0;
  map.on('baselayerchange', function (e) {
    const name = e.name;
    let layer_id = 0;
    if (name == 'Open Street Map') layer_id = 0;
    if (name == 'MapQuest') layer_id = 2;
    if (name == 'Google Maps') layer_id = 3;
    if (name == 'Google Maps Road & Satellite') layer_id = 4;
    if (name == 'Bing Maps') layer_id = 5;
    if (name == 'Bing Satellite') layer_id = 7;
    if (name == 'Bing Hybrid') layer_id = 8;
    if (name == 'Here Maps') layer_id = 9;
    if (name == 'Here Maps Satellite') layer_id = 10;
    if (name == 'Google Maps Satellite') layer_id = 11;
    if (name == 'Google Maps Traffic') layer_id = 12;
    if (name == 'Google Maps Terrain') layer_id = 13;
    if (name == 'MapQuest Hybrid') layer_id = 14;
    if (name == 'MapQuest Satellite') layer_id = 15;
    if (name == 'MapQuest Dark') layer_id = 16;
    if (name == 'MapQuest Light') layer_id = 17;
    if (name == 'WebTrack') layer_id = 18;
    if (name == 'WebTrack Light') layer_id = 19;
    if (name == 'WebTrack Dark') layer_id = 20;
    selectedLayer = layer_id;
  });

  // homeFunction to update home region
  const homeFunction = async () => {
    var centerLatLng = map.getCenter().wrap();
    var config = {
      homeLatitude: centerLatLng.lat,
      homeLongitude: centerLatLng.lng,
      homeZoom: map.getZoom(),
      homeLayer: selectedLayer
    };
    dispatch(authActions.startUpdatingMapSettingsConfiguration(config));
  };

  //Add Toolbar to Map Container
  useDeepCompareEffect(() => {
    const container = context.layerContainer || context.map;
    const homeActionTooltip = () => {
      return intl.formatMessage({ id: 'map.homeToolbar.tooltipSetHomeRegion' });
    };
    var setHomeAction = L.Toolbar2.Action.extend({
      options: {
        toolbarIcon: {
          // html: '<i class="fa fa-home" uib-tooltip="Home" tooltip-append-to-body="true" tooltip-placement="right"></i>'
          tooltip: homeActionTooltip(),
          html: ReactDOMServer.renderToStaticMarkup(
            <>
              <Home size={14} />
            </>
          )
        }
      },
      addHooks: homeFunction
    });

    new L.Toolbar2.Control({
      position: position,
      actions: [setHomeAction]
    }).addTo(container);
  }, [listenMapControlChanges]);

  return null;
};

ToolbarHomeControl.defaultProps = {
  position: 'topleft',
  useToolbar: false,
  name: 'ToolbarHomeControl',
  listenMapControlChanges: []
};
export default ToolbarHomeControl;
