/* -------------------------------------------------------------------------- */
/*                         Schema Contract Templates                          */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const contractTemplate = new schema.Entity(
  'contractTemplates',
  {},
  { idAttribute: 'templateId' }
);
export const contractTemplates = new schema.Array(contractTemplate);
