/* -------------------------------------------------------------------------- */
/*                            Schema Agents                                   */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const agent = new schema.Entity(
  "agents",
  {},
  { idAttribute: "agentId" }
);
export const agents = new schema.Array(agent);
