export const FORMS_FETCH_STARTED = 'FORMS_FETCH_STARTED';
export const FORMS_FETCH_COMPLETED = 'FORMS_FETCH_COMPLETED';
export const FORMS_FETCH_FAILED = 'FORMS_FETCH_FAILED';

export const FORMS_PARTIAL_FETCH_STARTED = 'FORMS_PARTIAL_FETCH_STARTED';
export const FORMS_PARTIAL_FETCH_COMPLETED = 'FORMS_PARTIAL_FETCH_COMPLETED';
export const FORMS_PARTIAL_FETCH_FAILED = 'FORMS_PARTIAL_FETCH_FAILED';

export const FORM_SECTIONS_FETCH_STARTED = 'FORM_SECTIONS_FETCH_STARTED';
export const FORM_SECTIONS_FETCH_COMPLETED = 'FORM_SECTIONS_FETCH_COMPLETED';
export const FORM_SECTIONS_FETCH_FAILED = 'FORM_SECTIONS_FETCH_FAILED';

export const FORM_QUESTIONS_FETCH_STARTED = 'FORM_QUESTIONS_FETCH_STARTED';
export const FORM_QUESTIONS_FETCH_COMPLETED = 'FORM_QUESTIONS_FETCH_COMPLETED';
export const FORM_QUESTIONS_FETCH_FAILED = 'FORM_QUESTIONS_FETCH_FAILED';

export const FORM_ADD_STARTED = 'FORM_ADD_STARTED';
export const FORM_ADD_COMPLETED = 'FORM_ADD_COMPLETED';
export const FORM_ADD_FAILED = 'FORM_ADD_FAILED';

export const FORM_SECTION_ADD_STARTED = 'FORM_SECTION_ADD_STARTED';
export const FORM_SECTION_ADD_COMPLETED = 'FORM_SECTION_ADD_COMPLETED';
export const FORM_SECTION_ADD_FAILED = 'FORM_SECTION_ADD_FAILED';

export const FORM_EDIT_STARTED = 'FORM_EDIT_STARTED';
export const FORM_EDIT_COMPLETED = 'FORM_EDIT_COMPLETED';
export const FORM_EDIT_FAILED = 'FORM_EDIT_FAILED';

export const FORM_EXTERNAL_ADD_STARTED = 'FORM_EXTERNAL_ADD_STARTED';

export const FORM_EXTERNAL_EDIT_STARTED = 'FORM_EXTERNAL_EDIT_STARTED';

export const FORM_REMOVE_STARTED = 'FORM_REMOVE_STARTED';
export const FORM_REMOVE_COMPLETED = 'FORM_REMOVE_COMPLETED';
export const FORM_REMOVE_FAILED = 'FORM_REMOVE_FAILED';

export const FORM_RESPONSE_SAVE_STARTED = 'FORM_RESPONSE_SAVE_STARTED';
export const FORM_RESPONSE_SAVE_COMPLETED = 'FORM_RESPONSE_SAVE_COMPLETED';
export const FORM_RESPONSE_SAVE_FAILED = 'FORM_RESPONSE_SAVE_FAILED';

export const FORM_SELECTED = 'FORM_SELECTED';
export const FORMS_ALL_SELECTED = 'FORMS_ALL_SELECTED';
export const FORM_DESELECTED = 'FORM_DESELECTED';
export const FORMS_ALL_DESELECTED = 'FORMS_ALL_DESELECTED';
export const FORMS_ALL_QUESTIONS_CLEARED = 'FORMS_ALL_QUESTIONS_CLEARED';

export const FORM_SECTION_ADDED = 'FORM_SECTION_ADDED';
export const FORM_SECTION_DELETED = 'FORM_SECTION_DELETED';
export const FORM_SECTION_MOVED = 'FORM_SECTION_MOVED';
export const FORM_SECTION_NAME_EDITED = 'FORM_SECTION_NAME_EDITED';
export const FORM_SECTION_COPIED = 'FORM_SECTION_COPIED';
export const FORM_SECTION_CHANGE_COLLAPSED = 'FORM_SECTION_CHANGE_COLLAPSED';

export const FORM_SECTIONS_SET = 'FORM_SECTIONS_SET';

export const FORM_ROW_ADDED = 'FORM_ROW_ADDED';
export const FORM_ROW_DELETED = 'FORM_ROW_DELETED';
export const FORM_ROW_MOVED = 'FORM_ROW_MOVED';
export const FORM_ROW_NAME_EDITED = 'FORM_ROW_NAME_EDITED';

export const FORM_QUESTION_ADDED = 'FORM_QUESTION_ADDED';
export const FORM_QUESTION_DELETED = 'FORM_QUESTION_DELETED';
export const FORM_QUESTION_MOVED = 'FORM_QUESTION_MOVED';
export const FORM_QUESTION_COPIED = 'FORM_QUESTION_COPIED';

export const FORM_QUESTION_SET = 'FORM_QUESTIONS_SET';
export const FORM_QUESTION_NAME_EDITED = 'FORM_QUESTION_NAME_EDITED';
export const FORM_QUESTION_HEIGHT_EDITED = 'FORM_QUESTION_HEIGHT_EDITED';
export const FORM_QUESTION_CHANGE_COLLAPSED = 'FORM_QUESTION_CHANGE_COLLAPSED';

export const FORM_OPTION_ADDED = 'FORM_OPTION_ADDED';
export const FORM_OPTION_DELETED = 'FORM_OPTION_DELETED';
export const FORM_OPTION_MOVED = 'FORM_OPTION_MOVED';

export const FORM_RESPONSE_FETCH_STARTED = 'FORM_RESPONSE_FETCH_STARTED';
export const FORM_RESPONSE_FETCH_COMPLETED = 'FORM_RESPONSE_FETCH_COMPLETED';
export const FORM_RESPONSE_FETCH_FAILED = 'FORM_RESPONSE_FETCH_FAILED';

export const FORM_RESPONSE_RESEND_STARTED = 'FORM_RESPONSE_RESEND_STARTED';
export const FORM_RESPONSE_RESEND_COMPLETED = 'FORM_RESPONSE_RESEND_COMPLETED';
export const FORM_RESPONSE_RESEND_FAILED = 'FORM_RESPONSE_RESEND_FAILED';

export const FORM_ANSWER_ADDED = 'FORM_ANSWER_ADDED';
export const FORM_ANSWER_MOVED = 'FORM_ANSWER_MOVED';
export const FORM_ANSWER_EDITED = 'FORM_ANSWER_EDITED';
export const FORM_ANSWER_DELETED = 'FORM_ANSWER_DELETED';
export const FORM_ANSWER_SELECTED = 'FORM_ANSWER_SELECTED';
export const FORM_ANSWER_DESELECTED = 'FORM_ANSWER_DESELECTED';

export const FORM_RESPONSES_CLEARED = 'FORM_RESPONSES_CLEARED';
export const FORM_QUESTIONS_CLEARED = 'FORM_QUESTIONS_CLEARED';
