// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react';
import Lightbox from 'react-image-lightbox';
import { useState } from 'react';
import SwiperCore, {
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  Autoplay,
  Lazy,
  Virtual,
  Thumbs
} from 'swiper';

SwiperCore.use([
  Navigation,
  Pagination,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  Autoplay,
  Lazy,
  Virtual,
  Thumbs
]);

const defaultGalleryParams = {
  effect: 'fade',
  spaceBetween: 30,
  initialSlide: 0,
  centeredSlides: true,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false,
    pauseOnMouseEnter: true,
    reverseDirection: false
  },
  loop: true,
  navigation: true,
  pagination: {
    clickable: true
  }
};

interface MediaGalleryProps {
  isRtl: boolean;
  images: Image[];
  className: string;
  classNameThumbs?: string;
  classNameImg?: string;
  autoplay?: any;
  loop?: boolean;
  navigation?: boolean;
  pagination?: boolean;
  imagesPath: string;
  height: string; //percentage
  heightThumbs?: string; //percentage
  onSlideChange?: Function;
  useThumbs?: boolean;
}

interface Image {
  imageUrl: string;
  fullImageUrl: string;
  imageName: string;
  imageId: any;
  imageDescription: string;
  imageType: number;
  imagePath: string;
}

const MediaGallery = (props: MediaGalleryProps) => {
  const {
    isRtl,
    images,
    className,
    classNameImg,
    autoplay,
    loop,
    height,
    navigation,
    pagination,
    onSlideChange,
    useThumbs,
    classNameThumbs,
    heightThumbs
  } = props;

  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  //if useThumbs
  const [thumbsSwiper, setThumbsSwiper] = useState(null); // if useThums
  const paramsThumbsDefault = {
    className: 'gallery-thumbs',
    spaceBetween: 10,
    slidesPerView: 4,
    freeMode: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    onSwiper: setThumbsSwiper
  };

  const galleryOptions = {
    ...defaultGalleryParams,
    autoplay: autoplay != null ? autoplay : defaultGalleryParams.autoplay,
    loop: loop != null ? loop : defaultGalleryParams.loop,
    navigation: navigation != null ? navigation : defaultGalleryParams.navigation,
    pagination: pagination != null ? pagination : defaultGalleryParams.pagination,
    thumbs: useThumbs ? { swiper: thumbsSwiper } : undefined
  };

  return (
    <>
      {images.length > 1 ? (
        <>
          <Swiper
            dir={isRtl ? 'rtl' : 'ltr'}
            {...galleryOptions}
            style={{ height: height, cursor: 'pointer' }}
            initialSlide={0}
            className={className}
            onSlideChange={(swiper) => {
              if (onSlideChange)
                onSlideChange(
                  swiper.activeIndex > 0 ? (swiper.activeIndex - 1) % images.length : 0
                );
            }}
          >
            {images.map((image, index) => {
              return (
                <SwiperSlide key={image.imageId} style={{ backgroundColor: 'white' }}>
                  <img
                    src={image.fullImageUrl}
                    className={classNameImg}
                    width="100%"
                    onClick={() => {
                      setLightboxIndex(index);
                      setIsLightboxOpen(true);
                    }}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {useThumbs && images.length >= 3 && (
            <Swiper
              {...paramsThumbsDefault}
              className={`gallery-thumbs ${classNameThumbs}`}
              style={{ height: heightThumbs }}
            >
              {images.map((image, index) => {
                return (
                  <SwiperSlide key={image.imageId + 'thumb'}>
                    <img src={image.fullImageUrl} className="img-contain" width="100%" />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <img
            src={images[0].fullImageUrl}
            width="100%"
            className={className}
            style={{ height: height, cursor: 'pointer' }}
            onClick={() => {
              setLightboxIndex(0);
              setIsLightboxOpen(true);
            }}
          />
        </div>
      )}
      {isLightboxOpen && (
        <Lightbox
          discourageDownloads={false}
          reactModalStyle={{ overlay: { zIndex: 20000 } }}
          imageTitle={images[lightboxIndex].imageName}
          imageCaption={images[lightboxIndex].imageDescription}
          mainSrc={images[lightboxIndex].fullImageUrl}
          nextSrc={images[(lightboxIndex + 1) % images.length].fullImageUrl}
          prevSrc={images[(lightboxIndex + images.length - 1) % images.length].fullImageUrl}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setLightboxIndex((lightboxIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % images.length)}
        />
      )}
    </>
  );
};

MediaGallery.defaultProps = {
  isRtl: false,
  images: [],
  className: '',
  classNameImg: 'img-contain',
  classNameThumbs: '',
  autoplay: null,
  loop: null,
  navigation: null,
  pagination: null,
  imagesPath: '',
  height: '33%',
  heightThumbs: '10%',
  useThumbs: false,
  onSlideChange: (activeSlideId) => null
};

export default MediaGallery;
