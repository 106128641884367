export const ASSETS_FETCH_STARTED = 'ASSETS_FETCH_STARTED';
export const ASSETS_FETCH_COMPLETED = 'ASSETS_FETCH_COMPLETED';
export const ASSETS_FETCH_FAILED = 'ASSETS_FETCH_FAILED';

export const ASSETS_PARTIAL_FETCH_STARTED = 'ASSETS_PARTIAL_FETCH_STARTED';
export const ASSETS_PARTIAL_FETCH_COMPLETED = 'ASSETS_PARTIAL_FETCH_COMPLETED';
export const ASSETS_PARTIAL_FETCH_FAILED = 'ASSETS_PARTIAL_FETCH_FAILED';

export const ASSET_ADD_STARTED = 'ASSET_ADD_STARTED';
export const ASSET_ADD_COMPLETED = 'ASSET_ADD_COMPLETED';
export const ASSET_ADD_FAILED = 'ASSET_ADD_FAILED';

export const ASSET_EDIT_STARTED = 'ASSET_EDIT_STARTED';
export const ASSET_EDIT_COMPLETED = 'ASSET_EDIT_COMPLETED';
export const ASSET_EDIT_FAILED = 'ASSET_EDIT_FAILED';

export const ASSET_REMOVE_STARTED = 'ASSET_REMOVE_STARTED';
export const ASSET_REMOVE_COMPLETED = 'ASSET_REMOVE_COMPLETED';
export const ASSET_REMOVE_FAILED = 'ASSET_REMOVE_FAILED';

export const ASSET_SELECTED = 'ASSET_SELECTED';
export const ASSETS_ALL_SELECTED = 'ASSETS_ALL_SELECTED';
export const ASSET_DESELECTED = 'ASSET_DESELECTED';
export const ASSETS_ALL_DESELECTED = 'ASSETS_ALL_DESELECTED';

export const ASSET_IDENTIFIER_CHECK_STARTED = 'ASSET_IDENTIFIER_CHECK_STARTED';
export const ASSET_IDENTIFIER_CHECK_COMPLETED = 'ASSET_IDENTIFIER_CHECK_COMPLETED';
export const ASSET_IDENTIFIER_CHECK_FAILED = 'ASSET_IDENTIFIER_CHECK_FAILED';

export const ASSET_SECURITY_SEAL_CODE_CHECK_STARTED = 'ASSET_SECURITY_SEAL_CODE_CHECK_STARTED';
export const ASSET_SECURITY_SEAL_CODE_CHECK_COMPLETED = 'ASSET_SECURITY_SEAL_CODE_CHECK_COMPLETED';
export const ASSET_SECURITY_SEAL_CODE_CHECK_FAILED = 'ASSET_SECURITY_SEAL_CODE_CHECK_FAILED';
