import { IndicatorType } from 'views/components/IndicatorsCard';
import { NoticeDTO } from '../types';
import permissons from '../permissions';
import { numberWithCommas } from 'utility/Utils';
import { FormattedMessage, IntlShape } from 'react-intl';
import orderBy from 'lodash/orderBy';
import { notice } from '../schemas/preventiveNotices/notices';
import ReactHtmlParser from 'react-html-parser';
import decode from 'decode-html';

export interface FormattedNotice extends NoticeDTO {
  lack: number;
  progress: number;
  noticeMessage: string;
  progress2: number;
  noticeProgressLabel: string;
  noticeTypeId2Format: string;
}

export const getPreventiveNoticesByOdometerFormattedData = (
  data: NoticeDTO[],
  intl: IntlShape
): FormattedNotice[] => {
  return data?.map((notice) => {
    const l = notice.firstOccurrence - notice.odometer;
    const lack = isNaN(l) ? 0 : l;
    const p = ((notice.frequency - lack) / notice.frequency) * 100;
    const progress = p <= 0 ? 0 : p > 100 ? 100 : p;
    const noticeProgressLabel = `${numberWithCommas(progress)}%`;
    const noticeMessageFormatted: string = decode(notice.noticeMessage);

    return {
      ...notice,
      lack,
      progress,
      noticeMessage: noticeMessageFormatted,
      progress2: progress,
      noticeProgressLabel,
      noticeTypeId2Format: intl.formatMessage({
        id: `vehicleMaintenance.noticeType${notice.noticeTypeId2 ?? notice.noticeTypeId}`
      })
    };
  });
};

export const getPreventiveNoticesByOdometerIndicators = (
  data: Array<NoticeDTO>,
  events: { onAllClick(): void; onCurrentsClick(): void; onExpiredClick(): void }
) => {
  const indicators: IndicatorType[] = [];

  //Total de avisos
  indicators.push({
    title: (
      <>
        <FormattedMessage id="vehicleMaintenance.totalNotices" />
      </>
    ),
    size: 4,
    permissions: [permissons.totalNotices],
    bigIndicator: `${numberWithCommas(data?.length?.toString())}`,
    onClick: events.onAllClick
  });

  //Total de avisos activos
  indicators.push({
    title: (
      <>
        <FormattedMessage id="vehicleMaintenance.currentNotices" />
      </>
    ),
    size: 4,
    permissions: [permissons.activeNotices],
    bigIndicator: `${numberWithCommas(
      data?.filter((notice) => (notice?.lack ?? 0) > 0)?.length?.toString()
    )}`,
    onClick: events.onCurrentsClick
  });

  //Total de avisos pendientes
  indicators.push({
    title: (
      <>
        <FormattedMessage id="vehicleMaintenance.expiredNotices" />
      </>
    ),
    size: 4,
    permissions: [permissons.expiredNotices],
    bigIndicator: `${numberWithCommas(
      data?.filter((notice) => (notice?.lack ?? 0) <= 0)?.length?.toString()
    )}`,
    onClick: events.onExpiredClick
  });
  data = orderBy(data, 'lack', 'asc');

  return { indicators, data };
};
