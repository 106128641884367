import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export const promiseWith = <T>(data: T) => {
  return new Promise<T>((resolve) => resolve(data));
};

export class HttpAxios {
  service: AxiosInstance;

  constructor(config: any) {
    this.service = axios.create(config);
  }

  async getWithToken(path: string, token: string) {
    const response = await this.service.get(path, {
      headers: { Authorization: `Bearer ${token}` }
    });
    return promiseWith(response.data);
  }

  async getPdf(path: string, token: string): Promise<any> {
    const response = await this.service.get(path, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: 'arraybuffer'
    });
    return promiseWith(response.data);
  }

  async get<T>(path: string, config?: AxiosRequestConfig): Promise<T> {
    const response = await this.service.get<T>(path, config);
    return promiseWith<T>(response.data);
  }

  async patch(path: string, payload: string) {
    const response = await this.service.patch(path, payload);
    return promiseWith(response.data);
  }

  async post<T = any>(path: string, payload: any, config?: AxiosRequestConfig) {
    const response = await this.service.post(path, payload, config);
    return promiseWith<T>(response.data);
  }

  async put(path: string, payload: any = {}, config?: AxiosRequestConfig) {
    const response = await this.service.put(path, payload, config);
    return promiseWith(response.data);
  }

  async delete(path: string) {
    const response = await this.service.delete(path);
    return promiseWith(response.data);
  }
}
