import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/user';

interface RolesPermissionsUserRequest {
  userId?: number;
  userName?: string;
  rolId?: number;
  permissionId?: string;
  permissions?: string[];
}

interface ScreensRequest {
  userId?: number;
  roleId: number;
}

interface UserDeleteRequest {
  userName: string;
}

interface UserNameValidate {
  userName: string;
  userNameEdit: string;
}

interface UserFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
}

interface UserFiltersStatus {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
  userStatus?: number;
}

interface UserRequest {
  userName: string;
  password: string;
  completeName: string;
  email: string;
  imageName: string;
  accessLevelId: number;
  utcZoneId: number;
  currencyId: number;
  metricSystemId: number;
  userStatus: number;
  operatorId: number;
  organizationId: number;
  divisionId: number;
  subdivisionId: number;
  enableLocationClustering: number;
  locationMapLabelAlwaysOn: number;
  activateUnitTrackMode: number;
  unitMapLabelAlwaysOn: number;
  useGoogleMaps: number;
  useBingMaps: number;
  useHereMaps: number;
}

export const getUsersListService = (request: UserFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.user',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const saveUserService = (request: UserRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.user',
    action: 'Edit',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteUserService = (request: UserDeleteRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.userName}`,
    object: 'users.user',
    action: 'Delete',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const availabilityUserNameService = (request: UserNameValidate) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  return httpWebtrack.post(`${baseUrl}/availabilityUserName`, request);
};

export const getRoles = (request: RolesPermissionsUserRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.roles',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/roles`, request);
};

export const getPermissions = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.permissions',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/permissions`, request);
};

export const getRolesAndPermissions = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.roles',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/rolesAndPermissions`, request);
};

export const getPermissionGroups = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.roles',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/permissionGroups`, request);
};

export const getRolePermissionGroups = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.roles',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/rolePermissionGroups`, request);
};

export const addRole = (request: RolesPermissionsUserRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.role',
    action: 'Edit',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/addRole`, request);
};

export const removeRole = (request: RolesPermissionsUserRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.role',
    action: 'Delete',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/removeRole`, request);
};

export const addPermission = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.permission',
    action: 'Edit',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/addPermission`, request);
};

export const addPermissions = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.permission',
    action: 'Edit',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/addPermissions`, request);
};

export const removePermission = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.permission',
    action: 'Delete',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/removePermission`, request);
};

export const removePermissions = (
  request: RolesPermissionsUserRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.permission',
    action: 'Delete',
    genderType: 'SingularMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/removePermissions`, request);
};

export const getScreens = (request: ScreensRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.screen',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/screens`, request);
};

export const getSuspendedUsersListService = (
  request: UserFiltersStatus,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.user',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDisabled`, request);
};

export const updateUserStatus = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'users.user',
    action: 'Edit',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/updateStatus`, request);
};
