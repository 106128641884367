const permissions = {
  listQuotation: 'PE001',
  addQuotation: 'PE002',
  editQuotation: 'PE003',
  removeQuotation: 'PE004',
  denyByManagement: 'PE001',
  denyByCustomer: 'PE001',
  approveByManagement: 'PE001',
  acceptByCustomer: 'PE001',
  copyQuotation: 'PE001',
  previewQuotation: 'PE001',
  followUpQuotation: 'PE001',
  consolidateContract: 'PE001'
};

export default permissions;
