import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/workplan';

export interface deleteWorkplanExecutionLocationFilters {
  visitId: number;
  locationId: number;
  sequenceNumber: number;
  applyToWorkplan: boolean;
  locationName: string;
}

export const getWorkplansListService = (request: any, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getAssignedWorkplansWithScheduleService = (
  request: any,
  configAction: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/getAssignedWithSchedule`, request);
};

export const getAssignedWorkplansWithDateTimeService = (
  request: any,
  configAction: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/getAssignedWithDateTime`, request);
};

export interface BulkInsertRequest {
  workplanType: number;
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  workplans: string; // JSON string
}
export const workplansBulkInsert = (request: BulkInsertRequest, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/workplansBulkInsert`, request);
};

export const deleteWorkplanService = (request: any, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.workplanName}`,
    object: 'workplans.workplan',
    action: 'Delete',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const saveWorkplanService = (request: any, configAction: ConfigActions, isNew: boolean) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.workplanName}`,
    object: 'workplans.workplan',
    action: isNew ? 'Create' : 'Edit',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteWorkplanExecutionLocationService = (
  request: deleteWorkplanExecutionLocationFilters,
  configAction: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.locationName}`,
    object: 'workplans.location',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/deleteWorkplanExecutionLocation`, request);
};

export const saveWorkplanExecutionLocationService = (request: any, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.locationName}`,
    object: 'workplans.location',
    action: 'Edit',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/saveWorkplanExecutionLocation`, request);
};
