import split from 'lodash/split';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as formSelectors from '../../screens/Forms/reducers';
import FetchEntities from '../../screens/Entities/views/FetchEntities';
import MultiSelectInput from '../MultiSelectInput';
import DropdownInput from '../DropdownInput';

const FormsDropdown = (props) => {
  const {
    id,
    name,
    forceinitialvalue,
    membership = {},
    organizationFormTypeId,
    label = undefined,
    selectFirst = false,
    isInModal = false,
    isMultiple = true,
    isClearable = false,
    required = false,
    ...inputProps
  } = props;
  const intl = useIntl();
  const { isFetching, forms } = useSelector((state) => ({
    isFetching: formSelectors.isFetchingListForms(state),
    forms: formSelectors
      .getFormsListByMembership(state, membership)
      .filter(
        (form) => !organizationFormTypeId || form.organizationFormTypeId === organizationFormTypeId
      )
      .map((form) => ({
        id: form.organizationFormId,
        value: form.organizationFormId,
        label: form.organizationFormName
      }))
  }));

  const [isSelectedFirst, setIsSelectedFirst] = useState<boolean>(false);
  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };
  //Update reactselect values of  currencies when fetched is finished
  useEffect(() => {
    if (!isFetching) {
      resetDropdown();
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isSelectedFirst && selectFirst && forms.length > 0) {
      inputProps.field.onChange(forms[0]?.id);
      setIsSelectedFirst(true);
    }
  }, [selectFirst, forms]);

  useEffect(() => {
    if (membership?.organizationId) {
      setIsSelectedFirst(false);
    }
  }, [membership?.organizationId]);

  return (
    <>
      <FetchEntities useForms />
      {isMultiple ? (
        <MultiSelectInput
          {...inputProps}
          id={id}
          key={resetValue}
          name={name}
          required={required}
          isLoading={isFetching}
          isClearable={isClearable}
          componentKey={resetValue}
          isInModal={isInModal}
          options={forms}
          label={
            label ||
            intl.formatMessage({
              id: 'forms.forms'
            })
          }
          placeholder={intl.formatMessage({ id: 'common.select' })}
        />
      ) : (
        <DropdownInput
          {...inputProps}
          id={id}
          key={resetValue}
          name={name}
          required={required}
          isLoading={isFetching}
          isClearable={isClearable}
          componentKey={resetValue}
          isInModal={isInModal}
          options={forms}
          label={
            label ||
            intl.formatMessage({
              id: 'forms.forms'
            })
          }
          placeholder={intl.formatMessage({ id: 'common.select' })}
        />
      )}
    </>
  );
};

FormsDropdown.defaultProps = {};

export default FormsDropdown;
