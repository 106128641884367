import * as types from '../types';

export const startFetchingSubdivisions = (filters = {}) => ({
  type: types.SUBDIVISIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingSubdivisions = (entities, order, operatorId, organizationId) => ({
  type: types.SUBDIVISIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingSubdivisions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SUBDIVISIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingSubdivisions = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.SUBDIVISIONS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingSubdivisions = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingSubdivisions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SUBDIVISIONS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingSubdivision = (subdivision) => ({
  type: types.SUBDIVISION_ADD_STARTED,
  payload: subdivision
});
export const completeAddingSubdivision = (oldId, subdivision) => ({
  type: types.SUBDIVISION_ADD_COMPLETED,
  payload: { oldId, subdivision }
});
export const failAddingSubdivision = (oldId, error) => ({
  type: types.SUBDIVISION_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingSubdivision = (subdivision) => ({
  type: types.SUBDIVISION_EDIT_STARTED,
  payload: subdivision
});
export const completeEditingSubdivision = (subdivision) => ({
  type: types.SUBDIVISION_EDIT_COMPLETED,
  payload: subdivision
});
export const failEditingSubdivision = (oldSubdivision, error) => ({
  type: types.SUBDIVISION_EDIT_FAILED,
  payload: {
    oldSubdivision,
    error
  }
});

export const startRemovingSubdivision = (subdivisionId) => ({
  type: types.SUBDIVISION_REMOVE_STARTED,
  payload: {
    subdivisionId
  }
});
export const completeRemovingSubdivision = (subdivisionId) => ({
  type: types.SUBDIVISION_REMOVE_COMPLETED,
  payload: {
    subdivisionId
  }
});
export const failRemovingSubdivision = (subdivisionId, error) => ({
  type: types.SUBDIVISION_REMOVE_FAILED,
  payload: {
    subdivisionId,
    error
  }
});

export const selectSubdivision = (subdivisionId) => ({
  type: types.SUBDIVISION_SELECTED,
  payload: subdivisionId
});

export const selectAllSubdivisions = (
  subdivisionIds = [] //List of SubdivisionIds
) => ({
  type: types.SUBDIVISIONS_ALL_SELECTED,
  payload: subdivisionIds
});

export const deselectSubdivision = (subdivisionId) => ({
  type: types.SUBDIVISION_DESELECTED,
  payload: subdivisionId
});

export const deselectAllSubdivisions = (
  subdivisionIds = [] //List of SubdivisionIds
) => ({
  type: types.SUBDIVISIONS_ALL_DESELECTED,
  payload: subdivisionIds
});
