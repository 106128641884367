import * as types from 'views/screens/Contacts/types';

export const startFetchingContacts = (query: any = {}) => ({
  type: types.CONTACTS_FETCH_STARTED,
  payload: { query }
});
export const completeFetchingContacts = (entities, order) => ({
  type: types.CONTACTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingContacts = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.CONTACTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingContacts = (entityTypeId) => ({
  type: types.CONTACTS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId }
});
export const completePartialFetchingContacts = (entities, order) => ({
  type: types.CONTACTS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingContacts = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CONTACTS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingContact = (contact) => ({
  type: types.CONTACT_ADD_STARTED,
  payload: contact
});
export const completeAddingContact = (oldId, contact) => ({
  type: types.CONTACT_ADD_COMPLETED,
  payload: { oldId, contact }
});
export const failAddingContact = (oldId, error) => ({
  type: types.CONTACT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingContact = (contact) => ({
  type: types.CONTACT_EDIT_STARTED,
  payload: contact
});
export const completeEditingContact = (contact) => ({
  type: types.CONTACT_EDIT_COMPLETED,
  payload: contact
});
export const failEditingContact = (oldContact, error) => ({
  type: types.CONTACT_EDIT_FAILED,
  payload: {
    oldContact,
    error
  }
});

export const startRemovingContact = (contacts, contactsName) => ({
  type: types.CONTACT_REMOVE_STARTED,
  payload: {
    contacts,
    contactsName
  }
});
export const completeRemovingContact = (contacts, contactsName) => ({
  type: types.CONTACT_REMOVE_COMPLETED,
  payload: {
    contacts,
    contactsName
  }
});
export const failRemovingContact = (contacts, error) => ({
  type: types.CONTACT_REMOVE_FAILED,
  payload: {
    contacts,
    error
  }
});

export const selectContact = (contactId) => ({
  type: types.CONTACT_SELECTED,
  payload: contactId
});

export const deselectContact = (contactId) => ({
  type: types.CONTACT_DESELECTED,
  payload: contactId
});

export const deselectAllContacts = (
  contactIds = [] //List of ContactIds
) => ({
  type: types.CONTACTS_ALL_DESELECTED,
  payload: contactIds
});
