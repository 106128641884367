import {
  Aggregate,
  AggregateColumnDirective,
  AggregateColumnsDirective,
  AggregateDirective,
  AggregatesDirective,
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter,
  Freeze,
  GridComponent,
  Group,
  Inject,
  Page,
  Reorder,
  Resize,
  RowDD,
  Search,
  Sort,
  Toolbar,
  VirtualScroll
} from '@syncfusion/ej2-react-grids';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { omit } from 'lodash';
import { FC, Key, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Spinner } from 'reactstrap';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
// ** Internationalization Context
import { ToolbarItems } from '@syncfusion/ej2-react-grids';
import { TableColumn } from 'utility/types';
import * as authSelectors from '../../../redux/reducers/auth';
import { IntlContext } from '../../../utility/context/Internationalization';

/**
 * Componente "tonto" para mostrar tablas en catálogos
 * Props:
 *  columns:
 *    Una lista de objetos con la información de las columnas, cada objeto debe tener fieldName y columnName
 *    Por defecto, la tabla ordenará según el primer parámetro, para usar el orden por defecto debe pasarse el prop allowSorting=false
 *  data:
 *    Una lista de objetos con la información a presentar
 *  onSelect:
 *    Función para manejar la acción cuando se selecciona un ítem, recibe un parámetro, el objeto seleccionado
 *  onDeselect:
 *    Equivalente a onSelect, maneja la acción de deseleccionar
 */

interface SortedColumn {
  sortedColumnIndex: number;
  direction: 'Ascending' | 'Descending';
}

interface Props {
  setTableRef?: (value: GridComponent | null) => void;
  id?: string;
  version?: number;
  enablePersistence?: boolean;
  toolbarOptions?: ToolbarItems[];
  toolbarClick?: any;
  omitPersistenceKeys?: string[];
  enableVirtualization?: boolean;
  rerender?: Date | Key;
  selectAll?: Date | Key;
  deselectAll?: Date | Key;
  columns: TableColumn[];
  initialFilterColumns?: any[];
  sortSettings?: any;
  sortedColumn?: SortedColumn;
  data: any[];
  actionSave?: (value: any) => any;
  onDrop?: Function;
  onSelect?: Function;
  onDeselect?: Function;
  selected?: any;
  changeSelected?: Function;
  rerenderSelected?: Date;
  isFetching?: boolean;
  allowSorting?: boolean;
  allowRowDragAndDrop?: boolean;
  allowPaging?: boolean;
  pageSize?: number;
  allowResizing?: boolean;
  allowReordering?: boolean;
  allowFiltering?: boolean;
  allowMultipleSelection?: boolean;
  allowExcelExport?: boolean;
  initialSelectedRowIndex?: number;
  persistSelectIndexes?: boolean;
  frozenColumns?: number;
  height?: number;
  allowGrouping?: boolean;
  groupSettings?: any;
  allowDeleting?: boolean;
}

const Table: FC<Props> = (props) => {
  const { isFetching } = props;

  return !isFetching ? (
    <TableComponent {...props} />
  ) : (
    <Row>
      <Col sm="12" className="grid-spinner">
        <Spinner color="primary" size="lg" />
      </Col>
    </Row>
  );
};
const TableComponent = (props: Props) => {
  const {
    id,
    version,
    enablePersistence,
    omitPersistenceKeys,
    enableVirtualization,
    columns,
    rerender,
    selectAll,
    deselectAll,
    initialFilterColumns,
    sortSettings = undefined,
    data,
    actionSave,
    onDrop,
    onSelect,
    onDeselect,
    selected,
    changeSelected,
    rerenderSelected,
    allowSorting,
    allowRowDragAndDrop,
    allowPaging,
    pageSize,
    allowResizing,
    allowReordering,
    allowFiltering,
    allowMultipleSelection,
    sortedColumn,
    frozenColumns,
    initialSelectedRowIndex,
    persistSelectIndexes,
    height,
    toolbarOptions,
    allowExcelExport,
    toolbarClick,
    setTableRef,
    allowGrouping,
    groupSettings
  } = props;

  const [state, changeState] = useState(Object);
  const [show, setShow] = useState<boolean>(true);
  const [locale, setLocale] = useState<any>();
  const stateRef = useRef(state);
  /**
   * Key must be a React.Key type
   * when its a date, javascript automatically converts to key
   */
  const [key, changeKey] = useState<Date | Key>(new Date());
  const [tooltip, setTooltip] = useState<TooltipComponent | null>(null);
  const [tableColumns, setTableColumns] = useState<any[]>([]);
  const [selectedRowIndexes, setSelectedRowIndexes] = useState<any[]>([]);
  const [scrollLeft, setScrollLeft] = useState<any>(null);
  const intlContext = useContext(IntlContext);
  const dataTable = data?.map((row, i) => ({
    ...row,
    dataRowTableId: i
  }));

  const { user, authSystems } = useSelector((state) => ({
    user: authSelectors?.getAuthUser(state),
    authSystems: authSelectors?.getAuthSystems(state)
  }));

  useEffect(() => {
    //Elimina del localStorage las keys que se quieren eliminar
    try {
      if (id && enablePersistence && omitPersistenceKeys && omitPersistenceKeys?.length > 0) {
        const value = window?.localStorage?.getItem('grid' + id);
        if (value) {
          const savedVersion = parseInt(
            JSON?.parse(window?.localStorage?.getItem('grid' + id + '_version') || '0')
          );
          if ((version || 0) === savedVersion) {
            const model = omit(JSON?.parse(value), omitPersistenceKeys);
            window?.localStorage?.setItem('grid' + id, JSON?.stringify(model));
          } else {
            window?.localStorage?.removeItem('grid' + id);
            window?.localStorage?.setItem('grid' + id + '_version', JSON?.stringify(version));
          }
        }
      }
    } catch (error) {
      console.warn('1');
      console.warn(error);
    }
  }, []);

  useEffect(() => {
    try {
      if (state) {
        stateRef.current = state;
      }
      //Al cargar por primera vez la tabla se realiza un autoFit de todas las columnas
      if (state?.headerModule && state?.autoFitColumns) {
        state?.autoFitColumns();
      }
    } catch (error) {
      console.warn('2');
      console.warn(error);
    }
  }, [state]);

  useEffect(() => {
    //Se setean la columna que se quiere ordenar en la tabla
    try {
      if (
        state?.headerModule &&
        state?.sortSettings &&
        sortedColumn?.sortedColumnIndex &&
        tableColumns?.length > 0 &&
        (state?.sortSettings?.columns?.length === 0 ||
          tableColumns[sortedColumn?.sortedColumnIndex]?.fieldName !==
            state?.sortSettings?.columns[0]?.field ||
          sortedColumn.direction !== state?.sortSettings?.columns[0]?.direction)
      ) {
        state.sortSettings.columns = [
          {
            field: tableColumns[sortedColumn?.sortedColumnIndex]?.fieldName,
            direction: sortedColumn?.direction
          }
        ];
        reset();
      }
    } catch (error) {
      console.warn('3');
      console.warn(error);
    }
  }, [state?.sortSettings, tableColumns?.length, sortedColumn?.sortedColumnIndex]);

  useDeepCompareEffect(() => {
    //Se restringe dependiendo del nivel de acceso del usuario
    try {
      if (columns?.length > 0) {
        if (user?.accessLevelId == 11 || user?.accessLevelId == 13) {
          setTableColumns(
            columns
              ?.filter((column) => column !== undefined && column !== null)
              ?.filter(
                (column) => column?.fieldName && !['operatorName']?.includes(column?.fieldName)
              )
          );
        } else if (user?.accessLevelId == 7) {
          setTableColumns(
            columns
              ?.filter((column) => column !== undefined && column !== null)
              ?.filter(
                (column) =>
                  column?.fieldName &&
                  !['operatorName', 'organizationName']?.includes(column?.fieldName)
              )
          );
        } else if (user?.accessLevelId == 4) {
          setTableColumns(
            columns
              ?.filter((column) => column !== undefined && column !== null)
              ?.filter(
                (column) =>
                  column?.fieldName &&
                  !['operatorName', 'organizationName', 'divisionName']?.includes(column?.fieldName)
              )
          );
        } else if (user?.accessLevelId == 2 || user?.accessLevelId == 20) {
          setTableColumns(
            columns
              ?.filter((column) => column !== undefined && column !== null)
              ?.filter(
                (column) =>
                  column?.fieldName &&
                  ![
                    'operatorName',
                    'organizationName',
                    'divisionName',
                    'subdivisionName'
                  ]?.includes(column?.fieldName)
              )
          );
        } else if (user?.accessLevelId == 15 || user?.accessLevelId == 9) {
          setTableColumns(columns?.filter((column) => column !== undefined && column !== null));
        }
      }
    } catch (error) {
      //
      console.warn('4');
      console.warn(error);
    }
  }, [user, columns?.length]);

  useEffect(() => {
    try {
      if (
        selected &&
        changeSelected &&
        dataTable?.length > 0 &&
        typeof state?.getFilteredRecords === 'function'
      ) {
        if (state?.selectRowsByRange) state?.selectRowsByRange(0, dataTable?.length);
        var selectedRows: any[] = [];
        selectedRows = state?.getFilteredRecords();
        if (selectedRows?.length === 0) {
          const input = document?.getElementById(`${id}_searchbar`);
          if (input) {
            selectedRows = dataTable?.filter((data) =>
              tableColumns
                ?.filter((column) => typeof data[column?.fieldName] === 'string')
                ?.map((column) =>
                  data[column?.fieldName]
                    ?.toLowerCase()
                    ?.trim()
                    ?.includes(input['value']?.toLowerCase()?.trim())
                )
                ?.includes(true)
            );
          } else {
            selectedRows = [
              ...selected,
              ...dataTable?.filter(
                (row, i) => !selected?.map((row) => row?.dataRowTableId)?.includes(i)
              )
            ];
          }
        }
        changeSelected(selectedRows);
      }
    } catch (error) {
      console.warn('5');
      console.warn(error);
    }
  }, [selectAll]);

  useEffect(() => {
    try {
      if (state?.clearSelection) state?.clearSelection();
      if (selected && changeSelected) {
        changeSelected([]);
      }
    } catch (error) {
      console.warn('6');
      console.warn(error);
    }
  }, [deselectAll]);

  // useEffect(() => {
  //   try {
  //     if (persistSelectIndexes) {
  //       if (state?.selectionModule?.selectedRowIndexes?.length > 0) {
  //         setSelectedRowIndexes([state?.selectionModule?.selectedRowIndexes, false]);
  //       }
  //     }
  //     reset();
  //   } catch (error) {
  //     console.warn('7');
  //     console.warn(error);
  //   }
  // }, [dataTable]);

  useEffect(() => {
    try {
      if (locale) {
        setShow(false);
        setTimeout(() => setShow(true), 1000);
      } else {
        setLocale(intlContext?.locale);
      }
    } catch (error) {
      console.warn('8');
      console.warn(error);
    }
  }, [intlContext?.locale]);

  const compareDeps = tableColumns?.map((col) => ({
    fieldName: col?.fieldName,
    headerText: col?.headerText,
    hide: col?.hide
  }));
  useDeepCompareEffect(() => {
    try {
      console.log('rerender by tableColumns map');
      reset();
      if (rerender) setTimeout(() => changeKey(rerender), 200);
    } catch (error) {
      console.warn('9');
      console.warn(error);
    }
  }, [rerender, compareDeps]);

  useEffect(() => {
    try {
      if (state?.selectRows && selected)
        state?.selectRows(
          selected?.map((object) => {
            return object?.dataRowTableId;
          })
        );
      if (changeSelected) changeSelected(selected);
    } catch (error) {
      console.warn('10');
      console.warn(error);
    }
  }, [rerenderSelected]);

  const reset = () => {
    if (scrollLeft) {
      setTimeout(() => {
        stateRef.current.scrollModule['content'].scrollLeft = scrollLeft;
        setScrollLeft(null);
      }, 25);
    }
    /*  if (state?.scrollModule?.content?.scrollTop > 0) state.scrollModule.content.scrollTop = 0; */
    if (state?.refresh) state?.refresh();
    const select: any[] = selected;
    if (selected && changeSelected) changeSelected([]);
  };

  const select = (event: any) => {
    if (selected && changeSelected) {
      if (Array?.isArray(event?.data)) {
        if (event?.data?.length > 0)
          changeSelected([
            ...selected,
            ...event?.data?.filter(
              (row) => !selected?.map((row) => row?.dataRowTableId)?.includes(row?.dataRowTableId)
            )
          ]);
        else
          changeSelected([
            ...selected,
            ...dataTable?.filter(
              (row, i) =>
                event?.rowIndexes?.includes(i) &&
                !selected?.map((row) => row?.dataRowTableId)?.includes(row?.dataRowTableId)
            )
          ]);
      } else if (event?.data === undefined)
        changeSelected([...selected, ...dataTable?.filter((row, i) => i === event?.rowIndex)]);
      else changeSelected([...selected, event?.data]);
      if (onSelect) onSelect(event);
    }
  };

  const deselect = (event: any) => {
    if (selected && changeSelected) {
      if (Array?.isArray(event?.data))
        changeSelected(
          selected?.filter(
            (row) => !event?.data?.map((row) => row?.dataRowTableId)?.includes(row?.dataRowTableId)
          )
        );
      else
        changeSelected(
          selected?.filter(
            (row) =>
              row?.dataRowTableId !==
              (event?.data?.dataRowTableId ?? dataTable[event?.rowIndex]?.dataRowTableId)
          )
        );
      if (onDeselect) onDeselect(event);
    }
  };

  const toolbar: ToolbarItems[] | undefined =
    toolbarOptions ??
    (tableColumns && tableColumns?.some((column) => column.allowEditing) ? undefined : ['Search']);

  return show ? (
    <TooltipComponent
      target=".e-rowcell.hasTooltip"
      ref={(t) => {
        if (t) {
          setTooltip(t);
        }
      }}
      beforeRender={(arg) => {
        if (arg && tooltip) {
          const target = arg?.target;
          const idColumnTooltip = target?.id;
          const indexColumnTooltip = target?.getAttribute('index') || 0;
          const parent =
            arg?.target?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement
              ?.children[1]?.children[0]?.children[1]?.children[indexColumnTooltip] ||
            arg?.target?.parentElement;
          if (idColumnTooltip && parent) {
            if (tableColumns[idColumnTooltip]?.tooltipColumn) {
              for (let index = 0; index < parent?.childElementCount; index++) {
                const children = parent?.children[index];
                const columnTitle = children?.getAttribute('aria-label');
                if (
                  columnTitle &&
                  children?.classList?.contains('e-hide') &&
                  children
                    ?.getAttribute('aria-label')
                    ?.includes(tableColumns[idColumnTooltip]?.tooltipColumn)
                ) {
                  tooltip.content = children?.innerHTML;
                  tooltip.cssClass = children?.innerHTML ? '' : 'hidden-tooltip';
                }
              }
            } else {
              tooltip.content = tableColumns[idColumnTooltip]?.tooltipText;
              tooltip.cssClass = tableColumns[idColumnTooltip]?.tooltipText ? '' : 'hidden-tooltip';
            }
          }
        }
      }}
    >
      {tableColumns?.length > 0 && (
        <GridComponent
          id={id}
          key={key?.toString()}
          showColumnChooser={true}
          height={allowPaging ? undefined : height}
          cellSave={(event: any) => {
            try {
              if (event && event?.name === 'cellSave') {
                const columns = event?.rowData;
                columns[event?.columnName] = event?.value;
                setScrollLeft(state?.scrollModule?.content?.scrollLeft);
                if (actionSave) actionSave(columns);
              }
            } catch (error) {
              console.warn('cs');
              console.warn(error);
            }
          }}
          rowDrop={(event) => {
            try {
              if (onDrop) onDrop(event);
            } catch (error) {
              console.warn('rd');
              console.warn(error);
            }
          }}
          enablePersistence={enablePersistence}
          enableVirtualization={
            tableColumns &&
            (tableColumns?.some((column) => column?.allowEditing) || dataTable?.length <= 100)
              ? false
              : enableVirtualization
          }
          locale={intlContext?.locale === 'es' ? 'es-US' : intlContext?.locale}
          dataSource={dataTable?.map((row, i) => ({
            ...row,
            ...tableColumns
              ?.filter((col) => col?.type === 'dateTime')
              ?.reduce(
                (obj, item) =>
                  Object?.assign(obj, {
                    [item?.fieldName]: row[item?.fieldName] ? new Date(row[item?.fieldName]) : null
                  }),
                {}
              )
          }))}
          gridLines={'Horizontal'}
          rowSelected={(event) => {
            select(event);
          }}
          rowDeselected={(event) => {
            deselect(event);
          }}
          ref={(ref) => {
            if (setTableRef) setTableRef(ref);
            changeState(ref);
          }}
          allowPaging={allowPaging}
          pageSettings={{
            pageSize: enableVirtualization ? 100 : pageSize,
            currentPage: initialSelectedRowIndex
              ? Math?.ceil((initialSelectedRowIndex + 1) / (pageSize ?? 15))
              : undefined
          }}
          allowResizing={allowResizing}
          allowRowDragAndDrop={allowRowDragAndDrop}
          allowExcelExport={allowExcelExport}
          allowReordering={allowReordering}
          allowFiltering={allowFiltering}
          filterSettings={{
            columns: initialFilterColumns,
            type: 'Menu'
          }}
          allowSorting={allowSorting}
          sortSettings={
            sortSettings
              ? sortSettings
              : tableColumns[0]
              ? {
                  columns: [{ field: tableColumns[0]?.fieldName, direction: 'Ascending' }]
                }
              : undefined
          }
          toolbarClick={toolbarClick}
          className={toolbar && toolbar?.length > 0 ? 'grid-round-header' : 'grid-round-header'}
          toolbar={toolbar}
          resizeSettings={{
            mode: 'Auto'
          }}
          selectionSettings={
            allowMultipleSelection
              ? {
                  enableSimpleMultiRowSelection: true,
                  type: 'Multiple'
                }
              : undefined
          }
          selectedRowIndex={
            initialSelectedRowIndex != null ? initialSelectedRowIndex % (pageSize ?? 15) : undefined
          }
          dataBound={() => {
            try {
              if (selectedRowIndexes?.length > 0 && selectedRowIndexes[0]?.length > 0) {
                if (selectedRowIndexes[1]) {
                  state?.selectRows(selectedRowIndexes[0]);
                  setSelectedRowIndexes([]);
                } else setSelectedRowIndexes([selectedRowIndexes[0], true]);
              }
            } catch (error) {
              console.warn('dbound');
              console.warn(error);
            }
          }}
          editSettings={{
            allowEditing:
              toolbarOptions?.includes('Edit') ||
              (tableColumns && tableColumns?.some((column) => column?.allowEditing)),
            allowAdding: toolbarOptions?.includes('Add'),
            allowDeleting: toolbarOptions?.includes('Delete'),
            mode: 'Batch'
          }}
          groupSettings={groupSettings}
          allowGrouping={allowGrouping}
        >
          <ColumnsDirective>
            {tableColumns?.map((column, i) =>
              column?.columns && column?.columns?.length > 0 ? (
                <ColumnDirective
                  customAttributes={column?.customAttributes}
                  headerText={
                    (column?.staticText ||
                      (column?.headerText
                        ? appIntl()?.formatMessage({ id: column?.headerText })
                        : '')) +
                    (column?.metric
                      ? column?.staticText || column?.headerText
                        ? ' (' +
                          (authSystems[column?.metric] !== undefined
                            ? authSystems[column?.metric]
                            : column?.metric) +
                          ')'
                        : authSystems[column?.metric] !== undefined
                        ? authSystems[column?.metric]
                        : column?.metric
                      : '')
                  }
                  key={column?.fieldName || column?.customKey}
                  columns={column?.columns?.map((stackedColumn, j) =>
                    stackedColumn?.columns && stackedColumn?.columns?.length > 0
                      ? {
                          autoFit: true,
                          customAttributes: stackedColumn?.customAttributes,
                          headerText:
                            (stackedColumn?.staticText ||
                              (stackedColumn?.headerText
                                ? appIntl()?.formatMessage({ id: stackedColumn?.headerText })
                                : '')) +
                            (stackedColumn?.metric
                              ? stackedColumn?.staticText || stackedColumn?.headerText
                                ? ' (' +
                                  (authSystems[stackedColumn?.metric] !== undefined
                                    ? authSystems[stackedColumn?.metric]
                                    : stackedColumn?.metric) +
                                  ')'
                                : authSystems[stackedColumn?.metric] !== undefined
                                ? authSystems[stackedColumn?.metric]
                                : stackedColumn?.metric
                              : ''),
                          key: stackedColumn?.fieldName || stackedColumn?.customKey,
                          type: stackedColumn.type || 'string',
                          visible: stackedColumn?.hide !== true,
                          columns: stackedColumn?.columns?.map((stackedStackedColumn) => ({
                            autoFit: true,
                            key: stackedStackedColumn?.fieldName,
                            field: stackedStackedColumn?.fieldName,
                            maxWidth: stackedStackedColumn?.maxWidth,
                            minWidth: stackedStackedColumn?.minWidth,
                            // commandsTemplate: stackedColumn.template,
                            headerText:
                              (stackedStackedColumn?.staticText ||
                                (stackedStackedColumn?.headerText
                                  ? appIntl()?.formatMessage({
                                      id: stackedStackedColumn?.headerText
                                    })
                                  : '')) +
                              (stackedStackedColumn?.metric
                                ? stackedColumn?.staticText || stackedStackedColumn?.headerText
                                  ? ' (' +
                                    (authSystems[stackedStackedColumn?.metric] !== undefined
                                      ? authSystems[stackedStackedColumn?.metric]
                                      : stackedStackedColumn?.metric) +
                                    ')'
                                  : authSystems[stackedStackedColumn?.metric] !== undefined
                                  ? authSystems[stackedStackedColumn?.metric]
                                  : stackedStackedColumn?.metric
                                : ''),
                            allowEditing: stackedStackedColumn?.allowEditing ?? false,
                            isPrimaryKey: stackedStackedColumn?.isPrimaryKey,
                            editType:
                              stackedStackedColumn?.editType ??
                              (stackedStackedColumn?.type === 'number'
                                ? 'numericedit'
                                : stackedStackedColumn?.type === 'boolean'
                                ? 'booleanedit'
                                : stackedStackedColumn?.type === 'dateTime'
                                ? 'datetimeedit'
                                : 'stringedit'),
                            editTemplate: stackedStackedColumn?.editTemplate ?? null,
                            headerTemplate: stackedStackedColumn?.headerTemplate,
                            format: stackedStackedColumn?.formatColumn,
                            type: stackedStackedColumn?.type || 'string',
                            displayAsCheckBox: stackedStackedColumn?.type === 'boolean',
                            template: stackedStackedColumn?.template,
                            customAttributes: stackedStackedColumn?.customAttributes
                              ? stackedStackedColumn?.customAttributes
                              : {
                                  class: stackedStackedColumn?.hasTooltip
                                    ? 'hasTooltip'
                                    : 'withoutTooltip',
                                  id: i + '-' + j
                                },
                            visible: stackedStackedColumn?.hide !== true,
                            freeze: j < (frozenColumns ?? -1) ? 'Left' : undefined,
                            // valueAccessor: (field, data) =>
                            //   stackedColumn.format ? stackedColumn.format(data[field]) : data[field],
                            edit: stackedStackedColumn?.edit,
                            textAlign: stackedStackedColumn?.textAlign
                              ? stackedStackedColumn?.textAlign
                              : 'Left'
                          }))
                        }
                      : {
                          autoFit: true,
                          key: stackedColumn?.fieldName,
                          field: stackedColumn?.fieldName,
                          maxWidth: stackedColumn?.maxWidth,
                          minWidth: stackedColumn?.minWidth,
                          // commandsTemplate: stackedColumn.template,
                          headerText:
                            (stackedColumn?.staticText ||
                              (stackedColumn?.headerText
                                ? appIntl()?.formatMessage({ id: stackedColumn?.headerText })
                                : '')) +
                            (stackedColumn?.metric
                              ? stackedColumn?.staticText || stackedColumn?.headerText
                                ? ' (' +
                                  (authSystems[stackedColumn?.metric] !== undefined
                                    ? authSystems[stackedColumn?.metric]
                                    : stackedColumn?.metric) +
                                  ')'
                                : authSystems[stackedColumn?.metric] !== undefined
                                ? authSystems[stackedColumn?.metric]
                                : stackedColumn?.metric
                              : ''),
                          allowEditing: stackedColumn?.allowEditing ?? false,
                          isPrimaryKey: stackedColumn?.isPrimaryKey,
                          editType:
                            stackedColumn?.editType ??
                            (stackedColumn?.type === 'number'
                              ? 'numericedit'
                              : stackedColumn?.type === 'boolean'
                              ? 'booleanedit'
                              : stackedColumn?.type === 'dateTime'
                              ? 'datetimeedit'
                              : 'stringedit'),
                          editTemplate: stackedColumn?.editTemplate ?? null,
                          headerTemplate: stackedColumn?.headerTemplate,
                          format: stackedColumn?.formatColumn,
                          type: stackedColumn?.type || 'string',
                          displayAsCheckBox: stackedColumn?.type === 'boolean',
                          template: stackedColumn?.template,
                          customAttributes: column?.customAttributes
                            ? {
                                class: 'side-borders-columns-subColumns',
                                id: i + '-' + j
                              }
                            : {
                                class: column?.hasTooltip ? 'hasTooltip' : 'withoutTooltip',
                                id: i + '-' + j
                              },
                          visible: stackedColumn?.hide !== true,
                          freeze: j < (frozenColumns ?? -1) ? 'Left' : undefined,
                          // valueAccessor: (field, data) =>
                          //   stackedColumn?.format ? stackedColumn.format(data[field]) : data[field],
                          edit: stackedColumn?.edit,
                          textAlign: stackedColumn?.textAlign ? stackedColumn?.textAlign : 'Left'
                        }
                  )}
                ></ColumnDirective>
              ) : (
                <ColumnDirective
                  autoFit={true}
                  key={column?.fieldName ?? column?.customKey}
                  field={column?.fieldName}
                  maxWidth={column?.maxWidth}
                  minWidth={column?.minWidth}
                  // commandsTemplate={column.template}
                  headerText={
                    (column?.staticText ||
                      (column?.headerText
                        ? appIntl()?.formatMessage({ id: column?.headerText })
                        : '')) +
                    (column?.metric
                      ? column?.staticText || column?.headerText
                        ? ' (' +
                          (authSystems[column?.metric] !== undefined
                            ? authSystems[column?.metric]
                            : column?.metric) +
                          ')'
                        : authSystems[column?.metric] !== undefined
                        ? authSystems[column?.metric]
                        : column?.metric
                      : '')
                  }
                  allowEditing={column?.allowEditing ?? false}
                  isPrimaryKey={column?.isPrimaryKey}
                  editType={
                    column?.editType ??
                    (column?.type === 'number'
                      ? 'numericedit'
                      : column?.type === 'boolean'
                      ? 'booleanedit'
                      : column?.type === 'dateTime'
                      ? 'datetimeedit'
                      : 'stringedit')
                  }
                  editTemplate={column?.editTemplate ?? null}
                  headerTemplate={column?.headerTemplate}
                  format={column?.formatColumn}
                  type={column?.type || 'string'}
                  displayAsCheckBox={column?.type === 'boolean'}
                  template={column?.template}
                  customAttributes={
                    column?.customAttributes
                      ? column?.customAttributes
                      : {
                          class: column?.hasTooltip ? 'hasTooltip' : 'withoutTooltip',
                          id: i
                        }
                  }
                  visible={column?.hide !== true}
                  freeze={i < (frozenColumns ?? -1) ? 'Left' : undefined}
                  valueAccessor={(field, data) =>
                    column?.format ? column?.format(data[field]) : data[field]
                  }
                  edit={column?.edit}
                  textAlign={column?.textAlign ? column?.textAlign : 'Left'}
                ></ColumnDirective>
              )
            )}
            {tableColumns
              ?.filter((column) => column?.tooltipColumn)
              ?.map((column) => (
                <ColumnDirective
                  customAttributes={column?.customAttributes}
                  autoFit={true}
                  key={column?.tooltipColumn}
                  field={column?.tooltipColumn}
                  template={column?.tooltipTemplate}
                  visible={false}
                ></ColumnDirective>
              ))}
          </ColumnsDirective>
          {frozenColumns === 0 && (
            <AggregatesDirective>
              <AggregateDirective>
                {tableColumns &&
                tableColumns?.filter((column) => column?.aggregateType)?.length > 0 ? (
                  <AggregateColumnsDirective>
                    {tableColumns
                      ?.filter((column) => column?.aggregateType)
                      ?.map((column) => (
                        <AggregateColumnDirective
                          key={column?.fieldName}
                          field={column?.fieldName}
                          type={column?.aggregateType}
                          customAggregate={column?.customAggregateFn || undefined}
                          footerTemplate={(props) => (
                            <span>
                              {appIntl()?.formatMessage({
                                id: column?.aggregateText || 'common.table' + column?.aggregateType
                              })}
                              :{' '}
                              {column?.aggregateType === 'Average'
                                ? props[column?.aggregateType]?.toFixed(2)
                                : props[column?.aggregateType]}
                            </span>
                          )}
                        />
                      ))}
                  </AggregateColumnsDirective>
                ) : (
                  <AggregateColumnsDirective>
                    <AggregateColumnDirective
                      field={tableColumns[0] ? tableColumns[0]?.fieldName : undefined}
                      type={'Count'}
                      footerTemplate={(props) => (
                        <span>
                          {appIntl()?.formatMessage({ id: 'common.tableCount' })}: {props?.Count}
                        </span>
                      )}
                    />
                  </AggregateColumnsDirective>
                )}
              </AggregateDirective>
            </AggregatesDirective>
          )}
          <Inject
            services={[
              Resize,
              Filter,
              Sort,
              Page,
              Toolbar,
              Search,
              Freeze,
              Reorder,
              Aggregate,
              Edit,
              RowDD,
              VirtualScroll,
              ExcelExport,
              ColumnChooser,
              Group
            ]}
          />
        </GridComponent>
      )}
    </TooltipComponent>
  ) : (
    <Row>
      <Col sm="12" className="grid-spinner">
        <Spinner color="primary" size="lg" />
      </Col>
    </Row>
  );
};

Table.defaultProps = {
  enablePersistence: true,
  omitPersistenceKeys: [
    'selectedRowIndex',
    'searchSettings',
    'filterSettings',
    'scrollPosition',
    'pageSettings'
  ],
  enableVirtualization: false,
  isFetching: false,
  allowSorting: true,
  allowPaging: true,
  pageSize: 15,
  allowRowDragAndDrop: false,
  allowResizing: true,
  allowFiltering: true,
  allowReordering: true,
  sortedColumn: {
    sortedColumnIndex: 0,
    direction: 'Ascending'
  },
  allowMultipleSelection: false,
  frozenColumns: 0,
  height: 450,
  allowDeleting: false,
  allowGrouping: false
};

export default Table;
