/* -------------------------------------------------------------------------- */
/*                  Componente SlidingPaneDowntimeBinnacle                    */
/* -------------------------------------------------------------------------- */
// Este componente permite ver la bitácora de cualquier entidad

import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Spinner, Card, Media, CardText, Button, Row, Col, Badge } from 'reactstrap';
import Avatar from '../../../@core/components/avatar';
import moment from 'moment';
import { Send } from 'react-feather';
import { useState, useEffect, useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import useGetDowntimeBinnacle from '../../screens/Units/hooks/useGetDowntimeBinnacle';
import Table from 'views/components/Table';
import Toolbar from 'views/components/Toolbar';
import ReportViewerButton from 'views/components/ReportViewerButton';
import DateInput from 'views/formComponents/DateInput';
import { BroadcastChannel } from 'broadcast-channel';
import * as authSelectors from '../../../redux/reducers/auth';
import * as unitSelectors from '../../screens/Units/reducers';

//COLUMNAS DE LA TABLA
const columnInfo = [
  {
    fieldName: 'startDate',
    headerText: 'units.startDate'
  },
  {
    fieldName: 'endDate',
    headerText: 'units.endDate'
  },
  {
    fieldName: 'startUserName',
    headerText: 'units.startUser'
  },
  {
    fieldName: 'endUserName',
    headerText: 'units.endUser'
  },
  {
    fieldName: 'downtimeReasonDescription',
    headerText: 'units.reason'
  }
];

interface Props {
  unitId?: number | null;
  unitName?: string;
  isOpenSlidingPane: boolean;
  closeSlidingPaneDowntimeBinnacle(): void;
  initialDateRange?: string[];
}

const SlidingPaneDowntimeBinnacle = (props: Props) => {
  const {
    unitId,
    unitName,
    isOpenSlidingPane,
    closeSlidingPaneDowntimeBinnacle,
    initialDateRange = []
  } = props;
  const intl = useIntl();
  const { control, watch, reset } = useForm();
  const formValues = watch();

  const user = useSelector(authSelectors.getUser);
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  const isEditingDowntime = useSelector((state) => unitSelectors.isEditingUnitDowntime(state));

  const [isReportOpen, setIsReportOpen] = useState(false);

  //CANAL DE COMUNICACION PARA EL VISOR DE REPORTE
  const channelName = 'report_unitDowntimeBinnacle';
  const channel = useMemo(() => new BroadcastChannel(channelName), []);

  const { downtimeBinnacle, isFetchingDowntimeBinnacle } = useGetDowntimeBinnacle(
    unitId,
    formValues?.dateRange && typeof formValues?.dateRange !== 'string'
      ? formValues?.dateRange[0]
      : undefined,
    formValues?.dateRange && typeof formValues?.dateRange !== 'string'
      ? formValues?.dateRange[1]
      : undefined,
    isEditingDowntime
  );

  const reportDetail = [
    {
      value: downtimeBinnacle.map((binnacle) => ({
        ...binnacle,
        startDate: moment(binnacle.startDate).format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(binnacle.endDate).format('YYYY-MM-DD HH:mm:ss')
      })),
      name: 'reportDetail'
    },
    {
      value: [
        {
          currentDate: moment().startOf('day').format('DD/MM/YYYY'),
          userName: user?.completeName,
          startDateRange: formValues?.dateRange ? formValues?.dateRange[0].substr(0, 10) : '',
          endDateRange: formValues?.dateRange ? formValues?.dateRange[1].substr(0, 10) : '',
          unitName: unitName,
          tableHeaderColor: primary,
          partnerLogo: 'https://www.webtrack.online/desarrollo/reloaded/images/logo.png'
        }
      ],
      name: 'infoHeaderDataSet'
    }
  ];

  useEffect(() => {
    channel.postMessage({
      reportDetail: reportDetail,
      reportPath: 'UnitDowntimeBinnacle.rdl'
    });
  }, [isReportOpen]);

  useEffect(() => {
    channel.addEventListener('message', (e) => {
      setIsReportOpen(e.isReportOpen);
    });
    reset({
      ...formValues,
      dateRange:
        initialDateRange !== undefined &&
        initialDateRange !== null &&
        typeof initialDateRange !== 'string' &&
        initialDateRange.length === 2
          ? [initialDateRange[0], initialDateRange[1]]
          : [
              moment().startOf('day').format('YYYY-MM-DD HH:mm'),
              moment().endOf('day').format('YYYY-MM-DD HH:mm')
            ]
    });
  }, []);

  useEffect(() => {
    if (
      initialDateRange !== undefined &&
      initialDateRange !== null &&
      typeof initialDateRange !== 'string' &&
      initialDateRange.length === 2
    ) {
      reset({
        ...formValues,
        dateRange: [initialDateRange[0], initialDateRange[1]]
      });
    }
  }, [initialDateRange]);

  return unitId && unitName ? (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: 'trackingUnits.downtimeBinnacle' })}
      subtitle={unitName}
      width={'55rem'}
      onRequestClose={() => {
        closeSlidingPaneDowntimeBinnacle();
        reset({
          ...formValues,
          dateRange:
            initialDateRange !== undefined &&
            initialDateRange !== null &&
            typeof initialDateRange !== 'string' &&
            initialDateRange.length === 2
              ? [initialDateRange[0], initialDateRange[1]]
              : [
                  moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                  moment().endOf('day').format('YYYY-MM-DD HH:mm')
                ]
        });
      }}
    >
      <>
        <Row>
          <Col sm="6">
            <Controller
              control={control}
              name="dateRange"
              defaultValue={
                initialDateRange !== undefined &&
                initialDateRange !== null &&
                typeof initialDateRange !== 'string' &&
                initialDateRange.length === 2
                  ? [initialDateRange[0], initialDateRange[1]]
                  : [
                      moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                      moment().endOf('day').format('YYYY-MM-DD HH:mm')
                    ]
              }
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <DateInput
                  field={field}
                  fieldstate={fieldState}
                  required
                  maxDate={moment().endOf('day').format('YYYY-MM-DD HH:mm')}
                  isRange
                />
              )}
            />
          </Col>
        </Row>
        <Toolbar
          buttons={[
            <ReportViewerButton
              tooltipMessage={'reports.seeReport'}
              channelName={channelName}
              handleClick={() => {}}
            />
          ]}
        />
        <Table
          id={'tableDowntimeBinnacle'}
          columns={columnInfo}
          isFetching={isFetchingDowntimeBinnacle}
          data={downtimeBinnacle.map((binnacle) => ({
            ...binnacle,
            startDate: moment(binnacle.startDate).format('YYYY-MM-DD HH:mm:ss'),
            endDate: binnacle.endDate
              ? moment(binnacle.endDate).format('YYYY-MM-DD HH:mm:ss')
              : intl.formatMessage({ id: 'units.inProgress' })
          }))}
          enablePersistence={false}
        />
      </>
    </SlidingPane>
  ) : null;
};

SlidingPaneDowntimeBinnacle.defaultProps = {};

export default SlidingPaneDowntimeBinnacle;
