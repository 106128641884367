export const INSURANCE_AGENTS_FETCH_STARTED = 'INSURANCE_AGENTS_FETCH_STARTED';
export const INSURANCE_AGENTS_FETCH_COMPLETED = 'INSURANCE_AGENTS_FETCH_COMPLETED';
export const INSURANCE_AGENTS_FETCH_FAILED = 'INSURANCE_AGENTS_FETCH_FAILED';

export const INSURANCE_AGENTS_PARTIAL_FETCH_STARTED = 'INSURANCE_AGENTS_PARTIAL_FETCH_STARTED';
export const INSURANCE_AGENTS_PARTIAL_FETCH_COMPLETED = 'INSURANCE_AGENTS_PARTIAL_FETCH_COMPLETED';
export const INSURANCE_AGENTS_PARTIAL_FETCH_FAILED = 'INSURANCE_AGENTS_PARTIAL_FETCH_FAILED';

export const INSURANCE_AGENT_FETCH_STARTED = 'INSURANCE_AGENT_FETCH_STARTED';
export const INSURANCE_AGENT_FETCH_COMPLETED = 'INSURANCE_AGENT_FETCH_COMPLETED';
export const INSURANCE_AGENT_FETCH_FAILED = 'INSURANCE_AGENT_FETCH_FAILED';

export const INSURANCE_AGENT_SELECTED = 'INSURANCE_AGENT_SELECTED';
export const INSURANCE_AGENTS_ALL_SELECTED = 'INSURANCE_AGENTS_ALL_SELECTED';
export const INSURANCE_AGENT_DESELECTED = 'INSURANCE_AGENT_DESELECTED';
export const INSURANCE_AGENTS_ALL_DESELECTED = 'INSURANCE_AGENTS_ALL_DESELECTED';
