import businessPartners from './businessPartners';

export default [
  {
    header: 'sidebar.general',
    iconName: 'List',
    children: [...businessPartners] //Only to check permission in children inside
  },
  ...businessPartners
];
