/* -------------------------------------------------------------------------- */
/*                          Componente SlidingPaneForm                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un SlidingPaneForm genérico que se utiliza en muchas pantallas de la aplicación.

import { Button, Row, Col } from 'reactstrap';
import SlidingPane from 'react-sliding-pane';
import Table from '../../components/Table';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Fragment, useEffect, useRef, useState } from 'react';
import WebtrackCustomInput from '../WebtrackCustomInput';

interface Props {
  title: any;
  isOpenSlidingPane: any;
  closeSlidingPaneForm: any;
  onClickCancelButton?: any;
  idTable?: string;
  subtitle?: any;
  confirmText?: any;
  useTable?: boolean;
  heightTable?: number;
  columnsTable?: any[];
  selected?: any;
  isInMap?: boolean;
  changeSelected?: Function;
  dataTable?: any[];
  onSelectTable?: any;
  onDeselectTable?: any;
  width?: string;
  inputs?: any[];
  hasButtonSubmit?: boolean;
  hasButtonCancel?: boolean;
  action?: any;
  control?: any;
  handleSubmit?: Function;
  change?: any;
  onClose?: any;
  timeout?: number;
}

const SlidingPaneForm = (props: Props) => {
  //const [touched, setTouched] = useState(false);
  const {
    idTable,
    title,
    subtitle,
    confirmText,
    useTable,
    heightTable,
    columnsTable,
    selected,
    changeSelected,
    dataTable,
    onSelectTable,
    onDeselectTable,
    isOpenSlidingPane,
    closeSlidingPaneForm,
    inputs,
    hasButtonSubmit,
    hasButtonCancel,
    action,
    width,
    control,
    handleSubmit,
    change,
    onClose,
    isInMap = false,
    onClickCancelButton,
    timeout
  } = props;

  const { control: innerControl, handleSubmit: innerHandleSubmit, watch } = useForm();
  const [isInnerForm, setIsInnerForm] = useState<any>();

  const formValues = useRef<any>();

  useEffect(() => {
    formValues.current = watch();
  }, [watch()]);

  useEffect(() => {
    setIsInnerForm(!(control && handleSubmit));
  }, []);

  const closeSlidingPane = (saving) => {
    closeSlidingPaneForm();
    if (onClose != null && !saving) onClose(); //Close without saving
  };

  const save = (values) => {
    closeSlidingPane(true);
    action(values);
  };

  return (
    <SlidingPane
      className="custom-sliding-pane-form"
      overlayClassName={isInMap ? 'custom-overlay-sliding-pane-map' : 'custom-overlay-sliding-pane'}
      isOpen={isOpenSlidingPane}
      title={title}
      subtitle={subtitle}
      width={width || '50rem'}
      onRequestClose={() => closeSlidingPane(false)}
    >
      {useTable && columnsTable && dataTable && (
        <>
          <Table
            id={idTable}
            columns={columnsTable}
            height={heightTable}
            data={dataTable}
            selected={selected}
            changeSelected={(event) => {
              if (changeSelected) changeSelected(event);
            }}
            onSelect={(event: any) => {
              if (onSelectTable) onSelectTable(event);
            }}
            onDeselect={(event: any) => {
              if (onDeselectTable) onDeselectTable(event);
            }}
          />
          <br />
          <hr />
          <br />
        </>
      )}
      <Row className={'d-flex justify-content-center'}>
        {inputs &&
          inputs
            .filter((input) => input)
            .map((input, i) => {
              let WrapperInput = Fragment;
              var wrapperInputProps = {
                key: i.toString()
              };
              if (input.type !== 'membership') {
                WrapperInput = Col;
                wrapperInputProps['sm'] = input.sm ? input.sm : '12';
                wrapperInputProps['className'] = input.type === 'membership' ? 'pl-0 pr-0' : '';
              }

              return (
                <WrapperInput {...wrapperInputProps}>
                  {input.subtitle && (
                    <h5 className={i === 0 ? 'text-center' : 'mt-2 text-center'}>
                      {input.subtitle}
                    </h5>
                  )}
                  <WebtrackCustomInput
                    control={isInnerForm ? innerControl : control}
                    input={{ ...input, name: input.name || 'input-' + i, id: i.toString() }}
                    change={change}
                  />
                </WrapperInput>
              );
            })}
        <Row className={'footer-slide-pane'}>
          <Col sm="12 d-flex ">
            {hasButtonSubmit && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="primary"
                /*type="submit"*/
                onClick={
                  isInnerForm
                    ? innerHandleSubmit((values) => {
                        if (timeout) setTimeout(() => save(formValues.current), timeout);
                        else save(values);
                      })
                    : handleSubmit &&
                      handleSubmit((values) => {
                        if (timeout) setTimeout(() => save(formValues.current), timeout);
                        else save(values);
                      })
                }
              >
                {confirmText || <FormattedMessage id="common.save" />}
              </Button.Ripple>
            )}
            {hasButtonCancel && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="light"
                onClick={() => {
                  closeSlidingPane(false);
                  if (onClickCancelButton) onClickCancelButton();
                }}
              >
                <FormattedMessage id="common.cancel" />
              </Button.Ripple>
            )}
          </Col>
        </Row>
      </Row>
    </SlidingPane>
  );
};

SlidingPaneForm.defaultProps = {
  inputs: [],
  useTable: false,
  heightTable: 200,
  hasButtonSubmit: true,
  hasButtonCancel: true,
  columnsTable: [],
  dataTable: []
};

export default SlidingPaneForm;
