import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as authSelectors from '../../../../../redux/reducers/auth';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routeCheckpoints';
import * as typesRoutes from '../../types/routes';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED:
    case types.ROUTE_CHECKPOINTS_CLEAR: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.ROUTE_CHECKPOINTS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((routeCheckpointId) => {
        newState[routeCheckpointId] = {
          isSelected: false,
          ...state[routeCheckpointId],
          ...entities[routeCheckpointId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //ROUTE ADD AND EDIT COMPLETED
    case typesRoutes.ROUTE_ADD_COMPLETED: {
      const {
        routeInformation: {
          checkpoints: { entities, order }
        }
      } = action.payload;
      const newState = { ...state };
      order.forEach((routeId) => {
        newState[routeId] = {
          isSelected: false,
          ...state[routeId],
          ...entities[routeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case typesRoutes.ROUTE_EDIT_COMPLETED: {
      const {
        checkpoints: { entities, order }
      } = action.payload;
      const newState = { ...state };
      order.forEach((routeId) => {
        newState[routeId] = {
          isSelected: false,
          ...state[routeId],
          ...entities[routeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED:
    case types.ROUTE_CHECKPOINTS_CLEAR: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.ROUTE_CHECKPOINTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(state, order);
    }

    //ROUTE ADD AND EDIT COMPLETED
    case typesRoutes.ROUTE_ADD_COMPLETED: {
      const {
        routeInformation: {
          checkpoints: { order }
        }
      } = action.payload;
      return union(state, order);
    }

    case typesRoutes.ROUTE_EDIT_COMPLETED: {
      const {
        checkpoints: { order }
      } = action.payload;
      return union(state, order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_CHECKPOINTS_FETCH_STARTED: {
      return true;
    }
    case types.ROUTE_CHECKPOINTS_FETCH_COMPLETED:
    case types.ROUTE_CHECKPOINTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTE_CHECKPOINTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_CHECKPOINTS_FETCH_STARTED:
    case types.ROUTE_CHECKPOINTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const routeCheckpoints = combineReducers({
  byId,
  order,
  isFetchingList,
  errorFetchingList
});

export default routeCheckpoints;

//Own state
export const getOwnState = (state) => state.routes.routeCheckpoints;

//Information
export const getRouteCheckpoint = (state, routeCheckpointId) => {
  const routeCheckpoint = getOwnState(state).byId[routeCheckpointId];
  if (routeCheckpoint) {
    return {
      ...routeCheckpoint,
      segmentStopTime: routeCheckpoint.segmentStopTime / 60,
      radiusFormat: authSelectors
        .getParsers(state)
        .convertMetersToAuthUserDistance2System(routeCheckpoint.radius)
    };
  } else undefined;
};

export const getRouteCheckpointsList = (state) =>
  getOwnState(state).order.map((id) => getRouteCheckpoint(state, id));
export const getRouteCheckpointsListByRoute = (state, routeId) =>
  getRouteCheckpointsList(state).filter((routeCheckpoint) => routeCheckpoint.routeId === routeId);

//Status of sagas
export const isFetchingListRouteCheckpoints = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingListRouteCheckpointsErrors = (state) =>
  getOwnState(state).errorFetchingList;
