import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { select, takeEvery, put, call } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as entitySelectors from '../../Entities/reducers';
import * as actions from '../actions';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import {
  getGroupsListService,
  getAllEntitiesInGroupService,
  saveGroupService,
  saveGroupWithEntitiesService,
  deleteGroupService,
  leaveGroupService
} from '../services/groups';
import * as types from '../types';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchGroupsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getGroupsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { groups },
          result //order
        } = normalize(resultData, schemas.groups); //normalize data to byId and order
        store.dispatch(actions.completeFetchingGroups(groups, result, operatorId, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingGroups(error));
      }
    }
  );
}

export function* watchFetchGroupsList() {
  yield takeEvery(types.GROUPS_FETCH_STARTED, fetchGroupsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchGroupsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getGroupsListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { groups },
          result //order
        } = normalize(resultData, schemas.groups); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingGroups(groups, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingGroups(error));
      }
    }
  );
}

export function* watchPartialFetchGroupsList() {
  yield takeEvery(types.GROUPS_PARTIAL_FETCH_STARTED, partialFetchGroupsList);
}

/* -------------------------------------------------------------------------- */
/*                             FETCH ALL ENTITIES                             */
/* -------------------------------------------------------------------------- */
function* fetchAllEntitiesInGroup(action) {
  const groupId = action.payload;
  getAllEntitiesInGroupService(
    { groupId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { users }
        } = normalize(resultData[0], schemas.users); //normalize data to byId for users
        const {
          entities: { locations }
        } = normalize(resultData[1], schemas.locations); //normalize data to byId for locations
        const {
          entities: { workplans }
        } = normalize(resultData[2], schemas.workplans); //normalize data to byId for workplans
        const {
          entities: { units }
        } = normalize(resultData[3], schemas.units); //normalize data to byId for units
        const {
          entities: { geofences }
        } = normalize(resultData[4], schemas.geofences); //normalize data to byId for geofences
        const {
          entities: { drivers }
        } = normalize(resultData[5], schemas.drivers); //normalize data to byId for drivers
        const entities = {
          users: users,
          locations: locations,
          workplans: workplans,
          units: units,
          geofences: geofences,
          drivers: drivers
        };
        store.dispatch(actions.completeFetchingAllEntitiesGroups(entities, groupId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingAllEntitiesGroups(error));
      }
    }
  );
}

export function* watchFetchAllEntitiesInGroup() {
  yield takeEvery(types.GROUPS_ALL_ENTITIES_FETCH_STARTED, fetchAllEntitiesInGroup);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addGroup(action) {
  const { group, users, userInSession, additionalOnSave } = action.payload;
  saveGroupService(
    { groupName: group.groupName, owners: users.map((user) => user.userId).toString() },
    {
      successAction: (response) => {
        const addedGroup = camelcaseKeys(response?.data);
        store.dispatch(actions.completeAddingGroup(group.groupId, addedGroup));
        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'groups.group'
          }),
          name: group.groupName
        });
        store.dispatch(actions.deselectAllGroups());
        store.dispatch(actions.selectGroup(addedGroup.groupId));
        if (typeof additionalOnSave === 'function') additionalOnSave();
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingGroup(group.groupId, error));
      }
    }
  );
}

export function* watchAddGroupStarted() {
  yield takeEvery(types.GROUP_ADD_STARTED, addGroup);
}

/* -------------------------------------------------------------------------- */
/*                                ADD WITH ENTITIES                           */
/* -------------------------------------------------------------------------- */
function* addGroupWithEntities(action) {
  const { group, userInSession, entityType, entities, params } = action.payload;

  saveGroupWithEntitiesService(
    {
      groupName: group.groupName,
      owners: [userInSession.userId].toString(),
      ...params,
      entities:
        entityType === 'users'
          ? entities.map((entity) => entity.userName).toString()
          : entityType === 'locations'
          ? entities.map((entity) => entity.locationId).toString()
          : entityType === 'workplans'
          ? entities.map((entity) => entity.workplanId).toString()
          : entityType === 'units'
          ? entities.map((entity) => entity.unitId).toString()
          : entityType === 'geofences'
          ? entities.map((entity) => entity.geofenceId).toString()
          : entityType === 'drivers'
          ? entities.map((entity) => entity.driverId).toString()
          : '',
      entityType
    },
    {
      successAction: (response) => {
        const addedGroup = camelcaseKeys(response?.data);
        store.dispatch(actions.completeAddingGroupWithEntities(group.groupId, addedGroup));
        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'groups.group'
          }),
          name: group.groupName
        });
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingGroupWithEntities(group.groupId, error));
      }
    }
  );
}

export function* watchAddGroupWithEntitiesStarted() {
  yield takeEvery(types.GROUP_ADD_WITH_ENTITIES_STARTED, addGroupWithEntities);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editGroup(action) {
  const group = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/group/edit`, {
        method: 'POST',
        body: JSON.stringify(group),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedGroup = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingGroup(editedGroup));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'groups.group'
          }),
          name: group.groupName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldGroup } = yield select((state) =>
          selectors.getGroup(state.groups, group.groupId)
        );
        yield put(actions.failEditingGroup(oldGroup, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldGroup } = yield select((state) => selectors.getGroup(state.groups, group.groupId));
    yield put(actions.failEditingGroup(oldGroup, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditGroupsStarted() {
  yield takeEvery(types.GROUP_EDIT_STARTED, editGroup);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeGroup(action) {
  const { groupId } = action.payload;
  const group = yield select((state) => selectors.getGroup(state, groupId));
  deleteGroupService(
    { ...group },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingGroup(group.groupId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingGroup(group.groupId, error));
      }
    }
  );
}

export function* watchRemoveGroupStarted() {
  yield takeEvery(types.GROUP_REMOVE_STARTED, removeGroup);
}

/* -------------------------------------------------------------------------- */
/*                                   LEAVE                                    */
/* -------------------------------------------------------------------------- */
function* leaveGroup(action) {
  const { groupId, isAsesor, asesorUsername } = action.payload;
  const group = yield select((state) => selectors.getGroup(state, groupId));
  leaveGroupService(
    { ...group },
    {
      successAction: (response) => {
        store.dispatch(actions.completeLeavingGroup(group.groupId, isAsesor, asesorUsername));
      },
      errorAction: (error) => {
        store.dispatch(actions.failLeavingGroup(group.groupId, error));
      }
    }
  );
}

export function* watchLeaveGroupStarted() {
  yield takeEvery(types.GROUP_LEAVE_STARTED, leaveGroup);
}

/* -------------------------------------------------------------------------- */
/*                    EDIT NOTIFICATIONS CONFIGURATION                         */
/* -------------------------------------------------------------------------- */
function* editNotificationsConfiguration(action) {
  const group = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/group/editNotificationsConfiguration`, {
        method: 'POST',
        body: JSON.stringify(group),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        //const editedGroup = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingNotificationsConfigurationGroup(group));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'groups.group'
          }),
          name: group.groupName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldGroup } = yield select((state) =>
          selectors.getGroup(state.groups, group.groupId)
        );
        yield put(actions.failEditingNotificationsConfigurationGroup(oldGroup, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldUnit } = yield select((state) => selectors.getGroup(state.groups, group.groupId));
    yield put(actions.failEditingNotificationsConfigurationGroup(oldUnit, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditConfigurationNotificationsStarted() {
  yield takeEvery(
    types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_STARTED,
    editNotificationsConfiguration
  );
}

/* -------------------------------------------------------------------------- */
/*                        REMOVE ENTITIES FROM GROUP                          */
/* -------------------------------------------------------------------------- */
function* removeEntitiesFromGroup(action) {
  const group: any = action.payload.group;
  const entityType = action.payload.entityType;
  const entities = action.payload.entities;

  const partialEntityUrl =
    entityType === 'users'
      ? 'removeUsersFromGroup'
      : entityType === 'locations'
      ? 'removeLocationsFromGroup'
      : entityType === 'workplans'
      ? 'removeWorkPlansFromGroup'
      : entityType === 'units'
      ? 'removeUnitsFromGroup'
      : entityType === 'geofences'
      ? 'removeGeofencesFromGroup'
      : entityType === 'drivers'
      ? 'removeDriversFromGroup'
      : '';
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/group/${partialEntityUrl}`, {
        method: 'POST',
        body: JSON.stringify({ groupId: group.groupId, entities: entities }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingEntitiesFromGroup(group.groupId, entityType, entities));
        alerts.showSuccessRemovedFromGroupMessage({
          object: appIntl().formatMessage({
            id:
              entityType === 'users'
                ? entities.length > 1
                  ? 'users.users'
                  : 'users.user'
                : entityType === 'locations'
                ? entities.length > 1
                  ? 'locations.locations'
                  : 'locations.location'
                : entityType === 'workplans'
                ? entities.length > 1
                  ? 'workplans.workplans'
                  : 'workplans.workplan'
                : entityType === 'units'
                ? entities.length > 1
                  ? 'units.units'
                  : 'units.unit'
                : entityType === 'geofences'
                ? entities.length > 1
                  ? 'geofences.geofences'
                  : 'geofences.geofence'
                : entityType === 'drivers'
                ? entities.length > 1
                  ? 'drivers.drivers'
                  : 'drivers.driver'
                : 'groups.entities'
          }),
          name: group.groupName,
          multiple: entities.length > 1,
          altText:
            entityType === 'locations' || entityType === 'units' || entityType === 'geofences'
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchRemoveEntitiesFromGroup() {
  yield takeEvery(types.GROUP_REMOVE_ENTITIES_STARTED, removeEntitiesFromGroup);
}

/* -------------------------------------------------------------------------- */
/*                            ADD ENTITIES TO GROUP                           */
/* -------------------------------------------------------------------------- */
function* addEntitiesToGroup(action) {
  const group: any = action.payload.group;
  const entityType = action.payload.entityType;
  const entities = action.payload.entities;
  const params = action.payload.params;

  const partialEntityUrl =
    entityType === 'users'
      ? 'addUsersToGroup'
      : entityType === 'locations'
      ? 'addLocationsToGroup'
      : entityType === 'workplans'
      ? 'addWorkPlansToGroup'
      : entityType === 'units'
      ? 'addUnitsToGroup'
      : entityType === 'geofences'
      ? 'addGeofencesToGroup'
      : entityType === 'drivers'
      ? 'addDriversToGroup'
      : '';
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/group/${partialEntityUrl}`, {
        method: 'POST',
        body: JSON.stringify({
          groupId: group.groupId,
          entities:
            entityType === 'users'
              ? entities.map((entity) => entity.userName)
              : entityType === 'locations'
              ? entities.map((entity) => entity.locationId)
              : entityType === 'workplans'
              ? entities.map((entity) => entity.workPlanId)
              : entityType === 'units'
              ? entities.map((entity) => entity.unitId)
              : entityType === 'geofences'
              ? entities.map((entity) => entity.geofenceId)
              : entityType === 'drivers'
              ? entities.map((entity) => entity.driverId)
              : [],
          ...params
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeAddingEntitiesToGroup(group, entityType, entities, params));
        alerts.showSuccessAddedToGroupMessage({
          object: appIntl().formatMessage({
            id:
              entityType === 'users'
                ? entities.length > 1
                  ? 'users.users'
                  : 'users.user'
                : entityType === 'locations'
                ? entities.length > 1
                  ? 'locations.locations'
                  : 'locations.location'
                : entityType === 'workplans'
                ? entities.length > 1
                  ? 'workplans.workplans'
                  : 'workplans.workplan'
                : entityType === 'units'
                ? entities.length > 1
                  ? 'units.units'
                  : 'units.unit'
                : entityType === 'geofences'
                ? entities.length > 1
                  ? 'geofences.geofences'
                  : 'geofences.geofence'
                : entityType === 'drivers'
                ? entities.length > 1
                  ? 'drivers.drivers'
                  : 'drivers.driver'
                : 'groups.entities'
          }),
          name: group.groupName,
          multiple: entities.length > 1,
          altText:
            entityType === 'locations' || entityType === 'units' || entityType === 'geofences'
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchAddEntitiesToGroup() {
  yield takeEvery(types.GROUP_ADD_ENTITIES_STARTED, addEntitiesToGroup);
}

/* -------------------------------------------------------------------------- */
/*                        EDIT USER PROFILE IN GROUP                          */
/* -------------------------------------------------------------------------- */
function* editUserPorfileInGroup(action) {
  const { groupId, userName, userNameInSession, profileId } = action.payload;

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/group/editUsersProfileInGroup`, {
        method: 'POST',
        body: JSON.stringify({ groupId, profileId, users: [userName] }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(
          actions.completeEditingUserProfileInGroup(groupId, userName, userNameInSession, profileId)
        );
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'users.user'
          }),
          name: userName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditUserProfileInGroup() {
  yield takeEvery(types.GROUP_USER_PROFILE_EDIT_STARTED, editUserPorfileInGroup);
}
