export const ROUTES_ASSIGNMENTS_FETCH_STARTED = 'ROUTES_ASSIGNMENTS_FETCH_STARTED';
export const ROUTES_ASSIGNMENTS_FETCH_COMPLETED = 'ROUTES_ASSIGNMENTS_FETCH_COMPLETED';
export const ROUTES_ASSIGNMENTS_FETCH_FAILED = 'ROUTES_ASSIGNMENTS_FETCH_FAILED';

export const ROUTES_ASSIGNMENT_EDIT_STARTED = 'ROUTES_ASSIGNMENT_EDIT_STARTED';
export const ROUTES_ASSIGNMENT_EDIT_COMPLETED = 'ROUTES_ASSIGNMENT_EDIT_COMPLETED';
export const ROUTES_ASSIGNMENT_EDIT_FAILED = 'ROUTES_ASSIGNMENT_EDIT_FAILED';

export const ROUTES_ASSIGNMENT_REMOVE_STARTED = 'ROUTES_ASSIGNMENT_REMOVE_STARTED';
export const ROUTES_ASSIGNMENT_REMOVE_COMPLETED = 'ROUTES_ASSIGNMENT_REMOVE_COMPLETED';
export const ROUTES_ASSIGNMENT_REMOVE_FAILED = 'ROUTES_ASSIGNMENT_REMOVE_FAILED';

export const ROUTES_ASSIGNMENTS_IMPORT_STARTED = 'ROUTES_ASSIGNMENTS_IMPORT_STARTED';
export const ROUTES_ASSIGNMENTS_IMPORT_COMPLETED = 'ROUTES_ASSIGNMENTS_IMPORT_COMPLETED';
export const ROUTES_ASSIGNMENTS_IMPORT_FAILED = 'ROUTES_ASSIGNMENTS_IMPORT_FAILED';
