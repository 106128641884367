const permissions = {
  listPerson: 'PE001',
  addPerson: 'PE002',
  editPerson: 'PE003',
  removePerson: 'PE004',
  massAssignment: 'OP002',
  sensorConfiguration: 'AD007'
};

export default permissions;
