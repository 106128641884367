import paths from 'views/screens/Customers/paths';
import * as screens from '@core/layouts/components/menu/screens';
import permissions from 'views/screens/Customers/permissions';
import Icon from '../../utility/icomoon';

export default [
  {
    id: 'customers',
    title: 'customers.customers',
    icon: <Icon size={20} icon="Customers" />,
    permissions: [permissions.listCustomers],
    iconName: 'Customers',
    navLink: paths.listCustomer,
    screenId: screens.CUSTOMERS
  }
];
