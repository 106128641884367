import paths from './paths';
import * as screens from '@core/layouts/components/menu/screens';
import permissions from './permissions';

export default [
  {
    id: 'dashboard',
    title: 'dashboard.dashboard',
    iconName: 'Grid',
    badge: 'primary',
    badgeText: 'common.comingSoon',
    permissions: [permissions.dashboard],
    navLink: paths.dashboard,
    screenId: screens.DASHBOARD
  }
];
