import { useEffect, useState } from 'react';
import { getDriverImagesService } from '../services/drivers';
import camelcaseKeys from 'camelcase-keys';

const useGetDriverImages = (
  driverId: number | undefined,
  update: boolean
): {
  driverImages: any;
  isFetchingDriverImages: boolean;
} => {
  const [driverImages, setData] = useState<any>([]);
  const [isFetchingDriverImages, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (driverId !== undefined && driverId !== null) {
      setIsFetching(true);
      getDriverImagesService(
        {
          driverId
        },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response?.data !== undefined && response?.data !== null) {
              setData(camelcaseKeys(response.data));
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [update]);
  return { driverImages, isFetchingDriverImages };
};

export default useGetDriverImages;
