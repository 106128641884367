import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/spareParts';
import { SparePartsDTO } from 'views/screens/vehicleMaintenance/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SPAREPARTS_FETCH_COMPLETED:
    case types.SPAREPARTS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((sparePartId) => {
        newState[sparePartId] = {
          isSelected: false,
          ...state[sparePartId],
          ...entities[sparePartId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SPAREPART_ADD_COMPLETED: {
      const { id, oldId, sparePart } = action.payload;
      const newState = omit(state, oldId);
      newState[id] = {
        ...sparePart,
        isConfirmed: true,
        isSelected: false
      };
      return newState;
    }

    case types.SPAREPART_EDIT_COMPLETED: {
      const responseSparePart = action.payload;
      const id = responseSparePart.sparePartId;

      const oldSparePart = omit(state[id], ['oldSparePart']);
      const newSparePart = omit(responseSparePart, ['oldSparePart']);

      const confirmedSparePart = {
        ...oldSparePart,
        ...newSparePart,
        isConfirmed: true
      };

      const newState = {
        ...state,
        [id]: confirmedSparePart
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.SPAREPART_ADD_STARTED: {
      const sparePart = action.payload;
      const newState = { ...state };
      newState[sparePart.tempId] = {
        isSelected: false,
        ...sparePart,
        isConfirmed: false
      };
      return newState;
    }

    case types.SPAREPART_EDIT_STARTED: {
      const sparePart = action.payload;
      const id = sparePart.sparePartId;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          oldSparePart: state[id],
          isConfirmed: false,
          ...sparePart
        }
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.SPAREPART_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.SPAREPART_EDIT_FAILED: {
      const { oldSparePart } = action.payload;
      return {
        ...state,
        [oldSparePart.sparePartId]: {
          ...omit(state[oldSparePart.sparePartId], ['oldSparePart']),
          ...oldSparePart,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SPAREPART_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return omit(state, id);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SPAREPARTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.SPAREPARTS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.SPAREPART_ADD_COMPLETED: {
      const { id, oldId } = action.payload;
      return state.map((sparePartId) => (sparePartId === oldId ? id : sparePartId));
    }

    case types.SPAREPART_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return state.filter((sparePartIdState) => sparePartIdState !== id);
    }

    //CASE ADD STARTED
    case types.SPAREPART_ADD_STARTED: {
      const sparePart = action.payload;
      return [...state, sparePart.tempId];
    }

    //CASE ADD FAILED
    case types.SPAREPART_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((sparePartIdState) => sparePartIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPARTS_FETCH_STARTED: {
      return true;
    }
    case types.SPAREPARTS_FETCH_COMPLETED:
    case types.SPAREPARTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPARTS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.SPAREPARTS_PARTIAL_FETCH_COMPLETED:
    case types.SPAREPARTS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPART_ADD_STARTED: {
      return true;
    }
    case types.SPAREPART_ADD_COMPLETED:
    case types.SPAREPART_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPART_EDIT_STARTED: {
      return true;
    }
    case types.SPAREPART_EDIT_COMPLETED:
    case types.SPAREPART_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPART_REMOVE_STARTED: {
      return true;
    }
    case types.SPAREPART_REMOVE_COMPLETED:
    case types.SPAREPART_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SPAREPARTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SPAREPARTS_FETCH_STARTED:
    case types.SPAREPARTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SPAREPARTS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SPAREPARTS_PARTIAL_FETCH_STARTED:
    case types.SPAREPARTS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SPAREPART_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SPAREPART_ADD_STARTED:
    case types.SPAREPART_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SPAREPART_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SPAREPART_EDIT_STARTED:
    case types.SPAREPART_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SPAREPART_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SPAREPART_REMOVE_STARTED:
    case types.SPAREPART_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.SPAREPARTS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const spareParts = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

export default spareParts;

//Own state
export const getOwnState = (state) => state.spareParts;

//Information
export const getSparePart = (state, id): types.SpareParts | null => {
  const sparePart = getOwnState(state).byId[id] ?? null;

  return {
    ...sparePart
  };
};

export const getSparePartsList = (state): types.SpareParts[] =>
  getOwnState(state).order?.map((id) => getSparePart(state, id));

export const getSparePartsListByMembership = (state, membership) => {
  //Se filtra por divisionId
  if (membership.organizationId) {
    if (membership.divisionId) {
      if (membership.subdivisionId) {
        return getSparePartsList(state).filter(
          (sparePart) =>
            sparePart.organizationId === membership.organizationId &&
            sparePart.divisionId === membership.divisionId &&
            sparePart.subdivisionId === membership.subdivisionId
        );
      }
      return getSparePartsList(state).filter(
        (sparePart) =>
          sparePart.organizationId === membership.organizationId &&
          sparePart.divisionId === membership.divisionId
      );
    }
    return getSparePartsList(state).filter(
      (sparePart) => sparePart.organizationId === membership.organizationId
    );
  }
  //Si no se ha seleccionado una división se devuelve la lista completa
  else return getSparePartsList(state);
};

//Status of sagas
export const isPartialFetchingListSpareParts = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListSpareParts = (state) => getOwnState(state).isFetchingList;
export const haveSparePartsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingSparePart = (state) => getOwnState(state).isAdding;
export const isEditingSparePart = (state) => getOwnState(state).isEditing;
export const isRemovingSparePart = (state) => getOwnState(state).isRemoving;
//Errors
export const getPartialFetchingListSparePartsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListSparePartsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingSparePartErrors = (state) => getOwnState(state).errorAdding;
export const getEditingSparePartErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingSparePartErrors = (state) => getOwnState(state).errorRemoving;
