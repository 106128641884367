const permissions = {
  temperatureCatalog: 'TE001',
  temperatureStadistics: 'TE002',
  fuelCatalog: 'TE003',
  fuelReport: 'TE007',
  fuelStadistics: 'TE004',
  tirePressureCatalog: 'TE005',
  obd2Monitor: 'TE006',
  telemtryUnits: 'TU001',
  downtime: 'UP027'
};
 
export default permissions;
