/* -------------------------------------------------------------------------- */
/*                          NoticeTypeDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un NoticeTypeDropdownInput
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DropdownInput from 'views/components/DropdownInput';
import { Field } from 'redux-form';

interface Props {
  defaultValue?: any;
  name?: string;
  id?: string;
}

export const noticeTypeOptions = [
  { noticeTypeId2: 1, noticeTypeName: 'Mantenimiento' },
  { noticeTypeId2: 2, noticeTypeName: 'Inspección' }
];

const DropdownNotices = (props) => {
  const intl = useIntl();
  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  return (
    <DropdownInput
      {...props}
      name={props?.name ?? 'noticeTypeId2'}
      id={props?.id ?? 'noticeTypeId2'}
      key={resetValue}
      label={<FormattedMessage id="vehicleMaintenance.noticeType" />}
      options={noticeTypeOptions.map((noticeType) => {
        return {
          ...noticeType,
          id: noticeType.noticeTypeId2,
          value: noticeType.noticeTypeId2,
          label: intl.formatMessage({
            id: `vehicleMaintenance.noticeType${noticeType.noticeTypeId2}`
          }),
          label_intl: intl.formatMessage({
            id: `vehicleMaintenance.noticeType${noticeType.noticeTypeId2}`
          })
        };
      })}
    />
  );
};

const NoticeTypeDropdownInput = (props: Props) => {
  return (
    <Field
      name="noticeTypeId2"
      id={props?.id ?? 'noticeTypeId2'}
      required={true}
      component={DropdownNotices}
      type={'select'}
      defaultValue={props.defaultValue}
    />
  );
};

export default NoticeTypeDropdownInput;
