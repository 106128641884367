import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { getAssetsListService } from '../services';
import * as assetSelectors from '../reducers';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchAssetsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getAssetsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { assets },
          result //order
        } = normalize(resultData, schemas.assets); //normalize data to byId and order
        store.dispatch(actions.completeFetchingAssets(assets, result, operatorId, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingAssets(error));
      }
    }
  );
}

export function* watchFetchAssetsList() {
  yield takeEvery(types.ASSETS_FETCH_STARTED, fetchAssetsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchAssetsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getAssetsListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { assets },
          result //order
        } = normalize(resultData, schemas.assets); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingAssets(assets, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingAssets(error));
      }
    }
  );
}

export function* watchPartialFetchAssetsList() {
  yield takeEvery(types.ASSETS_PARTIAL_FETCH_STARTED, partialFetchAssetsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addAsset(action) {
  const asset = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/asset/save`, {
        method: 'POST',
        body: JSON.stringify({ ...asset, assetId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedAsset = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingAsset(asset.assetId, addedAsset));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'assets.asset'
          }),
          name: asset.assetIdentifier
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingAsset(asset.assetId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingAsset(asset.assetId, error));
  }
}

export function* watchAddAssetsStarted() {
  yield takeEvery(types.ASSET_ADD_STARTED, addAsset);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editAsset(action) {
  const asset = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/asset/save`, {
        method: 'POST',
        body: JSON.stringify(asset),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedAsset = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingAsset(editedAsset));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'assets.asset'
          }),
          name: editedAsset.assetIdentifier
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldAsset } = yield select((state) =>
          selectors.getAsset(state.assets, asset.assetId)
        );
        yield put(actions.failEditingAsset(oldAsset, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldAsset } = yield select((state) => selectors.getAsset(state.assets, asset.assetId));
    yield put(actions.failEditingAsset(oldAsset, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditAssetsStarted() {
  yield takeEvery(types.ASSET_EDIT_STARTED, editAsset);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeAsset(action) {
  const { assetId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedAsset = yield select((state) => assetSelectors.getAsset(state, assetId));
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/asset/delete`, {
        method: 'POST',
        body: JSON.stringify({ assetId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingAsset(assetId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'assets.asset'
          }),
          name: deletedAsset.assetIdentifier
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingAsset(assetId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingAsset(assetId, error));
  }
}

export function* watchRemoveAssetStarted() {
  yield takeEvery(types.ASSET_REMOVE_STARTED, removeAsset);
}

/* -------------------------------------------------------------------------- */
/*                            IDENTIFIER CHECK                                */
/* -------------------------------------------------------------------------- */
function* checkIdentifier(action) {
  const { assetId, identifier } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/asset/validateIdentifier`, {
        method: 'POST',
        body: JSON.stringify({ assetId, identifier }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const validCode = jsonResult.data.AssetIdentifierStatus === 1;
        yield put(actions.completeCheckingCode(validCode));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchIdentifierCheckStarted() {
  yield takeEvery(types.ASSET_IDENTIFIER_CHECK_STARTED, checkIdentifier);
}

/* -------------------------------------------------------------------------- */
/*                      SECURITY SEAL CODE CHECK                               */
/* -------------------------------------------------------------------------- */
function* checkSecuritySealCode(action) {
  const { assetId, securitySealCode } = action.payload;

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/asset/validateSecuritySealCode`, {
        method: 'POST',
        body: JSON.stringify({ assetId: assetId ?? null, securitySealCode }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const validCode = jsonResult.data.IsValid === 1;
        yield put(actions.completeCheckingSecuritySealCode(validCode));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchSecuritySealCodeCheckStarted() {
  yield takeEvery(types.ASSET_SECURITY_SEAL_CODE_CHECK_STARTED, checkSecuritySealCode);
}
