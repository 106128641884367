/* -------------------------------------------------------------------------- */
/*                          Componente SlidingPaneForm                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un SlidingPaneForm genérico que se utiliza en muchas pantallas de la aplicación.
// @ts-nocheck
import L from 'leaflet';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { FormGroup, Label, Spinner } from 'reactstrap';
import EditButton from 'views/components/EditButton';
import TitleH4 from 'views/components/TitleH4';
import Toolbar from 'views/components/Toolbar';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as routeActions from '../../actions/routes';
import routePermissions from '../../permissions';

interface Props {
  title: any;
  subtitle: any;
  route: any;
  isLoading: boolean;
  isOpenSlidingPane: any;
  closeSlidingPane: any;
  createRoute: any;
}

const SlidingPaneEditRoute = (props: Props) => {
  //const [touched, setTouched] = useState(false);
  const { title, subtitle, route, isLoading, isOpenSlidingPane, closeSlidingPane, createRoute } =
    props;

  const intl = useIntl();

  const { authSystems } = useSelector((state) => ({
    authSystems: authSelectors.getAuthSystems(state)
  }));

  return (
    <SlidingPane
      className="custom-sliding-pane-form"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isOpenSlidingPane}
      title={title}
      subtitle={subtitle}
      width={'40rem'}
      onRequestClose={() => closeSlidingPane()}
    >
      <TitleH4 textId={'routes.logisticRoute'} center={true} textTransform={'uppercase'} />
      {isLoading ? (
        <div className="d-flex justify-content-center my-1">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <Toolbar
            buttons={[
              <EditButton
                tooltipMessage={'routes.logisticRoute'}
                isDisabled={false}
                handleClick={() => createRoute(route)}
                permissions={[routePermissions.editRoute]}
              />
            ]}
          />
          <FormGroup>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">{intl.formatMessage({ id: 'routes.name' })}</Label>
            <Label className="label-value-information">{route.routeName ?? 'N/A'}</Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'routes.abbreviation' })}
            </Label>
            <Label className="label-value-information">{route.abbreviation ?? 'N/A'}</Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'routes.origin' })}
            </Label>
            <Label className="label-value-information">
              {route.sourceName ??
                (route?.checkpoints?.length > 1 ? route?.checkpoints[0]?.checkpointName : 'N/A')}
            </Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'routes.destination' })}
            </Label>
            <Label className="label-value-information">
              {route.targetName ??
                (route?.checkpoints?.length > 1
                  ? route?.checkpoints[route?.checkpoints?.length - 1]?.checkpointName
                  : 'N/A')}
            </Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'routes.distance' })}
            </Label>
            <Label className="label-value-information-lowercase">
              {route.distanceFormat ? route.distanceFormat + ' ' + authSystems['distance'] : 'N/A'}
            </Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'organizations.organization' })}
            </Label>
            <Label className="label-value-information">{route.organizationName ?? 'N/A'}</Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'divisions.division' })}
            </Label>
            <Label className="label-value-information">{route.divisionName ?? 'N/A'}</Label>
            <hr className={'full-width-slide-panel'} />
            <Label className="label-information">
              {intl.formatMessage({ id: 'subdivisions.subdivision' })}
            </Label>
            <Label className="label-value-information">{route.subdivisionName ?? 'N/A'}</Label>
            <hr className={'full-width-slide-panel'} />
          </FormGroup>
        </>
      )}
    </SlidingPane>
  );
};

SlidingPaneEditRoute.defaultProps = {};

export default SlidingPaneEditRoute;
