import * as types from '../../types/backlog';

export const startFetchingJobsBacklog = (filters = {}) => ({
  type: types.JOBS_BACKLOG_FETCH_STARTED,
  payload: filters
});
export const completeFetchingJobsBacklog = (entities, order) => ({
  type: types.JOBS_BACKLOG_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingJobsBacklog = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.JOBS_BACKLOG_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingJobsBacklog = (entityTypeId) => ({
  type: types.JOBS_BACKLOG_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});
export const completePartialFetchingJobsBacklog = (entities, order) => ({
  type: types.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingJobsBacklog = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.JOBS_BACKLOG_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingJobBacklog = (jobBacklog) => ({
  type: types.JOB_BACKLOG_ADD_STARTED,
  payload: jobBacklog
});
export const completeAddingJobBacklog = (oldId, jobBacklog) => ({
  type: types.JOB_BACKLOG_ADD_COMPLETED,
  payload: { oldId, jobBacklog }
});
export const failAddingJobBacklog = (oldId, error) => ({
  type: types.JOB_BACKLOG_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingJobBacklog = (jobBacklog) => ({
  type: types.JOB_BACKLOG_EDIT_STARTED,
  payload: jobBacklog
});
export const completeEditingJobBacklog = (jobBacklog) => ({
  type: types.JOB_BACKLOG_EDIT_COMPLETED,
  payload: jobBacklog
});
export const failEditingJobBacklog = (oldJobBacklog, error) => ({
  type: types.JOB_BACKLOG_EDIT_FAILED,
  payload: {
    oldJobBacklog,
    error
  }
});

export const selectJobBacklog = (jobId) => ({
  type: types.JOB_BACKLOG_SELECTED,
  payload: jobId
});

export const selectAllJobsBacklog = (
  jobIds = [] //List of JobBacklogIds
) => ({
  type: types.JOBS_BACKLOG_ALL_SELECTED,
  payload: jobIds
});

export const deselectJobBacklog = (jobId) => ({
  type: types.JOB_BACKLOG_DESELECTED,
  payload: jobId
});

export const deselectAllJobsBacklog = (
  jobIds = [] //List of JobBacklogIds
) => ({
  type: types.JOBS_BACKLOG_ALL_DESELECTED,
  payload: jobIds
});

export const startRemovingJobsBacklog = (jobs) => ({
  type: types.JOBS_BACKLOG_REMOVE_STARTED,
  payload: {
    jobs
  }
});

export const completeRemovingJobsBacklog = (jobs) => ({
  type: types.JOBS_BACKLOG_REMOVE_COMPLETED,
  payload: {
    jobs
  }
});

export const failRemovingJobsBacklog = (jobs, error) => ({
  type: types.JOBS_BACKLOG_REMOVE_FAILED,
  payload: {
    jobs,
    error
  }
});

export const startChangeStatusJobs = (jobs, jobStatus, comment) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_STARTED,
  payload: { jobs, jobStatus, comment }
});
export const completeChangeStatusJobs = (jobs, jobStatus, comment) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_COMPLETED,
  payload: { jobs, jobStatus, comment }
});
export const failChangeStatusJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startChangeJobTypeJobs = (jobs, jobTypeId) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_STARTED,
  payload: { jobs, jobTypeId }
});
export const completeChangeJobTypeJobs = (jobs, jobTypeId) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_COMPLETED,
  payload: { jobs, jobTypeId }
});
export const failChangeJobTypeJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_STATUS_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startChangePriorityJobs = (jobs, jobPriority) => ({
  type: types.JOBS_BACKLOG_CHANGE_PRIORITY_STARTED,
  payload: { jobs, jobPriority }
});
export const completeChangePriorityJobs = (jobs, jobPriority) => ({
  type: types.JOBS_BACKLOG_CHANGE_PRIORITY_COMPLETED,
  payload: { jobs, jobPriority }
});
export const failChangePriorityJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_PRIORITY_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startChangeStartDateJobs = (jobs, startDate) => ({
  type: types.JOBS_BACKLOG_CHANGE_START_DATE_STARTED,
  payload: { jobs, startDate }
});
export const completeChangeStartDateJobs = (jobs, startDate) => ({
  type: types.JOBS_BACKLOG_CHANGE_START_DATE_COMPLETED,
  payload: { jobs, startDate }
});
export const failChangeStartDateJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_START_DATE_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startChangeDueDateJobs = (jobs, dueDate) => ({
  type: types.JOBS_BACKLOG_CHANGE_DUE_DATE_STARTED,
  payload: { jobs, dueDate }
});
export const completeChangeDueDateJobs = (jobs, dueDate) => ({
  type: types.JOBS_BACKLOG_CHANGE_DUE_DATE_COMPLETED,
  payload: { jobs, dueDate }
});
export const failChangeDueDateJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_DUE_DATE_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startChangeMinMaxTimeJobs = (jobs, maxTime) => ({
  type: types.JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_STARTED,
  payload: { jobs, maxTime }
});
export const completeChangeMinMaxTimeJobs = (jobs, maxTime) => ({
  type: types.JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_COMPLETED,
  payload: { jobs, maxTime }
});
export const failChangeMinMaxTimeJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startAssignUsersJobs = (jobs, userNames, users) => ({
  type: types.JOBS_BACKLOG_ASSIGN_USERS_STARTED,
  payload: { jobs, userNames, users }
});
export const completeAssignUsersJobs = (jobs, userNames, users) => ({
  type: types.JOBS_BACKLOG_ASSIGN_USERS_COMPLETED,
  payload: { jobs, userNames, users }
});
export const failAssignUsersJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_ASSIGN_USERS_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startAssignFormsJobs = (jobs, formIds, forms) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FORMS_STARTED,
  payload: { jobs, formIds, forms }
});
export const completeAssignFormsJobs = (jobs, formIds, forms) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FORMS_COMPLETED,
  payload: { jobs, formIds, forms }
});
export const failAssignFormsJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FORMS_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startAssignFilesJobs = (jobs, fileIds) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FILES_STARTED,
  payload: { jobs, fileIds }
});
export const completeAssignFilesJobs = (jobs, order) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FILES_COMPLETED,
  payload: { jobs, order }
});
export const failAssignFilesJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_ASSIGN_FILES_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startRescheduleJobs = (jobs, startDate, dueDate, comment) => ({
  type: types.JOBS_BACKLOG_RESCHEDULE_JOBS_STARTED,
  payload: { jobs, startDate, dueDate, comment }
});
export const completeRescheduleJobs = (jobs, startDate, dueDate, comment) => ({
  type: types.JOBS_BACKLOG_RESCHEDULE_JOBS_COMPLETED,
  payload: { jobs, startDate, dueDate, comment }
});
export const failRescheduleJobs = (oldJobs, error) => ({
  type: types.JOBS_BACKLOG_RESCHEDULE_JOBS_FAILED,
  payload: {
    oldJobs,
    error
  }
});

export const startImportingJobs = (organizationId, divisionId, subdivisionId, jobs) => ({
  type: types.JOBS_BACKLOG_IMPORT_STARTED,
  payload: {
    organizationId,
    divisionId,
    subdivisionId,
    jobs
  }
});
export const completeImportingJobs = (entities, order) => ({
  type: types.JOBS_BACKLOG_IMPORT_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failImportingJobs = (error) => ({
  type: types.JOBS_BACKLOG_IMPORT_FAILED,
  payload: {
    error
  }
});
