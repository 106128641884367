import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';

import * as actions from '../../actions/routesExecutions';
import * as types from '../../types/routesExecutions';
import * as selectors from '../../reducers/routes';
import * as schemas from '../../schemas/routesExecutions';

import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { useIntl } from 'react-intl';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { store } from 'redux/storeConfig/store';
import {
  changeToAlternateRouteService,
  saveRouteExecutionService
} from '../../services/routeExecution';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                            FETCH LIST EXECUTIONS                           */
/* -------------------------------------------------------------------------- */
function* fetchRoutesExecutions(action) {
  try {
    const filters = action.payload;
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/route/getExecutions`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment({ ...filters })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const indicators = camelcaseKeys(jsonResult.data.indicators);
        const { scheduledBySchedule } = camelcaseKeys(jsonResult.data.scheduledBySchedule);
        const { totalNotShown } = camelcaseKeys(jsonResult.data.totalNotShown);
        const resultData = jsonResult.data.routesList.map((result) => camelcaseKeys(result));
        const {
          entities: { routesExecutions },
          result //order
        } = normalize(resultData, schemas.routesExecutions); //normalize data to byId and order
        yield put(
          actions.completeFetchingRoutesExecutions(routesExecutions, result, {
            ...indicators,
            scheduledBySchedule,
            totalNotShown
          })
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routeFetchMonitorMessageError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routeFetchMonitorMessageError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingRoutesExecutions(error));
  }
}

export function* watchFetchRoutesExecutions() {
  yield takeEvery(types.ROUTES_EXECUTIONS_FETCH_STARTED, fetchRoutesExecutions);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editRouteExecution(action) {
  const routeExecution = action.payload;
  const oldRouteExecution = yield select((state) =>
    selectors.getRoutesExecution(state, routeExecution.routeAssignmentId)
  );
  saveRouteExecutionService(
    {
      ...routeExecution,
      routeAssignmentId: oldRouteExecution.routeAssignmentId,
      unitName: oldRouteExecution.unitName
    },
    {
      successAction: (response) => {
        const editedRouteExecution = camelcaseKeys(response.data);
        store.dispatch(
          actions.completeEditRouteExecution({ ...routeExecution, ...editedRouteExecution })
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditRouteExecution(error));
      }
    }
  );
}

export function* watchEditRouteExecutionStarted() {
  yield takeEvery(types.ROUTE_EXECUTION_EDIT_STARTED, editRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* changeToAlternateRoute(action) {
  const { routeAssignmentId, routeId, reason } = action.payload;
  changeToAlternateRouteService(
    { routeAssignmentId, routeId, reason },
    {
      successAction: (response) => {
        if (response.success)
          alerts.showSuccessMessage({
            success: appIntl().formatMessage({
              id: 'routes.successfullyChangeAlternateRoute'
            })
          });
        store.dispatch(actions.completeChangeToAlternateRoute(routeAssignmentId, routeId, reason));
      },
      errorAction: (error) => {
        const errorTxt = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.changeAlternateRouteMessageError'
          })
        };
        alerts.showErrorAlertFailChanges({ error: errorTxt });
        store.dispatch(actions.failChangeToAlternateRoute(error));
      }
    }
  );
}

export function* watchChangeToAlternateRouteStarted() {
  yield takeEvery(types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED, changeToAlternateRoute);
}

/* -------------------------------------------------------------------------- */
/*                          Cancel Route Execution                         */
/* -------------------------------------------------------------------------- */
function* cancelRouteExecution(action) {
  const newStatus = 8;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeCancelRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasCanceled'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesCancelationMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCancelRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesCancelationMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCancelRoutesExecutions(error));
  }
}

export function* watchCancelRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_CANCEL_STARTED, cancelRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                         Finalize Route Execution                        */
/* -------------------------------------------------------------------------- */
function* finalizeRouteExecution(action) {
  const newStatus = 6;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeFinalizeRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasFinished'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesFinalizationMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFinalizeRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesFinalizationMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFinalizeRoutesExecutions(error));
  }
}

export function* watchFinalizeRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_FINALIZE_STARTED, finalizeRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                          Complete Route Execution                          */
/* -------------------------------------------------------------------------- */
function* completeRouteExecution(action) {
  const newStatus = 2;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeCompleteRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasCompleted'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesCompletationMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCompleteRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesCompletationMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCompleteRoutesExecutions(error));
  }
}

export function* watchCompleteRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_COMPLETE_STARTED, completeRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                            Remove Route Execution                          */
/* -------------------------------------------------------------------------- */
function* removeRouteExecution(action) {
  const newStatus = 0;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeRemoveRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasRemoved'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesRemoveMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemoveRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesRemoveMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemoveRoutesExecutions(error));
  }
}

export function* watchRemoveRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_REMOVE_STARTED, removeRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                            Pause Route Execution                          */
/* -------------------------------------------------------------------------- */
function* pauseRouteExecution(action) {
  const newStatus = 5;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completePauseRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasPaused'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesPauseMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPauseRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesPauseMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPauseRoutesExecutions(error));
  }
}

export function* watchPauseRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_PAUSE_STARTED, pauseRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                            Start Route Execution                          */
/* -------------------------------------------------------------------------- */
function* startRouteExecution(action) {
  const newStatus = 3;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeStartRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasStarted'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesStartMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failStartRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesStartMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failStartRoutesExecutions(error));
  }
}

export function* watchStartRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_START_STARTED, startRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                       Manual Transit Route Execution                       */
/* -------------------------------------------------------------------------- */
function* manualTransitRouteExecution(action) {
  const newStatus = 3;
  const routes = action.payload.routes;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/updateRouteExecutionStatus`, {
        method: 'POST',
        body: JSON.stringify(routes.map((route) => ({ ...route, newRouteStatus: newStatus }))),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === routes.length
      ) {
        yield put(
          actions.completeManualTransitRoutesExecutions(
            routes.map((route) => ({ ...route, newRouteStatus: newStatus }))
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesManualTransit'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesManualTransitMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failManualTransitRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesManualTransitMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failManualTransitRoutesExecutions(error));
  }
}

export function* watchManualTransitRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED, manualTransitRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                            Share Route Execution                           */
/* -------------------------------------------------------------------------- */
function* shareRouteExecution(action) {
  const newStatus = 0;
  const routes = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/shareRouteExecutions`, {
        method: 'POST',
        body: JSON.stringify(routes),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeShareRoutesExecutions(routes.routeAssignments, routes.emailList));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.selectedRoutesWasShared'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routesShareMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failShareRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routesShareMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failShareRoutesExecutions(error));
  }
}

export function* watchShareRouteExecution() {
  yield takeEvery(types.ROUTES_EXECUTION_SHARE_STARTED, shareRouteExecution);
}

/* -------------------------------------------------------------------------- */
/*                        Assign Route Execution Unit                      */
/* -------------------------------------------------------------------------- */
function* assignRouteExecutionUnit(action) {
  const assignation = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/assignUnit`, {
        method: 'POST',
        body: JSON.stringify(assignation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === assignation.length
      ) {
        yield put(actions.completeAssignUnitRoutesExecutions(assignation));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.unitWasSuccessfullyAssigned'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.unitAssignmentIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignUnitRoutesExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.unitAssignmentIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignUnitRoutesExecutions(error));
  }
}

export function* watchAsignRouteExecutionUnit() {
  yield takeEvery(types.ROUTES_EXECUTION_ASSIGN_UNIT_STARTED, assignRouteExecutionUnit);
}
