import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/workplans';
import * as types from '../../types/workplans';
import * as selectors from '../../reducers/workplans';
import * as schemas from '../../schemas/workplans';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as entitySelectors from '../../../Entities/reducers';
import * as workplanSelectors from '../../reducers/workplans';
import * as geofenceSelectors from '../../../Geofences/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import {
  getWorkplansListService,
  deleteWorkplanService,
  saveWorkplanService
} from '../../services/workplans';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchWorkplansList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getWorkplansListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { workplans },
          result //order
        } = normalize(resultData, schemas.workplans); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingWorkplans(workplans, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingWorkplans(error));
      }
    }
  );
}

export function* watchFetchWorkplansList() {
  yield takeEvery(types.WORKPLANS_FETCH_STARTED, fetchWorkplansList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETSH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchWorkplansList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getWorkplansListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { workplans },
          result //order
        } = normalize(resultData, schemas.workplans); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingWorkplans(workplans, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingWorkplans(error));
      }
    }
  );
}

export function* watchPartialFetchWorkplansList() {
  yield takeEvery(types.WORKPLANS_PARTIAL_FETCH_STARTED, partialFetchWorkplansList);
}

/* -------------------------------------------------------------------------- */
/*                            FETCH LOCATIONS LIST                            */
/* -------------------------------------------------------------------------- */
function* fetchWorkplanLocationsList(action) {
  const workplanId = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/workplan/getLocations`, {
        method: 'POST',
        body: JSON.stringify({ workplanId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const locations = jsonResult.data.map((result) => camelcaseKeys(result));
        yield put(actions.completeFetchingWorkplanLocations(workplanId, locations));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingWorkplanLocations(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingWorkplanLocations(error));
  }
}

export function* watchFetchWorkplanLocationsList() {
  yield takeEvery(types.WORKPLAN_SELECTED, fetchWorkplanLocationsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addWorkplan(action) {
  const workplan = action.payload;

  saveWorkplanService(
    { ...workplan, workplanId: null },
    {
      successAction: async (response) => {
        const addedWorkplan = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingWorkplan(workplan.workplanId, addedWorkplan));
      },
      errorAction: async (error) => {
        store.dispatch(actions.failAddingWorkplan(workplan.workplanId, error));
      }
    },
    true
  );
}

export function* watchAddWorkplansStarted() {
  yield takeEvery(types.WORKPLAN_ADD_STARTED, addWorkplan);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editWorkplan(action) {
  const workplan = action.payload;
  const { oldWorkplan } = yield select(selectors.getWorkplan, workplan.workplanId);

  saveWorkplanService(
    workplan,
    {
      successAction: async (response) => {
        const editedWorkplan = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingWorkplan(editedWorkplan));
      },
      errorAction: async (error) => {
        store.dispatch(actions.failEditingWorkplan(oldWorkplan, error));
      }
    },
    false
  );
}

export function* watchEditWorkplansStarted() {
  yield takeEvery(types.WORKPLAN_EDIT_STARTED, editWorkplan);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeWorkplan(action) {
  const { workplanId } = action.payload;
  const workplanDeleted = yield select(workplanSelectors.getWorkplan, workplanId);

  deleteWorkplanService(
    { workplanId, workplanName: workplanDeleted?.workplanName },
    {
      successAction: async (response) => {
        store.dispatch(actions.completeRemovingWorkplan(workplanId));
      },
      errorAction: async (error) => {
        store.dispatch(actions.failRemovingWorkplan(workplanId, error));
      }
    }
  );
}

export function* watchRemoveWorkplanStarted() {
  yield takeEvery(types.WORKPLAN_REMOVE_STARTED, removeWorkplan);
}

/* -------------------------------------------------------------------------- */
/*                                Copy Workplan                               */
/* -------------------------------------------------------------------------- */
function* copyWorkplan(action) {
  const workplan = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/copyWorkplan`, {
        method: 'POST',
        body: JSON.stringify(workplan),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(
          actions.completeCopyWorkplan(
            workplan.workplanId,
            jsonResult.data.newWorkplanId,
            workplan.newWorkplanName,
            workplan.organizationId,
            workplan.divisionId,
            workplan.subdivisionId
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.copySuccessful'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.copyError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCopyWorkplan(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.copyError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCopyWorkplan(error));
  }
}

export function* watchCopyWorkplanStarted() {
  yield takeEvery(types.WORKPLAN_COPY_STARTED, copyWorkplan);
}

/* -------------------------------------------------------------------------- */
/*                           Assign Origin Geofence                           */
/* -------------------------------------------------------------------------- */
function* assignOriginGeofence(action) {
  const workplan = action.payload;
  const geofence = yield select(geofenceSelectors.getGeofence, workplan.originGeofenceId);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/assignOriginGeofence`, {
        method: 'POST',
        body: JSON.stringify(workplan),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === workplan.workplanIds.length
      ) {
        yield put(
          actions.completeAssignOriginGeofenceWorkplan(
            workplan.workplanIds,
            workplan.originGeofenceId,
            geofence.geofenceName,
            workplan.originExitWindow,
            workplan.originValidationWindow
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.assignOriginGeofenceSuccess'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.assignOriginGeofenceError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignOriginGeofenceWorkplan(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.assignOriginGeofenceError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignOriginGeofenceWorkplan(error));
  }
}

export function* watchAssignOriginGeofenceStarted() {
  yield takeEvery(types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED, assignOriginGeofence);
}

/* -------------------------------------------------------------------------- */
/*                         Assign Work Area Geofence                          */
/* -------------------------------------------------------------------------- */
function* assignWorkGeofence(action) {
  const workplan = action.payload;
  const geofence = yield select(geofenceSelectors.getGeofence, workplan.workGeofenceId);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/assignWorkGeofence`, {
        method: 'POST',
        body: JSON.stringify(workplan),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === workplan.workplanIds.length
      ) {
        yield put(
          actions.completeAssignWorkGeofenceWorkplan(
            workplan.workplanIds,
            workplan.workGeofenceId,
            geofence.geofenceName,
            workplan.workGeofenceArriveWindow,
            workplan.workGeofenceMaxTime
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.assignWorkGeofenceSuccess'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.assignWorkGeofenceError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignWorkGeofenceWorkplan(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.assignWorkGeofenceError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignWorkGeofenceWorkplan(error));
  }
}

export function* watchAssignWorkGeofenceStarted() {
  yield takeEvery(types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED, assignWorkGeofence);
}

/* -------------------------------------------------------------------------- */
/*                        Assign Destination Geofence                         */
/* -------------------------------------------------------------------------- */
function* assignDestinationGeofence(action) {
  const workplan = action.payload;
  const geofence = yield select(geofenceSelectors.getGeofence, workplan.destinationGeofenceId);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/assignDestinationGeofence`, {
        method: 'POST',
        body: JSON.stringify(workplan),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === workplan.workplanIds.length
      ) {
        yield put(
          actions.completeAssignDestinationGeofenceWorkplan(
            workplan.workplanIds,
            workplan.destinationGeofenceId,
            geofence.geofenceName
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.assignDestinationGeofenceSuccess'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.assignDestinationGeofenceError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignDestinationGeofenceWorkplan(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.assignDestinationGeofenceError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignDestinationGeofenceWorkplan(error));
  }
}

export function* watchAssignDestinationGeofenceStarted() {
  yield takeEvery(types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED, assignDestinationGeofence);
}
