import * as authSelectors from 'redux/reducers/auth';
import { useBoolean } from 'hooks/useBoolean';
import { FC, Fragment } from 'react';
import Icon from 'utility/icomoon';
import SlidingPaneManualHourmeterAdjustment from '../SlidingPaneManualHourmeterAdjustment';
import DefaultButton from 'views/components/DefaultButton';
import { useSelector } from 'react-redux';

export enum ButtonTypes {
  Icon = 1,
  Button = 2
}

interface Props {
  unitId: number;
  size?: number;
  buttonType?: ButtonTypes;
  defaultValue?: number;
  isDisabled?: boolean;
  additionalOnSuccess?: () => void;
}

const ManualHourmeterAdjustment: FC<Props> = ({
  size = 16,
  buttonType = ButtonTypes.Button,
  defaultValue = 0,
  isDisabled = false,
  unitId,
  additionalOnSuccess
}) => {
  // validate permission
  const hasPermission = useSelector((store) =>
    authSelectors.getAuthUserHasPermissionsAny(store, ['OP005'])
  );
  if (!hasPermission) return <></>;
  // validate permission

  const { value: isOpen, setTrue: open, setFalse: close } = useBoolean();
  const onClickIcon = (event) => {
    open();
  };

  return (
    <Fragment>
      {buttonType === ButtonTypes.Icon ? (
        <Icon icon="Edit2" size={size} onClick={onClickIcon} style={{ cursor: 'pointer' }} />
      ) : (
        <DefaultButton
          color="light"
          handleClick={() => {
            open();
          }}
          icon={'SetMaximumStopTime'}
          tooltipMessage="units.editOdometer"
          isDisabled={isDisabled}
        />
      )}
      <SlidingPaneManualHourmeterAdjustment
        isOpen={isOpen}
        close={close}
        defaultValue={defaultValue}
        unitId={unitId}
        additionalOnSuccess={additionalOnSuccess}
      />
    </Fragment>
  );
};

export default ManualHourmeterAdjustment;
