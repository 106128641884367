import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../../actions/workplansAssignments';
import * as types from '../../types/workplansAssignments';
import * as selectors from '../../reducers/workplansAssignments';
import * as schemas from '../../schemas/workplanAssignments';
import camelcaseKeys from 'camelcase-keys';
import { store } from 'redux/storeConfig/store';
import {
  getWorkplansAssignmentsListService,
  saveWorkplanAssignmentByScheduleService,
  saveWorkplanAssignmentByDateTimeService,
  deleteWorkplanAssignmentService
} from '../../services/workplansAssignments';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as alerts from '../../../../../redux/utils/alerts';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchAssignmentsList(action) {
  const { operatorId, organizationId } = action.payload;
  getWorkplansAssignmentsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultDataWS = response.data.withScheduleList.map((result) => camelcaseKeys(result));
        const resultDataWDT = response.data.withDateTimeList.map((result) => camelcaseKeys(result));
        const {
          entities: { assignmentsBySchedule },
          result: withScheduleOrder //order
        } = normalize(resultDataWS, schemas.assignmentsBySchedule); //normalize data to byId and order
        const {
          entities: { assignmentsByDateTime },
          result: withDateTimeOrder //order
        } = normalize(resultDataWDT, schemas.assignmentsByDateTime); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingAssignmentsWithSchedule(assignmentsBySchedule, withScheduleOrder)
        );
        store.dispatch(
          actions.completeFetchingAssignmentsWithDateTime(assignmentsByDateTime, withDateTimeOrder)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingAssignments(error));
      }
    }
  );
}

export function* watchFetchWorkplansAssignmentsList() {
  yield takeEvery(types.WORKPLAN_ASSIGNMENT_FETCH_STARTED, fetchAssignmentsList);
}

/* -------------------------------------------------------------------------- */
/*                         EDIT ASSIGNMENT BY SCHEDULE                         */
/* -------------------------------------------------------------------------- */
function* editAssignmentBySchedule(action) {
  const assignment = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(
        fetch,
        `${API_BASE_URL}/workplan/updateWorkplanAssignmentSchedule`,
        {
          method: 'POST',
          body: JSON.stringify(
            endDateAdjustment({
              ...assignment
            })
          ),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedAssignment = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingAssignmentWithSchedule(editedAssignment));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'workplans.workplanAssignment'
          }),
          name: editedAssignment.workplanName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.assignmentByScheduleEditFailure'
          }),
          errorNumber: jsonResult.number
        };
        const { oldAssignment } = yield select((state) =>
          selectors.getWorkplansAssignment(state, assignment.assignmentId, 'bySchedule')
        );
        yield put(actions.failEditingAssignmentWithSchedule(oldAssignment, error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({ id: 'workplans.assignmentByDateTimeEditFailure' }),
      errorNumber: -1
    };

    const { oldAssignment } = yield select((state) =>
      selectors.getWorkplansAssignment(state, assignment.assignmentId, 'bySchedule')
    );
    yield put(actions.failEditingAssignmentWithSchedule(oldAssignment, error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditWorkplanAssignmentByScheduleStarted() {
  yield takeEvery(types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED, editAssignmentBySchedule);
}

/* -------------------------------------------------------------------------- */
/*                         EDIT ASSIGNMENT BY DATETIME                        */
/* -------------------------------------------------------------------------- */
function* editAssignmentByDateTime(action) {
  const assignment = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(
        fetch,
        `${API_BASE_URL}/workplan/updateWorkplanAssignmentDateTime`,
        {
          method: 'POST',
          body: JSON.stringify(
            endDateAdjustment({
              ...assignment
            })
          ),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedAssignment = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingAssignmentWithDateTime(editedAssignment));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'workplans.workplanAssignment'
          }),
          name: editedAssignment.workplanName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.assignmentByDateTimeEditFailure'
          }),
          errorNumber: jsonResult.number
        };
        const { oldAssignment } = yield select((state) =>
          selectors.getWorkplansAssignment(state, assignment.assignmentId, 'byDateTime')
        );
        yield put(actions.failEditingAssignmentWithDateTime(oldAssignment, error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({ id: 'workplans.assignmentByDateTimeEditFailure' }),
      errorNumber: -1
    };

    const { oldAssignment } = yield select((state) =>
      selectors.getWorkplansAssignment(state, assignment.assignmentId, 'byDateTime')
    );
    yield put(actions.failEditingAssignmentWithDateTime(oldAssignment, error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditWorkplanAssignmentByDateTimeStarted() {
  yield takeEvery(types.WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_STARTED, editAssignmentByDateTime);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeWorkplanAssignment(action) {
  const { assignmentId, assignmentType } = action.payload;
  const assignmentDeleted = yield select(
    selectors.getWorkplansAssignment,
    assignmentId,
    assignmentType
  );

  deleteWorkplanAssignmentService(
    {
      assignmentId,
      assignmentName: `${assignmentDeleted?.workplanName}-${assignmentDeleted?.unitName}`
    },
    {
      successAction: async (response) => {
        assignmentType === 'bySchedule'
          ? store.dispatch(actions.completeRemovingAssignmentWithSchedule(assignmentId))
          : assignmentType === 'byDateTime'
          ? store.dispatch(actions.completeRemovingAssignmentWithDateTime(assignmentId))
          : '';
      },
      errorAction: async (error) => {
        store.dispatch(actions.failRemovingAssignment(assignmentId, error));
      }
    }
  );
}

export function* watchRemoveWorkplanAssignmentStarted() {
  yield takeEvery(types.WORKPLAN_ASSIGNMENT_REMOVE_STARTED, removeWorkplanAssignment);
}
