/* -------------------------------------------------------------------------- */
/*                          DriverDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un DriverDropdownInput

import { FC, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import Avatar from '../../../@core/components/avatar';
import { DefaultRootState } from 'redux/storeConfig/types';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import * as driverSelectors from 'views/screens/Drivers/reducers';
import DropdownInput, { DropdownProps } from '../DropdownInput';

interface Props extends Omit<DropdownProps, 'options'> {
  options?: DropdownProps['options'];
  useLabel?: boolean;
  driversFilters?: any;
  require?: boolean;
}

const DriversDropdownInput: FC<Props> = (props) => {
  const { useLabel = true, driversFilters } = props;
  const { drivers, isFetching } = useSelector((state: DefaultRootState) => ({
    drivers: driverSelectors.getDriversList(state),
    isFetching:
      driverSelectors.isFetchingListDrivers(state) ||
      driverSelectors.isPartialFetchingListDrivers(state)
  }));

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  //Update reactselect values of  drivers when fetched is finished
  useEffect(() => {
    if (!isFetching) {
      resetDropdown();
    }
  }, [isFetching, props?.options?.length]);

  return (
    <>
      {!props.options && <FetchEntities useDrivers driversFilters={driversFilters ?? {}} />}
      <DropdownInput
        require={props.require}
        {...props}
        key={resetValue}
        isLoading={isFetching}
        label={useLabel ? props.label || <FormattedMessage id="drivers.driver" /> : null}
        options={
          props.options ||
          drivers.map((driver) => {
            return {
              id: driver.driverId,
              icon: (
                <Avatar
                  // @ts-ignore unreachable error
                  img={driver?.driverPhotoUrl ? driver?.driverPhotoUrl : undefined}
                  color="light-primary"
                  className={'mr-1'}
                  content={driver.driverName ?? ''}
                  initials
                />
              ),
              value: driver.driverId,
              label: driver.driverName,
              label_intl: driver.driverName
            };
          })
        }
      />
    </>
  );
};

export default DriversDropdownInput;
