import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansAssignments';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const bySchedule = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }

    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((assignmentId) => {
        newState[assignmentId] = {
          isSelected: false,
          ...state[assignmentId],
          ...entities[assignmentId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //TYPES REMOVE COMPLETED
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED: {
      const { assignmentId } = action.payload;
      return omit(state, assignmentId);
    }

    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED: {
      const assignment = action.payload;
      return {
        ...state,
        [assignment.assignmentId]: {
          ...state[assignment.assignmentId],
          ...assignment
        }
      };
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const byDateTime = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((assignmentId) => {
        newState[assignmentId] = {
          isSelected: false,
          ...state[assignmentId],
          ...entities[assignmentId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //TYPES REMOVE COMPLETED
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED: {
      const { assignmentId } = action.payload;
      return omit(state, assignmentId);
    }

    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_COMPLETED: {
      const assignment = action.payload;
      return {
        ...state,
        [assignment.assignmentId]: {
          ...state[assignment.assignmentId],
          ...assignment
        }
      };
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const byScheduleOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED: {
      const { assignmentId } = action.payload;
      return state.filter((assignmentIdState) => assignmentIdState !== assignmentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const byDateTimeOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED: {
      const { assignmentId } = action.payload;
      return state.filter((assignmentIdState) => assignmentIdState !== assignmentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingAssignments = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_ASSIGNMENT_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_FETCH_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED: {
      return true;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_STARTED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_STARTED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_STARTED: {
      return true;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_FAILED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_FAILED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingAssignments = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_ASSIGNMENT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_FETCH_STARTED:
    case types.WORKPLAN_ASSIGNMENT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED:
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_FAILED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_FAILED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_STARTED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_STARTED:
    case types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_STARTED:
    case types.WORKPLAN_ASSIGNMENT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const workplansAssignments = combineReducers({
  bySchedule,
  byDateTime,
  byScheduleOrder,
  byDateTimeOrder,
  isFetchingAssignments,
  //isAdding,
  isEditing,
  isRemoving,
  errorFetchingAssignments,
  //errorAdding,
  errorEditing,
  errorRemoving
});

export default workplansAssignments;

//Own state
export const getOwnState = (state) => state.workplans.workplansAssignments;

//Information
export const getWorkplansAssignment = (state, assignmentId, assignmentType) => {
  if (assignmentType === 'bySchedule') {
    return getOwnState(state).bySchedule[assignmentId];
  } else if (assignmentType === 'byDateTime') {
    return getOwnState(state).byDateTime[assignmentId];
  }
};
export const getWorkplansAssignmentsByScheduleList = (state) =>
  getOwnState(state).byScheduleOrder?.map((id) => getWorkplansAssignment(state, id, 'bySchedule'));

export const getWorkplansAssignmentsByScheduleListByMembership = (
  state,
  membership: DefaultFilters
) => {
  return getWorkplansAssignmentsByScheduleList(state)
    .filter((assignment) => {
      return !!assignment?.operatorId && !!membership?.operatorId
        ? assignment?.operatorId === membership?.operatorId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.organizationId && !!membership?.organizationId
        ? assignment?.organizationId === membership?.organizationId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.divisionId && !!membership?.divisionId
        ? assignment?.divisionId === membership?.divisionId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.subdivisionId && !!membership?.subdivisionId
        ? assignment?.subdivisionId === membership?.subdivisionId
        : !!assignment;
    });
};

export const getWorkplansAssignmentsByDateTimeList = (state) =>
  getOwnState(state).byDateTimeOrder?.map((id) => getWorkplansAssignment(state, id, 'byDateTime'));

export const getWorkplansAssignmentsByDateTimeListByMembership = (
  state,
  membership: DefaultFilters
) => {
  return getWorkplansAssignmentsByDateTimeList(state)
    .filter((assignment) => {
      return !!assignment?.operatorId && !!membership?.operatorId
        ? assignment?.operatorId === membership?.operatorId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.organizationId && !!membership?.organizationId
        ? assignment?.organizationId === membership?.organizationId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.divisionId && !!membership?.divisionId
        ? assignment?.divisionId === membership?.divisionId
        : !!assignment;
    })
    .filter((assignment) => {
      return !!assignment?.subdivisionId && !!membership?.subdivisionId
        ? assignment?.subdivisionId === membership?.subdivisionId
        : !!assignment;
    });
};

//Status of sagas
export const isFetchingWorkplansAssignments = (state) => getOwnState(state).isFetchingAssignments;
//export const isAddingWorkplansComments = (state) => getOwnState(state).isAdding;
export const isEditingWorplansAssignments = (state) => getOwnState(state).isEditing;
export const isRemovingWorkplansAssignments = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingWorkplansAssignmentsErrors = (state) =>
  getOwnState(state).errorFetchingAssignments;
//export const getAddingWorkplansCommentsErrors = (state) => getOwnState(state).errorAdding;
export const getAddingWorkplansAssignmentsErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingWorkplansAssignmentsErrors = (state) => getOwnState(state).errorRemoving;
