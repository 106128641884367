export const WORKPLAN_COMMENTS_FETCH_STARTED = 'WORKPLAN_COMMENTS_FETCH_STARTED';
export const WORKPLAN_COMMENTS_FETCH_COMPLETED = 'WORKPLAN_COMMENTS_FETCH_COMPLETED';
export const WORKPLAN_COMMENTS_FETCH_FAILED = 'WORKPLAN_COMMENTS_FETCH_FAILED';

export const WORKPLAN_COMMENT_ADD_STARTED = 'WORKPLAN_COMMENT_ADD_STARTED';
export const WORKPLAN_COMMENT_ADD_COMPLETED = 'WORKPLAN_COMMENT_ADD_COMPLETED';
export const WORKPLAN_COMMENT_ADD_FAILED = 'WORKPLAN_COMMENT_ADD_FAILED';

export const WORKPLAN_COMMENT_REMOVE_STARTED = 'WORKPLAN_COMMENT_REMOVE_STARTED';
export const WORKPLAN_COMMENT_REMOVE_COMPLETED = 'WORKPLAN_COMMENT_REMOVE_COMPLETED';
export const WORKPLAN_COMMENT_REMOVE_FAILED = 'WORKPLAN_COMMENT_REMOVE_FAILED';
