import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CONTRACT_TEMPLATES_FETCH_COMPLETED:
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((templateId) => {
        newState[templateId] = {
          isSelected: false,
          ...state[templateId],
          ...entities[templateId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.CONTRACT_TEMPLATE_ADD_COMPLETED: {
      const { oldId, contractTemplate } = action.payload;
      const newState = omit(state, oldId);
      newState[contractTemplate.templateId] = {
        ...state[oldId],
        ...contractTemplate,
        isConfirmed: true
      };
      return newState;
    }

    case types.CONTRACT_TEMPLATE_EDIT_COMPLETED: {
      const contractTemplate = action.payload;
      return {
        ...state,
        [contractTemplate.templateId]: {
          ...omit(state[contractTemplate.templateId], ['oldContractTemplate']),
          ...omit(contractTemplate, ['oldContractTemplate']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.CONTRACT_TEMPLATE_ADD_STARTED: {
      const contractTemplate = action.payload;
      const newState = { ...state };
      newState[contractTemplate.templateId] = {
        isSelected: false,
        ...contractTemplate,
        isConfirmed: false
      };
      return newState;
    }

    case types.CONTRACT_TEMPLATE_EDIT_STARTED: {
      const contractTemplate = action.payload;
      return {
        ...state,
        [contractTemplate.templateId]: {
          ...state[contractTemplate.templateId],
          oldDivision: state[contractTemplate.templateId],
          ...contractTemplate,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.CONTRACT_TEMPLATE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.CONTRACT_TEMPLATE_EDIT_FAILED: {
      const { oldContractTemplate } = action.payload;
      return {
        ...state,
        [oldContractTemplate.templateId]: {
          ...omit(state[oldContractTemplate.templateId], ['oldContractTemplate']),
          ...oldContractTemplate,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.CONTRACT_TEMPLATE_REMOVE_COMPLETED: {
      const { templateId } = action.payload;
      return omit(state, templateId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.CONTRACT_TEMPLATE_SELECTED: {
      const templateId = action.payload;
      const newState = {
        ...state,
        [templateId]: {
          ...state[templateId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.CONTRACT_TEMPLATE_DESELECTED: {
      const templateId = action.payload;
      return {
        ...state,
        [templateId]: {
          ...state[templateId],
          isSelected: false
        }
      };
    }

    case types.CONTRACT_TEMPLATES_ALL_SELECTED: {
      const templateIds = action.payload;
      const newState = { ...state };
      if (templateIds.length == 0) {
        forEach(state, (contractTemplate: any, templateId) => {
          newState[templateId] = {
            ...contractTemplate,
            isSelected: true
          };
        });
      } else {
        templateIds.forEach((templateId) => {
          newState[templateId] = {
            ...state[templateId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.CONTRACT_TEMPLATES_ALL_DESELECTED: {
      const templateIds = action.payload;
      const newState = { ...state };
      if (templateIds.length == 0) {
        forEach(state, (contractTemplate: any, templateId) => {
          newState[templateId] = {
            ...contractTemplate,
            isSelected: false
          };
        });
      } else {
        templateIds.forEach((templateId) => {
          newState[templateId] = {
            ...state[templateId],
            isSelected: false
          };
        });
      }
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CONTRACT_TEMPLATES_FETCH_COMPLETED:
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.CONTRACT_TEMPLATE_ADD_COMPLETED: {
      const { oldId, contractTemplate } = action.payload;
      return state.map((templateId) =>
        templateId === oldId ? contractTemplate.templateId : templateId
      );
    }

    case types.CONTRACT_TEMPLATE_REMOVE_COMPLETED: {
      const { templateId } = action.payload;
      return state.filter((templateIdState) => templateIdState !== templateId);
    }

    //CASE ADD STARTED
    case types.CONTRACT_TEMPLATE_ADD_STARTED: {
      const contractTemplate = action.payload;
      return [...state, contractTemplate.templateId];
    }

    //CASE ADD FAILED
    case types.CONTRACT_TEMPLATE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((templateIdState) => templateIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATES_FETCH_STARTED: {
      return true;
    }
    case types.CONTRACT_TEMPLATES_FETCH_COMPLETED:
    case types.CONTRACT_TEMPLATES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED:
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_ADD_STARTED: {
      return true;
    }
    case types.CONTRACT_TEMPLATE_ADD_COMPLETED:
    case types.CONTRACT_TEMPLATE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_EDIT_STARTED: {
      return true;
    }
    case types.CONTRACT_TEMPLATE_EDIT_COMPLETED:
    case types.CONTRACT_TEMPLATE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_REMOVE_STARTED: {
      return true;
    }
    case types.CONTRACT_TEMPLATE_REMOVE_COMPLETED:
    case types.CONTRACT_TEMPLATE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTRACT_TEMPLATES_FETCH_STARTED:
    case types.CONTRACT_TEMPLATES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED:
    case types.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTRACT_TEMPLATE_ADD_STARTED:
    case types.CONTRACT_TEMPLATE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTRACT_TEMPLATE_EDIT_STARTED:
    case types.CONTRACT_TEMPLATE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.CONTRACT_TEMPLATE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTRACT_TEMPLATE_REMOVE_STARTED:
    case types.CONTRACT_TEMPLATE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const contractTemplates = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default contractTemplates;

//Own state
export const getOwnState = (state) => state.contractTemplates;

//Information
export const getContractTemplate = (state, templateId) => {
  return getOwnState(state).byId[templateId];
};

export const getContractTemplatesList = (state) =>
  getOwnState(state)
    .order.map((id) => getContractTemplate(state, id))
    .filter((contractTemplate) => contractTemplate?.status > 0);

export const getSelectedContractTemplates = (state) => {
  const selectedContractTemplates = getContractTemplatesList(state).filter(
    (contractTemplate) => contractTemplate?.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedContractTemplates.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedContractTemplates.length > 0) return selectedContractTemplates;
};

export const getSelectedContractTemplate = (state) => {
  const selectedContractTemplates = getContractTemplatesList(state).filter(
    (contractTemplate) => contractTemplate?.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedContractTemplates.length === 1) return selectedContractTemplates[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListContractTemplates = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListContractTemplates = (state) => getOwnState(state).isFetchingList;
export const isAddingContractTemplate = (state) => getOwnState(state).isAdding;
export const isEditingContractTemplate = (state) => getOwnState(state).isEditing;
export const isRemovingContractTemplate = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListContractTemplatesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListContractTemplatesErrors = (state) =>
  getOwnState(state).errorFetchingList;
export const getAddingContractTemplateErrors = (state) => getOwnState(state).errorAdding;
export const getEditingContractTemplateErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingContractTemplateErrors = (state) => getOwnState(state).errorRemoving;
