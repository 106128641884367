import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { imagesEntities, imagesOrder } = action.payload;
      const newState = { ...state };
      imagesOrder.forEach((imageId) => {
        newState[imageId] = {
          ...state[imageId],
          ...imagesEntities[imageId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { imagesOrder } = action.payload;
      return uniq([...state, ...imagesOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const images = combineReducers({
  byId,
  order
});

export default images;

//Own state
export const getOwnState = (state) => state.units.images;

//Information
export const getImage = (state, imageId) => {
  const image = getOwnState(state).byId[imageId];
  if (image) {
    return {
      ...image
    };
  } else return undefined;
};
export const getImagesList = (state) => getOwnState(state).order.map((id) => getImage(state, id));
