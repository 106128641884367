import { SaveSparePartCategoryDTO } from 'views/screens/vehicleMaintenance/types';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/spareParts';

export const startFetchingSpareParts = (filters = {}) => ({
  type: types.SPAREPARTS_FETCH_STARTED,
  payload: filters
});

export const completeFetchingSpareParts = (entities, order) => ({
  type: types.SPAREPARTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failFetchingSpareParts = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SPAREPARTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingSpareParts = (entityTypeId) => ({
  type: types.SPAREPARTS_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});

export const completePartialFetchingSpareParts = (entities, order) => ({
  type: types.SPAREPARTS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failPartialFetchingSpareParts = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SPAREPARTS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingSparePart = (sparePart: SaveSparePartCategoryDTO) => ({
  type: types.SPAREPART_ADD_STARTED,
  payload: sparePart
});

export const completeAddingSparePart = (id: string, oldId: string, sparePart) => ({
  type: types.SPAREPART_ADD_COMPLETED,
  payload: { id, oldId, sparePart }
});

export const failAddingSparePart = (oldId, error) => ({
  type: types.SPAREPART_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingSparePart = (sparePart: SaveSparePartCategoryDTO) => ({
  type: types.SPAREPART_EDIT_STARTED,
  payload: sparePart
});

export const completeEditingSparePart = (sparePart) => ({
  type: types.SPAREPART_EDIT_COMPLETED,
  payload: sparePart
});

export const failEditingSparePart = (oldSparePart, error) => ({
  type: types.SPAREPART_EDIT_FAILED,
  payload: {
    oldSparePart,
    error
  }
});

export const startRemovingSparePart = (request: {
  sparePartId: number;
  sparePartName: string;
}) => ({
  type: types.SPAREPART_REMOVE_STARTED,
  payload: {
    ...request
  }
});

export const completeRemovingSparePart = (id) => ({
  type: types.SPAREPART_REMOVE_COMPLETED,
  payload: {
    id
  }
});

export const failRemovingSparePart = (id, error) => ({
  type: types.SPAREPART_REMOVE_FAILED,
  payload: {
    id,
    error
  }
});
