import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as unitSelectors from '../../screens/Units/reducers';
import { FC, useEffect, useState } from 'react';
import FetchEntities, { DefaultFilters } from '../../screens/Entities/views/FetchEntities';
import DropdownInput, { DropdownProps, Options } from '../DropdownInput';
import { ReduxUnit } from 'views/screens/Units/types';

interface Props extends DropdownProps {
  usePartialMembership?: boolean;
  membership?: DefaultFilters;
  unitsFilters?: any;
  placeholder?: string;
  isClearable?: boolean;
  forceinitialvalue?: string | number;
  options?: Options;
  label?: string | JSX.Element;
  useLabel?: true;
}

const UnitsDropdown: FC<Props> = (props) => {
  const {
    usePartialMembership,
    membership,
    unitsFilters,
    useLabel = true,
    placeholder,
    isClearable = false,
    forceinitialvalue
  } = props;
  const { units, isFetching } = useSelector((state: any) => {
    let units: ReduxUnit[] = [];

    if (!!membership && usePartialMembership) {
      units = unitSelectors.getActiveUnitsListByPartialMembership(state, membership);
    } else if (!!membership) {
      units = unitSelectors.getActiveUnitsListByMembership(state, membership);
    } else {
      units = unitSelectors.getUnitsList(state);
    }

    return {
      units,
      isFetching:
        unitSelectors.isFetchingListUnits(state) || unitSelectors.isPartialFetchingListUnits(state)
    };
  });

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  //Update reactselect values of  units when fetched is finished
  useEffect(() => {
    if (!isFetching) {
      resetDropdown();
    }
  }, [
    isFetching,
    props?.options?.length,
    membership?.operatorId,
    membership?.organizationId,
    membership?.divisionId,
    membership?.subdivisionId
  ]);

  return (
    <>
      {!props.options && <FetchEntities useUnits unitsFilters={unitsFilters ?? {}} />}
      <DropdownInput
        {...props}
        key={resetValue}
        isLoading={isFetching}
        label={
          useLabel ? props.label || <FormattedMessage id="trackingUnits.trackingUnit" /> : null
        }
        options={
          props.options ||
          (unitsFilters?.unitTypeId
            ? units.filter((unit) => (typeof unitsFilters?.unitTypeId === 'string' || typeof unitsFilters?.unitTypeId === 'number') ? 
                          unit.unitTypeId === unitsFilters?.unitTypeId
                          : unitsFilters?.unitTypeId.includes(unit.unitTypeId)
                          )
            : units
          ).map((unit) => {
            return {
              ...unit,
              id: unit.unitId,
              value: unit.unitId,
              label: unit.unitName,
              label_intl: unit.unitName
            };
          })
        }
        placeholder={
          placeholder ??
          useIntl().formatMessage({
            id: 'common.all'
          })
        }
        forceinitialvalue={forceinitialvalue ?? null}
        isClearable={isClearable}
      />
    </>
  );
};

UnitsDropdown.defaultProps = {};

export default UnitsDropdown;
