import flattenDeep from 'lodash/flattenDeep';
import omit from 'lodash/omit';
import orderBy from 'lodash/orderBy';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import CheckBoxInput from 'views/formComponents/CheckBoxInput';
import DeleteButton from 'views/components/DeleteButton';
import Membership from 'views/formComponents/Membership';
import Table from 'views/components/Table';
import TableTimePicker from 'views/components/TableTimePicker';
import TextInput from 'views/formComponents/TextInput';
import TimeInput from 'views/formComponents/TimeInput';
import Toolbar from 'views/components/Toolbar';
import * as scheduleActions from 'views/screens/Schedules/actions';
import * as scheduleSelectors from 'views/screens/Schedules/reducers';
import { daysList } from 'views/screens/Schedules/utils';
import * as utility from '../../../utility/Utils';
import * as alerts from 'redux/utils/alerts';

const columnsTable = [
  {
    fieldName: 'day',
    headerText: 'schedules.day'
  },
  {
    fieldName: 'startTime',
    headerText: 'schedules.startTime',
    type: 'dateTime',
    formatColumn: 'HH:mm',
    allowEditing: true,
    editTemplate: (args) => TableTimePicker({ args, id: 'startTime' })
  },
  {
    fieldName: 'endTime',
    headerText: 'schedules.endTime',
    type: 'dateTime',
    formatColumn: 'HH:mm',
    allowEditing: true,
    editTemplate: (args) => TableTimePicker({ args, id: 'endTime' })
  }
];

interface propsType {
  history: any;
  isOpenShiftPane: any;
  closeSchedulePane: any;
  initialValues: any;
}
const ShiftSlide = (props: propsType) => {
  const { history, isOpenShiftPane, closeSchedulePane, initialValues, ...moreProps } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const isNew = useSelector(scheduleSelectors.getSelectedSchedule) ? false : true;
  const [active, setActive] = useState('1');
  const [innerSelected, changeInnerSelected] = useState<any[]>([]);
  const [shiftDays, setShiftDays] = useState<any[]>([]);
  const currentSchedule = useSelector(scheduleSelectors.getSelectedSchedule);

  useEffect(() => {
    if (!isNew) {
      const scheduleShifts = daysList
        .map((day, index) =>
          currentSchedule[day]
            ?.split('|')
            ?.map((time) => ({
              key: index,
              day: intl.formatMessage({ id: `common.${day}` }),
              startTime: new Date(
                2000,
                1,
                2,
                parseInt(time.split('-')[0]?.split(':')[0]),
                parseInt(time.split('-')[0]?.split(':')[1])
              ),
              endTime: new Date(
                2000,
                1,
                2,
                parseInt(time.split('-')[1]?.split(':')[0]),
                parseInt(time.split('-')[1]?.split(':')[1])
              )
            }))
            .filter(
              (day) =>
                (Object.prototype.toString.call(day.startTime) === '[object Date]' &&
                  !isNaN(day.startTime)) ||
                (Object.prototype.toString.call(day.endTime) === '[object Date]' &&
                  !isNaN(day.endTime))
            )
        )
        ?.filter((list) => list?.length > 0);
      setShiftDays(flattenDeep(scheduleShifts));
    } else {
      setShiftDays([]);
    }
  }, [isNew]);

  const formValues = watch();

  const saveShift = (values) => {
    var scheduleShifts = '';
    shiftDays.forEach((day, index) => {
      if (typeof day.startTime === 'object') {
        scheduleShifts += `${index + 1}~${
          ((day.key + 1) % 7) + 1
        }~${day.startTime.getHours()}:${day.startTime.getMinutes()}~${day.endTime.getHours()}:${day.endTime.getMinutes()}^`;
      } else {
        scheduleShifts += `${index + 1}~${((day.key + 1) % 7) + 1}~${day.startTime}~${
          day.endTime
        }^`;
      }
    });
    const commonValues = {
      ...values,
      scheduleShifts: scheduleShifts.slice(0, -1),
      scheduleType: 2,
      frequencyType: 1,
      frequencyInterval: 0,
      startDate: DateTime.now(),
      startTime: DateTime.now(),
      endDate: DateTime.local(2050, 1, 1),
      endTime: DateTime.local(2050, 1, 1),
      operatorId: values.membership?.operatorId,
      organizationId: values.membership?.organizationId,
      divisionId: values.membership?.divisionId,
      subdivisionId: values.membership?.subdivisionId
    };
    if (isNew) {
      dispatch(
        scheduleActions.startAddingShift({
          ...commonValues,
          scheduleId: uuidv4()
        })
      );
    } else {
      dispatch(
        scheduleActions.startEditingShift(
          omit(
            {
              ...commonValues,
              scheduleId: currentSchedule.scheduleId
            },
            ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
          )
        )
      );
    }
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenShiftPane}
      title={
        isNew
          ? intl.formatMessage({ id: 'schedules.addShift' })
          : intl.formatMessage({ id: 'schedules.editShift' })
      }
      width={'50rem'}
      onRequestClose={() => {
        closeSchedulePane();
        reset();
        setShiftDays([]);
      }}
    >
      {/*Nombre y membership*/}
      <Row>
        <Col sm="12">
          <Controller
            control={control}
            name="scheduleName"
            shouldUnregister
            defaultValue={initialValues?.scheduleName}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldstate={fieldState}
                id={'scheduleNameInput'}
                required={true}
                label={<FormattedMessage id="schedules.name" />}
                type={'text'}
              />
            )}
          />
        </Col>
        <Membership
          name="membership"
          shouldUnregister
          control={control}
          defaultValue={initialValues?.membership}
          options={{
            useOperator: false,
            useUserOrganization: true,
            useUserDivision: true,
            useUserSubdivision: true,
            setUserOrganization: true,
            setUserDivision: true,
            setUserSubdivision: true,
            showAllDivision: true,
            showAllSubdivision: true,
            colSize: 12
          }}
          isInModal={true}
        />
      </Row>
      {/*Checkboxes de días*/}
      <Row>
        <>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="monday"
              shouldUnregister
              defaultValue={initialValues?.monday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.monday" />} />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="tuesday"
              shouldUnregister
              defaultValue={initialValues?.tuesday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.tuesday" />} />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="wednesday"
              shouldUnregister
              defaultValue={initialValues?.wednesday}
              render={({ field }) => (
                <CheckBoxInput
                  field={field}
                  label={<FormattedMessage id="schedules.wednesday" />}
                />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="thursday"
              shouldUnregister
              defaultValue={initialValues?.thursday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.thursday" />} />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="friday"
              shouldUnregister
              defaultValue={initialValues?.friday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.friday" />} />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="saturday"
              shouldUnregister
              defaultValue={initialValues?.saturday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.saturday" />} />
              )}
            />
          </Col>
          <Col sm={1} className={'pl-0 pl-sm-0 pl-md-3 pl-lg-4 pl-xl-5'}>
            <Controller
              control={control}
              name="sunday"
              shouldUnregister
              defaultValue={initialValues?.sunday}
              render={({ field }) => (
                <CheckBoxInput field={field} label={<FormattedMessage id="schedules.sunday" />} />
              )}
            />
          </Col>
        </>
      </Row>
      <br />
      {/*Botones para seleccionar días*/}
      <Row>
        <>
          <Col sm="12 d-flex justify-content-center">
            <Button.Ripple
              className="mr-1"
              color="primary"
              onClick={() => {
                setValue('sunday', true);
                setValue('monday', true);
                setValue('tuesday', true);
                setValue('wednesday', true);
                setValue('thursday', true);
                setValue('friday', true);
                setValue('saturday', true);
              }}
            >
              {intl.formatMessage({ id: 'schedules.selectAllDays' })}
            </Button.Ripple>
            <Button.Ripple
              className="mr-1"
              color="primary"
              onClick={() => {
                setValue('monday', true);
                setValue('tuesday', true);
                setValue('wednesday', true);
                setValue('thursday', true);
                setValue('friday', true);
                setValue('saturday', false);
                setValue('sunday', false);
              }}
            >
              {intl.formatMessage({ id: 'schedules.selectWeekDays' })}
            </Button.Ripple>
            <Button.Ripple
              className="mr-1"
              color="light"
              onClick={() => {
                setValue('monday', false);
                setValue('tuesday', false);
                setValue('wednesday', false);
                setValue('thursday', false);
                setValue('friday', false);
                setValue('saturday', false);
                setValue('sunday', false);
              }}
            >
              {intl.formatMessage({ id: 'schedules.deselectAll' })}
            </Button.Ripple>
          </Col>
        </>
      </Row>
      <br />
      {/*Horas*/}
      <Row>
        <>
          <Col sm="6">
            <Controller
              control={control}
              name="startTime"
              shouldUnregister
              defaultValue={initialValues?.startTime}
              render={({ field, fieldState }) => (
                <TimeInput
                  field={field}
                  fieldstate={fieldState}
                  label={<FormattedMessage id="schedules.startTime" />}
                />
              )}
            />
          </Col>
          <Col sm="6">
            <Controller
              control={control}
              name="endTime"
              shouldUnregister
              defaultValue={initialValues?.endTime}
              render={({ field, fieldState }) => (
                <TimeInput
                  field={field}
                  fieldstate={fieldState}
                  label={<FormattedMessage id="schedules.endTime" />}
                />
              )}
            />
          </Col>
        </>
      </Row>
      <br />
      {/*Botones para agregar botones a tabla*/}
      <Row>
        <>
          <Col sm="12 d-flex justify-content-center">
            <Button.Ripple
              className="mr-1"
              color="primary"
              /*Función para agregar valores a la tabla*/
              onClick={() => {
                //Se revisa que la hora sea válida y se crean los horarios a agregar
                if (
                  formValues.startTime &&
                  formValues.endTime &&
                  formValues.endTime > formValues.startTime
                ) {
                  const daysToAdd = daysList
                    .map((day, index) => {
                      if (formValues[day]) {
                        return {
                          key: index,
                          day: intl.formatMessage({ id: `common.${day}` }),
                          startTime: formValues.startTime,
                          endTime: formValues.endTime
                        };
                      }
                    }) /*Se revisa que tenga por lo menos un día*/
                    .filter((day) => day);
                  /*Devuelve los horarios válidos*/
                  const validDays = daysToAdd.filter(
                    (newDay) =>
                      shiftDays.filter((currentDay) => {
                        /*Si los días no son iguales no es necesario revisar las horas*/
                        if (newDay?.key !== currentDay.key) return false;
                        const newDayStartTime = Date.parse(newDay?.startTime);
                        const newDayEndTime = Date.parse(newDay?.endTime);
                        const currentDayStartTime = Date.parse(currentDay.startTime);
                        const currentDayEndTime = Date.parse(currentDay.endTime);
                        return (
                          newDayStartTime === currentDayStartTime ||
                          newDayEndTime === currentDayEndTime ||
                          (newDayStartTime <= currentDayEndTime &&
                            newDayStartTime >= currentDayStartTime) ||
                          (newDayEndTime <= currentDayEndTime &&
                            newDayEndTime >= currentDayStartTime)
                        );
                      }).length === 0
                  );
                  if (validDays.length > 0) {
                    if (daysToAdd.length !== validDays.length) {
                      alerts.showErrorAlertFailChangesToast({
                        error: {
                          errorMessage: intl.formatMessage(
                            { id: 'schedules.partialDaysErrorMessage' },
                            {
                              errorDays: daysToAdd.length - validDays.length,
                              totalDays: daysToAdd.length
                            }
                          )
                        }
                      });
                    }
                    setShiftDays([...shiftDays, ...validDays]);
                    changeInnerSelected([]);
                  } else {
                    if (daysToAdd.length === 0) {
                      alerts.showErrorAlertFailChangesToast({
                        error: {
                          errorMessage: intl.formatMessage({ id: 'schedules.daysErrorMessage' })
                        }
                      });
                    } else {
                      alerts.showErrorAlertFailChangesToast({
                        error: {
                          errorMessage: intl.formatMessage({
                            id: 'schedules.hoursRangeErrorMessage'
                          })
                        }
                      });
                    }
                  }
                } else {
                  if (formValues.startTime == null) {
                    alerts.showErrorAlertFailChangesToast({
                      error: {
                        errorMessage: intl.formatMessage({ id: 'schedules.startHourErrorMessage' })
                      }
                    });
                  } else if (formValues.endTime == null) {
                    alerts.showErrorAlertFailChangesToast({
                      error: {
                        errorMessage: intl.formatMessage({ id: 'schedules.endHourErrorMessage' })
                      }
                    });
                  } else {
                    alerts.showErrorAlertFailChangesToast({
                      error: {
                        errorMessage: intl.formatMessage({ id: 'schedules.hoursErrorMessage' })
                      }
                    });
                  }
                }
              }}
            >
              <FormattedMessage id="common.add" />
            </Button.Ripple>
          </Col>
        </>
      </Row>
      <br />
      {/*Botón para eliminar entradas en horarios*/}
      <Toolbar
        buttons={[
          <DeleteButton
            tooltipMessage={'schedules.rows'}
            isDisabled={innerSelected.length < 1}
            handleClick={() => {
              setShiftDays(
                shiftDays.filter(
                  (shiftDay) =>
                    !innerSelected.find(
                      (element) =>
                        element.day === shiftDay.day &&
                        element.startTime.getTime() == shiftDay.startTime.getTime() &&
                        element.endTime.getTime() == shiftDay.endTime.getTime()
                    )
                )
              );
            }}
          />
        ]}
      />
      <br />
      {/*Tabla con valores ingresados*/}
      <Row>
        <>
          <Table
            id={'tableSchedulesSchedulesListScreen3'}
            columns={columnsTable}
            data={orderBy(shiftDays, ['key', 'startTime'])}
            changeSelected={changeInnerSelected}
            selected={innerSelected}
            allowMultipleSelection={true}
            allowDeleting={true}
            allowSorting={false}
            enablePersistence={false}
            actionSave={(columns) => {
              if (columns.startime == null || columns.endTime == null) {
                setShiftDays([]);
              }
              setShiftDays(
                shiftDays.map((day) => {
                  if (
                    columns.startTime &&
                    columns.endTime &&
                    day.key === columns.key &&
                    day.day === columns.day
                  ) {
                    let errors = 0;
                    for (let currentDay in shiftDays) {
                      if (
                        shiftDays[currentDay].key === day.key &&
                        `${shiftDays[currentDay].startTime?.getHours()}:${shiftDays[
                          currentDay
                        ].startTime?.getMinutes()}` ===
                          `${columns.startTime?.getHours()}:${columns.startTime?.getMinutes()}` &&
                        `${shiftDays[currentDay].endTime?.getHours()}:${shiftDays[
                          currentDay
                        ].endTime?.getMinutes()}` ===
                          `${columns.endTime?.getHours()}:${columns.endTime?.getMinutes()}`
                      ) {
                        errors++;
                      }
                    }
                    if (columns.endTime.getTime() > columns.startTime.getTime() && errors === 0) {
                      return {
                        ...day,
                        startTime: columns.startTime,
                        endTime: columns.endTime
                      };
                    }
                  }
                  return { ...day, startTime: day.startTime, endTime: day.endTime };
                })
              );
            }}
          />
          <br />
          <br />
        </>
      </Row>
      {/*Botones para guardar y cancelar*/}
      <Row className={'footer-slide-pane'}>
        <Col sm="12">
          <Button.Ripple
            className="mr-1 mt-50 mb-50"
            color="primary"
            /*type="submit"*/
            onClick={handleSubmit((values) => {
              closeSchedulePane(false);
              saveShift(values);
              reset();
              setShiftDays([]);
            })}
          >
            <FormattedMessage id="common.save" />
          </Button.Ripple>
          <Button.Ripple
            className="mt-50 mb-50"
            color="light"
            onClick={() => {
              closeSchedulePane();
              reset();
              setShiftDays([]);
            }}
          >
            <FormattedMessage id="common.cancel" />
          </Button.Ripple>
        </Col>
      </Row>
    </SlidingPane>
  );
};
export default connect(
  (state) => ({
    initialValues: scheduleSelectors.getSelectedSchedule(state)
      ? //Valores existentes
        {
          scheduleName: scheduleSelectors.getSelectedSchedule(state).scheduleName,
          scheduleType: scheduleSelectors.getSelectedSchedule(state).frequencyType === 1 ? 0 : 1,
          frequency:
            scheduleSelectors.getSelectedSchedule(state).frequencyType === 4
              ? 0
              : scheduleSelectors.getSelectedSchedule(state).frequencyType === 8
              ? 1
              : 2,
          membership: {
            organizationId: scheduleSelectors.getSelectedSchedule(state).organizationId,
            divisionId: scheduleSelectors.getSelectedSchedule(state).divisionId,
            subdivisionId: scheduleSelectors.getSelectedSchedule(state).subdivisionId
          },
          oneTimeDate: DateTime.fromISO(scheduleSelectors.getSelectedSchedule(state).startDate)
            .plus({
              hour: scheduleSelectors.getSelectedSchedule(state).startTime.hours,
              minute: scheduleSelectors.getSelectedSchedule(state).startTime.minutes
            })
            .toString(),
          oneTimeTime: DateTime.local(
            2000,
            1,
            2,
            scheduleSelectors.getSelectedSchedule(state).startTime.hours,
            scheduleSelectors.getSelectedSchedule(state).startTime.minutes
          ).toFormat('HH-mm'),
          recurrenceRange: [
            scheduleSelectors.getSelectedSchedule(state).startDate,
            scheduleSelectors.getSelectedSchedule(state).endDate
          ],
          sunday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            0
          ),
          monday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            1
          ),
          tuesday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            2
          ),
          wednesday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            3
          ),
          thursday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            4
          ),
          friday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            5
          ),
          saturday: utility.isBitSet(
            scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
            6
          ),
          monthlyDay: scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
          //Valores iniciales
          frequencyDaily:
            scheduleSelectors.getSelectedSchedule(state).frequencyType === 4
              ? scheduleSelectors.getSelectedSchedule(state).frequencyInterval === 1
                ? 1
                : 0
              : 0,
          frequencyWeekly:
            scheduleSelectors.getSelectedSchedule(state).frequencyType === 8
              ? scheduleSelectors.getSelectedSchedule(state).frequencyRelativeInterval === 0
                ? 1
                : 0
              : 0,
          frequencyMonthly:
            scheduleSelectors.getSelectedSchedule(state).frequencyRecurrenceFactor > 1 ? 0 : 1,
          frequencyDailyInterval: scheduleSelectors.getSelectedSchedule(state).frequencyInterval,
          frequencyWeeklyInterval:
            scheduleSelectors.getSelectedSchedule(state).frequencyRecurrenceFactor,
          // oneTimeDate: DateTime.local(2050, 1, 1).toString().slice(0, -6) + 'Z'
          isMonthlyRelative: scheduleSelectors.getSelectedSchedule(state).frequencyType === 32,
          relativeMonth:
            scheduleSelectors.getSelectedSchedule(state).frequencyRelativeInterval === 1
              ? 0
              : scheduleSelectors.getSelectedSchedule(state).frequencyRelativeInterval === 2
              ? 1
              : scheduleSelectors.getSelectedSchedule(state).frequencyRelativeInterval === 4
              ? 2
              : scheduleSelectors.getSelectedSchedule(state).frequencyRelativeInterval === 8
              ? 3
              : 4,
          weekDaysList: scheduleSelectors.getSelectedSchedule(state).frequencyInterval
        }
      : //Valores iniciales
        {
          frequencyType: 0,
          frequencyDaily: 1,
          frequencyWeekly: 1,
          frequencyMonthly: 1,
          frequencyInterval: 1,
          frequencyDayType: 1
        }
  }),
  undefined,
  (stateProps, dispatchProps, ownProps: any) => ({
    ...ownProps,
    ...stateProps
  })
)(ShiftSlide);
