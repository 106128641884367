import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import * as types from '../../../types/preventiveNotices/noticesByOdometer';
import { NoticeDTO } from '../../../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.NOTICES_BY_ODOMETER_FETCH_COMPLETED:
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((noticeId) => {
        newState[noticeId] = {
          ...state[noticeId],
          ...entities[noticeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.NOTICE_BY_ODOMETER_ADD_COMPLETED: {
      const { id, oldId, notice } = action.payload;
      const newState = omit(state, oldId);
      newState[id] = {
        ...notice,
        isConfirmed: true,
        isSelected: false
      };
      return newState;
    }

    case types.NOTICE_BY_ODOMETER_RENEW_COMPLETED:
    case types.NOTICE_BY_ODOMETER_EDIT_COMPLETED: {
      const notice = action.payload;
      const id = `${notice.unitId}-${notice.noticeId}`;
      const newState = {
        ...state,
        [id]: {
          ...omit(state[id], ['oldNotice']),
          ...omit(notice, ['oldNotice']),
          isConfirmed: true
        }
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.NOTICE_BY_ODOMETER_ADD_STARTED: {
      const notice = action.payload;
      const newState = { ...state };
      newState[notice.tempId] = {
        isSelected: false,
        ...notice,
        isConfirmed: false
      };
      return newState;
    }

    case types.NOTICE_BY_ODOMETER_RENEW_STARTED:
    case types.NOTICE_BY_ODOMETER_EDIT_STARTED: {
      const notice = action.payload;
      const id = `${notice.unitId}-${notice.noticeId}`;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          oldNotice: state[id],
          isConfirmed: false,
          ...notice
        }
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.NOTICE_BY_ODOMETER_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.NOTICE_BY_ODOMETER_RENEW_FAILED:
    case types.NOTICE_BY_ODOMETER_EDIT_FAILED: {
      const { oldNotice } = action.payload;
      return {
        ...state,
        [oldNotice.noticeId]: {
          ...omit(state[oldNotice.noticeId], ['oldNotice']),
          ...oldNotice,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.NOTICE_BY_ODOMETER_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return omit(state, id);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.NOTICES_BY_ODOMETER_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.NOTICE_BY_ODOMETER_ADD_COMPLETED: {
      const { id, oldId } = action.payload;
      return state.map((noticeId) => (noticeId === oldId ? id : noticeId));
    }

    case types.NOTICE_BY_ODOMETER_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return state.filter((noticeIdState) => noticeIdState !== id);
    }

    //CASE ADD STARTED
    case types.NOTICE_BY_ODOMETER_ADD_STARTED: {
      const notice = action.payload;
      return [...state, notice.tempId];
    }

    //CASE ADD FAILED
    case types.NOTICE_BY_ODOMETER_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((noticeIdState) => noticeIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.NOTICES_BY_ODOMETER_FETCH_STARTED: {
      return true;
    }
    case types.NOTICES_BY_ODOMETER_FETCH_COMPLETED:
    case types.NOTICES_BY_ODOMETER_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_COMPLETED:
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_ADD_STARTED: {
      return true;
    }
    case types.NOTICE_BY_ODOMETER_ADD_COMPLETED:
    case types.NOTICE_BY_ODOMETER_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_EDIT_STARTED: {
      return true;
    }
    case types.NOTICE_BY_ODOMETER_EDIT_COMPLETED:
    case types.NOTICE_BY_ODOMETER_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_REMOVE_STARTED: {
      return true;
    }
    case types.NOTICE_BY_ODOMETER_REMOVE_COMPLETED:
    case types.NOTICE_BY_ODOMETER_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.NOTICES_BY_ODOMETER_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.NOTICES_BY_ODOMETER_FETCH_STARTED:
    case types.NOTICES_BY_ODOMETER_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_STARTED:
    case types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.NOTICE_BY_ODOMETER_ADD_STARTED:
    case types.NOTICE_BY_ODOMETER_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.NOTICE_BY_ODOMETER_EDIT_STARTED:
    case types.NOTICE_BY_ODOMETER_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.NOTICE_BY_ODOMETER_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.NOTICE_BY_ODOMETER_REMOVE_STARTED:
    case types.NOTICE_BY_ODOMETER_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.NOTICES_BY_ODOMETER_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const noticesByOdometer = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

export default noticesByOdometer;

//Own state
export const getOwnState = (state) => state.noticesByOdometer;

//Information
export const getNotice = (state, id) => {
  const notice = getOwnState(state).byId[id];

  return {
    ...notice
  };
};

export const getNoticesList = (state): Array<NoticeDTO> =>
  getOwnState(state).order?.map((id) => getNotice(state, id));

export type NoticesListStateType = 'ALL' | 'EXPIRED' | 'CURRENT';

export const getNoticesListByMembershipAndState = (
  state: any,
  membership: any,
  stateType: NoticesListStateType
) => {
  if (stateType === 'CURRENT') {
    return getNoticesListByMembership(state, membership).filter((x) => x.progress < 100);
  } else if (stateType === 'EXPIRED') {
    return getNoticesListByMembership(state, membership).filter((x) => x.progress >= 100);
  } else {
    return getNoticesListByMembership(state, membership);
  }
};

export const getNoticesListByMembership = (state, membership) => {
  return getNoticesList(state).filter(
    (notice) =>
      !membership ||
      (membership &&
        (!membership.operatorId || membership.operatorId === notice.operatorId) &&
        (!membership.organizationId || membership.organizationId === notice.organizationId) &&
        (!membership.divisionId || membership.divisionId === notice.divisionId) &&
        (!membership.subdivisionId || membership.subdivisionId === notice.subdivisionId))
  );
};

//Status of sagas
export const isPartialFetchingListNotices = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListNotices = (state) => getOwnState(state).isFetchingList;
export const haveNoticesBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingNotice = (state) => getOwnState(state).isAdding;
export const isEditingNotice = (state) => getOwnState(state).isEditing;
export const isRemovingNotice = (state) => getOwnState(state).isRemoving;
//Errors
export const getPartialFetchingListNoticesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListNoticesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingNoticeErrors = (state) => getOwnState(state).errorAdding;
export const getEditingNoticeErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingNoticeErrors = (state) => getOwnState(state).errorRemoving;
