const permissions = {
  viewWorkplanModule: 'WP001',
  assignWorkplan: 'WP002',
  searchWorkplans: 'WP020',
  viewPlansIndicator: 'WP021',
  viewLocationsIndicator: 'WP022',
  viewVisitsIndicator: 'WP023',
  viewExecutionIndicator: 'WP024',
  viewArrivalEffectivnessGraph: 'WP025',
  viewPermanenceEffectivenessGraph: 'WP026',
  viewLocationsUnitsEffectivenessGraph: 'WP027',
  changeUnit: 'WP028',
  finalizeWorkplanAssignment: 'WP029',
  viewWorkplanMonitor: 'WP019',
  cancelWorkplanAssignment: 'WP030',
  viewWorkplanReport: 'WP031',
  addWorkplan: 'WP035',
  editWorkplan: 'WP036',
  deleteWorkplan: 'WP037',
  viewWorkplanCatalog: 'WP038',
  scheduledWorkplanList: 'WP018',
  scheduledWorkplanEdit: 'WP032',
  scheduledWorkplanDelete: 'WP033',
  loginextConfiguration: 'WP042'
};

export default permissions;
