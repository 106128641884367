/* -------------------------------------------------------------------------- */
/*                            Schema Forms                                  */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';
import { utils } from 'sortablejs';
import * as util from 'utility/Utils';

const formSchema = new schema.Entity('forms', {}, { idAttribute: 'organizationFormId' });
export const forms = new schema.Array(formSchema);

const sectionSchema = new schema.Entity('sections', {}, { idAttribute: 'sectionId' });
export const sections = new schema.Array(sectionSchema);

const questionSchema = new schema.Entity('questions', {}, { idAttribute: 'questionId' });
export const questions = new schema.Array(questionSchema);

const responseSchema = new schema.Entity(
  'responses',
  { questionResponse: [questionSchema] },
  {
    idAttribute: 'responseId',
    mergeStrategy: (entityA, entityB) =>
      !util.isIterable(entityA) ? [entityA, entityB] : [...entityA, entityB]
  }
);
export const responses = new schema.Array(responseSchema);
