export const getImportedLocationStatusColorClass = (success) => {
  return success ? 'light-success' : 'light-danger';
};

export const showUnitByZoomAndRadius = (zoom, radius) => {
  if (zoom >= 15) return true;
  else if (zoom === 14) return true;
  else if (zoom === 13) return radius >= 100;
  else if (zoom === 12) return radius >= 500;
  else if (zoom === 11) return radius >= 1000;
  else if (zoom === 10) return radius >= 1500;
  else if (zoom === 9) return radius >= 2000;
  else return false;
};
