import { useEffect, useState } from 'react';
import { getOperatorContactsService } from '../services/operators';
import camelcaseKeys from 'camelcase-keys';

const useGetOperatorContacts = (
  operatorId: number | undefined
): {
  operatorContacts: any;
  isFetchingOperatorContacts: boolean;
} => {
  const [operatorContacts, setData] = useState<any>([]);
  const [isFetchingOperatorContacts, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (operatorId !== undefined && operatorId !== null) {
      setIsFetching(true);
      getOperatorContactsService(
        {
          operatorId
        },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response?.data !== undefined && response?.data !== null) {
              setData(camelcaseKeys(response.data));
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [operatorId]);
  return { operatorContacts, isFetchingOperatorContacts };
};

export { useGetOperatorContacts };
