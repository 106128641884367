const paths = {
  telemetryTemperature: '/telemetry/temperature',
  telemetryFuelMonitor: '/telemetry/fuel/monitor',
  telemetryFuelReport: '/telemetry/fuel/report',
  telemetryFuelReportByVehicles: '/telemetry/fuel/vehicles/report',
  telemetryUnits: '/telemetry/units',
  telemetryOBD2: '/telemetry/obd2'
};

export default paths;
