import { combineReducers } from 'redux';

import union from 'lodash/union';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED IMPORT
    case types.LOCATIONS_IMPORT_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((id) => {
        newState[id] = {
          isSelected: false,
          isShownInMap: false,
          ...state[id],
          ...entities[id],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED IMPORT
    case types.LOCATIONS_IMPORT_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const isImporting = (state = false, action) => {
  switch (action.type) {
    case types.LOCATIONS_IMPORT_STARTED: {
      return true;
    }
    case types.LOCATIONS_IMPORT_COMPLETED:
    case types.LOCATIONS_IMPORT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorImporting = (state = null, action) => {
  switch (action.type) {
    case types.LOCATIONS_IMPORT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.LOCATIONS_IMPORT_STARTED:
    case types.LOCATIONS_IMPORT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const importedLocations = combineReducers({
  byId,
  order,
  isImporting,
  errorImporting
});

export default importedLocations;
// export default locations;

//Own state
export const getOwnState = (state) => state.locations.importedLocations;

//Information
export const getLocation = (state, id) => getOwnState(state).byId[id];

export const getLocationsList = (state) =>
  getOwnState(state).order.map((id) => getLocation(state, id));

//Status of sagas
export const isImportingLocations = (state) => getOwnState(state).isImporting;

//Errors
export const getImportingLocationsErrors = (state) => getOwnState(state).errorImporting;
