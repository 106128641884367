import { HttpWebtrack } from 'utility/http';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';
import { ConfigActions } from 'utility/http/http-events';

export const baseUrl = '/route';

interface Request {
  routeAssignmentId: number;
}

export const getRouteExecutionsReportService = async (
  request: Request,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);

  return httpWebtrack.post(`${baseUrl}/getExecutionsReport`, request);
};

export const getRouteExecutionService = async (request: Request, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);

  return httpWebtrack.post(`${baseUrl}/getExecution`, request);
};

export const saveRouteExecutionService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.unitName}`,
    object: 'routes.assignmentOfUnit',
    action: request.routeAssignmentId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveExecution`, request);
};

export const changeToAlternateRouteService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);

  return httpWebtrack.post(`${baseUrl}/changeToAlternateRoute`, request);
};
