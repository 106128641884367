// @ts-nocheck
// import Control from 'react-leaflet-control';
import '@geoman-io/leaflet-geoman-free';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-toolbar';
import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Eye, EyeOff, X } from 'react-feather';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'utility/icomoon';
import * as authSelectors from '../../../../../redux/reducers/auth';
import unitsPermissions from '../../../../../views/screens/Units/permissions';
import * as unitActions from '../../../../../views/screens/Units/actions';

export interface UnitsToolbarOptions {
  useToolbar: boolean;
  showUnits?: bool;
  setSelectedUnit?: any;
  setShowUnits?: Function;
  listenMapControlChanges?: any[];
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

/* -------------------------------------------------------------------------- */
/*                             ToolbarUnitsControl                           */
/* -------------------------------------------------------------------------- */
const ToolbarUnitsControl = (props: UnitsToolbarOptions) => {
  const {
    showUnits,
    setShowUnits,
    setSelectedUnit,
    position = 'topleft',
    listenMapControlChanges
  } = props;
  const context = useLeafletContext();
  const intl = useIntl();
  const dispatch = useDispatch();

  const { store } = useSelector((state) => ({
    store: state
  }));

  useEffect(() => {
    const container = context.layerContainer || context.map;
    try {
      var ImmediateSubAction = L.Toolbar2.Action.extend({
        initialize: function (map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks: function () {
          this.myAction.disable();
        }
      });

      var createNewUnitAction = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: showUnits
              ? intl.formatMessage({ id: 'map.unitsToolbar.tooltipHideUnits' })
              : intl.formatMessage({ id: 'map.unitsToolbar.tooltipShowUnits' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>{showUnits ? <EyeOff size={14} /> : <Eye size={14} />}</>
            )
          }
        },
        addHooks: () => {
          if (!showUnits) {
            dispatch(unitActions.showAllUnitsInMap());
          } else {
            dispatch(unitActions.hideAllUnitsInMap());
          }
          setShowUnits(!showUnits);
        }
      });

      var cancelUnit = ImmediateSubAction.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'common.tooltipCancel' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <X size={14} />
              </>
            )
          }
        },
        addHooks: function () {
          ImmediateSubAction.prototype.addHooks.call(this);
        }
      });

      var unitsOptions = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.unitsToolbar.tooltipUnits' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <Icon size={16} icon="Crosshair" />
              </>
            )
          },

          subToolbar: new L.Toolbar2({
            actions: [createNewUnitAction, cancelUnit].filter((action) => action != null)
          })
        },
        disable: function (e) {
          L.Toolbar2.Action.prototype.disable.call(this);
        }
      });

      L.Toolbar2.UnitsToolbar = L.Toolbar2.Control.extend({
        options: {
          actions: [unitsOptions]
        }
      });

      var unitsToolbar = new L.Toolbar2.UnitsToolbar({ position: position });
      unitsToolbar.addTo(container);

      return () => {
        unitsToolbar.remove();
      };
    } catch {}
  }, [...listenMapControlChanges]);
  return null;
};

ToolbarUnitsControl.defaultProps = {
  position: 'topleft',
  showUnits: false,
  setShowUnits: () => null,
  listenMapControlChanges: []
};

export default ToolbarUnitsControl;
