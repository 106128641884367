import Icon from '../../utility/icomoon';
import paths from 'views/screens/Groups/paths';
import permissions from 'views/screens/Groups/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'groups',
    title: 'groups.groups',
    icon: <Icon size={20} icon="Groups" />,
    permissions: [permissions.listGroup],
    iconName: 'Groups',
    navLink: paths.listGroup,
    screenId: screens.GROUPS
  }
];
