const permissions = {
  listGroup: 'GP001',
  addGroup: 'GP002',
  editGroup: 'GP003',
  removeGroup: 'GP004',
  addUsersToGroup: 'GP005',
  removeUsersFromGroup: 'GP009',
  addLocationsToGroup: 'GP006',
  removeLocationsFromGroup: 'GP010',
  addWorkplansToGroup: 'GP007',
  removeWorkplansFromGroup: 'GP011',
  addUnitsToGroup: 'GP008',
  removeUnitsFromGroup: 'GP012',
  addGeofencesToGroup: 'GP014',
  removeGeofencesFromGroup: 'GP015',
  addDriversToGroup: 'GP016',
  removeDriversFromGroup: 'GP017',
  appConfiguration: 'GP013'
};

export default permissions;
