import telemetry from 'views/screens/Telemetry/sidebarLayout';

export default [
  {
    header: 'telemetry.telemetry',
    iconName: 'Target',
    children: [...telemetry]
  },
  ...telemetry
];
