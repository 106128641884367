import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { Button, Tooltip, Spinner, DropdownToggle } from 'reactstrap';
import { useSelector } from 'react-redux';
import * as authSelectors from 'redux/reducers/auth';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from '../../../utility/icomoon';
import { BroadcastChannel } from 'broadcast-channel';

interface Props {
  id?: string;
  color?: string;
  isDisabled?: boolean;
  permissions?: any[];
  channelName: string;
  handleClick: any;
  tooltipMessage?: string;
  useEmbbededChannel?: boolean;
  reportDataSource?: ReportDataSource;
  isLoading?: boolean;
  isVisible?: boolean;
  isDataReady?: boolean;
  reportPath?: string;
  isTextButton?: boolean;
  title?: string;
}

const ReportViewerButton = (props: Props) => {
  const store = useSelector((state) => state);
  const {
    isDisabled,
    handleClick,
    tooltipMessage,
    permissions,
    channelName,
    id,
    color,
    reportDataSource,
    reportPath,
    useEmbbededChannel,
    isLoading,
    isVisible,
    isDataReady,
    isTextButton,
    title
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const user = useSelector(authSelectors.getUser);
  const intl = useIntl();
  const [buttonClicked, setButtonClicked] = useState(false);

  //CANAL DE COMUNICACION PARA EL VISOR DE REPORTE
  const channel = useMemo(() => new BroadcastChannel(channelName), []);
  const [isReportViewerOpen, setIsReportViewerOpen] = useState(false);

  useEffect(() => {
    let isActive = true;
    if (useEmbbededChannel) {
      channel.addEventListener('message', (e) => {
        if (!isActive) return;
        setIsReportViewerOpen(e.isReportOpen);
      });
    }
    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    if (isReportViewerOpen) {
      postReportData();
    }
  }, [isReportViewerOpen]);

  useEffect(() => {
    if (buttonClicked === true && isDataReady === true) {
      window.open(`/report-viewer/${channelName}`, '_blank');
      setButtonClicked(false);
    }
  }, [buttonClicked, isDataReady]);

  const postReportData = () => {
    //enviar info del reporte

    //dar formato a reportDataSource

    const infoHeader = {
      name: 'infoHeaderDataSet',
      value: [
        {
          title: reportDataSource?.title,
          subtitle: reportDataSource?.subtitle,
          filters: reportDataSource?.filters,
          tableHeaderColor: primary,
          audit:
            intl.formatMessage({ id: 'reports.generationUser' }) +
            user.username +
            ' - ' +
            intl.formatMessage({ id: 'reports.generationDate' }) +
            moment().format('YYYY-MM-DD HH:mm'),
          partnerLogo: 'https://www.webtrack.online/desarrollo/reloaded/images/logo.png'
        }
      ]
    };

    //verificar que infoHeader exista
    if (
      reportDataSource?.dataSets &&
      reportDataSource?.dataSets.findIndex((ds) => ds.name == 'infoHeaderDataSet') < 0
    )
      reportDataSource?.dataSets.push(infoHeader);

    // actualizar datasets
    const datasets = reportDataSource?.dataSets?.map((ds) =>
      ds.name == 'infoHeaderDataSet'
        ? { ...ds, value: [{ ...ds.value[0], ...infoHeader.value[0] }] }
        : ds
    );
    channel.postMessage({
      reportDetail: datasets,
      reportPath: reportPath
    });
  };

  return isVisible &&
    (!permissions || authSelectors.getAuthUserHasPermissionsAny(store, permissions)) ? (
    <>
      <Button.Ripple
        id={id ? id : 'reportViewerButton'}
        className={`btn-icon ${isDisabled ? 'disabled' : ''}`}
        color={color ? color : 'light'}
        disabled={isDisabled}
        onClick={() => {
          setButtonClicked(true);
          handleClick();
        }}
      >
        {isVisible && isLoading ? (
          <>
            <Spinner as="span" animation="border" size={'sm'} role="status" aria-hidden="true" />
          </>
        ) : isVisible && !isTextButton ? (
          <Icon size={20} icon="noun_excel_3323808" />
        ) : (
          <FormattedMessage id={title} />
        )}
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={id ? id : 'reportViewerButton'}
          toggle={toggle}
        >
          <FormattedMessage id={tooltipMessage} />
        </Tooltip>
      </Button.Ripple>
      {/* <DropdownToggle
        caret
        color="primary"
      /> */}
    </>
  ) : null;
};

ReportViewerButton.defaultProps = {
  isDisabled: false,
  useEmbbededChannel: false,
  isDataReady: true,
  isLoading: false,
  isVisible: true
};

export default ReportViewerButton;

export interface ReportDataSource {
  title: string;
  subtitle?: string;
  filters?: string;
  dataSets?: ReportDataSet[];
}
export interface ReportDataSet {
  value: any[];
  name: string;
}
