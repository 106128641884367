/* -------------------------------------------------------------------------- */
/*                          Componente SlidingPaneForm                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un SlidingPaneForm genérico que se utiliza en muchas pantallas de la aplicación.

// @ts-nocheck
import '@geoman-io/leaflet-geoman-free';
import 'leaflet-routing-machine';
import { useState } from 'react';
import { Edit2, MapPin, MoreVertical, Plus, Trash } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Col, InputGroupText, ListGroupItem, Media, Row, Tooltip } from 'reactstrap';
import { Controller } from 'react-hook-form';
import Icon from 'utility/icomoon';
import LocationsDropdownInput from 'views/formComponents/LocationDropDown';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import * as locationSelectors from '../../../../screens/Locations/reducers';
import '../../utils/lrm-google';

interface Props {
  control: any;
  formValues: any;
  checkpoints: any[];
  createdLocations: any[];
  addCheckpoint: any;
  editCheckpoint: any;
  editPath: any;
  deleteCheckpoint: any;
  addNewMarkerOnMap: any;
  addGoogleLocationToCheckpoint: any;
  addLocationToCheckpoint: any;
  deleteLocationOfCheckpoint: any;
  item: any;
  index: number;
}

const RouteItem = (props: Props) => {
  const {
    control,
    formValues,
    checkpoints,
    createdLocations,
    addCheckpoint,
    editCheckpoint,
    editPath,
    deleteCheckpoint,
    addNewMarkerOnMap,
    addGoogleLocationToCheckpoint,
    addLocationToCheckpoint,
    deleteLocationOfCheckpoint,
    item,
    index
  } = props;
  const intl = useIntl();
  const [mouseEnter, setMouseEnter] = useState<boolean>(false);
  const [tooltipAddOpen, setTooltipAddOpen] = useState<boolean>(false);
  const [tooltipEditOpen, setTooltipEditOpen] = useState<boolean>(false);
  const [tooltipEditOpen2, setTooltipEditOpen2] = useState<boolean>(false);
  const [tooltipEditSegmentOpen, setTooltipEditSegmentOpen] = useState<boolean>(false);
  const [tooltipDeleteOpen, setTooltipDeleteOpen] = useState<boolean>(false);

  const locations = useSelector((state) => {
    const dropDownLocations = [...locationSelectors.getLocationsList(state), ...createdLocations];
    return (
      item.locationId && !dropDownLocations.map((loc) => loc.locationId).includes(item.locationId)
        ? [...dropDownLocations, item]
        : dropDownLocations
    ).map((loc) =>
      loc.locationId === item.locationId ? { ...loc, locationName: item.checkpointName } : loc
    );
  });

  return (
    <ListGroupItem
      className={index === checkpoints.length - 1 ? '' : 'border-bottom-0 pb-0'}
      key={item.checkpointId}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseEnter(false)}
    >
      <Row className={'d-flex align-items-center'}>
        <Col
          id={'icon-' + index}
          sm="1"
          onClick={() =>
            item.latitude != null &&
            item.longitude != null &&
            editCheckpoint(
              item.checkpointId,
              index === 0 ? 1 : index === checkpoints.length - 1 ? 3 : 2,
              index
            )
          }
        >
          {index === 0 ? (
            <Icon
              style={item.latitude != null && item.longitude != null ? { cursor: 'pointer' } : {}}
              size={20}
              icon="Origin"
              className={'mb-1'}
            />
          ) : index === checkpoints.length - 1 ? (
            <Icon
              style={item.latitude != null && item.longitude != null ? { cursor: 'pointer' } : {}}
              size={20}
              icon="Destination"
              className={'mb-1'}
            />
          ) : (
            <Icon
              style={item.latitude != null && item.longitude != null ? { cursor: 'pointer' } : {}}
              size={20}
              icon="MapPin"
              className={'mb-1'}
            />
          )}
          {item.latitude != null && item.longitude != null && (
            <Tooltip
              placement="top"
              isOpen={tooltipEditOpen2}
              target={'icon-' + index}
              toggle={() => setTooltipEditOpen2(!tooltipEditOpen2)}
              trigger="click hover"
            >
              {intl.formatMessage({
                id:
                  index === 0
                    ? 'routes.editOrigin'
                    : index === checkpoints.length - 1
                    ? 'routes.editDestination'
                    : 'routes.editCheckpoint'
              })}
            </Tooltip>
          )}
        </Col>
        <Col sm="11">
          <Controller
            control={control}
            name={'checkpointId-' + item.checkpointId.toString()}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <LocationsDropdownInput
                field={field}
                fieldstate={fieldState}
                id={'locationIdInput-' + item.checkpointId.toString()}
                useGoogleLocations
                required
                isClearable
                onSelectChangeLocationType
                useLabel={false}
                additionalonchangefunction={(value) => {
                  value
                    ? formValues['checkpointId-' + item.checkpointId.toString()] !== value &&
                      (value?.googleLocation
                        ? addGoogleLocationToCheckpoint(item.checkpointId, value)
                        : addLocationToCheckpoint(item.checkpointId, value))
                    : formValues['checkpointId-' + item.checkpointId.toString()] &&
                      deleteLocationOfCheckpoint(item.checkpointId);
                }}
                isInModal={true}
                forceinitialvalue={item.locationId}
                options={locations.map((location) => ({
                  value: location.locationId,
                  label: location.locationName
                }))}
                appendcomponent={
                  <>
                    <InputGroupText
                      id={'editButton-' + index}
                      style={{ cursor: 'pointer' }}
                      className={'m-0'}
                      onClick={() =>
                        item.latitude != null && item.longitude != null
                          ? editCheckpoint(
                              item.checkpointId,
                              index === 0 ? 1 : index === checkpoints.length - 1 ? 3 : 2,
                              index
                            )
                          : addNewMarkerOnMap(index)
                      }
                    >
                      {item.latitude != null && item.longitude != null ? (
                        <Edit2 size={15} />
                      ) : (
                        <MapPin size={15} />
                      )}
                      <Tooltip
                        placement="top"
                        isOpen={tooltipEditOpen}
                        target={'editButton-' + index}
                        toggle={() => setTooltipEditOpen(!tooltipEditOpen)}
                        trigger="click hover"
                      >
                        {intl.formatMessage({
                          id:
                            item.latitude != null && item.longitude != null
                              ? index === 0
                                ? 'routes.editOrigin'
                                : index === checkpoints.length - 1
                                ? 'routes.editDestination'
                                : 'routes.editCheckpoint'
                              : 'routes.selectPointOnMap'
                        })}
                      </Tooltip>
                    </InputGroupText>
                    {checkpoints.length > 2 && (
                      <InputGroupText
                        id={'deleteButton-' + index}
                        style={{ cursor: 'pointer' }}
                        className={'m-0'}
                        onClick={() => deleteCheckpoint(item.checkpointId)}
                      >
                        <Trash size={15} />
                        {/* <Tooltip
                          placement="top"
                          isOpen={tooltipDeleteOpen}
                          target={'deleteButton-' + index}
                          toggle={() => setTooltipDeleteOpen(!tooltipDeleteOpen)}
                          trigger="click hover"
                        >
                          {intl.formatMessage({
                            id:
                              index === 0
                                ? 'routes.deleteOrigin'
                                : index === checkpoints.length - 1
                                ? 'routes.deleteDestination'
                                : 'routes.deleteCheckpoint'
                          })}
                        </Tooltip> */}
                      </InputGroupText>
                    )}
                  </>
                }
                placeholder={intl.formatMessage({
                  id: 'locations.locationPlaceholder'
                })}
              />
            )}
          />
        </Col>
      </Row>
      <Media>
        {index !== checkpoints.length - 1 && (
          <>
            <MoreVertical
              id={'segment-' + index}
              style={{ cursor: 'pointer' }}
              size={20}
              className={'mr-1'}
              onClick={() => editPath(index)}
            />
            <Tooltip
              placement="top"
              isOpen={tooltipEditSegmentOpen}
              target={'segment-' + index}
              toggle={() => setTooltipEditSegmentOpen(!tooltipEditSegmentOpen)}
              trigger="click hover"
            >
              {intl.formatMessage({
                id: 'routes.editSegment'
              })}
            </Tooltip>
          </>
        )}
        <div id={'addCheckpoint' + index} style={{ width: '20px', height: '20px' }}>
          {mouseEnter && (
            <>
              <Plus style={{ cursor: 'pointer' }} size={20} onClick={() => addCheckpoint(index)} />
              <Tooltip
                placement="right"
                isOpen={tooltipAddOpen}
                target={'addCheckpoint' + index}
                toggle={() => setTooltipAddOpen(!tooltipAddOpen)}
                trigger="click hover"
              >
                {intl.formatMessage({
                  id: 'routes.addNewCheckPoint'
                })}
              </Tooltip>
            </>
          )}
        </div>
      </Media>
    </ListGroupItem>
  );
};

RouteItem.defaultProps = {
  inputs: [],
  initialTab: 0,
  hasButtonSubmit: true,
  hasButtonCancel: true
};

export default RouteItem;
