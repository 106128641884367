/**
 * `endDateAdjustment` es una función que recibe los parametros data que se enviarán en
 * un request a backend y ajusta las fechas "fin" que no contienen segundos.
 * por ejemplo: 23:59 se convertirá en 23:59:59.999
 *
 * @param {Object} data - The original end date that needs to be adjusted.
 *
 * @returns {Object} - The adjusted end date.
 *
 * @example
 *
 * const data = { ...moreParams, endData: '2024-01-25 23:59'};
 * const adjustedData = endDateAdjustment(data);
 *
 * console.log(adjustedData); // Logs { ...moreParams, endData: '2024-01-25 23:59:59.999'}
 */
export const endDateAdjustment = (data: Object): Object => {
  if (data) {
    try {
      //console.log('Object.keys(data)', Object.keys(data));
      //console.log('data', data);
      Object.keys(data)?.map((key: any) => {
        if (
          key.toUpperCase().includes('DATE') &&
          data[key] !== null &&
          data[key].slice(-5) == '23:59'
        ) {
          data[key] = data[key].replace('23:59', '23:59:59.999');
        }
      });
      //console.log('data final', data);
    } catch (e) {}
  }
  return data;
};
