import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.AGENTS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((agentId) => {
        newState[agentId] = {
          isSelected: false,
          ...entities[agentId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.AGENTS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          ...state[unitId],
          ...entities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.AGENT_FETCH_COMPLETED: {
      const agent = action.payload;
      const newState = { ...state };
      newState[agent.agentId] = {
        isSelected: false,
        ...state[agent.agentId],
        ...agent,
        isConfirmed: true
      };
      return newState;
    }

    case types.AGENT_ADD_COMPLETED: {
      const { oldId, agent } = action.payload;
      const newState = omit(state, oldId);
      newState[agent.agentId] = {
        ...state[oldId],
        ...agent,
        isConfirmed: true
      };
      return newState;
    }

    case types.AGENT_EDIT_COMPLETED: {
      const agent = action.payload;
      return {
        ...state,
        [agent.agentId]: {
          ...omit(state[agent.agentId], ['oldAgent']),
          ...omit(agent, ['oldAgent']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.AGENT_ADD_STARTED: {
      const agent = action.payload;
      const newState = { ...state };
      newState[agent.agentId] = {
        isSelected: false,
        ...agent,
        isConfirmed: false
      };
      return newState;
    }
    case types.AGENT_EDIT_STARTED: {
      const agent = action.payload;
      return {
        ...state,
        [agent.agentId]: {
          ...state[agent.agentId],
          oldAgent: state[agent.agentId],
          ...agent,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.AGENT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.AGENT_EDIT_FAILED: {
      const oldAgent = action.payload;
      return {
        ...state,
        [oldAgent.agentId]: {
          ...omit(state[oldAgent.agentId], ['oldAgent']),
          ...oldAgent,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.AGENT_REMOVE_COMPLETED: {
      const { agentId } = action.payload;
      return omit(state, agentId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.AGENT_SELECTED: {
      const agentId = action.payload;
      const newState = {
        ...state,
        [agentId]: {
          ...state[agentId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.AGENT_DESELECTED: {
      const agentId = action.payload;
      return {
        ...state,
        [agentId]: {
          ...state[agentId],
          isSelected: false
        }
      };
    }

    case types.AGENTS_ALL_SELECTED: {
      const agentIds = action.payload;
      const newState = { ...state };
      if (agentIds.length == 0) {
        forEach(state, (agent: any, agentId) => {
          newState[agentId] = {
            ...agent,
            isSelected: true
          };
        });
      } else {
        agentIds.forEach((agentId) => {
          newState[agentId] = {
            ...state[agentId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.AGENTS_ALL_DESELECTED: {
      const agentIds = action.payload;
      const newState = { ...state };
      if (agentIds.length == 0) {
        forEach(state, (agent: any, agentId) => {
          newState[agentId] = {
            ...agent,
            isSelected: false
          };
        });
      } else {
        agentIds.forEach((agentId) => {
          newState[agentId] = {
            ...state[agentId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.AGENTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.AGENTS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.AGENT_FETCH_COMPLETED: {
      const agent = action.payload;
      return uniq([...state, agent.agentId]);
    }

    case types.AGENT_ADD_COMPLETED: {
      const { oldId, agent } = action.payload;
      return state.map((agentId) => (agentId === oldId ? agent.agentId : agentId));
    }

    case types.AGENT_REMOVE_COMPLETED: {
      const { agentId } = action.payload;
      return state.filter((agentIdState) => agentIdState !== agentId);
    }

    //CASE ADD STARTED
    case types.AGENT_ADD_STARTED: {
      const agent = action.payload;
      return [...state, agent.agentId];
    }

    //CASE ADD FAILED
    case types.AGENT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((agentIdState) => agentIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.AGENTS_FETCH_STARTED: {
      return true;
    }
    case types.AGENTS_FETCH_COMPLETED:
    case types.AGENTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.AGENT_FETCH_STARTED: {
      return true;
    }
    case types.AGENT_FETCH_COMPLETED:
    case types.AGENT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.AGENT_ADD_STARTED: {
      return true;
    }
    case types.AGENT_ADD_COMPLETED:
    case types.AGENT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.AGENT_EDIT_STARTED: {
      return true;
    }
    case types.AGENT_EDIT_COMPLETED:
    case types.AGENT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.AGENT_REMOVE_STARTED: {
      return true;
    }
    case types.AGENT_REMOVE_COMPLETED:
    case types.AGENT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.AGENTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.AGENTS_FETCH_STARTED:
    case types.AGENTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.AGENT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.AGENT_FETCH_STARTED:
    case types.AGENT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.AGENT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.AGENT_ADD_STARTED:
    case types.AGENT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.AGENT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.AGENT_EDIT_STARTED:
    case types.AGENT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.AGENT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.AGENT_REMOVE_STARTED:
    case types.AGENT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const agents = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default agents;

//Own state
export const getOwnState = (state) => state.agents;

//Information
export const getAgent = (state, agentId) => getOwnState(state).byId[agentId];
export const getAgentsList = (state) =>
  getOwnState(state)
    .order.map((id) => getAgent(state, id))
    .filter((agent) => agent.agentStatus > 0);

export const getAgentsListByMembership = (state, membership) => {
  return getAgentsList(state).filter(
    (agent) =>
      membership &&
      (!membership.operatorId || membership.operatorId === agent.operatorId) &&
      (!membership.organizationId || membership.organizationId === agent.organizationId) &&
      (!membership.divisionId || membership.divisionId === agent.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === agent.subdivisionId)
  );
};
export const getSelectedAgents = (state) => {
  const selectedAgents = getAgentsList(state).filter((agent) => agent.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedAgents.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedAgents.length > 0) return selectedAgents;
};
export const getSelectedAgent = (state) => {
  const selectedAgents = getAgentsList(state).filter((agent) => agent.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedAgents.length === 1) return selectedAgents[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingAgent = (state) => getOwnState(state).isFetching;
export const isFetchingListAgents = (state) => getOwnState(state).isFetchingList;
export const isAddingAgent = (state) => getOwnState(state).isAdding;
export const isEditingAgent = (state) => getOwnState(state).isEditing;
export const isRemovingAgent = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingAgentErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListAgentsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingAgentErrors = (state) => getOwnState(state).errorAdding;
export const getEditingAgentErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingAgentErrors = (state) => getOwnState(state).errorRemoving;
