import paths from 'views/screens/Jobs/paths';
import permissions from 'views/screens/Jobs/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'jobs',
    title: 'jobs.jobs',
    iconName: 'Jobs',
    permissions: [permissions.jobs],
    children: [
      {
        id: 'jobsBacklog',
        title: 'jobs.backlog',
        iconName: 'Server',
        permissions: [permissions.listJobsBacklog],
        navLink: paths.backlog,
        screenId: screens.BACKLOG_JOBS
      },
      {
        id: 'ImportJobs',
        title: 'jobs.importJobs',
        iconName: 'UploadCloud',
        permissions: [permissions.jobsImport],
        navLink: paths.import,
        screenId: screens.IMPORT_JOBS
      },
      {
        id: 'jobsBoard',
        title: 'jobs.board',
        iconName: 'Trello',
        permissions: [permissions.jobsBoard],
        navLink: paths.board,
        screenId: screens.JOBS_BOARD
      },
      {
        id: 'jobsReport',
        title: 'jobs.report',
        iconName: 'Clock',
        permissions: [permissions.jobsReport],
        navLink: paths.report,
        screenId: screens.JOBS_REPORT
      },
      {
        id: 'workingActivityReport',
        title: 'jobs.reports.workingActivity',
        iconName: 'Calendar',
        permissions: [permissions.jobsWorkingActivity],
        navLink: paths.workingActivityReport,
        screenId: screens.JOBS_WORK_ACTIVITY
      }
    ]
  }
];
