import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import { Thermometer } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Badge, Col, Dropdown, DropdownMenu, DropdownToggle, Label, Media, Row } from 'reactstrap';
import * as authSelectors from 'redux/reducers/auth';
import useDeepCompareEffect from 'use-deep-compare-effect';
import ControlChart from 'views/components/ControlChart';
import DefaultButton from 'views/components/DefaultButton';
import DefaultSimpleButton from 'views/components/DefaultSimpleButton';
import Table from 'views/components/Table';
import DateInput from 'views/formComponents/DateInput';
import DropdownInput from 'views/formComponents/DropdownInput';
import useGetUnitTemperatureHistory from '../../hooks/useGetUnitTemperatureHistory';

const columns = [
  {
    fieldName: 'systemDate',
    headerText: 'common.date',
    type: 'dateTime',
    formatColumn: 'yyyy-MM-dd HH:mm'
  },
  {
    fieldName: 'metricValue',
    type: 'number',
    formatColumn: 'N0'
  },
  {
    fieldName: 'locationName',
    headerText: 'sensors.locationName'
  },
  {
    fieldName: 'isIgnitionOn',
    headerText: 'sensors.ignition',
    type: 'boolean'
  },
  {
    fieldName: 'speedFormat',
    headerText: 'units.speed',
    metric: 'speed',
    type: 'number',
    formatColumn: 'N2'
  }
];

/* -------------------------------------------------------------------------- */
/*                                UnitTemperature                             */
/* -------------------------------------------------------------------------- */
export default function UnitTemperature({ unit, sensors }) {
  const intl = useIntl();
  const history = useHistory();
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const [showHistTemperature, setShowHistTemperature] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [isOpenDateRangeForm, setIsOpenDateRangeForm] = useState<boolean>(false);
  const [isOpenSensorForm, setIsOpenSensorForm] = useState<boolean>(false);
  const defaultValue = [
    moment().startOf('day').format('YYYY-MM-DD HH:mm'),
    moment().endOf('day').format('YYYY-MM-DD HH:mm')
  ];
  const [dateRange, setDateRange] = useState<any[]>(defaultValue);
  const [sensorId, setSensorId] = useState<number>(sensors[0]?.sensorId);
  const [measureId, setMeasureId] = useState<number>(sensors[0]?.measureId);
  const { control: controlSensor, watch: watchSensor } = useForm();
  const { control, watch } = useForm({ defaultValues: { dateRange: defaultValue } });
  const { data, isFetching } = useGetUnitTemperatureHistory(
    showHistTemperature,
    unit?.unitId,
    sensorId,
    measureId,
    dateRange[0],
    dateRange[1]
  );

  const formValuesSensor = watchSensor('sensorId');
  const formValuesDateRange = watch('dateRange');
  const parsers = useSelector(authSelectors.getParsers);
  const deviceMetrics = unit.deviceMetrics.filter(
    (metric) => metric?.measureTypeId && metric.measureTypeId === 3
  );

  useDeepCompareEffect(() => {
    setMeasureId(sensors[0]?.measureId);
    setSensorId(sensors[0]?.sensorId);
  }, [sensors]);

  useEffect(() => {
    if (formValuesSensor != null) {
      setIsOpenSensorForm(false);
      setSensorId(formValuesSensor);
      setMeasureId(sensors.filter((s) => s.sensorId === formValuesSensor)[0]?.measureId);
    }
  }, [formValuesSensor]);

  useEffect(() => {
    if (formValuesDateRange) {
      setIsOpenDateRangeForm(false);
      setDateRange(formValuesDateRange);
    }
  }, [formValuesDateRange]);

  return (
    <Row>
      {!showHistTemperature ? (
        unit.deviceMetrics
          .filter((metric) => metric?.measureTypeId && metric.measureTypeId === 3)
          .map((metric, i) => (
            <Fragment key={i}>
              {i % 2 === 0 && i !== 0 && <hr className={'full-width-slide-panel'} />}
              <Col className={i % 2 !== 0 ? 'border-left' : ''}>
                <Label className="label-information">
                  <Thermometer color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {metric.metricDescription}
                  {sensors?.length > 0 && (
                    <DefaultSimpleButton
                      icon={'TrendingUp'}
                      className={'ml-2'}
                      id={'showTemperatureHistButton'}
                      tooltipMessage={intl.formatMessage({
                        id: 'units.showHistoricalTemperature'
                      })}
                      color={primary}
                      handleClick={() => {
                        setShowHistTemperature(!showHistTemperature);
                        setShowTable(false);
                      }}
                    />
                  )}
                </Label>

                <Label className="label-value-information">{`${
                  metric.numberValue
                } ${metric.measurementUnitName?.replace('&deg;', '\xB0')}`}</Label>
              </Col>
            </Fragment>
          ))
      ) : (
        <Row>
          <Col sm="12">
            <Media>
              <Dropdown
                tag="div"
                direction={'up'}
                isOpen={isOpenSensorForm}
                toggle={(event) => {
                  if (
                    !isOpenSensorForm ||
                    (typeof event?.isDefaultPrevented === 'function' && !event.isDefaultPrevented())
                  )
                    setIsOpenSensorForm(!isOpenSensorForm);
                }}
              >
                <DropdownToggle tag="div">
                  <Badge
                    pill
                    style={{ maxWidth: '180px' }}
                    className="text-wrap mt-25"
                    color={'primary'}
                  >
                    {sensors.filter((s) => s.sensorId === sensorId)[0]?.uniqueIdentifier}
                  </Badge>
                </DropdownToggle>
                <DropdownMenu tag="div" style={{ width: '300px' }}>
                  <div>
                    <Controller
                      control={controlSensor}
                      name="sensorId"
                      defaultValue={sensorId}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <DropdownInput
                          field={field}
                          fieldstate={fieldState}
                          required={true}
                          label={intl.formatMessage({ id: 'sensors.sensor' })}
                          options={sensors.map((s) => ({
                            id: s.sensorId,
                            value: s.sensorId,
                            label: s.uniqueIdentifier
                          }))}
                        />
                      )}
                    />
                  </div>
                </DropdownMenu>
              </Dropdown>
              <Dropdown
                tag="div"
                direction={'up'}
                isOpen={isOpenDateRangeForm}
                toggle={(event) => {
                  if (
                    !isOpenDateRangeForm ||
                    (typeof event?.isDefaultPrevented === 'function' && !event.isDefaultPrevented())
                  )
                    setIsOpenDateRangeForm(!isOpenDateRangeForm);
                }}
              >
                <DropdownToggle tag="div">
                  <Badge pill className="mt-25" color={'primary'}>
                    {`${dateRange[0]} - ${dateRange[1]}`}
                  </Badge>
                </DropdownToggle>
                <DropdownMenu tag="div" style={{ width: '300px' }}>
                  <div>
                    <Controller
                      control={control}
                      name="dateRange"
                      defaultValue={defaultValue}
                      rules={{ required: true }}
                      render={({ field, fieldState }) => (
                        <DateInput
                          field={field}
                          fieldstate={fieldState}
                          required={true}
                          // enableTime
                          isRange
                        />
                      )}
                    />
                  </div>
                </DropdownMenu>
              </Dropdown>
              {showHistTemperature && (
                <DefaultSimpleButton
                  icon={showTable ? 'BarChart' : 'List'}
                  className={'ml-2'}
                  id={'showTableButton'}
                  tooltipMessage={intl.formatMessage({
                    id: showTable ? 'units.showGraph' : 'units.showTable'
                  })}
                  color={primary}
                  handleClick={() => {
                    setShowTable(!showTable);
                  }}
                />
              )}
              <DefaultSimpleButton
                icon={'X'}
                className={'ml-2'}
                id={'showTemperatureHistButton'}
                tooltipMessage={intl.formatMessage({
                  id: 'units.hideHistoricalTemperature'
                })}
                color={primary}
                handleClick={() => {
                  setShowHistTemperature(!showHistTemperature);
                  setShowTable(false);
                }}
              />
            </Media>
          </Col>
          <Col sm={'10'} className={'mt-1'} />
          <Col sm={'2'} className={'mt-1'}>
            <DefaultButton
              id={'showOnTelemetryMap'}
              icon={'TrendingUp'}
              color="info"
              permissions={['TE002']}
              handleClick={() => {
                history.push(
                  { pathname: '/telemetry/temperature/monitor' },
                  {
                    unitId: unit?.unitId,
                    sensorId,
                    measureId,
                    dateRange
                  }
                );
              }}
              tooltipMessage={intl.formatMessage({ id: 'telemetry.showOnTelemetry' })}
            />
          </Col>
          <Col sm="12">
            {showTable ? (
              <div className={'mt-1'}>
                <Table
                  id={'tableUnitTemperatureSlidingPane1'}
                  columns={columns.map((c) =>
                    c.fieldName === 'metricValue'
                      ? {
                          ...c,
                          staticText: `${intl.formatMessage({
                            id: 'units.temperature'
                          })} (${deviceMetrics[0].measurementUnitName?.replace('&deg;', '\xB0')})`
                        }
                      : c
                  )}
                  data={data.map((d) => ({
                    ...d,
                    speedFormat: parsers.convertKilometersPerHourToAuthUserSpeedSystem(d.speed)
                  }))}
                  isFetching={isFetching}
                />
              </div>
            ) : (
              <ControlChart
                data={data.map((d) => ({
                  date: new Date(d.systemDate),
                  value: d.metricValue,
                  color:
                    d.metricValue > sensors.filter((s) => s.sensorId === sensorId)[0]?.upperLimit ||
                    d.metricValue < sensors.filter((s) => s.sensorId === sensorId)[0]?.lowerLimit
                      ? '#FF4757'
                      : '#1E90FF'
                }))}
                titleTooltip={intl.formatMessage({ id: 'units.temperature' })}
                metric={deviceMetrics[0].measurementUnitName?.replace('&deg;', '\xB0')}
                isFetching={isFetching}
                bulletRadius={0}
                upperLimit={sensors.filter((s) => s.sensorId === sensorId)[0]?.upperLimit}
                lowerLimit={sensors.filter((s) => s.sensorId === sensorId)[0]?.lowerLimit}
                max={
                  sensors.filter((s) => s.sensorId === sensorId)[0]?.maxValue ??
                  deviceMetrics[0].maxValue
                }
                min={
                  sensors.filter((s) => s.sensorId === sensorId)[0]?.minValue ??
                  deviceMetrics[0].minValue
                }
              />
            )}
          </Col>
        </Row>
      )}
    </Row>
  );
}
