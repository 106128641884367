import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ORGANIZATION_CONTRACTS_FETCH_COMPLETED:
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((contractId) => {
        newState[contractId] = {
          isSelected: false,
          ...state[contractId],
          ...entities[contractId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ORGANIZATION_CONTRACT_ADD_COMPLETED: {
      const { oldId, organizationContract } = action.payload;
      const newState = omit(state, oldId);
      newState[organizationContract.contractId] = {
        ...state[oldId],
        ...organizationContract,
        isConfirmed: true
      };
      return newState;
    }

    case types.ORGANIZATION_CONTRACT_EDIT_COMPLETED: {
      const organizationContract = action.payload;
      return {
        ...state,
        [organizationContract.contractId]: {
          ...omit(state[organizationContract.contractId], ['oldOrganizationContract']),
          ...omit(organizationContract, ['oldOrganizationContract']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.ORGANIZATION_CONTRACT_ADD_STARTED: {
      const organizationContract = action.payload;
      const newState = { ...state };
      newState[organizationContract.contractId] = {
        isSelected: false,
        ...organizationContract,
        isConfirmed: false
      };
      return newState;
    }

    case types.ORGANIZATION_CONTRACT_EDIT_STARTED: {
      const organizationContract = action.payload;
      return {
        ...state,
        [organizationContract.contractId]: {
          ...state[organizationContract.contractId],
          oldOrganizationContract: state[organizationContract.contractId],
          ...organizationContract,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.ORGANIZATION_CONTRACT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.ORGANIZATION_CONTRACT_EDIT_FAILED: {
      const { oldOrganizationContract } = action.payload;
      return {
        ...state,
        [oldOrganizationContract.contractId]: {
          ...omit(state[oldOrganizationContract.contractId], ['oldOrganizationContract']),
          ...oldOrganizationContract,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ORGANIZATION_CONTRACT_REMOVE_COMPLETED: {
      const { contractId } = action.payload;
      return omit(state, contractId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.ORGANIZATION_CONTRACT_SELECTED: {
      const contractId = action.payload;
      const newState = {
        ...state,
        [contractId]: {
          ...state[contractId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.ORGANIZATION_CONTRACT_DESELECTED: {
      const contractId = action.payload;
      return {
        ...state,
        [contractId]: {
          ...state[contractId],
          isSelected: false
        }
      };
    }

    case types.ORGANIZATION_CONTRACTS_ALL_SELECTED: {
      const contractIds = action.payload;
      const newState = { ...state };
      if (contractIds.length == 0) {
        forEach(state, (organizationContract: any, contractIds) => {
          newState[contractIds] = {
            ...organizationContract,
            isSelected: true
          };
        });
      } else {
        contractIds.forEach((contractId) => {
          newState[contractId] = {
            ...state[contractId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.ORGANIZATION_CONTRACTS_ALL_DESELECTED: {
      const contractIds = action.payload;
      const newState = { ...state };
      if (contractIds.length == 0) {
        forEach(state, (organizationContract: any, contractId) => {
          newState[contractId] = {
            ...organizationContract,
            isSelected: false
          };
        });
      } else {
        contractIds.forEach((contractId) => {
          newState[contractId] = {
            ...state[contractId],
            isSelected: false
          };
        });
      }
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ORGANIZATION_CONTRACTS_FETCH_COMPLETED:
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.ORGANIZATION_CONTRACT_ADD_COMPLETED: {
      const { oldId, organizationContract } = action.payload;
      return state.map((contractId) =>
        contractId === oldId ? organizationContract.contractId : contractId
      );
    }

    case types.ORGANIZATION_CONTRACT_REMOVE_COMPLETED: {
      const { contractId } = action.payload;
      return state.filter((contractIdState) => contractIdState !== contractId);
    }

    //CASE ADD STARTED
    case types.ORGANIZATION_CONTRACT_ADD_STARTED: {
      const organizationContract = action.payload;
      return [...state, organizationContract.contractId];
    }

    //CASE ADD FAILED
    case types.ORGANIZATION_CONTRACT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((contractIdState) => contractIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACTS_FETCH_STARTED: {
      return true;
    }
    case types.ORGANIZATION_CONTRACTS_FETCH_COMPLETED:
    case types.ORGANIZATION_CONTRACTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED:
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_ADD_STARTED: {
      return true;
    }
    case types.ORGANIZATION_CONTRACT_ADD_COMPLETED:
    case types.ORGANIZATION_CONTRACT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_EDIT_STARTED: {
      return true;
    }
    case types.ORGANIZATION_CONTRACT_EDIT_COMPLETED:
    case types.ORGANIZATION_CONTRACT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_REMOVE_STARTED: {
      return true;
    }
    case types.ORGANIZATION_CONTRACT_REMOVE_COMPLETED:
    case types.ORGANIZATION_CONTRACT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_CONTRACTS_FETCH_STARTED:
    case types.ORGANIZATION_CONTRACTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED:
    case types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_CONTRACT_ADD_STARTED:
    case types.ORGANIZATION_CONTRACT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_CONTRACT_EDIT_STARTED:
    case types.ORGANIZATION_CONTRACT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CONTRACT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_CONTRACT_REMOVE_STARTED:
    case types.ORGANIZATION_CONTRACT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const organizationContracts = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default organizationContracts;

//Own state
export const getOwnState = (state) => state.organizationContracts;

//Information
export const getOrganizationContract = (state, contractId) => {
  return getOwnState(state).byId[contractId];
};

export const getOrganizationContractsList = (state) =>
  getOwnState(state)
    .order.map((id) => getOrganizationContract(state, id))
    .filter((organizationContract) => organizationContract?.status > 0);

export const getSelectedOrganizationContracts = (state) => {
  const selectedOrganizationContracts = getOrganizationContractsList(state).filter(
    (organizationContract) => organizationContract?.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedOrganizationContracts.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedOrganizationContracts.length > 0) return selectedOrganizationContracts;
};

export const getSelectedOrganizationContract = (state) => {
  const selectedOrganizationContracts = getOrganizationContractsList(state).filter(
    (organizationContract) => organizationContract?.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedOrganizationContracts.length === 1) return selectedOrganizationContracts[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListOrganizationContracts = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListOrganizationContracts = (state) => getOwnState(state).isFetchingList;
export const isAddingOrganizationContract = (state) => getOwnState(state).isAdding;
export const isEditingOrganizationContract = (state) => getOwnState(state).isEditing;
export const isRemovingOrganizationContract = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListOrganizationContractsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListOrganizationContractsErrors = (state) =>
  getOwnState(state).errorFetchingList;
export const getAddingOrganizationContractErrors = (state) => getOwnState(state).errorAdding;
export const getEditingOrganizationContractErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingOrganizationContractErrors = (state) => getOwnState(state).errorRemoving;
