import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
/**
 * Componente "tonto" para mostrar tablas en catálogos
 * Props:
 *  textId:
 *    El id del texto a traducir
 */
const TitleH4 = ({ textId, center = false, textTransform = '', style = {} }) => (
  <h4 style={{ textAlign: center ? 'center' : '', textTransform: textTransform, ...style }}>
    <FormattedMessage id={textId} />
  </h4>
);

export default TitleH4;
