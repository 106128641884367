export const GROUPS_FETCH_STARTED = 'GROUPS_FETCH_STARTED';
export const GROUPS_FETCH_COMPLETED = 'GROUPS_FETCH_COMPLETED';
export const GROUPS_FETCH_FAILED = 'GROUPS_FETCH_FAILED';

export const GROUPS_PARTIAL_FETCH_STARTED = 'GROUPS_PARTIAL_FETCH_STARTED';
export const GROUPS_PARTIAL_FETCH_COMPLETED = 'GROUPS_PARTIAL_FETCH_COMPLETED';
export const GROUPS_PARTIAL_FETCH_FAILED = 'GROUPS_PARTIAL_FETCH_FAILED';

export const GROUPS_ALL_ENTITIES_FETCH_STARTED = 'GROUPS_ALL_ENTITIES_FETCH_STARTED';
export const GROUPS_ALL_ENTITIES_FETCH_COMPLETED = 'GROUPS_ALL_ENTITIES_FETCH_COMPLETED';
export const GROUPS_ALL_ENTITIES_FETCH_FAILED = 'GROUPS_ALL_ENTITIES_FETCH_FAILED';

export const GROUP_EDIT_STARTED = 'GROUP_EDIT_STARTED';
export const GROUP_EDIT_COMPLETED = 'GROUP_EDIT_COMPLETED';
export const GROUP_EDIT_FAILED = 'GROUP_EDIT_FAILED';

export const GROUP_ADD_STARTED = 'GROUP_ADD_STARTED';
export const GROUP_ADD_COMPLETED = 'GROUP_ADD_COMPLETED';
export const GROUP_ADD_FAILED = 'GROUP_ADD_FAILED';

export const GROUP_ADD_WITH_ENTITIES_STARTED = 'GROUP_ADD_WITH_ENTITIES_STARTED';
export const GROUP_ADD_WITH_ENTITIES_COMPLETED = 'GROUP_ADD_WITH_ENTITIES_COMPLETED';
export const GROUP_ADD_WITH_ENTITIES_FAILED = 'GROUP_ADD_WITH_ENTITIES_FAILED';

export const GROUP_REMOVE_STARTED = 'GROUP_REMOVE_STARTED';
export const GROUP_REMOVE_COMPLETED = 'GROUP_REMOVE_COMPLETED';
export const GROUP_REMOVE_FAILED = 'GROUP_REMOVE_FAILED';

export const GROUP_LEAVE_STARTED = 'GROUP_LEAVE_STARTED';
export const GROUP_LEAVE_COMPLETED = 'GROUP_LEAVE_COMPLETED';
export const GROUP_LEAVE_FAILED = 'GROUP_LEAVE_FAILED';

export const GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_STARTED =
  'GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_STARTED';
export const GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_COMPLETED =
  'GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_COMPLETED';
export const GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_FAILED =
  'GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_FAILED';

export const GROUP_REMOVE_ENTITIES_STARTED = 'GROUP_REMOVE_ENTITIES_STARTED';
export const GROUP_REMOVE_ENTITIES_COMPLETED = 'GROUP_REMOVE_ENTITIES_COMPLETED';

export const GROUP_ADD_ENTITIES_STARTED = 'GROUP_ADD_ENTITIES_STARTED';
export const GROUP_ADD_ENTITIES_COMPLETED = 'GROUP_ADD_ENTITIES_COMPLETED';

export const GROUP_USER_PROFILE_EDIT_STARTED = 'GROUP_USER_PROFILE_EDIT_STARTED';
export const GROUP_USER_PROFILE_EDIT_COMPLETED = 'GROUP_USER_PROFILE_EDIT_COMPLETED';

export const GROUP_SELECTED = 'GROUP_SELECTED';
export const GROUPS_ALL_SELECTED = 'GROUPS_ALL_SELECTED';
export const GROUP_DESELECTED = 'GROUP_DESELECTED';
export const GROUPS_ALL_DESELECTED = 'GROUPS_ALL_DESELECTED';

export interface ReduxGroup {
  isSelected: boolean;
  users: any;
  locations: { [key: string]: any };
  workplans: { [key: string]: any };
  units: { [key: string]: any };
  geofences: { [key: string]: any };
  drivers: { [key: string]: any };
  groupId: number;
  groupName: string;
  groupStatus: number;
  profileId: number;
  isInGroup: number;
  locationsCount: number;
  workPlansCount: number;
  userCount: number;
  unitCount: number;
  geofenceCount: number;
  driverCount: number;
  permanenceTimeUnauthorizedMeeting: number;
  notifyUnauthorizedMeeting: number;
  notifyMemberAdded: number;
  notifyMemberDeleted: number;
  notifyAdminMemberAdded: number;
  notifyAdminMemberLeave: number;
  notifyAdminMemberDeleted: number;
  notifyAdminGroupDeleted: number;
  safeDriverSpeedLimit: number;
  notifyAdminSpeedEvent: number;
  notifyAdminPhoneEvent: number;
  notifyOwnerSpeedEvent: number;
  notifyOwnerPhoneEvent: number;
  notifyRiskyDriving: number;
  notifyMemberAlertsSac: number;
  notifyAdminAlertsSac: number;
  notifyOwnerAlertsSac: number;
  applicationIdentities: any;
}
