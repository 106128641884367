import 'leaflet-rotatedmarker';
import { useEffect, useRef } from 'react';
import { Marker } from 'react-leaflet';

const RotatedMarker = ({ children, ...props }: any) => {
  const markerRef = useRef<any>();

  const { rotationAngle, rotationOrigin } = props;
  useEffect(() => {
    if (markerRef.current?.setRotationAngle && markerRef.current?.setRotationOrigin) {
      markerRef.current.setRotationAngle(rotationAngle);
      markerRef.current.setRotationOrigin(rotationOrigin);
    }
  }, [rotationAngle, rotationOrigin]);

  return (
    <Marker
      ref={(ref: any) => {
        if (ref) markerRef.current = ref;
      }}
      {...props}
    >
      {children}
    </Marker>
  );
};

export default RotatedMarker;
