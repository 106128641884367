import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.QUOTATIONS_FETCH_COMPLETED:
    case types.QUOTATIONS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((quotationId) => {
        newState[quotationId] = {
          isSelected: false,
          ...state[quotationId],
          ...entities[quotationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.QUOTATIONS_ADD_COMPLETED: {
      const { oldId, quotation } = action.payload;
      const newState = omit(state, oldId);
      newState[quotation.quotationId] = {
        ...state[oldId],
        ...quotation,
        isConfirmed: true
      };
      return newState;
    }

    case types.QUOTATIONS_EDIT_COMPLETED: {
      const quotation = action.payload;
      return {
        ...state,
        [quotation.quotationId]: {
          ...omit(state[quotation.quotationId], ['oldQuotation']),
          ...omit(quotation, ['oldQuotation']),
          isConfirmed: true
        }
      };
    }

    case types.QUOTATION_COPY_COMPLETED: {
      const { quotation } = action.payload;
      const newState = state;
      newState[quotation.quotationId] = {
        isSelected: false,
        ...quotation,
        isConfirmed: true
      };
      return newState;
    }

    case types.QUOTATION_APPROVE_DENY_MANAGEMENT_COMPLETED: {
      const quotation = action.payload;
      return {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          quotationStatus: quotation.approve == 1 ? 2 : 5,
          denyRequisitionReason: quotation.denyRequisitionReason
        }
      };
    }

    case types.QUOTATION_ACCEPT_DENY_CUSTOMER_COMPLETED: {
      const quotation = action.payload;
      return {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          quotationStatus: quotation.accept == 1 ? 6 : 7,
          deniedCustomerReason: quotation.deniedCustomerReason
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.QUOTATIONS_ADD_STARTED: {
      const { quotation, isSelected } = action.payload;
      const newState = { ...state };
      newState[quotation.quotationId] = {
        isSelected: isSelected,
        ...quotation,
        isConfirmed: false
      };
      return newState;
    }

    case types.QUOTATIONS_EDIT_STARTED: {
      const quotation = action.payload;
      return {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          oldQuotation: state[quotation.quotationId],
          ...quotation,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.QUOTATIONS_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.QUOTATIONS_EDIT_FAILED: {
      const { oldQuotation } = action.payload;
      return {
        ...state,
        [oldQuotation.quotationId]: {
          ...omit(state[oldQuotation.quotationId], ['oldQuotation']),
          ...oldQuotation,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.QUOTATIONS_REMOVE_COMPLETED: {
      const { quotationId } = action.payload;
      return omit(state, quotationId);
    }

    //TYPES SCHEDULE AND CHECK SCHEDULE
    case types.QUOTATIONS_SCHEDULE_COMPLETED: {
      const quotation = action.payload;
      const newState = {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          isScheduled: true
        }
      };
      return newState;
    }

    case types.QUOTATIONS_SET_TIMESUP_COMPLETED: {
      const quotation = action.payload;
      const newState = {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          isTimesup: quotation.timesup
        }
      };
      return newState;
    }

    case types.QUOTATIONS_CHECK_SCHEDULING_COMPLETED: {
      const quotation = action.payload;
      const newState = {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          quotationId: quotation.quotationIdDB,
          isScheduled: quotation.isScheduled,
          quotationStatus: quotation.quotationStatus,
          isTimesup: quotation.isTimesup,
          isSelected: true
        }
      };
      return newState;
    }

    case types.QUOTATION_CHANGE_ORGANIZATION: {
      const { quotation, organization } = action.payload;
      const newState = {
        ...state,
        [quotation.quotationId]: {
          ...state[quotation.quotationId],
          ...organization
        },
        ...(quotation.baseQuotationId
          ? {
              [quotation.baseQuotationId]: {
                ...state[quotation.baseQuotationId],
                ...organization
              }
            }
          : {})
      };
      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.QUOTATIONS_SELECTED: {
      const quotationId = action.payload;
      const newState = {
        ...state,
        [quotationId]: {
          ...state[quotationId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.QUOTATIONS_DESELECTED: {
      const quotationId = action.payload;
      return {
        ...state,
        [quotationId]: {
          ...state[quotationId],
          isSelected: false
        }
      };
    }

    case types.QUOTATIONS_ALL_SELECTED: {
      const quotationIds = action.payload;
      const newState = { ...state };
      if (quotationIds.length == 0) {
        forEach(state, (quotation: any, quotationIds) => {
          newState[quotationIds] = {
            ...quotation,
            isSelected: true
          };
        });
      } else {
        quotationIds.forEach((quotationId) => {
          newState[quotationId] = {
            ...state[quotationId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.QUOTATIONS_ALL_DESELECTED: {
      const quotationIds = action.payload;
      const newState = { ...state };
      if (quotationIds.length == 0) {
        forEach(state, (quotation: any, quotationId) => {
          newState[quotationId] = {
            ...quotation,
            isSelected: false
          };
        });
      } else {
        quotationIds.forEach((quotationId) => {
          newState[quotationId] = {
            ...state[quotationId],
            isSelected: false
          };
        });
      }
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.QUOTATIONS_FETCH_COMPLETED:
    case types.QUOTATIONS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.QUOTATIONS_ADD_COMPLETED: {
      const { oldId, quotation } = action.payload;
      return state.map((quotationId) =>
        quotationId === oldId ? quotation.quotationId : quotationId
      );
    }

    case types.QUOTATION_COPY_COMPLETED: {
      const { quotation } = action.payload;
      return [...state, quotation.quotationId];
    }

    case types.QUOTATIONS_CHECK_SCHEDULING_STARTED: {
      const { quotationId } = action.payload;
      return [
        ...state.filter((quotationIdState) => quotationIdState !== Number(quotationId)),
        Number(quotationId)
      ];
    }

    case types.QUOTATIONS_REMOVE_COMPLETED: {
      const { quotationId } = action.payload;
      return state.filter((quotationIdState) => quotationIdState !== quotationId);
    }

    //CASE ADD STARTED
    case types.QUOTATIONS_ADD_STARTED: {
      const { quotation } = action.payload;
      return [...state, quotation.quotationId];
    }

    //CASE ADD FAILED
    case types.QUOTATIONS_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((quotationIdState) => quotationIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_FETCH_STARTED: {
      return true;
    }
    case types.QUOTATIONS_FETCH_COMPLETED:
    case types.QUOTATIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.QUOTATIONS_PARTIAL_FETCH_COMPLETED:
    case types.QUOTATIONS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_ADD_STARTED: {
      return true;
    }
    case types.QUOTATIONS_ADD_COMPLETED:
    case types.QUOTATIONS_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCopying = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATION_COPY_STARTED: {
      return true;
    }
    case types.QUOTATION_COPY_COMPLETED:
    case types.QUOTATION_COPY_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_EDIT_STARTED: {
      return true;
    }
    case types.QUOTATIONS_EDIT_COMPLETED:
    case types.QUOTATIONS_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isScheduling = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_SCHEDULE_STARTED: {
      return true;
    }
    case types.QUOTATIONS_SCHEDULE_COMPLETED:
    case types.QUOTATIONS_SCHEDULE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCheckingSchedule = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_CHECK_SCHEDULING_STARTED: {
      return true;
    }
    case types.QUOTATIONS_CHECK_SCHEDULING_COMPLETED:
    case types.QUOTATIONS_CHECK_SCHEDULING_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isSettingTimesup = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_SET_TIMESUP_STARTED: {
      return true;
    }
    case types.QUOTATIONS_SET_TIMESUP_COMPLETED:
    case types.QUOTATIONS_SET_TIMESUP_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATIONS_REMOVE_STARTED: {
      return true;
    }
    case types.QUOTATIONS_REMOVE_COMPLETED:
    case types.QUOTATIONS_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isApprovingDenyingManagement = (state = false, action) => {
  switch (action.type) {
    case types.QUOTATION_APPROVE_DENY_MANAGEMENT_STARTED: {
      return true;
    }
    case types.QUOTATION_APPROVE_DENY_MANAGEMENT_COMPLETED:
    case types.QUOTATION_APPROVE_DENY_MANAGEMENT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATIONS_FETCH_STARTED:
    case types.QUOTATIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATIONS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATIONS_PARTIAL_FETCH_STARTED:
    case types.QUOTATIONS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATIONS_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATIONS_ADD_STARTED:
    case types.QUOTATIONS_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCopying = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATION_COPY_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATION_COPY_STARTED:
    case types.QUOTATION_COPY_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATIONS_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATIONS_EDIT_STARTED:
    case types.QUOTATIONS_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.QUOTATIONS_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.QUOTATIONS_REMOVE_STARTED:
    case types.QUOTATIONS_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const quotations = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isCopying,
  isEditing,
  isScheduling,
  isCheckingSchedule,
  isSettingTimesup,
  isApprovingDenyingManagement,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorCopying,
  errorEditing,
  errorRemoving
});

export default quotations;

//Own state
export const getOwnState = (state) => state.quotations;

//Information
export const getQuotation = (state, quotationId) => {
  return getOwnState(state).byId[quotationId];
};

export const getQuotationsSpecificList = (state, list) =>
  list.map((quotationId) => getQuotation(state, quotationId));

export const getQuotationsList = (state) =>
  getOwnState(state)
    .order.map((id) => getQuotation(state, id))
    .filter((quotation) => quotation?.quotationStatus > 0);

export const getSelectedQuotations = (state) => {
  const selectedQuotations = getQuotationsList(state).filter((quotation) => quotation?.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedQuotations.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedQuotations.length > 0) return selectedQuotations;
};

export const getSelectedQuotation = (state) => {
  const selectedQuotations = getQuotationsList(state).filter((quotation) => quotation?.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedQuotations.length === 1) return selectedQuotations[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListQuotations = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListQuotations = (state) => getOwnState(state).isFetchingList;
export const isAddingQuotation = (state) => getOwnState(state).isAdding;
export const isCopyingQuotation = (state) => getOwnState(state).isCopying;
export const isEditingQuotation = (state) => getOwnState(state).isEditing;
export const isSchedulingQuotation = (state) => getOwnState(state).isScheduling;
export const isSettingTimesupQuotation = (state) => getOwnState(state).isSettingTimesup;
export const isCheckingScheduleQuotation = (state) => getOwnState(state).isCheckingSchedule;
export const isRemovingQuotation = (state) => getOwnState(state).isRemoving;
export const isApprovingDenyingQuotationManagement = (state) =>
  getOwnState(state).isApprovingDenyingManagement;

//Errors
export const getPartialFetchingListQuotationsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListQuotationsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingQuotationErrors = (state) => getOwnState(state).errorAdding;
export const getCopyingQuotationErrors = (state) => getOwnState(state).errorCopying;
export const getEditingQuotationErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingQuotationErrors = (state) => getOwnState(state).errorRemoving;
