import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routesComments';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ROUTES_EXECUTION_COMMENT_ADD_COMPLETED: {
      const { oldId, comment } = action.payload;
      const newState = omit(state, oldId);
      newState[comment.routeCommentId] = {
        ...state[oldId],
        ...comment,
        isConfirmed: true
      };
      return newState;
    }

    case types.ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED: {
      const comment = action.payload;
      return {
        ...state,
        [comment.routeCommentId]: {
          ...omit(state[comment.routeCommentId], ['oldComment']),
          ...omit(comment, ['oldComment']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.ROUTES_EXECUTION_COMMENT_ADD_STARTED: {
      const comment = action.payload;
      const newState = { ...state };
      newState[comment.routeCommentId] = {
        isSelected: false,
        ...comment,
        isConfirmed: false
      };
      return newState;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_STARTED: {
      const comment = action.payload;
      return {
        ...state,
        [comment.routeCommentId]: {
          ...state[comment.routeCommentId],
          oldComment: state[comment.routeCommentId],
          ...comment,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.ROUTES_EXECUTION_COMMENT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_FAILED: {
      const { oldComment } = action.payload;
      return {
        ...state,
        [oldComment.routeCommentId]: {
          ...omit(state[oldComment.routeCommentId], ['oldComment']),
          ...oldComment,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED: {
      const { routeCommentId } = action.payload;
      return omit(state, routeCommentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.ROUTES_EXECUTION_COMMENT_ADD_COMPLETED: {
      const { oldId, comment } = action.payload;
      return state.map((routeCommentId) =>
        routeCommentId === oldId ? comment.routeCommentId : routeCommentId
      );
    }

    case types.ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED: {
      const { routeCommentId } = action.payload;
      return state.filter((routeCommentIdState) => routeCommentIdState !== routeCommentId);
    }

    //CASE ADD STARTED
    case types.ROUTES_EXECUTION_COMMENT_ADD_STARTED: {
      const comment = action.payload;
      return [...state, comment.routeCommentId];
    }

    //CASE ADD FAILED
    case types.ROUTES_EXECUTION_COMMENT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((routeCommentIdState) => routeCommentIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingComments = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED:
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_COMMENT_ADD_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_COMMENT_ADD_COMPLETED:
    case types.ROUTES_EXECUTION_COMMENT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED:
    case types.ROUTES_EXECUTION_COMMENT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_STARTED: {
      return true;
    }
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED:
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingComments = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_STARTED:
    case types.ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_COMMENT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_COMMENT_ADD_STARTED:
    case types.ROUTES_EXECUTION_COMMENT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_COMMENT_EDIT_STARTED:
    case types.ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_STARTED:
    case types.ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const routesComments = combineReducers({
  byId,
  order,
  isFetchingComments,
  isAdding,
  isEditing,
  isRemoving,
  errorFetchingComments,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default routesComments;

//Own state
export const getOwnState = (state) => state.routes.routesExecutions.routesComments;

//Information
export const getRoutesComment = (state, visitId) => {
  return getOwnState(state).byId[visitId];
};
export const getRoutesCommentsList = (state) =>
  getOwnState(state).order.map((id) => getRoutesComment(state, id));
export const getRoutesCommentsListByRouteAssignmentId = (state, routeAssignmentId) =>
  getRoutesCommentsList(state).filter((comment) => comment.routeAssignmentId === routeAssignmentId);

//Status of sagas
export const isFetchingRoutesComments = (state) => getOwnState(state).isFetchingComments;
export const isAddingRoutesComments = (state) => getOwnState(state).isAdding;
export const isEditingRoutesComments = (state) => getOwnState(state).isEditing;
export const isRemovingRoutesComments = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingRoutesCommentsErrors = (state) => getOwnState(state).errorFetchingComments;
export const getAddingRoutesCommentsErrors = (state) => getOwnState(state).errorAdding;
export const getEditingRoutesCommentsErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingRoutesCommentsErrors = (state) => getOwnState(state).errorRemoving;
