const permissions = {
  trackingUnitsList: 'UP001',
  trackingUnitsCreate: 'UP002',
  trackingUnitsEdit: 'UP003',
  trackingUnitsDelete: 'UP004',
  viewUnitMap: 'UP025',
  lastCommunicationDate: 'UP026',
  downtime: 'UP027',
  readDowntimeReasons: 'UP028',
  addDowntimeReasons: 'UP029',
  editDowntimeReasons: 'UP030',
  removeDowntimeReasons: 'UP031',
  changeToInactive: 'UP032',
  changeToPlaced: 'UP033',
  changeToActivated: 'UP034',
  changeToRemoved: 'UP035',
  changeToCanceled: 'UP036',
  cellularProvider: 'UP022'
};

export default permissions;
