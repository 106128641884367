import workplans from '../../views/screens/Workplans/sidebarLayout';
import routes from '../../views/screens/Routes/sidebarLayout';
import optimizations from '../../views/screens/Optimizations/sidebarLayout';
import vehicleFuel from './vehicleFuel';

import vehicleMaintenance from './vehicleMaintenance';

export default [
  {
    header: 'sidebar.logistics',
    iconName: 'Share2',
    badgeText: 'common.premium',
    children: [...routes, ...workplans, ...vehicleFuel, ...vehicleMaintenance, ...optimizations] //Only to check permission in children inside
  },
  ...routes,
  ...workplans,
  ...vehicleMaintenance,
  ...vehicleFuel,
  ...optimizations
];
