export const VEHICLE_MODELS_FETCH_STARTED = 'VEHICLE_MODELS_FETCH_STARTED';
export const VEHICLE_MODELS_FETCH_COMPLETED = 'VEHICLE_MODELS_FETCH_COMPLETED';
export const VEHICLE_MODELS_FETCH_FAILED = 'VEHICLE_MODELS_FETCH_FAILED';

export const VEHICLE_MODELS_PARTIAL_FETCH_STARTED = 'VEHICLE_MODELS_PARTIAL_FETCH_STARTED';
export const VEHICLE_MODELS_PARTIAL_FETCH_COMPLETED = 'VEHICLE_MODELS_PARTIAL_FETCH_COMPLETED';
export const VEHICLE_MODELS_PARTIAL_FETCH_FAILED = 'VEHICLE_MODELS_PARTIAL_FETCH_FAILED';

export const VEHICLE_MODEL_FETCH_STARTED = 'VEHICLE_MODEL_FETCH_STARTED';
export const VEHICLE_MODEL_FETCH_COMPLETED = 'VEHICLE_MODEL_FETCH_COMPLETED';
export const VEHICLE_MODEL_FETCH_FAILED = 'VEHICLE_MODEL_FETCH_FAILED';

export const VEHICLE_MODEL_SELECTED = 'VEHICLE_MODEL_SELECTED';
export const VEHICLE_MODELS_ALL_SELECTED = 'VEHICLE_MODELS_ALL_SELECTED';
export const VEHICLE_MODEL_DESELECTED = 'VEHICLE_MODEL_DESELECTED';
export const VEHICLE_MODELS_ALL_DESELECTED = 'VEHICLE_MODELS_ALL_DESELECTED';
