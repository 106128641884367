import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import {
  getMaintenanceLinesListService,
  saveMaintenanceLineService,
  deleteMaintenanceLineService,
  copyMaintenanceLineService,
  importMaintenanceLinesService
} from '../services';
import { store } from 'redux/storeConfig/store';
import { MaintenancePlans } from '../types';

const mapStringToObject = (range: string) => {
  const [odometer, title, description, maintenanceRangeId, maintenanceLineId] = range.split('~');
  return {
    odometer: parseInt(odometer),
    title,
    description,
    maintenanceRangeId: Number(maintenanceLineId),
    maintenanceLineId: Number(maintenanceRangeId)
  };
};

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchMaintenanceLinesList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  // @ts-ignore
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getMaintenanceLinesListService(
    { operatorId, organizationId },
    {
      successAction: (response: { data: MaintenancePlans[] }) => {
        const resultData = response.data.map((result) => {
          const r = camelcaseKeys(result);
          return {
            ...r,
            maintenanceRangesByHourMeter: r.maintenanceRangesByHourMeter
              ?.split('^')
              .map(mapStringToObject),
            maintenanceRangesByOdometer: r.maintenanceRangesByOdometer
              ?.split('^')
              .map(mapStringToObject)
          };
        });
        const {
          entities: { maintenanceLines },
          result //order
        } = normalize(resultData, schemas.maintenanceLines); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingMaintenanceLines(
            maintenanceLines,
            result,
            operatorId,
            organizationId
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingMaintenanceLines(error));
      }
    }
  );
}

export function* watchFetchMaintenanceLinesList() {
  yield takeEvery(types.MAINTENANCE_LINES_FETCH_STARTED, fetchMaintenanceLinesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchMaintenanceLinesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  // @ts-ignore
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getMaintenanceLinesListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response: { data: MaintenancePlans[] }) => {
        const resultData = response.data.map((result) => {
          const r = camelcaseKeys(result);
          return {
            ...r,
            maintenanceRangesByHourMeter: r.maintenanceRangesByHourMeter
              ?.split('^')
              .map(mapStringToObject),
            maintenanceRangesByOdometer: r.maintenanceRangesByOdometer
              ?.split('^')
              .map(mapStringToObject)
          };
        });
        const {
          entities: { maintenanceLines },
          result //order
        } = normalize(resultData, schemas.maintenanceLines); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingMaintenanceLines(
            maintenanceLines,
            result,
            operatorId,
            organizationId
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingMaintenanceLines(error));
      }
    }
  );
}

export function* watchPartialFetchMaintenanceLinesList() {
  yield takeEvery(types.MAINTENANCE_LINES_PARTIAL_FETCH_STARTED, partialFetchMaintenanceLinesList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addMaintenanceLine(action) {
  const maintenanceLine = action.payload;
  saveMaintenanceLineService(
    { ...maintenanceLine, maintenanceLineId: null },
    {
      successAction: (response) => {
        const r = camelcaseKeys(response.data);
        const addedMaintenanceLine = {
          ...r,
          maintenanceRangesByHourMeter: r.maintenanceRangesByHourMeter
            ?.split('^')
            .map(mapStringToObject),
          maintenanceRangesByOdometer: r.maintenanceRangesByOdometer
            ?.split('^')
            .map(mapStringToObject)
        };
        store.dispatch(
          actions.completeAddingMaintenanceLine(
            maintenanceLine.maintenanceLineId,
            addedMaintenanceLine
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingMaintenanceLine(maintenanceLine.maintenanceLineId, error));
      }
    }
  );
}

export function* watchAddMaintenanceLinesStarted() {
  yield takeEvery(types.MAINTENANCE_LINE_ADD_STARTED, addMaintenanceLine);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editMaintenanceLine(action) {
  const maintenanceLine = action.payload;
  const oldMaintenanceLine = yield select((state) =>
    selectors.getMaintenanceLine(state, maintenanceLine.maintenanceLineId)
  );
  saveMaintenanceLineService(
    { ...maintenanceLine },
    {
      successAction: (response) => {
        const r = camelcaseKeys(response.data);
        const editedMaintenanceLine = {
          ...r,
          maintenanceRangesByHourMeter: r.maintenanceRangesByHourMeter
            ?.split('^')
            .map(mapStringToObject),
          maintenanceRangesByOdometer: r.maintenanceRangesByOdometer
            ?.split('^')
            .map(mapStringToObject)
        };
        store.dispatch(actions.completeEditingMaintenanceLine(editedMaintenanceLine));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingMaintenanceLine(oldMaintenanceLine, error));
      }
    }
  );
}

export function* watchEditMaintenanceLinesStarted() {
  yield takeEvery(types.MAINTENANCE_LINE_EDIT_STARTED, editMaintenanceLine);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeMaintenanceLine(action) {
  const { maintenanceLineId } = action.payload;
  const maintenanceLine = yield select((state) =>
    selectors.getMaintenanceLine(state, maintenanceLineId)
  );
  deleteMaintenanceLineService(
    { ...maintenanceLine },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingMaintenanceLine(maintenanceLineId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingMaintenanceLine(maintenanceLineId, error));
      }
    }
  );
}

export function* watchRemoveMaintenanceLineStarted() {
  yield takeEvery(types.MAINTENANCE_LINE_REMOVE_STARTED, removeMaintenanceLine);
}

/* -------------------------------------------------------------------------- */
/*                                    COPY                                    */
/* -------------------------------------------------------------------------- */
function* copyMaintenanceLine(action) {
  const maintenanceLine = action.payload;
  copyMaintenanceLineService(
    { ...maintenanceLine },
    {
      successAction: (response) => {
        const r = camelcaseKeys(response.data);
        const copiedMaintenanceLine = {
          ...r,
          maintenanceRangesByHourMeter: r.maintenanceRangesByHourMeter
            ?.split('^')
            .map(mapStringToObject),
          maintenanceRangesByOdometer: r.maintenanceRangesByOdometer
            ?.split('^')
            .map(mapStringToObject)
        };
        store.dispatch(actions.completeCopyMaintenanceLine(copiedMaintenanceLine));
      },
      errorAction: (error) => {
        store.dispatch(actions.failCopyMaintenanceLine(error));
      }
    }
  );
}

export function* watchCopyMaintenanceLineStarted() {
  yield takeEvery(types.MAINTENANCE_LINE_COPY_STARTED, copyMaintenanceLine);
}
