/* -------------------------------------------------------------------------- */
/*                          LocationDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un LocationDropdownInput

import { env } from 'env';
const logo = require(`@src/assets/images/logo/${env.REACT_APP_FLAVOR}logo.svg`).default;
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroupText, UncontrolledTooltip } from 'reactstrap';
import { DefaultRootState } from 'redux/storeConfig/types';
import * as authSelectors from 'redux/reducers/auth';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import * as locationSelectors from 'views/screens/Locations/reducers';
import * as locationActions from 'views/screens/Locations/actions';
import locationsPermissions from 'views/screens/Locations/permissions';
import DropdownInput from '../DropdownInput';
import GoogleLocationsDropdownInput from '../GoogleLocationDropDown';
import { useScript } from 'views/components/Map/utils';
import SlidingPaneEditLocation from 'views/screens/Locations/views/SlidingPaneEditLocation';
const LocationsDropdownInput = (props) => {
  const {
    field,
    byLocationClassId,
    locationsFilters,
    useGoogleLocations = false,
    createFromGoogleLocations = false,
    useLabel = true,
    notAssigned = false,
    customerId,
    officeId,
    initialLocationType = 1,
    showOrganization = false
  } = props;

  const dispatch = useDispatch();
  const intl = useIntl();

  const [googlePlaceId, setGooglePlaceId] = useState<any>(undefined);
  const [isOpenSlidingPane, setIsOpenSlidingPane] = useState<boolean>(false);
  const [editingLocation, setEditingLocation] = useState<any>({});
  const [locationType, setLocationType] = useState(initialLocationType);

  const { locations, isFetching, isAdding, locationByPlaceId, user, store } = useSelector(
    (state: DefaultRootState) => ({
      locations: byLocationClassId
        ? locationSelectors.getLocationsListByLocationClassId(state, byLocationClassId)
        : locationSelectors.getLocationsList(state),
      isFetching:
        locationSelectors.isFetchingListLocations(state) ||
        locationSelectors.isPartialFetchingListLocations(state),
      isAdding: locationSelectors.isAddingLocation(state),
      locationByPlaceId: locationSelectors.getLocationByGooglePlaceId(state, googlePlaceId),
      user: authSelectors.getAuthUser(state),
      store: state
    })
  );
  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${user.googleMapsApiKey}&libraries=places`
  );

  //Update reactselect values of  locations when fetched is finished
  useEffect(() => {
    if (!isFetching) {
      resetDropdown();
    }
  }, [isFetching, props?.options?.length]);

  useEffect(() => {
    if (field.value?.googleLocation && createFromGoogleLocations) {
      setIsOpenSlidingPane(true);
      setEditingLocation(field.value);
    }
  }, [field.value, createFromGoogleLocations]);

  useEffect(() => {
    if (!isAdding && googlePlaceId && locationByPlaceId?.locationId) {
      setLocationType(1);
      setGooglePlaceId(undefined);
      field.onChange(locationByPlaceId.locationId);
    }
  }, [isAdding]);

  return (
    <>
      {!props.options && <FetchEntities useLocations locationsFilters={locationsFilters ?? {}} />}
      {locationType === 1 && (
        <DropdownInput
          {...props}
          key={resetValue}
          isLoading={isFetching}
          label={useLabel ? props.label || <FormattedMessage id="locations.locationClass.7" /> : null}
          options={
            props.options ||
            (notAssigned
              ? locations.filter(
                  (location) =>
                    (!location.customerId && !location.officeId) ||
                    (location.customerId === customerId && location.officeId === officeId)
                )
              : locations
            ).map((location) => {
              return {
                ...location,
                icon: showOrganization ? `${location.organizationName}` : undefined,
                id: location.locationId,
                value: location.locationId,
                label: location.locationName,
                label_intl: location.locationName
              };
            })
          }
          prependcomponent={
            useGoogleLocations &&
            authSelectors.getAuthUserHasPermissionsAny(store, [
              locationsPermissions.searchExternalLocations
            ]) && (
              <InputGroupText
                style={{ cursor: 'pointer' }}
                className={'m-0'}
                onClick={() => setLocationType(2)}
              >
                <UncontrolledTooltip placement={'top'} target={'logoImg'}>
                  {intl.formatMessage({ id: 'locations.systemLocations' })}
                </UncontrolledTooltip>
                <img src={logo} id={'logoImg'} alt="logo" height={'20px'} />
              </InputGroupText>
            )
          }
          appendcomponent={props.appendcomponent}
        />
      )}
      {locationType === 2 && useGoogleLocations && (
        <GoogleLocationsDropdownInput {...props} setLocationType={() => setLocationType(1)} />
      )}
      <SlidingPaneEditLocation
        title={intl.formatMessage({ id: 'locations.addLocation' })}
        isOpenSlidingPane={isOpenSlidingPane}
        closeSlidingPane={() => setIsOpenSlidingPane(false)}
        editingLocation={editingLocation}
        onClose={() => {
          setEditingLocation(undefined);
          setLocationType(1);
          field.onChange(undefined);
        }}
        action={(values) => {
          setGooglePlaceId(editingLocation.locationId);
          dispatch(
            locationActions.startAddingLocation({
              ...editingLocation,
              googleLocation: {
                ...editingLocation.googleLocation,
                googlePlaceId: editingLocation.locationId
              },
              ...values,
              ...values.membership,
              enableDispatch: 0,
              dispatchNotificationFreq: 0,
              dispatchEmails: null,
              dispatchCompletionType: 0,
              locationTypeId: 0
            })
          );
        }}
      />
    </>
  );
};

export default LocationsDropdownInput;
