import * as types from '../../types/workplansAssignments';

export const startFetchingAssignments = (filters = {}) => ({
  type: types.WORKPLAN_ASSIGNMENT_FETCH_STARTED,
  payload: filters
});
export const completeFetchingAssignmentsWithSchedule = (entities, order) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const completeFetchingAssignmentsWithDateTime = (entities, order) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingAssignments = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.WORKPLAN_ASSIGNMENT_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startRemovingAssignment = (assignmentId, assignmentType) => ({
  type: types.WORKPLAN_ASSIGNMENT_REMOVE_STARTED,
  payload: {
    assignmentId,
    assignmentType
  }
});
export const completeRemovingAssignmentWithSchedule = (assignmentId) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED,
  payload: {
    assignmentId
  }
});
export const completeRemovingAssignmentWithDateTime = (assignmentId) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED,
  payload: {
    assignmentId
  }
});
export const failRemovingAssignment = (assignmentId, error) => ({
  type: types.WORKPLAN_ASSIGNMENT_REMOVE_FAILED,
  payload: {
    assignmentId,
    error
  }
});

export const startEditingAssignmentWithSchedule = (assignment) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED,
  payload: assignment
});
export const completeEditingAssignmentWithSchedule = (assignment) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED,
  payload: assignment
});
export const failEditingAssignmentWithSchedule = (oldAssignment, error) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_FAILED,
  payload: {
    oldAssignment,
    error
  }
});

export const startEditingAssignmentWithDateTime = (assignment) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_STARTED,
  payload: assignment
});
export const completeEditingAssignmentWithDateTime = (assignment) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_COMPLETED,
  payload: assignment
});
export const failEditingAssignmentWithDateTime = (oldAssignment, error) => ({
  type: types.WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_FAILED,
  payload: {
    oldAssignment,
    error
  }
});
