/* -------------------------------------------------------------------------- */
/*                              Schema Organizations                          */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const organization = new schema.Entity(
  "organizations",
  {},
  { idAttribute: "organizationId" }
);
export const organizations = new schema.Array(organization);
