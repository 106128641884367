/* eslint-disable semi */
/* -------------------------------------------------------------------------- */
/*                                  Saga Auth                                 */
/* -------------------------------------------------------------------------- */

import camelcaseKeys from 'camelcase-keys';
import jwtDecode from 'jwt-decode';
import { normalize } from 'normalizr';
import { call, delay, put, select, takeEvery } from 'redux-saga/effects';
import sidebarOptions, * as sidebar from '../../../navigation/vertical';
import outsideSidebarOptions, * as outsideSidebar from '../../../navigation/outside-sidebar';
import * as actions from '../../actions/auth';
import * as navbarActions from '../../actions/navbar';
import * as selectors from '../../reducers/auth';
import * as navbarSelectors from '../../reducers/navbar';
import * as schemas from '../../schemas/bookmarks';
import * as types from '../../types/auth';
import * as navbarTypes from '../../types/navbar';
import * as alerts from '../../utils/alerts';
import API_BASE_URL from '../settings/apibaseurl';
import TOKEN_LIFE_TIME from '../settings/tokenLifeTime';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import { getTokenRefreshFailCount } from '../../reducers/auth';

function* login(action) {
  try {
    let timerInterval;
    // alerts.showLoading();
    const response = yield call(fetch, `${API_BASE_URL}/account/login`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    // alerts.closeLoading();
    if (response.status <= 300) {
      const { token } = yield response.json();
      //Se guarda el persisted storage////////
      yield localStorage.setItem('auth', token);
      let userData = camelcaseKeys(jwtDecode(token));
      yield localStorage.setItem('userData', JSON.stringify(userData));

      // alerts.showSuccessMessage({
      //   success: `Bienvenido ${userData.username}`,
      //   timer: 2000,
      // });

      // yield put(reset("login"));
      ////////////////////////////////////////
      yield put(actions.completeLogin(token));
      // Obtener informacion adicional del usuario
      // yield put(actions.authenticationUserInformationStarted());
    } else {
      yield put(
        actions.failLogin(
          'El nombre de usuario y contraseña introducidos no coinciden con nuestros registros. Revísalos e inténtalo de nuevo.'
        )
      );

      alerts.showErrorAlertFailChanges({
        error: {
          errorMessage:
            'El nombre de usuario y contraseña introducidos no coinciden con nuestros registros. Revísalos e inténtalo de nuevo.'
        }
      });
    }
  } catch (error) {
    yield put(actions.failLogin('Falló la autentitación.'));
    alerts.showErrorAlertFailChanges({
      error: {
        errorMessage:
          'El nombre de usuario y contraseña introducidos no coinciden con nuestros registros. Revísalos e inténtalo de nuevo.'
      }
    });
  }
}

export function* watchLoginStarted() {
  yield takeEvery(types.AUTHENTICATION_STARTED, login);
}

function* userInformationRequest(action) {
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    const userId = yield select(selectors.getAuthUserID);

    if (isAuth) {
      const token = yield select(selectors.getAuthToken);

      const responseSuperAdmin = yield call(fetch, `${API_BASE_URL}/superAdmins/${userId}/`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `JWT ${token}`
        }
      });

      if (responseSuperAdmin.status <= 300) {
        const jsonResultUser = yield responseSuperAdmin.json();
        yield put(actions.authenticationUserInformationCompleted(jsonResultUser));
      } else {
        const responseRestaurantAdmin = yield call(
          fetch,
          `${API_BASE_URL}/restaurantAdmins/${userId}/`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `JWT ${token}`
            }
          }
        );

        if (responseRestaurantAdmin.status <= 300) {
          const jsonResultUser = yield responseRestaurantAdmin.json();
          yield put(actions.authenticationUserInformationCompleted(jsonResultUser));
        } else {
          yield put(actions.logout());
        }
      }
    }
  } catch (error) {
    yield put(actions.logout());
    yield put(actions.failRefreshToken('Falló la conexión'));
  }
}

export function* watchUserInformationRequest() {
  yield takeEvery(types.AUTHENTICATION_USER_INFORMATION_STARTED, userInformationRequest);
}

/* -------------------------------------------------------------------------- */
/*                                REFRESH TOKEN                               */
/* -------------------------------------------------------------------------- */
function* refreshToken(action) {
  const tokenRefreshFailCount = yield select(getTokenRefreshFailCount);
  try {
    const isAuth = yield select((state) => state.auth.token != null);
    const token = yield select((state) => state.auth.token);

    const expiration = yield select((state) => state.auth.user.exp);
    const now = parseInt(new Date().getTime() / 1000);

    if (expiration - now < TOKEN_LIFE_TIME / 2) {
      const response = yield call(fetch, `${API_BASE_URL}/account/refresh-token`, {
        method: 'POST',
        body: JSON.stringify({ token }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      if (response.status === 200) {
        const { token } = yield response.json();
        yield put(actions.completeRefreshToken(token));
        //Se guarda el persisted storage////////
        yield localStorage.setItem('auth', token);
        yield localStorage.setItem('userData', JSON.stringify(camelcaseKeys(jwtDecode(token))));
      } else {
        if (tokenRefreshFailCount && tokenRefreshFailCount >= 2) yield put(actions.logout());
        const { non_field_errors } = yield response.json();
        yield put(actions.failRefreshToken({ ...non_field_errors[0], tokenRefreshFailCount }));
        yield delay(200);
      }
    }
  } catch (error) {
    if (tokenRefreshFailCount && tokenRefreshFailCount >= 2) yield put(actions.logout());

    yield put(actions.failRefreshToken('Falló la conexión'));
  }
}

export function* watchRefreshTokenStarted() {
  yield takeEvery(types.TOKEN_REFRESH_STARTED, refreshToken);
}

/* -------------------------------------------------------------------------- */
/*                  FORCE REFRESH TOKEN WHEN PROFILE UPDATED                  */
/* -------------------------------------------------------------------------- */

/* -------------------------------------------------------------------------- */
/*                                REFRESH TOKEN                               */
/* -------------------------------------------------------------------------- */
function* forceRefreshToken(action) {
  const token = yield select((state) => state.auth.token);

  try {
    const response = yield call(fetch, `${API_BASE_URL}/account/refresh-token`, {
      method: 'POST',
      body: JSON.stringify({ token }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (response.status === 200) {
      const { token } = yield response.json();
      yield put(actions.completeRefreshToken(token));
      //Se guarda el persisted storage////////
      yield localStorage.setItem('auth', token);
      yield localStorage.setItem('userData', JSON.stringify(camelcaseKeys(jwtDecode(token))));
    } else {
      // yield put(actions.logout());
      // const { non_field_errors } = yield response.json();
      // yield put(actions.failRefreshToken(non_field_errors[0]));
      // yield delay(200);
    }
  } catch (error) {
    // yield put(actions.logout());
    // yield put(actions.failRefreshToken('Falló la conexión'));
  }
}

export function* watchForceRefreshToken() {
  yield takeEvery(
    [
      types.PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED,
      types.PROFILE_UPDATE_MAP_OPTIONS_COMPLETED,
      types.PROFILE_UPDATE_PASSWORD_COMPLETED,
      types.PROFILE_UPDATE_PICTURE_COMPLETED,
      types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED,
      types.PROFILE_UPDATE_HOME_SCREEN_COMPLETED,
      types.TOKEN_REFRESH_FORCE_STARTED
    ],
    forceRefreshToken
  );
}
/* -------------------------------------------------------------------------- */
/*                                 FETCH BOOKMARKS                                 */
/* -------------------------------------------------------------------------- */
function* fetchUserBookmarks(action) {
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/account/userSettings`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const bookmarkSetting = jsonResult.data.filter(
          (setting) => camelcaseKeys(setting).settingId == 19
        );
        const bookmarkSettingValue =
          bookmarkSetting.length == 1 ? camelcaseKeys(bookmarkSetting[0]).settingValue : '';
        const bookmarkIds =
          (bookmarkSettingValue ?? '') != '' ? bookmarkSettingValue.split(',') : [];
        const optionsSuggestions = [
          ...sidebar.getOptions(sidebarOptions),
          ...sidebar.getOptions(outsideSidebarOptions)
        ];
        const {
          entities: {
            bookmarks //byId
          },
          result //order
        } = normalize(optionsSuggestions, schemas.bookmarks); //normalize data to byId and order
        let userBookmarks = bookmarkIds.map((id) => {
          bookmarks[id] = {
            ...bookmarks[id],
            isBookmarked: true
          };
          return {
            ...bookmarks[id]
          };
        });
        let newSuggestions = result.map((id) => bookmarks[id]);

        yield put(navbarActions.completeFetchingBookmarks(userBookmarks, newSuggestions));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(navbarActions.failFetchingBookmarks());
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(navbarActions.failFetchingBookmarks());
  }
}

export function* watchFetchUserBookmarksStarted() {
  yield takeEvery(navbarTypes.GET_BOOKMARKS_FETCH_STARTED, fetchUserBookmarks);
}

/* -------------------------------------------------------------------------- */
/*                         ADD USER BOOKMARK                                  */
/* -------------------------------------------------------------------------- */
function* updateUserBookmarks(action) {
  // const id = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    const userBookmarks = yield select(navbarSelectors.getUserBookmarks);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      const userBookmarksSettingsValue = userBookmarks.map((ub) => ub.id).toString();
      const response = yield call(fetch, `${API_BASE_URL}/account/saveUserSettings`, {
        method: 'POST',
        body: JSON.stringify({
          settingId: 19,
          settingValue: userBookmarksSettingsValue
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        // const updateUserSettings = camelcaseKeys(jsonResult.data);
        yield put(navbarActions.updateBookmarkedCompleted());
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(navbarActions.updateBookmarkedFailed());
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(navbarActions.updateBookmarkedFailed());
  }
}

export function* watchUpdateUserBookmarkssStarted() {
  yield takeEvery(navbarTypes.UPDATE_BOOKMARKED_STARTED, updateUserBookmarks);
}

/* -------------------------------------------------------------------------- */
/*                 UPDATE PROFILE GENERAL INFORMATION                         */
/* -------------------------------------------------------------------------- */
function* updateProfileGeneralInformation(action) {
  const values = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      yield delay(2000);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/profile/saveProfile`, {
        method: 'POST',
        body: JSON.stringify({
          ...values
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // alerts.closeLoading();
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingProfileGeneralInformation(values));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingProfileGeneralInformation(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.errorMessage'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingProfileGeneralInformation(error));
  }
}

export function* watchUpdateProfileGeneralInformationStarted() {
  yield takeEvery(
    types.PROFILE_UPDATE_GENERAL_INFORMATION_STARTED,
    updateProfileGeneralInformation
  );
}

/* -------------------------------------------------------------------------- */
/*                     UPDATE PROFILE MAP SETTINGS CONFIG                     */
/* -------------------------------------------------------------------------- */
function* updateMapProfileSettings(action) {
  const values = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/account/saveUserSettingsMap`, {
        method: 'POST',
        body: JSON.stringify({
          ...values
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.mapSettingsConfiguration.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingMapSettingsConfiguration(values));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.mapSettingsConfiguration.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingMapSettingsConfiguration(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.mapSettingsConfiguration.errorMessage'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingMapSettingsConfiguration(error));
  }
}

export function* watchUpdateMapProfileSettingsStarted() {
  yield takeEvery(
    types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_STARTED,
    updateMapProfileSettings
  );
}

/* -------------------------------------------------------------------------- */
/*                     UPDATE PROFILE MAP OPTIONS                             */
/* -------------------------------------------------------------------------- */
function* updateProfileMapOptions(action) {
  const values = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/profile/saveMapOptions`, {
        method: 'POST',
        body: JSON.stringify({
          ...values
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // alerts.closeLoading();
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.mapOptions.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingProfileMapOptions(values));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.mapOptions.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingProfileMapOptions(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.mapOptions.errorMessage'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingProfileMapOptions(error));
  }
}

export function* watchUpdateProfileMapOptionsStarted() {
  yield takeEvery(types.PROFILE_UPDATE_MAP_OPTIONS_STARTED, updateProfileMapOptions);
}

/* -------------------------------------------------------------------------- */
/*                        UPDATE PROFILE PASSWORD                             */
/* -------------------------------------------------------------------------- */
function* updateProfilePassword(action) {
  const values = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/profile/password`, {
        method: 'POST',
        body: JSON.stringify({
          ...values
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // alerts.closeLoading();
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.changePassword.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingProfilePassword(values));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.changePassword.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingProfilePassword(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.changePassword.errorMessageGeneral'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingProfilePassword(error));
  }
}

export function* watchUpdateProfilePasswordStarted() {
  yield takeEvery(types.PROFILE_UPDATE_PASSWORD_STARTED, updateProfilePassword);
}

/* -------------------------------------------------------------------------- */
/*                             UPDATE HOME SCREEN                             */
/* -------------------------------------------------------------------------- */
function* updateHomeScreen(action) {
  const homeScreenId = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      // alerts.showLoading();
      const response = yield call(fetch, `${API_BASE_URL}/profile/homeScreen`, {
        method: 'POST',
        body: JSON.stringify({
          homeScreenId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // alerts.closeLoading();
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'profile.changeHomeScreen.successMessage'
          }),
          timer: 4000
        });
        yield put(actions.completeUpdatingProfileHomeScreen(homeScreenId));
      } else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.changeHomeScreen.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingProfileHomeScreen(error));
      }
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.changeHomeScreen.errorMessageGeneral'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failUpdatingProfileHomeScreen(error));
  }
}

export function* watchUpdateProfileHomeScreenStarted() {
  yield takeEvery(types.PROFILE_UPDATE_HOME_SCREEN_STARTED, updateHomeScreen);
}

/* -------------------------------------------------------------------------- */
/*                              CHANGE PRODUCT                                */
/* -------------------------------------------------------------------------- */
function* changeProduct(action) {
  const serviceId = action.payload;
  try {
    const isAuth = yield select(selectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(selectors.getAuthToken);
      // alerts.showLoading();
      /*const response = yield call(fetch, `${API_BASE_URL}/profile/service`, {
        method: 'POST',
        body: JSON.stringify({
          homeScreenId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });*/
      // alerts.closeLoading();
      //const jsonResult = yield response.json();
      //if (response.status <= 300 && jsonResult.success) {
      alerts.showSuccessMessage({
        success: appIntl().formatMessage({
          id: 'profile.changeProduct.successMessage'
        }),
        timer: 4000
      });
      yield put(actions.completeChangingProduct(serviceId));
      /*} else {
        const error = {
          // errorMessage: jsonResult.message,
          errorMessage: appIntl().formatMessage({
            id: 'profile.changeActualService.errorMessage'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failUpdatingProfileActualService(error));
      }*/
    }
  } catch (err) {
    const error = {
      // errorMessage: err.toString(),
      errorMessage: appIntl().formatMessage({
        id: 'profile.changeProduct.errorMessageGeneral'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failChangingProduct(error));
  }
}

export function* watchChangeProductStarted() {
  yield takeEvery(types.PROFILE_CHANGE_PRODUCT_STARTED, changeProduct);
}
