import { combineReducers } from 'redux';

import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';

import brands from '../../../../redux/reducers/static-catalogs/brands';
import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SENSORS_FETCH_COMPLETED:
    case types.SENSORS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((sensorId) => {
        newState[sensorId] = {
          isSelected: false,
          ...entities[sensorId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.SENSOR_FETCH_COMPLETED: {
      const sensor = action.payload;
      const newState = { ...state };
      newState[sensor.deviceId] = {
        isSelected: false,
        ...state[sensor.deviceId],
        ...sensor,
        isConfirmed: true
      };
      return newState;
    }

    case types.SENSOR_ADD_COMPLETED: {
      const { oldId, sensor } = action.payload;
      const newState = omit(state, oldId);
      newState[sensor.sensorId] = {
        ...state[oldId],
        ...sensor,
        isConfirmed: true
      };
      return newState;
    }

    case types.SENSOR_EDIT_COMPLETED: {
      const sensor = action.payload;
      return {
        ...state,
        [sensor.sensorId]: {
          ...omit(state[sensor.sensorId], ['oldSensor']),
          ...omit(sensor, ['oldSensor']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.SENSOR_ADD_STARTED: {
      const sensor = action.payload;
      const newState = { ...state };
      newState[sensor.sensorId] = {
        isSelected: false,
        ...sensor,
        isConfirmed: false
      };
      return newState;
    }
    case types.SENSOR_EDIT_STARTED: {
      const sensor = action.payload;
      return {
        ...state,
        [sensor.sensorId]: {
          ...state[sensor.sensorId],
          oldSensor: state[sensor.sensorId],
          ...sensor,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.SENSOR_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.SENSOR_EDIT_FAILED: {
      const oldSensor = action.payload;
      return {
        ...state,
        [oldSensor.sensorId]: {
          ...omit(state[oldSensor.sensorId], ['oldSensor']),
          ...oldSensor,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.SENSOR_REMOVE_COMPLETED: {
      const { sensorId } = action.payload;
      return omit(state, sensorId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.SENSOR_SELECTED: {
      const sensorId = action.payload;
      const newState = {
        ...state,
        [sensorId]: {
          ...state[sensorId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.SENSOR_DESELECTED: {
      const sensorId = action.payload;
      return {
        ...state,
        [sensorId]: {
          ...state[sensorId],
          isSelected: false
        }
      };
    }

    case types.SENSORS_ALL_SELECTED: {
      const sensorIds = action.payload;
      const newState = { ...state };
      if (sensorIds.length == 0) {
        forEach(state, (sensor: any, sensorId) => {
          newState[sensorId] = {
            ...sensor,
            isSelected: true
          };
        });
      } else {
        sensorIds.forEach((sensorId) => {
          newState[sensorId] = {
            ...state[sensorId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.SENSORS_ALL_DESELECTED: {
      const sensorIds = action.payload;
      const newState = { ...state };
      if (sensorIds.length == 0) {
        forEach(state, (sensor: any, sensorId) => {
          newState[sensorId] = {
            ...sensor,
            isSelected: false
          };
        });
      } else {
        sensorIds.forEach((sensorId) => {
          newState[sensorId] = {
            ...state[sensorId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    case types.START_SENSOR_CALIBRATION_COMPLETED: {
      const sensorId = action.payload?.sensorId;
      return {
        ...state,
        [sensorId]: {
          ...state[sensorId],
          calibrationState: 1
        }
      };
    }

    case types.FINALIZE_SENSOR_CALIBRATION_COMPLETED: {
      const sensorId = action.payload?.sensorId;
      return {
        ...state,
        [sensorId]: {
          ...state[sensorId],
          calibrationState: 0
        }
      };
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SENSORS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.SENSORS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.SENSOR_FETCH_COMPLETED: {
      const sensor = action.payload;
      return uniq([...state, sensor.deviceId]);
    }

    case types.SENSOR_ADD_COMPLETED: {
      const { oldId, sensor } = action.payload;
      return state.map((sensorId) => (sensorId === oldId ? sensor.sensorId : sensorId));
    }

    case types.SENSOR_REMOVE_COMPLETED: {
      const { sensorId } = action.payload;
      return state.filter((sensorIdState) => sensorIdState !== sensorId);
    }

    //CASE ADD STARTED
    case types.SENSOR_ADD_STARTED: {
      const sensor = action.payload;
      return [...state, sensor.sensorId];
    }

    //CASE ADD FAILED
    case types.SENSOR_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((sensorIdState) => sensorIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SENSORS_FETCH_STARTED: {
      return true;
    }
    case types.SENSORS_FETCH_COMPLETED:
    case types.SENSORS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_FETCH_STARTED: {
      return true;
    }
    case types.SENSOR_FETCH_COMPLETED:
    case types.SENSOR_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_ADD_STARTED: {
      return true;
    }
    case types.SENSOR_ADD_COMPLETED:
    case types.SENSOR_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_EDIT_STARTED: {
      return true;
    }
    case types.SENSOR_EDIT_COMPLETED:
    case types.SENSOR_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_REMOVE_STARTED: {
      return true;
    }
    case types.SENSOR_REMOVE_COMPLETED:
    case types.SENSOR_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SENSORS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSORS_FETCH_STARTED:
    case types.SENSORS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_FETCH_STARTED:
    case types.SENSOR_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_ADD_STARTED:
    case types.SENSOR_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_EDIT_STARTED:
    case types.SENSOR_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_REMOVE_STARTED:
    case types.SENSOR_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const validCode = (state = true, action) => {
  switch (action.type) {
    case types.IDENTIFIER_CHECK_COMPLETED: {
      return action.payload;
    }
    case types.IDENTIFIER_CHECK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const sensors = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  brands,
  validCode
});

export default sensors;

//Own state
export const getOwnState = (state) => state.sensors;

//Information
export const getSensor = (state, sensorId) => getOwnState(state).byId[sensorId];
export const getSensorsList = (state) =>
  getOwnState(state)
    .order.map((id) => getSensor(state, id))
    .filter((sensor) => sensor.sensorStatus > 0);

export const getSensorsListByMembership = (state, membership) =>
  getSensorsList(state).filter(
    (sensor) =>
      membership &&
      (!membership.operatorId || membership.operatorId === sensor.operatorId) &&
      (!membership.organizationId || membership.organizationId === sensor.organizationId) &&
      (!membership.divisionId || membership.divisionId === sensor.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === sensor.subdivisionId)
  );
export const getSelectedSensors = (state) => {
  const selectedSensors = getSensorsList(state).filter((sensor) => sensor.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedSensors.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedSensors.length > 0) return selectedSensors;
};

export const getSelectedSensor = (state) => {
  const selectedSensors = getSensorsList(state).filter((sensor) => {
    return sensor.isSelected;
  });
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedSensors.length === 1) return selectedSensors[0];
  //De lo contrario se devuelve null
  else return null;
};

export const isValidCode = (state) => getOwnState(state).validCode;

//Status of sagas
export const isFetchingSensor = (state) => getOwnState(state).isFetching;
export const isFetchingListSensors = (state) => getOwnState(state).isFetchingList;
export const isAddingSensor = (state) => getOwnState(state).isAdding;
export const isEditingSensor = (state) => getOwnState(state).isEditing;
export const isRemovingSensor = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingSensorErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListSensorsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingSensorErrors = (state) => getOwnState(state).errorAdding;
export const getEditingSensorErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingSensorErrors = (state) => getOwnState(state).errorRemoving;
