import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/workplansComments';
import * as types from '../../types/workplansComments';
import * as selectors from '../../reducers/workplansComments';
import * as schemas from '../../schemas/workplansComments';
import * as entitySelectors from '../../../Entities/reducers';
import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { store } from 'redux/storeConfig/store';
import {
  deleteCommentService,
  getWorkplanCommentsListService,
  saveCommentService
} from '../../services/workplansComments';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchCommentsList(action) {
  const { visitId, locationId } = action.payload;

  getWorkplanCommentsListService(
    { visitId, locationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { comments },
          result //order
        } = normalize(resultData, schemas.comments); //normalize data to byId and order
        store.dispatch(actions.completeFetchingComments(comments, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingComments(error));
      }
    }
  );
}

export function* watchFetchWorkplansCommentsList() {
  yield takeEvery(types.WORKPLAN_COMMENTS_FETCH_STARTED, fetchCommentsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addComment(action) {
  const comment = action.payload;
  saveCommentService(
    { ...comment, jobCommentId: null },
    {
      successAction: (response) => {
        const addedComment = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingComment(comment.jobCommentId, addedComment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingComment(comment.jobCommentId, error));
      }
    }
  );
}

export function* watchAddWorkplansCommentStarted() {
  yield takeEvery(types.WORKPLAN_COMMENT_ADD_STARTED, addComment);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeComment(action) {
  const { jobCommentId } = action.payload;
  const deletedComment = yield select((state) =>
    selectors.getWorkplansComment(state, jobCommentId)
  );
  deleteCommentService(
    { ...deletedComment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingComment(jobCommentId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingComment(jobCommentId, error));
      }
    }
  );
}

export function* watchRemoveWorkplansCommentStarted() {
  yield takeEvery(types.WORKPLAN_COMMENT_REMOVE_STARTED, removeComment);
}
