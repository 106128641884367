import * as types from '../../types/workplansExecutionLocations';

export const startAddingWorkplanExecutionLocation = (workplanExecutionLocation) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED,
  payload: workplanExecutionLocation
});
export const completeAddingWorkplanExecutionLocation = (oldId, workplanExecutionLocation) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED,
  payload: { oldId, workplanExecutionLocation }
});
export const failAddingWorkplanExecutionLocation = (oldId, error) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingWorkplanExecutionLocation = (workplanExecutionLocation) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED,
  payload: workplanExecutionLocation
});
export const completeEditingWorkplanExecutionLocation = (workplanExecutionLocation) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED,
  payload: workplanExecutionLocation
});
export const failEditingWorkplanExecutionLocation = (oldWorkplanExecutionLocation, error) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED,
  payload: {
    oldWorkplanExecutionLocation,
    error
  }
});

export const startRemovingWorkplanExecutionLocation = (
  visitId,
  locationId,
  sequenceNumber,
  applyToWorkplan
) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED,
  payload: {
    visitId,
    locationId,
    sequenceNumber,
    applyToWorkplan
  }
});
export const completeRemovingWorkplanExecutionLocation = (workplanExecutionLocationId) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED,
  payload: {
    workplanExecutionLocationId
  }
});
export const failRemovingWorkplanExecutionLocation = (workplanExecutionLocationId, error) => ({
  type: types.WORKPLANS_EXECUTION_LOCATION_REMOVE_FAILED,
  payload: {
    workplanExecutionLocationId,
    error
  }
});
