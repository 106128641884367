import * as types from '../types';

export const startFetchingAssets = (filters = {}) => ({
  type: types.ASSETS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingAssets = (entities, order, operatorId, organizationId) => ({
  type: types.ASSETS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingAssets = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ASSETS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingAssets = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.ASSETS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingAssets = (entities, order, operatorId, organizationId) => ({
  type: types.ASSETS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingAssets = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ASSETS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingAsset = (asset) => ({
  type: types.ASSET_ADD_STARTED,
  payload: asset
});
export const completeAddingAsset = (oldId, asset) => ({
  type: types.ASSET_ADD_COMPLETED,
  payload: { oldId, asset }
});
export const failAddingAsset = (oldId, error) => ({
  type: types.ASSET_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingAsset = (asset) => ({
  type: types.ASSET_EDIT_STARTED,
  payload: asset
});
export const completeEditingAsset = (asset) => ({
  type: types.ASSET_EDIT_COMPLETED,
  payload: asset
});
export const failEditingAsset = (oldAsset, error) => ({
  type: types.ASSET_EDIT_FAILED,
  payload: {
    oldAsset,
    error
  }
});

export const startRemovingAsset = (assetId) => ({
  type: types.ASSET_REMOVE_STARTED,
  payload: {
    assetId
  }
});
export const completeRemovingAsset = (assetId) => ({
  type: types.ASSET_REMOVE_COMPLETED,
  payload: {
    assetId
  }
});
export const failRemovingAsset = (assetId, error) => ({
  type: types.ASSET_REMOVE_FAILED,
  payload: {
    assetId,
    error
  }
});

export const selectAsset = (assetId) => ({
  type: types.ASSET_SELECTED,
  payload: assetId
});

export const selectAllAssets = (
  assetIds = [] //List of AssetIds
) => ({
  type: types.ASSETS_ALL_SELECTED,
  payload: assetIds
});

export const deselectAsset = (assetId) => ({
  type: types.ASSET_DESELECTED,
  payload: assetId
});

export const deselectAllAssets = (
  assetIds = [] //List of AssetIds
) => ({
  type: types.ASSETS_ALL_DESELECTED,
  payload: assetIds
});

export const startCheckingCode = (assetId, identifier) => ({
  type: types.ASSET_IDENTIFIER_CHECK_STARTED,
  payload: {
    assetId,
    identifier
  }
});
export const completeCheckingCode = (result) => ({
  type: types.ASSET_IDENTIFIER_CHECK_COMPLETED,
  payload: result
});
export const failCheckingCode = (error) => ({
  type: types.ASSET_IDENTIFIER_CHECK_FAILED,
  payload: {
    error
  }
});

export const startCheckingSecuritySealCode = (assetId, securitySealCode) => ({
  type: types.ASSET_SECURITY_SEAL_CODE_CHECK_STARTED,
  payload: {
    assetId,
    securitySealCode
  }
});
export const completeCheckingSecuritySealCode = (result) => ({
  type: types.ASSET_SECURITY_SEAL_CODE_CHECK_COMPLETED,
  payload: result
});
export const failCheckingSecuritySealCode = (error) => ({
  type: types.ASSET_SECURITY_SEAL_CODE_CHECK_FAILED,
  payload: {
    error
  }
});
