/* -------------------------------------------------------------------------- */
/*                    Componente SlidingPaneEditOffice                   */
/* -------------------------------------------------------------------------- */
// Este componente permite crear o editar clientes

import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Row, Col } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from 'views/formComponents/TextInput';
import Membership from 'views/formComponents/Membership';
import * as authSelectors from 'redux/reducers/auth';
import * as locationSelectors from 'views/screens/Locations/reducers';
import * as officeSelectors from 'views/screens/Offices/reducers';
import * as officeActions from 'views/screens/Offices/actions';
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash/omit';
import TabsWrapper from 'views/components/TabsWrapper';
import NumericalInput from 'views/formComponents/NumericalInput';
import TimeInput from 'views/formComponents/TimeInput';
import CustomersDropdown from 'views/formComponents/CustomersDropdown';
import LocationsDropdownInput from 'views/formComponents/LocationDropDown';

interface Props {
  office: any;
  isOpenSlidingPane: any;
  closeSlidingPaneEditOffice: any;
  enableSelectLocation?: boolean;
}

const SlidingPaneEditOffice = (props: Props) => {
  const {
    office,
    isOpenSlidingPane,
    closeSlidingPaneEditOffice,
    enableSelectLocation = false
  } = props;
  const { control, handleSubmit, watch, reset } = useForm();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const intl = useIntl();
  const dispatch = useDispatch();

  const formValues = watch();

  const { isNew, initialValues, locationName, locationPrivateCode, parsers } = useSelector(
    (state) => {
      const initialValues = officeSelectors.getSelectedOffice(state);
      return {
        isNew: initialValues ? false : true,
        initialValues,
        locationName:
          locationSelectors.getLocation(
            state,
            formValues?.locationId !== initialValues?.locationId
              ? formValues?.locationId
              : undefined
          )?.locationName ?? initialValues?.officeName,
        locationPrivateCode:
          locationSelectors.getLocation(
            state,
            formValues?.locationId !== initialValues?.locationId
              ? formValues?.locationId
              : undefined
          )?.privateCode ?? initialValues?.privateCode,
        parsers: authSelectors.getParsers(state)
      };
    }
  );

  const editOffice = (values) => {
    dispatch(officeActions.startEditingOffice(getValues(initialValues?.officeId, values)));
    closeSlidingPaneEditOffice();
    reset();
  };

  const createOffice = (values) => {
    dispatch(officeActions.startAddingOffice(getValues(uuidv4(), values)));
    closeSlidingPaneEditOffice();
    reset();
  };

  const getValues = (officeId, values) => ({
    ...omit(values, 'membership'),
    officeId,
    organizationId: values.membership?.organizationId,
    divisionId: values.membership?.divisionId,
    subdivisionId: values.membership?.subdivisionId,
    isMainOffice: initialValues?.isMainOffice,
    startTime: values.startTime
      ? new Date(values.startTime).toLocaleTimeString('en-GB')
      : undefined,
    endTime: values.endTime ? new Date(values.endTime).toLocaleTimeString('en-GB') : undefined,
    averageCapacity: values.averageCapacity
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.averageCapacity).toFixed(2)
      : undefined,
    minCapacity: values.minCapacity
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.minCapacity).toFixed(2)
      : undefined,
    maxCapacity: values.maxCapacity
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.maxCapacity).toFixed(2)
      : undefined
  });

  useEffect(() => {
    if (office?.officeId) {
      dispatch(officeActions.selectOffice(office?.officeId));
      return () => {
        dispatch(officeActions.deselectOffice(office?.officeId));
      };
    }
  }, [office?.officeId]);

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: isNew ? 'offices.create' : 'offices.edit' })}
      subtitle={isNew ? undefined : initialValues?.officeName}
      width={'45rem'}
      onRequestClose={() => {
        closeSlidingPaneEditOffice();
        reset();
      }}
    >
      <Row>
        <Membership
          control={control}
          shouldUnregister
          isInModal
          defaultValue={
            initialValues
              ? {
                  organizationId: initialValues.organizationId,
                  divisionId: initialValues.divisionId,
                  subdivisionId: initialValues.subdivisionId
                }
              : undefined
          }
          name="membership"
          options={{
            useOperator: false,
            useUserOrganization: true,
            showAllDivision: true,
            showAllSubdivision: true,
            colSize: 6
          }}
        />
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'customerId'}
            rules={{ required: true }}
            defaultValue={initialValues?.customerId}
            render={({ field, fieldState }) => (
              <CustomersDropdown
                field={field}
                fieldstate={fieldState}
                id={'customerIdInput'}
                isInModal
                enableSelectLocation={enableSelectLocation}
                appendAddCustomer={!initialValues?.isMainOffice}
                isDisabled={initialValues?.isMainOffice}
                customersFilters={formValues?.membership}
                required
              />
            )}
          />
        </Col>
        {!enableSelectLocation && (
          <Col sm="12">
            <Controller
              control={control}
              shouldUnregister
              name={'locationId'}
              defaultValue={initialValues?.locationId}
              render={({ field, fieldState }) => (
                <LocationsDropdownInput
                  field={field}
                  fieldstate={fieldState}
                  isInModal
                  isClearable
                  notAssigned
                  customerId={initialValues?.customerId}
                  officeId={initialValues?.officeId}
                  useGoogleLocations
                  createFromGoogleLocations
                  id={'inputLocation'}
                  locationsFilters={formValues?.membership}
                  label={intl.formatMessage({ id: 'locations.location' })}
                />
              )}
            />
          </Col>
        )}
        <Col sm="12">
          <Controller
            control={control}
            name="officeName"
            rules={{ required: true }}
            defaultValue={initialValues?.officeName}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldstate={fieldState}
                id={'officeNameInput'}
                forceinitialvalue={initialValues?.isMainOffice ? undefined : locationName}
                label={intl.formatMessage({
                  id: 'common.name'
                })}
                disabled={initialValues?.isMainOffice}
                required
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name="privateCode"
            defaultValue={initialValues?.privateCode}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldstate={fieldState}
                forceinitialvalue={locationPrivateCode}
                id={'privateCodeInput'}
                label={intl.formatMessage({
                  id: 'offices.privateCode'
                })}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'averageTime'}
            defaultValue={initialValues?.averageTime}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                id={'averageTimeInput'}
                label={intl.formatMessage({ id: 'workplans.averageTime' })}
                appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                min={formValues?.minTime ?? 0}
                max={formValues?.maxTime ?? 1000}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'minTime'}
            defaultValue={initialValues?.minTime}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                id={'minTimeInput'}
                label={intl.formatMessage({ id: 'workplans.minTime' })}
                appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                min={0}
                max={formValues?.maxTime ?? 1000}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'maxTime'}
            defaultValue={initialValues?.maxTime}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                id={'maxTimeInput'}
                label={intl.formatMessage({ id: 'workplans.maxTime' })}
                appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                min={formValues?.minTime ?? 0}
                max={1000}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'startTime'}
            defaultValue={initialValues?.startTime ? new Date(initialValues.startTime) : undefined}
            render={({ field, fieldState }) => (
              <TimeInput
                field={field}
                fieldstate={fieldState}
                allowNulls
                required={false}
                label={intl.formatMessage({ id: 'workplans.startTime' })}
                maxTime={
                  formValues?.endTime ? moment(formValues.endTime).format('HH:mm') : undefined
                }
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'endTime'}
            defaultValue={initialValues?.endTime ? new Date(initialValues.endTime) : undefined}
            render={({ field, fieldState }) => (
              <TimeInput
                field={field}
                fieldstate={fieldState}
                allowNulls
                required={false}
                label={intl.formatMessage({ id: 'workplans.endTime' })}
                minTime={
                  formValues?.startTime ? moment(formValues.startTime).format('HH:mm') : undefined
                }
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'averageCapacity'}
            defaultValue={
              initialValues?.averageCapacity
                ? parsers
                    .convertCubicMetersToAuthUserVolumeSystem(initialValues.averageCapacity)
                    .toFixed(2)
                : undefined
            }
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                allowDecimals
                decimalsPrecision={2}
                id={'averageCapacityInput'}
                metric={'volume'}
                label={intl.formatMessage({ id: 'offices.averageCapacity' })}
                min={formValues?.minCapacity ?? 0}
                max={formValues?.maxCapacity ?? 1000}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'minCapacity'}
            defaultValue={
              initialValues?.minCapacity
                ? parsers
                    .convertCubicMetersToAuthUserVolumeSystem(initialValues.minCapacity)
                    .toFixed(2)
                : undefined
            }
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                allowDecimals
                decimalsPrecision={2}
                id={'minCapacityInput'}
                metric={'volume'}
                label={intl.formatMessage({ id: 'offices.minCapacity' })}
                min={0}
                max={formValues?.maxCapacity ?? 1000}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name={'maxCapacity'}
            defaultValue={
              initialValues?.maxCapacity
                ? parsers
                    .convertCubicMetersToAuthUserVolumeSystem(initialValues.maxCapacity)
                    .toFixed(2)
                : undefined
            }
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                allowDecimals
                decimalsPrecision={2}
                id={'maxCapacityInput'}
                metric={'volume'}
                label={intl.formatMessage({ id: 'offices.maxCapacity' })}
                min={formValues?.minCapacity ?? 0}
                max={1000}
              />
            )}
          />
        </Col>
      </Row>
      <br />
      <br />
      <Row className={'footer-slide-pane'}>
        <Col sm="12">
          <Button.Ripple
            className="mr-1 mt-50 mb-50"
            id="saveOfficesButton"
            color="primary"
            /*type="submit"*/
            onClick={handleSubmit((values) => (isNew ? createOffice(values) : editOffice(values)))}
          >
            <FormattedMessage id="common.save" />
          </Button.Ripple>
          <Button.Ripple
            className="mt-50 mb-50"
            color="light"
            onClick={() => {
              closeSlidingPaneEditOffice();
              reset();
            }}
          >
            <FormattedMessage id="common.cancel" />
          </Button.Ripple>
        </Col>
      </Row>
    </SlidingPane>
  );
};

SlidingPaneEditOffice.defaultProps = {};

export default SlidingPaneEditOffice;
