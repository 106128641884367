import { useState } from 'react';
import { camelize, isNotNullOrUndefined, isNullOrUndefined } from 'utility/Utils';
import { ConfigActions } from 'utility/http/http-events';

function useWebtrackServiceOnAction<T = any, K = any>(
  service: (request: T, configActions: ConfigActions) => void,
  additionalActions?: {
    additionalOnError?: (error: any) => void;
    additionalOnSuccess?: (response: any) => void;
  }
) {
  const { additionalOnError, additionalOnSuccess } = additionalActions ?? {};

  const [isFetching, setIsFetching] = useState(false);
  const [response, setResponse] = useState<K | null>(null);
  const [error, setError] = useState<any>(null);

  const successAction = (res) => {
    const newRes = isNotNullOrUndefined(res) ? camelize(res) : null;
    setResponse(newRes);
    setIsFetching(false);
    if (additionalOnSuccess) {
      additionalOnSuccess(newRes);
    }
  };

  const errorAction = (error) => {
    setError(error);
    setIsFetching(false);
    if (additionalOnError) {
      additionalOnError(error);
    }
  };

  const handleAction = (values: T) => {
    setIsFetching(true);
    if (isNullOrUndefined(values)) errorAction(new Error('Undefined values'));
    else service(values, { successAction, errorAction });
  };

  return { isFetching, response, error, handleAction };
}

export { useWebtrackServiceOnAction };
