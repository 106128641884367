export const ORGANIZATION_CONTRACTS_FETCH_STARTED = 'ORGANIZATION_CONTRACTS_FETCH_STARTED';
export const ORGANIZATION_CONTRACTS_FETCH_COMPLETED = 'ORGANIZATION_CONTRACTS_FETCH_COMPLETED';
export const ORGANIZATION_CONTRACTS_FETCH_FAILED = 'ORGANIZATION_CONTRACTS_FETCH_FAILED';

export const ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED =
  'ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED';
export const ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED =
  'ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED';
export const ORGANIZATION_CONTRACTS_PARTIAL_FETCH_FAILED =
  'ORGANIZATION_CONTRACTS_PARTIAL_FETCH_FAILED';

export const ORGANIZATION_CONTRACT_ADD_STARTED = 'ORGANIZATION_CONTRACT_ADD_STARTED';
export const ORGANIZATION_CONTRACT_ADD_COMPLETED = 'ORGANIZATION_CONTRACT_ADD_COMPLETED';
export const ORGANIZATION_CONTRACT_ADD_FAILED = 'ORGANIZATION_CONTRACT_ADD_FAILED';

export const ORGANIZATION_CONTRACT_EDIT_STARTED = 'ORGANIZATION_CONTRACT_EDIT_STARTED';
export const ORGANIZATION_CONTRACT_EDIT_COMPLETED = 'ORGANIZATION_CONTRACT_EDIT_COMPLETED';
export const ORGANIZATION_CONTRACT_EDIT_FAILED = 'ORGANIZATION_CONTRACT_EDIT_FAILED';

export const ORGANIZATION_CONTRACT_REMOVE_STARTED = 'ORGANIZATION_CONTRACT_REMOVE_STARTED';
export const ORGANIZATION_CONTRACT_REMOVE_COMPLETED = 'ORGANIZATION_CONTRACT_REMOVE_COMPLETED';
export const ORGANIZATION_CONTRACT_REMOVE_FAILED = 'ORGANIZATION_CONTRACT_REMOVE_FAILED';

export const ORGANIZATION_CONTRACT_SELECTED = 'ORGANIZATION_CONTRACT_SELECTED';
export const ORGANIZATION_CONTRACTS_ALL_SELECTED = 'ORGANIZATION_CONTRACTS_ALL_SELECTED';
export const ORGANIZATION_CONTRACT_DESELECTED = 'ORGANIZATION_CONTRACT_DESELECTED';
export const ORGANIZATION_CONTRACTS_ALL_DESELECTED = 'ORGANIZATION_CONTRACTS_ALL_DESELECTED';
