import { HttpWebtrack } from 'utility/http';
import { SaveProviderCategoryDTO, DeleteProviderCategoryDTO } from '../types';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/catalogs';

export const saveProviderCategory = (request: SaveProviderCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.providerName}`,
    object: 'vehicleMaintenance.provider',
    action: `${request.providerId ? 'Edit' : 'Create'}`,
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/providers`, request);
};

export const deleteProviderCategory = (request: DeleteProviderCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.providerName}`,
    object: 'vehicleMaintenance.provider',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.get(`${baseUrl}/deleteProvider/${request.providerId}`);
};

export const getProviderList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/providers`);
};
