import * as types from '../../types/routeCheckpoints';

export const startFetchingRouteCheckpoints = (routeId) => ({
  type: types.ROUTE_CHECKPOINTS_FETCH_STARTED,
  payload: routeId
});
export const completeFetchingRouteCheckpoints = (entities, order) => ({
  type: types.ROUTE_CHECKPOINTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingRouteCheckpoints = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTE_CHECKPOINTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
export const clearRouteCheckpoints = () => ({
  type: types.ROUTE_CHECKPOINTS_CLEAR,
  payload: {}
});
