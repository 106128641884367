import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/route';

export const getRoutesListService = async (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'routes.route',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const deleteRouteService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.routeName}`,
    object: 'routes.route',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const deleteRoutesService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: '',
    object: 'routes.routes',
    action: 'Delete',
    genderType: 'PluralFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/deleteMultiple`, request);
};

export const saveRouteService = (request: any, configActions: ConfigActions, isNew: boolean) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.route.routeName}`,
    object: 'routes.route',
    action: isNew ? 'Create' : 'Edit',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const getAlternateRoutesService = async (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);

  return httpWebtrack.post(`${baseUrl}/getAlternateRoutes`, request);
};
