import { SaveWorkmanshipCategoryDTO } from 'views/screens/vehicleMaintenance/types';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/workmanship';

export const startFetchingWorkmanships = (filters = {}) => ({
  type: types.WORKMANSHIPS_FETCH_STARTED,
  payload: filters
});

export const completeFetchingWorkmanships = (entities, order) => ({
  type: types.WORKMANSHIPS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failFetchingWorkmanships = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.WORKMANSHIPS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingWorkmanships = (entityTypeId) => ({
  type: types.WORKMANSHIPS_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});

export const completePartialFetchingWorkmanships = (entities, order) => ({
  type: types.WORKMANSHIPS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failPartialFetchingWorkmanships = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKMANSHIPS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingWorkmanship = (workmanship: SaveWorkmanshipCategoryDTO) => ({
  type: types.WORKMANSHIP_ADD_STARTED,
  payload: workmanship
});

export const completeAddingWorkmanship = (id: string, oldId: string, workmanship) => ({
  type: types.WORKMANSHIP_ADD_COMPLETED,
  payload: { id, oldId, workmanship }
});

export const failAddingWorkmanship = (oldId, error) => ({
  type: types.WORKMANSHIP_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingWorkmanship = (workmanship: SaveWorkmanshipCategoryDTO) => ({
  type: types.WORKMANSHIP_EDIT_STARTED,
  payload: workmanship
});

export const completeEditingWorkmanship = (workmanship) => ({
  type: types.WORKMANSHIP_EDIT_COMPLETED,
  payload: workmanship
});

export const failEditingWorkmanship = (oldWorkmanship, error) => ({
  type: types.WORKMANSHIP_EDIT_FAILED,
  payload: {
    oldWorkmanship,
    error
  }
});

export const startRemovingWorkmanship = (request: {
  workmanshipId: number;
  description: string;
}) => ({
  type: types.WORKMANSHIP_REMOVE_STARTED,
  payload: {
    ...request
  }
});

export const completeRemovingWorkmanship = (id) => ({
  type: types.WORKMANSHIP_REMOVE_COMPLETED,
  payload: {
    id
  }
});

export const failRemovingWorkmanship = (id, error) => ({
  type: types.WORKMANSHIP_REMOVE_FAILED,
  payload: {
    id,
    error
  }
});
