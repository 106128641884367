import * as types from '../../../types/static-catalogs/location-classes';

export const startFetchingLocationClasses = (filters = {}) => ({
  type: types.LOCATION_CLASSES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingLocationClasses = (entities, order) => ({
  type: types.LOCATION_CLASSES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingLocationClasses = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.LOCATION_CLASSES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
