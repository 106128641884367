import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import {
  deleteProtocolService,
  getProtocolsListService,
  saveProtocolService
} from '../services/protocols';

/* -------------------------------------------------------------------------- */
/*                                    FETCH                                   */
/* -------------------------------------------------------------------------- */

function* fetchProtocolsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });
  getProtocolsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { protocols },
          result
        } = normalize(resultData, schemas.protocols);
        store.dispatch(
          actions.completeFetchingProtocols(protocols, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingProtocols(error));
      }
    }
  );
}

export function* watchFetchProtocolsList() {
  yield takeEvery(types.PROTOCOLS_FETCH_STARTED, fetchProtocolsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */

function* partialFetchProtocolsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getProtocolsListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { protocols },
          result
        } = normalize(resultData, schemas.protocols);
        store.dispatch(
          actions.completePartialFetchingProtocols(protocols, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingProtocols(error));
      }
    }
  );
}

export function* watchPartialFetchProtocolsList() {
  yield takeEvery(types.PROTOCOLS_PARTIAL_FETCH_STARTED, partialFetchProtocolsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */

function* addProtocol(action) {
  const protocol = action.payload;
  saveProtocolService(
    {
      ...protocol,
      protocolId: null
    },
    {
      successAction: (response) => {
        const addedProtocol = camelcaseKeys(response?.data[0]);
        console.log('addedProtocol', addedProtocol);
        store.dispatch(actions.completeAddingProtocol(protocol.protocolId, addedProtocol));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingProtocol(protocol.protocolId, error));
      }
    }
  );
}

export function* watchAddProtocolStarted() {
  yield takeEvery(types.PROTOCOLS_ADD_STARTED, addProtocol);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */

function* editProtocol(action) {
  const protocol = action.payload;
  const oldProtocol = yield select((state) => selectors.getProtocol(state, protocol.protocolId));
  saveProtocolService(
    {
      ...protocol
    },
    {
      successAction: (response) => {
        const editedProtocol = camelcaseKeys(response?.data[0]);
        store.dispatch(actions.completeEditingProtocol(editedProtocol));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingProtocol(oldProtocol, error));
      }
    }
  );
}

export function* watchEditProtocolStarted() {
  yield takeEvery(types.PROTOCOLS_EDIT_STARTED, editProtocol);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeProtocol(action) {
  const { protocolId } = action.payload;
  const protocol = yield select((state) => selectors.getProtocol(state, protocolId));
  deleteProtocolService(
    { ...protocol },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingProtocol(protocolId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingProtocol(protocolId, error));
      }
    }
  );
}

export function* watchRemoveProtocolStarted() {
  yield takeEvery(types.PROTOCOLS_REMOVE_STARTED, removeProtocol);
}
