export const ROUTES_EXECUTION_COMMENTS_FETCH_STARTED = 'ROUTES_EXECUTION_COMMENTS_FETCH_STARTED';
export const ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED =
  'ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED';
export const ROUTES_EXECUTION_COMMENTS_FETCH_FAILED = 'ROUTES_EXECUTION_COMMENTS_FETCH_FAILED';

export const ROUTES_EXECUTION_COMMENT_ADD_STARTED = 'ROUTES_EXECUTION_COMMENT_ADD_STARTED';
export const ROUTES_EXECUTION_COMMENT_ADD_COMPLETED = 'ROUTES_EXECUTION_COMMENT_ADD_COMPLETED';
export const ROUTES_EXECUTION_COMMENT_ADD_FAILED = 'ROUTES_EXECUTION_COMMENT_ADD_FAILED';

export const ROUTES_EXECUTION_COMMENT_EDIT_STARTED = 'ROUTES_EXECUTION_COMMENT_EDIT_STARTED';
export const ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED = 'ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED';
export const ROUTES_EXECUTION_COMMENT_EDIT_FAILED = 'ROUTES_EXECUTION_COMMENT_EDIT_FAILED';

export const ROUTES_EXECUTION_COMMENT_REMOVE_STARTED = 'ROUTES_EXECUTION_COMMENT_REMOVE_STARTED';
export const ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED =
  'ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED';
export const ROUTES_EXECUTION_COMMENT_REMOVE_FAILED = 'ROUTES_EXECUTION_COMMENT_REMOVE_FAILED';
