import jobs from 'views/screens/Jobs/sidebarLayout';
import safeDriver from 'views/screens/SafeDriver/sidebarLayout';

export default [
  {
    header: 'sidebar.apps',
    iconName: 'BarChart2',
    children: [...jobs, ...safeDriver] //Only to check permission in children inside
  },
  ...jobs,
  ...safeDriver
];
