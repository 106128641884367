import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as authSelectors from '../../../../redux/reducers/auth';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import * as entitySelectors from '../../Entities/reducers';
import { ENTITY_UNITS } from '../../Entities/constants';
import * as actions from '../actions';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as deviceMetricsSchemas from '../schemas/deviceMetrics';
import * as deviceActionsSchemas from '../schemas/deviceActions';
import * as groupsSchemas from '../schemas/groups';
import * as reportsSchemas from '../schemas/reports';
import * as imagesSchemas from '../schemas/images';
import * as sensorsSchemas from '../schemas/sensors';
import * as types from '../types';
import {
  getUnitsListService,
  getUnitsLiveStatusListService,
  getUnitInformationService,
  executeDeviceActionService,
  getUnitHistoricalPositionsReportService,
  acknowledgeUnitEventService,
  updateDeviceService,
  updateSimCardService
} from '../services';
import { camelize } from 'utility/Utils';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchUnitsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  console.log('operatorId', filterOperatorId, 'organizationId', filterOrganizationId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getUnitsListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { units },
          result //order
        } = normalize(resultData, schemas.units); //normalize data to byId and order
        store.dispatch(actions.completeFetchingUnits(units, result, operatorId, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingUnits(error));
      }
    }
  );
}

export function* watchFetchUnitsList() {
  yield takeEvery(types.UNITS_FETCH_STARTED, fetchUnitsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchUnitsList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getUnitsListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { units },
          result //order
        } = normalize(resultData, schemas.units); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingUnits(units, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingUnits(error));
      }
    }
  );
}

export function* watchPartialFetchUnitsList() {
  yield takeEvery(types.UNITS_PARTIAL_FETCH_STARTED, partialFetchUnitsList);
}

/* -------------------------------------------------------------------------- */
/*                              FETCH LIVE STATUS                             */
/* -------------------------------------------------------------------------- */
function* fetchUnitsLiveStatusList(action) {
  const operatorId = yield select(entitySelectors.getUserUpdateByEntityOperatorId, ENTITY_UNITS);
  const organizationId = yield select(
    entitySelectors.getUserUpdateByEntityOrganizationId,
    ENTITY_UNITS
  );
  const userFetchDate = yield select(selectors.getUnitLiveFetchDate, organizationId);

  getUnitsLiveStatusListService(
    { operatorId, organizationId, userFetchDate },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { units },
          result //order
        } = normalize(resultData, schemas.units); //normalize data to byId and order
        store.dispatch(actions.completeFetchingUnitsLiveStatus(units, result, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingUnitsLiveStatus(error));
      }
    }
  );
}

export function* watchFetchUnitsLiveStatusList() {
  yield takeEvery(types.UNITS_LIVE_STATUS_FETCH_STARTED, fetchUnitsLiveStatusList);
}

/* -------------------------------------------------------------------------- */
/*                              FETCH INFORMATION                             */
/* -------------------------------------------------------------------------- */
function* fetchUnitInformation(action) {
  const { unitId } = action.payload;

  getUnitInformationService(
    { unitId },
    {
      successAction: (response) => {
        const resultData = response.data;
        //Unit data Live Status
        const unitData = camelcaseKeys(resultData.unitData);
        //Unit device metrics
        const unitMetrics = resultData.unitMetrics.map((result) => camelcaseKeys(result));
        const {
          entities: { deviceMetrics: deviceMetricsEntities },
          result: deviceMetricsOrder //order
        } = normalize(unitMetrics, deviceMetricsSchemas.deviceMetrics); //normalize data to byId and order

        //Unit device actions
        const unitActions = resultData.unitActions.map((result) => camelcaseKeys(result));
        const {
          entities: { deviceActions: deviceActionsEntities },
          result: deviceActionsOrder //order
        } = normalize(unitActions, deviceActionsSchemas.deviceActions); //normalize data to byId and order

        //Unit groups
        const groupsData = resultData.groupsData.map((result) => camelcaseKeys(result));
        const {
          entities: { groups: groupsEntities },
          result: groupsOrder //order
        } = normalize(groupsData, groupsSchemas.groups); //normalize data to byId and order

        //Unit Reports
        const unitReports = resultData.unitReports.map((result) => camelcaseKeys(result));
        const {
          entities: { reports: reportsEntities },
          result: reportsOrder //order
        } = normalize(unitReports, reportsSchemas.reports); //normalize data to byId and order

        //Unit Images
        const unitImages = resultData.unitImages.map((result) => camelcaseKeys(result));
        const {
          entities: { images: imagesEntities },
          result: imagesOrder //order
        } = normalize(unitImages, imagesSchemas.images); //normalize data to byId and order

        //Unit Sensors
        const unitSensorMeasures = resultData.unitSensorMeasures.map((result) =>
          camelcaseKeys(result)
        );
        const {
          entities: { sensors: sensorsEntities },
          result: sensorsOrder //order
        } = normalize(unitSensorMeasures, sensorsSchemas.sensors); //normalize data to byId and order

        store.dispatch(
          actions.completeFetchingUnitInformation(
            unitId,
            unitData,
            deviceMetricsEntities,
            deviceMetricsOrder,
            deviceActionsEntities,
            deviceActionsOrder,
            groupsEntities,
            groupsOrder,
            reportsEntities,
            reportsOrder,
            imagesEntities,
            imagesOrder,
            sensorsEntities,
            sensorsOrder
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingUnitInformation(error));
      }
    }
  );
}

export function* watchFetchUnitInformationStarted() {
  yield takeEvery(types.UNIT_INFORMATION_FETCH_STARTED, fetchUnitInformation);
}

/* -------------------------------------------------------------------------- */
/*                              FETCH LIVE STATUS                             */
/* -------------------------------------------------------------------------- */
function* fetchUnitHistoricalPositionsReport(action) {
  const { unitId, reportType, startDate, endDate, idleSpeed } = action.payload;

  getUnitHistoricalPositionsReportService(
    { unitId, reportType, startDate, endDate, idleSpeed },
    {
      successAction: (response) => {
        const distance = response.data.distance;
        const parkedPositions = response.data?.parkedPositions?.map((result, i) => ({
          ...camelcaseKeys(result),
          id: `parkedPosition-${i}`
        }));
        const polyline = response.data?.polyline?.map((result) => camelcaseKeys(result));
        const positions = response.data?.positions?.map((result, i) => ({
          ...camelcaseKeys(result),
          id: `position-${i}`
        }));
        store.dispatch(
          actions.completeFetchingUnitPositions(
            unitId,
            distance,
            parkedPositions,
            polyline,
            positions
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingUnitPositions(error));
      }
    }
  );
}

export function* watchFetchUnitHistoricalPositionsStarted() {
  yield takeEvery(types.UNIT_POSITIONS_FETCH_STARTED, fetchUnitHistoricalPositionsReport);
}

/* -------------------------------------------------------------------------- */
/*                          EXECUTE DEVICE ACTION                             */
/* -------------------------------------------------------------------------- */
function* executeDeviceAction(action) {
  const { unitId, deviceActionId, parameters } = action.payload;

  executeDeviceActionService({
    unitId,
    deviceActionId,
    parameters
  });
}

export function* watchExecuteDeviceAction() {
  yield takeEvery(types.UNIT_EXECUTE_DEVICE_ACTION, executeDeviceAction);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addUnit(action) {
  const unit = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/save`, {
        method: 'POST',
        body: JSON.stringify({ ...unit, unitId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedUnit = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingUnit(unit.unitId, addedUnit));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'units.unit'
          }),
          name: unit.unitName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingUnit(unit.unitId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingUnit(unit.unitId, error));
  }
}

export function* watchAddUnitsStarted() {
  yield takeEvery(types.UNIT_ADD_STARTED, addUnit);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editUnit(action) {
  const unit = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/save`, {
        method: 'POST',
        body: JSON.stringify(unit),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedUnit = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingUnit(editedUnit));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'units.unit'
          }),
          name: unit.unitName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldUnit } = yield select((state) => selectors.getUnit(state.units, unit.unitId));
        yield put(actions.failEditingUnit(oldUnit, error));
        // alerts.showErrorAlertFailChanges({ error });
      }

      store.dispatch(actions.startFetchingUnits());
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldUnit } = yield select((state) => selectors.getUnit(state.units, unit.unitId));
    yield put(actions.failEditingUnit(oldUnit, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditUnitsStarted() {
  yield takeEvery(types.UNIT_EDIT_STARTED, editUnit);
}

/* -------------------------------------------------------------------------- */
/*                                UNIT ACKNOWLEDGE                            */
/* -------------------------------------------------------------------------- */
function* acknowledgeUnitEvent(action) {
  const { unitId } = action.payload;
  acknowledgeUnitEventService(
    { unitId },
    {
      successAction: (response) => {
        store.dispatch(actions.completeAcknowledgeUnitEvent(unitId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAcknowledgeUnitEvent(error));
      }
    }
  );
}

export function* watchAcknowledgeUnitEvent() {
  yield takeEvery(types.UNIT_ACKNOWLEDGE_STARTED, acknowledgeUnitEvent);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeUnit(action) {
  const unitBody = action.payload;
  const units = unitBody.units;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/delete`, {
        method: 'POST',
        body: JSON.stringify({ units }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingUnit(unitBody.units, unitBody.unitsName));
        alerts.showSuccessDisabledMessage({
          object: appIntl().formatMessage({
            id: unitBody.units.length > 1 ? 'units.units' : 'units.unit'
          }),
          multiple: unitBody.units.length > 1,
          altText: true,
          name: unitBody.unitsName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingUnit(unitBody.units, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingUnit(unitBody.units, error));
  }
}

export function* watchRemoveUnitStarted() {
  yield takeEvery(types.UNIT_REMOVE_STARTED, removeUnit);
}

/* -------------------------------------------------------------------------- */
/*                                ACTIVE UNITS                                */
/* -------------------------------------------------------------------------- */
function* activeUnits(action) {
  const units = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/updateUnitStatus`, {
        method: 'POST',
        body: JSON.stringify(units),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success /*&&
        jsonResult.data.length === unitGeofences.length*/
      ) {
        yield put(actions.completeActivingUnits(units));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'units.unitsWereSuccessfullyActived'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'units.unitsActivationIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failActivingUnits(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'units.unitsActivationIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failActivingUnits(error));
  }
}

export function* watchActiveUnits() {
  yield takeEvery(types.UNITS_ACTIVE_STARTED, activeUnits);
}

/* -------------------------------------------------------------------------- */
/*                                ASSIGN DRIVER                             */
/* -------------------------------------------------------------------------- */
function* assignDriver(action) {
  const units = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/assignDriver`, {
        method: 'POST',
        body: JSON.stringify(units),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeAssigningDriverUnits(units));
        units[0].driverName && units[0].driverName !== ''
          ? alerts.showSuccessAssignedMessage({
              object: appIntl().formatMessage({
                id: units.length > 1 ? 'drivers.drivers' : 'drivers.driver'
              }),
              name: units.length > 1 ? '' : units[0].driverName,
              plural: units.length > 1
            })
          : alerts.showSuccessUnassignedMessage({
              object: appIntl().formatMessage({
                id: 'drivers.driver'
              })
            });

        /*  store.dispatch(actions.startFetchingUnits()); */
        store.dispatch(actions.startFetchingUnitsLiveStatus());
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchAssignDriverUnitsStarted() {
  yield takeEvery(types.UNITS_ASSIGN_DRIVER_STARTED, assignDriver);
}

/* -------------------------------------------------------------------------- */
/*                             START UNIT DOWNTIME                            */
/* -------------------------------------------------------------------------- */
function* startUnitDowntime(action) {
  const { unitId, reasonId } = action.payload;
  try {
    //const { unit } = yield select((state) => selectors.getUnit(state.units, unitId));

    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/startDowntime`, {
        method: 'POST',
        body: JSON.stringify({ unitId, reasonId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedUnit = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeStartDowntimeUnit(
            unitId,
            editedUnit.reasonId,
            editedUnit.reasonDescription
          )
        );
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({ id: 'units.startDowntimeSuccess' })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertWarning({
          error: { errorMessage: appIntl().formatMessage({ id: 'units.startDowntimeFailure' }) }
        });
        yield put(actions.failStartDowntimeUnit(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    alerts.showErrorAlertWarning({
      error: { errorMessage: appIntl().formatMessage({ id: 'units.startDowntimeFailure' }) }
    });
    yield put(actions.failStartDowntimeUnit(error));
  }
}

export function* watchStartUnitDowntimeStarted() {
  yield takeEvery(types.UNIT_START_DOWNTIME_STARTED, startUnitDowntime);
}

/* -------------------------------------------------------------------------- */
/*                             END UNIT DOWNTIME                              */
/* -------------------------------------------------------------------------- */
function* endUnitDowntime(action) {
  const { unitId } = action.payload;
  try {
    //const { unit } = yield select((state) => selectors.getUnit(state.units, unitId));

    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/unit/endDowntime`, {
        method: 'POST',
        body: JSON.stringify({ unitId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedUnit = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeEndDowntimeUnit(unitId, editedUnit.mainStateId, editedUnit.mainStateDate)
        );
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({ id: 'units.endDowntimeSuccess' })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertWarning({
          error: { errorMessage: appIntl().formatMessage({ id: 'units.endDowntimeFailure' }) }
        });
        yield put(actions.failEndDowntimeUnit(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    alerts.showErrorAlertWarning({
      error: { errorMessage: appIntl().formatMessage({ id: 'units.endDowntimeFailure' }) }
    });
    yield put(actions.failEndDowntimeUnit(error));
  }
}

export function* watchEndUnitDowntimeStarted() {
  yield takeEvery(types.UNIT_END_DOWNTIME_STARTED, endUnitDowntime);
}

/* -------------------------------------------------------------------------- */
/*                            CREATE SHARED LINK                              */
/* -------------------------------------------------------------------------- */
function* createSharedLink(action) {
  const { unitId, unitName, emails, ttl } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/unit/shareUnitLocationLink`, {
        method: 'POST',
        body: JSON.stringify({ unitId, unitName, emails, ttl }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const sharedLink = jsonResult.data;
        yield put(actions.completeCreatingShareLink(sharedLink));
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({ id: 'common.sharedLinkCreatedSuccess' })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertWarning({
          error: {
            errorMessage: appIntl().formatMessage({ id: 'common.sharedLinkCreatingFailure' })
          }
        });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchCreateSharedLinkUnitStarted() {
  yield takeEvery(types.UNIT_CREATE_SHARE_LINK_STARTED, createSharedLink);
}

/* -------------------------------------------------------------------------- */
/*                            UPDATE DEVICE                                   */
/* -------------------------------------------------------------------------- */
function* updateDevice(action) {
  const requestData = action.payload;
  const oldUnitId = requestData.unitId;
  const additionalOnSuccess = requestData.additionalOnSuccess;
  updateDeviceService(requestData, {
    successAction(response) {
      const [data] = camelize(response?.data);
      store.dispatch(actions.completeUpdateDevice(data));
      if (typeof additionalOnSuccess === 'function') additionalOnSuccess();
    },
    errorAction(error) {
      store.dispatch(actions.failUpdateDevice(oldUnitId, error.toString()));
    }
  });
}

export function* watchUpdateDevice() {
  yield takeEvery(types.UNIT_UPDATE_DEVICE_STARTED, updateDevice);
}

/* -------------------------------------------------------------------------- */
/*                            UPDATE SIM                                      */
/* -------------------------------------------------------------------------- */
function* updateSim(action) {
  const requestData = action.payload;
  const oldUnitId = requestData.unitId;
  const additionalOnSuccess = requestData?.additionalOnSuccess;

  updateSimCardService(requestData, {
    successAction(response) {
      const [data] = camelize(response?.data);
      store.dispatch(actions.completeUpdateSim(data));
      if (typeof additionalOnSuccess === 'function') additionalOnSuccess();
    },
    errorAction(error) {
      store.dispatch(actions.failUpdateSim(oldUnitId, error?.toString() ?? ''));
    }
  });
}

export function* watchUpdateSim() {
  yield takeEvery(types.UNIT_UPDATE_SIM_STARTED, updateSim);
}
