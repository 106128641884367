import { List, Plus } from 'react-feather';
import Icon from '../../utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';
import operatorPaths from 'views/screens/Operators/paths';
import organizationPaths from 'views/screens/Organizations/paths';
import agentPaths from 'views/screens/Agents/paths';
import divisionPaths from 'views/screens/Divisions/paths';
import subdivisionPaths from 'views/screens/Subdivisions/paths';
import serviceClassPaths from 'views/screens/ServiceClasses/paths';
import operatorPermissions from 'views/screens/Operators/permissions';
import organizationPermissions from 'views/screens/Organizations/permissions';
import agentPermissions from 'views/screens/Agents/permissions';
import divisionPermissions from 'views/screens/Divisions/permissions';
import subdivisionPermissions from 'views/screens/Subdivisions/permissions';
import serviceClassPermissions from 'views/screens/ServiceClasses/permissions';

export default [
  {
    id: 'afiliations',
    title: 'sidebar.affiliations',
    icon: <Icon size={20} icon="Afiliaciones" />,
    permissions: [
      operatorPermissions.operatorList,
      organizationPermissions.organizationCatalog,
      divisionPermissions.listDivision,
      subdivisionPermissions.subdivisionCatalog,
      serviceClassPermissions.listServiceClass,
      agentPermissions.agentList
    ],
    iconName: 'Afiliaciones',
    children: [
      {
        id: 'operators',
        title: 'operators.operators',
        icon: <Icon size={20} icon="Operator" />,
        iconName: 'Operator',
        permissions: [operatorPermissions.operatorList],
        accessLevels: [9, 15],
        navLink: operatorPaths.listOperator,
        screenId: screens.OPERATOR
      },
      {
        id: 'agent',
        title: 'agents.agent',
        icon: <Icon size={20} icon="noun_Call-Center_2560173" />,
        permissions: [agentPermissions.agentList],
        accessLevels: [9, 13, 15],
        iconName: 'noun_Call-Center_2560173',
        navLink: agentPaths.listAgent,
        screenId: screens.AGENT
      },
      {
        id: 'organization',
        title: 'organizations.organization',
        icon: <Icon size={20} icon="Organization" />,
        permissions: [organizationPermissions.organizationCatalog],
        accessLevels: [9, 11, 13, 15],
        iconName: 'Organization',
        navLink: organizationPaths.listOrganization,
        screenId: screens.ORGANIZATION
      },
      {
        id: 'division',
        title: 'divisions.division',
        icon: <Icon size={20} icon="Division" />,
        permissions: [divisionPermissions.listDivision],
        accessLevels: [7, 9, 11, 13, 15],
        iconName: 'Division',
        navLink: divisionPaths.listDivision,
        screenId: screens.DIVISION
      },
      {
        id: 'subdivision',
        title: 'subdivisions.subdivision',
        icon: <Icon size={20} icon="Subdivision" />,
        permissions: [subdivisionPermissions.subdivisionCatalog],
        accessLevels: [4, 7, 9, 11, 13, 15],
        iconName: 'Subdivision',
        navLink: subdivisionPaths.listSubdivision,
        screenId: screens.SUBDIVISION
      },
      {
        id: 'service-class',
        title: 'serviceClasses.serviceClasses',
        icon: <Icon size={20} icon="noun_service_1318655" />,
        permissions: [serviceClassPermissions.listServiceClass],
        accessLevels: [9, 13, 15],
        iconName: 'noun_service_1318655',
        navLink: serviceClassPaths.listServiceClass,
        screenId: screens.SERVICE_CLASSES
      }
    ]
  }
];
