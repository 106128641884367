import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from 'views/screens/Operators/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.OPERATORS_FETCH_COMPLETED:
    case types.OPERATORS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((operatorId) => {
        newState[operatorId] = {
          isSelected: false,
          ...state[operatorId],
          ...entities[operatorId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.OPERATOR_ADD_COMPLETED: {
      const { oldId, operator } = action.payload;
      const newState = omit(state, oldId);
      newState[operator.operatorId] = {
        ...state[oldId],
        ...operator,
        isConfirmed: true
      };
      return newState;
    }

    case types.OPERATOR_EDIT_COMPLETED: {
      const operator = action.payload;
      return {
        ...state,
        [operator.operatorId]: {
          ...omit(state[operator.operatorId], ['oldOperator']),
          ...omit(operator, ['oldOperator']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.OPERATOR_ADD_STARTED: {
      const operator = action.payload;
      const newState = { ...state };
      newState[operator.operatorId] = {
        isSelected: false,
        ...operator,
        isConfirmed: false
      };
      return newState;
    }
    case types.OPERATOR_EDIT_STARTED: {
      const operator = action.payload;
      return {
        ...state,
        [operator.operatorId]: {
          ...state[operator.operatorId],
          oldOperator: state[operator.operatorId],
          ...operator,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.OPERATOR_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.OPERATOR_EDIT_FAILED: {
      const { oldOperator } = action.payload;
      return {
        ...state,
        [oldOperator.operatorId]: {
          ...omit(state[oldOperator.operatorId], ['oldOperator']),
          ...oldOperator,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.OPERATOR_REMOVE_COMPLETED: {
      const { operatorId } = action.payload;
      return omit(state, operatorId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.OPERATOR_SELECTED: {
      const operatorId = action.payload;
      const newState = {
        ...state,
        [operatorId]: {
          ...state[operatorId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.OPERATOR_DESELECTED: {
      const operatorId = action.payload;
      return {
        ...state,
        [operatorId]: {
          ...state[operatorId],
          isSelected: false
        }
      };
    }

    case types.OPERATORS_ALL_SELECTED: {
      const operatorIds = action.payload;
      const newState = { ...state };
      if (operatorIds.length == 0) {
        forEach(state, (operator: any, operatorId) => {
          newState[operatorId] = {
            ...operator,
            isSelected: true
          };
        });
      } else {
        operatorIds.forEach((operatorId) => {
          newState[operatorId] = {
            ...state[operatorId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.OPERATORS_ALL_DESELECTED: {
      const operatorIds = action.payload;
      const newState = { ...state };
      if (operatorIds.length == 0) {
        forEach(state, (operator: any, operatorId) => {
          newState[operatorId] = {
            ...operator,
            isSelected: false
          };
        });
      } else {
        operatorIds.forEach((operatorId) => {
          newState[operatorId] = {
            ...state[operatorId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.OPERATORS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.OPERATORS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.OPERATOR_ADD_COMPLETED: {
      const { oldId, operator } = action.payload;
      return state.map((operatorId) => (operatorId === oldId ? operator.operatorId : operatorId));
    }

    case types.OPERATOR_REMOVE_COMPLETED: {
      const { operatorId } = action.payload;
      return state.filter((operatorIdState) => operatorIdState !== operatorId);
    }

    //CASE ADD STARTED
    case types.OPERATOR_ADD_STARTED: {
      const operator = action.payload;
      return [...state, operator.operatorId];
    }

    //CASE ADD FAILED
    case types.OPERATOR_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((operatorIdState) => operatorIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.OPERATORS_FETCH_STARTED: {
      return true;
    }
    case types.OPERATORS_FETCH_COMPLETED:
    case types.OPERATORS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.OPERATORS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.OPERATORS_PARTIAL_FETCH_COMPLETED:
    case types.OPERATORS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.OPERATOR_ADD_STARTED: {
      return true;
    }
    case types.OPERATOR_ADD_COMPLETED:
    case types.OPERATOR_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.OPERATOR_EDIT_STARTED: {
      return true;
    }
    case types.OPERATOR_EDIT_COMPLETED:
    case types.OPERATOR_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditingStandardPrices = (state = false, action) => {
  switch (action.type) {
    case types.OPERATOR_EDIT_STANDARD_PRICES_STARTED: {
      return true;
    }
    case types.OPERATOR_EDIT_STANDARD_PRICES_COMPLETED:
    case types.OPERATOR_EDIT_STANDARD_PRICES_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.OPERATOR_REMOVE_STARTED: {
      return true;
    }
    case types.OPERATOR_REMOVE_COMPLETED:
    case types.OPERATOR_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.OPERATORS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATORS_FETCH_STARTED:
    case types.OPERATORS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.OPERATORS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATORS_PARTIAL_FETCH_STARTED:
    case types.OPERATORS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.OPERATOR_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATOR_ADD_STARTED:
    case types.OPERATOR_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.OPERATOR_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATOR_EDIT_STARTED:
    case types.OPERATOR_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditingStandardPrices = (state = null, action) => {
  switch (action.type) {
    case types.OPERATOR_EDIT_STANDARD_PRICES_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATOR_EDIT_STANDARD_PRICES_STARTED:
    case types.OPERATOR_EDIT_STANDARD_PRICES_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.OPERATOR_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.OPERATOR_REMOVE_STARTED:
    case types.OPERATOR_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const operators = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isEditingStandardPrices,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorEditingStandardPrices,
  errorRemoving
});

export default operators;

//Own state
export const getOwnState = (state) => state.operators;

//Information
export const getOperator = (state, operatorId) => getOwnState(state).byId[operatorId];
export const getOperatorsList = (state) =>
  getOwnState(state)
    .order.map((id) => getOperator(state, id))
    .filter((operator) => operator.operatorStatus > 0);
export const getSelectedOperators = (state) => {
  const selectedOperators = getOperatorsList(state).filter((operator) => operator.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedOperators.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedOperators.length > 0) return selectedOperators;
};

export const getSelectedOperator = (state) => {
  const selectedOperators = getOperatorsList(state).filter((operator) => operator.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedOperators.length === 1) return selectedOperators[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListOperators = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListOperators = (state) => getOwnState(state).isFetchingList;
export const isAddingOperator = (state) => getOwnState(state).isAdding;
export const isEditingOperator = (state) => getOwnState(state).isEditing;
export const isEditingOperatorStandardPrices = (state) =>
  getOwnState(state).isEditingStandardPrices;
export const isRemovingOperator = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListOperatorsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListOperatorsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingOperatorErrors = (state) => getOwnState(state).errorAdding;
export const getEditingOperatorErrors = (state) => getOwnState(state).errorEditing;
export const getEditingOperatorStandardPricesErrors = (state) =>
  getOwnState(state).errorEditingStandardPrices;
export const getRemovingOperatorErrors = (state) => getOwnState(state).errorRemoving;
