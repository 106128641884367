/* -------------------------------------------------------------------------- */
/*                              Schema Currencies                             */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const locationClass = new schema.Entity(
  'locationClasses',
  {},
  { idAttribute: 'locationClassId' }
);
export const locationClasses = new schema.Array(locationClass);
