// @ts-nocheck
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet.icon.glyph';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { getRouteBounds } from 'views/screens/Routes/utils';
import wkx from 'wkx';

export interface MapObjectsBoundsOptions {
  isSelectedSomething?: any;
  setMapPositionToGeofenceBounds?: boolean;
  setMapPositionToLocationBounds?: boolean;
  setMapPositionToUnitBounds?: boolean;
  setMapPositionToUnitPositionsBounds?: boolean;
  setMapPositionToRouteBounds?: boolean;
  geofences?: any[];
  locations?: any[];
  units?: any[];
  routes?: any[];
  rerenderMapObjectBounds?: date;
  paddingBottomRight?: any[];
  paddingTopLeft?: any[];
}

const MapObjectsBounds = (props: MapObjectsBoundsOptions) => {
  const context = useLeafletContext();

  const {
    isSelectedSomething,
    setMapPositionToGeofenceBounds = false,
    setMapPositionToLocationBounds = false,
    setMapPositionToUnitBounds = false,
    setMapPositionToUnitPositionsBounds = false,
    setMapPositionToRouteBounds = false,
    geofences = [],
    locations = [],
    units = [],
    routes = [],
    rerenderMapObjectBounds,
    paddingBottomRight,
    paddingTopLeft
  } = props;
  const store = useSelector((state) => state);
  const map = useMap();

  const zoomMapToObjects = () => {
    var bounds = [];
    if (setMapPositionToLocationBounds) {
      locations
        .filter((location) => location?.latitude != null && location?.longitude != null)
        .map((location) => {
          bounds = [...bounds, L.latLng(location.latitude, location.longitude)];
        });
    }
    if (setMapPositionToGeofenceBounds) {
      geofences.map((geofence) => {
        let geofenceGeoJSON;
        geofenceGeoJSON = WKBtoGeoJSON(geofence.geofenceWktString);
        if (geofence.shape == 'C') {
          bounds = [
            ...bounds,
            L.latLng(geofenceGeoJSON.coordinates[1], geofenceGeoJSON.coordinates[0])
          ];
        } else if (geofence.shape == 'R') {
          bounds = [
            ...bounds,
            ...geofenceGeoJSON.coordinates[0].map((coords) => L.latLng([coords[1], coords[0]]))
          ];
        } else if (geofence.shape == 'P') {
          bounds = [
            ...bounds,
            ...(geofenceGeoJSON.type == 'LineString'
              ? geofenceGeoJSON.coordinates.map((coords) => L.latLng([coords[1], coords[0]]))
              : geofenceGeoJSON.coordinates[0].map((coords) => L.latLng([coords[1], coords[0]])))
          ];
        } else if (geofence.shape == 'U') {
          bounds = [
            ...bounds,
            _.flatten(
              geofenceGeoJSON.coordinates.map((coordinate) =>
                coordinate.map((coords) =>
                  coords[1] && coords[0] ? L.latLng([coords[1], coords[0]]) : null
                )
              )
            )
          ];
        }
      });
    }
    if (setMapPositionToUnitBounds) {
      units
        .filter((unit) => unit?.latitude != null && unit?.longitude != null)
        .map((unit) => {
          bounds = [...bounds, L.latLng(unit.latitude, unit.longitude)];
        });
    }
    if (setMapPositionToUnitPositionsBounds) {
      units
        .filter((unit) => unit?.historicalPositions?.polyline)
        .map((unit) => {
          unit.historicalPositions.polyline.map((position) => {
            bounds = [...bounds, L.latLng(position.latitude, position.longitude)];
          });
        });
    }
    if (setMapPositionToRouteBounds) {
      routes.map((route) => {
        bounds = [...bounds, ...getRouteBounds(route)];
      });
    }
    if (
      (setMapPositionToGeofenceBounds ||
        setMapPositionToLocationBounds ||
        setMapPositionToUnitBounds ||
        setMapPositionToUnitPositionsBounds ||
        setMapPositionToRouteBounds) &&
      bounds.length > 0
    ) {
      map.flyToBounds(
        L.latLngBounds(bounds.filter((bound) => bound != null && bound instanceof L.LatLng)),
        {
          paddingBottomRight: paddingBottomRight ?? undefined,
          paddingTopLeft: paddingTopLeft ?? undefined,
          maxZoom: 15,
          animate: 15 !== map.getZoom()
        }
      );
    }
  };

  const WKBtoGeoJSON = (wkt) => {
    // let wkbBuffer = new Buffer(hex);
    let geometry = wkx.Geometry.parse(wkt);

    return geometry.toGeoJSON();
  };
  useDeepCompareEffect(() => {
    if (
      isSelectedSomething == null ||
      Object.values(isSelectedSomething).filter((s) => s).length === 0
    )
      zoomMapToObjects();
  }, [
    isSelectedSomething,
    geofences,
    locations,
    setMapPositionToGeofenceBounds,
    rerenderMapObjectBounds
  ]);
  return null;
};

MapObjectsBounds.defaultProps = {
  setMapPositionToGeofenceBounds: false,
  setMapPositionToLocationBounds: false,
  geofences: [],
  locations: []
};

export default MapObjectsBounds;
