import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchQuotationsList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/quotation/get`, {
        method: 'POST',
        body: JSON.stringify({
          operatorId,
          organizationId:
            filterOrganizationId === null || filterOrganizationId === undefined
              ? filterOrganizationId
              : organizationId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { quotations },
          result //order
        } = normalize(resultData, schemas.quotations); //normalize data to byId and order
        yield put(
          actions.completeFetchingQuotations(quotations ?? [], result, operatorId, organizationId)
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingQuotations(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingQuotations(error));
  }
}

export function* watchFetchQuotationsList() {
  yield takeEvery(types.QUOTATIONS_FETCH_STARTED, fetchQuotationsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchQuotationsList(action) {
  try {
    const {
      entityTypeId,
      operatorId: filterOperatorId,
      organizationId: filterOrganizationId
    } = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const { operatorId, organizationId } = yield select(
      authSelectors.getAuthUserMembershipFilters,
      {
        filterOperatorId,
        filterOrganizationId
      }
    );

    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/quotation/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date, operatorId, organizationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { quotations },
          result //order
        } = normalize(resultData, schemas.quotations); //normalize data to byId and order
        yield put(
          actions.completePartialFetchingQuotations(quotations, result, operatorId, organizationId)
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingQuotations(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingQuotations(error));
  }
}

export function* watchPartialFetchQuotationsList() {
  yield takeEvery(types.QUOTATIONS_PARTIAL_FETCH_STARTED, partialFetchQuotationsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addQuotation(action) {
  const { quotation } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/quotation/save`, {
        method: 'POST',
        body: JSON.stringify({ ...quotation, quotationId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedQuotation = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingQuotation(quotation.quotationId, addedQuotation));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: quotation.quotationNumber,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingQuotation(quotation.quotationId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingQuotation(quotation.quotationId, error));
  }
}

export function* watchAddQuotationsStarted() {
  yield takeEvery(types.QUOTATIONS_ADD_STARTED, addQuotation);
}

/* -------------------------------------------------------------------------- */
/*                                    COPY                                    */
/* -------------------------------------------------------------------------- */
function* copyQuotation(action) {
  const { quotation } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/quotation/copy`, {
        method: 'POST',
        body: JSON.stringify({ ...quotation, fromQuotationId: quotation.quotationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const copiedQuotation = camelcaseKeys(jsonResult.data);
        yield put(actions.completeCopyingQuotation(copiedQuotation));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({ id: 'quotations.copyingQuotationSuccess' })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({ id: 'quotations.copyingQuotationFailure' }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCopyingQuotation(quotation.quotationId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({ id: 'quotations.copyingQuotationFailure' }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCopyingQuotation(quotation.quotationId, error));
  }
}

export function* watchCopyQuotationsStarted() {
  yield takeEvery(types.QUOTATION_COPY_STARTED, copyQuotation);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editQuotation(action) {
  const quotation = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/save`, {
        method: 'POST',
        body: JSON.stringify(quotation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedQuotation = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingQuotation(editedQuotation));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: editedQuotation.quotationNumber,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldQuotation } = yield select((state) =>
          selectors.getQuotation(state.quotations, quotation.quotationId)
        );
        yield put(actions.failEditingQuotation(oldQuotation, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const oldQuotation = yield select((state) =>
      selectors.getQuotation(state, quotation.quotationId)
    );
    // console.log('old', yield select((state) => selectors.getDivision(state, division.divisionId)));
    yield put(actions.failEditingQuotation(oldQuotation, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditQuotationsStarted() {
  yield takeEvery(types.QUOTATIONS_EDIT_STARTED, editQuotation);
}

/* -------------------------------------------------------------------------- */
/*                            APPROVE DENY MANAGEMENT                          */
/* -------------------------------------------------------------------------- */
function* approveDenyQuotationManagement(action) {
  const quotation = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/approveManagement`, {
        method: 'POST',
        body: JSON.stringify(quotation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeApprovingDenyingQuotationManagement(quotation));
        alerts.showSuccessApprovedDeniedMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: quotation.quotationNumber,
          altText: true,
          approve: quotation.approve
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id:
              quotation.approve === 1
                ? 'quotations.approvingQuotationManagementFailure'
                : 'quotations.denyingQuotationManagementFailure'
          }),
          errorNumber: jsonResult.number
        };
        yield put(actions.failApprovingDenyingQuotationManagement(error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id:
          quotation.approve === 1
            ? 'quotations.approvingQuotationManagementFailure'
            : 'quotations.denyingQuotationManagementFailure'
      }),
      errorNumber: -1
    };

    yield put(actions.failApprovingDenyingQuotationManagement(error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchApproveDenyQuotationManagementStarted() {
  yield takeEvery(types.QUOTATION_APPROVE_DENY_MANAGEMENT_STARTED, approveDenyQuotationManagement);
}

/* -------------------------------------------------------------------------- */
/*                              ACCEPT DENY CUSTOMER                          */
/* -------------------------------------------------------------------------- */
function* acceptDenyQuotationCustomer(action) {
  const quotation = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/acceptCustomer`, {
        method: 'POST',
        body: JSON.stringify(quotation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeAcceptingDenyingQuotationCustomer(quotation));
        alerts.showSuccessAcceptedDeniedMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: quotation.quotationNumber,
          altText: true,
          accept: quotation.accept
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id:
              quotation.accept === 1
                ? 'quotations.acceptingQuotationCustomerFailure'
                : 'quotations.denyingQuotationCustomerFailure'
          }),
          errorNumber: jsonResult.number
        };
        yield put(actions.failAcceptingDenyingQuotationCustomer(error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id:
          quotation.accept === 1
            ? 'quotations.acceptingQuotationCustomerFailure'
            : 'quotations.denyingQuotationCustomerFailure'
      }),
      errorNumber: -1
    };

    yield put(actions.failAcceptingDenyingQuotationCustomer(error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchAcceptDenyQuotationCustomerStarted() {
  yield takeEvery(types.QUOTATION_ACCEPT_DENY_CUSTOMER_STARTED, acceptDenyQuotationCustomer);
}

/* -------------------------------------------------------------------------- */
/*                                    SCHEDULE                                */
/* -------------------------------------------------------------------------- */
function* scheduleQuotation(action) {
  const quotation = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/scheduleQuotation`, {
        method: 'POST',
        body: JSON.stringify(quotation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeSchedulingQuotation(quotation));
        alerts.showSuccessScheduledMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: quotation.quotationName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({ id: 'quotations.schedulingQuotationFailure' }),
          errorNumber: jsonResult.number
        };
        yield put(actions.failSchedulingQuotation(error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({ id: 'quotations.schedulingQuotationFailure' }),
      errorNumber: -1
    };

    yield put(actions.failSchedulingQuotation(error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchScheduleQuotationsStarted() {
  yield takeEvery(types.QUOTATIONS_SCHEDULE_STARTED, scheduleQuotation);
}

/* -------------------------------------------------------------------------- */
/*                                CHECK SCHEDULE                              */
/* -------------------------------------------------------------------------- */
function* checkQuotationScheduling(action) {
  const { quotationId, quotationLink } = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/checkScheduling`, {
        method: 'POST',
        body: JSON.stringify({ quotationId, quotationLink }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const quotationScheduled = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeCheckingQuotationScheduling({
            quotationId,
            quotationIdDB: quotationScheduled?.quotationId,
            isScheduled: quotationScheduled?.isScheduled,
            quotationStatus: quotationScheduled?.quotationStatus,
            isTimesup: quotationScheduled?.isTimesup
          })
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'quotations.checkingQuotationSchedulingFailure'
          }),
          errorNumber: jsonResult.number
        };
        yield put(actions.failCheckingQuotationScheduling(error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'quotations.checkingQuotationSchedulingFailure'
      }),
      errorNumber: -1
    };

    yield put(actions.failCheckingQuotationScheduling(error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchCheckQuotationSchedulingStarted() {
  yield takeEvery(types.QUOTATIONS_CHECK_SCHEDULING_STARTED, checkQuotationScheduling);
}

/* -------------------------------------------------------------------------- */
/*                                 SET TIMESUP                                */
/* -------------------------------------------------------------------------- */
function* setTimesupQuotation(action) {
  const { quotation, showAlerts } = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/quotation/setTimesup`, {
        method: 'POST',
        body: JSON.stringify(quotation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeSettingTimesupQuotation(quotation));
        if (showAlerts)
          alerts.showSuccessMessage({
            success: appIntl().formatMessage({ id: 'common.changesSuccess' })
          });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({ id: 'common.changesFailure' }),
          errorNumber: jsonResult.number
        };
        yield put(actions.failSettingTimesupQuotation(error));
        if (showAlerts) alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({ id: 'common.changesFailure' }),
      errorNumber: -1
    };

    yield put(actions.failSettingTimesupQuotation(error));
    if (showAlerts) alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchSetTimesupQuotationStarted() {
  yield takeEvery(types.QUOTATIONS_SET_TIMESUP_STARTED, setTimesupQuotation);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeQuotation(action) {
  const { quotationId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedQuotation = yield select((state) => selectors.getQuotation(state, quotationId));
      const response = yield call(fetch, `${API_BASE_URL}/quotation/delete`, {
        method: 'POST',
        body: JSON.stringify({ quotationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingQuotation(quotationId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'quotations.quotation'
          }),
          name: deletedQuotation.quotationNumber,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingQuotation(quotationId, error));
      }
    }
  } catch (err) {
    // console.log('err', err);
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingQuotation(quotationId, error));
  }
}

export function* watchRemoveQuotationStarted() {
  yield takeEvery(types.QUOTATIONS_REMOVE_STARTED, removeQuotation);
}
