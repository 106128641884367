import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/catalogs/providers';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/providers';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/catalogs/providers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteProviderCategory,
  getProviderList,
  saveProviderCategory
} from 'views/screens/vehicleMaintenance/services/providersService';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteProviderCategoryDTO,
  SaveProviderCategoryDTO
} from 'views/screens/vehicleMaintenance/types';
/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchProvidersList(action) {
  try {
    const jsonResult = yield call(getProviderList);
    const resultData = jsonResult.map((result) => camelcaseKeys(result));
    const {
      entities: { providers },
      result //order
    } = normalize(resultData, schemas.providers); //normalize data to byId and order
    yield put(actions.completeFetchingProviders(providers, result));
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingProviders(error));
  }
}

export function* watchFetchProvidersList() {
  yield takeEvery(types.PROVIDERS_FETCH_STARTED2, fetchProvidersList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchProvidersList(action) {}

export function* watchPartialFetchProvidersList() {
  yield takeEvery(types.PROVIDERS_PARTIAL_FETCH_STARTED2, partialFetchProvidersList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addProvider(action: PayloadAction<SaveProviderCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveProviderCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingProvider(addedData.providerId, request.tempId, {
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failAddingProvider(request.tempId, e));
  }
}

export function* watchAddProvidersStarted() {
  yield takeEvery(types.PROVIDER_ADD_STARTED, addProvider);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editProvider(action: PayloadAction<SaveProviderCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveProviderCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingProvider({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failEditingProvider(request.providerId, e));
  }
}

export function* watchEditProvidersStarted() {
  yield takeEvery(types.PROVIDER_EDIT_STARTED, editProvider);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeProvider(action: PayloadAction<DeleteProviderCategoryDTO>) {
  const request = action.payload;
  try {
    yield call(deleteProviderCategory, request);
    yield put(actions.completeRemovingProvider(request.providerId));
  } catch (e) {
    yield put(actions.failRemovingProvider(request.providerId, e));
  }
}

export function* watchRemoveProviderStarted() {
  yield takeEvery(types.PROVIDER_REMOVE_STARTED, removeProvider);
}
