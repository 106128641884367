/* -------------------------------------------------------------------------- */
/*                        Componente DateInput                           */
/* -------------------------------------------------------------------------- */
// Este componente contiene un date input genérico que se utiliza en muchas pantallas de la aplicación.

import { english } from 'flatpickr/dist/l10n/default';
import { Spanish } from 'flatpickr/dist/l10n/es';
import moment from 'moment';
import { useRef, useState } from 'react';
import { Calendar, XSquare } from 'react-feather';
import Flatpickr from 'react-flatpickr';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Col,
  FormGroup,
  Label,
  PopoverBody,
  Row,
  UncontrolledPopover,
  UncontrolledTooltip,
  InputGroupText,
  InputGroup
} from 'reactstrap';

interface Props {
  field: any;
  fieldstate: any;
  errorMessage?: boolean;
  required?: boolean;
  maxDate?: Date | string;
  minDate?: Date | string;
  popoverBody?: any;
  isRange?: boolean;
  showTime?: boolean;
  enableTime?: boolean;
  onChange?: (date?: string | [string, string] | null) => void;
  label?: any;
  allowNulls?: boolean;
  disabled?: boolean;
  defaultHour?: number;
  defaultMinute?: number;
  additionalTimeProps?: any; // to pass additional props to the time picker as minHour/minMinute
}

const DateInput = (props: Props) => {
  const {
    field,
    fieldstate,
    errorMessage,
    required,
    allowNulls = false,
    maxDate,
    popoverBody,
    onChange,
    minDate,
    isRange,
    showTime,
    enableTime,
    label,
    disabled,
    defaultHour = 11,
    defaultMinute = 11,
    additionalTimeProps = {}
  } = props;
  const intl = useIntl();
  const startRef = useRef<any>({});
  const startButtonRef = useRef<any>({});
  const endRef = useRef<any>({});
  const endButtonRef = useRef<any>({});
  const ref = useRef<any>({});
  const [prevValue, setPrevValue] = useState<any[]>([]);
  const [prevValueRange, setPrevValueRange] = useState<any[]>([]);

  return (
    <FormGroup>
      <Label for={field.name}>
        {label || <FormattedMessage id={isRange ? 'common.dates' : 'common.date'} />}
        {required && <span className="required">&nbsp;*</span>}
      </Label>
      <Row>
        <Col sm={allowNulls ? '10' : undefined}>
          <InputGroup>
            {isRange && enableTime && (
              <>
                <div ref={startButtonRef} className={'input-group-prepend'}>
                  <InputGroupText
                    id={'startDateButton'}
                    style={{ cursor: 'pointer' }}
                    className={'m-0'}
                    onClick={() => {
                      if (field.value?.length == 2)
                        startRef.current.flatpickr.open(undefined, startButtonRef.current);
                      else ref.current.flatpickr.open();
                    }}
                  >
                    <Calendar size={15} />
                  </InputGroupText>
                </div>
                <Flatpickr
                  ref={startRef}
                  value={field.value?.length == 2 ? field.value[0] : undefined}
                  onChange={(value) => {
                    field.onChange([moment(value[0]).format('YYYY-MM-DD HH:mm'), field.value[1]]);
                  }}
                  options={{
                    locale: intl.locale === 'es' ? Spanish : english,
                    dateFormat: showTime ? 'Y-m-d H:i' : 'Y-m-d',
                    minDate: minDate,
                    maxDate: field.value?.length == 2 ? field.value[1] : undefined,
                    enableTime: true,
                    mode: 'single',
                    defaultHour: defaultHour,
                    defaultMinute: defaultMinute,
                    ...additionalTimeProps
                  }}
                  className={'d-none'}
                />
                <UncontrolledTooltip trigger="hover" placement="top" target={'startDateButton'}>
                  {intl.formatMessage({ id: 'common.startDate' })}
                </UncontrolledTooltip>
              </>
            )}
            <Flatpickr
              ref={ref}
              disabled={disabled}
              id={field.name}
              className={
                required && fieldstate.error != null
                  ? 'form-control flatpickr-input has-error'
                  : 'form-control flatpickr-input'
              }
              options={{
                locale: intl.locale === 'es' ? Spanish : english,
                dateFormat: showTime ? 'Y-m-d H:i' : 'Y-m-d',
                minDate: minDate,
                maxDate: maxDate,
                enableTime: enableTime,
                mode: isRange ? 'range' : 'single',
                defaultHour: defaultHour,
                defaultMinute: defaultMinute,
                ...additionalTimeProps
              }}
              value={field.value}
              onChange={(value, _, config) => {
                if (!config.isOpen && isRange && value.length === 1) {
                  ref.current.flatpickr.selectedDates = [];
                  ref.current.flatpickr.setDate(ref.current.flatpickr.selectedDates);
                } else {
                  if (value.length === 1 && !isRange) {
                    const date = (
                      enableTime ? moment(value[0]) : moment(value[0]).startOf('day')
                    ).format(enableTime ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD');
                    field.onChange(date);
                    if (onChange) onChange(date);
                  } else if (value.length === 1 && isRange && enableTime) {
                    if (ref.current?.flatpickr) {
                      if (prevValue.length < value.length) {
                        ref.current.flatpickr.selectedDates[0].setHours(0, 0, 0, 0);
                        ref.current.flatpickr.setDate(ref.current.flatpickr.selectedDates);
                      } else if (prevValue.length > value.length) {
                        ref.current.flatpickr.selectedDates[0].setHours(
                          prevValue[0].getHours(),
                          prevValue[0].getMinutes(),
                          0,
                          0
                        );
                        ref.current.flatpickr.setDate(ref.current.flatpickr.selectedDates);
                      }
                    }
                    setPrevValue([...value]);
                  } else if (value.length === 2 && isRange) {
                    if (ref.current?.flatpickr && enableTime) {
                      if (prevValue.length > prevValueRange.length) {
                        ref.current.flatpickr.selectedDates[1].setHours(23, 59, 0, 0);
                        ref.current.flatpickr.setDate(ref.current.flatpickr.selectedDates);
                      } else if (prevValue.length < prevValueRange.length) {
                        ref.current.flatpickr.selectedDates[1].setHours(
                          prevValueRange[1].getHours(),
                          prevValueRange[1].getMinutes(),
                          0,
                          0
                        );
                        ref.current.flatpickr.setDate(ref.current.flatpickr.selectedDates);
                      }
                    }
                    field.onChange(
                      value.map((date, i) => {
                        if (!enableTime) {
                          if (i === 0)
                            return moment(date).startOf('day').format('YYYY-MM-DD HH:mm');
                          return moment(date).endOf('day').format('YYYY-MM-DD HH:mm');
                        } else return moment(date).format('YYYY-MM-DD HH:mm');
                      })
                    );
                    setPrevValueRange(value);
                    setPrevValue([...value]);
                  }
                }
              }}
              onClose={(value) => {
                if (!isRange && value.length < 1) field.onChange(null);
                if (isRange && value.length < 2) {
                  field.onChange(null);
                  setPrevValue([]);
                  setPrevValueRange([]);
                }
              }}
            />
            {isRange && enableTime && (
              <UncontrolledTooltip trigger="hover" placement="top" target={field.name}>
                {intl.formatMessage({ id: 'common.dateRange' })}
                <br />
                {field.value?.length == 2 ? field.value.join(' - ') : undefined}
              </UncontrolledTooltip>
            )}
            {popoverBody && (
              <UncontrolledPopover trigger="hover" placement="top" target={field.name}>
                <PopoverBody>{popoverBody}</PopoverBody>
              </UncontrolledPopover>
            )}
            {isRange && enableTime && (
              <>
                <Flatpickr
                  ref={endRef}
                  value={field.value?.length == 2 ? field.value[1] : undefined}
                  onChange={(value) => {
                    field.onChange([field.value[0], moment(value[0]).format('YYYY-MM-DD HH:mm')]);
                  }}
                  options={{
                    locale: intl.locale === 'es' ? Spanish : english,
                    dateFormat: showTime ? 'Y-m-d H:i' : 'Y-m-d',
                    minDate: field.value?.length == 2 ? field.value[0] : undefined,
                    maxDate: maxDate,
                    enableTime: true,
                    mode: 'single',
                    defaultHour: defaultHour,
                    defaultMinute: defaultMinute,
                    ...additionalTimeProps
                  }}
                  className={'d-none'}
                />
                <div ref={endButtonRef} className={'input-group-append'}>
                  <InputGroupText
                    id={'endDateButton'}
                    style={{ cursor: 'pointer' }}
                    className={'m-0'}
                    onClick={() => {
                      if (field.value?.length == 2)
                        endRef.current.flatpickr.open(undefined, endButtonRef.current);
                      else ref.current.flatpickr.open();
                    }}
                  >
                    <Calendar size={15} />
                  </InputGroupText>
                </div>
                <UncontrolledTooltip trigger="hover" placement="top" target={'endDateButton'}>
                  {intl.formatMessage({ id: 'common.endDate' })}
                </UncontrolledTooltip>
              </>
            )}
          </InputGroup>
        </Col>
        {allowNulls && (
          <Col sm="1" style={{ cursor: 'pointer' }} className="mt-50">
            <XSquare
              onClick={() => {
                field.onChange(null);
                if (onChange) onChange(null);
              }}
            />
          </Col>
        )}
      </Row>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

DateInput.defaultProps = {
  required: false,
  enableTime: false,
  showTime: true,
  disabled: false
};

export default DateInput;
