import { HttpWebtrack } from 'utility/http';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/unit';

interface UnitImage {
  unitId: number;
  imageId: number;
  imageTypeId: number;
  imageName: string;
  imageUrl: string;
  isDefault: boolean;
}

interface UnitDeleteRequest {
  unitId: number;
  imageId: number;
  imageName: string;
}

export const saveUnitImage = (request: UnitImage) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.imageName}`,
    object: 'units.unitImage',
    action: request.imageId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/saveUnitImage`, request);
};

export const deleteUnitImage = (request: UnitDeleteRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.imageName}`,
    object: 'units.unitImage',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/deleteUnitImage`, request);
};
