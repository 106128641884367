import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/vehicle';

interface VehicleFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
  unitId?: number;
  vehicleId?: number;
}

interface ImportVehiclesFilters {
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  Vehicles?: String;
}

export const getVehiclesListService = (request: VehicleFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.vehicle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getFuelTanksAndSealTypes = (request: VehicleFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.vehicle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getFuelTanksAndSealTypes`, request);
};

export const getVehiclesListDropdownService = (
  request: VehicleFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.vehicle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/dropdown`, request);
};

export const getBrandsAndModelsService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.vehicle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/brandsAndModels`, request);
};

export const saveBrandService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.maker',
    action: request?.brandId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveBrand`, request);
};

export const saveModelService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.model',
    action: request?.modelId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveModel`, request);
};

export const deleteBrandService = (request: any, configActions?: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.maker',
    action: 'Delete',
    genderType: 'SingularFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/deleteBrand`, request);
};

export const deleteModelService = (request: any, configActions?: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.model',
    action: 'Delete',
    genderType: 'SingularFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/deleteModel`, request);
};

export const importVehiclesService = async (
  request: ImportVehiclesFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'vehicles.vehicles',
    action: 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importVehicles`, request);
};
