import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchOrganizationContractsList(action) {
  const { operatorId, organizationId } = action.payload;

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/organizationContract/get`, {
        method: 'POST',
        body: JSON.stringify({
          operatorId,
          organizationId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { organizationContracts },
          result //order
        } = normalize(resultData, schemas.organizationContracts); //normalize data to byId and order
        yield put(
          actions.completeFetchingOrganizationContracts(
            organizationContracts ?? [],
            result,
            operatorId,
            organizationId
          )
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingOrganizationContracts(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString() ?? '',
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingOrganizationContracts(error));
  }
}

export function* watchFetchOrganizationContractsList() {
  yield takeEvery(types.ORGANIZATION_CONTRACTS_FETCH_STARTED, fetchOrganizationContractsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchOrganizationContractsList(action) {
  try {
    const {
      entityTypeId,
      operatorId: filterOperatorId,
      organizationId: filterOrganizationId
    } = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const { operatorId, organizationId } = yield select(
      authSelectors.getAuthUserMembershipFilters,
      {
        filterOperatorId,
        filterOrganizationId
      }
    );

    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/organizationContract/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date, operatorId, organizationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { organizationContracts },
          result //order
        } = normalize(resultData, schemas.organizationContracts); //normalize data to byId and order
        yield put(
          actions.completePartialFetchingOrganizationContracts(
            organizationContracts,
            result,
            operatorId,
            organizationId
          )
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingOrganizationContracts(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString() ?? '',
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingOrganizationContracts(error));
  }
}

export function* watchPartialFetchOrganizationContractsList() {
  yield takeEvery(
    types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED,
    partialFetchOrganizationContractsList
  );
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addOrganizationContract(action) {
  const organizationContract = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/organizationContract/save`, {
        method: 'POST',
        body: JSON.stringify({ ...organizationContract, contractId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedOrganizationContract = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeAddingOrganizationContract(
            organizationContract.contractId,
            addedOrganizationContract
          )
        );

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'contracts.contract'
          }),
          name: organizationContract.contractNumber
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingOrganizationContract(organizationContract.contractId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingOrganizationContract(organizationContract.contractId, error));
  }
}

export function* watchAddOrganizationContractsStarted() {
  yield takeEvery(types.ORGANIZATION_CONTRACT_ADD_STARTED, addOrganizationContract);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editOrganizationContract(action) {
  const organizationContract = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organizationContract/save`, {
        method: 'POST',
        body: JSON.stringify(organizationContract),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedOrganizationContract = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingOrganizationContract(editedOrganizationContract));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'contracts.contract'
          }),
          name: editedOrganizationContract.contractNumber
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldOrganizationContract } = yield select((state) =>
          selectors.getOrganizationContract(
            state.organizationContracts,
            organizationContract.contractId
          )
        );
        yield put(actions.failEditingOrganizationContract(oldOrganizationContract, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };

    const oldOrganizationContract = yield select((state) =>
      selectors.getOrganizationContract(state, organizationContract.contractId)
    );
    yield put(actions.failEditingOrganizationContract(oldOrganizationContract, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditOrganizationContractsStarted() {
  yield takeEvery(types.ORGANIZATION_CONTRACT_EDIT_STARTED, editOrganizationContract);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeOrganizationContract(action) {
  const { contractId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedOrganizationContract = yield select((state) =>
        selectors.getOrganizationContract(state, contractId)
      );
      const response = yield call(fetch, `${API_BASE_URL}/organizationContract/delete`, {
        method: 'POST',
        body: JSON.stringify({ contractId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingOrganizationContract(contractId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'contracts.contract'
          }),
          name: deletedOrganizationContract.contractNumber
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingOrganizationContract(contractId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingOrganizationContract(contractId, error));
  }
}

export function* watchRemoveOrganizationContractStarted() {
  yield takeEvery(types.ORGANIZATION_CONTRACT_REMOVE_STARTED, removeOrganizationContract);
}
