/* -------------------------------------------------------------------------- */
/*                       Componente TableDateTimePicker                       */
/* -------------------------------------------------------------------------- */
// Este componente contiene TableDateTimePicker que se usa en la tabla de syncfusion.

import React from 'react';
import moment from 'moment';
import { getValue } from '@syncfusion/ej2-base';
import { TimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import { DateTime } from 'luxon';

interface Props {
  args: any;
  id: any;
  min?: any;
  max?: any;
  isDisabled?: boolean;
}

const TableTimePicker = (props: Props) => {
  const { args, id, min, max, isDisabled } = props;
  return (
    <TimePickerComponent
      value={getValue(id, args)}
      min={min}
      max={max}
      strictMode={true}
      enabled={!isDisabled}
      id={id}
      placeholder={appIntl().formatMessage({ id: 'common.hour' })}
      floatLabelType="Never"
      step={15}
      format={'HH:mm'}
    />
  );
};

TableTimePicker.defaultProps = {
  min: moment().startOf('day').toDate(),
  max: undefined,
  isDisabled: false
};

export default TableTimePicker;
