import drivers from './drivers';
import people from './people';
import users from './users';
import customers from './customers';
import contacts from 'views/screens/Contacts/sidebarLayout';
import offices from 'views/screens/Offices/sidebarLayout';

export default [
  {
    id: 'catalogs',
    title: 'sidebar.catalogs',
    icon: 'Users',
    permissions: [],
    iconName: 'Users',
    children: [...users, ...drivers, ...people, ...contacts, ...customers, ...offices]
  }
];
