import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { normalize } from 'normalizr';
import * as schemas from '../schemas';
import camelcaseKeys from 'camelcase-keys';

import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }

    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.GROUP_ADD_WITH_ENTITIES_COMPLETED:
    case types.GROUP_ADD_COMPLETED: {
      const { oldId, group } = action.payload;
      const newState = omit(state, oldId);
      newState[group.groupId] = {
        ...state[oldId],
        ...group
      };
      return newState;
    }

    case types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_COMPLETED:
    case types.GROUP_EDIT_COMPLETED: {
      const group = action.payload;
      return {
        ...state,
        [group.groupId]: {
          ...omit(state[group.groupId], ['oldGroup']),
          ...omit(group, ['oldGroup'])
        }
      };
    }

    case types.GROUPS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((groupId) => {
        const {
          entities: { users }
        } = normalize(
          entities[groupId].entities[0].map((entity) => camelcaseKeys(entity)),
          schemas.users
        ); //normalize data to byId for users
        const {
          entities: { locations }
        } = normalize(
          entities[groupId].entities[1].map((entity) => camelcaseKeys(entity)),
          schemas.locations
        ); //normalize data to byId for locations
        const {
          entities: { workplans }
        } = normalize(
          entities[groupId].entities[2].map((entity) => camelcaseKeys(entity)),
          schemas.workplans
        ); //normalize data to byId for workplans
        const {
          entities: { units }
        } = normalize(
          entities[groupId].entities[3].map((entity) => camelcaseKeys(entity)),
          schemas.units
        ); //normalize data to byId for units
        const {
          entities: { geofences }
        } = normalize(
          entities[groupId].entities[4].map((entity) => camelcaseKeys(entity)),
          schemas.geofences
        ); //normalize data to byId for geofences
        const {
          entities: { drivers }
        } = normalize(
          entities[groupId].entities[5].map((entity) => camelcaseKeys(entity)),
          schemas.drivers
        ); //normalize data to byId for drivers

        newState[groupId] = {
          ...state[groupId],
          isSelected: false,
          users: users ?? {},
          locations: locations ?? {},
          workplans: workplans ?? {},
          units: units ?? {},
          geofences: geofences ?? {},
          drivers: drivers ?? {},
          ...omit(entities[groupId], 'entities')
        };
      });
      return newState;
    }

    case types.GROUPS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((groupId) => {
        const {
          entities: { users }
        } = normalize(
          entities[groupId].entities[0].map((entity) => camelcaseKeys(entity)),
          schemas.users
        ); //normalize data to byId for users
        const {
          entities: { locations }
        } = normalize(
          entities[groupId].entities[1].map((entity) => camelcaseKeys(entity)),
          schemas.locations
        ); //normalize data to byId for locations
        const {
          entities: { workplans }
        } = normalize(
          entities[groupId].entities[2].map((entity) => camelcaseKeys(entity)),
          schemas.workplans
        ); //normalize data to byId for workplans
        const {
          entities: { units }
        } = normalize(
          entities[groupId].entities[3].map((entity) => camelcaseKeys(entity)),
          schemas.units
        ); //normalize data to byId for units
        const {
          entities: { geofences }
        } = normalize(
          entities[groupId].entities[4].map((entity) => camelcaseKeys(entity)),
          schemas.geofences
        ); //normalize data to byId for geofences
        const {
          entities: { drivers }
        } = normalize(
          entities[groupId].entities[5].map((entity) => camelcaseKeys(entity)),
          schemas.drivers
        ); //normalize data to byId for drivers

        newState[groupId] = {
          ...state[groupId],
          isSelected: false,
          users: users ?? {},
          locations: locations ?? {},
          workplans: workplans ?? {},
          units: units ?? {},
          geofences: geofences ?? {},
          drivers: drivers ?? {},
          ...omit(entities[groupId], 'entities')
        };
      });
      return newState;
    }

    case types.GROUPS_ALL_ENTITIES_FETCH_COMPLETED: {
      const { entities, groupId } = action.payload;
      const newState = { ...state };
      newState[groupId] = {
        users: entities.users,
        locations: entities.locations,
        workplans: entities.workplans,
        units: entities.units,
        geofences: entities.geofences,
        drivers: entities.drivers,
        ...state[groupId]
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.GROUP_ADD_STARTED: {
      const { group, users, userInSession } = action.payload;
      const newState = { ...state };
      const usersToAdd = {};
      users
        .filter((user) => (group.isInGroup === 0 ? user.userName !== userInSession : user))
        .map((user) => {
          usersToAdd[user.userName] = user;
        });
      newState[group.groupId] = {
        isSelected: true,
        users: usersToAdd,
        locations: {},
        workplans: {},
        units: {},
        geofences: {},
        drivers: {},
        profileId: 1,
        locationsCount: 0,
        workPlansCount: 0,
        userCount: users.length,
        unitCount: 0,
        geofenceCount: 0,
        driverCount: 0,
        ...group,
        isInGroup: group.isInGroup
      };
      return newState;
    }

    case types.GROUP_ADD_WITH_ENTITIES_STARTED: {
      const { group, userInSession, entityType, entities, params } = action.payload;
      const newState = { ...state };
      const usersToAdd = { [`${userInSession.userName}`]: userInSession };
      const entitiesToAdd = entities.map((entity) =>
        entityType === 'users'
          ? {
              userId: entity.userId,
              userName: entity.userName,
              completeName: entity.completeName,
              userImage: entity.userImage,
              profileId: params.profileId
            }
          : entityType === 'locations'
          ? {
              locationId: entity.locationId,
              locationName: entity.locationName,
              radius: entity.radius,
              latitude: entity.latitude,
              longitude: entity.longitude,
              organizationName: entity.organizationName
            }
          : entityType === 'workplans'
          ? {
              workPlanId: entity.workplanId,
              workPlanName: entity.workplanName,
              organizationName: entity.organizationName
            }
          : entityType === 'units'
          ? {
              unitId: entity.unitId,
              groupName: group.groupName,
              unitName: entity.unitName,
              themeId: entity.themeId,
              privateCode: entity.privateCode,
              organizationName: entity.organizationName
            }
          : entityType === 'geofences'
          ? {
              geofenceId: entity.geofenceId,
              geofenceName: entity.geofenceName,
              notifyAdmin: params.notifyAdmin,
              notifyOwner: params.notifyOwner,
              shape: entity.shape,
              notifyEmails: params.notifyEmails
            }
          : entityType === 'drivers'
          ? {
              driverId: entity.driverId,
              driverName: entity.driverName,
              driverPhoto: entity.driverPhoto,
              licenseNumber: entity.licenseNumber,
              licenseType: entity.licenseType,
              licenseExpireDate: entity.licenseExpireDate
            }
          : entity
      );

      newState[group.groupId] = {
        isSelected: false,
        users: usersToAdd,
        locations: {},
        workplans: {},
        units: {},
        geofences: {},
        drivers: {},
        profileId: 1,
        locationsCount: 0,
        workPlansCount: 0,
        userCount: 1,
        unitCount: 0,
        geofenceCount: 0,
        driverCount: 0,
        ...group,
        isInGroup: group.isInGroup
      };

      entitiesToAdd.forEach((entity) => {
        newState[group.groupId] = {
          ...newState[group.groupId],
          [entityType]: {
            ...newState[group.groupId][entityType],
            [entityType === 'users'
              ? entity.userName
              : entityType === 'locations'
              ? entity.locationId
              : entityType === 'workplans'
              ? entity.workplanId
              : entityType === 'units'
              ? entity.unitId
              : entityType === 'geofences'
              ? entity.geofenceId
              : entityType === 'drivers'
              ? entity.driverId
              : entity.entityId]: entity
          },
          [entityType === 'users'
            ? 'userCount'
            : entityType === 'locations'
            ? 'locationsCount'
            : entityType === 'workplans'
            ? 'workPlansCount'
            : entityType === 'units'
            ? 'unitCount'
            : entityType === 'geofences'
            ? 'geofenceCount'
            : entityType === 'drivers'
            ? 'driverCount'
            : '']:
            newState[group.groupId][
              entityType === 'users'
                ? 'userCount'
                : entityType === 'locations'
                ? 'locationsCount'
                : entityType === 'workplans'
                ? 'workPlansCount'
                : entityType === 'units'
                ? 'unitCount'
                : entityType === 'geofences'
                ? 'geofenceCount'
                : entityType === 'drivers'
                ? 'driverCount'
                : ''
            ] + 1
        };
      });
      return newState;
    }

    case types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_STARTED:
    case types.GROUP_EDIT_STARTED: {
      const group = action.payload;
      return {
        ...state,
        [group.groupId]: {
          ...state[group.groupId],
          oldGroup: state[group.groupId],
          ...group
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_FAILED:
    case types.GROUP_EDIT_FAILED: {
      const { oldGroup } = action.payload;
      return {
        ...state,
        [oldGroup.groupId]: {
          ...omit(state[oldGroup.groupId], ['oldGroup']),
          ...oldGroup
        }
      };
    }

    //TYPES REMOVE AND ADD ENTITIES
    case types.GROUP_REMOVE_ENTITIES_COMPLETED: {
      const groupId = action.payload.groupId;
      const entityType = action.payload.entityType; // users, locations, workplans, units, geofences, drivers
      const entities = action.payload.entities;
      const newState = {
        ...state,
        [groupId]: {
          ...state[groupId],
          [entityType]: omit(state[groupId][entityType], entities),
          [entityType === 'users'
            ? 'userCount'
            : entityType === 'locations'
            ? 'locationsCount'
            : entityType === 'workplans'
            ? 'workPlansCount'
            : entityType === 'units'
            ? 'unitCount'
            : entityType === 'geofences'
            ? 'geofenceCount'
            : entityType === 'drivers'
            ? 'driverCount'
            : '']:
            state[groupId][
              entityType === 'users'
                ? 'userCount'
                : entityType === 'locations'
                ? 'locationsCount'
                : entityType === 'workplans'
                ? 'workPlansCount'
                : entityType === 'units'
                ? 'unitCount'
                : entityType === 'geofences'
                ? 'geofenceCount'
                : entityType === 'drivers'
                ? 'driverCount'
                : ''
            ] - entities.length
        }
      };
      return newState;
    }

    case types.GROUP_ADD_ENTITIES_COMPLETED: {
      const group = action.payload.group;
      const entityType = action.payload.entityType; // users, locations, workplans, units, geofences, drivers
      const entities = action.payload.entities;
      const params = action.payload.params;
      const newState = { ...state };

      const entitiesToAdd = entities.map((entity) =>
        entityType === 'users'
          ? {
              userId: entity.userId,
              userName: entity.userName,
              completeName: entity.completeName,
              userImage: entity.userImage,
              profileId: params.profileId
            }
          : entityType === 'locations'
          ? {
              locationId: entity.locationId,
              locationName: entity.locationName,
              radius: entity.radius,
              latitude: entity.latitude,
              longitude: entity.longitude,
              organizationName: entity.organizationName
            }
          : entityType === 'workplans'
          ? {
              workPlanId: entity.workPlanId,
              workPlanName: entity.workPlanName,
              organizationName: entity.organizationName
            }
          : entityType === 'units'
          ? {
              unitId: entity.unitId,
              groupName: group.groupName,
              unitName: entity.unitName,
              themeId: entity.themeId,
              privateCode: entity.privateCode,
              organizationName: entity.organizationName
            }
          : entityType === 'geofences'
          ? {
              geofenceId: entity.geofenceId,
              geofenceName: entity.geofenceName,
              notifyAdmin: params.notifyAdmin,
              notifyOwner: params.notifyOwner,
              shape: entity.shape,
              notifyEmails: params.notifyEmails
            }
          : entityType === 'drivers'
          ? {
              driverId: entity.driverId,
              driverName: entity.driverName,
              driverPhoto: entity.driverPhoto,
              licenseNumber: entity.licenseNumber,
              licenseType: entity.licenseType,
              licenseExpireDate: entity.licenseExpireDate
            }
          : entity
      );

      entitiesToAdd.forEach((entity) => {
        newState[group.groupId] = {
          ...state[group.groupId],
          [entityType]: {
            ...newState[group.groupId][entityType],
            [entityType === 'users'
              ? entity.userName
              : entityType === 'locations'
              ? entity.locationId
              : entityType === 'workplans'
              ? entity.workPlanId
              : entityType === 'units'
              ? entity.unitId
              : entityType === 'geofences'
              ? entity.geofenceId
              : entityType === 'drivers'
              ? entity.driverId
              : entity.entityId]: entity
          },
          [entityType === 'users'
            ? 'userCount'
            : entityType === 'locations'
            ? 'locationsCount'
            : entityType === 'workplans'
            ? 'workPlansCount'
            : entityType === 'units'
            ? 'unitCount'
            : entityType === 'geofences'
            ? 'geofenceCount'
            : entityType === 'drivers'
            ? 'driverCount'
            : '']:
            newState[group.groupId][
              entityType === 'users'
                ? 'userCount'
                : entityType === 'locations'
                ? 'locationsCount'
                : entityType === 'workplans'
                ? 'workPlansCount'
                : entityType === 'units'
                ? 'unitCount'
                : entityType === 'geofences'
                ? 'geofenceCount'
                : entityType === 'drivers'
                ? 'driverCount'
                : ''
            ] + 1
        };
      });
      return newState;
    }

    //TYPE EDIT USER PROFILE IN GROUP
    case types.GROUP_USER_PROFILE_EDIT_COMPLETED: {
      const { groupId, userName, userNameInSession, profileId } = action.payload;
      const users = { ...state[groupId].users };
      users[userName] = { ...users[userName], profileId: profileId };
      const newState = {
        ...state,
        [groupId]: {
          ...state[groupId],
          users: users,
          profileId: userNameInSession === userName ? profileId : state[groupId]['profileId']
        }
      };
      return newState;
    }

    //TYPES REMOVE COMPLETED
    case types.GROUP_REMOVE_COMPLETED: {
      const { groupId } = action.payload;
      return omit(state, groupId);
    }

    //TYPES LEAVE COMPLETED
    case types.GROUP_LEAVE_COMPLETED: {
      const { groupId, isAsesor, asesorUsername } = action.payload;
      if (isAsesor === true) {
        const newState = {
          ...state,
          [groupId]: {
            ...state[groupId],
            users: omit(state[groupId]['users'], asesorUsername),
            userCount: state[groupId]['userCount'] - 1,
            isInGroup: 0,
            profileId: 1
          }
        };
        return newState;
      }
      return omit(state, groupId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.GROUP_SELECTED: {
      const groupId = action.payload;
      const newState = {
        ...state,
        [groupId]: {
          ...state[groupId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.GROUP_DESELECTED: {
      const groupId = action.payload;
      return {
        ...state,
        [groupId]: {
          ...state[groupId],
          isSelected: false
        }
      };
    }

    case types.GROUPS_ALL_SELECTED: {
      const groupIds = action.payload;
      const newState = { ...state };
      if (groupIds.length == 0) {
        forEach(state, (group: any, groupId) => {
          newState[groupId] = {
            ...group,
            isSelected: true
          };
        });
      } else {
        groupIds.forEach((groupId) => {
          newState[groupId] = {
            ...state[groupId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.GROUPS_ALL_DESELECTED: {
      const groupIds = action.payload;
      const newState = { ...state };
      if (groupIds.length == 0) {
        forEach(state, (group: any, groupId) => {
          newState[groupId] = {
            ...group,
            isSelected: false
          };
        });
      } else {
        groupIds.forEach((groupId) => {
          newState[groupId] = {
            ...state[groupId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.GROUPS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.GROUP_ADD_WITH_ENTITIES_COMPLETED:
    case types.GROUP_ADD_COMPLETED: {
      const { oldId, group } = action.payload;
      return state.map((groupId) => (groupId === oldId ? group.groupId : groupId));
    }

    case types.GROUPS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.GROUP_REMOVE_COMPLETED: {
      const { groupId } = action.payload;
      return state.filter((groupIdState) => groupIdState !== groupId);
    }

    case types.GROUP_LEAVE_COMPLETED: {
      const { groupId, isAsesor } = action.payload;
      return state.filter((groupIdState) =>
        isAsesor === true ? groupIdState : groupIdState !== groupId
      );
    }

    //CASE ADD STARTED
    case types.GROUP_ADD_WITH_ENTITIES_STARTED:
    case types.GROUP_ADD_STARTED: {
      const { group, user } = action.payload;
      return [...state, group.groupId];
    }

    //CASE ADD FAILED
    case types.GROUP_ADD_WITH_ENTITIES_FAILED:
    case types.GROUP_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((groupIdState) => groupIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUPS_FETCH_STARTED: {
      return true;
    }
    case types.GROUPS_FETCH_COMPLETED:
    case types.GROUPS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingAllEntities = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUPS_ALL_ENTITIES_FETCH_STARTED: {
      return true;
    }
    case types.GROUPS_ALL_ENTITIES_FETCH_COMPLETED:
    case types.GROUPS_ALL_ENTITIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUPS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.GROUPS_PARTIAL_FETCH_COMPLETED:
    case types.GROUPS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUP_ADD_STARTED: {
      return true;
    }
    case types.GROUP_ADD_WITH_ENTITIES_FAILED:
    case types.GROUP_ADD_WITH_ENTITIES_COMPLETED:
    case types.GROUP_ADD_COMPLETED:
    case types.GROUP_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUP_ADD_ENTITIES_STARTED:
    case types.GROUP_ADD_WITH_ENTITIES_STARTED:
    case types.GROUP_REMOVE_ENTITIES_STARTED:
    case types.GROUP_EDIT_STARTED: {
      return true;
    }
    case types.GROUP_ADD_COMPLETED:
    case types.GROUP_ADD_WITH_ENTITIES_COMPLETED:
    case types.GROUP_REMOVE_ENTITIES_COMPLETED:
    case types.GROUP_EDIT_COMPLETED:
    case types.GROUP_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUP_REMOVE_STARTED: {
      return true;
    }
    case types.GROUP_REMOVE_COMPLETED:
    case types.GROUP_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isLeaving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GROUP_LEAVE_STARTED: {
      return true;
    }
    case types.GROUP_LEAVE_COMPLETED:
    case types.GROUP_LEAVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUP_ADD_WITH_ENTITIES_FAILED:
    case types.GROUP_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUP_ADD_WITH_ENTITIES_STARTED:
    case types.GROUP_ADD_WITH_ENTITIES_COMPLETED:
    case types.GROUP_ADD_STARTED:
    case types.GROUP_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUP_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUP_EDIT_STARTED:
    case types.GROUP_ADD_ENTITIES_STARTED:
    case types.GROUP_ADD_ENTITIES_COMPLETED:
    case types.GROUP_REMOVE_ENTITIES_COMPLETED:
    case types.GROUP_REMOVE_ENTITIES_STARTED:
    case types.GROUP_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUP_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUP_REMOVE_STARTED:
    case types.GROUP_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorLeaving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUP_LEAVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUP_LEAVE_STARTED:
    case types.GROUP_LEAVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUPS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUPS_FETCH_STARTED:
    case types.GROUPS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingAllEntities = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUPS_ALL_ENTITIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUPS_ALL_ENTITIES_FETCH_STARTED:
    case types.GROUPS_ALL_ENTITIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GROUPS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GROUPS_PARTIAL_FETCH_STARTED:
    case types.GROUPS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const groups: any = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isFetchingAllEntities,
  isAdding,
  isEditing,
  isRemoving,
  isLeaving,
  errorPartialFetchingList,
  errorFetchingList,
  errorFetchingAllEntities,
  errorAdding,
  errorEditing,
  errorRemoving,
  errorLeaving
});

const groupsPersistedConfig = {
  key: 'groups',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAdding', 'isEditing', 'isRemoving', 'isFetchingList', 'isPartialFetchingList'],
  whitelist: ['byId', 'order']
};

export default persistReducer(groupsPersistedConfig, groups);

//Own state
export const getOwnState = (state) => state.groups;

//Information
export const getGroup = (state, groupId) => {
  const group = getOwnState(state).byId[groupId];
  if (group) {
    return {
      ...group
    };
  } else return undefined;
};
export const getUsersInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.users ? Object.values(group.users) : [];
};
export const getLocationsInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.locations ? Object.values(group.locations) : [];
};
export const getWorkplansInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.workplans ? Object.values(group.workplans) : [];
};
export const getUnitsInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.units ? Object.values(group.units) : [];
};
export const getGeofencesInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.geofences ? Object.values(group.geofences) : [];
};
export const getDriversInGroup = (state, groupId) => {
  const group = getGroup(state, groupId);
  return group && group.divers ? Object.values(group.divers) : [];
};

export const getGroupsList = (state) =>
  getOwnState(state)
    .order.map((id) => getGroup(state, id))
    .filter((group) => group.groupStatus > 0);

export const getAllGroupsList = (state) =>
  getOwnState(state).order.map((id) => getGroup(state, id));

export const getSelectedGroups = (state) => {
  const selectedGroups = getGroupsList(state).filter((group) => group.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedGroups.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedGroups.length > 0) return selectedGroups;
};

export const getUnfilteredSelectedGroup = (state) =>
  getAllGroupsList(state).find((group) => group.isSelected) ?? null;

export const getSelectedGroup = (state) => {
  const selectedGroups = getGroupsList(state).filter((group) => group.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedGroups.length === 1) return selectedGroups[0];
  //De lo contrario se devuelve null
  else return null;
};
export const getGroupsListByMembership = (state, membership) => {
  return getGroupsList(state).filter(
    (group) =>
      membership &&
      (!membership.operatorId || membership.operatorId === group.operatorId) &&
      (!membership.organizationId || membership.organizationId === group.organizationId) &&
      (!membership.divisionId || membership.divisionId === group.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === group.subdivisionId) &&
      group.unitStatus > 0
  );
};

//Status of sagas
export const isPartialFetchingListGroups = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListGroups = (state) => getOwnState(state).isFetchingList;
export const isFetchingAllEntitiesGroups = (state) => getOwnState(state).isFetchingAllEntitities;
export const isAddingGroup = (state) => getOwnState(state).isAdding;
export const isEditingGroup = (state) => getOwnState(state).isEditing;
export const isRemovingGroup = (state) => getOwnState(state).isRemoving;
export const isLeavingGroup = (state) => getOwnState(state).isLeaving;

//Errors
export const getPartialFetchingListGroupsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListGroupsErrors = (state) => getOwnState(state).errorFetchingList;
export const getFetchingAllEntitiesErrors = (state) => getOwnState(state).errorFetchingAllEntities;
export const getAddingGroupErrors = (state) => getOwnState(state).errorAdding;
export const getEditingGroupErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingGroupErrors = (state) => getOwnState(state).errorRemoving;
export const getLeavingGroupErrors = (state) => getOwnState(state).errorLeaving;
