export const MONITORING_USERS_FETCH_STARTED = 'MONITORING_USERS_FETCH_STARTED';
export const MONITORING_USERS_FETCH_COMPLETED = 'MONITORING_USERS_FETCH_COMPLETED';
export const MONITORING_USERS_FETCH_FAILED = 'MONITORING_USERS_FETCH_FAILED';

export const MONITORING_USERS_PARTIAL_FETCH_STARTED = 'MONITORING_USERS_PARTIAL_FETCH_STARTED';
export const MONITORING_USERS_PARTIAL_FETCH_COMPLETED = 'MONITORING_USERS_PARTIAL_FETCH_COMPLETED';
export const MONITORING_USERS_PARTIAL_FETCH_FAILED = 'MONITORING_USERS_PARTIAL_FETCH_FAILED';

export const MONITORING_USER_ADD_STARTED = 'MONITORING_USER_ADD_STARTED';
export const MONITORING_USER_ADD_COMPLETED = 'MONITORING_USER_ADD_COMPLETED';
export const MONITORING_USER_ADD_FAILED = 'MONITORING_USER_ADD_FAILED';

export const MONITORING_USER_EDIT_STARTED = 'MONITORING_USER_EDIT_STARTED';
export const MONITORING_USER_EDIT_COMPLETED = 'MONITORING_USER_EDIT_COMPLETED';
export const MONITORING_USER_EDIT_FAILED = 'MONITORING_USER_EDIT_FAILED';

export const MONITORING_USER_REMOVE_STARTED = 'MONITORING_USER_REMOVE_STARTED';
export const MONITORING_USER_REMOVE_COMPLETED = 'MONITORING_USER_REMOVE_COMPLETED';
export const MONITORING_USER_REMOVE_FAILED = 'MONITORING_USER_REMOVE_FAILED';

export const MONITORING_USER_FETCH_STARTED = 'MONITORING_USER_FETCH_STARTED';
export const MONITORING_USER_FETCH_COMPLETED = 'MONITORING_USER_FETCH_COMPLETED';
export const MONITORING_USER_FETCH_FAILED = 'MONITORING_USER_FETCH_FAILED';

export const MONITORING_USER_SELECTED = 'MONITORING_USER_SELECTED';
export const MONITORING_USERS_ALL_SELECTED = 'MONITORING_USERS_ALL_SELECTED';
export const MONITORING_USER_DESELECTED = 'MONITORING_USER_DESELECTED';
export const MONITORING_USERS_ALL_DESELECTED = 'MONITORING_USERS_ALL_DESELECTED';

export const MONITORING_USER_UPDATE_PASSWORD_STARTED = 'MONITORING_USER_UPDATE_PASSWORD_STARTED';
export const MONITORING_USER_UPDATE_PASSWORD_COMPLETED =
  'MONITORING_USER_UPDATE_PASSWORD_COMPLETED';
export const MONITORING_USER_UPDATE_PASSWORD_FAILED = 'MONITORING_USER_UPDATE_PASSWORD_FAILED';

export const MONITORING_USER_SAVE_ROLES_PERMISSIONS_STARTED =
  'MONITORING_USER_SAVE_ROLES_PERMISSIONS_STARTED';
export const MONITORING_USER_SAVE_ROLES_PERMISSIONS_COMPLETED =
  'MONITORING_USER_SAVE_ROLES_PERMISSIONS_COMPLETED';

export const USERS_ASSIGN_SCHEDULE_STARTED = 'USERS_ASSIGN_SCHEDULE_STARTED';
export const USERS_ASSIGN_SCHEDULE_COMPLETED = 'USERS_ASSIGN_SCHEDULE_COMPLETED';

export const USERS_UNASSIGN_SCHEDULE_STARTED = 'USERS_UNASSIGN_SCHEDULE_STARTED';
export const USERS_UNASSIGN_SCHEDULE_COMPLETED = 'USERS_UNASSIGN_SCHEDULE_COMPLETED';

export interface MonitoringUser {
  isSelected: boolean;
  userId: number;
  userName: string;
  brandId?: number | null;
  imageName: null | string;
  userImageUrl: null | string;
  accessLevelId: number;
  accessLevelName: string;
  divisionId: number;
  divisionName: string;
  email: string;
  emailConfirmed: boolean;
  completeName: string;
  organizationId: number;
  organizationName: string;
  phoneNumber: null | string | number;
  subdivisionId: number;
  subdivisionName: string;
  operatorId: number;
  agentId: number | null;
  operatorName: string;
  groupId: number | string;
  utcZoneId: number;
  metricSystemId: number | null;
  currencyId: number | null;
  driverId: number | null;
  driverName: null | string;
  userStatus: number;
  lastLoginDate: string | null;
  enableLocationClustering: boolean | null;
  locationMapLabelAlwaysOn: boolean | null;
  activateUnitTrackMode: boolean | null;
  unitMapLabelAlwaysOn: boolean | null;
  useGoogleMaps: boolean | null;
  useBingMaps: boolean | null;
  useHereMaps: boolean | null;
  unitsLabelContent: null | string;
  roleId: number | null;
  homeScreenId: number | null;
  scheduleId: number | null;
  scheduleName: null | string;
  isConfirmed: boolean;
  zoomLevelToStartClustering?: number;
  countriesForGoogleSearch?: string;
}

export interface AssignedUnit {
  unitId: number;
  unitName: string;
  privateCode?: string;
}
