import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../settings/apibaseurl';
import * as actions from '../../../actions/static-catalogs/currencies';
import * as types from '../../../types/static-catalogs/currencies';
import * as schemas from '../../../schemas/currencies';
import * as authSelectors from '../../../reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../utils/alerts';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchCurrenciesList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/profile/currency`, {
        method: 'GET',
        // body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { currencies },
          result //order
        } = normalize(resultData, schemas.currencies); //normalize data to byId and order
        yield put(actions.completeFetchingCurrencies(currencies, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingCurrencies(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingCurrencies(error));
  }
}

export function* watchFetchCurrenciesList() {
  yield takeEvery(types.CURRENCIES_FETCH_STARTED, fetchCurrenciesList);
}
