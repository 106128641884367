import * as types from '../../types/routesComments';

export const startFetchingComments = (routeAssignmentId) => ({
  type: types.ROUTES_EXECUTION_COMMENTS_FETCH_STARTED,
  payload: { routeAssignmentId }
});
export const completeFetchingComments = (entities, order) => ({
  type: types.ROUTES_EXECUTION_COMMENTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingComments = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_EXECUTION_COMMENTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingComment = (comment) => ({
  type: types.ROUTES_EXECUTION_COMMENT_ADD_STARTED,
  payload: comment
});
export const completeAddingComment = (oldId, comment) => ({
  type: types.ROUTES_EXECUTION_COMMENT_ADD_COMPLETED,
  payload: { oldId, comment }
});
export const failAddingComment = (oldId, error) => ({
  type: types.ROUTES_EXECUTION_COMMENT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingComment = (comment) => ({
  type: types.ROUTES_EXECUTION_COMMENT_EDIT_STARTED,
  payload: comment
});
export const completeEditingComment = (comment) => ({
  type: types.ROUTES_EXECUTION_COMMENT_EDIT_COMPLETED,
  payload: comment
});
export const failEditingComment = (oldComment, error) => ({
  type: types.ROUTES_EXECUTION_COMMENT_EDIT_FAILED,
  payload: {
    oldComment,
    error
  }
});

export const startRemovingComment = (routeCommentId) => ({
  type: types.ROUTES_EXECUTION_COMMENT_REMOVE_STARTED,
  payload: {
    routeCommentId
  }
});
export const completeRemovingComment = (routeCommentId) => ({
  type: types.ROUTES_EXECUTION_COMMENT_REMOVE_COMPLETED,
  payload: {
    routeCommentId
  }
});
export const failRemovingComment = (routeCommentId, error) => ({
  type: types.ROUTES_EXECUTION_COMMENT_REMOVE_FAILED,
  payload: {
    routeCommentId,
    error
  }
});
