import * as types from 'views/screens/Operators/types';

export const startFetchingOperators = (filters = {}) => ({
  type: types.OPERATORS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingOperators = (entities, order) => ({
  type: types.OPERATORS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingOperators = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.OPERATORS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingOperators = (entityTypeId) => ({
  type: types.OPERATORS_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});
export const completePartialFetchingOperators = (entities, order) => ({
  type: types.OPERATORS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingOperators = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.OPERATORS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingOperator = (operator) => ({
  type: types.OPERATOR_ADD_STARTED,
  payload: operator
});
export const completeAddingOperator = (oldId, operator) => ({
  type: types.OPERATOR_ADD_COMPLETED,
  payload: { oldId, operator }
});
export const failAddingOperator = (oldId, error) => ({
  type: types.OPERATOR_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingOperator = (operator) => ({
  type: types.OPERATOR_EDIT_STARTED,
  payload: operator
});
export const completeEditingOperator = (operator) => ({
  type: types.OPERATOR_EDIT_COMPLETED,
  payload: operator
});
export const failEditingOperator = (oldOperator, error) => ({
  type: types.OPERATOR_EDIT_FAILED,
  payload: {
    oldOperator,
    error
  }
});

export const startEditingOperatorStandardPrices = (standardPrices) => ({
  type: types.OPERATOR_EDIT_STANDARD_PRICES_STARTED,
  payload: standardPrices
});
export const completeEditingOperatorStandardPrices = (standardPrices) => ({
  type: types.OPERATOR_EDIT_STANDARD_PRICES_COMPLETED,
  payload: standardPrices
});
export const failEditingOperatorStandardPrices = (oldStandardPrices, error) => ({
  type: types.OPERATOR_EDIT_STANDARD_PRICES_FAILED,
  payload: {
    oldStandardPrices,
    error
  }
});

export const startRemovingOperator = (operatorId) => ({
  type: types.OPERATOR_REMOVE_STARTED,
  payload: {
    operatorId
  }
});
export const completeRemovingOperator = (operatorId) => ({
  type: types.OPERATOR_REMOVE_COMPLETED,
  payload: {
    operatorId
  }
});
export const failRemovingOperator = (operatorId, error) => ({
  type: types.OPERATOR_REMOVE_FAILED,
  payload: {
    operatorId,
    error
  }
});

export const selectOperator = (operatorId) => ({
  type: types.OPERATOR_SELECTED,
  payload: operatorId
});

export const selectAllOperators = (
  operatorIds = [] //List of OperatorIds
) => ({
  type: types.OPERATORS_ALL_SELECTED,
  payload: operatorIds
});

export const deselectOperator = (operatorId) => ({
  type: types.OPERATOR_DESELECTED,
  payload: operatorId
});

export const deselectAllOperators = (
  operatorIds = [] //List of OperatorIds
) => ({
  type: types.OPERATORS_ALL_DESELECTED,
  payload: operatorIds
});
