export const VEHICLES_FETCH_STARTED = 'VEHICLES_FETCH_STARTED';
export const VEHICLES_FETCH_COMPLETED = 'VEHICLES_FETCH_COMPLETED';
export const VEHICLES_FETCH_FAILED = 'VEHICLES_FETCH_FAILED';

export const VEHICLES_PARTIAL_FETCH_STARTED = 'VEHICLES_PARTIAL_FETCH_STARTED';
export const VEHICLES_PARTIAL_FETCH_COMPLETED = 'VEHICLES_PARTIAL_FETCH_COMPLETED';
export const VEHICLES_PARTIAL_FETCH_FAILED = 'VEHICLES_PARTIAL_FETCH_FAILED';

export const VEHICLE_ADD_STARTED = 'VEHICLE_ADD_STARTED';
export const VEHICLE_ADD_COMPLETED = 'VEHICLE_ADD_COMPLETED';
export const VEHICLE_ADD_FAILED = 'VEHICLE_ADD_FAILED';

export const VEHICLE_EDIT_STARTED = 'VEHICLE_EDIT_STARTED';
export const VEHICLE_EDIT_COMPLETED = 'VEHICLE_EDIT_COMPLETED';
export const VEHICLE_EDIT_FAILED = 'VEHICLE_EDIT_FAILED';

export const VEHICLE_REMOVE_STARTED = 'VEHICLE_REMOVE_STARTED';
export const VEHICLE_REMOVE_COMPLETED = 'VEHICLE_REMOVE_COMPLETED';
export const VEHICLE_REMOVE_FAILED = 'VEHICLE_REMOVE_FAILED';

export const VEHICLE_SELECTED = 'VEHICLE_SELECTED';
export const VEHICLES_ALL_SELECTED = 'VEHICLES_ALL_SELECTED';
export const VEHICLE_DESELECTED = 'VEHICLE_DESELECTED';
export const VEHICLES_ALL_DESELECTED = 'VEHICLES_ALL_DESELECTED';

export const VEHICLE_LICENSE_PLATE_CHECK_STARTED = 'VEHICLE_LICENSE_PLATE_CHECK_STARTED';
export const VEHICLE_LICENSE_PLATE_CHECK_COMPLETED = 'VEHICLE_LICENSE_PLATE_CHECK_COMPLETED';
export const VEHICLE_LICENSE_PLATE_CHECK_FAILED = 'VEHICLE_LICENSE_PLATE_CHECK_FAILED';
