import maps from './maps';
import dashboard from 'views/screens/Dashboard/sidebarLayout';
import pointsOfInterest from './pointsOfInterest';
import reports from './reports';
import trackingUnits from './trackingUnits';

export default [
  {
    header: 'sidebar.general',
    iconName: 'List',
    children: [...dashboard, ...maps, ...reports, ...trackingUnits, ...pointsOfInterest] //Only to check permission in children inside
  },
  ...dashboard,
  ...maps,
  ...reports,
  ...trackingUnits,
  ...pointsOfInterest
];
