import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/organization';

interface ConsolidableEventsRequest {
  organizationId: number;
}

export interface loginextConfiguration {
  organizationId: number;
  organizationName: string;
  loginextEffectiveVisitingTime: number;
  loginextDeliveryDelay: number;
}

export const getOrganizationsListService = async (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'organizations.organization',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getConsolidableEvents = async (
  request: ConsolidableEventsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'organizations.consolidatedEvents',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/GetConsolidableEvents`, request);
};

export const getDowntimeReasons = async (
  request: ConsolidableEventsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'organizations.downtimeReasons',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDowntimeReasons`, request);
};

export const deleteOrganizationService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.organizationName}`,
    object: 'organizations.organization',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const saveOrganizationService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.organizationName}`,
    object: 'organizations.organization',
    action: request.organizationId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const getContactsService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.organizationName}`,
    object: 'organizations.organization',
    action: 'Get',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getContacts`, request);
};

export const saveLoginextConfigurationService = (
  request: loginextConfiguration,
  configAction: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.organizationName}`,
    object: 'organizations.activeLoginext',
    action: 'Edit',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/saveLoginextConfiguration`, request);
};
