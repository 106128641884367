/* -------------------------------------------------------------------------- */
/*                           Tipos del reductor Auth                          */
/* -------------------------------------------------------------------------- */

export const AUTHENTICATION_STARTED = 'AUTHENTICATION_STARTED';
export const AUTHENTICATION_COMPLETED = 'AUTHENTICATION_COMPLETED';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

export const AUTHENTICATION_FAILED_CLEAR = 'AUTHENTICATION_FAILED_CLEAR';
export const AUTHENTICATION_IDENTITY_CLEARED = 'AUTHENTICATION_IDENTITY_CLEARED';

export const AUTHENTICATION_SET_MEMBERSHIP = 'AUTHENTICATION_SET_MEMBERSHIP';

export const AUTHENTICATION_USER_INFORMATION_STARTED = 'AUTHENTICATION_USER_INFORMATION_STARTED';
export const AUTHENTICATION_USER_INFORMATION_COMPLETED =
  'AUTHENTICATION_USER_INFORMATION_COMPLETED';

export const TOKEN_REFRESH_STARTED = 'TOKEN_REFRESH_STARTED';
export const TOKEN_REFRESH_FORCE_STARTED = 'TOKEN_REFRESH_FORCE_STARTED';
export const TOKEN_REFRESH_COMPLETED = 'TOKEN_REFRESH_COMPLETED';
export const TOKEN_REFRESH_FAILED = 'TOKEN_REFRESH_FAILED';

export const PROFILE_UPDATE_GENERAL_INFORMATION_STARTED =
  'PROFILE_UPDATE_GENERAL_INFORMATION_STARTED';
export const PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED =
  'PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED';
export const PROFILE_UPDATE_GENERAL_INFORMATION_FAILED =
  'PROFILE_UPDATE_GENERAL_INFORMATION_FAILED';

export const PROFILE_UPDATE_PASSWORD_STARTED = 'PROFILE_UPDATE_PASSWORD_STARTED';
export const PROFILE_UPDATE_PASSWORD_COMPLETED = 'PROFILE_UPDATE_PASSWORD_COMPLETED';
export const PROFILE_UPDATE_PASSWORD_FAILED = 'PROFILE_UPDATE_PASSWORD_FAILED';

export const PROFILE_UPDATE_PICTURE_STARTED = 'PROFILE_UPDATE_PICTURE_STARTED';
export const PROFILE_UPDATE_PICTURE_COMPLETED = 'PROFILE_UPDATE_PICTURE_COMPLETED';
export const PROFILE_UPDATE_PICTURE_FAILED = 'PROFILE_UPDATE_PICTURE_FAILED';

export const PROFILE_UPDATE_MAP_OPTIONS_STARTED = 'PROFILE_UPDATE_MAP_OPTIONS_STARTED';
export const PROFILE_UPDATE_MAP_OPTIONS_COMPLETED = 'PROFILE_UPDATE_MAP_OPTIONS_COMPLETED';
export const PROFILE_UPDATE_MAP_OPTIONS_FAILED = 'PROFILE_UPDATE_MAP_OPTIONS_FAILED';

export const PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_STARTED =
  'PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_STARTED';
export const PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED =
  'PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED';
export const PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_FAILED =
  'PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_FAILED';

export const PROFILE_UPDATE_HOME_SCREEN_STARTED = 'PROFILE_UPDATE_HOME_SCREEN_STARTED';
export const PROFILE_UPDATE_HOME_SCREEN_COMPLETED = 'PROFILE_UPDATE_HOME_SCREEN_COMPLETED';
export const PROFILE_UPDATE_HOME_SCREEN_FAILED = 'PROFILE_UPDATE_HOME_SCREEN_FAILED';

export const PROFILE_CHANGE_PRODUCT_STARTED = 'PROFILE_CHANGE_PRODUCT_STARTED';
export const PROFILE_CHANGE_PRODUCT_COMPLETED = 'PROFILE_CHANGE_PRODUCT_COMPLETED';
export const PROFILE_CHANGE_PRODUCT_FAILED = 'PROFILE_CHANGE_PRODUCT_FAILED';
