/* -------------------------------------------------------------------------- */
/*                                Reducer Auth                                */
/* -------------------------------------------------------------------------- */
// Este reducer contiene toda la información del usuario en sesión.

import jwtDecode from 'jwt-decode';
import { combineReducers } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as types from '../../types/auth';
import { tsNullKeyword } from '@babel/types';

const token = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return null;
    }
    case types.AUTHENTICATION_COMPLETED: {
      return action.payload.token;
    }
    case types.TOKEN_REFRESH_COMPLETED: {
      return action.payload.newToken;
    }
    case types.TOKEN_REFRESH_FAILED: {
      return action.payload.tokenRefreshFailCount && tokenRefreshFailCount >= 2 ? null : state;
    }
    case types.AUTHENTICATION_FAILED: {
      return null;
    }
    case types.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const tokenRefreshFailCount = (state = null, action) => {
  switch (action.type) {
    case types.TOKEN_REFRESH_COMPLETED: {
      return 0;
    }
    case types.TOKEN_REFRESH_FAILED: {
      return state + 1;
    }
    default: {
      return state;
    }
  }
};

const user = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return null;
    }
    case types.AUTHENTICATION_COMPLETED: {
      return camelcaseKeys(jwtDecode(action.payload.token));
    }
    case types.TOKEN_REFRESH_COMPLETED: {
      return camelcaseKeys(jwtDecode(action.payload.newToken));
    }
    case types.AUTHENTICATION_FAILED: {
      return null;
    }
    case types.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED:
      return { ...state, ...action.payload };
    case types.PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED:
      return { ...state, ...action.payload };
    case types.PROFILE_UPDATE_MAP_OPTIONS_COMPLETED: {
      const mapOptions = { ...action.payload };
      console.log({ mapOptions });

      return {
        ...state,
        enableLocationClustering: mapOptions.enableLocationClustering ? 1 : 0,
        locationMapLabelAlwaysOn: mapOptions.locationMapLabelAlwaysOn ? 1 : 0,
        activateUnitTrackMode: mapOptions.activateUnitTrackMode ? 1 : 0,
        unitMapLabelAlwaysOn: mapOptions.unitMapLabelAlwaysOn ? 1 : 0,
        unitsLabelContent: mapOptions.unitsLabelContent,
        zoomLevelToStartClustering: mapOptions.zoomLevelToStartClustering
      };
    }

    case types.PROFILE_UPDATE_PICTURE_COMPLETED: {
      const newImage = { ...action.payload };
      return {
        ...state,
        imageName: newImage.imageName,
        userImageUrl: newImage.userImageUrl
      };
    }

    case types.PROFILE_UPDATE_HOME_SCREEN_COMPLETED: {
      const homeScreenId = action.payload;
      return {
        ...state,
        homeScreenId: homeScreenId
      };
    }

    default: {
      return state;
    }
  }
};

const membership = (state = {}, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    case types.AUTHENTICATION_SET_MEMBERSHIP: {
      const membership = action.payload;
      return membership;
    }
    default: {
      return state;
    }
  }
};

const navigationOptions = (state = { productId: 1 }, action) => {
  switch (action.type) {
    case types.PROFILE_CHANGE_PRODUCT_COMPLETED: {
      const productId = action.payload;
      return {
        ...state,
        productId
      };
    }

    default: {
      return state;
    }
  }
};

const isAuthenticating = (state = false, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return true;
    }
    case types.AUTHENTICATION_COMPLETED: {
      return false;
    }
    case types.AUTHENTICATION_FAILED: {
      return false;
    }
    case types.AUTHENTICATION_USER_INFORMATION_STARTED: {
      return true;
    }
    case types.AUTHENTICATION_USER_INFORMATION_COMPLETED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingProfileGeneralInformation = (state = false, action) => {
  switch (action.type) {
    case types.PROFILE_UPDATE_GENERAL_INFORMATION_STARTED: {
      return true;
    }
    case types.PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED:
    case types.PROFILE_UPDATE_GENERAL_INFORMATION_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingMapSettingsConfiguration = (state = false, action) => {
  switch (action.type) {
    case types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_STARTED: {
      return true;
    }
    case types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED:
    case types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingProfileMapOptions = (state = false, action) => {
  switch (action.type) {
    case types.PROFILE_UPDATE_MAP_OPTIONS_STARTED: {
      return true;
    }

    case types.PROFILE_UPDATE_MAP_OPTIONS_COMPLETED:
    case types.PROFILE_UPDATE_MAP_OPTIONS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingProfilePassword = (state = false, action) => {
  switch (action.type) {
    case types.PROFILE_UPDATE_PASSWORD_STARTED: {
      return true;
    }

    case types.PROFILE_UPDATE_PASSWORD_COMPLETED:
    case types.PROFILE_UPDATE_PASSWORD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRefreshing = (state = false, action) => {
  switch (action.type) {
    case types.TOKEN_REFRESH_STARTED: {
      return true;
    }
    case types.TOKEN_REFRESH_COMPLETED: {
      return false;
    }
    case types.TOKEN_REFRESH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const refreshingError = (state = null, action) => {
  switch (action.type) {
    case types.TOKEN_REFRESH_STARTED: {
      return null;
    }
    case types.TOKEN_REFRESH_COMPLETED: {
      return null;
    }
    case types.TOKEN_REFRESH_FAILED: {
      return action.payload.error;
    }
    default: {
      return state;
    }
  }
};

const error = (state = null, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_STARTED: {
      return null;
    }
    case types.AUTHENTICATION_COMPLETED: {
      return null;
    }
    case types.AUTHENTICATION_FAILED_CLEAR: {
      return null;
    }
    case types.AUTHENTICATION_FAILED: {
      return action.payload.error;
    }
    default: {
      return state;
    }
  }
};

const auth = combineReducers({
  token,
  user,
  membership,
  navigationOptions,
  isAuthenticating,
  isUpdatingMapSettingsConfiguration,
  isUpdatingProfileGeneralInformation,
  isUpdatingProfilePassword,
  isUpdatingProfileMapOptions,
  isRefreshing,
  error,
  refreshingError,
  tokenRefreshFailCount
});

const authPersistConfig = {
  key: 'auth',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'isUpdatingProfilePassword',
    'isUpdatingProfileGeneralInformation',
    'isUpdatingMapSettingsConfiguration',
    'isUpdatingProfileMapOptions',
    'isAuthenticating'
  ]
  // blackList: [],
  // whiteList: [],
};

export default persistReducer(authPersistConfig, auth);

//Own state
export const getOwnState = (state) => state.auth;

export const getUser = (state) => getOwnState(state).user;

//Information
export const getAuthToken = (state) => getOwnState(state).token;
export const getIsAuthenticating = (state) => getOwnState(state).isAuthenticating;
//Updating profile
export const getIsUpdatingProfileInformation = (state) =>
  getOwnState(state).isUpdatingProfileGeneralInformation;
export const getisUpdatingMapSettingsConfiguration = (state) =>
  getOwnState(state).isUpdatingMapSettingsConfiguration;
export const getIsUpdatingProfileMapOptions = (state) =>
  getOwnState(state).isUpdatingProfileMapOptions;
export const getIsUpdatingProfilePassword = (state) => getOwnState(state).isUpdatingProfilePassword;
export const getAuthenticatingError = (state) => getOwnState(state).error;
export const getAuthUserID = (state) =>
  getOwnState(state).user ? getOwnState(state).user.user_id : null;
export const getAuthExpiration = (state) =>
  getOwnState(state).user ? getOwnState(state).user.exp : null;
export const getAuthUsername = (state) =>
  getOwnState(state).user ? getOwnState(state).user.username : null;
export const getAuthUser = (state) => getOwnState(state).user ?? {};
export const getAuthUserForMembership = (state) => ({
  ...(getOwnState(state).user ?? {}),
  ...(getOwnState(state).membership ?? {})
});

export const getAuthUserTimezoneName = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.zoneName
      ? getOwnState(state).user.zoneName
      : Intl.DateTimeFormat().resolvedOptions().timeZone
    : Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getAuthUserCountryIdsForGoogleSearch = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.countriesForGoogleSearch
      ? getOwnState(state).user.countriesForGoogleSearch
      : null
    : null;

/**
 * Get auth user map center
 * @param {*} state State from Redux
 * @returns {LatLngExpression}
 */
export const getAuthUserMapHomeCenter = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.homeLatitude && getOwnState(state).user.homeLongitude
      ? [getOwnState(state).user.homeLatitude, getOwnState(state).user.homeLongitude]
      : [14.62, -90.53]
    : [14.62, -90.53];
export const getAuthUserMapHomeZoom = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.homeZoom
      ? getOwnState(state).user.homeZoom
      : 12
    : 12;
export const isAuthenticated = (state) => getAuthToken(state) != null;
export const getAuthUserPermissions = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user?.permissions == ''
      ? []
      : getOwnState(state).user?.permissions.split(',')
    : [];

export const getAuthUserOrganizationId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.organizationId
      ? getOwnState(state).user.organizationId
      : null
    : null;

export const getAuthUserDivisionId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.divisionId
      ? getOwnState(state).user.divisionId
      : null
    : null;

export const getAuthUserSubdivisionId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.subdivisionId
      ? getOwnState(state).user.subdivisionId
      : null
    : null;

export const getAuthUserAccessLevelId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.accessLevelId
      ? getOwnState(state).user.accessLevelId
      : null
    : null;

export const getAuthUserHomeScreenId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user?.homeScreenId
      ? parseInt(getOwnState(state).user?.homeScreenId)
      : null
    : null;

export const getNavigationProduct = (state) =>
  getOwnState(state).navigationOptions
    ? getOwnState(state).navigationOptions?.productId
      ? parseInt(getOwnState(state).navigationOptions?.productId)
      : null
    : null;

export const getAuthUserMembershipFilters = (state, { filterOperatorId, filterOrganizationId }) => {
  const {
    accessLevelId,
    operatorId: userOperatorId,
    organizationId: userOrganizationId
  } = getAuthUserForMembership(state);

  if ([9, 11, 13, 15].includes(parseInt(accessLevelId))) {
    if (filterOperatorId && !filterOrganizationId)
      return {
        operatorId: filterOperatorId,
        organizationId: null
      };
    else if (!filterOperatorId && filterOrganizationId)
      return {
        operatorId: organizationSelectors.getOrganization(state, filterOrganizationId)?.operatorId,
        organizationId: filterOrganizationId
      };
    else if (!filterOperatorId && !filterOrganizationId) {
      return {
        //NOTA: Anteriormente se tenia los valores quemados del usuario
        operatorId: null,
        organizationId: null
      };
    } else
      return {
        operatorId: filterOperatorId,
        organizationId: filterOrganizationId
      };
  } else
    return {
      allowNullOrganizationId: true,
      operatorId: null,
      organizationId: null
    };
};

export const getAuthUserOperatorId = (state) =>
  getOwnState(state).user
    ? getOwnState(state).user.operatorId
      ? getOwnState(state).user.operatorId
      : null
    : null;
export const getAuthUserHasPermissionsAny = (state, permissions = []) =>
  permissions.some((userPer) => getAuthUserPermissions(state).includes(userPer));

export const getAuthUserHasPermissionByAccessLevels = (state, accessLevels = []) =>
  accessLevels.includes(parseInt(getAuthUser(state).accessLevelId));

export const isAuthUserSystemOrOperator = (state) =>
  [9, 13, 15].includes(parseInt(getAuthUser(state).accessLevelId));

export const getAuthUserHasPermissionsEvery = (state, permissions = []) =>
  permissions.every((userPer) => getAuthUserPermissions(state).includes(userPer));

//Systems
//   -------------------------------------------------------------------
//  | Metric      | Metric System 1 | Metric System 2 | Metric System 3 |
//   -------------------------------------------------------------------
//  | distance    |      km         |      km         |      mi         |
//  | capacity    |      gal        |      lt         |      gal        |
//  | temperature |       C         |       C         |       F         |
//  | distance2   |       m         |       m         |      pies       |
//  | speed       |      km/h       |      km/h       |      mph        |
//  | fuel        |     km/gal      |      km/l       |      mpg        |
//  | fuel2       |      g/h        |      l/h        |      g/h        |
//   -------------------------------------------------------------------
//
//  IMPORTANTE: La base de datos utiliza Metric System 2
//  1-> Centroamericano
//  2-> Metrico Decimal
//  3-> U.S

export const getAuthSystems = (state) => {
  const distance = getOwnState(state)?.user?.distanceSystem;
  const capacity = getOwnState(state)?.user?.capacitySystem;

  return {
    distance,
    capacity,
    volume: getOwnState(state)?.user?.volumeSystem,
    temperature: getOwnState(state)?.user?.temperatureSystem,
    distance2: getOwnState(state)?.user?.distance2System,
    speed: getOwnState(state)?.user?.speedSystem,
    fuel: getOwnState(state)?.user?.fuelSystem,
    fuel2: getOwnState(state)?.user?.fuel2System,
    performance: `${distance}/${capacity}`
  };
};

export const getParsers = (state) => ({
  /* -------------------------------------------------------------------------- */
  /*                                  Distance                                  */
  /* -------------------------------------------------------------------------- */
  /**
   * Parser de Metros -> Sistema métrico del usuario (Kilometros o Millas)
   */
  convertMetersToAuthUserDistanceSystem: (meters) =>
    meters / (getAuthUser(state)?.metricSystemId == 3 ? 1609 : 1000),
  /**
   * Parser de Kilometros -> Sistema métrico del usuario (Kilometros o Millas)
   */
  convertKilometersToAuthUserDistanceSystem: (kilometers) =>
    kilometers / (getAuthUser(state)?.metricSystemId == 3 ? 1.609 : 1),
  /**
   * Parser de Sistema métrico del usuario (Kilometros o Millas) -> Metros
   */
  convertAuthUserDistanceSystemToMeters: (authUserDistanceSystem) =>
    authUserDistanceSystem * (getAuthUser(state)?.metricSystemId == 3 ? 1609 : 1000),

  /* Pasar de sistema métrico del usuario (Kilometros o Millas) -> Kilometros */
  convertAuthUserDistanceSystemToKilometers: (authUserDistanceSystem) =>
    authUserDistanceSystem * (getAuthUser(state)?.metricSystemId == 3 ? 1.609 : 1),

  /* -------------------------------------------------------------------------- */
  /*                                  Capacity                                  */
  /* -------------------------------------------------------------------------- */
  //   -------------------------------------------------------------------
  //  | Metric      | Metric System 1 | Metric System 2 | Metric System 3 |
  //   -------------------------------------------------------------------
  //  | capacity    |      gal        |      lt         |      gal        |
  //   -------------------------------------------------------------------
  /**
   * Parser de Galones -> Sistema de capacidad del usuario (Galones o Litros)
   */
  convertGalonsToAuthUserCapacitySystem: (gal) =>
    gal * (getAuthUser(state)?.metricSystemId == 2 ? 3.78541 : 1),
  /**
   * Parser de Sistema de capacidad del usuario (Galones o Litros) -> Galones
   */
  convertAuthUserCapacitySystemToGalons: (authUserCapacitySystem) =>
    authUserCapacitySystem / (getAuthUser(state)?.metricSystemId == 2 ? 3.78541 : 1),
  /**
   * Parser de Litros -> Sistema de capacidad del usuario (Galones o Litros)
   */
  convertLitersToAuthUserCapacitySystem: (lt) =>
    lt / (getAuthUser(state)?.metricSystemId == 2 ? 1 : 3.78541),
  /**
   * Parser de Sistema de capacidad del usuario (Galones o Litros) -> Litros
   */
  convertAuthUserCapacitySystemToLiters: (authUserCapacitySystem) =>
    authUserCapacitySystem * (getAuthUser(state)?.metricSystemId == 2 ? 1 : 3.78541),

  /* -------------------------------------------------------------------------- */
  /*                                   Volume                                   */
  /* -------------------------------------------------------------------------- */
  /**
   * Parser de m³ -> Sistema de volumen del usuario (m³ o yd³)
   */
  convertCubicMetersToAuthUserVolumeSystem: (cubicMeters) =>
    cubicMeters / (getAuthUser(state)?.metricSystemId == 3 ? 0.764555 : 1),
  /**
   * Parser de Sistema de volumen del usuario (m³ o yd³) -> m³
   */
  convertAuthUserVolumeSystemToCubicMeters: (authUserVolumeSystem) =>
    authUserVolumeSystem * (getAuthUser(state)?.metricSystemId == 3 ? 0.764555 : 1),
  /**
   * Parser de Sistema de volumen del usuario (m³ o yd³) -> cm³
   */
  convertAuthUserVolumeSystemToCubicCentimeters: (authUserVolumeSystem) =>
    authUserVolumeSystem * (getAuthUser(state)?.metricSystemId == 3 ? 0.764555 : 1) * 1000000,

  /**
   * Parser de metros cubicos a centimetros cubicos (m³) -> cm³
   */
  convertAuthUserVolumeSystemToCubicCentimeters: (authUserVolumeSystem) =>
    authUserVolumeSystem * 1000000,

  /* -------------------------------------------------------------------------- */
  /*                                 Temperature                                */
  /* -------------------------------------------------------------------------- */
  /**
   * Parser de Celsius -> Sistema de temperatura del usuario (Celsius o Fahrenheit)
   */
  convertCelsiusToAuthUserTemperatureSystem: (c) =>
    getAuthUser(state)?.metricSystemId == 3 ? (c * 9) / 5 + 32 : c,
  /**
   * Parser de Sistema de temperatura del usuario (Celsius o Fahrenheit) -> Celsius
   */
  convertAuthUserTemperatureSystemToCelsius: (authUserTemperatureSystem) =>
    getAuthUser(state)?.metricSystemId == 3
      ? (authUserTemperatureSystem - 32) * (5 / 9)
      : authUserTemperatureSystem,

  /* -------------------------------------------------------------------------- */
  /*                                  Distance2                                 */
  /* -------------------------------------------------------------------------- */
  /**
   * Parser de Metros -> Sistema métrico del usuario (Metros o Pies)
   */
  convertMetersToAuthUserDistance2System: (meters) =>
    meters * (getAuthUser(state)?.metricSystemId == 3 ? 3.281 : 1),
  /**
   * Parser de Sistema métrico del usuario (Metros o Pies) -> Metros
   */
  convertAuthUserDistance2SystemToMeters: (authUserDistance2System) =>
    authUserDistance2System / (getAuthUser(state)?.metricSystemId == 3 ? 3.281 : 1),

  /* -------------------------------------------------------------------------- */
  /*                                    Speed                                   */
  /* -------------------------------------------------------------------------- */
  /**
   * Parser de km/h -> Sistema de velocidad del usuario (km/h o mph)
   */
  convertKilometersPerHourToAuthUserSpeedSystem: (kilometersPerHour) =>
    kilometersPerHour / (getAuthUser(state)?.metricSystemId == 3 ? 1.609 : 1),
  /**
   * Parser de Sistema de velocidad del usuario (km/h o mph) -> km/h
   */
  convertAuthUserSpeedSystemToKilometersPerHour: (authUserSpeedSystem) =>
    authUserSpeedSystem * (getAuthUser(state)?.metricSystemId == 3 ? 1.609 : 1),

  /* -------------------------------------------------------------------------- */
  /*                                    Fuel                                    */
  /* -------------------------------------------------------------------------- */
  //   -------------------------------------------------------------------
  //  | Metric      | Metric System 1 | Metric System 2 | Metric System 3 |
  //   -------------------------------------------------------------------
  //  | fuel        |     km/gal      |      km/l       |      mpg        |
  //   -------------------------------------------------------------------
  /**
   * Parser de km/gal -> Sistema de capacidad del usuario (km/gal o km/l o mpg)
   */
  convertKilometersPerGalonToAuthUserFuelSystem: (kmPerGal) =>
    getAuthUser(state)?.metricSystemId == 2
      ? kmPerGal / 3.7854
      : getAuthUser(state)?.metricSystemId == 3
      ? kmPerGal / 1.609
      : kmPerGal,
  /**
   * Parser de Sistema de capacidad del usuario (km/gal o km/l o mpg) -> km/gal
   */
  convertAuthUserFuelSystemToKilometersPerGalon: (authUserFuelSystem) =>
    getAuthUser(state)?.metricSystemId == 2 //km/lt
      ? authUserFuelSystem * 3.7854
      : getAuthUser(state)?.metricSystemId == 3 //mpg
      ? authUserFuelSystem * 1.609
      : authUserFuelSystem,
  /**
   * Parser de km/gal -> Sistema de capacidad del usuario (km/gal o km/l o mpg)
   */
  convertKilometersPerLiterToAuthUserFuelSystem: (kmPerLiter) =>
    getAuthUser(state)?.metricSystemId == 1 //km/gal
      ? kmPerLiter * 3.7854
      : getAuthUser(state)?.metricSystemId == 3 //mpg
      ? (kmPerLiter * 3.7854) / 1.609
      : kmPerLiter,
  /**
   * Parser de Sistema de capacidad del usuario (km/gal o km/l o mpg) -> km/gal
   */
  convertAuthUserFuelSystemToKilometersPerLiter: (authUserFuelSystem) =>
    getAuthUser(state)?.metricSystemId == 1 //km/gal
      ? authUserFuelSystem / 3.7854
      : getAuthUser(state)?.metricSystemId == 3 //mpg
      ? (authUserFuelSystem / 3.7854) * 1.609
      : authUserFuelSystem,
  /* -------------------------------------------------------------------------- */
  /*                                    Fuel2                                   */
  /* -------------------------------------------------------------------------- */
  //   -------------------------------------------------------------------
  //  | Metric      | Metric System 1 | Metric System 2 | Metric System 3 |
  //   -------------------------------------------------------------------
  //  | fuel2       |      g/h        |      l/h        |      g/h        |
  //   -------------------------------------------------------------------
  /**
   * Parser de g/h -> Sistema de capacidad del usuario (g/h o l/h)
   */
  convertGalonsPerHourToAuthUserFuel2System: (galPerHour) =>
    galPerHour * (getAuthUser(state)?.metricSystemId == 2 ? 3.7854 : 1),
  /**
   * Parser de Sistema de capacidad del usuario (g/h o l/h) -> g/h
   */
  convertAuthUserFuel2SystemToGalonsPerHour: (authUserFuel2System) =>
    authUserFuel2System / (getAuthUser(state)?.metricSystemId == 2 ? 3.7854 : 1),
  /**
   * Parser de g/h -> Sistema de capacidad del usuario (g/h o l/h)
   */
  convertLitersPerHourToAuthUserFuel2System: (litersPerHour) =>
    litersPerHour / (getAuthUser(state)?.metricSystemId == 2 ? 1 : 3.7854),
  /**
   * Parser de Sistema de capacidad del usuario (g/h o l/h) -> g/h
   */
  convertAuthUserFuel2SystemToLitersPerHour: (authUserFuel2System) =>
    authUserFuel2System * (getAuthUser(state)?.metricSystemId == 2 ? 1 : 3.7854)
});

//Status of sagas
export const getIsRefreshingToken = (state) => getOwnState(state).isRefreshing;

//Errors
export const getRefreshingError = (state) => getOwnState(state).refreshingError;

//Errors
export const getTokenRefreshFailCount = (state) => getOwnState(state).tokenRefreshFailCount;
