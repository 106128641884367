const permissions = {
  listEmailNotification: 'NT001',
  addEmailNotification: 'NT004',
  removeEmailNotification: 'NT005',

  listConsoleNotification: 'NT002',
  addConsoleNotification: 'NT006',
  removeConsoleNotification: 'NT008',

  listWebServiceNotification: 'NT003',
  addWebServiceNotification: 'NT007',
  removeWebServiceNotification: 'NT009'
};

export default permissions;
