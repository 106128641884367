export const SENSORS_FETCH_STARTED = 'SENSORS_FETCH_STARTED';
export const SENSORS_FETCH_COMPLETED = 'SENSORS_FETCH_COMPLETED';
export const SENSORS_FETCH_FAILED = 'SENSORS_FETCH_FAILED';

export const SENSORS_PARTIAL_FETCH_STARTED = 'SENSORS_PARTIAL_FETCH_STARTED';
export const SENSORS_PARTIAL_FETCH_COMPLETED = 'SENSORS_PARTIAL_FETCH_COMPLETED';
export const SENSORS_PARTIAL_FETCH_FAILED = 'SENSORS_PARTIAL_FETCH_FAILED';

export const SENSOR_FETCH_STARTED = 'SENSOR_FETCH_STARTED';
export const SENSOR_FETCH_COMPLETED = 'SENSOR_FETCH_COMPLETED';
export const SENSOR_FETCH_FAILED = 'SENSOR_FETCH_FAILED';

export const SENSOR_ADD_STARTED = 'SENSOR_ADD_STARTED';
export const SENSOR_ADD_COMPLETED = 'SENSOR_ADD_COMPLETED';
export const SENSOR_ADD_FAILED = 'SENSOR_ADD_FAILED';

export const SENSOR_EDIT_STARTED = 'SENSOR_EDIT_STARTED';
export const SENSOR_EDIT_COMPLETED = 'SENSOR_EDIT_COMPLETED';
export const SENSOR_EDIT_FAILED = 'SENSOR_EDIT_FAILED';

export const SENSOR_REMOVE_STARTED = 'SENSOR_REMOVE_STARTED';
export const SENSOR_REMOVE_COMPLETED = 'SENSOR_REMOVE_COMPLETED';
export const SENSOR_REMOVE_FAILED = 'SENSOR_REMOVE_FAILED';

export const SENSOR_SELECTED = 'SENSOR_SELECTED';
export const SENSORS_ALL_SELECTED = 'SENSORS_ALL_SELECTED';
export const SENSOR_DESELECTED = 'SENSOR_DESELECTED';
export const SENSORS_ALL_DESELECTED = 'SENSORS_ALL_DESELECTED';

export const IDENTIFIER_CHECK_STARTED = 'IDENTIFIER_CHECK_STARTED';
export const IDENTIFIER_CHECK_COMPLETED = 'IDENTIFIER_CHECK_COMPLETED';
export const IDENTIFIER_CHECK_FAILED = 'IDENTIFIER_CHECK_FAILED';

export const START_SENSOR_CALIBRATION_STARTED = 'START_SENSOR_CALIBRATION_STARTED';
export const START_SENSOR_CALIBRATION_COMPLETED = 'START_SENSOR_CALIBRATION_COMPLETED';
export const START_SENSOR_CALIBRATION_FAILED = 'START_SENSOR_CALIBRATION_FAILED';

export const FINALIZE_SENSOR_CALIBRATION_STARTED = 'FINALIZE_SENSOR_CALIBRATION_STARTED';
export const FINALIZE_SENSOR_CALIBRATION_COMPLETED = 'FINALIZE_SENSOR_CALIBRATION_COMPLETED';
export const FINALIZE_SENSOR_CALIBRATION_FAILED = 'FINALIZE_SENSOR_CALIBRATION_FAILED';
