/* -------------------------------------------------------------------------- */
/*                            Componente TextInput                            */
/* -------------------------------------------------------------------------- */
// Este componente contiene un textfield genérico que se utiliza en muchas pantallas de la aplicación.

import React, { useEffect, useState } from 'react';
import {
  Label,
  Input,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

interface Props {
  id: any;
  label?: any;
  field: any;
  fieldstate: any;
  type?: any;
  required?: any;
  placeholder?: string;
  errorMessage?: any;
  errorinvalidmessage?: any;
  errorvalidatemessage?: any;
  autocompleteinput?: string;
  topRightComponent?: any;
  prependcomponent?: any;
  appendcomponent?: any;
  forceinitialvalue?: string;
  disabled?: boolean;
  additionalonchangefunction?: Function;
  onFocus?: () => void;
}

const TextInput = (props: Props) => {
  const {
    id,
    label,
    required,
    placeholder,
    field,
    fieldstate,
    type = 'text',
    autocompleteinput = 'off',
    topRightComponent,
    prependcomponent,
    appendcomponent,
    disabled,
    errorMessage,
    errorinvalidmessage,
    errorvalidatemessage,
    forceinitialvalue,
    additionalonchangefunction
  } = props;

  useEffect(() => {
    if (forceinitialvalue != null) onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };

  return (
    <FormGroup>
      {label && (
        <Label className="form-label" for={id}>
          {label}
          {required && <span className="required">&nbsp;*</span>}
          {topRightComponent != null && topRightComponent}
        </Label>
      )}
      <InputGroup>
        {prependcomponent && (
          <InputGroupAddon addonType="prepend">
            <InputGroupText>{prependcomponent}</InputGroupText>
          </InputGroupAddon>
        )}
        <Input
          {...{
            ..._.omit({ ...props }, [
              'additionalonchangefunction',
              'forceinitialvalue',
              'defaultValue'
            ])
          }}
          value={field.value ?? ''}
          onBlur={field.onBlur}
          onChange={(event) => {
            onChangeFinal(event.target.value);
          }}
          onFocus={props.onFocus}
          id={id}
          placeholder={placeholder}
          autoComplete={autocompleteinput}
          type={type}
          invalid={fieldstate.error != null}
          disabled={disabled}
        />
        {appendcomponent && (
          <InputGroupAddon addonType="append">
            <InputGroupText>{appendcomponent}</InputGroupText>
          </InputGroupAddon>
        )}
      </InputGroup>
      {fieldstate.error && fieldstate.error.type === 'required' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      ) : fieldstate.error && fieldstate.error.type === 'pattern' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorinvalidmessage || (
            <FormattedMessage id="common.invalidField" />
          )}
        </div>
      ) : fieldstate.error && fieldstate.error.type === 'validate' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorvalidatemessage || (
            <FormattedMessage id="common.invalidField" />
          )}
        </div>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

TextInput.defaultProps = {};

export default TextInput;
