import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../settings/apibaseurl';
import * as actions from '../../../actions/static-catalogs/sensor-models';
import * as types from '../../../types/static-catalogs/sensor-models';
import * as selectors from '../../../reducers/static-catalogs/sensor-models';
import * as schemas from '../../../schemas/sensor-models';
import * as authSelectors from '../../../reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../utils/alerts';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchSensorModelsList(action) {
  const brandId = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/sensorModel/get`, {
        method: 'POST',
        body: JSON.stringify({ brandId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sensorModels },
          result //order
        } = normalize(resultData, schemas.sensorModels); //normalize data to byId and order
        yield put(actions.completeFetchingSensorModels(sensorModels, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingSensorModels(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingSensorModels(error));
  }
}

export function* watchFetchSensorModelsList() {
  yield takeEvery(types.SENSOR_MODELS_FETCH_STARTED, fetchSensorModelsList);
}
