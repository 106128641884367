/* -------------------------------------------------------------------------- */
/*                       Schema Execution Workplans                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const workplanExecution = new schema.Entity(
  'workplansExecutions',
  {},
  { idAttribute: 'visitId' }
);
export const workplansExecutions = new schema.Array(workplanExecution);
