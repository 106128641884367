/* -------------------------------------------------------------------------- */
/*                            Schema Service Classes                          */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const serviceClass = new schema.Entity(
  "serviceClasses",
  {},
  { idAttribute: "serviceClassId" }
);
export const serviceClasses = new schema.Array(serviceClass);
