import { env } from 'env';
import oldEn from './en.json'; // Este debería desaparecer conforme vayamos avanzando
import alertsEn from './events-en.json';
//Idiomas de cada módulo
//COMPONENTES
import fileUploadi18n from 'views/components/FileUploader/i18n/en.json';
import paymentMethodsi18n from 'views/formComponents/PaymentMethodsDropDown/i18n/en.json';

//PANTALLAS
import vehicleFueli18n from 'views/screens/VehicleFuel/i18n/en.json';
import workplansi18n from 'views/screens/Workplans/i18n/en.json';
import routesi18n from 'views/screens/Routes/i18n/en.json';
import schedulesi18n from 'views/screens/Schedules/i18n/en.json';
import mapi18n from 'views/components/Map/i18n/en.json';
import operatorsi18n from 'views/screens/Operators/i18n/en.json';
import organizationsi18n from 'views/screens/Organizations/i18n/en.json';
import divisionsi18n from 'views/screens/Divisions/i18n/en.json';
import subdivisionsi18n from 'views/screens/Subdivisions/i18n/en.json';
import agentsi18n from 'views/screens/Agents/i18n/en.json';
import serviceClassesi18n from 'views/screens/ServiceClasses/i18n/en.json';
import modulesi18n from 'views/screens/Modules/i18n/en.json';
import vehiclesi18n from 'views/screens/Vehicles/i18n/en.json';
import assetsi18n from 'views/screens/Assets/i18n/en.json';
import usersi18n from 'views/screens/Users/i18n/en.json';
import simsi18n from 'views/screens/SIMs/i18n/en.json';
import locationi18n from 'views/screens/Locations/i18n/en.json';
import driveri18n from 'views/screens/Drivers/i18n/en.json';
import sensorsi18n from 'views/screens/Sensors/i18n/en.json';
import unitsi18n from 'views/screens/Units/i18n/en.json';
import vehicleMaintenancei18n from 'views/screens/vehicleMaintenance/i18n/en.json';
import maintenanceLinesi18n from 'views/screens/MaintenanceLines/i18n/en.json';
import formsi18n from 'views/screens/Forms/i18n/en.json';
import geofencesi18n from 'views/screens/Geofences/i18n/en.json';
import jobsi18n from 'views/screens/Jobs/i18n/en.json';
import safeDriveri18n from 'views/screens/SafeDriver/i18n/en.json';
import dashboardi18n from 'views/screens/Dashboard/i18n/en.json';
import trackingUnitsi18n from 'views/screens/TrackingUnits/i18n/en.json';
import reportsi18n from 'views/screens/Reports/i18n/en.json';
import peoplei18n from 'views/screens/People/i18n/en.json';
import notificationi18n from 'views/screens/Notifications/i18n/en.json';
import telemetryi18n from 'views/screens/Telemetry/i18n/en.json';
import operationsi18n from 'views/screens/Operations/i18n/en.json';
import groupsi18n from 'views/screens/Groups/i18n/en.json';
import consolei18n from 'views/screens/Console/i18n/en.json';
import contractsi18n from 'views/screens/Contracts/i18n/en.json';
import quotationsi18n from 'views/screens/Quotations/i18n/en.json';
import contractTemplatesi18n from 'views/screens/ContractTemplates/i18n/en.json';
import customersi18n from 'views/screens/Customers/i18n/en.json';
import officesi18n from 'views/screens/Offices/i18n/en.json';
import entitiesi18n from 'views/screens/Entities/i18n/en.json';
import optimizationsi18n from 'views/screens/Optimizations/i18n/en.json';
import businessPartneri18n from 'views/screens/BusinessPartners/i18n/en.json';
import protocolsi18n from 'views/screens/Protocols/i18n/en.json';

const en = {
  ...alertsEn,
  ...oldEn,
  ...vehicleFueli18n,
  ...workplansi18n,
  ...routesi18n,
  ...fileUploadi18n,
  ...mapi18n,
  ...operatorsi18n,
  ...organizationsi18n,
  ...divisionsi18n,
  ...subdivisionsi18n,
  ...agentsi18n,
  ...serviceClassesi18n,
  ...modulesi18n,
  ...vehiclesi18n,
  ...assetsi18n,
  ...usersi18n,
  ...simsi18n,
  ...locationi18n,
  ...driveri18n,
  ...sensorsi18n,
  ...unitsi18n,
  ...schedulesi18n,
  ...vehicleMaintenancei18n,
  ...maintenanceLinesi18n,
  ...paymentMethodsi18n,
  ...formsi18n,
  ...geofencesi18n,
  ...jobsi18n,
  ...safeDriveri18n,
  ...dashboardi18n,
  ...trackingUnitsi18n,
  ...reportsi18n,
  ...peoplei18n,
  ...notificationi18n,
  ...telemetryi18n,
  ...operationsi18n,
  ...groupsi18n,
  ...consolei18n,
  ...contractsi18n,
  ...customersi18n,
  ...entitiesi18n,
  ...contractTemplatesi18n,
  ...quotationsi18n,
  ...officesi18n,
  ...entitiesi18n,
  ...optimizationsi18n,
  ...businessPartneri18n,
  ...protocolsi18n
};

const replaceUnit = (obj) => {
  let newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string')
      newObj[key] = value
        .replace(/(Tracking Units|Tracking units|Units)/, 'Padlocks')
        .replace(/(tracking units|units)/, 'padlocks')
        .replace(/(Tracking unit|Tracking Unit|Unit)/, 'Padlock')
        .replace(/(tracking unit|unit$)/, 'padlock')
        .replace(/(unit )/, 'padlock ');
  }
  return newObj;
};

export default !!parseInt(env.REACT_APP_SAT_MONITOR ?? '0') ? replaceUnit(en) : en;
