import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/workplan';

export interface workplansAssignmentsFilters {
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  groupId?: number;
}

export interface saveWorkplanAssignmentByScheduleServiceFilters {
  workplanName?: string;
  assignmentId?: number;
  driverId?: number;
  scheduleId?: number;
  workplanDuration?: number;
  workplanTicket?: string;
  workplanComments?: string;
  workplanId?: number;
  unitId?: number;
}

export interface saveWorkplanAssignmentByDateTimeServiceFilters {
  workplanName?: string;
  assignmentId: number;
  startDate?: Date;
  endDate?: Date;
  workplanTicket?: string;
  workplanComments?: string;
  driverId?: number;
}

export const getWorkplansAssignmentsListService = (
  request: workplansAssignmentsFilters,
  configAction: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/getAssignments`, request);
};

export const saveWorkplanAssignmentByScheduleService = (
  request: saveWorkplanAssignmentByScheduleServiceFilters,
  configAction: ConfigActions,
  isNew: boolean
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.workplanName}`,
    object: 'workplans.workplan',
    action: isNew ? 'Create' : 'Edit',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/updateWorkplanAssignmentSchedule`, request);
};

export const assignWorkplanExecutionUnit = (request: any, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.workplanName}`,
    object: 'workplans.workplanAssignment',
    action: 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/assignUnit`, request);
};

export const saveWorkplanAssignmentByDateTimeService = (
  request: saveWorkplanAssignmentByDateTimeServiceFilters,
  configAction: ConfigActions,
  isNew: boolean
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.workplanName}`,
    object: 'workplans.workplan',
    action: isNew ? 'Create' : 'Edit',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/updateWorkplanAssignmentDateTime`, request);
};

export const deleteWorkplanAssignmentService = (request: any, configAction: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.assignmentName}`,
    object: 'workplans.assignment',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configAction);
  return httpWebtrack.post(`${baseUrl}/deleteWorkplanAssignment`, request);
};
