export const CATEGORIES_FETCH_STARTED = 'CATEGORIES_FETCH_STARTED';
export const CATEGORIES_FETCH_COMPLETED = 'CATEGORIES_FETCH_COMPLETED';
export const CATEGORIES_FETCH_FAILED = 'CATEGORIES_FETCH_FAILED';

export const CATEGORIES_PARTIAL_FETCH_STARTED = 'CATEGORIES_PARTIAL_FETCH_STARTED';
export const CATEGORIES_PARTIAL_FETCH_COMPLETED = 'CATEGORIES_PARTIAL_FETCH_COMPLETED';
export const CATEGORIES_PARTIAL_FETCH_FAILED = 'CATEGORIES_PARTIAL_FETCH_FAILED';

export const CATEGORY_ADD_STARTED = 'CATEGORY_ADD_STARTED';
export const CATEGORY_ADD_COMPLETED = 'CATEGORY_ADD_COMPLETED';
export const CATEGORY_ADD_FAILED = 'CATEGORY_ADD_FAILED';

export const CATEGORY_EDIT_STARTED = 'CATEGORY_EDIT_STARTED';
export const CATEGORY_EDIT_COMPLETED = 'CATEGORY_EDIT_COMPLETED';
export const CATEGORY_EDIT_FAILED = 'CATEGORY_EDIT_FAILED';

export const CATEGORY_REMOVE_STARTED = 'CATEGORY_REMOVE_STARTED';
export const CATEGORY_REMOVE_COMPLETED = 'CATEGORY_REMOVE_COMPLETED';
export const CATEGORY_REMOVE_FAILED = 'CATEGORY_REMOVE_FAILED';
