import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/categories';
import { CategoriesCatalogDTO } from 'views/screens/vehicleMaintenance/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CATEGORIES_FETCH_COMPLETED:
    case types.CATEGORIES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((categoryId) => {
        newState[categoryId] = {
          isSelected: false,
          ...state[categoryId],
          ...entities[categoryId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.CATEGORY_ADD_COMPLETED: {
      const { id, oldId, category } = action.payload;
      const newState = omit(state, oldId);
      newState[id] = {
        ...category,
        isConfirmed: true,
        isSelected: false
      };
      return newState;
    }

    case types.CATEGORY_EDIT_COMPLETED: {
      const category = action.payload;
      const id = category.categoryId;
      const newState = {
        ...state,
        [id]: {
          ...omit(state[id], ['oldCategory']),
          ...omit(category, ['oldCategory']),
          isConfirmed: true
        }
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.CATEGORY_ADD_STARTED: {
      const category = action.payload;
      const newState = { ...state };
      newState[category.tempId] = {
        isSelected: false,
        ...category,
        isConfirmed: false
      };
      return newState;
    }

    case types.CATEGORY_EDIT_STARTED: {
      const category = action.payload;
      const id = category.categoryId;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          oldCategory: state[id],
          isConfirmed: false,
          ...category
        }
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.CATEGORY_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.CATEGORY_EDIT_FAILED: {
      const { oldCategory } = action.payload;
      return {
        ...state,
        [oldCategory.categoryId]: {
          ...omit(state[oldCategory.categoryId], ['oldCategory']),
          ...oldCategory,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.CATEGORY_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return omit(state, id);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CATEGORIES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.CATEGORIES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.CATEGORY_ADD_COMPLETED: {
      const { id, oldId } = action.payload;
      return state.map((categoryId) => (categoryId === oldId ? id : categoryId));
    }

    case types.CATEGORY_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return state.filter((categoryIdState) => categoryIdState !== id);
    }

    //CASE ADD STARTED
    case types.CATEGORY_ADD_STARTED: {
      const category = action.payload;
      return [...state, category.tempId];
    }

    //CASE ADD FAILED
    case types.CATEGORY_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((categoryIdState) => categoryIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORIES_FETCH_STARTED: {
      return true;
    }
    case types.CATEGORIES_FETCH_COMPLETED:
    case types.CATEGORIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORIES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.CATEGORIES_PARTIAL_FETCH_COMPLETED:
    case types.CATEGORIES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORY_ADD_STARTED: {
      return true;
    }
    case types.CATEGORY_ADD_COMPLETED:
    case types.CATEGORY_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORY_EDIT_STARTED: {
      return true;
    }
    case types.CATEGORY_EDIT_COMPLETED:
    case types.CATEGORY_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORY_REMOVE_STARTED: {
      return true;
    }
    case types.CATEGORY_REMOVE_COMPLETED:
    case types.CATEGORY_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CATEGORIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CATEGORIES_FETCH_STARTED:
    case types.CATEGORIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CATEGORIES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CATEGORIES_PARTIAL_FETCH_STARTED:
    case types.CATEGORIES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.CATEGORY_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CATEGORY_ADD_STARTED:
    case types.CATEGORY_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.CATEGORY_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CATEGORY_EDIT_STARTED:
    case types.CATEGORY_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.CATEGORY_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CATEGORY_REMOVE_STARTED:
    case types.CATEGORY_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.CATEGORIES_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const categories = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

export default categories;

//Own state
export const getOwnState = (state) => state.categories;

//Information
export const getCategory = (state, id) => {
  const category = getOwnState(state).byId[id];

  return {
    ...category
  };
};

export const getCategoriesList = (state): Array<CategoriesCatalogDTO> =>
  getOwnState(state).order?.map((id) => getCategory(state, id));

export const getCategoriesListByMembership = (state, membership) => {
  //Se filtra por divisionId
  if (membership.organizationId) {
    return getCategoriesList(state).filter(
      (category) => category.organizationId === membership.organizationId
    );
  }
  //Si no se ha seleccionado una división se devuelve la lista completa
  else return getCategoriesList(state);
};

//Status of sagas
export const isPartialFetchingListCategories = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListCategories = (state) => getOwnState(state).isFetchingList;
export const haveCategoriesBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingCategory = (state) => getOwnState(state).isAdding;
export const isEditingCategory = (state) => getOwnState(state).isEditing;
export const isRemovingCategory = (state) => getOwnState(state).isRemoving;
//Errors
export const getPartialFetchingListCategoriesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListCategoriesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingCategoryErrors = (state) => getOwnState(state).errorAdding;
export const getEditingCategoryErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingCategoryErrors = (state) => getOwnState(state).errorRemoving;
