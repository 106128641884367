import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/routesAssignments';
import * as types from '../../types/routesAssignments';
import * as selectors from '../../reducers/routesAssignments';
import * as schemas from '../../schemas/routesAssignments';
import * as entitySelectors from '../../../Entities/reducers';
import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { store } from 'redux/storeConfig/store';
import {
  deleteAssignmentService,
  getRouteAssignmentsListService,
  importRouteAssignmentsService,
  saveAssignmentService
} from '../../services/routesAssignments';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchAssignmentsList(action) {
  const { startDate, endDate, organizationId, divisionId, subdivisionId } = action.payload;

  getRouteAssignmentsListService(
    { startDate, endDate, organizationId, divisionId, subdivisionId },
    {
      successAction: (response) => {
        const resultDataByDate = response.data.assignmentsByDate.map((result) => ({
          ...camelcaseKeys(result),
          typeId: 1
        }));
        const resultDataBySchedule = response.data.assignmentsBySchedule.map((result) => ({
          ...camelcaseKeys(result),
          typeId: 2
        }));
        const {
          entities: { assignmentsByDate },
          result: orderByDate //order
        } = normalize(resultDataByDate, schemas.assignmentsByDate); //normalize data to byId and order
        const {
          entities: { assignmentsBySchedule },
          result: orderBySchedule //order
        } = normalize(resultDataBySchedule, schemas.assignmentsBySchedule); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingAssignments(
            assignmentsByDate,
            orderByDate,
            assignmentsBySchedule,
            orderBySchedule
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingAssignments(error));
      }
    }
  );
}

export function* watchFetchAssignmentsList() {
  yield takeEvery(types.ROUTES_ASSIGNMENTS_FETCH_STARTED, fetchAssignmentsList);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editAssignment(action) {
  const assignment = action.payload;
  const { oldAssignment } = yield select((state) =>
    selectors.getRoutesAssignment(state, assignment.routeAssignmentId)
  );
  saveAssignmentService(
    {
      ...assignment,
      routeAssignmentId: oldAssignment.routeAssignmentId,
      routeId: oldAssignment.routeId,
      unitId: oldAssignment.unitId,
      scheduleId: oldAssignment.scheduleId,
      unitName: oldAssignment.unitName
    },
    {
      successAction: (response) => {
        const editedAssignment = camelcaseKeys(response.data);
        store.dispatch(
          actions.completeEditingAssignment(assignment.routeAssignmentId, editedAssignment)
        );
      },
      errorAction: (error) => {
        store.dispatch(
          actions.failEditingAssignment(assignment.routeAssignmentId, oldAssignment, error)
        );
      }
    }
  );
}

export function* watchEditAssignmentsStarted() {
  yield takeEvery(types.ROUTES_ASSIGNMENT_EDIT_STARTED, editAssignment);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeAssignment(action) {
  const { routeAssignmentId } = action.payload;
  const deletedAssignment = yield select((state) =>
    selectors.getRoutesAssignment(state, routeAssignmentId)
  );
  deleteAssignmentService(
    { ...deletedAssignment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingAssignment(routeAssignmentId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingAssignment(routeAssignmentId, error));
      }
    }
  );
}

export function* watchRemoveAssignmentStarted() {
  yield takeEvery(types.ROUTES_ASSIGNMENT_REMOVE_STARTED, removeAssignment);
}

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* importRouteAssignments(action) {
  const { routeAssignments } = action.payload;

  importRouteAssignmentsService(
    { ...routeAssignments },
    {
      successAction: (response) => {
        const resultDataByDate = response.data.map((result) => ({
          ...camelcaseKeys(result),
          typeId: 1
        }));
        const {
          entities: { assignmentsByDate },
          result: orderByDate //order
        } = normalize(resultDataByDate, schemas.assignmentsByDate); //normalize data to byId and order
        store.dispatch(actions.completeImportingRouteAssignments(assignmentsByDate, orderByDate));
      },
      errorAction: (error) => {
        store.dispatch(actions.failImportingRouteAssignments(error));
      }
    }
  );
}

export function* watchImportRouteAssignments() {
  yield takeEvery(types.ROUTES_ASSIGNMENTS_IMPORT_STARTED, importRouteAssignments);
}
