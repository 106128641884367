import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as authSelectors from '../../../../redux/reducers/auth';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import * as entitySelectors from '../../Entities/reducers';
import * as actions from '../actions';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import { getVehiclesListService } from '../services';
import * as types from '../types';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchVehiclesList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getVehiclesListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { vehicles },
          result //order
        } = normalize(resultData, schemas.vehicles); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingVehicles(vehicles, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingVehicles(error));
      }
    }
  );
}

export function* watchFetchVehiclesList() {
  yield takeEvery(types.VEHICLES_FETCH_STARTED, fetchVehiclesList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchVehiclesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getVehiclesListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { vehicles },
          result //order
        } = normalize(resultData, schemas.vehicles); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingVehicles(vehicles, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingVehicles(error));
      }
    }
  );
}

export function* watchPartialFetchVehiclesList() {
  yield takeEvery(types.VEHICLES_PARTIAL_FETCH_STARTED, partialFetchVehiclesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addVehicle(action) {
  const vehicle = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/vehicle/save`, {
        method: 'POST',
        body: JSON.stringify({ ...vehicle, vehicleId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedVehicle = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingVehicle(vehicle.vehicleId, addedVehicle));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'vehicles.vehicle'
          }),
          name: vehicle.vehicleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingVehicle(vehicle.vehicleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingVehicle(vehicle.vehicleId, error));
  }
}

export function* watchAddVehiclesStarted() {
  yield takeEvery(types.VEHICLE_ADD_STARTED, addVehicle);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editVehicle(action) {
  const vehicle = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/vehicle/save`, {
        method: 'POST',
        body: JSON.stringify({ ...vehicle }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedVehicle = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingVehicle(editedVehicle));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'vehicles.vehicle'
          }),
          name: editedVehicle.vehicleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldVehicle } = yield select((state) =>
          selectors.getVehicle(state.vehicles, vehicle.vehicleId)
        );
        yield put(actions.failEditingVehicle(oldVehicle, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldVehicle } = yield select((state) =>
      selectors.getVehicle(state.vehicles, vehicle.vehicleId)
    );
    yield put(actions.failEditingVehicle(oldVehicle, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditVehiclesStarted() {
  yield takeEvery(types.VEHICLE_EDIT_STARTED, editVehicle);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeVehicle(action) {
  const { vehicleId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedVehicle = yield select((state) => selectors.getVehicle(state, vehicleId));
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/vehicle/delete`, {
        method: 'POST',
        body: JSON.stringify({ vehicleId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingVehicle(vehicleId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'vehicles.vehicle'
          }),
          name: deletedVehicle.vehicleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingVehicle(vehicleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingVehicle(vehicleId, error));
  }
}

export function* watchRemoveVehicleStarted() {
  yield takeEvery(types.VEHICLE_REMOVE_STARTED, removeVehicle);
}

/* -------------------------------------------------------------------------- */
/*                            LICENSE PLATE CHECK                             */
/* -------------------------------------------------------------------------- */
function* checkRegistrationCode(action) {
  const { vehicleId, licensePlate } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/vehicle/validateLicensePlate`, {
        method: 'POST',
        body: JSON.stringify({ licensePlate, vehicleId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const validCode = jsonResult.data.LicensePlateStatus === 1;
        yield put(actions.completeCheckingCode(validCode));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchLicenseCheckStarted() {
  yield takeEvery(types.VEHICLE_LICENSE_PLATE_CHECK_STARTED, checkRegistrationCode);
}
