import { NoticeByOdometerRequest } from 'views/screens/vehicleMaintenance/types';
import * as types from 'views/screens/vehicleMaintenance/types/preventiveNotices/noticesByOdometer';

export const startFetchingNotices = (filters = {}) => ({
  type: types.NOTICES_BY_ODOMETER_FETCH_STARTED,
  payload: filters
});

export const completeFetchingNotices = (entities, order) => ({
  type: types.NOTICES_BY_ODOMETER_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failFetchingNotices = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.NOTICES_BY_ODOMETER_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingNotices = (entityTypeId) => ({
  type: types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});

export const completePartialFetchingNotices = (entities, order) => ({
  type: types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failPartialFetchingNotices = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.NOTICES_BY_ODOMETER_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingNotice = (notice: NoticeByOdometerRequest) => ({
  type: types.NOTICE_BY_ODOMETER_ADD_STARTED,
  payload: notice
});

export const completeAddingNotice = (id: string, oldId: string, notice) => ({
  type: types.NOTICE_BY_ODOMETER_ADD_COMPLETED,
  payload: { id, oldId, notice }
});

export const failAddingNotice = (oldId, error) => ({
  type: types.NOTICE_BY_ODOMETER_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingNotice = (notice: NoticeByOdometerRequest) => ({
  type: types.NOTICE_BY_ODOMETER_EDIT_STARTED,
  payload: notice
});

export const completeEditingNotice = (notice) => ({
  type: types.NOTICE_BY_ODOMETER_EDIT_COMPLETED,
  payload: notice
});

export const failEditingNotice = (oldNotice, error) => ({
  type: types.NOTICE_BY_ODOMETER_EDIT_FAILED,
  payload: {
    oldNotice,
    error
  }
});

//===================== RENEW ========================================
export const startRenewNotice = (notice: NoticeByOdometerRequest) => ({
  type: types.NOTICE_BY_ODOMETER_RENEW_STARTED,
  payload: notice
});

export const completeRenewNotice = (notice) => ({
  type: types.NOTICE_BY_ODOMETER_RENEW_COMPLETED,
  payload: notice
});

export const failRenewNotice = (oldNotice, error) => ({
  type: types.NOTICE_BY_ODOMETER_RENEW_FAILED,
  payload: {
    oldNotice,
    error
  }
});

export const startRemovingNotice = (request: { noticeId: number; unitId: number }) => ({
  type: types.NOTICE_BY_ODOMETER_REMOVE_STARTED,
  payload: {
    ...request
  }
});

export const completeRemovingNotice = (id) => ({
  type: types.NOTICE_BY_ODOMETER_REMOVE_COMPLETED,
  payload: {
    id
  }
});

export const failRemovingNotice = (id, error) => ({
  type: types.NOTICE_BY_ODOMETER_REMOVE_FAILED,
  payload: {
    id,
    error
  }
});
