import { SaveProviderCategoryDTO } from 'views/screens/vehicleMaintenance/types';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/providers';

export const startFetchingProviders = (filters = {}) => ({
  type: types.PROVIDERS_FETCH_STARTED2,
  payload: filters
});

export const completeFetchingProviders = (entities, order) => ({
  type: types.PROVIDERS_FETCH_COMPLETED2,
  payload: {
    entities,
    order
  }
});

export const failFetchingProviders = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.PROVIDERS_FETCH_FAILED2,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingProviders = (entityTypeId) => ({
  type: types.PROVIDERS_PARTIAL_FETCH_STARTED2,
  payload: entityTypeId
});

export const completePartialFetchingProviders = (entities, order) => ({
  type: types.PROVIDERS_PARTIAL_FETCH_COMPLETED2,
  payload: {
    entities,
    order
  }
});

export const failPartialFetchingProviders = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.PROVIDERS_PARTIAL_FETCH_FAILED2,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingProvider = (provider: SaveProviderCategoryDTO) => ({
  type: types.PROVIDER_ADD_STARTED,
  payload: provider
});

export const completeAddingProvider = (id: string, oldId: string, provider) => ({
  type: types.PROVIDER_ADD_COMPLETED,
  payload: { id, oldId, provider }
});

export const failAddingProvider = (oldId, error) => ({
  type: types.PROVIDER_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingProvider = (provider: SaveProviderCategoryDTO) => ({
  type: types.PROVIDER_EDIT_STARTED,
  payload: provider
});

export const completeEditingProvider = (provider) => ({
  type: types.PROVIDER_EDIT_COMPLETED,
  payload: provider
});

export const failEditingProvider = (oldProvider, error) => ({
  type: types.PROVIDER_EDIT_FAILED,
  payload: {
    oldProvider,
    error
  }
});

export const startRemovingProvider = (request: { providerId: number; providerName: string }) => ({
  type: types.PROVIDER_REMOVE_STARTED,
  payload: {
    ...request
  }
});

export const completeRemovingProvider = (id) => ({
  type: types.PROVIDER_REMOVE_COMPLETED,
  payload: {
    id
  }
});

export const failRemovingProvider = (id, error) => ({
  type: types.PROVIDER_REMOVE_FAILED,
  payload: {
    id,
    error
  }
});
