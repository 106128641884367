import { useLeafletContext } from '@react-leaflet/core';
import { useContext, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import L from 'leaflet';
import 'leaflet.icon.glyph';
import { Fragment, useEffect, useState } from 'react';
import { Marker, Tooltip, Rectangle, useMapEvents } from 'react-leaflet';
import { Media } from 'reactstrap';
import SlidingPaneUnitInformation from '../../../../screens/Units/views/SlidingPaneUnitInformation';
import { getRectBoundsFromLatLng } from '../../utils';
import MarkerClusterGroup from '../MarkerClusterGroup';
import { useDispatch, useSelector } from 'react-redux';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as unitSelectors from '../../../../screens/Units/reducers';
import * as unitActions from '../../../../screens/Units/actions';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { renderToString } from 'react-dom/server';
import Icon from 'utility/icomoon';
import { UNIT_FETCH_INTERVAL } from 'views/screens/Units/types';
import { getUnitIcon } from 'views/screens/Units/utils';
import { ShowHistoricalPositions } from 'views/screens/Units/views/SlidingPaneUnitHistoricalPositionsReport';

export interface MapUnitsOptions {
  isSelectedSomething?: any;
  setIsSelectedSomething?: Function;
  setMapPositionToUnitBounds?: boolean;
  useSlidingPaneUnitInformation?: boolean;
  useSlidingpanelUnitInformation?: boolean;
  showHistoricalPositions?: ShowHistoricalPositions;
  usePopovers?: boolean;
  units?: any[]; // la lista de unidades que actualimente tiene el mapa
  selectedUnitIds?: any[]; // se utiliza unicamente para resaltar los Markers de unidades en el mapa
  showUnits?: boolean;
  clusterMarkers?: boolean;
  isMapFullScreen?: boolean;
  useIsShownUnit?: boolean; //param only when you have a property in each unit isShownInMap to show units on map
  initialShowSlidingPanelUnitInformationOpen?: boolean;
  initialUnitInformationOpenId?: any;
  selectedUnit?: any;
  setSelectedUnit?: (value) => void;
  selectedUnitRef?: any;
  startClusteringAt?: number;
}

const MapUnits = (props: MapUnitsOptions) => {
  //const context = useLeafletContext();

  const map = useMapEvents({
    moveend: () => {
      displayUnits();
    }
  });

  const location = useLocation();
  const history = useHistory();

  const {
    isSelectedSomething,
    setIsSelectedSomething,
    showUnits = false,
    useIsShownUnit = false,
    units = [],
    selectedUnitIds,
    setMapPositionToUnitBounds = false,
    useSlidingPaneUnitInformation = true,
    clusterMarkers = true,
    usePopovers,
    showHistoricalPositions,
    isMapFullScreen = false,
    initialShowSlidingPanelUnitInformationOpen = false,
    initialUnitInformationOpenId,
    selectedUnit,
    setSelectedUnit,
    selectedUnitRef,
    startClusteringAt = undefined
  } = props;

  //const [selectedUnit, setSelectedUnit] = useState<any>({});

  const { store, user, selectedUnitState, isFetchingListUnitsLiveStatus } = useSelector(
    (state) => ({
      store: state,
      user: authSelectors.getAuthUser(state),
      selectedUnitState: selectedUnit?.unitId
        ? unitSelectors.getUnit(state, selectedUnit?.unitId) ?? null
        : null,
      isFetchingListUnitsLiveStatus: unitSelectors.isFetchingListUnitsLiveStatus(state)
    })
  );

  const fetchPositionInterval = useRef<any>();
  const dispatch = useDispatch();
  const [showUnitInformationSlidingPane, setShowUnitInformationSlidingPane] = useState(false);
  const [initialUnitId, setInitialUnitId] = useState<any>();
  const [setMapPositionToUnitsBounds, changeSetMapPositionToUnitsBounds] = useState<boolean>(true);

  useEffect(() => {
    if (setIsSelectedSomething) {
      setIsSelectedSomething({
        ...isSelectedSomething,
        units: selectedUnit?.unitId != null
      });
    }
  }, [selectedUnit]);

  const zoomMapToUnit = (latLng) => {
    if (setMapPositionToUnitsBounds) {
      var ll;
      if (latLng instanceof L.LatLng) {
        ll = latLng;
      } else {
        ll = L.latLng(latLng);
      }
      map.flyToBounds([ll], {
        paddingBottomRight: [550, 0],
        paddingTopLeft: [0, 70],
        maxZoom: 15,
        animate: 15 !== map.getZoom()
      });
    }
  };

  const cleanup = () => {
    if (fetchPositionInterval.current) clearInterval(fetchPositionInterval.current);
  };

  useEffect(() => {
    return () => cleanup();
  }, []);

  useDeepCompareEffect(() => {
    if (units.length > 0) {
      if (setMapPositionToUnitBounds && map) {
        if (units.length == 1) {
          const [firstUnit] = units;
          if (firstUnit?.latitude != null && firstUnit?.longitude != null) {
            var ll = L.latLng(firstUnit?.latitude, firstUnit?.longitude);
            map.flyToBounds(ll.toBounds(800), {});
          }
        } else {
          let bounds = L.latLngBounds(units.map((loc) => [loc.latitude, loc.longitude]));
          map.fitBounds(bounds);
        }
      }
      cleanup();
      if (showUnits || useIsShownUnit) {
        dispatch(unitActions.startFetchingUnitsLiveStatus());
        fetchPositionInterval.current = setInterval(() => {
          dispatch(unitActions.startFetchingUnitsLiveStatus());
        }, UNIT_FETCH_INTERVAL);
      }
    }
  }, [
    (units || []).map((unit) => unit?.unitId),
    setMapPositionToUnitBounds,
    showUnits,
    useIsShownUnit
  ]);

  //Open and select unit if params are set
  useEffect(() => {
    if (!isFetchingListUnitsLiveStatus) {
      if (initialShowSlidingPanelUnitInformationOpen && useSlidingPaneUnitInformation) {
        if (initialUnitInformationOpenId) {
          const initialUnitInformation = unitSelectors.getUnit(store, initialUnitInformationOpenId);

          if (
            initialUnitId !== initialUnitInformationOpenId &&
            initialUnitInformation?.latitude != null &&
            initialUnitInformation?.longitude != null
          ) {
            setInitialUnitId(initialUnitInformation.unitId);
            //setEditingUnitParent(initialUnitInformation);
            if (setSelectedUnit) setSelectedUnit(initialUnitInformation);
            var ll = L.latLng(initialUnitInformation.latitude, initialUnitInformation.longitude);
            map.flyToBounds(ll.toBounds(800), {});
          }
          setShowUnitInformationSlidingPane(true);
        }
      } else {
        if (initialUnitId) {
          setInitialUnitId(undefined);
          if (setSelectedUnit) setSelectedUnit({});
          setShowUnitInformationSlidingPane(false);
          //setEditingUnitParent(undefined);
        }
      }
    }
  }, [initialUnitInformationOpenId, isFetchingListUnitsLiveStatus]);

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  // Check if you want to cluster markers
  let WrapperMarkerClusterGroup = Fragment;
  const wrapperProps = {};
  let icon: JSX.Element | undefined = undefined;
  if (clusterMarkers && map && map.getZoom() < 13) {
    WrapperMarkerClusterGroup = MarkerClusterGroup;
    icon = <Icon icon={'Unidades'} size={12} />;
    wrapperProps['disableClusteringAtZoom'] = startClusteringAt;
  }

  const [unitsBounds, setUnitsBounds] = useState<any[]>([]);
  const displayUnits = () => {
    setUnitsBounds(
      units.filter(
        (unit) =>
          unit &&
          unit?.latitude != null &&
          unit?.longitude != null &&
          (map ? map.getBounds().contains(L.latLng(unit.latitude, unit.longitude)) : true)
      )
    );
  };

  useEffect(() => {
    if (selectedUnitState?.latitude != null && selectedUnitState?.longitude != null)
      zoomMapToUnit([selectedUnitState.latitude, selectedUnitState.longitude]);
  }, [selectedUnitState?.latitude, selectedUnitState?.longitude]);

  useDeepCompareEffect(() => {
    displayUnits();
  }, [
    showUnits,
    units?.map((unit) => ({
      latitude: unit?.latitude,
      longitude: unit?.longitude,
      isShownInMap: unit?.isShownInMap
    }))
  ]);

  return (
    <>
      {(showUnits || useIsShownUnit) && (
        <WrapperMarkerClusterGroup {...(icon ? { icon } : undefined)} {...wrapperProps}>
          {unitsBounds.map((unit) => {
            return (
              unit.latitude != null &&
              unit.longitude != null &&
              ((showUnits && !useIsShownUnit) || (useIsShownUnit && unit.isShownInMap)) && (
                <div key={unit.unitId}>
                  <Marker
                    // pmIgnore={true}
                    zIndexOffset={
                      selectedUnitIds &&
                      selectedUnitIds.length > 0 &&
                      selectedUnitIds.includes(unit.unitId)
                        ? 850
                        : 800
                    }
                    riseOnHover={true}
                    icon={L.divIcon({
                      className: 'custom-div-icon',
                      html: renderToString(
                        <Icon
                          icon={`${getUnitIcon(unit.themeId)}${
                            (selectedUnitIds?.length ?? 0) > 0 &&
                            !(selectedUnitIds ?? []).includes(unit.unitId)
                              ? 'Colorless'
                              : ''
                          }`}
                          style={{ color: 'rgba(128, 128, 128, 0.6)' }}
                          size={30}
                        />
                      ),
                      iconSize: [30, 30],
                      iconAnchor: [15, 15],
                      popupAnchor: [0, -18]
                    })}
                    position={[unit.latitude, unit.longitude]}
                    eventHandlers={{
                      click: (e) => {
                        // if (editingUnitParentRef?.current) {
                        //   history.replace();
                        //   dispatch(unitActions.showUnitInMap(editingUnitParentRef?.current.unitId));
                        //   if (setEditingUnitParent) setEditingUnitParent(undefined);
                        // }
                        if (setSelectedUnit) setSelectedUnit(unit);
                        if (useSlidingPaneUnitInformation) {
                          setShowUnitInformationSlidingPane(true);
                        }
                        zoomMapToUnit(e.latlng);
                      }
                    }}
                  >
                    {usePopovers && (
                      <Tooltip
                        permanent={user?.unitMapLabelAlwaysOn == 1}
                        direction={'bottom'}
                        offset={[0, 10]}
                        className={'unit-map-label'}
                      >
                        <b className="text-center" style={{ fontSize: '8px' }}>
                          {unit?.alias?.toUpperCase() || unit.unitName.toUpperCase()}
                        </b>
                        {user?.unitsLabelContent?.split(',').map((l) => (
                          <>
                            {l == '0' && (
                              <>
                                <br />
                                <span>
                                  {!isEmpty(unit.locationName) ? unit.locationName : 'N/A'}
                                </span>
                              </>
                            )}
                            {l == '1' && (
                              <>
                                <br />
                                <span>
                                  {unit.gpsDate
                                    ? moment(unit.gpsDate).format('YYYY-MM-DD HH:mm')
                                    : 'N/A'}
                                </span>
                              </>
                            )}
                            {l == '2' && (
                              <>
                                <br />
                                <span>
                                  {unit.lastCommunicationDate
                                    ? moment(unit.lastCommunicationDate).format('YYYY-MM-DD HH:mm')
                                    : 'N/A'}
                                </span>
                              </>
                            )}
                            {l == '3' && (
                              <>
                                <br />
                                <span>{unit.odometerFormat || 'N/A'}</span>
                              </>
                            )}
                            {l == '4' && (
                              <>
                                <br />
                                <span>{unit.speedFormat || 'N/A'}</span>
                              </>
                            )}
                          </>
                        ))}

                        {unit.fullImageUrl && (
                          <>
                            <br /> <br />
                            <Media
                              src={unit.fullImageUrl}
                              height="90"
                              width="90"
                              style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                              }}
                            />
                          </>
                        )}
                      </Tooltip>
                    )}
                  </Marker>
                </div>
              )
            );
          })}
        </WrapperMarkerClusterGroup>
      )}
      {showHistoricalPositions?.show ? (
        units
          .filter((unit) => unit && unit?.unitId)
          .map((unit) => (
            <SlidingPaneUnitInformation
              closeSlidingPane={() => {}}
              changeSetMapPositionToUnitsBounds={() => {}}
              key={unit?.unitId}
              showHistoricalPositions={showHistoricalPositions}
              isSlidingPaneOpen={showUnitInformationSlidingPane}
              title={unit?.unitName}
              unitId={unit?.unitId}
              selectedUnit={selectedUnit}
              selectedUnitRef={selectedUnitRef}
              setSelectedUnit={setSelectedUnit}
            />
          ))
      ) : selectedUnit?.unitId ? (
        <SlidingPaneUnitInformation
          closeSlidingPane={() => {
            setShowUnitInformationSlidingPane(false);
            //unitContextValue.changeUnitState({ isSelected: false });
            if (setSelectedUnit) setSelectedUnit(undefined);
          }}
          setMapPositionToPositionsBounds={true}
          changeSetMapPositionToUnitsBounds={changeSetMapPositionToUnitsBounds}
          showHistoricalPositions={showHistoricalPositions}
          isSlidingPaneOpen={showUnitInformationSlidingPane}
          title={selectedUnit?.unitName}
          unitId={selectedUnit?.unitId}
          selectedUnit={selectedUnit}
          selectedUnitRef={selectedUnitRef}
          setSelectedUnit={setSelectedUnit}
        />
      ) : null}
    </>
  );
};

MapUnits.defaultProps = {
  setMapPositionToUnitBounds: false,
  useSlidingPaneUnitInformation: true,
  units: [],
  showUnits: false,
  clusterMarkers: true,
  isMapFullScreen: false,
  initialShowSlidingPanelUnitInformationOpen: false,
  usePopovers: true,
  initialUnitInformationOpenId: undefined,
  useIsShownUnit: false
};

export default MapUnits;
