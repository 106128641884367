import { all, fork } from 'redux-saga/effects';
import {
  watchAddAgentsStarted,
  watchAgentFetchStarted,
  watchEditAgentsStarted,
  watchFetchAgentsList,
  watchPartialFetchAgentsList,
  watchRemoveAgentStarted
} from '../../views/screens/Agents/sagas';
import {
  watchAddDivisionsStarted,
  watchEditDivisionsStarted,
  watchFetchDivisionsList,
  watchPartialFetchDivisionsList,
  watchRemoveDivisionStarted
} from '../../views/screens/Divisions/sagas';
import { watchFetchEntitiesList, watchUpdateEntities } from '../../views/screens/Entities/sagas';
import {
  watchAddGeofencesStarted,
  watchAssignGeofenceUnit,
  watchEditGeofenceAssignation,
  watchEditGeofencesStarted,
  watchFetchGeofencesList,
  watchPartialFetchGeofencesList,
  watchRemoveAllAssignmentsByGeofences,
  watchRemoveAllAssignmentsByUnits,
  watchRemoveGeofenceAssignation,
  watchRemoveGeofenceStarted
} from '../../views/screens/Geofences/sagas';
import {
  watchAddModulesStarted,
  watchEditModulesStarted,
  watchFetchModulesList,
  watchModuleFetchStarted,
  watchPartialFetchModulesList,
  watchRemoveModuleStarted
} from '../../views/screens/Modules/sagas';
import {
  watchAddOperatorsStarted,
  watchEditOperatorStandardPricesStarted,
  watchEditOperatorsStarted,
  watchFetchOperatorsList,
  watchPartialFetchOperatorsList,
  watchRemoveOperatorStarted
} from '../../views/screens/Operators/sagas';
import {
  watchAddOrganizationDowntimeReasonsStarted,
  watchAddOrganizationsStarted,
  watchCodeAssignStarted,
  watchEditOrganizationDowntimeReasonsStarted,
  watchEditOrganizationsStarted,
  watchFetchOrganizationsList,
  watchPartialFetchOrganizationsList,
  watchRegistrationCodeStarted,
  watchRemoveOrganizationDowntimeReasonsStarted,
  watchRemoveOrganizationStarted,
  watchSaveConsolidableEventsStarted
} from '../../views/screens/Organizations/sagas';
import {
  watchAddServiceClassesStarted,
  watchEditServiceClassesStarted,
  watchFetchServiceClassesList,
  watchRemoveServiceClassStarted,
  watchServiceClassFetchStarted
} from '../../views/screens/ServiceClasses/sagas';
import {
  watchAddSubdivisionsStarted,
  watchEditSubdivisionsStarted,
  watchFetchSubdivisionsList,
  watchPartialFetchSubdivisionsList,
  watchRemoveSubdivisionStarted
} from '../../views/screens/Subdivisions/sagas';
import {
  watchAcknowledgeUnitEvent,
  watchActiveUnits,
  watchAddUnitsStarted,
  watchAssignDriverUnitsStarted,
  watchCreateSharedLinkUnitStarted,
  watchEditUnitsStarted,
  watchEndUnitDowntimeStarted,
  watchExecuteDeviceAction,
  watchFetchUnitHistoricalPositionsStarted,
  watchFetchUnitInformationStarted,
  watchFetchUnitsList,
  watchFetchUnitsLiveStatusList,
  watchPartialFetchUnitsList,
  watchRemoveUnitStarted,
  watchStartUnitDowntimeStarted,
  watchUpdateSim
} from '../../views/screens/Units/sagas';
import {
  watchAddSchedulesStarted,
  watchAddShiftStarted,
  watchEditSchedulesShiftStarted,
  watchEditSchedulesStarted,
  watchFetchSchedulesList,
  watchPartialFetchSchedulesList,
  watchRemoveScheduleStarted
} from './../../views/screens/Schedules/sagas';
import {
  watchChangeProductStarted,
  watchFetchUserBookmarksStarted,
  watchForceRefreshToken,
  watchLoginStarted,
  watchRefreshTokenStarted,
  watchUpdateMapProfileSettingsStarted,
  watchUpdateProfileGeneralInformationStarted,
  watchUpdateProfileHomeScreenStarted,
  watchUpdateProfileMapOptionsStarted,
  watchUpdateProfilePasswordStarted,
  watchUpdateUserBookmarkssStarted,
  watchUserInformationRequest
} from './auth';

import {
  watchAddRoutesStarted,
  watchCopyRouteStarted,
  watchEditRoutesStarted,
  watchFetchRoutesList,
  watchPartialFetchRoutesList,
  watchRemoveRouteStarted,
  watchRemoveRoutesStarted
} from '../../views/screens/Routes/sagas/routes';

import {
  watchAddContactsStarted,
  watchEditContactsStarted,
  watchFetchContactsList,
  watchRemoveContactsStarted
} from 'views/screens/Contacts/sagas';
import { watchFetchRouteCheckpointsList } from '../../views/screens/Routes/sagas/routeCheckpoints';
import {
  watchEditAssignmentsStarted,
  watchFetchAssignmentsList,
  watchImportRouteAssignments,
  watchRemoveAssignmentStarted
} from '../../views/screens/Routes/sagas/routesAssignments';
import {
  watchAddCommentsStarted,
  watchEditCommentsStarted,
  watchFetchCommentsList,
  watchRemoveCommentStarted
} from '../../views/screens/Routes/sagas/routesComments';
import {
  watchAsignRouteExecutionUnit,
  watchCancelRouteExecution,
  watchChangeToAlternateRouteStarted,
  watchCompleteRouteExecution,
  watchEditRouteExecutionStarted,
  watchFetchRoutesExecutions,
  watchFinalizeRouteExecution,
  watchManualTransitRouteExecution,
  watchPauseRouteExecution,
  watchRemoveRouteExecution,
  watchShareRouteExecution,
  watchStartRouteExecution
} from '../../views/screens/Routes/sagas/routesExecutions';
import { watchFetchRoutesReport } from '../../views/screens/Routes/sagas/routesReport';
import {
  watchAddMonitoringUsersStarted,
  watchAssignScheduleUsersStarted,
  watchEditMonitoringUsersStarted,
  watchFetchMonitoringUsersList,
  watchPartialFetchMonitoringUsersList,
  watchRemoveMonitoringUserStarted,
  watchSaveMonitoringUserRolesAndPermissionsStarted,
  watchUnassignScheduleUsersStarted,
  watchUpdateMonitoringUserPasswordStarted
} from '../../views/screens/Users/sagas';
import {
  watchAddWorkplansStarted,
  watchAssignDestinationGeofenceStarted,
  watchAssignOriginGeofenceStarted,
  watchAssignWorkGeofenceStarted,
  watchCopyWorkplanStarted,
  watchEditWorkplansStarted,
  watchFetchWorkplanLocationsList,
  watchFetchWorkplansList,
  watchPartialFetchWorkplansList,
  watchRemoveWorkplanStarted
} from '../../views/screens/Workplans/sagas/workplans';
import {
  watchEditWorkplanAssignmentByDateTimeStarted,
  watchEditWorkplanAssignmentByScheduleStarted,
  watchFetchWorkplansAssignmentsList,
  watchRemoveWorkplanAssignmentStarted
} from '../../views/screens/Workplans/sagas/workplansAssignments';
import {
  watchAddWorkplansCommentStarted,
  watchFetchWorkplansCommentsList,
  watchRemoveWorkplansCommentStarted
} from '../../views/screens/Workplans/sagas/workplansComments';
import {
  watchAddWorkplanExecutionLocationsStarted,
  watchEditWorkplanExecutionLocationsStarted,
  watchRemoveWorkplanExecutionLocationStarted
} from '../../views/screens/Workplans/sagas/workplansExecutionLocations';
import {
  watchAsignWorkplanExecutionUnit,
  watchCancelWorkplanExecution,
  watchChangeWorkplanExecutionUnit,
  watchFetchWorkplanExecution,
  watchFetchWorkplansExecutions,
  watchFinalizeWorkplanExecution,
  watchManualTransitWorkplanExecution
} from '../../views/screens/Workplans/sagas/workplansExecutions';
import { watchFetchWorkplansReport } from '../../views/screens/Workplans/sagas/workplansReport';
import {
  watchFetchWorkplansAllVisits,
  watchFetchWorkplansVisits
} from '../../views/screens/Workplans/sagas/workplansVisits';
import { watchFetchConfigurationsList } from './configurations';
import { watchFetchModelsList } from './models';
import { watchFetchBrandsList } from './static-catalogs/brands';

import {
  watchFetchCountriesList,
  watchFetchCurrenciesList,
  watchFetchInsuranceAgentsList,
  watchFetchInsuranceCompaniesList,
  watchFetchLocationClassesList,
  watchFetchPaymentMethodsList,
  watchFetchProvidersList,
  watchFetchSensorBrandsList,
  watchFetchSensorMeasuresList,
  watchFetchSensorModelsList,
  watchFetchTimeZonesList,
  watchFetchVehicleMakersList,
  watchFetchVehicleModelsList
} from './static-catalogs';

import {
  watchAddSimsStarted,
  watchEditSimsStarted,
  watchFetchSimsList,
  watchPartialFetchSimsList,
  watchRemoveSimStarted
} from '../../views/screens/SIMs/sagas';

import {
  watchAddVehiclesStarted,
  watchEditVehiclesStarted,
  watchFetchVehiclesList,
  watchLicenseCheckStarted,
  watchPartialFetchVehiclesList,
  watchRemoveVehicleStarted
} from '../../views/screens/Vehicles/sagas';

import {
  watchAddAssetsStarted,
  watchEditAssetsStarted,
  watchFetchAssetsList,
  watchIdentifierCheckStarted,
  watchPartialFetchAssetsList,
  watchRemoveAssetStarted,
  watchSecuritySealCodeCheckStarted
} from '../../views/screens/Assets/sagas';

import {
  watchAddSensorsStarted,
  watchEditSensorsStarted,
  watchFetchSensorsList,
  watchPartialFetchSensorsList,
  watchRemoveSensorStarted,
  watchSensorCalibrationFinalizeStarted,
  watchSensorCalibrationStartStarted,
  watchSensorIdentifierCheckStarted
} from '../../views/screens/Sensors/sagas';

import {
  watchAddLocationsStarted,
  watchEditLocationsStarted,
  watchFetchLocationsList,
  watchImportLocationsStarted,
  watchPartialFetchLocationsList,
  watchRemoveLocationStarted
} from '../../views/screens/Locations/sagas';

import {
  watchAddDriversStarted,
  watchAssignDriversToUnits,
  watchAssignSensor,
  watchEditDriversStarted,
  watchFetchDriversList,
  watchPartialFetchDriversList,
  watchRemoveDriverStarted,
  watchStartImportDriverAndUnit,
  watchUnassignSensor
} from '../../views/screens/Drivers/sagas';

import {
  watchAddJobsBacklogStarted,
  watchAssignFilesJobsBacklogStarted,
  watchAssignFormsJobsBacklogStarted,
  watchAssignUsersJobsBacklogStarted,
  watchChangeDueDateJobsBacklogStarted,
  watchChangeMinMaxTimeJobsBacklogStarted,
  watchChangePriorityJobsBacklogStarted,
  watchChangeStartDateJobsBacklogStarted,
  watchChangeStatusJobsBacklogStarted,
  watchChangeStatusJobsBoardStarted,
  watchEditJobsBacklogStarted,
  watchFetchJob,
  watchFetchJobsBacklogList,
  watchFetchJobsBoardList,
  watchFetchJobsReportList,
  watchImportJobsStarted,
  watchPartialFetchJobsBacklogList,
  watchRemoveJobsBacklogStarted,
  watchRescheduleJobsStarted
} from '../../views/screens/Jobs/sagas';

import {
  watchAddEntitiesToGroup,
  watchAddGroupStarted,
  watchAddGroupWithEntitiesStarted,
  watchEditConfigurationNotificationsStarted,
  watchEditGroupsStarted,
  watchEditUserProfileInGroup,
  watchFetchAllEntitiesInGroup,
  watchFetchGroupsList,
  watchLeaveGroupStarted,
  watchPartialFetchGroupsList,
  watchRemoveEntitiesFromGroup,
  watchRemoveGroupStarted
} from '../../views/screens/Groups/sagas';

import {
  watchAcceptDenyQuotationCustomerStarted,
  watchAddQuotationsStarted,
  watchApproveDenyQuotationManagementStarted,
  watchCheckQuotationSchedulingStarted,
  watchCopyQuotationsStarted,
  watchEditQuotationsStarted,
  watchFetchQuotationsList,
  watchPartialFetchQuotationsList,
  watchRemoveQuotationStarted,
  watchScheduleQuotationsStarted,
  watchSetTimesupQuotationStarted
} from '../../views/screens/Quotations/sagas';

import {
  watchAddOrganizationContractsStarted,
  watchEditOrganizationContractsStarted,
  watchFetchOrganizationContractsList,
  watchPartialFetchOrganizationContractsList,
  watchRemoveOrganizationContractStarted
} from '../../views/screens/Contracts/sagas';

import {
  watchAddContractTemplatesStarted,
  watchEditContractTemplatesStarted,
  watchFetchContractTemplatesList,
  watchPartialFetchContractTemplatesList,
  watchRemoveContractTemplateStarted
} from '../../views/screens/ContractTemplates/sagas';

import {
  watchAddCustomersStarted,
  watchEditCustomersStarted,
  watchFetchCustomersList,
  watchPartialFetchCustomersList,
  watchRemoveCustomerStarted
} from '../../views/screens/Customers/sagas';

import {
  watchAddOfficesStarted,
  watchEditOfficesStarted,
  watchFetchOfficesList,
  watchPartialFetchOfficesList,
  watchRemoveOfficeStarted
} from '../../views/screens/Offices/sagas';

import {
  watchAddExternalFormsStarted,
  watchAddFormsStarted,
  watchEditExternalFormsStarted,
  watchEditFormsStarted,
  watchFetchFormsList,
  watchFetchQuestions,
  watchFetchSections,
  watchPartialFetchFormsList,
  watchRemoveFormStarted,
  watchResponseAddedStarted,
  watchResponseFetchStarted,
  watchResponseResendStarted
} from '../../views/screens/Forms/sagas';

import {
  watchAddCategoriesStarted,
  watchEditCategoriesStarted,
  watchFetchCategoriesList,
  watchPartialFetchCategoriesList,
  watchRemoveCategoryStarted
} from '../../views/screens/vehicleMaintenance/sagas/catalogs/categories';

import {
  watchAddMaintenanceLinesStarted,
  watchCopyMaintenanceLineStarted,
  watchEditMaintenanceLinesStarted,
  watchFetchMaintenanceLinesList,
  watchPartialFetchMaintenanceLinesList,
  watchRemoveMaintenanceLineStarted
} from '../../views/screens/MaintenanceLines/sagas';

import {
  watchAddNoticesStarted,
  watchEditNoticesStarted,
  watchFetchNoticesList,
  watchPartialFetchNoticesList,
  watchRemoveNoticeStarted,
  watchRenewNoticesStarted
} from '../../views/screens/vehicleMaintenance/sagas/preventiveNotices/noticesByOdometer';

import {
  watchAddNoticesStarted as watchAddNoticesStarted2,
  watchEditNoticesStarted as watchEditNoticesStarted2,
  watchFetchNoticesList as watchFetchNoticesList2,
  watchPartialFetchNoticesList as watchPartialFetchNoticesList2,
  watchRemoveNoticeStarted as watchRemoveNoticeStarted2,
  watchRenewNoticesStarted as watchRenewNoticesStarted2
} from '../../views/screens/vehicleMaintenance/sagas/preventiveNotices/noticesByDate';

import {
  watchAddNoticesStarted as watchAddNoticesStarted3,
  watchEditNoticesStarted as watchEditNoticesStarted3,
  watchFetchNoticesList as watchFetchNoticesList3,
  watchPartialFetchNoticesList as watchPartialFetchNoticesList3,
  watchRemoveNoticeStarted as watchRemoveNoticeStarted3,
  watchRenewNoticesStarted as watchRenewNoticesStarted3
} from '../../views/screens/vehicleMaintenance/sagas/preventiveNotices/noticesByHourmeter';

import {
  watchAddProvidersStarted,
  watchEditProvidersStarted,
  watchFetchProvidersList as watchFetchProvidersList2,
  watchPartialFetchProvidersList,
  watchRemoveProviderStarted
} from '../../views/screens/vehicleMaintenance/sagas/catalogs/providers';

import {
  watchAddWorkmanshipsStarted,
  watchEditWorkmanshipsStarted,
  watchFetchWorkmanshipsList,
  watchPartialFetchWorkmanshipsList,
  watchRemoveWorkmanshipStarted
} from '../../views/screens/vehicleMaintenance/sagas/catalogs/workmanship';

import {
  watchAddSparePartsStarted,
  watchEditSparePartsStarted,
  watchFetchSparePartsList,
  watchPartialFetchSparePartsList,
  watchRemoveSparePartStarted
} from '../../views/screens/vehicleMaintenance/sagas/catalogs/spareParts';

import {
  watchFetchProtocolsList,
  watchPartialFetchProtocolsList,
  watchAddProtocolStarted,
  watchEditProtocolStarted,
  watchRemoveProtocolStarted
} from '../../views/screens/Protocols/sagas';

function* mainSaga() {
  yield all([
    //Login
    fork(watchLoginStarted),
    fork(watchUserInformationRequest),
    fork(watchRefreshTokenStarted),

    //UserSettings
    fork(watchFetchUserBookmarksStarted),
    fork(watchUpdateUserBookmarkssStarted),

    //Profile
    fork(watchUpdateProfileGeneralInformationStarted),
    fork(watchUpdateProfileMapOptionsStarted),
    fork(watchUpdateProfilePasswordStarted),
    fork(watchForceRefreshToken),
    fork(watchUpdateMapProfileSettingsStarted),
    fork(watchUpdateProfileHomeScreenStarted),
    fork(watchChangeProductStarted),

    //Entities
    fork(watchFetchEntitiesList),
    fork(watchUpdateEntities),

    //Operators
    fork(watchFetchOperatorsList),
    fork(watchPartialFetchOperatorsList),
    fork(watchAddOperatorsStarted),
    fork(watchEditOperatorsStarted),
    fork(watchEditOperatorStandardPricesStarted),
    fork(watchRemoveOperatorStarted),

    //Organizations
    fork(watchFetchOrganizationsList),
    fork(watchPartialFetchOrganizationsList),
    fork(watchAddOrganizationsStarted),
    fork(watchEditOrganizationsStarted),
    fork(watchRemoveOrganizationStarted),
    fork(watchRegistrationCodeStarted),
    fork(watchCodeAssignStarted),
    fork(watchSaveConsolidableEventsStarted),
    fork(watchAddOrganizationDowntimeReasonsStarted),
    fork(watchEditOrganizationDowntimeReasonsStarted),
    fork(watchRemoveOrganizationDowntimeReasonsStarted),

    //Divisions
    fork(watchFetchDivisionsList),
    fork(watchPartialFetchDivisionsList),
    fork(watchAddDivisionsStarted),
    fork(watchEditDivisionsStarted),
    fork(watchRemoveDivisionStarted),

    //Subdivisions
    fork(watchFetchSubdivisionsList),
    fork(watchPartialFetchSubdivisionsList),
    fork(watchAddSubdivisionsStarted),
    fork(watchEditSubdivisionsStarted),
    fork(watchRemoveSubdivisionStarted),

    //Units
    fork(watchFetchUnitsList),
    fork(watchPartialFetchUnitsList),
    fork(watchFetchUnitsLiveStatusList),
    fork(watchFetchUnitInformationStarted),
    fork(watchAddUnitsStarted),
    fork(watchEditUnitsStarted),
    fork(watchRemoveUnitStarted),
    fork(watchFetchUnitHistoricalPositionsStarted),
    fork(watchExecuteDeviceAction),
    fork(watchActiveUnits),
    fork(watchAcknowledgeUnitEvent),
    fork(watchAssignDriverUnitsStarted),
    fork(watchCreateSharedLinkUnitStarted),
    fork(watchEndUnitDowntimeStarted),
    fork(watchStartUnitDowntimeStarted),
    // fork(watchUpdateDevice),
    fork(watchUpdateSim),

    //Geofences
    fork(watchFetchGeofencesList),
    fork(watchPartialFetchGeofencesList),
    fork(watchAddGeofencesStarted),
    fork(watchEditGeofencesStarted),
    fork(watchRemoveGeofenceStarted),
    fork(watchAssignGeofenceUnit),
    fork(watchRemoveGeofenceAssignation),
    fork(watchRemoveAllAssignmentsByGeofences),
    fork(watchRemoveAllAssignmentsByUnits),
    fork(watchEditGeofenceAssignation),

    //Locations
    fork(watchFetchLocationsList),
    fork(watchPartialFetchLocationsList),
    fork(watchAddLocationsStarted),
    fork(watchEditLocationsStarted),
    fork(watchRemoveLocationStarted),
    fork(watchImportLocationsStarted),

    //Drivers
    fork(watchFetchDriversList),
    fork(watchPartialFetchDriversList),
    fork(watchAddDriversStarted),
    fork(watchEditDriversStarted),
    fork(watchRemoveDriverStarted),
    fork(watchAssignSensor),
    fork(watchUnassignSensor),
    fork(watchStartImportDriverAndUnit),
    fork(watchAssignDriversToUnits),

    //JobsBacklog
    fork(watchFetchJobsBacklogList),
    fork(watchFetchJob),
    fork(watchAddJobsBacklogStarted),
    fork(watchEditJobsBacklogStarted),
    fork(watchPartialFetchJobsBacklogList),
    fork(watchAssignFilesJobsBacklogStarted),
    fork(watchAssignFormsJobsBacklogStarted),
    fork(watchAssignUsersJobsBacklogStarted),
    fork(watchChangeDueDateJobsBacklogStarted),
    fork(watchChangeMinMaxTimeJobsBacklogStarted),
    fork(watchChangePriorityJobsBacklogStarted),
    fork(watchChangeStartDateJobsBacklogStarted),
    fork(watchChangeStatusJobsBacklogStarted),
    fork(watchRemoveJobsBacklogStarted),
    fork(watchRescheduleJobsStarted),
    fork(watchFetchJobsBoardList),
    fork(watchChangeStatusJobsBoardStarted),
    fork(watchFetchJobsReportList),
    fork(watchImportJobsStarted),

    //Groups
    fork(watchFetchGroupsList),
    fork(watchPartialFetchGroupsList),
    fork(watchFetchAllEntitiesInGroup),
    fork(watchEditGroupsStarted),
    fork(watchEditConfigurationNotificationsStarted),
    fork(watchRemoveEntitiesFromGroup),
    fork(watchAddEntitiesToGroup),
    fork(watchEditUserProfileInGroup),
    fork(watchAddGroupStarted),
    fork(watchAddGroupWithEntitiesStarted),
    fork(watchRemoveGroupStarted),
    fork(watchLeaveGroupStarted),

    //Quotations
    fork(watchFetchQuotationsList),
    fork(watchPartialFetchQuotationsList),
    fork(watchAddQuotationsStarted),
    fork(watchEditQuotationsStarted),
    fork(watchRemoveQuotationStarted),
    fork(watchCheckQuotationSchedulingStarted),
    fork(watchScheduleQuotationsStarted),
    fork(watchSetTimesupQuotationStarted),
    fork(watchApproveDenyQuotationManagementStarted),
    fork(watchAcceptDenyQuotationCustomerStarted),
    fork(watchCopyQuotationsStarted),

    //Organization Contracts
    fork(watchFetchOrganizationContractsList),
    fork(watchPartialFetchOrganizationContractsList),
    fork(watchAddOrganizationContractsStarted),
    fork(watchEditOrganizationContractsStarted),
    fork(watchRemoveOrganizationContractStarted),

    //Contract Templates
    fork(watchFetchContractTemplatesList),
    fork(watchPartialFetchContractTemplatesList),
    fork(watchAddContractTemplatesStarted),
    fork(watchEditContractTemplatesStarted),
    fork(watchRemoveContractTemplateStarted),

    //Customers
    fork(watchFetchCustomersList),
    fork(watchPartialFetchCustomersList),
    fork(watchAddCustomersStarted),
    fork(watchEditCustomersStarted),
    fork(watchRemoveCustomerStarted),

    //Offices
    fork(watchFetchOfficesList),
    fork(watchPartialFetchOfficesList),
    fork(watchAddOfficesStarted),
    fork(watchEditOfficesStarted),
    fork(watchRemoveOfficeStarted),

    //Categories
    fork(watchFetchCategoriesList),
    fork(watchPartialFetchCategoriesList),
    fork(watchAddCategoriesStarted),
    fork(watchEditCategoriesStarted),
    fork(watchRemoveCategoryStarted),

    //MaintenanceLines
    fork(watchFetchMaintenanceLinesList),
    fork(watchPartialFetchMaintenanceLinesList),
    fork(watchAddMaintenanceLinesStarted),
    fork(watchEditMaintenanceLinesStarted),
    fork(watchRemoveMaintenanceLineStarted),
    fork(watchCopyMaintenanceLineStarted),

    //Notices by Odometer
    fork(watchFetchNoticesList),
    fork(watchPartialFetchNoticesList),
    fork(watchAddNoticesStarted),
    fork(watchEditNoticesStarted),
    fork(watchRemoveNoticeStarted),
    fork(watchRenewNoticesStarted),

    //Notices by Date
    fork(watchFetchNoticesList2),
    fork(watchPartialFetchNoticesList2),
    fork(watchAddNoticesStarted2),
    fork(watchEditNoticesStarted2),
    fork(watchRemoveNoticeStarted2),
    fork(watchRenewNoticesStarted2),

    //Notices by hour meter
    fork(watchFetchNoticesList3),
    fork(watchPartialFetchNoticesList3),
    fork(watchAddNoticesStarted3),
    fork(watchEditNoticesStarted3),
    fork(watchRemoveNoticeStarted3),
    fork(watchRenewNoticesStarted3),

    //Provider
    fork(watchFetchProvidersList2),
    fork(watchPartialFetchProvidersList),
    fork(watchAddProvidersStarted),
    fork(watchEditProvidersStarted),
    fork(watchRemoveProviderStarted),

    //SpareParts
    fork(watchFetchSparePartsList),
    fork(watchPartialFetchSparePartsList),
    fork(watchAddSparePartsStarted),
    fork(watchEditSparePartsStarted),
    fork(watchRemoveSparePartStarted),

    //Workshops
    fork(watchFetchWorkmanshipsList),
    fork(watchPartialFetchWorkmanshipsList),
    fork(watchAddWorkmanshipsStarted),
    fork(watchEditWorkmanshipsStarted),
    fork(watchRemoveWorkmanshipStarted),

    //Routes
    fork(watchFetchRoutesList),
    fork(watchAddRoutesStarted),
    fork(watchEditRoutesStarted),
    fork(watchPartialFetchRoutesList),
    fork(watchRemoveRouteStarted),
    fork(watchRemoveRoutesStarted),
    fork(watchCopyRouteStarted),

    //Routes Checkponts
    fork(watchFetchRouteCheckpointsList),

    //Routes Executions
    fork(watchFetchRoutesExecutions),
    fork(watchCancelRouteExecution),
    fork(watchFinalizeRouteExecution),
    fork(watchCompleteRouteExecution),
    fork(watchRemoveRouteExecution),
    fork(watchPauseRouteExecution),
    fork(watchStartRouteExecution),
    fork(watchManualTransitRouteExecution),
    fork(watchShareRouteExecution),
    fork(watchAsignRouteExecutionUnit),
    fork(watchEditRouteExecutionStarted),
    fork(watchChangeToAlternateRouteStarted),

    //Routes Assignments
    fork(watchFetchAssignmentsList),
    fork(watchEditAssignmentsStarted),
    fork(watchRemoveAssignmentStarted),
    fork(watchImportRouteAssignments),

    //Routes Comments
    fork(watchFetchCommentsList),
    fork(watchAddCommentsStarted),
    fork(watchEditCommentsStarted),
    fork(watchRemoveCommentStarted),

    //Routes Report
    fork(watchFetchRoutesReport),

    //Schedules
    fork(watchFetchSchedulesList),
    fork(watchPartialFetchSchedulesList),
    fork(watchAddSchedulesStarted),
    fork(watchEditSchedulesStarted),
    fork(watchRemoveScheduleStarted),
    fork(watchEditSchedulesShiftStarted),
    fork(watchAddShiftStarted),

    //Service classes
    fork(watchFetchServiceClassesList),
    fork(watchAddServiceClassesStarted),
    fork(watchEditServiceClassesStarted),
    fork(watchServiceClassFetchStarted),
    fork(watchRemoveServiceClassStarted),

    //Agents
    fork(watchFetchAgentsList),
    fork(watchPartialFetchAgentsList),
    fork(watchAddAgentsStarted),
    fork(watchEditAgentsStarted),
    fork(watchAgentFetchStarted),
    fork(watchRemoveAgentStarted),

    //Modules
    fork(watchFetchModulesList),
    fork(watchPartialFetchModulesList),
    fork(watchAddModulesStarted),
    fork(watchEditModulesStarted),
    fork(watchModuleFetchStarted),
    fork(watchRemoveModuleStarted),

    //Brands
    fork(watchFetchBrandsList),

    //Workplans
    fork(watchFetchWorkplansList),
    fork(watchPartialFetchWorkplansList),
    fork(watchFetchWorkplanLocationsList),
    fork(watchAddWorkplansStarted),
    fork(watchEditWorkplansStarted),
    fork(watchRemoveWorkplanStarted),
    fork(watchCopyWorkplanStarted),
    fork(watchAssignOriginGeofenceStarted),
    fork(watchAssignWorkGeofenceStarted),
    fork(watchAssignDestinationGeofenceStarted),

    //Workplans Executions
    fork(watchFetchWorkplansExecutions),
    fork(watchFetchWorkplanExecution),
    fork(watchCancelWorkplanExecution),
    fork(watchFinalizeWorkplanExecution),
    fork(watchManualTransitWorkplanExecution),
    fork(watchChangeWorkplanExecutionUnit),
    fork(watchAsignWorkplanExecutionUnit),

    //Workplan Execution Locations
    fork(watchAddWorkplanExecutionLocationsStarted),
    fork(watchEditWorkplanExecutionLocationsStarted),
    fork(watchRemoveWorkplanExecutionLocationStarted),

    //Workplan Comments
    fork(watchFetchWorkplansCommentsList),
    fork(watchAddWorkplansCommentStarted),
    fork(watchRemoveWorkplansCommentStarted),

    //Workplan Assignments
    fork(watchFetchWorkplansAssignmentsList),
    fork(watchEditWorkplanAssignmentByScheduleStarted),
    fork(watchEditWorkplanAssignmentByDateTimeStarted),
    fork(watchRemoveWorkplanAssignmentStarted),

    //Workplans Visits
    fork(watchFetchWorkplansVisits),
    fork(watchFetchWorkplansAllVisits),

    //Workplans Report
    fork(watchFetchWorkplansReport),

    //Models
    fork(watchFetchModelsList),

    //Configurations
    fork(watchFetchConfigurationsList),

    //Contacts
    fork(watchFetchContactsList),
    fork(watchAddContactsStarted),
    fork(watchEditContactsStarted),
    fork(watchRemoveContactsStarted),

    //Monitoring Users
    fork(watchFetchMonitoringUsersList),
    fork(watchPartialFetchMonitoringUsersList),
    fork(watchAddMonitoringUsersStarted),
    fork(watchEditMonitoringUsersStarted),
    fork(watchRemoveMonitoringUserStarted),
    fork(watchUpdateMonitoringUserPasswordStarted),
    fork(watchSaveMonitoringUserRolesAndPermissionsStarted),
    fork(watchAssignScheduleUsersStarted),
    fork(watchUnassignScheduleUsersStarted),

    //Static Catalogs
    fork(watchFetchCurrenciesList),
    fork(watchFetchPaymentMethodsList),
    fork(watchFetchTimeZonesList),
    fork(watchFetchProvidersList),
    fork(watchFetchLocationClassesList),
    fork(watchFetchSensorMeasuresList),

    //Sims
    fork(watchFetchSimsList),
    fork(watchPartialFetchSimsList),
    fork(watchAddSimsStarted),
    fork(watchEditSimsStarted),
    fork(watchRemoveSimStarted),

    //Countries
    fork(watchFetchCountriesList),

    //Vehicles
    fork(watchFetchVehiclesList),
    fork(watchPartialFetchVehiclesList),
    fork(watchAddVehiclesStarted),
    fork(watchEditVehiclesStarted),
    fork(watchLicenseCheckStarted),
    fork(watchFetchVehicleMakersList),
    fork(watchFetchVehicleModelsList),
    fork(watchFetchInsuranceCompaniesList),
    fork(watchFetchInsuranceAgentsList),
    fork(watchRemoveVehicleStarted),

    //Assets
    fork(watchFetchAssetsList),
    fork(watchPartialFetchAssetsList),
    fork(watchEditAssetsStarted),
    fork(watchIdentifierCheckStarted),
    fork(watchAddAssetsStarted),
    fork(watchRemoveAssetStarted),
    fork(watchSecuritySealCodeCheckStarted),

    //Sensors
    fork(watchFetchSensorsList),
    fork(watchPartialFetchSensorsList),
    fork(watchFetchSensorBrandsList),
    fork(watchFetchSensorModelsList),
    fork(watchSensorIdentifierCheckStarted),
    fork(watchAddSensorsStarted),
    fork(watchEditSensorsStarted),
    fork(watchRemoveSensorStarted),
    fork(watchSensorCalibrationStartStarted),
    fork(watchSensorCalibrationFinalizeStarted),

    //Forms
    fork(watchFetchFormsList),
    fork(watchPartialFetchFormsList),
    fork(watchFetchSections),
    fork(watchFetchQuestions),
    fork(watchAddFormsStarted),
    fork(watchEditFormsStarted),
    fork(watchAddExternalFormsStarted),
    fork(watchEditExternalFormsStarted),
    fork(watchRemoveFormStarted),
    fork(watchResponseAddedStarted),
    fork(watchResponseResendStarted),
    fork(watchResponseFetchStarted),

    //Protocols
    fork(watchFetchProtocolsList),
    fork(watchPartialFetchProtocolsList),
    fork(watchAddProtocolStarted),
    fork(watchEditProtocolStarted),
    fork(watchRemoveProtocolStarted)
  ]);
}

export default mainSaga;
