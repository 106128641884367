import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/providers';
import { ProvidersDTO } from 'views/screens/vehicleMaintenance/types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.PROVIDERS_FETCH_COMPLETED2:
    case types.PROVIDERS_PARTIAL_FETCH_COMPLETED2: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((providerId) => {
        newState[providerId] = {
          isSelected: false,
          ...state[providerId],
          ...entities[providerId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.PROVIDER_ADD_COMPLETED: {
      const { id, oldId, provider } = action.payload;
      const newState = omit(state, oldId);
      newState[id] = {
        ...provider,
        isConfirmed: true,
        isSelected: false
      };
      return newState;
    }

    case types.PROVIDER_EDIT_COMPLETED: {
      const provider = action.payload;
      const id = provider.providerId;
      const newState = {
        ...state,
        [id]: {
          ...omit(state[id], ['oldProvider']),
          ...omit(provider, ['oldProvider']),
          isConfirmed: true
        }
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.PROVIDER_ADD_STARTED: {
      const provider = action.payload;
      const newState = { ...state };
      newState[provider.tempId] = {
        isSelected: false,
        ...provider,
        isConfirmed: false
      };
      return newState;
    }

    case types.PROVIDER_EDIT_STARTED: {
      const provider = action.payload;
      const id = provider.providerId;
      const newState = {
        ...state,
        [id]: {
          ...state[id],
          oldProvider: state[id],
          isConfirmed: false,
          ...provider
        }
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.PROVIDER_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.PROVIDER_EDIT_FAILED: {
      const { oldProvider } = action.payload;
      return {
        ...state,
        [oldProvider.providerId]: {
          ...omit(state[oldProvider.providerId], ['oldProvider']),
          ...oldProvider,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.PROVIDER_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return omit(state, id);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.PROVIDERS_FETCH_COMPLETED2: {
      const { order } = action.payload;
      return union(order);
    }

    case types.PROVIDERS_PARTIAL_FETCH_COMPLETED2: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.PROVIDER_ADD_COMPLETED: {
      const { id, oldId } = action.payload;
      return state.map((providerId) => (providerId === oldId ? id : providerId));
    }

    case types.PROVIDER_REMOVE_COMPLETED: {
      const { id } = action.payload;
      return state.filter((providerIdState) => providerIdState !== id);
    }

    //CASE ADD STARTED
    case types.PROVIDER_ADD_STARTED: {
      const provider = action.payload;
      return [...state, provider.tempId];
    }

    //CASE ADD FAILED
    case types.PROVIDER_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((providerIdState) => providerIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDERS_FETCH_STARTED2: {
      return true;
    }
    case types.PROVIDERS_FETCH_COMPLETED2:
    case types.PROVIDERS_FETCH_FAILED2: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDERS_PARTIAL_FETCH_STARTED2: {
      return true;
    }
    case types.PROVIDERS_PARTIAL_FETCH_COMPLETED2:
    case types.PROVIDERS_PARTIAL_FETCH_FAILED2: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDER_ADD_STARTED: {
      return true;
    }
    case types.PROVIDER_ADD_COMPLETED:
    case types.PROVIDER_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDER_EDIT_STARTED: {
      return true;
    }
    case types.PROVIDER_EDIT_COMPLETED:
    case types.PROVIDER_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDER_REMOVE_STARTED: {
      return true;
    }
    case types.PROVIDER_REMOVE_COMPLETED:
    case types.PROVIDER_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.PROVIDERS_FETCH_FAILED2: {
      const { error } = action.payload;
      return error;
    }
    case types.PROVIDERS_FETCH_STARTED2:
    case types.PROVIDERS_FETCH_COMPLETED2: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.PROVIDERS_PARTIAL_FETCH_FAILED2: {
      const { error } = action.payload;
      return error;
    }
    case types.PROVIDERS_PARTIAL_FETCH_STARTED2:
    case types.PROVIDERS_PARTIAL_FETCH_COMPLETED2: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.PROVIDER_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROVIDER_ADD_STARTED:
    case types.PROVIDER_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.PROVIDER_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROVIDER_EDIT_STARTED:
    case types.PROVIDER_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.PROVIDER_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROVIDER_REMOVE_STARTED:
    case types.PROVIDER_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.PROVIDERS_FETCH_COMPLETED2: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const providers = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

export default providers;

//Own state
export const getOwnState = (state) => state.providers;

//Information
export const getProvider = (state, id) => {
  const provider = getOwnState(state).byId[id];

  return {
    ...provider
  };
};

export const getProvidersList = (state): Array<ProvidersDTO> =>
  getOwnState(state).order?.map((id) => getProvider(state, id));

export const getProvidersListByMembership = (state, membership) => {
  //Se filtra por divisionId
  if (membership.organizationId) {
    return getProvidersList(state).filter(
      (provider) => provider.organizationId === membership.organizationId
    );
  }
  //Si no se ha seleccionado una división se devuelve la lista completa
  else return getProvidersList(state);
};

//Status of sagas
export const isPartialFetchingListProviders = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListProviders = (state) => getOwnState(state).isFetchingList;
export const haveProvidersBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingProvider = (state) => getOwnState(state).isAdding;
export const isEditingProvider = (state) => getOwnState(state).isEditing;
export const isRemovingProvider = (state) => getOwnState(state).isRemoving;
//Errors
export const getPartialFetchingListProvidersErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListProvidersErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingProviderErrors = (state) => getOwnState(state).errorAdding;
export const getEditingProviderErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingProviderErrors = (state) => getOwnState(state).errorRemoving;
