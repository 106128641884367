import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { sensorsEntities, sensorsOrder } = action.payload;
      const newState = { ...state };
      sensorsOrder.forEach((sensorId) => {
        newState[sensorId] = {
          ...state[sensorId],
          ...sensorsEntities[sensorId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { sensorsOrder } = action.payload;
      return uniq([...state, ...sensorsOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const sensors = combineReducers({
  byId,
  order
});

export default sensors;

//Own state
export const getOwnState = (state) => state.units.sensors;

//Information
export const getSensor = (state, sensorId) => {
  const sensor = getOwnState(state).byId[sensorId];
  if (sensor) {
    return {
      ...sensor
    };
  } else return undefined;
};
export const getSensorsList = (state) => getOwnState(state).order.map((id) => getSensor(state, id));
