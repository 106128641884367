export const SUBDIVISIONS_FETCH_STARTED = "SUBDIVISIONS_FETCH_STARTED";
export const SUBDIVISIONS_FETCH_COMPLETED = "SUBDIVISIONS_FETCH_COMPLETED";
export const SUBDIVISIONS_FETCH_FAILED = "SUBDIVISIONS_FETCH_FAILED";

export const SUBDIVISIONS_PARTIAL_FETCH_STARTED =
  "SUBDIVISIONS_PARTIAL_FETCH_STARTED";
export const SUBDIVISIONS_PARTIAL_FETCH_COMPLETED =
  "SUBDIVISIONS_PARTIAL_FETCH_COMPLETED";
export const SUBDIVISIONS_PARTIAL_FETCH_FAILED =
  "SUBDIVISIONS_PARTIAL_FETCH_FAILED";

export const SUBDIVISION_ADD_STARTED = "SUBDIVISION_ADD_STARTED";
export const SUBDIVISION_ADD_COMPLETED = "SUBDIVISION_ADD_COMPLETED";
export const SUBDIVISION_ADD_FAILED = "SUBDIVISION_ADD_FAILED";

export const SUBDIVISION_EDIT_STARTED = "SUBDIVISION_EDIT_STARTED";
export const SUBDIVISION_EDIT_COMPLETED = "SUBDIVISION_EDIT_COMPLETED";
export const SUBDIVISION_EDIT_FAILED = "SUBDIVISION_EDIT_FAILED";

export const SUBDIVISION_REMOVE_STARTED = "SUBDIVISION_REMOVE_STARTED";
export const SUBDIVISION_REMOVE_COMPLETED = "SUBDIVISION_REMOVE_COMPLETED";
export const SUBDIVISION_REMOVE_FAILED = "SUBDIVISION_REMOVE_FAILED";

export const SUBDIVISION_SELECTED = "SUBDIVISION_SELECTED";
export const SUBDIVISIONS_ALL_SELECTED = "SUBDIVISIONS_ALL_SELECTED";
export const SUBDIVISION_DESELECTED = "SUBDIVISION_DESELECTED";
export const SUBDIVISIONS_ALL_DESELECTED = "SUBDIVISIONS_ALL_DESELECTED";
