import * as types from '../types';

export const startFetchingServiceClasses = (filters = {}, isDropdown, isWebPage = false) => ({
  type: types.SERVICECLASSES_FETCH_STARTED,
  payload: {
    filters,
    isDropdown,
    isWebPage
  }
});
export const completeFetchingServiceClasses = (entities, order) => ({
  type: types.SERVICECLASSES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingServiceClasses = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SERVICECLASSES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
export const startPartialFetchingServiceClasses = (entityTypeId) => ({
  type: types.SERVICECLASSES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId }
});
export const completePartialFetchingServiceClasses = (entities, order) => ({
  type: types.SERVICECLASSES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingServiceClasses = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SERVICECLASSES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingServiceClass = (serviceClassId) => ({
  type: types.SERVICECLASS_FETCH_STARTED,
  payload: serviceClassId
});

export const completeFetchingServiceClass = (serviceClass) => ({
  type: types.SERVICECLASS_FETCH_COMPLETED,
  payload: serviceClass
});

export const failFetchingServiceClass = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SERVICECLASS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingServiceClass = (serviceClass) => ({
  type: types.SERVICECLASS_ADD_STARTED,
  payload: serviceClass
});
export const completeAddingServiceClass = (oldId, serviceClass) => ({
  type: types.SERVICECLASS_ADD_COMPLETED,
  payload: { oldId, serviceClass }
});
export const failAddingServiceClass = (oldId, error) => ({
  type: types.SERVICECLASS_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingServiceClass = (serviceClass) => ({
  type: types.SERVICECLASS_EDIT_STARTED,
  payload: serviceClass
});
export const completeEditingServiceClass = (serviceClass) => ({
  type: types.SERVICECLASS_EDIT_COMPLETED,
  payload: serviceClass
});
export const failEditingServiceClass = (oldServiceClass, error) => ({
  type: types.SERVICECLASS_EDIT_FAILED,
  payload: {
    oldServiceClass,
    error
  }
});

export const startRemovingServiceClass = (serviceClassId) => ({
  type: types.SERVICECLASS_REMOVE_STARTED,
  payload: {
    serviceClassId
  }
});
export const completeRemovingServiceClass = (serviceClassId) => ({
  type: types.SERVICECLASS_REMOVE_COMPLETED,
  payload: {
    serviceClassId
  }
});
export const failRemovingServiceClass = (serviceClassId, error) => ({
  type: types.SERVICECLASS_REMOVE_FAILED,
  payload: {
    serviceClassId,
    error
  }
});

export const selectServiceClass = (serviceClassId) => ({
  type: types.SERVICECLASS_SELECTED,
  payload: serviceClassId
});

export const selectAllServiceClasses = (
  serviceClassIds = [] //List of ServiceClassIds
) => ({
  type: types.SERVICECLASSES_ALL_SELECTED,
  payload: serviceClassIds
});

export const deselectServiceClass = (serviceClassId) => ({
  type: types.SERVICECLASS_DESELECTED,
  payload: serviceClassId
});

export const deselectAllServiceClasses = (
  serviceClassIds = [] //List of ServiceClassIds
) => ({
  type: types.SERVICECLASSES_ALL_DESELECTED,
  payload: serviceClassIds
});
