import { Plus, PlusCircle, UploadCloud, List, Shield } from 'react-feather';
import paths from 'views/screens/vehicleMaintenance/paths';
import permissions from 'views/screens/vehicleMaintenance/permissions';
import Icon from '../../utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'vehicleMaintenance',
    title: 'sidebar.vehicleMaintenance',
    icon: <Icon size={20} icon="Maintainance" />,
    iconName: 'Maintainance',
    permissions: [
      permissions.preventiveNotices,
      permissions.sparepartsCatalog,
      permissions.workshopCatalog,
      permissions.categoriesCatalog,
      permissions.providersCatalog,
      permissions.importTicket,
      permissions.generalReport,
      permissions.byCategoryReport,
      permissions.byUnitReport,
      permissions.byWorkShopReport
    ],
    children: [
      {
        id: 'vehicleMaintenancePreventiveNotices',
        title: 'vehicleMaintenance.preventiveNotices',
        icon: <Icon size={20} icon="PreventiveNotice" />,
        iconName: 'PreventiveNotice',
        permissions: [permissions.preventiveNotices],
        navLink: paths.preventiveNotices,
        screenId: screens.PREVENTIVE_NOTICES
      },
      {
        id: 'addWorkshopOrders',
        title: 'vehicleMaintenance.addWorkshopOrders',
        icon: <Icon size={20} icon="Users" />,
        iconName: 'Users',
        permissions: [permissions.addTickets],
        navLink: paths.addWorkshopOrders,
        screenId: screens.WORKSHOP_ADD_ORDER
      },
      {
        id: 'vehicleMaintenanceCatalogs',
        title: 'vehicleMaintenance.catalogs',
        icon: <Shield size={20} />,
        iconName: 'Shield',
        permissions: [
          permissions.sparepartsCatalog,
          permissions.workshopCatalog,
          permissions.categoriesCatalog,
          permissions.providersCatalog
        ],
        children: [
          {
            id: 'vehicleMaintenanceSpareParts',
            title: 'vehicleMaintenance.sparepart',
            icon: <Icon size={20} icon="Spareparts" />,
            iconName: 'Spareparts',
            permissions: [permissions.sparepartsCatalog],
            navLink: paths.sparepartsCatalog,
            screenId: screens.SPAREPARTS_CATALOG
          },
          {
            id: 'vehicleMaintenanceWorkForce',
            title: 'vehicleMaintenance.workmanships',
            icon: <Icon size={20} icon="Workmanship" />,
            iconName: 'Workmanship',
            permissions: [permissions.workshopCatalog],
            navLink: paths.workshopCatalog,
            screenId: screens.WORKMANSHIPS_CATALOG
          },
          {
            id: 'vehicleMaintenanceCategories',
            title: 'vehicleMaintenance.categories',
            iconName: 'Plus',
            icon: <Plus size={20} />,
            permissions: [permissions.categoriesCatalog],
            navLink: paths.categoriesCatalog,
            screenId: screens.SPAREPARTS_CATEGORIES_CATALOG
          },
          {
            id: 'vehicleMaintenanceProviders',
            title: 'vehicleMaintenance.providers',
            icon: <Icon size={20} icon="SparepartProvider" />,
            iconName: 'SparepartProvider',
            permissions: [permissions.providersCatalog],
            navLink: paths.providersCatalog,
            screenId: screens.SPAREPARTS_PROVIDERS_CATALOG
          },
          {
            id: 'vehicleMaintenanceRanges',
            title: 'maintenanceLines.maintenanceLines',
            icon: <Icon size={20} icon="Activity" />,
            iconName: 'Activity',
            permissions: [permissions.maintenanceLines],
            navLink: paths.maintenanceLineList,
            screenId: screens.MAINTENANCE_RANGES
          }
        ]
      },
      {
        id: 'vehicleMaintenanceReports',
        title: 'vehicleMaintenance.reports',
        icon: <Shield size={20} />,
        iconName: 'Shield',
        permissions: [
          permissions.generalReport,
          permissions.byCategoryReport,
          permissions.byUnitReport,
          permissions.byWorkShopReport
        ],
        children: [
          {
            id: 'vehicleMaintenanceGeneralReport',
            title: 'vehicleMaintenance.generalReport',
            icon: <Plus size={20} />,
            iconName: 'Plus',
            permissions: [permissions.generalReport],
            navLink: paths.generalReport,
            screenId: screens.GENERAL_MAINTENANCE_REPORT
          },
          {
            id: 'vehicleMaintenanceByCategoryReport',
            title: 'vehicleMaintenance.byCategory',
            iconName: 'Plus',
            icon: <Plus size={20} />,
            permissions: [permissions.byCategoryReport],
            navLink: paths.byCategoryReport,
            screenId: screens.BY_CATEGORY_REPORT
          },
          {
            id: 'vehicleMaintenanceByUnitReport',
            title: 'vehicleMaintenance.byUnit',
            iconName: 'Plus',
            icon: <Plus size={20} />,
            permissions: [permissions.byUnitReport],
            navLink: paths.byUnitReport,
            screenId: screens.BY_UNIT_REPORT
          },
          {
            id: 'vehicleMaintenanceByWorkShopReport',
            title: 'vehicleMaintenance.byWorkShop',
            iconName: 'Plus',
            icon: <Plus size={20} />,
            permissions: [permissions.byWorkShopReport],
            navLink: paths.byWorkShopReport,
            screenId: screens.BY_WORKSHOP_REPORT
          }
        ]
      },
      {
        id: 'vehicleMaintenanceImportTicket',
        title: 'vehicleFuel.importTicket',
        iconName: 'UploadCloud',
        icon: <UploadCloud size={20} />,
        permissions: [permissions.importTicket],
        navLink: paths.importTicket,
        screenId: screens.IMPORT_MAINTENANCE_TICKETS
      }
    ]
  }
];
