import { HttpWebtrack } from 'utility/http';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/location';

interface LocationImage {
  locationId: number;
  imageTypeId: number;
  imageName: string;
  imageUrl: string;
  imageDescription: string;
  isDefault: boolean;
  imageLocationId?: number;
  locationImageId?: number;
}

interface LocationImagesRequest {
  locationId: number;
}

interface LocationDeleteRequest {
  locationId: number;
  locationImageId: number;
  imageName: string;
}

export const saveLocationImage = (request: LocationImage) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.imageName}`,
    object: 'locations.locationImage',
    action: request.locationImageId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/saveLocationImage`, request);
};

export const getLocationImages = (request: LocationImagesRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  return httpWebtrack.post(`${baseUrl}/getLocationImages`, request);
};

export const deleteLocationImage = (request: LocationDeleteRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.imageName}`,
    object: 'locations.locationImage',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/deleteLocationImage`, request);
};
