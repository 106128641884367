export const VEHICLE_MAKERS_FETCH_STARTED = 'VEHICLE_MAKERS_FETCH_STARTED';
export const VEHICLE_MAKERS_FETCH_COMPLETED = 'VEHICLE_MAKERS_FETCH_COMPLETED';
export const VEHICLE_MAKERS_FETCH_FAILED = 'VEHICLE_MAKERS_FETCH_FAILED';

export const VEHICLE_MAKERS_PARTIAL_FETCH_STARTED = 'VEHICLE_MAKERS_PARTIAL_FETCH_STARTED';
export const VEHICLE_MAKERS_PARTIAL_FETCH_COMPLETED = 'VEHICLE_MAKERS_PARTIAL_FETCH_COMPLETED';
export const VEHICLE_MAKERS_PARTIAL_FETCH_FAILED = 'VEHICLE_MAKERS_PARTIAL_FETCH_FAILED';

export const VEHICLE_MAKER_FETCH_STARTED = 'VEHICLE_MAKER_FETCH_STARTED';
export const VEHICLE_MAKER_FETCH_COMPLETED = 'VEHICLE_MAKER_FETCH_COMPLETED';
export const VEHICLE_MAKER_FETCH_FAILED = 'VEHICLE_MAKER_FETCH_FAILED';

export const VEHICLE_MAKER_SELECTED = 'VEHICLE_MAKER_SELECTED';
export const VEHICLE_MAKERS_ALL_SELECTED = 'VEHICLE_MAKERS_ALL_SELECTED';
export const VEHICLE_MAKER_DESELECTED = 'VEHICLE_MAKER_DESELECTED';
export const VEHICLE_MAKERS_ALL_DESELECTED = 'VEHICLE_MAKERS_ALL_DESELECTED';
