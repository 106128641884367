/* -------------------------------------------------------------------------- */
/*                   Schema Execution Route Checkpoints                       */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const routeCheckpoint = new schema.Entity(
  'routeCheckpoints',
  {},
  { idAttribute: (value) => `${value.routeId}-${value.checkpointId}` }
);
export const routeCheckpoints = new schema.Array(routeCheckpoint);
