import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Plus } from 'react-feather';
import { FormattedMessage } from 'react-intl';

import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import * as authSelectors from '../../../redux/reducers/auth';

interface Props {
  id?: string;
  isDisabled?: boolean;
  permissions?: string[];
  handleClick: () => void;
  tooltipMessage?: string; // only strings because of additional format
  visible?: boolean;
}

const AddButton = (props: Props) => {
  const store = useSelector((state) => state);
  const { isDisabled, handleClick, tooltipMessage, permissions, visible = true, id = '' } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (!permissions || authSelectors.getAuthUserHasPermissionsAny(store, permissions)) &&
    visible ? (
    <Button.Ripple
      id={`addButton${id}`}
      className={`btn-icon ${isDisabled ? 'disabled' : ''}`}
      color="success"
      disabled={isDisabled}
      onClick={() => handleClick()}
    >
      <Plus size={16} />
      {tooltipMessage && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={`addButton${id}`}
          toggle={toggle}
          trigger="click hover"
        >
          <FormattedMessage
            id={'buttons.add'}
            values={{ objectType: appIntl().formatMessage({ id: tooltipMessage }) }}
          />
        </Tooltip>
      )}
    </Button.Ripple>
  ) : null;
};

AddButton.defaultProps = {
  isDisabled: false
};

export default AddButton;
