import * as types from '../../types/report';

export const startFetchingJobsReport = (filters = {}) => ({
  type: types.JOBS_REPORT_FETCH_STARTED,
  payload: filters
});
export const completeFetchingJobsReport = (
  indicators,
  entities,
  order,
  entitiesByUser,
  orderByUser
) => ({
  type: types.JOBS_REPORT_FETCH_COMPLETED,
  payload: {
    indicators,
    entities,
    order,
    entitiesByUser,
    orderByUser
  }
});
export const failFetchingJobsReport = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.JOBS_REPORT_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
