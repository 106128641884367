import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as entitySelectors from '../../../Entities/reducers';
import * as actions from '../../actions/backlog';
import * as selectors from '../../reducers/backlog';
import * as schemas from '../../schemas';
import * as schemasImportedJobs from '../../schemas/importJobs';
import {
  // deleteJobBacklogService,
  getJobsBacklogListService,
  saveJobBacklogService,
  deleteJobsBacklogService,
  changeJobsDueDateService,
  changeJobsMinMaxTimeService,
  changeJobsStartDateService,
  changeJobsStatusService,
  changeJobsPriorityService,
  assignJobsFilesService,
  assignJobsUsersService,
  assignJobsFormsService,
  rescheduleJobsService,
  importJobsService
} from '../../services/jobs';
import * as types from '../../types/backlog';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchJobsBacklogList(action) {
  getJobsBacklogListService(
    {},
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { jobs },
          result //order
        } = normalize(resultData, schemas.jobs); //normalize data to byId and order
        store.dispatch(actions.completeFetchingJobsBacklog(jobs, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingJobsBacklog(error));
      }
    }
  );
}

export function* watchFetchJobsBacklogList() {
  yield takeEvery(types.JOBS_BACKLOG_FETCH_STARTED, fetchJobsBacklogList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETSH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchJobsBacklogList(action) {
  const entityTypeId = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  getJobsBacklogListService(
    { userFetchDate: date },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { jobs },
          result //order
        } = normalize(resultData, schemas.jobs); //normalize data to byId and order
        store.dispatch(actions.completePartialFetchingJobsBacklog(jobs, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingJobsBacklog(error));
      }
    }
  );
}

export function* watchPartialFetchJobsBacklogList() {
  yield takeEvery(types.JOBS_BACKLOG_PARTIAL_FETCH_STARTED, partialFetchJobsBacklogList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addJobBacklog(action) {
  const jobBacklog = action.payload;
  saveJobBacklogService(
    { ...jobBacklog, jobId: null },
    {
      successAction: (response) => {
        const addedJobBacklog = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingJobBacklog(jobBacklog.jobId, addedJobBacklog));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingJobBacklog(jobBacklog.jobId, error));
      }
    }
  );
}

export function* watchAddJobsBacklogStarted() {
  yield takeEvery(types.JOB_BACKLOG_ADD_STARTED, addJobBacklog);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editJobBacklog(action) {
  const jobBacklog = action.payload;
  const oldJobBacklog = yield select((state) => selectors.getJobBacklog(state, jobBacklog.jobId));
  saveJobBacklogService(
    { ...jobBacklog },
    {
      successAction: (response) => {
        const editedJobBacklog = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingJobBacklog(editedJobBacklog));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingJobBacklog(oldJobBacklog, error));
      }
    }
  );
}

export function* watchEditJobsBacklogStarted() {
  yield takeEvery(types.JOB_BACKLOG_EDIT_STARTED, editJobBacklog);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeJobsBacklog(action) {
  const { jobs } = action.payload;
  // const jobBacklog = yield select((state) => selectors.getJobBacklog(state, jobId));
  deleteJobsBacklogService(
    { jobs },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingJobsBacklog(jobs));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingJobsBacklog(jobs, error));
      }
    }
  );
}

export function* watchRemoveJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_REMOVE_STARTED, removeJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE STATUS                                   */
/* -------------------------------------------------------------------------- */
function* changeStatusJobsBacklog(action) {
  const { jobs, jobStatus, comment } = action.payload;
  changeJobsStatusService(
    { jobs, jobStatus, comment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangeStatusJobs(jobs, jobStatus, comment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangeStatusJobs(jobs, error));
      }
    }
  );
}

export function* watchChangeStatusJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_CHANGE_STATUS_STARTED, changeStatusJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                           RESCHEDULE JOBS                                 */
/* -------------------------------------------------------------------------- */
function* rescheduleJobs(action) {
  const { jobs, startDate, dueDate, comment } = action.payload;
  rescheduleJobsService(
    { jobs, startDate, dueDate, comment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRescheduleJobs(jobs, startDate, dueDate, comment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRescheduleJobs(jobs, error));
      }
    }
  );
}

export function* watchRescheduleJobsStarted() {
  yield takeEvery(types.JOBS_BACKLOG_RESCHEDULE_JOBS_STARTED, rescheduleJobs);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE PRIORITY                                 */
/* -------------------------------------------------------------------------- */
function* changePriorityJobsBacklog(action) {
  const { jobs, jobPriority } = action.payload;
  changeJobsPriorityService(
    { jobs, jobPriority },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangePriorityJobs(jobs, jobPriority));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangePriorityJobs(jobs, error));
      }
    }
  );
}

export function* watchChangePriorityJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_CHANGE_PRIORITY_STARTED, changePriorityJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE START DATE                               */
/* -------------------------------------------------------------------------- */
function* changeStartDateJobsBacklog(action) {
  const { jobs, startDate } = action.payload;
  changeJobsStartDateService(
    { jobs, startDate },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangeStartDateJobs(jobs, startDate));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangeStartDateJobs(jobs, error));
      }
    }
  );
}

export function* watchChangeStartDateJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_CHANGE_START_DATE_STARTED, changeStartDateJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE DUE DATE                                 */
/* -------------------------------------------------------------------------- */
function* changeDueDateJobsBacklog(action) {
  const { jobs, dueDate } = action.payload;
  changeJobsDueDateService(
    { jobs, dueDate },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangeDueDateJobs(jobs, dueDate));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangeDueDateJobs(jobs, error));
      }
    }
  );
}

export function* watchChangeDueDateJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_CHANGE_DUE_DATE_STARTED, changeDueDateJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE MAX TIME                                 */
/* -------------------------------------------------------------------------- */
function* changeMinMaxTimeJobsBacklog(action) {
  const { jobs, maxTime } = action.payload;
  changeJobsMinMaxTimeService(
    { jobs, maxTime },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangeMinMaxTimeJobs(jobs, maxTime));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangeMinMaxTimeJobs(jobs, error));
      }
    }
  );
}

export function* watchChangeMinMaxTimeJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_CHANGE_MIN_MAX_TIME_STARTED, changeMinMaxTimeJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            ASSIGN USERS                                    */
/* -------------------------------------------------------------------------- */
function* assignUsersJobsBacklog(action) {
  const { jobs, userNames, users } = action.payload;
  assignJobsUsersService(
    { jobs, userNames },
    {
      successAction: (response) => {
        store.dispatch(actions.completeAssignUsersJobs(jobs, userNames, users));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAssignUsersJobs(jobs, error));
      }
    }
  );
}

export function* watchAssignUsersJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_ASSIGN_USERS_STARTED, assignUsersJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            ASSIGN FORMS                                    */
/* -------------------------------------------------------------------------- */
function* assignFormsJobsBacklog(action) {
  const { jobs, formIds, forms } = action.payload;
  assignJobsFormsService(
    { jobs, formIds },
    {
      successAction: (response) => {
        store.dispatch(actions.completeAssignFormsJobs(jobs, formIds, forms));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAssignFormsJobs(jobs, error));
      }
    }
  );
}

export function* watchAssignFormsJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_ASSIGN_FORMS_STARTED, assignFormsJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                            ASSIGN FILES                                    */
/* -------------------------------------------------------------------------- */
function* assignFilesJobsBacklog(action) {
  const { jobs, fileIds } = action.payload;
  assignJobsFilesService(
    { jobs, fileIds },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { jobs: entities },
          result //order
        } = normalize(resultData, schemas.jobs); //normalize data to byId and order
        store.dispatch(
          actions.completeAssignFilesJobs(
            entities ??
              jobs.split(',').reduce((acc, curr) => ((acc[curr] = { files: '' }), acc), {}),
            result.length > 0 ? result : jobs.split(',')
          )
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failAssignFilesJobs(jobs, error));
      }
    }
  );
}

export function* watchAssignFilesJobsBacklogStarted() {
  yield takeEvery(types.JOBS_BACKLOG_ASSIGN_FILES_STARTED, assignFilesJobsBacklog);
}

/* -------------------------------------------------------------------------- */
/*                                    IMPORT                                  */
/* -------------------------------------------------------------------------- */
function* importJobs(action) {
  const jobs = action.payload;
  importJobsService(
    { ...jobs },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { importedJobs },
          result //order
        } = normalize(resultData, schemasImportedJobs.importedJobs); //normalize data to byId and order
        store.dispatch(actions.completeImportingJobs(importedJobs, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failImportingJobs(error));
      }
    }
  );
}

export function* watchImportJobsStarted() {
  yield takeEvery(types.JOBS_BACKLOG_IMPORT_STARTED, importJobs);
}
