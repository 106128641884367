import wkx, { GeometryCollection, Polygon } from 'wkx';
import L from 'leaflet';

export const getExtraGeofenceSaveValues = (geofence) => {
  let vertexCount: number | undefined;
  let latitude: number | undefined;
  let longitude: number | undefined;
  let latitude2: number | undefined;
  let longitude2: number | undefined;
  let vertexCsv: string | undefined;
  let geofenceWKT = geofence.geofenceWktString;
  let geometry = wkx.Geometry.parse(geofenceWKT);
  let geofenceGeoJSON: any = geometry.toGeoJSON();
  if (geofence.shape == 'C') {
    const coords = L.GeoJSON.coordsToLatLng(geofenceGeoJSON.coordinates);
    latitude = coords.lat;
    longitude = coords.lng;
    vertexCount = 1;
  } else if (geofence.shape == 'R') {
    const providedCoordinates = geofenceGeoJSON?.coordinates;
    const coords =
      geofenceGeoJSON.type == 'LineString'
        ? L.GeoJSON.coordsToLatLngs(providedCoordinates)
        : L.GeoJSON.coordsToLatLngs(providedCoordinates[0]);
    latitude = coords[0].lat;
    longitude = coords[0].lng;
    latitude2 = coords[2].lat;
    longitude2 = coords[2].lng;
    vertexCount = 4;
  } else if (geofence.shape == 'P') {
    const providedCoordinates = geofenceGeoJSON?.coordinates;

    let coords;

    if (geofenceGeoJSON.type == 'LineString') {
      coords = L.GeoJSON.coordsToLatLngs(providedCoordinates);
    } else if (geometry instanceof GeometryCollection) {
      geometry.geometries.forEach((c) => {
        const geoJson: any = c.toGeoJSON();
        if (geoJson?.type == 'LineString') {
          coords = L.GeoJSON.coordsToLatLngs(geoJson.coordinates);
        } else {
          coords = L.GeoJSON.coordsToLatLngs(geoJson.coordinates[0]);
        }
      });
    } else {
      coords = L.GeoJSON.coordsToLatLngs(providedCoordinates[0]);
    }

    vertexCount = coords.length - 1;
    vertexCsv = coords
      .map((vertex, index) => {
        return `${index + 1},${vertex.lat},${vertex.lng}`;
      })
      .join(';');
  }

  return { vertexCount, longitude, longitude2, latitude, latitude2, vertexCsv, geofenceWKT };
};
