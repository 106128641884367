import { MapPin } from 'react-feather';
import locations from '../../views/screens/Locations/sidebarLayout';
import geofences from './geofences';

export default [
  {
    id: 'pointsOfInterest',
    title: 'sidebar.pointsOfInterest',
    icon: <MapPin size={20} />,
    iconName: 'MapPin',
    permissions: [],
    children: [...geofences, ...locations]
  }
];
