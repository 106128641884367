/* -------------------------------------------------------------------------- */
/*                               Schema Operators                             */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const operator = new schema.Entity(
  "operators",
  {},
  { idAttribute: "operatorId" }
);
export const operators = new schema.Array(operator);
