import {
  ArrowDownCircle,
  BatteryCharging,
  Circle,
  Clock,
  MapPin,
  Navigation,
  PauseCircle,
  PlayCircle,
  Power,
  StopCircle,
  Watch,
  Wifi,
  WifiOff
} from 'react-feather';
import { UnitMainState, UnitsMainState } from 'views/screens/Units/types';
import Icon from 'utility/icomoon';

export const MainStateType: UnitsMainState = {
  ONLINE: {
    id: 1000,
    intl: 'units.mainStateId.1000',
    color: '#00D57E',
    textColor: '#75BB5A',
    tooltip: 'units.mainStateId.tooltip.1000',
    shortIntl: 'units.mainStateId.1000'
  },
  OFFLINE: {
    id: 0,
    intl: 'units.mainStateId.0',
    color: '#BA0000',
    textColor: '#FFFFFF',
    tooltip: 'units.mainStateId.tooltip.0',
    shortIntl: 'units.mainStateId.0'
  },
  OFF: {
    id: 1,
    intl: 'units.mainStateId.1',
    color: '#FFFF00',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.1',
    shortIntl: 'units.mainStateId.1'
  },
  MOVING: {
    id: 2,
    intl: 'units.mainStateId.2',
    color: '#00D57E',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.2',
    shortIntl: 'units.mainStateId.2'
  },
  ON_IDLE: {
    id: 3,
    intl: 'units.mainStateId.3',
    color: '#FF4459',
    textColor: '#FFFFFF',
    tooltip: 'units.mainStateId.tooltip.3',
    shortIntl: 'units.mainStateId.shortIntl.3'
  },
  ON_STANDBY: {
    id: 4,
    intl: 'units.mainStateId.4',
    color: '#FF7A00',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.4',
    shortIntl: 'units.mainStateId.shortIntl.4'
  },
  ON_STARTED: {
    id: 5,
    intl: 'units.mainStateId.5',
    color: '#00EAD5',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.5',
    shortIntl: 'units.mainStateId.shortIntl.5'
  },
  DOWNTIME: {
    id: 6,
    intl: 'units.mainStateId.6',
    color: '#7B7B74',
    textColor: '#FFFFFF',
    tooltip: 'units.mainStateId.tooltip.6',
    shortIntl: 'units.mainStateId.6'
  },
  ON_STOPPED: {
    id: 7,
    intl: 'units.mainStateId.7',
    color: '#FFC737',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.7',
    shortIntl: 'units.mainStateId.shortIntl.7'
  },
  ON_MOVING: {
    id: 8,
    intl: 'units.mainStateId.8',
    color: '#00D57E',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.8',
    shortIntl: 'units.mainStateId.shortIntl.8'
  },
  STOPPED: {
    id: 9,
    intl: 'units.mainStateId.9',
    color: '#FDAC34',
    textColor: '#1C1C1C',
    tooltip: 'units.mainStateId.tooltip.9',
    shortIntl: 'units.mainStateId.9'
  },
  OPPORTUNITY_CHARGE: {
    id: 10,
    intl: 'units.mainStateId.10',
    color: '#7669e8',
    textColor: '#FFFFFF',
    tooltip: 'units.mainStateId.tooltip.10',
    shortIntl: 'units.mainStateId.shortIntl.10'
  },
  DISCONNECTED: {
    id: 11,
    intl: 'units.mainStateId.11',
    color: '#DD77DD',
    textColor: '#000000',
    tooltip: 'units.mainStateId.tooltip.11',
    shortIntl: 'units.mainStateId.shortIntl.11'
  }
};

/**
 * Get Icon by state id
 * @param id id of state
 * @returns Icon as JSX element
 */
export const getIconByStatId = (id: number): JSX.Element => {
  switch (id) {
    case 1000:
      return <Wifi />;
    case 0:
      return <WifiOff />;
    case 1:
      return <Power />;
    case 2:
      return <MapPin />;
    case 3:
      return <Watch />;
    case 4:
      return <Clock />;
    case 5:
      return <PlayCircle />;
    case 6:
      return <ArrowDownCircle />;
    case 7:
      return <PauseCircle />;
    case 8:
      return <Navigation />;
    case 9:
      return <StopCircle />;
    case 10:
      return <BatteryCharging />;
  }
  return <Circle />;
};

export const GetMainStateById = (id: number): UnitMainState => {
  //console.log('unitLiveStatus.mainStateId==>', id);
  let state = MainStateType.ONLINE;
  switch (id) {
    case 100:
      state = MainStateType.ONLINE;
      break;
    case 0:
      state = MainStateType.OFFLINE;
      break;
    case 1:
      state = MainStateType.OFF;
      break;
    case 2:
      state = MainStateType.MOVING;
      break;
    case 3:
      state = MainStateType.ON_IDLE;
      break;
    case 4:
      state = MainStateType.ON_STANDBY;
      break;
    case 5:
      state = MainStateType.ON_STARTED;
      break;
    case 6:
      state = MainStateType.DOWNTIME;
      break;
    case 7:
      state = MainStateType.ON_STOPPED;
      break;
    case 8:
      state = MainStateType.ON_MOVING;
      break;
    case 9:
      state = MainStateType.STOPPED;
      break;
    case 10:
      state = MainStateType.OPPORTUNITY_CHARGE;
      break;
    case 11:
      state = MainStateType.DISCONNECTED;
      break;
    default:
      state = MainStateType.ONLINE;
      break;
  }
  //console.log('unitLiveStatus.mainState==>', state);
  return state;
};

export const GetMainStateStyle = (
  state: UnitMainState
): {
  backgroundColor: string;
  color: string;
} => ({
  backgroundColor: state.color,
  color: state.textColor
});

export const GetMainStateName = (
  mainState: UnitMainState,
  mainStateTitle: string,
  mainStateDate: string,
  speed: string,
  downTimeReason: string,
  extraMessage: string
): string =>
  mainStateTitle +
  ([MainStateType.MOVING.id, MainStateType.ON_MOVING.id].includes(mainState.id)
    ? ' - ' + speed
    : mainState.id === MainStateType.DOWNTIME.id
    ? ' - ' + downTimeReason
    : mainState.id === MainStateType.OFFLINE.id
    ? ''
    : mainStateDate
    ? ' - ' + extraMessage
    : '');

export const getUnitIcon = (themeId: number): string => {
  switch (themeId) {
    case 0:
      return 'Normal';
    case 1:
      return 'Car';
    case 2:
      return 'Buses';
    case 3:
      return 'Motorcycles';
    case 4:
      return 'Trucks';
    case 5:
      return 'Car';
    //   return 'Funny'; //Pendiente
    case 6:
      return 'PersonalMale';
    case 7:
      return 'PersonalFemale';
    case 8:
      return 'Panel';
    case 9:
      return 'PickUp';
    case 10:
      return 'MiniMan';
    case 11:
      return 'Container';
    case 12:
      return 'Cab';
    case 13:
      return 'Platform';
    case 14:
      return 'ManHealth';
    case 15:
      return 'WomanHealth';
    case 16:
      return 'Taxis';
    case 17:
      return 'Ship';
    case 18:
      return 'CargoShip';
    case 19:
      return 'Sailboat';
    case 20:
      return 'Excavator';
    case 21:
      return 'Bulldozer';
    case 22:
      return 'LiftTruck';
    case 23:
      return 'Scooter';
    case 24:
      return 'JetSki';
    case 25:
      return 'Ambulances';
    case 26:
      return 'Planes';
    case 27:
      return 'Airplanes';
    case 28:
      return 'Helicopter';
    case 29:
      return 'DumpTruck';
    case 30:
      return 'Steamroller';
    case 31:
      return 'TankerTruck';
    case 32:
      return 'ConcreteMixerTruck';
    case 33:
      return 'CraneTruck';
    case 34:
      return 'DeliveryTruck';
    case 35:
      return 'Tank';
    case 200:
      return 'Special';
  }
  return 'Special';
};

export const getUnitActionIcon = (deviceActionId: number): string => {
  switch (deviceActionId) {
    case 0:
      return 'RequestLocation';
    case 1:
      return 'Paralyze';
    case 2:
      return 'Enable';
    case 3:
      return 'OpenDoor';
    case 4:
      return 'OpenDoor2';
    case 5:
      return 'ScriptLoader';
    case 6:
      return 'Geofences1';
    case 8:
      return 'Measurement';
    case 9:
      return 'OpenPadlock';
    case 11:
      return 'RequestMileage';
    case 12:
      return 'ChangeTemperatureLimits';
    case 13:
      return 'ClosePadlock';
    case 20:
      return 'NormalFrequency2m_10m';
    case 21:
      return 'SlowFrequency2h';
    case 22:
      return 'FastFrequency1m_5m';
    case 23:
      return 'SlowFrequency4h_4h';
    case 30:
      return 'Band850'; //Pendiente
    case 31:
      return 'Band900'; //Pendiente
    case 32:
      return 'Band1800'; //Pendiente
    case 33:
      return 'Band1900'; //Pendiente
    case 34:
      return 'Band850_1900GuatemalaNicaragua'; //Pendiente
    case 35:
      return 'Band900_1800CostaRica'; //Pendiente
    case 36:
      return 'Band900_1900'; //Pendiente
    case 50:
      return 'Screenshot';
    case 51:
      return 'StartVideoCameraRecording';
    case 52:
      return 'StopVideoRecording';
    case 53:
      return 'CaptureImageOutdoorCamera';
    case 54:
      return 'StartOutdoorVideoCameraRecording';
    case 55:
      return 'LiveVideo';
    case 56:
      return 'LiveVideoOutdoorCamera';
    case 57:
      return 'StopLiveVideo';
    case 60:
      return 'ActivateMonitoringVoice';
    case 61:
      return 'DisableMonitoringVoice';
    case 70:
      return 'ActivateLapReport';
    case 71:
      return 'TurnOffLapReporting';
    case 80:
      return 'EnableSecurity';
    case 81:
      return 'DisableSecurity';
    case 90:
      return 'ResetUnit';
    case 91:
      return 'ResetGPS';
    case 92:
      return 'FullTank';
    case 93:
      return 'FastFrequency30seg_5m';
    case 100:
      return 'ScriptResponse';
    case 1000:
      return 'SetMaximumSpeed';
    case 1001:
      return 'SetMaximumStopTime';
    case 1002:
      return 'ManualReport';
    case 1003:
      return 'MovementTransmissionFrequency';
    case 1004:
      return 'ParkedTransmissionFrequency';
    case 1005:
      return 'InstantGPSModuleChange';
    case 1006:
      return 'Message';
    case 1007:
      return 'AssignMonitoringUser';
    case 1008:
      return 'UnassignMonitoringUser';
    case 1009:
      return 'TransmissionFrequency';
    case 1010:
      return 'ActivateLongRoutes';
    case 1011:
      return 'DisableLongRoutes';
    case 1200:
      return 'GoToNextSection';
  }
  return 'GoToNextSection';
};

export const getUnitMainStateIconAndSize = (mainStateId: number): Array<string | number> => {
  switch (mainStateId) {
    case 1:
      return ['Alert-12', 30]; //Apagado
    case 2:
      return ['Moving', 30]; //En movimiento
    case 3:
      return ['Idle', 30]; //Ocioso
    case 4:
      return ['Stopped', 30]; //Detenido
    case 5:
      return ['Alert-11', 30]; //Encendido
    default:
      return ['XCircle', 17]; //Fuera de línea
  }
};

export const getUnitCommunicationDiagnosticsIcon = (lastCommunicationDate) => {
  if (lastCommunicationDate) {
    const date1 = new Date();
    const date2 = new Date(lastCommunicationDate);
    const hoursDiff = Math.abs(date1.getTime() - date2.getTime()) / 36e5;
    if (hoursDiff <= 1 / 3) return 'CommunicationDiagnosticsGreen';
    else if (hoursDiff <= 24) return 'CommunicationDiagnosticsYellow';
    else return 'CommunicationDiagnosticsRed';
  } else return 'CommunicationDiagnosticsRed';
};

export const getUnitHeadingIcon = (heading = 0) => {
  //Norte
  if (heading < 22.5) return 'ArrowUp';
  //Noreste
  else if (heading < 67.5) return 'ArrowUpRight';
  //Este
  else if (heading < 112.5) return 'ArrowRight';
  //Sureste
  else if (heading < 157.5) return 'ArrowDownRight';
  //Sur
  else if (heading < 202.5) return 'ArrowDown';
  //Suroeste
  else if (heading < 247.5) return 'ArrowDownLeft';
  //Oeste
  else if (heading < 292.5) return 'ArrowLeft';
  //Noroeste
  else if (heading < 337.5) return 'ArrowUpLeft';
};

export const getUnitHeadingFormat = (heading = 0) => {
  //Norte
  if (heading < 22.5) return 'common.north';
  //Noreste
  else if (heading < 67.5) return 'common.northeast';
  //Este
  else if (heading < 112.5) return 'common.east';
  //Sureste
  else if (heading < 157.5) return 'common.southeast';
  //Sur
  else if (heading < 202.5) return 'common.south';
  //Suroeste
  else if (heading < 247.5) return 'common.southwest';
  //Oeste
  else if (heading < 292.5) return 'common.west';
  //Noroeste
  else return 'common.norwest';
};

export const getDatesDifferenceString = (date1, date2, intl) => {
  const [endTime, startTime] =
    new Date(date1).getTime() > new Date(date2).getTime()
      ? [new Date(date1).getTime(), new Date(date2).getTime()]
      : [new Date(date2).getTime(), new Date(date1).getTime()];
  return getTimeDifferenceString(startTime, endTime, intl);
};

export const getTimeDifferenceString = (startTime, endTime, intl) => {
  const days = Math.floor(Math.abs(endTime - startTime) / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    Math.abs(endTime - (startTime + 1000 * 60 * 60 * 24 * days)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    Math.abs(endTime - (startTime + 1000 * 60 * 60 * (24 * days + hours))) / (1000 * 60)
  );
  const seconds = Math.floor(
    Math.abs(endTime - (startTime + 1000 * 60 * (24 * 60 * days + 60 * hours + minutes))) / 1000
  );
  return (
    (days > 0 ? `${days} ${intl.formatMessage({ id: 'common.days' })} ` : '') +
    (hours > 0 ? `${hours} ${intl.formatMessage({ id: 'common.hours' })} ` : '') +
    (minutes > 0 ? `${minutes} ${intl.formatMessage({ id: 'common.minutes' })} ` : '') +
    (seconds > 0 ? `${seconds} ${intl.formatMessage({ id: 'common.seconds' })} ` : '')
  );
};

export const getIconByDatesDifference = (date1, date2) => {
  const endDate = new Date(date1).getTime();
  const startDate = new Date(date2).getTime();
  const minutes = Math.floor(Math.abs(endDate - startDate) / (1000 * 60));
  if (minutes >= 0 && minutes <= 5) {
    return 'Parking01';
  } else if (minutes > 5 && minutes <= 15) {
    return 'Parking02';
  } else if (minutes > 15 && minutes <= 30) {
    return 'Parking03';
  } else if (minutes > 30 && minutes <= 60) {
    return 'Parking04';
  } else if (minutes > 60 && minutes <= 60 * 4) {
    return 'Parking04';
  } else if (minutes > 60 * 4 && minutes <= 60 * 8) {
    return 'Parking06';
  } else if (minutes > 60 * 8) {
    return 'Parking07';
  }
};
