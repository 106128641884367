export const INSURANCE_COMPANIES_FETCH_STARTED = 'INSURANCE_COMPANIES_FETCH_STARTED';
export const INSURANCE_COMPANIES_FETCH_COMPLETED = 'INSURANCE_COMPANIES_FETCH_COMPLETED';
export const INSURANCE_COMPANIES_FETCH_FAILED = 'INSURANCE_COMPANIES_FETCH_FAILED';

export const INSURANCE_COMPANIES_PARTIAL_FETCH_STARTED =
  'INSURANCE_COMPANIES_PARTIAL_FETCH_STARTED';
export const INSURANCE_COMPANIES_PARTIAL_FETCH_COMPLETED =
  'INSURANCE_COMPANIES_PARTIAL_FETCH_COMPLETED';
export const INSURANCE_COMPANIES_PARTIAL_FETCH_FAILED = 'INSURANCE_COMPANIES_PARTIAL_FETCH_FAILED';

export const INSURANCE_MAKER_FETCH_STARTED = 'INSURANCE_MAKER_FETCH_STARTED';
export const INSURANCE_MAKER_FETCH_COMPLETED = 'INSURANCE_MAKER_FETCH_COMPLETED';
export const INSURANCE_MAKER_FETCH_FAILED = 'INSURANCE_MAKER_FETCH_FAILED';

export const INSURANCE_MAKER_SELECTED = 'INSURANCE_MAKER_SELECTED';
export const INSURANCE_COMPANIES_ALL_SELECTED = 'INSURANCE_COMPANIES_ALL_SELECTED';
export const INSURANCE_MAKER_DESELECTED = 'INSURANCE_MAKER_DESELECTED';
export const INSURANCE_COMPANIES_ALL_DESELECTED = 'INSURANCE_COMPANIES_ALL_DESELECTED';
