import { Nav, NavItem, NavLink, Badge } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import * as IconFeather from 'react-feather';
import Icon from 'utility/icomoon';

/* -------------------------------------------------------------------------- */
/*                               Tabs Components                              */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* To use this component create the following in your main component          */
/* const [activeTab, setActiveTab] = useState(1);
  const tabs = [
    { icon: "User", title: "profile.generalData", tabId: 1 },
    { icon: "Lock", title: "profile.changePassword", tabId: 2 },
    { icon: "Map", title: "profile.mapOptions", tabId: 3 },
  ];
  const toggleTab = (tab) => {
    setActiveTab(tab);
  };         
  Tabs is a list of the tabs you want to render
  Active Tab the current active tab
  SetActiveTab your function to toggle de active tab                               
                                                                              */
/* -------------------------------------------------------------------------- */
const Tabs = ({
  activeTab,
  toggleTab,
  tabs = [],
  vertical = false,
  checkbox = false,
  useIntl = true,
  align = 'left'
}) => {
  return (
    <Nav
      className={`${vertical ? 'nav-left' : ''} justify-content-${align}`}
      pills
      vertical={vertical}
    >
      {tabs.map((tab) => {
        const IconTag = IconFeather[tab.icon];
        return (
          <NavItem key={`tabs${tab.tabId}`}>
            <NavLink active={activeTab === tab.tabId} onClick={() => toggleTab(tab.tabId)}>
              {!checkbox ? (
                IconTag ? (
                  <IconTag size={18} className={tab.title ? 'mr-1' : 'ml-50 mr-25'} />
                ) : (
                  <Icon size={18} icon={tab.icon} className={tab.title ? 'mr-1' : 'ml-50 mr-25'} />
                )
              ) : (
                tab.checkboxComponent
              )}
              {tab.title && (
                <span className="font-weight-bold">
                  {useIntl ? <FormattedMessage id={tab.title} /> : tab.title}
                </span>
              )}
              &nbsp;&nbsp;
              {tab.indicator && (
                <Badge className="badge-pill-tab-custom" pill>
                  {tab.indicator}
                </Badge>
              )}
            </NavLink>
          </NavItem>
        );
      })}
    </Nav>
  );
};

export default Tabs;
