import * as types from '../types';

export const startFetchingVehicles = (filters = {}) => ({
  type: types.VEHICLES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingVehicles = (entities, order, operatorId, organizationId) => ({
  type: types.VEHICLES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingVehicles = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.VEHICLES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingVehicles = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.VEHICLES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingVehicles = (entities, order, operatorId, organizationId) => ({
  type: types.VEHICLES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingVehicles = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.VEHICLES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingVehicle = (vehicle) => ({
  type: types.VEHICLE_ADD_STARTED,
  payload: vehicle
});
export const completeAddingVehicle = (oldId, vehicle) => ({
  type: types.VEHICLE_ADD_COMPLETED,
  payload: { oldId, vehicle }
});
export const failAddingVehicle = (oldId, error) => ({
  type: types.VEHICLE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingVehicle = (vehicle) => ({
  type: types.VEHICLE_EDIT_STARTED,
  payload: vehicle
});
export const completeEditingVehicle = (vehicle) => ({
  type: types.VEHICLE_EDIT_COMPLETED,
  payload: vehicle
});
export const failEditingVehicle = (oldVehicle, error) => ({
  type: types.VEHICLE_EDIT_FAILED,
  payload: {
    oldVehicle,
    error
  }
});

export const startRemovingVehicle = (vehicleId) => ({
  type: types.VEHICLE_REMOVE_STARTED,
  payload: {
    vehicleId
  }
});
export const completeRemovingVehicle = (vehicleId) => ({
  type: types.VEHICLE_REMOVE_COMPLETED,
  payload: {
    vehicleId
  }
});
export const failRemovingVehicle = (vehicleId, error) => ({
  type: types.VEHICLE_REMOVE_FAILED,
  payload: {
    vehicleId,
    error
  }
});

export const selectVehicle = (vehicleId) => ({
  type: types.VEHICLE_SELECTED,
  payload: vehicleId
});

export const selectAllVehicles = (
  vehicleIds = [] //List of VehicleIds
) => ({
  type: types.VEHICLES_ALL_SELECTED,
  payload: vehicleIds
});

export const deselectVehicle = (vehicleId) => ({
  type: types.VEHICLE_DESELECTED,
  payload: vehicleId
});

export const deselectAllVehicles = (
  vehicleIds = [] //List of VehicleIds
) => ({
  type: types.VEHICLES_ALL_DESELECTED,
  payload: vehicleIds
});

export const startCheckingCode = (vehicleId, licensePlate) => ({
  type: types.VEHICLE_LICENSE_PLATE_CHECK_STARTED,
  payload: {
    vehicleId,
    licensePlate
  }
});
export const completeCheckingCode = (result) => ({
  type: types.VEHICLE_LICENSE_PLATE_CHECK_COMPLETED,
  payload: result
});
export const failCheckingCode = (error) => ({
  type: types.VEHICLE_LICENSE_PLATE_CHECK_FAILED,
  payload: {
    error
  }
});
