import * as types from '../types';

export const startFetchingLocations = (filters = {}) => ({
  type: types.LOCATIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingLocations = (entities, order, operatorId, organizationId) => ({
  type: types.LOCATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingLocations = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.LOCATIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingLocations = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.LOCATIONS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingLocations = (entities, order, operatorId, organizationId) => ({
  type: types.LOCATIONS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingLocations = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.LOCATIONS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingLocation = (location) => ({
  type: types.LOCATION_ADD_STARTED,
  payload: location
});
export const completeAddingLocation = (oldId, location) => ({
  type: types.LOCATION_ADD_COMPLETED,
  payload: { oldId, location }
});
export const failAddingLocation = (oldId, error) => ({
  type: types.LOCATION_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingLocation = (location) => ({
  type: types.LOCATION_EDIT_STARTED,
  payload: location
});
export const completeEditingLocation = (location) => ({
  type: types.LOCATION_EDIT_COMPLETED,
  payload: location
});
export const failEditingLocation = (oldLocation, error) => ({
  type: types.LOCATION_EDIT_FAILED,
  payload: {
    oldLocation,
    error
  }
});

export const startRemovingLocation = (locationId) => ({
  type: types.LOCATION_REMOVE_STARTED,
  payload: {
    locationId
  }
});
export const completeRemovingLocation = (locationId) => ({
  type: types.LOCATION_REMOVE_COMPLETED,
  payload: {
    locationId
  }
});
export const failRemovingLocation = (locationId, error) => ({
  type: types.LOCATION_REMOVE_FAILED,
  payload: {
    locationId,
    error
  }
});

export const startImportingLocations = (organizationId, divisionId, subdivisionId, locations) => ({
  type: types.LOCATIONS_IMPORT_STARTED,
  payload: {
    organizationId,
    divisionId,
    subdivisionId,
    locations
  }
});
export const completeImportingLocations = (entities, order) => ({
  type: types.LOCATIONS_IMPORT_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failImportingLocations = (error) => ({
  type: types.LOCATIONS_IMPORT_FAILED,
  payload: {
    error
  }
});

export const selectLocation = (locationId) => ({
  type: types.LOCATION_SELECTED,
  payload: locationId
});

export const selectAllLocations = (
  locationIds: any[] = [] //List of LocationIds
) => ({
  type: types.LOCATIONS_ALL_SELECTED,
  payload: locationIds
});

export const deselectLocation = (locationId) => ({
  type: types.LOCATION_DESELECTED,
  payload: locationId
});

export const deselectAllLocations = (
  locationIds: any[] = [] //List of LocationIds
) => ({
  type: types.LOCATIONS_ALL_DESELECTED,
  payload: locationIds
});

export const showLocationInMap = (locationId) => ({
  type: types.LOCATION_SHOW_IN_MAP,
  payload: locationId
});

export const showAllLocationsInMap = (
  locationIds: any[] = [] //List of LocationIds
) => ({
  type: types.LOCATIONS_ALL_SHOW_IN_MAP,
  payload: locationIds
});

export const hideLocationInMap = (locationId) => ({
  type: types.LOCATION_HIDE_IN_MAP,
  payload: locationId
});

export const hideAllLocationsInMap = (
  locationIds: any[] = [] //List of LocationIds
) => ({
  type: types.LOCATIONS_ALL_HIDE_IN_MAP,
  payload: locationIds
});
