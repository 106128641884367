import * as types from '../../../types/static-catalogs/cellular-providers';

export const startFetchingProviders = (country) => ({
  type: types.PROVIDERS_FETCH_STARTED,
  payload: country
});
export const completeFetchingProviders = (entities, order) => ({
  type: types.PROVIDERS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingProviders = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.PROVIDERS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
