import administrations from './administrations';
import operations from './operations';
import telemetry from './telemetry';
import general from './general';
import generalCRM from './generalCRM';
import apps from './apps';
import generalAdmin from './generalAdmin';
import generalMonitoringCenter from './generalMonitoringCenter';

const sidebarOptions = [...general, ...apps, ...operations, ...telemetry, ...administrations];
export const sidebarOptionsCRM = [...generalCRM];
export const sidebarOptionsMonitoring = [...generalMonitoringCenter];
export const sidebarOptionsAdmin = [...generalAdmin];

export function getOptions(sidebarOptions) {
  let options = [];

  sidebarOptions.forEach((option) => {
    if (option['navLink'] != null) {
      const optionNav = {
        ...option,
        isBookmarked: false,
        link: option['navLink'],

        target: option['id'],
        icon: option['iconName']
      };
      options.push(optionNav);
    }
    if (option['children'] != null && option['header'] == null) {
      let childrenOptions = getOptions(option['children']);
      options = [...options, ...childrenOptions];
    }
  });

  return options;
}

export default sidebarOptions;
