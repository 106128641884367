import * as types from '../../../types/static-catalogs/payment-methods';

export const startFetchingPaymentMethods = (filters = {}) => ({
  type: types.PAYMENT_METHODS_FETCH_STARTED,
  payload: filters
});

export const completeFetchingPaymentMethods = (entities, order) => ({
  type: types.PAYMENT_METHODS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingPaymentMethods = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.PAYMENT_METHODS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
