import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                Common Success, Confirmation And Error Alerts               */
/* -------------------------------------------------------------------------- */
export const showSuccessMessage = ({ success, timer = 4000 }) => {
  Swal.fire({
    timer: timer,
    icon: 'success',
    text: `${success}`,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showConfirmationMessage = ({
  title = '',
  message,
  confirmText = '',
  action,
  iconColor = '#FF4757',
  actionNot = () => {},
  confirmButtonClass = 'btn btn-outline-danger mr-1',
  showCancelButton = true,
  width = 500
}) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: true,
    width: width,
    showCancelButton,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: confirmButtonClass
    },
    iconColor,
    buttonsStyling: false,
    confirmButtonText: confirmText || appIntl().formatMessage({ id: 'common.confirm' }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    } else {
      actionNot();
    }
  });
};
interface showConfirmationWithCheckboxMessageProps {
  title: string;
  message?: string;
  confirmText: string;
  inputLabel: string;
  action: Function;
  width?: any;
  initialValue?: 0 | 1;
}
export const showConfirmationWithCheckboxMessage = (
  props: showConfirmationWithCheckboxMessageProps
) => {
  const { title, message, confirmText, inputLabel, action, width, initialValue } = props;
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: true,
    width: width || 500,
    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: confirmText,
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true,
    input: 'checkbox',
    inputValue: initialValue || 0,
    inputPlaceholder: inputLabel
  }).then((result) => {
    if (result.isConfirmed) {
      action(result.value);
    }
  });
};

export const showConfirmationMessageToast = ({
  title = '',
  message,
  confirmText = '',
  iconColor = '',
  action,
  timer = 0,
  width = 500,
  showCancelButton = true
}) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    position: 'top-end',
    timer: timer === 0 ? undefined : timer,
    timerProgressBar: timer !== 0,
    didOpen(toast) {
      if (timer !== 0) {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    },
    toast: true,
    showConfirmButton: true,
    width: width,
    showCancelButton,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-primary mb-1'
    },
    iconColor: iconColor || '#3FC3EE',
    buttonsStyling: false,
    confirmButtonText: confirmText || appIntl().formatMessage({ id: 'common.confirm' }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' })
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

interface ShowErrorAlertFailChangesProps {
  error: any;
  timer?: number;
  title?: any;
  icon?: SweetAlertIcon;
}

export const showErrorAlertFailChanges = ({
  error,
  timer = undefined,
  title = 'Error',
  icon = 'error'
}: ShowErrorAlertFailChangesProps) => {
  const message =
    error == null ? appIntl().formatMessage({ id: 'common.changesFail' }) : error.errorMessage;
  Swal.fire({
    icon: icon,
    iconColor: '#FF4757',
    title: title,
    text: `${message}`,
    timer: timer,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showErrorAlertReportNoAvailable = () => {
  const message = appIntl().formatMessage({ id: 'common.reportNotAvailable' });
  Swal.fire({
    icon: 'warning',
    iconColor: '#FF4757',
    text: `${message}`,
    showConfirmButton: false,
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showLoadingReport = ({ timer = 3000 }) => {
  const message = appIntl().formatMessage({ id: 'reports.loadingReport' });
  Swal.fire({
    title: `${message}`,
    timer: timer,
    didOpen: () => {
      Swal.showLoading();
    }
  });
};

export const showErrorAlertFailChangesToast = ({ error, timer = 4000 }) => {
  const message =
    error == null ? appIntl().formatMessage({ id: 'common.changesFail' }) : error.errorMessage;
  Swal.fire({
    timer: timer,
    icon: 'error',
    text: `${message}`,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#FF4757',
    buttonsStyling: false
  });
};

type errorProps = {
  error?: { errorMessage: string } | null | undefined;
  timer?: any;
  title?: string;
};

export const showErrorAlertWarning = ({ error, timer = undefined, title = '' }: errorProps) => {
  const message =
    error == null ? appIntl().formatMessage({ id: 'common.changesFail' }) : error.errorMessage;
  Swal.fire({
    icon: 'warning',
    iconColor: '#FF4757',
    title: title,
    text: `${message}`,
    timer: timer,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                 Success Alerts Created, Edited and Deleted                 */
/* -------------------------------------------------------------------------- */
export const showSuccessCreatedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? altText
        ? 'common.createdSuccessMessageAlt'
        : 'common.createdSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessEditedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? altText
        ? 'common.editedSuccessMessageAlt'
        : 'common.editedSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessApprovedDeniedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false,
  approve = 1
}) => {
  const messageId =
    approve === 1
      ? object != null
        ? altText
          ? 'common.approvedSuccessMessageAlt'
          : 'common.approvedSuccessMessage'
        : 'common.changesSuccess'
      : object != null
      ? altText
        ? 'common.deniedSuccessMessageAlt'
        : 'common.deniedSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessAcceptedDeniedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false,
  accept = 1
}) => {
  const messageId =
    accept === 1
      ? object != null
        ? altText
          ? 'common.acceptedSuccessMessageAlt'
          : 'common.acceptedSuccessMessage'
        : 'common.changesSuccess'
      : object != null
      ? altText
        ? 'common.deniedSuccessMessageAlt'
        : 'common.deniedSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessScheduledMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? altText
        ? 'common.scheduledSuccessMessageAlt'
        : 'common.scheduledSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessRolesAndPermissionsSavedMessage = ({
  name = '',
  success = null,
  timer = 4000
}) => {
  const messageId = 'common.savedRolesAndPermissionsSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessAssignedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false,
  plural = false
}) => {
  const messageId =
    object != null
      ? altText && !plural
        ? 'common.assignedSuccessMessageAlt'
        : altText && plural
        ? 'common.assignedSuccessMessageAlts'
        : !altText && plural
        ? 'common.assignedSuccessMessages'
        : 'common.assignedSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessUnassignedMessage = ({
  object,
  name = '',
  success = null,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? altText
        ? 'common.unassignedSuccessMessageAlt'
        : 'common.unassignedSuccessMessage'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessDeletedMessage = ({
  object,
  name = '',
  success = null,
  multiple = false,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? !multiple
        ? altText
          ? 'common.deletedSuccessMessageAlt'
          : 'common.deletedSuccessMessage'
        : altText
        ? 'common.deletedSuccessMessageMultipleAlt'
        : 'common.deletedSuccessMessageMultiple'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessRemovedFromGroupMessage = ({
  object,
  name = '',
  success = null,
  multiple = false,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? !multiple
        ? altText
          ? 'common.removedFromGroupSuccessMessageAlt'
          : 'common.removedFromGroupSuccessMessage'
        : altText
        ? 'common.removedFromGroupSuccessMessageMultipleAlt'
        : 'common.removedFromGroupSuccessMessageMultiple'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessAddedToGroupMessage = ({
  object,
  name = '',
  success = null,
  multiple = false,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? !multiple
        ? altText
          ? 'common.addedToGroupSuccessMessageAlt'
          : 'common.addedToGroupSuccessMessage'
        : altText
        ? 'common.addedToGroupSuccessMessageMultipleAlt'
        : 'common.addedToGroupSuccessMessageMultiple'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessDisabledMessage = ({
  object,
  name = '',
  success = null,
  multiple = false,
  timer = 4000,
  altText = false
}) => {
  const messageId =
    object != null
      ? !multiple
        ? altText
          ? 'common.disabledSuccessMessageAlt'
          : 'common.disabledSuccessMessage'
        : altText
        ? 'common.disabledSuccessMessageMultipleAlt'
        : 'common.disabledSuccessMessageMultiple'
      : 'common.changesSuccess';
  const message =
    success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : success;
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessCancelNotificationMessage = ({ timer = 4000 }) => {
  const message = appIntl().formatMessage({
    id: 'common.cancelNotificationSuccessMessage'
  });
  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                  Error Alerts Created, Edited And Deleted                  */
/* -------------------------------------------------------------------------- */
export const showFailCreatedMessage = ({ object, name = '', error, timer = undefined }) => {
  const messageId = object != null ? 'common.createdFailMessage' : 'common.changesFail';
  const message =
    error == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : error.message;
  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showFailEditedMessage = ({ object, name = '', error, timer = undefined }) => {
  const messageId = object != null ? 'common.editedFailMessage' : 'common.changesFail';
  const message =
    error == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : error.message;
  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showFailDeletedMessage = ({
  object,
  name = '',
  error = { message: null },
  multiple = false,
  timer = undefined
}) => {
  const messageId =
    object != null
      ? !multiple
        ? 'common.deletedFailMessage'
        : 'common.deletedFailMessageMultiple'
      : 'common.changesFail';
  const message =
    error.message == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object, name }
        )
      : error.message;
  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: timer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                      Delete Confirmation Message Alert                     */
/* -------------------------------------------------------------------------- */
interface DeleteConfirmationMessageProps {
  action: any;
  objectType: any;
  objectName: any;
  isDisabled?: boolean;
  isUnassign?: boolean;
  deleteWarning?: any;
  width?: any;
}

export const showDeleteConfirmationMessage = ({
  action,
  objectType,
  objectName,
  isDisabled = false,
  isUnassign = false,
  width = '32rem',
  deleteWarning
}: DeleteConfirmationMessageProps) => {
  Swal.fire({
    icon: 'warning',
    width: width,
    title: appIntl().formatMessage(
      {
        id: isDisabled
          ? 'common.disableConfirmationMessage'
          : isUnassign
          ? 'common.unassignConfirmationMessage'
          : 'common.deleteConfirmationMessage'
      },
      { objectType, objectName }
    ),
    text:
      !isDisabled && !isUnassign
        ? deleteWarning
          ? deleteWarning
          : appIntl().formatMessage({ id: 'common.deleteWarning' })
        : '',
    showConfirmButton: true,

    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: appIntl().formatMessage({
      id: isDisabled
        ? 'common.confirmDisable'
        : isUnassign
        ? 'common.confirmUnassign'
        : 'common.confirmDelete'
    }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const showLeaveConfirmationMessage = ({ action, objectType, objectName }) => {
  Swal.fire({
    icon: 'warning',
    title: appIntl().formatMessage(
      {
        id: 'common.leaveConfirmationMessage'
      },
      { objectType, objectName }
    ),
    text: '',
    showConfirmButton: true,

    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: appIntl().formatMessage({
      id: 'common.confirmLeave'
    }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const showRemoveFromGroupConfirmationMessage = ({
  action,
  objectType,
  objectName,
  objectCount,
  isMultiple = false
}) => {
  Swal.fire({
    icon: 'warning',
    title: appIntl().formatMessage(
      {
        id: isMultiple
          ? 'common.removeFromGroupConfirmationMessageMultiple'
          : 'common.removeFromGroupConfirmationMessage'
      },
      { objectType, objectName, objectCount }
    ),
    text: '',
    showConfirmButton: true,

    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: appIntl().formatMessage({
      id: isMultiple ? 'common.confirmRemoveMultiple' : 'common.confirmRemove'
    }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

/* -------------------------------------------------------------------------- */
/*                      Delete Confirmation Message Alert                     */
/* -------------------------------------------------------------------------- */
interface DeleteMultiple {
  action?: any;
  objectType?: any;
  objectsQuantity?: number;
}

export const showDeleteConfirmationMessageMultiple = (props: DeleteMultiple) => {
  const { action, objectType, objectsQuantity } = props;
  const MySwal = withReactContent(Swal);
  MySwal.fire({
    icon: 'warning',
    title: appIntl().formatMessage(
      {
        id: 'common.deleteConfirmationMessageMultiple'
      },
      { objectType, objectsQuantity }
    ),
    showConfirmButton: true,

    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: appIntl().formatMessage({
      id: 'common.confirmDelete'
    }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

/* -------------------------------------------------------------------------- */
/*                                LOADING Alert                               */
/* -------------------------------------------------------------------------- */
export const showLoading = () => {
  Swal.fire({
    title: appIntl().formatMessage({ id: 'common.loading' }),
    customClass: {
      loader: 'custom-loader'
    },
    loaderHtml: '<div className="spinner-border text-primary" size="lg"></div>',
    didOpen: () => {
      Swal.showLoading();
    }
  });
};

export const closeLoading = () => {
  Swal.close();
};

/* -------------------------------------------------------------------------- */
/*                              INFORMATION Alert                             */
/* -------------------------------------------------------------------------- */
export const showInformationMessage = ({ message, timer = 4000 }) => {
  Swal.fire({
    timer: timer,
    icon: 'info',
    text: `${message}`,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
};
