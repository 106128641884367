// @ts-nocheck
import L from 'leaflet';
import 'leaflet.icon.glyph';
import { Fragment, useEffect, useState } from 'react';
import { Marker, Rectangle, Tooltip, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { Media } from 'reactstrap';
import useDeepCompareEffect from 'use-deep-compare-effect';
import Icon from 'utility/icomoon';
import { showUnitByZoomAndRadius } from 'views/screens/Locations/utils';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as locationActions from '../../../../screens/Locations/actions';
import * as locationSelectors from '../../../../screens/Locations/reducers';
import SlidingpanelLocationInformation from '../../../../screens/Locations/views/SlidingpanelLocationInformation';
import { getRectBoundsFromLatLng } from '../../utils';
import MarkerClusterGroup from '../MarkerClusterGroup';

export interface MapLocationsOptions {
  isSelectedSomething?: any;
  setIsSelectedSomething?: Function;
  setMapPositionToLocationBounds?: boolean;
  useSlidingpanelLocationInformation?: boolean;
  usePopovers?: boolean;
  locations?: any[];
  selectedLocationIds?: any[];
  showLocations?: boolean;
  useRectangleBounds?: boolean;
  clusterMarkers?: boolean;
  hideMarkersByZoom?: boolean;
  isMapFullScreen?: boolean;
  useIsShownLocation?: boolean; //param only when you have a property in each location isShownInMap to show locations on map
  initialShowSlidingPanelLocationInformationOpen?: boolean;
  initialLocationInformationOpenId?: any;
  editingLocationParent?: any;
  setEditingLocationParent?: Function;
  editingLocationParentRef?: any;
  selectedLocationInTable?: any;
  startClusteringAt?: number;
}

const MapLocations = (props: MapLocationsOptions) => {
  const map = useMapEvents({
    moveend: () => {
      displayMarkers();
    }
  });
  const {
    isSelectedSomething,
    setIsSelectedSomething,
    showLocations = false,
    useIsShownLocation = false,
    locations = [],
    selectedLocationIds,
    useRectangleBounds = true,
    setMapPositionToLocationBounds = false,
    useSlidingpanelLocationInformation = true,
    clusterMarkers = false,
    hideMarkersByZoom = true,
    usePopovers,
    isMapFullScreen = false,
    initialShowSlidingPanelLocationInformationOpen = false,
    initialLocationInformationOpenId,
    editingLocationParent,
    setEditingLocationParent,
    editingLocationParentRef,
    selectedLocationInTable,
    startClusteringAt = undefined
  } = props;
  const dispatch = useDispatch();
  const [showLocationInformationSlidepanel, setShowLocationInformationSlidepanel] = useState(false);
  const [initialLocationId, setInitialLocationId] = useState<any>();
  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const { store, user, isEditing, isAdding } = useSelector((state) => ({
    store: state,
    user: authSelectors.getAuthUser(state),
    isEditing: locationSelectors.isEditingLocation(state),
    isAdding: locationSelectors.isAddingLocation(state)
  }));

  useEffect(() => {
    if (setIsSelectedSomething) {
      setIsSelectedSomething({
        ...isSelectedSomething,
        locations: selectedLocation?.locationId != null
      });
    }
  }, [selectedLocation]);

  useEffect(() => {
    if (!isEditing && selectedLocation.locationId) {
      setSelectedLocation(locationSelectors.getLocation(store, selectedLocation.locationId));
      setShowLocationInformationSlidepanel(true);
    }
  }, [isEditing]);

  const zoomMapToLocation = (latLng) => {
    var ll;
    if (latLng instanceof L.LatLng) {
      ll = latLng;
    } else {
      ll = L.latLng(latLng);
    }
    map.flyToBounds([ll], {
      paddingBottomRight: [selectedLocationInTable ? 0 : 550, 0],
      paddingTopLeft: [0, 70],
      maxZoom: 15,
      animate: 15 !== map.getZoom()
    });
  };

  useDeepCompareEffect(() => {
    if (setMapPositionToLocationBounds && map && locations.length > 0) {
      if (locations.length == 1) {
        if (locations[0].latitude != null && locations[0].longitude != null) {
          var ll = L.latLng(locations[0].latitude, locations[0].longitude);
          map.flyToBounds(ll.toBounds(800), {});
        }
      } else {
        let bounds = L.latLngBounds(locations.map((loc) => [loc.latitude, loc.longitude]));
        map.fitBounds(bounds);
      }
    }
  }, [
    locations?.map((location) => ({
      latitude: location?.latitude,
      longitude: location?.longitude,
      isShownInMap: location?.isShownInMap
    })),
    setMapPositionToLocationBounds
  ]);

  useEffect(() => {
    if (selectedLocationInTable) {
      var ll = L.latLng(selectedLocationInTable.latitude, selectedLocationInTable.longitude);
      zoomMapToLocation(ll);
    }
  }, [selectedLocationInTable]);

  //Open and select location if params are set
  useEffect(() => {
    if (initialShowSlidingPanelLocationInformationOpen && useSlidingpanelLocationInformation) {
      if (initialLocationInformationOpenId) {
        const initialLocationInformation = locationSelectors.getLocation(
          store,
          initialLocationInformationOpenId
        );

        if (
          initialLocationId !== initialLocationInformationOpenId &&
          initialLocationInformation.latitude != null &&
          initialLocationInformation.longitude != null
        ) {
          setInitialLocationId(initialLocationInformation.locationId);
          setSelectedLocation(initialLocationInformation);
          var ll = L.latLng(
            initialLocationInformation.latitude,
            initialLocationInformation.longitude
          );
          zoomMapToLocation(ll);
        }
        setShowLocationInformationSlidepanel(true);
      }
    } else {
      setInitialLocationId(undefined);
      setSelectedLocation({});
      setShowLocationInformationSlidepanel(false);
    }
  }, [initialLocationInformationOpenId]);
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  // Check if you want to cluster markers
  let WrapperMarkerClusterGroup = Fragment;
  const wrapperProps = {};
  let icon = undefined;
  if (clusterMarkers) {
    WrapperMarkerClusterGroup = MarkerClusterGroup;
    icon = <Icon icon={'Marker1'} size={12} />;
    wrapperProps['disableClusteringAtZoom'] = startClusteringAt;
  }

  const [locationsBounds, setLocationsBounds] = useState([]);
  const displayMarkers = () => {
    setLocationsBounds(
      locations.filter((location) =>
        map
          ? location?.latitude != null &&
            location?.longitude != null &&
            map.getBounds().contains(L.latLng(location.latitude, location.longitude)) &&
            (!hideMarkersByZoom ||
              showUnitByZoomAndRadius(map.getZoom(), location?.radius || 50) ||
              selectedLocationIds?.includes(location?.locationId))
          : true
      )
    );
  };
  useDeepCompareEffect(() => {
    displayMarkers();
  }, [
    showLocations,
    isEditing,
    isAdding,
    locations?.map((location) => ({
      latitude: location.latitude,
      longitude: location?.longitude,
      isShownInMap: location?.isShownInMap
    }))
  ]);

  return (
    <>
      {(showLocations || useIsShownLocation) && (
        <WrapperMarkerClusterGroup {...(icon ? { icon } : undefined)} {...wrapperProps}>
          {locationsBounds.map(
            (location) =>
              location.latitude != null &&
              location.longitude != null &&
              ((showLocations && !useIsShownLocation) ||
                (useIsShownLocation && location.isShownInMap)) && (
                <div key={location.locationId}>
                  <Marker
                    // pmIgnore={true}
                    zIndexOffset={
                      selectedLocationIds &&
                      selectedLocationIds.length > 0 &&
                      selectedLocationIds.includes(location.locationId)
                        ? 850
                        : 800
                    }
                    riseOnHover={true}
                    icon={L.divIcon({
                      className: 'custom-div-icon',
                      html: `<div  class='marker-pin' style='background-color:${
                        selectedLocationIds?.length > 0 &&
                        !selectedLocationIds.includes(location.locationId)
                          ? 'rgba(128, 128, 128, 0.6)'
                          : location.locationClassDefaultColor == null
                          ? '$marker-color'
                          : location.locationClassDefaultColor
                      }'></div>${
                        location.locationClassContent ??
                        `<i class='icon-${
                          location.locationClassIconName ?? 'Marker1'
                        }' style='width: 20px;
                  font-size: 20px;color:white;'></i>`
                      }`,
                      iconSize: [30, 42],
                      iconAnchor: [15, 42],
                      popupAnchor: [0, -35]
                    })}
                    position={[location.latitude, location.longitude]}
                    eventHandlers={{
                      click: (e) => {
                        if (editingLocationParentRef?.current) {
                          dispatch(
                            locationActions.showLocationInMap(
                              editingLocationParentRef?.current.locationId
                            )
                          );
                          if (setEditingLocationParent) setEditingLocationParent(undefined);
                        }
                        setSelectedLocation(location);
                        if (useSlidingpanelLocationInformation) {
                          setShowLocationInformationSlidepanel(true);
                        }
                        zoomMapToLocation(e.latlng);
                      }
                    }}
                  >
                    {usePopovers && (
                      <Tooltip
                        permanent={user?.locationMapLabelAlwaysOn == 1}
                        direction={'top'}
                        offset={[0, -35]}
                      >
                        <b className="text-center" style={{ fontSize: '14px' }}>
                          {location.locationName}
                        </b>

                        {location.fullImageUrl && (
                          <>
                            <br /> <br />
                            <Media
                              src={location.fullImageUrl}
                              height="90"
                              width="90"
                              style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto'
                              }}
                            />
                          </>
                        )}
                      </Tooltip>
                    )}
                  </Marker>

                  {useRectangleBounds && (
                    <Rectangle
                      bounds={getRectBoundsFromLatLng(
                        location.latitude,
                        location.longitude,
                        location.radius ?? 10
                      )}
                      pathOptions={{ color: location.boundsCustomColor || primary }}
                    />
                  )}
                </div>
              )
          )}
        </WrapperMarkerClusterGroup>
      )}

      <SlidingpanelLocationInformation
        isLoading={isEditing}
        closeSlidepanel={() => {
          setShowLocationInformationSlidepanel(false);
          setSelectedLocation({});
        }}
        isSlidepanelOpen={showLocationInformationSlidepanel}
        title={selectedLocation.locationName}
        location={selectedLocation}
        editingLocationParent={editingLocationParent}
        editingLocationParentRef={editingLocationParentRef}
        setEditingLocationParent={setEditingLocationParent}
      />
    </>
  );
};

MapLocations.defaultProps = {
  setMapPositionToLocationBounds: false,
  useSlidingpanelLocationInformation: true,
  locations: [],
  showLocations: false,
  useRectangleBounds: true,
  clusterMarkers: false,
  hideMarkersByZoom: true,
  isMapFullScreen: false,
  initialShowSlidingPanelLocationInformationOpen: false,
  usePopovers: true,
  initialLocationInformationOpenId: undefined,
  useIsShownLocation: false
};

export default MapLocations;
