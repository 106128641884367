export const DIVISIONS_FETCH_STARTED = "DIVISIONS_FETCH_STARTED";
export const DIVISIONS_FETCH_COMPLETED = "DIVISIONS_FETCH_COMPLETED";
export const DIVISIONS_FETCH_FAILED = "DIVISIONS_FETCH_FAILED";

export const DIVISIONS_PARTIAL_FETCH_STARTED =
  "DIVISIONS_PARTIAL_FETCH_STARTED";
export const DIVISIONS_PARTIAL_FETCH_COMPLETED =
  "DIVISIONS_PARTIAL_FETCH_COMPLETED";
export const DIVISIONS_PARTIAL_FETCH_FAILED = "DIVISIONS_PARTIAL_FETCH_FAILED";

export const DIVISION_ADD_STARTED = "DIVISION_ADD_STARTED";
export const DIVISION_ADD_COMPLETED = "DIVISION_ADD_COMPLETED";
export const DIVISION_ADD_FAILED = "DIVISION_ADD_FAILED";

export const DIVISION_EDIT_STARTED = "DIVISION_EDIT_STARTED";
export const DIVISION_EDIT_COMPLETED = "DIVISION_EDIT_COMPLETED";
export const DIVISION_EDIT_FAILED = "DIVISION_EDIT_FAILED";

export const DIVISION_REMOVE_STARTED = "DIVISION_REMOVE_STARTED";
export const DIVISION_REMOVE_COMPLETED = "DIVISION_REMOVE_COMPLETED";
export const DIVISION_REMOVE_FAILED = "DIVISION_REMOVE_FAILED";

export const DIVISION_SELECTED = "DIVISION_SELECTED";
export const DIVISIONS_ALL_SELECTED = "DIVISIONS_ALL_SELECTED";
export const DIVISION_DESELECTED = "DIVISION_DESELECTED";
export const DIVISIONS_ALL_DESELECTED = "DIVISIONS_ALL_DESELECTED";
