import camelcaseKeys from 'camelcase-keys';
import { useEffect, useState } from 'react';
import { getUnitTemperatureHistory } from '../services';

const useGetUnitTemperatureHistory = (
  fetch: boolean,
  unitId: number | undefined,
  sensorId: number | undefined,
  measureId: number | undefined,
  startDate: Date | string | undefined,
  endDate: Date | string | undefined
): {
  data: any;
  isFetching: boolean;
} => {
  const [data, setData] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (fetch && unitId && sensorId && startDate && endDate) {
      setIsFetching(true);
      getUnitTemperatureHistory(
        { unitId, sensorId, measureId, startDate, endDate },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response?.success) {
              setData(response.data.temperatureHistory.map((result) => camelcaseKeys(result)));
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [fetch, unitId, sensorId, measureId, startDate, endDate]);
  return { data, isFetching };
};

export default useGetUnitTemperatureHistory;
