/* -------------------------------------------------------------------------- */
/*                        Schema Organization Contracts                       */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const organizationContract = new schema.Entity(
  'organizationContracts',
  {},
  { idAttribute: 'contractId' }
);
export const organizationContracts = new schema.Array(organizationContract);
