/* -------------------------------------------------------------------------- */
/*                       Schema Execution Workplans                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const workplanExecutionLocation = new schema.Entity(
  'workplanExecutionLocations',
  {},
  { idAttribute: (value) => `${value.visitId}-${value.locationId}` }
);
export const workplanExecutionLocations = new schema.Array(workplanExecutionLocation);
