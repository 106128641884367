import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/catalogs/categories';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/categories';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/catalogs/categories';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteCategory,
  getCategoryList,
  saveCategory
} from 'views/screens/vehicleMaintenance/services/categoriesService';
import { PayloadAction } from '@reduxjs/toolkit';
import { DeleteCategoryDTO, SaveCategoryDTO } from 'views/screens/vehicleMaintenance/types';
/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchCategoriesList(action) {
  try {
    const jsonResult = yield call(getCategoryList);
    const resultData = jsonResult.map((result) => camelcaseKeys(result));
    const {
      entities: { categories },
      result //order
    } = normalize(resultData, schemas.categories); //normalize data to byId and order
    yield put(actions.completeFetchingCategories(categories, result));
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingCategories(error));
  }
}

export function* watchFetchCategoriesList() {
  yield takeEvery(types.CATEGORIES_FETCH_STARTED, fetchCategoriesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchCategoriesList(action) {}

export function* watchPartialFetchCategoriesList() {
  yield takeEvery(types.CATEGORIES_PARTIAL_FETCH_STARTED, partialFetchCategoriesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addCategory(action: PayloadAction<SaveCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingCategory(addedData.categoryId, request.tempId, {
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failAddingCategory(request.tempId, e));
  }
}

export function* watchAddCategoriesStarted() {
  yield takeEvery(types.CATEGORY_ADD_STARTED, addCategory);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editCategory(action: PayloadAction<SaveCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingCategory({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failEditingCategory(request.categoryId, e));
  }
}

export function* watchEditCategoriesStarted() {
  yield takeEvery(types.CATEGORY_EDIT_STARTED, editCategory);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeCategory(action: PayloadAction<DeleteCategoryDTO>) {
  const request = action.payload;
  try {
    yield call(deleteCategory, request);
    yield put(actions.completeRemovingCategory(request.categoryId));
  } catch (e) {
    yield put(actions.failRemovingCategory(request.categoryId, e));
  }
}

export function* watchRemoveCategoryStarted() {
  yield takeEvery(types.CATEGORY_REMOVE_STARTED, removeCategory);
}
