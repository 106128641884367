import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import * as scheduleSelectors from '../reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { useIntl } from 'react-intl';
import * as utility from '../../../../utility/Utils';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { addSchedulesDescription } from '../utils';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchSchedulesList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/schedule/get`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { schedules },
          result //order
        } = normalize(resultData, schemas.schedules); //normalize data to byId and order
        yield put(actions.completeFetchingSchedules(schedules, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingSchedules(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingSchedules(error));
  }
}

export function* watchFetchSchedulesList() {
  yield takeEvery(types.SCHEDULES_FETCH_STARTED, fetchSchedulesList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchSchedulesList(action) {
  try {
    const entityTypeId = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/schedule/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { schedules },
          result //order
        } = normalize(resultData, schemas.schedules); //normalize data to byId and order
        yield put(actions.completePartialFetchingSchedules(schedules, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingSchedules(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingSchedules(error));
  }
}

export function* watchPartialFetchSchedulesList() {
  yield takeEvery(types.SCHEDULES_PARTIAL_FETCH_STARTED, partialFetchSchedulesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addSchedule(action) {
  const schedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/schedule/saveTrigger`, {
        method: 'POST',
        body: JSON.stringify({ ...schedule, scheduleId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedSchedule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingSchedule(schedule.scheduleId, addedSchedule));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'schedules.schedule'
          }),
          name: schedule.scheduleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingSchedule(schedule.scheduleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingSchedule(schedule.scheduleId, error));
  }
}

export function* watchAddSchedulesStarted() {
  yield takeEvery(types.SCHEDULE_ADD_STARTED, addSchedule);
}

export function* completeAddingSchedule({ payload }) {
  const { oldId, schedule } = payload;
  yield put(actions.startFetchingSchedules());
}

export function* watchAddSchedulesCompleted() {
  yield takeEvery(types.SCHEDULE_ADD_COMPLETED, addSchedule);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editSchedule(action) {
  const schedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/schedule/saveTrigger`, {
        method: 'POST',
        body: JSON.stringify(schedule),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedSchedule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingSchedule(editedSchedule));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'schedules.schedule'
          }),
          name: editedSchedule.scheduleName
        });
        yield put(actions.startFetchingSchedules());
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldSchedule } = yield select((state) =>
          selectors.getSchedule(state.schedules, schedule.scheduleId)
        );
        yield put(actions.failEditingSchedule(oldSchedule, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldSchedule } = yield select((state) =>
      selectors.getSchedule(state.schedules, schedule.scheduleId)
    );
    yield put(actions.failEditingSchedule(oldSchedule, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditSchedulesStarted() {
  yield takeEvery(types.SCHEDULE_EDIT_STARTED, editSchedule);
}

// added in order to resolve problem of not refreshing the schedule list after editing a schedule
function* completeEditSchedule(action) {
  const schedule = action.payload;
  yield put(actions.startFetchingSchedules());
}

export function* watchCompleteEditSchedule() {
  yield takeEvery(types.SCHEDULE_EDIT_COMPLETED, completeEditSchedule);
}

/* -------------------------------------------------------------------------- */
/*                                   ADD SHIFT                                */
/* -------------------------------------------------------------------------- */
function* addShift(action) {
  const schedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/schedule/saveShift`, {
        method: 'POST',
        body: JSON.stringify({ ...schedule, scheduleId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedSchedule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingSchedule(schedule.scheduleId, addedSchedule));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'schedules.schedule'
          }),
          name: schedule.scheduleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingSchedule(schedule.scheduleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingSchedule(schedule.scheduleId, error));
  }
}

export function* watchAddShiftStarted() {
  yield takeEvery(types.SCHEDULE_SHIFT_ADD_STARTED, addShift);
}

/* -------------------------------------------------------------------------- */
/*                                EDIT SHIFT                                  */
/* -------------------------------------------------------------------------- */
function* editScheduleShift(action) {
  const schedule = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/schedule/saveShift`, {
        method: 'POST',
        body: JSON.stringify(schedule),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedSchedule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingShift(editedSchedule));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'schedules.schedule'
          }),
          name: editedSchedule.scheduleName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldSchedule } = yield select((state) =>
          selectors.getSchedule(state.schedules, schedule.scheduleId)
        );
        yield put(actions.failEditingShift(oldSchedule, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    const { oldSchedule } = yield select((state) =>
      selectors.getSchedule(state.schedules, schedule.scheduleId)
    );
    yield put(actions.failEditingShift(oldSchedule, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditSchedulesShiftStarted() {
  yield takeEvery(types.SCHEDULE_SHIFT_EDIT_STARTED, editScheduleShift);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeSchedule(action) {
  const { scheduleId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/schedule/delete`, {
        method: 'POST',
        body: JSON.stringify({ scheduleId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const scheduleDeleted = yield select(scheduleSelectors.getSchedule, scheduleId);
        yield put(actions.completeRemovingSchedule(scheduleId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'schedules.schedule'
          }),
          name: scheduleDeleted?.scheduleName
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'schedules.scheduleDeleteMessageError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingSchedule(scheduleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'schedules.scheduleDeleteMessageError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingSchedule(scheduleId, error));
  }
}

export function* watchRemoveScheduleStarted() {
  yield takeEvery(types.SCHEDULE_REMOVE_STARTED, removeSchedule);
}
