import { combineReducers } from 'redux';

import union from 'lodash/union';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansExecutions';
import * as geofenceSelectors from '../../../Geofences/reducers';
import * as unitSelectors from '../../../Units/reducers';
import locations, * as workplansExecutionsLocationsSelectors from '../workplansExecutionLocations';
import permissions from '../../permissions';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED: {
      const { workplanExecution } = action.payload;
      const newState = { ...state };

      newState[workplanExecution.visitId] = {
        isSelected: false,
        ...state[workplanExecution.visitId],
        ...workplanExecution,
        isConfirmed: true
      };
      return newState;
    }
    case types.WORKPLANS_EXECUTION_CANCEL_COMPLETED: {
      const { visitId } = action.payload;
      return omit(state, visitId);
    }
    case types.WORKPLANS_EXECUTION_FINALIZE_COMPLETED: {
      const { visitId } = action.payload;
      const newState = { ...state };
      newState[visitId] = {
        isSelected: false,
        ...state[visitId],
        executionStatus: 5,
        isConfirmed: true
      };
      return newState;
    }
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED: {
      const { visitId, unitId } = action.payload;
      const newState = { ...state };
      newState[visitId] = {
        isSelected: false,
        ...state[visitId],
        unitId,
        isConfirmed: true
      };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED: {
      const { workplanExecution } = action.payload;
      return uniq([...state, workplanExecution.visitId]);
    }
    case types.WORKPLANS_EXECUTION_CANCEL_COMPLETED: {
      const { visitId } = action.payload;
      return state.filter((id) => id !== visitId);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const indicators = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      const { indicators } = action.payload;
      const newState = { ...indicators };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const executionStatus = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      const { executionStatus } = action.payload;
      const newState = { ...executionStatus };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const geographicStatus = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      const { geographicStatus } = action.payload;
      const newState = { ...geographicStatus };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingExecutions = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_EXECUTIONS_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED:
    case types.WORKPLANS_EXECUTIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_EXECUTION_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED:
    case types.WORKPLAN_EXECUTION_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCancelingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_EXECUTION_CANCEL_STARTED: {
      return true;
    }
    case types.WORKPLANS_EXECUTION_CANCEL_COMPLETED:
    case types.WORKPLANS_EXECUTION_CANCEL_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFinalizingExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_EXECUTION_FINALIZE_STARTED: {
      return true;
    }
    case types.WORKPLANS_EXECUTION_FINALIZE_COMPLETED:
    case types.WORKPLANS_EXECUTION_FINALIZE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isManualTransitExecution = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_STARTED: {
      return true;
    }
    case types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_COMPLETED:
    case types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isChangingExecutionUnit = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED: {
      return true;
    }
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED:
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingExecutions = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_EXECUTIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_EXECUTIONS_FETCH_STARTED:
    case types.WORKPLANS_EXECUTIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_EXECUTION_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_EXECUTION_FETCH_STARTED:
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCancelingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_EXECUTION_CANCEL_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_EXECUTION_CANCEL_STARTED:
    case types.WORKPLANS_EXECUTION_CANCEL_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFinalizingExecution = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_EXECUTION_FINALIZE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_EXECUTION_FINALIZE_STARTED:
    case types.WORKPLANS_EXECUTION_FINALIZE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorChangingExecutionUnit = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED:
    case types.WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const workplansExecutions = combineReducers({
  byId,
  order,
  indicators,
  executionStatus,
  geographicStatus,
  locations,
  isFetchingExecutions,
  isFetchingExecution,
  isCancelingExecution,
  isFinalizingExecution,
  isManualTransitExecution,
  isChangingExecutionUnit,
  errorFetchingExecutions,
  errorFetchingExecution,
  errorCancelingExecution,
  errorFinalizingExecution,
  errorChangingExecutionUnit
});

export default workplansExecutions;

//Own state
export const getOwnState = (state) => state.workplans.workplansExecutions;

//Information
export const getWorkplansExecution = (state, visitId) => {
  const intl = appIntl();
  const workplanExecution = getOwnState(state).byId[visitId];
  if (workplanExecution) {
    const workplansExecutionsLocations =
      workplansExecutionsLocationsSelectors.getLocationsListByVisit(
        state,
        workplanExecution.visitId
      );
    const workplansExecutionsGeofences = [
      ...geofenceSelectors
        .getGeofencesList(state)
        .filter((geofence) =>
          [
            workplanExecution.originGeofenceId,
            workplanExecution.workGeofenceId,
            workplanExecution.destinationGeofenceId
          ].includes(geofence.geofenceId)
        ),
      workplanExecution?.workGeofenceArea
        ? {
            geofenceId: 'autogeneratedGeofence',
            shape: 'P',
            geofenceName: intl.formatMessage({ id: 'workplans.autogeneratedGeofence' }),
            geofenceWktString: workplanExecution?.workGeofenceArea
          }
        : undefined
    ].filter((geofence) => geofence);
    const originGeofence =
      workplansExecutionsGeofences.filter(
        (geofence) => geofence.geofenceId === workplanExecution?.originGeofenceId
      )[0] ?? undefined;
    const workGeofence =
      workplansExecutionsGeofences.filter((geofence) =>
        [workplanExecution?.workGeofenceId, 'autogeneratedGeofence'].includes(geofence.geofenceId)
      )[0] ?? undefined;
    const destinationGeofence =
      workplansExecutionsGeofences.filter(
        (geofence) => geofence.geofenceId === workplanExecution?.destinationGeofenceId
      )[0] ?? undefined;

    var executionStatusLabel = '';
    var executionStatusLabelInfo = '';
    switch (workplanExecution.executionStatus) {
      case 0:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.programmed'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.programmedInfo'
        });
        break;
      case 1:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.onTransit'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.onTransitInfo'
        });
        break;
      case 2:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.onOrigin'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.onOriginInfo'
        });
        break;
      case 3:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.onDestination'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.onDestinationInfo'
        });
        break;
      case 4:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.onLocation'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.onLocationInfo'
        });
        break;
      case 5:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.complete'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.completeInfo'
        });
        break;
      case 7:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.dht'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.dhtInfo'
        });
        break;
      case 8:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.ddt'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.ddtInfo'
        });
        break;
      case 9:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.fat'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.fatInfo'
        });
        break;
      case 10:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.dhd'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.dhdInfo'
        });
        break;
      case 11:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.eao'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.eaoInfo'
        });
        break;
      case 12:
        executionStatusLabel = intl.formatMessage({
          id: 'workplans.incomplete'
        });
        executionStatusLabelInfo = intl.formatMessage({
          id: 'workplans.incompleteInfo'
        });
        break;
      default:
        executionStatusLabel = '';
        executionStatusLabelInfo = '';
        break;
    }
    return {
      ...workplanExecution,
      progress: (workplanExecution.visited / (workplanExecution.total || 1)) * 100,
      progressLabel:
        Math.round((workplanExecution.visited / (workplanExecution.total || 1)) * 100) + '%',
      pending: workplanExecution.total - workplanExecution.visited,
      sequenceCompliance:
        parseInt(workplanExecution.sequenceCountValid || 0) +
        ' | ' +
        parseInt(workplanExecution.sequenceCountInvalid || 0) +
        ' | ' +
        parseInt(workplanExecution.sequenceCountPending || 0),
      executionStatusLabel,
      executionStatusLabelInfo,
      originGeofenceName: originGeofence?.geofenceName ?? '',
      workGeofenceId: workGeofence?.geofenceId ?? null,
      workGeofenceName: workGeofence?.geofenceName ?? '',
      destinationGeofenceName: destinationGeofence?.geofenceName ?? '',
      locations: workplansExecutionsLocations,
      geofences: workplansExecutionsGeofences
    };
  } else return undefined;
};
export const getWorkplansExecutionsList = (state) =>
  getOwnState(state).order.map((id) => getWorkplansExecution(state, id));
export const getIndicators = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;

  const total =
    indicators.totalComplete + indicators.totalIncomplete > 0
      ? indicators.totalComplete + indicators.totalIncomplete
      : 1;
  const finalIndicators = {
    ...indicators,
    executionPercent: (indicators.totalComplete / total) * 100
  };
  return [
    {
      title: intl.formatMessage({
        id: 'workplans.plans'
      }),
      permissions: [permissions.viewPlansIndicator],
      bigIndicator: finalIndicators.totalAssignedPlans || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.scheduledWorkplans'
            }) +
            ': ' +
            parseInt(finalIndicators.totalScheduledPlans || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.activeWorkplans'
            }) +
            ': ' +
            parseInt(finalIndicators.totalActivePlans || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.completeWorkplans'
            }) +
            ': ' +
            parseInt(finalIndicators.totalCompletePlans || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.incompleteWorkplans'
            }) +
            ': ' +
            parseInt(finalIndicators.totalIncompletePlans || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.units'
            }) +
            ': ' +
            parseInt(finalIndicators.totalUnits || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.locations'
      }),
      permissions: [permissions.viewLocationsIndicator],
      bigIndicator: finalIndicators.totalLocations || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.pending'
            }) +
            ': ' +
            parseInt(finalIndicators.totalIncomplete || 0)
        },
        {
          popoverTitle: intl.formatMessage({
            id: 'workplans.sequenceCompliance'
          }),
          popoverBody: intl.formatMessage({
            id: 'workplans.complianceYesNotPend'
          }),
          info:
            intl.formatMessage({
              id: 'workplans.sequence'
            }) +
            ': ' +
            parseInt(finalIndicators.sequenceCountValid || 0) +
            ' | ' +
            parseInt(finalIndicators.sequenceCountInvalid || 0) +
            ' | ' +
            parseInt(finalIndicators.sequenceCountPending || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.withoutSequence'
            }) +
              ': ' +
              (finalIndicators.totalLocations -
                (finalIndicators.sequenceCountValid +
                  finalIndicators.sequenceCountInvalid +
                  finalIndicators.sequenceCountPending)) || 0
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.confirmed'
            }) +
            ': ' +
            parseInt(finalIndicators.totalConfirmed || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.visits'
      }),
      permissions: [permissions.viewVisitsIndicator],
      bigIndicator: finalIndicators.totalVisited || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.onTime'
            }) +
            ': ' +
            parseInt(finalIndicators.totalNormal || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.early'
            }) +
            ': ' +
            parseInt(finalIndicators.totalEarly || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.late'
            }) +
            ': ' +
            parseInt(finalIndicators.totalLate || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.repeated'
            }) +
            ': ' +
            parseInt(finalIndicators.totalRepeated || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.visitsPercent'
      }),
      permissions: [permissions.viewExecutionIndicator],
      bigIndicator: Math.round(finalIndicators.executionPercent || 0) + '%'
    }
  ];
};
export const getDurationStatus = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  var labels: any[] = [];
  var series: any[] = [];
  if (
    indicators.totalNormalDuration > 0 ||
    indicators.totalShortDuration > 0 ||
    indicators.totalLongDuration > 0
  ) {
    labels = [
      intl.formatMessage({
        id: 'workplans.normal'
      }),
      intl.formatMessage({
        id: 'workplans.toShortPermanence'
      }),
      intl.formatMessage({
        id: 'workplans.toLongPermanence'
      })
    ];
    series = [
      indicators.totalNormalDuration,
      indicators.totalShortDuration,
      indicators.totalLongDuration
    ];
  }
  return {
    title: intl.formatMessage({
      id: 'workplans.effectivenessInPermanence'
    }),
    permissions: [permissions.viewPermanenceEffectivenessGraph],
    labels: labels,
    series: series,
    duration: ['N', 'C', 'L']
  };
};
export const getExecutionStatus = (state) => {
  const intl = appIntl();
  const executionStatus = getOwnState(state).executionStatus;
  var labels: any[] = [];
  var series: any[] = [];
  if (
    executionStatus.complete > 0 ||
    executionStatus.incomplete > 0 ||
    executionStatus.inAction > 0 ||
    executionStatus.scheduled > 0
  ) {
    labels = [
      intl.formatMessage({
        id: 'workplans.complete'
      }),
      intl.formatMessage({
        id: 'workplans.incomplete'
      }),
      intl.formatMessage({
        id: 'workplans.inAction'
      }),
      intl.formatMessage({
        id: 'workplans.scheduledWorkplans'
      })
    ];
    series = [
      executionStatus.complete,
      executionStatus.incomplete,
      executionStatus.inAction,
      executionStatus.scheduled
    ];
  }
  return {
    title: intl.formatMessage({
      id: 'workplans.executionStatus'
    }),
    labels: labels,
    values: ['T', 'I', 'A', 'P'],
    series: series
  };
};
export const getGeographicStatus = (state) => {
  const intl = appIntl();
  const geographicStatus = getOwnState(state).geographicStatus;
  const colorsGeographicStatus = [
    '#490002',
    '#44ABFF',
    '#FFC737',
    '#005BE0',
    '#7669E8',
    '#FF4459',
    '#1F8836',
    '#00D57E',
    '#FF7B7B'
  ];
  return {
    title: intl.formatMessage({
      id: 'workplans.geographicStatus'
    }),
    labels: Object.keys(geographicStatus)
      .filter((label) => geographicStatus[label] > 0)
      .map((label) =>
        intl.formatMessage({
          id: 'workplans.' + label
        })
      ),
    values: Object.keys(geographicStatus)
      .filter((label) => geographicStatus[label] > 0)
      .map((label) => 'workplans.' + label),
    colors: Object.keys(geographicStatus)
      .map((label, i) => {
        if (geographicStatus[label] > 0) return colorsGeographicStatus[i];
      })
      .filter((color) => color),
    series: Object.values(geographicStatus).filter((value: any) => value > 0)
  };
};
//Status of sagas
export const isFetchingWorkplansExecutions = (state) => getOwnState(state).isFetchingExecutions;
export const isFetchingWorkplanExecution = (state) => getOwnState(state).isFetchingExecution;
export const isCancelingWorkplansExecution = (state) => getOwnState(state).isCancelingExecution;
export const isFinalizingWorkplansExecution = (state) => getOwnState(state).isFinalizingExecution;
export const isManualTransitWorkplansExecution = (state) =>
  getOwnState(state).isManualTransitExecution;
export const isChangingWorkplansExecutionUnit = (state) =>
  getOwnState(state).isChangingExecutionUnit;

//Errors
export const getFetchingWorkplansExecutionsErrors = (state) =>
  getOwnState(state).errorFetchingExecutions;
export const getFetchingWorkplanExecutionErrors = (state) =>
  getOwnState(state).errorFetchingExecution;
export const getCancelingWorkplansExecutionErrors = (state) =>
  getOwnState(state).errorCancelingExecution;
export const getFinalizingWorkplansExecutionErrors = (state) =>
  getOwnState(state).errorFinalizingExecution;
export const getChangingWorkplansExecutionUnitErrors = (state) =>
  getOwnState(state).errorChangingExecutionUnit;
