import * as types from '../types';

export const startFetchingOrganizationContracts = (filters = {}) => ({
  type: types.ORGANIZATION_CONTRACTS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingOrganizationContracts = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.ORGANIZATION_CONTRACTS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingOrganizationContracts = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ORGANIZATION_CONTRACTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingOrganizationContracts = (
  entityTypeId,
  operatorId = null,
  organizationId = null,
  allowNullOrganizationId
) => ({
  type: types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId, allowNullOrganizationId }
});
export const completePartialFetchingOrganizationContracts = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingOrganizationContracts = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingOrganizationContract = (organizationContract) => ({
  type: types.ORGANIZATION_CONTRACT_ADD_STARTED,
  payload: organizationContract
});
export const completeAddingOrganizationContract = (oldId, organizationContract) => ({
  type: types.ORGANIZATION_CONTRACT_ADD_COMPLETED,
  payload: { oldId, organizationContract }
});
export const failAddingOrganizationContract = (oldId, error) => ({
  type: types.ORGANIZATION_CONTRACT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingOrganizationContract = (organizationContract) => ({
  type: types.ORGANIZATION_CONTRACT_EDIT_STARTED,
  payload: organizationContract
});
export const completeEditingOrganizationContract = (organizationContract) => ({
  type: types.ORGANIZATION_CONTRACT_EDIT_COMPLETED,
  payload: organizationContract
});
export const failEditingOrganizationContract = (oldOrganizationContract, error) => ({
  type: types.ORGANIZATION_CONTRACT_EDIT_FAILED,
  payload: {
    oldOrganizationContract,
    error
  }
});

export const startRemovingOrganizationContract = (contractId) => ({
  type: types.ORGANIZATION_CONTRACT_REMOVE_STARTED,
  payload: {
    contractId
  }
});
export const completeRemovingOrganizationContract = (contractId) => ({
  type: types.ORGANIZATION_CONTRACT_REMOVE_COMPLETED,
  payload: {
    contractId
  }
});
export const failRemovingOrganizationContract = (contractId, error) => ({
  type: types.ORGANIZATION_CONTRACT_REMOVE_FAILED,
  payload: {
    contractId,
    error
  }
});

export const selectOrganizationContract = (contractId) => ({
  type: types.ORGANIZATION_CONTRACT_SELECTED,
  payload: contractId
});

export const selectAllOrganizationContracts = (
  contractIds = [] //List of ContractIds
) => ({
  type: types.ORGANIZATION_CONTRACTS_ALL_SELECTED,
  payload: contractIds
});

export const deselectOrganizationContract = (contractId) => ({
  type: types.ORGANIZATION_CONTRACT_DESELECTED,
  payload: contractId
});

export const deselectAllOrganizationContracts = (
  contractIds = [] //List of DivisionIds
) => ({
  type: types.ORGANIZATION_CONTRACTS_ALL_DESELECTED,
  payload: contractIds
});
