import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchServiceClassesList(action) {
  const { filters, isDropdown, isWebPage } = action.payload;
  let urlGet = `${API_BASE_URL}/serviceClass`;
  if (isDropdown) {
    urlGet = `${API_BASE_URL}/serviceClass/allStatus`;
  }
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, urlGet, {
        method: 'POST',
        body: JSON.stringify({ operatorId: isWebPage ? filters?.operatorId : null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { serviceClasses },
          result //order
        } = normalize(resultData, schemas.serviceClasses); //normalize data to byId and order
        yield put(actions.completeFetchingServiceClasses(serviceClasses, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingServiceClasses(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingServiceClasses(error));
  }
}

export function* watchFetchServiceClassesList() {
  yield takeEvery(types.SERVICECLASSES_FETCH_STARTED, fetchServiceClassesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addServiceClass(action) {
  const serviceClass = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/serviceClass/save`, {
        method: 'POST',
        body: JSON.stringify({ ...serviceClass, serviceClassId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedServiceClass = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeAddingServiceClass(serviceClass.serviceClassId, addedServiceClass)
        );

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'serviceClasses.serviceClass'
          }),
          name: addedServiceClass.serviceClassName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingServiceClass(serviceClass.serviceClassId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingServiceClass(serviceClass.serviceClassId, error));
  }
}

export function* watchAddServiceClassesStarted() {
  yield takeEvery(types.SERVICECLASS_ADD_STARTED, addServiceClass);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editServiceClass(action) {
  const serviceClass = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/serviceClass/save`, {
        method: 'POST',
        body: JSON.stringify(serviceClass),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedServiceClass = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingServiceClass(editedServiceClass));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'serviceClasses.serviceClass'
          }),
          name: editedServiceClass.serviceClassName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const oldServiceClass = yield select((state) =>
          selectors.getServiceClass(state, serviceClass.serviceClassId)
        );
        yield put(actions.failEditingServiceClass(oldServiceClass, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const oldServiceClass = yield select((state) =>
      selectors.getServiceClass(state, serviceClass.serviceClassId)
    );
    yield put(actions.failEditingServiceClass(oldServiceClass, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditServiceClassesStarted() {
  yield takeEvery(types.SERVICECLASS_EDIT_STARTED, editServiceClass);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeServiceClass(action) {
  const { serviceClassId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedServiceClass = yield select((state) =>
      selectors.getServiceClass(state, serviceClassId)
    );
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/serviceClass/delete`, {
        method: 'POST',
        body: JSON.stringify({ serviceClassId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingServiceClass(serviceClassId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'serviceClasses.serviceClass'
          }),
          name: deletedServiceClass.serviceClassName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingServiceClass(serviceClassId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingServiceClass(serviceClassId, error));
  }
}

export function* watchRemoveServiceClassStarted() {
  yield takeEvery(types.SERVICECLASS_REMOVE_STARTED, removeServiceClass);
}

/* -------------------------------------------------------------------------- */
/*                             FETCH SERVICE CLASS                            */
/* -------------------------------------------------------------------------- */
function* fetchServiceClass(action) {
  const serviceClassId = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/serviceClass/${serviceClassId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const serviceClass = camelcaseKeys(jsonResult.data);
        yield put(actions.completeFetchingServiceClass(serviceClass));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };

        yield put(actions.failFetchingServiceClass(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    yield put(actions.failFetchingServiceClass(error));
  }
}

export function* watchServiceClassFetchStarted() {
  yield takeEvery(types.SERVICECLASS_FETCH_STARTED, fetchServiceClass);
}
