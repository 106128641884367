/* -------------------------------------------------------------------------- */
/*                          Schema Workplan Assignment                        */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const assignmentBySchedule = new schema.Entity(
  'assignmentsBySchedule',
  {},
  { idAttribute: 'assignmentId' }
);
export const assignmentsBySchedule = new schema.Array(assignmentBySchedule);

export const assignmentByDateTime = new schema.Entity(
  'assignmentsByDateTime',
  {},
  { idAttribute: 'assignmentId' }
);
export const assignmentsByDateTime = new schema.Array(assignmentByDateTime);
