import { combineReducers } from 'redux';

import { union, omit } from 'lodash';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansExecutions';
import * as typesLocations from '../../types/workplansExecutionLocations';
import * as locationSelectors from '../../../Locations/reducers';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED: {
      const { workplanExecutionLocationsEntities, workplanExecutionLocationsOrder } =
        action.payload;
      const newState = { ...state };
      workplanExecutionLocationsOrder.forEach((workplanExecutionLocationId) => {
        newState[workplanExecutionLocationId] = {
          isSelected: false,
          workplanExecutionLocationId,
          ...state[workplanExecutionLocationId],
          ...workplanExecutionLocationsEntities[workplanExecutionLocationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED: {
      const { oldId, workplanExecutionLocation } = action.payload;
      const newState = omit(state, oldId);
      newState[workplanExecutionLocation.workplanExecutionLocationId] = {
        ...state[oldId],
        ...workplanExecutionLocation,
        isConfirmed: true
      };
      return newState;
    }

    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED: {
      const workplanExecutionLocation = action.payload;
      return {
        ...state,
        [workplanExecutionLocation.workplanExecutionLocationId]: {
          ...omit(state[workplanExecutionLocation.workplanExecutionLocationId], [
            'oldWorkplanExecutionLocation'
          ]),
          ...omit(workplanExecutionLocation, ['oldWorkplanExecutionLocation']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED: {
      const workplanExecutionLocation = action.payload;
      const newState = { ...state };
      newState[workplanExecutionLocation.workplanExecutionLocationId] = {
        isSelected: false,
        ...workplanExecutionLocation,
        isConfirmed: false
      };
      return newState;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED: {
      const workplanExecutionLocation = action.payload;
      return {
        ...state,
        [workplanExecutionLocation.workplanExecutionLocationId]: {
          ...state[workplanExecutionLocation.workplanExecutionLocationId],
          oldWorkplanExecutionLocation:
            state[workplanExecutionLocation.workplanExecutionLocationId],
          ...workplanExecutionLocation,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED: {
      const { oldWorkplanExecutionLocation } = action.payload;
      return {
        ...state,
        [oldWorkplanExecutionLocation.workplanId]: {
          ...omit(state[oldWorkplanExecutionLocation.workplanId], ['oldWorkplanExecutionLocation']),
          ...oldWorkplanExecutionLocation,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED: {
      const { workplanExecutionLocationId } = action.payload;
      return omit(state, workplanExecutionLocationId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLAN_EXECUTION_FETCH_COMPLETED: {
      const { workplanExecutionLocationsOrder } = action.payload;
      return union(workplanExecutionLocationsOrder);
    }

    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED: {
      const { oldId, workplanExecutionLocation } = action.payload;
      return state.map((workplanExecutionLocationId) =>
        workplanExecutionLocationId === oldId
          ? workplanExecutionLocation.workplanExecutionLocationId
          : workplanExecutionLocationId
      );
    }

    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED: {
      const { workplanExecutionLocationId } = action.payload;
      return state.filter(
        (workplanExecutionLocationIdState) =>
          workplanExecutionLocationIdState !== workplanExecutionLocationId
      );
    }

    //CASE ADD STARTED
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED: {
      const workplanExecutionLocation = action.payload;
      return [...state, workplanExecutionLocation.workplanExecutionLocationId];
    }

    //CASE ADD FAILED
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter(
        (workplanExecutionLocationIdState) => workplanExecutionLocationIdState !== oldId
      );
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED: {
      return true;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED: {
      return true;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED: {
      return true;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED:
    case typesLocations.WORKPLANS_EXECUTION_LOCATION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const locations = combineReducers({
  byId,
  order,
  isAdding,
  isEditing,
  isRemoving,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default locations;

//Own state
export const getOwnState = (state) => state.workplans.workplansExecutions.locations;

//Information
export const getLocation = (state, workplanExecutionLocationId) => {
  const intl = appIntl();
  const style = getComputedStyle(document.body);
  const workplanExecutionLocation = getOwnState(state).byId[workplanExecutionLocationId];
  if (workplanExecutionLocation) {
    const location = locationSelectors.getLocation(state, workplanExecutionLocation.locationId);
    return {
      ...location,
      ...workplanExecutionLocation,
      boundsCustomColor: style.getPropertyValue(
        ['A', 'T', 'V'].includes(workplanExecutionLocation.locationVisitStatus)
          ? '--success'
          : '--danger'
      ),
      locationClassDefaultColor: style.getPropertyValue(
        ['A', 'T', 'V'].includes(workplanExecutionLocation.locationVisitStatus)
          ? '--success'
          : '--danger'
      ),
      jobStatusLabel: workplanExecutionLocation.jobStatus
        ? intl.formatMessage({
            id: 'jobs.jobStatus.' + workplanExecutionLocation.jobStatus
          })
        : '',
      sequenceComplianceLabel: ['0', '1', '2', 0, 1, 2].includes(
        workplanExecutionLocation.validSequence
      )
        ? intl.formatMessage({
            id:
              'workplans.sequenceCompliance.' +
              // 0: Pending, 1: fulfilled, 2: unfulfilled
              workplanExecutionLocation.validSequence
          })
        : '',
      locationVisitStatusLabel: workplanExecutionLocation.locationVisitStatus
        ? intl.formatMessage({
            id: 'workplans.locationVisitStatus.' + workplanExecutionLocation.locationVisitStatus
          })
        : ''
    };
  } else return undefined;
};
export const getLocationsList = (state) =>
  getOwnState(state).order.map((id) => getLocation(state, id));
export const getLocationsListByVisit = (state, visitId) =>
  getLocationsList(state).filter(
    (workplanExecutionLocation) => workplanExecutionLocation.visitId === visitId
  );

//Status of sagas
export const isAddingLocation = (state) => getOwnState(state).isAdding;
export const isEditingLocation = (state) => getOwnState(state).isEditing;
export const isRemovingLocation = (state) => getOwnState(state).isRemoving;

//Errors
export const getAddingLocationErrors = (state) => getOwnState(state).errorAdding;
export const getEditingLocationErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingLocationErrors = (state) => getOwnState(state).errorRemoving;
