const paths = {
  routeList: '/route/list',
  routeEdit: '/route/edit',
  routeMonitor: '/route/monitor',
  routeReport: '/route/report',
  routeAssignUnit: '/route/assignUnit',
  importRouteAssignments: '/route/importRouteAssignments'
};

export default paths;
