import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';

import * as actions from '../../actions/workplansReport';
import * as types from '../../types/workplansReport';
import * as selectors from '../../reducers/workplans';
import * as workplansExecutionsSchemas from '../../schemas/workplansExecutions';
import * as unitsSchemas from '../../../Units/schemas';
import * as locationsSchemas from '../../../../../redux/schemas/locations';

import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                              FETCH LIST REPORT                             */
/* -------------------------------------------------------------------------- */
function* fetchWorkplansReport(action) {
  try {
    const filters = action.payload;
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/workplan/getReport`, {
        method: 'POST',
        body: JSON.stringify(
          endDateAdjustment({
            ...filters
          })
        ),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const indicators = camelcaseKeys(jsonResult.data.indicators);

        const units = jsonResult.data.units.map((result) => camelcaseKeys(result));
        const {
          entities: { units: unitsEntities },
          result: unitsOrder //order
        } = normalize(units, unitsSchemas.units); //normalize data to byId and order

        const locations = jsonResult.data.locations.map((result) => camelcaseKeys(result));
        const {
          entities: { locations: locationsEntities },
          result: locationsOrder //order
        } = normalize(locations, locationsSchemas.locations); //normalize data to byId and order

        const resultData = jsonResult.data.workplansList.map((result) => camelcaseKeys(result));
        const {
          entities: { workplansExecutions },
          result //order
        } = normalize(resultData, workplansExecutionsSchemas.workplansExecutions); //normalize data to byId and order
        yield put(
          actions.completeFetchingWorkplansReport(
            workplansExecutions,
            result,
            indicators,
            unitsEntities,
            unitsOrder,
            locationsEntities,
            locationsOrder
          )
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.workplanFetchReportMessageError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingWorkplansReport(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.workplanFetchReportMessageError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingWorkplansReport(error));
  }
}

export function* watchFetchWorkplansReport() {
  yield takeEvery(types.WORKPLANS_REPORT_FETCH_STARTED, fetchWorkplansReport);
}
