import * as types from '../../../types/static-catalogs/vehicle-models';

export const startFetchingVehicleModels = (maker) => ({
  type: types.VEHICLE_MODELS_FETCH_STARTED,
  payload: maker
});
export const completeFetchingVehicleModels = (entities, order) => ({
  type: types.VEHICLE_MODELS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingVehicleModels = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.VEHICLE_MODELS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
