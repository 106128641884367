import paths from 'views/screens/SafeDriver/paths';
import permissions from 'views/screens/SafeDriver/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'safeDriver',
    title: 'safeDriver.safeDriver',
    iconName: 'SafeDriver',
    badge: 'primary',
    badgeText: 'common.comingSoon',
    permissions: [permissions.safedriver],
    navLink: paths.list,
    screenId: screens.SAFE_DRIVER
  }
];
