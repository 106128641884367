export const LOCATIONS_FETCH_STARTED = 'LOCATIONS_FETCH_STARTED';
export const LOCATIONS_FETCH_COMPLETED = 'LOCATIONS_FETCH_COMPLETED';
export const LOCATIONS_FETCH_FAILED = 'LOCATIONS_FETCH_FAILED';

export const LOCATIONS_PARTIAL_FETCH_STARTED = 'LOCATIONS_PARTIAL_FETCH_STARTED';
export const LOCATIONS_PARTIAL_FETCH_COMPLETED = 'LOCATIONS_PARTIAL_FETCH_COMPLETED';
export const LOCATIONS_PARTIAL_FETCH_FAILED = 'LOCATIONS_PARTIAL_FETCH_FAILED';

export const LOCATION_ADD_STARTED = 'LOCATION_ADD_STARTED';
export const LOCATION_ADD_COMPLETED = 'LOCATION_ADD_COMPLETED';
export const LOCATION_ADD_FAILED = 'LOCATION_ADD_FAILED';

export const LOCATION_EDIT_STARTED = 'LOCATION_EDIT_STARTED';
export const LOCATION_EDIT_COMPLETED = 'LOCATION_EDIT_COMPLETED';
export const LOCATION_EDIT_FAILED = 'LOCATION_EDIT_FAILED';

export const LOCATION_REMOVE_STARTED = 'LOCATION_REMOVE_STARTED';
export const LOCATION_REMOVE_COMPLETED = 'LOCATION_REMOVE_COMPLETED';
export const LOCATION_REMOVE_FAILED = 'LOCATION_REMOVE_FAILED';

export const LOCATIONS_IMPORT_STARTED = 'LOCATIONS_IMPORT_STARTED';
export const LOCATIONS_IMPORT_COMPLETED = 'LOCATIONS_IMPORT_COMPLETED';
export const LOCATIONS_IMPORT_FAILED = 'LOCATIONS_IMPORT_FAILED';

export const LOCATION_SELECTED = 'LOCATION_SELECTED';
export const LOCATIONS_ALL_SELECTED = 'LOCATIONS_ALL_SELECTED';
export const LOCATION_DESELECTED = 'LOCATION_DESELECTED';
export const LOCATIONS_ALL_DESELECTED = 'LOCATIONS_ALL_DESELECTED';

export const LOCATION_SHOW_IN_MAP = 'LOCATION_SHOW_IN_MAP';
export const LOCATIONS_ALL_SHOW_IN_MAP = 'LOCATIONS_ALL_SHOW_IN_MAP';
export const LOCATION_HIDE_IN_MAP = 'LOCATION_HIDE_IN_MAP';
export const LOCATIONS_ALL_HIDE_IN_MAP = 'LOCATIONS_ALL_HIDE_IN_MAP';

export interface NewReduxLocation {
  isSelected: boolean;
  isShownInMap: boolean;
  locationId: number;
  locationAddress: string;
  dispatchCompletionType: number | null;
  dispatchEmails: string;
  dispatchNotificationFreq: number;
  enableDispatch: boolean;
  icon: string;
  latitude: number;
  locationName: string;
  locationTypeId: number;
  longitude: number;
  operatorId: number;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: string | null;
  subdivisionId: number | null;
  subdivisionName: string | null;
  customerId: number | null;
  officeId: number | null;
  privateCode: null | string;
  radius: number;
  locationClassId: number;
  locationClassName: string;
  locationClassIconName: string;
  locationClassDefaultColor: string | null;
  locationStatus: number;
  fullImageUrl: null | string;
  imageUrl: null | string;
  imageTypeId: number | null;
  imageCount: number;
  isConfirmed: boolean;
}
