import { useEffect, useState, useRef } from 'react';
import { getDowntimeBinnacleService } from '../services/index';
import camelcaseKeys from 'camelcase-keys';

const useGetDowntimeBinnacle = (
  unitId: number | undefined | null,
  startDate: string | undefined,
  endDate: string | undefined,
  execute?: boolean | undefined
): {
  downtimeBinnacle: any;
  isFetchingDowntimeBinnacle: boolean;
} => {
  const [downtimeBinnacle, setData] = useState<any>([]);
  const [isFetchingDowntimeBinnacle, setIsFetching] = useState<boolean>(false);
  useEffect(() => {
    if (
      unitId !== undefined &&
      unitId !== null &&
      startDate !== undefined &&
      startDate !== null &&
      endDate !== undefined &&
      endDate !== null
    ) {
      setIsFetching(true);
      getDowntimeBinnacleService(
        {
          unitId,
          startDate,
          endDate
        },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response) {
              setData(
                response.data.map((binnacle) => {
                  return camelcaseKeys({
                    ...binnacle
                  });
                })
              );
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [unitId, startDate, endDate, execute]);
  return { downtimeBinnacle, isFetchingDowntimeBinnacle };
};

export default useGetDowntimeBinnacle;
