import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../settings/apibaseurl';
import * as actions from '../../actions/configurations';
import * as types from '../../types/configurations';
import * as selectors from '../../reducers/configurations';
import * as schemas from '../../schemas/configurations';
import * as authSelectors from '../../reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../utils/alerts';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchConfigurationsList(action) {
  const { brandId, modelId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (brandId && modelId) {
      if (isAuth) {
        const token = yield select(authSelectors.getAuthToken);
        const response = yield call(
          fetch,
          `${API_BASE_URL}/model/configurations/${brandId}/${modelId}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        );
        const jsonResult = yield response.json();
        if (response.status <= 300 && jsonResult.success) {
          const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
          const {
            entities: { configurations },
            result //order
          } = normalize(resultData, schemas.configurations); //normalize data to byId and order
          yield put(actions.completeFetchingConfigurations(configurations, result));
        } else {
          const error = {
            errorMessage: jsonResult.message,
            errorNumber: jsonResult.number
          };
          // alerts.showErrorAlertFailChanges({ error });
          yield put(actions.failFetchingConfigurations(error));
        }
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingConfigurations(error));
  }
}

export function* watchFetchConfigurationsList() {
  yield takeEvery(types.CONFIGURATIONS_FETCH_STARTED, fetchConfigurationsList);
}
