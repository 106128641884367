const permissions = {
  listJobsBacklog: 'JB002',
  addJobBacklog: 'GF002',
  editJobBacklog: 'GF004',
  removeJobBacklog: 'GF003',
  searchJobBacklog: 'GF001',
  jobs: 'JB001',
  jobsBoard: 'JB003',
  jobsReport: 'JB004',
  jobsImport: 'JB013',
  jobsWorkingActivity: 'JB014',
  listAsignedJobsBacklog: 'GF006',
  asignJobBacklogToUnit: 'GF007',
  listOwnLocations: 'JB008',
  addLocations: 'JB005',
  editLocations: 'JB006',
  assignLocations: 'JB007',
  listLocationImages: 'JB010',
  addLocationImages: 'JB011',
  removeLocationImages: 'JB012'
};

export default permissions;
