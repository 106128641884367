import { List, Plus } from 'react-feather';
import Icon from '../../utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';
import modulePaths from 'views/screens/Modules/paths';
import vehiclePaths from 'views/screens/Vehicles/paths';
import simPaths from 'views/screens/SIMs/paths';
import sensorPaths from 'views/screens/Sensors/paths';
import assetPaths from 'views/screens/Assets/paths';
import modulePermissions from 'views/screens/Modules/permissions';
import vehiclePermissions from 'views/screens/Vehicles/permissions';
import simPermissions from 'views/screens/SIMs/permissions';
import sensorPermissions from 'views/screens/Sensors/permissions';
import assetPermissions from 'views/screens/Assets/permissions';

export default [
  {
    id: 'inventories',
    title: 'sidebar.inventories',
    icon: <Icon size={20} icon="inbox-filled" />,
    permissions: [],
    iconName: 'inbox-filled',
    children: [
      {
        id: 'modules',
        title: 'modules.modules',
        icon: <Icon size={20} icon="noun_GPS_3452904" />,
        permissions: [modulePermissions.listModule],
        iconName: 'noun_GPS_3452904',
        navLink: modulePaths.listModule,
        screenId: screens.DEVICES
      },
      {
        id: 'sims',
        title: 'sims.sim',
        icon: <Icon size={20} icon="noun_SIM_3331582-1" />,
        permissions: [simPermissions.listSim],
        iconName: 'noun_SIM_3331582-1',
        navLink: simPaths.listSim,
        screenId: screens.SIMS
      },
      {
        id: 'sensors',
        title: 'sensors.sensors',
        icon: <Icon size={20} icon="noun_proximity-sensor_614178" />,
        permissions: [sensorPermissions.listSensor],
        iconName: 'noun_proximity-sensor_614178',
        navLink: sensorPaths.listSensor,
        screenId: screens.SENSORS
      },
      {
        id: 'assets',
        title: 'assets.assets',
        icon: <Icon size={20} icon="noun_Check_3687547" />,
        permissions: [assetPermissions.assetCatalog],
        iconName: 'noun_Check_3687547',
        navLink: assetPaths.listAsset,
        screenId: screens.ASSETS
      },
      {
        id: 'vehicles',
        title: 'vehicles.vehicles',
        icon: <Icon size={20} icon="noun_Wheel_4039720" />,
        permissions: [vehiclePermissions.listVehicle],
        iconName: 'noun_Wheel_4039720',
        navLink: vehiclePaths.listVehicle,
        screenId: screens.VEHICLES
      }
    ]
  }
];
