export const CUSTOMERS_FETCH_STARTED = 'CUSTOMERS_FETCH_STARTED';
export const CUSTOMERS_FETCH_COMPLETED = 'CUSTOMERS_FETCH_COMPLETED';
export const CUSTOMERS_FETCH_FAILED = 'CUSTOMERS_FETCH_FAILED';

export const CUSTOMERS_PARTIAL_FETCH_STARTED = 'CUSTOMERS_PARTIAL_FETCH_STARTED';
export const CUSTOMERS_PARTIAL_FETCH_COMPLETED = 'CUSTOMERS_PARTIAL_FETCH_COMPLETED';
export const CUSTOMERS_PARTIAL_FETCH_FAILED = 'CUSTOMERS_PARTIAL_FETCH_FAILED';

export const CUSTOMER_ADD_STARTED = 'CUSTOMER_ADD_STARTED';
export const CUSTOMER_ADD_COMPLETED = 'CUSTOMER_ADD_COMPLETED';
export const CUSTOMER_ADD_FAILED = 'CUSTOMER_ADD_FAILED';

export const CUSTOMER_EDIT_STARTED = 'CUSTOMER_EDIT_STARTED';
export const CUSTOMER_EDIT_COMPLETED = 'CUSTOMER_EDIT_COMPLETED';
export const CUSTOMER_EDIT_FAILED = 'CUSTOMER_EDIT_FAILED';

export const CUSTOMER_REMOVE_STARTED = 'CUSTOMER_REMOVE_STARTED';
export const CUSTOMER_REMOVE_COMPLETED = 'CUSTOMER_REMOVE_COMPLETED';
export const CUSTOMER_REMOVE_FAILED = 'CUSTOMER_REMOVE_FAILED';

export const CUSTOMER_SELECTED = 'CUSTOMER_SELECTED';
export const CUSTOMERS_ALL_SELECTED = 'CUSTOMERS_ALL_SELECTED';
export const CUSTOMER_DESELECTED = 'CUSTOMER_DESELECTED';
export const CUSTOMERS_ALL_DESELECTED = 'CUSTOMERS_ALL_DESELECTED';

export interface Customer {
  isSelected: boolean;
  customerId: number;
  customerName: string;
  businessName: null | string;
  taxId: null | string;
  privateCode: null | string;
  customerStatus: number;
  organizationId: number;
  organizationName: string;
  divisionId: null | number;
  divisionName: null | string;
  subdivisionId: null | number;
  subdivisionName: null | string;
  customerAddress: null | string;
  phone1: null | string;
  phone2: null | string;
  phone3: null | string;
  contactName1: null | string;
  contactName2: null | string;
  contactName3: null | string;
  comments: null | string;
  customerEmails: null | string;
  officeId: number;
  isConfirmed: boolean;
}
