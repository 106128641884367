/* -------------------------------------------------------------------------- */
/*                     Componente SlidingPaneEditFileName                     */
/* -------------------------------------------------------------------------- */
// Este componente permite editar el nombre del archivo

import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Row, Col, Card, CardBody, CardImg } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import TextInput from '../../../formComponents/TextInput';
import Icon from 'utility/icomoon';

interface Props {
  file: any;
  editFileName?: (file) => void;
  isImg: boolean;
  isOpenSlidingPane: any;
  closeSlidingPaneEditFileName: any;
}

const SlidingPaneEditFileName = (props: Props) => {
  const { file, isOpenSlidingPane, closeSlidingPaneEditFileName, isImg, editFileName } = props;
  const { control, handleSubmit, watch, reset } = useForm();
  const intl = useIntl();

  const onSubmit = (values) => {
    if (editFileName)
      editFileName({
        fileId: file.fileId,
        fileName: `${values.fileName}.${file?.fileExtension}`
      });
    closeSlidingPaneEditFileName();
    reset();
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: 'common.editFileName' })}
      subtitle={`${intl.formatMessage({ id: 'common.actualFileName' })}: ${file.fileName}`}
      width={'35rem'}
      onRequestClose={() => {
        closeSlidingPaneEditFileName();
        reset();
      }}
    >
      <>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              name="fileName"
              defaultValue={file?.fileName?.split('.')[0]}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextInput
                  field={field}
                  fieldstate={fieldState}
                  id={'fileNameInput'}
                  label={intl.formatMessage({
                    id: 'common.fileName'
                  })}
                  required
                />
              )}
            />
          </Col>
        </Row>
        <div className="image-container-with-buttons">
          <Card className="webtrack file-preview">
            <div>
              {isImg && <CardImg width="100%" style={{ height: '100%' }} src={file.fileUrl} />}

              {!isImg && (
                <CardBody className="text-center" style={{ height: '85px' }}>
                  <div>
                    <div className={`avatar p-50 m-0 ${'bg-light-primary'}`}>
                      <div className="avatar-content">
                        <Icon
                          icon={
                            file.fileExtension
                              ? `File${file.fileExtension?.toUpperCase()}`
                              : 'FileGeneric'
                          }
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              )}
            </div>
          </Card>
        </div>
        <Row className={'footer-slide-pane'}>
          <Col sm="12">
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit((values) => onSubmit(values))}
            >
              <FormattedMessage id="common.save" />
            </Button.Ripple>
            <Button.Ripple
              className="mt-50 mb-50"
              color="light"
              onClick={() => {
                closeSlidingPaneEditFileName();
                reset();
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          </Col>
        </Row>
      </>
    </SlidingPane>
  );
};

SlidingPaneEditFileName.defaultProps = {};

export default SlidingPaneEditFileName;
