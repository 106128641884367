const paths = {
  workplanList: '/workplan/list',
  workplanEdit: '/workplan/edit',
  workplanMonitor: '/workplan/monitor',
  workplanReport: '/workplan/report',
  workplanAssignUnit: '/workplan/assignUnit',
  scheduledWorkplan: '/workplan/scheduled'
  // workplanImport: '/workplan/import'
};

export default paths;
