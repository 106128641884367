import * as types from '../types';

export const startFetchingMaintenanceLines = (filters = {}) => ({
  type: types.MAINTENANCE_LINES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingMaintenanceLines = (entities, order, operatorId, organizationId) => ({
  type: types.MAINTENANCE_LINES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingMaintenanceLines = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.MAINTENANCE_LINES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingMaintenanceLines = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.MAINTENANCE_LINES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingMaintenanceLines = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingMaintenanceLines = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.MAINTENANCE_LINES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingMaintenanceLine = (maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_ADD_STARTED,
  payload: maintenanceLine
});
export const completeAddingMaintenanceLine = (oldId, maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_ADD_COMPLETED,
  payload: { oldId, maintenanceLine }
});
export const failAddingMaintenanceLine = (oldId, error) => ({
  type: types.MAINTENANCE_LINE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingMaintenanceLine = (maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_EDIT_STARTED,
  payload: maintenanceLine
});
export const completeEditingMaintenanceLine = (maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_EDIT_COMPLETED,
  payload: maintenanceLine
});
export const failEditingMaintenanceLine = (oldMaintenanceLine, error) => ({
  type: types.MAINTENANCE_LINE_EDIT_FAILED,
  payload: {
    oldMaintenanceLine,
    error
  }
});

export const startRemovingMaintenanceLine = (maintenanceLineId: number) => ({
  type: types.MAINTENANCE_LINE_REMOVE_STARTED,
  payload: {
    maintenanceLineId
  }
});
export const completeRemovingMaintenanceLine = (maintenanceLineId) => ({
  type: types.MAINTENANCE_LINE_REMOVE_COMPLETED,
  payload: {
    maintenanceLineId
  }
});
export const failRemovingMaintenanceLine = (maintenanceLineId, error) => ({
  type: types.MAINTENANCE_LINE_REMOVE_FAILED,
  payload: {
    maintenanceLineId,
    error
  }
});

export const startCopyMaintenanceLine = (maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_COPY_STARTED,
  payload: maintenanceLine
});
export const completeCopyMaintenanceLine = (maintenanceLine) => ({
  type: types.MAINTENANCE_LINE_COPY_COMPLETED,
  payload: maintenanceLine
});
export const failCopyMaintenanceLine = (error) => ({
  type: types.MAINTENANCE_LINE_COPY_FAILED,
  payload: {
    error
  }
});

export const selectMaintenanceLine = (maintenanceLineId) => ({
  type: types.MAINTENANCE_LINE_SELECTED,
  payload: maintenanceLineId
});

export const selectAllMaintenanceLines = (
  maintenanceLineIds = [] //List of MaintenanceLineIds
) => ({
  type: types.MAINTENANCE_LINES_ALL_SELECTED,
  payload: maintenanceLineIds
});

export const deselectMaintenanceLine = (maintenanceLineId) => ({
  type: types.MAINTENANCE_LINE_DESELECTED,
  payload: maintenanceLineId
});

export const deselectAllMaintenanceLines = (
  maintenanceLineIds = [] //List of MaintenanceLineIds
) => ({
  type: types.MAINTENANCE_LINES_ALL_DESELECTED,
  payload: maintenanceLineIds
});
