export const WORKPLANS_FETCH_STARTED = 'WORKPLANS_FETCH_STARTED';
export const WORKPLANS_FETCH_COMPLETED = 'WORKPLANS_FETCH_COMPLETED';
export const WORKPLANS_FETCH_FAILED = 'WORKPLANS_FETCH_FAILED';

export const WORKPLANS_PARTIAL_FETCH_STARTED = 'WORKPLANS_PARTIAL_FETCH_STARTED';
export const WORKPLANS_PARTIAL_FETCH_COMPLETED = 'WORKPLANS_PARTIAL_FETCH_COMPLETED';
export const WORKPLANS_PARTIAL_FETCH_FAILED = 'WORKPLANS_PARTIAL_FETCH_FAILED';

export const WORKPLAN_LOCATIONS_FETCH_COMPLETED = 'WORKPLAN_LOCATIONS_FETCH_COMPLETED';
export const WORKPLAN_LOCATIONS_FETCH_FAILED = 'WORKPLAN_LOCATIONS_FETCH_FAILED';

export const WORKPLAN_ADD_STARTED = 'WORKPLAN_ADD_STARTED';
export const WORKPLAN_ADD_COMPLETED = 'WORKPLAN_ADD_COMPLETED';
export const WORKPLAN_ADD_FAILED = 'WORKPLAN_ADD_FAILED';

export const WORKPLAN_EDIT_STARTED = 'WORKPLAN_EDIT_STARTED';
export const WORKPLAN_EDIT_COMPLETED = 'WORKPLAN_EDIT_COMPLETED';
export const WORKPLAN_EDIT_FAILED = 'WORKPLAN_EDIT_FAILED';

export const WORKPLAN_REMOVE_STARTED = 'WORKPLAN_REMOVE_STARTED';
export const WORKPLAN_REMOVE_COMPLETED = 'WORKPLAN_REMOVE_COMPLETED';
export const WORKPLAN_REMOVE_FAILED = 'WORKPLAN_REMOVE_FAILED';

export const WORKPLAN_SELECTED = 'WORKPLAN_SELECTED';
export const WORKPLANS_ALL_SELECTED = 'WORKPLANS_ALL_SELECTED';
export const WORKPLAN_DESELECTED = 'WORKPLAN_DESELECTED';
export const WORKPLANS_ALL_DESELECTED = 'WORKPLANS_ALL_DESELECTED';

export const WORKPLAN_COPY_STARTED = 'WORKPLAN_COPY_STARTED';
export const WORKPLAN_COPY_COMPLETED = 'WORKPLAN_COPY_COMPLETED';
export const WORKPLAN_COPY_FAILED = 'WORKPLAN_COPY_FAILED';

export const WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED = 'WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED';
export const WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED =
  'WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED';
export const WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_FAILED = 'WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_FAILED';

export const WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED =
  'WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED';
export const WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED =
  'WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED';
export const WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_FAILED =
  'WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_FAILED';

export const WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED =
  'WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED';
export const WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED =
  'WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED';
export const WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_FAILED =
  'WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_FAILED';
