import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/customer';

export const getCustomersListService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'customers.customer',
    action: 'Get',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const saveCustomerService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'customers.customer',
    action: request.customerId ? 'Edit' : 'Create',
    name: request.customerName,
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteCustomerService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.customerName}`,
    object: 'customers.customer',
    action: 'Delete',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const importCustomersService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'customers.importCustomer',
    action: 'Get',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importCustomers`, request);
};
