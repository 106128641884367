const permissions = {
  //permisos catalogos
  sparepartsCatalog: 'MV006',
  workshopCatalog: 'MV005',
  categoriesCatalog: 'MV024',
  providersCatalog: 'MV020',

  //permisos add
  addSpareparts: 'MV012',
  addWorkshop: 'MV015',
  addCategories: 'MV025',
  addProviders: 'MV021',

  //permisos edit
  editSpareparts: 'MV013',
  editWorkshop: 'MV016',
  editCategories: 'MV026',
  editProviders: 'MV022',

  //permisos remove
  removeSpareparts: 'MV014',
  removeWorkshop: 'MV017',
  removeCategories: 'MV027',
  removeProviders: 'MV023',

  //reportes
  generalReport: 'MV003',
  byCategoryReport: 'MV006',
  byUnitReport: 'MV006',
  byWorkShopReport: 'MV006',

  //tickets
  importTicket: 'MV002',
  addTickets: 'MV032',

  //maintenanceLines
  maintenanceLines: 'MV028',
  maintenanceLineAdd: 'MV029',
  maintenanceLineEdit: 'MV030',
  maintenanceLineRemove: 'MV031',

  //preventiveNotices
  preventiveNotices: 'MV007',
  addPreventiveNotices: 'MV008',
  editPreventiveNotices: 'MV009',
  removePreventiveNotices: 'MV011',
  reschedulePreventiveNotice: 'MV010',
  totalNotices: 'MV006',
  expiredNotices: 'MV006',
  activeNotices: 'MV006'
};

export default permissions;
