import Icon from '../../utility/icomoon';
import paths from 'views/screens/People/paths';
import permissions from 'views/screens/People/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'people',
    title: 'people.people',
    icon: <Icon size={20} icon="noun_people_1850291" />,
    permissions: [permissions.listPerson],
    iconName: 'noun_people_1850291',
    navLink: paths.listPeople,
    screenId: screens.PEOPLE
  }
];
