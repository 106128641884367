import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../../types/static-catalogs/insurance-companies';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.INSURANCE_COMPANIES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((vehicleMakerId) => {
        newState[vehicleMakerId] = {
          isSelected: false,
          ...entities[vehicleMakerId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.INSURANCE_COMPANIES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.INSURANCE_COMPANIES_FETCH_STARTED: {
      return true;
    }
    case types.INSURANCE_COMPANIES_FETCH_COMPLETED:
    case types.INSURANCE_COMPANIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.INSURANCE_COMPANIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.INSURANCE_COMPANIES_FETCH_STARTED:
    case types.INSURANCE_COMPANIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const insuranceCompanies = combineReducers({
  byId,
  order,
  isFetchingList,
  errorFetchingList
});

export default insuranceCompanies;

//Own state
export const getOwnState = (state) => state.staticCatalogs.insuranceCompanies;

//Information
export const getVehicleMaker = (state, vehicleMakerId) => getOwnState(state).byId[vehicleMakerId];
export const getInsuranceCompaniesList = (state) =>
  getOwnState(state).order.map((id) => getVehicleMaker(state, id));
export const getInsuranceCompaniesListByCountry = (state, countryId) =>
  getInsuranceCompaniesList(state).filter((company) => company.countryId === countryId);

export const getSelectedInsuranceCompanies = (state) => {
  const selectedInsuranceCompanies = getInsuranceCompaniesList(state).filter(
    (vehicleMaker) => vehicleMaker.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedInsuranceCompanies.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedInsuranceCompanies.length > 0) return selectedInsuranceCompanies;
};

export const getSelectedVehicleMaker = (state) => {
  const selectedInsuranceCompanies = getInsuranceCompaniesList(state).filter(
    (vehicleMaker) => vehicleMaker.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedInsuranceCompanies.length === 1) return selectedInsuranceCompanies[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingVehicleMaker = (state) => getOwnState(state).isFetching;
export const isFetchingListInsuranceCompanies = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingVehicleMakerErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListInsuranceCompaniesErrors = (state) =>
  getOwnState(state).errorFetchingList;
