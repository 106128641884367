/* -------------------------------------------------------------------------- */
/*                            Componente ColorPicker                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un colorPicker genérico que se utiliza en muchas pantallas de la aplicación.

import React, { useState, useEffect } from 'react';
import { Label, Input, FormGroup, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import _ from 'lodash';
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';

interface Props {
  id: any;
  field: any;
  label: string;
  required: any;
  forceinitialvalue?: string;
  disabled: boolean;
  additionalonchangefunction?: Function;
}
const style = getComputedStyle(document.body);
const primary = style.getPropertyValue('--primary').trim();
// const borderColor =;
const ColorPicker = (props: Props) => {
  const {
    id,
    label,
    required,
    field,
    disabled,
    forceinitialvalue = primary,
    additionalonchangefunction
  } = props;

  useEffect(() => {
    if (field.value == null || field.value == undefined || field.value.length == 0) {
      field.onChange(forceinitialvalue);
    }
  }, [field.value]);

  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  useEffect(() => {
    if (forceinitialvalue) onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  const styles = reactCSS({
    default: {
      color: {
        width: '100%',
        margin: '0 auto',
        height: '22px',
        borderRadius: '5px',
        background: `${field.value ?? forceinitialvalue}`
      },
      swatch: {
        // padding: '5px',
        // background: '#fff',
        // borderRadius: '1px',
        // boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        // display: 'inline-block',
        // cursor: 'pointer'
        padding: '0.438rem 1rem',
        cursor: 'pointer',
        backgroundColor: ' #ffffff',
        backgroundClip: 'padding-box',
        border: `1px solid #d8d6de`,
        borderRadius: '0.357rem'
      },
      popover: {
        position: 'absolute',
        zIndex: '50000'
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px'
      }
    }
  });
  return (
    <FormGroup>
      <Label className="form-label" for={id}>
        {label}
        {required && <span className="required">&nbsp;*</span>}
      </Label>
      <div>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={handleClose} />
            <SketchPicker
              id={id}
              key={id}
              color={field.value}
              width={300}
              onChangeComplete={(color, event) => {
                onChangeFinal(color.hex);
              }}
              disableAlpha={true}
              disabled={disabled}
              presetColors={[
                primary,
                '#D0021B',
                '#F5A623',
                '#F8E71C',
                '#8B572A',
                '#7ED321',
                '#417505',
                '#BD10E0',
                '#9013FE',
                '#4A90E2',
                '#50E3C2',
                '#B8E986',
                '#000000',
                '#4A4A4A',
                '#9B9B9B'
              ]}
            />
          </div>
        ) : null}
      </div>
    </FormGroup>
  );
};

ColorPicker.defaultProps = {
  required: true,
  disabled: false
};

export default ColorPicker;
