import * as types from '../types';

export const startFetchingOffices = (filters = {}) => ({
  type: types.OFFICES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingOffices = (entities, order, operatorId, organizationId) => ({
  type: types.OFFICES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingOffices = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.OFFICES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingOffices = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.OFFICES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingOffices = (entities, order, operatorId, organizationId) => ({
  type: types.OFFICES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingOffices = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.OFFICES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingOffice = (office) => ({
  type: types.OFFICE_ADD_STARTED,
  payload: office
});
export const completeAddingOffice = (oldId, office) => ({
  type: types.OFFICE_ADD_COMPLETED,
  payload: { oldId, office }
});
export const failAddingOffice = (oldId, error) => ({
  type: types.OFFICE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingOffice = (office) => ({
  type: types.OFFICE_EDIT_STARTED,
  payload: office
});
export const completeEditingOffice = (office) => ({
  type: types.OFFICE_EDIT_COMPLETED,
  payload: { office }
});
export const failEditingOffice = (oldOffice, error) => ({
  type: types.OFFICE_EDIT_FAILED,
  payload: {
    oldOffice,
    error
  }
});

export const startRemovingOffice = (officeId) => ({
  type: types.OFFICE_REMOVE_STARTED,
  payload: {
    officeId
  }
});
export const completeRemovingOffice = (officeId) => ({
  type: types.OFFICE_REMOVE_COMPLETED,
  payload: {
    officeId
  }
});
export const failRemovingOffice = (officeId, error) => ({
  type: types.OFFICE_REMOVE_FAILED,
  payload: {
    officeId,
    error
  }
});

export const selectOffice = (officeId) => ({
  type: types.OFFICE_SELECTED,
  payload: officeId
});

export const selectAllOffices = (
  officeIds = [] //List of OfficeIds
) => ({
  type: types.OFFICES_ALL_SELECTED,
  payload: officeIds
});

export const deselectOffice = (officeId) => ({
  type: types.OFFICE_DESELECTED,
  payload: officeId
});

export const deselectAllOffices = (
  officeIds = [] //List of OfficeIds
) => ({
  type: types.OFFICES_ALL_DESELECTED,
  payload: officeIds
});
