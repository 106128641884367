export const CURRENCIES_FETCH_STARTED = 'CURRENCIES_FETCH_STARTED';
export const CURRENCIES_FETCH_COMPLETED = 'CURRENCIES_FETCH_COMPLETED';
export const CURRENCIES_FETCH_FAILED = 'CURRENCIES_FETCH_FAILED';

export interface Currency {
  isSelected: boolean;
  currencyId: number;
  currencyName: string;
  codeAlpha: string;
  symbol: string;
  countryCode: string;
  isConfirmed: boolean;
}
