import Icon from '../../utility/icomoon';
import organizationContractPaths from 'views/screens/Contracts/paths';
import contractTemplatesPaths from 'views/screens/ContractTemplates/paths';
import serviceClassPaths from 'views/screens/ServiceClasses/paths';
import quotationPaths from 'views/screens/Quotations/paths';
import organizationContractPermissions from 'views/screens/Contracts/permissions';
import contractTemplatesPermissions from 'views/screens/ContractTemplates/permissions';
import serviceClassPermissions from 'views/screens/ServiceClasses/permissions';
import quotationPermissions from 'views/screens/Quotations/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'service-class-orm',
    title: 'serviceClasses.serviceClasses',
    icon: <Icon size={20} icon="DollarSign" />,
    permissions: [serviceClassPermissions.listServiceClass],
    accessLevels: [9, 13, 15],
    iconName: 'DollarSign',
    navLink: serviceClassPaths.listServiceClass,
    screenId: screens.SERVICE_CLASSES
  },
  {
    id: 'contracts-templates',
    title: 'contractTemplates.templates',
    icon: <Icon size={20} icon="PenTool" />,
    permissions: [contractTemplatesPermissions.listTemplate],
    iconName: 'PenTool',
    navLink: contractTemplatesPaths.listTemplate,
    screenId: screens.CONTRACT_TEMPLATES
  },
  {
    id: 'contracts',
    title: 'contracts.contracts',
    icon: <Icon size={20} icon="FileText" />,
    iconName: 'FileText',
    permissions: [organizationContractPermissions.listContract],
    navLink: organizationContractPaths.listContract,
    screenId: screens.CONTRACTS
  },
  {
    id: 'quotations',
    title: 'quotations.quotations',
    icon: <Icon size={20} icon="Edit" />,
    iconName: 'Edit',
    permissions: [quotationPermissions.listQuotation],
    navLink: quotationPaths.listQuotations,
    screenId: screens.QUOTATIONS
  }
];
