/* -------------------------------------------------------------------------- */
/*                          JobTypeDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un JobTypeDropdownInput

import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { getJobTypeIcon } from '../../utils';
import DropdownInput from '../../../../formComponents/DropdownInput';
import Icon from '../../../../../utility/icomoon';

const JobTypeDropdownInput = (props) => {
  const {
    forceinitialvalue,
    field,
    additionalonchangefunction = (value) => null,
    disabled
  } = props;
  const intl = useIntl();
  const jobTypes = Array(11)
    .fill(null)
    .map((_, i) => ({
      id: i + 1,
      value: i + 1,
      label: `jobs.jobType.${i + 1}`,
      iconName: getJobTypeIcon(i + 1)
    }));

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  useEffect(() => {
    if (field.value == null || field.value == undefined || field.value.length == 0) {
      field.onChange(forceinitialvalue);
    }
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue) {
      onChangeFinal(forceinitialvalue);
    }
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  return (
    <DropdownInput
      {...omit(props, ['forceinitialvalue'])}
      name="jobTypeId"
      id={'jobTypeId'}
      key={resetValue}
      isDisabled={disabled}
      label={<FormattedMessage id="jobs.jobType" />}
      options={jobTypes.map((jobType) => ({
        ...jobType,
        icon: <Icon icon={jobType.iconName} />,
        label: <FormattedMessage id={`jobs.jobType.${jobType.value}`} />,
        label_intl: intl.formatMessage({ id: 'jobs.jobType.' + jobType.value })
      }))}
    />
  );
};

export default JobTypeDropdownInput;
