import { Fragment } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Col, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Icon from 'utility/icomoon';
import DefaultSimpleButton from 'views/components/DefaultSimpleButton';
import { useSelector } from 'react-redux';
import * as authSelectors from 'redux/reducers/auth';
/* -------------------------------------------------------------------------- */
/*                                   UnitFuel                                 */
/* -------------------------------------------------------------------------- */
export default function UnitFuel({ unit, sensors, authSystems }) {
  const intl = useIntl();
  const history = useHistory();
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const sensorId = sensors[0]?.sensorId;
  const measureId = sensors[0]?.measureId;

  const { parsers } = useSelector((state) => {
    return {
      parsers: authSelectors.getParsers(state)
    };
  });

  return (
    <Row>
      {unit.deviceMetrics
        .filter((metric) => metric?.measureTypeId && metric.measureTypeId === 8)
        .map((metric, i) => {
          return (
            <Fragment key={i}>
              {i % 2 === 0 && i !== 0 && <hr className={'full-width-slide-panel'} />}
              <Col className={i % 2 !== 0 ? 'border-left' : ''}>
                <Label className="label-information">
                  <Icon
                    icon={'GasStation'}
                    color={primary}
                    size={16}
                    style={{ marginRight: '0.2rem' }}
                  />
                  <div id={metric.metricDescription + i}>{metric.metricDescription}</div>
                  {metric.metricDescription === 'Combustible' && (
                    <UncontrolledTooltip placement={'top'} target={metric.metricDescription + i}>
                      {intl.formatMessage({ id: 'telemetry.telemetryFilteredValue' })}
                    </UncontrolledTooltip>
                  )}

                  {sensors?.length > 0 && (
                    <DefaultSimpleButton
                      icon={'TrendingUp'}
                      className={'ml-2'}
                      id={'showFuelHistButton'}
                      tooltipMessage={intl.formatMessage({
                        id: 'telemetry.showOnTelemetry'
                      })}
                      color={primary}
                      handleClick={() => {
                        history.push(
                          { pathname: '/telemetry/fuel/monitor' },
                          {
                            unitId: unit?.unitId,
                            sensorId,
                            measureId
                          }
                        );
                      }}
                    />
                  )}
                </Label>

                <Label className="label-value-information">{`${
                  metric?.measurementUnitId === 4
                    ? parsers.convertGalonsToAuthUserCapacitySystem(metric?.numberValue).toFixed(2)
                    : parsers.convertLitersToAuthUserCapacitySystem(metric?.numberValue).toFixed(2)
                } ${authSystems?.capacity}`}</Label>
              </Col>
            </Fragment>
          );
        })}
    </Row>
  );
}
