const permissions = {
  listUsers: 'US001',
  addUser: 'US002',
  editUser: 'US003',
  removeUser: 'US004',
  changePasswordUser: 'US005',
  loadRolesPermissions: 'US007',
  editUserPermissions: 'US006',
  loadUserPermissions: 'US008',
  suspendUser: 'US009',
  reactivateUser: 'US010',
  role1000: 'RS000',
  role1001: 'RS001',
  role1002: 'RS002',
  role1003: 'RS003',
  role1005: 'RS005',
  role1009: 'RS009',
  role1012: 'RS012',
  role1015: 'RS015',
  role1016: 'RS016',
  role1020: 'RS020',
  role1023: 'RS023',
  customerAccessLevel: 'AS002',
  divisionAccessLevel: 'AS004',
  organizationAccessLevel: 'AS007',
  systemAccessLevel: 'AS009',
  agentAccessLevel: 'AS011',
  operatorAccessLevel: 'AS013',
  gpsAccessLevel: 'AS020'
};

export default permissions;
