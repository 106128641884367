/* -------------------------------------------------------------------------- */
/*                            Schema Agents                                   */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const brand = new schema.Entity(
  "brands",
  {},
  { idAttribute: "brandId" }
);
export const brands = new schema.Array(brand);
