export const CONTACTS_FETCH_STARTED = 'CONTACTS_FETCH_STARTED';
export const CONTACTS_FETCH_COMPLETED = 'CONTACTS_FETCH_COMPLETED';
export const CONTACTS_FETCH_FAILED = 'CONTACTS_FETCH_FAILED';

export const CONTACTS_PARTIAL_FETCH_STARTED = 'CONTACTS_PARTIAL_FETCH_STARTED';
export const CONTACTS_PARTIAL_FETCH_COMPLETED = 'CONTACTS_PARTIAL_FETCH_COMPLETED';
export const CONTACTS_PARTIAL_FETCH_FAILED = 'CONTACTS_PARTIAL_FETCH_FAILED';

export const CONTACT_ADD_STARTED = 'CONTACT_ADD_STARTED';
export const CONTACT_ADD_COMPLETED = 'CONTACT_ADD_COMPLETED';
export const CONTACT_ADD_FAILED = 'CONTACT_ADD_FAILED';

export const CONTACT_EDIT_STARTED = 'CONTACT_EDIT_STARTED';
export const CONTACT_EDIT_COMPLETED = 'CONTACT_EDIT_COMPLETED';
export const CONTACT_EDIT_FAILED = 'CONTACT_EDIT_FAILED';

export const CONTACT_REMOVE_STARTED = 'CONTACT_REMOVE_STARTED';
export const CONTACT_REMOVE_COMPLETED = 'CONTACT_REMOVE_COMPLETED';
export const CONTACT_REMOVE_FAILED = 'CONTACT_REMOVE_FAILED';

export const CONTACT_FETCH_STARTED = 'CONTACT_FETCH_STARTED';
export const CONTACT_FETCH_COMPLETED = 'CONTACT_FETCH_COMPLETED';
export const CONTACT_FETCH_FAILED = 'CONTACT_FETCH_FAILED';

export const CONTACT_SELECTED = 'CONTACT_SELECTED';
export const CONTACTS_ALL_SELECTED = 'CONTACTS_ALL_SELECTED';
export const CONTACT_DESELECTED = 'CONTACT_DESELECTED';
export const CONTACTS_ALL_DESELECTED = 'CONTACTS_ALL_DESELECTED';
