import console from './console';
import protocol from './protocol';

export default [
  {
    header: 'sidebar.general',
    iconName: 'Target',
    children: [...console, ...protocol]
  },
  ...console,
  ...protocol
];
