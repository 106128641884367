import * as types from '../../types/routesAssignments';

export const startFetchingAssignments = (
  startDate,
  endDate,
  organizationId,
  divisionId,
  subdivisionId
) => ({
  type: types.ROUTES_ASSIGNMENTS_FETCH_STARTED,
  payload: { startDate, endDate, organizationId, divisionId, subdivisionId }
});
export const completeFetchingAssignments = (
  entitiesByDate,
  orderByDate,
  entitiesBySchedule,
  orderBySchedule
) => ({
  type: types.ROUTES_ASSIGNMENTS_FETCH_COMPLETED,
  payload: {
    orderByDate,
    entitiesByDate,
    orderBySchedule,
    entitiesBySchedule
  }
});
export const failFetchingAssignments = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_ASSIGNMENTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startEditingAssignment = (assignment) => ({
  type: types.ROUTES_ASSIGNMENT_EDIT_STARTED,
  payload: assignment
});
export const completeEditingAssignment = (routeAssignmentId, assignment) => ({
  type: types.ROUTES_ASSIGNMENT_EDIT_COMPLETED,
  payload: { routeAssignmentId, assignment }
});
export const failEditingAssignment = (routeAssignmentId, oldAssignment, error) => ({
  type: types.ROUTES_ASSIGNMENT_EDIT_FAILED,
  payload: { routeAssignmentId, oldAssignment, error }
});

export const startRemovingAssignment = (routeAssignmentId) => ({
  type: types.ROUTES_ASSIGNMENT_REMOVE_STARTED,
  payload: {
    routeAssignmentId
  }
});
export const completeRemovingAssignment = (routeAssignmentId) => ({
  type: types.ROUTES_ASSIGNMENT_REMOVE_COMPLETED,
  payload: {
    routeAssignmentId
  }
});
export const failRemovingAssignment = (routeAssignmentId, error) => ({
  type: types.ROUTES_ASSIGNMENT_REMOVE_FAILED,
  payload: {
    routeAssignmentId,
    error
  }
});

export const startImportingRouteAssignments = (routeAssignments) => ({
  type: types.ROUTES_ASSIGNMENTS_IMPORT_STARTED,
  payload: {
    routeAssignments
  }
});
export const completeImportingRouteAssignments = (entitiesByDate, orderByDate) => ({
  type: types.ROUTES_ASSIGNMENTS_IMPORT_COMPLETED,
  payload: {
    entitiesByDate,
    orderByDate
  }
});
export const failImportingRouteAssignments = (error) => ({
  type: types.ROUTES_ASSIGNMENTS_IMPORT_FAILED,
  payload: {
    error
  }
});
