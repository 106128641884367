export const SPAREPARTS_FETCH_STARTED = 'SPAREPARTS_FETCH_STARTED';
export const SPAREPARTS_FETCH_COMPLETED = 'SPAREPARTS_FETCH_COMPLETED';
export const SPAREPARTS_FETCH_FAILED = 'SPAREPARTS_FETCH_FAILED';

export const SPAREPARTS_PARTIAL_FETCH_STARTED = 'SPAREPARTS_PARTIAL_FETCH_STARTED';
export const SPAREPARTS_PARTIAL_FETCH_COMPLETED = 'SPAREPARTS_PARTIAL_FETCH_COMPLETED';
export const SPAREPARTS_PARTIAL_FETCH_FAILED = 'SPAREPARTS_PARTIAL_FETCH_FAILED';

export const SPAREPART_ADD_STARTED = 'SPAREPART_ADD_STARTED';
export const SPAREPART_ADD_COMPLETED = 'SPAREPART_ADD_COMPLETED';
export const SPAREPART_ADD_FAILED = 'SPAREPART_ADD_FAILED';

export const SPAREPART_EDIT_STARTED = 'SPAREPART_EDIT_STARTED';
export const SPAREPART_EDIT_COMPLETED = 'SPAREPART_EDIT_COMPLETED';
export const SPAREPART_EDIT_FAILED = 'SPAREPART_EDIT_FAILED';

export const SPAREPART_REMOVE_STARTED = 'SPAREPART_REMOVE_STARTED';
export const SPAREPART_REMOVE_COMPLETED = 'SPAREPART_REMOVE_COMPLETED';
export const SPAREPART_REMOVE_FAILED = 'SPAREPART_REMOVE_FAILED';

export interface SpareParts {
  isSelected: boolean;
  sparePartId: number;
  sparePartName: string;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: string | null;
  subdivisionId: number | null;
  subdivisionName: null | string;
  categoryName: string;
  categoryId: number;
  categoryIcon: string;
  isConfirmed: boolean;
}
