/* -------------------------------------------------------------------------- */
/*                      Componente SlidepanelLocationImages                     */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Sidebar para subir una imagen.

import camelcaseKeys from 'camelcase-keys';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import Toolbar from 'views/components/Toolbar';
import ImageUploader from 'views/formComponents/ImageUploader';
import TextInput from 'views/formComponents/TextInput';
import {
  deleteLocationImage,
  getLocationImages,
  saveLocationImage
} from 'views/screens/Locations/services/locationImages';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import DefaultButton from '../../../../components/DefaultButton';
import MediaGallery from '../../../../components/MediaGallery';
import TitleH4 from '../../../../components/TitleH4';
import CheckboxInput from '../../../../formComponents/CheckBoxInput';

/* -------------------------------------------------------------------------- */
/*                         SlidepanelLocationImages                           */
/* -------------------------------------------------------------------------- */

interface Props {
  closeSlidepanel: Function;
  isSlidepanelOpen: boolean;
  title: string;
  isInMap: boolean;
  location: any;
  addImageInitial: boolean;
}
const SlidepanelLocationImages = (props: Props) => {
  const { closeSlidepanel, isSlidepanelOpen, title, isInMap, location, addImageInitial } = props;
  const { control, handleSubmit } = useForm();
  const intl = useIntl();

  const [locationImages, setLocationImages] = useState<any[]>([]);
  const [isLoadingLocationImages, setIsLoadingLocationImages] = useState<Boolean>(false);
  const [activeSlideImage, setActiveSlideImage] = useState<number>(0);

  //addImage to use slide to add image instead of editing
  const [addImage, setAddImage] = useState<boolean>(addImageInitial);
  useEffect(() => {
    setAddImage(addImageInitial);
  }, [addImageInitial]);
  const [uploadImage, setUploadImage] = useState({
    imageName: '',
    imageUrl: ''
  });

  //getLocationImages and set them in state
  const getLocationImagesAsync = async (location) => {
    setIsLoadingLocationImages(true);
    setLocationImages([]);
    const response = await getLocationImages({ locationId: location.locationId });
    setIsLoadingLocationImages(false);
    if (response.success) {
      const images = camelcaseKeys(response.data).map((loc) => {
        return {
          ...loc,
          // imageUrl: `${authSelectors.getAuthUser(store).imagesUrl}locationImages/${loc.imageUrl}`,
          imagePath: 'locationImages/',
          imageId: loc.locationImageId,
          isDefault: loc.isDefault == null || loc.isDefault == 0 ? 0 : 1
        };
      });
      setLocationImages(images);
    }
  };

  //function to save an image on locationimages table
  const saveLocationImageAsync = async (values) => {
    setIsLoadingLocationImages(true);
    const defaultImageValues = !addImage ? locationImages[activeSlideImage] : {};
    const locationImageSave = {
      ...defaultImageValues,
      ...values,
      imageUrl: !addImage
        ? defaultImageValues.imageUrl
        : values.imageTypeId == 1
        ? values.fileName
        : values.imageUrl,
      imageName: values.imageName,
      imageDescription: values.imageDescription,
      isDefault: values.isDefault
    };
    const response = await saveLocationImage(locationImageSave);
    if (response.success) {
      await getLocationImagesAsync(location);
      setAddImage(false);
      setIsLoadingLocationImages(false);
    } else {
      const error = {
        errorMessage: response.message,
        errorNumber: response.number
      };
      // alerts.showErrorAlertFailChanges({ error });
    }
  };

  //function to delete location image in locationimages table
  const deleteLocationImageAsync = async () => {
    const locationImageDelete = {
      ...locationImages[activeSlideImage]
    };
    alerts.showDeleteConfirmationMessage({
      action: async () => {
        setIsLoadingLocationImages(true);
        const response = await deleteLocationImage(locationImageDelete);
        if (response.success) {
          await getLocationImagesAsync(location);
          setIsLoadingLocationImages(false);
        } else {
          const error = {
            errorMessage: response.message,
            errorNumber: response.number
          };
          // alerts.showErrorAlertFailChanges({ error });
        }
      },
      objectType: appIntl().formatMessage({ id: 'locations.locationImage' }),
      objectName: locationImageDelete.imageName
    });
  };

  //each time location changes, get images
  useEffect(() => {
    if (isSlidepanelOpen && location) {
      getLocationImagesAsync(location);
    }
  }, [location, isSlidepanelOpen]);

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName={isInMap ? 'custom-overlay-sliding-pane-map' : 'custom-overlay-sliding-pane'}
      isOpen={isSlidepanelOpen}
      title={title}
      width="40rem"
      onRequestClose={() => {
        if (closeSlidepanel) closeSlidepanel();
      }}
    >
      <TitleH4
        textId={!addImage ? 'locations.locationImages' : 'locations.newImage'}
        center={true}
        textTransform={'uppercase'}
      />
      {/* Loading locaition images show spinner */}
      {isLoadingLocationImages && (
        <div className="d-flex justify-content-center my-1">
          <Spinner color="primary" />
        </div>
      )}

      {/* Toolbar for editing images */}
      {!isLoadingLocationImages && (
        <>
          {!addImage && (
            <Toolbar
              buttons={[
                <DefaultButton
                  id={'locationImagesAddButton'}
                  icon={'Upload'}
                  isDisabled={false}
                  handleClick={() => {
                    setAddImage(true);
                  }}
                  tooltipMessage={appIntl().formatMessage({ id: 'locations.addImage' })}
                />,
                locationImages.length > 0 && (
                  <DefaultButton
                    id={'locationImagesDeleteButton'}
                    color="danger"
                    icon={'Trash'}
                    tooltipMessage={appIntl().formatMessage({ id: 'locations.deleteImage' })}
                    isDisabled={false}
                    handleClick={() => {
                      deleteLocationImageAsync();
                    }}
                  />
                )
              ]}
            />
          )}
          {/* locationimagesgallery to edit and its respective form  */}
          {locationImages.length > 0 && !addImage && (
            <MediaGallery
              isRtl={false}
              images={locationImages}
              autoplay={false}
              onSlideChange={(slideId) => {
                setActiveSlideImage(slideId);
              }}
              navigation={true}
              pagination={true}
              useThumbs={true}
              className={'full-width-slide-panel'}
              classNameThumbs={'full-width-slide-panel'}
              heightThumbs={'10%'}
            />
          )}

          {locationImages.length > 0 && !addImage && (
            <Form>
              <br />
              <Row>
                <Col sm={'12'}>
                  <Controller
                    control={control}
                    name="imageName"
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'imageNameInput'}
                        required={false}
                        forceinitialvalue={
                          !addImage ? locationImages[activeSlideImage].imageName : ''
                        }
                        label={<FormattedMessage id="common.imageName" />}
                      />
                    )}
                  />
                </Col>
                <Col sm={'12'}>
                  <Controller
                    control={control}
                    name="imageDescription"
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'imageDescriptionInput'}
                        required={false}
                        forceinitialvalue={
                          !addImage ? locationImages[activeSlideImage].imageDescription : ''
                        }
                        label={<FormattedMessage id="common.imageDescription" />}
                      />
                    )}
                  />
                </Col>
                <Col sm={'12'}>
                  <Controller
                    control={control}
                    name="isDefault"
                    render={({ field }) => (
                      <CheckboxInput
                        field={field}
                        forceinitialvalue={
                          !addImage ? locationImages[activeSlideImage].isDefault == 1 : false
                        }
                        label={<FormattedMessage id="common.imageDefault" />}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <FormGroup className="d-flex mb-0">
                    <Button.Ripple
                      className="mr-1"
                      color="primary"
                      /*type="submit"*/
                      onClick={handleSubmit((values) => {
                        saveLocationImageAsync(values);
                        closeSlidepanel();
                      })}
                    >
                      <FormattedMessage id="common.save" />
                    </Button.Ripple>
                    <Button.Ripple
                      color="light"
                      onClick={() => {
                        if (closeSlidepanel) closeSlidepanel();
                      }}
                    >
                      <FormattedMessage id="common.cancel" />
                    </Button.Ripple>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          )}

          {/* Message to show if there are no images to edit  */}
          {locationImages.length == 0 && !isLoadingLocationImages && !addImage && (
            <div className="misc-inner p-2 p-sm-3">
              <div className="w-100 text-center">
                <h2 className="mb-1">
                  <FormattedMessage id="locations.noImages"></FormattedMessage>
                </h2>
                <p className="mb-2">
                  <FormattedMessage id="locations.startUploadingImages"></FormattedMessage>
                </p>
                <Button.Ripple
                  color="primary"
                  className="btn-sm-block mb-1"
                  onClick={() => {
                    setAddImage(true);
                  }}
                >
                  <FormattedMessage id="locations.startUploadingImagesButton"></FormattedMessage>
                </Button.Ripple>
              </div>
            </div>
          )}

          {/* Image Uploader if addImage is in true */}
          {addImage && (
            <ImageUploader
              cropShape={'rectangle'} //cropShape
              showGrid={false} //grid in image
              aspect={4 / 3} // aspect in image
              onSuccessChangeFunction={(values) => {}} //Function to do after a succesfull upload
              onFailureChangeFunction={() => {}} //Function to do after a failure upload
              otherValues={{ locationId: location.locationId }}
              inputs={[
                {
                  type: 'text',
                  name: 'imageName',
                  label: intl.formatMessage({ id: 'common.imageName' }),
                  required: true,
                  initialValue: ''
                },
                {
                  type: 'text',
                  name: 'imageDescription',
                  label: intl.formatMessage({ id: 'common.imageDescription' }),
                  required: false,
                  initialValue: ''
                },
                {
                  name: 'isDefault',
                  //component: CheckboxInput,
                  forceinitialvalue: false,
                  label: intl.formatMessage({ id: 'common.imageDefault' }),
                  required: false,
                  type: 'checkbox',
                  initialValue: ''
                }
              ]}
              onChangeImage={(value) => setUploadImage(value)}
              functionAfterUpload={(values) => {
                saveLocationImageAsync(values);
              }}
              closeSlidepanel={() => {
                if (addImageInitial) {
                  closeSlidepanel();
                } else {
                  setAddImage(false);
                }
              }}
              filesPath={'/locationImages/'}
            />
          )}
        </>
      )}
      <br />
      {addImage && !addImageInitial && (
        <Row>
          <Col sm="12">
            <FormGroup className="d-flex mb-0">
              <Button.Ripple
                color="light"
                onClick={() => {
                  setAddImage(false);
                }}
              >
                <FormattedMessage id="locations.goBackToImages" />
              </Button.Ripple>
            </FormGroup>
          </Col>
        </Row>
      )}
    </SlidingPane>
  );
};
SlidepanelLocationImages.defaultProps = {
  closeSlidepanel: () => null,
  isSlidepanelOpen: false,
  title: '',
  isInMap: true,
  location: {},
  submitting: false,
  addImageInitial: false
};

export default SlidepanelLocationImages;
