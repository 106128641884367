import { combineReducers } from "redux";

import union from "lodash/union";

import * as types from "../../../types/static-catalogs";

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.TIME_ZONES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((timeZoneId) => {
        newState[timeZoneId] = {
          isSelected: false,
          ...state[timeZoneId],
          ...entities[timeZoneId],
          isConfirmed: true,
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.TIME_ZONES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.TIME_ZONES_FETCH_STARTED: {
      return true;
    }
    case types.TIME_ZONES_FETCH_COMPLETED:
    case types.TIME_ZONES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.TIME_ZONES_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.TIME_ZONES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.TIME_ZONES_FETCH_STARTED:
    case types.TIME_ZONES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const timeZones = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList,
});

export default timeZones;

//Own state
export const getOwnState = (state) => state.staticCatalogs.timeZones;

//Information
export const getTimeZone = (state, timeZoneId) =>
  getOwnState(state).byId[timeZoneId];
export const getTimeZonesList = (state) =>
  getOwnState(state).order.map((id) => getTimeZone(state, id));
export const getTimeZoneDropdownOption = (state, timeZoneId) => {
  let tz = getTimeZone(state, timeZoneId);
  return tz != undefined
    ? {
        ...tz,
        id: tz.zoneId,
        value: tz.zoneId,
        label: tz.zoneName,
      }
    : null;
};
//Status of sagas
export const isFetchingListTimeZones = (state) =>
  getOwnState(state).isFetchingList;
export const haveTimezonesBeenFetched = (state) =>
  getOwnState(state).hasAlreadyFetched;
//Errors
export const getFetchingListTimeZonesErrors = (state) =>
  getOwnState(state).errorFetchingList;
