import Swal from 'sweetalert2';
import { ConfigMessages } from 'utility/http/http-events';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';

export interface AlertsConfig extends ConfigMessages {
  success?: string | null;
  altText?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                Common Success, Confirmation And Error Alerts               */
/* -------------------------------------------------------------------------- */
export const showSuccessMessage = ({ success, timer = 4000 }) => {
  Swal.fire({
    timer: timer,
    icon: 'success',
    text: `${success}`,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showConfirmationMessage = ({ title, message, confirmText, action, width }) => {
  Swal.fire({
    icon: 'warning',
    title: title,
    text: message,
    showConfirmButton: true,
    width: width,
    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: confirmText,
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

export const showErrorAlertFailChanges = ({ error, timer = undefined, title = 'Error' }) => {
  const message =
    error == null ? appIntl().formatMessage({ id: 'common.changesFail' }) : error.errorMessage;
  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    title: title,
    text: `${message}`,
    timer: timer,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showErrorCustomMessage = (config: AlertsConfig) => {
  Swal.fire({
    icon: 'error',
    text: `${config.customMessageError}`,
    timer: config.errorTimer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#FF4757',
    buttonsStyling: false
  });
};

export const showErrorAlertWarning = ({ error, timer = undefined, title = '' }) => {
  const message =
    error == null ? appIntl().formatMessage({ id: 'common.changesFail' }) : error.errorMessage;
  Swal.fire({
    icon: 'warning',
    iconColor: '#FF4757',
    title: title,
    text: `${message}`,
    timer: timer,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                 Success Alerts Created, Edited and Deleted                 */
/* -------------------------------------------------------------------------- */
const getSuccessMessage = (genderType: string | undefined, action: string) => {
  switch (genderType) {
    case 'PluralFemale':
      return `common.${action}SuccessMessageMultipleAlt`;
    case 'PluralMale':
      return `common.${action}SuccessMessageMultiple`;
    case 'SingularFemale':
      return `common.${action}SuccessMessageAlt`;
    case 'SingularMale':
      return `common.${action}SuccessMessage`;
    default:
      return 'common.changesSuccess';
  }
};

export const showSuccessCreatedMessage = (config: AlertsConfig) => {
  const messageId = getSuccessMessage(config.genderType, 'created');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: 3000,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessEditedMessage = (config: AlertsConfig) => {
  const messageId = getSuccessMessage(config.genderType, 'edited');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: config.successTimer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessCustomMessage = (config: AlertsConfig) => {
  Swal.fire({
    icon: 'success',
    text: `${config.customMessage}`,
    timer: config.successTimer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessDeletedMessage = (config: AlertsConfig) => {
  const messageId = getSuccessMessage(config.genderType, 'deleted');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: config.successTimer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

export const showSuccessLeavedMessage = (config: AlertsConfig) => {
  const messageId = getSuccessMessage(config.genderType, 'leaved');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'success',
    text: `${message}`,
    timer: config.successTimer,
    timerProgressBar: true,
    showConfirmButton: false,
    position: 'top-end',
    toast: true,
    didOpen(toast) {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
    customClass: {
      confirmButton: 'btn btn-primary',
      content: 'mr-1 ml-1'
      // icon: "success-alert-icon",
    },
    iconColor: '#2ed573',
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                  Error Alerts Created, Edited And Deleted                  */
/* -------------------------------------------------------------------------- */
const getFailMessage = (genderType: string | undefined, action: string) => {
  switch (genderType) {
    case 'PluralFemale':
      return `common.${action}FailMessageMultipleAlt`;
    case 'PluralMale':
      return `common.${action}FailMessageMultiple`;
    case 'SingularFemale':
      return `common.${action}FailMessageAlt`;
    case 'SingularMale':
      return `common.${action}FailMessage`;
    default:
      return 'common.changesFail';
  }
};

export const showFailCreatedMessage = (config: AlertsConfig) => {
  const messageId = getFailMessage(config.genderType, 'created');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: config.errorTimer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showFailEditedMessage = (config: AlertsConfig) => {
  const messageId = getFailMessage(config.genderType, 'edited');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: config.errorTimer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showFailDeletedMessage = (config: AlertsConfig) => {
  const messageId = getFailMessage(config.genderType, 'deleted');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: config.errorTimer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

export const showFailLeavedMessage = (config: AlertsConfig) => {
  const messageId = getFailMessage(config.genderType, 'leaved');

  const message =
    config.success == null
      ? appIntl().formatMessage(
          {
            id: messageId
          },
          { object: config.object, name: config.name }
        )
      : config.success;

  Swal.fire({
    icon: 'error',
    iconColor: '#FF4757',
    text: `${message}`,
    timer: config.errorTimer,
    timerProgressBar: true,
    showConfirmButton: true,
    customClass: {
      confirmButton: 'btn btn-primary'
    },
    buttonsStyling: false
  });
};

/* -------------------------------------------------------------------------- */
/*                      Delete Confirmation Message Alert                     */
/* -------------------------------------------------------------------------- */
export const showDeleteConfirmationMessage = ({ action, objectType, objectName }) => {
  Swal.fire({
    icon: 'warning',
    title: appIntl().formatMessage(
      {
        id: 'common.deleteConfirmationMessage'
      },
      { objectType, objectName }
    ),
    text: appIntl().formatMessage({ id: 'common.deleteWarning' }),
    showConfirmButton: true,

    showCancelButton: true,
    customClass: {
      cancelButton: 'btn btn-primary',
      confirmButton: 'btn btn-outline-danger mr-1'
    },
    iconColor: '#FF4757',
    buttonsStyling: false,
    confirmButtonText: appIntl().formatMessage({
      id: 'common.confirmDelete'
    }),
    cancelButtonText: appIntl().formatMessage({ id: 'common.cancel' }),
    focusConfirm: false,
    focusCancel: true
  }).then((result) => {
    if (result.isConfirmed) {
      action();
    }
  });
};

/* -------------------------------------------------------------------------- */
/*                                LOADING Alert                               */
/* -------------------------------------------------------------------------- */
export const showLoading = () => {
  Swal.fire({
    title: appIntl().formatMessage({ id: 'common.loading' }),
    customClass: {
      loader: 'custom-loader'
    },
    loaderHtml: '<div className="spinner-border text-primary" size="lg"></div>',
    didOpen: () => {
      Swal.showLoading();
    }
  });
};

export const closeLoading = () => {
  Swal.close();
};
