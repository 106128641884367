import { LatLngExpression, Map } from 'leaflet';
import 'leaflet-draw';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { MapContainer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import * as authSelectors from '../../../redux/reducers/auth';
import * as unitActions from '../../../views/screens/Units/actions';
import * as geofenceSelectors from '../../screens/Geofences/reducers';
import * as locationSelectors from '../../screens/Locations/reducers';
import * as routeSelectors from '../../screens/Routes/reducers/routes';
import * as unitSelectors from '../../screens/Units/reducers';
import MapBaseLayers, { MapBaseLayersOptions } from './components/MapBaseLayers';
import MapGeofenceEdit, { MapGeofenceEditOptions } from './components/MapGeofenceEdit';
import MapGeofences, { MapGeofencesOptions } from './components/MapGeofences';
import MapLocationEdit, { MapLocationEditOptions } from './components/MapLocationEdit';
import MapLocations, { MapLocationsOptions } from './components/MapLocations';
import MapObjectsBounds, { MapObjectsBoundsOptions } from './components/MapObjectsBounds';
import MapRouteEdit from './components/MapRouteEdit';
import MapRoutes, { MapRoutesOptions } from './components/MapRoutes';
import MapUnits, { MapUnitsOptions } from './components/MapUnits';
import RulerControl, { RulerControlOptions } from './components/RulerControl';
import ToolbarGeofencesControl, {
  GeofencesToolbarOptions
} from './components/ToolbarGeofencesControl';
import ToolbarHomeControl, { HomeToolbarOptions } from './components/ToolbarHomeControl';
import ToolbarLocationsControl, {
  LocationsToolbarOptions
} from './components/ToolbarLocationsControl';
import ToolbarRoutesControl, { RoutesToolbarOptions } from './components/ToolbarRoutesControl';
import ToolbarUnitsControl, { UnitsToolbarOptions } from './components/ToolbarUnitsControl';
import { zoomLevelsAsKM } from 'utility/constants/leafletZoomLevels';

interface MapProps {
  mapBaseLayersOptions: MapBaseLayersOptions;
  mapOptions: MapOptions;
  homeToolbarOptions: HomeToolbarOptions;
  locationsToolbarOptions: LocationsToolbarOptions;
  geofencesToolbarOptions: GeofencesToolbarOptions;
  routesToolbarOptions: RoutesToolbarOptions;
  unitsToolbarOptions: UnitsToolbarOptions;
  rulerControlOptions: RulerControlOptions;
  mapLocationsOptionsToolbar: MapLocationsOptions;
  mapLocationsOptions: MapLocationsOptions;
  mapLocationEditOptions: MapLocationEditOptions;
  mapGeofencesOptionsToolbar: MapGeofencesOptions;
  mapGeofencesOptions: MapGeofencesOptions;
  mapObjectsBoundsOptions: MapObjectsBoundsOptions;
  mapGeofenceEditOptions: MapGeofenceEditOptions;
  mapRoutesOptionsToolbar: MapRoutesOptions;
  mapRoutesOptions: MapRoutesOptions;
  mapUnitsOptionsToolbar: MapUnitsOptions;
  mapUnitsOptions: MapUnitsOptions;
  startClusteringAt?: number;
}

interface MapOptions {
  center?: LatLngExpression;
  zoom?: number;
  style?: React.CSSProperties;
  className: string;
  rerenderMap?: Date | React.Key;
}

const MapsBasic = (props: MapProps) => {
  const zoomLevelToStartClustering = useSelector(
    (state) => authSelectors.getAuthUser(state).zoomLevelToStartClustering
  );

  const {
    mapBaseLayersOptions,
    mapOptions,
    homeToolbarOptions,
    locationsToolbarOptions,
    geofencesToolbarOptions,
    routesToolbarOptions,
    unitsToolbarOptions,
    rulerControlOptions,
    mapLocationsOptionsToolbar,
    mapLocationsOptions,
    mapLocationEditOptions,
    mapGeofencesOptionsToolbar,
    mapGeofencesOptions,
    mapGeofenceEditOptions,
    mapRoutesOptionsToolbar,
    mapRoutesOptions,
    mapUnitsOptionsToolbar,
    mapUnitsOptions,
    mapObjectsBoundsOptions,
    startClusteringAt = zoomLevelToStartClustering ?? 9 //Nivel de zoom determinado
  } = props;
  const { style, className, center, zoom, rerenderMap } = mapOptions;

  const history = useHistory();
  const dispatch = useDispatch();

  const [map, setMap] = useState<Map | undefined>();
  const intl = useIntl();
  const [isSelectedSomething, setIsSelectedSomething] = useState<any>({});
  const [listenMapControlChanges, setMapControlChanges] = useState<
    undefined | (boolean | string)[]
  >(undefined);
  const [listenMapControlChangesLayers, setMapControlChangesLayers] = useState<
    undefined | (string | boolean)[]
  >(undefined);
  const [showLocations, setShowLocations] = useState(false);
  const [showGeofences, setShowGeofences] = useState(false);
  const [showRoutes, setShowRoutes] = useState(false);
  const [showUnits, setShowUnits] = useState(unitsToolbarOptions.showUnits === true);

  const { store, locations, geofences, units, routes } = useSelector((state) => ({
    store: state,
    locations: locationSelectors.getLocationsList(state),
    geofences: geofenceSelectors.getGeofencesList(state),
    units: unitSelectors.getUnitsList(state),
    routes: routeSelectors.getRoutesList(state)
  }));

  const initialMapCenter: LatLngExpression = center ||
    authSelectors.getAuthUserMapHomeCenter(store) || [51.5074, 0.1278];
  const initialZoom = zoom || authSelectors.getAuthUserMapHomeZoom(store) || 13;
  const [mapPosition, setMapPosition] = useState<LatLngExpression>(initialMapCenter);
  useEffect(() => {
    setMapControlChanges([
      // @ts-ignore MapQuest
      typeof MQ,
      showLocations,
      showGeofences,
      showRoutes,
      showUnits,
      intl.locale
    ]);
  }, [showLocations, showGeofences, showRoutes, showUnits, intl.locale]);

  useEffect(() => {
    // @ts-ignore MapQuest
    setMapControlChangesLayers([typeof MQ, intl.locale]);
  }, [intl.locale]);

  //Used For Editing Location
  const [editingLocation, setEditingLocation] = useState<any>(undefined);
  const [mapLocationEditOptionsToolbar, setMapLocationEditOptionsToolbar] =
    useState<any>(undefined);

  const editingLocationRef = useRef<any>(editingLocation);

  useEffect(() => {
    editingLocationRef.current = editingLocation;
    setMapLocationEditOptionsToolbar({
      location:
        editingLocationRef?.current &&
        editingLocationRef?.current.latitude != null &&
        editingLocationRef?.current.longitude != null
          ? editingLocationRef?.current
          : {
              latitude:
                editingLocationRef?.current && editingLocationRef?.current.latitude != null
                  ? editingLocationRef?.current.latitude
                  : initialMapCenter[0],
              longitude:
                editingLocationRef?.current && editingLocationRef?.current.longitude != null
                  ? editingLocationRef?.current.longitude
                  : initialMapCenter[1],
              radius:
                editingLocationRef?.current && editingLocationRef?.current.radius
                  ? editingLocationRef?.current.radius
                  : 10,
              locationClassIconName:
                editingLocationRef?.current && editingLocationRef?.current.locationClassIconName
                  ? editingLocationRef?.current.locationClassIconName
                  : 'Marker1'
            },
      onLocationDragend: (values) => {
        if (setEditingLocation)
          setEditingLocation({
            ...editingLocationRef?.current,
            longitude: values.lng,
            latitude: values.lat
          });
      },
      showLocation: editingLocationRef && editingLocationRef.current ? true : false,
      hideMarkersByZoom: false,
      useRectangleBounds: true,
      isMapFullScreen: true,
      usePopovers: true
    });
  }, [editingLocation]);

  //Used For Editing Geofence
  const [editingGeofence, setEditingGeofence] = useState<any>(undefined);
  const [mapGeofenceEditOptionsToolbar, setMapGeofenceEditOptionsToolbar] =
    useState<any>(undefined);
  const editingGeofenceRef = useRef(editingGeofence);

  useEffect(() => {
    editingGeofenceRef.current = editingGeofence;
    setMapGeofenceEditOptionsToolbar({
      geofence: editingGeofenceRef?.current ? editingGeofenceRef?.current : {},
      onGeofenceEdit: (values) => {
        if (setEditingGeofence)
          setEditingGeofence({
            ...editingGeofenceRef?.current,
            ...values
          });
      },
      showGeofence: editingGeofenceRef && editingGeofenceRef.current,

      isMapFullScreen: true,
      usePopovers: true
    });
  }, [editingGeofence]);

  //Used For Editing Route
  const [editingRoute, setEditingRoute] = useState<any>(undefined);
  const editingRouteRef = useRef(editingRoute);

  useEffect(() => {
    editingRouteRef.current = editingRoute;
  }, [editingRoute]);

  //Used For Editing Unit
  const [selectedUnit, setSelectedUnit] = useState<any>(undefined);
  const selectedUnitRef = useRef(selectedUnit);

  useEffect(() => {
    //al cambiar unidad también vamos a limpiar el history para evitar que
    //se cargue la unidad que esta como parametro en el estado de history
    if (
      selectedUnit &&
      selectedUnit?.unitId &&
      selectedUnitRef?.current &&
      selectedUnitRef?.current?.unitId != selectedUnit?.unitId
    ) {
      // @ts-ignore reset history
      history.replace();
    }

    if (selectedUnit && selectedUnit?.unitId)
      dispatch(unitActions.showUnitInMap(selectedUnit?.unitId));

    selectedUnitRef.current = selectedUnit;
  }, [selectedUnit]);

  //Used to show Control Show All Units
  useEffect(() => {
    setShowUnits(unitsToolbarOptions.showUnits === true);
  }, [unitsToolbarOptions.showUnits]);

  /* -------------------------------------------------------------------------- */
  /*                           Map Bounds and rerender                          */
  /* -------------------------------------------------------------------------- */
  useDeepCompareEffect(() => {
    if (
      !mapObjectsBoundsOptions.setMapPositionToGeofenceBounds &&
      !mapObjectsBoundsOptions.setMapPositionToLocationBounds
    ) {
      if (center) setMapPosition(center);
    }
  }, [center || []]);

  useEffect(() => {
    if (map != null) {
      setTimeout(function () {
        map.invalidateSize(true);
      }, 100);
    }
  }, [map, rerenderMap]);

  useEffect(() => {
    // @ts-ignore locale does return lang
    if (map) map.pm.setLang(intl.locale);
  }, [map, intl]);

  return (
    <div>
      <MapContainer
        center={mapPosition}
        preferCanvas={true}
        zoom={initialZoom}
        className={className}
        style={{ ...style }}
        whenCreated={(leafletMap) => {
          setMap(leafletMap);
        }}
      >
        <MapBaseLayers
          {...mapBaseLayersOptions}
          listenMapControlChanges={listenMapControlChangesLayers}
        />

        {/* MapLocations */}
        {showLocations && mapLocationsOptionsToolbar.useIsShownLocation && (
          <MapLocations
            isSelectedSomething={isSelectedSomething}
            setIsSelectedSomething={setIsSelectedSomething}
            {...mapLocationsOptionsToolbar}
            showLocations={showLocations}
            useSlidingpanelLocationInformation={true}
            locations={locations}
            setEditingLocationParent={setEditingLocation}
            editingLocationParent={editingLocation}
            editingLocationParentRef={editingLocationRef}
            startClusteringAt={startClusteringAt}
          />
        )}

        {mapLocationsOptions.showLocations && <MapLocations {...mapLocationsOptions} />}
        {mapLocationEditOptionsToolbar?.showLocation && (
          <MapLocationEdit {...mapLocationEditOptionsToolbar} />
        )}
        {mapLocationEditOptions.showLocation && <MapLocationEdit {...mapLocationEditOptions} />}

        {/* Geofences */}
        {(showGeofences || mapGeofencesOptionsToolbar.useIsShownGeofence) && (
          <MapGeofences
            isSelectedSomething={isSelectedSomething}
            setIsSelectedSomething={setIsSelectedSomething}
            {...mapGeofencesOptionsToolbar}
            showGeofences={showGeofences}
            useSlidingpanelGeofenceInformation={true}
            geofences={geofences}
            setMapPositionToGeofenceBounds={false}
            setEditingGeofenceParent={setEditingGeofence}
            editingGeofenceParent={editingGeofence}
            editingGeofenceParentRef={editingGeofenceRef}
            startClusteringAt={startClusteringAt}
          />
        )}
        {mapGeofencesOptions.showGeofences && <MapGeofences {...mapGeofencesOptions} />}
        {mapGeofenceEditOptionsToolbar?.showGeofence && (
          <MapGeofenceEdit {...mapGeofenceEditOptionsToolbar} />
        )}
        {mapGeofenceEditOptions.showGeofence && <MapGeofenceEdit {...mapGeofenceEditOptions} />}

        {/* MapRoutes */}
        {(showRoutes || mapRoutesOptionsToolbar.useIsShownRoute) && (
          <MapRoutes
            isSelectedSomething={isSelectedSomething}
            setIsSelectedSomething={setIsSelectedSomething}
            {...mapRoutesOptionsToolbar}
            showRoutes={showRoutes}
            useSlidingPaneRouteInformation={true}
            routes={routes}
            setEditingRouteParent={setEditingRoute}
            editingRouteParent={editingRoute}
          />
        )}

        {mapRoutesOptions.showRoutes && <MapRoutes {...mapRoutesOptions} />}
        {editingRoute?.showRoute && (
          <MapRouteEdit setEditingRouteParent={setEditingRoute} {...editingRoute} />
        )}

        {/* MapUnits */}
        {(showUnits || mapUnitsOptionsToolbar.useIsShownUnit) && (
          <MapUnits
            isSelectedSomething={isSelectedSomething}
            setIsSelectedSomething={setIsSelectedSomething}
            {...mapUnitsOptionsToolbar}
            showUnits={showUnits}
            useSlidingpanelUnitInformation={true}
            units={units}
            setSelectedUnit={setSelectedUnit}
            selectedUnit={selectedUnit}
            selectedUnitRef={selectedUnitRef}
            startClusteringAt={startClusteringAt}
          />
        )}

        {mapUnitsOptions.showUnits && (
          <MapUnits
            {...mapUnitsOptions}
            setSelectedUnit={setSelectedUnit}
            selectedUnit={selectedUnit}
            selectedUnitRef={selectedUnitRef}
            startClusteringAt={startClusteringAt}
          />
        )}

        {/* Map Object Bounds */}
        <MapObjectsBounds isSelectedSomething={isSelectedSomething} {...mapObjectsBoundsOptions} />

        {/* Toolbars */}
        {homeToolbarOptions.useToolbar && (
          <ToolbarHomeControl
            {...homeToolbarOptions}
            listenMapControlChanges={listenMapControlChanges}
          />
        )}

        {authSelectors.getAuthUserHasPermissionsAny(store, ['LO008', 'LO001']) &&
          locationsToolbarOptions.useToolbar && (
            <ToolbarLocationsControl
              {...locationsToolbarOptions}
              showLocations={showLocations}
              setShowLocations={(value) => setShowLocations(value)}
              listenMapControlChanges={listenMapControlChanges}
            />
          )}

        {authSelectors.getAuthUserHasPermissionsAny(store, ['GF001', 'GF002']) &&
          geofencesToolbarOptions.useToolbar && (
            <ToolbarGeofencesControl
              {...geofencesToolbarOptions}
              showGeofences={showGeofences}
              setShowGeofences={(value) => setShowGeofences(value)}
              listenMapControlChanges={listenMapControlChanges}
            />
          )}

        {authSelectors.getAuthUserHasPermissionsAny(store, ['UP025']) &&
          unitsToolbarOptions.useToolbar && (
            <ToolbarUnitsControl
              {...unitsToolbarOptions}
              showUnits={showUnits}
              setSelectedUnit={setSelectedUnit}
              setShowUnits={(value) => setShowUnits(value)}
              listenMapControlChanges={listenMapControlChanges}
            />
          )}

        {authSelectors.getAuthUserHasPermissionsAny(store, ['RL001']) &&
          routesToolbarOptions.useToolbar && (
            <ToolbarRoutesControl
              {...routesToolbarOptions}
              showRoutes={showRoutes}
              setEditingRouteParent={setEditingRoute}
              setShowRoutes={(value) => setShowRoutes(value)}
              listenMapControlChanges={listenMapControlChanges}
            />
          )}
        {rulerControlOptions.useControl && (
          <RulerControl
            {...rulerControlOptions}
            listenMapControlChanges={listenMapControlChanges}
          />
        )}
      </MapContainer>
    </div>
  );
};

MapsBasic.defaultProps = {
  mapOptions: {
    center: [51.5074, 0.1278],
    zoom: 13,
    style: {},
    className: '',
    rerenderMap: null
  },

  homeToolbarOptions: ToolbarHomeControl.defaultProps,
  locationsToolbarOptions: ToolbarLocationsControl.defaultProps,
  geofencesToolbarOptions: ToolbarGeofencesControl.defaultProps,
  routesToolbarOptions: ToolbarRoutesControl.defaultProps,
  unitsToolbarOptions: ToolbarUnitsControl.defaultProps,
  rulerControlOptions: RulerControl.defaultProps,
  mapBaseLayersOptions: MapBaseLayers.defaultProps,
  mapLocationsOptionsToolbar: MapLocations.defaultProps,
  mapLocationsOptions: MapLocations.defaultProps,
  mapLocationEditOptions: MapLocationEdit.defaultProps,
  mapGeofencesOptionsToolbar: MapGeofences.defaultProps,
  mapGeofencesOptions: MapGeofences.defaultProps,
  mapGeofenceEditOptions: MapGeofenceEdit.defaultProps,
  mapRoutesOptionsToolbar: MapRoutes.defaultProps,
  mapRoutesOptions: MapRoutes.defaultProps,
  mapRouteEditOptions: MapRouteEdit.defaultProps,
  mapUnitsOptionsToolbar: MapUnits.defaultProps,
  mapUnitsOptions: MapUnits.defaultProps,
  mapObjectsBoundsOptions: MapObjectsBounds.defaultProps
};
export default MapsBasic;
