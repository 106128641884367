// @ts-nocheck
import { useEffect, useState } from 'react';
import L from 'leaflet';
import { useSelector } from 'react-redux';
import { useLeafletContext } from '@react-leaflet/core';
import 'leaflet.polylinemeasure';
import { useIntl } from 'react-intl';
import ReactDOMServer from 'react-dom/server';

import { ArrowRight, X } from 'react-feather';

export interface RulerControlOptions {
  useControl: boolean;
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  listenMapControlChanges?: any[];
}

/* -------------------------------------------------------------------------- */
/*                       RulerControl to Measure in map                       */
/* -------------------------------------------------------------------------- */
const RulerControl = (props: RulerControlOptions) => {
  const { position = 'bottomleft', listenMapControlChanges } = props;
  //https://github.com/ppete2/Leaflet.PolylineMeasure
  const context = useLeafletContext();
  const intl = useIntl();
  useEffect(() => {
    const container = context.layerContainer || context.map;
    var pmOptions = {
      position: position,
      tooltipTextDraganddelete: intl.formatMessage({
        id: 'map.ruler.tooltipTextDraganddelete'
      }),
      tooltipTextResume: intl.formatMessage({ id: 'map.ruler.tooltipTextResume' }),
      tooltipTextAdd: intl.formatMessage({ id: 'map.ruler.tooltipTextAdd' }),
      tooltipTextFinish: intl.formatMessage({ id: 'map.ruler.tooltipTextFinish' }),
      tooltipTextDelete: intl.formatMessage({ id: 'map.ruler.tooltipTextDelete' }),
      tooltipTextMove: intl.formatMessage({ id: 'map.ruler.tooltipTextMove' }),

      measureControlClasses: [],
      measureControlLabel: ReactDOMServer.renderToStaticMarkup(
        <>
          <ArrowRight size={14} />
        </>
      ),
      clearControlLabel: ReactDOMServer.renderToStaticMarkup(
        <>
          <X size={14} />
        </>
      ),
      measureControlTitleOn: intl.formatMessage({ id: 'map.ruler.measureControlTitleOn' }),
      measureControlTitleOff: intl.formatMessage({ id: 'map.ruler.measureControlTitleOff' }),
      showClearControl: true, // Show a control to clear all the measurements
      clearControlTitle: intl.formatMessage({ id: 'map.ruler.clearControlTitle' }),
      showUnitControl: true, // Show a control to change the units of measurements
      unitControlTitle: {
        // Title texts to show on the Unit Control button
        text: intl.formatMessage({ id: 'map.ruler.unitControlTitle.text' }),
        metres: intl.formatMessage({ id: 'map.ruler.unitControlTitle.metres' }),
        landmiles: intl.formatMessage({ id: 'map.ruler.unitControlTitle.landmiles' }),
        nauticalmiles: intl.formatMessage({ id: 'map.ruler.unitControlTitle.nauticalmiles' })
      }
    };
    const control = L.control.polylineMeasure(pmOptions);

    container.addControl(control);

    return () => {
      container.removeControl(control);
    };
  }, [...listenMapControlChanges]);

  return null;
};

RulerControl.defaultProps = {
  position: 'bottomleft',
  useControl: true,
  listenMapControlChanges: []
};

export default RulerControl;
