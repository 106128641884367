/* -------------------------------------------------------------------------- */
/*                         Componente CustomersDropdown                       */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownInput que permite elegir un cliente y abrir un slide para crear nuevos clientes

import { useState } from 'react';
import { Edit2, Plus } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { InputGroupText, UncontrolledTooltip } from 'reactstrap';
import SlidingPaneAddEditCustomer from 'views/formComponents/SlidingPaneAddEditCustomer';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import * as customerSelectors from '../../screens/Customers/reducers';
import DropdownInput from '../DropdownInput';

const CustomersDropdown = (props) => {
  const {
    appendAddCustomer = true,
    enableSelectLocation = false,
    isClearable = false,
    customersFilters,
    notAssigned = false,
    locationId,
    field,
    ...dropdownProps
  } = props;
  const intl = useIntl();
  const { customers } = useSelector((state) => ({
    customers: notAssigned
      ? customerSelectors.getNotAssignedCustomersList(state, locationId)
      : customerSelectors.getCustomersList(state)
  }));
  const [isOpenShiftPane, setOpenShiftPane] = useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(undefined);
  const closeShiftPane = () => setOpenShiftPane(false);
  const id_dropdwon = appendAddCustomer ?  'In_SP' : 'Out_SP'
  return (
    <>
      <FetchEntities useCustomers customersFilters={customersFilters} />
      <DropdownInput
        {...props}
        options={
          customers.map((customer) => ({
            value: customer.customerId,
            label: customer.customerName
          })) || []
        }
        id = {'customerDropdown' + id_dropdwon}
        label={intl.formatMessage({ id: 'customers.customer' })}
        placeholder={intl.formatMessage({ id: 'customers.selectCustomer' })}
        appendcomponent={
          appendAddCustomer ? (
            <>
              {field.value && (
                <InputGroupText
                  id={'editCustomer'}
                  style={{ cursor: 'pointer' }}
                  className={'m-0'}
                  onClick={() => {
                    setSelectedCustomer({ customerId: field.value });
                    setOpenShiftPane(true);
                  }}
                >
                  <UncontrolledTooltip
                    placement="top"
                    target={'editCustomer'}
                    trigger="click hover"
                  >
                    <FormattedMessage
                      id={'buttons.edit'}
                      values={{ objectType: intl.formatMessage({ id: 'customers.customer' }) }}
                    />
                  </UncontrolledTooltip>
                  <Edit2 size={15} />
                </InputGroupText>
              )}
              <InputGroupText
                id={'addCustomer'}
                style={{ cursor: 'pointer' }}
                className={'m-0'}
                onClick={() => {
                  setSelectedCustomer(undefined);
                  setOpenShiftPane(true);
                }}
              >
                <UncontrolledTooltip placement="top" target={'addCustomer'} trigger="click hover">
                  <FormattedMessage
                    id={'buttons.add'}
                    values={{ objectType: intl.formatMessage({ id: 'customers.customer' }) }}
                  />
                </UncontrolledTooltip>
                <Plus size={15} />
              </InputGroupText>
            </>
          ) : (
            <></>
          )
        }
      />
      <SlidingPaneAddEditCustomer
        customer={selectedCustomer}
        enableSelectLocation={enableSelectLocation}
        isOpenSlidingPane={isOpenShiftPane}
        closeSlidingPaneAddEditCustomer={() => {
          setSelectedCustomer(undefined);
          closeShiftPane();
        }}
      />
    </>
  );
};

export default CustomersDropdown;
