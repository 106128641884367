import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import union from 'lodash/union';
import uniq from 'lodash/uniq';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as operatorSelectors from 'views/screens/Operators/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import unitLiveStatus, * as selectorsUnitLiveStatus from './unitLiveStatus';
import deviceMetrics from './deviceMetrics';
import deviceActions from './deviceActions';
import groups from './groups';
import reports from './reports';
import images from './images';
import sensors from './sensors';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';
import { areNotNullOrUndefined } from 'utility/Utils';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.UNITS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[unitId],
          ...entities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.UNITS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[unitId],
          ...entities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.UNIT_ADD_COMPLETED: {
      const { oldId, unit } = action.payload;
      const newState = omit(state, oldId);
      newState[unit.unitId] = {
        isShownInMap: false,
        ...state[oldId],
        ...unit,
        isConfirmed: true
      };
      return newState;
    }

    case types.UNITS_ACTIVE_COMPLETED: {
      const units = action.payload;
      const newState = { ...state };
      units.forEach((unit) => {
        newState[unit.unitId] = {
          isShownInMap: false,
          isSelected: false,
          ...unit,
          isConfirmed: false
        };
      });
      return newState;
    }

    case types.UNIT_EDIT_COMPLETED: {
      const unit = action.payload;
      return {
        ...state,
        [unit.unitId]: {
          isShownInMap: false,
          ...omit(state[unit.unitId], ['oldUnit']),
          ...omit(unit, ['oldUnit']),
          isConfirmed: true
        }
      };
    }

    case types.UNITS_ASSIGN_DRIVER_COMPLETED: {
      const units = action.payload;
      const newState = { ...state };
      units.forEach((unit) => {
        newState[unit.unitId] = {
          ...state[unit.unitId],
          driverId: unit.driverId
        };
      });
      return newState;
    }

    case types.UNIT_START_DOWNTIME_COMPLETED: {
      const { unitId, reasonId, reasonDescription } = action.payload;
      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          downtimeReasonId: reasonId,
          downtimeReasonDescription: reasonDescription
        }
      };
    }

    case types.UNIT_END_DOWNTIME_COMPLETED: {
      const { unitId, mainStateId } = action.payload;
      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          downtimeReasonId: null,
          downtimeReasonDescription: null
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.UNIT_ADD_TO_STATE: {
      const unit = action.payload;
      const newState = { ...state };
      newState[unit.unitId] = {
        isShownInMap: false,
        isSelected: false,
        ...newState[unit.unitId],
        ...unit,
        isConfirmed: true
      };
      return newState;
    }
    case types.UNIT_ADD_STARTED: {
      const unit = action.payload;
      const newState = { ...state };
      newState[unit.unitId] = {
        isShownInMap: false,
        isSelected: false,
        ...unit,
        isConfirmed: false
      };
      return newState;
    }
    case types.UNIT_EDIT_STARTED: {
      const unit = action.payload;
      return {
        ...state,
        [unit.unitId]: {
          isShownInMap: false,
          ...state[unit.unitId],
          oldUnit: state[unit.unitId],
          ...unit,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.UNIT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.UNIT_EDIT_FAILED: {
      const { oldUnit } = action.payload;
      return {
        ...state,
        [oldUnit.unitId]: {
          isShownInMap: false,
          ...omit(state[oldUnit.unitId], ['oldUnit']),
          ...oldUnit,
          isConfirmed: true
        }
      };
    }

    // EDIT DEVICE
    case types.UNIT_UPDATE_DEVICE_STARTED: {
      const { unitId } = action.payload;

      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          oldUnit: state[unitId],
          isConfirmed: false
        }
      };
    }

    case types.UNIT_UPDATE_DEVICE_COMPLETED: {
      const { unitId, deviceId, deviceModelName, deviceIdentifier, brandName } = action.payload;

      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          deviceModelName: deviceModelName,
          deviceIdentifier: deviceIdentifier,
          brandName: brandName,
          deviceId,
          isConfirmed: true
        }
      };
    }

    case types.UNIT_UPDATE_DEVICE_FAILED: {
      const { oldUnit } = action.payload;
      return {
        ...state,
        [oldUnit.unitId]: {
          isShownInMap: false,
          ...omit(state[oldUnit.unitId], ['oldUnit']),
          ...oldUnit,
          isConfirmed: true
        }
      };
    }

    // update sim
    case types.UNIT_UPDATE_SIM_STARTED: {
      const { unitId, simId } = action.payload;

      const newUnit: types.ReduxUnit = {
        ...state[unitId],
        oldUnit: state[unitId],
        simCardId: simId,
        isConfirmed: false
      };

      return {
        ...state,
        [unitId]: newUnit
      };
    }

    case types.UNIT_UPDATE_SIM_COMPLETED: {
      const changes = action.payload?.changes;

      const unitId = changes?.unitId ?? null;
      const unitPublicId = changes?.unitPublicId ?? null;
      const simCardId = changes?.simCardId;
      const imsi = changes?.imsi ?? null;
      const cellularNumber = changes?.cellularNumber ?? null;
      const mobileOperatorId = changes?.mobileOperatorId ?? null;
      const mobileOperatorName = changes?.mobileOperatorName ?? null;

      if (unitId === null) {
        return {
          ...state
        };
      }

      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          unitPublicId,
          simCardId,
          imsi,
          cellularNumber,
          mobileOperatorId,
          mobileOperatorName,
          isConfirmed: true
        }
      };
    }

    case types.UNIT_UPDATE_SIM_FAILED: {
      const { oldUnit } = action.payload;
      return {
        ...state,
        [oldUnit.unitId]: {
          isShownInMap: false,
          ...omit(state[oldUnit.unitId], ['oldUnit']),
          ...oldUnit,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.UNIT_REMOVE_COMPLETED: {
      const unitBody = action.payload;
      const newState = { ...state };
      unitBody?.units?.forEach((unitId) => {
        newState[unitId] = {
          ...state[unitId],
          unitStatus: 0,
          unitStatusName: 'Desactivado'
        };
      });
      return newState;
    }

    //TYPES SHOW AND HIDE UNITS IN MAP
    case types.UNIT_SHOW_IN_MAP: {
      const unitId = action.payload;
      const newState = {
        ...state,
        [unitId]: {
          ...state[unitId],
          isShownInMap: true
        }
      };
      return newState;
    }

    case types.UNIT_HIDE_IN_MAP: {
      const unitId = action.payload;
      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          isShownInMap: false
        }
      };
    }

    case types.UNITS_ALL_SHOW_IN_MAP: {
      const unitIds = action.payload;
      const newState = { ...state };
      if (unitIds.length == 0) {
        forEach(state, (unit: any, unitId) => {
          newState[unitId] = {
            ...unit,
            isShownInMap: true
          };
        });
      } else {
        unitIds.forEach((unitId) => {
          newState[unitId] = {
            ...state[unitId],
            isShownInMap: true
          };
        });
      }

      return newState;
    }

    case types.UNITS_ALL_HIDE_IN_MAP: {
      const unitIds = action.payload;
      const newState = { ...state };
      if (unitIds.length == 0) {
        forEach(state, (unit: any, unitId) => {
          newState[unitId] = {
            ...unit,
            isShownInMap: false
          };
        });
      } else {
        unitIds.forEach((unitId) => {
          newState[unitId] = {
            ...state[unitId],
            isShownInMap: false
          };
        });
      }

      return newState;
    }

    case types.SET_DATE_RANGE: {
      const { unitId, dateRange } = action.payload;
      const newState = {
        ...state,
        [unitId]: {
          ...state[unitId],
          dateRange: dateRange
        }
      };
      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.UNIT_SELECTED: {
      const unitId = action.payload;
      const newState = {
        ...state,
        [unitId]: {
          ...state[unitId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.UNIT_DESELECTED: {
      const unitId = action.payload;
      return {
        ...state,
        [unitId]: {
          ...state[unitId],
          isSelected: false
        }
      };
    }

    case types.UNITS_ALL_SELECTED: {
      const unitIds = action.payload;
      const newState = { ...state };
      if (unitIds.length == 0) {
        forEach(state, (unit: any, unitId) => {
          newState[unitId] = {
            ...unit,
            isSelected: true
          };
        });
      } else {
        unitIds.forEach((unitId) => {
          newState[unitId] = {
            ...state[unitId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.UNITS_ALL_DESELECTED: {
      const unitIds = action.payload;
      const newState = { ...state };
      if (unitIds.length == 0) {
        forEach(state, (unit: any, unitId) => {
          newState[unitId] = {
            ...unit,
            isSelected: false
          };
        });
      } else {
        unitIds.forEach((unitId) => {
          newState[unitId] = {
            ...state[unitId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.UNITS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.UNITS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.UNIT_ADD_COMPLETED: {
      const { oldId, unit } = action.payload;
      return state
        .map((unitId) => (unitId === oldId ? unit.unitId : unitId))
        .filter((unitId) => unitId !== null);
    }

    case types.UNITS_ACTIVE_COMPLETED: {
      const units = action.payload;
      return [...state, ...units.map((unit) => unit.unitId)];
    }

    case types.UNIT_REMOVE_COMPLETED: {
      const unitBody = action.payload;
      //return state.filter((unitIdState) => unitBody.units.map((unitId) => unitIdState !== unitId));
      return state;
    }

    case types.UNIT_ADD_TO_STATE: {
      const unit = action.payload;
      return [...state.filter((unitId) => unitId !== unit.unitId), unit.unitId];
    }

    //CASE ADD STARTED
    case types.UNIT_ADD_STARTED: {
      const unit = action.payload;
      return [...state, unit.unitId];
    }

    //CASE ADD FAILED
    case types.UNIT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((unitIdState) => unitIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_FETCH_STARTED: {
      return true;
    }
    case types.UNITS_FETCH_COMPLETED:
    case types.UNITS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.UNITS_PARTIAL_FETCH_COMPLETED:
    case types.UNITS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_ADD_STARTED: {
      return true;
    }
    case types.UNIT_ADD_COMPLETED:
    case types.UNIT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingDevice = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_UPDATE_DEVICE_STARTED: {
      return true;
    }
    case types.UNIT_UPDATE_DEVICE_COMPLETED:
    case types.UNIT_UPDATE_DEVICE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_ACTIVE_STARTED:
    case types.UNITS_ASSIGN_DRIVER_STARTED:
    case types.UNIT_EDIT_STARTED: {
      return true;
    }
    case types.UNITS_ACTIVE_COMPLETED:
    case types.UNIT_EDIT_COMPLETED:
    case types.UNITS_ASSIGN_DRIVER_COMPLETED:
    case types.UNITS_ACTIVE_FAILED:
    case types.UNIT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditingDowntime = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_START_DOWNTIME_STARTED:
    case types.UNIT_END_DOWNTIME_STARTED: {
      return true;
    }
    case types.UNIT_START_DOWNTIME_COMPLETED:
    case types.UNIT_START_DOWNTIME_FAILED:
    case types.UNIT_END_DOWNTIME_COMPLETED:
    case types.UNIT_END_DOWNTIME_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_REMOVE_STARTED: {
      return true;
    }
    case types.UNIT_REMOVE_COMPLETED:
    case types.UNIT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCreatingSharedLink = (state = false, action) => {
  switch (action.type) {
    case types.UNIT_CREATE_SHARE_LINK_STARTED: {
      return true;
    }
    case types.UNIT_CREATE_SHARE_LINK_COMPLETED:
    case types.UNIT_CREATE_SHARE_LINK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const sharedLink = (state = '', action) => {
  switch (action.type) {
    case types.UNIT_CLEAR_SHARE_LINK:
    case types.UNIT_CREATE_SHARE_LINK_FAILED:
    case types.UNIT_CREATE_SHARE_LINK_STARTED: {
      return '';
    }
    case types.UNIT_CREATE_SHARE_LINK_COMPLETED: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const oldMainStateIds = (state = [], action) => {
  switch (action.type) {
    case types.UNIT_END_DOWNTIME_FAILED:
    case types.UNIT_END_DOWNTIME_STARTED: {
      return [];
    }
    case types.UNIT_END_DOWNTIME_COMPLETED: {
      return [
        {
          unitId: action.payload?.unitId,
          mainStateId: action.payload?.mainStateId,
          mainStateDate: action.payload?.mainStateDate
        }
      ];
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNITS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNITS_FETCH_STARTED:
    case types.UNITS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNITS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNITS_PARTIAL_FETCH_STARTED:
    case types.UNITS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_ADD_STARTED:
    case types.UNIT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorUpdatingDevice = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_UPDATE_DEVICE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_UPDATE_DEVICE_STARTED:
    case types.UNIT_UPDATE_DEVICE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_EDIT_STARTED:
    case types.UNIT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_REMOVE_STARTED:
    case types.UNIT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const units: any = combineReducers({
  byId,
  order,
  unitLiveStatus,
  deviceMetrics,
  deviceActions,
  groups,
  reports,
  images,
  sensors,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isEditingDowntime,
  isRemoving,
  isCreatingSharedLink,
  sharedLink,
  oldMainStateIds,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched,
  errorUpdatingDevice,
  isUpdatingDevice
});

const unitsPersistedConfig = {
  key: 'units',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'deviceMetrics',
    'deviceActions',
    'groups',
    'reports',
    'images',
    'sensors',
    'isPartialFetchingList',
    'isFetchingList',
    'isAdding',
    'isEditing',
    'isRemoving',
    'isCreatingSharedLink',
    'sharedLink',
    'errorPartialFetchingList',
    'errorFetchingList',
    'errorAdding',
    'errorEditing',
    'errorRemoving',
    'hasAlreadyFetched'
  ],
  whitelist: ['byId', 'order', 'unitLiveStatus']
  // blackList: [],
};
export default persistReducer(unitsPersistedConfig, units);

//Own state
export const getOwnState = (state) => state.units;

export const isCreatingSharedLinkUnit = (state) => getOwnState(state).isCreatingSharedLink;

export const sharedLinkUnit = (state) => getOwnState(state).sharedLink;

export const oldUnitMainStateIds = (state) => getOwnState(state).oldMainStateIds;

//Information
export const getUnit = (state, unitId): types.ReduxUnit | undefined => {
  const unit = getOwnState(state).byId[unitId];
  if (unit) {
    const operator = unit.operatorId
      ? operatorSelectors.getOperator(state, unit.operatorId)
      : undefined;
    const organization = unit.organizationId
      ? organizationSelectors.getOrganization(state, unit.organizationId)
      : undefined;
    const division = unit.divisionId
      ? divisionSelectors.getDivision(state, unit.divisionId)
      : undefined;
    const subdivision = unit.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, unit.subdivisionId)
      : undefined;
    const unitLiveStatus = unit.unitId ? getUnitLiveStatus(state, unit.unitId) : {};
    return {
      ...unit,
      ...unitLiveStatus,
      operatorName: operator ? operator.operatorName : '',
      organizationName: organization ? organization.organizationName : '',
      divisionName: division ? division.divisionName : '',
      subdivisionName: subdivision ? subdivision.subdivisionName : ''
    };
  } else return undefined;
};

export const getUnitsList = (state): types.ReduxUnit[] =>
  getOwnState(state).order.map((id) => getUnit(state, id));
export const getUnitsSpecificList = (state, list) => list.map((id) => getUnit(state, id));
export const getActiveUnitsList = (state) =>
  getOwnState(state)
    .order.map((id) => getUnit(state, id))
    .filter((unit) => unit.unitStatus > 0);
export const getActiveUnitsListByMembership = (state, membership) => {
  return getUnitsList(state).filter(
    (unit) =>
      membership &&
      (!membership.operatorId || membership.operatorId === unit.operatorId) &&
      (!membership.organizationId || membership.organizationId === unit.organizationId) &&
      (!membership.divisionId || membership.divisionId === unit.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === unit.subdivisionId) &&
      unit.unitStatus > 0
  );
};
export const getActiveUnitsListByPartialMembership = (state, membership: DefaultFilters) => {
  if (Object.values(membership).length < 0 || areNotNullOrUndefined(...Object.values(membership))) {
    return [];
  }

  const useOperator = !!membership.operatorId;
  const useOrganization = !!membership?.organizationId;
  const useDivision = !!membership.divisionId;
  const useSubdivision = !!membership.subdivisionId;

  const filterUnit = (unit: types.ReduxUnit) => {
    if (useOperator) {
      const validOrganization = unit.operatorId === membership.operatorId;
      if (!validOrganization) return false;
    }

    if (useOrganization) {
      const validOrganization = unit.organizationId === membership.organizationId;
      if (!validOrganization) return false;
    }

    if (useDivision) {
      const validDivision = unit.divisionId === membership.divisionId;
      if (!validDivision) return false;
    }

    if (useSubdivision) {
      const validSubdivision = unit.subdivisionId === membership.subdivisionId;
      if (!validSubdivision) return false;
    }

    const isActive = unit.unitStatus > 0;

    return isActive;
  };

  return getUnitsList(state).filter(filterUnit);
};
export const getSelectedUnits = (state) => {
  const selectedUnits = getUnitsList(state).filter((unit) => unit.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedUnits.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedUnits.length > 0) return selectedUnits;
};
export const getUnitByPrivateCode = (state: any, privateCode: string) => {
  const unit = getUnitsList(state).find((unit) => unit?.privateCode === privateCode);
  if (unit) {
    const operator = unit.operatorId
      ? operatorSelectors.getOperator(state, unit.operatorId)
      : undefined;
    const organization = unit.organizationId
      ? organizationSelectors.getOrganization(state, unit.organizationId)
      : undefined;
    const division = unit.divisionId
      ? divisionSelectors.getDivision(state, unit.divisionId)
      : undefined;
    const subdivision = unit.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, unit.subdivisionId)
      : undefined;
    const unitLiveStatus = unit.unitId ? getUnitLiveStatus(state, unit.unitId) : {};
    return {
      ...unit,
      ...unitLiveStatus,
      operatorName: operator ? operator.operatorName : '',
      organizationName: organization ? organization.organizationName : '',
      divisionName: division ? division.divisionName : '',
      subdivisionName: subdivision ? subdivision.subdivisionName : ''
    };
  } else return undefined;
};

export const getUnitByIdOrPrivateCode = (state: any, unitId: number, privateCode: string) => {
  if (unitId !== null && unitId !== undefined) {
    const unit = getUnit(state, unitId);
    if (unit) return { ...unit, privateCode: unit?.privateCode ?? '' };
  }

  if (
    privateCode !== null &&
    privateCode !== undefined &&
    privateCode !== '' &&
    !/^\s*$/.test(privateCode)
  ) {
    const unit = getUnitByPrivateCode(state, privateCode);
    if (unit) return { ...unit };
  }
};

export const getSelectedUnit = (state) => {
  const selectedUnits = getUnitsList(state).filter((unit) => unit.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedUnits.length === 1) return selectedUnits[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListUnits = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListUnits = (state) => getOwnState(state).isFetchingList;
export const haveUnitsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingUnit = (state) => getOwnState(state).isAdding;
export const isEditingUnit = (state) => getOwnState(state).isEditing;
export const isEditingUnitDowntime = (state) => getOwnState(state).isEditingDowntime;
export const isRemovingUnit = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListUnitsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListUnitsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingUnitErrors = (state) => getOwnState(state).errorAdding;
export const getEditingUnitErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingUnitErrors = (state) => getOwnState(state).errorRemoving;

/* -------------------------------------------------------------------------- */
/*                            UNIT LIVE STATUS                                */
/* -------------------------------------------------------------------------- */
//Information
export const getUnitLiveFetchDate = (state, organizationId) =>
  selectorsUnitLiveStatus.getFetchDate(state, organizationId);
export const getUnitLiveStatus = (state, unitId) => selectorsUnitLiveStatus.getUnit(state, unitId);
export const getUnitsLiveStatusList = (state) => selectorsUnitLiveStatus.getUnitsList(state);

//Status of sagas
export const isFetchingListUnitsLiveStatus = (state) =>
  selectorsUnitLiveStatus.isFetchingListUnitsLiveStatus(state);
export const isFetchingUnitInformation = (state) =>
  selectorsUnitLiveStatus.isFetchingUnitInformation(state);
export const isFetchingUnitPositions = (state) =>
  selectorsUnitLiveStatus.isFetchingUnitPositions(state);
export const isAcknowledgeUnitEvent = (state) =>
  selectorsUnitLiveStatus.isAcknowledgeUnitEvent(state);
export const isFetchedUnitPosition = (state, unitId) =>
  selectorsUnitLiveStatus.isFetchedUnitPosition(state, unitId);

//Errors
export const getFetchingListUnitsLiveStatusErrors = (state) =>
  selectorsUnitLiveStatus.getFetchingListUnitsLiveStatusErrors(state);
export const getFetchingUnitInformationErrors = (state) =>
  selectorsUnitLiveStatus.getFetchingUnitInformationErrors(state);
