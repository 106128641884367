import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';
import { appIntl } from 'utility/context/IntlGlobalProvider';

export const baseUrl = '/form';

interface FormFilters {
  userFetchDate?: Date;
}

export const getFormsListService = (request: FormFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'forms.form',
    action: 'Get',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getFormsSectionsListService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getSections`, request);
};

export const getFormsQuestionsListService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getQuestions`, request);
};

export const saveFormService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'forms.form',
    action: request.organizationFormId ? 'Edit' : 'Create',
    name: request.formName,
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const saveExternalFormService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'forms.form',
    action: request.organizationFormId ? 'Edit' : 'Create',
    name: request.formName,
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveExternal`, request);
};

export const saveResponseFormService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'forms.form',
    action: 'Custom',
    customMessage: intl.formatMessage({
      id: 'forms.fillFormMessage'
    }),
    customMessageError: intl.formatMessage({
      id: 'forms.fillFormErrorMessage'
    })
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveResponse`, request);
};

export const resendResponseFormService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'forms.form',
    action: 'Custom',
    customMessage: intl.formatMessage({
      id: 'forms.resendFormResponseMessage'
    }),
    customMessageError: intl.formatMessage({
      id: 'forms.resendFormResponseErrorMessage'
    })
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/resendResponse`, request);
};

export const deleteFormService = (request: any, configActions: ConfigActions, person: any) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.formName}`,
    object: person && person === true ? 'people.person' : 'forms.form',
    action: 'Delete',
    genderType: person && person === true ? 'SingularFemale' : 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};
