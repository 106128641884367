import { isEmpty } from 'lodash';
import Stepper from 'bs-stepper';
import { useRerender } from 'hooks/useRerender';
import { Key, createContext, useContext, useMemo, useState } from 'react';
import { AssignmentByDate } from './ReviewAssignmentByDateTab';
import { AssignmentBySchedule } from './ReviewAssignmentByScheduleTab';
import { AlreadyLeftTheOrigin, AssignmentTypeFormValues } from './SelectAssignmentTypeTab';
import { CommonValuesFormValues } from './SelectCommonValuesTab';
import { UnitsRoutesAssignment } from './SelectUnitsAndRoutesTab';
import { EditableAssignmentFieldsWithNames } from './SlidingPaneEditAssignmentByDate';
import { ValidationError, duplicatedUnitRouteAssignmentValidator } from '../../utils';
import { useIntl } from 'react-intl';
import { AssignedUnit } from '../../hooks/useGetAssignedUnits';
import { useSelector } from 'react-redux';
import * as schedulesSelectors from '../../../Schedules/reducers';

export interface ValidationFilter {
  unitId: number;
  routeId: number;
  correlative: number;
}

interface AssignmentContextValue {
  resetForms: Key;
  startResetForms(): void;
  stepper: Stepper | null;
  setStepper(stepper: Stepper | null): void;
  assignmentType: Partial<AssignmentTypeFormValues>;
  updateAssignmentType: (assignmentType: Partial<AssignmentTypeFormValues>) => void;
  commonValues: Partial<CommonValuesFormValues>;
  updateCommonValues: (commonValues: Partial<CommonValuesFormValues>) => void;
  revalidateUnitRouteAssignments(): void;
  assignedUnits: AssignedUnit[];
  updateAssignedUnits: (assignedUnits: AssignedUnit[]) => void;
  assignmentsByDate: Partial<AssignmentByDate>[];
  assignmentsBySchedule: Partial<AssignmentBySchedule>[];
  unitRouteAssignments: UnitsRoutesAssignment[];
  updateUnitRouteAssignments: (unitRouteAssignments: UnitsRoutesAssignment[]) => void;
  removeUnitRouteAssignment: (unitId: number, routeId: number) => void;
  resetAssignments(): void;
  removeAllAssignments(): void;
  removeAllCompletedAssignments(completedAssignments: Partial<UnitsRoutesAssignment>[]): void;
  syncAssignmentsByDate(): void;
  syncAssignmentsBySchedule(): void;
  partialUpdateSelectedAssignmentsByDate(
    partialChanges: Partial<EditableAssignmentFieldsWithNames>,
    selected: Partial<AssignmentByDate>[]
  ): void;
  partialUpdateAssignmentsByDate(partialChanges: Partial<AssignmentByDate>): void;
  partialUpdateSelectedAssignmentsBySchedule(
    partialChanges: Partial<EditableAssignmentFieldsWithNames>,
    selected: Partial<AssignmentBySchedule>[]
  ): void;
  partialUpdateAssignmentsBySchedule(partialChanges: Partial<AssignmentBySchedule>): void;
}

interface PartialUpdatedValues {
  [key: number]: any;
}

export const AssignmentContext = createContext<AssignmentContextValue | null>(null);

export const AssignmentProvider = ({ children }) => {
  const [stepper, changeStepper] = useState<Stepper | null>(null);
  const [assignmentType, setAssignmentType] = useState<Partial<AssignmentTypeFormValues>>({});
  const [commonValues, setCommonValues] = useState<Partial<CommonValuesFormValues>>({});
  const [assignedUnits, setAssignedUnits] = useState<AssignedUnit[]>([]);
  const [assignmentsByDate, setAssignmentsByDate] = useState<Partial<AssignmentByDate>[]>([]);
  const [assignmentsBySchedule, setAssignmentsBySchedule] = useState<
    Partial<AssignmentBySchedule>[]
  >([]);
  // For patch purpose on Step 4 (overwrite changes if user has already edited the assignment)
  const [partialUpdatedAssignmentsByDate, setPartialUpdatedAssignmentsByDate] =
    useState<PartialUpdatedValues>({});
  const [partialUpdatedAssignmentsBySchedule, setPartialUpdatedAssignmentsBySchedule] =
    useState<PartialUpdatedValues>({});

  const [unitRouteAssignments, setUnitRouteAssignments] = useState<UnitsRoutesAssignment[]>([]);

  const { rerender: resetForms, startRerender: startResetForms } = useRerender();
  const intl = useIntl();

  const { schedule } = useSelector((state) => ({
    schedule: schedulesSelectors.getSchedule(state, commonValues.scheduleId)
  }));

  const validators: ((...args: any[]) => ValidationError[])[] = useMemo(
    () => [
      duplicatedUnitRouteAssignmentValidator(assignedUnits, commonValues, assignmentType, schedule)
    ],
    [assignedUnits, commonValues, assignmentType, schedule]
  );

  const setStepper = (stepper: Stepper | null) => {
    changeStepper(stepper);
  };

  const updateAssignmentType = (assignmentType: Partial<AssignmentTypeFormValues>) => {
    setAssignmentType((prevState) => ({ ...prevState, ...assignmentType }));
  };

  const updateCommonValues = (commonValues: Partial<CommonValuesFormValues>) => {
    setCommonValues((prevState) => ({ ...prevState, ...commonValues }));
  };

  const revalidateUnitRouteAssignments = () => {
    const validatedItems: UnitsRoutesAssignment[] = validateAssignments(unitRouteAssignments);
    setUnitRouteAssignments(validatedItems);
  };

  const validateAssignments = (
    assignments:
      | UnitsRoutesAssignment[]
      | Partial<AssignmentByDate>[]
      | Partial<AssignmentBySchedule>[],
    validationFilters?: ValidationFilter
  ) =>
    assignments.map((assignment) => {
      let isValidationNeeded = !validationFilters || Object.keys(validationFilters).length === 0;
      for (const key in validationFilters) {
        if (validationFilters[key] !== undefined && assignment[key] === validationFilters[key]) {
          isValidationNeeded = true;
        }
      }

      if (isValidationNeeded) {
        let validationErrors: ValidationError[] = [];
        validators.forEach((validator) => {
          const validatorErrors = validator(assignments, assignment);
          if (validatorErrors) validationErrors.push(...validatorErrors);
        });
        return {
          ...assignment,
          validationErrors
        };
      }

      return assignment;
    });

  const updateAssignedUnits = (assignedUnits: AssignedUnit[]) => {
    const uniqueUnitIdValues = [...new Set(assignedUnits.map((item) => item.unitId))];
    setAssignedUnits((prevState) => {
      // Replace if updated info is received
      const oldStatus =
        prevState && prevState.length > 0
          ? prevState.filter((item) => !uniqueUnitIdValues.includes(item.unitId))
          : [];
      return [...oldStatus, ...assignedUnits];
    });
  };

  const updateUnitRouteAssignments = (providedAssignments: UnitsRoutesAssignment[]) => {
    const newAssignments = [...providedAssignments];

    const newData: UnitsRoutesAssignment[] = unitRouteAssignments.map((unitRouteAssignment) => {
      const newDataIdx = newAssignments.findIndex((newAssignment) => {
        return (
          newAssignment.unitId === unitRouteAssignment.unitId &&
          newAssignment.routeId === unitRouteAssignment.routeId
        );
      });

      if (newDataIdx > -1) {
        // get and remove item from newAssignments
        const newAssignment = newAssignments.splice(newDataIdx, 1)[0];
        // update unitRouteAssignment with newAssignment
        return newAssignment;
      }

      return unitRouteAssignment;
    });

    const newItems = [...newData, ...newAssignments];
    const validatedItems: UnitsRoutesAssignment[] = validateAssignments(newItems);
    setUnitRouteAssignments(validatedItems);
  };

  const removeUnitRouteAssignment = (unitId: number, routeId: number) => {
    const newData: UnitsRoutesAssignment[] = unitRouteAssignments.filter(
      (unitRouteAssignment) =>
        unitRouteAssignment.unitId !== unitId || unitRouteAssignment.routeId !== routeId
    );
    const validatedItems: UnitsRoutesAssignment[] = validateAssignments(newData);
    setUnitRouteAssignments(validatedItems);
  };

  const resetAssignments = () => {
    setAssignmentsByDate([]);
    setAssignmentsBySchedule([]);
  };

  const removeAllAssignments = () => {
    resetAssignments();
    setUnitRouteAssignments([]);
  };

  const removeAllCompletedAssignments = (
    completedAssignments: Partial<UnitsRoutesAssignment>[]
  ) => {
    if (completedAssignments && completedAssignments.length > 0) {
      // By Date
      setAssignmentsByDate((prev) =>
        prev.filter(
          (item) =>
            completedAssignments.findIndex(
              (el) => el.routeId === item.routeId && el.unitId === item.unitId
            ) === -1
        )
      );
      setPartialUpdatedAssignmentsByDate({});
      // By Schedule
      setAssignmentsBySchedule((prev) =>
        prev.filter(
          (item) =>
            completedAssignments.findIndex(
              (el) => el.routeId === item.routeId && el.unitId === item.unitId
            ) === -1
        )
      );
      setPartialUpdatedAssignmentsBySchedule({});
      // General Assignments
      const validatedItems: UnitsRoutesAssignment[] = validateAssignments(
        unitRouteAssignments.filter(
          (item) =>
            completedAssignments.findIndex(
              (el) => el.routeId === item.routeId && el.unitId === item.unitId
            ) === -1
        )
      );
      setUnitRouteAssignments(validatedItems);
    }
  };

  const syncAssignmentsByDate = () => {
    const newData: Partial<AssignmentByDate>[] = unitRouteAssignments.map((unitRouteAssignment) => {
      // New requirement: persist changes made by user on step 4
      const partialUpdates =
        partialUpdatedAssignmentsByDate.hasOwnProperty(unitRouteAssignment.correlative || -1) &&
        partialUpdatedAssignmentsByDate[unitRouteAssignment.correlative || -1] &&
        partialUpdatedAssignmentsByDate[unitRouteAssignment.correlative || -1].unitId ===
          unitRouteAssignment.unitId &&
        partialUpdatedAssignmentsByDate[unitRouteAssignment.correlative || -1].routeId ===
          unitRouteAssignment.routeId
          ? partialUpdatedAssignmentsByDate[unitRouteAssignment.correlative || -1]
          : {};

      if (isEmpty(partialUpdates))
        setPartialUpdatedAssignmentsByDate((prev) => ({
          ...prev,
          [unitRouteAssignment.correlative || -1]: undefined
        }));

      const data: Partial<AssignmentByDate> = {
        unitId: unitRouteAssignment.unitId,
        unitName: unitRouteAssignment.unitName ?? '',
        routeId: unitRouteAssignment.routeId,
        routeName: unitRouteAssignment.routeName ?? '',
        correlative: unitRouteAssignment.correlative,
        customerId: unitRouteAssignment.customerId ?? null,
        customerName: unitRouteAssignment.customerName ?? null,
        globalNotificationFrec: assignmentType.globalNotificationFrec,
        startDate: commonValues.startDate,
        driverId: null,
        completionType: commonValues.completionType,
        completionTimeByUserInSec: 0,
        automaticMonitoringWhenArrivingAtOrigin:
          commonValues.automaticMonitoringWhenArrivingAtOrigin,
        startTimeWindowInSec: 0,
        speedLimit: commonValues.speedLimit,
        customField1: null,
        customField2: null,
        customField3: null,
        customField4: null,
        customField5: null,
        customField6: null,
        globalNotificationEmails: assignmentType.globalNotificationEmails,
        useSharedLink: assignmentType.useSharedLink,
        notificationEmails: assignmentType.notificationEmails,
        notificationEvents: assignmentType.notificationEvents,
        scheduleId: null, // because is by date the schedule id always will be null
        alreadyLeftTheOrigin: assignmentType.alreadyLeftTheOrigin === AlreadyLeftTheOrigin.Yes,
        isSpecialCargo: commonValues.isSpecialCargo,
        completionTimeInMinutes: commonValues.completionTime?.totalSeconds
          ? commonValues.completionTime?.totalSeconds / 60 ?? 0
          : undefined,
        startTimeWindow: commonValues.startTimeWindow?.totalSeconds
          ? commonValues.startTimeWindow?.totalSeconds / 60
          : undefined,
        validationErrors: unitRouteAssignment.validationErrors,
        ...partialUpdates
      };

      return data;
    });

    const validatedItems: AssignmentByDate[] = validateAssignments(newData);
    setAssignmentsByDate(validatedItems);
  };

  const syncAssignmentsBySchedule = () => {
    const newData: Partial<AssignmentBySchedule>[] = unitRouteAssignments.map(
      (unitRouteAssignment) => {
        // New requirement: persist changes made by user on step 4
        const partialUpdates =
          partialUpdatedAssignmentsBySchedule.hasOwnProperty(
            unitRouteAssignment.correlative || -1
          ) &&
          partialUpdatedAssignmentsBySchedule[unitRouteAssignment.correlative || -1] &&
          partialUpdatedAssignmentsBySchedule[unitRouteAssignment.correlative || -1].unitId ===
            unitRouteAssignment.unitId &&
          partialUpdatedAssignmentsBySchedule[unitRouteAssignment.correlative || -1].routeId ===
            unitRouteAssignment.routeId
            ? partialUpdatedAssignmentsBySchedule[unitRouteAssignment.correlative || -1]
            : {};

        if (isEmpty(partialUpdates))
          setPartialUpdatedAssignmentsBySchedule((prev) => ({
            ...prev,
            [unitRouteAssignment.correlative || -1]: undefined
          }));

        const data: Partial<AssignmentBySchedule> = {
          unitId: unitRouteAssignment.unitId,
          unitName: unitRouteAssignment.unitName ?? '',
          routeId: unitRouteAssignment.routeId,
          routeName: unitRouteAssignment.routeName ?? '',
          correlative: unitRouteAssignment.correlative,
          customerId: unitRouteAssignment?.customerId ?? undefined,
          customerName: unitRouteAssignment.customerName ?? null,
          globalNotificationFrec: assignmentType.globalNotificationFrec,
          startDate: commonValues.startDate,
          driverId: null,
          completionType: commonValues.completionType,
          completionTimeByUserInSec: 0,
          automaticMonitoringWhenArrivingAtOrigin:
            commonValues.automaticMonitoringWhenArrivingAtOrigin,
          startTimeWindowInSec: 0,
          speedLimit: commonValues.speedLimit,
          customField1: null,
          customField2: null,
          customField3: null,
          customField4: null,
          customField5: null,
          customField6: null,
          globalNotificationEmails: assignmentType.globalNotificationEmails,
          useSharedLink: assignmentType.useSharedLink,
          notificationEmails: assignmentType.notificationEmails,
          notificationEvents: assignmentType.notificationEvents,
          scheduleId: commonValues.scheduleId,
          scheduleName: commonValues.scheduleName,
          alreadyLeftTheOrigin: assignmentType.alreadyLeftTheOrigin === AlreadyLeftTheOrigin.Yes,
          isSpecialCargo: commonValues.isSpecialCargo,
          completionTimeInMinutes: commonValues.completionTime?.totalSeconds
            ? commonValues.completionTime?.totalSeconds / 60 ?? 0
            : undefined,
          startTimeWindow: commonValues.startTimeWindow?.totalSeconds
            ? commonValues.startTimeWindow?.totalSeconds / 60
            : undefined,
          validationErrors: unitRouteAssignment.validationErrors,
          ...partialUpdates
        };

        return data;
      }
    );

    const validatedItems: AssignmentBySchedule[] = validateAssignments(newData);
    setAssignmentsBySchedule(validatedItems);
  };

  const partialUpdateAssignmentsByDate = (partialChanges: Partial<AssignmentByDate>) => {
    const newData = assignmentsByDate.map((assignment) => {
      if (assignment.correlative === partialChanges.correlative) {
        setPartialUpdatedAssignmentsByDate((prev) => ({
          ...prev,
          [partialChanges.correlative || -1]: { ...assignment, ...partialChanges }
        }));

        return { ...assignment, ...partialChanges };
      }
      return assignment;
    });

    // If partial changes includes route or unit, update unitRouteAssignments
    if (
      assignmentsByDate.findIndex(
        (assignment) => assignment.correlative === partialChanges.correlative
      ) > -1
    ) {
      const assignment = assignmentsByDate.find(
        (assignment) => assignment.correlative === partialChanges.correlative
      );
      if (
        assignment?.unitId !== (partialChanges.unitId || -1) ||
        assignment?.routeId !== (partialChanges.routeId || -1)
      ) {
        const validatedItems: UnitsRoutesAssignment[] = validateAssignments(
          unitRouteAssignments.map((item) => {
            if (item.correlative === partialChanges.correlative) {
              return {
                ...item,
                unitId: partialChanges.unitId || -1,
                unitName: partialChanges.unitName || '',
                routeId: partialChanges.routeId || -1,
                routeName: partialChanges.routeName || ''
              };
            }
            return item;
          })
        );
        setUnitRouteAssignments(validatedItems);
      }
    }

    const validatedItems: AssignmentByDate[] = validateAssignments(newData);
    setAssignmentsByDate(validatedItems);
  };

  const partialUpdateSelectedAssignmentsByDate = (
    partialChanges: Partial<EditableAssignmentFieldsWithNames>,
    selected: Partial<AssignmentByDate>[]
  ) => {
    const newData: Partial<AssignmentByDate>[] = assignmentsByDate.map((assignment) => {
      const idx = selected.findIndex((item) => item.correlative === assignment.correlative);
      if (idx > -1) {
        const newAssignment: Partial<AssignmentByDate> = {
          correlative: assignment.correlative,
          unitId: partialChanges.unitId ?? assignment.unitId,
          unitName: partialChanges.unitName ?? assignment.unitName,
          routeId: partialChanges.routeId ?? assignment.routeId,
          routeName: partialChanges.routeName ?? assignment.routeName,
          customerId: assignment.customerId,
          customerName: assignment.customerName,
          globalNotificationEmails: assignment.globalNotificationEmails,
          useSharedLink: assignment.useSharedLink,
          notificationEmails: assignment.notificationEmails,
          notificationEvents: assignment.notificationEvents,
          scheduleId: assignment.scheduleId,
          driverId: partialChanges.driverId ?? assignment.driverId,
          driverName: partialChanges.driverName ?? assignment.driverName,
          completionTimeByUserInSec: assignment.completionTimeByUserInSec,
          alreadyLeftTheOrigin: assignment.alreadyLeftTheOrigin,
          startTimeWindowInSec: assignment.startTimeWindowInSec,
          isSpecialCargo: partialChanges.isSpecialCargo ?? assignment.isSpecialCargo,
          customField1: partialChanges.customField1 ?? assignment.customField1,
          customField2: partialChanges.customField2 ?? assignment.customField2,
          customField3: partialChanges.customField3 ?? assignment.customField3,
          customField4: partialChanges.customField4 ?? assignment.customField4,
          customField5: partialChanges.customField5 ?? assignment.customField5,
          customField6: partialChanges.customField6 ?? assignment.customField6,
          completionTimeInMinutes:
            partialChanges.completionTimeInMinutes ?? assignment.completionTimeInMinutes,
          startTimeWindow: partialChanges.startTimeWindow ?? assignment.startTimeWindow,
          globalNotificationFrec: assignment.globalNotificationFrec,
          startDate: partialChanges?.startDate ?? assignment.startDate,
          automaticMonitoringWhenArrivingAtOrigin:
            partialChanges?.automaticMonitoringWhenArrivingAtOrigin ??
            assignment.automaticMonitoringWhenArrivingAtOrigin,
          speedLimit: partialChanges?.speedLimit ?? assignment.speedLimit,
          completionType: assignment.completionType
        };

        setPartialUpdatedAssignmentsByDate((prev) => ({
          ...prev,
          [assignment.correlative || -1]: { ...newAssignment }
        }));

        // If partial changes includes route or unit, update unitRouteAssignments

        if (
          assignment?.unitId !== (partialChanges.unitId || -1) ||
          assignment?.routeId !== (partialChanges.routeId || -1)
        ) {
          const validatedItems: UnitsRoutesAssignment[] = validateAssignments(
            unitRouteAssignments.map((item) => {
              if (item.correlative === assignment.correlative) {
                return {
                  ...item,
                  unitId: partialChanges.unitId || -1,
                  unitName: partialChanges.unitName || '',
                  routeId: partialChanges.routeId || -1,
                  routeName: partialChanges.routeName || ''
                };
              }
              return item;
            })
          );
          setUnitRouteAssignments(validatedItems);
        }

        return newAssignment;
      }
      return assignment;
    });

    const validatedItems: AssignmentByDate[] = validateAssignments(newData);
    setAssignmentsByDate(validatedItems);
  };

  const partialUpdateAssignmentsBySchedule = (partialChanges: Partial<AssignmentBySchedule>) => {
    const newData = assignmentsBySchedule.map((assignment) => {
      if (assignment.correlative === partialChanges.correlative) {
        setPartialUpdatedAssignmentsBySchedule((prev) => ({
          ...prev,
          [partialChanges.correlative || -1]: { ...assignment, ...partialChanges }
        }));

        return { ...assignment, ...partialChanges };
      }
      return assignment;
    });

    // If partial changes includes route or unit, update unitRouteAssignments
    if (
      assignmentsBySchedule.findIndex(
        (assignment) => assignment.correlative === partialChanges.correlative
      ) > -1
    ) {
      const assignment = assignmentsBySchedule.find(
        (assignment) => assignment.correlative === partialChanges.correlative
      );
      if (
        assignment?.unitId !== (partialChanges.unitId || -1) ||
        assignment?.routeId !== (partialChanges.routeId || -1)
      ) {
        const validatedItems: UnitsRoutesAssignment[] = validateAssignments(
          unitRouteAssignments.map((item) => {
            if (item.correlative === partialChanges.correlative) {
              return {
                ...item,
                unitId: partialChanges.unitId || -1,
                unitName: partialChanges.unitName || '',
                routeId: partialChanges.routeId || -1,
                routeName: partialChanges.routeName || ''
              };
            }
            return item;
          })
        );
        setUnitRouteAssignments(validatedItems);
      }
    }

    const validatedItems: AssignmentBySchedule[] = validateAssignments(newData);
    setAssignmentsBySchedule(validatedItems);
  };

  const partialUpdateSelectedAssignmentsBySchedule = (
    partialChanges: Partial<EditableAssignmentFieldsWithNames>,
    selected: Partial<AssignmentBySchedule>[]
  ) => {
    const newData: Partial<AssignmentBySchedule>[] = assignmentsBySchedule.map((assignment) => {
      const idx = selected.findIndex((item) => item.correlative === assignment.correlative);
      if (idx > -1) {
        const newAssignment: Partial<AssignmentBySchedule> = {
          correlative: assignment.correlative,
          unitId: partialChanges.unitId ?? assignment.unitId,
          unitName: partialChanges.unitName ?? assignment.unitName,
          routeId: partialChanges.routeId ?? assignment.routeId,
          routeName: partialChanges.routeName ?? assignment.routeName,
          customerId: assignment.customerId,
          customerName: assignment.customerName,
          globalNotificationEmails: assignment.globalNotificationEmails,
          useSharedLink: assignment.useSharedLink,
          notificationEmails: assignment.notificationEmails,
          notificationEvents: assignment.notificationEvents,
          driverId: partialChanges.driverId ?? assignment.driverId,
          driverName: partialChanges.driverName ?? assignment.driverName,
          completionTimeByUserInSec: assignment.completionTimeByUserInSec,
          alreadyLeftTheOrigin: assignment.alreadyLeftTheOrigin,
          startTimeWindowInSec: assignment.startTimeWindowInSec,
          isSpecialCargo: partialChanges.isSpecialCargo ?? assignment.isSpecialCargo,
          customField1: partialChanges.customField1 ?? assignment.customField1,
          customField2: partialChanges.customField2 ?? assignment.customField2,
          customField3: partialChanges.customField3 ?? assignment.customField3,
          customField4: partialChanges.customField4 ?? assignment.customField4,
          customField5: partialChanges.customField5 ?? assignment.customField5,
          customField6: partialChanges.customField6 ?? assignment.customField6,
          completionTimeInMinutes:
            partialChanges.completionTimeInMinutes ?? assignment.completionTimeInMinutes,
          startTimeWindow: partialChanges.startTimeWindow ?? assignment.startTimeWindow,
          globalNotificationFrec: assignment.globalNotificationFrec,
          startDate: null, // null because is by schedule
          automaticMonitoringWhenArrivingAtOrigin:
            partialChanges?.automaticMonitoringWhenArrivingAtOrigin ??
            assignment.automaticMonitoringWhenArrivingAtOrigin,
          speedLimit: partialChanges?.speedLimit ?? assignment.speedLimit,
          completionType: assignment.completionType,
          scheduleId: partialChanges?.scheduleId ?? assignment.scheduleId,
          scheduleName: partialChanges?.scheduleName ?? assignment.scheduleName ?? undefined,
          scheduleNextRunDate:
            partialChanges?.scheduleNextRunDate ?? assignment.scheduleNextRunDate ?? undefined
        };

        setPartialUpdatedAssignmentsBySchedule((prev) => ({
          ...prev,
          [assignment.correlative || -1]: { ...newAssignment }
        }));

        // If partial changes includes route or unit, update unitRouteAssignments

        if (
          assignment?.unitId !== (partialChanges.unitId || -1) ||
          assignment?.routeId !== (partialChanges.routeId || -1)
        ) {
          const validatedItems: UnitsRoutesAssignment[] = validateAssignments(
            unitRouteAssignments.map((item) => {
              if (item.correlative === assignment.correlative) {
                return {
                  ...item,
                  unitId: partialChanges.unitId || -1,
                  unitName: partialChanges.unitName || '',
                  routeId: partialChanges.routeId || -1,
                  routeName: partialChanges.routeName || ''
                };
              }
              return item;
            })
          );
          setUnitRouteAssignments(validatedItems);
        }
        return newAssignment;
      }
      return assignment;
    });

    const validatedItems: AssignmentBySchedule[] = validateAssignments(newData);
    setAssignmentsBySchedule(validatedItems);
  };

  const value: AssignmentContextValue = {
    resetForms,
    startResetForms,
    stepper,
    setStepper,
    assignmentType,
    updateAssignmentType,
    commonValues,
    updateCommonValues,
    revalidateUnitRouteAssignments,
    assignedUnits,
    updateAssignedUnits,
    assignmentsByDate,
    assignmentsBySchedule,
    resetAssignments,
    removeAllAssignments,
    removeAllCompletedAssignments,
    updateUnitRouteAssignments,
    unitRouteAssignments,
    removeUnitRouteAssignment,
    syncAssignmentsByDate,
    syncAssignmentsBySchedule,
    partialUpdateSelectedAssignmentsByDate,
    partialUpdateAssignmentsByDate,
    partialUpdateSelectedAssignmentsBySchedule,
    partialUpdateAssignmentsBySchedule
  };

  if (!AssignmentContext) {
    throw new Error('useAssignmentContext must be used within a AssignmentProvider');
  }

  return <AssignmentContext.Provider value={value}>{children}</AssignmentContext.Provider>;
};

export const useAssignmentContext = () => {
  const context = useContext(AssignmentContext);
  if (context === null) {
    throw new Error('useAssignmentContext must be used within a AssignmentProvider');
  }
  return context;
};
