import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';
import { appIntl } from 'utility/context/IntlGlobalProvider';

export const baseUrl = '/unit';

interface UnitFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
}

interface DeviceActionParametersRequest {
  unitId: number;
  deviceActionId: number;
}

interface HistoricalPositionsReportRequest {
  unitId: number;
  reportType: number | undefined;
  startDate: Date | string;
  endDate: Date | string;
  idleSpeed: number | undefined;
}

interface ExecuteDeviceActionRequest {
  unitId: number;
  deviceActionId: number;
  parameters: string;
}

interface UnitInformation {
  unitId?: number;
  startDate?: string;
  endDate?: string;
}

interface FleetFilters {
  unitName?: string;
  deviceIdentifier?: string;
  membership?: any;
  brandId?: number;
}
interface DeviceActionsRequest {
  unitId: number;
}

interface ChangeUnitSubState {
  unitId: number;
  subStateId: number;
}

interface RouteAssignments {
  unitId: number;
}

export const getUnitsListService = (request: UnitFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getDisabledUnitsListService = (request: UnitFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDisabled`, request);
};

export const changeUnitSubState = (
  request: ChangeUnitSubState,
  customMessage,
  customMessageError,
  configActions?: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Custom',
    customMessage,
    customMessageError,
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  //httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/ChangeUnitSubState`, request);
};

export const getRouteAssignments = (request: RouteAssignments, configActions?: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getRouteAssignments`, request);
};

export const getFleetService = (request: FleetFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getFleet`, request);
};

export const getUnitsLiveStatusListService = (
  request: UnitFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitsLiveStatus`, request);
};

export const getUnitInformationService = (
  request: UnitInformation,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Get',
    genderType: 'SingularFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitInformation`, request);
};

export const getUnitDeviceActionParametersService = (request: DeviceActionParametersRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Custom',
    customMessageError: intl.formatMessage({
      id: 'units.parametersFetchErrorMessage'
    })
  });
  return httpWebtrack.post(`${baseUrl}/getUnitDeviceActionParameters`, request);
};
export const getUnitDeviceActionsService = (
  request: DeviceActionsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Custom',
    customMessageError: intl.formatMessage({
      id: 'units.parametersFetchErrorMessage'
    })
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitDeviceActions`, request);
};

export const getUnitHistoricalPositionsReportService = (
  request: HistoricalPositionsReportRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Custom',
    customMessageError: intl.formatMessage({
      id: 'units.unitHistoricalPositionsErrorMessage'
    })
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitPositionHistory`, request);
};

export const executeDeviceActionService = (
  request: ExecuteDeviceActionRequest,
  configActions?: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    object: 'units.unit',
    action: 'Custom',
    customMessage: intl.formatMessage({
      id: 'units.executeDeviceActionMessage'
    }),
    customMessageError: intl.formatMessage({
      id: 'units.executeDeviceActionErrorMessage'
    })
  });
  if (configActions) {
    httpWebtrack.setConfigActions(configActions);
  }
  return httpWebtrack.post(`${baseUrl}/executeDeviceAction`, request);
};

export const acknowledgeUnitEventService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/acknowledgeEvent`, request);
};

export const getUnitRunningTimeByHour = (
  request: UnitInformation,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'reports.reports',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitRunningTimeByHour`, request);
};

export const getUnitScriptHistory = (request: UnitInformation, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'reports.reports',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitScriptHistory`, request);
};

export const getUnitTemperatureHistory = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getTemperatureSensorReport`, request);
};

export const getUnitFuelHistory = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getFuelSensorReportPro`, request);
};

export const getAssetFuelHistory = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getAssetFuelSensorReport`, request);
};

export const getUnitFuelSamples = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitFuelSamples`, request);
};

export const getUnitFuelInformation = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitFuelInformation`, request);
};

export const getUnitFuelEvents = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUnitFuelEvents`, request);
};

export const getDowntimeBinnacleService = (
  request: UnitInformation,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'trackingUnits.binnacle',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDowntimeBinnacle`, request);
};

export const getUserAssignedUnits = (
  request: { userIdRequest: number },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.assignedUnits',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getUserAssignedUnits`, request);
};

export const removeUserAssignedUnits = (
  request: { userIdRequest: number; unitsIds: number[] },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.assignedUnits',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/removeUserAssignedUnits`, request);
};

export const addUserAssignedUnits = (
  request: { userIdRequest: number; unitIds: number[] },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.assignedUnits',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/addUserAssignedUnits`, request);
};

export const updateOdometer = (
  request: { unitId: number; newOdometer: number },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'trackingUnits.binnacle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/updateOdometer`, request);
};

export const updateHourmeter = (
  request: { unitId: number; newHourmeter: number },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'trackingUnits.binnacle',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/updateHourmeter`, request);
};

export const updateDeviceService = (
  request: { unitId: number; deviceId: number | null },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'trackingUnits.trackingUnit',
    action: 'Edit',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/updateDevice`, request);
};

export const updateSimCardService = (
  request: { unitId: number; simCard: number | null },
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'trackingUnits.trackingUnit',
    action: 'Edit',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/updateSimCard`, request);
};
