/* -------------------------------------------------------------------------- */
/*                            Componente FetchEntities                            */
/* -------------------------------------------------------------------------- */
// Este componente es utilizado para cargar cualquier entity
// Simplemente se debe enviar el use{Entity} en verdadero
// Si desea que se cada cierto tiempo se este viendo si el entity debe actualizarse coloque un entity timeout
// Puede utilizar varios componentes FetchEntities para diferenciar los que necesitan timeout de los que no

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as entityActions from '../../actions';
import * as entitiesSelectors from '../../reducers';
import {
  ENTITY_AGENTS,
  ENTITY_ASSETS,
  ENTITY_CONTACTS,
  ENTITY_COUNTRIES,
  ENTITY_DIVISIONS,
  ENTITY_FORMS,
  ENTITY_GEOFENCES,
  ENTITY_JOBS_BACKLOG,
  ENTITY_GROUPS,
  ENTITY_LOCATIONS,
  ENTITY_MODULES,
  ENTITY_OPERATORS,
  ENTITY_ORGANIZATIONS,
  ENTITY_DRIVERS,
  ENTITY_ROUTES,
  ENTITY_SCHEDULES,
  ENTITY_SENSORS,
  ENTITY_SERVICE_CLASSES,
  ENTITY_SIM_CARDS,
  ENTITY_SUBDIVISONS,
  ENTITY_MAINTENANCE_LINES,
  ENTITY_UNITS,
  ENTITY_USERS,
  ENTITY_VEHICLES,
  ENTITY_WORKPLANS,
  ENTITY_ORGANIZATION_CONTRACTS,
  ENTITY_CUSTOMERS,
  ENTITY_CONTRACT_TEMPLATES,
  ENTITY_OFFICES,
  ENTITY_PROTOCOLS
} from '../../constants';

export interface DefaultFilters {
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  groupId?: number;
}

interface Props {
  useUnits?: boolean;
  unitsFilters?: DefaultFilters;
  useSubdivisions?: boolean;
  subdivisionsFilters?: DefaultFilters;
  useMaintenanceLines?: boolean;
  maintenanceLinesFilters?: DefaultFilters;
  useVehicles?: boolean;
  vehiclesFilters?: DefaultFilters;
  useSims?: boolean;
  simsFilters?: DefaultFilters;
  useModules?: boolean;
  modulesFilters?: DefaultFilters;
  useDrivers?: boolean;
  driversFilters?: DefaultFilters;
  useAgents?: boolean;
  useServiceClasses?: boolean;
  useServiceClassesDropdown?: boolean;
  useUsers?: boolean;
  usersFilters?: DefaultFilters;
  useOperators?: boolean;
  useDivisions?: boolean;
  divisionsFilters?: DefaultFilters;
  useOrganizations?: boolean;
  useContacts?: boolean;
  useWorkplans?: boolean;
  workplansFilters?: DefaultFilters;
  useGeofences?: boolean;
  geofencesFilters?: DefaultFilters;
  useSchedules?: boolean;
  useForms?: boolean;
  useRoutes?: boolean;
  routesFilters?: DefaultFilters;
  useCountries?: boolean;
  useLocations?: boolean;
  locationsFilters?: DefaultFilters;
  useOffices?: boolean;
  officesFilters?: DefaultFilters;
  useAssets?: boolean;
  assetsFilters?: DefaultFilters;
  useSensors?: boolean;
  useJobsBacklog?: boolean;
  jobsBacklogFilters?: DefaultFilters;
  useGroups?: boolean;
  groupsFilters?: DefaultFilters;
  useOrganizationContracts?: boolean;
  organizationContractsFilters?: DefaultFilters;
  useContractTemplates?: boolean;
  contractTemplatesFilters?: DefaultFilters;
  useCustomers?: boolean;
  customersFilters?: DefaultFilters;
  useProtocols?: boolean;
  protocolsFilters?: DefaultFilters;
  intervalTime?: number; //milliseconds ?: send undefined,null or 0 to avoid a setInterval
  additionalEntities?: number[]; //list of entitytypes (int) to also load
}

const FetchEntities = (props: Props) => {
  const {
    useUnits,
    unitsFilters,
    useSubdivisions,
    subdivisionsFilters,
    useMaintenanceLines,
    maintenanceLinesFilters,
    useVehicles,
    vehiclesFilters,
    useSims,
    simsFilters,
    useModules,
    modulesFilters,
    useDrivers,
    driversFilters,
    useAgents,
    useServiceClasses,
    useServiceClassesDropdown,
    useUsers,
    usersFilters,
    useOperators,
    useDivisions,
    divisionsFilters,
    useOrganizations,
    useContacts,
    useWorkplans,
    workplansFilters,
    useGeofences,
    geofencesFilters,
    useSchedules,
    useForms,
    useRoutes,
    routesFilters,
    useCountries,
    useLocations,
    locationsFilters,
    useOffices,
    officesFilters,
    useAssets,
    assetsFilters,
    useSensors,
    useJobsBacklog,
    jobsBacklogFilters,
    useGroups,
    groupsFilters,
    useOrganizationContracts,
    organizationContractsFilters,
    useContractTemplates,
    contractTemplatesFilters,
    useCustomers,
    customersFilters,
    useProtocols,
    protocolsFilters,
    intervalTime,
    additionalEntities
  } = props;
  const dispatch = useDispatch();

  const { isEntityUpdatedFunction, isFetchedListEntities } = useSelector((state) => ({
    isEntityUpdatedFunction: entitiesSelectors.isEntityUpdated(state),
    isFetchedListEntities: entitiesSelectors.isFetchedListEntities(state)
  }));

  const fetchEntities = () => {
    let entities: any[] = [];
    if (additionalEntities != null) entities = [...additionalEntities];
    if (useAgents) entities.push(ENTITY_AGENTS);
    if (useServiceClasses) entities.push(ENTITY_SERVICE_CLASSES);
    if (useOperators) entities.push(ENTITY_OPERATORS);
    if (useOrganizations) entities.push(ENTITY_ORGANIZATIONS);
    if (useContacts) entities.push(ENTITY_CONTACTS);
    if (useSchedules) entities.push(ENTITY_SCHEDULES);
    if (useForms) entities.push(ENTITY_FORMS);
    if (useCountries) entities.push(ENTITY_COUNTRIES);
    if (useSensors) entities.push(ENTITY_SENSORS);
    if (useOrganizationContracts) entities.push(ENTITY_ORGANIZATION_CONTRACTS);
    if (useContractTemplates) entities.push(ENTITY_CONTRACT_TEMPLATES);
    if (useCustomers) entities.push(ENTITY_CUSTOMERS);
    if (useProtocols) entities.push(ENTITY_PROTOCOLS);
    if (entities.length > 0) {
      dispatch(entityActions.startUpdatingEntities(entities));
    }

    const isEntityUpdated = isEntityUpdatedFunction(entities);
    const finalEntities = entities.filter((_, i) => isEntityUpdated[i] !== true);
    if (finalEntities.length > 0) dispatch(entityActions.startUpdatingEntities(finalEntities));
  };

  const fetchEntitiesWithFilters = (entities: any[]) => {
    const isEntityUpdated = isEntityUpdatedFunction(entities);
    const finalEntities = entities.filter((_, i) => isEntityUpdated[i] !== true);
    if (finalEntities.length > 0) {
      dispatch(entityActions.startUpdatingEntities(finalEntities));
    }
  };

  const fetchDivisions = (divisionsFilters) => {
    if (useDivisions && divisionsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_DIVISIONS,
          operatorId: divisionsFilters?.operatorId,
          organizationId: divisionsFilters?.organizationId
        }
      ]);
  };

  const fetchSubdivisions = (subdivisionsFilters) => {
    if (useSubdivisions && subdivisionsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_SUBDIVISONS,
          operatorId: subdivisionsFilters?.operatorId,
          organizationId: subdivisionsFilters?.organizationId
        }
      ]);
  };

  const fetchMaintenanceLines = (maintenanceLinesFilters) => {
    if (useMaintenanceLines && maintenanceLinesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_MAINTENANCE_LINES,
          operatorId: maintenanceLinesFilters?.operatorId,
          organizationId: maintenanceLinesFilters?.organizationId
        }
      ]);
  };

  const fetchLocations = (locationsFilters) => {
    if (useLocations && locationsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_LOCATIONS,
          operatorId: locationsFilters?.operatorId,
          organizationId: locationsFilters?.organizationId
        }
      ]);
  };

  const fetchOffices = (officesFilters) => {
    if (useOffices && officesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_OFFICES,
          operatorId: officesFilters?.operatorId,
          organizationId: officesFilters?.organizationId
        }
      ]);
  };

  const fetchDrivers = (driversFilters) => {
    if (useDrivers && driversFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_DRIVERS,
          operatorId: driversFilters?.operatorId,
          organizationId: driversFilters?.organizationId
        }
      ]);
  };

  const fetchWorkplans = (workplansFilters) => {
    if (useWorkplans && workplansFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_WORKPLANS,
          operatorId: workplansFilters?.operatorId,
          organizationId: workplansFilters?.organizationId
        }
      ]);
  };

  const fetchGeofences = (geofencesFilters) => {
    if (useGeofences && geofencesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_GEOFENCES,
          operatorId: geofencesFilters?.operatorId,
          organizationId: geofencesFilters?.organizationId
        }
      ]);
  };

  const fetchRoutes = (routesFilters) => {
    if (useRoutes && routesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_ROUTES,
          operatorId: routesFilters?.operatorId,
          organizationId: routesFilters?.organizationId
        }
      ]);
  };

  const fetchUnits = (unitsFilters) => {
    if (useUnits && unitsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_UNITS,
          operatorId: unitsFilters?.operatorId,
          organizationId: unitsFilters?.organizationId,
          allowNullOrganizationId: true
        }
      ]);
  };

  const fetchVehicles = (vehiclesFilters) => {
    if (useVehicles && vehiclesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_VEHICLES,
          operatorId: vehiclesFilters?.operatorId,
          organizationId: vehiclesFilters?.organizationId
        }
      ]);
  };

  const fetchAssets = (assetsFilters) => {
    if (useAssets && assetsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_ASSETS,
          operatorId: assetsFilters?.operatorId,
          organizationId: assetsFilters?.organizationId
        }
      ]);
  };

  const fetchJobsBacklog = (jobsFilters: DefaultFilters | undefined) => {
    if (useJobsBacklog && jobsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_JOBS_BACKLOG,
          operatorId: jobsFilters?.operatorId,
          organizationId: jobsFilters?.organizationId
        }
      ]);
  };

  const fetchUsers = (usersFilters) => {
    if (useUsers)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_USERS,
          operatorId: usersFilters?.operatorId,
          organizationId: usersFilters?.organizationId,
          allowNullOrganizationId: true,
          allowNullOperatorId: true
        }
      ]);
  };

  const fetchGroups = (groupsFilters) => {
    if (useGroups)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_GROUPS,
          operatorId: groupsFilters?.operatorId,
          organizationId: groupsFilters?.organizationId
        }
      ]);
  };

  const fetchOrganizationContracts = (organizationContractsFilters) => {
    if (useOrganizationContracts && organizationContractsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_ORGANIZATION_CONTRACTS,
          operatorId: organizationContractsFilters?.operatorId,
          organizationId: organizationContractsFilters?.organizationId
        }
      ]);
  };

  const fetchContracTemplates = (contractTemplatesFilters) => {
    if (useContractTemplates && contractTemplatesFilters)
      dispatch(
        entityActions.startUpdatingEntities([
          {
            entityTypeId: ENTITY_CONTRACT_TEMPLATES,
            operatorId: contractTemplatesFilters?.operatorId,
            organizationId: contractTemplatesFilters?.organizationId,
            allowNullOrganizationId: true
          }
        ])
      );
  };

  const fetchCustomers = (customersFilters) => {
    if (useCustomers && customersFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_CUSTOMERS,
          operatorId: customersFilters?.operatorId,
          organizationId: customersFilters?.organizationId
        }
      ]);
  };

  const fetchModules = (modulesFilters) => {
    if (useModules && modulesFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_MODULES,
          operatorId: modulesFilters?.operatorId,
          organizationId: modulesFilters?.organizationId,
          allowNullOrganizationId: true
        }
      ]);
  };

  const fetchSims = (simsFilters) => {
    if (useSims && simsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_SIM_CARDS,
          operatorId: simsFilters?.operatorId,
          organizationId: simsFilters?.organizationId,
          allowNullOrganizationId: true
        }
      ]);
  };

  const fetchServiceClasses = () => {
    if (useServiceClasses)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_SERVICE_CLASSES,
          allowNullOrganizationId: true
        }
      ]);
  };

  const fetchServiceClassesDropdown = () => {
    if (useServiceClassesDropdown) {
      console.log('useServiceClassesDropdown', useServiceClassesDropdown);
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_SERVICE_CLASSES,
          isDropdown: true,
          allowNullOrganizationId: true
        }
      ]);
    }
  };

  const fetchProtocols = (protocolsFilters) => {
    if (useProtocols && protocolsFilters)
      fetchEntitiesWithFilters([
        {
          entityTypeId: ENTITY_PROTOCOLS,
          operatorId: protocolsFilters?.operatorId,
          organizationId: protocolsFilters?.organizationId,
          allowNullOrganizationId: true
        }
      ]);
  };

  useEffect(() => {
    if (isFetchedListEntities) fetchEntities();
  }, [isFetchedListEntities]);

  //Se actualiza la entity de divisions cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchDivisions(divisionsFilters);
  }, [isFetchedListEntities, useDivisions, divisionsFilters?.organizationId]);

  //Se actualiza la entity de subdivisions cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchSubdivisions(subdivisionsFilters);
  }, [isFetchedListEntities, useSubdivisions, subdivisionsFilters?.organizationId]);

  //Se actualiza la entity de maintenanceLines cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchMaintenanceLines(maintenanceLinesFilters);
  }, [isFetchedListEntities, useMaintenanceLines, maintenanceLinesFilters?.organizationId]);

  //Se actualiza la entity de locations cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchLocations(locationsFilters);
  }, [isFetchedListEntities, useLocations, locationsFilters?.organizationId]);

  //Se actualiza la entity de offices cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchOffices(officesFilters);
  }, [isFetchedListEntities, useOffices, officesFilters?.organizationId]);

  //Se actualiza la entity de drivers cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchDrivers(driversFilters);
  }, [isFetchedListEntities, useDrivers, driversFilters?.organizationId]);

  //Se actualiza la entity de workplans cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchWorkplans(workplansFilters);
  }, [isFetchedListEntities, useWorkplans, workplansFilters?.organizationId]);

  //Se actualiza la entity de geofences cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchGeofences(geofencesFilters);
  }, [isFetchedListEntities, useGeofences, geofencesFilters?.organizationId]);

  //Se actualiza la entity de workplans cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchRoutes(routesFilters);
  }, [isFetchedListEntities, useRoutes, routesFilters?.organizationId]);

  //Se actualiza la entity de units cuando cambia la organización y el operador
  useEffect(() => {
    if (isFetchedListEntities) fetchUnits(unitsFilters);
  }, [isFetchedListEntities, useUnits, unitsFilters?.operatorId, unitsFilters?.organizationId]);

  //Se actualiza la entity de vehicles cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchVehicles(vehiclesFilters);
  }, [isFetchedListEntities, useVehicles, vehiclesFilters?.organizationId]);

  //Se actualiza la entity de assets cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchAssets(assetsFilters);
  }, [isFetchedListEntities, useAssets, assetsFilters?.organizationId]);

  useEffect(() => {
    if (isFetchedListEntities) fetchJobsBacklog(jobsBacklogFilters);
  }, [isFetchedListEntities, useJobsBacklog, assetsFilters?.organizationId]);

  //Se actualiza la entity de users cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchUsers(usersFilters);
  }, [isFetchedListEntities, useUsers, usersFilters?.organizationId]);

  //Se actualiza la entity de customers cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchCustomers(customersFilters);
  }, [isFetchedListEntities, useCustomers, customersFilters?.organizationId]);

  //Se actualiza la entity de groups cuando cambia la organización
  useEffect(() => {
    if (isFetchedListEntities) fetchGroups(groupsFilters);
  }, [isFetchedListEntities, useGroups, groupsFilters?.organizationId]);

  //Se actualiza la entity de contratos de organizacion cuando cambia la organización y operador
  useEffect(() => {
    if (isFetchedListEntities) fetchOrganizationContracts(organizationContractsFilters);
  }, [
    isFetchedListEntities,
    useOrganizationContracts,
    organizationContractsFilters?.operatorId,
    organizationContractsFilters?.organizationId
  ]);

  //Se actualiza la entity de plantillas de contratos cuando cambia el operador
  useEffect(() => {
    if (isFetchedListEntities) fetchContracTemplates(contractTemplatesFilters);
  }, [
    isFetchedListEntities,
    useContractTemplates,
    contractTemplatesFilters?.operatorId,
    contractTemplatesFilters?.organizationId
  ]);

  //Se actualiza la entity de modules cuando cambia la operador u organización
  useEffect(() => {
    if (isFetchedListEntities) fetchModules(modulesFilters);
  }, [
    isFetchedListEntities,
    useModules,
    modulesFilters?.operatorId,
    modulesFilters?.organizationId
  ]);

  //Se actualiza la entity de sims cuando cambia la operador u organización
  useEffect(() => {
    if (isFetchedListEntities) fetchSims(simsFilters);
  }, [isFetchedListEntities, useSims, simsFilters?.operatorId, simsFilters?.organizationId]);

  //Se actualiza la entity de class service
  useEffect(() => {
    if (isFetchedListEntities) fetchServiceClasses();
  }, [isFetchedListEntities, useServiceClasses]);

  //Se actualiza la entity de class service
  useEffect(() => {
    if (isFetchedListEntities) fetchServiceClassesDropdown();
  }, [isFetchedListEntities, useServiceClassesDropdown]);

  //Se actualiza la entity de protocolos
  useEffect(() => {
    if (isFetchedListEntities) fetchProtocols(protocolsFilters);
  }, [
    isFetchedListEntities,
    useProtocols,
    protocolsFilters?.operatorId,
    protocolsFilters?.organizationId
  ]);

  return null;
};

FetchEntities.defaultProps = {
  useUnits: false,
  useSubdivisions: false,
  useMaintenanceLines: false,
  useVehicles: false,
  useSims: false,
  useModules: false,
  useDrivers: false,
  useAgents: false,
  useServiceClasses: false,
  useUsers: false,
  useOperators: false,
  useDivisions: false,
  useOrganizations: false,
  useContacts: false,
  useWorkplans: false,
  useGeofences: false,
  useSchedules: false,
  useForms: false,
  useRoutes: false,
  useCountries: false,
  useLocations: false,
  useAssets: false,
  useSensors: false,
  useJobsBacklog: false,
  useGroups: false,
  useCustomers: false,
  useOrganizationContracts: false,
  useContractTemplates: false,
  useProtocols: false,
  intervalTime: null, //milliseconds
  additionalEntities: [] //list of entitytypes (int) to also load
};

export default FetchEntities;
