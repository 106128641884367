import * as types from '../types';

export const startFetchingDrivers = (filters = {}) => ({
  type: types.DRIVERS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingDrivers = (entities, order, operatorId, organizationId) => ({
  type: types.DRIVERS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingDrivers = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.DRIVERS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingDrivers = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.DRIVERS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingDrivers = (entities, order, operatorId, organizationId) => ({
  type: types.DRIVERS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingDrivers = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.DRIVERS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingDriver = (driver, person) => ({
  type: types.DRIVER_ADD_STARTED,
  payload: driver,
  person: person
});
export const completeAddingDriver = (oldId, driver) => ({
  type: types.DRIVER_ADD_COMPLETED,
  payload: { oldId, driver }
});
export const failAddingDriver = (oldId, error) => ({
  type: types.DRIVER_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingDriver = (driver, person) => ({
  type: types.DRIVER_EDIT_STARTED,
  payload: driver,
  person: person
});
export const completeEditingDriver = (driver) => ({
  type: types.DRIVER_EDIT_COMPLETED,
  payload: driver
});
export const failEditingDriver = (oldDriver, error) => ({
  type: types.DRIVER_EDIT_FAILED,
  payload: {
    oldDriver,
    error
  }
});

export const startRemovingDriver = (driverId, person) => ({
  type: types.DRIVER_REMOVE_STARTED,
  payload: {
    driverId
  },
  person: person
});
export const completeRemovingDriver = (driverId) => ({
  type: types.DRIVER_REMOVE_COMPLETED,
  payload: {
    driverId
  }
});
export const failRemovingDriver = (driverId, error) => ({
  type: types.DRIVER_REMOVE_FAILED,
  payload: {
    driverId,
    error
  }
});

export const selectDriver = (driverId) => ({
  type: types.DRIVER_SELECTED,
  payload: driverId
});

export const selectAllDrivers = (
  driverIds = [] //List of DriverIds
) => ({
  type: types.DRIVERS_ALL_SELECTED,
  payload: driverIds
});

export const deselectDriver = (driverId) => ({
  type: types.DRIVER_DESELECTED,
  payload: driverId
});

export const deselectAllDrivers = (
  driverIds = [] //List of DriverIds
) => ({
  type: types.DRIVERS_ALL_DESELECTED,
  payload: driverIds
});

export const startMassiveDriversAssignment = (driversAndUnitsIds: Array<any>, membership: any) => ({
  type: types.START_MASSIVE_DRIVERS_ASSIGNMENT,
  payload: { driversAndUnitsIds, membership }
});
export const completeMassiveDriversAssignment = (data) => ({
  type: types.COMPLETE_MASSIVE_DRIVERS_ASSIGNMENT,
  payload: data
});
export const errorMassiveDriversAssignment = (data) => ({
  type: types.COMPLETE_MASSIVE_DRIVERS_ASSIGNMENT,
  payload: data
});

export const startImportData = (dataToImport: Array<any>) => ({
  type: types.START_IMPORT_DRIVERS_UNITS_IMPORT,
  payload: dataToImport
});
export const completeImportDriver = (importedData) => ({
  type: types.COMPLETE_IMPORT_DRIVERS_UNITS_IMPORT,
  payload: importedData
});

export const startDriverAssignSensor = (driverId, sensor) => ({
  type: types.DRIVER_ASSIGN_SENSOR_STARTED,
  payload: { driverId, sensor }
});
export const completeDriverAssignSensor = (driverId, sensor) => ({
  type: types.DRIVER_ASSIGN_SENSOR_COMPLETED,
  payload: { driverId, sensor }
});

export const startDriverUnassignSensor = (driverId, sensor) => ({
  type: types.DRIVER_UNASSIGN_SENSOR_STARTED,
  payload: { driverId, sensor }
});
export const completeDriverUnassignSensor = (driverId, sensor) => ({
  type: types.DRIVER_UNASSIGN_SENSOR_COMPLETED,
  payload: { driverId, sensor }
});
