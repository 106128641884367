import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/routesComments';
import * as types from '../../types/routesComments';
import * as selectors from '../../reducers/routesComments';
import * as schemas from '../../schemas/routesComments';
import * as entitySelectors from '../../../Entities/reducers';
import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { store } from 'redux/storeConfig/store';
import {
  deleteCommentService,
  getRouteCommentsListService,
  saveCommentService
} from '../../services/routesComments';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchCommentsList(action) {
  const { routeAssignmentId } = action.payload;

  getRouteCommentsListService(
    { routeAssignmentId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { comments },
          result //order
        } = normalize(resultData, schemas.comments); //normalize data to byId and order
        store.dispatch(actions.completeFetchingComments(comments, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingComments(error));
      }
    }
  );
}

export function* watchFetchCommentsList() {
  yield takeEvery(types.ROUTES_EXECUTION_COMMENTS_FETCH_STARTED, fetchCommentsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addComment(action) {
  const comment = action.payload;
  saveCommentService(
    { ...comment, routeCommentId: null },
    {
      successAction: (response) => {
        const addedComment = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingComment(comment.routeCommentId, addedComment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingComment(comment.routeCommentId, error));
      }
    }
  );
}

export function* watchAddCommentsStarted() {
  yield takeEvery(types.ROUTES_EXECUTION_COMMENT_ADD_STARTED, addComment);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editComment(action) {
  const comment = action.payload;
  const { oldComment } = yield select((state) =>
    selectors.getRoutesComment(state, comment.routeCommentId)
  );
  saveCommentService(
    { ...comment },
    {
      successAction: (response) => {
        const editedComment = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingComment(editedComment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingComment(oldComment, error));
      }
    }
  );
}

export function* watchEditCommentsStarted() {
  yield takeEvery(types.ROUTES_EXECUTION_COMMENT_EDIT_STARTED, editComment);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeComment(action) {
  const { routeCommentId } = action.payload;
  const deletedComment = yield select((state) => selectors.getRoutesComment(state, routeCommentId));
  deleteCommentService(
    { ...deletedComment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingComment(routeCommentId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingComment(routeCommentId, error));
      }
    }
  );
}

export function* watchRemoveCommentStarted() {
  yield takeEvery(types.ROUTES_EXECUTION_COMMENT_REMOVE_STARTED, removeComment);
}
