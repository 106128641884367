//filtrar data de vehiculos por performanceStatus segun rango seleccionado por del usuario
import { useMemo } from 'react';
import camelcaseKeys from "camelcase-keys";
import moment from "moment"
import { all } from "redux-saga/effects"
import { isNullOrUndefined } from "utility/Utils"

export const performanceColors = ['#FF4757', '#ffa437', '#2ED573', '#1E90FF', '#7d39f6'];

const vehiclePerformanceTitle = {
  Deficient : 'common.deficient',
  Regular : 'common.regular',
  Efficient : 'common.efficient',
  Top : 'common.top'
}

/**
 * Get translated title based on performance status
 * @param {number} status
 * @param {[number, number, number, number]} rangePerformance 
 * @returns {string | null} Translated title
 */
export const getPerformanceTitle = (status, rangePerformance) => {
  const performanceStatus = Math.round(status)

  const isDeficient = performanceStatus <= rangePerformance[1] - 1;
  const isRegular = performanceStatus >= rangePerformance[1] && performanceStatus <= rangePerformance[2] - 1;
  const isEfficient = performanceStatus >= rangePerformance[2] && performanceStatus <= rangePerformance[3];
  const isTop = performanceStatus > rangePerformance[3] && performanceStatus < 200;

  let vehiclePerformance = null;

  if (isTop) {
    vehiclePerformance = vehiclePerformanceTitle.Top;
  } else if (isEfficient) {
    vehiclePerformance = vehiclePerformanceTitle.Efficient;
  } else if (isRegular) {
    vehiclePerformance = vehiclePerformanceTitle.Regular;
  } else if (isDeficient) {
    vehiclePerformance = vehiclePerformanceTitle.Deficient;
  }

  return vehiclePerformance;
}

/**
 * Get color based on performance status
 * @param {number} status 
 * @param {number | undefined} expectedPerformance 
 * @param {number[]} rangePerformance 
 * @returns {string} Hex color
 */
export const getPerformanceColor = (status, expectedPerformance, rangePerformance) => {

  const performanceStatus = Math.round(status)

    return  (!performanceStatus || ((performanceStatus <= rangePerformance[1] - 1))
      ? performanceColors[0]
      : performanceStatus >= rangePerformance[1] && performanceStatus <= rangePerformance[2] - 1
      ? performanceColors[1]
      : performanceStatus >= rangePerformance[2] && performanceStatus <= rangePerformance[3]
      ? performanceColors[2]
      : performanceStatus > rangePerformance[3] && performanceStatus < 200
      ? performanceColors[3]
      : performanceColors[4])
};

const getAllFilteredData = (byVehicles,ranges = [0,79,80,89,90,110]) =>{
    

  const unitsWithRecentData = byVehicles.filter(v => {

    console.log('vehicle',v)

    const diff = moment().diff(v.systemDate, 'days');
    const hasRecentData = diff <= 7;
    const hasNoDowntimeReasonId = isNullOrUndefined(v.downTimeReasonId);
    const hasPerformance = (parseFloat(v.expectedfuelEconomyByVehicles) > 0 &&
     (parseFloat(v.travel) >= parseFloat(v.expectedfuelEconomyByVehicles) && 
     parseFloat(v.travel) >= (parseFloat(v.expectedfuelEconomyByVehicles) * parseFloat(v.loadThreshold)) && 
     parseFloat(v.consumption) > 1) || v.historicalFuelEconomy > 0)
    
    return hasRecentData && hasNoDowntimeReasonId && hasPerformance;
  });

  const unitsWithoutRecentData  = byVehicles.filter(v => {
    const diff = moment().diff(v.systemDate, 'days');
    const hasNoRecentData = diff > 7;
    const hasNoDowntimeReasonId = isNullOrUndefined(v.downTimeReasonId);

    return (hasNoRecentData && hasNoDowntimeReasonId) || isNullOrUndefined(v.gpsDate);
    });

    const unitsWithoutPerformance = byVehicles.filter(v => {
      const diff = moment().diff(v.systemDate, 'days');
      const hasRecentData = diff <= 7;
      const hasNoDowntimeReasonId = isNullOrUndefined(v.downTimeReasonId);
      const hasNoPerformance = parseFloat(v.expectedfuelEconomyByVehicles) <= 0 || parseFloat(v.travel) < parseFloat(v.expectedfuelEconomyByVehicles) || parseFloat(v.consumption) < 1
      
      return hasRecentData && hasNoDowntimeReasonId && (hasNoPerformance && v.historicalFuelEconomy <= 0);
    });

  

    const dowtimeUnits = byVehicles.filter((u)=>u.downTimeReasonId)





    //0,80,90,110,200
    const allFilters = {
        poorPerformance: filterTelemetryData(ranges[0],ranges[1]-1, unitsWithRecentData),
        regularPerformance: filterTelemetryData(ranges[1], ranges[2]-1, unitsWithRecentData),
        goodPerformance: filterTelemetryData(ranges[2], ranges[3], unitsWithRecentData),
        overPerformance: filterTelemetryData(ranges[3]+1, 0, unitsWithRecentData),
        excessivePerformance: filterTelemetryData(200, 0, unitsWithRecentData),
        unitsWithDischarges: filterTelemetryData(0.1, 0, unitsWithRecentData, 'unloaded'),
        unitsWithoutDischarges: filterTelemetryData(0, 0, unitsWithRecentData, 'unloaded'),
        unitsWithRecentData:unitsWithRecentData,
        unitsWithoutRecentData:unitsWithoutRecentData,
        dowtimeUnits: dowtimeUnits,
        unitsWithoutPerformance: unitsWithoutPerformance
    }

    return {...allFilters,goodUnitsPercent: (allFilters.goodPerformance.length * 100) / unitsWithRecentData.length}
}


/* 
min: 0  max:0  condition: property=== "0.00"
min: 80 max:0  condition: property >= min
min: 10 max:50 condition: property <= max && property >= min
*/
const filterTelemetryData = (min = 0, max = 0, vehiclesData, property = 'performanceStatus') => {
if(min === 0 && max === 0) {
    const filtered = vehiclesData.filter((v) => v[property] === "0.00");
    
    return filtered
}else{
    const filtered = max !== 0 
    ? vehiclesData.filter((v) => v.[property] <= max && v[property] >= min) 
    : vehiclesData.filter((v) => v[property] >= min);
    return filtered;
}
};

const dec2bin = (dec) => {
    var binary = '';
    while (dec > 0) {
      binary = (dec % 2) + binary;
      dec = Math.floor(dec / 2);
    }
    while (binary.length < 32) {
        binary = '0' + binary;
      }
    return binary;
  }


  const reverseBinary = (binary) => {
    var reversedBinary = '';
    for (var i = binary.length - 1; i >= 0; i--) {
      reversedBinary += binary[i];
    }
    return reversedBinary;
  };


  const dec2binReverse = (dec) => {
    const binary = dec2bin(dec);
    return reverseBinary(binary);
  };

  const searchUseMetric = (metricsMask, bitNumber)=>{

    return (metricsMask & (2 ** bitNumber)) == 2 ** bitNumber

  }

const Metrics = {
    ACCState: {
        MetricId: 1,
        MetricName: "ACCState",
        BitNumber: 0,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 1
      },
      ACCX: {
        MetricId: 2,
        MetricName: "ACCX",
        BitNumber: 1,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      ACCY: {
        MetricId: 3,
        MetricName: "ACCY",
        BitNumber: 2,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      ACCZ: {
        MetricId: 4,
        MetricName: "ACCZ",
        BitNumber: 3,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      AD1: {
        MetricId: 5,
        MetricName: "AD1",
        BitNumber: 4,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      AD2: {
        MetricId: 6,
        MetricName: "AD2",
        BitNumber: 5,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      AppVersion: {
        MetricId: 7,
        MetricName: "AppVersion",
        BitNumber: 6,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 1,
        OrderNumber: 10
      },
      BackupBattery: {
        MetricId: 8,
        MetricName: "BackupBattery",
        BitNumber: 7,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 5
      },
      Battery: {
        MetricId: 9,
        MetricName: "Battery",
        BitNumber: 8,
        UseInVehicles: 0,
        UseInAssets: 1,
        UseInPeople: 1,
        OrderNumber: 2
      },
      CellNumber: {
        MetricId: 10,
        MetricName: "CellNumber",
        BitNumber: 9,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 15
      },
      CoverTamperState: {
        MetricId: 11,
        MetricName: "CoverTamperState",
        BitNumber: 10,
        UseInVehicles: 0,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 7
      },
      DoorState: {
        MetricId: 12,
        MetricName: "DoorState",
        BitNumber: 11,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 6
      },
      FirmwareVersion: {
        MetricId: 13,
        MetricName: "FirmwareVersion",
        BitNumber: 12,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      GSMSignalLevel: {
        MetricId: 14,
        MetricName: "GSMSignalLevel",
        BitNumber: 13,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      HardwareVersion: {
        MetricId: 15,
        MetricName: "HardwareVersion",
        BitNumber: 14,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      ICCID: {
        MetricId: 16,
        MetricName: "ICCID",
        BitNumber: 15,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 18
      },
      IsCharging: {
        MetricId: 17,
        MetricName: "IsCharging",
        BitNumber: 16,
        UseInVehicles: 0,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 3
      },
      MainVoltage: {
        MetricId: 18,
        MetricName: "MainVoltage",
        BitNumber: 17,
        UseInVehicles: 1,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 4
      },
      MCC: {
        MetricId: 19,
        MetricName: "MCC",
        BitNumber: 18,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 13
      },
      MNC: {
        MetricId: 20,
        MetricName: "MNC",
        BitNumber: 19,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 14
      },
      OverSpeedState: {
        MetricId: 21,
        MetricName: "OverSpeedState",
        BitNumber: 20,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      PadlockAuthorizationType: {
        MetricId: 22,
        MetricName: "PadlockAuthorizationType",
        BitNumber: 21,
        UseInVehicles: 0,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 8
      },
      PadlockState: {
        MetricId: 23,
        MetricName: "PadlockState",
        BitNumber: 22,
        UseInVehicles: 0,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 9
      },
      PLMN: {
        MetricId: 24,
        MetricName: "PLMN",
        BitNumber: 23,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 12
      },
      Satellites: {
        MetricId: 25,
        MetricName: "Satellites",
        BitNumber: 24,
        UseInVehicles: 1,
        UseInAssets: 1,
        UseInPeople: 0,
        OrderNumber: 11
      },
      ShockSensorState: {
        MetricId: 26,
        MetricName: "ShockSensorState",
        BitNumber: 25,
        UseInVehicles: 0,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 0
      },
      TrailerID: {
        MetricId: 27,
        MetricName: "TrailerID",
        BitNumber: 26,
        UseInVehicles: 1,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 17
      },
      DriverId: {
        MetricId: 28,
        MetricName: "DriverId",
        BitNumber: 27,
        UseInVehicles: 1,
        UseInAssets: 0,
        UseInPeople: 0,
        OrderNumber: 16
      }
    
  };


  const getSummaryDataForDynamicTable = (performanceSummary,intl)=>{


    if (performanceSummary?.length > 0) {
      const statuses = ['efficient', 'top', 'regular', 'deficient'];

      // Initialize an object to store the results
      const result = {};

      // Iterate through the data
      performanceSummary?.forEach((row) => {
        const weekNumber = row.weekNumber;
        // Iterate through the statuses
        statuses.forEach((status) => {
          const statusLowerCase = status.toLowerCase();
          // Create or update the status object
          if (!result[statusLowerCase]) {
            result[statusLowerCase] = {};
          }
          // Creamos un nuevo campo con el total del estado en la semana de la iteracion
          result[statusLowerCase][`totalWeek${weekNumber}`] = `${row[statusLowerCase]} - ${(
            (row[statusLowerCase] / row.total) *
            100
          ).toFixed(0)} %`;
        });
      });

      // Convert the result object to an array of objects
      const newConsolidatedInfo = Object.keys(result).map((status) => ({
        status: intl.formatMessage({ id: `common.${status}` }),
        ...result[status]
      }));


      return newConsolidatedInfo
    } 

    return []

  }





  function useCombinedFuelHistory(data, samples, fuelEvents, sensors, selectedSensor) {
    const combinedData = useMemo(() => {
      return data?.fuelHistory?.map((result) => {
        const resultFinal = camelcaseKeys(result);
        const match = fuelEvents.find(
          (event) => event.unitPositionLogId === resultFinal.unitPositionLogId
        );
        const isSensorValid =
          sensors?.length === 1 ||
          (match !== undefined && selectedSensor?.sensorId === 0 && sensors?.length > 0);
  
        if (isSensorValid) {
          const sampleMatch = samples?.fuelSamples?.find(
            (sample) =>
              sample.unitPositionLogId === resultFinal.unitPositionLogId &&
              resultFinal.eventId !== 332 &&
              resultFinal.eventId !== 331
          );
          return {
            ...resultFinal,
            ...(match || sampleMatch)
          };
        }
  
        return resultFinal;
      });
    }, [data, samples, fuelEvents, sensors, selectedSensor]);
  
    return combinedData;
  }
  
 

export { filterTelemetryData,getAllFilteredData,Metrics,dec2binReverse,searchUseMetric,getSummaryDataForDynamicTable,useCombinedFuelHistory };
