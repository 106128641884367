import { env } from 'env';
import { DateTime } from 'luxon';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { htmlToText } from 'html-to-text';
import moment, { Moment } from 'moment';
import _ from 'lodash';
import { transform, isObject, isArray, camelCase, isDate } from 'lodash';
import { FormatListOptions, IntlShape } from 'react-intl';
import {
  Browser,
  DeviceInfo,
  DeviceType,
  KeyboardModifiers,
  OperatingSystem,
  optionKeySpecialCharacters,
  optionShiftSpecialCombinations
} from './types';
import exceldate from 'exceldate';

export const imageFormatsForReports = ['png', 'jpg', 'jpeg'];

// Parse HTML to text to use en tooltips
export const formatHtmlToText = (htmlStr) => {
  return htmlStr ? htmlToText(htmlStr, { wordwrap: 130 }) : 'N/A';
};

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Date minus TimezoneOffset
export const getDateWithoutTimezoneOffset = (date) =>
  date
    ? typeof date.getTime === 'function'
      ? new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString().slice(0, -1)
      : date
    : null;

// etiqueta con formato HH : MM para indicadores
export const generateHourMinuteLabel = (seconds: number, round: boolean = false) => {
  let hours = 0;
  let minutes = 0;

  if (round === true) {
    seconds += 60;
  }

  if (seconds >= 3600) {
    hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
  }

  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60) % 60;
    seconds -= minutes * 60;
  }

  return `${hours > 9 ? hours : '0' + hours} Hrs : ${minutes > 9 ? minutes : '0' + minutes} Mins`;
};

// etiqueta con formato D : HH : MM : SS para indicadores
export const generateDayHourMinuteSecondLabel = (seconds, round = false) => {
  let days = 0;
  let hours = 0;
  let minutes = 0;

  if (round === true) {
    seconds += 60;
  }

  if (seconds >= 86400) {
    days = Math.floor(seconds / 86400);
    seconds -= days * 86400;
  }

  if (seconds >= 3600) {
    hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
  }

  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60) % 60;
    seconds -= minutes * 60;
  }

  seconds = Math.floor(seconds);

  return `${days > 0 ? days + 'D ' : ''}${hours > 9 ? hours : '0' + hours} Hrs : ${
    minutes > 9 ? minutes : '0' + minutes
  } Mins : ${seconds > 9 ? seconds : '0' + seconds} S`;
};

// etiqueta con formato HH:MM:SS
export const generateHourMinuteSecondStr = (seconds) => {
  seconds = parseInt(seconds.toFixed(0));
  let hours = 0;
  let minutes = 0;

  if (seconds >= 3600) {
    hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
  }

  if (seconds >= 60) {
    minutes = Math.floor(seconds / 60) % 60;
    seconds -= minutes * 60;
  }

  return `${hours > 9 ? hours : '0' + hours}:${minutes > 9 ? minutes : '0' + minutes}:${
    seconds > 9 ? seconds : '0' + seconds
  }`;
};

// ** Returns K format from a number
export const kFormatter = (num) => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num);

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '');

// función que retorna DateTime.now() con offset de usuario
export const todayWithUtcOffset = (utcOffset?: number) =>
  utcOffset ? DateTime.utc().plus({ minutes: +utcOffset }) : DateTime.now();

// función que retorna la cantidad de horas transcurridas en un rango de fechas
// si el rango incluye el día de hoy entonces las horas transcurridas son hasta DateTime.now()
// -- si endDate es > a DateTime.now() siempre se usará DateTime.now() como fecha fin

export const elapsedHoursInDateRange = (
  startDate: string | Date | DateTime,
  endDate: string | Date | DateTime,
  utcOffset?: number
) => {
  const today = todayWithUtcOffset(utcOffset);

  const dtStart: DateTime =
    typeof startDate === 'string'
      ? DateTime.fromISO(startDate)
      : startDate instanceof DateTime
      ? startDate
      : DateTime.fromJSDate(startDate);

  const dtEnd: DateTime =
    typeof endDate === 'string'
      ? DateTime.fromISO(endDate)
      : endDate instanceof DateTime
      ? endDate
      : DateTime.fromJSDate(endDate);

  return dtStart.diff(DateTime.min(today, dtEnd), 'hours').toObject().hours;
};

// función que retorna la cantidad de horas transcurridas en un día
// si ese día es hoy entonces las horas transcurridas son hasta DateTime.now()
export const elapsedHoursInDate = (date: string | Date | DateTime, utcOffset?: number) => {
  const today = todayWithUtcOffset(utcOffset);

  const dt: DateTime =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : date instanceof DateTime
      ? date
      : DateTime.fromJSDate(date);

  return isToday(dt, utcOffset) ? today.diff(dt, 'hours').toObject().hours : 24.0;
};

// ** Checks if the passed date is today
export const isToday = (date: string | Date | DateTime, utcOffset?: number) => {
  const today = todayWithUtcOffset(utcOffset);

  const dt: DateTime =
    typeof date === 'string'
      ? DateTime.fromISO(date)
      : date instanceof DateTime
      ? date
      : DateTime.fromJSDate(date);

  return (
    /* eslint-disable operator-linebreak */
    dt.get('day') === today.get('day') &&
    dt.get('month') === today.get('month') &&
    dt.get('year') === today.get('year')
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting: Intl.DateTimeFormatOptions = { month: 'short', day: 'numeric' };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' };
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  let token = localStorage.getItem('auth');
  let userData = getUserData();
  return token && userData && userData.exp > Date.now() / 1000;
};
export const getUserData = () => JSON.parse(localStorage?.getItem('userData') || '{}');
/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === 'admin') return '/';
  if (userRole === 'client') return '/access-control';
  return '/login';
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
});

// Checks if given strings is a valid email
// return bool
export const isValidEmail = (email) => {
  const emailRe =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRe.test(String(email).toLowerCase());
};

// Checks if given strings is a valid email
// returns bool
export const isNumber = (stringToCheck) => {
  const numberRe = /^\d+$/;
  return numberRe.test(stringToCheck);
};

export function numberWithCommas(x) {
  if (!x) return '0';
  //Trunco el número a 2 decimales
  x = trunc(x, 2);
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function formatAmount(n: number | string) {
  if (!isValidNumber(n).test) return Number('0').toFixed(2);

  return Number(n)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function trunc(x, posiciones = 0) {
  if (!x.toString().includes('.')) {
    return x.toString();
  }
  var decimalLength = x.toString().indexOf('.') + 1;
  var numStr = x.toString().substr(0, decimalLength + posiciones);
  return numStr;
}

export const isBitSet = (b, pos) => (b & (1 << pos)) !== 0;

export const convertTime = (time) => ({
  ...time,
  startTime: DateTime.local(
    2000,
    1,
    2,
    ...time.startTime.split(':').map((time) => +time)
  ).toString(),
  endTime: DateTime.local(2000, 1, 2, ...time.endTime.split(':').map((time) => +time)).toString()
});

export const isIterable = (obj) => obj != null && typeof obj[Symbol.iterator] === 'function';

export const imagesLink = 'https://pictures.webtrack.online/partner/desarrollo/FormsFiles/';

/**
 * Get days between date and today
 * @param date
 * @returns Days between date and today
 */
export const getDaysBetweenDateAndToday = (date) => {
  const intl: IntlShape = appIntl();
  const startDate = new Date(date);
  const endDate = new Date();
  var seconds = Math.abs(endDate?.getTime() - startDate?.getTime()) / 1000;

  if (seconds <= 60) {
    return intl.formatMessage({ id: 'units.aFewSecondsAgo' });
  }

  var startYear = startDate?.getFullYear();
  var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var yearDiff = endDate?.getFullYear() - startYear;
  var monthDiff = endDate?.getMonth() - startDate?.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate?.getDate() - startDate?.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate?.getMonth()];
  }

  var weekDiff = dayDiff >= 7 ? Math.floor(dayDiff / 7) : 0;
  dayDiff -= 7 * weekDiff;

  var hourDiff = 0;
  var minuteDiff = 0;

  if (seconds >= 60 && seconds < 3600) {
    minuteDiff = Math.round(seconds / 60);
  } else if (seconds >= 3600 && seconds < 86400) {
    hourDiff = Math.round(seconds / 3600);
    dayDiff = 0;
  }

  return yearDiff > 0
    ? yearDiff > 1
      ? `${intl.formatMessage({ id: 'units.yearsAgo' }, { value: yearDiff })}`
      : `${intl.formatMessage({ id: 'units.aYearAgo' })}`
    : monthDiff > 0
    ? monthDiff > 1
      ? `${intl.formatMessage({ id: 'units.monthsAgo' }, { value: monthDiff })}`
      : `${intl.formatMessage({ id: 'units.aMonthAgo' })}`
    : weekDiff > 0
    ? weekDiff > 1
      ? `${intl.formatMessage({ id: 'units.weeksAgo' }, { value: weekDiff })}`
      : `${intl.formatMessage({ id: 'units.aWeekAgo' })}`
    : dayDiff > 0
    ? dayDiff > 1
      ? `${intl.formatMessage({ id: 'units.daysAgo' }, { value: dayDiff })}`
      : `${intl.formatMessage({ id: 'units.aDayAgo' })}`
    : hourDiff > 0
    ? hourDiff > 1
      ? `${intl.formatMessage({ id: 'units.hoursAgo' }, { value: hourDiff })}`
      : `${intl.formatMessage({ id: 'units.anHourAgo' })}`
    : minuteDiff > 0
    ? minuteDiff > 1
      ? `${intl.formatMessage({ id: 'units.minutesAgo' }, { value: minuteDiff })}`
      : `${intl.formatMessage({ id: 'units.aMinuteAgo' })}`
    : '';
};

//Genera el listado de fechas en un rango de fechas establecido en el DateInput
export const getDateColumnsInDateRange = (intl, dateRange: string[], stacked: boolean = false) => {
  if (dateRange && dateRange.length > 0) {
    let startDate = new Date(dateRange[0]);
    let endDate = new Date(dateRange[1]);
    let dateArray: any = [];

    while (startDate <= endDate) {
      dateArray.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
    return dateArray.map((date) => {
      const dateTitle =
        date.getFullYear() +
        '-' +
        (date.getMonth() + 1 < 10 ? '0' : '') +
        (date.getMonth() + 1) +
        '-' +
        (date.getDate() < 10 ? '0' : '') +
        date.getDate();

      return {
        fieldName: stacked ? undefined : dateTitle,
        customKey: stacked ? dateTitle : undefined,
        staticText:
          date.getDay() === 0
            ? `${intl.formatMessage({ id: 'common.sun' })} ${date.getDate()}`
            : date.getDay() === 1
            ? `${intl.formatMessage({ id: 'common.mon' })} ${date.getDate()}`
            : date.getDay() === 2
            ? `${intl.formatMessage({ id: 'common.tue' })} ${date.getDate()}`
            : date.getDay() === 3
            ? `${intl.formatMessage({ id: 'common.wed' })} ${date.getDate()}`
            : date.getDay() === 4
            ? `${intl.formatMessage({ id: 'common.thu' })} ${date.getDate()}`
            : date.getDay() === 5
            ? `${intl.formatMessage({ id: 'common.fri' })} ${date.getDate()}`
            : date.getDay() === 6
            ? `${intl.formatMessage({ id: 'common.sat' })} ${date.getDate()}`
            : ''
      };
    });
  } else {
    return [];
  }
};

export const getWeekColumns = (intl, data: Array<any>, stacked: boolean = false) => {
  const weeks = [];

  if (data) {
    data.forEach((item: any) => {
      if (item.weekNumber && !weeks.includes(item.weekNumber)) {
        weeks.push(item.weekNumber);
      }
    });

    const columns = weeks.map((week) => {
      const startWeekDate = DateTime.fromISO(
        data?.find((d) => d.weekNumber === week).startWeekDate
      ).toFormat('yyyy-MM-dd');

      const endWeekDate = DateTime.fromISO(
        data?.find((d) => d.weekNumber === week).endWeekDate
      ).toFormat('yyyy-MM-dd');

      const weekColumns = [
        {
          fieldName: `performance-week${week}`,
          headerText: 'jobs.reports.performance',
          customAttributes: { class: 'side-borders-columns-subColumns' }
        },
        {
          fieldName: `travel-week${week}`,
          headerText: 'jobs.reports.travel',
          customAttributes: { class: 'side-borders-columns-subColumns' }
        },
        {
          fieldName: `consumption-week${week}`,
          headerText: 'jobs.reports.workTime',
          customAttributes: { class: 'side-borders-columns-subColumns' }
        },
        {
          fieldName: `total-week${week}`,
          headerText: 'jobs.reports.workTime',
          customAttributes: { class: 'side-borders-columns-subColumns' }
        }
      ];

      return {
        customKey: `week${week}`,
        staticText: `${
          intl.formatMessage({ id: 'common.week' }) + ` ${week}`
        } | ${startWeekDate} -> ${endWeekDate}`,
        customAttributes: { class: 'side-borders-columns-header' },
        columns: weekColumns
      };
    });

    return columns;
  } else {
    return [];
  }
};

const userTimezoneOffset = new Date().getTimezoneOffset() * 60000;
export const convertExcelDateToSystemDate = (excelDate) => {
  if (typeof excelDate === 'string') {
    return excelDate;
  } else {
    let systemDate = moment(
      new Date(Math.round((excelDate - 25569) * 86400 * 1000) + userTimezoneOffset)
    ).format('YYYY-MM-DD HH:mm');
    return systemDate;
  }
};

export interface ValidatorsReturn {
  test: boolean;
  errorMessage?: string;
}

/**
 * Is Alpha Numeric Or Empty
 * @name isAlphaNumericOrEmpty
 * @description Test if value is valid or empty
 * @param value Unknown value to test or empty
 * @returns ValidatorsReturn
 */
export const isAlphaNumericOrEmpty = (value: unknown): ValidatorsReturn => {
  if (!isNotNullOrUndefined(value)) {
    return {
      test: true,
      errorMessage: undefined
    };
  }

  // Additional verification for empty values (spaces)
  if (typeof value === 'string') {
    const valueToValidate = String(value).trim();
    if (valueToValidate.length === 0) {
      return {
        test: true,
        errorMessage: undefined
      };
    }
  }

  return isAlphaNumeric(value);
};

/**
 * Is Alpha Numeric
 * @name isAlphaNumeric
 * @description Test if value is valid
 * @param value Unknown value to test
 * @returns ValidatorsReturn
 */
export const isAlphaNumeric = (value: unknown): ValidatorsReturn => {
  const alpha = /^.+$/;
  switch (typeof value) {
    case 'string': {
      const valueToValidate = String(value).trim();
      const test = isNotNullOrUndefined(value) ? alpha.test(valueToValidate) : false;

      if (valueToValidate.length === 0) {
        return {
          test: false,
          errorMessage: appIntl().formatMessage({ id: 'validators.alphaNumeric' })
        };
      }

      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'number': {
      const test = isNotNullOrUndefined(value) ? alpha.test(String(value)) : false;
      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'boolean': {
      const test = isNotNullOrUndefined(value) ? alpha.test(String(value)) : false;
      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'object': {
      const test = isNotNullOrUndefined(value) ? alpha.test(String(value)) : false;
      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'undefined': {
      return {
        test: false,
        errorMessage: appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'function': {
      return {
        test: false,
        errorMessage: appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'symbol': {
      return {
        test: false,
        errorMessage: appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
    case 'bigint': {
      const test = isNotNullOrUndefined(value) ? alpha.test(String(value)) : false;
      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }

    default: {
      const test = isNotNullOrUndefined(value) ? alpha.test(String(value)) : false;
      return {
        test: test,
        errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.alphaNumeric' })
      };
    }
  }
};

/**
 * Is Valid Number Or Empty
 * @name isValidNumberOrEmpty
 * @description Test if value is valid or empty
 * @param value Unknown value to test or empty
 * @returns ValidatorsReturn
 */
export const isValidNumberOrEmpty = (value: unknown): ValidatorsReturn => {
  if (!isNotNullOrUndefined(value)) {
    return {
      test: true,
      errorMessage: undefined
    };
  }

  return isValidNumber(value);
};

/**
 * Is Valid Number
 * @name isValidNumber
 * @description Test if value is valid
 * @param value Unknown value to test
 * @returns ValidatorsReturn
 */
export const isValidNumber = (value: unknown): ValidatorsReturn => {
  let numeric = /^\d*\.?\d*$/;
  let test = numeric.test(String(value));

  // Test if value is a number
  if (test) {
    return { test: test, errorMessage: undefined };
  } else {
    return { test: test, errorMessage: appIntl().formatMessage({ id: 'validators.number' }) };
  }
};

/**
 * Validate last 4 digits of a card
 * @name validLast4Digits
 * @description Test if value is valid
 * @param value {Unknown} value to test
 * @returns {ValidatorsReturn}
 */
export const validLast4Digits = (value: unknown): ValidatorsReturn => {
  try {
    // Check if the value is a number
    const isNumber = isValidNumber(value);
    // Check if the number is a 4 digits number
    const is4Digits = String(value).length === 4;
    // Check if the number is a 4 digits number and a number
    const is4DigitsNumber = isNumber && is4Digits;
    // Return the validation
    return {
      test: is4DigitsNumber,
      errorMessage: is4DigitsNumber
        ? undefined
        : appIntl().formatMessage({ id: 'validators.last4' })
    };
  } catch (e) {
    return {
      test: false,
      errorMessage: appIntl().formatMessage({ id: 'validators.last4' })
    };
  }
};

/**
 * Validate provided date
 * @name isValidDate
 * @description Test if value is valid
 * @param date {Unknown} value to test
 * @returns {ValidatorsReturn}
 * @example
 * isValidDate('2020-01-01') // true
 * isValidDate('2020-01-01 00:00:00') // true
 * isValidDate('2020-01-01 00:00:00.000') // true
 * isValidDate('2020-01-01 00:00:00.000Z') // true
 * isValidDate('2020-01-01 00:00:00.000+00:00') // true
 */
export const isValidDate = (date): ValidatorsReturn => {
  if (isNullOrUndefined(date) || date === '' || /^\s+$/.test(String(date))) {
    return { test: false, errorMessage: appIntl().formatMessage({ id: 'validators.date' }) };
  }

  const isValid = moment(date, 'YYYY-MM-DD').isValid();
  return {
    test: isValid,
    errorMessage: isValid ? undefined : appIntl().formatMessage({ id: 'validators.date' })
  };
};
export const isValidExcelDate = (excelDate) => {
  const date = convertExcelDateToSystemDate(excelDate);
  const test = moment(date, 'YYYY-MM-DD').isValid();
  return {
    test: test,
    errorMessage: test ? undefined : appIntl().formatMessage({ id: 'validators.date' })
  };
};

export const validateColumns = (dataInfo, columnDefinitions) => {
  const rules = columnDefinitions.map((cd) => cd.rules);
  const newData = dataInfo.map((row, rowIndex) => {
    let invalidColumns = new Array();
    const rowValues = Object.values(row);
    //validar que las columnas esten completas

    rowValues.forEach((colValue, colIndex) => {
      const required = rules[colIndex] ? rules[colIndex]?.required : false;
      const validation = rules[colIndex]
        ? rules[colIndex].validator(colValue)
        : { test: true, errorMessage: '' };

      if (colValue === '') {
        if (required) {
          invalidColumns.push({
            column: colIndex,
            value: colValue,
            error: `Es requerido`
          });
        }
      } else {
        if (validation.test) {
          return;
        } else {
          invalidColumns.push({
            column: colIndex,
            value: colValue,
            error: validation.errorMessage
          });
        }
      }
    });

    return {
      ...row,
      originalRowId: rowIndex + 1,
      invalidColumns: invalidColumns,
      rowIsValid: invalidColumns.length > 0 ? false : true
    };
  });

  return newData;
};

/**
 * Validate provided required value
 * @name validateRequiredValue
 * @description Test if value is valid
 * @param value {Unknown} value to test
 * @returns {ValidatorsReturn}
 * @example
 * validateRequiredValue('') // false
 * validateRequiredValue(null) // false
 * validateRequiredValue('undefined') // false
 * validateRequiredValue('null') // false
 * validateRequiredValue('NaN') // false
 * validateRequiredValue(undefined) // false
 * validateRequiredValue(0) // true
 * validateRequiredValue(1) // true
 * validateRequiredValue('0') // true
 */
const validateRequiredValue = (value: unknown): ValidatorsReturn => {
  const test = value !== null && value !== undefined;

  if (test) {
    return { test: true, errorMessage: undefined };
  } else {
    return {
      test: false,
      errorMessage: appIntl().formatMessage({ id: 'validators.requiredValue' })
    };
  }
};

const validateUniqueValue = (value: unknown, data: any, columnName: any): ValidatorsReturn => {
  const valueCount = data.filter((item) => String(item[columnName]) === String(value)).length;
  const test = valueCount === 1;

  if (test) {
    return { test: true, errorMessage: undefined };
  } else {
    return {
      test: false,
      errorMessage: appIntl().formatMessage({ id: 'validators.repeatedValue' })
    };
  }
};

export const isValidDayOfTheWeek = (value: unknown): ValidatorsReturn => {
  const intl: IntlShape = appIntl();
  if (typeof value === 'number') {
    return {
      test: value >= 1 && value <= 7,
      errorMessage: intl.formatMessage({ id: 'validators.dayOfTheWeek' })
    };
  }

  if (typeof value === 'string') {
    const formattedDayOfTheWeek = intl.formatDate(new Date(value), { weekday: 'long' });

    const dayOfTheWeekMoment = moment(formattedDayOfTheWeek, 'dddd', intl.locale);

    if (dayOfTheWeekMoment.isValid()) {
      return {
        test: true,
        errorMessage: undefined
      };
    }
  }

  return {
    test: false,
    errorMessage: intl.formatMessage({ id: 'validators.requiredValue' })
  };
};

/**
 * Is valid boolean
 * @param value - Value to test
 * @description Test if value is valid
 * @returns {ValidatorsReturn}
 */
export const isValidBoolean = (value: unknown): ValidatorsReturn => {
  if (typeof value === 'boolean') {
    // value is a boolean, which is a valid type for a boolean field
    return { test: true, errorMessage: undefined };
  } else if (typeof value === 'string') {
    const toValidate = value.toLowerCase().trim();
    if (toValidate === 'true' || toValidate === 'false') {
      // value is a string with a valid value for a boolean field
      return { test: true, errorMessage: undefined };
    }
  } else if (typeof value === 'number') {
    if (value === 0 || value === 1) {
      // value is a number with a valid value for a boolean field
      return { test: true, errorMessage: undefined };
    }
  }

  return {
    test: false,
    errorMessage: appIntl().formatMessage({ id: 'validators.requiredValue' })
  };
};

export const isValidBit = (value: unknown): ValidatorsReturn => {
  if (typeof value === 'string') {
    const toValidate = value.toLowerCase().trim();
    if (toValidate === '1' || toValidate === '0') {
      // value is a string with a valid value for a boolean field
      return { test: true, errorMessage: undefined };
    }
  } else if (typeof value === 'number') {
    if (value === 0 || value === 1) {
      // value is a number with a valid value for a boolean field
      return { test: true, errorMessage: undefined };
    }
  }

  return {
    test: false,
    errorMessage: appIntl().formatMessage({ id: 'validators.requiredValue' })
  };
};

export const isValidLatitude = (value: unknown): ValidatorsReturn => {
  switch (typeof value) {
    case 'number':
      const test: boolean = value >= -90 && value <= 90;
      return {
        test,
        errorMessage: !test ? undefined : appIntl().formatMessage({ id: 'validators.latitude' })
      };
    case 'string': {
      const parsedValue = parseFloat(value);
      const test: boolean = !isNaN(parsedValue) && parsedValue >= -90 && parsedValue <= 90;
      return {
        test,
        errorMessage: !test ? undefined : appIntl().formatMessage({ id: 'validators.latitude' })
      };
    }
  }

  return {
    test: false,
    errorMessage: appIntl().formatMessage({ id: 'validators.bit' })
  };
};

export const isValidLongitude = (value: unknown): ValidatorsReturn => {
  switch (typeof value) {
    case 'number':
      const test: boolean = value >= -180 && value <= 180;
      return {
        test,
        errorMessage: !test ? undefined : appIntl().formatMessage({ id: 'validators.longitude' })
      };
    case 'string': {
      const parsedValue = parseFloat(value);
      const test: boolean = !isNaN(parsedValue) && parsedValue >= -180 && parsedValue <= 180;
      return {
        test,
        errorMessage: !test ? undefined : appIntl().formatMessage({ id: 'validators.longitude' })
      };
    }
  }

  return {
    test: false,
    errorMessage: appIntl().formatMessage({ id: 'validators.requiredValue' })
  };
};

export const validators = {
  isAlphaNumericOrEmpty,
  isAlphaNumeric,
  isValidNumberOrEmpty,
  isValidNumber,
  isValidEmail,
  validLast4Digits,
  isValidDate,
  isValidExcelDate,
  validateRequiredValue,
  validateUniqueValue,
  isValidDayOfTheWeek,
  isValidBoolean,
  isValidLatitude,
  isValidLongitude,
  isValidBit
};

export const normalizeString = (value: string): string => {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .trim();
};

/**
 * Normalize input texts
 * @param data string from an input
 * @returns normalized strings allowing spaces after words
 */
export const normalizeInputText = (data: string): string => {
  // Allow clear input
  if (typeof data === 'string' && data.trim().length === 0) {
    return '';
  }

  // Allow only letters and spaces
  const validInputRegex = /^(\w+\s?)+$/;
  if (validInputRegex.test(data)) {
    return data;
  }

  // Allow only letters, numbers and spaces after words (not before)
  return normalizeString(data);
};

export const parseToString = (data: unknown): string | undefined => {
  switch (typeof data) {
    case 'string':
      if (data.trim().length === 0 || data.trim() === '') {
        return undefined;
      }
      return data;
    case 'number':
      return normalizeString(data.toString());
    case 'boolean':
      return normalizeString(data.toString());
    case 'object':
      if (isNullOrUndefined(data)) {
        return undefined;
      }
      if (isDate(data)) {
        return normalizeString(data.toString());
      }
  }

  return undefined;
};

/**
 * get any date and set todays day, month and year
 */

export const setTodaysDate = (date: Date): Date => {
  const today = new Date();
  const newDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );

  return newDate;
};

/**
 * Excel date to JS Date
 * @param serial Date saved as number from excel
 * @returns {Date} parsed number
 */
export const excelDateToJSDate = (serial: number): Date => {
  const utcDays = Math.floor(serial - 25569);
  const utcValue = utcDays * 86400;
  const dateInfo = new Date(utcValue * 1000);

  const fractionalDay = serial - Math.floor(serial) + 0.0000001;

  let totalSeconds = Math.floor(86400 * fractionalDay);

  const seconds = totalSeconds % 60;

  totalSeconds -= seconds;

  const hours = Math.floor(totalSeconds / (60 * 60));
  const minutes = Math.floor(totalSeconds / 60) % 60;

  return new Date(
    dateInfo.getFullYear(),
    dateInfo.getMonth(),
    dateInfo.getDate(),
    hours,
    minutes,
    seconds
  );
};

/**
 * function that receives an excel date and returns a date object
 * @param date {number} excel date
 * @returns {Date | undefined}
 * @example
 * parseToDate(0.20833333333333334) // 2023-03-31T00:17:57.000Z
 */
const parseToDate = (excelDate: unknown): Date | undefined => {
  if (typeof excelDate === 'number') {
    return excelDateToJSDate(excelDate);
  } else if (typeof excelDate === 'string') {
    const dateObj = new Date(excelDate);
    return dateObj;
  } else if (isDate(excelDate)) {
    return excelDate;
  }

  return undefined;
};

export const parseToDateTime = (excelDate: unknown): Date | undefined => {
  if (typeof excelDate === 'number') {
    return DateTime.fromISO(exceldate(excelDate).toISOString(), { zone: 'utc' })
      .setZone('system', { keepLocalTime: true })
      .toJSDate();
  } else if (typeof excelDate === 'string') {
    const dateObj = new Date(excelDate);
    return dateObj;
  } else if (isDate(excelDate)) {
    return excelDate;
  }

  return undefined;
};

export const parseToNumber = (data: unknown): number | undefined => {
  if (typeof data === 'number') {
    return data ?? 0;
  }

  if (typeof data === 'string') {
    const value = Number(data);

    return isNaNOrNullable(value) ? 0 : value;
  }

  return undefined;
};

/**
 * @name dateFormatter
 * @description date formatter function with moment js library
 * @param dateFormat default 'DD/MM/AAAA HH:mm:ss'
 * @returns date formatter function
 */
export const dateFormatter = (dateFormat: string = 'DD/MM/YYYY HH:mm:ss') => {
  return (d: Date) => {
    const m = moment(d);
    if (!m.isValid()) {
      console.warn(d, 'is not a valid date');
      return '';
    }
    return m.format(dateFormat);
  };
};

export const parseToBoolean = (data: unknown): boolean | undefined => {
  switch (typeof data) {
    case 'boolean':
      return data;
    case 'string':
      switch (data.toLowerCase().trim()) {
        case 'true':
          return true;
        case 'false':
          return false;
        case '1':
          return true;
        case '0':
          return false;
      }
      break;
    case 'number':
      return data === 1;
    default:
      return undefined;
  }
};

export const parseToBit = (data: unknown): 0 | 1 | undefined => {
  const result = parseToBoolean(data);
  if (result === undefined) {
    return result;
  }

  return result ? 1 : 0;
};

export const parsers = {
  parseToString,
  parseToDate,
  parseToNumber,
  parseToBoolean,
  parseToBit,
  parseToDateTime
};

export const timeToSeconds = (time: Date): number => {
  return time.getHours() * 3600 + time.getMinutes() * 60 + time.getSeconds();
};

export const timeToMinutes = (time: Date): number => {
  return time.getHours() * 60 + time.getMinutes();
};

export const getValueFromTryCatch = (handleTry: Function, handleCatch: Function) => {
  let value: any;
  try {
    value = handleTry();
  } catch (err) {
    console.error(err);
    value = handleCatch(err);
  }
  return value;
};

export const compareObjectValues = (obj1: object, obj2: object): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export const getSecondsFromDateHours = (date: Date) => {
  return typeof date?.getHours === 'function' ? date?.getHours() * 60 * 60 ?? 0 : 0;
};

export const getSecondsFromDateMinutes = (date: Date) => {
  return typeof date?.getMinutes === 'function' ? date?.getMinutes() * 60 ?? 0 : 0;
};

export const getSecondsFromDate = (date: Date) => {
  return typeof date?.getSeconds === 'function' ? date?.getSeconds() ?? 0 : 0;
};

export const getMinutesFromDate = (date: Date) => {
  return typeof date?.getMinutes === 'function' ? date?.getMinutes() ?? 0 : 0;
};

export const getTotalSecondsFromDate = (date: Date) => {
  const hoursInSeconds = getSecondsFromDateHours(date);
  const minutesInSeconds = getSecondsFromDateMinutes(date);
  const seconds = getSecondsFromDate(date);

  const totalSeconds = hoursInSeconds + minutesInSeconds + seconds;
  return totalSeconds;
};

export interface keysAndParsers {
  key: string;
  parse: Function;
}

/**
 * Map formatted string to array
 * @param data string formatted like "1~39~n...^1~28~n..."
 * @param keys array of key to map data to object
 *    [{key: 'name', parse: () => {}},
 *     {key: 'lastname', parse: () => {}},
 *     ...]
 * @returns Array<any>
 */
export const mapFormattedStringToArray = (
  data: string,
  keysAndValidator: Array<keysAndParsers>
): Array<any> => {
  // First split to get every object as string
  const newArray: Array<any> = data.split('^').map((objectAsString: string) => {
    // Second split to get object as array
    const objectAsArray: Array<string> = objectAsString.split('~');

    // mapped object with provided keys
    const newObject = keysAndValidator.reduce(
      (object: any, { key, parse }: keysAndParsers, index: number): any => {
        const newOjectAcum = { ...object };
        // validates index is contained into array
        // in order to avoid access to undefined values
        if (objectAsArray.length > index) {
          try {
            // new attribute = Value from array located in index
            newOjectAcum[key] = parse(objectAsArray[index]);
          } catch (error) {
            newOjectAcum[key] = undefined;
          }
        }
        return newOjectAcum;
      },
      {}
    );

    return newObject;
  });
  return newArray ?? [];
};

/**
 * Create a Date with provided minutes and hours
 * @param hours Number of hours
 * @param minutes Number of minutes
 * @returns new date with provided minutes and hours
 */
export const createDateWithHoursAndMinutes = (hours: number, minutes: number): Date => {
  const newDate: Date = new Date();
  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

export const secondsToMilliseconds = (seconds: number): number => seconds * 1000;

//Genera la etiqueta de fecha en el formato YYYY-MM-DD
export const generateDateLabelByDate = (date) => {
  return (
    date.getFullYear() +
    '-' +
    (date.getMonth() + 1 < 10 ? '0' : '') +
    (date.getMonth() + 1) +
    '-' +
    (date.getDate() < 10 ? '0' : '') +
    date.getDate()
  );
};

//Calcula la diferencia de horas que existe en un rango de fechas ingresado en el DateInput
export const getDaysHoursInDateRange = (dateRange: Array<string>): number => {
  if (dateRange && dateRange.length > 0) {
    let startDate = new Date(dateRange[0]);
    let endDate = new Date(dateRange[1]);
    let difference = endDate.getTime() - startDate.getTime();
    return Math.ceil(difference / (1000 * 3600 * 24)) * 24 * 3600;
  } else {
    return 0;
  }
};

/**
 * Generate date array
 * @param dateRange Date range provided by date range input
 * @returns Array in range of the date
 */
export const generateDateArray = (dateRange: Array<string>): Array<Date> => {
  const dateArray: Array<Date> = [];
  if (dateRange && dateRange.length > 0) {
    let startDate = new Date(dateRange[0]);
    let endDate = new Date(dateRange[1]);
    while (startDate <= endDate) {
      dateArray.push(new Date(startDate));
      startDate.setDate(startDate.getDate() + 1);
    }
  }
  return dateArray;
};

/**
 * Get date as string
 * @param date Date to get as string
 * @description Returns date as string in format YYYY-MM-DD
 * @returns Date as string
 */
export const getDateAsString = (date: Date): string => {
  return date.toISOString().substring(0, 10);
};

/**
 * Generate day label by date
 * @param date Date to generate label
 * @returns Label generated
 */
export const generateDayLabelByDate = (date: Date): string => {
  const intl = appIntl();
  return date.getDay() === 0
    ? `${intl.formatMessage({ id: 'common.sun' })} ${date.getDate()}`
    : date.getDay() === 1
    ? `${intl.formatMessage({ id: 'common.mon' })} ${date.getDate()}`
    : date.getDay() === 2
    ? `${intl.formatMessage({ id: 'common.tue' })} ${date.getDate()}`
    : date.getDay() === 3
    ? `${intl.formatMessage({ id: 'common.wed' })} ${date.getDate()}`
    : date.getDay() === 4
    ? `${intl.formatMessage({ id: 'common.thu' })} ${date.getDate()}`
    : date.getDay() === 5
    ? `${intl.formatMessage({ id: 'common.fri' })} ${date.getDate()}`
    : date.getDay() === 6
    ? `${intl.formatMessage({ id: 'common.sat' })} ${date.getDate()}`
    : '';
};

/**
 * Get date string from date item date range
 * @param date Date to generate string
 * @returns String of the date
 */
export const getDateStringFromDateItemDateRange = (date: any): string => {
  return date.toISOString().substr(0, 10);
};

/**
 * Safe Access to nullable prop
 * @param obj Object to validate
 * @param key Key to validate in the object
 * @param defaultValue Default value provided
 * @returns Value in the object of default
 */
export const safeAccessToNullProp = (obj: any, key: string, defaultValue: any = undefined): any => {
  let value = undefined;
  try {
    // Access to value in object, catch if error
    value = obj[key];
    // if value is undefined throw controlled error
    if (isNullOrUndefined(value)) throw new Error('Undefined value');
  } catch (error) {
    // Catch any error and assign default value as value
    value = defaultValue;
  }
  // Return value or default value
  return value;
};

/**
 * Is in object
 * validates if a prop is in an object
 * @param obj: Object to validate
 * @param key: string to validate value is in the object
 * @returns: boolean
 */
export const isInObject = (obj: any, key: string): boolean => {
  let isInObject: boolean;
  try {
    isInObject = obj[key] !== undefined && obj[key] !== null;
  } catch (error) {
    isInObject = false;
  }
  return isInObject;
};

/**
 * Is not in object
 * validates if a prop is not in an object
 * @param obj: Object to validate
 * @param key: string to validate value is in the object
 * @returns: boolean
 */
export const isNotInObject = (obj: any, key: string): boolean => !isInObject(obj, key);

/**
 * Is null or undefined
 * @param value Get any value to validate
 * @returns true if is null or undefined, else false
 */
export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined;

/**
 * Is not null or undefined
 * @param value Get any value to validate
 * @returns false if is null or undefined, else true
 */
export const isNotNullOrUndefined = (value: any) => !isNullOrUndefined(value);

export const isNaNOrNullable = (n: number | null | undefined) => {
  return n === null || n === undefined || isNaN(n);
};

export const isNotNaNOrNullable = (n: number | null | undefined) => !isNaNOrNullable(n);

/**
 * Check if every element is not null or undefined
 * return true if there are not null or undefined values
 */
export const areNotNullOrUndefined = (...arr: any[]) => {
  return !arr.some((item) => isNullOrUndefined(item));
};

/**
 * Round time for data report
 * @param time: number
 * @returns: number
 */
export const parseSecondsToHours = (time: number): number => {
  if (isNaNOrNullable(time)) return 0;

  return Math.round((time / 3600) * 100) / 100;
};

export const parseHoursToSeconds = (time: number): number => {
  if (isNaNOrNullable(time)) return 0;
  return time * 3600;
};

/**
 * Round time for report witht epsilon
 * @param time : number time in seconds
 * @returns time: number rounded
 */
export const parseSecondsToHoursWithEpsilon = (time: number): number => {
  if (isNaNOrNullable(time)) return 0;
  return Math.round((time / 3600 + Number.EPSILON) * 100) / 100;
};

export const getTimeInSeconds = (date: Date) => date.getTime() / 1000;

export const getTimeInMinutes = (date: Date) => getTimeInSeconds(date) / 60;

/**
 * Get previous element in array
 * @param array : T[]
 * @param currentIdx number, idx of current interation
 * @returns : T next element or undefined
 */
export function getNextElementInArray<T>(array: Array<T>, currentIdx: number): T | undefined {
  if (array.length < 1) return;
  let nextElement: T | undefined;
  try {
    nextElement = array[currentIdx + 1];
  } catch (error) {
    console.error(new Error('index out of array'));
    return undefined;
  }
  return nextElement;
}

/**
 * Get previous element in array
 * @param array : T[]
 * @param currentIdx number, idx of current interation
 * @returns : T previous element or undefined
 */
export function getPreviousElementInArray<T>(array: Array<T>, currentIdx: number): T | undefined {
  if (array.length < 1) return;
  let nextElement: T | undefined;
  try {
    nextElement = array[currentIdx - 1];
  } catch (error) {
    console.error(new Error('index out of array'));
    return undefined;
  }
  return nextElement;
}

/**
 * Return hour with left zero
 * @param n number
 * @returns if 9 ? 09 if 10 ? 10
 */
export const addLeftZeroToHours = (n: number): string => {
  return (n < 10 && n > -1 ? '0' : '') + n;
};

/**
 * Get difference between dates
 * @param a Date end
 * @param b Date start
 * @returns Time difference as string HH:mm:ss
 */
export const getDifferenceBetweenDatesHHMMSS = (a: Date, b: Date): string => {
  // difference in ms
  const duration = a.getTime() - b.getTime();

  // minutes and seconds from utc moment date
  const minutesSeconds = moment.utc(duration).format('mm:ss');

  // Math floor in order to avoid floats and get only hours
  const hours = Math.floor(moment.duration(duration).asHours());

  return `${addLeftZeroToHours(hours)}:${minutesSeconds}`;
};

/**
 * Function that returns number of values
 * validates most of the cases of types
 * @param obj Object to get number of values
 * @returns Return number of values of 0 as default
 */
export const getNumberOfValues = (obj: unknown) => {
  // Validate array
  if (Array.isArray(obj)) return obj.length;

  // validate null or undefined
  if (isNullOrUndefined(obj)) return 0;

  // case object
  if (typeof obj == 'object') return Object.values(obj ?? {}).length;

  return 0;
};

export const hasTheSameStructure = (objs: Array<any>): boolean => {
  if (isNullOrUndefined(objs)) return false;
  if (objs.length <= 0) return false;
  const keys: Array<string> = Object.keys(objs[0]);
  return objs.every((current) => _.isEqual(Object.keys(current), keys));
};

/**
 * Get Days between date and today
 * @param date : string | date
 * @returns : string indicating difference between date and today
 */
export const getDaysBetweenDateAndTodayRef = (date: Date | string): string => {
  const intl = appIntl();
  const startDate = new Date(new Date().toISOString().substr(0, 10));
  const endDate: Date = date instanceof Date ? date : new Date(date);
  var startYear = startDate.getFullYear();
  var february = (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var yearDiff = endDate.getFullYear() - startYear;
  var monthDiff = endDate.getMonth() - startDate.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate.getDate() - startDate.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate.getMonth()];
  }

  var weekDiff = dayDiff >= 7 ? Math.floor(dayDiff / 7) : 0;
  dayDiff -= 7 * weekDiff;

  return (
    (yearDiff > 0
      ? yearDiff > 1
        ? `${yearDiff} ${intl.formatMessage({ id: 'common.years' })}`
        : `${yearDiff} ${intl.formatMessage({ id: 'common.year' })}`
      : '') +
    (monthDiff > 0
      ? monthDiff > 1
        ? ` ${monthDiff} ${intl.formatMessage({ id: 'common.months' })}`
        : ` ${monthDiff} ${intl.formatMessage({ id: 'common.month' })}`
      : '') +
    (weekDiff > 0
      ? weekDiff > 1
        ? ` ${weekDiff} ${intl.formatMessage({ id: 'common.weeks' })}`
        : ` ${weekDiff} ${intl.formatMessage({ id: 'common.week' })}`
      : '') +
    (dayDiff > 0
      ? dayDiff > 1
        ? ` ${dayDiff} ${intl.formatMessage({ id: 'common.days2' })}`
        : ` ${dayDiff} ${intl.formatMessage({ id: 'common.day' })}`
      : '')
  );
};

export const isHex = (hex: string) => /^#([A-Fa-f0-9]{3}){1,2}$/.test(hex);

export const hexToRgbA = (hex: string) => {
  if (!isHex(hex)) return 'rgba(0 ,0 ,0 ,0)';

  let c;
  c = hex.substring(1).split('');
  if (c.length == 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }
  c = '0x' + c.join('');
  return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',1)';
};

export const getZeroIfNaNOrNullable = (n?: number | undefined): number =>
  isNaNOrNullable(n) ? 0 : n ?? 0;

/**
 * Has Database date format
 * @param date date as string
 * @returns bolean true if has format
 */
export const hasDBDateFormat = (date: string | undefined): boolean => {
  if (isNullOrUndefined(date)) return false;
  const regex = /[0-9]{4,4}-[0-9]{2,2}-[0-9]{2,2}T[0-9]{2,2}:[0-9]{2,2}:[0-9]{2,2}/;
  if (typeof date === 'string') return regex.test(date);
  return false;
};

/**
 * Get Date String formatted
 * @param date : undefined | null | string | Date | Moment date
 * @returns : date YYYY-MM-DD HH:mm or empty string
 */
export const getDateStrFormatted = (date: unknown): string => {
  if (isNullOrUndefined(date)) return '';

  // Format for date
  const dateFormat = 'YYYY-MM-DD HH:mm';
  if (typeof date === 'string' && hasDBDateFormat(date)) return moment(date).format(dateFormat);
  if (typeof date === 'string' && date.length > 0) return date;
  if (date instanceof Date) return moment(date).format(dateFormat);
  if (moment.isMoment(date)) return date.format(dateFormat);

  return '';
};

export const getDateAsKey = (date: Date | Moment | undefined | null | string): string => {
  const format = 'YYYY-MM-DD';
  if (isNullOrUndefined(date)) return '';
  if (typeof date === 'string') return moment(date).format(format);
  if (date instanceof Date) return moment(date).format(format);
  if (moment.isMoment(date)) return date.format(format);
  return '';
};

export const getIsMobile = (navigator) => /iPhone|iPad|iPod|Android/i?.test(navigator?.userAgent);

export const getShortDate = (date: string | Date | Moment | undefined | null): string => {
  if (isNullOrUndefined(date)) return '';
  if (typeof date === 'string') return moment(new Date(date)).format('MMM DD');
  if (date instanceof Date) return moment(date).format('MMM DD');
  if (moment.isMoment(date)) return date.format('MMM DD');
  return '';
};

/**
 * Recursively transforms object to camel case format
 * @param obj Object to transform
 * @returns Camel case keys of the object
 */
export const camelize = (obj: any) =>
  transform(obj, (acc: any, value, key: string, target) => {
    const camelKey = isArray(target) ? key : camelCase(key);

    acc[camelKey] = isObject(value) ? camelize(value) : value;
  });

export function truncateStr(text: string, limit: number) {
  if (text.length > limit) {
    for (let i = limit; i > 0; i--) {
      if (
        text.charAt(i) === ' ' &&
        (text.charAt(i - 1) != ',' || text.charAt(i - 1) != '.' || text.charAt(i - 1) != ';')
      ) {
        return text.substring(0, i) + '...';
      }
    }
    return text.substring(0, limit) + '...';
  } else {
    return text;
  }
}

/**
 * Format number 1234.2334 => 1,234.2334
 * @param n number
 * @param useSelectedLocale boolean in order to use app intl locale or default
 * @returns formatted number
 */
export const formatNumber = (n: number | string, useSelectedLocale: boolean = false): string => {
  let numberToValidate = Number(n);

  if (isNaNOrNullable(numberToValidate)) return '0';
  // validateNumber
  if (typeof n === 'string') {
    const regexIsNumber = /^\d+(\.\d+)?$/;
    const isNumber = regexIsNumber.test(n);
    if (!isNumber) return '0';
  }

  const localeToUse = useSelectedLocale ? appIntl().locale : 'en-US';
  const newNumberFormatted = numberToValidate.toLocaleString(localeToUse);
  return newNumberFormatted;
};

export const testIsNumber = (n: unknown) => {
  // create regex that test if the string is number
  // can be negative and have decimals
  // const regexIsNumber = /^-?\d+\.?\d*$/;
  const regexIsNumber = /^\d+(\.\d+)?$/;

  switch (typeof n) {
    case 'number':
      return true;
    case 'string':
      return regexIsNumber.test(n);
    default:
      return false;
  }
};

export const getUseCRM = () => !!parseInt(env.REACT_APP_PRODUCT_CRM ?? '0');

export const getUseConsole = () => !!parseInt(env.REACT_APP_PRODUCT_CONSOLE ?? '0');

export const getUseLoginext = () => (env.REACT_APP_USE_LOGINEXT === 'true' ? true : false);

export const getIsWebtrackUser = (user): boolean => {
  const { userName, email } = user;
  const webtrackEmail = '@webtrackgps.net';
  return (
    (typeof userName === 'string' && userName.includes(webtrackEmail)) ||
    (typeof email === 'string' && email.includes(webtrackEmail))
  );
};

// function that get time as string with this format '09-00' and return the hour as number 9
export function getHourFromStringTime(time: string): number {
  // validate time
  if (isNullOrUndefined(time)) return 0;

  // validate with regex that time has the format '09-00'
  const regex = /^[0-9]{2,2}-[0-9]{2,2}$/;
  if (!regex.test(time)) return 0;

  const hour = time.split('-')[0];
  return parseInt(hour);
}

// function that get time as string with this format '09-00' and return the minutes as number 0
export function getMinutesFromStringTime(time: string): number {
  // validate time
  if (isNullOrUndefined(time)) return 0;

  // validate with regex that time has the format '09-00'
  const regex = /^[0-9]{2,2}-[0-9]{2,2}$/;
  if (!regex.test(time)) return 0;

  const minutes = time.split('-')[1];
  return parseInt(minutes);
}
export const isEmptyData = (data: unknown) => {
  switch (typeof data) {
    case 'string':
      return data.trim().length === 0;
    case 'number':
      return isNaNOrNullable(data);
    case 'boolean':
      return false;
    case 'undefined':
      return true;
    case 'symbol':
      return isNotNullOrUndefined(data);
    case 'function':
      return false;
    case 'object':
      if (Array.isArray(data)) {
        return data.length === 0;
      }
      if (isNullOrUndefined(data)) {
        return true;
      }

      // @ts-ignore
      // by this line the code has validated if the data is null or undefined
      return compareObjectValues(data, {});
    default:
      isNullOrUndefined(data);
  }
};

export const isNotEmptyData = (data: unknown) => !isEmptyData(data);

/**
 * generate json without backslash
 * @param json
 * @returns
 */
export function jsonWithoutBackslash(data: any): string {
  const json = JSON.stringify(data ?? {});
  return json.replace(/\\/g, '');
}

export function safeStringify(obj: object) {
  const cache = new Set();
  const parsed = Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object' && value !== null) {
      if (cache.has(key)) {
        return acc;
      }

      acc[key] = value;
      cache.add(key);
    }

    return acc;
  }, {});

  console.log({ parsed });
  const data = JSON.stringify(parsed);

  return data;
}

export function safeStringifyWithoutBackslash(data: object): string {
  const json = safeStringify(data);
  return json.replace(/\\/g, '');
}

export type JoinOptions = 'conjunction' | 'disjunction' | 'unit';
export const joinTexts = (items: string[], by: JoinOptions = 'conjunction') => {
  const intl: IntlShape = appIntl();
  return intl.formatList(items, { type: by });
};

export const stripHtml = (html: string): string => {
  const temp = document.createElement('div');
  temp.innerHTML = html;
  return temp.textContent ?? '';
};

export const roundWithEpsilon = (n?: number) => {
  if (!n) {
    return null;
  }
  return Math.round((n + Number.EPSILON) * 100) / 100;
};

export const formatHourMeter = (hourMeter?: number | null | undefined) =>
  // @ts-ignore unreachable error because of validation
  isNotNaNOrNullable(hourMeter) ? parseSecondsToHours(hourMeter) + ' H' : 'N/A';

export const getSecondsBetweenDates = ([startDate, endDate]: [string, string]) => {
  if (isNullOrUndefined(startDate) || isNullOrUndefined(endDate)) return 0;
  const start = moment(startDate);
  const end = moment(endDate);
  return end.diff(start, 'seconds');
};

export const getDeviceInfo = (): DeviceInfo => {
  const userAgent = navigator.userAgent;
  const isMobile = /Mobile/.test(userAgent);
  const isBrowserMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    userAgent
  );

  const platform = navigator.platform;
  let os: OperatingSystem = OperatingSystem.Unknown;

  if (platform.startsWith('Win')) {
    os = OperatingSystem.Windows;
  } else if (platform.startsWith('Mac')) {
    os = OperatingSystem.MacOS;
  } else if (platform.startsWith('Linux')) {
    os = OperatingSystem.Linux;
  } else if (
    platform.includes('iPhone') ||
    platform.includes('iPad') ||
    platform.includes('iPod')
  ) {
    os = OperatingSystem.IOS;
  } else if (platform.includes('Android')) {
    os = OperatingSystem.Android;
  }

  const isBrowserChrome = /Chrome/i.test(userAgent);
  const isBrowserFirefox = /Firefox/i.test(userAgent);
  const isBrowserSafari = /Safari/i.test(userAgent) && !isBrowserChrome;

  let browser: Browser = Browser.Unknown;

  if (isBrowserChrome) {
    browser = Browser.Chrome;
  } else if (isBrowserFirefox) {
    browser = Browser.Firefox;
  } else if (isBrowserSafari) {
    browser = Browser.Safari;
  }

  const deviceType: DeviceType =
    isMobile || isBrowserMobile ? DeviceType.Mobile : DeviceType.Desktop;

  return {
    os,
    browser,
    deviceType
  };
};

export const getKeyboardModifiers = (event: KeyboardEvent): KeyboardModifiers => {
  const deviceInfo = getDeviceInfo();

  let ctrl = false;
  let alt = false;
  let shift = false;
  let parsedKey: string | undefined = undefined;

  if (deviceInfo.os === OperatingSystem.MacOS) {
    if (event.shiftKey) {
      shift = true;
    }
    if (event.metaKey) {
      ctrl = true;
    }

    if (
      event.altKey ||
      (event.key === 'Alt' && event.location === KeyboardEvent.DOM_KEY_LOCATION_RIGHT)
    ) {
      alt = true;
    }

    if (event.key in optionKeySpecialCharacters || event.key in optionShiftSpecialCombinations) {
      alt = true;
      // if shift is also pressed check the values
      parsedKey = shift
        ? optionShiftSpecialCombinations[event.key]
        : optionKeySpecialCharacters[event.key];
    }
  } else {
    if (event.ctrlKey) {
      ctrl = true;
    }
    if (event.altKey) {
      alt = true;
    }
  }

  return {
    ctrl,
    alt,
    shift,
    parsedKey
  };
};

export const toBit = (data: unknown): 1 | 0 => {
  return !!data ? 1 : 0;
};
