import { appIntl } from 'utility/context/IntlGlobalProvider';
import { Col, Row, Spinner, Badge } from 'reactstrap';

// sh.isProcessed === 0
//             ? intl.formatMessage({ id: 'units.pending' })
//             : sh.isProcessed === 1
//             ? intl.formatMessage({ id: 'units.sent' })
//             : sh.isProcessed === 2
//             ? intl.formatMessage({ id: 'units.cancelled' })
//             : '',

export const getStatusStyles = (
  status: number
): { text: string; color: string; icon: string } | null => {
  const intl = appIntl();

  switch (status) {
    case 0: {
      return {
        text: intl.formatMessage({ id: 'units.pending' }),
        color: 'warning',
        icon: 'PreventiveNotice'
      };
    }
    case 1: {
      return {
        text: intl.formatMessage({ id: 'units.sent' }),
        color: 'success',
        icon: 'Check'
      };
    }
    case 2: {
      return {
        text: intl.formatMessage({ id: 'units.canceled' }),
        color: 'danger',
        icon: 'PreventiveNotice'
      };
    }
    default: {
      return null;
    }
  }
};
