import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { reportsEntities, reportsOrder } = action.payload;
      const newState = { ...state };
      reportsOrder.forEach((reportId) => {
        newState[reportId] = {
          ...state[reportId],
          ...reportsEntities[reportId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { reportsOrder } = action.payload;
      return uniq([...state, ...reportsOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const reports = combineReducers({
  byId,
  order
});

export default reports;

//Own state
export const getOwnState = (state) => state.units.reports;

//Information
export const getReport = (state, reportId) => {
  const report = getOwnState(state).byId[reportId];
  if (report) {
    return {
      ...report
    };
  } else return undefined;
};
export const getReportsList = (state) => getOwnState(state).order.map((id) => getReport(state, id));
