/* -------------------------------------------------------------------------- */
/*                          Componente DropdownInput                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownInput genérico que se utiliza en muchas pantallas de la aplicación.

import _ from 'lodash';
import { useEffect } from 'react';
import Select, { components } from 'react-select';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { FormGroup, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const { Option, SingleValue, NoOptionsMessage } = components;
//Filter option needed to work with formatted messages
const filterOption = (candidate, input) => {
  let showOptionFilter = false;
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data.label &&
        _.deburr(candidate.data.label.toLowerCase()).includes(_.deburr(input.toLowerCase())));
  } catch (err) {}
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data.label_intl &&
        _.deburr(candidate.data.label_intl.toLowerCase()).includes(_.deburr(input.toLowerCase())));
  } catch (err) {}

  return showOptionFilter;
};

const IconOption = (props) => {
  return (
    <Option {...props}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {props.data.iconSVG && (
          <img
            src={props.data.iconSVG}
            style={{
              display: 'inline-block',
              width: '1em',
              height: '1em',
              marginRight: '0.5rem',
              marginTop: '0.25rem'
            }}
          />
        )}
        {props.data.label}
      </div>
    </Option>
  );
};
const IconSingleValue = (props) => {
  return (
    <SingleValue {...props}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {props.data.iconSVG && (
          <img
            src={props.data.iconSVG}
            style={{
              display: 'inline-block',
              width: '1em',
              height: '1em',
              marginRight: '0.5rem',
              marginTop: '0.25rem'
            }}
          />
        )}
        {props.data.label}
      </div>
    </SingleValue>
  );
};

const cache = new CellMeasurerCache({
  defaultHeight: 60,
  fixedWidth: true
});

const MenuList = (props) => {
  const rows = props.children;
  const rowRenderer = ({ key, index, parent, style }) => (
    <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
      {({ measure, registerChild }) => (
        <div ref={registerChild} key={key} style={style}>
          {rows[index]}
        </div>
      )}
    </CellMeasurer>
  );

  return rows.length > 0 ? (
    <AutoSizer disableWidth>
      {({ height }) => (
        <List
          containerStyle={{
            width: '100%',
            backgroundColor: '#ffffff',
            maxWidth: '100%'
          }}
          style={{
            width: '100%'
          }}
          width={1}
          height={250}
          rowCount={rows.length || 0}
          rowRenderer={rowRenderer}
          rowHeight={(ref) => {
            const height = cache.rowHeight(ref);
            return height + height * 0.2;
          }}
        />
      )}
    </AutoSizer>
  ) : (
    <NoOptionsMessage {...props} />
  );
};

const DropdownInput = (props) => {
  //const [touched, setTouched] = useState(false);
  const {
    input,
    meta: { error, touched, initial },
    hasPlaceholder = true,
    options = [],
    intl,
    isInModal = false,
    required,
    forceinitialvalue,
    additionalonchangefunction = (value) => null,
    isDisabled = false,
    menuIsOpen,
    onInputChange,
    ...inputProps
  } = props;
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  useEffect(() => {
    onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    input.onChange(value);

    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };

  return (
    <FormGroup>
      {inputProps.label && (
        <Label className="form-label" for={inputProps.id}>
          {inputProps.label}
          {required && <span className="required">&nbsp;*</span>}
        </Label>
      )}
      <InputGroup>
        <InputGroupAddon addonType="prepend">{props.prependcomponent}</InputGroupAddon>
        <Select
          {...input}
          {...inputProps}
          // Se comentó al hacer cambio a .tsx ya que está repetido
          // menuPortalTarget={document.getElementById('portal-target')}
          styles={{
            menuPortal: (styles) => ({ ...styles, zIndex: 91000 }),
            control: (provided, state) => {
              return {
                ...provided,

                boxShadow: state.isFocused ? 'none!important' : 'none!important',
                border: state.isFocused
                  ? `1px solid ${primary} !important`
                  : '1px solid #d8d6de !important'
              };
            },
            singleValue: (provided, state) => {
              return {
                ...provided,
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.45,
                color: '#6e6b7b',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
              };
            },
            menuList: (provided, state) => {
              return {
                ...provided,

                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: 1.45,
                color: '#6e6b7b',
                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
              };
            }
          }}
          id={inputProps.id}
          components={{ Option: IconOption, SingleValue: IconSingleValue, MenuList }}
          placeholder={
            inputProps.placeholder ?? appIntl().formatMessage({ id: 'common.selectOption' })
          }
          className={error && touched ? 'has-error custom-dropdown-input' : 'custom-dropdown-input'}
          onChange={(event) => {
            onChangeFinal(event ? event.value : null);
            if (inputProps.onSelect) inputProps.onSelect(event);
          }}
          onBlur={() => {
            input.onBlur(input.value === '' ? null : input.value);
          }}
          options={options}
          filterOption={filterOption}
          isClearable={inputProps.isClearable}
          isDisabled={isDisabled}
          value={
            options.filter((option) => {
              const selectedValue = /^\d+$/.test(input.value) ? parseInt(input.value) : input.value;
              const optionValue = /^\d+$/.test(option.value)
                ? parseInt(option.value)
                : option.value;
              return optionValue === selectedValue;
            })[0] || null
          }
          menuIsOpen={menuIsOpen}
          defaultValue={
            inputProps.defaultValue ?? options.filter((opt) => opt.value == initial).length > 0
              ? options.filter((opt) => opt.value == initial)[0]
              : null
          }
          noOptionsMessage={() => appIntl().formatMessage({ id: 'common.noOptions' })}
          maxMenuHeight={250}
          menuPortalTarget={isInModal ? undefined : document.querySelector('body')} // fix issue https://github.com/JedWatson/react-select/issues/3263
        />
        <InputGroupAddon addonType="append">{props.appendcomponent}</InputGroupAddon>
      </InputGroup>
      {error && touched && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {error}
        </div>
      )}
    </FormGroup>
  );
};

export default DropdownInput;
