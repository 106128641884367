/* -------------------------------------------------------------------------- */
/*                               Schema Divisions                             */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const division = new schema.Entity(
  "divisions",
  {},
  { idAttribute: "divisionId" }
);
export const divisions = new schema.Array(division);
