/* -------------------------------------------------------------------------- */
/*                      Componente SlidepanelGeofenceInformation                     */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Sidebar para subir una imagen.

import camelcaseKeys from 'camelcase-keys';
import { env } from 'env';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import { FormGroup, Label, Spinner } from 'reactstrap';
import Toolbar from '../../../../components/Toolbar';
import * as authSelectors from '../../../../../redux/reducers/auth';
// import * as geofenceClassesSelectors from '../../../../../redux/reducers/static-catalogs/geofence-classes';
import DefaultButton from '../../../../components/DefaultButton';
import EditButton from '../../../../components/EditButton';
import MapsBasic from '../../../../components/Map';
import SlidingPaneForm from '../../../../formComponents/SlidingPaneForm';
import TitleH4 from '../../../../components/TitleH4';
import * as geofenceActions from '../../actions';
import geofencePermissions from '../../permissions';
import { Circle, Hexagon, Square, TrendingUp } from 'react-feather';
import { getExtraGeofenceSaveValues } from '../../utils';
import * as geofencesActions from '../../actions';
import Icon from 'utility/icomoon';

/* -------------------------------------------------------------------------- */
/*                            SlidepanelGeofenceInformation                   */
/* -------------------------------------------------------------------------- */
export default function SlidepanelGeofenceInformation({
  closeSlidepanel,
  isSlidepanelOpen,
  isLoading = false,
  title = '',
  isInMap = true,
  geofence,
  editingGeofenceParent = undefined,
  setEditingGeofenceParent = undefined,
  editingGeofenceParentRef = undefined,
  onEditGeofence = (editedGeofence) => {}
}) {
  const store = useSelector((state) => state);
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [rerenderMap, setRerenderMap] = useState<Date>(new Date());
  const [isOpenSlidingPaneForm, setOpenSlidingPaneForm] = useState(false);
  const [slidingPaneProps, setSlidingPaneProps] = useState<any>({ inputs: [] });
  const mapHomeCenter = authSelectors.getAuthUserMapHomeCenter(store);

  const isSatMonitor = !!parseInt(env.REACT_APP_SAT_MONITOR ?? '0');
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  const [editingGeofenceLocal, setEditingGeofenceLocal] = useState<any>(undefined);
  const editingGeofenceLocalRef = React.useRef(editingGeofenceLocal);
  const setEditingGeofence =
    setEditingGeofenceParent == null ? setEditingGeofenceLocal : setEditingGeofenceParent;
  const editingGeofence =
    setEditingGeofenceParent == null ? editingGeofenceLocal : editingGeofenceParent;
  const editingGeofenceRef =
    setEditingGeofenceParent == null ? editingGeofenceLocalRef : editingGeofenceParentRef;
  useEffect(() => {
    if (editingGeofenceRef?.current !== undefined) {
      if (isInMap) dispatch(geofenceActions.hideGeofenceInMap(editingGeofence.geofenceId));
      editGeofence();
    }
  }, [editingGeofenceRef?.current]);

  useEffect(() => {
    if (geofence.geofenceId) {
      setOpenSlidingPaneForm(false);
    }
  }, [geofence.geofenceId]);

  const closeSlidingPaneForm = () => {
    setOpenSlidingPaneForm(false);
    setSlidingPaneProps({ inputs: [] });
    if (isInMap) {
      if (editingGeofenceRef?.current) {
        dispatch(geofenceActions.showGeofenceInMap(editingGeofenceRef?.current.geofenceId));
        if (setEditingGeofence) setEditingGeofence(undefined);
      }
    }
  };

  const editGeofence = () => {
    const initialLatitude = parseFloat(mapHomeCenter[0]);
    const initialLongitude = parseFloat(mapHomeCenter[1]);
    const initialRadius = 10;

    setSlidingPaneProps({
      title: editingGeofenceRef?.current
        ? editingGeofenceRef?.current.geofenceId
          ? editingGeofenceRef?.current.geofenceName
          : intl.formatMessage({ id: 'geofences.addGeofence' })
        : intl.formatMessage({ id: 'geofences.addGeofence' }),
      inputs: [
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.name' }),
          required: true,
          initialValue: editingGeofenceRef?.current
            ? editingGeofenceRef?.current.geofenceName
            : undefined,
          name: 'geofenceName',
          additionalonchangefunction: (value) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                geofenceName: value
              });
          },
          customValidation(values, props) {
            return values !== null && values !== undefined ? undefined : (
              <FormattedMessage id={'common.requiredField'} />
            );
          }
        },
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.geofenceAddress' }),
          required: false,
          initialValue: editingGeofenceRef?.current
            ? editingGeofenceRef?.current.geofenceAddress
            : '',
          name: 'geofenceAddress',
          additionalonchangefunction: (value) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                geofenceAddress: value
              });
          }
        },
        {
          type: 'geofenceShapesDropdown',
          required: true,
          initialValue:
            editingGeofenceRef?.current && editingGeofenceRef?.current.shape
              ? editingGeofenceRef?.current.shape
              : 'C',
          name: 'shape',
          customValidation(values, props) {
            return values !== null && values !== undefined ? undefined : (
              <FormattedMessage id={'common.requiredField'} />
            );
          },
          disabled: editingGeofenceRef?.current?.geofenceId ? true : false,
          additionalonchangefunction: (value) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                shape: value
              });
          }
        },
        editingGeofenceRef?.current &&
        editingGeofenceRef?.current.shape &&
        editingGeofenceRef?.current.shape == 'C'
          ? {
              type: 'number',
              allowUndefined: false,
              label: intl.formatMessage({ id: 'geofences.radius' }),
              required: true,
              min: 1,
              step: 1,
              allowDecimals: true,
              useNumberSpinner: true,
              appendcomponent: 'm',
              max: 100000,
              name: 'radius',
              initialValue:
                editingGeofenceRef?.current && editingGeofenceRef?.current.radius
                  ? editingGeofenceRef?.current.radius
                  : initialRadius,
              additionalonchangefunction: (value) => {
                if (setEditingGeofence)
                  setEditingGeofence({
                    ...editingGeofenceRef?.current,
                    radius: value
                  });
              }
            }
          : {},
        {
          type: 'colorPicker',
          label: intl.formatMessage({ id: 'geofences.color' }),
          required: true,
          name: 'color',
          initialValue:
            editingGeofenceRef?.current && editingGeofenceRef?.current.color
              ? editingGeofenceRef?.current.color
              : undefined,
          additionalonchangefunction: (value) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                color: value
              });
          }
        },
        !isInMap
          ? {
              type: 'component',
              name: 'Map',
              initialValue: null,
              component: (
                <>
                  <MapsBasic
                    mapOptions={{
                      className: '',
                      style: { height: '40vh' },
                      rerenderMap: rerenderMap
                    }}
                    mapGeofenceEditOptions={{
                      geofence: editingGeofenceRef?.current ? editingGeofenceRef?.current : {},
                      onGeofenceDragend: (values) => {
                        if (setEditingGeofence)
                          setEditingGeofence({
                            ...editingGeofenceRef?.current,
                            geofenceWktString: values.lng,
                            latitude: values.lat
                          });
                      },
                      onGeofenceEdit: (values) => {
                        if (setEditingGeofence)
                          setEditingGeofence({
                            ...editingGeofenceRef?.current,
                            ...values,
                            geofenceWktString: values.geofenceWktString
                          });
                      },
                      showGeofence: true,
                      isMapFullScreen: false,
                      usePopovers: false
                    }}
                    rulerControlOptions={{ position: 'bottomleft', useControl: false }}
                  ></MapsBasic>
                  <br />
                </>
              )
            }
          : {},
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.privateCode' }),
          required: false,
          name: 'privateCode',
          initialValue: editingGeofenceRef?.current ? editingGeofenceRef?.current.privateCode : '',
          additionalonchangefunction: (value) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                privateCode: value
              });
          }
        },
        {
          type: 'membership',
          name: 'membership',
          options: {
            colSize: '12',
            useOperator: false,
            useUserOrganization: true,
            setUserOrganization: true,
            showAllDivision: true,
            showAllSubdivision: true
          },
          initialValue: {
            operatorId: editingGeofenceRef?.current
              ? editingGeofenceRef?.current.operatorId
              : authSelectors.getAuthUserOperatorId(store),
            organizationId: editingGeofenceRef?.current
              ? editingGeofenceRef?.current.organizationId
              : authSelectors.getAuthUserOrganizationId(store),
            divisionId: editingGeofenceRef?.current
              ? editingGeofenceRef?.current.divisionId
              : undefined,
            subdivisionId: editingGeofenceRef?.current
              ? editingGeofenceRef?.current.subdivisionId
              : undefined
          },
          additionalonchangefunction: (values) => {
            if (setEditingGeofence)
              setEditingGeofence({
                ...editingGeofenceRef?.current,
                membership: values,
                ...values
              });
          }
        },
        isSatMonitor
          ? {
              type: 'radioButtons',
              name: 'isParentGeofence',
              label: `${intl.formatMessage({ id: 'geofences.type' })}:`,
              initialValue:
                editingGeofenceRef?.current?.isParentGeofence === true
                  ? '1'
                  : editingGeofenceRef?.current?.isParentGeofence === false
                  ? '0'
                  : undefined,
              radioButtons: [
                {
                  label: intl.formatMessage({ id: 'geofences.mainGeofence' }),
                  value: '1'
                },
                {
                  label: intl.formatMessage({ id: 'geofences.subGeofence' }),
                  value: '0'
                }
              ],
              additionalonchangefunction: (value) => {
                if (setEditingGeofence)
                  setEditingGeofence({
                    ...editingGeofenceRef?.current,
                    isParentGeofence: value == '1'
                  });
              }
            }
          : undefined,
        editingGeofenceRef?.current?.isParentGeofence === false && !!isSatMonitor
          ? {
              type: 'geofenceDropdown',
              name: 'parentGeofenceId',
              initialValue: editingGeofenceRef?.current?.parentGeofenceId,
              label: intl.formatMessage({ id: 'geofences.mainGeofence' }),
              placeholder: intl.formatMessage({ id: 'workplans.selectGeofence' }),
              isClearable: false,
              parentGeofences: true,
              required: true,
              subGeofenceWktString: editingGeofenceRef?.current?.geofenceWktString,
              additionalonchangefunction: (value) => {
                if (setEditingGeofence)
                  setEditingGeofence({
                    ...editingGeofenceRef?.current,
                    parentGeofenceId: value
                  });
              }
            }
          : undefined,
        editingGeofenceRef?.current?.isParentGeofence === false && !!isSatMonitor
          ? {
              type: 'switch',
              name: 'isActivationGeofence',
              initialValue: editingGeofenceRef?.current?.isActivationGeofence,
              label: intl.formatMessage({ id: 'trackingUnits.sat.isActivation/DeactivationArea' })
            }
          : undefined
      ],
      action: (values) => {
        if (editingGeofenceRef?.current.geofenceId) {
          const geofence = editingGeofenceRef?.current;
          const {
            vertexCount,
            longitude,
            longitude2,
            latitude,
            latitude2,
            vertexCsv,
            geofenceWKT
          } = getExtraGeofenceSaveValues(geofence);
          const updatedGeofence = _.omit(
            {
              ...editingGeofenceRef?.current,
              ...values,
              ...values.membership,
              isParentGeofence:
                values.isParentGeofence == '1'
                  ? true
                  : values.isParentGeofence == '0'
                  ? false
                  : undefined,
              parentGeofenceId:
                values.isParentGeofence == '0' ? values.parentGeofenceId : undefined,
              isActivationGeofence:
                values.isParentGeofence == '0' ? values.isActivationGeofence : undefined,
              geofenceWKT,
              vertexCount,
              latitude,
              latitude2,
              longitude,
              longitude2,
              vertexCsv
            },
            ['layer']
          );
          dispatch(geofencesActions.startEditingGeofence(updatedGeofence));
          if (onEditGeofence) onEditGeofence(updatedGeofence);
          if (setEditingGeofence) setEditingGeofence(undefined);
        }
      }
    });
    setOpenSlidingPaneForm(true);
  };

  useEffect(() => {
    if (isSlidepanelOpen) {
      if (!isInMap) {
        setRerenderMap(new Date());
      }
    }
  }, [isSlidepanelOpen]);

  return (
    <>
      <SlidingPane
        className="custom-sliding-pane"
        overlayClassName={
          isInMap ? 'custom-overlay-sliding-pane-map' : 'custom-overlay-sliding-pane'
        }
        isOpen={isSlidepanelOpen}
        title={title}
        width="40rem"
        onRequestClose={closeSlidepanel}
      >
        <TitleH4 textId={'geofences.geofence'} center={true} textTransform={'uppercase'} />
        {/* loading spiner for geofence images  */}
        {isLoading && (
          <div className="d-flex justify-content-center my-1">
            <Spinner color="primary" />
          </div>
        )}

        <br />

        {!isLoading && geofence && geofence.shape && (
          <>
            {/* Toolbar for geofenceinformation */}
            <Toolbar
              buttons={[
                !isInMap && (
                  <DefaultButton
                    id={'mapGeofenceButton'}
                    icon={'Map'}
                    color="info"
                    isDisabled={false}
                    handleClick={() => {
                      history.push({ pathname: '/home' }, { geofencesRouteParam: [geofence] });
                      // dispatch(geofenceActions.hideAllGeofencesInMap());
                      // dispatch(geofenceActions.showGeofenceInMap(geofence.geofenceId));
                    }}
                    tooltipMessage={intl.formatMessage({ id: 'geofences.seeInMap' })}
                  />
                ),
                isInMap && (
                  <EditButton
                    permissions={[geofencePermissions.editGeofence]}
                    tooltipMessage={'geofences.geofence'}
                    isDisabled={false}
                    handleClick={() => {
                      if (setEditingGeofence) setEditingGeofence(geofence);
                    }}
                  />
                ),
                isInMap && geofence.layer && (
                  <DefaultButton
                    id={'GoToBackGeofence'}
                    icon={'SkipBack'}
                    isDisabled={false}
                    handleClick={() => {
                      try {
                        geofence.layer.bringToBack();
                        geofence.layer.closePopup();
                      } catch {}
                    }}
                    tooltipMessage={intl.formatMessage({ id: 'geofences.bringToBack' })}
                  />
                ),
                isInMap && geofence.layer && (
                  <DefaultButton
                    id={'hideGeofence'}
                    icon={geofence.isShownInMap ? 'EyeOff' : 'Eye'}
                    isDisabled={false}
                    handleClick={() => {
                      if (geofence.isShownInMap) {
                        dispatch(geofenceActions.hideGeofenceInMap(geofence.geofenceId));
                        closeSlidepanel();
                      }
                    }}
                    tooltipMessage={intl.formatMessage({ id: 'geofences.hideInMap' })}
                  />
                )
              ]}
            />

            {/* geofence information */}
            <FormGroup>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {intl.formatMessage({ id: 'geofences.name' })}
              </Label>
              <Label className="label-value-information">{geofence.geofenceName ?? 'N/A'}</Label>

              {/* Little Map detail of geofence */}
              {!isInMap && (
                <>
                  <br />
                  <MapsBasic
                    mapOptions={{
                      className: '',
                      style: { height: '35vh' },
                      rerenderMap: rerenderMap
                    }}
                    mapGeofencesOptions={{
                      clusterMarkers: false,
                      showGeofences: true,
                      setMapPositionToGeofenceBounds: true,
                      useIsShownGeofence: false,
                      usePopovers: true,
                      useSlidingpanelGeofenceInformation: false,
                      geofences: [geofence]
                    }}
                  ></MapsBasic>
                </>
              )}

              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'geofences.shape' })}
              </Label>
              <Label className="label-value-information">
                <span style={{}}>
                  {geofence.shape == 'C' && <Circle className={'mr-25'} size={14} />}
                  {geofence.shape == 'R' && <Square className={'mr-25'} size={14} />}
                  {geofence.shape == 'P' && <Hexagon className={'mr-25'} size={14} />}
                  {geofence.shape == 'U' && <TrendingUp className={'mr-25'} size={14} />}
                  <FormattedMessage id={`geofences.shape.${geofence.shape}`} />
                </span>
              </Label>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'geofences.color' })}
              </Label>
              <Label className="label-value-information">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  {geofence.color?.toUpperCase()}{' '}
                  <Circle className={'ml-25'} size={14} color={geofence.color} />
                </span>
              </Label>
              <hr className={'full-width-slide-panel'} />
              {geofence.shape == 'C' && (
                <>
                  <Label className="label-information">
                    {' '}
                    {intl.formatMessage({ id: 'geofences.radius' })}
                  </Label>
                  <Label className="label-value-information">{geofence.radius ?? 'N/A'}</Label>
                  <hr className={'full-width-slide-panel'} />{' '}
                </>
              )}
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'geofences.vertices' })}
              </Label>
              <Label className="label-value-information">
                {geofence.vertexCount != null ? geofence.vertexCount : 'N/A'}
              </Label>
              <hr className={'full-width-slide-panel'} />

              <Label className="label-information">
                {intl.formatMessage({ id: 'geofences.privateCode' })}
              </Label>
              <Label className="label-value-information">
                {!_.isEmpty(geofence.privateCode) ? geofence.privateCode : 'N/A'}
              </Label>
              <hr className={'full-width-slide-panel'} />

              {isSatMonitor && (
                <>
                  <Label className="label-information">
                    {intl.formatMessage({ id: 'geofences.type' })}
                  </Label>
                  <Label className="label-value-information">
                    {intl.formatMessage({
                      id:
                        geofence.isParentGeofence === true
                          ? 'geofences.mainGeofence'
                          : geofence.isParentGeofence === false
                          ? 'geofences.subGeofence'
                          : 'trackingUnits.sat.unknown'
                    })}
                  </Label>
                  <hr className={'full-width-slide-panel'} />

                  {geofence.isParentGeofence === false && (
                    <>
                      <Label className="label-information">
                        {intl.formatMessage({
                          id: 'trackingUnits.sat.isActivation/DeactivationArea'
                        })}
                      </Label>
                      <Label className="label-value-information">
                        {geofence.isActivationGeofence ? (
                          <Icon icon={'CheckCircle'} color={primary} />
                        ) : (
                          <Icon icon={'XCircle'} color={primary} />
                        )}
                      </Label>
                      <hr className={'full-width-slide-panel'} />
                    </>
                  )}
                </>
              )}
            </FormGroup>
          </>
        )}
      </SlidingPane>

      <SlidingPaneForm
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneForm}
        isInMap={isInMap}
        closeSlidingPaneForm={closeSlidingPaneForm}
        {...slidingPaneProps}
      />
    </>
  );
}
