import { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import Tabs from './Tabs';
import TabsCardContent from './TabsCardContent';
import TabsContent from './TabsContent';
/* -------------------------------------------------------------------------- */
/*                               Tabs Wrapper                              */
/* -------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------- */
/* To use this component create the following in your main component          */
/* 
  const initalActiveTab = 1; //or set it from state
  const tabs = [
    { icon: "User", title: "profile.generalData", tabId: 1 },
    { icon: "Lock", title: "profile.changePassword", tabId: 2 },
    { icon: "Map", title: "profile.mapOptions", tabId: 3 },
  ];
  const tabsContent = [
    { tabId: 1, component: <GeneralTabContent /> },
    { tabId: 2, component: <PasswordTabContent /> },
    {
      tabId: 3,
      component: <MapOptionsTabContent />,
    },
  ];
  Tabs is a list of the tabs you want to render
  Initial active tab
  Vertical if you wants tabs to be shown vertical, false if you want them horizontal                           
                                                                              */
/* -------------------------------------------------------------------------- */
export interface Tab {
  title: string;
  tabId: number;
  icon: string;
  indicator?: string;
}

export interface TabsContentProps {
  tabId: number;
  component: JSX.Element;
}

interface Props {
  vertical?: boolean;
  initialActiveTab?: number;
  tabs: Array<Tab> | null | any;
  tabsContent: Array<TabsContentProps> | null | any;
  className?: string;
  useCardContent?: boolean;
  activeTab?: number | null;
  isEditing?: boolean;
  setActiveTab?: ((n: number) => void) | null | any;
  checkbox?: boolean;
  useIntl?: boolean;
  align?: string;
}

const TabsWrapper = ({
  vertical = false,
  initialActiveTab = 1,
  tabs = null,
  tabsContent = null,
  className = '',
  useCardContent = true,
  activeTab = null, //If you need control of activeTab outside the component
  setActiveTab = null, //If you need control of activeTab outside the component
  checkbox = false,
  useIntl = true,
  align = 'left'
}: Props) => {
  if (activeTab == null || setActiveTab == null)
    [activeTab, setActiveTab] = useState(initialActiveTab);
  useEffect(() => {
    setActiveTab(initialActiveTab);
  }, [initialActiveTab]);
  return (
    <>
      {vertical ? (
        <Row className={className}>
          <Col className="mb-2 mb-md-0" md="3">
            <Tabs
              activeTab={activeTab}
              toggleTab={setActiveTab}
              tabs={tabs}
              align={align}
              vertical={vertical}
              checkbox={checkbox}
              useIntl={useIntl}
            />
          </Col>
          <Col md="9">
            {useCardContent ? (
              <TabsCardContent activeTab={activeTab} tabsContent={tabsContent} />
            ) : (
              <TabsContent activeTab={activeTab} tabsContent={tabsContent} />
            )}
          </Col>
        </Row>
      ) : (
        <Row className={className}>
          <Col sm="12" style={{ paddingLeft: '0px' }}>
            <Tabs
              activeTab={activeTab}
              toggleTab={setActiveTab}
              tabs={tabs}
              align={align}
              vertical={vertical}
              checkbox={checkbox}
              useIntl={useIntl}
            />
            {useCardContent ? (
              <TabsCardContent activeTab={activeTab} tabsContent={tabsContent} />
            ) : (
              <TabsContent activeTab={activeTab} tabsContent={tabsContent} />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};

export default TabsWrapper;
