import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import * as utility from '../../../../utility/Utils';
import * as interfaces from 'utility/types';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { DateTime } from 'luxon';
import uniq from 'lodash/uniq';
import camelCase from 'lodash/camelCase';
import { store } from 'redux/storeConfig/store';
import {
  getFormsListService,
  getFormsQuestionsListService,
  getFormsSectionsListService,
  resendResponseFormService,
  saveExternalFormService,
  saveFormService,
  saveResponseFormService
} from '../services/forms';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchFormsList(action) {
  getFormsListService(
    {},
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { forms },
          result //order
        } = normalize(resultData, schemas.forms); //normalize data to byId and order
        store.dispatch(actions.completeFetchingForms(forms, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingForms(error));
      }
    }
  );
}

export function* watchFetchFormsList() {
  yield takeEvery(types.FORMS_FETCH_STARTED, fetchFormsList);
}

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* partialFetchFormsList(action) {
  const entityTypeId = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  getFormsListService(
    { userFetchDate: date },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { forms },
          result //order
        } = normalize(resultData, schemas.forms); //normalize data to byId and order
        store.dispatch(actions.completePartialFetchingForms(forms, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingForms(error));
      }
    }
  );
}

export function* watchPartialFetchFormsList() {
  yield takeEvery(types.FORMS_PARTIAL_FETCH_STARTED, partialFetchFormsList);
}
/* -------------------------------------------------------------------------- */
/*                              FETCH SECTIONS                                */
/* -------------------------------------------------------------------------- */
function* fetchSections(action) {
  const formId = action.payload;

  getFormsSectionsListService(
    { formId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { sections },
          result //order
        } = normalize(resultData, schemas.sections); //normalize data to byId and order
        store.dispatch(actions.completeFetchingFormSections(sections, result, formId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingFormSections(error));
      }
    }
  );
}

export function* watchFetchSections() {
  yield takeEvery(types.FORM_SECTIONS_FETCH_STARTED, fetchSections);
}

/* -------------------------------------------------------------------------- */
/*                             FETCH QUESTIONS                                */
/* -------------------------------------------------------------------------- */
function* fetchQuestions(action) {
  const formId = action.payload;

  getFormsQuestionsListService(
    { formId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { questions },
          result //order
        } = normalize(resultData, schemas.questions); //normalize data to byId and order
        store.dispatch(actions.completeFetchingFormQuestions(questions, result, formId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingFormQuestions(error));
      }
    }
  );
}

export function* watchFetchQuestions() {
  yield takeEvery(types.FORM_QUESTIONS_FETCH_STARTED, fetchQuestions);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addForm(action) {
  const form = action.payload;
  saveFormService(
    { ...form, organizationFormId: null },
    {
      successAction: (response) => {
        const addedForm = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingForm(form.organizationFormId, addedForm));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingForm(form.organizationFormId, error));
      }
    }
  );
}

export function* watchAddFormsStarted() {
  yield takeEvery(types.FORM_ADD_STARTED, addForm);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editForm(action) {
  const form = action.payload;
  const { oldForm } = yield select((state) => selectors.getForm(state, form.organizationFormId));
  saveFormService(
    { ...form },
    {
      successAction: (response) => {
        const editedForm = camelcaseKeys(response?.data);
        store.dispatch(actions.completeEditingForm({ ...editedForm, questions: [] }));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingForm(oldForm, error));
      }
    }
  );
}

export function* watchEditFormsStarted() {
  yield takeEvery(types.FORM_EDIT_STARTED, editForm);
}

/* -------------------------------------------------------------------------- */
/*                                 ADD EXTERNAL                               */
/* -------------------------------------------------------------------------- */
function* addFormExternal(action) {
  const form = action.payload;
  saveExternalFormService(
    { ...form, organizationFormId: null },
    {
      successAction: (response) => {
        const addedForm = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingForm(form.organizationFormId, addedForm));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingForm(form.organizationFormId, error));
      }
    }
  );
}

export function* watchAddExternalFormsStarted() {
  yield takeEvery(types.FORM_EXTERNAL_ADD_STARTED, addFormExternal);
}

/* -------------------------------------------------------------------------- */
/*                                EDIT EXTERNAL                               */
/* -------------------------------------------------------------------------- */
function* editFormExternal(action) {
  const form = action.payload;
  const { oldForm } = yield select((state) => selectors.getForm(state, form.organizationFormId));
  saveExternalFormService(
    { ...form },
    {
      successAction: (response) => {
        const editedForm = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingForm({ ...editedForm, questions: [] }));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingForm(oldForm, error));
      }
    }
  );
}

export function* watchEditExternalFormsStarted() {
  yield takeEvery(types.FORM_EXTERNAL_EDIT_STARTED, editFormExternal);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeForm(action) {
  const { formId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedForm = yield select((state) => selectors.getForm(state, formId));
      const response = yield call(fetch, `${API_BASE_URL}/form/delete`, {
        method: 'POST',
        body: JSON.stringify({ formId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingForm(formId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'forms.form'
          }),
          name: deletedForm.organizationFormName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingForm(formId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingForm(formId, error));
  }
}

export function* watchRemoveFormStarted() {
  yield takeEvery(types.FORM_REMOVE_STARTED, removeForm);
}

/* -------------------------------------------------------------------------- */
/*                              Save responses                               */
/* -------------------------------------------------------------------------- */
function* saveResponse(action) {
  const formResponse = action.payload;
  saveResponseFormService(
    { ...formResponse },
    {
      successAction: (response) => {
        store.dispatch(actions.completeAddingResponse());
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingResponse());
      }
    }
  );
}

export function* watchResponseAddedStarted() {
  yield takeEvery(types.FORM_RESPONSE_SAVE_STARTED, saveResponse);
}

/* -------------------------------------------------------------------------- */
/*                              Get responses                                 */
/* -------------------------------------------------------------------------- */
function* getResponses(action) {
  const payload = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/form/getResponses`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment({ ...payload })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const {
          success,
          data: { definition, answers }
        } = jsonResult;
        const camelDefinition = camelcaseKeys(definition);
        const {
          entities: { responses },
          result
        }: { entities: any; result: any[] } = normalize(camelDefinition, schemas.responses);
        const finalObject = {};
        uniq(result).forEach((answer) => {
          const {
            entities: { questions },
            result
          }: { entities: any; result: any[] } = normalize(responses[answer], schemas.questions);
          finalObject[answer] = {
            questions: questions,
            result: result
          };
        });
        yield put(
          actions.completeFetchingResponse(finalObject, uniq(result), payload.organizationFormId)
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };

        yield put(actions.failFetchingResponse(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    // yield put(actions.failFetchingForm(error));
  }
}

export function* watchResponseFetchStarted() {
  yield takeEvery(types.FORM_RESPONSE_FETCH_STARTED, getResponses);
}

/* -------------------------------------------------------------------------- */
/*                             Resend responses                               */
/* -------------------------------------------------------------------------- */
function* resendResponse(action) {
  const { organizationFormId, responseIds } = action.payload;
  resendResponseFormService(
    { organizationFormId, responseIds },
    {
      successAction: (response) => {
        store.dispatch(actions.completeResendingResponse(organizationFormId, responseIds));
      },
      errorAction: (error) => {
        store.dispatch(actions.failResendingResponse(organizationFormId, responseIds));
      }
    }
  );
}

export function* watchResponseResendStarted() {
  yield takeEvery(types.FORM_RESPONSE_RESEND_STARTED, resendResponse);
}
