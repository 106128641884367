import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchContractTemplatesList(action) {
  const { operatorId, organizationId } = action.payload;

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/contractTemplate/get`, {
        method: 'POST',
        body: JSON.stringify({
          operatorId
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { contractTemplates },
          result //order
        } = normalize(resultData, schemas.contractTemplates); //normalize data to byId and order
        yield put(
          actions.completeFetchingContractTemplates(
            contractTemplates ?? [],
            result,
            operatorId,
            organizationId
          )
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingContractTemplates(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingContractTemplates(error));
  }
}

export function* watchFetchContractTemplatesList() {
  yield takeEvery(types.CONTRACT_TEMPLATES_FETCH_STARTED, fetchContractTemplatesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchContractTemplatesList(action) {
  try {
    const {
      entityTypeId,
      operatorId: filterOperatorId,
      organizationId: filterOrganizationId
    } = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const { operatorId, organizationId } = yield select(
      authSelectors.getAuthUserMembershipFilters,
      {
        filterOperatorId,
        filterOrganizationId
      }
    );

    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/contractTemplate/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date, operatorId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { contractTemplates },
          result //order
        } = normalize(resultData, schemas.contractTemplates); //normalize data to byId and order
        yield put(
          actions.completePartialFetchingContractTemplates(
            contractTemplates,
            result,
            operatorId,
            organizationId
          )
        );
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingContractTemplates(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingContractTemplates(error));
  }
}

export function* watchPartialFetchContractTemplatesList() {
  yield takeEvery(
    types.CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED,
    partialFetchContractTemplatesList
  );
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addContractTemplate(action) {
  const contractTemplate = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      // console.log('division', division);
      const response = yield call(fetch, `${API_BASE_URL}/contractTemplate/save`, {
        method: 'POST',
        body: JSON.stringify({ ...contractTemplate, templateId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedContractTemplate = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeAddingContractTemplate(contractTemplate.templateId, addedContractTemplate)
        );

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'contractTemplates.contractTemplate'
          }),
          name: contractTemplate.templateName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingContractTemplate(contractTemplate.templateId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingContractTemplate(contractTemplate.templateId, error));
  }
}

export function* watchAddContractTemplatesStarted() {
  yield takeEvery(types.CONTRACT_TEMPLATE_ADD_STARTED, addContractTemplate);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editContractTemplate(action) {
  const contractTemplate = action.payload;
  // console.log('payload edit', action.payload);
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/contractTemplate/save`, {
        method: 'POST',
        body: JSON.stringify(contractTemplate),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedContractTemplate = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingContractTemplate(editedContractTemplate));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'contractTemplates.contractTemplate'
          }),
          name: editedContractTemplate.templateName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldContractTemplate } = yield select((state) =>
          selectors.getContractTemplate(state.contractTemplates, contractTemplate.templateId)
        );
        yield put(actions.failEditingContractTemplate(oldContractTemplate, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const oldContractTemplate = yield select((state) =>
      selectors.getContractTemplate(state, contractTemplate.templateId)
    );
    // console.log('old', yield select((state) => selectors.getDivision(state, division.divisionId)));
    yield put(actions.failEditingContractTemplate(oldContractTemplate, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditContractTemplatesStarted() {
  yield takeEvery(types.CONTRACT_TEMPLATE_EDIT_STARTED, editContractTemplate);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeContractTemplate(action) {
  const { templateId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedContractTemplate = yield select((state) =>
        selectors.getContractTemplate(state, templateId)
      );
      const response = yield call(fetch, `${API_BASE_URL}/contractTemplate/delete`, {
        method: 'POST',
        body: JSON.stringify({ templateId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingContractTemplate(templateId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'contractTemplates.template'
          }),
          name: deletedContractTemplate.templateName,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingContractTemplate(templateId, error));
      }
    }
  } catch (err) {
    // console.log('err', err);
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingContractTemplate(templateId, error));
  }
}

export function* watchRemoveContractTemplateStarted() {
  yield takeEvery(types.CONTRACT_TEMPLATE_REMOVE_STARTED, removeContractTemplate);
}
