import * as types from '../types';

export const startFetchingGeofences = (filters = {}) => ({
  type: types.GEOFENCES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingGeofences = (entities, order, operatorId, organizationId) => ({
  type: types.GEOFENCES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingGeofences = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.GEOFENCES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingGeofences = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.GEOFENCES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingGeofences = (entities, order, operatorId, organizationId) => ({
  type: types.GEOFENCES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingGeofences = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.GEOFENCES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingGeofence = (geofence) => ({
  type: types.GEOFENCE_ADD_STARTED,
  payload: geofence
});
export const completeAddingGeofence = (oldId, geofence) => ({
  type: types.GEOFENCE_ADD_COMPLETED,
  payload: { oldId, geofence }
});
export const failAddingGeofence = (oldId, error) => ({
  type: types.GEOFENCE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingGeofence = (geofence) => ({
  type: types.GEOFENCE_EDIT_STARTED,
  payload: geofence
});
export const completeEditingGeofence = (geofence) => ({
  type: types.GEOFENCE_EDIT_COMPLETED,
  payload: geofence
});
export const failEditingGeofence = (oldGeofence, error) => ({
  type: types.GEOFENCE_EDIT_FAILED,
  payload: {
    oldGeofence,
    error
  }
});

export const startRemovingGeofence = (geofenceId) => ({
  type: types.GEOFENCE_REMOVE_STARTED,
  payload: {
    geofenceId
  }
});
export const completeRemovingGeofence = (geofenceId) => ({
  type: types.GEOFENCE_REMOVE_COMPLETED,
  payload: {
    geofenceId
  }
});
export const failRemovingGeofence = (geofenceId, error) => ({
  type: types.GEOFENCE_REMOVE_FAILED,
  payload: {
    geofenceId,
    error
  }
});

export const startRemovingGeofenceAssignation = (unitGeofences) => ({
  type: types.GEOFENCE_ASSIGNATION_REMOVE_STARTED,
  payload: {
    unitGeofences
  }
});

export const startRemovingAllAssignmentsByGeofences = (geofences) => {
  return {
    type: types.GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_STARTED,
    payload: {
      geofences
    }
  };
};

export const startRemovingAllAssignmentsByUnits = (units) => {
  return {
    type: types.GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_STARTED,
    payload: {
      units
    }
  };
};

export const completeRemovingGeofenceAssignation = (unitGeofences) => ({
  type: types.GEOFENCE_ASSIGNATION_REMOVE_COMPLETED,
  payload: {
    unitGeofences
  }
});
export const failRemovingGeofenceAssignation = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.GEOFENCE_ASSIGNATION_REMOVE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startEditingGeofenceAssignation = (unitGeofenceInfo) => ({
  type: types.GEOFENCE_ASSIGNATION_EDIT_STARTED,
  payload: {
    unitGeofenceInfo
  }
});
export const completeEditingGeofenceAssignation = (unitGeofenceInfo) => ({
  type: types.GEOFENCE_ASSIGNATION_EDIT_COMPLETED,
  payload: {
    unitGeofenceInfo
  }
});
export const failEditingGeofenceAssignation = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.GEOFENCE_ASSIGNATION_EDIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const selectGeofence = (geofenceId) => ({
  type: types.GEOFENCE_SELECTED,
  payload: geofenceId
});

export const selectAllGeofences = (
  geofenceIds: any[] = [] //List of GeofenceIds
) => ({
  type: types.GEOFENCES_ALL_SELECTED,
  payload: geofenceIds
});

export const deselectGeofence = (geofenceId) => ({
  type: types.GEOFENCE_DESELECTED,
  payload: geofenceId
});

export const deselectAllGeofences = (
  geofenceIds: any[] = [] //List of GeofenceIds
) => ({
  type: types.GEOFENCES_ALL_DESELECTED,
  payload: geofenceIds
});

export const showGeofenceInMap = (geofenceId) => ({
  type: types.GEOFENCE_SHOW_IN_MAP,
  payload: geofenceId
});

export const showAllGeofencesInMap = (
  geofenceIds: any[] = [] //List of GeofenceIds
) => ({
  type: types.GEOFENCES_ALL_SHOW_IN_MAP,
  payload: geofenceIds
});

export const hideGeofenceInMap = (geofenceId) => ({
  type: types.GEOFENCE_HIDE_IN_MAP,
  payload: geofenceId
});

export const hideAllGeofencesInMap = (
  geofenceIds: any[] = [] //List of GeofenceIds
) => ({
  type: types.GEOFENCES_ALL_HIDE_IN_MAP,
  payload: geofenceIds
});

export const startAssignUnitsGeofences = (assignation) => ({
  type: types.GEOFENCE_ASSIGN_UNIT_STARTED,
  payload: assignation
});
export const completeAssignUnitsGeofences = (assignation) => ({
  type: types.GEOFENCE_ASSIGN_UNIT_COMPLETED,
  payload: assignation
});
export const failAssignUnitsGeofences = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.GEOFENCE_ASSIGN_UNIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
