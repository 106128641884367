import { HttpWebtrack } from 'utility/http';
import { PaymentMethods } from '../types';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/vehicleFuel';

export const getPaymentMethods = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  return httpWebtrack.get(`${baseUrl}/paymentMethods`);
};
