import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/geofence';

interface GeofenceSaveRequest {
  geofenceId?: number;
  shape: string;
  color: string;
  geofenceName: string;
  geofenceAddress?: string;
  privateCode?: string;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  latitude?: number;
  longitude?: number;
  latitude2?: number;
  longitude2?: number;
  radius?: number;
  vertexCount?: number;
  geofenceWKT?: string;
  vertexCsv?: string;
}

interface GeofenceDeleteRequest {
  geofenceId: number;
  geofenceName: string;
}

interface GeofenceFilters {
  startDate?: string;
  endDate?: string;
  unitId?: number;
  geofenceId?: number;
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  userFetchDate?: Date;
  email?: string;
  consoleName?: string;
  deepSearch?: boolean;
  byUnit?: number;
  tab?: number;
}

export const getGeofencesListService = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.geofence',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getGeofenceDropdownByOrganization = (
  request: GeofenceFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.geofence',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/organization/dropdown`, request);
};

export const getCpGeofence = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.geofence',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/cpgeofence`, request);
};

export const saveGeofenceService = (request: GeofenceSaveRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.geofence',
    action: request.geofenceId ? 'Edit' : 'Create',
    name: request.geofenceName,
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteGeofenceService = (
  request: GeofenceDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.geofenceName}`,
    object: 'geofences.geofence',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const getUnitGeofences = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.assignedGeofences',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/unitGeofences`, request);
};

export const getUnitGeofenceInfo = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.assignedGeofences',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/UnitGeofenceInfo`, request);
};

export const getAssignments = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.assignedGeofences',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/assignedUnits`, request);
};

export const getUnassignedEntities = (request: GeofenceFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'geofences.assignedGeofences',
    action: 'Get',
    genderType: 'PluralFemale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/unassignedEntities`, request);
};
