/* -------------------------------------------------------------------------- */
/*                          Componente DurationInput                           */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DurationInput genérico que se utiliza en muchas pantallas de la aplicación.
// Incluye fechas para aumentar la cantidad en incrementos de 1

import { useState, useEffect, FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Input,
  UncontrolledPopover,
  PopoverBody
} from 'reactstrap';
import { timeInSeconds } from 'utility/timeUtils/timeInSeconds';

interface Duration {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  totalSeconds: number;
}

interface Props {
  id: string;
  field: any;
  fieldstate: any;
  label: string;
  required?: boolean;
  errorMessage?: string;
  forceinitialvalue?: Omit<Duration, 'totalSeconds'> | Pick<Duration, 'totalSeconds'>;
  useDays?: boolean;
  useHours?: boolean;
  useMinutes?: boolean;
  useSeconds?: boolean;
  autoCompleteInput?: boolean;
  min?: number;
  max?: number;
  disabled?: boolean;
  allowDecimals?: boolean;
  decimalsPrecision?: number;
  additionalonchangefunction?: (value) => void;
}

const DurationInput: FC<Props> = (props) => {
  const {
    field: { value, onChange, onBlur },
    forceinitialvalue,
    useDays,
    useHours,
    useMinutes,
    useSeconds,
    errorMessage,
    fieldstate,
    min,
    max,
    allowDecimals,
    decimalsPrecision,
    additionalonchangefunction,
    ...inputProps
  } = props;

  const [inputValue, setInputValue] = useState<Duration>({
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
    totalSeconds: 0
  });

  //Cuando se hace un reset en el form
  useEffect(() => {
    if (
      forceinitialvalue &&
      typeof forceinitialvalue === 'object' &&
      'totalSeconds' in forceinitialvalue &&
      forceinitialvalue?.totalSeconds
    ) {
      let valueSec: number = forceinitialvalue?.totalSeconds;

      const days = Math.floor(valueSec / timeInSeconds.day);
      valueSec = valueSec - days * timeInSeconds.day;

      const hours = Math.floor(valueSec / timeInSeconds.hour);
      valueSec = valueSec - hours * timeInSeconds.hour;

      const minutes = Math.floor(valueSec / timeInSeconds.minute);
      valueSec = valueSec - minutes * timeInSeconds.minute;
      const seconds = valueSec;

      setInputValue({
        days: days.toString(),
        hours: hours.toString(),
        minutes: minutes.toString(),
        seconds: seconds.toString(),
        totalSeconds: forceinitialvalue?.totalSeconds
      });
      onChangeFinal({
        days: days.toString(),
        hours: hours.toString(),
        minutes: minutes.toString(),
        seconds: seconds.toString(),
        totalSeconds: forceinitialvalue?.totalSeconds
      });
    }
  }, [...Object.values(forceinitialvalue ?? {})]);

  useEffect(() => {
    if (!value) {
      setInputValue({
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        totalSeconds: 0
      });
    } else {
      if (!inputValue || (inputValue.totalSeconds === 0 && value)) {
        let valueSec: number = value?.totalSeconds ? value?.totalSeconds : value;

        const days = Math.floor(valueSec / timeInSeconds.day);
        valueSec = valueSec - days * timeInSeconds.day;

        const hours = Math.floor(valueSec / timeInSeconds.hour);
        valueSec = valueSec - hours * timeInSeconds.hour;

        const minutes = Math.floor(valueSec / timeInSeconds.minute);
        valueSec = valueSec - minutes * timeInSeconds.minute;

        const seconds = valueSec;

        const newValue = {
          days: days.toString(),
          hours: hours.toString(),
          minutes: minutes.toString(),
          seconds: seconds.toString(),
          totalSeconds: value?.totalSeconds ? value?.totalSeconds : value
        };
        setInputValue(newValue);
        onChange(newValue);
      }
    }
  }, [value]);

  const handleChangeNumber = (event, type: 'days' | 'hours' | 'minutes' | 'seconds') => {
    const { value, min, max } = event.target;
    if (value) {
      let newValue = Math.max(Number(min), Math.min(Number(max), Number(value)));
      if (!allowDecimals) {
        newValue = Math.round(newValue);
      } else {
        newValue = Number(newValue.toFixed(decimalsPrecision));
      }
      setInputValue({ ...inputValue, [type]: newValue.toString() });
    } else setInputValue({ ...inputValue, [type]: '0' });
  };
  const intl = useIntl();

  const onChangeFinal = (value) => {
    onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  const onBlurInput = (event) => {
    onChangeFinal({
      ...inputValue,
      totalSeconds:
        (Number(inputValue?.days) || 0) * timeInSeconds.day +
        (Number(inputValue?.hours) || 0) * timeInSeconds.hour +
        (Number(inputValue?.minutes) || 0) * timeInSeconds.minute +
        (Number(inputValue?.seconds) || 0)
    });
  };

  return (
    <FormGroup>
      <Label className="form-label" for={inputProps.id}>
        {inputProps.label}
        {inputProps.required && <span className="required">&nbsp;*</span>}
      </Label>

      <InputGroup className={'number-input-hide-spin'}>
        {useDays && (
          <>
            <Input
              id={'days'}
              onBlur={onBlurInput}
              min={min}
              max={max}
              type="number"
              onChange={(event) => {
                handleChangeNumber(event, 'days');
              }}
              value={inputValue.days}
            />
            <InputGroupAddon addonType="append">
              {intl.formatMessage({ id: 'common.days' })}
            </InputGroupAddon>
            <UncontrolledPopover trigger="hover" placement="top" target={'days'}>
              <PopoverBody>{intl.formatMessage({ id: 'common.days' })}</PopoverBody>
            </UncontrolledPopover>
          </>
        )}
        {useHours && (
          <>
            <Input
              max={23}
              min={0}
              id={'hours'}
              placeholder={'H'}
              type="number"
              onChange={(event) => {
                handleChangeNumber(event, 'hours');
              }}
              value={inputValue.hours}
              onBlur={onBlurInput}
              className={useMinutes && 'input-group-border-right-0'}
            />
            {useMinutes && <InputGroupAddon addonType="append">{':'}</InputGroupAddon>}
            <UncontrolledPopover trigger="hover" placement="top" target={'hours'}>
              <PopoverBody>{intl.formatMessage({ id: 'common.hours' })}</PopoverBody>
            </UncontrolledPopover>
          </>
        )}
        {useMinutes && (
          <>
            <Input
              max={59}
              min={0}
              id={'minutes'}
              placeholder={'M'}
              type="number"
              onChange={(event) => {
                handleChangeNumber(event, 'minutes');
              }}
              value={inputValue.minutes}
              onBlur={onBlurInput}
              className={
                useSeconds
                  ? 'input-group-border-left-0 input-group-border-right-0'
                  : 'input-group-border-left-0'
              }
            />
            {useSeconds && <InputGroupAddon addonType="append">{':'}</InputGroupAddon>}
            <UncontrolledPopover trigger="hover" placement="top" target={'minutes'}>
              <PopoverBody>{intl.formatMessage({ id: 'common.minutes' })}</PopoverBody>
            </UncontrolledPopover>
          </>
        )}
        {useSeconds && (
          <>
            <Input
              max={59}
              min={0}
              id={'seconds'}
              placeholder={'S'}
              type="number"
              onChange={(event) => {
                handleChangeNumber(event, 'seconds');
              }}
              value={inputValue.seconds}
              onBlur={onBlurInput}
              className={'input-group-border-left-0'}
            />
            <UncontrolledPopover trigger="hover" placement="top" target={'seconds'}>
              <PopoverBody>{intl.formatMessage({ id: 'common.seconds' })}</PopoverBody>
            </UncontrolledPopover>
          </>
        )}
      </InputGroup>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

DurationInput.defaultProps = {
  min: 0,
  max: 365,
  useDays: true,
  useHours: true,
  useMinutes: true,
  useSeconds: false,
  allowDecimals: false,
  decimalsPrecision: 2
};

export default DurationInput;
