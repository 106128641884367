/* -------------------------------------------------------------------------- */
/*                            Schema Modules                                  */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";
//Cambio de nombre porque module causaba problemas al inicializar
export const moduleSchema = new schema.Entity(
  "modules",
  {},
  { idAttribute: "deviceId" }
);
export const modules = new schema.Array(moduleSchema);
