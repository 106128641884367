import { combineReducers } from 'redux';
import moment from 'moment';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as deviceMetricsSelectors from '../deviceMetrics';
import * as deviceActionsSelectors from '../deviceActions';
import * as groupsSelectors from '../groups';
import * as reportsSelectors from '../reports';
import * as imagesSelectors from '../images';
import * as sensorsSelectors from '../sensors';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { getUnitHeadingFormat } from '../../utils';
import { GetMainStateById, GetMainStateName } from 'views/screens/Units/utils';
import { UnitLiveStatus } from '../../types/UnitLiveStatusTypes';
import { formatHourMeter, numberWithCommas, roundWithEpsilon } from 'utility/Utils';

const fetchDate = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED: {
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      return date.toISOString();
    }
    default: {
      return state;
    }
  }
};

const organizationId = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED: {
      const { organizationId } = action.payload;
      return organizationId;
    }
    default: {
      return state;
    }
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNITS_LIVE_STATUS_FETCH_COMPLETED
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((unitId) => {
        newState[unitId] = {
          ...state[unitId],
          ...entities[unitId]
        };
      });
      return newState;
    }
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const {
        unitId,
        unitData,
        deviceMetricsOrder,
        deviceActionsOrder,
        groupsOrder,
        reportsOrder,
        imagesOrder,
        sensorsOrder
      } = action.payload;
      const newState = { ...state };
      newState[unitId] = {
        ...state[unitId],
        ...unitData,
        deviceMetricsOrder,
        deviceActionsOrder,
        groupsOrder,
        reportsOrder,
        imagesOrder,
        sensorsOrder
      };
      return newState;
    }
    case types.UNIT_POSITIONS_FETCH_COMPLETED: {
      const { unitId, distance, parkedPositions, polyline, positions } = action.payload;
      const newState = { ...state };
      newState[unitId] = {
        ...state[unitId],
        historicalPositions: {
          allPositions: [...(positions || []), ...(parkedPositions || [])],
          distance,
          parkedPositions,
          polyline,
          positions
        }
      };
      return newState;
    }
    case types.UNIT_ACKNOWLEDGE_COMPLETED: {
      const { unitId } = action.payload;
      const newState = { ...state };
      newState[unitId] = {
        ...state[unitId],
        eventId: 0,
        eventName: 'Normal',
        eventParam: null,
        eventTextColor: null,
        eventColor: 'BBFFAA'
      };
      return newState;
    }

    case types.UNIT_EDIT_COMPLETED: {
      const unit = action.payload;
      const oldUnit = state[unit.unitId];
      return {
        ...state,
        [unit.unitId]: {
          ...oldUnit,
          unitName: unit?.unitName ?? oldUnit?.unitName,
          themeId: unit?.themeId ?? oldUnit?.themeId
        }
      };
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNITS_LIVE_STATUS_FETCH_COMPLETED
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { unitId } = action.payload;
      return uniq([...state, unitId]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingLiveStatusList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_LIVE_STATUS_FETCH_STARTED: {
      return true;
    }
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED:
    case types.UNITS_LIVE_STATUS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingInformation = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_INFORMATION_FETCH_STARTED: {
      return true;
    }
    case types.UNIT_INFORMATION_FETCH_COMPLETED:
    case types.UNIT_INFORMATION_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingPositions = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_POSITIONS_FETCH_STARTED: {
      return true;
    }
    case types.UNIT_POSITIONS_FETCH_COMPLETED:
    case types.UNIT_POSITIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAcknowledgeEvent = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNIT_ACKNOWLEDGE_STARTED: {
      return true;
    }
    case types.UNIT_ACKNOWLEDGE_COMPLETED:
    case types.UNIT_ACKNOWLEDGE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchedPosition = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    case types.UNIT_POSITIONS_FETCH_STARTED: {
      const { unitId } = action.payload;
      const newState = { ...state };
      return { [unitId]: newState[unitId] ?? false };
    }
    case types.UNIT_POSITIONS_FETCH_COMPLETED: {
      const { unitId } = action.payload;
      return { [unitId]: true };
    }
    case types.UNIT_POSITIONS_FETCH_FAILED:
    case types.UNIT_POSITIONS_RESET_IS_FETCHED: {
      return {};
    }
    default: {
      return state;
    }
  }
};

const errorFetchingLiveStatusList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNITS_LIVE_STATUS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNITS_LIVE_STATUS_FETCH_STARTED:
    case types.UNITS_LIVE_STATUS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingInformation = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_INFORMATION_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_INFORMATION_FETCH_STARTED:
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const isEditingInformation = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.UNITS_ACTIVE_STARTED:
    case types.UNITS_ASSIGN_DRIVER_STARTED:
    case types.UNIT_EDIT_STARTED: {
      return true;
    }
    case types.UNITS_ACTIVE_COMPLETED:
    case types.UNIT_EDIT_COMPLETED:
    case types.UNITS_ASSIGN_DRIVER_COMPLETED:
    case types.UNITS_ACTIVE_FAILED:
    case types.UNIT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorEditingInformation = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.UNIT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.UNIT_EDIT_STARTED:
    case types.UNIT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const unitLiveStatus = combineReducers({
  fetchDate,
  organizationId,
  byId,
  order,
  isFetchingLiveStatusList,
  isFetchingInformation,
  isFetchingPositions,
  isAcknowledgeEvent,
  isFetchedPosition,
  errorFetchingLiveStatusList,
  errorFetchingInformation,
  errorEditingInformation
});

export default unitLiveStatus;

//Own state
export const getOwnState = (state) => state.units.unitLiveStatus;

//Information
export const getFetchDate = (state, organizationId) =>
  getOwnState(state).organizationId === organizationId ? getOwnState(state).fetchDate : undefined;
export const getUnit = (state, unitId): UnitLiveStatus | null => {
  const intl = appIntl();
  const unit = getOwnState(state).byId[unitId];
  if (unit) {
    const {
      deviceMetricsOrder,
      deviceActionsOrder,
      groupsOrder,
      reportsOrder,
      imagesOrder,
      sensorsOrder,
      ...unitLiveStatus
    } = unit;
    const speedFormat =
      authSelectors
        .getParsers(state)
        .convertKilometersPerHourToAuthUserSpeedSystem(
          unitLiveStatus.speed < 0 ? 0 : unitLiveStatus.speed
        )
        .toFixed(2) +
      ' ' +
      authSelectors.getAuthSystems(state).speed;

    const odometerFormat =
      authSelectors
        .getParsers(state)
        .convertKilometersToAuthUserDistanceSystem(unitLiveStatus.odometer)
        .toFixed(2) +
      ' ' +
      authSelectors.getAuthSystems(state).distance;
    return {
      ...unitLiveStatus,
      speedLimit: authSelectors.getParsers(state).convertKilometersToAuthUserDistanceSystem(240),
      odometerFormat,
      hourMeterFormat: formatHourMeter(unitLiveStatus.hourMeter),
      mainStateId: unitLiveStatus.mainStateId || 0,
      mainState: GetMainStateById(unitLiveStatus.mainStateId),
      mainStateName: GetMainStateName(
        GetMainStateById(unitLiveStatus.mainStateId),
        intl.formatMessage({ id: GetMainStateById(unitLiveStatus.mainStateId).intl }),
        unitLiveStatus.mainStateDate,
        speedFormat,
        unitLiveStatus.downtimeReasonDescription,
        moment(unitLiveStatus.mainStateDate).fromNow()
      ),
      speed: unitLiveStatus.speed < 0 ? 0 : unitLiveStatus.speed,
      speedFormat,
      headingFormat: intl.formatMessage({
        id: getUnitHeadingFormat(unitLiveStatus.heading)
      }),
      historicalPositions: {
        allPositions: unitLiveStatus?.historicalPositions?.allPositions || [],
        distance: unitLiveStatus?.historicalPositions?.distance || 0,
        parkedPositions: unitLiveStatus?.historicalPositions?.parkedPositions || [],
        polyline: unitLiveStatus?.historicalPositions?.polyline || [],
        positions: unitLiveStatus?.historicalPositions?.positions || [],
        distanceFormat:
          authSelectors
            .getParsers(state)
            .convertKilometersToAuthUserDistanceSystem(
              unitLiveStatus?.historicalPositions?.distance || 0
            )
            .toFixed(2) +
          ' ' +
          authSelectors.getAuthSystems(state).distance
      },
      deviceMetrics: deviceMetricsOrder
        ? deviceMetricsOrder.map((id) => deviceMetricsSelectors.getDeviceMetric(state, id))
        : [],
      deviceActions: deviceActionsOrder
        ? deviceActionsOrder.map((id) => deviceActionsSelectors.getDeviceAction(state, id))
        : [],
      groups: groupsOrder ? groupsOrder.map((id) => groupsSelectors.getGroup(state, id)) : [],
      reports: reportsOrder ? reportsOrder.map((id) => reportsSelectors.getReport(state, id)) : [],
      images: imagesOrder ? imagesOrder.map((id) => imagesSelectors.getImage(state, id)) : [],
      sensors: sensorsOrder ? sensorsOrder.map((id) => sensorsSelectors.getSensor(state, id)) : []
    };
  } else return null;
};
export const getUnitsList = (state): UnitLiveStatus[] =>
  getOwnState(state).order.map((id) => getUnit(state, id));

//Status of sagas
export const isFetchingListUnitsLiveStatus = (state) => getOwnState(state).isFetchingLiveStatusList;
export const isFetchingUnitInformation = (state) => getOwnState(state).isFetchingInformation;
export const isFetchingUnitPositions = (state) => getOwnState(state).isFetchingPositions;
export const isAcknowledgeUnitEvent = (state) => getOwnState(state).isAcknowledgeEvent;
export const isFetchedUnitPosition = (state, unitId) =>
  getOwnState(state).isFetchedPosition[unitId] ?? false;

//Errors
export const getFetchingListUnitsLiveStatusErrors = (state) =>
  getOwnState(state).errorFetchingLiveStatusList;
export const getFetchingUnitInformationErrors = (state) =>
  getOwnState(state).errorFetchingInformation;
