import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { getModulesListService } from '../services';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchModulesList(action) {
  const {
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId,
    allowNullOrganizationId
  } = action.payload;

  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getModulesListService(
    {
      operatorId,
      organizationId:
        allowNullOrganizationId && filterOrganizationId === null ? null : organizationId
    },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { modules },
          result //order
        } = normalize(resultData, schemas.modules); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingModules(modules, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingModules(error));
      }
    }
  );
}

export function* watchFetchModulesList() {
  yield takeEvery(types.MODULES_FETCH_STARTED, fetchModulesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchModulesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId,
    allowNullOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getModulesListService(
    {
      userFetchDate: date,
      operatorId,
      organizationId:
        allowNullOrganizationId && filterOrganizationId === null ? null : organizationId
    },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { modules },
          result //order
        } = normalize(resultData, schemas.modules); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingModules(modules, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingModules(error));
      }
    }
  );
}

export function* watchPartialFetchModulesList() {
  yield takeEvery(types.MODULES_PARTIAL_FETCH_STARTED, partialFetchModulesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addModule(action) {
  const module = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/module/save`, {
        method: 'POST',
        body: JSON.stringify({ ...module, deviceId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'modules.module'
          }),
          name: module.identifier
        });
        const addedModule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeAddingModule(module.deviceId, addedModule));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingModule(module.deviceId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingModule(module.deviceId, error));
  }
}

export function* watchAddModulesStarted() {
  yield takeEvery(types.MODULE_ADD_STARTED, addModule);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editModule(action) {
  const module = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/module/save`, {
        method: 'POST',
        body: JSON.stringify({
          ...module
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedModule = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingModule(editedModule));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'modules.module'
          }),
          name: editedModule.identifier
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldModule } = yield select((state) => selectors.getModule(state, module.deviceId));
        yield put(actions.failEditingModule(oldModule, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldModule } = yield select((state) => selectors.getModule(state, module.deviceId));
    yield put(actions.failEditingModule(oldModule, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditModulesStarted() {
  yield takeEvery(types.MODULE_EDIT_STARTED, editModule);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeModule(action) {
  const { moduleId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const deletedModule = yield select((state) => selectors.getModule(state, moduleId));
      const response = yield call(fetch, `${API_BASE_URL}/module/delete`, {
        method: 'POST',
        body: JSON.stringify({ moduleId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingModule(moduleId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'modules.module'
          }),
          name: deletedModule.identifier
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingModule(moduleId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingModule(moduleId, error));
  }
}

export function* watchRemoveModuleStarted() {
  yield takeEvery(types.MODULE_REMOVE_STARTED, removeModule);
}

/* -------------------------------------------------------------------------- */
/*                               FETCH MODULE                               */
/* -------------------------------------------------------------------------- */
function* fetchModule(action) {
  const moduleId = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/module/${moduleId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const module = camelcaseKeys(jsonResult.data);
        yield put(actions.completeFetchingModule(module));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };

        yield put(actions.failFetchingModule(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    yield put(actions.failFetchingModule(error));
  }
}

export function* watchModuleFetchStarted() {
  yield takeEvery(types.MODULE_FETCH_STARTED, fetchModule);
}
