export const AGENTS_FETCH_STARTED = 'AGENTS_FETCH_STARTED';
export const AGENTS_FETCH_COMPLETED = 'AGENTS_FETCH_COMPLETED';
export const AGENTS_FETCH_FAILED = 'AGENTS_FETCH_FAILED';

export const AGENTS_PARTIAL_FETCH_STARTED = 'AGENTS_PARTIAL_FETCH_STARTED';
export const AGENTS_PARTIAL_FETCH_COMPLETED = 'AGENTS_PARTIAL_FETCH_COMPLETED';
export const AGENTS_PARTIAL_FETCH_FAILED = 'AGENTS_PARTIAL_FETCH_FAILED';

export const AGENT_FETCH_STARTED = 'AGENT_FETCH_STARTED';
export const AGENT_FETCH_COMPLETED = 'AGENT_FETCH_COMPLETED';
export const AGENT_FETCH_FAILED = 'AGENT_FETCH_FAILED';

export const AGENT_ADD_STARTED = 'AGENT_ADD_STARTED';
export const AGENT_ADD_COMPLETED = 'AGENT_ADD_COMPLETED';
export const AGENT_ADD_FAILED = 'AGENT_ADD_FAILED';

export const AGENT_EDIT_STARTED = 'AGENT_EDIT_STARTED';
export const AGENT_EDIT_COMPLETED = 'AGENT_EDIT_COMPLETED';
export const AGENT_EDIT_FAILED = 'AGENT_EDIT_FAILED';

export const AGENT_REMOVE_STARTED = 'AGENT_REMOVE_STARTED';
export const AGENT_REMOVE_COMPLETED = 'AGENT_REMOVE_COMPLETED';
export const AGENT_REMOVE_FAILED = 'AGENT_REMOVE_FAILED';

export const AGENT_SELECTED = 'AGENT_SELECTED';
export const AGENTS_ALL_SELECTED = 'AGENTS_ALL_SELECTED';
export const AGENT_DESELECTED = 'AGENT_DESELECTED';
export const AGENTS_ALL_DESELECTED = 'AGENTS_ALL_DESELECTED';
