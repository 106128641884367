// icon.js
import IcoMoon from 'react-icomoon';
const iconSet = require('./IcomoonReactive/selection.json');

const Icon = ({ ...props }) => {
  return (
    <IcoMoon
      iconSet={iconSet}
      {...props}
      icon={props.icon ?? 'Hexagon'}
      size={props.size ?? 20}
      color={props.color ?? 'currentColor'}
    />
  );
};

export default Icon;
