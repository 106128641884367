/* -------------------------------------------------------------------------- */
/*                         Acciones del reductor Auth                         */
/* -------------------------------------------------------------------------- */

import * as types from '../../types/auth';

export const startLogin = ({ username, password }) => ({
  type: types.AUTHENTICATION_STARTED,
  payload: { username, password }
});

export const completeLogin = (token) => ({
  type: types.AUTHENTICATION_COMPLETED,
  payload: { token }
});

export const failLogin = (error) => ({
  type: types.AUTHENTICATION_FAILED,
  payload: { error }
});

export const clearLoginError = () => ({
  type: types.AUTHENTICATION_FAILED_CLEAR
});

export const logout = () => ({
  type: types.AUTHENTICATION_IDENTITY_CLEARED
});

export const setSessionMembership = (membership = {}) => ({
  type: types.AUTHENTICATION_SET_MEMBERSHIP,
  payload: membership
});

export const authenticationUserInformationStarted = () => ({
  type: types.AUTHENTICATION_USER_INFORMATION_STARTED
});

export const authenticationUserInformationCompleted = (user) => ({
  type: types.AUTHENTICATION_USER_INFORMATION_COMPLETED,
  payload: { user }
});

export const startRefreshToken = () => ({
  type: types.TOKEN_REFRESH_STARTED
});

export const startForceRefreshToken = () => ({
  type: types.TOKEN_REFRESH_FORCE_STARTED
});

export const completeRefreshToken = (newToken) => ({
  type: types.TOKEN_REFRESH_COMPLETED,
  payload: { newToken }
});

export const failRefreshToken = (error) => ({
  type: types.TOKEN_REFRESH_FAILED,
  payload: { error }
});

export const startUpdatingProfileGeneralInformation = (values) => ({
  type: types.PROFILE_UPDATE_GENERAL_INFORMATION_STARTED,
  payload: values
});

export const completeUpdatingProfileGeneralInformation = (values) => ({
  type: types.PROFILE_UPDATE_GENERAL_INFORMATION_COMPLETED,
  payload: values
});

export const failUpdatingProfileGeneralInformation = (error) => ({
  type: types.PROFILE_UPDATE_GENERAL_INFORMATION_FAILED,
  payload: { error }
});

export const startUpdatingProfilePassword = (values) => ({
  type: types.PROFILE_UPDATE_PASSWORD_STARTED,
  payload: values
});

export const completeUpdatingProfilePassword = (values) => ({
  type: types.PROFILE_UPDATE_PASSWORD_COMPLETED,
  payload: values
});

export const failUpdatingProfilePassword = (error) => ({
  type: types.PROFILE_UPDATE_PASSWORD_FAILED,
  payload: { error }
});

export const startUpdatingProfilePicture = (values) => ({
  type: types.PROFILE_UPDATE_PICTURE_STARTED,
  payload: values
});

export const completeUpdatingProfilePicture = (values) => ({
  type: types.PROFILE_UPDATE_PICTURE_COMPLETED,
  payload: values
});

export const failUpdatingProfilePicture = (error) => ({
  type: types.PROFILE_UPDATE_PICTURE_FAILED,
  payload: { error }
});

export const startUpdatingProfileHomeScreen = (homeScreenId) => ({
  type: types.PROFILE_UPDATE_HOME_SCREEN_STARTED,
  payload: homeScreenId
});

export const completeUpdatingProfileHomeScreen = (homeScreenId) => ({
  type: types.PROFILE_UPDATE_HOME_SCREEN_COMPLETED,
  payload: homeScreenId
});

export const failUpdatingProfileHomeScreen = (error) => ({
  type: types.PROFILE_UPDATE_HOME_SCREEN_FAILED,
  payload: { error }
});

export const startChangingProduct = (productId) => ({
  type: types.PROFILE_CHANGE_PRODUCT_STARTED,
  payload: productId
});

export const completeChangingProduct = (productId) => ({
  type: types.PROFILE_CHANGE_PRODUCT_COMPLETED,
  payload: productId
});

export const failChangingProduct = (error) => ({
  type: types.PROFILE_CHANGE_PRODUCT_FAILED,
  payload: { error }
});

export const startUpdatingProfileMapOptions = (values) => ({
  type: types.PROFILE_UPDATE_MAP_OPTIONS_STARTED,
  payload: values
});

export const completeUpdatingProfileMapOptions = (values) => ({
  type: types.PROFILE_UPDATE_MAP_OPTIONS_COMPLETED,
  payload: values
});

export const failUpdatingProfileMapOptions = (error) => ({
  type: types.PROFILE_UPDATE_MAP_OPTIONS_FAILED,
  payload: { error }
});

export const startUpdatingMapSettingsConfiguration = (values) => ({
  type: types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_STARTED,
  payload: values
});

export const completeUpdatingMapSettingsConfiguration = (values) => ({
  type: types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_COMPLETED,
  payload: values
});

export const failUpdatingMapSettingsConfiguration = (error) => ({
  type: types.PROFILE_UPDATE_MAP_SETTINGS_CONFIGURATION_FAILED,
  payload: { error }
});
