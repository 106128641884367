import * as types from '../../../types/static-catalogs/vehicle-makers';

export const startFetchingVehicleMakers = () => ({
  type: types.VEHICLE_MAKERS_FETCH_STARTED,
  payload: {}
});
export const completeFetchingVehicleMakers = (entities, order) => ({
  type: types.VEHICLE_MAKERS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingVehicleMakers = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.VEHICLE_MAKERS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
