/* eslint-disable no-param-reassign,no-case-declarations */
import * as axios from 'axios';
import Swal from 'sweetalert2';
import API_BASE_URL from 'redux/sagas/settings/apibaseurl';
import * as alerts from 'redux/utils/alerts-interceptors';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { HttpWebtrack } from 'utility/http';

export interface ConfigMessages {
  object: string;
  name?: string;
  customMessage?: string;
  customMessageError?: string;
  action?: 'Create' | 'Delete' | 'Edit' | 'Get' | 'Custom' | 'Leave';
  genderType?: 'PluralFemale' | 'SingularFemale' | 'PluralMale' | 'SingularMale';
  successTimer?: number;
  useSuccessTimer?: boolean;
  errorTimer?: number;
}

export interface ConfigActions {
  successAction?: Function;
  errorAction?: Function;
}

export const redirectTo = (document: any, path: string) => {
  document.location = path;
  // window.history.pushState({}, '', path);
  // window.location.reload();
};

export const handleSuccess = (
  response: any,
  configMessage: ConfigMessages | undefined,
  configAction: ConfigActions | undefined
) => {
  if (configMessage) {
    const { successTimer = 4000, useSuccessTimer = true } = configMessage;
    const alertObject: alerts.AlertsConfig = {
      object: appIntl().formatMessage({
        id: configMessage.object
      }),
      name: configMessage.name,
      genderType: configMessage.genderType,
      successTimer: !!useSuccessTimer ? successTimer : undefined,
      customMessage: configMessage.customMessage
    };

    switch (configMessage.action) {
      case 'Create':
        alerts.showSuccessCreatedMessage(alertObject);
        break;
      case 'Delete':
        alerts.showSuccessDeletedMessage(alertObject);
        break;
      case 'Edit':
        alerts.showSuccessEditedMessage(alertObject);
        break;
      case 'Leave':
        alerts.showSuccessLeavedMessage(alertObject);
        break;
      case 'Custom':
        if (configMessage.customMessage) alerts.showSuccessCustomMessage(alertObject);
        break;
    }
  }
  if (configAction?.successAction) configAction.successAction(response.data);
  // HttpWebtrack.setConfigMessage(undefined);
  // HttpWebtrack.setConfigActions(undefined);
  return response;
};

export const handleError = (
  error: any,
  configMessage: ConfigMessages | undefined,
  configAction: ConfigActions | undefined
) => {
  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 6000
  });

  switch (error?.response?.status) {
    // case 401:
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'No Autorizado'
    //   });
    //   break;
    // case 403:
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'No Autorizado'
    //   });
    //   setTimeout(() => {
    //     if (error?.response.data?.source !== 'login') {
    //       localStorage.clear();
    //       redirectTo(document, `${API_BASE_URL}/`);
    //     }
    //   }, 2000);
    //   break;
    // case 404:
    //   Toast.fire({
    //     icon: 'error',
    //     title: 'Servicio no encontrado!'
    //   });
    //   break;
    default:
      if (configMessage) {
        const alertObject: alerts.AlertsConfig = {
          object: appIntl().formatMessage({
            id: configMessage.object
          }),
          name: configMessage.name,
          genderType: configMessage.genderType,
          errorTimer: configMessage.action === 'Custom' ? 4000 : configMessage.errorTimer,
          customMessageError: configMessage.customMessageError
        };

        switch (configMessage.action) {
          case 'Create':
            alerts.showFailCreatedMessage(alertObject);
            break;
          case 'Delete':
            alerts.showFailDeletedMessage(alertObject);
            break;
          case 'Leave':
            alerts.showFailLeavedMessage(alertObject);
            break;
          case 'Edit':
            alerts.showFailEditedMessage(alertObject);
            // case 'Get':
            //   alerts.showErrorAlertFailChanges({
            //     error: { errorMessage: 'Error al obtener el listado' }
            //   });
            break;
          case 'Custom':
            if (configMessage.customMessageError) alerts.showErrorCustomMessage(alertObject);
            break;
        }
      }
      break;
  }
  if (configAction?.errorAction) configAction.errorAction(error);
  // HttpWebtrack.setConfigMessage(undefined);
  // HttpWebtrack.setConfigActions(undefined);
  return Promise.reject(error);
};
