import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Trash } from 'react-feather';
import { FormattedMessage } from 'react-intl';

import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import * as authSelectors from '../../../redux/reducers/auth';

interface Props {
  isDisabled?: boolean;
  id?: any;
  permissions?: any[];
  handleClick: any;
  tooltipLabel?: string;
  tooltipMessage?: string;
  visible?: boolean;
}

const DeleteButton = (props: Props) => {
  const store = useSelector((state) => state);
  const {
    id = '',
    isDisabled,
    handleClick,
    tooltipLabel,
    tooltipMessage,
    permissions,
    visible = true
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (!permissions || authSelectors.getAuthUserHasPermissionsAny(store, permissions)) &&
    visible ? (
    <Button.Ripple
      id={`deleteButton${id}`}
      className={`btn-icon ${isDisabled ? 'disabled' : ''}`}
      color="danger"
      disabled={isDisabled}
      onClick={() => handleClick()}
    >
      <Trash size={16} />
      {tooltipMessage && (
        <Tooltip
          placement="auto"
          isOpen={tooltipOpen}
          target={`deleteButton${id}`}
          toggle={toggle}
          trigger="click hover"
          className="bg-transparent"
        >
          <FormattedMessage
            id={tooltipLabel ?? 'buttons.delete'}
            values={{ objectType: appIntl().formatMessage({ id: tooltipMessage }) }}
          />
        </Tooltip>
      )}
    </Button.Ripple>
  ) : null;
};

DeleteButton.defaultProps = {
  isDisabled: false
};

export default DeleteButton;
