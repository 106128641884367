import { appIntl } from 'utility/context/IntlGlobalProvider';
import { HttpWebtrack } from 'utility/http';
import { NoticeByDateRequest, NoticeByHourmeterRequest, NoticeByOdometerRequest } from '../types';
import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';
import { ConfigActions } from 'utility/http/http-events';

export const baseUrl = '/vehicleMaintenance';

export const saveNoticeByOdometer = (request: NoticeByOdometerRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  if (request.noticeId) {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Edit',
      genderType: 'SingularMale'
    });
  } else {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Create',
      genderType: 'SingularMale'
    });
  }
  return httpWebtrack.post(`${baseUrl}/saveNoticeByOdometer`, request);
};

export const saveNoticeByHourmeter = (
  request: Partial<NoticeByHourmeterRequest>,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  if (request.noticeId) {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Edit',
      genderType: 'SingularMale'
    });
  } else {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Create',
      genderType: 'SingularMale'
    });
  }
  return httpWebtrack.post(`${baseUrl}/saveNoticeByHourMeter`, request);
};

export const saveNoticeByDate = (request: NoticeByDateRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  if (request.noticeId) {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Edit',
      genderType: 'SingularMale'
    });
  } else {
    httpWebtrack.setConfigMessage({
      name: ``,
      object: 'vehicleMaintenance.notice',
      action: 'Create',
      genderType: 'SingularMale'
    });
  }
  return httpWebtrack.post(`${baseUrl}/saveNoticeByDate`, request);
};

export const deleteNotice = (request: { noticeId: number; unitId: number }) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: ``,
    object: 'vehicleMaintenance.notice',
    action: 'Delete',
    genderType: 'SingularMale'
  });
  return httpWebtrack.get(`${baseUrl}/deleteNotice/${request.noticeId}/${request.unitId}`);
};

export const getNoticeByOdometerList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/scheduledNoticesByOdometer`);
};

export const getNoticeByDateList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/scheduledNoticesByDate`);
};

export const getNoticeByHourmeterList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/scheduledNoticesByHourmeter`);
};

export const renewNoticeByOdometer = (request: NoticeByOdometerRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: ``,
    object: 'vehicleMaintenance.notice',
    action: 'Custom',
    customMessage: appIntl().formatMessage({ id: 'vehicleMaintenance.noticeRenewSuccessMsg' })
  });
  return httpWebtrack.post(`${baseUrl}/renewNoticeByOdometer`, request);
};

export const renewNoticeByHourmeter = (request: NoticeByHourmeterRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: ``,
    object: 'vehicleMaintenance.notice',
    action: 'Custom',
    customMessage: appIntl().formatMessage({ id: 'vehicleMaintenance.noticeRenewSuccessMsg' })
  });
  return httpWebtrack.post(`${baseUrl}/renewNoticeByHourmeter`, request);
};

export const renewNoticeByDate = (request: NoticeByOdometerRequest) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: ``,
    object: 'vehicleMaintenance.notice',
    action: 'Custom',
    customMessage: appIntl().formatMessage({ id: 'vehicleMaintenance.noticeRenewSuccessMsg' })
  });
  return httpWebtrack.post(`${baseUrl}/renewNoticeByDate`, request);
};
