import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/catalogs/spareParts';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/spareParts';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/catalogs/spareParts';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteSparePartCategory,
  getSparePartList,
  saveSparePartCategory
} from 'views/screens/vehicleMaintenance/services/sparePartsService';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteSparePartCategoryDTO,
  SaveSparePartCategoryDTO
} from 'views/screens/vehicleMaintenance/types';
/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchSparePartsList(action) {
  try {
    const jsonResult = yield call(getSparePartList);
    const resultData = jsonResult.map((result) => camelcaseKeys(result));
    const {
      entities: { spareParts },
      result //order
    } = normalize(resultData, schemas.spareParts); //normalize data to byId and order
    yield put(actions.completeFetchingSpareParts(spareParts, result));
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingSpareParts(error));
  }
}

export function* watchFetchSparePartsList() {
  yield takeEvery(types.SPAREPARTS_FETCH_STARTED, fetchSparePartsList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchSparePartsList(action) {}

export function* watchPartialFetchSparePartsList() {
  yield takeEvery(types.SPAREPARTS_PARTIAL_FETCH_STARTED, partialFetchSparePartsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addSparePart(action: PayloadAction<SaveSparePartCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveSparePartCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingSparePart(addedData.sparePartId, request.tempId, {
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failAddingSparePart(request.tempId, e));
  }
}

export function* watchAddSparePartsStarted() {
  yield takeEvery(types.SPAREPART_ADD_STARTED, addSparePart);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editSparePart(action: PayloadAction<SaveSparePartCategoryDTO>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(saveSparePartCategory, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingSparePart({
        ...request,
        ...addedData
      })
    );
  } catch (e) {
    yield put(actions.failEditingSparePart(request.sparePartId, e));
  }
}

export function* watchEditSparePartsStarted() {
  yield takeEvery(types.SPAREPART_EDIT_STARTED, editSparePart);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeSparePart(action: PayloadAction<DeleteSparePartCategoryDTO>) {
  const request = action.payload;
  try {
    yield call(deleteSparePartCategory, request);
    yield put(actions.completeRemovingSparePart(request.sparePartId));
  } catch (e) {
    yield put(actions.failRemovingSparePart(request.sparePartId, e));
  }
}

export function* watchRemoveSparePartStarted() {
  yield takeEvery(types.SPAREPART_REMOVE_STARTED, removeSparePart);
}
