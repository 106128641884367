/* -------------------------------------------------------------------------- */
/*                       Schema Execution Workplans                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const workplansVisit = new schema.Entity(
  'workplansVisits',
  {},
  { idAttribute: 'historicalVisitId' }
);
export const workplansVisits = new schema.Array(workplansVisit);
