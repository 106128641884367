/* -------------------------------------------------------------------------- */
/*                            Schema Agents                                   */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const configuration = new schema.Entity(
  "configurations",
  {},
  { idAttribute: "deviceConfigurationId" }
);
export const configurations = new schema.Array(configuration);
