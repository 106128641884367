import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/workplan';

export const getWorkplanCommentsListService = async (
  request: any,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getComments`, request);
};

export const saveCommentService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'workplans.comment',
    action: request.workplanCommentId ? 'Edit' : 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveComment`, request);
};

export const deleteCommentService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'workplans.comment',
    action: 'Delete',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/deleteComment`, request);
};
