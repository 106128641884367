/* eslint-disable semi */
import { applyMiddleware, compose, createStore } from 'redux';
import { createInjectorsEnhancer } from 'redux-injectors';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import createSagaMiddleware from 'redux-saga';
import reducer from '../reducers';
import mainSaga from '../sagas';

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'auth',
    'entities',
    'workplans',
    'routes',
    'locations',
    'drivers',
    'geofences',
    'jobsBacklog',
    'groups',
    'jobsBoard',
    'jobsReport',
    'units'
  ],
  //auth is a nested blacklist, check auth reducer for blacklist details
  //blacklist no guarda
  //whitelist sí guarda
  // There is an issue in the source code of redux-persist (default setTimeout does not cleaning)
  timeout: null
};

const persistedReducer = persistReducer(persistConfig, reducer);

let composeEnhancers = compose;

// if (process.env.NODE_ENV === "development") {
composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 15
    })) ||
  compose;
// }

const runSaga = sagaMiddleware.run;
const injectEnhancer = createInjectorsEnhancer({
  createReducer: reducer,
  runSaga
});

// const store = composeEnhancers(applyMiddleware(sagaMiddleware))(createStore)(persistedReducer);
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware), injectEnhancer)
);

const persistor = persistStore(store);

sagaMiddleware.run(mainSaga);

export { persistor, store };
