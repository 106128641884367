// ** React Imports
import { Suspense, lazy } from 'react';
import ReactDOM, { Route } from 'react-dom';
import './globals';
// ** Redux Imports
import { Provider } from 'react-redux';
import { persistor, store } from './redux/storeConfig/store';
import { PersistGate } from 'redux-persist/integration/react';

// ** Intl, CASL & ThemeColors Context
import ability from './configs/acl/ability';
import { AbilityContext } from './utility/context/Can';
import { IntlProviderWrapper } from './utility/context/Internationalization';

// ** Toast & ThemeColors Context
import { ToastContainer } from 'react-toastify';
import { ThemeContext } from './utility/context/ThemeColors';

// ** Spinner (Splash Screen)
import Spinner from './@core/components/spinner/Fallback-spinner';

// ** Ripple Button
import './@core/components/ripple-button';

// ** PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx.min';

// ** React Perfect Scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css';

//** Rect-Bootstrap **
import 'bootstrap/dist/css/bootstrap.min.css';

// ** React Toastify
import '@styles/react/libs/toastify/toastify.scss';

// ** Core styles
import './@core/assets/fonts/feather/iconfont.css';
import './@core/scss/core.scss';
import './assets/scss/style.scss';

// ** Service Worker
import * as serviceWorker from './serviceWorker';
import { isUserLoggedIn } from '@utils';

// ** Query params provider
import { QueryParamProvider } from 'use-query-params';

import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';

import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense(
  'Mgo+DSMBaFt/QHNqVVhkW1pFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF9iSH9bd0NnUHxdcXZWRA==;Mgo+DSMBPh8sVXJ0S0V+XE9AcVRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS3xSd0RrWHlaeXdWR2RZUw==;ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RiUX5YdH1WRWFbUUY=;NzYzODkxQDMyMzAyZTMzMmUzMEpVYnBRSnd4cVAxNXo5UTExZzBXY0p3d2JZSE1ZOGhkaFBYMlZ4cHVibGc9;NzYzODkyQDMyMzAyZTMzMmUzMGliaWllODl5K0VLY1VlU1Q4cXhWdnNFNDBmRERUcUIwdmtkR2VpdkRKQ1U9;NRAiBiAaIQQuGjN/V0Z+X09EaFtFVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdERiWHdfdnBcRWNeVkx0;NzYzODk0QDMyMzAyZTMzMmUzMEgvZTVraXlQOVFpdklQR1ZmM1l0QUJoUE1zTnRzQ2RqOG9RQVdvNlYwK1E9;NzYzODk1QDMyMzAyZTMzMmUzMGc4UGJna0hnVzI3b3RkYStWUm5wblFJK0JOOUdFdjZ1Ynl3K3VFQUpEWkU9;Mgo+DSMBMAY9C3t2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0RiUX5YdH1WRWRVUkY=;NzYzODk3QDMyMzAyZTMzMmUzME1zWnlxQk1aUHNpOUtPdHJOL3l6UFdjSVM5aDhoZE9FWW1seXdHME44dEk9;NzYzODk4QDMyMzAyZTMzMmUzMGp3WkZqdmZkdU56VmpoU0NNVHkybG9xWmErM0F0cTE2RXVoNFhWZSs0ZDQ9;NzYzODk5QDMyMzAyZTMzMmUzMEgvZTVraXlQOVFpdklQR1ZmM1l0QUJoUE1zTnRzQ2RqOG9RQVdvNlYwK1E9'
);

// ** Lazy load app
//
const LazyApp = lazy(() => import('./App'));

// same configuration you would create for the Rollbar.js SDK
const rollbarConfig = {
  accessToken: 'c8343b46465b4e74b54163748c1bdf25',
  environment: process.env.NODE_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true
};

// replace console.* for disable log on production
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  <RollbarProvider config={rollbarConfig}>
    <ErrorBoundary>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <Suspense fallback={<Spinner />}>
              <AbilityContext.Provider value={ability}>
                <ThemeContext>
                  <IntlProviderWrapper>
                    <LazyApp />
                    <ToastContainer newestOnTop />
                  </IntlProviderWrapper>
                </ThemeContext>
              </AbilityContext.Provider>
            </Suspense>
          </PersistGate>
        </Provider>
      </QueryParamProvider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
