// @ts-nocheck
// import Control from 'react-leaflet-control';
import { env } from 'env';
import '@geoman-io/leaflet-geoman-free';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-toolbar';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Eye, EyeOff, Plus, X, Hexagon, Square, Circle } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { v4 as uuidv4 } from 'uuid';
import * as authSelectors from '../../../../../redux/reducers/auth';
// import * as geofenceClassesSelectors from '../../../../../redux/reducers/static-catalogs/geofence-classes';
import * as geofenceActions from '../../../../../views/screens/Geofences/actions';
import geofencePermissions from '../../../../../views/screens/Geofences/permissions';
import SlidingPaneForm from '../../../../formComponents/SlidingPaneForm';
import Icon from 'utility/icomoon';
import wkx from 'wkx';
import { getExtraGeofenceSaveValues } from 'views/screens/Geofences/utils';
import * as geofenceSelectors from 'views/screens/Geofences/reducers';
import { zoomMapToGeofence } from '../MapGeofences';

const style = getComputedStyle(document.body);
const primary = style.getPropertyValue('--primary').trim();

export interface GeofencesToolbarOptions {
  useToolbar: boolean;
  showGeofences?: bool;
  setShowGeofences?: Function;
  listenMapControlChanges?: any[];
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

/* -------------------------------------------------------------------------- */
/*                           ToolbarGeofencesControl                          */
/* -------------------------------------------------------------------------- */
const ToolbarGeofencesControl = (props: GeofencesToolbarOptions) => {
  const { showGeofences, setShowGeofences, position = 'topleft', listenMapControlChanges } = props;
  const context = useLeafletContext();
  const intl = useIntl();
  const map = useMap();
  const dispatch = useDispatch();

  //States to create new geofences
  const [newGeofences, setNewGeofencesLayers] = useState<any[]>([]);
  const newGeofencesLayersRef = React.useRef(newGeofences);
  const [isOpenSlidingPaneForm, setOpenSlidingPaneForm] = useState(false);
  const isOpenSlidingPaneFormRef = React.useRef(isOpenSlidingPaneForm);
  const [slidingPaneProps, setSlidingPaneProps] = useState<any>({ inputs: [] });
  const slidingPanePropsRef = React.useRef(slidingPaneProps);
  const [editingGeofence, setEditingGeofence] = useState<any>(undefined);
  const editingGeofenceRef = React.useRef(editingGeofence);
  const isSatMonitor = !!parseInt(env.REACT_APP_SAT_MONITOR ?? '0');

  const { store, mainGeofence } = useSelector((state) => ({
    store: state,
    mainGeofence: geofenceSelectors.getGeofence(state, editingGeofence?.geofence?.parentGeofenceId)
  }));

  useEffect(() => {
    if (mainGeofence?.geofenceId) {
      zoomMapToGeofence(map, true, mainGeofence);
      dispatch(geofenceActions.showGeofenceInMap(mainGeofence.geofenceId));
      return () => {
        dispatch(geofenceActions.hideGeofenceInMap(mainGeofence.geofenceId));
      };
    }
  }, [mainGeofence?.geofenceId]);

  useEffect(() => {
    editingGeofenceRef.current = editingGeofence;
    if (editingGeofence !== undefined) {
      editGeofence();
    }
  }, [editingGeofence]);

  useEffect(() => {
    isOpenSlidingPaneFormRef.current = isOpenSlidingPaneForm;
  }, [isOpenSlidingPaneForm]);

  useEffect(() => {
    slidingPanePropsRef.current = slidingPaneProps;
  }, [slidingPaneProps]);

  useEffect(() => {
    newGeofencesLayersRef.current = newGeofences;
  }, [newGeofences]);

  const editGeofence = () => {
    const layer = editingGeofenceRef.current.layer;
    const shape = layer.pm._shape ? layer.pm._shape.charAt(0).toUpperCase() : 'C';
    const initialRadius = shape == 'C' ? layer.getRadius() : undefined;
    newGeofencesLayersRef.current.forEach((m) => {
      try {
        if (layer.editing.options.geofenceId != m.editing.options.geofenceId) {
          m.pm.disable({});
        }
      } catch {}
    });

    setOpenSlidingPaneForm(false);
    setSlidingPaneProps({
      title: editingGeofenceRef.current?.geofence
        ? editingGeofenceRef.current?.geofence.geofenceId
          ? editingGeofenceRef.current?.geofence.geofenceName
          : intl.formatMessage({ id: 'geofences.addGeofence' })
        : intl.formatMessage({ id: 'geofences.addGeofence' }),
      inputs: [
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.name' }),
          required: true,
          initialValue: editingGeofenceRef.current?.geofence
            ? editingGeofenceRef.current?.geofence.geofenceName
            : undefined,
          name: 'geofenceName',
          additionalonchangefunction: (value) => {
            layer.editing.options = {
              ...layer.editing.options,
              geofenceName: value
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, geofenceName: value }
            });
            try {
              layer.bindPopup(value);
              layer.openPopup();
            } catch (error) {}
          },
          customValidation(values, props) {
            return values !== null && values !== undefined ? undefined : (
              <FormattedMessage id={'common.requiredField'} />
            );
          }
        },
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.geofenceAddress' }),
          required: false,
          initialValue: editingGeofenceRef.current?.geofence
            ? editingGeofenceRef.current?.geofence.geofenceAddress
            : '',
          name: 'geofenceAddress',
          additionalonchangefunction: (value) => {
            layer.editing.options = {
              ...layer.editing.options,
              geofenceAddres: value
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, geofenceAddress: value }
            });
          }
        },
        {
          type: 'geofenceShapesDropdown',
          required: true,
          initialValue: shape ? shape : 'C',
          name: 'shape',
          customValidation(values, props) {
            return values !== null && values !== undefined ? undefined : (
              <FormattedMessage id={'common.requiredField'} />
            );
          },
          disabled: true,
          additionalonchangefunction: (value) => {
            layer.editing.options = {
              ...layer.editing.options,
              shape: value
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, shape: value }
            });
          }
        },
        shape && shape == 'C'
          ? {
              type: 'number',
              allowUndefined: false,
              label: intl.formatMessage({ id: 'geofences.radius' }),
              required: true,
              min: 1,
              step: 1,
              allowDecimals: true,
              useNumberSpinner: true,
              appendcomponent: 'm',
              max: 100000,
              name: 'radius',
              initialValue:
                editingGeofenceRef.current?.geofence && editingGeofenceRef.current?.geofence.radius
                  ? editingGeofenceRef.current?.geofence.radius
                  : initialRadius,
              additionalonchangefunction: (value) => {
                if (value) {
                  layer.editing.options = {
                    ...layer.editing.options,
                    radius: value
                  };
                  setEditingGeofence({
                    layer: layer,
                    geofence: { ...editingGeofenceRef.current?.geofence, radius: value }
                  });
                  try {
                    layer.setRadius(value);
                  } catch {}
                }
              }
            }
          : {},
        {
          type: 'colorPicker',
          label: intl.formatMessage({ id: 'geofences.color' }),
          required: true,
          name: 'color',
          initialValue:
            editingGeofenceRef.current?.geofence && editingGeofenceRef.current?.geofence.color
              ? editingGeofenceRef.current?.geofence.color
              : undefined,
          additionalonchangefunction: (value) => {
            layer.editing.options = {
              ...layer.editing.options,
              color: value.trim()
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, color: value.trim() }
            });

            try {
              layer.setStyle({
                color: value.trim()
              });
            } catch {}
          }
        },
        {
          type: 'text',
          label: intl.formatMessage({ id: 'geofences.privateCode' }),
          required: false,
          name: 'privateCode',
          initialValue: editingGeofenceRef.current?.geofence
            ? editingGeofenceRef.current?.geofence.privateCode
            : '',
          additionalonchangefunction: (value) => {
            layer.editing.options = {
              ...layer.editing.options,
              privateCode: value
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, privateCode: value }
            });
          }
        },
        {
          type: 'membership',
          name: 'membership',
          options: {
            colSize: '12',
            useOperator: false,
            useUserOrganization: true,
            showAllDivision: true,
            showAllSubdivision: true
          },
          initialValue: editingGeofenceRef.current?.geofence
            ? {
                operatorId: editingGeofenceRef.current.geofence.operatorId,
                organizationId: editingGeofenceRef.current.geofence.organizationId,
                divisionId: editingGeofenceRef.current.geofence.divisionId,
                subdivisionId: editingGeofenceRef.current.geofence.subdivisionId
              }
            : undefined,
          additionalonchangefunction: (values) => {
            layer.editing.options = {
              ...layer.editing.options,
              membership: values,
              ...values
            };
            setEditingGeofence({
              layer: layer,
              geofence: { ...editingGeofenceRef.current?.geofence, membership: values, ...values }
            });
          }
        },
        isSatMonitor
          ? {
              type: 'radioButtons',
              name: 'isParentGeofence',
              label: `${intl.formatMessage({ id: 'geofences.type' })}:`,
              initialValue:
                editingGeofenceRef.current?.geofence?.isParentGeofence === true
                  ? '1'
                  : editingGeofenceRef.current?.geofence?.isParentGeofence === false
                  ? '0'
                  : undefined,
              radioButtons: [
                {
                  label: intl.formatMessage({ id: 'geofences.mainGeofence' }),
                  value: '1'
                },
                {
                  label: intl.formatMessage({ id: 'geofences.subGeofence' }),
                  value: '0'
                }
              ],
              additionalonchangefunction: (value) => {
                setEditingGeofence({
                  layer: layer,
                  geofence: {
                    ...editingGeofenceRef.current?.geofence,
                    isParentGeofence: value == '1'
                  }
                });
              }
            }
          : undefined,
        editingGeofenceRef.current?.geofence?.isParentGeofence === false && !!isSatMonitor
          ? {
              type: 'geofenceDropdown',
              name: 'parentGeofenceId',
              initialValue: editingGeofenceRef.current?.geofence?.parentGeofenceId,
              label: intl.formatMessage({ id: 'geofences.mainGeofence' }),
              placeholder: intl.formatMessage({ id: 'workplans.selectGeofence' }),
              isClearable: false,
              parentGeofences: true,
              required: true,
              subGeofenceWktString: editingGeofenceRef.current?.geofence?.geofenceWktString,
              additionalonchangefunction: (value) => {
                setEditingGeofence({
                  layer: layer,
                  geofence: {
                    ...editingGeofenceRef.current?.geofence,
                    parentGeofenceId: value
                  }
                });
              }
            }
          : undefined,
        editingGeofenceRef.current?.geofence?.isParentGeofence === false && !!isSatMonitor
          ? {
              type: 'switch',
              name: 'isActivationGeofence',
              initialValue: editingGeofenceRef.current?.geofence?.isActivationGeofence,
              label: intl.formatMessage({ id: 'trackingUnits.sat.isActivation/DeactivationArea' })
            }
          : undefined
      ],
      action: (values) => {
        var geometry = wkx.Geometry.parseGeoJSON(layer.toGeoJSON().geometry);
        map.removeLayer(layer);

        const geofence = {
          ...editingGeofenceRef.current?.geofence,
          geofenceWktString: geometry.toWkt()
        };
        const { vertexCount, longitude, longitude2, latitude, latitude2, vertexCsv, geofenceWKT } =
          getExtraGeofenceSaveValues(geofence);

        dispatch(
          geofenceActions.startAddingGeofence({
            ...editingGeofenceRef.current?.geofence,
            ...values,
            ...values.membership,
            isParentGeofence:
              values.isParentGeofence == '1'
                ? true
                : values.isParentGeofence == '0'
                ? false
                : undefined,
            parentGeofenceId: values.isParentGeofence == '0' ? values.parentGeofenceId : undefined,
            isActivationGeofence:
              values.isParentGeofence == '0' ? values.isActivationGeofence : undefined,
            geofenceWKT,
            vertexCount,
            latitude,
            latitude2,
            longitude,
            longitude2,
            vertexCsv,
            isShownInMap: true,
            geofenceId: uuidv4()
          })
        );
        setEditingGeofence(undefined);
      }
    });
    setOpenSlidingPaneForm(true);

    setTimeout(() => {
      try {
        layer.pm.enable({});
      } catch {}
    }, 100);
    setTimeout(() => {
      try {
        layer.bindPopup(
          layer.editing.options.geofenceName
            ? layer.editing.options.geofenceName
            : appIntl().formatMessage({ id: 'geofences.new' })
        );
        layer.openPopup();
      } catch {}
    }, 100);
  };

  useEffect(() => {
    const container = context.layerContainer || context.map;
    try {
      /* -------------------------------------------------------------------------- */
      /*                         Geofence Creation Listener                         */
      /* -------------------------------------------------------------------------- */
      container.on('pm:create', function (e) {
        setEditingGeofence(undefined);
        if (
          (e.shape == 'Circle' || e.shape == 'Rectangle' || e.shape == 'Polygon') &&
          e.layer.editing?.options?.geofenceId == null
        ) {
          let layer = e.layer;
          layer.pm.enableLayerDrag();
          layer.pm.enable({ draggable: true });
          layer.on('click', (e) => {
            setOpenSlidingPaneForm(false);
            setSlidingPaneProps({ inputs: [] });
            setEditingGeofence({
              layer: layer,
              geofence: layer.editing.options
            });
          });
          layer.on('pm:edit', (e) => {
            var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);

            layer.editing.options = {
              ...layer.options,
              organizationId: undefined,
              divisionId: undefined,
              subdivisionId: undefined,
              geofenceWktString: geometry.toWkt(),
              radius:
                editingGeofenceRef.current?.geofence?.shape == 'C'
                  ? e.target.getRadius()
                  : undefined
            };
            setEditingGeofence({
              layer: layer,
              geofence: {
                ...editingGeofenceRef.current?.geofence,
                geofenceWktString: geometry.toWkt(),
                radius:
                  editingGeofenceRef.current?.geofence?.shape == 'C'
                    ? e.target.getRadius()
                    : undefined
              }
            });
          });
          layer.on('pm:dragend', (e) => {
            layer.pm.enableLayerDrag();
            layer.pm.enable({ draggable: true });
            var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);

            layer.editing.options = {
              ...layer.editing.options,
              geofenceWktString: geometry.toWkt(),
              radius:
                editingGeofenceRef.current?.geofence?.shape == 'C'
                  ? e.target.getRadius()
                  : undefined
            };
            setEditingGeofence({
              layer: layer,
              geofence: {
                ...editingGeofenceRef.current?.geofence,
                geofenceWktString: geometry.toWkt(),
                radius:
                  editingGeofenceRef.current?.geofence?.shape == 'C'
                    ? e.target.getRadius()
                    : undefined
              }
            });
          });
          layer.on('dblclick', () => {
            layer.pm.remove();
          });

          let shape = e.shape.charAt(0).toUpperCase();
          var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);
          setOpenSlidingPaneForm(false);
          setSlidingPaneProps({ inputs: [] });
          if (!layer.editing) layer.editing = {};
          layer.editing.options = {
            ...layer.options,
            geofenceId: uuidv4(),
            geofenceName: undefined,
            geofenceAddress: '',
            shape: shape,
            radius: shape == 'C' ? e.layer.getRadius() : undefined,
            geofenceWktString: geometry.toWkt(),
            operatorId: undefined,
            organizationId: undefined,
            divisionId: undefined,
            subdivisionId: undefined,
            privateCode: '',
            isConfirmed: false
          };
          setNewGeofencesLayers((prevlayers) => [...prevlayers, layer]);

          //   // zoomMapToGeofence(layer.getLatLng());
          setEditingGeofence({
            layer: layer,
            geofence: layer.editing.options
          });
        }
      });

      /* -------------------------------------------------------------------------- */
      /*                             Actions of Toolbar                             */
      /* -------------------------------------------------------------------------- */
      var ImmediateSubAction = L.Toolbar2.Action.extend({
        initialize: function (map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks: function () {
          this.myAction.disable();
        }
      });

      var viewGeofences = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: showGeofences
              ? intl.formatMessage({ id: 'map.geofencesToolbar.tooltipHideGeofences' })
              : intl.formatMessage({ id: 'map.geofencesToolbar.tooltipShowGeofences' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>{showGeofences ? <EyeOff size={14} /> : <Eye size={14} />}</>
            )
          }
        },
        addHooks: () => {
          if (!showGeofences) {
            dispatch(geofenceActions.showAllGeofencesInMap());
          } else {
            dispatch(geofenceActions.hideAllGeofencesInMap());
          }
          setShowGeofences(!showGeofences);
          setOpenSlidingPaneForm(false);
          newGeofencesLayersRef.current.forEach((m) => {
            try {
              m.pm.disableLayerDrag();
              m.pm.disable({});
              m.closePopup();
            } catch {}
          });
        }
      });

      var createNewCircleGeofence = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.geofencesToolbar.tooltipAddCircle' }),
            html: ReactDOMServer.renderToStaticMarkup(<> {<Circle size={14} />}</>)
          }
        },
        addHooks: () => {
          container.pm.enableDraw('Circle', { pathOptions: { color: primary } });
        }
      });

      var createNewRectangleGeofence = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.geofencesToolbar.tooltipAddRectangle' }),
            html: ReactDOMServer.renderToStaticMarkup(<> {<Square size={14} />}</>)
          }
        },
        addHooks: () => {
          container.pm.enableDraw('Rectangle', { pathOptions: { color: primary } });
        }
      });

      var createNewPolygonGeofence = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.geofencesToolbar.tooltipAddPolygon' }),
            html: ReactDOMServer.renderToStaticMarkup(<> {<Hexagon size={14} />}</>)
          }
        },
        addHooks: () => {
          container.pm.enableDraw('Polygon', {
            pathOptions: { color: primary },
            removeLayerBelowMinVertexCount: false
          });
        }
      });

      var cancelGeofence = ImmediateSubAction.extend({
        options: {
          toolbarIcon: {
            // html: '<i class="fa fa-times"></i>'
            tooltip: intl.formatMessage({ id: 'common.tooltipCancel' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <X size={14} />
              </>
            )
          }
        },
        addHooks: function () {
          container.pm.disableDraw('layer');
          ImmediateSubAction.prototype.addHooks.call(this);
        }
      });

      var geofencesOptions = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            // html: '<i class="fa fa-map-layer" uib-tooltip="{{\'views.map.sidebar.Geofence\' | translate}}" tooltip-append-to-body="true" tooltip-placement="right"></i>'
            tooltip: intl.formatMessage({ id: 'map.geofencesToolbar.tooltipGeofences' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <Icon size={20} icon="Geofences" />
              </>
            )
          },

          subToolbar: new L.Toolbar2({
            actions: [
              authSelectors.getAuthUserHasPermissionsEvery(store, [
                geofencePermissions.listGeofencesOnMap
              ])
                ? viewGeofences
                : null,
              authSelectors.getAuthUserHasPermissionsEvery(store, [geofencePermissions.addGeofence])
                ? createNewCircleGeofence
                : null,
              authSelectors.getAuthUserHasPermissionsEvery(store, [geofencePermissions.addGeofence])
                ? createNewRectangleGeofence
                : null,
              authSelectors.getAuthUserHasPermissionsEvery(store, [geofencePermissions.addGeofence])
                ? createNewPolygonGeofence
                : null,
              cancelGeofence
            ].filter((action) => action != null)
          })
        },
        disable: function (e) {
          L.Toolbar2.Action.prototype.disable.call(this);
        }
      });

      L.Toolbar2.GeofencesToolbar = L.Toolbar2.Control.extend({
        options: {
          actions: [geofencesOptions]
        }
      });

      var geofencesToolbar = new L.Toolbar2.GeofencesToolbar({ position: position });
      geofencesToolbar.addTo(container);

      return () => {
        geofencesToolbar.remove();
      };
    } catch {}
  }, [...listenMapControlChanges]);
  return (
    <>
      <SlidingPaneForm
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneForm}
        closeSlidingPaneForm={() => {
          setOpenSlidingPaneForm(false);
          try {
            editingGeofenceRef.current.layer.pm.disable({});
          } catch {}
          newGeofencesLayersRef.current.forEach((m, i) => {
            try {
              m.pm.disableLayerDrag();
              m.pm.disable({});
              m.closePopup();
            } catch {}
          });
        }}
        onClickCancelButton={() => {
          map.removeLayer(editingGeofenceRef.current.layer);
          setEditingGeofence(undefined);
        }}
        isInMap={true}
        {...slidingPaneProps}
      />
    </>
  );
};

ToolbarGeofencesControl.defaultProps = {
  position: 'topleft',
  showGeofences: false,
  setShowGeofence: () => null,
  listenMapControlChanges: []
};

export default ToolbarGeofencesControl;
