/* -------------------------------------------------------------------------- */
/*                                 Schema Notices                          */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const notice = new schema.Entity(
  'notices',
  {},
  { idAttribute: (value) => `${value.unitId}-${value.noticeId}` }
);
export const notices = new schema.Array(notice);
