import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';
import configurations from '../../../../redux/reducers/configurations';
import * as authTypes from '../../../../redux/types/auth';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.MONITORING_USERS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;

      const newState = {};
      order.forEach((monitoringUserId) => {
        newState[monitoringUserId] = {
          isSelected: state[monitoringUserId]?.isSelected ?? false,
          ...entities[monitoringUserId],
          isConfirmed: true
        };
      });
      return newState;
    }
    case types.MONITORING_USERS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((monitoringUserId) => {
        newState[monitoringUserId] = {
          isSelected: newState[monitoringUserId].isSelected ?? false,
          ...entities[monitoringUserId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.MONITORING_USER_ADD_COMPLETED: {
      const { oldId, monitoringUser } = action.payload;
      const newState = omit(state, oldId);
      newState[monitoringUser.userName] = {
        isShownInMap: false,
        ...state[oldId],
        ...monitoringUser,
        isConfirmed: true
      };
      return newState;
    }

    case types.MONITORING_USER_EDIT_COMPLETED: {
      const { oldId, monitoringUser } = action.payload;
      return {
        ...omit(state, [oldId]),
        [monitoringUser.userName]: {
          isShownInMap: false,
          ...omit(state[oldId], ['oldMonitoringUser']),
          ...omit(monitoringUser, ['oldMonitoringUser']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.MONITORING_USER_ADD_STARTED: {
      const monitoringUser = action.payload;
      const newState = { ...state };
      newState[monitoringUser.userName] = {
        isShownInMap: false,
        isSelected: false,
        ...monitoringUser,
        isConfirmed: false
      };
      return newState;
    }
    case types.MONITORING_USER_EDIT_STARTED: {
      const monitoringUser = action.payload;
      return {
        ...state,
        [monitoringUser.oldUserName]: {
          isShownInMap: false,
          ...state[monitoringUser.oldUserName],
          oldMonitoringUser: state[monitoringUser.oldUserName],
          ...monitoringUser,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.MONITORING_USER_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.MONITORING_USER_EDIT_FAILED: {
      const { oldMonitoringUser } = action.payload;
      return {
        ...state,
        [oldMonitoringUser.userName]: {
          isShownInMap: false,
          ...omit(state[oldMonitoringUser.userName], ['oldMonitoringUser']),
          ...oldMonitoringUser,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.MONITORING_USER_REMOVE_COMPLETED: {
      const { users } = action.payload;
      return omit(state, users);
    }

    case types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_COMPLETED: {
      const values = action.payload;
      const newState = {
        ...state,
        [values.userName]: {
          ...state[values.userName],
          roleId: values.roleId
        }
      };
      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.MONITORING_USER_SELECTED: {
      const monitoringUserId = action.payload;
      const newState = {
        ...state,
        [monitoringUserId]: {
          ...state[monitoringUserId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.MONITORING_USER_DESELECTED: {
      const monitoringUserId = action.payload;
      return {
        ...state,
        [monitoringUserId]: {
          ...state[monitoringUserId],
          isSelected: false
        }
      };
    }

    case types.MONITORING_USERS_ALL_SELECTED: {
      const monitoringUserIds = action.payload;
      const newState = { ...state };
      if (monitoringUserIds.length == 0) {
        forEach(state, (monitoringUser: any, monitoringUserId) => {
          newState[monitoringUserId] = {
            ...monitoringUser,
            isSelected: true
          };
        });
      } else {
        monitoringUserIds.forEach((monitoringUserId) => {
          newState[monitoringUserId] = {
            ...state[monitoringUserId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.MONITORING_USERS_ALL_DESELECTED: {
      const monitoringUserIds = action.payload;
      const newState = { ...state };
      if (monitoringUserIds.length == 0) {
        forEach(state, (monitoringUser: any, monitoringUserId) => {
          newState[monitoringUserId] = {
            ...monitoringUser,
            isSelected: false
          };
        });
      } else {
        monitoringUserIds.forEach((monitoringUserId) => {
          newState[monitoringUserId] = {
            ...state[monitoringUserId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    case types.USERS_ASSIGN_SCHEDULE_COMPLETED: {
      const userAssignSchedule = action.payload;
      const newState = { ...state };
      userAssignSchedule?.users?.forEach((userName) => {
        newState[userName] = {
          ...state[userName],
          scheduleId: userAssignSchedule.scheduleId,
          scheduleName: userAssignSchedule.scheduleName
        };
      });
      return newState;
    }

    case types.USERS_UNASSIGN_SCHEDULE_COMPLETED: {
      const userAssignSchedule = action.payload;
      const newState = { ...state };
      userAssignSchedule?.users?.forEach((userName) => {
        newState[userName] = {
          ...state[userName],
          scheduleId: null,
          scheduleName: null
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.MONITORING_USERS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }
    case types.MONITORING_USERS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.MONITORING_USER_FETCH_COMPLETED: {
      const monitoringUser = action.payload;
      return uniq([...state, monitoringUser.monitoringUserId]);
    }
    case types.MONITORING_USER_ADD_COMPLETED: {
      const { oldId, monitoringUser } = action.payload;
      return state.map((userName) => (userName === oldId ? monitoringUser.userName : userName));
    }

    case types.MONITORING_USER_EDIT_COMPLETED: {
      const { oldId, monitoringUser } = action.payload;
      if (oldId !== monitoringUser.userName) {
        return state.filter((userNameState) => userNameState !== oldId);
      }
      return state;
    }

    case types.MONITORING_USER_REMOVE_COMPLETED: {
      const { users } = action.payload;
      return state.filter((userNameState) => users.indexOf(userNameState) < 0);
    }

    //CASE ADD STARTED
    case types.MONITORING_USER_ADD_STARTED: {
      const monitoringUser = action.payload;
      return [...state, monitoringUser.userName];
    }

    //CASE ADD FAILED
    case types.MONITORING_USER_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((userNameState) => userNameState !== oldId);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MONITORING_USERS_FETCH_STARTED: {
      return true;
    }
    case types.MONITORING_USERS_FETCH_COMPLETED:
    case types.MONITORING_USERS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.MONITORING_USER_FETCH_STARTED: {
      return true;
    }
    case types.MONITORING_USER_FETCH_COMPLETED:
    case types.MONITORING_USER_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.MONITORING_USER_ADD_STARTED: {
      return true;
    }
    case types.MONITORING_USER_ADD_COMPLETED:
    case types.MONITORING_USER_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_STARTED:
    case types.USERS_ASSIGN_SCHEDULE_STARTED:
    case types.USERS_UNASSIGN_SCHEDULE_STARTED:
    case types.MONITORING_USER_EDIT_STARTED: {
      return true;
    }
    case types.MONITORING_USER_EDIT_COMPLETED:
    case types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_COMPLETED:
    case types.USERS_ASSIGN_SCHEDULE_COMPLETED:
    case types.USERS_UNASSIGN_SCHEDULE_COMPLETED:
    case types.MONITORING_USER_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.MONITORING_USER_REMOVE_STARTED: {
      return true;
    }
    case types.MONITORING_USER_REMOVE_COMPLETED:
    case types.MONITORING_USER_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.MONITORING_USERS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MONITORING_USERS_FETCH_STARTED:
    case types.MONITORING_USERS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.MONITORING_USER_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MONITORING_USER_FETCH_STARTED:
    case types.MONITORING_USER_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.MONITORING_USER_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MONITORING_USER_ADD_STARTED:
    case types.MONITORING_USER_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.MONITORING_USER_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MONITORING_USER_EDIT_STARTED:
    case types.MONITORING_USER_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.MONITORING_USER_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MONITORING_USER_REMOVE_STARTED:
    case types.MONITORING_USER_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const isUpdatingMonitoringUserPassword = (state = false, action) => {
  switch (action.type) {
    case types.MONITORING_USER_UPDATE_PASSWORD_STARTED: {
      return true;
    }

    case types.MONITORING_USER_UPDATE_PASSWORD_COMPLETED:
    case types.MONITORING_USER_UPDATE_PASSWORD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const monitoringUsers = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  isUpdatingMonitoringUserPassword,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  configurations
});

export default monitoringUsers;

//Own state
export const getOwnState = (state) => state.people.monitoringUsers;

//Information
export const getMonitoringUser = (state, userName): types.MonitoringUser =>
  getOwnState(state).byId[userName];
export const getMonitoringUsersList = (state): types.MonitoringUser[] =>
  getOwnState(state)
    .order.map((userName) => getMonitoringUser(state, userName))
    .filter((user) => user?.userStatus > 0);
export const getMonitoringUsersSpecificList = (state, list) =>
  list.map((userName) => getMonitoringUser(state, userName));
export const getSelectedMonitoringUsers = (state) => {
  const selectedMonitoringUsers = getMonitoringUsersList(state).filter(
    (monitoringUser) => monitoringUser.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedMonitoringUsers.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedMonitoringUsers.length > 0) return selectedMonitoringUsers ?? null;

  return null;
};
export const getMonitoringUsersFromBrand = (state, brandId) =>
  getMonitoringUsersList(state).filter((monitoringUser) => monitoringUser?.brandId === brandId);

export const getSelectedMonitoringUser = (state) => {
  const selectedMonitoringUsers = getMonitoringUsersList(state).filter(
    (monitoringUser) => monitoringUser.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedMonitoringUsers.length === 1) return selectedMonitoringUsers[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingMonitoringUser = (state) => getOwnState(state).isFetching;
export const isFetchingListMonitoringUsers = (state) => getOwnState(state).isFetchingList;
export const isAddingMonitoringUser = (state) => getOwnState(state).isAdding;
export const isEditingMonitoringUser = (state) => getOwnState(state).isEditing;
export const isRemovingMonitoringUser = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingMonitoringUserErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListMonitoringUsersErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingMonitoringUserErrors = (state) => getOwnState(state).errorAdding;
export const getEditingMonitoringUserErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingMonitoringUserErrors = (state) => getOwnState(state).errorRemoving;
