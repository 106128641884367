/* -------------------------------------------------------------------------- */
/*                          Componente DropdownInput                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownInput genérico que se utiliza en muchas pantallas de la aplicación.

import { Controller } from 'react-hook-form';
import DropdownInput from '../DropdownInput';
import TextInput from '../TextInput';
import NumericalInput from '../NumericalInput';
import TimeInput from '../TimeInput';
import SwitchInput from '../SwitchInput';
import Membership from '../Membership';
import ContactsDropdown from '../ContactsDropdown';
import CustomersDropdown from '../CustomersDropdown';
import DropdownCategoriesIcons from '../../screens/vehicleMaintenance/components/dropDownIcons';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import CheckboxInput from '../CheckBoxInput';
import LocationClassesDropdownInput from '../LocationClassesDropdownInput';
import ColorPicker from '../ColorPicker';
import GeofenceShapeDropdownInput from '../GeofenceShapeDropdownInput';
import UnitsDropdown from '../UnitsDropdown';
import NoticeTypeDropdownInput from 'views/screens/vehicleMaintenance/components/NoticeTypeDropdownInput';
import DurationInput from '../DurationInput';
import MultiSelectInput from '../MultiSelectInput';
import DateInput from '../DateInput';
import moment from 'moment';
import JobTypeDropdownInput from 'views/screens/Jobs/components/JobTypeDropdownInput';
import JobPriorityDropdownInput from '../../screens/Jobs/components/JobPriorityDropdownInput';
import JobStatusDropdownInput from 'views/screens/Jobs/components/JobStatusDropdownInput';
import UsersDropdown from '../UsersDropdown';
import LocationsDropdownInput from '../LocationDropDown';
import GeofencesDropdownInput from '../GeofenceDropDown';
import TextEditorInput from '../TextEditorInput';
import CategoriesDropdownInput from 'views/screens/vehicleMaintenance/components/CategoriesDropdownInput';
import FormsDropdown from '../FormsDropdown';
import DriversDropdownInput from '../DriverDropDown';
import ScheduleDropdown from '../ScheduleDropdown';
import RadioButtonsInput from '../RadioButtonInput';

interface Props {
  input: any;
  control: any;
  change?: any;
}

const validateText = (value) =>
  value && value.trim() ? undefined : appIntl().formatMessage({ id: 'common.requiredField' });

const validate = (value) =>
  value ? undefined : appIntl().formatMessage({ id: 'common.requiredField' });

const WebtrackCustomInput = (props: Props) => {
  //const [touched, setTouched] = useState(false);
  const { input, change, control } = props;

  return input.type === 'select' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <DropdownInput
          field={field}
          fieldstate={fieldState}
          required={input.required}
          isClearable={input.isClearable}
          hasPlaceholder={input.hasPlaceholder ? input.hasPlaceholder : true}
          id={input.id}
          isInModal={true}
          options={input.options ? input.options : []}
          label={input.label}
          placeholder={input.placeholder}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'multiSelect' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <MultiSelectInput
          field={field}
          fieldstate={fieldState}
          required={input.required}
          isClearable={input.isClearable}
          id={input.id}
          isInModal={true}
          options={input.options ? input.options : []}
          label={input.label}
          placeholder={input.placeholder}
        />
      )}
    />
  ) : input.type == 'date' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <DateInput
          field={field}
          fieldstate={fieldState}
          required={input.required}
          label={input.label}
          allowNulls={input.allowNulls}
          onChange={input.onchange || input.additionalonchangefunction || undefined}
          maxDate={input.maxDate}
          minDate={input.minDate}
          showTime={input.showTime}
          enableTime={input.enableTime}
        />
      )}
    />
  ) : input.type === 'text' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <TextInput
          field={field}
          fieldstate={fieldState}
          required={input.required === true}
          id={input.id}
          label={input.label}
          appendcomponent={input.appendcomponent}
          type={input.textType ?? 'text'}
          placeholder={input.placeholder}
          forceinitialvalue={input.initialValue || input.forceinitialvalue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'textEditor' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <TextEditorInput
          field={field}
          fieldstate={fieldState}
          required={input.required === true}
          id={input.id}
          label={input.label}
          type={'textEditor'}
          forceinitialvalue={input.initialValue || input.forceinitialvalue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'duration' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <DurationInput
          field={field}
          fieldstate={fieldState}
          required={input.required === true}
          id={input.id}
          label={input.label}
          useDays={input.useDays}
          useHours={input.useHours}
          useMinutes={input.useMinutes}
          useSeconds={input.useSeconds}
          forceinitialvalue={input.initialValue || input.forceinitialvalue}
          min={input.min}
          max={input.max}
          allowDecimals={input.allowDecimals}
          decimalsPrecision={input.decimalsPrecision}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'number' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <NumericalInput
          field={field}
          fieldstate={fieldState}
          required={input.required === true}
          id={input.id}
          label={input.label}
          metric={input.metric}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          allowUndefined={input.allowUndefined}
          allowZero={input.allowZero}
          appendcomponent={input.appendcomponent}
          useNumberSpinner={input.useNumberSpinner}
          allowDecimals={input.allowDecimals}
          decimalsPrecision={input.decimalsPrecision}
          step={input.step ?? 1}
          min={input.min}
          max={input.max}
          placeholder={input.placeholder}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'time' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <TimeInput
          field={field}
          fieldstate={fieldState}
          required={input.required === true}
          label={input.label}
          allowNulls={input.allowNulls}
          minTime={input.minTime}
          maxTime={input.maxTime}
        />
      )}
    />
  ) : input.type === 'colorPicker' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field }) => (
        <ColorPicker
          field={field}
          required={input.required === true}
          id={input.id}
          label={input.label}
          disabled={input.disabled}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'jobTypeDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <JobTypeDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'jobPriorityDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <JobPriorityDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'jobStatusDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <JobStatusDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'locationClassesDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <LocationClassesDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'geofenceShapesDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <GeofenceShapeDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          disabled={input.disabled}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'locationDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <LocationsDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          locationsFilters={input.locationsFilters}
        />
      )}
    />
  ) : input.type === 'driverDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <DriversDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          required={input.required}
          isClearable={input.isClearable}
          driversFilters={input.driversFilters}
        />
      )}
    />
  ) : input.type === 'geofenceDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <GeofencesDropdownInput
          field={field}
          fieldstate={fieldState}
          id={input.id}
          label={input.label}
          placeholder={input.placeholder}
          isClearable={input.isClearable}
          required={input.required}
          parentGeofences={input.parentGeofences}
          subGeofenceWktString={input.subGeofenceWktString}
          additionalonchangefunction={input.additionalonchangefunction}
          subGeofences={input.subGeofences}
        />
      )}
    />
  ) : input.type === 'organizationFormsDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <FormsDropdown
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          isClearable={input.isClearable}
          isMultiple={input.isMultiple}
          id={input.id}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          label={input.label}
        />
      )}
    />
  ) : input.type === 'usersDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <UsersDropdown
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          isClearable={input.isClearable}
          isMultiple={input.isMultiple}
          id={input.id}
          usersFilters={input.usersFilters}
          forceinitialvalue={input.forceinitialvalue || input.initialValue}
          label={input.label}
        />
      )}
    />
  ) : input.type === 'contactsDropdown' ? (
    <ContactsDropdown
      control={control}
      shouldUnregister
      name={input.name}
      id={input.id}
      initialFetch={input.initialFetch}
      defaultContacts={input.defaultContacts || input.initialValue}
      isInModal={true}
      label={input.label}
    />
  ) : input.type === 'customersDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={{ required: input.required }}
      render={({ field, fieldState }) => (
        <CustomersDropdown
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          id={input.id}
          isClearable={input.isClearable}
          customersFilters={input.customersFilters}
          label={input.label}
          required={input.required}
        />
      )}
    />
  ) : input.type === 'unitsDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <UnitsDropdown
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          isClearable={input.isClearable}
          options={input.options}
          isMultiple={input.isMultiple}
          id={input.id}
          unitsFilters={input.unitsFilters}
          forceinitialvalue={input.forceinitialvalue}
          label={input.label}
        />
      )}
    />
  ) : input.type === 'scheduleDropdown' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <ScheduleDropdown
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          isClearable={input.isClearable}
          options={input.options}
          isMultiple={input.isMultiple}
          showScheduleInformation={input.showScheduleInformation}
          id={input.id}
          forceinitialvalue={input.forceinitialvalue}
          scheduleType={input.scheduleType ?? 'trigger'}
          label={input.label}
        />
      )}
    />
  ) : input.type === 'noticeTypeDropdown' ? (
    <NoticeTypeDropdownInput id={input.id} defaultValue={input.initialValue} />
  ) : input.type === 'categoriesDropdown' ? (
    <CategoriesDropdownInput id={input.id} name={input.name} />
  ) : input.type === 'dropDownCategoriesIcons' ? (
    <DropdownCategoriesIcons name={input.name} id={input.id} defaultValue={input.initialValue} />
  ) : input.type === 'switch' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field }) => (
        <SwitchInput
          field={field}
          label={input.label}
          forceinitialvalue={input.forceinitialvalue}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'radioButtons' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <RadioButtonsInput
          field={field}
          fieldstate={fieldState}
          isInModal={true}
          label={input.label}
          radioButtons={input.radioButtons}
          additionalonchangefunction={input.additionalonchangefunction}
        />
      )}
    />
  ) : input.type === 'checkbox' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <CheckboxInput
          field={field}
          label={input.label}
          forceinitialvalue={input.forceinitialvalue}
        />
      )}
    />
  ) : input.type === 'checkbox' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <CheckboxInput
          field={field}
          label={input.label}
          forceinitialvalue={input.forceinitialvalue}
        />
      )}
    />
  ) : input.type === 'membership' ? (
    <Membership
      control={control}
      shouldUnregister
      key={input.id}
      name={input.name}
      defaultValue={input.initialValue}
      isInModal={true}
      options={input.options}
      additionalonchangefunction={input.additionalonchangefunction}
    />
  ) : input.type === 'custom' ? (
    <Controller
      control={control}
      shouldUnregister
      name={input.name}
      defaultValue={input.initialValue}
      rules={
        input.required && typeof input.customValidation === 'function'
          ? { required: input.required, validate: input.customValidation }
          : { required: input.required }
      }
      render={({ field, fieldState }) => (
        <input.component
          id={input.id}
          // isInModal={true}
          options={input.options}
          onBlur={(event, newValue, previousValue, name) => {
            change('validationHack', Date.now());
          }}
          label={input.label ? appIntl().formatMessage({ id: input.label }) : null}
          {...input}
        />
      )}
    />
  ) : input.type === 'component' ? (
    <div id={input.id}>{input.component}</div>
  ) : null;
};

WebtrackCustomInput.defaultProps = {
  input: {}
};

export default WebtrackCustomInput;
