import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as contactActions from 'views/screens/Contacts/actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as contactSchemas from 'views/screens/Contacts/schemas';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import * as operatorSelectors from '../reducers';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchOperatorsList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/operator/get`, {
        method: 'POST',
        body: JSON.stringify({}),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { operators },
          result //order
        } = normalize(resultData, schemas.operators); //normalize data to byId and order
        yield put(actions.completeFetchingOperators(operators, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingOperators(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingOperators(error));
  }
}

export function* watchFetchOperatorsList() {
  yield takeEvery(types.OPERATORS_FETCH_STARTED, fetchOperatorsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchOperatorsList(action) {
  try {
    const entityTypeId = action.payload;
    const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/operator/get`, {
        method: 'POST',
        body: JSON.stringify({ userFetchDate: date }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const resultData = jsonResult.data.map((result) => camelcaseKeys(result));
        const {
          entities: { operators },
          result //order
        } = normalize(resultData, schemas.operators); //normalize data to byId and order
        yield put(actions.completePartialFetchingOperators(operators, result));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failPartialFetchingOperators(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failPartialFetchingOperators(error));
  }
}

export function* watchPartialFetchOperatorsList() {
  yield takeEvery(types.OPERATORS_PARTIAL_FETCH_STARTED, partialFetchOperatorsList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addOperator(action) {
  const operator = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/operator/save`, {
        method: 'POST',
        body: JSON.stringify({ ...operator, operatorId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedOperator = camelcaseKeys(jsonResult.data.operatorData);

        // const operatorContacts = camelcaseKeys(jsonResult.data.contacts);
        // const {
        //   entities: { contacts },
        //   result //order
        // } = normalize(operatorContacts, contactSchemas.contacts); //normalize data to byId and order
        // yield put(contactActions.completePartialFetchingContacts(contacts, result));

        yield put(actions.completeAddingOperator(operator.operatorId, addedOperator));

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'operators.operator'
          }),
          name: operator.operatorName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingOperator(operator.operatorId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingOperator(operator.operatorId, error));
  }
}

export function* watchAddOperatorsStarted() {
  yield takeEvery(types.OPERATOR_ADD_STARTED, addOperator);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editOperator(action) {
  const operator = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/operator/save`, {
        method: 'POST',
        body: JSON.stringify(operator),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedOperator = camelcaseKeys(jsonResult.data.operatorData);

        const operatorContacts = camelcaseKeys(jsonResult.data.contacts);
        const {
          entities: { contacts },
          result //order
        } = normalize(operatorContacts, contactSchemas.contacts); //normalize data to byId and order
        yield put(contactActions.completePartialFetchingContacts(contacts, result));

        yield put(actions.completeEditingOperator(editedOperator));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'operators.operator'
          }),
          name: editedOperator.operatorName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldOperator } = yield select((state) =>
          selectors.getOperator(state.operators, operator.operatorId)
        );
        yield put(actions.failEditingOperator(oldOperator, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldOperator } = yield select((state) =>
      selectors.getOperator(state.operators, operator.operatorId)
    );
    yield put(actions.failEditingOperator(oldOperator, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditOperatorsStarted() {
  yield takeEvery(types.OPERATOR_EDIT_STARTED, editOperator);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeOperator(action) {
  const { operatorId } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    const deletedOperator = yield select((state) =>
      operatorSelectors.getOperator(state, operatorId)
    );
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/operator/delete`, {
        method: 'POST',
        body: JSON.stringify({ operatorId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingOperator(operatorId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'operators.operator'
          }),
          name: deletedOperator.operatorName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingOperator(operatorId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingOperator(operatorId, error));
  }
}

export function* watchRemoveOperatorStarted() {
  yield takeEvery(types.OPERATOR_REMOVE_STARTED, removeOperator);
}

/* -------------------------------------------------------------------------- */
/*                           EDIT STANDARD PRICES                             */
/* -------------------------------------------------------------------------- */
function* editOperatorStandardPrices(action) {
  const standardPrices = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/operator/saveStandardPrices`, {
        method: 'POST',
        body: JSON.stringify(standardPrices),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedOperatorStandardPrices = camelcaseKeys(jsonResult.data);

        // console.log('editedOperator', editedOperator);
        yield put(actions.completeEditingOperatorStandardPrices(editedOperatorStandardPrices));
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({ id: 'operators.editStandardPricesSuccess' })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        yield put(actions.failEditingOperator(standardPrices, error));
        alerts.showErrorAlertFailChanges({
          error: {
            errorMessage: appIntl().formatMessage({ id: 'operators.editStandardPricesFailure' })
          }
        });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    yield put(actions.failEditingOperator(standardPrices, error));
    alerts.showErrorAlertFailChanges({
      error: {
        errorMessage: appIntl().formatMessage({ id: 'operators.editStandardPricesFailure' })
      }
    });
  }
}

export function* watchEditOperatorStandardPricesStarted() {
  yield takeEvery(types.OPERATOR_EDIT_STANDARD_PRICES_STARTED, editOperatorStandardPrices);
}
