import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';
import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/protocol';

interface ProtocolAddRequest {
  protocolId: number;
  protocolName: string;
  protocolDescription: string;
  eventTypes: string;
  protocolPriority: number;
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
}

interface ProtocolDeleteRequest {
  protocolId: number;
  protocolName: string;
}

export const getProtocolsListService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'protocol.protocol',
    action: 'Get',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const saveProtocolService = (request: ProtocolAddRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'protocol.protocol',
    action: request.protocolId ? 'Edit' : 'Create',
    name: request.protocolName,
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteProtocolService = (
  request: ProtocolDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'protocol.protocol',
    action: 'Delete',
    name: `${request.protocolName}`,
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};
