/* -------------------------------------------------------------------------- */
/*                             Componente Checkbox                              */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Switch genérico que se utiliza en muchas pantallas de la aplicación.
import { useEffect } from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { v4 as UUIDv4 } from 'uuid';

interface Props {
  id?: string;
  field: any;
  label: string | JSX.Element;
  initialValue?: boolean;
  forceinitialvalue?: boolean;
  onChange?: (value: boolean) => void;
  onFocus?: () => void;
  style?: React.CSSProperties;
  classNameCustom?: string;
  disabled?: boolean;
}

const CheckboxInput = (props: Props) => {
  const {
    id = `checkbox-${UUIDv4()}`,
    field,
    label,
    initialValue,
    forceinitialvalue,
    onChange,
    style = {},
    classNameCustom = '',
    disabled = false,
    onFocus
  } = props;
  useEffect(() => {
    if (!field.value) field.onChange(initialValue);
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue != undefined) field.onChange(forceinitialvalue);
  }, [forceinitialvalue]);
  return (
    <FormGroup style={style}>
      <CustomInput
        className={`custom-control-Primary ${classNameCustom}`}
        id={id}
        inline
        type="checkbox"
        name={field.name}
        checked={field.value ?? false}
        disabled={disabled}
        onChange={(event) => {
          field.onChange(event.target.checked);
          if (onChange) onChange(event.target.checked);
        }}
        onFocus={onFocus}
        label={label}
      />
    </FormGroup>
  );
};

CheckboxInput.defaultProps = {
  label: '',
  initialValue: false
};

export default CheckboxInput;
