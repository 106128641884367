import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/location';

interface LocationSaveRequest {
  locationId?: number;
  dispatchCompletionType: number;
  dispatchEmails: string;
  dispatchNotificationFreq: number;
  enableDispatch: number;
  latitude: number;
  longitude: number;
  radius: number;
  locationName: string;
  locationTypeId: number;
  locationClassId: number;
  locationAddress?: string;
  privateCode?: string;
  organizationId: number;
  divisionId: number;
  subdivisionId: number;
}

interface LocationDeleteRequest {
  locationId: number;
  locationName: string;
}
interface LocationFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
}

export const getLocationsListService = (request: LocationFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'locations.location',
    action: 'Get',
    genderType: 'PluralFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const saveLocationService = (request: LocationSaveRequest, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'locations.location',
    action: request.locationId ? 'Edit' : 'Create',
    name: request.locationName,
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteLocationService = (
  request: LocationDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.locationName}`,
    object: 'locations.location',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const importLocationsService = (
  request: LocationDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importLocations`, request);
};

export const importLocationsServiceOptimization = (
  request: LocationDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importLocationsOptimizations`, request);
};
