/* -------------------------------------------------------------------------- */
/*                Componente SlidingPanePreviewDriverImages                   */
/* -------------------------------------------------------------------------- */
// Este componente permite visualizar las imagenes adjuntas de un conductor

import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row, Spinner } from 'reactstrap';
import useGetDriverImages from 'views/screens/Drivers/hooks/useGetDriverImages';
import { FilePreview } from 'views/components/FilesGallery';

interface Props {
  driver: any;
  isOpenSlidingPane: any;
  closeSlidingPanePreviewDriverImages: any;
}

const SlidingPanePreviewDriverImages = (props: Props) => {
  const { driver, isOpenSlidingPane, closeSlidingPanePreviewDriverImages } = props;
  const intl = useIntl();

  const [executeGetDriverImages, setExecuteGetDriverImages] = useState(false);

  //HOOKS PARA OBTENER LAS IMAGENES DEL CONDUCTOR
  const { driverImages, isFetchingDriverImages } = useGetDriverImages(
    driver?.driverId,
    executeGetDriverImages
  );

  useEffect(() => {
    if (driver?.driverId !== undefined && driver?.driverId !== null) {
      setExecuteGetDriverImages(!executeGetDriverImages);
    }
  }, [driver?.driverId]);

  return driver ? (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: 'drivers.attachedImages' })}
      subtitle={driver.driverName}
      width={'40rem'}
      onRequestClose={() => {
        closeSlidingPanePreviewDriverImages();
      }}
    >
      {isFetchingDriverImages ? (
        <Row>
          <Col sm="12" className="grid-spinner">
            <Spinner color="primary" size="lg" />
          </Col>
        </Row>
      ) : (
        <Row>
          {driverImages.map((image) => (
            <Col key={image.fileId} sm="6">
              <FilePreview
                file={{
                  fileExtension: image.fileExtension,
                  fileName: image.fileTitle,
                  fileUrl: image.fileUrl,
                  fileId: image.fileId
                }}
              ></FilePreview>
            </Col>
          ))}
        </Row>
      )}
    </SlidingPane>
  ) : (
    <></>
  );
};

SlidingPanePreviewDriverImages.defaultProps = {};

export default SlidingPanePreviewDriverImages;
