import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/routes';
import * as types from '../../types/routes';
import * as selectors from '../../reducers/routes';
import * as schemas from '../../schemas/routes';
import * as schemasRouteCheckpoints from '../../schemas/routeCheckpoints';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as entitySelectors from '../../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';

import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import {
  getRoutesListService,
  saveRouteService,
  deleteRouteService,
  deleteRoutesService
} from '../../services/routes';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchRoutesList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getRoutesListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { routes },
          result //order
        } = normalize(resultData, schemas.routes); //normalize data to byId and order
        store.dispatch(actions.completeFetchingRoutes(routes, result, operatorId, organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingRoutes(error));
      }
    }
  );
}

export function* watchFetchRoutesList() {
  yield takeEvery(types.ROUTES_FETCH_STARTED, fetchRoutesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchRoutesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getRoutesListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { routes },
          result //order
        } = normalize(resultData, schemas.routes); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingRoutes(routes, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingRoutes(error));
      }
    }
  );
}

export function* watchPartialFetchRoutesList() {
  yield takeEvery(types.ROUTES_PARTIAL_FETCH_STARTED, partialFetchRoutesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addRoute(action) {
  const route = action.payload;

  saveRouteService(
    { ...route, route: { ...route.route, routeId: null } },
    {
      successAction: async (response) => {
        const addedRoute = camelcaseKeys(response.data.routeData);
        const addedCheckpoints = response.data.checkpointList
          .map((result) => camelcaseKeys(result))
          .map((chk) =>
            chk.segmentPath
              ? {
                  ...chk,
                  segmentPath: chk.segmentPath.split(';').map((path) => {
                    const pathArray = path.split(',');
                    if (pathArray.length === 5)
                      return {
                        routePathId: parseFloat(pathArray[0]),
                        checkpointId: parseFloat(pathArray[1]),
                        latitude: parseFloat(pathArray[2]),
                        longitude: parseFloat(pathArray[3]),
                        distance: parseFloat(pathArray[4])
                      };
                  })
                }
              : { ...chk, segmentPath: [] }
          );
        const routeGroupId = response.data.routeGroupId;
        const {
          entities: { routeCheckpoints },
          result //order
        } = normalize(addedCheckpoints, schemasRouteCheckpoints.routeCheckpoints); //normalize data to byId and order
        store.dispatch(
          actions.completeAddingRoute(route.route.routeId, {
            route: { ...addedRoute, routeGroupId },
            checkpoints: { entities: routeCheckpoints, order: result }
          })
        );
      },
      errorAction: async (error) => {
        store.dispatch(actions.failAddingRoute(route.route.routeId, error));
      }
    },
    true
  );
}

export function* watchAddRoutesStarted() {
  yield takeEvery(types.ROUTE_ADD_STARTED, addRoute);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editRoute(action) {
  const route = action.payload;
  const { oldRoute } = yield select(selectors.getRoute, route.route.routeId);

  saveRouteService(
    route,
    {
      successAction: async (response) => {
        const editedRoute = camelcaseKeys(response.data.routeData);
        const editedCheckpoints = response.data.checkpointList
          .map((result) => camelcaseKeys(result))
          .map((chk) =>
            chk.segmentPath
              ? {
                  ...chk,
                  segmentPath: chk.segmentPath.split(';').map((path) => {
                    const pathArray = path.split(',');
                    if (pathArray.length === 5)
                      return {
                        routePathId: parseFloat(pathArray[0]),
                        checkpointId: parseFloat(pathArray[1]),
                        latitude: parseFloat(pathArray[2]),
                        longitude: parseFloat(pathArray[3]),
                        distance: parseFloat(pathArray[4])
                      };
                  })
                }
              : { ...chk, segmentPath: [] }
          );
        const routeGroupId = response.data.routeGroupId;
        const {
          entities: { routeCheckpoints },
          result //order
        } = normalize(editedCheckpoints, schemasRouteCheckpoints.routeCheckpoints); //normalize data to byId and order
        store.dispatch(
          actions.completeEditingRoute({
            route: { ...editedRoute, routeGroupId },
            checkpoints: { entities: routeCheckpoints, order: result }
          })
        );
      },
      errorAction: async (error) => {
        store.dispatch(actions.failEditingRoute(oldRoute, error));
      }
    },
    false
  );
}

export function* watchEditRoutesStarted() {
  yield takeEvery(types.ROUTE_EDIT_STARTED, editRoute);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeRoute(action) {
  const { routeId } = action.payload;
  const routeDeleted = yield select(selectors.getRoute, routeId);

  deleteRouteService(
    { routeId, routeName: routeDeleted?.routeName },
    {
      successAction: async (response) => {
        store.dispatch(actions.completeRemovingRoute(routeId));
      },
      errorAction: async (error) => {
        store.dispatch(actions.failRemovingRoute(routeId, error));
      }
    }
  );
}

export function* watchRemoveRouteStarted() {
  yield takeEvery(types.ROUTE_REMOVE_STARTED, removeRoute);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeRoutes(action) {
  const { routeIds } = action.payload;

  deleteRoutesService(
    { routeIds },
    {
      successAction: async (response) => {
        store.dispatch(
          actions.completeRemovingRoutes(routeIds.split(',').map((routeId) => parseInt(routeId)))
        );
      },
      errorAction: async (error) => {
        store.dispatch(
          actions.failRemovingRoutes(
            routeIds.split(',').map((routeId) => parseInt(routeId)),
            error
          )
        );
      }
    }
  );
}

export function* watchRemoveRoutesStarted() {
  yield takeEvery(types.ROUTES_REMOVE_STARTED, removeRoutes);
}

/* -------------------------------------------------------------------------- */
/*                                  Copy Route                                */
/* -------------------------------------------------------------------------- */
function* copyRoute(action) {
  const route = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/route/copyRoute`, {
        method: 'POST',
        body: JSON.stringify(route),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(
          actions.completeCopyRoute(
            route.routeId,
            jsonResult.data.newRouteId,
            route.newRouteName,
            route.organizationId,
            route.divisionId,
            route.subdivisionId
          )
        );

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'routes.copySuccessful'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.copyError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCopyRoute(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.copyError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCopyRoute(error));
  }
}

export function* watchCopyRouteStarted() {
  yield takeEvery(types.ROUTE_COPY_STARTED, copyRoute);
}
