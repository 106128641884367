import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../../actions/routeCheckpoints';
import * as types from '../../types/routeCheckpoints';
import * as selectors from '../../reducers/routes';
import * as schemas from '../../schemas/routeCheckpoints';
import * as schemasRouteCheckpoints from '../../schemas/routeCheckpoints';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as entitySelectors from '../../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';

import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { getRouteCheckpointsListService } from '../../services/routeCheckpoints';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchRouteCheckpointsList(action) {
  const routeId = action.payload;

  getRouteCheckpointsListService(
    { routeId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const finalResultData = resultData.map((chk) =>
          chk.segmentPath
            ? {
                ...chk,
                segmentPath: chk.segmentPath.split(';').map((path) => {
                  const pathArray = path.split(',');
                  if (pathArray.length === 5)
                    return {
                      routePathId: parseFloat(pathArray[0]),
                      checkpointId: parseFloat(pathArray[1]),
                      latitude: parseFloat(pathArray[2]),
                      longitude: parseFloat(pathArray[3]),
                      distance: parseFloat(pathArray[4])
                    };
                })
              }
            : { ...chk, segmentPath: [] }
        );
        const {
          entities: { routeCheckpoints },
          result //order
        } = normalize(finalResultData, schemas.routeCheckpoints); //normalize data to byId and order
        store.dispatch(actions.completeFetchingRouteCheckpoints(routeCheckpoints, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingRouteCheckpoints(error));
      }
    }
  );
}

export function* watchFetchRouteCheckpointsList() {
  yield takeEvery(types.ROUTE_CHECKPOINTS_FETCH_STARTED, fetchRouteCheckpointsList);
}
