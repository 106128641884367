/* -------------------------------------------------------------------------- */
/*                               Schema bookmarks                             */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const bookmark = new schema.Entity(
  "bookmarks",
  {},
  { idAttribute: "id" }
);
export const bookmarks = new schema.Array(bookmark);
