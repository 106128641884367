export const DASHBOARD = 1;
export const MAP = 2;
export const REPORTS = 3;
export const TRACKING_UNITS = 4;
export const GEOFENCES_CATALOG = 5;
export const ASSIGNED_GEOFENCES = 6;
export const LOCATIONS_CATALOG = 7;
export const IMPORT_LOCATIONS = 8;
export const BACKLOG_JOBS = 9;
export const JOBS_BOARD = 10;
export const JOBS_REPORT = 11;
export const JOBS_WORK_ACTIVITY = 12;
export const SAFE_DRIVER = 13;
export const LOGISTIC_ROUTE_CATALOG = 14;
export const LOGISTIC_ROUTE_MONITOR = 15;
export const LOGISTIC_ROUTE_REPORT = 16;
export const ASSIGN_LOGISTIC_ROUTE = 17;
export const WORK_PLAN_CATALOG = 18;
export const WORK_PLAN_MONITOR = 19;
export const WORK_PLAN_REPORT = 20;
export const ASSIGN_WORK_PLAN = 21;
export const TEMPERATURE_TELEMETRY = 22;
export const FUEL_TELEMETRY_MONITOR = 23;
export const PREVENTIVE_NOTICES = 24;
export const SPAREPARTS_CATALOG = 25;
export const WORKMANSHIPS_CATALOG = 26;
export const SPAREPARTS_CATEGORIES_CATALOG = 27;
export const SPAREPARTS_PROVIDERS_CATALOG = 28;
export const GENERAL_MAINTENANCE_REPORT = 29;
export const BY_CATEGORY_REPORT = 30;
export const BY_UNIT_REPORT = 31;
export const BY_WORKSHOP_REPORT = 32;
export const IMPORT_MAINTENANCE_TICKETS = 33;
export const NEW_TICKET = 34;
export const IMPORT_FUEL_TICKETS = 35;
export const FUEL_TICKETS_CONSOLIDATED_REPORT = 36;
export const FUEL_TICKETS_REPORT_BY_UNIT = 37;
export const OPERATOR = 38;
export const ORGANIZATION = 39;
export const DIVISION = 40;
export const SUBDIVISION = 41;
export const SERVICE_CLASSES = 42;
export const AGENT = 43;
export const GROUPS = 44;
export const SHIFT = 45;
export const TRIGGER = 46;
export const USERS = 47;
export const DRIVERS = 48;
export const PEOPLE = 49;
export const FORMS_CATALOG = 50;
export const ANSWERS_FORMS = 51;
export const DEVICES = 52;
export const SIMS = 53;
export const SENSORS = 54;
export const ASSETS = 55;
export const VEHICLES = 56;
export const EMAIL_NOTIFICATIONS = 57;
export const CONSOLE_NOTIFICATIONS = 58;
export const WEB_SERVICE_NOTIFICATIONS = 59;
export const IMPORT_JOBS = 60;
export const SCHEDULED_WORKPLANS = 61;
export const FUEL_TELEMETRY_REPORT = 62;
export const CONTRACTS = 63;
export const FLEET = 64;
export const CUSTOMERS = 65;
export const CONTACTS = 66;
export const MAINTENANCE_RANGES = 67;
export const CONTRACT_TEMPLATES = 68;
export const QUOTATIONS = 69;
export const OPTIMIZATIONS = 70;
export const IMPORT_ROUTE_ASSIGNMENTS = 72;
export const RECORD_MULTIPLE_TICKETS = 73;
export const BUSINESS_PARTNERS = 74;
export const WEB_SERVICE_RATES = 75;
export const WORKPLAN_IMPORT = 76;
export const TELEMETRY_UNITS = 77;
export const WORKSHOP_ADD_ORDER = 78;
export const AUTOMATIZATIONS = 79;
export const TELEMETRY_OBD2 = 80;
export const FUEL_TELEMETRY_REPORT_BY_VEHICLES = 81;
export const PROTOCOL_CATALOG = 82;
