import { combineReducers } from 'redux';
import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import { type } from 'os';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    case types.PROTOCOLS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((protocolId) => {
        newState[protocolId] = {
          isSelected: false,
          ...state[protocolId],
          ...entities[protocolId],
          isConfirmed: true
        };
      });
      return newState;
    }
    case types.PROTOCOLS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((protocolId) => {
        newState[protocolId] = {
          isSelected: false,
          ...state[protocolId],
          ...entities[protocolId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.PROTOCOLS_ADD_COMPLETED: {
      const { oldId, protocol } = action.payload;
      const newState = omit(state, oldId);
      newState[protocol.protocolId] = {
        ...state[oldId],
        ...protocol,
        isConfirmed: true
      };
      return newState;
    }

    case types.PROTOCOLS_EDIT_COMPLETED: {
      const protocol = action.payload;
      return {
        ...state,
        [protocol.protocolId]: {
          ...omit(state[protocol.protocolId], ['oldPROTOCOLS']),
          ...omit(protocol, ['oldPROTOCOLS']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.PROTOCOLS_ADD_STARTED: {
      const protocol = action.payload;
      const newState = { ...state };
      newState[protocol.protocolId] = {
        isSelected: false,
        isConfirmed: false
      };
      return newState;
    }
    case types.PROTOCOLS_EDIT_STARTED: {
      const protocol = action.payload;
      return {
        ...state,
        [protocol.protocolId]: {
          ...state[protocol.protocolId],
          oldProtocol: state[protocol.protocolId],
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.PROTOCOLS_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.PROTOCOLS_EDIT_FAILED: {
      const { oldProtocol } = action.payload;
      return {
        ...state,
        [oldProtocol.protocolId]: {
          ...omit(state[oldProtocol.protocolId], ['oldPROTOCOLS']),
          ...oldProtocol,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.PROTOCOLS_REMOVE_COMPLETED: {
      const { protocolId } = action.payload;
      return omit(state, protocolId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.PROTOCOLS_SELECTED: {
      const protocolId = action.payload;
      const newState = {
        ...state,
        [protocolId]: {
          ...state[protocolId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.PROTOCOLS_DESELECTED: {
      const protocolId = action.payload;
      return {
        ...state,
        [protocolId]: {
          ...state[protocolId],
          isSelected: false
        }
      };
    }

    case types.PROTOCOLS_ALL_SELECTED: {
      const protocolIds = action.payload;
      const newState = { ...state };
      if (protocolIds.length == 0) {
        forEach(state, (protocol: any, protocolId) => {
          newState[protocolId] = {
            ...protocol,
            isSelected: true
          };
        });
      } else {
        protocolIds.forEach((protocolId) => {
          newState[protocolId] = {
            ...state[protocolId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.PROTOCOLS_ALL_DESELECTED: {
      const protocolIds = action.payload;
      const newState = { ...state };
      if (protocolIds.length == 0) {
        forEach(state, (protocol: any, protocolId) => {
          newState[protocolId] = {
            ...protocol,
            isSelected: false
          };
        });
      } else {
        protocolIds.forEach((protocolId) => {
          newState[protocolId] = {
            ...state[protocolId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.PROTOCOLS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.PROTOCOLS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.PROTOCOLS_ADD_COMPLETED: {
      const { oldId, protocol } = action.payload;
      return state.map((protocolId) => (protocolId === oldId ? protocol.protocolId : protocolId));
    }

    case types.PROTOCOLS_REMOVE_COMPLETED: {
      const { protocolId } = action.payload;
      return state.filter((protocolIdState) => protocolIdState !== protocolId);
    }

    //CASE ADD STARTED
    case types.PROTOCOLS_ADD_STARTED: {
      const protocol = action.payload;
      return [...state, protocol.protocolId];
    }

    //CASE ADD FAILED
    case types.PROTOCOLS_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((protocolIdState) => protocolIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_FETCH_STARTED: {
      return true;
    }
    case types.PROTOCOLS_FETCH_COMPLETED:
    case types.PROTOCOLS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.PROTOCOLS_PARTIAL_FETCH_COMPLETED:
    case types.PROTOCOLS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_ADD_STARTED: {
      return true;
    }
    case types.PROTOCOLS_ADD_COMPLETED:
    case types.PROTOCOLS_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_EDIT_STARTED: {
      return true;
    }
    case types.PROTOCOLS_EDIT_COMPLETED:
    case types.PROTOCOLS_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_REMOVE_STARTED: {
      return true;
    }
    case types.PROTOCOLS_REMOVE_COMPLETED:
    case types.PROTOCOLS_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROTOCOLS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROTOCOLS_FETCH_STARTED:
    case types.PROTOCOLS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROTOCOLS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROTOCOLS_PARTIAL_FETCH_STARTED:
    case types.PROTOCOLS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROTOCOLS_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROTOCOLS_ADD_STARTED:
    case types.PROTOCOLS_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROTOCOLS_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROTOCOLS_EDIT_STARTED:
    case types.PROTOCOLS_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.PROTOCOLS_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PROTOCOLS_REMOVE_STARTED:
    case types.PROTOCOLS_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.PROTOCOLS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const protocols: any = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched
});

const protocolsPersistedConfig = {
  key: 'protocols',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAdding', 'isEditing', 'isRemoving', 'isFetchingList', 'isPartialFetchingList'],
  whitelist: ['byId', 'order']
  // blackList: [],
};

export default persistReducer(protocolsPersistedConfig, protocols);
// export default drivers;

export const getOwnState = (state) => state.protocols;

export const getProtocol = (state, protocolId): types.Protocol | null =>
  getOwnState(state).byId[protocolId] ?? null;

export const getProtocolsList = (state): types.Protocol[] =>
  getOwnState(state).order.map((id) => getProtocol(state, id));

export const getProtocolsListByMembership = (state, membership) => {
  return getProtocolsList(state).filter(
    (protocol) =>
      membership &&
      (!membership.operatorId || membership.operatorId === protocol.operatorId) &&
      (!membership.organizationId ||
        membership.organizationId === protocol.organizationId ||
        !protocol.organizationId) &&
      (!membership.divisionId ||
        membership.divisionId === protocol.divisionId ||
        !protocol.divisionId) &&
      (!membership.subdivisionId ||
        membership.subdivisionId === protocol.subdivisionId ||
        !protocol.subdivisionId)
  );
};

//Status of sagas
export const isPartialFetchingListProtocols = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListProtocols = (state) => getOwnState(state).isFetchingList;
export const haveProtocolsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingProtocol = (state) => getOwnState(state).isAdding;
export const isEditingProtocol = (state) => getOwnState(state).isEditing;
export const isRemovingProtocol = (state) => getOwnState(state).isRemoving;
export const isCopyingProtocol = (state) => getOwnState(state).isCopying;

//Errors
export const getPartialFetchingListProtocolsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListProtocolsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingProtocolErrors = (state) => getOwnState(state).errorAdding;
export const getEditingProtocolErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingProtocolErrors = (state) => getOwnState(state).errorRemoving;
