import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.MODULES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((moduleId) => {
        newState[moduleId] = {
          isSelected: false,
          ...entities[moduleId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.MODULES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((moduleId) => {
        newState[moduleId] = {
          isSelected: false,
          ...entities[moduleId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.MODULE_FETCH_COMPLETED: {
      const module = action.payload;
      const newState = { ...state };
      newState[module.deviceId] = {
        isSelected: false,
        ...state[module.deviceId],
        ...module,
        isConfirmed: true
      };
      return newState;
    }

    case types.MODULE_ADD_COMPLETED: {
      const { oldId, module } = action.payload;
      const newState = omit(state, oldId);
      newState[module.deviceId] = {
        ...state[oldId],
        ...module,
        isConfirmed: true
      };
      return newState;
    }

    case types.MODULE_EDIT_COMPLETED: {
      const module = action.payload;
      return {
        ...state,
        [module.deviceId]: {
          ...omit(state[module.deviceId], ['oldModule']),
          ...omit(module, ['oldModule']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.MODULE_ADD_STARTED: {
      const module = action.payload;
      const newState = { ...state };
      newState[module.deviceId] = {
        isSelected: false,
        ...module,
        isConfirmed: false
      };
      return newState;
    }
    case types.MODULE_EDIT_STARTED: {
      const module = action.payload;
      return {
        ...state,
        [module.deviceId]: {
          ...state[module.deviceId],
          oldModule: state[module.deviceId],
          ...module,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.MODULE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.MODULE_EDIT_FAILED: {
      const oldModule = action.payload;
      return {
        ...state,
        [oldModule.deviceId]: {
          ...omit(state[oldModule.deviceId], ['oldModule']),
          ...oldModule,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.MODULE_REMOVE_COMPLETED: {
      const { moduleId } = action.payload;
      return omit(state, moduleId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.MODULE_SELECTED: {
      const moduleId = action.payload;
      const newState = {
        ...state,
        [moduleId]: {
          ...state[moduleId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.MODULE_DESELECTED: {
      const moduleId = action.payload;
      return {
        ...state,
        [moduleId]: {
          ...state[moduleId],
          isSelected: false
        }
      };
    }

    case types.MODULES_ALL_SELECTED: {
      const moduleIds = action.payload;
      const newState = { ...state };
      if (moduleIds.length == 0) {
        forEach(state, (module: any, moduleId) => {
          newState[moduleId] = {
            ...module,
            isSelected: true
          };
        });
      } else {
        moduleIds.forEach((moduleId) => {
          newState[moduleId] = {
            ...state[moduleId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.MODULES_ALL_DESELECTED: {
      const moduleIds = action.payload;
      const newState = { ...state };
      if (moduleIds.length == 0) {
        forEach(state, (module: any, moduleId) => {
          newState[moduleId] = {
            ...module,
            isSelected: false
          };
        });
      } else {
        moduleIds.forEach((moduleId) => {
          newState[moduleId] = {
            ...state[moduleId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.MODULES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.MODULES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.MODULE_FETCH_COMPLETED: {
      const module = action.payload;
      return uniq([...state, module.deviceId]);
    }

    case types.MODULE_ADD_COMPLETED: {
      const { oldId, module } = action.payload;
      return state.map((deviceId) => (deviceId === oldId ? module.deviceId : deviceId));
    }

    case types.MODULE_REMOVE_COMPLETED: {
      const { moduleId } = action.payload;
      return state.filter((moduleIdState) => moduleIdState !== moduleId);
    }

    //CASE ADD STARTED
    case types.MODULE_ADD_STARTED: {
      const module = action.payload;
      return [...state, module.deviceId];
    }

    //CASE ADD FAILED
    case types.MODULE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((moduleIdState) => moduleIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MODULES_FETCH_STARTED: {
      return true;
    }
    case types.MODULES_FETCH_COMPLETED:
    case types.MODULES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MODULES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.MODULES_PARTIAL_FETCH_COMPLETED:
    case types.MODULES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.MODULE_FETCH_STARTED: {
      return true;
    }
    case types.MODULE_FETCH_COMPLETED:
    case types.MODULE_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.MODULE_ADD_STARTED: {
      return true;
    }
    case types.MODULE_ADD_COMPLETED:
    case types.MODULE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.MODULE_EDIT_STARTED: {
      return true;
    }
    case types.MODULE_EDIT_COMPLETED:
    case types.MODULE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.MODULE_REMOVE_STARTED: {
      return true;
    }
    case types.MODULE_REMOVE_COMPLETED:
    case types.MODULE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.MODULES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODULES_FETCH_STARTED:
    case types.MODULES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.MODULE_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODULE_FETCH_STARTED:
    case types.MODULE_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.MODULE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODULE_ADD_STARTED:
    case types.MODULE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.MODULE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODULE_EDIT_STARTED:
    case types.MODULE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.MODULE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODULE_REMOVE_STARTED:
    case types.MODULE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const dirty = (state = false, action) => {
  switch (action.type) {
    case types.MODULE_DIRTY: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};

const modules = combineReducers({
  byId,
  order,
  isFetching,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  dirty
});

export default modules;

//Own state
export const getOwnState = (state) => state.modules;

//Information
export const getModule = (state, moduleId) => getOwnState(state).byId[moduleId];
export const getModulesList = (state) =>
  getOwnState(state)
    .order.map((id) => getModule(state, id))
    .filter((device) => device.deviceStatus > 0);
export const getModulesListByMembership = (state, membership) =>
  getModulesList(state).filter(
    (module) =>
      membership &&
      (!membership.operatorId || membership.operatorId === module.operatorId) &&
      (!membership.organizationId || membership.organizationId === module.organizationId) &&
      (!membership.divisionId || membership.divisionId === module.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === module.subdivisionId)
  );
export const getSelectedModules = (state) => {
  const selectedModules = getModulesList(state).filter((module) => module.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedModules.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedModules.length > 0) return selectedModules;
};

export const getSelectedModule = (state) => {
  const selectedModules = getModulesList(state).filter((module) => {
    return module.isSelected;
  });
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedModules.length === 1) return selectedModules[0];
  //De lo contrario se devuelve null
  else return null;
};

export const isDirty = (state) => getOwnState(state).dirty;

//Status of sagas
export const isFetchingModule = (state) => getOwnState(state).isFetching;
export const isPartialFetchingListModules = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListModules = (state) => getOwnState(state).isFetchingList;
export const isAddingModule = (state) => getOwnState(state).isAdding;
export const isEditingModule = (state) => getOwnState(state).isEditing;
export const isRemovingModule = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingModuleErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListModulesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingModuleErrors = (state) => getOwnState(state).errorAdding;
export const getEditingModuleErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingModuleErrors = (state) => getOwnState(state).errorRemoving;
