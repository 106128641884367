export const CONTRACT_TEMPLATES_FETCH_STARTED = 'CONTRACT_TEMPLATES_FETCH_STARTED';
export const CONTRACT_TEMPLATES_FETCH_COMPLETED = 'CONTRACT_TEMPLATES_FETCH_COMPLETED';
export const CONTRACT_TEMPLATES_FETCH_FAILED = 'CONTRACT_TEMPLATES_FETCH_FAILED';

export const CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED = 'CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED';
export const CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED =
  'CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED';
export const CONTRACT_TEMPLATES_PARTIAL_FETCH_FAILED = 'CONTRACT_TEMPLATES_PARTIAL_FETCH_FAILED';

export const CONTRACT_TEMPLATE_ADD_STARTED = 'CONTRACT_TEMPLATE_ADD_STARTED';
export const CONTRACT_TEMPLATE_ADD_COMPLETED = 'CONTRACT_TEMPLATE_ADD_COMPLETED';
export const CONTRACT_TEMPLATE_ADD_FAILED = 'CONTRACT_TEMPLATE_ADD_FAILED';

export const CONTRACT_TEMPLATE_EDIT_STARTED = 'CONTRACT_TEMPLATE_EDIT_STARTED';
export const CONTRACT_TEMPLATE_EDIT_COMPLETED = 'CONTRACT_TEMPLATE_EDIT_COMPLETED';
export const CONTRACT_TEMPLATE_EDIT_FAILED = 'CONTRACT_TEMPLATE_EDIT_FAILED';

export const CONTRACT_TEMPLATE_REMOVE_STARTED = 'CONTRACT_TEMPLATE_REMOVE_STARTED';
export const CONTRACT_TEMPLATE_REMOVE_COMPLETED = 'CONTRACT_TEMPLATE_REMOVE_COMPLETED';
export const CONTRACT_TEMPLATE_REMOVE_FAILED = 'CONTRACT_TEMPLATE_REMOVE_FAILED';

export const CONTRACT_TEMPLATE_SELECTED = 'CONTRACT_TEMPLATE_SELECTED';
export const CONTRACT_TEMPLATES_ALL_SELECTED = 'CONTRACT_TEMPLATES_ALL_SELECTED';
export const CONTRACT_TEMPLATE_DESELECTED = 'CONTRACT_TEMPLATE_DESELECTED';
export const CONTRACT_TEMPLATES_ALL_DESELECTED = 'CONTRACT_TEMPLATES_ALL_DESELECTED';
