import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../types/models';
import configurations from '../configurations';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.MODELS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((modelId) => {
        newState[modelId] = {
          isSelected: false,
          ...entities[modelId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.MODELS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.MODELS_FETCH_STARTED: {
      return true;
    }
    case types.MODELS_FETCH_COMPLETED:
    case types.MODELS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.MODELS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.MODELS_FETCH_STARTED:
    case types.MODELS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const models = combineReducers({
  byId,
  order,
  isFetchingList,
  errorFetchingList,
  configurations
});

export default models;

//Own state
export const getOwnState = (state) => state.staticCatalogs.brands.models;

//Information
export const getModel = (state, modelId) => getOwnState(state).byId[modelId];
export const getModelsList = (state) => getOwnState(state).order.map((id) => getModel(state, id));

export const getModelsFromBrand = (state, brandId) => {
  return getModelsList(state).filter((model) => model.brandId === brandId);
};

//Status of sagas
export const isFetchingListModels = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingListModelsErrors = (state) => getOwnState(state).errorFetchingList;
