import { HttpWebtrack } from 'utility/http';
import { SaveSparePartCategoryDTO, DeleteSparePartCategoryDTO } from '../types';
import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/catalogs';

export const saveSparePartCategory = (request: SaveSparePartCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.sparePartName}`,
    object: 'vehicleMaintenance.sparepart',
    action: `${request.sparePartId ? 'Edit' : 'Create'}`,
    genderType: 'SingularFemale'
  });
  return httpWebtrack.post(`${baseUrl}/spareParts`, request);
};

export const deleteSparePartCategory = (request: DeleteSparePartCategoryDTO) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.sparePartName}`,
    object: 'vehicleMaintenance.sparepart',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  return httpWebtrack.get(`${baseUrl}/deleteSparePart/${request.sparePartId}`);
};

export const getSparePartList = () => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage(undefined);
  return httpWebtrack.get(`${baseUrl}/spareParts`);
};
