/* -------------------------------------------------------------------------- */
/*                         SlidingPaneStartUnitDowntime                       */
/* -------------------------------------------------------------------------- */
// Componente que permite iniciar el estado downtime a una unidad seleccionada

import SlidingPane from 'react-sliding-pane';
import { useEffect, useState, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useIntl, FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Spinner, Button } from 'reactstrap';
import * as unitActions from '../../screens/Units/actions';
import * as unitSelectors from '../../screens/Units/reducers';
import useGetDowntimeReasons from 'views/screens/Organizations/hooks/useGetDowntimeReasons';

import DropdownInput from 'views/formComponents/DropdownInput';

interface Props {
  setDowntime?: (value) => void;
  closeSlidingPane: (value) => void;
  isSlidingPaneOpen: boolean;
  unit: any;
}

const SlidingPaneStartUnitDowntime = (props: Props) => {
  const { closeSlidingPane, setDowntime, isSlidingPaneOpen, unit } = props;

  //HOOKS
  const { control, watch, handleSubmit, reset } = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const formValues = watch();

  const { downtimeReasons, isFetchingDowntimeReasons } = useGetDowntimeReasons(
    unit?.organizationId
  );

  const mainStates = [...Array(7).keys()].map((id) => ({
    id: id,
    value: id,
    label: intl.formatMessage({ id: `units.mainStateId.${id}` }),
    label_intl: intl.formatMessage({ id: `units.mainStateId.${id}` })
  }));

  const onSubmit = () => {
    dispatch(unitActions.startStartDowntimeUnit(unit?.unitId, formValues?.subMainStateId));
    if (setDowntime)
      setDowntime({
        downtimeReasonId: formValues?.subMainStateId,
        downtimeReasonDescription: downtimeReasons.filter(
          (reason) => reason.reasonId === formValues?.subMainStateId
        )[0]?.reasonDescription
      });
    reset();
    closeSlidingPane(true);
  };

  useEffect(() => {
    reset({
      ...formValues,
      mainStateId: 6
    });
  }, []);

  return unit !== undefined && unit !== null ? (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isSlidingPaneOpen}
      title={intl.formatMessage({ id: 'units.startDowntime' })}
      subtitle={unit?.unitName}
      width={'35rem'}
      onRequestClose={() => {
        if (closeSlidingPane) closeSlidingPane(false);
      }}
    >
      <Fragment>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              name="mainStateId"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <DropdownInput
                  field={field}
                  fieldstate={fieldState}
                  id={'mainStateDropdown'}
                  label={intl.formatMessage({ id: 'units.status' })}
                  options={mainStates}
                  isDisabled
                />
              )}
            />
          </Col>
          {formValues?.mainStateId === 6 && (
            <Col sm="12">
              <Controller
                control={control}
                name="subMainStateId"
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DropdownInput
                    field={field}
                    fieldstate={fieldState}
                    id={'subMainStateDropdown'}
                    label={intl.formatMessage({ id: 'units.reason' })}
                    options={downtimeReasons.map((downtimeReason) => ({
                      id: downtimeReason.reasonId,
                      value: downtimeReason.reasonId,
                      label: downtimeReason.reasonDescription,
                      label_intl: downtimeReason.reasonDescription
                    }))}
                    required={true}
                    isLoading={isFetchingDowntimeReasons}
                  />
                )}
              />
            </Col>
          )}
        </Row>
        <Row className={'footer-slide-pane'}>
          <Col sm="12">
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit(() => onSubmit())}
            >
              <FormattedMessage id="common.save" />
            </Button.Ripple>
            <Button.Ripple
              className="mt-50 mb-50"
              color="light"
              onClick={() => {
                reset();
                closeSlidingPane(false);
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          </Col>
        </Row>
      </Fragment>
    </SlidingPane>
  ) : (
    <></>
  );
};

SlidingPaneStartUnitDowntime.defaultProps = {
  closeSlidingPane: () => null,
  isSlidingPaneOpen: false,
  unit: {}
};

export default SlidingPaneStartUnitDowntime;
