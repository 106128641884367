import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';

import * as actions from '../../actions/routesReport';
import * as types from '../../types/routesReport';
import * as selectors from '../../reducers/routes';
import * as routesExecutionsSchemas from '../../schemas/routesExecutions';
import * as unitsSchemas from '../../../Units/schemas';
import * as routesSchemas from '../../schemas/routes';

import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                              FETCH LIST REPORT                             */
/* -------------------------------------------------------------------------- */
function* fetchRoutesReport(action) {
  try {
    const filters = action.payload;
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/route/getReport`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment({ ...filters })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const indicators = camelcaseKeys(jsonResult.data.indicators);

        const units = jsonResult.data.units.map((result) => camelcaseKeys(result));
        const {
          entities: { units: unitsEntities },
          result: unitsOrder //order
        } = normalize(units, unitsSchemas.units); //normalize data to byId and order

        const routes = jsonResult.data.routes.map((result) => camelcaseKeys(result));
        const {
          entities: { routes: routesEntities },
          result: routesOrder //order
        } = normalize(routes, routesSchemas.routes); //normalize data to byId and order

        const resultData = jsonResult.data.routeExecutions.map((result) => camelcaseKeys(result));
        const {
          entities: { routesExecutions },
          result //order
        } = normalize(resultData, routesExecutionsSchemas.routesExecutions); //normalize data to byId and order

        yield put(
          actions.completeFetchingRoutesReport(
            routesExecutions,
            result,
            indicators,
            unitsEntities,
            unitsOrder,
            routesEntities,
            routesOrder
          )
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'routes.routeFetchReportMessageError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingRoutesReport(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'routes.routeFetchReportMessageError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingRoutesReport(error));
  }
}

export function* watchFetchRoutesReport() {
  yield takeEvery(types.ROUTES_REPORT_FETCH_STARTED, fetchRoutesReport);
}
