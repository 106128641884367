import { call, takeEvery, put, select } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import API_BASE_URL from '../../settings/apibaseurl';
import * as actions from '../../../actions/static-catalogs/payment-methods';
import * as types from '../../../types/static-catalogs/payment-methods';
import * as schemas from '../../../schemas/payment-methods';
import * as authSelectors from '../../../reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../utils/alerts';
import { getPaymentMethods } from 'views/screens/VehicleFuel/services/paymentMethodsService';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchPaymentMethodsList(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const jsonResult = yield call(getPaymentMethods);
      const resultData = jsonResult.map((result) => camelcaseKeys(result));
      const {
        entities: { paymentMethods },
        result //order
      } = normalize(resultData, schemas.paymentMethods); //normalize data to byId and order
      yield put(actions.completeFetchingPaymentMethods(paymentMethods, result));
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingPaymentMethods(error));
  }
}

export function* watchFetchPaymentMethodsList() {
  yield takeEvery(types.PAYMENT_METHODS_FETCH_STARTED, fetchPaymentMethodsList);
}
