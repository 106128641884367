/* -------------------------------------------------------------------------- */
/*          Componente SlidingPaneUnitHistoricalPositionsReport               */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Sidebar para subir una imagen.

import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { renderToString } from 'react-dom/server';
import { MapPin } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { Marker, Polyline, Tooltip, useMapEvents } from 'react-leaflet';
import { connect, useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Col, Label, Row, Spinner, Media, Button } from 'reactstrap';
import Icon from 'utility/icomoon';
import DateInput from 'views/formComponents/DateInput';
import RotatedMarker from 'views/components/Map/components/RotatedMarker';
import SwitchInput from 'views/formComponents/SwitchInput';
import Table from 'views/components/Table';
import Toolbar from 'views/components/Toolbar';
import DefaultButton from '../../../../components/DefaultButton';
import * as unitActions from '../../actions';
import * as unitSelectors from '../../reducers';
import { UNIT_IDLE_SPEED } from '../../types';
import { getDatesDifferenceString, getIconByDatesDifference } from '../../utils';
import ReportViewerButton from 'views/components/ReportViewerButton';
import { orderBy } from 'lodash';
import * as authSelectors from 'redux/reducers/auth';
import UnitsDropdown from 'views/formComponents/UnitsDropdown';
import { numberWithCommas } from 'utility/Utils';
import { getUnitHeadingIcon }  from 'views/screens/Units/utils';
// import { HeatmapLayer } from 'react-leaflet-heatmap-layer/src';

/* -------------------------------------------------------------------------- */
/*                 SlidingPaneUnitHistoricalPositionsReport                   */
/* -------------------------------------------------------------------------- */

export interface ShowHistoricalPositions {
  show: boolean;
  showUnitHistoryMarkers: boolean;
  showUnitHistoryParkedMarkers: boolean;
  showUnitHistoryConsoleEventMarkers?: boolean;
  showUnitHistoryOnlyNormalPositionsMarkers?: boolean;
  useInterval?: number;
  reportType: number;
  startDate: string;
  endDate: string;
  idleSpeed?: number;
  selectedPositionId?: any;
  lineWidth?: any;
}

interface Props {
  closeSlidingPane: Function;
  isSlidingPaneOpen: boolean;
  showHistoricalPositions?: ShowHistoricalPositions;
  title: string;
  subtitle: string;
  unit: any;
  setMapPositionToPositionsBounds: boolean;
  changeSetMapPositionToUnitsBounds: Function;
  selectedUnit?: any;
  setSelectedUnit?: Function;
  selectedUnitRef?: any;
}
const SlidingPaneUnitHistoricalPositionsReport = (props: Props) => {
  const intl = useIntl();
  const columnInfo = [
    {
      fieldName: 'eventName',
      template: (position) =>
        position.eventId != null ? (
          <Media key={position.id}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                background: 'white',
                width: 20,
                height: 20,
                padding: 3,
                borderRadius: '100%',
                marginRight: '0.2rem',
                borderStyle: 'solid',
                borderColor: `#${position.eventColor}`
              }}
            >
              <Icon
                icon={position.eventId !== 0 ? `Alert-${position.eventId}` : 'MapPin'}
                color={`#${position.eventColor}`}
                size={20}
              />
            </div>
            <FormattedMessage id={`organizations.event${position.eventId}`} />
          </Media>
        ) : (
          <Media key={position.id} className="d-flex align-items-center justify-content-start">
            <Icon
              icon={getIconByDatesDifference(position.endDate, position.gpsDate)}
              size={32}
              style={{
                margin: '-15px -3px -15px -7px'
              }}
            />
            {position.eventId ? (
              <FormattedMessage id={`organizations.event${position.eventId}`} />
            ) : (
              position.eventName
            )}
          </Media>
        ),
      maxWidth: '150',
      headerText: 'units.event'
    },
    {
      fieldName: 'locationName',
      template: (position) => (
        <Media key={position.id} className="d-flex align-items-center justify-content-start">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '20px',
              height: '20px',
              marginRight: '0.2rem'
            }}
          >
          <Icon icon={getArrowImage(position.heading)} size={17} />
          </div>
          <span>{position.locationName}</span>
        </Media>
      ),
      maxWidth: '150',
      headerText: 'units.locationName'
    },    
    {
      fieldName: 'gpsDate',
      maxWidth: '150',
      headerText: 'units.date',
      type: 'dateTime',
      formatColumn: 'yyyy-MM-dd HH:mm'
    }
  ];
  function formatIgnitionStatus(ignitionValue) 
    {
    return ignitionValue === 1 ? 'Encendido' : 'Apagado';
    }

    const {
      closeSlidingPane,
      isSlidingPaneOpen,
      showHistoricalPositions,
      title,
      subtitle,
      setMapPositionToPositionsBounds,
      unit,
      changeSetMapPositionToUnitsBounds,
      selectedUnit,
      setSelectedUnit,
      selectedUnitRef
    } = props;
    function formatToTwoDecimals(value) {
      if (value === null || value === undefined) return '';
      return parseFloat(value).toFixed(2);
    }
  const extraColumns = [
    {
      fieldName: 'gpsDate',
      headerText: 'units.date',
      type: 'dateTime',
      formatColumn: 'yyyy-MM-dd HH:mm'
    },
    {
      fieldName: 'systemDate',
      headerText: 'units.receptionDate',
      type: 'dateTime',
      formatColumn: 'yyyy-MM-dd HH:mm'
    },
    {
      fieldName: 'eventName',
      template: (position) => {
        return position.eventId != null ? (
          <Media key={position.id}>
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                background: 'white',
                width: 20,
                height: 20,
                padding: 3,
                borderRadius: '100%',
                marginRight: '0.2rem',
                borderStyle: 'solid',
                borderColor: `#${position.eventColor}`
              }}
            >
              <Icon
                icon={position.eventId !== null ? `Alert-${position.eventId}` : 'MapPin'}
                color={`#${position.eventColor}`}
                size={20}
              />
            </div>
            <FormattedMessage id={`organizations.event${position.eventId}`} />
          </Media>
        ) : (
          <Media key={position.id} className="d-flex align-items-center justify-content-start">
            <Icon
              icon={getIconByDatesDifference(position.endDate, position.gpsDate)}
              size={32}
              style={{
                margin: '-15px -3px -15px -7px'
              }}
            />
            {position.eventName}
          </Media>
        );
      },
      headerText: 'units.event'
    },
    {
      fieldName: 'locationName',
      template: (position) => (
        <Media key={position.id} className="d-flex align-items-center justify-content-start">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '20px',
              height: '20px',
              marginRight: '0.2rem'
            }}
          >
          <Icon icon={getArrowImage(position.heading)} size={17} />
          </div>
          <span>{position.locationName}</span>
        </Media>
      ),
      maxWidth: '150',
      headerText: 'units.locationName'
    },    
    {
      fieldName: 'speed',
      headerText: 'units.speed'
    },
     {
      fieldName: 'metric',
      headerText: unit.defaultMetricName+'('+unit.defaultMetricUnit+')',
      format: (value) => formatToTwoDecimals(value),

    },
    {
      fieldName: 'isIgnitionOn',
      headerText: 'sensors.ignition',
      format: formatIgnitionStatus,
      visible: unit.hasIgnitionInput,
    },
    {
      fieldName: 'odometer',
      headerText: 'units.odometer',
      format: (value: any) => numberWithCommas(value)
    },
  ];

  const { control, watch, reset, handleSubmit } = useForm();
  const formValues = watch();

  const dispatch = useDispatch();

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const [selected, changeSelected] = useState<any[any]>([]);
  const fetchInterval = useRef<any>();
  const [size, changeSize] = useState<string>('40rem');
  const [viewTable, changeViewTable] = useState<boolean>(true);
  const [search, setSearch] = useState<Date>(new Date());
  const [rerender, changeRerender] = useState<Date>(new Date());
  const [historicalPositionsOnMapBounds, setHistoricalPositionsOnMapBounds] = useState<any>(null);

  const { isFetching, user, dropDownUnit } = useSelector((state) => ({
    isFetching: unitSelectors.isFetchingUnitPositions(state),
    dropDownUnit: formValues?.selectedUnit
      ? unitSelectors.getUnit(state, formValues?.selectedUnit)
      : undefined,
    user: authSelectors.getUser(state)
  }));

  useEffect(() => {
    if (
      formValues?.selectedUnit &&
      dropDownUnit &&
      selectedUnit?.unitId !== formValues?.selectedUnit
    ) {
      //unitSelectors.getUnit(store, unit.unitId);
      setSelectedUnit ? setSelectedUnit(dropDownUnit) : '';
      changeSetMapPositionToUnitsBounds(true);
    }
  }, [formValues?.selectedUnit]);

  const map = useMapEvents({
    moveend: () => {
      displayObjects();
    }
  });
  const cleanup = () => {
    if (fetchInterval.current) clearInterval(fetchInterval.current);
  };

  useEffect(() => {
    return () => cleanup();
  }, []);

  const zoomMapToPositions = () => {
    if (
      setMapPositionToPositionsBounds &&
      unit?.historicalPositions?.positions?.filter(
        (position, index) => position?.latitude != null && position?.longitude != null
      ).length > 0
    ) {
      map.flyToBounds(
        L.latLngBounds(
          unit?.historicalPositions?.positions
            .filter((position, index) => position?.latitude != null && position?.longitude != null)
            .map((position) => L.latLng(position.latitude, position.longitude))
        ),
        {
          paddingBottomRight: [550, 0],
          paddingTopLeft: [0, 70],
          maxZoom: 15,
          animate: 15 !== map.getZoom()
        }
      );
    }
  };

  const displayObjects = () => {
    setHistoricalPositionsOnMapBounds({
      positions: unit?.historicalPositions?.positions?.filter(
        (position, index) =>
          showHistoricalPositions?.selectedPositionId === position.id ||
          (position?.latitude != null &&
            position?.longitude != null &&
            (map
              ? map.getBounds().contains(L.latLng(position.latitude, position.longitude))
              : true) &&
            (map?.getZoom() > 14 || (map?.getZoom() > 10 && index % 10 === 0) || index % 20 === 0))
      ),
      parkedPositions: unit?.historicalPositions?.parkedPositions?.filter(
        (position, index) =>
          showHistoricalPositions?.selectedPositionId === position.id ||
          (position?.latitude != null &&
            position?.longitude != null &&
            (map
              ? map.getBounds().contains(L.latLng(position.latitude, position.longitude))
              : true) &&
            (map?.getZoom() > 14 || (map?.getZoom() > 10 && index % 10 === 0) || index % 20 === 0))
      ),
      polyline:
        unit?.historicalPositions?.polyline?.length > 0
          ? map
              .getBounds()
              .intersects(
                unit?.historicalPositions?.polyline?.map((position) => [
                  position.latitude,
                  position.longitude
                ])
              )
            ? unit?.historicalPositions.polyline
            : []
          : undefined,
      consoleEvents: unit?.consoleEvents
    });
  };
  useEffect(() => {
    changeRerender(new Date());
    changeViewTable(false);
    setTimeout(() => changeViewTable(true), 200);
  }, [size]);

  useEffect(() => {
    if (selected.length > 0)
      map.flyToBounds(
        [[selected[selected.length - 1].latitude, selected[selected.length - 1].longitude]],
        {
          paddingBottomRight: [550, 0],
          paddingTopLeft: [0, 70],
          maxZoom: 15,
          animate: 15 !== map.getZoom()
        }
      );
    else zoomMapToPositions();
  }, [selected]);

  useEffect(() => {
    if (!isFetching) {
      zoomMapToPositions();
      displayObjects();
    }
  }, [isFetching]);

  useEffect(() => {
    if (unit?.unitId) {
      reset({
        dateRange: [
          formValues?.dateRange
            ? formValues?.dateRange[0]
            : moment().startOf('day').format('YYYY-MM-DD HH:mm'),
          formValues?.dateRange
            ? formValues?.dateRange[1]
            : moment().endOf('day').format('YYYY-MM-DD HH:mm')
        ],
        showUnitHistoryMarkers: true,
        groupPositionsIntoPraked: true,
        showUnitHistoryParkedMarkers: true,
        showUnitHeatMap: false
      });
    }
  }, [unit?.unitId, isSlidingPaneOpen]);

  useEffect(() => {
    if (
      unit?.unitId &&
      isSlidingPaneOpen &&
      formValues &&
      formValues.dateRange?.length === 2 &&
      formValues?.groupPositionsIntoPraked != undefined
    ) {
      dispatch(
        unitActions.startFetchingUnitPositions(
          unit?.unitId,
          formValues.groupPositionsIntoPraked ? 2 : 1,
          formValues.dateRange[0],
          formValues.dateRange[1],
          UNIT_IDLE_SPEED
        )
      );
    }
  }, [unit?.unitId, isSlidingPaneOpen, search, formValues?.groupPositionsIntoPraked]);

  useEffect(() => {
    cleanup();

    if (
      unit?.unitId &&
      showHistoricalPositions &&
      showHistoricalPositions.show &&
      showHistoricalPositions.startDate &&
      showHistoricalPositions.startDate !== '' &&
      showHistoricalPositions.endDate &&
      showHistoricalPositions.endDate !== '' &&
      showHistoricalPositions.reportType
    ) {
      if (showHistoricalPositions?.useInterval) {
        fetchInterval.current = setInterval(() => {
          dispatch(
            unitActions.startFetchingUnitPositions(
              unit.unitId,
              showHistoricalPositions.reportType,
              showHistoricalPositions.startDate,
              showHistoricalPositions.endDate,
              showHistoricalPositions.idleSpeed || UNIT_IDLE_SPEED
            )
          );
        }, showHistoricalPositions.useInterval);
      }

      dispatch(
        unitActions.startFetchingUnitPositions(
          unit.unitId,
          showHistoricalPositions.reportType,
          showHistoricalPositions.startDate,
          showHistoricalPositions.endDate,
          showHistoricalPositions.idleSpeed || UNIT_IDLE_SPEED
        )
      );
    }
  }, [
    unit?.unitId,
    showHistoricalPositions?.show,
    showHistoricalPositions?.useInterval,
    showHistoricalPositions?.reportType,
    showHistoricalPositions?.startDate,
    showHistoricalPositions?.endDate,
    showHistoricalPositions?.idleSpeed
  ]);

  useEffect(() => {
    if (showHistoricalPositions?.selectedPositionId) displayObjects();
  }, [showHistoricalPositions?.selectedPositionId]);

  function getArrowImage(heading) {
    if (heading >= 337.5 || heading < 22.5) {
        return 'ArrowUp';  //Norte
    } else if (heading >= 22.5 && heading < 67.5) {
        return 'ArrowUpRight'; //Noreste
    } else if (heading >= 67.5 && heading < 112.5) {
        return 'ArrowRight'; //Este
    } else if (heading >= 112.5 && heading < 157.5) {
        return 'ArrowDownRight'; //Sureste
    } else if (heading >= 157.5 && heading < 202.5) {
        return 'ArrowDown'; //Sur
    } else if (heading >= 202.5 && heading < 247.5) {
        return'ArrowDownLeft'; //Suroeste
    } else if (heading >= 247.5 && heading < 292.5) {
        return 'ArrowLeft'; //Oeste
    } else { 
        return'ArrowUpLeft'; //Noroeste
    }
    
  }

  //Reporte exportable ----------------------------------------------------------
  const reportDataSource = {
    title: intl.formatMessage({ id: 'units.historicalPositions.report.tittle' }),
    subtitle: `Id: ${unit.unitId} - ${unit.unitName}`, // Id: Numero_ID - Nombre_Unidad 
    filters:
      (formValues.dateRange ? formValues.dateRange[0] : '') +
      ' - ' +
      (formValues.dateRange ? formValues.dateRange[1] : ''),
    dataSets: [
      {
        value: orderBy(
          unit?.historicalPositions?.allPositions.map((position) => ({
            ...position,
            eventName: position.eventName || intl.formatMessage({ id: 'units.parked' }),
            headingArrows: process.env.NODE_ENV === 'production' ? process.env.PUBLIC_URL : window.location.origin + "/assets/"+getArrowImage(position.heading)+".png",          
            speed: position.speed === null ? '' : position.speed,
            metric: position.metric === null ? '' : parseFloat(position.metric).toFixed(2),
            ignition: position.isIgnitionOn === 1 ? 'Encendido' : 'Apagado'
          })),
          'gpsDate',
          'asc'
        ),
        name: 'HistoricalPositionUnits'
      },
      {
        value: [
          {
            defaultMetricUnit: unit.defaultMetricUnit,
            defaultMetricName: unit.defaultMetricName
          }
        ],
        name: 'Metrics'
      },
      {
        value: [
          {
            distanceSystem: user.distanceSystem
          }
        ],
        name: 'User'
      },
      {
        value: [
          {
            distanceAllPositions: unit?.historicalPositions?.distance
          }
        ],
        name: 'DistancePositionsUnit'
      }
    ]
  };

  return (
    <>
      {historicalPositionsOnMapBounds?.positions?.length > 0 &&
        (isSlidingPaneOpen || showHistoricalPositions?.show) &&
        (showHistoricalPositions?.show
          ? showHistoricalPositions?.showUnitHistoryMarkers
          : formValues?.showUnitHistoryMarkers) &&
        historicalPositionsOnMapBounds.positions.map((position, index) =>
          position.eventId === 0 ? (
            <RotatedMarker
              key={`${index}-${
                (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                showHistoricalPositions?.selectedPositionId === position.id
              }`}
              // pmIgnore={true}
              zIndexOffset={750}
              riseOnHover={true}
              rotationAngle={position.heading}
              rotationOrigin="center"
              icon={L.divIcon({
                className: 'custom-div-icon',
                html: `<svg viewBox="0 0 58 58">
                <polygon style="fill:${primary}; stroke:black; stroke-width: 2" 
                points="28.2,34.4 14.5,44.1 28.2,7.2 40.6,44.1 "/></svg>`,
                iconSize: [22, 22],
                iconAnchor: [11, 11]
              })}
              position={[position.latitude, position.longitude]}
              eventHandlers={{
                click: () => {}
              }}
            >
              <Tooltip
                permanent={
                  (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                  showHistoricalPositions?.selectedPositionId === position.id
                }
              >
                <div>
                  <b style={{ fontSize: '11px' }}>
                    {moment(position.gpsDate).format('YYYY-MM-DD HH:mm')}
                  </b>
                  <br />
                  <b style={{ fontSize: '11px' }}>{position.eventName}</b>
                  <br />

                  {position?.locationName?.split(',').map((e) => {
                    return (
                      <p style={{ fontSize: '11px', margin: '0px', fontWeight: 'bold' }}>{e}</p>
                    );
                  })}
                </div>
              </Tooltip>
            </RotatedMarker>
          ) : (
            (showHistoricalPositions?.showUnitHistoryOnlyNormalPositionsMarkers ?? false) ==
              false && (
              <Marker
                key={`${index}-${
                  (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                  showHistoricalPositions?.selectedPositionId === position.id
                }`}
                // pmIgnore={true}
                zIndexOffset={700}
                riseOnHover={true}
                icon={L.divIcon({
                  className: 'custom-div-icon',
                  html: renderToString(
                    <div
                      className="d-flex align-items-center justify-content-center"
                      style={{
                        background: 'white',
                        width: 30,
                        height: 30,
                        padding: 3,
                        borderRadius: '100%',
                        borderStyle: 'solid',
                        borderColor: `#${position.eventColor}`
                      }}
                    >
                      <Icon
                        icon={`Alert-${position.eventId}`}
                        color={`#${position.eventColor}`}
                        size={30}
                      />
                    </div>
                  ),
                  iconSize: [30, 30],
                  iconAnchor: [15, 15]
                })}
                position={[position.latitude, position.longitude]}
                eventHandlers={{
                  click: () => {}
                }}
              >
                <Tooltip
                  permanent={
                    (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                    showHistoricalPositions?.selectedPositionId === position.id
                  }
                >
                  <b style={{ fontSize: '11px' }}>
                    {moment(position.gpsDate).format('YYYY-MM-DD HH:mm')}
                  </b>
                  <br />
                  <b style={{ fontSize: '11px' }}>{position.eventName}</b>
                  <br />
                  <b style={{ fontSize: '11px' }}>
                    {position?.locationName?.split(',').map((e) => {
                      return (
                        <p style={{ fontSize: '11px', margin: '0px', fontWeight: 'bold' }}>{e}</p>
                      );
                    })}
                  </b>
                </Tooltip>
              </Marker>
            )
          )
        )}
      {historicalPositionsOnMapBounds?.parkedPositions?.length > 0 &&
        (isSlidingPaneOpen || showHistoricalPositions?.show) &&
        (showHistoricalPositions?.show
          ? showHistoricalPositions?.showUnitHistoryParkedMarkers
          : formValues?.groupPositionsIntoPraked && formValues?.showUnitHistoryParkedMarkers) &&
        historicalPositionsOnMapBounds.parkedPositions.map((position, index) => (
          <Marker
            key={`${index}-${
              (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
              showHistoricalPositions?.selectedPositionId === position.id
            }`}
            // pmIgnore={true}
            zIndexOffset={700}
            riseOnHover={true}
            icon={L.divIcon({
              className: 'custom-div-icon',
              html: renderToString(
                <Icon
                  icon={getIconByDatesDifference(position.endDate, position.gpsDate)}
                  size={48}
                />
              ),
              iconSize: [30, 30],
              iconAnchor: [15, 15]
            })}
            position={[position.latitude, position.longitude]}
            eventHandlers={{
              click: () => {}
            }}
          >
            <Tooltip
              permanent={
                (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                showHistoricalPositions?.selectedPositionId === position.id
              }
            >
              <b style={{ fontSize: '11px' }}>
                {moment(position.gpsDate).format('YYYY-MM-DD HH:mm')}
              </b>
              <br />
              <b style={{ fontSize: '11px' }}>
                {`${intl.formatMessage({ id: 'units.duration' })}: ${getDatesDifferenceString(
                  position.endDate,
                  position.gpsDate,
                  intl
                )}`}
              </b>
              <br />
              <b style={{ fontSize: '11px' }}>{position.locationName}</b>
            </Tooltip>
          </Marker>
        ))}
      {/* {unit?.historicalPositions?.polyline?.length > 0 && isSlidingPaneOpen && (
        <HeatmapLayer
          points={unit.historicalPositions.polyline.map((point) => ({
            ...point,
            intensity: 1
          }))}
          latitudeExtractor={(m: any) => m.latitude}
          longitudeExtractor={(m: any) => m.longitude}
          intensityExtractor={(m: any) => parseFloat(m.intensity)}
          max={1}
          minOpacity={0.2}
        />
      )} */}
      {historicalPositionsOnMapBounds?.polyline?.length > 0 &&
        (isSlidingPaneOpen || showHistoricalPositions?.show) && (
          <Polyline
            pathOptions={{ color: primary, weight: showHistoricalPositions?.lineWidth ?? 3 }}
            positions={historicalPositionsOnMapBounds.polyline.map((point) => [
              point.latitude,
              point.longitude
            ])}
            eventHandlers={{
              click: () => {}
            }}
          ></Polyline>
        )}
      {historicalPositionsOnMapBounds?.consoleEvents?.length > 0 &&
        showHistoricalPositions?.showUnitHistoryConsoleEventMarkers === true &&
        (isSlidingPaneOpen || showHistoricalPositions?.show) &&
        historicalPositionsOnMapBounds.consoleEvents.map((position, index) => (
          <Marker
            key={`${index}-${
              (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
              showHistoricalPositions?.selectedPositionId === position.id
            }`}
            // pmIgnore={true}
            zIndexOffset={700}
            riseOnHover={true}
            icon={L.divIcon({
              className: 'custom-div-icon',
              html: renderToString(
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    background: 'white',
                    width: 30,
                    height: 30,
                    padding: 3,
                    borderRadius: '100%',
                    borderStyle: 'solid',
                    borderColor: `#${position.eventColor}`
                  }}
                >
                  <Icon
                    icon={`Alert-${position.eventId}`}
                    color={`#${position.eventColor}`}
                    size={30}
                  />
                </div>
              ),
              iconSize: [30, 30],
              iconAnchor: [15, 15]
            })}
            position={[position.latitude, position.longitude]}
            eventHandlers={{
              click: () => {}
            }}
          >
            <Tooltip
              permanent={
                (selected.length > 0 && selected[selected.length - 1].id === position.id) ||
                showHistoricalPositions?.selectedPositionId === position.id
              }
            >
              <b style={{ fontSize: '11px' }}>
                {moment(position.gpsDate).format('YYYY-MM-DD HH:mm')}
              </b>
              <br />
              <b style={{ fontSize: '11px' }}>
                {intl.formatMessage({
                  id: `organizations.event${position.eventId}`
                })}
              </b>
              <br />
              <b style={{ fontSize: '11px' }}>{position.locationName}</b>
            </Tooltip>
          </Marker>
        ))}
      <SlidingPane
        className="custom-sliding-pane"
        overlayClassName={'custom-overlay-sliding-pane-map'}
        isOpen={isSlidingPaneOpen}
        title={title}
        subtitle={`Id: ${unit.unitId} - ${unit.unitName}`}
        width={size}
        onRequestClose={() => {
          if (closeSlidingPane) closeSlidingPane();
        }}
      >
        <>
          <Row>
            <Col sm="12">
              {unit && unit?.unitId && unit.organizationId && unit?.operatorId ? (
                <Controller
                  control={control}
                  name="selectedUnit"
                  defaultValue={unit?.unitId}
                  render={({ field, fieldState }) => (
                    <UnitsDropdown
                      field={field}
                      fieldstate={fieldState}
                      id={'unitDropdown'}
                      required
                      placeholder={intl.formatMessage({ id: 'units.searchUnit' })}
                      membership={{
                        operatorId: unit?.operatorId,
                        organizationId: unit?.organizationId
                      }}
                      unitsFilters={{
                        operatorId: unit?.operatorId,
                        organizationId: unit?.organizationId
                      }}
                      forceinitialvalue={unit?.unitId}
                    />
                  )}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col sm={9}>
              <Controller
                control={control}
                name="dateRange"
                defaultValue={[
                  moment().startOf('day').format('YYYY-MM-DD HH:mm'),
                  moment().endOf('day').format('YYYY-MM-DD HH:mm')
                ]}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DateInput
                    field={field}
                    fieldstate={fieldState}
                    maxDate={moment().endOf('day').format('YYYY-MM-DD HH:mm')}
                    required
                    isRange
                    enableTime
                  />
                )}
              />
            </Col>
            <Col sm={3} className="mt-25">
              <Button.Ripple
                className="mt-1"
                color="primary"
                /*type="submit"*/
                onClick={handleSubmit(() => {
                  setSearch(new Date());
                })}
              >
                <FormattedMessage id="common.search" />
              </Button.Ripple>
            </Col>
            <Col sm={4} className={'d-flex justify-content-center'}>
              <Controller
                control={control}
                name={'showUnitHistoryMarkers'}
                defaultValue={true}
                render={({ field }) => (
                  <SwitchInput
                    field={field}
                    tooltipMessage={intl.formatMessage({ id: 'units.showUnitHistoryMarkers' })}
                    label={<MapPin color={primary} size={15} />}
                  />
                )}
              />
            </Col>
            <Col sm={4} className={'d-flex justify-content-center'}>
              <Controller
                control={control}
                name={'groupPositionsIntoPraked'}
                defaultValue={true}
                render={({ field }) => (
                  <SwitchInput
                    field={field}
                    tooltipMessage={intl.formatMessage({ id: 'units.groupPositionsIntoPraked' })}
                    label={<Icon icon={'Parking'} color={primary} size={15} />}
                  />
                )}
              />
            </Col>
            {formValues?.groupPositionsIntoPraked && (
              <Col sm={4} className={'d-flex justify-content-center'}>
                <Controller
                  control={control}
                  name={'showUnitHistoryParkedMarkers'}
                  defaultValue={true}
                  render={({ field }) => (
                    <SwitchInput
                      field={field}
                      tooltipMessage={intl.formatMessage({
                        id: 'units.showUnitHistoryParkedMarkers'
                      })}
                      label={<Icon icon={'LetterP'} color={primary} size={15} />}
                    />
                  )}
                />
              </Col>
            )}
            {/* <Col sm={3}>
              <Controller
                control={control}
                name={'showUnitHeatMap'}
                defaultValue={false}
                render={({ field }) => (
                  <SwitchInput
                    field={field}
                    tooltipMessage={intl.formatMessage({ id: 'units.showUnitHeatMap' })}
                    label={
                      <>
                        <Icon icon={'LetterH'} color={primary} size={15} />
                        <Icon
                          icon={'LetterM'}
                          color={primary}
                          size={15}
                          style={{ marginLeft: '-0.4rem' }}
                        />
                      </>
                    }
                  />
                )}
              />
            </Col> */}
            {isFetching && (
              <Col className={'my-5'} sm={12}>
                <div className={'d-flex justify-content-center my-5'}>
                  <Spinner color="primary" />
                </div>
              </Col>
            )}
            {!isFetching && (
              <Col sm={12}>
                <hr className={'full-width-slide-panel'} />
                <Label className="label-information">
                  {`${intl.formatMessage({ id: 'units.distance' })}:`}
                </Label>
                <Label className="label-value-information">
                  {unit?.historicalPositions?.distanceFormat}
                </Label>
                <hr className={'full-width-slide-panel'} />
              </Col>
            )}
            {!isFetching && unit?.historicalPositions?.positions?.length > 0 && (
              <Col sm={12}>
                <Toolbar
                  buttons={[
                    <DefaultButton
                      id={'maximize'}
                      icon={size === '80rem' ? 'Minimize' : 'Maximize'}
                      handleClick={() => {
                        changeSize(size === '40rem' ? '80rem' : '40rem');
                      }}
                      tooltipMessage={intl.formatMessage({
                        id: size === '80rem' ? 'workplans.minimize' : 'workplans.maximize'
                      })}
                    ></DefaultButton>,
                    <ReportViewerButton
                      tooltipMessage={'reports.seeReport'}
                      channelName={'report_Historical_Position_Units'}
                      handleClick={() => {}}
                      reportDataSource={reportDataSource}
                      useEmbbededChannel={true}
                      reportPath={'HistoricalPositionUnits.rdl'}
                    />
                  ]}
                />
                {viewTable && (
                  <Table
                    id={'tableUnitsSlidingPaneUnitHistoricalPositionsReport1'}
                    enablePersistence={false}
                    rerender={rerender}
                    sortedColumn={{
                      sortedColumnIndex: size === '80rem' ? 0 : 2,
                      direction: 'Ascending'
                    }}
                    columns={
                      size === '80rem'
                      ? extraColumns
                          .filter(column => column.visible !== false && column.headerText != "null(null)")
                          .map(column => column)
                        : columnInfo
                    }
                    onDeselect={() => changeSelected([])}
                    data={unit?.historicalPositions?.allPositions.map((position) => ({
                      ...position,
                      eventName: position.eventName || intl.formatMessage({ id: 'units.parked' }),
                      speed: position.speed === null ? '' : position.speed,
                      metric:  isNaN(position.metric) && position.metric === null? '' : position.metric,
                    }))}
                    selected={selected}
                    changeSelected={changeSelected}
                  />
                )}
              </Col>
            )}
          </Row>
        </>
      </SlidingPane>
    </>
  );
};
SlidingPaneUnitHistoricalPositionsReport.defaultProps = {
  closeSlidingPane: () => null,
  isSlidingPaneOpen: false,
  showHistoricalPositions: { show: false },
  title: '',
  unit: {}
};

export default SlidingPaneUnitHistoricalPositionsReport;
