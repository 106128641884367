import { ReduxUnit } from 'views/screens/Units/types';

export const GEOFENCES_FETCH_STARTED = 'GEOFENCES_FETCH_STARTED';
export const GEOFENCES_FETCH_COMPLETED = 'GEOFENCES_FETCH_COMPLETED';
export const GEOFENCES_FETCH_FAILED = 'GEOFENCES_FETCH_FAILED';

export const GEOFENCES_PARTIAL_FETCH_STARTED = 'GEOFENCES_PARTIAL_FETCH_STARTED';
export const GEOFENCES_PARTIAL_FETCH_COMPLETED = 'GEOFENCES_PARTIAL_FETCH_COMPLETED';
export const GEOFENCES_PARTIAL_FETCH_FAILED = 'GEOFENCES_PARTIAL_FETCH_FAILED';

export const GEOFENCE_ADD_STARTED = 'GEOFENCE_ADD_STARTED';
export const GEOFENCE_ADD_COMPLETED = 'GEOFENCE_ADD_COMPLETED';
export const GEOFENCE_ADD_FAILED = 'GEOFENCE_ADD_FAILED';

export const GEOFENCE_EDIT_STARTED = 'GEOFENCE_EDIT_STARTED';
export const GEOFENCE_EDIT_COMPLETED = 'GEOFENCE_EDIT_COMPLETED';
export const GEOFENCE_EDIT_FAILED = 'GEOFENCE_EDIT_FAILED';

export const GEOFENCE_REMOVE_STARTED = 'GEOFENCE_REMOVE_STARTED';
export const GEOFENCE_REMOVE_COMPLETED = 'GEOFENCE_REMOVE_COMPLETED';
export const GEOFENCE_REMOVE_FAILED = 'GEOFENCE_REMOVE_FAILED';

export const GEOFENCE_SELECTED = 'GEOFENCE_SELECTED';
export const GEOFENCES_ALL_SELECTED = 'GEOFENCES_ALL_SELECTED';
export const GEOFENCE_DESELECTED = 'GEOFENCE_DESELECTED';
export const GEOFENCES_ALL_DESELECTED = 'GEOFENCES_ALL_DESELECTED';

export const GEOFENCE_SHOW_IN_MAP = 'GEOFENCE_SHOW_IN_MAP';
export const GEOFENCES_ALL_SHOW_IN_MAP = 'GEOFENCES_ALL_SHOW_IN_MAP';
export const GEOFENCE_HIDE_IN_MAP = 'GEOFENCE_HIDE_IN_MAP';
export const GEOFENCES_ALL_HIDE_IN_MAP = 'GEOFENCES_ALL_HIDE_IN_MAP';

export const GEOFENCE_ASSIGN_UNIT_STARTED = 'GEOFENCE_ASSIGN_UNIT_STARTED';
export const GEOFENCE_ASSIGN_UNIT_COMPLETED = 'GEOFENCE_ASSIGN_UNIT_COMPLETED';
export const GEOFENCE_ASSIGN_UNIT_FAILED = 'GEOFENCE_ASSIGN_UNIT_FAILED';

export const GEOFENCE_ASSIGNATION_REMOVE_STARTED = 'GEOFENCE_ASSIGNATION_REMOVE_STARTED';
export const GEOFENCE_ASSIGNATION_REMOVE_COMPLETED = 'GEOFENCE_ASSIGNATION_REMOVE_COMPLETED';
export const GEOFENCE_ASSIGNATION_REMOVE_FAILED = 'GEOFENCE_ASSIGNATION_REMOVE_FAILED';

export const GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_STARTED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_STARTED';
export const GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_COMPLETED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_COMPLETED';
export const GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_FAILED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_FAILED';

export const GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_STARTED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_STARTED';
export const GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_COMPLETED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_UNIT_COMPLETED';
export const GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_FAILED =
  'GEOFENCE_ASSIGNATION_REMOVE_BY_UNIT_FAILED';

export const GEOFENCE_ASSIGNATION_EDIT_STARTED = 'GEOFENCE_ASSIGNATION_EDIT_STARTED';
export const GEOFENCE_ASSIGNATION_EDIT_COMPLETED = 'GEOFENCE_ASSIGNATION_EDIT_COMPLETED';
export const GEOFENCE_ASSIGNATION_EDIT_FAILED = 'GEOFENCE_ASSIGNATION_EDIT_FAILED';

export interface ReduxGeofence {
  isSelected: boolean;
  isShowInMap: boolean;
  geofenceId: number;
  geofenceStatus: number;
  geofenceAddress: string;
  shape: string;
  privateCode: string;
  geofenceName: string;
  operatorId: number;
  organizationId: number;
  organizationName: string;
  divisionId?: number;
  subdivisionId?: number;
  vertexCount: number;
  color: string;
  radius: number;
  latitude: number;
  latitude2?: number;
  longitude: number;
  longitude2?: number;
  isParentGeofence?: boolean;
  isActivationGeofence: boolean;
  parentGeofenceId?: number;
  geofenceWktString: string;
  isConfirmed: boolean;
  isShownInMap: boolean;
  divisionName: string;
  subdivisionName: string;
}

export interface Assignment extends ReduxGeofence, ReduxUnit {
  operatorName: string;
  notify: number;
  consoles: string;
  notificationEmails: string;
}
