export const QUOTATIONS_FETCH_STARTED = 'QUOTATIONS_FETCH_STARTED';
export const QUOTATIONS_FETCH_COMPLETED = 'QUOTATIONS_FETCH_COMPLETED';
export const QUOTATIONS_FETCH_FAILED = 'QUOTATIONS_FETCH_FAILED';

export const QUOTATIONS_PARTIAL_FETCH_STARTED = 'QUOTATIONS_PARTIAL_FETCH_STARTED';
export const QUOTATIONS_PARTIAL_FETCH_COMPLETED = 'QUOTATIONS_PARTIAL_FETCH_COMPLETED';
export const QUOTATIONS_PARTIAL_FETCH_FAILED = 'QUOTATIONS_PARTIAL_FETCH_FAILED';

export const QUOTATIONS_ADD_STARTED = 'QUOTATIONS_ADD_STARTED';
export const QUOTATIONS_ADD_COMPLETED = 'QUOTATIONS_ADD_COMPLETED';
export const QUOTATIONS_ADD_FAILED = 'QUOTATIONS_ADD_FAILED';

export const QUOTATIONS_EDIT_STARTED = 'QUOTATIONS_EDIT_STARTED';
export const QUOTATIONS_EDIT_COMPLETED = 'QUOTATIONS_EDIT_COMPLETED';
export const QUOTATIONS_EDIT_FAILED = 'QUOTATIONS_EDIT_FAILED';

export const QUOTATIONS_REMOVE_STARTED = 'QUOTATIONS_REMOVE_STARTED';
export const QUOTATIONS_REMOVE_COMPLETED = 'QUOTATIONS_REMOVE_COMPLETED';
export const QUOTATIONS_REMOVE_FAILED = 'QUOTATIONS_REMOVE_FAILED';

export const QUOTATIONS_SCHEDULE_STARTED = 'QUOTATIONS_SCHEDULE_STARTED';
export const QUOTATIONS_SCHEDULE_COMPLETED = 'QUOTATIONS_SCHEDULE_COMPLETED';
export const QUOTATIONS_SCHEDULE_FAILED = 'QUOTATIONS_SCHEDULE_FAILED';

export const QUOTATIONS_CHECK_SCHEDULING_STARTED = 'QUOTATIONS_CHECK_SCHEDULING_STARTED';
export const QUOTATIONS_CHECK_SCHEDULING_COMPLETED = 'QUOTATIONS_CHECK_SCHEDULING_COMPLETED';
export const QUOTATIONS_CHECK_SCHEDULING_FAILED = 'QUOTATIONS_CHECK_SCHEDULING_FAILED';

export const QUOTATIONS_SET_TIMESUP_STARTED = 'QUOTATIONS_SET_TIMESUP_STARTED';
export const QUOTATIONS_SET_TIMESUP_COMPLETED = 'QUOTATIONS_SET_TIMESUP_COMPLETED';
export const QUOTATIONS_SET_TIMESUP_FAILED = 'QUOTATIONS_SET_TIMESUP_FAILED';

export const QUOTATIONS_SELECTED = 'QUOTATIONS_SELECTED';
export const QUOTATIONS_ALL_SELECTED = 'QUOTATIONS_ALL_SELECTED';
export const QUOTATIONS_DESELECTED = 'QUOTATIONS_DESELECTED';
export const QUOTATIONS_ALL_DESELECTED = 'QUOTATIONS_ALL_DESELECTED';

export const QUOTATION_APPROVE_DENY_MANAGEMENT_STARTED =
  'QUOTATION_APPROVE_DENY_MANAGEMENT_STARTED';
export const QUOTATION_APPROVE_DENY_MANAGEMENT_FAILED = 'QUOTATION_APPROVE_DENY_MANAGEMENT_FAILED';
export const QUOTATION_APPROVE_DENY_MANAGEMENT_COMPLETED =
  'QUOTATION_APPROVE_DENY_MANAGEMENT_COMPLETED';

export const QUOTATION_ACCEPT_DENY_CUSTOMER_STARTED = 'QUOTATION_ACCEPT_DENY_CUSTOMER_STARTED';
export const QUOTATION_ACCEPT_DENY_CUSTOMER_FAILED = 'QUOTATION_ACCEPT_DENY_CUSTOMER_FAILED';
export const QUOTATION_ACCEPT_DENY_CUSTOMER_COMPLETED = 'QUOTATION_ACCEPT_DENY_CUSTOMER_COMPLETED';

export const QUOTATION_COPY_STARTED = 'QUOTATION_COPY_STARTED';
export const QUOTATION_COPY_FAILED = 'QUOTATION_COPY_FAILED';
export const QUOTATION_COPY_COMPLETED = 'QUOTATION_COPY_COMPLETED';

export const QUOTATION_CHANGE_ORGANIZATION = 'QUOTATION_CHANGE_ORGANIZATION';
