import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.GEOFENCES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((geofenceId) => {
        newState[geofenceId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[geofenceId],
          ...entities[geofenceId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.GEOFENCES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((geofenceId) => {
        newState[geofenceId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[geofenceId],
          ...entities[geofenceId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.GEOFENCE_ADD_COMPLETED: {
      const { oldId, geofence } = action.payload;
      const newState = omit(state, oldId);
      newState[geofence.geofenceId] = {
        isShowInMap: false,
        ...state[oldId],
        ...geofence,
        isConfirmed: true
      };
      return newState;
    }

    case types.GEOFENCE_EDIT_COMPLETED: {
      const geofence = action.payload;
      return {
        ...state,
        [geofence.geofenceId]: {
          isShowInMap: false,
          ...omit(state[geofence.geofenceId], ['oldGeofence']),
          ...omit(geofence, ['oldGeofence']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.GEOFENCE_ADD_STARTED: {
      const geofence = action.payload;
      const newState = { ...state };
      newState[geofence.geofenceId] = {
        isShowInMap: false,
        isSelected: false,
        ...geofence,
        isConfirmed: false
      };
      return newState;
    }
    case types.GEOFENCE_EDIT_STARTED: {
      const geofence = action.payload;
      return {
        ...state,
        [geofence.geofenceId]: {
          isShowInMap: false,
          ...state[geofence.geofenceId],
          oldGeofence: state[geofence.geofenceId],
          ...geofence,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.GEOFENCE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.GEOFENCE_EDIT_FAILED: {
      const { oldGeofence } = action.payload;
      return {
        ...state,
        [oldGeofence.geofenceId]: {
          isShowInMap: false,
          ...omit(state[oldGeofence.geofenceId], ['oldGeofence']),
          ...oldGeofence,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.GEOFENCE_REMOVE_COMPLETED: {
      const { geofenceId } = action.payload;
      return omit(state, geofenceId);
    }

    //TYPES SHOW AND HIDE GEOFENCES IN MAP
    case types.GEOFENCE_SHOW_IN_MAP: {
      const geofenceId = action.payload;
      const newState = {
        ...state,
        [geofenceId]: {
          ...state[geofenceId],
          isShownInMap: true
        }
      };
      return newState;
    }

    case types.GEOFENCE_HIDE_IN_MAP: {
      const geofenceId = action.payload;
      return {
        ...state,
        [geofenceId]: {
          ...state[geofenceId],
          isShownInMap: false
        }
      };
    }

    case types.GEOFENCES_ALL_SHOW_IN_MAP: {
      const geofenceIds = action.payload;
      const newState = { ...state };
      if (geofenceIds.length == 0) {
        forEach(state, (geofence: any, geofenceId) => {
          newState[geofenceId] = {
            ...geofence,
            isShownInMap: true
          };
        });
      } else {
        geofenceIds.forEach((geofenceId) => {
          newState[geofenceId] = {
            ...state[geofenceId],
            isShownInMap: true
          };
        });
      }

      return newState;
    }

    case types.GEOFENCES_ALL_HIDE_IN_MAP: {
      const geofenceIds = action.payload;
      const newState = { ...state };
      if (geofenceIds.length == 0) {
        forEach(state, (geofence: any, geofenceId) => {
          newState[geofenceId] = {
            ...geofence,
            isShownInMap: false
          };
        });
      } else {
        geofenceIds.forEach((geofenceId) => {
          newState[geofenceId] = {
            ...state[geofenceId],
            isShownInMap: false
          };
        });
      }

      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.GEOFENCE_SELECTED: {
      const geofenceId = action.payload;
      const newState = {
        ...state,
        [geofenceId]: {
          ...state[geofenceId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.GEOFENCE_DESELECTED: {
      const geofenceId = action.payload;
      return {
        ...state,
        [geofenceId]: {
          ...state[geofenceId],
          isSelected: false
        }
      };
    }

    case types.GEOFENCES_ALL_SELECTED: {
      const geofenceIds = action.payload;
      const newState = { ...state };
      if (geofenceIds.length == 0) {
        forEach(state, (geofence: any, geofenceId) => {
          newState[geofenceId] = {
            ...geofence,
            isSelected: true
          };
        });
      } else {
        geofenceIds.forEach((geofenceId) => {
          newState[geofenceId] = {
            ...state[geofenceId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.GEOFENCES_ALL_DESELECTED: {
      const geofenceIds = action.payload;
      const newState = { ...state };
      if (geofenceIds.length == 0) {
        forEach(state, (geofence: any, geofenceId) => {
          newState[geofenceId] = {
            ...geofence,
            isSelected: false
          };
        });
      } else {
        geofenceIds.forEach((geofenceId) => {
          newState[geofenceId] = {
            ...state[geofenceId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.GEOFENCES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.GEOFENCES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.GEOFENCE_ADD_COMPLETED: {
      const { oldId, geofence } = action.payload;
      return state.map((geofenceId) => (geofenceId === oldId ? geofence.geofenceId : geofenceId));
    }

    case types.GEOFENCE_ADD_STARTED: {
      const geofence = action.payload;
      return [...state, geofence.geofenceId];
    }

    case types.GEOFENCE_REMOVE_COMPLETED: {
      const { geofenceId } = action.payload;
      return state.filter((geofenceIdState) => geofenceIdState !== geofenceId);
    }

    //CASE ADD STARTED
    case types.GEOFENCE_ADD_STARTED: {
      const geofence = action.payload;
      return [...state, geofence.geofenceId];
    }

    //CASE ADD FAILED
    case types.GEOFENCE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((geofenceIdState) => geofenceIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GEOFENCES_FETCH_STARTED: {
      return true;
    }
    case types.GEOFENCES_FETCH_COMPLETED:
    case types.GEOFENCES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GEOFENCES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.GEOFENCES_PARTIAL_FETCH_COMPLETED:
    case types.GEOFENCES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GEOFENCE_ADD_STARTED: {
      return true;
    }
    case types.GEOFENCE_ADD_COMPLETED:
    case types.GEOFENCE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GEOFENCE_ASSIGNATION_EDIT_STARTED:
    case types.GEOFENCE_EDIT_STARTED: {
      return true;
    }
    case types.GEOFENCE_EDIT_COMPLETED:
    case types.GEOFENCE_ASSIGNATION_EDIT_COMPLETED:
    case types.GEOFENCE_ASSIGNATION_EDIT_FAILED:
    case types.GEOFENCE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.GEOFENCE_ASSIGNATION_REMOVE_STARTED:
    case types.GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_STARTED:
    case types.GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_STARTED:
    case types.GEOFENCE_REMOVE_STARTED: {
      return true;
    }
    case types.GEOFENCE_ASSIGNATION_REMOVE_COMPLETED:
    case types.GEOFENCE_REMOVE_COMPLETED:
    case types.GEOFENCE_ASSIGNATION_REMOVE_FAILED:
    case types.GEOFENCE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GEOFENCES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GEOFENCES_FETCH_STARTED:
    case types.GEOFENCES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GEOFENCES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GEOFENCES_PARTIAL_FETCH_STARTED:
    case types.GEOFENCES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GEOFENCE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GEOFENCE_ADD_STARTED:
    case types.GEOFENCE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GEOFENCE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GEOFENCE_EDIT_STARTED:
    case types.GEOFENCE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.GEOFENCE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.GEOFENCE_REMOVE_STARTED:
    case types.GEOFENCE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const geofences: any = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

const geofencesPersistedConfig = {
  key: 'geofences',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAdding', 'isEditing', 'isRemoving', 'isFetchingList', 'isPartialFetchingList'],
  whitelist: ['byId', 'order']
};

export default persistReducer(geofencesPersistedConfig, geofences);

//Own state
export const getOwnState = (state) => state.geofences;

//Information
export const getGeofence = (state, geofenceId) => {
  const geofence = getOwnState(state).byId[geofenceId];
  if (geofence) {
    const organization = geofence.organizationId
      ? organizationSelectors.getOrganization(state, geofence.organizationId)
      : undefined;
    const division = geofence.divisionId
      ? divisionSelectors.getDivision(state, geofence.divisionId)
      : undefined;
    const subdivision = geofence.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, geofence.subdivisionId)
      : undefined;
    return {
      ...geofence,
      organizationName: organization ? organization.organizationName : '',
      divisionName: division ? division.divisionName : '',
      subdivisionName: subdivision ? subdivision.subdivisionName : ''
    };
  } else return undefined;
};

export const getGeofencesList = (state) =>
  getOwnState(state)
    .order.map((id) => getGeofence(state, id))
    .filter((geofence) => geofence.geofenceStatus > 0);
export const getParentGeofencesList = (state) =>
  getOwnState(state)
    .order.map((id) => getGeofence(state, id))
    .filter((geofence) => geofence.isParentGeofence);
export const getSubGeofencesList = (state) =>
  getOwnState(state)
    .order.map((id) => getGeofence(state, id))
    .filter((geofence) => geofence.isParentGeofence === false && geofence.parentGeofenceId != null);

export const getGeofenceByPrivateCode = (satate, privateCode) => {
  return getGeofencesList(satate).find((geofence) => geofence.privateCode === privateCode);
};
export const getGeofencesListByMembership = (state, membership, geofencesList: any = null) => {
  if (!geofencesList) geofencesList = getGeofencesList(state);
  return geofencesList.filter(
    (geofence) =>
      membership &&
      (!membership.operatorId || membership.operatorId === geofence.operatorId) &&
      (!membership.organizationId || membership.organizationId === geofence.organizationId) &&
      (!membership.divisionId || membership.divisionId === geofence.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === geofence.subdivisionId) &&
      (!membership.groupId ||
        geofence.groups?.split(',').includes(String(membership.groupId)) ||
        membership.groupId + '' === geofence.groups)
  );
};
export const getGeofenceByPrivateCodeByMembership = (
  state,
  privateCode: string,
  membership: DefaultFilters
) => {
  return getGeofencesListByMembership(state, membership).find(
    (geofence) => geofence.privateCode === privateCode
  );
};
export const getParentGeofencesListByMembership = (state, membership) =>
  getGeofencesListByMembership(state, membership, getParentGeofencesList(state));
export const getSubGeofencesListByMembership = (state, membership) =>
  getGeofencesListByMembership(state, membership, getSubGeofencesList(state));
export const getSelectedGeofences = (state) => {
  const selectedGeofences = getGeofencesList(state).filter((geofence) => geofence.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedGeofences.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedGeofences.length > 0) return selectedGeofences;
};

export const getSelectedGeofence = (state) => {
  const selectedGeofences = getGeofencesList(state).filter((geofence) => geofence.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedGeofences.length === 1) return selectedGeofences[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListGeofences = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListGeofences = (state) => getOwnState(state).isFetchingList;
export const isAddingGeofence = (state) => getOwnState(state).isAdding;
export const isEditingGeofence = (state) => getOwnState(state).isEditing;
export const isRemovingGeofence = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListGeofencesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListGeofencesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingGeofenceErrors = (state) => getOwnState(state).errorAdding;
export const getEditingGeofenceErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingGeofenceErrors = (state) => getOwnState(state).errorRemoving;
