/* -------------------------------------------------------------------------- */
/*                            Reductor de Entities                            */
/* -------------------------------------------------------------------------- */

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import union from 'lodash/union';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as authTypes from '../../../../redux/types/auth';
import * as entityTypes from '../../../screens/Entities/constants';
import * as types from '../types';
import * as operatorsTypes from '../../Operators/types';
import * as organizationsTypes from '../../Organizations/types';
import * as divisionsTypes from '../../Divisions/types';
import * as subdivisionsTypes from '../../Subdivisions/types';
import * as maintenanceLinesTypes from '../../MaintenanceLines/types';
import * as unitsTypes from '../../Units/types';
import * as brandsTypes from '../../../../redux/types/static-catalogs/brands';
import * as modelsTypes from '../../../../redux/types/models';
import * as geofencesTypes from '../../Geofences/types';
import * as workplansTypes from '../../Workplans/types/workplans';

import * as routesTypes from '../../Routes/types/routes';
import * as schedulesTypes from '../../Schedules/types';
import * as agentsTypes from '../../Agents/types';
import * as serviceClassesTypes from '../../ServiceClasses/types';
import * as monitoringUsersTypes from '../../Users/types';
import * as contactsActions from 'views/screens/Contacts/types';
import * as vehicleTypes from '../../Vehicles/types';
import * as assetsTypes from '../../Assets/types';
import * as locationTypes from '../../Locations/types';
import * as officeTypes from '../../Offices/types';
import * as driverTypes from '../../Drivers/types';
import * as modulesTypes from '../../Modules/types';
import * as sensorsTypes from '../../Sensors/types';
import * as simsTypes from 'views/screens/SIMs/types';
import * as formsTypes from 'views/screens/Forms/types';
import * as jobsTypes from 'views/screens/Jobs/types';
import * as groupsTypes from 'views/screens/Groups/types';
import * as customersTypes from 'views/screens/Customers/types';
import * as contractsTypes from 'views/screens/Contracts/types';
import * as contractTemplatesTypes from 'views/screens/ContractTemplates/types';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }

    case types.ENTITIES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((entityTypeId) => {
        newState[entityTypeId] = {
          ...state[entityTypeId],
          ...entities[entityTypeId],
          timestamp: Date.parse(entities[entityTypeId].auditDate + 'z') / 1000
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ENTITIES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const userUpdateByEntity = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //USER DATETIME UPDATE BY ENTITIES FETCH
    case unitsTypes.UNITS_FETCH_COMPLETED:
    case unitsTypes.UNITS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_UNITS] = {
        entityTypeId: entityTypes.ENTITY_UNITS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case subdivisionsTypes.SUBDIVISIONS_FETCH_COMPLETED:
    case subdivisionsTypes.SUBDIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_SUBDIVISONS] = {
        entityTypeId: entityTypes.ENTITY_SUBDIVISONS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case maintenanceLinesTypes.MAINTENANCE_LINES_FETCH_COMPLETED:
    case maintenanceLinesTypes.MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_MAINTENANCE_LINES] = {
        entityTypeId: entityTypes.ENTITY_MAINTENANCE_LINES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case vehicleTypes.VEHICLES_FETCH_COMPLETED:
    case vehicleTypes.VEHICLES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_VEHICLES] = {
        entityTypeId: entityTypes.ENTITY_VEHICLES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case modulesTypes.MODULES_FETCH_COMPLETED:
    case modulesTypes.MODULES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_MODULES] = {
        entityTypeId: entityTypes.ENTITY_MODULES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case agentsTypes.AGENTS_FETCH_COMPLETED:
    case agentsTypes.AGENTS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_AGENTS] = {
        entityTypeId: entityTypes.ENTITY_AGENTS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case serviceClassesTypes.SERVICECLASSES_FETCH_COMPLETED:
    case serviceClassesTypes.SERVICECLASSES_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_SERVICE_CLASSES] = {
        entityTypeId: entityTypes.ENTITY_SERVICE_CLASSES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case operatorsTypes.OPERATORS_FETCH_COMPLETED:
    case operatorsTypes.OPERATORS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_OPERATORS] = {
        entityTypeId: entityTypes.ENTITY_OPERATORS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case divisionsTypes.DIVISIONS_FETCH_COMPLETED:
    case divisionsTypes.DIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_DIVISIONS] = {
        entityTypeId: entityTypes.ENTITY_DIVISIONS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case formsTypes.FORMS_FETCH_COMPLETED:
    case formsTypes.FORMS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_FORMS] = {
        entityTypeId: entityTypes.ENTITY_FORMS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case organizationsTypes.ORGANIZATIONS_FETCH_COMPLETED:
    case organizationsTypes.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_ORGANIZATIONS] = {
        entityTypeId: entityTypes.ENTITY_ORGANIZATIONS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case monitoringUsersTypes.MONITORING_USERS_FETCH_COMPLETED:
    case monitoringUsersTypes.MONITORING_USERS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_USERS] = {
        entityTypeId: entityTypes.ENTITY_USERS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case contactsActions.CONTACTS_FETCH_COMPLETED:
    case contactsActions.CONTACTS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_CONTACTS] = {
        entityTypeId: entityTypes.ENTITY_CONTACTS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case workplansTypes.WORKPLANS_FETCH_COMPLETED:
    case workplansTypes.WORKPLANS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_WORKPLANS] = {
        entityTypeId: entityTypes.ENTITY_WORKPLANS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case geofencesTypes.GEOFENCES_FETCH_COMPLETED:
    case geofencesTypes.GEOFENCES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_GEOFENCES] = {
        entityTypeId: entityTypes.ENTITY_GEOFENCES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case schedulesTypes.SCHEDULES_FETCH_COMPLETED:
    case schedulesTypes.SCHEDULES_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_SCHEDULES] = {
        entityTypeId: entityTypes.ENTITY_SCHEDULES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }
    case routesTypes.ROUTES_FETCH_COMPLETED:
    case routesTypes.ROUTES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_ROUTES] = {
        entityTypeId: entityTypes.ENTITY_ROUTES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case simsTypes.SIMS_FETCH_COMPLETED:
    case simsTypes.SIMS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_SIM_CARDS] = {
        entityTypeId: entityTypes.ENTITY_SIM_CARDS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case locationTypes.LOCATIONS_FETCH_COMPLETED:
    case locationTypes.LOCATIONS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_LOCATIONS] = {
        entityTypeId: entityTypes.ENTITY_LOCATIONS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case officeTypes.OFFICES_FETCH_COMPLETED:
    case officeTypes.OFFICES_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_OFFICES] = {
        entityTypeId: entityTypes.ENTITY_OFFICES,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case driverTypes.DRIVERS_FETCH_COMPLETED:
    case driverTypes.DRIVERS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_DRIVERS] = {
        entityTypeId: entityTypes.ENTITY_DRIVERS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case assetsTypes.ASSETS_FETCH_COMPLETED:
    case assetsTypes.ASSETS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_ASSETS] = {
        entityTypeId: entityTypes.ENTITY_ASSETS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }
    case sensorsTypes.SENSORS_FETCH_COMPLETED:
    case sensorsTypes.SENSORS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_SENSORS] = {
        entityTypeId: entityTypes.ENTITY_SENSORS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }

    case jobsTypes.JOBS_BACKLOG_FETCH_COMPLETED:
    case jobsTypes.JOBS_BACKLOG_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_JOBS_BACKLOG] = {
        entityTypeId: entityTypes.ENTITY_JOBS_BACKLOG,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }

    case groupsTypes.GROUPS_FETCH_COMPLETED:
    case groupsTypes.GROUPS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_GROUPS] = {
        entityTypeId: entityTypes.ENTITY_GROUPS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }

    case customersTypes.CUSTOMERS_FETCH_COMPLETED:
    case customersTypes.CUSTOMERS_PARTIAL_FETCH_COMPLETED: {
      const { operatorId, organizationId } = action.payload;
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_CUSTOMERS] = {
        entityTypeId: entityTypes.ENTITY_CUSTOMERS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString(),
        operatorId,
        organizationId
      };
      return newState;
    }

    case contractsTypes.ORGANIZATION_CONTRACTS_FETCH_COMPLETED:
    case contractsTypes.ORGANIZATION_CONTRACTS_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      const date = new Date();
      date.setMinutes(date.getMinutes() - 1);
      newState[entityTypes.ENTITY_ORGANIZATION_CONTRACTS] = {
        entityTypeId: entityTypes.ENTITY_ORGANIZATION_CONTRACTS,
        timestamp: Date.now() / 1000 - 60,
        date: date.toISOString()
      };
      return newState;
    }

    case contractTemplatesTypes.CONTRACT_TEMPLATES_FETCH_COMPLETED:
    case contractTemplatesTypes.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED: {
      const newState = { ...state };
      newState[entityTypes.ENTITY_CONTRACT_TEMPLATES] = {
        entityTypeId: entityTypes.ENTITY_CONTRACT_TEMPLATES,
        timestamp: Date.now() / 1000 - 60,
        date: new Date().toUTCString()
      };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING OR UPDATING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ENTITIES_FETCH_STARTED: {
      return true;
    }
    case types.ENTITIES_FETCH_COMPLETED:
    case types.ENTITIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchedList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ENTITIES_RESET_IS_FETCHED:
    case types.ENTITIES_FETCH_STARTED: {
      return false;
    }
    case types.ENTITIES_FETCH_COMPLETED:
    case types.ENTITIES_FETCH_FAILED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

//ERRORS FETCHING OR UPDATING
const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ENTITIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ENTITIES_FETCH_STARTED:
    case types.ENTITIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const entities: any = combineReducers({
  byId,
  order,
  userUpdateByEntity,
  isFetchingList,
  isFetchedList,
  errorFetchingList
});

const entitiesPersistConfig = {
  key: 'entities',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isFetchingList', 'isFetchedList', 'errorFetchingList']
  // blackList: [],
  // whiteList: [],
};

export default persistReducer(entitiesPersistConfig, entities);

//Own state
export const getOwnState = (state) => state.entities;

//Information
export const getEntity = (state, entityTypeId) => getOwnState(state).byId[entityTypeId];
export const getUserUpdateByEntityTimestamp = (state, entityTypeId) =>
  getOwnState(state).userUpdateByEntity[entityTypeId]
    ? getOwnState(state).userUpdateByEntity[entityTypeId].timestamp
    : null;
export const getUserUpdateByEntityDate = (state, entityTypeId) =>
  getOwnState(state).userUpdateByEntity[entityTypeId]
    ? getOwnState(state).userUpdateByEntity[entityTypeId].date
    : null;
export const getUserUpdateByEntityOperatorId = (state, entityTypeId) =>
  getOwnState(state).userUpdateByEntity[entityTypeId]
    ? getOwnState(state).userUpdateByEntity[entityTypeId]?.operatorId
    : null;
export const getUserUpdateByEntityOrganizationId = (state, entityTypeId) =>
  getOwnState(state).userUpdateByEntity[entityTypeId]
    ? getOwnState(state).userUpdateByEntity[entityTypeId]?.organizationId
    : null;
export const getEntitiesList = (state) =>
  getOwnState(state).order.map((id) => getEntity(state, id));

/**
 * Is Entity updated
 * @param state Redux State
 * @returns True if is updated, false or null if is not updated
 */
export const isEntityUpdated = (state) => (entitiesToUpdate) => {
  return entitiesToUpdate.map((entity) => {
    if (!entity?.entityTypeId) {
      const userUpdateByEntity = getUserUpdateByEntityTimestamp(state, entity);
      if (userUpdateByEntity == null) return null;
      if (userUpdateByEntity < getEntity(state, entity).timestamp) return false;
      return true;
    } else {
      // Vars to validate
      const { operatorId, organizationId, allowNullOrganizationId } =
        authSelectors.getAuthUserMembershipFilters(state, {
          filterOperatorId: entity.operatorId,
          filterOrganizationId: entity.organizationId
        });
      const updateEntityByTimestamp = getUserUpdateByEntityTimestamp(state, entity.entityTypeId);
      const entityTimestamp = getEntity(state, entity.entityTypeId).timestamp;
      const userUpdateEntityOperatorId = getUserUpdateByEntityOperatorId(
        state,
        entity.entityTypeId
      );
      const userUpdateEntityOrganizationId = getUserUpdateByEntityOrganizationId(
        state,
        entity.entityTypeId
      );
      // end Vars to validate

      // Entity type is sim
      if (entity.entityTypeId == 4) {
      }

      // Return entity as updated because of does not allow organization null
      // Return true in order to don't fetch entity
      if (!entity.allowNullOrganizationId && !allowNullOrganizationId && !organizationId) {
        return true;
      }

      // Entity first update, return null in order to update
      if (updateEntityByTimestamp == null) {
        return null;
      }

      // Entity is not updated, and return false in order to update
      if (
        updateEntityByTimestamp < entityTimestamp &&
        userUpdateEntityOperatorId === operatorId &&
        userUpdateEntityOrganizationId === organizationId
      ) {
        return false;
      }

      // Actualize entity
      if (
        userUpdateEntityOperatorId !== operatorId ||
        userUpdateEntityOrganizationId !== organizationId
      ) {
        return null;
      }
      // Default, does not fetch entity
      return true;
    }
  });
};

export const areAllEntitiesUpdated = (state, entitiesToUpdate) => {
  var areUpdated = true;
  entitiesToUpdate.map((entityTypeId) => {
    const timestamp = getUserUpdateByEntityTimestamp(state, entityTypeId);
    if (timestamp == null) areUpdated = false;
    else if (timestamp + 60 < getEntity(state, entityTypeId)?.timestamp) areUpdated = false;
  });
  return areUpdated;
};

//Status of sagas
export const isFetchingListEntities = (state) => getOwnState(state).isFetchingList;
export const isFetchedListEntities = (state) => getOwnState(state).isFetchedList;

//Errors
export const getFetchingListEntitiesErrors = (state) => getOwnState(state).errorFetchingList;
