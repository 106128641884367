export const ROUTES_EXECUTIONS_FETCH_STARTED = 'ROUTES_EXECUTIONS_FETCH_STARTED';
export const ROUTES_EXECUTIONS_FETCH_COMPLETED = 'ROUTES_EXECUTIONS_FETCH_COMPLETED';
export const ROUTES_EXECUTIONS_FETCH_FAILED = 'ROUTES_EXECUTIONS_FETCH_FAILED';

export const ROUTE_EXECUTION_EDIT_STARTED = 'ROUTE_EXECUTION_EDIT_STARTED';
export const ROUTE_EXECUTION_EDIT_COMPLETED = 'ROUTE_EXECUTION_EDIT_COMPLETED';
export const ROUTE_EXECUTION_EDIT_FAILED = 'ROUTE_EXECUTION_EDIT_FAILED';

export const ROUTES_EXECUTION_CANCEL_STARTED = 'ROUTES_EXECUTION_CANCEL_STARTED';
export const ROUTES_EXECUTION_CANCEL_COMPLETED = 'ROUTES_EXECUTION_CANCEL_COMPLETED';
export const ROUTES_EXECUTION_CANCEL_FAILED = 'ROUTES_EXECUTION_CANCEL_FAILED';

export const ROUTES_EXECUTION_FINALIZE_STARTED = 'ROUTES_EXECUTION_FINALIZE_STARTED';
export const ROUTES_EXECUTION_FINALIZE_COMPLETED = 'ROUTES_EXECUTION_FINALIZE_COMPLETED';
export const ROUTES_EXECUTION_FINALIZE_FAILED = 'ROUTES_EXECUTION_FINALIZE_FAILED';

export const ROUTES_EXECUTION_REMOVE_STARTED = 'ROUTES_EXECUTION_REMOVE_STARTED';
export const ROUTES_EXECUTION_REMOVE_COMPLETED = 'ROUTES_EXECUTION_REMOVE_COMPLETED';
export const ROUTES_EXECUTION_REMOVE_FAILED = 'ROUTES_EXECUTION_REMOVE_FAILED';

export const ROUTES_EXECUTION_COMPLETE_STARTED = 'ROUTES_EXECUTION_COMPLETE_STARTED';
export const ROUTES_EXECUTION_COMPLETE_COMPLETED = 'ROUTES_EXECUTION_COMPLETE_COMPLETED';
export const ROUTES_EXECUTION_COMPLETE_FAILED = 'ROUTES_EXECUTION_COMPLETE_FAILED';

export const ROUTES_EXECUTION_PAUSE_STARTED = 'ROUTES_EXECUTION_PAUSE_STARTED';
export const ROUTES_EXECUTION_PAUSE_COMPLETED = 'ROUTES_EXECUTION_PAUSE_COMPLETED';
export const ROUTES_EXECUTION_PAUSE_FAILED = 'ROUTES_EXECUTION_PAUSE_FAILED';

export const ROUTES_EXECUTION_START_STARTED = 'ROUTES_EXECUTION_START_STARTED';
export const ROUTES_EXECUTION_START_COMPLETED = 'ROUTES_EXECUTION_START_COMPLETED';
export const ROUTES_EXECUTION_START_FAILED = 'ROUTES_EXECUTION_START_FAILED';

export const ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED = 'ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED';
export const ROUTES_EXECUTION_MANUAL_TRANSIT_COMPLETED =
  'ROUTES_EXECUTION_MANUAL_TRANSIT_COMPLETED';
export const ROUTES_EXECUTION_MANUAL_TRANSIT_FAILED = 'ROUTES_EXECUTION_MANUAL_TRANSIT_FAILED';

export const ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED = 'ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED';
export const ROUTE_CHANGE_TO_ALTERNATE_ROUTE_COMPLETED =
  'ROUTE_CHANGE_TO_ALTERNATE_ROUTE_COMPLETED';
export const ROUTE_CHANGE_TO_ALTERNATE_ROUTE_FAILED = 'ROUTE_CHANGE_TO_ALTERNATE_ROUTE_FAILED';

export const ROUTES_EXECUTION_SHARE_STARTED = 'ROUTES_EXECUTION_SHARE_STARTED';
export const ROUTES_EXECUTION_SHARE_COMPLETED = 'ROUTES_EXECUTION_SHARE_COMPLETED';
export const ROUTES_EXECUTION_SHARE_FAILED = 'ROUTES_EXECUTION_SHARE_FAILED';

export const ROUTES_EXECUTION_ASSIGN_UNIT_STARTED = 'ROUTES_EXECUTION_ASSIGN_UNIT_STARTED';
export const ROUTES_EXECUTION_ASSIGN_UNIT_COMPLETED = 'ROUTES_EXECUTION_ASSIGN_UNIT_COMPLETED';
export const ROUTES_EXECUTION_ASSIGN_UNIT_FAILED = 'ROUTES_EXECUTION_ASSIGN_UNIT_FAILED';

export interface RouteExecution {
  routeAssignmentId: number;
  routeId: number;
  routeName: string;
  distance: number;
  privateCode: null | string;
  sourceName: string;
  targetName: string;
  unitId: number;
  unitOrganizationId: number;
  organizationId: number;
  unitName: string;
  unitLocation: string;
  routeStatus: number;
  routeProgress: number;
  speed: number;
  odometer: number;
  gpsDate: string;
  auditDate: string;
  assignmentDate: string;
  finalizedDate: null | string;
  completionDate: null | string;
  cancelDate: null | string;
  stopDate: null | string;
  startDate: null | string;
  transitDate: null | string;
  cancelReason: null | string;
  distanceToCheckpoint: number;
  locationName: string;
  correlative: number;
  assignNotificationEmails: null | string;
  notificationEvents: null | string;
  globalNotificationEmails: null | string;
  globalNotificationFrec: string;
  useSharedLink: boolean;
  routeNotificationEmails: string;
  requiredStartAtOrigin: boolean;
  completionType: number;
  isSpecialCargo: boolean;
  customField1: null | string;
  customField2: null | string;
  customField3: null | string;
  customField4: null | string;
  customField5: null | string;
  customField6: null | string;
  customerId: number;
  customerName: string;
  onTimeStatus: number;
  speedStatus: number;
  stopStatus: boolean;
  driverId: null | number;
  driverName: null | string;
  driverPhoto: null | string;
  driverPhoneNumber: null | string;
  driverBloodType: null | string;
  driverLicenseNumber: null | string;
  driverLicenseType: null | string;
  driverLicenseExpireDate: null | string;
  driverUseGlasses: null | boolean | number;
  driverBloodSugarProblems: null | boolean | number;
  routeStatusLabel: string;
  routeStatusLabelInfo: string;
  isDeviceOpenLabel: string;
  speedLabel: number;
  routeProgressLabel: string;
  distanceToCheckpointLabel: string;
  distanceLabel: string;
  fuelConsumption: number;
  expectedPerformance: number;
  performanceOnRoute: number;
  distanceProgress: number;
  hasFuelPerformance: boolean | number;
}
