import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplans';
import workplansExecutions, * as workplansSelectorsExecutions from '../workplansExecutions';
import workplansExecutionLocations, * as workplansSelectorsExecutionLocations from '../workplansExecutionLocations';
import workplansComments from '../workplansComments';
import workplansAssignments from '../workplansAssignments';
import workplansVisits, * as workplansSelectorsVisits from '../workplansVisits';
import workplansReport, * as workplansSelectorsReport from '../workplansReport';
import * as geofenceSelectors from '../../../Geofences/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import * as authSelectors from '../../../../../redux/reducers/auth';
import persistReducer from 'redux-persist/es/persistReducer';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, LOCATIONSFETCH, ADD AND EDIT
    case types.WORKPLANS_FETCH_COMPLETED:
    case types.WORKPLANS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((workplanId) => {
        newState[workplanId] = {
          isSelected: false,
          ...state[workplanId],
          ...entities[workplanId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.WORKPLAN_LOCATIONS_FETCH_COMPLETED: {
      const { workplanId, locations } = action.payload;
      const newState = {
        ...state,
        [workplanId]: {
          ...state[workplanId],
          locations
        }
      };
      return newState;
    }

    case types.WORKPLAN_ADD_COMPLETED: {
      const { oldId, workplan } = action.payload;
      const newState = omit(state, oldId);
      newState[workplan.workplanId] = {
        ...state[oldId],
        ...workplan,
        isConfirmed: true
      };
      return newState;
    }

    case types.WORKPLAN_EDIT_COMPLETED: {
      const workplan = action.payload;
      return {
        ...state,
        [workplan.workplanId]: {
          ...omit(state[workplan.workplanId], ['oldWorkplan']),
          ...omit(workplan, ['oldWorkplan']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.WORKPLAN_ADD_STARTED: {
      const workplan = action.payload;
      const newState = { ...state };
      newState[workplan.workplanId] = {
        isSelected: false,
        ...workplan,
        isConfirmed: false
      };
      return newState;
    }
    case types.WORKPLAN_EDIT_STARTED: {
      const workplan = action.payload;
      return {
        ...state,
        [workplan.workplanId]: {
          ...state[workplan.workplanId],
          oldWorkplan: state[workplan.workplanId],
          ...workplan,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.WORKPLAN_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.WORKPLAN_EDIT_FAILED: {
      const { oldWorkplan } = action.payload;
      return {
        ...state,
        [oldWorkplan.workplanId]: {
          ...omit(state[oldWorkplan.workplanId], ['oldWorkplan']),
          ...oldWorkplan,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.WORKPLAN_REMOVE_COMPLETED: {
      const { workplanId } = action.payload;
      return omit(state, workplanId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.WORKPLAN_SELECTED: {
      const workplanId = action.payload;
      const newState = {
        ...state,
        [workplanId]: {
          ...state[workplanId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.WORKPLAN_DESELECTED: {
      const workplanId = action.payload;
      return {
        ...state,
        [workplanId]: {
          ...state[workplanId],
          isSelected: false
        }
      };
    }

    case types.WORKPLANS_ALL_SELECTED: {
      const workplanIds = action.payload;
      const newState = { ...state };
      if (workplanIds.length == 0) {
        forEach(state, (workplan: any, workplanId) => {
          newState[workplanId] = {
            ...workplan,
            isSelected: true
          };
        });
      } else {
        workplanIds.forEach((workplanId) => {
          newState[workplanId] = {
            ...state[workplanId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.WORKPLANS_ALL_DESELECTED: {
      const workplanIds = action.payload;
      const newState = { ...state };
      if (workplanIds.length == 0) {
        forEach(state, (workplan: any, workplanId) => {
          newState[workplanId] = {
            ...workplan,
            isSelected: false
          };
        });
      } else {
        workplanIds.forEach((workplanId) => {
          newState[workplanId] = {
            ...state[workplanId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //COPY WORKPLAN
    case types.WORKPLAN_COPY_COMPLETED: {
      const {
        workplanId,
        newWorkplanId,
        newWorkplanName,
        organizationId,
        divisionId,
        subdivisionId
      } = action.payload;

      return {
        ...state,
        [newWorkplanId]: {
          isSelected: false,
          ...state[workplanId],
          workplanId: newWorkplanId,
          workplanName: newWorkplanName,
          organizationId,
          divisionId,
          subdivisionId,
          isConfirmed: true
        }
      };
    }

    //ASSIGN GEOFENCES
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED: {
      const {
        workplanIds,
        originGeofenceId,
        originGeofenceName,
        originExitWindow,
        originValidationWindow
      } = action.payload;
      const newState = { ...state };
      workplanIds.map(
        (workplanId) =>
          (newState[workplanId] = {
            isSelected: false,
            ...state[workplanId],
            originGeofenceId,
            originGeofenceName,
            originExitWindow,
            originValidationWindow,
            isConfirmed: true
          })
      );
      return newState;
    }
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED: {
      const {
        workplanIds,
        workGeofenceId,
        workGeofenceName,
        workGeofenceArriveWindow,
        workGeofenceMaxTime
      } = action.payload;
      const newState = { ...state };
      workplanIds.map(
        (workplanId) =>
          (newState[workplanId] = {
            isSelected: false,
            ...state[workplanId],
            workGeofenceId,
            workGeofenceName,
            workGeofenceArriveWindow,
            workGeofenceMaxTime,
            isConfirmed: true
          })
      );
      return newState;
    }
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED: {
      const { workplanIds, destinationGeofenceId, destinationGeofenceName } = action.payload;
      const newState = { ...state };
      workplanIds.map(
        (workplanId) =>
          (newState[workplanId] = {
            isSelected: false,
            ...state[workplanId],
            destinationGeofenceId,
            destinationGeofenceName,
            isConfirmed: true
          })
      );
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.WORKPLANS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.WORKPLANS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.WORKPLAN_ADD_COMPLETED: {
      const { oldId, workplan } = action.payload;
      return state.map((workplanId) => (workplanId === oldId ? workplan.workplanId : workplanId));
    }

    case types.WORKPLAN_REMOVE_COMPLETED: {
      const { workplanId } = action.payload;
      return state.filter((workplanIdState) => workplanIdState !== workplanId);
    }

    //CASE ADD STARTED
    case types.WORKPLAN_ADD_STARTED: {
      const workplan = action.payload;
      return [...state, workplan.workplanId];
    }

    //CASE ADD FAILED
    case types.WORKPLAN_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((workplanIdState) => workplanIdState !== oldId);
    }

    //COPY WORKPLAN
    case types.WORKPLAN_COPY_COMPLETED: {
      const { newWorkplanId } = action.payload;
      return [...state, newWorkplanId];
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_FETCH_COMPLETED:
    case types.WORKPLANS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_PARTIAL_FETCH_COMPLETED:
    case types.WORKPLANS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingLocationsList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_SELECTED: {
      return true;
    }
    case types.WORKPLAN_LOCATIONS_FETCH_COMPLETED:
    case types.WORKPLAN_LOCATIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_ADD_STARTED: {
      return true;
    }
    case types.WORKPLAN_ADD_COMPLETED:
    case types.WORKPLAN_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_EDIT_STARTED: {
      return true;
    }
    case types.WORKPLAN_EDIT_COMPLETED:
    case types.WORKPLAN_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_REMOVE_STARTED: {
      return true;
    }
    case types.WORKPLAN_REMOVE_COMPLETED:
    case types.WORKPLAN_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCopying = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_COPY_STARTED: {
      return true;
    }
    case types.WORKPLAN_COPY_COMPLETED:
    case types.WORKPLAN_COPY_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAssigningGeofence = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED: {
      return true;
    }
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_FAILED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_FAILED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_FETCH_STARTED:
    case types.WORKPLANS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_PARTIAL_FETCH_STARTED:
    case types.WORKPLANS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingLocationsList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_LOCATIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_SELECTED:
    case types.WORKPLAN_LOCATIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_ADD_STARTED:
    case types.WORKPLAN_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_EDIT_STARTED:
    case types.WORKPLAN_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_REMOVE_STARTED:
    case types.WORKPLAN_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCopying = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_COPY_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_COPY_COMPLETED:
    case types.WORKPLAN_COPY_STARTED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorAssigningGeofence = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_FAILED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_FAILED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_ORIGIN_GEOFENCE_STARTED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_WORK_AREA_GEOFENCE_STARTED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_COMPLETED:
    case types.WORKPLANS_ASSIGN_DESTINATION_GEOFENCE_STARTED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const workplans: any = combineReducers({
  workplansExecutions,
  workplansVisits,
  workplansReport,
  workplansComments,
  workplansAssignments,
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isFetchingLocationsList,
  isAdding,
  isEditing,
  isRemoving,
  isCopying,
  isAssigningGeofence,
  errorPartialFetchingList,
  errorFetchingList,
  errorFetchingLocationsList,
  errorAdding,
  errorEditing,
  errorRemoving,
  errorCopying,
  errorAssigningGeofence
});

const workplansPersistConfig = {
  key: 'workplans',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'workplansExecutions',
    'workplansVisits',
    'workplansReport',
    'workplansComments',
    'workplansAssignments',
    'isPartialFetchingList',
    'isFetchingList',
    'isFetchingLocationsList',
    'isAdding',
    'isEditing',
    'isRemoving',
    'isCopying',
    'isAssigningGeofence',
    'errorPartialFetchingList',
    'errorFetchingList',
    'errorFetchingLocationsList',
    'errorAdding',
    'errorEditing',
    'errorRemoving',
    'errorCopying',
    'errorAssigningGeofence'
  ],
  whitelist: ['byId', 'order']
};

export default persistReducer(workplansPersistConfig, workplans);

//Own state
export const getOwnState = (state) => state.workplans;

//Information
export const getWorkplan = (state, workplanId) => {
  const intl = appIntl();
  const workplan = getOwnState(state).byId[workplanId];
  if (workplan) {
    const organization = workplan.organizationId
      ? organizationSelectors.getOrganization(state, workplan.organizationId)
      : undefined;
    const division = workplan.divisionId
      ? divisionSelectors.getDivision(state, workplan.divisionId)
      : undefined;
    const subdivision = workplan.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, workplan.subdivisionId)
      : undefined;
    return {
      ...workplan,
      estimatedTotalDistance: workplan?.estimatedTotalDistance
        ? authSelectors
            .getParsers(state)
            .convertMetersToAuthUserDistanceSystem(workplan?.estimatedTotalDistance)
        : 0,
      originExitWindow: workplan?.originExitWindow / 60 || 0,
      originValidationWindow: workplan?.originValidationWindow / 60 || 0,
      workGeofenceName: workplan?.hasAutogeneratedGeofence
        ? intl.formatMessage({ id: 'workplans.autogeneratedGeofence' })
        : workplan?.workGeofenceName,
      workGeofenceArriveWindow: workplan?.workGeofenceArriveWindow / 60 || 0,
      workGeofenceMaxTime: workplan?.workGeofenceMaxTime / 60 || 0,
      organizationName: organization?.organizationName || '',
      divisionName: division?.divisionName || '',
      subdivisionName: subdivision?.subdivisionName || '',
      comments: workplan.comments || '',
      checkSequenceLabel: intl.formatMessage({
        id: workplan.checkSequence ? 'common.yes' : 'common.no'
      }),
      membership: {
        organizationId: workplan.organizationId || undefined,
        divisionId: workplan.divisionId || undefined,
        subdivisionId: workplan.subdivisionId || undefined
      }
    };
  } else return undefined;
};

export const getWorkplansList = (state) =>
  getOwnState(state)
    .order.map((id) => getWorkplan(state, id))
    .filter((workplan) => workplan.workplanStatus > 0);
export const getWorkplansListByMembership = (state, membership) => {
  return getWorkplansList(state).filter(
    (workplan) =>
      membership &&
      (!membership.operatorId || membership.operatorId === workplan.operatorId) &&
      (!membership.organizationId || membership.organizationId === workplan.organizationId) &&
      (!membership.divisionId || membership.divisionId === workplan.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === workplan.subdivisionId) &&
      (!membership.groupId || membership.groupId === workplan.groupId)
  );
};
export const getSelectedWorkplans = (state) => {
  const selectedWorkplans = getWorkplansList(state).filter((workplan) => workplan.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedWorkplans.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedWorkplans.length > 0) return selectedWorkplans;
};

export const getSelectedWorkplan = (state) => {
  const selectedWorkplans = getWorkplansList(state).filter((workplan) => workplan.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedWorkplans.length === 1) return selectedWorkplans[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListWorkplans = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListWorkplans = (state) => getOwnState(state).isFetchingList;
export const isFetchingLocationsListWorkplans = (state) =>
  getOwnState(state).isFetchingLocationsList;
export const isAddingWorkplan = (state) => getOwnState(state).isAdding;
export const isEditingWorkplan = (state) => getOwnState(state).isEditing;
export const isRemovingWorkplan = (state) => getOwnState(state).isRemoving;
export const isCopyingWorkplan = (state) => getOwnState(state).isCopying;
export const isAssigningGeofenceWorkplan = (state) => getOwnState(state).isAssigningGeofence;

//Errors
export const getPartialFetchingListWorkplansErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListWorkplansErrors = (state) => getOwnState(state).errorFetchingList;
export const getFetchingLocationsListWorkplansErrors = (state) =>
  getOwnState(state).errorFetchingLocationsList;
export const getAddingWorkplanErrors = (state) => getOwnState(state).errorAdding;
export const getEditingWorkplanErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingWorkplanErrors = (state) => getOwnState(state).errorRemoving;
export const getCopyingWorkplanErrors = (state) => getOwnState(state).errorCopying;
export const getAssigningGeofenceWorkplanErrors = (state) =>
  getOwnState(state).errorAssigningGeofence;

/* -------------------------------------------------------------------------- */
/*                            WORKPLANS EXECUTIONS                            */
/* -------------------------------------------------------------------------- */
//Information
export const getWorkplansExecution = (state, visitId) =>
  workplansSelectorsExecutions.getWorkplansExecution(state, visitId);
export const getWorkplansExecutionsList = (state) =>
  workplansSelectorsExecutions.getWorkplansExecutionsList(state);
export const getWorkplansExecutionsIndicators = (state) =>
  workplansSelectorsExecutions.getIndicators(state);
export const getWorkplansExecutionsDurationStatus = (state) =>
  workplansSelectorsExecutions.getDurationStatus(state);
export const getWorkplansExecutionStatus = (state) =>
  workplansSelectorsExecutions.getExecutionStatus(state);
export const getWorkplansExecutionsGeographicStatus = (state) =>
  workplansSelectorsExecutions.getGeographicStatus(state);
//Status of sagas
export const isFetchingWorkplansExecutions = (state) =>
  workplansSelectorsExecutions.isFetchingWorkplansExecutions(state);
export const isFetchingWorkplanExecution = (state) =>
  workplansSelectorsExecutions.isFetchingWorkplanExecution(state);
export const isCancelingWorkplansExecution = (state) =>
  workplansSelectorsExecutions.isCancelingWorkplansExecution(state);
export const isFinalizingWorkplansExecution = (state) =>
  workplansSelectorsExecutions.isFinalizingWorkplansExecution(state);
export const isManualTransitWorkplansExecution = (state) =>
  workplansSelectorsExecutions.isManualTransitWorkplansExecution(state);
export const isChangingWorkplansExecutionUnit = (state) =>
  workplansSelectorsExecutions.isChangingWorkplansExecutionUnit(state);

//Errors
export const getFetchingWorkplansExecutionsErrors = (state) =>
  workplansSelectorsExecutions.getFetchingWorkplansExecutionsErrors(state);
export const getFetchingWorkplansExecutionError = (state) =>
  workplansSelectorsExecutions.getFetchingWorkplanExecutionErrors(state);
export const getCancelingWorkplansExecutionErrors = (state) =>
  workplansSelectorsExecutions.getCancelingWorkplansExecutionErrors(state);
export const getFinalizingWorkplansExecutionErrors = (state) =>
  workplansSelectorsExecutions.getFinalizingWorkplansExecutionErrors(state);
export const getChangingWorkplansExecutionUnitErrors = (state) =>
  workplansSelectorsExecutions.getChangingWorkplansExecutionUnitErrors(state);

/* -------------------------------------------------------------------------- */
/*                              WORKPLANS VISITS                              */
/* -------------------------------------------------------------------------- */
//Information
export const getWorkplansVisit = (state, visitId) =>
  workplansSelectorsVisits.getWorkplansVisit(state, visitId);
export const getWorkplansVisitsList = (state) =>
  workplansSelectorsVisits.getWorkplansVisitsList(state);
export const getWorkplansVisitsListByDuration = (state, duration) =>
  workplansSelectorsVisits.getWorkplansVisitsListByDuration(state, duration);

//Status of sagas
export const isFetchingWorkplansVisits = (state) =>
  workplansSelectorsVisits.isFetchingWorkplansVisits(state);

//Errors
export const getFetchingWorkplansVisitsErrors = (state) =>
  workplansSelectorsVisits.getFetchingWorkplansVisitsErrors(state);

/* -------------------------------------------------------------------------- */
/*                              WORKPLANS REPORT                              */
/* -------------------------------------------------------------------------- */
//Information
export const getWorkplansReportExecution = (state, visitId) =>
  workplansSelectorsReport.getWorkplansExecution(state, visitId);
export const getWorkplansReportExecutionsList = (state) =>
  workplansSelectorsReport.getWorkplansExecutionsList(state);
export const getWorkplansReportUnit = (state, visitId) =>
  workplansSelectorsReport.getWorkplansUnit(state, visitId);
export const getWorkplansReportUnitsList = (state) =>
  workplansSelectorsReport.getWorkplansUnitsList(state);
export const getWorkplansReportLocation = (state, visitId) =>
  workplansSelectorsReport.getWorkplansLocation(state, visitId);
export const getWorkplansReportLocationsList = (state) =>
  workplansSelectorsReport.getWorkplansLocationsList(state);
export const getWorkplansReportIndicators = (state) =>
  workplansSelectorsReport.getIndicators(state);
export const getWorkplansReportEffectivenessStatus = (state) =>
  workplansSelectorsReport.getEffectivenessStatus(state);
export const getWorkplansReportArrivalStatus = (state) =>
  workplansSelectorsReport.getArrivalStatus(state);
export const getWorkplansReportDurationStatus = (state) =>
  workplansSelectorsReport.getDurationStatus(state);
export const getWorkplansReportSlidingPaneInormation = (state, chartId) =>
  workplansSelectorsReport.getSlidingPaneInformation(state, chartId);
//Status of sagas
export const isFetchingWorkplansReport = (state) =>
  workplansSelectorsReport.isFetchingWorkplansReport(state);

//Errors
export const getFetchingWorkplansReportErrors = (state) =>
  workplansSelectorsReport.getFetchingWorkplansReportErrors(state);

/* -------------------------------------------------------------------------- */
/*                       WORKPLAN EXECUTION LOCATIONS                         */
/* -------------------------------------------------------------------------- */
//Status of sagas
export const isAddingWorkplanExecutionLocation = (state) =>
  workplansSelectorsExecutionLocations.isAddingLocation(state);
export const isEditingWorkplanExecutionLocation = (state) =>
  workplansSelectorsExecutionLocations.isEditingLocation(state);
export const isRemovingWorkplanExecutionLocation = (state) =>
  workplansSelectorsExecutionLocations.isRemovingLocation(state);

//Errors
export const getAddingWorkplanExecutionLocationErrors = (state) =>
  workplansSelectorsExecutionLocations.getAddingLocationErrors(state);
export const getEditingWorkplanExecutionLocationErrors = (state) =>
  workplansSelectorsExecutionLocations.getEditingLocationErrors(state);
export const getRemovingWorkplanExecutionLocationErrors = (state) =>
  workplansSelectorsExecutionLocations.getRemovingLocationErrors(state);
