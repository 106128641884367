import * as types from '../types';

export const startFetchingContractTemplates = (filters = {}) => ({
  type: types.CONTRACT_TEMPLATES_FETCH_STARTED,
  payload: filters
});
export const completeFetchingContractTemplates = (entities, order, operatorId, organizationId) => ({
  type: types.CONTRACT_TEMPLATES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingContractTemplates = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CONTRACT_TEMPLATES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingContractTemplates = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.CONTRACT_TEMPLATES_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingContractTemplates = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.CONTRACT_TEMPLATES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingContractTemplates = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CONTRACT_TEMPLATES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingContractTemplate = (organizationContract) => ({
  type: types.CONTRACT_TEMPLATE_ADD_STARTED,
  payload: organizationContract
});
export const completeAddingContractTemplate = (oldId, organizationContract) => ({
  type: types.CONTRACT_TEMPLATE_ADD_COMPLETED,
  payload: { oldId, organizationContract }
});
export const failAddingContractTemplate = (oldId, error) => ({
  type: types.CONTRACT_TEMPLATE_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingContractTemplate = (organizationContract) => ({
  type: types.CONTRACT_TEMPLATE_EDIT_STARTED,
  payload: organizationContract
});
export const completeEditingContractTemplate = (organizationContract) => ({
  type: types.CONTRACT_TEMPLATE_EDIT_COMPLETED,
  payload: organizationContract
});
export const failEditingContractTemplate = (oldContractTemplate, error) => ({
  type: types.CONTRACT_TEMPLATE_EDIT_FAILED,
  payload: {
    oldContractTemplate,
    error
  }
});

export const startRemovingContractTemplate = (templateId) => ({
  type: types.CONTRACT_TEMPLATE_REMOVE_STARTED,
  payload: {
    templateId
  }
});
export const completeRemovingContractTemplate = (templateId) => ({
  type: types.CONTRACT_TEMPLATE_REMOVE_COMPLETED,
  payload: {
    templateId
  }
});
export const failRemovingContractTemplate = (templateId, error) => ({
  type: types.CONTRACT_TEMPLATE_REMOVE_FAILED,
  payload: {
    templateId,
    error
  }
});

export const selectContractTemplate = (templateId) => ({
  type: types.CONTRACT_TEMPLATE_SELECTED,
  payload: templateId
});

export const selectAllContractTemplates = (
  templateIds = [] //List of templateIds
) => ({
  type: types.CONTRACT_TEMPLATES_ALL_SELECTED,
  payload: templateIds
});

export const deselectContractTemplate = (templateId) => ({
  type: types.CONTRACT_TEMPLATE_DESELECTED,
  payload: templateId
});

export const deselectAllContractTemplates = (
  templateIds = [] //List of templateIds
) => ({
  type: types.CONTRACT_TEMPLATES_ALL_DESELECTED,
  payload: templateIds
});
