import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as operatorSelectors from 'views/screens/Operators/reducers';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.DIVISIONS_FETCH_COMPLETED:
    case types.DIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((divisionId) => {
        newState[divisionId] = {
          isSelected: false,
          ...state[divisionId],
          ...entities[divisionId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.DIVISION_ADD_COMPLETED: {
      const { oldId, division } = action.payload;
      const newState = omit(state, oldId);
      newState[division.divisionId] = {
        ...state[oldId],
        ...division,
        isConfirmed: true
      };
      return newState;
    }

    case types.DIVISION_EDIT_COMPLETED: {
      const division = action.payload;
      return {
        ...state,
        [division.divisionId]: {
          ...omit(state[division.divisionId], ['oldDivision']),
          ...omit(division, ['oldDivision']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.DIVISION_ADD_STARTED: {
      const division = action.payload;
      const newState = { ...state };
      newState[division.divisionId] = {
        isSelected: false,
        ...division,
        isConfirmed: false
      };
      return newState;
    }
    case types.DIVISION_EDIT_STARTED: {
      const division = action.payload;
      return {
        ...state,
        [division.divisionId]: {
          ...state[division.divisionId],
          oldDivision: state[division.divisionId],
          ...division,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.DIVISION_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.DIVISION_EDIT_FAILED: {
      const { oldDivision } = action.payload;
      return {
        ...state,
        [oldDivision.divisionId]: {
          ...omit(state[oldDivision.divisionId], ['oldDivision']),
          ...oldDivision,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.DIVISION_REMOVE_COMPLETED: {
      const { divisionId } = action.payload;
      return omit(state, divisionId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.DIVISION_SELECTED: {
      const divisionId = action.payload;
      const newState = {
        ...state,
        [divisionId]: {
          ...state[divisionId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.DIVISION_DESELECTED: {
      const divisionId = action.payload;
      return {
        ...state,
        [divisionId]: {
          ...state[divisionId],
          isSelected: false
        }
      };
    }

    case types.DIVISIONS_ALL_SELECTED: {
      const divisionIds = action.payload;
      const newState = { ...state };
      if (divisionIds.length == 0) {
        forEach(state, (division: any, divisionId) => {
          newState[divisionId] = {
            ...division,
            isSelected: true
          };
        });
      } else {
        divisionIds.forEach((divisionId) => {
          newState[divisionId] = {
            ...state[divisionId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.DIVISIONS_ALL_DESELECTED: {
      const divisionIds = action.payload;
      const newState = { ...state };
      if (divisionIds.length == 0) {
        forEach(state, (division: any, divisionId) => {
          newState[divisionId] = {
            ...division,
            isSelected: false
          };
        });
      } else {
        divisionIds.forEach((divisionId) => {
          newState[divisionId] = {
            ...state[divisionId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.DIVISIONS_FETCH_COMPLETED:
    case types.DIVISIONS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.DIVISION_ADD_COMPLETED: {
      const { oldId, division } = action.payload;
      return state.map((divisionId) => (divisionId === oldId ? division.divisionId : divisionId));
    }

    case types.DIVISION_REMOVE_COMPLETED: {
      const { divisionId } = action.payload;
      return state.filter((divisionIdState) => divisionIdState !== divisionId);
    }

    //CASE ADD STARTED
    case types.DIVISION_ADD_STARTED: {
      const division = action.payload;
      return [...state, division.divisionId];
    }

    //CASE ADD FAILED
    case types.DIVISION_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((divisionIdState) => divisionIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.DIVISIONS_FETCH_STARTED: {
      return true;
    }
    case types.DIVISIONS_FETCH_COMPLETED:
    case types.DIVISIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.DIVISIONS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.DIVISIONS_PARTIAL_FETCH_COMPLETED:
    case types.DIVISIONS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.DIVISION_ADD_STARTED: {
      return true;
    }
    case types.DIVISION_ADD_COMPLETED:
    case types.DIVISION_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.DIVISION_EDIT_STARTED: {
      return true;
    }
    case types.DIVISION_EDIT_COMPLETED:
    case types.DIVISION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.DIVISION_REMOVE_STARTED: {
      return true;
    }
    case types.DIVISION_REMOVE_COMPLETED:
    case types.DIVISION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.DIVISIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DIVISIONS_FETCH_STARTED:
    case types.DIVISIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.DIVISIONS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DIVISIONS_PARTIAL_FETCH_STARTED:
    case types.DIVISIONS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.DIVISION_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DIVISION_ADD_STARTED:
    case types.DIVISION_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.DIVISION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DIVISION_EDIT_STARTED:
    case types.DIVISION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.DIVISION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DIVISION_REMOVE_STARTED:
    case types.DIVISION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const divisions = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default divisions;

//Own state
export const getOwnState = (state) => state.divisions;

//Information
export const getDivision = (state, divisionId) => {
  const division = getOwnState(state).byId[divisionId];
  if (division) {
    const operator = operatorSelectors.getOperator(state, division.operatorId);
    const organization = organizationSelectors.getOrganization(state, division.organizationId);
    return {
      ...division,
      operatorName: operator ? operator.operatorName : '',
      organizationName: organization ? organization.organizationName : ''
    };
  } else return undefined;
};
export const getDivisionsList = (state) =>
  getOwnState(state)
    .order.map((id) => getDivision(state, id))
    .filter((division) => division.divisionStatus > 0);
export const getDivisionsListByMembership = (state, membership) => {
  //Se filtra por operatorId y organizationId
  if (membership.operatorId) {
    if (membership.organizationId) {
      return getDivisionsList(state).filter(
        (division) =>
          division.operatorId === membership.operatorId &&
          division.organizationId === membership.organizationId
      );
    }
    return getDivisionsList(state).filter(
      (division) => division.operatorId === membership.operatorId
    );
  }
  //Si no se ha seleccionado una organización se devuelve la lista completa
  else return getDivisionsList(state);
};

export const getDivisionsListBySelectedOrganization = (state, membership) => {
  //Se filtra por organizationId
  if (membership.organizationId) {
    return getDivisionsList(state).filter(
      (division) => division.organizationId === membership.organizationId
    );
  }
  //Si no se ha seleccionado una organización se devuelve una lista vacia
  else return [];
};

export const getSelectedDivisions = (state) => {
  const selectedDivisions = getDivisionsList(state).filter((division) => division.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedDivisions.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedDivisions.length > 0) return selectedDivisions;
};

export const getSelectedDivision = (state) => {
  const selectedDivisions = getDivisionsList(state).filter((division) => division.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedDivisions.length === 1) return selectedDivisions[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListDivisions = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListDivisions = (state) => getOwnState(state).isFetchingList;
export const isAddingDivision = (state) => getOwnState(state).isAdding;
export const isEditingDivision = (state) => getOwnState(state).isEditing;
export const isRemovingDivision = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListDivisionsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListDivisionsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingDivisionErrors = (state) => getOwnState(state).errorAdding;
export const getEditingDivisionErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingDivisionErrors = (state) => getOwnState(state).errorRemoving;
