/* -------------------------------------------------------------------------- */
/*                            Schema Device Metrics                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const deviceMetric = new schema.Entity(
  'deviceMetrics',
  {},
  { idAttribute: 'deviceMetricId' }
);
export const deviceMetrics = new schema.Array(deviceMetric);
