/* eslint-disable class-methods-use-this,no-param-reassign,no-restricted-syntax */
import API_BASE_URL from 'redux/sagas/settings/apibaseurl';
import { headers } from './constants';
import { HttpAxios } from './http-axios';
import { ConfigMessages, ConfigActions } from './http-events';

export const buildParams = (params: any) => {
  if (params) {
    for (const key in params) {
      if (params[key] === '' || params[key] === undefined) {
        delete params[key];
      }
    }
    return Object.keys(params).length > 0 ? params : undefined;
  }
  return undefined;
};

class HttpWebtrack extends HttpAxios {
  configMessage: ConfigMessages | undefined;
  configAction: ConfigActions | undefined;

  constructor() {
    super({ baseURL: API_BASE_URL, headers });
  }

  setConfigMessage = (configMessage: ConfigMessages | undefined) => {
    this.configMessage = configMessage;
  };

  setConfigActions = (configAction: ConfigActions | undefined) => {
    this.configAction = configAction;
  };
}

export default HttpWebtrack;
