import { FC, Fragment } from 'react';
import { ArrowLeft, ArrowRight } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, IntlShape, useIntl } from 'react-intl';
import { Button, Col, Form, Row } from 'reactstrap';
import ContactsDropdown from 'views/formComponents/ContactsDropdown';
import DropdownInput from 'views/formComponents/DropdownInput';
import MultiSelectInput from 'views/formComponents/MultiSelectInput';
import SwitchInput from 'views/formComponents/SwitchInput';
import RadioButtonInput from '../../../../formComponents/RadioButtonInput';
import { globalNotificationFrecs, routesEvents } from '../../utils';
import { useAssignmentContext } from './RoutesAssignUnitContext';

export enum AssignmentTypes {
  ByDate = 1,
  BySchedule = 2
}

export enum AlreadyLeftTheOrigin {
  Yes = 3,
  No = 4
}

export type AssignmentTypeFormValues = {
  assignmentType: AssignmentTypes;
  alreadyLeftTheOrigin: AlreadyLeftTheOrigin | undefined;
  globalNotificationFrec: string;
  useSharedLink: boolean;
  notificationEvents: string;
  globalNotificationEmails: string;
  notificationEmails: string;
};

const SelectAssignmentTypeTab: FC = () => {
  const { stepper, updateAssignmentType } = useAssignmentContext();
  const { control, handleSubmit, watch } = useForm<AssignmentTypeFormValues>();
  const intl: IntlShape = useIntl();

  const assignmentType = Number(watch('assignmentType') ?? '1');
  const globalNotificationEmails = watch('globalNotificationEmails');
  const notificationEmails = watch('notificationEmails');

  return (
    <Fragment>
      <Form>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              shouldUnregister
              name="assignmentType"
              defaultValue={1}
              render={({ field, fieldState }) => (
                <RadioButtonInput
                  field={field}
                  fieldstate={fieldState}
                  radioButtons={[
                    {
                      label: <FormattedMessage id={'routes.assignmentByDateAndTime'} />,
                      value: 1
                    },
                    { label: <FormattedMessage id={'routes.assignmentBySchedule'} />, value: 2 }
                  ]}
                />
              )}
            />
          </Col>
        </Row>
        <hr />
        {assignmentType == 1 && (
          <>
            <Row>
              <Col sm="12">
                <Controller
                  control={control}
                  shouldUnregister
                  name="alreadyLeftTheOrigin"
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <RadioButtonInput
                      field={field}
                      fieldstate={fieldState}
                      required
                      label={<FormattedMessage id={'routes.alreadyLeftTheOrigin'} />}
                      radioButtons={[
                        {
                          label: `${intl.formatMessage({ id: 'common.yes' })} (${intl.formatMessage(
                            {
                              id: 'routes.monitoredInfo'
                            }
                          )})`,
                          value: 3
                        },
                        {
                          label: <FormattedMessage id={'common.no'} />,
                          value: 4
                        }
                      ]}
                    />
                  )}
                />
              </Col>
            </Row>
            <hr />
          </>
        )}
        <Row>
          <Col sm="12" className={'mb-1'}>
            <h3>
              <FormattedMessage id={'routes.globalNotificationEmails'} />
            </h3>
          </Col>
          <Col sm="6">
            <ContactsDropdown
              control={control}
              name={'globalNotificationEmails'}
              id={'globalNotificationEmailsInput'}
            />
          </Col>
          <Col sm="6">
            {globalNotificationEmails?.length > 0 && (
              <Controller
                control={control}
                shouldUnregister
                name={'globalNotificationFrec'}
                defaultValue={'30'}
                render={({ field, fieldState }) => (
                  <DropdownInput
                    field={field}
                    fieldstate={fieldState}
                    id={'globalNotificationFrecInput'}
                    options={globalNotificationFrecs.map((option) => ({
                      ...option,
                      label: intl.formatMessage({ id: option.label }),
                      label_intl: intl.formatMessage({ id: option.label })
                    }))}
                    label={<FormattedMessage id={'routes.globalNotificationFrec'} />}
                  />
                )}
              />
            )}
          </Col>
          <Col sm="6">
            <Controller
              control={control}
              shouldUnregister
              name={'useSharedLink'}
              render={({ field }) => (
                <SwitchInput
                  field={field}
                  label={<FormattedMessage id={'routes.useSharedLink'} />}
                />
              )}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col sm="12" className={'mb-1'}>
            <h3>
              <FormattedMessage id={'routes.notificationEmails'} />
            </h3>
          </Col>
          <Col sm="6">
            <ContactsDropdown
              control={control}
              name={'notificationEmails'}
              id={'notificationEmailsInput'}
            />
          </Col>
          <Col sm="6">
            {notificationEmails?.length > 0 && (
              <Controller
                control={control}
                shouldUnregister
                name={'notificationEvents'}
                render={({ field, fieldState }) => (
                  <MultiSelectInput
                    field={field}
                    fieldstate={fieldState}
                    id={'notificationEventsInput'}
                    options={routesEvents.map((option) => ({
                      ...option,
                      label: intl.formatMessage({ id: option.label }),
                      label_intl: intl.formatMessage({ id: option.label })
                    }))}
                    label={<FormattedMessage id={'routes.notificationEvents'} />}
                  />
                )}
              />
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-between">
          <Button.Ripple color="secondary" className="btn-prev" outline disabled>
            <ArrowLeft size={14} className="align-middle mr-sm-25 mr-0"></ArrowLeft>
            <span className="align-middle d-sm-inline-block d-none">
              <FormattedMessage id={'common.previous'} />
            </span>
          </Button.Ripple>
          <Button.Ripple
            /*type="submit"*/
            color="primary"
            className="btn-next"
            onClick={handleSubmit((values) => {
              updateAssignmentType({
                assignmentType: Number(values.assignmentType),
                // @ts-ignore parse string to number
                alreadyLeftTheOrigin: Number(values.alreadyLeftTheOrigin),
                globalNotificationFrec: values.globalNotificationFrec,
                useSharedLink: values.useSharedLink,
                notificationEvents: values.notificationEvents,
                globalNotificationEmails: values.globalNotificationEmails,
                notificationEmails: values.notificationEmails
              });
              stepper?.next();
            })}
          >
            <span className="align-middle d-sm-inline-block d-none">
              <FormattedMessage id={'common.next'} />
            </span>
            <ArrowRight size={14} className="align-middle ml-sm-25 ml-0"></ArrowRight>
          </Button.Ripple>
        </div>
      </Form>
    </Fragment>
  );
};

export default SelectAssignmentTypeTab;
