/* -------------------------------------------------------------------------- */
/*                        Schema Execution Routes                             */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const routeExecution = new schema.Entity(
  'routesExecutions',
  {},
  { idAttribute: 'routeAssignmentId' }
);
export const routesExecutions = new schema.Array(routeExecution);
