import Icon from '../../utility/icomoon';
import paths from 'views/screens/Reports/paths';
import permissions from 'views/screens/Reports/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'reports',
    title: 'reports.reports',
    icon: <Icon size={20} icon="noun_report_556224" />,
    iconName: 'noun_report_556224',
    permissions: [
      permissions.systemCommunicationsDiagReport,
      permissions.systemNotificationsReport,
      permissions.accommodationChargesReport,
      permissions.mileageReport,
      permissions.hourMetersReport,
      permissions.consolidatedUnitActivityReport,
      permissions.consolidatedEventActivityReport,
      permissions.fuelSensorReport,
      permissions.consolidatedUnitMainStateReport,
      permissions.parkedGeofenceReport,
      permissions.administrativeReports,
      permissions.byUnitReports,
      permissions.consolidatedReports,
      permissions.listReports,
      permissions.runningTimeReport,
      permissions.unitActivity,
      permissions.byUnitReport,
      permissions.consumptionAPIReport,
      permissions.readWebEvents,
      permissions.scheduledReports
    ],
    navLink: paths.reportList,
    screenId: screens.REPORTS
    //children: [...scheduledReports, ...consolidated, ...byUnit, ...system]
  }
];
