export const SERVICECLASSES_FETCH_STARTED = 'SERVICECLASSES_FETCH_STARTED';
export const SERVICECLASSES_FETCH_COMPLETED = 'SERVICECLASSES_FETCH_COMPLETED';
export const SERVICECLASSES_FETCH_FAILED = 'SERVICECLASSES_FETCH_FAILED';

export const SERVICECLASSES_PARTIAL_FETCH_STARTED = 'SERVICECLASSES_PARTIAL_FETCH_STARTED';
export const SERVICECLASSES_PARTIAL_FETCH_COMPLETED = 'SERVICECLASSES_PARTIAL_FETCH_COMPLETED';
export const SERVICECLASSES_PARTIAL_FETCH_FAILED = 'SERVICECLASSES_PARTIAL_FETCH_FAILED';

export const SERVICECLASS_FETCH_STARTED = 'SERVICECLASS_FETCH_STARTED';
export const SERVICECLASS_FETCH_COMPLETED = 'SERVICECLASS_FETCH_COMPLETED';
export const SERVICECLASS_FETCH_FAILED = 'SERVICECLASS_FETCH_FAILED';

export const SERVICECLASS_ADD_STARTED = 'SERVICECLASS_ADD_STARTED';
export const SERVICECLASS_ADD_COMPLETED = 'SERVICECLASS_ADD_COMPLETED';
export const SERVICECLASS_ADD_FAILED = 'SERVICECLASS_ADD_FAILED';

export const SERVICECLASS_EDIT_STARTED = 'SERVICECLASS_EDIT_STARTED';
export const SERVICECLASS_EDIT_COMPLETED = 'SERVICECLASS_EDIT_COMPLETED';
export const SERVICECLASS_EDIT_FAILED = 'SERVICECLASS_EDIT_FAILED';

export const SERVICECLASS_REMOVE_STARTED = 'SERVICECLASS_REMOVE_STARTED';
export const SERVICECLASS_REMOVE_COMPLETED = 'SERVICECLASS_REMOVE_COMPLETED';
export const SERVICECLASS_REMOVE_FAILED = 'SERVICECLASS_REMOVE_FAILED';

export const SERVICECLASS_SELECTED = 'SERVICECLASS_SELECTED';
export const SERVICECLASSES_ALL_SELECTED = 'SERVICECLASSES_ALL_SELECTED';
export const SERVICECLASS_DESELECTED = 'SERVICECLASS_DESELECTED';
export const SERVICECLASSES_ALL_DESELECTED = 'SERVICECLASSES_ALL_DESELECTED';
