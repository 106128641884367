import * as types from '../types';

export const startFetchingCustomers = (filters = {}) => ({
  type: types.CUSTOMERS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingCustomers = (entities, order, operatorId, organizationId) => ({
  type: types.CUSTOMERS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingCustomers = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.CUSTOMERS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingCustomers = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.CUSTOMERS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingCustomers = (entities, order, operatorId, organizationId) => ({
  type: types.CUSTOMERS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingCustomers = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CUSTOMERS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingCustomer = (customer) => ({
  type: types.CUSTOMER_ADD_STARTED,
  payload: customer
});
export const completeAddingCustomer = (oldId, customer, office) => ({
  type: types.CUSTOMER_ADD_COMPLETED,
  payload: { oldId, customer, office }
});
export const failAddingCustomer = (oldId, error) => ({
  type: types.CUSTOMER_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingCustomer = (customer) => ({
  type: types.CUSTOMER_EDIT_STARTED,
  payload: customer
});
export const completeEditingCustomer = (customer, office) => ({
  type: types.CUSTOMER_EDIT_COMPLETED,
  payload: { customer, office }
});
export const failEditingCustomer = (oldCustomer, error) => ({
  type: types.CUSTOMER_EDIT_FAILED,
  payload: {
    oldCustomer,
    error
  }
});

export const startRemovingCustomer = (customerId) => ({
  type: types.CUSTOMER_REMOVE_STARTED,
  payload: {
    customerId
  }
});
export const completeRemovingCustomer = (customerId) => ({
  type: types.CUSTOMER_REMOVE_COMPLETED,
  payload: {
    customerId
  }
});
export const failRemovingCustomer = (customerId, error) => ({
  type: types.CUSTOMER_REMOVE_FAILED,
  payload: {
    customerId,
    error
  }
});

export const selectCustomer = (customerId) => ({
  type: types.CUSTOMER_SELECTED,
  payload: customerId
});

export const selectAllCustomers = (
  customerIds = [] //List of CustomerIds
) => ({
  type: types.CUSTOMERS_ALL_SELECTED,
  payload: customerIds
});

export const deselectCustomer = (customerId) => ({
  type: types.CUSTOMER_DESELECTED,
  payload: customerId
});

export const deselectAllCustomers = (
  customerIds = [] //List of CustomerIds
) => ({
  type: types.CUSTOMERS_ALL_DESELECTED,
  payload: customerIds
});
