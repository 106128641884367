import * as types from '../types';

export * from './backlog';
export * from './board';
export * from './report';

export const startFetchingJob = (jobId) => ({
  type: types.JOB_FETCH_STARTED,
  payload: { jobId }
});
export const completeFetchingJob = (job) => ({
  type: types.JOB_FETCH_COMPLETED,
  payload: { job }
});
export const failFetchingJob = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.JOB_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
