/* eslint-disable semi */
import { env } from '../../../../env';
import moment from 'moment';
import { Square } from 'react-feather';
import Icon from 'utility/icomoon';
import L from 'leaflet';
import * as authSelectors from '../../../../redux/reducers/auth';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { isNaNOrNullable } from 'utility/Utils';
import { getPerformanceColor, getPerformanceTitle } from 'views/screens/Telemetry/utils';
import { useRef } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { IntlShape } from 'react-intl';
import { UnitsRoutesAssignment } from '../views/RoutesAssignUnitScreen/SelectUnitsAndRoutesTab';
import { AssignedUnit } from '../hooks/useGetAssignedUnits';
import { AssignmentByDate } from '../views/RoutesAssignUnitScreen/ReviewAssignmentByDateTab';
import { CommonValuesFormValues } from '../views/RoutesAssignUnitScreen/SelectCommonValuesTab';
import { AssignmentBySchedule } from '../views/RoutesAssignUnitScreen/ReviewAssignmentByScheduleTab';
import {
  AssignmentTypeFormValues,
  AssignmentTypes
} from '../views/RoutesAssignUnitScreen/SelectAssignmentTypeTab';
import { Schedule } from 'views/screens/Schedules/views/listTrigger';

export const getRouteExecutionInformation = (state, routeExecution) => {
  const intl = appIntl();
  if (routeExecution) {
    let routeStatusLabel = '';
    let routeStatusLabelInfo = '';
    switch (routeExecution.routeStatus) {
      case 1:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.atOrigin'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.atOrigin'
        });
        break;
      case 2:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.atDestination'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.atDestination'
        });
        break;
      case 3:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.onRoute'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.onRoute'
        });
        break;
      case 5:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.paused'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.paused'
        });
        break;
      case 6:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.finalized'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.finalized'
        });
        break;
      case 7:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.offRoute'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.offRoute'
        });
        break;
      case 8:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.canceled'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.canceled'
        });
        break;
      case 10:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.atCheckpoint'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.atCheckpoint'
        });
        break;
      case 15:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.eao'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.eaoInfo'
        });
        break;
      case 16:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.unauthorizedReturn'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.unauthorizedReturn'
        });
        break;
      case 18:
        routeStatusLabel = intl.formatMessage({
          id: 'routes.singleScheduled'
        });
        routeStatusLabelInfo = intl.formatMessage({
          id: 'routes.singleScheduled'
        });
        break;
      default:
        routeStatusLabel = '';
        routeStatusLabelInfo = '';
        break;
    }

    const parsers = authSelectors.getParsers(state);
    const {
      convertKilometersPerHourToAuthUserSpeedSystem,
      convertKilometersToAuthUserDistanceSystem,
      convertMetersToAuthUserDistanceSystem,
      convertLitersToAuthUserCapacitySystem,
      convertKilometersPerLiterToAuthUserFuelSystem
    } = parsers;

    return {
      ...routeExecution,
      routeStatusLabel,
      routeStatusLabelInfo,
      isDeviceOpenLabel: intl.formatMessage({
        id: routeExecution.isDeviceOpen
          ? 'trackingUnits.sat.open'
          : routeExecution.isDeviceOpen == null
          ? 'trackingUnits.sat.unknown'
          : 'trackingUnits.sat.closed'
      }),
      stopStatus: !routeExecution.stopStatus,
      speedLabel: convertKilometersPerHourToAuthUserSpeedSystem(routeExecution.speed),
      routeProgressLabel: Math.round(routeExecution.routeProgress) + '%',
      distanceToCheckpointLabel:
        Math.round(
          convertMetersToAuthUserDistanceSystem(routeExecution.distanceToCheckpoint || 0)
        ) +
        ' ' +
        authSelectors.getAuthSystems(state).distance,
      distanceLabel:
        Math.round(convertMetersToAuthUserDistanceSystem(routeExecution.distance || 0)) +
        ' ' +
        authSelectors.getAuthSystems(state).distance,
      fuelConsumption: convertLitersToAuthUserCapacitySystem(routeExecution.fuelConsumption || 0),
      expectedPerformance: convertKilometersPerLiterToAuthUserFuelSystem(
        routeExecution.expectedPerformance || 0
      ),
      performanceOnRoute: convertKilometersPerLiterToAuthUserFuelSystem(
        routeExecution.performanceOnRoute || 0
      ),
      distanceProgress: convertKilometersToAuthUserDistanceSystem(
        routeExecution.distanceProgress || 0
      )
    };
  } else return undefined;
};

export const completionTypes = [
  {
    value: 0,
    label: 'routes.finalizeManual'
  },
  {
    value: 1,
    label: 'routes.finalize15mins'
  },
  {
    value: 2,
    label: 'routes.finalizeInmediately'
  }
];

export const globalNotificationFrecs = [
  {
    value: '15',
    label: 'routes.frecuency15mins'
  },
  {
    value: '30',
    label: 'routes.frecuency30mins'
  },
  {
    value: '60',
    label: 'routes.frecuency60mins'
  }
];

export const startTimeWindow = [
  {
    value: '0', // Start time window of 0 seconds (immediate start)
    label: '0 ' // Label for the start time window (in minutes)
  },
  {
    value: '900', // Start time window of 900 seconds (15 minutes)
    label: '15 ' // Label for the start time window (in minutes)
  },
  {
    value: '1800', // Start time window of 1800 seconds (30 minutes)
    label: '30 ' // Label for the start time window (in minutes)
  },
  {
    value: '2700', // Start time window of 2700 seconds (45 minutes)
    label: '45 ' // Label for the start time window (in minutes)
  },
  {
    value: '3600', // Start time window of 3600 seconds (60 minutes)
    label: '60 ' // Label for the start time window (in minutes)
  },
  {
    value: '7200', // Start time window of 7200 seconds (120 minutes)
    label: '120 ' // Label for the start time window (in minutes)
  },
  {
    value: '10800', // Start time window of 10800 seconds (180 minutes)
    label: '180 ' // Label for the start time window (in minutes)
  }
];

export const routesEvents = [
  { value: '1', label: 'routes.atOrigin' },
  { value: '3', label: 'routes.onRoute' },
  { value: '2', label: 'routes.singleCompleted' },
  { value: '6', label: 'routes.singleFinalized' },
  { value: '7', label: 'routes.offRoute' },
  { value: '4', label: 'routes.stoppedOnRoute' },
  { value: '9', label: 'routes.speeding' },
  { value: '10', label: 'routes.atCheckpoint' },
  { value: '16', label: 'routes.unauthorizedReturn' },
  { value: '18', label: 'routes.assigned' }
];

export const routesTimelineEvents = [
  { value: '1', label: 'routes.atOrigin', color: '#44abff' },
  { value: '2', label: 'routes.singleCompleted', color: '#00d57e' },
  { value: '3', label: 'routes.onRoute', color: '#00ead5' },
  { value: '4', label: 'routes.stoppedOnRoute', color: '#ffc737' },
  { value: '5', label: 'routes.paused', color: '#e3e3e4' },
  { value: '6', label: 'routes.singleFinalized', color: '#2ed573' },
  { value: '7', label: 'routes.offRoute', color: '#ff4459' },
  { value: '8', label: 'routes.canceled', color: '#ff4757' },
  { value: '9', label: 'routes.speeding', color: '#ff7a00' }, //Pendiente
  { value: '10', label: 'routes.atCheckpoint', color: '#7669e8' },
  { value: '15', label: 'routes.eao', color: '#490002' },
  { value: '16', label: 'routes.unauthorizedReturn', color: '#ba0000' },
  { value: '18', label: 'routes.assigned', color: '' } //Pendiente
];

export const routesCheckpointsIcons = [
  {
    value: '1',
    label: 'routes.checkpoint',
    icon: <Square size={20} />
  },
  {
    value: '2',
    label: 'routes.gate',
    icon: <Icon size={20} icon="Gate" />,
    iconTxt: 'Gate'
  },
  {
    value: '3',
    label: 'routes.patrol',
    icon: <Icon size={20} icon="Patrol" />,
    iconTxt: 'Patrol'
  },
  {
    value: '4',
    label: 'routes.motorcycle',
    icon: <Icon size={20} icon="Motorcycle" />,
    iconTxt: 'Motorcycle'
  },
  {
    value: '5',
    label: 'routes.frontier',
    icon: <Icon size={20} icon="Frontier" />,
    iconTxt: 'Frontier'
  },
  {
    value: '6',
    label: 'routes.carLot',
    icon: <Icon size={20} icon="CarLot" />,
    iconTxt: 'CarLot'
  }
];

export const getRouteStatusColorClass = (routeStatus: number): string => {
  if (isNaNOrNullable(routeStatus)) return '';
  switch (routeStatus) {
    case -1: //Comentario
      return 'light-comment';
    case 1:
      return 'light-onOrigin';
    case 2:
      return 'light-onDestination';
    case 3:
      return 'light-onRoute';
    case 4:
      return 'light-stoppedOnRoute';
    case 5:
      return 'light-route-paused';
    case 6:
      return 'light-route-finalized';
    case 7:
      return 'light-offRoute';
    case 8:
      return 'light-route-canceled';
    case 9:
      return 'light-route-speeding';
    case 10:
      return 'light-atCheckpoint';
    case 15:
      return 'light-eao';
    case 16:
      return 'light-unauthorizedReturn';
    case 18:
      return 'light-scheduled';
  }

  return '';
};

export const getMarker = (type, index, iconId, latLng) => {
  return L.marker(latLng, {
    icon: getIcon(type, index, iconId)
  });
};

export const getIcon = (
  type,
  index,
  iconId,
  checkpointClassDefaultColor = '',
  checkpointTextDefaultColor = ''
) => {
  return L.divIcon({
    className: 'custom-div-icon',
    html:
      type === 1
        ? `<div  class='marker-pin' style='background-color:${
            checkpointClassDefaultColor || '$marker-color'
          }'></div><i class='icon-Origin' style='width: 20px;
            font-size: 20px;color:${checkpointTextDefaultColor || 'white'};'></i>`
        : type === 3
        ? `<div  class='marker-pin' style='background-color:${
            checkpointClassDefaultColor || '$marker-color'
          }'></div><i class='icon-Destination' style='width: 20px;
            font-size: 20px;color:${checkpointTextDefaultColor || 'white'};'></i>`
        : iconId == null || iconId == 1 || iconId == 0
        ? `<div  class='marker-pin' style='background-color:${
            checkpointClassDefaultColor || '$marker-color'
          }'></div><i style='width: 100%;
            font-size: 16px;color:${
              checkpointTextDefaultColor || 'white'
            };padding-right:1px'>${index}</i>`
        : `<div  class='marker-pin' style='background-color:${
            checkpointClassDefaultColor || '$marker-color'
          }'></div><i class='icon-${routesCheckpointsIcons[iconId - 1].iconTxt}' style='width: 20px;
            font-size: 20px;color:${checkpointTextDefaultColor || 'white'};'></i>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42],
    popupAnchor: [0, -35]
  });
};

export const getRouteBounds = (route) => {
  if (
    route.checkpoints &&
    route.checkpoints.filter((chk) => chk.latitude != null && chk.longitude != null).length > 0
  ) {
    var latlngPolylines: any[] = [];
    if (route.checkpoints.filter((chk) => chk.segmentPath).length > 0)
      route.checkpoints.map(
        (chk) =>
          chk.segmentPath &&
          chk.segmentPath.map((path) =>
            latlngPolylines.push(L.latLng(path.latitude, path.longitude))
          )
      );

    return [
      ...route.checkpoints
        .filter((chk) => chk.latitude != null && chk.longitude != null)
        .map((chk) => L.latLng(chk.latitude, chk.longitude)),
      ...latlngPolylines
    ];
  } else return [];
};
export const getImportedRouteStatusColorClass = (success) => {
  return success ? 'light-success' : 'light-danger';
};

type RouteStatus = {
  expectedPerformance: number;
  performanceOnRoute: number;
};
export function performanceTemplate(args: RouteStatus, performanceUnit?: string) {
  const intl: IntlShape = appIntl();

  const performanceRanges = [0, 80, 90, 110, 200];
  const expectedPerformance = args['expectedPerformance'];
  const value = args['performanceOnRoute'] || 0;
  const percentage = (value / expectedPerformance) * 100;

  const color = getPerformanceColor(percentage, expectedPerformance, performanceRanges);
  const translation = getPerformanceTitle(percentage, performanceRanges);

  const valueNodeRef = useRef<any>(undefined);

  return (
    <p className="d-flex align-items-center justify-content-center">
      <span
        className="font-weight-bold m-0 cursor-default"
        ref={valueNodeRef}
        style={{ color: color }}
      >
        {value?.toFixed(2) ?? ''}
      </span>
      {performanceUnit ? <span style={{ marginLeft: '4px' }}>{performanceUnit}</span> : ''}
      <UncontrolledTooltip placement={'top'} target={valueNodeRef}>
        {`${translation ? intl.formatMessage({ id: translation }) : ''} - ${percentage.toFixed(
          2
        )}%`}
      </UncontrolledTooltip>
      {/* <Icon icon={'PreventiveNotice'} className={'mr-25'} size={15} color={'primary'} /> */}
    </p>
  );
}

export interface ValidationError {
  type?: string;
  payload?: any;
  level?: string;
}

export const VALIDATION_ERROR_TYPES = {
  ROUTE_ALREADY_ASSIGNED_AND_ACTIVE_ON_DATABASE: 'routes.routeAlreadyAssignedAndActiveOnDatabase',
  ROUTE_ALREADY_ASSIGNED_AT_SAME_HOUR_THAT_ANOTHER_ON_DATABASE:
    'routes.routeAlreadyAssignedAtSameHourThatAnotherOnDatabase',
  ROUTE_ALREADY_ASSIGNED_AT_SAME_HOUR_THAT_ANOTHER_ON_STATE:
    'routes.routeAlreadyAssignedAtSameHourThatAnotherOnState',
  ROUTE_ALREADY_ASSIGNED_ON_STATE: 'routes.routeAlreadyAssignedOnState'
};

export const getValidationErrorIcon = (errors?: ValidationError[]) => {
  if (!errors || errors.length === 0) return 'CheckCircle';
  if (errors.find((error) => error.level === 'error')) return 'AlertCircle';
  return 'PreventiveNotice';
};

export const getValidationErrorColor = (errors?: ValidationError[]) => {
  if (!errors || errors.length === 0) return 'success';
  if (errors.find((error) => error.level === 'error')) return 'danger';
  return 'warning';
};

const validateDateTimesDiff = (
  firstDate: moment.Moment,
  secondDate: moment.Moment,
  diffInMilliseconds?: string
) => {
  const diffInMillisecondsAsInt = parseInt(diffInMilliseconds || '0');
  return (
    firstDate.isSame(secondDate, 'day') &&
    Math.abs(firstDate.diff(secondDate, 'ms')) < diffInMillisecondsAsInt
  );
};

// Validator that needs assigned units, and then returns a function that receives the unitsRoutesAssignment to validate
export const duplicatedUnitRouteAssignmentValidator =
  (
    assignedUnitsFromDatabase: AssignedUnit[],
    commonValues: Partial<CommonValuesFormValues>,
    assignmentType: Partial<AssignmentTypeFormValues>,
    schedule: Schedule
  ) =>
  (
    unitsRoutesAssignmentsFromState:
      | UnitsRoutesAssignment[]
      | Partial<AssignmentByDate>[]
      | Partial<AssignmentBySchedule>[],
    unitsRoutesAssignment:
      | UnitsRoutesAssignment
      | Partial<AssignmentByDate>
      | Partial<AssignmentBySchedule>
  ): ValidationError[] => {
    const errors: ValidationError[] = [];
    const { startDate } = commonValues;
    const { unitId, routeId, correlative, routeName } = unitsRoutesAssignment;
    const { assignmentType: assignmentTypeNumber } = assignmentType;

    // Helper function to check for duplicate assignments at the same hour
    // TODO: In the future, we must provide a smart solution to calculate the min diff time for assignments by unit (data analysis)
    const hasAssignmentAtSameHourOrScheduleNextRun = (
      existingAssignments:
        | UnitsRoutesAssignment[]
        | Partial<AssignmentByDate>[]
        | Partial<AssignmentBySchedule>[]
    ) => {
      let comparePredicate: (existingAssignment: any) => boolean = () => false;
      if (assignmentTypeNumber === AssignmentTypes.ByDate) {
        const stepperStartDate = moment(startDate, 'YYYY-MM-DD HH:mm');
        const assignmentStartDate = moment(unitsRoutesAssignment.startDate, 'YYYY-MM-DDTHH:mm:ss');
        // First remove current assign on filter, and then check if there is any other assignment with the same hour
        comparePredicate = (existingAssignment) => {
          const existingAssignmentStartDate = moment(
            existingAssignment.startDate,
            'YYYY-MM-DDTHH:mm:ss'
          );
          return existingAssignment.startDate && unitsRoutesAssignment.startDate
            ? validateDateTimesDiff(
                unitsRoutesAssignment.startDate ? assignmentStartDate : stepperStartDate,
                existingAssignmentStartDate,
                env.REACT_APP_MIN_VALID_DIFF_TIME_BETWEEN_UNITS_ROUTES_ASSIGNMENT
              )
            : true;
        };
      } else {
        const stepperNextRunDate = moment(schedule.nextRunDate, 'YYYY-MM-DD HH:mm');
        // First remove current assign on filter, and then check if there is any other assignment with the same next run date
        comparePredicate = (existingAssignment) => {
          const existingAssignmentNextRunDate = moment(
            'nextRunDate' in existingAssignment ? existingAssignment.nextRunDate : '',
            'YYYY-MM-DDTHH:mm:ss'
          );
          const unitRoutAssignmentNextRunDate = moment(
            'scheduleNextRunDate' in unitsRoutesAssignment
              ? unitsRoutesAssignment.scheduleNextRunDate
              : '',
            'YYYY-MM-DDTHH:mm:ss'
          );
          if (
            'scheduleNextRunDate' in unitsRoutesAssignment &&
            unitsRoutesAssignment.scheduleNextRunDate
          ) {
            return validateDateTimesDiff(
              'scheduleNextRunDate' in unitsRoutesAssignment
                ? unitRoutAssignmentNextRunDate
                : stepperNextRunDate,
              existingAssignmentNextRunDate,
              env.REACT_APP_MIN_VALID_DIFF_TIME_BETWEEN_UNITS_ROUTES_ASSIGNMENT
            );
          }
          return true;
        };
      }

      // Remember: existingAssignments can be data from database or from state!
      return existingAssignments
        .filter(
          (existingAssignment) =>
            existingAssignment.unitId === unitId &&
            (existingAssignment.correlative ? existingAssignment.correlative !== correlative : true)
        )
        .some(comparePredicate);
    };

    // CASE 1: DATABASE DATA VALIDATION
    // Note that database data retrieved is filtered by active units/routes, so there is no need to validate startDate (at this moment)
    if (assignedUnitsFromDatabase.length > 0) {
      // CASE 1.1: unit has been already assigned to that route and has an active status
      const hasSameAssignmentFoundOnDatabase = assignedUnitsFromDatabase.some(
        (unitRouteAssignment) =>
          unitRouteAssignment.unitId === unitId &&
          unitRouteAssignment.routeId === routeId &&
          hasAssignmentAtSameHourOrScheduleNextRun(assignedUnitsFromDatabase)
      );

      if (hasSameAssignmentFoundOnDatabase) {
        errors.push({
          type: VALIDATION_ERROR_TYPES.ROUTE_ALREADY_ASSIGNED_AND_ACTIVE_ON_DATABASE,
          payload: { route: routeName },
          level: 'error'
        });
        return errors;
      }

      // CASE 1.2: a route already assigned to the unit with the same hour as the new assignment
      const hasAssignmentFoundAtSameHourOnDatabase =
        hasAssignmentAtSameHourOrScheduleNextRun(assignedUnitsFromDatabase);
      if (hasAssignmentFoundAtSameHourOnDatabase) {
        errors.push({
          type: VALIDATION_ERROR_TYPES.ROUTE_ALREADY_ASSIGNED_AT_SAME_HOUR_THAT_ANOTHER_ON_DATABASE
        });
        return errors;
      }
    }

    // CASE 2: HOOK FORM STATE DATA VALIDATION
    // There is no need to validate startDate at this moment
    if (unitsRoutesAssignmentsFromState.length > 0) {
      // CASE 2.1: unit has been already assigned to the same route and has an active status
      const hasSameAssignmentFoundOnState = unitsRoutesAssignmentsFromState.some(
        (unitRouteAssignment) =>
          unitRouteAssignment.unitId === unitId &&
          unitRouteAssignment.routeId === routeId &&
          unitRouteAssignment.correlative !== correlative
      );
      if (hasSameAssignmentFoundOnState) {
        errors.push({ type: VALIDATION_ERROR_TYPES.ROUTE_ALREADY_ASSIGNED_ON_STATE });
        return errors;
      }

      // CASE 2.2: a route already assigned to the unit with the same hour as the new assignment
      const hasAssignmentFoundAtSameHourOnState = hasAssignmentAtSameHourOrScheduleNextRun(
        unitsRoutesAssignmentsFromState
      );
      if (hasAssignmentFoundAtSameHourOnState) {
        errors.push({
          type: VALIDATION_ERROR_TYPES.ROUTE_ALREADY_ASSIGNED_AT_SAME_HOUR_THAT_ANOTHER_ON_STATE
        });
        return errors;
      }
    }

    return errors;
  };
