/* -------------------------------------------------------------------------- */
/*                            Schema Device Actions                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const deviceAction = new schema.Entity(
  'deviceActions',
  {},
  { idAttribute: 'deviceActionId' }
);
export const deviceActions = new schema.Array(deviceAction);
