/* -------------------------------------------------------------------------- */
/*                              Schema Subdivisions                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const maintenanceLine = new schema.Entity(
  'maintenanceLines',
  {},
  { idAttribute: 'maintenanceLineId' }
);
export const maintenanceLines = new schema.Array(maintenanceLine);
