import * as types from '../../../types/static-catalogs/countries';

export const startFetchingCountries = (filters = {}) => ({
  type: types.COUNTRIES_FETCH_STARTED,
  payload: { filters }
});
export const completeFetchingCountries = (entities, order) => ({
  type: types.COUNTRIES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingCountries = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.COUNTRIES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingCountries = (filters = {}) => ({
  type: types.COUNTRIES_PARTIAL_FETCH_STARTED,
  payload: { filters }
});
export const completePartialFetchingCountries = (entities, order) => ({
  type: types.COUNTRIES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingCountries = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.COUNTRIES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const selectModel = (modelId) => ({
  type: types.COUNTRY_SELECTED,
  payload: modelId
});

export const deselectModel = (modelId) => ({
  type: types.COUNTRY_DESELECTED,
  payload: modelId
});

export const deselectAllCountries = (
  modelIds = [] //List of ModelIds
) => ({
  type: types.COUNTRIES_ALL_DESELECTED,
  payload: modelIds
});
