import { AxiosRequestConfig, AxiosResponse } from 'axios';
// import { actions } from 'app/Layout/slice';
import { HttpWebtrack } from '../index';

const httpWebtrack = new HttpWebtrack();

const methods = ['POST', 'PUT', 'DELETE'];

export const loadingOverlyaInterceptor = (store: any) => {
  httpWebtrack.service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (methods.some((x) => x === config.method?.toUpperCase())) {
        // store.dispatch(actions.showSpinner());
      }
      return config;
    },
    (error: any) => {
      // store.dispatch(actions.hideSpinner());
      return Promise.reject(error);
    }
  );

  httpWebtrack.service.interceptors.response.use(
    (axiosResponse: AxiosResponse) => {
      if (methods.some((x) => x === axiosResponse.config.method?.toUpperCase())) {
        //store.dispatch(actions.hideSpinner());
      }

      return axiosResponse;
    },
    (error: any) => {
      //store.dispatch(actions.hideSpinner());
      return Promise.reject(error);
    }
  );

  httpWebtrack.service.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (methods.some((x) => x === config.method?.toUpperCase())) {
        //store.dispatch(actions.showSpinner());
      }
      return config;
    },
    (error: any) => {
      //store.dispatch(actions.hideSpinner());
      return Promise.reject(error);
    }
  );

  httpWebtrack.service.interceptors.response.use(
    (axiosResponse: AxiosResponse) => {
      if (methods.some((x) => x === axiosResponse.config.method?.toUpperCase())) {
        //store.dispatch(actions.hideSpinner());
      }

      return axiosResponse;
    },
    (error: any) => {
      //store.dispatch(actions.hideSpinner());
      return Promise.reject(error);
    }
  );
};
