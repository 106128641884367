import * as types from '../../types/workplansReport';

export const startFetchingWorkplansReport = (filters = {}) => ({
  type: types.WORKPLANS_REPORT_FETCH_STARTED,
  payload: filters
});
export const completeFetchingWorkplansReport = (
  entities,
  order,
  indicators,
  unitsEntities,
  unitsOrder,
  locationsEntities,
  locationsOrder
) => ({
  type: types.WORKPLANS_REPORT_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    indicators,
    unitsEntities,
    unitsOrder,
    locationsEntities,
    locationsOrder
  }
});
export const failFetchingWorkplansReport = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_REPORT_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
