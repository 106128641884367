/* eslint-disable no-param-reassign */
import { endDateAdjustment } from './interceptor-utils';

export const redirectTo = (document: any, path: string) => {
  document.location = path;
};

export const refreshTokenInterceptor = (failedRequest: any, session: any, store: any) => {
  //aquí iría el refresh token luego de que el backend responde con token vencido
};

export const authTokenInterceptor = async (config: any, session: any) => {
  if (session?.token) {
    config.headers.Authorization = `Bearer ${session?.token}`;
  }

  return { ...config, data: endDateAdjustment(config?.data) };
};
