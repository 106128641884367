const permissions = {
  addTicket: 'FT001',
  importTicket: 'FT002',
  byUnitReport: 'FT003',
  consolidatedReport: 'FT004',
  addLocation: 'LO001',
  recordMultiple: 'FT005'
};

export default permissions;
