import * as types from '../types';

export const startFetchingMonitoringUsers = (filters = {}) => ({
  type: types.MONITORING_USERS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingMonitoringUsers = (entities, order, operatorId, organizationId) => ({
  type: types.MONITORING_USERS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingMonitoringUsers = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.MONITORING_USERS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingMonitoringUsers = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.MONITORING_USERS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingMonitoringUsers = (
  entities,
  order,
  operatorId,
  organizationId
) => ({
  type: types.MONITORING_USERS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingMonitoringUsers = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.MONITORING_USERS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingMonitoringUser = (monitoringUser) => ({
  type: types.MONITORING_USER_ADD_STARTED,
  payload: monitoringUser
});
export const completeAddingMonitoringUser = (oldId, monitoringUser) => ({
  type: types.MONITORING_USER_ADD_COMPLETED,
  payload: { oldId, monitoringUser }
});
export const failAddingMonitoringUser = (oldId, error) => ({
  type: types.MONITORING_USER_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingMonitoringUser = (monitoringUser) => ({
  type: types.MONITORING_USER_EDIT_STARTED,
  payload: monitoringUser
});
export const completeEditingMonitoringUser = (oldId, monitoringUser) => ({
  type: types.MONITORING_USER_EDIT_COMPLETED,
  payload: { oldId, monitoringUser }
});
export const failEditingMonitoringUser = (oldMonitoringUser, error) => ({
  type: types.MONITORING_USER_EDIT_FAILED,
  payload: {
    oldMonitoringUser,
    error
  }
});

export const startRemovingMonitoringUser = (users, usersName) => ({
  type: types.MONITORING_USER_REMOVE_STARTED,
  payload: {
    users,
    usersName
  }
});
export const completeRemovingMonitoringUser = (users, usersName) => ({
  type: types.MONITORING_USER_REMOVE_COMPLETED,
  payload: {
    users,
    usersName
  }
});
export const failRemovingMonitoringUser = (users, error) => ({
  type: types.MONITORING_USER_REMOVE_FAILED,
  payload: {
    users,
    error
  }
});

export const selectMonitoringUser = (monitoringUserId) => ({
  type: types.MONITORING_USER_SELECTED,
  payload: monitoringUserId
});

export const deselectMonitoringUser = (monitoringUserId) => ({
  type: types.MONITORING_USER_DESELECTED,
  payload: monitoringUserId
});

export const deselectAllMonitoringUsers = (
  monitoringUserIds = [] //List of MonitoringUserIds
) => ({
  type: types.MONITORING_USERS_ALL_DESELECTED,
  payload: monitoringUserIds
});

export const startUpdatingMonitoringUserPassword = (values) => ({
  type: types.MONITORING_USER_UPDATE_PASSWORD_STARTED,
  payload: values
});

export const completeUpdatingMonitoringUserPassword = (values) => ({
  type: types.MONITORING_USER_UPDATE_PASSWORD_COMPLETED,
  payload: values
});

export const failUpdatingMonitoringUserPassword = (error) => ({
  type: types.MONITORING_USER_UPDATE_PASSWORD_FAILED,
  payload: { error }
});

export const startSavingMonitoringUserRolesAndPermissions = (values) => ({
  type: types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_STARTED,
  payload: values
});

export const completeSavingMonitoringUserRolesAndPermissions = (values) => ({
  type: types.MONITORING_USER_SAVE_ROLES_PERMISSIONS_COMPLETED,
  payload: values
});

export const startAssigningScheduleUsers = (userAssignSchedule) => ({
  type: types.USERS_ASSIGN_SCHEDULE_STARTED,
  payload: userAssignSchedule
});

export const completeAssigningScheduleUsers = (userAssignSchedule) => ({
  type: types.USERS_ASSIGN_SCHEDULE_COMPLETED,
  payload: userAssignSchedule
});

export const completeUnassigningScheduleUsers = (userAssignSchedule) => ({
  type: types.USERS_UNASSIGN_SCHEDULE_COMPLETED,
  payload: userAssignSchedule
});

export const startUnassigningScheduleUsers = (userAssignSchedule) => ({
  type: types.USERS_UNASSIGN_SCHEDULE_STARTED,
  payload: userAssignSchedule
});
