import { useEffect, useState } from 'react';
import L, { TileLayer } from 'leaflet';
import 'leaflet.gridlayer.googlemutant';
import 'leaflet-plugins/layer/tile/Bing'; //https://github.com/digidem/leaflet-bing-layer
import 'leaflet-providers';
import { useSelector } from 'react-redux';
import * as authSelectors from '../../../../../redux/reducers/auth';
import { useScript } from '../../utils';
import { useLeafletContext } from '@react-leaflet/core';

/* -------------------------------------------------------------------------- */
/*                     MapBaseLayers given User Properties                    */
/* -------------------------------------------------------------------------- */
export interface MapBaseLayersOptions {
  forceUseMapquestMaps?: boolean;
  forceUseGoogleMaps?: boolean;
  forceUseHereMaps?: boolean;
  forceUseBingMaps?: boolean;
  position: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
  listenMapControlChanges?: any[];
}

const MapBaseLayers = (props: MapBaseLayersOptions) => {
  const {
    forceUseMapquestMaps,
    forceUseGoogleMaps,
    forceUseHereMaps,
    forceUseBingMaps,
    position,
    listenMapControlChanges
  } = props;
  const user = useSelector(authSelectors.getAuthUser);
  const context = useLeafletContext();

  //Initialize mapquest and google maps
  //We get apikeys from usersession
  useScript(
    `https://www.mapquestapi.com/sdk/leaflet/v2.2/mq-map.js?key=${user.mapquestConsumerKey}`
  );
  useScript(
    `https://maps.googleapis.com/maps/api/js?key=${user.googleMapsApiKey}&libraries=places`
  );

  //Initialize Maps
  useEffect(() => {
    let osmmap: null | TileLayer = null;
    let gmRoadmap = null;
    let gmHybrid = null;
    let gmSatellite = null;
    let gmTerrain = null;
    let gmTraffic = null;
    let bing = null;
    let bing_satelite = null;
    let bing_hibrido = null;
    let here_layer = null;
    let here_satelite_layer = null;
    let mqOsm = null;
    let mqHybrid = null;
    let mqSatellite = null;
    let mqDark = null;
    let mqLight = null;
    let mapTilerBasic = null;
    let mapTilerLight = null;
    let mapTilerDark = null;

    const useMapquestMaps =
      //@ts-ignore MapQuest
      typeof MQ !== 'undefined' && (user.useMapquestMaps == 1 || forceUseMapquestMaps);
    const useGoogleMaps = user.useGoogleMaps == 1 || forceUseGoogleMaps;
    const useBingMaps = user.useBingMaps == 1 || forceUseBingMaps;
    const useHereMaps = user.useHereMaps == 1 || forceUseHereMaps;
    //Open Street Maps
    let osmurl = '//tile.openstreetmap.org/{z}/{x}/{y}.png';
    let osmAttrib = 'Map data &copy; OpenStreetMap contributors';
    osmmap = new L.TileLayer(osmurl, {
      maxZoom: 19,
      attribution: osmAttrib
    });
    let baseMapLayers = { OpenStreetMap: osmmap };

    //Mapquest Maps
    if (useMapquestMaps) {
      // @ts-ignore MapQuest
      mqOsm = MQ.mapLayer();
      // @ts-ignore MapQuest
      mqHybrid = MQ.hybridLayer();
      // @ts-ignore MapQuest
      mqSatellite = MQ.satelliteLayer();
      // @ts-ignore MapQuest
      mqDark = MQ.darkLayer();
      // @ts-ignore MapQuest
      mqLight = MQ.lightLayer();

      baseMapLayers['MapQuest'] = mqOsm;
      baseMapLayers['MapQuest Hybrid'] = mqHybrid;
      baseMapLayers['MapQuest Satellite'] = mqSatellite;
      baseMapLayers['MapQuest Dark'] = mqDark;
      baseMapLayers['MapQuest Light'] = mqLight;
    }

    //Google Maps
    if (useGoogleMaps) {
      gmRoadmap = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: 'roadmap'
      });
      gmHybrid = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: 'hybrid'
      });
      gmSatellite = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: 'satellite'
      });
      gmTerrain = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: 'terrain'
      });
      gmTraffic = L.gridLayer.googleMutant({
        maxZoom: 24,
        type: 'roadmap'
      });
      if (gmTraffic) gmTraffic.addGoogleLayer('TrafficLayer');

      baseMapLayers['Google Maps'] = gmRoadmap;
      baseMapLayers['Google Maps Road & Satellite'] = gmHybrid;
      baseMapLayers['Google Maps Traffic'] = gmTraffic;
      baseMapLayers['Google Maps Terrain'] = gmTerrain;
      baseMapLayers['Google Maps Satellite'] = gmSatellite;
    }

    // Bing Maps
    const bing_api_key = user.bingMapsApiKey;
    if (useBingMaps) {
      bing_satelite = new L.BingLayer(bing_api_key, {
        type: 'Aerial'
      });
      bing_hibrido = new L.BingLayer(bing_api_key, {
        type: 'AerialWithLabels'
      });
      bing = new L.BingLayer(bing_api_key, {
        type: 'Road'
      });
      baseMapLayers['Bing Maps'] = bing;
      baseMapLayers['Bing Satellite'] = bing_satelite;
      baseMapLayers['Bing Hybrid'] = bing_hibrido;
    }

    //Here maps
    const hereMapsAppId = user.hereMapsAppId;
    const hereMapsAppCode = user.hereMapsAppCode;
    if (useHereMaps) {
      here_layer = L.tileLayer.provider('HERE.normalDay', {
        app_id: hereMapsAppId,
        app_code: hereMapsAppCode
      });
      here_satelite_layer = L.tileLayer.provider('HERE.hybridDay', {
        app_id: hereMapsAppId,
        app_code: hereMapsAppCode
      });

      baseMapLayers['Here Maps'] = here_layer;
      baseMapLayers['Here Maps Satellite'] = here_satelite_layer;
    }

    const layerGroup = new L.Control.Layers(baseMapLayers, {}, { position: position });
    const container = context.layerContainer || context.map;
    container.addLayer(osmmap);
    container.addControl(layerGroup);

    //Check if user has a prefered map
    //vamos a revisar si hay un layer escogido
    var homeLayer = null;
    const homeLayerId = parseInt(user.homeLayer);
    switch (homeLayerId) {
      case 0:
        homeLayer = osmmap;
        break;
      case 1:
        homeLayer = osmmap;
        break;
      case 2:
        homeLayer = useMapquestMaps ? mqOsm : osmmap;
        break;
      case 3:
        homeLayer = useGoogleMaps ? gmRoadmap : useBingMaps ? bing : osmmap;
        break;
      case 4:
        homeLayer = useGoogleMaps ? gmHybrid : useBingMaps ? bing : osmmap;
        break;
      case 5:
        homeLayer = useBingMaps ? bing : osmmap;
        break;
      case 6:
        homeLayer = osmmap;
        break;
      case 7:
        homeLayer = useBingMaps ? bing_satelite : osmmap;
        break;
      case 8:
        homeLayer = useBingMaps ? bing_hibrido : osmmap;
        break;
      case 9:
        homeLayer = useHereMaps ? here_layer : osmmap;
        break;
      case 10:
        homeLayer = useHereMaps ? here_satelite_layer : osmmap;
        break;
      case 11:
        homeLayer = useGoogleMaps ? gmSatellite : useBingMaps ? bing : osmmap;
        break;
      case 12:
        homeLayer = useGoogleMaps ? gmTraffic : useBingMaps ? bing : osmmap;
        break;
      case 13:
        homeLayer = useGoogleMaps ? gmTerrain : useBingMaps ? bing : osmmap;
        break;
      case 14:
        homeLayer = useMapquestMaps ? mqHybrid : osmmap;
        break;
      case 15:
        homeLayer = useMapquestMaps ? mqSatellite : osmmap;
        break;
      case 16:
        homeLayer = useMapquestMaps ? mqDark : osmmap;
        break;
      case 17:
        homeLayer = useMapquestMaps ? mqLight : osmmap;
        break;
      case 18:
        homeLayer = osmmap; // mapTilerBasic;
        break;
      case 19:
        homeLayer = osmmap; // mapTilerLight;
        break;
      case 20:
        homeLayer = osmmap; // mapTilerDark;
        break;
    }

    if (!homeLayer) homeLayer = osmmap;

    homeLayer.addTo(container);
    return () => {
      container.removeControl(layerGroup);
    };
  }, [...listenMapControlChanges]); //typeof MQ if MapQuest is still not defined

  return null;
};

MapBaseLayers.defaultProps = {
  forceUseMapquestMaps: false,
  forceUseGoogleMaps: false,
  forceUseHereMaps: false,
  forceUseBingMaps: false,
  position: 'bottomleft',
  listenMapControlChanges: []
};
export default MapBaseLayers;
