//   -------------------------------------------
//   | entityTypeId | Entity                   |
//   -------------------------------------------
//   | 1            |	Unidad de Rastreo        |
//   | 2            |	Subdivision              |
//   | 3            |	Vehiculo                 |
//   | 4            |	SIM                      |
//   | 5            |	Modulo                   |
//   | 6            |	Piloto                   |
//   | 7            |	Transportista            |
//   | 8            |	Notificacion             |
//   | 9            |	Asesores                 |
//   | 12           |	Clases de Servicio       |
//   | 13           |	Componentes de Vehiculos |
//   | 14           |	Servicios de Vehiculos   |
//   | 15           |	Mensajes de Sistema      |
//   | 16           |	Login                    |
//   | 17           |	Usuarios                 |
//   | 18           |	Operador                 |
//   | 19           |	División                 |
//   | 20           | Formularios              |
//   | 30           |	Organizaciones           |
//   | 31           |	Usuarios                 |
//   | 32           |	Contactos                |
//   | 33           | Planes de Trabajo        |
//   | 34           | Geocercas                |
//   | 35           | Horarios                 |
//   | 37           | Rutas                    |
//   | 40           |	Marcas                   |
//   | 41           |	Modelos                  |
//   | 42           |	Configuraciones          |
//   | 43           |	Sims                     |
//   | 44           |	Países                   |
//   | 45           |	Referencias              |
//   | 46           |	Activos                  |
//   | 47           |	Sensores                 |
//   | 48           |	Jobs                     |
//   | 49           |	Grupos                   |
//   | 50           |	Contratos                |
//   | 51           |	Clientes                 |
//   | 52           |	Linea de Mantenimiento   |
//   | 53           |	Plantillas de Contratos  |
//   | 54           |	Cotizaciones             |
//   | 56           |   Protocolos               |
//   -------------------------------------------

//For more information in sql
// select * from dbo.EntityTypes

export const ENTITY_UNITS = 1;
export const ENTITY_SUBDIVISONS = 2;
export const ENTITY_VEHICLES = 3;
export const ENTITY_SIM_CARDS = 4;
export const ENTITY_MODULES = 5;
export const ENTITY_DRIVERS = 6;
// 7,8 MISSING
// export const ENTITY_NONE = 7;
// export const ENTITY_NONE = 8;
export const ENTITY_AGENTS = 9;
// 10,11 MISSING
// export const ENTITY_NONE = 10;
// export const ENTITY_NONE = 11;
export const ENTITY_SERVICE_CLASSES = 12;
// 13,14,15,16 MISSING
// export const ENTITY_NONE = 13;
// export const ENTITY_NONE = 14;
// export const ENTITY_NONE = 15;
// export const ENTITY_NONE = 16;
export const ENTITY_USERS = 17;
export const ENTITY_OPERATORS = 18;
export const ENTITY_DIVISIONS = 19;
// 20,21,22,23,24,25,26,27,28,29 MISSING
// export const ENTITY_NONE = 20;
// export const ENTITY_NONE = 21;
// export const ENTITY_NONE = 22;
// export const ENTITY_NONE = 23;
// export const ENTITY_NONE = 24;
// export const ENTITY_NONE = 25;
// export const ENTITY_NONE = 26;
// export const ENTITY_NONE = 27;
// export const ENTITY_NONE = 28;
// export const ENTITY_NONE = 29;
export const ENTITY_ORGANIZATIONS = 30;
// 31 MISSING
// export const ENTITY_NONE = 31;
export const ENTITY_CONTACTS = 32;
export const ENTITY_WORKPLANS = 33;
export const ENTITY_GEOFENCES = 34;
export const ENTITY_SCHEDULES = 35;
export const ENTITY_FORMS = 36;
export const ENTITY_ROUTES = 37;
// 38,39,40,41,42,43 MISSING
// export const ENTITY_NONE = 38;
// export const ENTITY_NONE = 39;
// export const ENTITY_NONE = 40;
// export const ENTITY_NONE = 41;
// export const ENTITY_NONE = 42;
// export const ENTITY_NONE = 43;
export const ENTITY_COUNTRIES = 44;
export const ENTITY_LOCATIONS = 45;
export const ENTITY_ASSETS = 46;
export const ENTITY_SENSORS = 47;
export const ENTITY_JOBS_BACKLOG = 48;
export const ENTITY_GROUPS = 49;
export const ENTITY_ORGANIZATION_CONTRACTS = 50;
export const ENTITY_CUSTOMERS = 51;
export const ENTITY_MAINTENANCE_LINES = 52;
export const ENTITY_CONTRACT_TEMPLATES = 53;
export const ENTITY_QUOTATIONS = 54;
export const ENTITY_OFFICES = 55;
export const ENTITY_PROTOCOLS = 56;

export const defaultEntitiesToFetchAftetLogin = [];

// Audit Entities Lookups
export const AUDIT_ENTITY_UNIT_MAIN_STATE_TYPE_ID_LOOKUP = 'UnitMainStateTypeId';
export const AUDIT_ENTITY_UNIT_STATUS_LOOKUP = 'UnitStatus';
export const AUDIT_ENTITY_UNIT_THEME_ID_LOOKUP = 'UnitThemeId';
export const AUDIT_ENTITY_UNIT_TYPE_ID_LOOKUP = 'UnitTypeId';
