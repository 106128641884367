/* -------------------------------------------------------------------------- */
/*                    Componente SlidingPaneAddEditCustomer                   */
/* -------------------------------------------------------------------------- */
// Este componente permite crear o editar clientes

import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Row, Col } from 'reactstrap';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import TextInput from '../../formComponents/TextInput';
import Membership from 'views/formComponents/Membership';
import * as authSelectors from 'redux/reducers/auth';
import * as locationSelectors from 'views/screens/Locations/reducers';
import * as customerSelectors from 'views/screens/Customers/reducers';
import * as customerActions from 'views/screens/Customers/actions';
import { v4 as uuidv4 } from 'uuid';
import omit from 'lodash/omit';
import TabsWrapper from 'views/components/TabsWrapper';
import NumericalInput from '../NumericalInput';
import TimeInput from '../TimeInput';
import LocationsDropdownInput from '../LocationDropDown';

interface Props {
  customer: any;
  isOpenSlidingPane: any;
  closeSlidingPaneAddEditCustomer: any;
  enableSelectLocation?: boolean;
}

const SlidingPaneAddEditCustomer = (props: Props) => {
  const {
    customer,
    isOpenSlidingPane,
    closeSlidingPaneAddEditCustomer,
    enableSelectLocation = false
  } = props;
  const { control, handleSubmit, watch, reset } = useForm();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const intl = useIntl();
  const dispatch = useDispatch();

  const formValues = watch();

  const { isNew, initialValues, locationPrivateCode, parsers } = useSelector((state) => {
    const initialValues = customerSelectors.getSelectedCustomer(state);
    return {
      isNew: initialValues ? false : true,
      initialValues,
      locationPrivateCode:
        locationSelectors.getLocation(
          state,
          formValues?.locationId !== initialValues?.locationId ? formValues?.locationId : undefined
        )?.privateCode ?? initialValues?.officePrivateCode,
      parsers: authSelectors.getParsers(state)
    };
  });

  const editCustomer = (values) => {
    dispatch(customerActions.startEditingCustomer(getValues(initialValues?.customerId, values)));
    closeSlidingPaneAddEditCustomer();
    reset();
  };

  const createCustomer = (values) => {
    dispatch(customerActions.startAddingCustomer(getValues(uuidv4(), values)));
    closeSlidingPaneAddEditCustomer();
    reset();
  };

  const getValues = (customerId, values) => ({
    ...omit(values, 'membership'),
    customerId,
    officeId: initialValues?.officeId,
    organizationId: values.membership?.organizationId,
    divisionId: values.membership?.divisionId,
    subdivisionId: values.membership?.subdivisionId,
    startTime: values.startTime
      ? new Date(values.startTime).toLocaleTimeString('en-GB')
      : undefined,
    endTime: values.endTime ? new Date(values.endTime).toLocaleTimeString('en-GB') : undefined,
    averageCapacity: !(values.averageCapacity === undefined || values.averageCapacity === null)
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.averageCapacity).toFixed(2)
      : undefined,
    minCapacity: !(values.minCapacity === undefined || values.minCapacity === null)
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.minCapacity).toFixed(2)
      : undefined,
    maxCapacity: !(values.maxCapacity === undefined || values.maxCapacity === null)
      ? parsers.convertAuthUserVolumeSystemToCubicMeters(values.maxCapacity).toFixed(2)
      : undefined
  });

  useEffect(() => {
    if (customer?.customerId) {
      dispatch(customerActions.selectCustomer(customer?.customerId));
      return () => {
        dispatch(customerActions.deselectCustomer(customer?.customerId));
      };
    }
  }, [customer?.customerId]);

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenSlidingPane}
      title={intl.formatMessage({ id: isNew ? 'customers.create' : 'customers.edit' })}
      subtitle={isNew ? undefined : initialValues?.customerName}
      width={'45rem'}
      onRequestClose={() => {
        closeSlidingPaneAddEditCustomer();
        reset();
      }}
    >
      <TabsWrapper
        initialActiveTab={0}
        activeTab={selectedTab}
        setActiveTab={setSelectedTab}
        useCardContent={false}
        tabs={[
          {
            tabId: 0,
            icon: 'Customers',
            title: 'customers.customer'
          },
          {
            tabId: 1,
            icon: 'Branch',
            title: 'customers.centralOffice'
          }
        ]}
        tabsContent={[
          {
            tabId: 0,
            component: (
              <Row>
                <Membership
                  name="membership"
                  control={control}
                  defaultValue={{
                    organizationId: initialValues?.organizationId,
                    divisionId: initialValues?.divisionId,
                    subdivisionId: initialValues?.subdivisionId
                  }}
                  options={{
                    useOperator: false,
                    useUserOrganization: true,
                    showAllDivision: true,
                    showAllSubdivision: true,
                    colSize: 6
                  }}
                />
                <Col sm="12">
                  <Controller
                    control={control}
                    name="customerName"
                    defaultValue={initialValues?.customerName}
                    rules={{ required: true }}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'customerNameInput'}
                        label={intl.formatMessage({
                          id: 'customers.customerName'
                        })}
                        required
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name="businessName"
                    defaultValue={initialValues?.businessName}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'businessNameInput'}
                        label={intl.formatMessage({
                          id: 'customers.businessName'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="taxId"
                    defaultValue={initialValues?.taxId}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'taxIdInput'}
                        label={intl.formatMessage({
                          id: 'customers.taxesId'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="privateCode"
                    defaultValue={initialValues?.privateCode}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'privateCodeInput'}
                        label={intl.formatMessage({
                          id: 'customers.privateCode'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name="customerAddress"
                    defaultValue={initialValues?.customerAddress}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'customerAddressInput'}
                        label={intl.formatMessage({
                          id: 'customers.address'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name="customerEmails"
                    defaultValue={initialValues?.customerEmails}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'customerEmailsInput'}
                        label={intl.formatMessage({
                          id: 'customers.email'
                        })}
                        type={'email'}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="contactName1"
                    defaultValue={initialValues?.contactName1}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'contactName1Input'}
                        label={intl.formatMessage({
                          id: 'customers.contact1'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="phone1"
                    defaultValue={initialValues?.phone1}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'phone1Input'}
                        label={intl.formatMessage({
                          id: 'customers.phone1'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="contactName2"
                    defaultValue={initialValues?.contactName2}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'contactName2Input'}
                        label={intl.formatMessage({
                          id: 'customers.contact2'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="phone2"
                    defaultValue={initialValues?.phone2}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'phone2Input'}
                        label={intl.formatMessage({
                          id: 'customers.phone2'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="contactName3"
                    defaultValue={initialValues?.contactName3}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'contactName3Input'}
                        label={intl.formatMessage({
                          id: 'customers.contact3'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="6">
                  <Controller
                    control={control}
                    name="phone3"
                    defaultValue={initialValues?.phone3}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'phone3Input'}
                        label={intl.formatMessage({
                          id: 'customers.phone3'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name="comments"
                    defaultValue={initialValues?.comments}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        id={'commentsInput'}
                        label={intl.formatMessage({
                          id: 'customers.comments'
                        })}
                        type={'textarea'}
                      />
                    )}
                  />
                </Col>
              </Row>
            )
          },
          {
            tabId: 1,
            component: (
              <Row>
                {!enableSelectLocation && (
                  <Col sm="12">
                    <Controller
                      control={control}
                      shouldUnregister
                      name={'locationId'}
                      defaultValue={initialValues?.locationId}
                      render={({ field, fieldState }) => (
                        <LocationsDropdownInput
                          field={field}
                          fieldstate={fieldState}
                          isInModal
                          isClearable
                          notAssigned
                          customerId={initialValues?.customerId}
                          officeId={initialValues?.officeId}
                          useGoogleLocations
                          createFromGoogleLocations
                          id={'inputLocation'}
                          locationsFilters={formValues?.membership}
                          label={intl.formatMessage({ id: 'locations.location' })}
                        />
                      )}
                    />
                  </Col>
                )}
                <Col sm="12">
                  <Controller
                    control={control}
                    name="officeName"
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        forceinitialvalue={formValues?.customerName}
                        id={'officeNameInput'}
                        label={intl.formatMessage({
                          id: 'common.name'
                        })}
                        disabled
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name="officePrivateCode"
                    defaultValue={initialValues?.officePrivateCode}
                    render={({ field, fieldState }) => (
                      <TextInput
                        field={field}
                        fieldstate={fieldState}
                        forceinitialvalue={locationPrivateCode}
                        id={'privateCodeInput'}
                        label={intl.formatMessage({
                          id: 'customers.privateCode'
                        })}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'averageTime'}
                    defaultValue={initialValues?.averageTime}
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        id={'averageTimeInput'}
                        label={intl.formatMessage({ id: 'workplans.averageTime' })}
                        appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                        min={formValues?.minTime ?? 0}
                        max={formValues?.maxTime ?? 1000}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'minTime'}
                    defaultValue={initialValues?.minTime}
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        id={'minTimeInput'}
                        label={intl.formatMessage({ id: 'workplans.minTime' })}
                        appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                        min={0}
                        max={formValues?.maxTime ?? 1000}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'maxTime'}
                    defaultValue={initialValues?.maxTime}
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        id={'maxTimeInput'}
                        label={intl.formatMessage({ id: 'workplans.maxTime' })}
                        appendcomponent={intl.formatMessage({ id: 'common.minutes' })}
                        min={formValues?.minTime ?? 0}
                        max={1000}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'startTime'}
                    defaultValue={
                      initialValues?.startTime ? new Date(initialValues.startTime) : undefined
                    }
                    render={({ field, fieldState }) => (
                      <TimeInput
                        field={field}
                        fieldstate={fieldState}
                        allowNulls
                        label={intl.formatMessage({ id: 'workplans.startTime' })}
                        maxTime={
                          formValues?.endTime
                            ? moment(formValues.endTime).format('HH:mm')
                            : undefined
                        }
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'endTime'}
                    defaultValue={
                      initialValues?.endTime ? new Date(initialValues.endTime) : undefined
                    }
                    render={({ field, fieldState }) => (
                      <TimeInput
                        field={field}
                        fieldstate={fieldState}
                        allowNulls
                        label={intl.formatMessage({ id: 'workplans.endTime' })}
                        minTime={
                          formValues?.startTime
                            ? moment(formValues.startTime).format('HH:mm')
                            : undefined
                        }
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'averageCapacity'}
                    defaultValue={
                      !(
                        initialValues?.averageCapacity === null ||
                        initialValues?.averageCapacity === undefined
                      )
                        ? parsers
                            .convertCubicMetersToAuthUserVolumeSystem(initialValues.averageCapacity)
                            .toFixed(2)
                        : undefined
                    }
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        allowDecimals
                        decimalsPrecision={2}
                        id={'averageCapacityInput'}
                        metric={'volume'}
                        label={intl.formatMessage({ id: 'customers.averageCapacity' })}
                        min={formValues?.minCapacity ?? 0}
                        max={formValues?.maxCapacity ?? 1000}
                        allowZero={true}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'minCapacity'}
                    defaultValue={
                      !(
                        initialValues?.minCapacity === null ||
                        initialValues?.minCapacity === undefined
                      )
                        ? parsers
                            .convertCubicMetersToAuthUserVolumeSystem(initialValues.minCapacity)
                            .toFixed(2)
                        : undefined
                    }
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        allowDecimals
                        decimalsPrecision={2}
                        allowZero={true}
                        id={'minCapacityInput'}
                        metric={'volume'}
                        label={intl.formatMessage({ id: 'customers.minCapacity' })}
                        min={0}
                        max={1000}
                      />
                    )}
                  />
                </Col>
                <Col sm="12">
                  <Controller
                    control={control}
                    name={'maxCapacity'}
                    defaultValue={
                      !(
                        initialValues?.maxCapacity === null ||
                        initialValues?.maxCapacity === undefined
                      )
                        ? parsers
                            .convertCubicMetersToAuthUserVolumeSystem(initialValues.maxCapacity)
                            .toFixed(2)
                        : undefined
                    }
                    render={({ field, fieldState }) => (
                      <NumericalInput
                        field={field}
                        fieldstate={fieldState}
                        allowDecimals
                        allowZero={true}
                        decimalsPrecision={2}
                        id={'maxCapacityInput'}
                        metric={'volume'}
                        label={intl.formatMessage({ id: 'customers.maxCapacity' })}
                        min={formValues?.minCapacity ?? 0}
                        max={1000}
                      />
                    )}
                  />
                </Col>
              </Row>
            )
          }
        ]}
      />

      <br />
      <br />
      <Row className={'footer-slide-pane'}>
        <Col sm="12">
          <Button.Ripple
            className="mr-1 mt-50 mb-50"
            id = "saveCustomersButton"
            color="primary"
            /*type="submit"*/
            onClick={handleSubmit((values) =>
              isNew ? createCustomer(values) : editCustomer(values)
            )}
          >
            <FormattedMessage id="common.save" />
          </Button.Ripple>
          <Button.Ripple
            className="mt-50 mb-50"
            color="light"
            onClick={() => {
              closeSlidingPaneAddEditCustomer();
              reset();
            }}
          >
            <FormattedMessage id="common.cancel" />
          </Button.Ripple>
        </Col>
      </Row>
    </SlidingPane>
  );
};

SlidingPaneAddEditCustomer.defaultProps = {};

export default SlidingPaneAddEditCustomer;
