import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as entityActions from '../../Entities/actions';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import { getOfficesListService, saveOfficeService, deleteOfficeService } from '../services';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchOfficesList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getOfficesListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { offices },
          result //order
        } = normalize(resultData, schemas.offices); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingOffices(offices, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingOffices(error));
      }
    }
  );
}

export function* watchFetchOfficesList() {
  yield takeEvery(types.OFFICES_FETCH_STARTED, fetchOfficesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchOfficesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getOfficesListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { offices },
          result //order
        } = normalize(resultData, schemas.offices); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingOffices(offices, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingOffices(error));
      }
    }
  );
}

export function* watchPartialFetchOfficesList() {
  yield takeEvery(types.OFFICES_PARTIAL_FETCH_STARTED, partialFetchOfficesList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addOffice(action) {
  const office = action.payload;
  saveOfficeService(
    { ...office, officeId: null },
    {
      successAction: (response) => {
        const addedOffice = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingOffice(office.officeId, addedOffice));
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingOffice(office.officeId, error));
      }
    }
  );
}

export function* watchAddOfficesStarted() {
  yield takeEvery(types.OFFICE_ADD_STARTED, addOffice);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editOffice(action) {
  const office = action.payload;
  const { oldOffice } = yield select((state) => selectors.getOffice(state, office.officeId));
  saveOfficeService(
    { ...office },
    {
      successAction: (response) => {
        const editedOffice = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingOffice(editedOffice));
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingOffice(oldOffice, error));
      }
    }
  );
}

export function* watchEditOfficesStarted() {
  yield takeEvery(types.OFFICE_EDIT_STARTED, editOffice);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeOffice(action) {
  const { officeId } = action.payload;
  const office = yield select((state) => selectors.getOffice(state, officeId));
  deleteOfficeService(
    { ...office },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingOffice(officeId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingOffice(officeId, error));
      }
    }
  );
}

export function* watchRemoveOfficeStarted() {
  yield takeEvery(types.OFFICE_REMOVE_STARTED, removeOffice);
}
