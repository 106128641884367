/* -------------------------------------------------------------------------- */
/*                         SlidingPaneStartUnitDowntime                       */
/* -------------------------------------------------------------------------- */
// Componente que permite iniciar el estado downtime a una unidad seleccionada

import { FC, Fragment } from 'react';
import * as authSelectors from 'redux/reducers/auth';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import NumericalInput from '../NumericalInput';
import { useWebtrackServiceOnAction } from 'hooks/useWebtrackServiceOnAction';
import * as alerts from 'redux/utils/alerts';
import { updateOdometer } from 'views/screens/Units/services';
import { useDispatch, useSelector } from 'react-redux';
import * as unitActions from 'views/screens/Units/actions';
import SimpleAlertMessage from 'views/components/SimpleAlertMessage';

interface Props {
  close: () => void;
  isOpen: boolean;
  unitId: number;
  defaultValue?: number;
  additionalOnSuccess?: () => void;
}

const SlidingPaneManualOdometerAdjustment: FC<Props> = ({
  isOpen,
  close,
  defaultValue = 0, // raw odometer in km
  unitId,
  additionalOnSuccess
}) => {
  //HOOKS
  const { control, handleSubmit, reset } = useForm<{ odometer: number }>();
  const intl = useIntl();
  const { handleAction } = useWebtrackServiceOnAction(updateOdometer, {
    additionalOnSuccess: () => {
      dispatch(unitActions.startFetchingUnitsLiveStatus());
      if (typeof additionalOnSuccess === 'function') {
        additionalOnSuccess();
      }
    }
  });
  const initialValue = useSelector((state) => {
    return authSelectors
      .getParsers(state)
      .convertMetersToAuthUserDistanceSystem(defaultValue)
      .toFixed(2);
  });
  const { distanceMeasure, parser } = useSelector((state) => {
    return {
      distanceMeasure: authSelectors.getAuthSystems(state).distance,
      parser: authSelectors.getParsers(state)
    };
  });
  const dispatch = useDispatch();
  const onSubmit = (values: { odometer: number }) => {
    const newOdometer = values.odometer;
    alerts.showConfirmationMessage({
      message: intl.formatMessage({ id: 'common.editConfirmationMessage' }),
      action: () => {
        handleAction({ unitId, newOdometer: newOdometer });
        close();
      },
      actionNot: () => {
        close();
      }
    });
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isOpen}
      title={intl.formatMessage({ id: 'units.editOdometer' })}
      subtitle={''}
      width={'35rem'}
      onRequestClose={() => {
        close();
      }}
    >
      <Fragment>
        <Row>
          <Col sm="12">
            <Controller
              control={control}
              name="odometer"
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <NumericalInput
                  field={field}
                  fieldstate={fieldState}
                  id={'odometerInput1'}
                  label={intl.formatMessage({ id: 'units.odometer' })}
                  forceinitialvalue={Number(defaultValue)}
                  required
                  allowZero
                  allowDecimals
                  appendcomponent={distanceMeasure}
                />
              )}
            />
          </Col>
          <Col sm="12" className="w-100 row-simple-message-container">
            <SimpleAlertMessage
              message={intl.formatMessage({ id: 'units.editOdometerWarning' })}
              icon="PreventiveNotice"
              visible={true}
            />
          </Col>
        </Row>
        <Row className={'footer-slide-pane'}>
          <Col sm="12">
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit(onSubmit)}
            >
              <FormattedMessage id="common.save" />
            </Button.Ripple>
            <Button.Ripple
              className="mt-50 mb-50"
              color="light"
              onClick={() => {
                reset();
                close();
              }}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          </Col>
        </Row>
      </Fragment>
    </SlidingPane>
  );
};

export default SlidingPaneManualOdometerAdjustment;