import forEach from 'lodash/forEach';
import { isNaNOrNullable, parseToNumber } from 'utility/Utils';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { timeInSeconds } from 'utility/timeUtils/timeInSeconds';
// Adds description to one schedule
// returns schedule object
export const addScheduleDescription = (schedule, intl) => {
  const isBitSet = (b, pos) => (b & (1 << pos)) !== 0;
  const relativeIntervalDay = {
    1: () => intl.formatMessage({ id: 'schedules.sunday' }),
    2: () => intl.formatMessage({ id: 'schedules.monday' }),
    3: () => intl.formatMessage({ id: 'schedules.tuesday' }),
    4: () => intl.formatMessage({ id: 'schedules.wednesday' }),
    5: () => intl.formatMessage({ id: 'schedules.thursday' }),
    6: () => intl.formatMessage({ id: 'schedules.friday' }),
    7: () => intl.formatMessage({ id: 'schedules.saturday' })
  };
  const frequencyType = {
    1: () => intl.formatMessage({ id: 'schedules.oneTime' }),
    4: () =>
      schedule.frequencyInterval > 1
        ? intl.formatMessage({ id: 'schedules.every' }) +
          schedule.frequencyInterval +
          intl.formatMessage({ id: 'schedules.days' })
        : intl.formatMessage({ id: 'schedules.everyDay' }),
    8: () => {
      var count = 0;
      var sDays = '';
      forEach([...Array(7).keys()], (value) => {
        if (isBitSet(schedule.frequencyInterval, value)) {
          if (sDays != '') sDays += ', ';
          sDays += relativeIntervalDay[value + 1]();
          count++;
        }
      });
      return (
        (count > 1
          ? intl.formatMessage({ id: 'schedules.theDays' })
          : intl.formatMessage({ id: 'schedules.the' })) +
        (count === 2 ? sDays.replace(',', ' y ') : sDays) +
        intl.formatMessage({ id: 'schedules.everySmall' }) +
        (schedule.frequencyRecurrenceFactor > 1
          ? schedule.frequencyRecurrenceFactor + intl.formatMessage({ id: 'schedules.weeks' })
          : intl.formatMessage({ id: 'schedules.week' }))
      );
    },
    16: () =>
      intl.formatMessage({ id: 'schedules.theDay' }) +
      schedule.frequencyInterval +
      intl.formatMessage({ id: 'schedules.ofEach' }) +
      (schedule.frequencyRecurrenceFactor > 1
        ? schedule.frequencyRecurrenceFactor + intl.formatMessage({ id: 'schedules.months' })
        : intl.formatMessage({ id: 'schedules.month' })),
    32: () =>
      ({
        1: () => intl.formatMessage({ id: 'schedules.relativeMonth0' }),
        2: () => intl.formatMessage({ id: 'schedules.relativeMonth1' }),
        4: () => intl.formatMessage({ id: 'schedules.relativeMonth2' }),
        8: () => intl.formatMessage({ id: 'schedules.relativeMonth3' }),
        16: () => intl.formatMessage({ id: 'schedules.relativeMonth4' })
      }[schedule.frequencyRelativeInterval]() +
      ' ' +
      relativeIntervalDay[schedule.frequencyInterval]() +
      intl.formatMessage({ id: 'schedules.ofEach' }) +
      (schedule.frequencyRecurrenceFactor > 1
        ? schedule.frequencyRecurrenceFactor + intl.formatMessage({ id: 'schedules.months' })
        : intl.formatMessage({ id: 'schedules.month' })))
  };
  const frequencyDescription = frequencyType[schedule.frequencyType]();
  return {
    ...schedule,
    frequencyDescription
  };
};

// Adds appropiate descriptions to a list of schedules
// returns array of schedule objects
export const addSchedulesDescription = (schedulesData, intl) =>
  schedulesData.map((schedules) => addScheduleDescription(schedules, intl));

//Lista de día de la semana
//Útil para .map en diferentes contextos
export const daysList = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
];

export const msToTime = (duration: unknown): string => {
  const parsed = parseToNumber(duration);

  if (!parsed) return '';

  const milliseconds = Math.floor((parsed % 1000) / 100),
    seconds = Math.floor((parsed / 1000) % 60),
    minutes = Math.floor((parsed / (1000 * 60)) % 60),
    hours = Math.floor((parsed / (1000 * 60 * 60)) % 24);

  const hoursT = hours < 10 ? '0' + hours : hours;
  const minutesT = minutes < 10 ? '0' + minutes : minutes;
  const secondsT = seconds < 10 ? '0' + seconds : seconds;

  return hoursT + ':' + minutesT + ':' + secondsT + '.' + milliseconds;
};

export const minutesToMilliseconds = (min: unknown) => {
  const minutes = parseToNumber(min);

  if (!minutes) return null;

  const timeAsMilliseconds = minutes * timeInSeconds.minute * 1000;

  return timeAsMilliseconds;
};

export const minutesToTime = (min: unknown): string => {
  const timeAsMilliseconds = minutesToMilliseconds(min);

  return msToTime(timeAsMilliseconds);
};
