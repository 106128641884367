import * as types from '../../../types/static-catalogs/insurance-agents';

export const startFetchingInsuranceAgents = () => ({
  type: types.INSURANCE_AGENTS_FETCH_STARTED,
  payload: {}
});
export const completeFetchingInsuranceAgents = (entities, order) => ({
  type: types.INSURANCE_AGENTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingInsuranceAgents = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.INSURANCE_AGENTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
