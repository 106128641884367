import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';

import * as actions from '../../actions/workplansVisits';
import * as types from '../../types/workplansVisits';
import * as selectors from '../../reducers/workplans';
import * as schemas from '../../schemas/workplansVisits';
import * as historicalSchemas from '../../schemas/workplansHistoricalVisits';

import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { useIntl } from 'react-intl';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { store } from 'redux/storeConfig/store';
import {
  getWorkplanAllVisitsListService,
  getWorkplanVisitsListService
} from '../../services/workplansVisits';

/* -------------------------------------------------------------------------- */
/*                              FETCH LIST VISITS                             */
/* -------------------------------------------------------------------------- */
function* fetchWorkplansVisits(action) {
  const filters = action.payload;

  getWorkplanVisitsListService(
    { ...filters },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { workplansVisits },
          result //order
        } = normalize(resultData, schemas.workplansVisits); //normalize data to byId and order
        store.dispatch(actions.completeFetchingWorkplansVisits(workplansVisits, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingWorkplansVisits(error));
      }
    }
  );
}

export function* watchFetchWorkplansVisits() {
  yield takeEvery(types.WORKPLANS_VISITS_FETCH_STARTED, fetchWorkplansVisits);
}

/* -------------------------------------------------------------------------- */
/*                          FETCH LIST ALL VISITS                             */
/* -------------------------------------------------------------------------- */
function* fetchWorkplansAllVisits(action) {
  const { visitId } = action.payload;
  getWorkplanAllVisitsListService(
    { visitId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { workplansVisits },
          result //order
        } = normalize(resultData, historicalSchemas.workplansVisits); //normalize data to byId and order
        store.dispatch(actions.completeFetchingWorkplansAllVisits(workplansVisits, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingWorkplansAllVisits(error));
      }
    }
  );
}

export function* watchFetchWorkplansAllVisits() {
  yield takeEvery(types.WORKPLANS_ALL_VISITS_FETCH_STARTED, fetchWorkplansAllVisits);
}
