import { List, MapPin } from 'react-feather';
import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'locations',
    title: 'locations.locations',
    icon: <MapPin size={20} />,
    iconName: 'MapPin',
    permissions: [permissions.listLocations, permissions.importLocation],
    children: [
      {
        id: 'locationsListScreen',
        title: 'locations.catalog',
        iconName: 'Catalog',
        permissions: [permissions.listLocations],
        // accessLevels: [9, 13, 15],
        navLink: paths.locationsList,
        screenId: screens.LOCATIONS_CATALOG
      },
      {
        id: 'importLocationsScreen',
        title: 'locations.importLocations',
        iconName: 'UploadCloud',
        permissions: [permissions.importLocation],
        // accessLevels: [9, 13, 15],
        navLink: paths.importLocations,
        screenId: screens.IMPORT_LOCATIONS
      }
    ]
  }
];
