import * as screens from '@core/layouts/components/menu/screens';
import paths from './paths';
import permissions from './permissions';

export default [
  {
    id: 'routes',
    title: 'routes.logisticRoute',
    permissions: [
      permissions.viewRouteCatalog,
      permissions.viewRouteMonitor,
      permissions.viewRouteReport,
      permissions.assignRoute
    ],
    iconName: 'Route',
    children: [
      {
        id: 'routesListScreen',
        title: 'sidebar.catalog',
        permissions: [permissions.viewRouteCatalog],
        iconName: 'RouteCatalog',
        navLink: paths.routeList,
        screenId: screens.LOGISTIC_ROUTE_CATALOG
      },
      {
        id: 'routesMonitorScreen',
        title: 'sidebar.monitor',
        permissions: [permissions.viewRouteMonitor],
        iconName: 'RouteMonitor',
        navLink: paths.routeMonitor,
        screenId: screens.LOGISTIC_ROUTE_MONITOR
      },
      {
        id: 'routesReportScreen',
        title: 'sidebar.report',
        permissions: [permissions.viewRouteReport],
        iconName: 'RouteReport',
        navLink: paths.routeReport,
        screenId: screens.LOGISTIC_ROUTE_REPORT
      },
      {
        id: 'routesAssignUnitScreen',
        title: 'sidebar.assignRoute',
        permissions: [permissions.assignRoute],
        iconName: 'PlusSquare',
        navLink: paths.routeAssignUnit,
        screenId: screens.ASSIGN_LOGISTIC_ROUTE
      },
      {
        id: 'importRouteAssignmentsScreen',
        title: 'sidebar.importRouteAssignments',
        permissions: [permissions.importRouteAssignments],
        iconName: 'UploadCloud',
        navLink: paths.importRouteAssignments,
        screenId: screens.IMPORT_ROUTE_ASSIGNMENTS
      }
    ]
  }
];
