import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import split from 'lodash/split';
import { v4 as uuidv4 } from 'uuid';

import MultiSelectInput from '../MultiSelectInput';
import { InputGroupText, UncontrolledTooltip } from 'reactstrap';
import Icon from 'utility/icomoon';

import * as contactActions from 'views/screens/Contacts/actions';
import * as ContactSelectors from 'views/screens/Contacts/reducers';
import * as authSelectors from 'views/../redux/reducers/auth';
import SlidingPaneAddEditContact from 'views/formComponents/SlidingPaneAddEditContact';
import { useGetOperatorContacts } from 'views/screens/Operators/hooks/useGetOperatorContacts';
// src\views\screens\Organizations\hooks\useGetContacts.ts
import { useGetContacts } from 'views/screens/Organizations/hooks/useGetContacts';

interface Props {
  id: any;
  name: any;
  control: any;
  shouldUnregister?: any;
  defaultContacts?: any;
  extraContacts?: any;
  label?: any;
  isInModal?: boolean;
  initialFetch?: boolean;
  required?: boolean;
  contactTypeId?: number;
  isMultiple?: boolean;
  isClearable?: boolean;
  canCreate?: boolean;
  setContactsIn?: any;
  contactsIn?: any;
  setNewContact?: (value) => void;
  closeSlidingPaneAddEditContact?: () => void;
  prependcomponentFun?: () => void;
  appendcomponentFun?: () => void;
  operatorId?: number;
  organizationId?: number;
  defaultJobTitle?: string;
  watch?: any;
  reset?: any;
}

const ContactsDropdown = (props: Props) => {
  const store = useSelector((state) => state);
  const userInSession = authSelectors.getUser(store);

  const {
    id,
    name,
    shouldUnregister,
    defaultContacts,
    extraContacts = [],
    label,
    isInModal,
    isMultiple = true,
    isClearable = false,
    initialFetch = true,
    canCreate = true,
    required,
    control,
    contactTypeId = 1,
    setContactsIn,
    contactsIn,
    setNewContact = null,
    closeSlidingPaneAddEditContact = undefined,
    prependcomponentFun = undefined,
    appendcomponentFun = undefined,
    operatorId = authSelectors.getAuthUserOperatorId(store),
    organizationId = authSelectors.getAuthUserOrganizationId(store),
    defaultJobTitle = '',
    watch = undefined,
    reset = undefined
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [selectedContacts, changeSelectedContacts] = useState(defaultContacts);

  const [addEditContactSlideOpen, openAddEditContactSlide] = useState(false);
  const [newContact, changeNewContact] = useState<any>(null);

  const selectAndEditContact = (contactId) => {
    dispatch(contactActions.selectContact(contactId));
    openAddEditContactSlide(true);
  };

  const selectedContactOnCreate = useSelector(ContactSelectors.getSelectedContact);

  const formatContactsForDropdown = (contact) => {
    if (contact)
      return {
        ...contact,
        id: contact.contactId,
        value: contactTypeId === 1 ? contact.email : contact.contactId,
        label:
          contact.contactName !== contact.email
            ? `${contact.contactName} <${contact.email}>`
            : contact.contactName,
        edit: selectAndEditContact,
        identifierIcon: (
          <>
            <UncontrolledTooltip
              placement={'top'}
              target={`contactInsideDropdown_${contact.contactId}_${id}`}
            >
              {intl.formatMessage({
                id:
                  contact.contactTypeId === 2
                    ? 'contacts.operatorContact'
                    : contact.contactTypeId === 3
                    ? 'contacts.organizationContact'
                    : 'contacts.userContact'
              })}
            </UncontrolledTooltip>
            <Icon
              icon={
                contact.contactTypeId === 3
                  ? 'Organization'
                  : contact.contactTypeId === 2
                  ? 'Operator'
                  : 'noun_User_2905483'
              }
              size={15}
              id={`contactInsideDropdown_${contact.contactId}_${id}`}
            />
          </>
        )
      };
    return {};
  };

  const contacts = useSelector(ContactSelectors.getContactsList);
  const userContactsEmails = contacts.map((c) => c.email);

  /**
   * Old contacts way to get operator contacts
   * 
   *  const operatorPublicContacts = contacts.filter(
    (contact) =>
      contact.contactTypeId === 2 &&
      contact.contactStatus > 0 &&
      (contact.isShared === 1 || contact.isShared === true) &&
      contact.operatorId == operatorId
  ); */

  /**
   * new way to get Operator contacts
   */
  // Exceutes an function that return  operatorPublicContacts, areFetchingOperatorContacts
  const { operatorPublicContacts, areFetchingOperatorContacts } = (() => {
    const { operatorContacts, isFetchingOperatorContacts } = useGetOperatorContacts(operatorId);
    return {
      areFetchingOperatorContacts: isFetchingOperatorContacts,
      // contactTypeId Added to objects
      operatorPublicContacts: operatorContacts.map((c) => ({ ...c, contactTypeId: 2 }))
    };
    // Self excecuted lambda in order to avoid creating
    // a new function to map contacts and add prop contactTypeId
  })();

  /**
   * Old contacts way to get organization contacts
   * 
   const organizationPublicContacts = contacts.filter(
    (contact) =>
      contact.contactTypeId === 3 &&
      contact.contactStatus > 0 &&
      (contact.isShared === 1 || contact.isShared === true) &&
      contact.organizationId == organizationId
  ); */

  const { organizationPublicContacts, areFetchingOrganizationContacts } = (() => {
    const { contacts, isFetching } = useGetContacts(organizationId);
    return {
      // contactTypeId Added to objects
      organizationPublicContacts: contacts.map((c) => ({ ...c, contactTypeId: 3 })),
      areFetchingOrganizationContacts: isFetching
    };
    // Self excecuted lambda in order to avoid creating
    // a new function to map contacts and add prop contactTypeId
  })();

  const filterContactByAcces = (
    userContacts: Array<any>,
    operatorContacts: Array<any>,
    OrganizationContacts: Array<any>
  ): Array<any> => {
    const usedEmails: Array<string> = userContacts.map((c) => c.email);
    // Added destructutred to new array to avoid memory issues
    const filteredContacts: Array<any> = [...userContacts];

    const addNotIncludedContacts = (contact: any) => {
      if (usedEmails.includes(contact.email)) return;
      usedEmails.push(contact.email);
      filteredContacts.push(contact);
    };

    // Validates has acces to Operator contacts
    if (['9', '11', '13'].indexOf(userInSession?.accessLevelId) >= 0) {
      operatorContacts?.forEach(addNotIncludedContacts);
    }
    // Validates has acces to Organization contacts
    if (['7', '9', '11', '13'].indexOf(userInSession?.accessLevelId)) {
      OrganizationContacts?.forEach(addNotIncludedContacts);
    }

    return filteredContacts?.map(formatContactsForDropdown);
  };

  const contactsToShow: Array<any> = filterContactByAcces(
    contacts,
    operatorPublicContacts,
    organizationPublicContacts
  );

  const contactsToAdd =
    contactTypeId === 1
      ? defaultContacts !== undefined && defaultContacts !== null && defaultContacts !== ''
        ? defaultContacts
            .split(',')
            .filter((c) => !userContactsEmails.includes(c))
            .filter(
              (defaultContact) =>
                contactsToShow
                  .concat(extraContacts)
                  .filter((contact) => contact.id === defaultContact).length === 0
            )
            .map((contactToAdd) => ({
              ...(contactTypeId === 1 ? {} : contactToAdd),
              id: contactTypeId === 1 ? contactToAdd : contactToAdd.contactId,
              value: contactTypeId === 1 ? contactToAdd : contactToAdd.contactId,
              label:
                contactTypeId === 1
                  ? contactToAdd
                  : contactToAdd.contactName !== contactToAdd.email
                  ? `${contactToAdd.contactName} <${contactToAdd.email}>`
                  : contactToAdd.contactName
            }))
        : []
      : [];

  const [resetValue, setResetValue] = useState(Date.now());
  const [selectedContact, setSelectedContact] = useState<any>(
    selectedContacts !== undefined &&
      selectedContacts !== null &&
      selectedContacts !== '' &&
      !isMultiple
      ? contactsToShow
          .concat(extraContacts)
          .concat(contactsToAdd)
          .filter((contact) => `${contact.contactId}` === selectedContacts)[0]
      : null
  );

  //Update reactselect values of  locations when fetched is finished
  useEffect(() => {
    changeSelectedContacts(defaultContacts);
    setResetValue(Date.now());
  }, [defaultContacts]);

  useEffect(() => {
    if (selectedContacts || initialFetch) dispatch(contactActions.startFetchingContacts(null));
  }, [selectedContacts]);

  useEffect(() => {
    if (
      newContact !== null &&
      newContact !== undefined &&
      isMultiple === false &&
      canCreate === false
    ) {
      changeSelectedContacts(`${newContact.contactId}`);
      if (reset) reset({ ...(watch ? watch() : {}), [name]: `${newContact.contactId}` });
    }
  }, [newContact]);

  useEffect(() => {
    if (
      selectedContactOnCreate !== null &&
      selectedContactOnCreate !== undefined &&
      isMultiple === false &&
      canCreate === true &&
      typeof selectedContactOnCreate.contactId === 'number' &&
      newContact !== null &&
      newContact !== undefined
    ) {
      changeSelectedContacts(`${selectedContactOnCreate.contactId}`);
      if (reset)
        reset({ ...(watch ? watch() : {}), [name]: `${selectedContactOnCreate.contactId}` });
    }
  }, [selectedContactOnCreate?.contactId]);

  const onChangeNewContact = (value) => {
    if (setNewContact) setNewContact(value);
    changeNewContact(value);
  };

  return (
    <>
      <Controller
        control={control}
        shouldUnregister={shouldUnregister}
        name={name}
        defaultValue={defaultContacts}
        rules={{ required: required }}
        render={({ field, fieldState }) => (
          <MultiSelectInput
            field={field}
            fieldstate={fieldState}
            id={id}
            required={required}
            isClearable={isClearable}
            isMultiple={isMultiple}
            isInModal={isInModal}
            key={resetValue}
            prependcomponent={
              isMultiple ? undefined : (
                <>
                  <UncontrolledTooltip placement={'top'} target={'editContactDropdownButton' + id}>
                    {intl.formatMessage({ id: 'contacts.editContact' })}
                  </UncontrolledTooltip>
                  <InputGroupText
                    id={'editContactDropdownButton' + id}
                    style={{ cursor: 'pointer' }}
                    className={'m-0'}
                    onClick={() => {
                      if (
                        selectedContacts !== undefined &&
                        selectedContacts !== null &&
                        selectedContacts.trim() !== ''
                      ) {
                        openAddEditContactSlide(true);
                        if (prependcomponentFun) prependcomponentFun();
                        setSelectedContact(
                          selectedContacts !== undefined &&
                            selectedContacts !== null &&
                            selectedContacts !== '' &&
                            !isMultiple
                            ? contactsToShow
                                .concat(extraContacts)
                                .concat(contactsToAdd)
                                .filter((contact) => `${contact.contactId}` === selectedContacts)[0]
                            : null
                        );
                      }
                    }}
                  >
                    <Icon icon={'Edit2'} />
                  </InputGroupText>
                </>
              )
            }
            appendcomponent={
              isMultiple ? undefined : (
                <>
                  <UncontrolledTooltip placement={'top'} target={'addContactDropdownButton' + id}>
                    {intl.formatMessage({ id: 'contacts.addContact' })}
                  </UncontrolledTooltip>
                  <InputGroupText
                    id={'addContactDropdownButton' + id}
                    style={{ cursor: 'pointer' }}
                    className={'m-0'}
                    onClick={() => {
                      if (appendcomponentFun) appendcomponentFun();
                      openAddEditContactSlide(true);
                      setSelectedContact(null);
                    }}
                  >
                    <Icon icon={'Plus'} />
                  </InputGroupText>
                </>
              )
            }
            onCreate={(event) =>
              dispatch(
                contactActions.startAddingContact({
                  contactName: event[event.length - 1].value,
                  email: event[event.length - 1].value,
                  contactId: uuidv4()
                })
              )
            }
            onSelect={(event) => {
              var string = '';
              isMultiple
                ? event.map((email) => (string = string + email.id + ','))
                : (string = `${string}${event !== undefined && event !== null ? event.id : ''},`);
              changeSelectedContacts(string.slice(0, -1));
            }}
            selectedValues={selectedContacts}
            type={'select'}
            options={contactsToShow.concat(extraContacts).concat(contactsToAdd)}
            label={
              label ||
              intl.formatMessage({
                id: 'contacts.contacts'
              })
            }
            placeholder={intl.formatMessage({ id: 'contacts.placeholder' })}
            canCreate={isMultiple && canCreate}
          />
        )}
      />
      <SlidingPaneAddEditContact
        operatorContact={contactTypeId === 2}
        organizationContact={contactTypeId === 3}
        userContact={contactTypeId === 1}
        contactsIn={contactsIn}
        setContactsIn={setContactsIn}
        selectedContact={isMultiple ? undefined : selectedContact}
        isOpenSlidingPane={addEditContactSlideOpen}
        closeSlidingPaneAddEditContact={() => {
          openAddEditContactSlide(false);
          if (closeSlidingPaneAddEditContact) closeSlidingPaneAddEditContact();
        }}
        setNewContact={onChangeNewContact}
        defaultJobTitle={defaultJobTitle}
        canCreate={
          (!isMultiple && canCreate && contactTypeId !== 1) || (contactTypeId === 1 && canCreate)
        }
        operatorId={operatorId}
        organizationId={organizationId}
        isSelectedOnCreate
        isEditingFromMulti={isMultiple && contactTypeId === 1}
      />
    </>
  );
};

ContactsDropdown.defaultProps = {
  isInModal: false
};

export default ContactsDropdown;
