import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Tooltip } from 'reactstrap';
import Icon from 'utility/icomoon';
import * as authSelectors from '../../../redux/reducers/auth';

interface Props {
  id: string;
  icon: string;
  permissions?: any[];
  handleClick?: any;
  className?: string;
  tooltipMessage?: any;
  visible?: boolean;
  color?: string;
  cursor?: string;
  size?: number;
}

const DefaultSimpleButton = (props: Props) => {
  const store = useSelector((state) => state);
  const {
    handleClick,
    tooltipMessage,
    icon,
    id,
    permissions,
    color,
    cursor = 'pointer',
    size,
    className,
    visible = true
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return !permissions || authSelectors.getAuthUserHasPermissionsAny(store, permissions) ? (
    <div id={id} className={className}>
      {visible ? (
        <>
          <Icon
            style={{ cursor }}
            icon={icon}
            color={color}
            size={size ?? 16}
            onClick={() => handleClick && handleClick()}
          />
          {tooltipMessage && (
            <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              target={id}
              toggle={toggle}
              trigger="click hover"
            >
              {tooltipMessage}
            </Tooltip>
          )}
        </>
      ) : null}
    </div>
  ) : null;
};

DefaultSimpleButton.defaultProps = {
  id: 'DefaultSimpleButton',
  icon: 'Plus',
  isDisabled: false,
  className: ''
};

export default DefaultSimpleButton;
