import * as types from '../types';

export const startFetchingAgents = (filters = {}) => ({
  type: types.AGENTS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingAgents = (entities, order) => ({
  type: types.AGENTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingAgents = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.AGENTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingAgents = (entityTypeId) => ({
  type: types.AGENTS_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});
export const completePartialFetchingAgents = (entities, order) => ({
  type: types.AGENTS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingAgents = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.AGENTS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingAgent = (agentId) => ({
  type: types.AGENT_FETCH_STARTED,
  payload: agentId
});

export const completeFetchingAgent = (agent) => ({
  type: types.AGENT_FETCH_COMPLETED,
  payload: agent
});

export const failFetchingAgent = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.AGENT_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingAgent = (agent) => ({
  type: types.AGENT_ADD_STARTED,
  payload: agent
});
export const completeAddingAgent = (oldId, agent) => ({
  type: types.AGENT_ADD_COMPLETED,
  payload: { oldId, agent }
});
export const failAddingAgent = (oldId, error) => ({
  type: types.AGENT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingAgent = (agent) => ({
  type: types.AGENT_EDIT_STARTED,
  payload: agent
});
export const completeEditingAgent = (agent) => ({
  type: types.AGENT_EDIT_COMPLETED,
  payload: agent
});
export const failEditingAgent = (oldAgent, error) => ({
  type: types.AGENT_EDIT_FAILED,
  payload: {
    oldAgent,
    error
  }
});

export const startRemovingAgent = (agentId) => ({
  type: types.AGENT_REMOVE_STARTED,
  payload: {
    agentId
  }
});
export const completeRemovingAgent = (agentId) => ({
  type: types.AGENT_REMOVE_COMPLETED,
  payload: {
    agentId
  }
});
export const failRemovingAgent = (agentId, error) => ({
  type: types.AGENT_REMOVE_FAILED,
  payload: {
    agentId,
    error
  }
});

export const selectAgent = (agentId) => ({
  type: types.AGENT_SELECTED,
  payload: agentId
});

export const selectAllAgents = (
  agentIds = [] //List of AgentIds
) => ({
  type: types.AGENTS_ALL_SELECTED,
  payload: agentIds
});

export const deselectAgent = (agentId) => ({
  type: types.AGENT_DESELECTED,
  payload: agentId
});

export const deselectAllAgents = (
  agentIds = [] //List of AgentIds
) => ({
  type: types.AGENTS_ALL_DESELECTED,
  payload: agentIds
});
