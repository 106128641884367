import Icon from '../../utility/icomoon';
import paths from 'views/screens/Notifications/paths';
import permissions from 'views/screens/Notifications/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'notifications',
    title: 'notifications.notifications',
    icon: <Icon size={20} icon="Bell" />,
    permissions: [
      permissions.listEmailNotification,
      permissions.listConsoleNotifications,
      permissions.listWebServiceNotification
    ],
    iconName: 'Bell',
    children: [
      {
        id: 'emailNotificationScreen',
        title: 'notifications.email',
        icon: <Icon size={20} icon="noun_Mail_1707176" />,
        permissions: [permissions.listEmailNotification],
        iconName: 'noun_Mail_1707176',
        navLink: paths.listEmailNotifications,
        screenId: screens.EMAIL_NOTIFICATIONS
      },
      {
        id: 'consoleNotificationScreen',
        title: 'notifications.console',
        icon: <Icon size={20} icon="Consola" />,
        permissions: [permissions.listConsoleNotification],
        iconName: 'Consola',
        navLink: paths.listConsoleNotifications,
        screenId: screens.CONSOLE_NOTIFICATIONS
      },
      {
        id: 'webNotificationScreen',
        title: 'notifications.webService',
        icon: <Icon size={20} icon="WebService" />,
        permissions: [permissions.listWebServiceNotification],
        iconName: 'WebService',
        navLink: paths.listWebServiceNotifications,
        screenId: screens.WEB_SERVICE_NOTIFICATIONS
      }
    ]
  }
];
