export const PROTOCOLS_FETCH_STARTED = 'PROTOCOLS_FETCH_STARTED';
export const PROTOCOLS_FETCH_COMPLETED = 'PROTOCOLS_FETCH_COMPLETED';
export const PROTOCOLS_FETCH_FAILED = 'PROTOCOLS_FETCH_FAILED';

export const PROTOCOLS_PARTIAL_FETCH_STARTED = 'PROTOCOLS_PARTIAL_FETCH_STARTED';
export const PROTOCOLS_PARTIAL_FETCH_COMPLETED = 'PROTOCOLS_PARTIAL_FETCH_COMPLETED';
export const PROTOCOLS_PARTIAL_FETCH_FAILED = 'PROTOCOLS_PARTIAL_FETCH_FAILED';

export const PROTOCOLS_ADD_STARTED = 'PROTOCOLS_ADD_STARTED';
export const PROTOCOLS_ADD_COMPLETED = 'PROTOCOLS_ADD_COMPLETED';
export const PROTOCOLS_ADD_FAILED = 'PROTOCOLS_ADD_FAILED';

export const PROTOCOLS_EDIT_STARTED = 'PROTOCOLS_EDIT_STARTED';
export const PROTOCOLS_EDIT_COMPLETED = 'PROTOCOLS_EDIT_COMPLETED';
export const PROTOCOLS_EDIT_FAILED = 'PROTOCOLS_EDIT_FAILED';

export const PROTOCOLS_REMOVE_STARTED = 'PROTOCOLS_REMOVE_STARTED';
export const PROTOCOLS_REMOVE_COMPLETED = 'PROTOCOLS_REMOVE_COMPLETED';
export const PROTOCOLS_REMOVE_FAILED = 'PROTOCOLS_REMOVE_FAILED';

export const PROTOCOLS_SELECTED = 'PROTOCOLS_SELECTED';
export const PROTOCOLS_ALL_SELECTED = 'PROTOCOLS_ALL_SELECTED';
export const PROTOCOLS_DESELECTED = 'PROTOCOLS_DESELECTED';
export const PROTOCOLS_ALL_DESELECTED = 'PROTOCOLS_ALL_DESELECTED';

export interface Protocol {
  isSelected: boolean;
  protocolId: number;
  protocolName: string;
  protocolDescription: string;
  protocolEvents: string;
  protocolPriority: number;
  operatorId: number;
  operatorName: string;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: string | null;
  subdivisionId: number | null;
  subdivisionName: string | null;
  isConfirmed: boolean;
}
