/* -------------------------------------------------------------------------- */
/*                              Schema Subdivisions                           */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const subdivision = new schema.Entity(
  "subdivisions",
  {},
  { idAttribute: "subdivisionId" }
);
export const subdivisions = new schema.Array(subdivision);
