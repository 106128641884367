const permissions = {
  listSensor: 'AD001',
  addSensor: 'AD002',
  editSensor: 'AD003',
  deleteSensor: 'AD004',
  measurementHistory: 'AD010',
  locationSensor: 'AD009'
};

export default permissions;
