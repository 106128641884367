export const MAINTENANCE_LINES_FETCH_STARTED = 'MAINTENANCE_LINES_FETCH_STARTED';
export const MAINTENANCE_LINES_FETCH_COMPLETED = 'MAINTENANCE_LINES_FETCH_COMPLETED';
export const MAINTENANCE_LINES_FETCH_FAILED = 'MAINTENANCE_LINES_FETCH_FAILED';

export const MAINTENANCE_LINES_PARTIAL_FETCH_STARTED = 'MAINTENANCE_LINES_PARTIAL_FETCH_STARTED';
export const MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED =
  'MAINTENANCE_LINES_PARTIAL_FETCH_COMPLETED';
export const MAINTENANCE_LINES_PARTIAL_FETCH_FAILED = 'MAINTENANCE_LINES_PARTIAL_FETCH_FAILED';

export const MAINTENANCE_LINE_ADD_STARTED = 'MAINTENANCE_LINE_ADD_STARTED';
export const MAINTENANCE_LINE_ADD_COMPLETED = 'MAINTENANCE_LINE_ADD_COMPLETED';
export const MAINTENANCE_LINE_ADD_FAILED = 'MAINTENANCE_LINE_ADD_FAILED';

export const MAINTENANCE_LINE_EDIT_STARTED = 'MAINTENANCE_LINE_EDIT_STARTED';
export const MAINTENANCE_LINE_EDIT_COMPLETED = 'MAINTENANCE_LINE_EDIT_COMPLETED';
export const MAINTENANCE_LINE_EDIT_FAILED = 'MAINTENANCE_LINE_EDIT_FAILED';

export const MAINTENANCE_LINE_REMOVE_STARTED = 'MAINTENANCE_LINE_REMOVE_STARTED';
export const MAINTENANCE_LINE_REMOVE_COMPLETED = 'MAINTENANCE_LINE_REMOVE_COMPLETED';
export const MAINTENANCE_LINE_REMOVE_FAILED = 'MAINTENANCE_LINE_REMOVE_FAILED';

export const MAINTENANCE_LINE_COPY_STARTED = 'MAINTENANCE_LINE_COPY_STARTED';
export const MAINTENANCE_LINE_COPY_COMPLETED = 'MAINTENANCE_LINE_COPY_COMPLETED';
export const MAINTENANCE_LINE_COPY_FAILED = 'MAINTENANCE_LINE_COPY_FAILED';

export const MAINTENANCE_LINE_SELECTED = 'MAINTENANCE_LINE_SELECTED';
export const MAINTENANCE_LINES_ALL_SELECTED = 'MAINTENANCE_LINES_ALL_SELECTED';
export const MAINTENANCE_LINE_DESELECTED = 'MAINTENANCE_LINE_DESELECTED';
export const MAINTENANCE_LINES_ALL_DESELECTED = 'MAINTENANCE_LINES_ALL_DESELECTED';

export interface MaintenancePlans {
  isSelected: boolean;
  maintenanceLineId: number;
  maintenanceLineName: string;
  isCycle: boolean;
  operatorId: number;
  operatorName: string;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: null | string;
  subdivisionId: number | null;
  subdivisionName: null | string;
  maintenanceRangesByOdometer: null | string;
  maintenanceRangesByHourMeter: null | string;
  maintenanceLineStatus: number;
  maintenanceNoticeTypeId: number;
  isConfirmed: boolean;
}

export enum MaintenanceNoticeTypes {
  Odometer = 1,
  Date = 2,
  HoursOfWork = 3,
  MaintenanceLinesByOdometer = 4,
  MaintenanceLinesByHourmeter = 5
}
