import { LatLngBoundsExpression } from 'leaflet';
import { useEffect } from 'react';

export const useScript = (url) => {
  useEffect(() => {
    let script;
    try {
      if ($(`script[src="${url}"]`).length === 0) {
        script = document.createElement('script');
        script.src = url;
        script.async = true;
        document.body.appendChild(script);
      }
    } catch {}
  }, [url]);
};

export const getRectBoundsFromLatLng = (latitude, longitude, radius): LatLngBoundsExpression => {
  var dy = radius; //radius in meters
  dy = (dy * 90) / 10002069; //10,000Km es la distancia media del ecuador al polo sobre el meridiano (90grad)
  var dx = Math.cos((latitude * Math.PI) / 180) * 40075000; //40,075Km es circunferencia en el ecuador
  dx = (radius * 360) / dx; //--la dx cambia segun la latitud
  var maxlat = latitude + dy;
  var maxlon = longitude + dx;
  var minlat = latitude - dy;
  var minlon = longitude - dx;

  var bounds = {
    minLatitude: minlat,
    minLongitude: minlon,
    maxLatitude: maxlat,
    maxLongitude: maxlon
  };
  return [
    [bounds.minLatitude, bounds.minLongitude],
    [bounds.maxLatitude, bounds.maxLongitude]
  ];
};
export const getIsInnerGeoreferenceFromLatLng = (latitude, longitude, radius, unitPositions) => {
  var dy = radius; //radius in meters
  dy = (dy * 90) / 10002069; //10,000Km es la distancia media del ecuador al polo sobre el meridiano (90grad)
  var dx = Math.cos((latitude * Math.PI) / 180) * 40075000; //40,075Km es circunferencia en el ecuador
  dx = (radius * 360) / dx; //--la dx cambia segun la latitud
  var maxlat = latitude + dy;
  var maxlon = longitude + dx;
  var minlat = latitude - dy;
  var minlon = longitude - dx;

  var bounds = {
    minLatitude: minlat,
    minLongitude: minlon,
    maxLatitude: maxlat,
    maxLongitude: maxlon
  };
  const matches = unitPositions.filter((unitPosition) => {
    if (
      unitPosition?.latitude >= bounds.minLatitude &&
      unitPosition?.latitude <= bounds.maxLatitude
    ) {
      return unitPosition;
    }
  });

  return { matches: matches, matchStatus: matches.length > 0 };

  /*  return [
    [bounds.minLatitude, bounds.minLongitude],
    [bounds.maxLatitude, bounds.maxLongitude]
  ]; */
};

export const getRectangleFromLatLng = (latitude, longitude, radius) => {
  var dy = radius; //radius in meters
  dy = (dy * 90) / 10002069; //10,000Km es la distancia media del ecuador al polo sobre el meridiano (90grad)
  var dx = Math.cos((latitude * Math.PI) / 180) * 40075000; //40,075Km es circunferencia en el ecuador
  dx = (radius * 360) / dx; //--la dx cambia segun la latitud
  var maxlat = latitude + dy;
  var maxlon = longitude + dx;
  var minlat = latitude - dy;
  var minlon = longitude - dx;

  var bounds = {
    minLatitude: minlat,
    minLongitude: minlon,
    maxLatitude: maxlat,
    maxLongitude: maxlon
  };
  return [
    [bounds.minLatitude, bounds.minLongitude],
    [bounds.minLatitude, bounds.maxLongitude],
    [bounds.maxLatitude, bounds.maxLongitude],
    [bounds.maxLatitude, bounds.minLongitude],
    [bounds.minLatitude, bounds.minLongitude]
  ];
};

export const getTriangleFromLatLng = (latitude, longitude, radius) => {
  var dy = radius; //radius in meters
  dy = (dy * 90) / 10002069; //10,000Km es la distancia media del ecuador al polo sobre el meridiano (90grad)
  var dx = Math.cos((latitude * Math.PI) / 180) * 40075000; //40,075Km es circunferencia en el ecuador
  dx = (radius * 360) / dx; //--la dx cambia segun la latitud
  var maxlat = latitude + dy;
  var maxlon = longitude + dx;
  var minlat = latitude - dy;
  var minlon = longitude - dx;

  var bounds = {
    minLatitude: minlat,
    minLongitude: minlon,
    maxLatitude: maxlat,
    maxLongitude: maxlon
  };
  return [
    [bounds.minLatitude, bounds.minLongitude],
    [bounds.minLatitude, bounds.maxLongitude],
    [bounds.maxLatitude, bounds.maxLongitude],
    [bounds.minLatitude, bounds.minLongitude]
  ];
};
