import ReactHtmlParser from 'react-html-parser';
import {
  Badge,
  Card,
  CardBody,
  CardText,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
  Media
} from 'reactstrap';
import Icon from 'utility/icomoon';
import MapsBasic from 'views/components/Map';
import Avatar from '../../../../@core/components/avatar';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import FilesGallery from '../../../components/FilesGallery';

const style = getComputedStyle(document.body);
const successColor = style.getPropertyValue('--success');
const warningColor = style.getPropertyValue('--warning');
const dangerColor = style.getPropertyValue('--danger');
const infoColor = style.getPropertyValue('--info');

export const getJobDescriptionTemplate = (job) => {
  return job?.jobDescription ? ReactHtmlParser(job.jobDescription) : 'N/A';
};
export const getJobPriorityColor = (priority) => {
  return priority == 1 ? dangerColor : priority == 2 ? warningColor : successColor;
};
export const getImportedJobStatusColorClass = (success) => {
  return success ? 'light-success' : 'light-danger';
};
export const getJobPriorityTemplate = (job) => {
  const color = job.jobPriority == 1 ? 'danger' : job.jobPriority == 2 ? 'warning' : 'success';
  const priorityColor = `light-${color}`;
  return (
    <span
      key={job.jobId}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Badge color={priorityColor} pill style={{ display: 'inline-block', fontSize: '13px' }}>
        <Icon icon={'AlertCircle'} className={'mr-25'} size={15} />
        {appIntl().formatMessage({ id: `jobs.priority.${job.jobPriority}` })}
      </Badge>
    </span>
  );
};

export const getJobTypeIcon = (jobBacklogTypeId) => {
  switch (parseInt(jobBacklogTypeId)) {
    case 1:
      return 'Pickup';
    case 2:
      return 'Delivery';
    case 3:
      return 'PickupAndDelivery';
    case 4:
      return 'LongProcess';
    case 5:
      return 'Maintainance';
    case 6:
      return 'Repair';
    case 7:
      return 'Assistance';
    case 8:
      return 'Visit';
    case 9:
      return 'Emergency';
    case 10:
      return 'Other';
    case 11:
      return 'Meeting';
    default:
      return 'JobsBacklog';
  }
};

export const jobTypesList = [
  {
    jobTypeId: 1,
    icon: <Icon icon={getJobTypeIcon(1)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.1'
  },
  {
    jobTypeId: 2,
    icon: <Icon icon={getJobTypeIcon(2)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.2'
  },
  {
    jobTypeId: 3,
    icon: <Icon icon={getJobTypeIcon(3)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.3'
  },
  {
    jobTypeId: 4,
    icon: <Icon icon={getJobTypeIcon(4)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.4'
  },
  {
    jobTypeId: 5,
    icon: <Icon icon={getJobTypeIcon(5)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.5'
  },
  {
    jobTypeId: 6,
    icon: <Icon icon={getJobTypeIcon(6)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.6'
  },
  {
    jobTypeId: 7,
    icon: <Icon icon={getJobTypeIcon(7)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.7'
  },
  {
    jobTypeId: 8,
    icon: <Icon icon={getJobTypeIcon(8)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.8'
  },
  {
    jobTypeId: 9,
    icon: <Icon icon={getJobTypeIcon(9)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.9'
  },
  {
    jobTypeId: 10,
    icon: <Icon icon={getJobTypeIcon(10)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.10'
  },
  {
    jobTypeId: 11,
    icon: <Icon icon={getJobTypeIcon(11)} className={'mr-25'} size={15} />,
    label: 'jobs.jobType.11'
  }
];

export const getJobTypeTemplate = (job, center = true) => {
  return (
    <div key={job.jobId}>
      <span
        style={
          center
            ? {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }
            : { display: 'flex' }
        }
      >
        <Badge pill color="light-info" style={{ display: 'inline-block', fontSize: '13px' }}>
          <Icon icon={getJobTypeIcon(job.jobTypeId)} className={'mr-25'} size={15} />
          {appIntl().formatMessage({
            id: `jobs.jobType.${job.jobTypeId}`
          })}
        </Badge>
      </span>
    </div>
  );
};

export const getJobRequesterTemplate = (job) => {
  return job.requesterUserName ? (
    <span
      key={job.jobId}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Avatar
        // @ts-ignore unreachable error
        img={job.requestUserImageUrl ? job.requestUserImageUrl : undefined}
        color="light-primary"
        className={'mr-25'}
        content={job.requesterCompleteName == null ? '' : job.requesterCompleteName}
        initials
      />
    </span>
  ) : (
    'N/A'
  );
};

export const getJobRequesterInfoTemplate = (job) => {
  return job.requesterUserName ? (
    <span
      key={job.jobId}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Avatar
        // @ts-ignore unreachable error
        img={job.requestUserImageUrl ? job.requestUserImageUrl : undefined}
        color="light-primary"
        className={'mr-25'}
        content={job.requesterCompleteName == null ? '' : job.requesterCompleteName}
        initials
      />
      {`${job.requesterCompleteName}`}
    </span>
  ) : (
    'N/A'
  );
};

export const getUsersText = (job) => {
  let users;
  if (!job.users) {
    users = [];
  } else {
    users = job.users.split(';');
  }

  return users.map((user) => user.split(',')[1]).join(' + ');
};

export const getJobUsersRowTemplate = (job, center = true) => {
  let users;
  if (!job.users) {
    users = [];
  } else {
    users = job.users.split(';');
  }

  return (
    <div
      key={job.jobId}
      style={
        center
          ? {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }
          : {}
      }
    >
      {users.map((userSplit, idx) => {
        const user = userSplit.split(',');
        const userName = user[0];
        const completeName = user[1];
        const userImageUrl = user[2];
        return (
          <span key={idx}>
            <Avatar
              // @ts-ignore unreachable error
              img={userImageUrl?.toUpperCase() != 'NULL' ? userImageUrl : undefined}
              color="light-primary"
              className={'mr-25'}
              content={completeName == null ? '' : completeName}
              initials
            />
          </span>
        );
      })}
    </div>
  );
};

export const getJobNotificationEmailsTemplate = (job) => {
  let notificationEmails;
  if (!job.notificationEmails) {
    notificationEmails = [];
  } else {
    notificationEmails = job.notificationEmails.split(',');
  }
  return notificationEmails.length > 0 ? (
    <div
      key={job.jobId}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3px'
        // alignItems: 'center',
        // justifyContent: 'center'
      }}
    >
      {notificationEmails.map((nemail, idx) => {
        return (
          <span style={{ marginTop: '4px' }} key={idx}>
            <Avatar
              // @ts-ignore unreachable error
              img={undefined}
              color="light-primary"
              className={'mr-25'}
              content={nemail}
              initials
            />
            {`${nemail}`}
          </span>
        );
      })}
    </div>
  ) : (
    'N/A'
  );
};

export const getJobUsersInfoTemplate = (job) => {
  let users;
  if (!job.users) {
    users = [];
  } else {
    users = job.users.split(';');
  }
  return (
    <div
      key={job.jobId}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3px'
        // alignItems: 'center',
        // justifyContent: 'center'
      }}
    >
      {users.map((userSplit, idx) => {
        const user = userSplit.split(',');
        const userName = user[0];
        const completeName = user[1];
        const userImageUrl = user[2];
        return (
          <span style={{ marginTop: '4px' }} key={idx}>
            <Avatar
              // @ts-ignore unreachable error
              img={userImageUrl?.toUpperCase() != 'NULL' ? userImageUrl : undefined}
              color="light-primary"
              className={'mr-25'}
              content={completeName == null ? '' : completeName}
              initials
            />
            {`${completeName} <${user[0]}>`}
          </span>
        );
      })}
    </div>
  );
};

export const getJobFormsInfoTemplate = (job) => {
  let forms;
  if (!job.forms) {
    forms = [];
  } else {
    forms = job.forms.split(';');
  }

  return forms.length > 0 ? (
    <div
      key={job.jobId}
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '3px'
        // alignItems: 'center',
        // justifyContent: 'center'
      }}
    >
      {forms.map((formSplit, idx) => {
        const form = formSplit.split(',');
        const organicationFormId = form[0];
        const organizationFormName = form[1];
        const formResponsesCount = form[2];
        return (
          <span style={{ marginTop: '4px' }} key={idx}>
            <Icon icon={'Feather'} className={'mr-25'} />
            {`${organizationFormName}`}

            <Badge
              pill
              color="light-info"
              className={'ml-25'}
              style={{ display: 'inline-block', fontSize: '13px' }}
            >
              {formResponsesCount}
            </Badge>
          </span>
        );
      })}
    </div>
  ) : (
    'N/A'
  );
};

export const getJobFilesInfoTemplate = (job) => {
  let files;
  if (!job.files) {
    files = [];
  } else {
    files = job.files.split(';');
  }

  return files.length > 0 ? (
    <Row key={job.jobId}>
      <Col md={'12'}>
        <FilesGallery
          files={files.map((fileSplit) => {
            const file = fileSplit.split(',');
            return {
              fileName: file[1],
              fileUrl: file[2],
              fileExtension: file[4]
            };
          })}
        />
      </Col>
    </Row>
  ) : (
    'N/A'
  );
};

export const getTotalEstimatedTimeTemplate = (job) => {
  return <span key={job.jobId}>{job.estimatedDurationText}</span>;
};

export const getTotalExecutionTimeTemplate = (job: {
  jobId?: string | number;
  estimatedDuration?: number;
  totalExecutionTime?: number;
  totalExecutionTimeText?: string;
}) => {
  const totalExecutionIsOnTime =
    job?.totalExecutionTime &&
    job?.estimatedDuration &&
    job?.totalExecutionTime <= job?.estimatedDuration &&
    job.estimatedDuration != 0;
  // const totalExecutionIsLate =
  //   job.totalExecutionTime > job.estimatedDuration && job.estimatedDuration != 0;
  const totalExecutionIsNotEstimated = job.estimatedDuration == 0;
  const totalExecutionTimeColor = totalExecutionIsNotEstimated
    ? 'light-warning'
    : totalExecutionIsOnTime
    ? 'light-success'
    : 'light-info';
  return (
    <span key={job.jobId}>
      <Badge
        pill
        color={totalExecutionTimeColor}
        style={{ display: 'inline-block', fontSize: '13px' }}
      >
        <Icon icon={'Clock'} className={'mr-25'} size={15} />
        {job.totalExecutionTimeText ?? ''}
      </Badge>
    </span>
  );
};

export const getJobStatusColor = (jobStatus) => {
  const colorsJobStatus = {
    canceled: '#490002',
    other: '#626262',
    finalized: '#2ed573',
    active: '#7D39F6',
    rescheduled: '#1AA6FB',
    scheduledFuture: '#FD9800',
    paused: '#ff7a00',
    incomplete: '#ff4459',
    backlog: '#00EAD5'
  };
  switch (parseInt(jobStatus)) {
    case 1:
      return colorsJobStatus.active;
    case 2:
      return colorsJobStatus.paused;
    case 3:
      return colorsJobStatus.finalized;
    case 4:
      return colorsJobStatus.incomplete;
    case 5:
      return colorsJobStatus.rescheduled;
    case 6:
      return colorsJobStatus.scheduledFuture;
    case 7:
      return colorsJobStatus.canceled;
    case 8:
      return colorsJobStatus.backlog;

    default:
      return colorsJobStatus.other;
  }
};

export const getJobStatusColorClass = (jobStatus) => {
  switch (parseInt(jobStatus)) {
    case 1:
      return 'light-active';
    case 2:
      return 'light-paused';
    case 3:
      return 'light-finalized';
    case 4:
      return 'light-incomplete';
    case 5:
      return 'light-rescheduled';
    case 6:
      return 'light-scheduled';
    case 7:
      return 'light-canceled';
    case 8:
      return 'light-backlog';

    default:
      return 'light-other';
  }
};

export const getJobStatusIcon = (jobStatus) => {
  switch (parseInt(jobStatus)) {
    case 1:
      return 'PlayCircle';
    case 2:
      return 'PauseCircle';
    case 3:
      return 'CheckCircle';
    case 4:
      return 'AlertTriangle';
    case 5:
      return 'RotateCw';
    case 6:
      return 'Calendar';
    case 7:
      return 'XCircle';
    case 8:
      return 'Server';
    default:
      return 'Jobs';
  }
};

export const getJobStatusTemplate = (job) => {
  return (
    <span key={job.jobId}>
      <Badge
        pill
        color={getJobStatusColorClass(job.jobStatus)}
        style={{
          display: 'inline-block',
          fontSize: '13px'
        }}
      >
        <Icon icon={getJobStatusIcon(job.jobStatus)} className={'mr-25'} size={15} />
        {job.jobStatusText}
      </Badge>
    </span>
  );
};

export const cardJobTemplate = (job: any) => {
  return (
    <div className={'card-template rounded'}>
      <div className="e-card-header">
        <div className="e-card-header-caption">
          <div className="e-card-header-title e-tooltip-text">{job.jobTitle}</div>
          <div className="e-tooltip-text">{getJobTypeTemplate(job, false)}</div>
        </div>
      </div>
      <div className="e-card-content">
        <div className="e-text">{getJobDescriptionTemplate(job)}</div>
      </div>
      <div className="e-card-custom-footer">
        <Row>
          <Col sm="6" className="pr-0">
            {getJobUsersRowTemplate(job, false)}
          </Col>
          <Col sm="6" className="pl-0">
            <div>
              <p className={'mb-0'}>{job.startDateFormat}</p>
              <p className={'mb-0'}>{job.dueDateFormat}</p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export const getJobStatusTitleColumnsTemplate = (job) => {
  return (
    <Media
      style={{
        width: '320px'
      }}
      className="d-flex align-items-center justify-content-between"
    >
      <h4
        className="mb-0"
        style={{
          fontSize: '16px'
        }}
      >
        {job.jobStatusText}
      </h4>
      <Badge
        pill
        color={getJobStatusColorClass(job.jobStatus)}
        className={'ml-auto'}
        style={{
          display: 'inline-block',
          fontSize: '13px'
        }}
      >
        {`${job.count} ${job.intlTasks}`}
      </Badge>
    </Media>
  );
};

export const getJobStatusText = (job) => {
  const today = new Date();
  if (
    job.startDate != null &&
    new Date(job.startDate).getDate() == today.getDate() &&
    job.jobStatus == 6
  ) {
    return appIntl().formatMessage({ id: `jobs.status.${job.jobStatus}.today` });
  }
  return appIntl().formatMessage({ id: `jobs.status.${job.jobStatus}` });
};

export const getJobLocationTemplate = (job) => {
  return (
    job.locationId != null && (
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Icon
          icon="Check"
          className={'mr-25'}
          color={job.onJobLocationCheck ? `${successColor}` : `${infoColor}`}
        />
      </span>
    )
  );
};
export const getJobLocationInfoTemplate = (job) => {
  return job.locationId != null ? (
    <div key={job.jobId}>
      <MapsBasic
        mapOptions={{
          className: '',
          style: { height: '35vh' }
        }}
        mapLocationsOptions={{
          hideMarkersByZoom: false,
          clusterMarkers: false,
          showLocations: true,
          setMapPositionToLocationBounds: true,
          useRectangleBounds: false,
          useSlidingpanelLocationInformation: false,
          locations: [
            {
              locationId: job.locationId,
              latitude: job.latitude,
              longitude: job.longitude,
              locationName: job.locationName,
              locationClassIconName: getJobTypeIcon(job.jobTypeId),
              locationClassDefaultColor: job.onJobLocationCheck ? `${successColor}` : `${infoColor}`
            }
          ]
        }}
        rulerControlOptions={{ useControl: false }}
      ></MapsBasic>
    </div>
  ) : null;
};

export const followingJobsSuccessChangeStatus = ({
  messageId,
  propertyChange,
  status,
  successJobs,
  intl
}) => {
  return (
    <Card className="webtrack bg-transparent border-success shadow-none">
      <CardBody>
        <CardText>
          {intl.formatMessage(
            { id: messageId },
            { propertyChange: intl.formatMessage({ id: propertyChange }), status: status }
          )}
        </CardText>
        <ListGroup flush>
          {successJobs?.map((job, i) => {
            return (
              <ListGroupItem key={i}>
                <Badge color="light-dark-gray" pill className="mr-1">
                  {job.jobId}
                </Badge>
                <span>{job.jobTitle}</span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

export const minsToDurationTimeText = (mins: number) => {
  // time definitions in order to make it explicit
  const minutesInADay = 3600;
  const secondsInAHour = 3600;
  const secondInAMinute = 60;
  const hoursInADay = 24;

  let seconds = Math.round(mins * secondInAMinute); // calculates all the seconds
  const days = Math.floor(seconds / (minutesInADay * hoursInADay)); //calculates the days

  seconds -= days * secondsInAHour * hoursInADay; // subtract the days from the seconds

  const hours = Math.floor(seconds / secondsInAHour); // calculates all the hours

  seconds -= hours * secondsInAHour; // subtract hour seconds from second

  const minutes = Math.floor(seconds / secondInAMinute); // calculates minutes

  seconds -= minutes * secondInAMinute; // subtract all the minute seconds from the seconds

  // ['12 d', '5 h', '15 m', '10 s']
  const timeArray: string[] = [];
  days && timeArray.push(days + 'd');
  (days || hours) && timeArray.push(hours + 'h');
  (days || hours || minutes) && timeArray.push(minutes + 'm');
  timeArray.push(seconds + 's');

  // '12d 5h 15m 10s'
  return timeArray.join(' ');
};

export const convertHtmlToText = (html) => {
  // Create a new div element
  var tempDivElement = document.createElement('div');

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html;

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || '';
};
