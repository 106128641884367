import { combineReducers } from 'redux';
import jobsBacklog from './backlog';
import jobsBoard from './board';
import jobsReport from './report';
import * as types from '../types';
import uniq from 'lodash/uniq';

const byId = (state = {}, action) => {
  switch (action.type) {
    case types.JOB_FETCH_COMPLETED: {
      const { job } = action.payload;
      const newState = { ...state };
      newState[job.jobId] = {
        ...state[job.jobId],
        ...job,
        isConfirmed: true
      };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.JOB_FETCH_COMPLETED: {
      const { job } = action.payload;
      return uniq([...state, job.jobId]);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.JOB_FETCH_STARTED: {
      return true;
    }
    case types.JOB_FETCH_COMPLETED:
    case types.JOB_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.JOB_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOB_FETCH_STARTED:
    case types.JOB_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const jobs = combineReducers({
  jobsBacklog,
  jobsBoard,
  jobsReport,
  byId,
  order,
  isFetching,
  errorFetching
});

export default jobs;

//Own state
export const getOwnState = (state) => state.jobs;

//Information
export const getJob = (state, jobId) => {
  const job = getOwnState(state).byId[jobId];
  if (job) {
    return job;
  } else return undefined;
};
export const getJobsList = (state) =>
  getOwnState(state)
    .order.map((id) => getJob(state, id))
    .filter((job) => job && job.jobStatus > 0);

export const getJobsListByMembership = (state, membership) => {
  return getJobsList(state).filter(
    (job) =>
      membership &&
      (!membership.operatorId || membership.operatorId === job.operatorId) &&
      (!membership.organizationId || membership.organizationId === job.organizationId) &&
      (!membership.divisionId || membership.divisionId === job.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === job.subdivisionId)
  );
};

//Status of sagas
export const isFetchingJob = (state) => getOwnState(state).isFetching;

//Errors
export const getFetchingJobErrors = (state) => getOwnState(state).errorFetching;
