import { call, takeEvery, put } from 'redux-saga/effects';
import { normalize } from 'normalizr';
import * as actions from 'views/screens/vehicleMaintenance/actions/preventiveNotices/noticesByHourmeter';
import * as types from 'views/screens/vehicleMaintenance/types/preventiveNotices/noticesByHourmeter';
import * as schemas from 'views/screens/vehicleMaintenance/schemas/preventiveNotices/notices';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from 'redux/utils/alerts';
import {
  deleteNotice,
  getNoticeByHourmeterList,
  saveNoticeByHourmeter,
  renewNoticeByHourmeter
} from 'views/screens/vehicleMaintenance/services/noticeService';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  NoticeByDateRequest,
  NoticeByHourmeterRequest
} from 'views/screens/vehicleMaintenance/types';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchNoticesList(action) {
  try {
    const jsonResult = yield call(getNoticeByHourmeterList);
    const entitiesCop = jsonResult.map((result) => camelcaseKeys(result));

    const {
      entities: { notices },
      result //order
    } = normalize(entitiesCop, schemas.notices); //normalize data to byId and order
    yield put(actions.completeFetchingNotices(notices, result));
  } catch (err) {
    const error = {
      errorMessage: err?.toString() ?? '',
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingNotices(error));
  }
}

export function* watchFetchNoticesList() {
  yield takeEvery(types.NOTICES_BY_HOURMETER_FETCH_STARTED, fetchNoticesList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchNoticesList(action) {}

export function* watchPartialFetchNoticesList() {
  yield takeEvery(types.NOTICES_BY_HOURMETER_PARTIAL_FETCH_STARTED, partialFetchNoticesList);
}
/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addNotice(action: PayloadAction<NoticeByHourmeterRequest>) {
  const request = action.payload;
  try {
    // @ts-ignore
    const jsonResult = yield call(saveNoticeByHourmeter, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeAddingNotice(
        `${addedData.unitId}-${addedData.noticeId}`,
        request?.tempId ?? '',
        {
          ...request,
          ...addedData
        }
      )
    );
    yield put(actions.startFetchingNotices());
  } catch (e) {
    yield put(actions.failAddingNotice(request.tempId, e));
  }
}

export function* watchAddNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_HOURMETER_ADD_STARTED, addNotice);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editNotice(action: PayloadAction<NoticeByHourmeterRequest>) {
  const request = action.payload;
  try {
    // @ts-ignore
    const jsonResult = yield call(saveNoticeByHourmeter, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeEditingNotice({
        ...request,
        ...addedData
      })
    );
    yield put(actions.startFetchingNotices());
  } catch (e) {
    yield put(actions.failEditingNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchEditNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_HOURMETER_EDIT_STARTED, editNotice);
}

/* -------------------------------------------------------------------------- */
/*                                    RENEW                                    */
/* -------------------------------------------------------------------------- */
function* renewNotice(action: PayloadAction<NoticeByHourmeterRequest>) {
  const request = action.payload;
  try {
    const jsonResult = yield call(renewNoticeByHourmeter, request);
    const addedData = camelcaseKeys(jsonResult);
    yield put(
      actions.completeRenewNotice({
        ...request,
        ...addedData
      })
    );
    yield put(actions.startFetchingNotices());
  } catch (e) {
    yield put(actions.failRenewNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchRenewNoticesStarted() {
  yield takeEvery(types.NOTICE_BY_HOURMETER_RENEW_STARTED, renewNotice);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeNotice(action: PayloadAction<{ noticeId: number; unitId: number }>) {
  const request = action.payload;
  try {
    yield call(deleteNotice, request);
    yield put(actions.completeRemovingNotice(`${request.unitId}-${request.noticeId}`));
  } catch (e) {
    yield put(actions.failRemovingNotice(`${request.unitId}-${request.noticeId}`, e));
  }
}

export function* watchRemoveNoticeStarted() {
  yield takeEvery(types.NOTICE_BY_HOURMETER_REMOVE_STARTED, removeNotice);
}
