// @ts-nocheck
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet.icon.glyph';
import { useEffect, useRef } from 'react';
import { Marker, Popup, Rectangle, useMap, Circle, GeoJSON, Polygon } from 'react-leaflet';
import { getRectBoundsFromLatLng } from '../../utils';
import wkx from 'wkx';

export interface MapGeofenceEditOptions {
  usePopovers?: boolean;
  geofence: any;
  onGeofenceDragend?: Function;
  onGeofenceEdit?: Function;
  showGeofence: boolean;
  isMapFullScreen?: boolean;
}

/* -------------------------------------------------------------------------- */
/*                 MapGeofenceEdit to edit a geofence in map                  */
/* -------------------------------------------------------------------------- */
const MapGeofenceEdit = (props: MapGeofenceEditOptions) => {
  const map = useMap();
  const { showGeofence, geofence, onGeofenceEdit, usePopovers, isMapFullScreen = false } = props;

  const zoomMapAfterEdit = !isMapFullScreen;
  const zoomMapToGeofence = (geofence) => {
    if (geofence.shape == 'C') {
      let geofenceGeoJSON = WKBtoGeoJSON(geofence, geofence.geofenceWktString);
      let feature = L.latLng(geofenceGeoJSON.coordinates[1], geofenceGeoJSON.coordinates[0]);
      map.flyToBounds(feature.toBounds(geofence.radius * 2 ?? 300), {});
    } else {
      let geofenceGeoJSON = WKBtoGeoJSON(geofence, geofence.geofenceWktString);
      let feature = L.geoJson(geofenceGeoJSON);
      // map.setView(feature.getBounds().getCenter(), 16);
      map.flyToBounds(feature.getBounds(), {});
    }
  };
  const WKBtoGeoJSON = (geofence, wkt) => {
    // let wkbBuffer = new Buffer(hex);
    if (wkt) {
      let geometry = wkx.Geometry.parse(wkt);
      if (geofence.shape == 'P') {
        geometry.type = 'LineString';
      }
      const geoJSON = geometry.toGeoJSON();

      return geoJSON;
    }
    return undefined;
  };

  useEffect(() => {
    try {
      geofenceRef.current.pm.enableLayerDrag();
      geofenceRef.current.pm.enable({ removeLayerBelowMinVertexCount: false, draggable: true });
      if (usePopovers) {
        geofenceRef.current.openPopup();
      }
    } catch {}
    if (geofence && Object.keys(geofence).length > 0) {
      if (zoomMapAfterEdit) zoomMapToGeofence(geofence);
    }
  }, [geofence.geofenceId]);

  useEffect(() => {
    try {
      if (geofenceRef.current) {
        geofenceRef.current.pm.enableLayerDrag();
        geofenceRef.current.pm.enable({ removeLayerBelowMinVertexCount: false, draggable: true });
      }
    } catch {}
  }, [geofence.geofenceWktString]);

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const geofenceRef = useRef(null);

  const geofenceGeoJSON = WKBtoGeoJSON(geofence, geofence.geofenceWktString);

  return (
    <>
      {showGeofence && geofenceGeoJSON && (
        <div>
          {geofence.shape == 'C' && (
            <>
              <Circle
                ref={geofenceRef}
                // center={L.latLng(geofenceGeoJSON.coordinates[1], geofenceGeoJSON.coordinates[0])}
                center={L.GeoJSON.coordsToLatLng(geofenceGeoJSON.coordinates)}
                radius={geofence.radius}
                pathOptions={{ color: geofence.color ?? primary }}
                eventHandlers={{
                  click: (e) => {},
                  'pm:edit': (e) => {
                    var geometry = wkx.Geometry.parseGeoJSON(e.target.toGeoJSON().geometry);

                    if (onGeofenceEdit)
                      onGeofenceEdit({
                        geofenceWktString: geometry.toWkt(),
                        radius: e.target.getRadius()
                      });
                    if (zoomMapAfterEdit)
                      zoomMapToGeofence({
                        ...geofence,
                        geofenceWktString: geometry.toWkt(),
                        radius: e.target.getRadius()
                      });
                  },
                  'pm:dragend': (e) => {
                    e.layer.pm.enableLayerDrag();
                    e.layer.pm.enable({ draggable: true });
                    var geometry = wkx.Geometry.parseGeoJSON(e.target.toGeoJSON().geometry);
                    if (onGeofenceEdit)
                      onGeofenceEdit({
                        geofenceWktString: geometry.toWkt(),
                        radius: e.target.getRadius()
                      });
                    if (zoomMapAfterEdit)
                      zoomMapToGeofence({
                        ...geofence,
                        geofenceWktString: geometry.toWkt(),
                        radius: e.target.getRadius()
                      });
                  }
                }}
              >
                {usePopovers && (
                  <Popup>
                    <b className="text-center" style={{ fontSize: '14px' }}>
                      {geofence.geofenceName}
                    </b>
                  </Popup>
                )}
              </Circle>
            </>
          )}

          {geofence.shape == 'R' && (
            <>
              <Rectangle
                ref={geofenceRef}
                bounds={
                  geofenceGeoJSON.type == 'LineString'
                    ? L.GeoJSON.coordsToLatLngs(geofenceGeoJSON.coordinates)
                    : L.GeoJSON.coordsToLatLngs(geofenceGeoJSON.coordinates[0])
                }
                pathOptions={{ color: geofence.color ?? primary }}
                eventHandlers={{
                  click: (e) => {},
                  'pm:edit': (e) => {
                    var geometry = wkx.Geometry.parseGeoJSON(e.target.toGeoJSON().geometry);
                    if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                    if (zoomMapAfterEdit)
                      zoomMapToGeofence({
                        ...geofence,
                        geofenceWktString: geometry.toWkt()
                      });
                  },

                  'pm:dragend': (e) => {
                    e.layer.pm.enableLayerDrag();
                    e.layer.pm.enable({ draggable: true });
                    var geometry = wkx.Geometry.parseGeoJSON(e.target.toGeoJSON().geometry);

                    if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                    if (zoomMapAfterEdit)
                      zoomMapToGeofence({
                        ...geofence,
                        geofenceWktString: geometry.toWkt()
                      });
                  }
                }}
              >
                {usePopovers && (
                  <Popup>
                    <b className="text-center" style={{ fontSize: '14px' }}>
                      {geofence.geofenceName}
                    </b>
                  </Popup>
                )}
              </Rectangle>
            </>
          )}

          {geofence.shape == 'P' &&
            ['MultiPolygon', 'GeometryCollection'].includes(geofenceGeoJSON.type) && (
              <>
                <GeoJSON
                  ref={geofenceRef}
                  data={geofenceGeoJSON}
                  pathOptions={{ color: geofence.color ?? primary }}
                  eventHandlers={{
                    click: (e) => {},
                    'pm:edit': (e) => {
                      var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);
                      if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                      if (zoomMapAfterEdit)
                        zoomMapToGeofence({
                          ...geofence,
                          geofenceWktString: geometry.toWkt()
                        });
                    },
                    'pm:dragend': (e) => {
                      e.layer.pm.enableLayerDrag();
                      e.layer.pm.enable({ draggable: true });
                      var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);
                      if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                      if (zoomMapAfterEdit)
                        zoomMapToGeofence({
                          ...geofence,
                          geofenceWktString: geometry.toWkt()
                        });
                    }
                  }}
                >
                  {usePopovers && (
                    <Popup>
                      <b className="text-center" style={{ fontSize: '14px' }}>
                        {geofence.geofenceName}
                      </b>
                    </Popup>
                  )}
                </GeoJSON>
              </>
            )}
          {geofence.shape == 'P' &&
            !['MultiPolygon', 'GeometryCollection'].includes(geofenceGeoJSON.type) && (
              <>
                <Polygon
                  ref={geofenceRef}
                  positions={
                    geofenceGeoJSON.type == 'LineString'
                      ? L.GeoJSON.coordsToLatLngs(geofenceGeoJSON.coordinates)
                      : // geofenceGeoJSON.coordinates.map((coords) => [coords[1], coords[0]])
                        // geofenceGeoJSON.coordinates[0].map((coords) => [coords[1], coords[0]])
                        L.GeoJSON.coordsToLatLngs(geofenceGeoJSON.coordinates[0])
                  }
                  pathOptions={{ color: geofence.color ?? primary }}
                  eventHandlers={{
                    click: (e) => {},
                    'pm:edit': (e) => {
                      var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);
                      if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                      if (zoomMapAfterEdit)
                        zoomMapToGeofence({
                          ...geofence,
                          geofenceWktString: geometry.toWkt()
                        });
                    },
                    'pm:dragend': (e) => {
                      e.layer.pm.enableLayerDrag();
                      e.layer.pm.enable({ draggable: true });
                      var geometry = wkx.Geometry.parseGeoJSON(e.layer.toGeoJSON().geometry);
                      if (onGeofenceEdit) onGeofenceEdit({ geofenceWktString: geometry.toWkt() });
                      if (zoomMapAfterEdit)
                        zoomMapToGeofence({
                          ...geofence,
                          geofenceWktString: geometry.toWkt()
                        });
                    }
                  }}
                >
                  {usePopovers && (
                    <Popup>
                      <b className="text-center" style={{ fontSize: '14px' }}>
                        {geofence.geofenceName}
                      </b>
                    </Popup>
                  )}
                </Polygon>
              </>
            )}
          {geofence.shape == 'U' && (
            <GeoJSON
              // pmIgnore={true}
              ref={geofenceRef}
              style={{ color: geofence.color }}
              data={geofenceGeoJSON}
            >
              {usePopovers && (
                <Popup>
                  <b className="text-center" style={{ fontSize: '14px' }}>
                    {geofence.geofenceName}
                  </b>
                </Popup>
              )}
            </GeoJSON>
          )}
        </div>
      )}
      )
    </>
  );
};

MapGeofenceEdit.defaultProps = {
  geofence: {},
  showGeofence: false,
  isMapFullScreen: false,
  usePopovers: true
};

export default MapGeofenceEdit;
