/* -------------------------------------------------------------------------- */
/*                             Componente Switch                              */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Switch genérico que se utiliza en muchas pantallas de la aplicación.
import { useEffect, useState, Fragment } from 'react';
import { FormGroup, CustomInput, Tooltip } from 'reactstrap';

interface Props {
  field: any;
  label: any;
  tooltipMessage?: string;
  forceinitialvalue?: boolean;
  additionalonchangefunction?: Function;
  className?: string;
  disabled?: boolean;
  onAfterChange?: Function;
}

const SwitchInput = (props: Props) => {
  const {
    field,
    label,
    forceinitialvalue,
    disabled = false,
    additionalonchangefunction,
    tooltipMessage = null,
    className = 'mt-2 mb-2',
    onAfterChange = (v, t) => {}
  } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    if (field.value != undefined) onChangeFinal(field.value);
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue != undefined) onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);

    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };

  return (
    <>
      <FormGroup className={className}>
        <CustomInput
          type="switch"
          id={field.name}
          name={field.name}
          label={<div id={'tooltip-' + field.name}>{label}</div>}
          checked={field.value ?? ''}
          onChange={() => {
            onChangeFinal(!field.value);
            onAfterChange(!field.value, field.name);
          }}
          disabled={disabled}
        />
      </FormGroup>
      {tooltipMessage && (
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          target={'tooltip-' + field.name}
          toggle={toggle}
          trigger="click hover"
        >
          {tooltipMessage}
        </Tooltip>
      )}
    </>
  );
};

SwitchInput.defaultProps = {
  label: '',
  initialValue: false
};

export default SwitchInput;
