import * as types from '../../types/routesExecutions';

export const startFetchingRoutesExecutions = (filters = {}) => ({
  type: types.ROUTES_EXECUTIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingRoutesExecutions = (entities, order, indicators) => ({
  type: types.ROUTES_EXECUTIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    indicators
  }
});
export const failFetchingRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startCancelRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_CANCEL_STARTED,
  payload: { routes }
});
export const completeCancelRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_CANCEL_COMPLETED,
  payload: { routes }
});
export const failCancelRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_CANCEL_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startEditRouteExecution = (routeExecution) => ({
  type: types.ROUTE_EXECUTION_EDIT_STARTED,
  payload: routeExecution
});
export const completeEditRouteExecution = (routeExecution) => ({
  type: types.ROUTE_EXECUTION_EDIT_COMPLETED,
  payload: routeExecution
});
export const failEditRouteExecution = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTE_EXECUTION_EDIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFinalizeRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_FINALIZE_STARTED,
  payload: { routes }
});
export const completeFinalizeRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_FINALIZE_COMPLETED,
  payload: { routes }
});
export const failFinalizeRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_FINALIZE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startCompleteRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_COMPLETE_STARTED,
  payload: { routes }
});
export const completeCompleteRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_COMPLETE_COMPLETED,
  payload: { routes }
});
export const failCompleteRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_COMPLETE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startRemoveRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_REMOVE_STARTED,
  payload: { routes }
});
export const completeRemoveRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_REMOVE_COMPLETED,
  payload: { routes }
});
export const failRemoveRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_REMOVE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPauseRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_PAUSE_STARTED,
  payload: { routes }
});
export const completePauseRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_PAUSE_COMPLETED,
  payload: { routes }
});
export const failPauseRoutesExecutions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_EXECUTION_PAUSE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startStartRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_START_STARTED,
  payload: { routes }
});
export const completeStartRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_START_COMPLETED,
  payload: { routes }
});
export const failStartRoutesExecutions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_EXECUTION_START_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startManualTransitRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_MANUAL_TRANSIT_STARTED,
  payload: { routes }
});
export const completeManualTransitRoutesExecutions = (routes: any[]) => ({
  type: types.ROUTES_EXECUTION_MANUAL_TRANSIT_COMPLETED,
  payload: { routes }
});
export const failManualTransitRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_MANUAL_TRANSIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startChangeToAlternateRoute = (routeAssignmentId, routeId, reason) => ({
  type: types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_STARTED,
  payload: { routeAssignmentId, routeId, reason }
});
export const completeChangeToAlternateRoute = (routeAssignmentId, routeId, reason) => ({
  type: types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_COMPLETED,
  payload: { routeAssignmentId, routeId, reason }
});
export const failChangeToAlternateRoute = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTE_CHANGE_TO_ALTERNATE_ROUTE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startShareRoutesExecutions = (routeAssignments: string, emailList: string) => ({
  type: types.ROUTES_EXECUTION_SHARE_STARTED,
  payload: { routeAssignments, emailList }
});
export const completeShareRoutesExecutions = (routeAssignments: string, emailList: string) => ({
  type: types.ROUTES_EXECUTION_SHARE_COMPLETED,
  payload: { routeAssignments, emailList }
});
export const failShareRoutesExecutions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ROUTES_EXECUTION_SHARE_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAssignUnitRoutesExecutions = (assignation) => ({
  type: types.ROUTES_EXECUTION_ASSIGN_UNIT_STARTED,
  payload: assignation
});
export const completeAssignUnitRoutesExecutions = (assignation) => ({
  type: types.ROUTES_EXECUTION_ASSIGN_UNIT_COMPLETED,
  payload: assignation
});
export const failAssignUnitRoutesExecutions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ROUTES_EXECUTION_ASSIGN_UNIT_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
