import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/job';

interface JobBacklogSaveRequest {
  jobId?: number;
  jobTitle: string;
}

interface JobsBacklogDeleteRequest {
  jobs: string;
}

interface JobBacklogFilters {
  userFetchDate?: Date;
}

interface JobsBacklogChangeStatusRequest {
  jobs: string;
  jobStatus?: number;
  comment?: string;
}

interface JobsBacklogRescheduleJobsRequest {
  jobs: string;
  startDate?: any;
  dueDate?: any;
  comment?: string;
}

interface JobsBacklogChangePriorityRequest {
  jobs: string;
  jobPriority?: number;
}

interface JobsBacklogChangeStartDateRequest {
  jobs: string;
  startDate?: any;
}

interface JobsBacklogChangeDueDateRequest {
  jobs: string;
  dueDate?: any;
}

interface JobsBacklogChangeMinMaxTimeRequest {
  jobs: string;
  maxTime?: number;
}

interface JobsBacklogAssignUsersRequest {
  jobs: string;
  userNames?: string;
}

interface JobsBacklogAssignFormsRequest {
  jobs: string;
  formIds?: string;
}

interface JobsBacklogAssignFilesRequest {
  jobs: string;
  fileIds?: string;
}

interface JobBoardFilters {
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  startDate: Date;
  endDate: Date;
}

interface JobsBoardChangeStatusRequest {
  jobs: string;
  jobStatus?: number;
  comment?: string;
}

interface JobReportFilters {
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  startDate: Date;
  endDate: Date;
}

interface JobReportByUserFilters {
  userName: string;
  operatorId?: number;
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  startDate: Date;
  endDate: Date;
}

export const getJobsBacklogListService = (
  request: JobBacklogFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getJobService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getJob`, request);
};

export const saveJobBacklogService = (
  request: JobBacklogSaveRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: request.jobId ? 'Edit' : 'Create',
    name: request.jobTitle,
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteJobsBacklogService = (
  request: JobsBacklogDeleteRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: ``,
    object: 'jobs.job',
    action: 'Delete',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const changeJobsStatusService = (
  request: JobsBacklogChangeStatusRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changeStatus`, request);
};

export const rescheduleJobsService = (
  request: JobsBacklogRescheduleJobsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/rescheduleJobs`, request);
};

export const changeJobsPriorityService = (
  request: JobsBacklogChangePriorityRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changePriority`, request);
};

export const changeJobsStartDateService = (
  request: JobsBacklogChangeStartDateRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changeStartDate`, request);
};

export const changeJobsDueDateService = (
  request: JobsBacklogChangeDueDateRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changeDueDate`, request);
};

export const changeJobsMinMaxTimeService = (
  request: JobsBacklogChangeMinMaxTimeRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changeMinMaxTime`, request);
};

export const assignJobsUsersService = (
  request: JobsBacklogAssignUsersRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/assignUsers`, request);
};

export const assignJobsFormsService = (
  request: JobsBacklogAssignFormsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/assignForms`, request);
};

export const assignJobsFilesService = (
  request: JobsBacklogAssignFilesRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Edit',
    name: '',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/assignFiles`, request);
};

export const getJobsBoardListService = (request: JobBoardFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getBoard`, request);
};

export const changeJobsStatusBoardService = (
  request: JobsBoardChangeStatusRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/changeStatus`, request);
};

export const getJobsReportListService = (
  request: JobReportFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getReport`, request);
};

export const getJobsReportByUserListService = (
  request: JobReportByUserFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'jobs.job',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getReportByUser`, request);
};

export const importJobsService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importJobs`, request);
};
