/* -------------------------------------------------------------------------- */
/*                          GoogleLocationDropdownInput                       */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un LocationDropdownInput

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { env } from 'env';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-google-places-autocomplete';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import Icon from 'utility/icomoon';
import * as authSelectors from '../../../redux/reducers/auth';
import * as countrySelectors from '../../../redux/reducers/static-catalogs/countries';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';

const GoogleLocationsDropdownInput = (props) => {
  const { field, fieldstate, errorMessage, setLocationType } = props;

  const intl = useIntl();
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const store = useSelector((state) => state);

  const countries = countrySelectors.getCountriesList(store);

  const userCountriesIds = authSelectors
    .getAuthUserCountryIdsForGoogleSearch(store)
    .split(',')
    .map((id) => {
      const country = countries.find((country) => country.countryId === parseInt(id));
      return country ? country.countryCode : null;
    });

  //Se guarda la referencia de google
  const [googleLocation, setGoogleLocation] = useState<any>(null);

  useEffect(() => {
    const getLatLngFromGoogle = async () => {
      const results = await geocodeByAddress(googleLocation?.label);
      const { lat, lng } = await getLatLng(results[0]);
      onChangeFinalGoogleLocation({
        googleLocation: googleLocation,
        locationId: googleLocation?.value?.place_id,
        locationName: googleLocation?.label,
        latitude: lat,
        longitude: lng,
        radius: 50
      });
    };
    if (googleLocation?.label && field?.locationId !== googleLocation?.value?.place_id) {
      getLatLngFromGoogle();
    } else {
      onChangeFinalGoogleLocation(null);
    }
  }, [googleLocation]);

  useEffect(() => {
    if (props?.forceinitialvalue?.googleLocation) {
      setGoogleLocation(props.forceinitialvalue.googleLocation);
      onChangeFinalGoogleLocation(props.forceinitialvalue);
    }
  }, [props?.forceinitialvalue?.googleLocation]);

  const onChangeFinalGoogleLocation = (value) => {
    if (value && props.onSelectChangeLocationType) {
      setLocationType();
    }

    field.onChange(value);

    if (props.additionalonchangefunction) {
      props.additionalonchangefunction(value);
    }
  };
  return (
    <>
      <FetchEntities useCountries />
      <FormGroup>
        {props.label && (
          <Label className="form-label" for={props.id}>
            {props.label}
            {props.required && <span className="required">&nbsp;*</span>}
          </Label>
        )}
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText
              style={{ cursor: 'pointer' }}
              className={'m-0'}
              onClick={setLocationType}
            >
              <UncontrolledTooltip placement={'top'} target={'GoogleImg'}>
                {intl.formatMessage({ id: 'locations.externalLocations' })}
              </UncontrolledTooltip>
              <Icon id={'GoogleImg'} icon={env.REACT_APP_GOOGLE_ICON ?? 'Google1'} />
            </InputGroupText>
          </InputGroupAddon>
          <GooglePlacesAutocomplete
            selectProps={{
              ...props,
              placeholder: intl.formatMessage({ id: 'locations.searchLocations' }),
              styles: {
                menuPortal: (styles) => ({ ...styles, zIndex: 91000 }),
                control: (provided, state) => {
                  return {
                    ...provided,
                    boxShadow: state.isFocused ? 'none!important' : 'none!important',
                    border: state.isFocused
                      ? `1px solid ${primary} !important`
                      : '1px solid #d8d6de !important'
                  };
                },
                singleValue: (provided, state) => {
                  return {
                    ...provided,
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.45,
                    color: '#6e6b7b',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                  };
                },
                menuList: (provided, state) => {
                  return {
                    ...provided,
                    fontWeight: 400,
                    fontSize: '1rem',
                    lineHeight: 1.45,
                    color: '#6e6b7b',
                    transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
                  };
                }
              },
              className:
                fieldstate.error != null
                  ? 'has-error custom-dropdown-input'
                  : 'custom-dropdown-input',
              defaultValue: props?.forceinitialvalue?.googleLocation,
              noOptionsMessage: () => appIntl().formatMessage({ id: 'common.noOptions' }),
              loadingMessage: () => appIntl().formatMessage({ id: 'common.loading' }),
              menuPortalTarget: props.isInModal ? undefined : document.querySelector('body'),
              onBlur: () => field.onBlur(),
              googleLocation,
              onChange: setGoogleLocation
            }}
            autocompletionRequest={{
              componentRestrictions: {
                country: userCountriesIds
              }
            }}
          />
          <InputGroupAddon addonType="append">{props.appendcomponent}</InputGroupAddon>
        </InputGroup>
        {fieldstate.error && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {fieldstate.error?.message || errorMessage || (
              <FormattedMessage id="common.requiredField" />
            )}
          </div>
        )}
      </FormGroup>
    </>
  );
};

export default GoogleLocationsDropdownInput;
