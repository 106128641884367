/* -------------------------------------------------------------------------- */
/*                          Componente NumericInput                           */
/* -------------------------------------------------------------------------- */
// Este componente contiene un NumericInput genérico que se utiliza en muchas pantallas de la aplicación.
// Incluye fechas para aumentar la cantidad en incrementos de 1

import { useState, useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Label, FormGroup, InputGroup, InputGroupAddon, Input } from 'reactstrap';
import * as authSelectors from '../../../redux/reducers/auth';
import { isNotNullOrUndefined, isNullOrUndefined, testIsNumber } from 'utility/Utils';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';

interface Props {
  id: string | number | symbol;
  label?: string | JSX.Element;
  field: ControllerRenderProps | any;
  fieldstate: ControllerFieldState | any;
  required?: boolean;
  errorMessage?: string;
  placeholder?: string;
  autoCompleteInput?: any;
  prependcomponent?: string | any;
  appendcomponent?: string | any;
  metric?:
    | 'distance'
    | 'distance2'
    | 'fuel'
    | 'fuel2'
    | 'speed'
    | 'temperature'
    | 'capacity'
    | 'volume';
  min?: number;
  max?: number;
  step?: number;
  allowUndefined?: boolean;
  useNumberSpinner?: boolean;
  disabled?: boolean;
  allowDecimals?: boolean;
  decimalsPrecision?: number;
  additionalonchangefunction?: (value: number | undefined) => void;
  forceinitialvalue?: number;
  topRightComponent?: any;
  style?: any;
  allowZero?: boolean;
  forceShowDecimals?: boolean;
  onFocus?: (event) => void;
  readonly?: boolean;
}

const NumericalInput: FC<Props> = ({
  id,
  label,
  required,
  field,
  fieldstate,
  errorMessage,
  min,
  max,
  step = '1',
  useNumberSpinner,
  allowUndefined = true,
  metric,
  prependcomponent,
  appendcomponent,
  placeholder,
  allowDecimals,
  decimalsPrecision = 10,
  additionalonchangefunction,
  forceinitialvalue,
  autoCompleteInput,
  topRightComponent,
  disabled = false,
  style = {},
  allowZero,
  forceShowDecimals,
  onFocus
}) => {
  const [fieldValue, setInputValue] = useState(field.value);

  const { authSystems } = useSelector((state) => ({
    authSystems: authSelectors.getAuthSystems(state)
  }));

  const handleChangeNumber = (newValue) => {
    const validate = () => {
      const isEmptyString =
        typeof newValue === 'string' && // is a string
        newValue === '';
      const isNumber = !isNaN(Number(newValue));
      const hasNumberFormat = testIsNumber(newValue);

      return (
        isEmptyString || // regex that test number
        isNumber || // is not NaN
        hasNumberFormat // is a number
      );
    };

    if (validate()) {
      setInputValue(newValue);
    }
  };

  const onChangeFinal = (value) => {
    if (value) {
      let newValue: number | string = Math.max(Number(min), Math.min(Number(max), Number(value)));
      if (!allowDecimals) {
        newValue = Math.round(newValue);
      } else if (allowDecimals && forceShowDecimals) {
        newValue = Number(newValue).toFixed(decimalsPrecision);
      } else {
        newValue = Number(newValue.toFixed(decimalsPrecision));
      }
      handleChangeNumber(newValue);
      field.onChange(newValue);
      if (additionalonchangefunction) {
        additionalonchangefunction(Number(newValue));
      }
    } else if ((value === 0 || value === '0') && allowZero) {
      let newValue: string | number = Math.max(Number(min), Math.min(Number(max), Number(value)));
      if (!allowDecimals) {
        newValue = Math.round(newValue);
      } else if (allowDecimals && forceShowDecimals) {
        newValue = Number(newValue).toFixed(decimalsPrecision);
      } else {
        newValue = Number(newValue.toFixed(decimalsPrecision));
      }
      handleChangeNumber(newValue);
      field.onChange(newValue);
      if (additionalonchangefunction) {
        additionalonchangefunction(Number(newValue));
      }
    } else {
      field.onChange(undefined);
      if (additionalonchangefunction) {
        additionalonchangefunction(undefined);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (allowDecimals) {
      if (event.key === '+' || event.key === 'e' || event.key === 'E') {
        event.preventDefault();
      }
    } else {
      if (event.key === '+' || event.key === 'e' || event.key === 'E' || event.key === '.') {
        event.preventDefault();
      }
    }

    if (event.key === '-' && min && min > 0) {
      event.preventDefault();
    }

    // avoid double dash
    if (event.target.value.length == 0 && event?.key === '-') {
      event.target.value = '';
    }
    if (event.target.value.length > 0 && event?.key === '-') {
      event.preventDefault();
    }
    // avoid double dash
  };

  useEffect(() => {
    handleChangeNumber(field.value);
    onChangeFinal(field.value);
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue) handleChangeNumber(forceinitialvalue);
    if (forceinitialvalue) onChangeFinal(forceinitialvalue);
  }, [forceinitialvalue]);
  return (
    <FormGroup>
      <Label className="form-label" for={id} style={style}>
        {label}
        {required && <span className="required">&nbsp;*</span>}
        {topRightComponent != null && topRightComponent}
      </Label>

      <InputGroup className={useNumberSpinner ? '' : 'number-field-hide-spin'}>
        {prependcomponent && (
          <InputGroupAddon addonType="prepend">{prependcomponent}</InputGroupAddon>
        )}

        <Input
          id={String(id) + 'text'}
          disabled={disabled}
          onBlur={(event) => {
            onChangeFinal(fieldValue);
          }}
          min={min}
          max={max}
          invalid={fieldstate.error != null}
          type="number"
          step={allowDecimals ? 'any' : step}
          onKeyDown={handleKeyDown}
          onChange={(event) => {
            handleChangeNumber(event.target.value);
          }}
          value={fieldValue ?? ''}
          placeholder={placeholder}
          autoComplete={autoCompleteInput}
          onFocus={onFocus}
        />
        {(appendcomponent || metric) && (
          <InputGroupAddon addonType="append">
            {metric ? authSystems[metric] : appendcomponent}
          </InputGroupAddon>
        )}
      </InputGroup>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

NumericalInput.defaultProps = {
  min: -10000000000,
  max: 100000000000,
  step: 1,
  allowDecimals: false,
  decimalsPrecision: 2,
  useNumberSpinner: false,
  readonly: false
};

export default NumericalInput;
