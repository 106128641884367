import deburr from 'lodash/deburr';
import { useState } from 'react';
import { renderToString } from 'react-dom/server';
import { FormattedMessage, useIntl } from 'react-intl';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import {
  Badge,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import useDeepCompareEffect from 'use-deep-compare-effect';
import DefaultButton from 'views/components/DefaultButton';
import {
  isNullOrUndefined,
  isValidEmail,
  normalizeInputText,
  normalizeString
} from '../../../utility/Utils';
import { appIntl } from '../../../utility/context/IntlGlobalProvider';
import Icon from '../../../utility/icomoon';

const { Option, SingleValue, MultiValue, NoOptionsMessage, MultiValueLabel } = components;

const filterOption = (candidate, input) => {
  let showOptionFilter = false;
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data &&
        candidate.data.label &&
        deburr(candidate.data.label.toLowerCase()).includes(deburr(input.toLowerCase())));
  } catch (err) {}
  try {
    showOptionFilter =
      showOptionFilter ||
      (candidate.data.label_intl &&
        deburr(candidate.data.label_intl.toLowerCase()).includes(deburr(input.toLowerCase())));
  } catch (err) {}

  return showOptionFilter;
};
const IconOption = (props) => {
  return (
    <Option {...props}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: (props.data.badge && 'space-between') || 'normal'
        }}
      >
        {props.data.badge !== undefined ? (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
              {`${props.data.label}`}
            </div>
            {props.data.badge && (
              <>
                <Badge
                  id={`badge${props.data.badge.id}`}
                  pill
                  className="mt-25"
                  style={props.data.badge.style}
                >
                  {props.data.badge.text}
                </Badge>
                <UncontrolledTooltip
                  trigger="hover"
                  placement="top"
                  target={`badge${props.data.badge.id}`}
                >
                  {props.data.badge.tooltip}
                </UncontrolledTooltip>
              </>
            )}
          </>
        ) : (
          <>
            {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
            {`${props.data.label}`}
          </>
        )}
      </div>
    </Option>
  );
};
const IconSingleValue = (props) => {
  return (
    <SingleValue {...props}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {`${props.data.label}`}
      </div>
    </SingleValue>
  );
};

const IconMultipleValue = (props) => {
  return (
    <MultiValue {...props}>
      {props.data.identifierIcon && (
        <div style={{ marginRight: '5px' }}>{props.data.identifierIcon}</div>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {props.data.icon != null && <div className="mr-1">{props.data.icon}</div>}
        {`${props.data.label}`}
      </div>
      {props.data.edit && (
        <div className="edit-option-multiselect" style={{ marginLeft: '5px' }}>
          <Icon icon={'Edit2'} size={12} onClick={() => props.data.edit(props.data.id)} />
        </div>
      )}
    </MultiValue>
  );
};

const MultiSelectInput = (props) => {
  const {
    field,
    fieldstate,
    options = [],
    isInModal = false,
    isMultiple = true,
    canCreate = false,
    isLoading = false,
    errorMessage,
    underText = '',
    ...inputProps
  } = props;
  const intl = useIntl();

  const [inputValue, setInputValue] = useState<string>('');
  const [innerOptions, setOptions] = useState<any[]>([]);
  useDeepCompareEffect(() => {
    if (field.value && options.length > 0) {
      if (typeof field.value === 'string') {
        const newValue = options
          .filter((option) =>
            field.value
              .split(',')
              .includes(isNaN(option.value) ? option.value : option.value.toString())
          )
          .map((option) => option.value)
          .join(',');
        if (field.value.length !== newValue.length) field.onChange(newValue);
      } else {
        field.onChange('');
      }
    }
    setOptions(options);
  }, [
    options.map((op) => ({
      ...op,
      icon: typeof op.icon === 'object' ? renderToString(op.icon) : op.icon
    })),
    intl.locale
  ]);

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const styles = {
    control: (provided, state) => {
      return {
        ...provided,

        boxShadow: state.isFocused ? 'none!important' : 'none!important',
        border: state.isFocused ? `1px solid ${primary} !important` : '1px solid #d8d6de !important'
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.45,
        color: '#6e6b7b',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      };
    },
    multiValue: (provided, state) => {
      return {
        ...provided,
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.45,
        color: '#6e6b7b',
        // backgroundColor: 'rgba(0, 51, 204, 0.12)',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,

        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: 1.45,
        color: '#6e6b7b',
        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
      };
    }
  };
  const SelectComponent = canCreate ? CreatableSelect : Select;

  const cache = new CellMeasurerCache({
    defaultHeight: 60,
    fixedWidth: true
  });

  const MenuList = (props) => {
    const rows = props.children;
    const rowRenderer = ({ key, index, parent, style }) => (
      <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
        {({ measure, registerChild }) => (
          <div ref={registerChild} key={key} style={style}>
            {rows[index]}
          </div>
        )}
      </CellMeasurer>
    );

    return rows.length > 0 ? (
      <AutoSizer disableWidth>
        {({ height }) => (
          <List
            containerStyle={{
              width: '100%',
              backgroundColor: '#ffffff',
              maxWidth: '100%'
            }}
            style={{
              width: '100%'
            }}
            width={1}
            height={200}
            rowCount={rows.length || 0}
            rowRenderer={rowRenderer}
            rowHeight={(ref) => {
              const height = cache.rowHeight(ref);
              return height + height * 0.2;
            }}
          />
        )}
      </AutoSizer>
    ) : (
      <NoOptionsMessage {...props} />
    );
  };

  const isOptionSelected = (option, value: string): boolean => {
    if (typeof value !== 'string') {
      return false;
    }
    const values = value.split(',');
    const optionValue = isNaN(option.value) ? option.value : option.value.toString();
    return values.includes(optionValue);
  };

  return (
    <FormGroup key={inputProps.key}>
      <Label className="form-label" for={inputProps.id}>
        {inputProps.label}
        {inputProps.required && <span className="required">&nbsp;*</span>}
      </Label>
      <InputGroup>
        <InputGroupAddon addonType="prepend">{props.prependcomponent}</InputGroupAddon>
        <SelectComponent
          isDisabled={inputProps.isDisabled}
          isMulti={isMultiple}
          styles={styles}
          id={inputProps.id}
          isLoading={isLoading}
          // formatOptionLabel={(option) => option.label.replace(/\s+/g, ' ')}
          // formatOptionLabel={(option) => getValidOptions(option.label)}
          components={{
            Option: IconOption,
            SingleValue: IconSingleValue,
            MultiValue: IconMultipleValue,
            MenuList
          }}
          placeholder={
            inputProps.placeholder ?? appIntl().formatMessage({ id: 'common.selectOption' })
          }
          className={
            fieldstate.error != null ? 'has-error custom-dropdown-input' : 'custom-dropdown-input'
          }
          onChange={(selectedOptions, action) => {
            if (action.action === 'create-option') {
              if (inputProps.onCreate) inputProps.onCreate(selectedOptions);
            }

            if (action.action === 'input-change') {
              const inputValue = selectedOptions.inputValue.replace(/\s+/g, ' ');
              return { ...selectedOptions, inputValue };
            }

            field.onChange(
              selectedOptions
                ? isMultiple
                  ? selectedOptions.map((selected) => selected.value.toString()).join(',')
                  : selectedOptions.value.toString()
                : null
            );
            if (inputProps.onSelect) inputProps.onSelect(selectedOptions);
            setInputValue('');
          }}
          onBlur={field.onBlur}
          isValidNewOption={canCreate ? (fieldValue) => isValidEmail(fieldValue) : null}
          options={innerOptions}
          filterOption={filterOption}
          isClearable={inputProps.isClearable === true}
          value={options.filter((option) =>
            typeof field.value === 'string'
              ? field.value
                  .split(',')
                  .map((value) => value)
                  .includes(isNaN(option.value) ? option.value : option.value.toString())
              : ''
          )}
          noOptionsMessage={(currentValue) =>
            canCreate
              ? appIntl().formatMessage(
                  { id: 'common.invalidEmail' },
                  { value: currentValue.fieldValue }
                )
              : appIntl().formatMessage({ id: 'common.noOptions' })
          }
          maxMenuHeight={250}
          formatCreateLabel={
            canCreate
              ? (value) => appIntl().formatMessage({ id: 'common.create' }, { value })
              : undefined
          }
          menuPortalTarget={isInModal ? undefined : document.querySelector('body')} // fix issue https://github.com/JedWatson/react-select/issues/3263
          inputValue={inputValue}
          onInputChange={(inputValue, action, c) => {
            const inputValueNormalized = normalizeInputText(inputValue);
            if (inputProps.onInputChange) inputProps.onInputChange(inputValueNormalized, action);

            if (typeof inputValueNormalized === 'string') {
              setInputValue(inputValueNormalized);
            }
          }}
        />
        <InputGroupAddon addonType="append">{props.appendcomponent}</InputGroupAddon>
      </InputGroup>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
      {underText !== '' && (
        <div style={{ display: 'flex' }}>
          {inputProps.underIcon &&
            (inputProps.underIcon.iconAction ? (
              <DefaultButton
                id={'underIconButton'}
                icon={inputProps.underIcon.iconName}
                iconSize={10}
                color={inputProps.underIcon.iconColor ?? 'secondary'}
                className="btn-under-icon"
                isDisabled={inputProps.underIcon.iconDisabled ?? false}
                handleClick={inputProps.underIcon.iconAction}
                tooltipMessage={inputProps.underIcon.iconTooltip ?? undefined}
              />
            ) : (
              <Icon
                size={20}
                icon={inputProps.underIcon.iconName}
                color={inputProps.underIcon.iconColor}
                style={inputProps.underIcon.iconStyle ?? {}}
              />
            ))}
          <div className="black-feedback" style={{ display: 'block' }}>
            {underText}
          </div>
        </div>
      )}
    </FormGroup>
  );
};

export default MultiSelectInput;
