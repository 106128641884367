/* -------------------------------------------------------------------------- */
/*                          CategoryDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un CategoryDropdownInput

import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { DefaultRootState } from 'redux/storeConfig/types';
import DropdownInput from 'views/components/DropdownInput';
import * as categoryActions from 'views/screens/vehicleMaintenance/actions/catalogs/categories';
import * as Categorieselectors from 'views/screens/vehicleMaintenance/reducers/catalogs/categories';

interface Props {
  defaultValue?: any;
  name?: string;
  id?: string;
  onSelect?: any;
}

const DropDownCategories = (props) => {
  const store = useSelector((state: DefaultRootState) => state);
  const dispatch = useDispatch();
  // const organizationId = store.auth.user.organizationId;
  const categories = Categorieselectors.getCategoriesList(store);

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  //Fetch Categories if they have not been fetched yet
  useEffect(() => {
    if (!Categorieselectors.haveCategoriesBeenFetched(store)) {
      dispatch(categoryActions.startFetchingCategories());
    }
  }, []);

  //Update reactselect values of  Categories when fetched is finished
  useEffect(() => {
    if (!Categorieselectors.isFetchingListCategories(store)) {
      resetDropdown();
    }
  }, [Categorieselectors.isFetchingListCategories(store)]);

  return (
    <DropdownInput
      {...props}
      name={props.name ?? 'categoryId'}
      id={props.id ?? 'categoryId'}
      key={resetValue}
      isLoading={Categorieselectors.isFetchingListCategories(store)}
      label={<FormattedMessage id="vehicleMaintenance.category" />}
      options={categories.map((category) => {
        return {
          ...category,
          id: category.categoryId,
          value: category.categoryId,
          label: category.categoryName,
          label_intl: category.categoryName
        };
      })}
    />
  );
};

const CategoriesDropdownInput = (props: Props) => {
  return (
    <Field
      {...props}
      name={props?.name ?? 'categoryId'}
      id={props?.id ?? 'categoryId'}
      required={true}
      component={DropDownCategories}
      type={'select'}
      defaultValue={props.defaultValue}
    />
  );
};

export default CategoriesDropdownInput;
