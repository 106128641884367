/* -------------------------------------------------------------------------- */
/*                        Schema Execution Routes                             */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const assignmentByDate = new schema.Entity(
  'assignmentsByDate',
  {},
  { idAttribute: 'routeAssignmentId' }
);
export const assignmentsByDate = new schema.Array(assignmentByDate);

export const idBySchedule = (value) => `${value.routeId}-${value.unitId}-${value.scheduleId}`;

export const assignmentBySchedule = new schema.Entity(
  'assignmentsBySchedule',
  {},
  { idAttribute: idBySchedule }
);
export const assignmentsBySchedule = new schema.Array(assignmentBySchedule);
