import { List, MapPin } from 'react-feather';
import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'offices',
    title: 'offices.offices',
    iconName: 'Branch',
    permissions: [permissions.listOffices],
    navLink: paths.listOffice,
    screenId: screens.LOCATIONS_CATALOG
  }
];
