import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/module';

interface ModuleFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
  unitId?: number;
  propertyTypeId?: number;
}

interface ImportModulesFilters {
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  warrantyProviderTypeId?: number;
  warrantyProviderId?: number;
  devices?: String;
}

export const getModulesListService = (request: ModuleFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'modules.module',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getModulesDropdown = (request: ModuleFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'modules.module',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDropdown`, request);
};

export const importModulesService = async (
  request: ImportModulesFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'modules.module',
    action: 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importModules`, request);
};
