import { List } from 'react-feather';
import paths from 'views/screens/Geofences/paths';
import permissions from 'views/screens/Geofences/permissions';
import Icon from '../../utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'geofences',
    title: 'geofences.geofences',
    icon: <Icon size={20} icon="Geofences" />,
    iconName: 'Geofences',
    permissions: [permissions.listGeofences, permissions.listAsignedGeofences],
    children: [
      {
        id: 'geofencesListScreen',
        title: 'geofences.catalog',
        icon: <List size={20} />,
        iconName: 'Catalog',
        permissions: [permissions.listGeofences],
        // accessLevels: [9, 13, 15],
        navLink: paths.geofencesList,
        screenId: screens.GEOFENCES_CATALOG
      },
      {
        id: 'geofencesAssignedListScreen',
        title: 'geofences.assignedGeofences',
        icon: <List size={20} />,
        iconName: 'Link',
        permissions: [permissions.listAsignedGeofences],
        // accessLevels: [9, 13, 15],
        navLink: paths.geofencesAssignedList,
        screenId: screens.ASSIGNED_GEOFENCES
      }
    ]
  }
];
