import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import routesExecutions, * as routesSelectorsExecutions from '../routesExecutions';
import routeCheckpoints, * as routeSelectorsCheckpoints from '../routeCheckpoints';
import routesAssignments from '../routesAssignments';
import routesReport, * as routesSelectorsReport from '../routesReport';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routes';
import * as authSelectors from '../../../../../redux/reducers/auth';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import persistReducer from 'redux-persist/es/persistReducer';
import localforage from 'localforage';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ROUTES_FETCH_COMPLETED:
    case types.ROUTES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((routeId) => {
        newState[routeId] = {
          isShownInMap: false,
          isSelected: false,
          ...state[routeId],
          ...entities[routeId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ROUTE_ADD_COMPLETED: {
      const {
        oldId,
        routeInformation: { route }
      } = action.payload;
      const newState = omit(state, oldId);
      newState[route.routeId] = {
        ...state[oldId],
        ...route,
        isShownInMap: true,
        isConfirmed: true
      };
      return newState;
    }

    case types.ROUTE_EDIT_COMPLETED: {
      const { route } = action.payload;
      return {
        ...state,
        [route.routeId]: {
          isShownInMap: false,
          ...omit(state[route.routeId], ['oldRoute']),
          ...omit(route, ['oldRoute']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.ROUTE_ADD_STARTED: {
      const { route } = action.payload;
      const newState = { ...state };
      newState[route.routeId] = {
        isShownInMap: false,
        isSelected: false,
        ...route,
        isConfirmed: false
      };
      return newState;
    }
    case types.ROUTE_EDIT_STARTED: {
      const { route } = action.payload;
      return {
        ...state,
        [route.routeId]: {
          isShownInMap: false,
          ...state[route.routeId],
          oldRoute: state[route.routeId],
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.ROUTE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.ROUTE_EDIT_FAILED: {
      const { oldRoute } = action.payload;
      return {
        ...state,
        [oldRoute.routeId]: {
          isShownInMap: false,
          ...omit(state[oldRoute.routeId], ['oldRoute']),
          ...oldRoute,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ROUTE_REMOVE_COMPLETED: {
      const { routeId } = action.payload;
      return omit(state, routeId);
    }

    //TYPES SHOW AND HIDE ROUTES IN MAP
    case types.ROUTE_SHOW_IN_MAP: {
      const routeId = action.payload;
      const newState = {
        ...state,
        [routeId]: {
          ...state[routeId],
          isShownInMap: true
        }
      };
      return newState;
    }

    case types.ROUTE_HIDE_IN_MAP: {
      const routeId = action.payload;
      return {
        ...state,
        [routeId]: {
          ...state[routeId],
          isShownInMap: false
        }
      };
    }

    case types.ROUTES_ALL_SHOW_IN_MAP: {
      const routeIds = action.payload;
      const newState = { ...state };
      if (routeIds.length == 0) {
        forEach(state, (route: any, routeId) => {
          newState[routeId] = {
            ...route,
            isShownInMap: true
          };
        });
      } else {
        routeIds.forEach((routeId) => {
          newState[routeId] = {
            ...state[routeId],
            isShownInMap: true
          };
        });
      }

      return newState;
    }

    case types.ROUTES_ALL_HIDE_IN_MAP: {
      const routeIds = action.payload;
      const newState = { ...state };
      if (routeIds.length == 0) {
        forEach(state, (route: any, routeId) => {
          newState[routeId] = {
            ...route,
            isShownInMap: false
          };
        });
      } else {
        routeIds.forEach((routeId) => {
          newState[routeId] = {
            ...state[routeId],
            isShownInMap: false
          };
        });
      }

      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.ROUTE_SELECTED: {
      const routeId = action.payload;
      const newState = {
        ...state,
        [routeId]: {
          ...state[routeId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.ROUTE_DESELECTED: {
      const routeId = action.payload;
      return {
        ...state,
        [routeId]: {
          ...state[routeId],
          isSelected: false
        }
      };
    }

    case types.ROUTES_ALL_SELECTED: {
      const routeIds = action.payload;
      const newState = { ...state };
      if (routeIds.length == 0) {
        forEach(state, (route: any, routeId) => {
          newState[routeId] = {
            ...route,
            isSelected: true
          };
        });
      } else {
        routeIds.forEach((routeId) => {
          newState[routeId] = {
            ...state[routeId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.ROUTES_ALL_DESELECTED: {
      const routeIds = action.payload;
      const newState = { ...state };
      if (routeIds.length == 0) {
        forEach(state, (route: any, routeId) => {
          newState[routeId] = {
            ...route,
            isSelected: false
          };
        });
      } else {
        routeIds.forEach((routeId) => {
          newState[routeId] = {
            ...state[routeId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //COPY ROUTE
    case types.ROUTE_COPY_COMPLETED: {
      const { routeId, newRouteId, newRouteName, organizationId, divisionId, subdivisionId } =
        action.payload;
      return {
        ...state,
        [newRouteId]: {
          isSelected: false,
          ...state[routeId],
          routeId: newRouteId,
          routeName: newRouteName,
          organizationId,
          divisionId,
          subdivisionId,
          isConfirmed: true
        }
      };
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ROUTES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.ROUTES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.ROUTE_ADD_COMPLETED: {
      const {
        oldId,
        routeInformation: { route }
      } = action.payload;
      return state.map((routeId) => (routeId === oldId ? route.routeId : routeId));
    }

    case types.ROUTE_REMOVE_COMPLETED: {
      const { routeId } = action.payload;
      return state.filter((routeIdState) => routeIdState !== routeId);
    }

    case types.ROUTES_REMOVE_COMPLETED: {
      const { routeIds } = action.payload;
      return state.filter((routeIdState) => !routeIds.includes(routeIdState));
    }

    //CASE ADD STARTED
    case types.ROUTE_ADD_STARTED: {
      const { route } = action.payload;
      return [...state, route.routeId];
    }

    //CASE ADD FAILED
    case types.ROUTE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((routeIdState) => routeIdState !== oldId);
    }

    //COPY ROUTE
    case types.ROUTE_COPY_COMPLETED: {
      const { newRouteId } = action.payload;
      return [...state, newRouteId];
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_FETCH_COMPLETED:
    case types.ROUTES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_PARTIAL_FETCH_COMPLETED:
    case types.ROUTES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_ADD_STARTED: {
      return true;
    }
    case types.ROUTE_ADD_COMPLETED:
    case types.ROUTE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_EDIT_STARTED: {
      return true;
    }
    case types.ROUTE_EDIT_COMPLETED:
    case types.ROUTE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_REMOVE_STARTED:
    case types.ROUTE_REMOVE_STARTED: {
      return true;
    }
    case types.ROUTES_REMOVE_COMPLETED:
    case types.ROUTES_REMOVE_FAILED:
    case types.ROUTE_REMOVE_COMPLETED:
    case types.ROUTE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isCopying = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_COPY_STARTED: {
      return true;
    }
    case types.ROUTE_COPY_COMPLETED:
    case types.ROUTE_COPY_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_FETCH_STARTED:
    case types.ROUTES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_PARTIAL_FETCH_STARTED:
    case types.ROUTES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_ADD_STARTED:
    case types.ROUTE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_EDIT_STARTED:
    case types.ROUTE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_REMOVE_FAILED:
    case types.ROUTE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_REMOVE_STARTED:
    case types.ROUTES_REMOVE_COMPLETED:
    case types.ROUTE_REMOVE_STARTED:
    case types.ROUTE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorCopying = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTE_COPY_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTE_COPY_COMPLETED:
    case types.ROUTE_COPY_STARTED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const routes: any = combineReducers({
  routesExecutions,
  routesAssignments,
  routeCheckpoints,
  routesReport,
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  isCopying,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  errorCopying
});

const routesPersistConfig = {
  key: 'routes',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: [
    'routesExecutions',
    'routeCheckpoints',
    'routesReport',
    'isPartialFetchingList',
    'isFetchingList',
    'isAdding',
    'isEditing',
    'isRemoving',
    'isCopying',
    'errorPartialFetchingList',
    'errorFetchingList',
    'errorAdding',
    'errorEditing',
    'errorRemoving',
    'errorCopying'
  ],
  whitelist: ['byId', 'order']
};

export default persistReducer(routesPersistConfig, routes);

//Own state
export const getOwnState = (state) => state.routes;

//Information
export const getRoute = (state, routeId): types.Route | undefined => {
  const route = getOwnState(state).byId[routeId];
  if (route) {
    return {
      ...route,
      checkpoints: routeSelectorsCheckpoints.getRouteCheckpointsListByRoute(state, route.routeId),
      distanceFormat: authSelectors
        .getParsers(state)
        .convertMetersToAuthUserDistanceSystem(route.distance)
        .toFixed(2),
      speedLimitFormat: authSelectors
        .getParsers(state)
        .convertKilometersPerHourToAuthUserSpeedSystem(route.speedLimit)
    };
  } else undefined;
};

export const getRoutesList = (state): types.Route[] =>
  getOwnState(state)
    .order.map((id) => getRoute(state, id))
    .filter((route) => route.routeStatus > 0);
export const getRoutesListByMembership = (state, membership) => {
  return getRoutesList(state).filter(
    (route) =>
      membership &&
      (!membership.operatorId || membership.operatorId === route.operatorId) &&
      (!membership.organizationId || membership.organizationId === route.organizationId) &&
      (!membership.divisionId || membership.divisionId === route.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === route.subdivisionId)
  );
};
export const getSelectedRoutes = (state) => {
  const selectedRoutes = getRoutesList(state).filter((route) => route.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedRoutes.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedRoutes.length > 0) return selectedRoutes;
};

export const getSelectedRoute = (state) => {
  const selectedRoutes = getRoutesList(state).filter((route) => route.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedRoutes.length === 1) return selectedRoutes[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListRoutes = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListRoutes = (state) => getOwnState(state).isFetchingList;
export const isAddingRoute = (state) => getOwnState(state).isAdding;
export const isEditingRoute = (state) => getOwnState(state).isEditing;
export const isRemovingRoute = (state) => getOwnState(state).isRemoving;
export const isCopyingRoute = (state) => getOwnState(state).isCopying;

//Errors
export const getPartialFetchingListRoutesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListRoutesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingRouteErrors = (state) => getOwnState(state).errorAdding;
export const getEditingRouteErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingRouteErrors = (state) => getOwnState(state).errorRemoving;
export const getCopyingRouteErrors = (state) => getOwnState(state).errorCopying;

/* -------------------------------------------------------------------------- */
/*                             ROUTES EXECUTIONS                              */
/* -------------------------------------------------------------------------- */
//Information
export const getRoutesExecution = (state, assignmentId) =>
  routesSelectorsExecutions.getRoutesExecution(state, assignmentId);
export const getRoutesExecutionsList = (state) =>
  routesSelectorsExecutions.getRoutesExecutionsList(state);
export const getRoutesExecutionsIndicatorsInformation = (state) =>
  routesSelectorsExecutions.getIndicatorsInformation(state);
export const getRoutesExecutionsIndicators = (state) =>
  routesSelectorsExecutions.getIndicators(state);
export const getRoutesExecutionsTotalNotShown = (state) =>
  routesSelectorsExecutions.getTotalNotShown(state);
export const getRoutesExecutionsStatus = (state) =>
  routesSelectorsExecutions.getExecutionsStatus(state);
export const getRoutesExecutionsRunningStatus = (state) =>
  routesSelectorsExecutions.getRunningStatus(state);

//Status of sagas
export const isFetchingRoutesExecutions = (state) =>
  routesSelectorsExecutions.isFetchingRoutesExecutions(state);
export const isCancelingRoutesExecution = (state) =>
  routesSelectorsExecutions.isCancelingRoutesExecution(state);
export const isFinalizingRoutesExecution = (state) =>
  routesSelectorsExecutions.isFinalizingRoutesExecution(state);
export const isCompletingRoutesExecution = (state) =>
  routesSelectorsExecutions.isCompletingRoutesExecution(state);
export const isRemovingRoutesExecution = (state) =>
  routesSelectorsExecutions.isRemovingRoutesExecution(state);
export const isPausingRoutesExecution = (state) =>
  routesSelectorsExecutions.isPausingRoutesExecution(state);
export const isStartingRoutesExecution = (state) =>
  routesSelectorsExecutions.isStartingRoutesExecution(state);
export const isUpdatingManualTransitRoutesExecution = (state) =>
  routesSelectorsExecutions.isUpdatingManualTransitRoutesExecution(state);
export const isSharingRoutesExecution = (state) =>
  routesSelectorsExecutions.isSharingRoutesExecution(state);
export const isChangingToAlternateRouteExecution = (state) =>
  routesSelectorsExecutions.isChangingToAlternateRouteExecution(state);

//Errors
export const getFetchingRoutesExecutionsErrors = (state) =>
  routesSelectorsExecutions.getFetchingRoutesExecutionsErrors(state);
export const getCancelingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getCancelingRoutesExecutionErrors(state);
export const getFinalizingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getFinalizingRoutesExecutionErrors(state);
export const getCompletingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getCompletingRoutesExecutionErrors(state);
export const getRemovingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getRemovingRoutesExecutionErrors(state);
export const getPausingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getPausingRoutesExecutionErrors(state);
export const getStartingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getStartingRoutesExecutionErrors(state);
export const getUpdatingManualTransitRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getUpdatingManualTransitRoutesExecutionErrors(state);
export const getSharingRoutesExecutionErrors = (state) =>
  routesSelectorsExecutions.getSharingRoutesExecutionErrors(state);
export const getChangingToAlternateRouteErrors = (state) =>
  routesSelectorsExecutions.getChangingToAlternateRouteErrors(state);

/* -------------------------------------------------------------------------- */
/*                               ROUTES REPORT                                */
/* -------------------------------------------------------------------------- */
//Information
export const getRoutesReportExecution = (state, visitId) =>
  routesSelectorsReport.getRoutesExecution(state, visitId);
export const getRoutesReportExecutionsList = (state) =>
  routesSelectorsReport.getRoutesExecutionsList(state);
export const getRoutesReportUnit = (state, visitId) =>
  routesSelectorsReport.getRoutesUnit(state, visitId);
export const getRoutesReportUnitsList = (state) => routesSelectorsReport.getRoutesUnitsList(state);
export const getRoutesReportRoute = (state, visitId) =>
  routesSelectorsReport.getRoutesRoute(state, visitId);
export const getRoutesReportRoutesList = (state) =>
  routesSelectorsReport.getRoutesRoutesList(state);
export const getRoutesReportIndicators = (state) => routesSelectorsReport.getIndicators(state);

//Status of sagas
export const isFetchingRoutesReport = (state) =>
  routesSelectorsReport.isFetchingRoutesReport(state);

//Errors
export const getFetchingRoutesReportErrors = (state) =>
  routesSelectorsReport.getFetchingRoutesReportErrors(state);

/* -------------------------------------------------------------------------- */
/*                           ROUTE CHECKPOINTS                                */
/* -------------------------------------------------------------------------- */
//Status of sagas
export const isFetchingListRouteCheckpoints = (state) =>
  routeSelectorsCheckpoints.isFetchingListRouteCheckpoints(state);

//Errors
export const getFetchingListRouteCheckpointsErrors = (state) =>
  routeSelectorsCheckpoints.getFetchingListRouteCheckpointsErrors(state);
