import { env } from 'env';
import oldEs from './es.json'; // Este debería desaparecer conforme vayamos avanzando
import alertsEs from './events-es.json';

//Idiomas de cada módulo
//COMPONENTES
import fileUploadi18n from 'views/components/FileUploader/i18n/es.json';
import paymentMethodsi18n from 'views/formComponents/PaymentMethodsDropDown/i18n/es.json';

//PANTALLAS
import vehicleFueli18n from 'views/screens/VehicleFuel/i18n/es.json';
import workplansi18n from 'views/screens/Workplans/i18n/es.json';
import routesi18n from 'views/screens/Routes/i18n/es.json';
import schedulesi18n from 'views/screens/Schedules/i18n/es.json';
import mapi18n from 'views/components/Map/i18n/es.json';
import operatorsi18n from 'views/screens/Operators/i18n/es.json';
import organizationsi18n from 'views/screens/Organizations/i18n/es.json';
import divisionsi18n from 'views/screens/Divisions/i18n/es.json';
import subdivisionsi18n from 'views/screens/Subdivisions/i18n/es.json';
import agentsi18n from 'views/screens/Agents/i18n/es.json';
import serviceClassesi18n from 'views/screens/ServiceClasses/i18n/es.json';
import modulesi18n from 'views/screens/Modules/i18n/es.json';
import vehiclesi18n from 'views/screens/Vehicles/i18n/es.json';
import assetsi18n from 'views/screens/Assets/i18n/es.json';
import usersi18n from 'views/screens/Users/i18n/es.json';
import simsi18n from 'views/screens/SIMs/i18n/es.json';
import locationi18n from 'views/screens/Locations/i18n/es.json';
import driveri18n from 'views/screens/Drivers/i18n/es.json';
import sensorsi18n from 'views/screens/Sensors/i18n/es.json';
import unitsi18n from 'views/screens/Units/i18n/es.json';
import vehicleMaintenancei18n from 'views/screens/vehicleMaintenance/i18n/es.json';
import maintenanceLinesi18n from 'views/screens/MaintenanceLines/i18n/es.json';
import formsi18n from 'views/screens/Forms/i18n/es.json';
import geofencesi18n from 'views/screens/Geofences/i18n/es.json';
import jobsi18n from 'views/screens/Jobs/i18n/es.json';
import safeDriveri18n from 'views/screens/SafeDriver/i18n/es.json';
import dashboardi18n from 'views/screens/Dashboard/i18n/es.json';
import trackingUnitsi18n from 'views/screens/TrackingUnits/i18n/es.json';
import reportsi18n from 'views/screens/Reports/i18n/es.json';
import peoplei18n from 'views/screens/People/i18n/es.json';
import notificationsi18n from 'views/screens/Notifications/i18n/es.json';
import telemetryi18n from 'views/screens/Telemetry/i18n/es.json';
import operationsi18n from 'views/screens/Operations/i18n/es.json';
import groupsi18n from 'views/screens/Groups/i18n/es.json';
import consolei18n from 'views/screens/Console/i18n/es.json';
import contractsi18n from 'views/screens/Contracts/i18n/es.json';
import quotationsi18n from 'views/screens/Quotations/i18n/es.json';
import contractTemplatesi18n from 'views/screens/ContractTemplates/i18n/es.json';
import customersi18n from 'views/screens/Customers/i18n/es.json';
import officesi18n from 'views/screens/Offices/i18n/es.json';
import entitiesi18n from 'views/screens/Entities/i18n/es.json';
import optimizationsi18n from 'views/screens/Optimizations/i18n/es.json';
import businessPartneri18n from 'views/screens/BusinessPartners/i18n/es.json';
import protocolsi18n from 'views/screens/Protocols/i18n/es.json';

const es = {
  ...alertsEs,
  ...oldEs,
  ...vehicleFueli18n,
  ...workplansi18n,
  ...routesi18n,
  ...fileUploadi18n,
  ...mapi18n,
  ...operatorsi18n,
  ...organizationsi18n,
  ...divisionsi18n,
  ...subdivisionsi18n,
  ...agentsi18n,
  ...serviceClassesi18n,
  ...modulesi18n,
  ...vehiclesi18n,
  ...assetsi18n,
  ...usersi18n,
  ...simsi18n,
  ...locationi18n,
  ...driveri18n,
  ...sensorsi18n,
  ...unitsi18n,
  ...schedulesi18n,
  ...vehicleMaintenancei18n,
  ...maintenanceLinesi18n,
  ...paymentMethodsi18n,
  ...formsi18n,
  ...geofencesi18n,
  ...jobsi18n,
  ...safeDriveri18n,
  ...dashboardi18n,
  ...trackingUnitsi18n,
  ...reportsi18n,
  ...peoplei18n,
  ...notificationsi18n,
  ...telemetryi18n,
  ...operationsi18n,
  ...groupsi18n,
  ...consolei18n,
  ...contractsi18n,
  ...customersi18n,
  ...entitiesi18n,
  ...contractTemplatesi18n,
  ...quotationsi18n,
  ...officesi18n,
  ...entitiesi18n,
  ...optimizationsi18n,
  ...businessPartneri18n,
  ...protocolsi18n
};

const replaceUnit = (obj) => {
  let newObj = {};
  for (const [key, value] of Object.entries(obj)) {
    if (typeof value === 'string')
      newObj[key] = value
        .replace(/(Unidades de Rastreo|Unidades)/, 'Marchamos')
        .replace(/(unidades de rastreo|unidades)/, 'marchamos')
        .replace(/(Unidad de Rastreo|Unidad de rastreo|Unidad)/, 'Marchamo')
        .replace(/(unidad de rastreo|unidad)/, 'Marchamo')
        // Minusculas
        .replace(/(nueva marchamo)/, 'nuevo marchamo')
        .replace(/(una marchamo)/, 'un marchamo')
        .replace(/(la marchamo)/, 'el marchamo')
        .replace(/(las marchamos)/, 'los marchamos')
        .replace(/(esta marchamo)/, 'este marchamo')
        .replace(/(estas marchamos)/, 'estos marchamos')
        .replace(/(ninguna marchamo)/, 'ningún marchamo')
        // Primera mayuscula
        .replace(/(Nueva marchamo)/, 'Nuevo marchamo')
        .replace(/(Una marchamo)/, 'Un marchamo')
        .replace(/(La marchamo)/, 'El marchamo')
        .replace(/(Las marchamos)/, 'Los marchamos')
        .replace(/(Esta marchamo)/, 'Este marchamo')
        .replace(/(Estas marchamos)/, 'Estos marchamos')
        .replace(/(Ninguna marchamo)/, 'Ningún marchamo')
        // Todas mayusculas
        .replace(/(Nueva Marchamo)/, 'Nuevo Marchamo')
        .replace(/(Una Marchamo)/, 'Un Marchamo')
        .replace(/(La Marchamo)/, 'El Marchamo')
        .replace(/(Las Marchamos)/, 'Los Marchamos')
        .replace(/(Esta Marchamo)/, 'Este Marchamo')
        .replace(/(Estas Marchamos)/, 'Estos Marchamos')
        .replace(/(Ninguna Marchamo)/, 'Ningún Marchamo');
  }
  return newObj;
};

export default !!parseInt(env.REACT_APP_SAT_MONITOR ?? '0') ? replaceUnit(es) : es;
