// ** Redux Imports
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
// ** Reducers Imports
import layout from './layout';
import auth from './auth';
import * as authTypes from '../types/auth';
import operators from 'views/screens/Operators/reducers';
import organizations from 'views/screens/Organizations/reducers';
import divisions from 'views/screens/Divisions/reducers';
import subdivisions from 'views/screens/Subdivisions/reducers';
import serviceClasses from 'views/screens/ServiceClasses/reducers';
import agents from 'views/screens/Agents/reducers';
import navbar from './navbar';
import modules from 'views/screens/Modules/reducers';
import entities from 'views/screens/Entities/reducers';
import workplans from 'views/screens/Workplans/reducers/workplans';
import locations from 'views/screens/Locations/reducers';
import drivers from 'views/screens/Drivers/reducers';
import monitoringUsers from 'views/screens/Users/reducers';
import jobs from 'views/screens/Jobs/reducers';
import groups from 'views/screens/Groups/reducers';
import organizationContracts from 'views/screens/Contracts/reducers';
import contractTemplates from 'views/screens/ContractTemplates/reducers';
import schedules from 'views/screens/Schedules/reducers';
import routes from 'views/screens/Routes/reducers/routes';
import units from 'views/screens/Units/reducers';
import geofences from 'views/screens/Geofences/reducers';
import staticCatalogs from './static-catalogs';
import localforage from 'localforage';
import people from 'views/screens/Contacts/reducers';
import sims from 'views/screens/SIMs/reducers';
import vehicles from 'views/screens/Vehicles/reducers';
import assets from 'views/screens/Assets/reducers';
import sensors from 'views/screens/Sensors/reducers';
import forms from 'views/screens/Forms/reducers';
import categories from 'views/screens/vehicleMaintenance/reducers/catalogs/categories';
import workmanship from 'views/screens/vehicleMaintenance/reducers/catalogs/workmanship';
import noticesByOdometer from 'views/screens/vehicleMaintenance/reducers/preventiveNotices/noticesByOdometer';
import noticesByDate from 'views/screens/vehicleMaintenance/reducers/preventiveNotices/noticesByDate';
import noticesByHourmeter from 'views/screens/vehicleMaintenance/reducers/preventiveNotices/noticesByHourmeter';
import providers from 'views/screens/vehicleMaintenance/reducers/catalogs/providers';
import spareParts from 'views/screens/vehicleMaintenance/reducers/catalogs/spareParts';
import maintenanceLines from 'views/screens/MaintenanceLines/reducers';
import customers from 'views/screens/Customers/reducers';
import quotations from 'views/screens/Quotations/reducers';
import offices from 'views/screens/Offices/reducers';
import protocols from 'views/screens/Protocols/reducers';

const reducer = combineReducers({
  auth,
  layout,
  operators,
  organizations,
  divisions,
  subdivisions,
  serviceClasses,
  agents,
  navbar,
  modules,
  entities,
  people,
  workplans,
  locations,
  drivers,
  monitoringUsers,
  routes,
  schedules,
  units,
  categories,
  workmanship,
  geofences,
  staticCatalogs,
  sims,
  vehicles,
  assets,
  sensors,
  forms,
  jobs,
  groups,
  quotations,
  organizationContracts,
  contractTemplates,
  customers,
  offices,
  form: formReducer,
  noticesByOdometer,
  noticesByDate,
  noticesByHourmeter,
  providers,
  spareParts,
  maintenanceLines,
  protocols
});

const rootReducer = (state, action): any => {
  let newState = { ...state };

  if (!action) {
    return reducer;
  }

  if (action.type === authTypes.AUTHENTICATION_IDENTITY_CLEARED) {
    //Remove Session Information
    localStorage.removeItem('auth');
    localStorage.removeItem('userData');
    //Clear redux-persist storage
    localforage.removeItem('persist:root');
    //Set initialState
    const {
      jobs,
      groups,
      entities,
      routes,
      workplans,
      locations,
      drivers,
      auth,
      geofences,
      units
    } = state;
    newState = {
      jobs,
      groups,
      entities,
      routes,
      workplans,
      locations,
      drivers,
      auth,
      geofences,
      units
    };
  }
  return reducer(newState, action);
};

export default rootReducer;
export type RootState = ReturnType<typeof reducer>;
