import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'forms',
    title: 'forms.forms',
    permissions: [permissions.listForms, permissions.answersFormReport],
    iconName: 'Formularios',
    children: [
      {
        id: 'listFormScreen',
        title: 'forms.catalog',
        permissions: [permissions.listForms],
        iconName: 'Catalog',
        navLink: paths.listForms,
        screenId: screens.FORMS_CATALOG
      },
      {
        id: 'monitorFormScreen',
        title: 'forms.monitoring',
        permissions: [permissions.answersFormReport],
        iconName: 'Catalog',
        navLink: paths.answersReportForms,
        screenId: screens.ANSWERS_FORMS
      }
    ]
  }
];
