/* -------------------------------------------------------------------------- */
/*                         Componente OfficesDropdown                       */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownInput que permite elegir un cliente y abrir un slide para crear nuevos clientes

import { useState } from 'react';
import { Edit2, Plus } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { InputGroupText, UncontrolledTooltip } from 'reactstrap';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import SlidingPaneEditOffice from 'views/screens/Offices/views/SlidingPaneEditOffice';
import * as officeSelectors from '../../screens/Offices/reducers';
import DropdownInput from '../DropdownInput';

const OfficesDropdown = (props) => {
  const {
    appendAddOffice = true,
    enableSelectLocation = false,
    isClearable = false,
    officesFilters,
    notAssigned = false,
    locationId,
    customerId,
    isMainOffice,
    field,
    ...dropdownProps
  } = props;
  const intl = useIntl();
  const { offices } = useSelector((state) => ({
    offices: notAssigned
      ? officeSelectors.getNotAssignedOfficesListByCustomer(state, locationId, customerId)
      : officeSelectors.getOfficesList(state)
  }));
  const [isOpenShiftPane, setOpenShiftPane] = useState<boolean>(false);
  const [selectedOffice, setSelectedOffice] = useState<any>(undefined);
  const closeShiftPane = () => setOpenShiftPane(false);

  return (
    <>
      <FetchEntities useOffices officesFilters={officesFilters} />
      <DropdownInput
        {...props}
        options={
          offices
            .filter((office) => isMainOffice == undefined || office.isMainOffice === isMainOffice)
            .map((office) => ({
              value: office.officeId,
              label: office.officeName
            })) || []
        }
        label={intl.formatMessage({ id: 'offices.office' })}
        placeholder={intl.formatMessage({ id: 'offices.selectOffice' })}
        appendcomponent={
          appendAddOffice ? (
            <>
              {field.value && (
                <InputGroupText
                  id={'editOffice'}
                  style={{ cursor: 'pointer' }}
                  className={'m-0'}
                  onClick={() => {
                    setSelectedOffice({ officeId: field.value });
                    setOpenShiftPane(true);
                  }}
                >
                  <UncontrolledTooltip placement="top" target={'editOffice'} trigger="click hover">
                    <FormattedMessage
                      id={'buttons.edit'}
                      values={{ objectType: intl.formatMessage({ id: 'offices.office' }) }}
                    />
                  </UncontrolledTooltip>
                  <Edit2 size={15} />
                </InputGroupText>
              )}
              <InputGroupText
                id={'addOffice'}
                style={{ cursor: 'pointer' }}
                className={'m-0'}
                onClick={() => {
                  setSelectedOffice(undefined);
                  setOpenShiftPane(true);
                }}
              >
                <UncontrolledTooltip placement="top" target={'addOffice'} trigger="click hover">
                  <FormattedMessage
                    id={'buttons.add'}
                    values={{ objectType: intl.formatMessage({ id: 'offices.office' }) }}
                  />
                </UncontrolledTooltip>
                <Plus size={15} />
              </InputGroupText>
            </>
          ) : (
            <></>
          )
        }
      />
      <SlidingPaneEditOffice
        office={selectedOffice}
        enableSelectLocation={enableSelectLocation}
        isOpenSlidingPane={isOpenShiftPane}
        closeSlidingPaneEditOffice={() => {
          setSelectedOffice(undefined);
          closeShiftPane();
        }}
      />
    </>
  );
};

export default OfficesDropdown;
