import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/driver';

interface DriverDeleteRequest {
  driverId: number;
  driverName: string;
}
interface DriverFilters {
  operatorId?: number;
  organizationId?: number;
  userFetchDate?: Date;
}
interface DriverImagesRequest {
  driverId: number;
}

interface ImportVehiclesFilters {
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  drivers?: String;
}

export const getDriversListService = (request: DriverFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'drivers.driver',
    action: 'Get',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getDriverInformationService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getDriverInformation`, request);
};

export const getDriverImagesService = (
  request: DriverImagesRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'drivers.driverImages',
    action: 'Get',
    genderType: 'PluralFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getImages`, request);
};

export const saveDriverService = (request: any, configActions: ConfigActions, person: any) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: person && person === true ? 'people.person' : 'drivers.driver',
    action: request.driverId ? 'Edit' : 'Create',
    name: request.driverName,
    genderType: person && person === true ? 'SingularFemale' : 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteDriverService = (
  request: DriverDeleteRequest,
  configActions: ConfigActions,
  person: any
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.driverName}`,
    object: person && person === true ? 'people.person' : 'drivers.driver',
    action: 'Delete',
    genderType: person && person === true ? 'SingularFemale' : 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const getSensorsForPeople = (configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'drivers.driver',
    action: 'Get',
    genderType: 'PluralMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.get(`${baseUrl}/sensors`);
};

export const assignDriversMassivelyService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'units.units',
    action: 'Edit',
    name: request.driverName,
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/massiveAssignDriversToUnits`, request);
};

export const importDriversService = async (
  request: ImportVehiclesFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'drivers.driver',
    action: 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importDrivers`, request);
};
