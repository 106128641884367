import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Icon from 'utility/icomoon';

interface Props {
  message: string;
  color?: string;
  icon?: string;
  visible?: boolean;
}

const SimpleAlertMessage = (props: Props) => {
  const { message, color = 'warning', icon = 'PreventiveNotice', visible = true } = props;

  const intl = useIntl();

  return visible ? (
    <Row className="w-100 d-flex justify-content-center">
      <Alert variant={color} className="mx-1">
        <Row className="px-3 py-1">
          <Icon size={16} icon={icon} className="mr-0" />
          <Col>{message}</Col>
        </Row>
      </Alert>
    </Row>
  ) : (
    <></>
  );
};

export default SimpleAlertMessage;
