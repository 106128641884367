/* -------------------------------------------------------------------------- */
/*                       Componente DropdownScheduleInput                     */
/* -------------------------------------------------------------------------- */
// Este componente contiene un DropdownScheduleInput genérico que se utiliza en muchas pantallas de la aplicación.

import { useState } from 'react';
import { Plus } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InputGroupText, Tooltip } from 'reactstrap';
import ShiftSlide from 'views/components/ShiftSlide';
import TriggerSlide from 'views/components/TriggerSlide';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import permissions from 'views/screens/Schedules/permissions';
import { addScheduleDescription, msToTime } from 'views/screens/Schedules/utils';
import * as authSelectors from '../../../redux/reducers/auth';
import * as schedulesActions from '../../screens/Schedules/actions';
import * as schedulesSelectors from '../../screens/Schedules/reducers';
import DropdownInput from '../DropdownInput';

const ScheduleDropdown = (props) => {
  const {
    scheduleType,
    appendAddSchedule = true,
    showScheduleInformation = true,
    field,
    showFrequency = true,
    showExecution = true,
    filters = null,
    filterValidNextRun = false,
    ...dropdownProps
  } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const selector =
    scheduleType === 'shifts'
      ? 'getShiftsList'
      : scheduleType === 'trigger'
      ? 'getTriggersList'
      : 'getSchedulesList';
  const { hasPermission, schedules, selectedSchedule, unfilteredSchedules } = useSelector(
    (state) => {
      const selected = schedulesSelectors.getSchedule(state, field?.value);
      return {
        hasPermission: authSelectors.getAuthUserHasPermissionsAny(state, [permissions.addSchedule]),
        schedules: schedulesSelectors[selector](state)
          .filter((schedule: any) => {
            return !!filterValidNextRun
              ? schedule?.nextRunDate !== null && schedule?.nextRunDate !== undefined
              : true;
          })
          .filter((schedule) =>
            filters !== null && filters !== undefined
              ? schedule.operatorId === filters?.operatorId &&
                schedule.organizationId === filters?.organizationId
              : schedule
          )
          .map((schedule) => ({
            value: schedule.scheduleId,
            label: schedule.scheduleName
          })),
        selectedSchedule:
          selected?.frequencyInterval != null ? addScheduleDescription(selected, intl) : {},
        unfilteredSchedules: schedulesSelectors[selector](state)
      };
    }
  );
  const [isOpenShiftPane, setOpenShiftPane] = useState<boolean>(false);
  const closeShiftPane = () => setOpenShiftPane(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <FetchEntities useSchedules />
      <DropdownInput
        {...props}
        options={schedules || []}
        label={intl.formatMessage({ id: 'schedules.schedule' })}
        placeholder={intl.formatMessage({ id: 'schedules.selectSchedule' })}
        appendcomponent={
          appendAddSchedule && hasPermission ? (
            <>
              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={'addSchedule'}
                toggle={toggle}
                trigger="click hover"
              >
                <FormattedMessage
                  id={'buttons.add'}
                  values={{ objectType: intl.formatMessage({ id: 'schedules.schedule' }) }}
                />
              </Tooltip>
              <InputGroupText
                id={'addSchedule'}
                style={{ cursor: 'pointer' }}
                className={'m-0'}
                onClick={() => {
                  dispatch(schedulesActions.deselectAllSchedules());
                  setOpenShiftPane(true);
                }}
              >
                <Plus size={15} />
              </InputGroupText>
            </>
          ) : (
            <></>
          )
        }
      />
      {showScheduleInformation && selectedSchedule?.scheduleId && (
        <>
          {showFrequency && (
            <div>
              <strong>{`${intl.formatMessage({ id: 'schedules.frequencyDescription' })}: `}</strong>
              {`${selectedSchedule.frequencyDescription ?? 'N/A'}`}
            </div>
          )}
          {showExecution && (
            <div className={'mb-1'}>
              <strong>{`${intl.formatMessage({ id: 'schedules.execution' })}: `}</strong>
              {`${intl.formatMessage({ id: 'common.atTime' })} ${
                selectedSchedule.startTime ? selectedSchedule.startTime : ''
              }`}
            </div>
          )}
        </>
      )}
      {scheduleType === 'shifts' && (
        <ShiftSlide isOpenShiftPane={isOpenShiftPane} closeSchedulePane={() => closeShiftPane()} />
      )}
      {scheduleType === 'trigger' && (
        <TriggerSlide
          isOpenTriggerPane={isOpenShiftPane}
          closeSchedulePane={() => closeShiftPane()}
        />
      )}
    </>
  );
};

export default ScheduleDropdown;
