import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as entityActions from '../../Entities/actions';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import { getCustomersListService, saveCustomerService, deleteCustomerService } from '../services';
import { store } from 'redux/storeConfig/store';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchCustomersList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getCustomersListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { customers },
          result //order
        } = normalize(resultData, schemas.customers); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingCustomers(customers, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingCustomers(error));
      }
    }
  );
}

export function* watchFetchCustomersList() {
  yield takeEvery(types.CUSTOMERS_FETCH_STARTED, fetchCustomersList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchCustomersList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getCustomersListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { customers },
          result //order
        } = normalize(resultData, schemas.customers); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingCustomers(customers, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingCustomers(error));
      }
    }
  );
}

export function* watchPartialFetchCustomersList() {
  yield takeEvery(types.CUSTOMERS_PARTIAL_FETCH_STARTED, partialFetchCustomersList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addCustomer(action) {
  const customer = action.payload;
  saveCustomerService(
    { ...customer, customerId: null },
    {
      successAction: (response) => {
        const addedCustomer = camelcaseKeys(response.data.customer);
        const addedOffice = camelcaseKeys(response.data.office);
        store.dispatch(
          actions.completeAddingCustomer(customer.customerId, addedCustomer, addedOffice)
        );
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingCustomer(customer.customerId, error));
      }
    }
  );
}

export function* watchAddCustomersStarted() {
  yield takeEvery(types.CUSTOMER_ADD_STARTED, addCustomer);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editCustomer(action) {
  const customer = action.payload;
  const { oldCustomer } = yield select((state) =>
    selectors.getCustomer(state, customer.customerId)
  );
  saveCustomerService(
    { ...customer },
    {
      successAction: (response) => {
        const editedCustomer = camelcaseKeys(response.data.customer);
        const editedOffice = camelcaseKeys(response.data.office);
        store.dispatch(actions.completeEditingCustomer(editedCustomer, editedOffice));
        store.dispatch(entityActions.startFetchingEntities());
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingCustomer(oldCustomer, error));
      }
    }
  );
}

export function* watchEditCustomersStarted() {
  yield takeEvery(types.CUSTOMER_EDIT_STARTED, editCustomer);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeCustomer(action) {
  const { customerId } = action.payload;
  const customer = yield select((state) => selectors.getCustomer(state, customerId));
  deleteCustomerService(
    { ...customer },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingCustomer(customerId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingCustomer(customerId, error));
      }
    }
  );
}

export function* watchRemoveCustomerStarted() {
  yield takeEvery(types.CUSTOMER_REMOVE_STARTED, removeCustomer);
}
