import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as entitySelectors from '../../../Entities/reducers';
import * as actions from '../../actions/board';
import * as selectors from '../../reducers/board';
import * as schemas from '../../schemas';
import { getJobsBoardListService, changeJobsStatusBoardService } from '../../services/jobs';
import * as types from '../../types/board';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchJobsBoardList(action) {
  const filters = action.payload;
  getJobsBoardListService(filters, {
    successAction: (response) => {
      const resultData = response.data.map((result) => camelcaseKeys(result));
      const {
        entities: { jobs },
        result //order
      } = normalize(resultData, schemas.jobs); //normalize data to byId and order
      store.dispatch(actions.completeFetchingJobsBoard(jobs, result));
    },
    errorAction: (error) => {
      store.dispatch(actions.failFetchingJobsBoard(error));
    }
  });
}

export function* watchFetchJobsBoardList() {
  yield takeEvery(types.JOBS_BOARD_FETCH_STARTED, fetchJobsBoardList);
}

/* -------------------------------------------------------------------------- */
/*                            CHANGE STATUS                                   */
/* -------------------------------------------------------------------------- */
function* changeStatusJobsBoard(action) {
  const { jobs, jobStatus, comment } = action.payload;
  changeJobsStatusBoardService(
    { jobs, jobStatus, comment },
    {
      successAction: (response) => {
        store.dispatch(actions.completeChangeStatusJobsBoard(jobs, jobStatus, comment));
      },
      errorAction: (error) => {
        store.dispatch(actions.failChangeStatusJobsBoard(jobs, error));
      }
    }
  );
}

export function* watchChangeStatusJobsBoardStarted() {
  yield takeEvery(types.JOBS_BOARD_CHANGE_STATUS_STARTED, changeStatusJobsBoard);
}
