import * as types from "../../../types/static-catalogs/currencies";

export const startFetchingCurrencies = (filters = {}) => ({
  type: types.CURRENCIES_FETCH_STARTED,
  payload: filters,
});
export const completeFetchingCurrencies = (entities, order) => ({
  type: types.CURRENCIES_FETCH_COMPLETED,
  payload: {
    entities,
    order,
  },
});
export const failFetchingCurrencies = (
  error = { errorMessage: "Error", errorNumber: 404 }
) => ({
  type: types.CURRENCIES_FETCH_FAILED,
  payload: {
    error, //error:{errorMessage:'Error', errorNumber:400}
  },
});
