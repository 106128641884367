/**
 * @description useBoolean is a custom hook that returns an object with 4 methods: value, setTrue, setFalse, and toggle.
 * @param {boolean} defaultValue - Boolean value that is passed to the hook. This value is used to set the initial state value.
 * @returns {object} - An object with 4 methods: value, setTrue, setFalse, and toggle.
 */

import { useCallback, useState } from 'react';

interface UseBooleanOutput {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

const useBoolean = (defaultValue: boolean = false): UseBooleanOutput => {
  const [value, setValue] = useState(!!defaultValue);

  const setTrue = useCallback(() => setValue(true), []);
  const setFalse = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((value) => !value), []);

  return { value, setTrue, setFalse, toggle };
};

export { useBoolean };
