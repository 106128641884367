import { combineReducers } from 'redux';
import uniq from 'lodash/uniq';
import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types';
import { appIntl } from 'utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { groupsEntities, groupsOrder } = action.payload;
      const newState = { ...state };
      groupsOrder.forEach((groupId) => {
        newState[groupId] = {
          ...state[groupId],
          ...groupsEntities[groupId]
        };
      });
      return newState;
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE UNIT_INFORMATION_FETCH_COMPLETED
    case types.UNIT_INFORMATION_FETCH_COMPLETED: {
      const { groupsOrder } = action.payload;
      return uniq([...state, ...groupsOrder]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

const groups = combineReducers({
  byId,
  order
});

export default groups;

//Own state
export const getOwnState = (state) => state.units.groups;

//Information
export const getGroup = (state, groupId) => {
  const group = getOwnState(state).byId[groupId];
  if (group) {
    return {
      ...group
    };
  } else return undefined;
};
export const getGroupsList = (state) => getOwnState(state).order.map((id) => getGroup(state, id));
