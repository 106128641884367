import '@geoman-io/leaflet-geoman-free';
import 'leaflet-toolbar';
import 'leaflet.icon.glyph';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import SlidingPaneForm from 'views/formComponents/SlidingPaneForm';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import * as routeActions from '../../../../screens/Routes/actions/routes';
import SlidingPaneEditRoute from '../SlidingPaneEditRoute';
import { useMapRouteEdit } from './useMapRouteEdit';

export interface MapRouteEditOptions {
  showRoute: boolean;
  route: any;
  routeCheckpoints: any;
  routePaths: any;
  setEditingRouteParent?: any;
}

const MapRouteEdit = (props: MapRouteEditOptions) => {
  const { setEditingRouteParent } = props;
  const {
    formValues,
    control,
    handleSubmit,
    isGeneratingRouteRef,
    isOpenSlidingPaneEditRoute,
    distanceRoute,
    paths,
    checkpoints,
    setCheckpoints,
    createdLocations,
    addCheckpoint,
    editCheckpoint,
    editPath,
    deleteCheckpoint,
    addNewMarkerOnMap,
    addGoogleLocationToCheckpoint,
    addLocationToCheckpoint,
    deleteLocationOfCheckpoint,
    editingRouteRef,
    deleteObjectsOnMap,
    setOpenSlidingPaneEditRoute,
    setEditingRoute,
    setCreatedLocations,
    setPaths,
    setSlidingPaneEditRouteProps,
    setDistanceRoute,
    slidingPaneEditRoutesProps,
    isOpenSlidingPaneForm,
    setOpenSlidingPaneForm,
    slidingPaneFormProps,
    widthSlidingPaneEditRoute,
    errors,
    isGeneratingRoute,
    alternateRoutes,
    setAlternateRoutes
  } = useMapRouteEdit(props);
  const intl = useIntl();
  const dispatch = useDispatch();

  return (
    <>
      <FetchEntities
        useRoutes
        routesFilters={formValues?.membership}
        useLocations
        locationsFilters={formValues?.membership}
      />
      <SlidingPaneEditRoute
        control={control}
        width={widthSlidingPaneEditRoute}
        routeId={editingRouteRef?.current?.routeId}
        handleSubmit={handleSubmit}
        formValues={formValues}
        errors={errors}
        isLoading={isGeneratingRouteRef.current}
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneEditRoute}
        isGeneratingRoute={isGeneratingRoute}
        distanceRoute={distanceRoute}
        paths={paths}
        checkpoints={checkpoints}
        setCheckpoints={setCheckpoints}
        createdLocations={createdLocations}
        addCheckpoint={addCheckpoint}
        editCheckpoint={editCheckpoint}
        editPath={editPath}
        deleteCheckpoint={deleteCheckpoint}
        addNewMarkerOnMap={addNewMarkerOnMap}
        addGoogleLocationToCheckpoint={addGoogleLocationToCheckpoint}
        addLocationToCheckpoint={addLocationToCheckpoint}
        deleteLocationOfCheckpoint={deleteLocationOfCheckpoint}
        alternateRoutes={alternateRoutes}
        setAlternateRoutes={setAlternateRoutes}
        closeSlidingPaneForm={(isSaving) => {
          if (!isSaving && editingRouteRef.current?.routeId)
            dispatch(routeActions.showRouteInMap(editingRouteRef.current.routeId));
          deleteObjectsOnMap();
          setOpenSlidingPaneEditRoute(false);
          setEditingRoute(undefined);
          setCreatedLocations([]);
          setCheckpoints([]);
          setPaths([]);
          setSlidingPaneEditRouteProps({ inputs: [] });
          setDistanceRoute({ ...distanceRoute, distance: 0 });
          setTimeout(() => setEditingRouteParent(undefined), 1000);
        }}
        {...slidingPaneEditRoutesProps}
      />
      <SlidingPaneForm
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneForm}
        closeSlidingPaneForm={() => {
          setOpenSlidingPaneForm(false);
        }}
        isInMap={true}
        {...slidingPaneFormProps}
      />
    </>
  );
};

MapRouteEdit.defaultProps = {
  route: {},
  showRoute: false,
  isMapFullScreen: false,
  usePopovers: true
};

export default MapRouteEdit;
