import * as types from '../../types/workplansComments';

export const startFetchingComments = (visitId, locationId) => ({
  type: types.WORKPLAN_COMMENTS_FETCH_STARTED,
  payload: { visitId, locationId }
});
export const completeFetchingComments = (entities, order) => ({
  type: types.WORKPLAN_COMMENTS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingComments = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.WORKPLAN_COMMENTS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingComment = (comment) => ({
  type: types.WORKPLAN_COMMENT_ADD_STARTED,
  payload: comment
});
export const completeAddingComment = (oldId, comment) => ({
  type: types.WORKPLAN_COMMENT_ADD_COMPLETED,
  payload: { oldId, comment }
});
export const failAddingComment = (oldId, error) => ({
  type: types.WORKPLAN_COMMENT_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startRemovingComment = (jobCommentId) => ({
  type: types.WORKPLAN_COMMENT_REMOVE_STARTED,
  payload: {
    jobCommentId
  }
});
export const completeRemovingComment = (jobCommentId) => ({
  type: types.WORKPLAN_COMMENT_REMOVE_COMPLETED,
  payload: {
    jobCommentId
  }
});
export const failRemovingComment = (jobCommentId, error) => ({
  type: types.WORKPLAN_COMMENT_REMOVE_FAILED,
  payload: {
    jobCommentId,
    error
  }
});
