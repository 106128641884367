import Icon from '../../utility/icomoon';
import paths from 'views/screens/Drivers/paths';
import permissions from 'views/screens/Drivers/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'drivers',
    title: 'drivers.drivers',
    icon: <Icon size={20} icon="noun_steering_790455" />,
    permissions: [permissions.listDrivers],
    iconName: 'noun_steering_790455',
    navLink: paths.listDrivers,
    screenId: screens.DRIVERS
  }
];
