import * as types from '../../types/workplansVisits';

export const startFetchingWorkplansVisits = (filters = {}) => ({
  type: types.WORKPLANS_VISITS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingWorkplansVisits = (entities, order) => ({
  type: types.WORKPLANS_VISITS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingWorkplansVisits = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_VISITS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingWorkplansAllVisits = (filters = {}) => ({
  type: types.WORKPLANS_ALL_VISITS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingWorkplansAllVisits = (entities, order) => ({
  type: types.WORKPLANS_ALL_VISITS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingWorkplansAllVisits = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.WORKPLANS_ALL_VISITS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
