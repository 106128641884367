import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { store } from 'redux/storeConfig/store';
import * as entitySelectors from '../../Entities/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as actions from '../actions';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import {
  deleteGeofenceService,
  getGeofencesListService,
  saveGeofenceService
} from '../services/geofences';
import * as types from '../types';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import { call, takeEvery, put, select } from 'redux-saga/effects';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchGeofencesList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getGeofencesListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { geofences },
          result //order
        } = normalize(resultData, schemas.geofences); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingGeofences(geofences, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingGeofences(error));
      }
    }
  );
}

export function* watchFetchGeofencesList() {
  yield takeEvery(types.GEOFENCES_FETCH_STARTED, fetchGeofencesList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETSH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchGeofencesList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getGeofencesListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { geofences },
          result //order
        } = normalize(resultData, schemas.geofences); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingGeofences(geofences, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingGeofences(error));
      }
    }
  );
}

export function* watchPartialFetchGeofencesList() {
  yield takeEvery(types.GEOFENCES_PARTIAL_FETCH_STARTED, partialFetchGeofencesList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addGeofence(action) {
  const geofence = action.payload;
  saveGeofenceService(
    { ...geofence, geofenceId: null },
    {
      successAction: (response) => {
        const addedGeofence = camelcaseKeys(response.data);
        store.dispatch(actions.completeAddingGeofence(geofence.geofenceId, addedGeofence));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingGeofence(geofence.geofenceId, error));
      }
    }
  );
}

export function* watchAddGeofencesStarted() {
  yield takeEvery(types.GEOFENCE_ADD_STARTED, addGeofence);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editGeofence(action) {
  const geofence = action.payload;
  const oldGeofence = yield select((state) => selectors.getGeofence(state, geofence.geofenceId));
  saveGeofenceService(
    { ...geofence },
    {
      successAction: (response) => {
        const editedGeofence = camelcaseKeys(response.data);
        store.dispatch(actions.completeEditingGeofence(editedGeofence));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingGeofence(oldGeofence, error));
      }
    }
  );
}

export function* watchEditGeofencesStarted() {
  yield takeEvery(types.GEOFENCE_EDIT_STARTED, editGeofence);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeGeofence(action) {
  const { geofenceId } = action.payload;
  const geofence = yield select((state) => selectors.getGeofence(state, geofenceId));
  deleteGeofenceService(
    { ...geofence },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingGeofence(geofenceId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingGeofence(geofenceId, error));
      }
    }
  );
}

export function* watchRemoveGeofenceStarted() {
  yield takeEvery(types.GEOFENCE_REMOVE_STARTED, removeGeofence);
}

/* -------------------------------------------------------------------------- */
/*                             Assign Geofence Unit                           */
/* -------------------------------------------------------------------------- */
function* assignGeofenceUnit(action) {
  const assignation = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/geofence/assignUnit`, {
        method: 'POST',
        body: JSON.stringify(assignation),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === assignation.length
      ) {
        yield put(actions.completeAssignUnitsGeofences(assignation));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'geofences.unitWasSuccessfullyAssigned'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'geofences.unitAssignmentIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignUnitsGeofences(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'geofences.unitAssignmentIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignUnitsGeofences(error));
  }
}

export function* watchAssignGeofenceUnit() {
  yield takeEvery(types.GEOFENCE_ASSIGN_UNIT_STARTED, assignGeofenceUnit);
}

/* -------------------------------------------------------------------------- */
/*                       Remove Geofence Assignation                          */
/* -------------------------------------------------------------------------- */
function* removeGeofenceAssignation(action) {
  const unitGeofences = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/geofence/deleteUnitGeofences`, {
        method: 'POST',
        body: JSON.stringify(unitGeofences),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success /*&&
        jsonResult.data.length === unitGeofences.length*/
      ) {
        yield put(actions.completeRemovingGeofenceAssignation(unitGeofences));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'geofences.assignationWasSuccessfullyRemoved'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'geofences.removingAssignationIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingGeofenceAssignation(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'geofences.removingAssignationIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingGeofenceAssignation(error));
  }
}

/* -------------------------------------------------------------------------- */
/*                       Remove All Assignments By Geofence                         */
/* -------------------------------------------------------------------------- */
function* removeAllAssigmentsByGeofences(action) {
  //const unitGeofences = action.payload;
  console.log('removeAll payload', action.payload);

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/geofence/deleteAllByGeofences`, {
        method: 'POST',
        body: JSON.stringify(action.payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      console.log('response', jsonResult);
      if (
        response.status <= 300 &&
        jsonResult.success /* &&
        jsonResult.data.length === unitGeofences.length */
      ) {
        yield put(actions.completeRemovingGeofenceAssignation(action.payload));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'geofences.assignationWasSuccessfullyRemoved'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'geofences.removingAssignationIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingGeofenceAssignation(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'geofences.removingAssignationIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingGeofenceAssignation(error));
  }
}

/* -------------------------------------------------------------------------- */
/*                       Remove All Assignments By Geofence                         */
/* -------------------------------------------------------------------------- */
function* removeAllAssigmentsByUnits(action) {
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/geofence/deleteAllByUnits`, {
        method: 'POST',
        body: JSON.stringify(action.payload),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      console.log('response', jsonResult);
      if (
        response.status <= 300 &&
        jsonResult.success /* &&
        jsonResult.data.length === unitGeofences.length */
      ) {
        yield put(actions.completeRemovingGeofenceAssignation(action.payload));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'geofences.assignationWasSuccessfullyRemoved'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'geofences.removingAssignationIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingGeofenceAssignation(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'geofences.removingAssignationIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingGeofenceAssignation(error));
  }
}

export function* watchRemoveGeofenceAssignation() {
  yield takeEvery(types.GEOFENCE_ASSIGNATION_REMOVE_STARTED, removeGeofenceAssignation);
}

export function* watchRemoveAllAssignmentsByGeofences() {
  yield takeEvery(
    types.GEOFENCE_ASSIGNATION_REMOVE_BY_GEOFENCES_STARTED,
    removeAllAssigmentsByGeofences
  );
}

export function* watchRemoveAllAssignmentsByUnits() {
  yield takeEvery(types.GEOFENCE_ASSIGNATION_REMOVE_BY_UNITS_STARTED, removeAllAssigmentsByUnits);
}

/* -------------------------------------------------------------------------- */
/*                         Edit Geofence Assignation                          */
/* -------------------------------------------------------------------------- */
function* editGeofenceAssignation(action) {
  const unitGeofenceInfo = action.payload.unitGeofenceInfo;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/geofence/editUnitGeofenceNotification`, {
        method: 'POST',
        body: JSON.stringify(unitGeofenceInfo),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success /*&&
        jsonResult.data.length === unitGeofences.length*/
      ) {
        yield put(actions.completeEditingGeofenceAssignation(unitGeofenceInfo));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'geofences.assignationWasSuccessfullyEdited'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'geofences.editingAssignationIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failEditingGeofenceAssignation(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'geofences.editingAssignationIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failEditingGeofenceAssignation(error));
  }
}

export function* watchEditGeofenceAssignation() {
  yield takeEvery(types.GEOFENCE_ASSIGNATION_EDIT_STARTED, editGeofenceAssignation);
}
