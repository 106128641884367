// @ts-nocheck
// import Control from 'react-leaflet-control';
import '@geoman-io/leaflet-geoman-free';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-toolbar';
import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Plus, X } from 'react-feather';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Icon from 'utility/icomoon';
import { v4 as uuidv4 } from 'uuid';
import * as authSelectors from '../../../../../redux/reducers/auth';
import routesPermissions from '../../../../../views/screens/Routes/permissions';

export interface RoutesToolbarOptions {
  useToolbar: boolean;
  showRoutes?: bool;
  setEditingRouteParent?: any;
  setShowRoutes?: Function;
  listenMapControlChanges?: any[];
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

/* -------------------------------------------------------------------------- */
/*                             ToolbarRoutesControl                           */
/* -------------------------------------------------------------------------- */
const ToolbarRoutesControl = (props: RoutesToolbarOptions) => {
  const {
    showRoutes,
    setShowRoutes,
    setEditingRouteParent,
    position = 'topleft',
    listenMapControlChanges
  } = props;
  const context = useLeafletContext();
  const intl = useIntl();

  const { store } = useSelector((state) => ({
    store: state
  }));

  useEffect(() => {
    const container = context.layerContainer || context.map;
    try {
      var ImmediateSubAction = L.Toolbar2.Action.extend({
        initialize: function (map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks: function () {
          this.myAction.disable();
        }
      });

      var createNewRouteAction = ImmediateSubAction.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.routesToolbar.tooltipAddRoute' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <Plus size={14} />
              </>
            )
          }
        },
        addHooks: function () {
          setEditingRouteParent({
            showRoute: true,
            route: { newRouteId: uuidv4() },
            routeCheckpoints: [
              { checkpointId: uuidv4() },
              { checkpointId: uuidv4() },
              { checkpointId: uuidv4() }
            ],
            routePaths: []
          });
          ImmediateSubAction.prototype.addHooks.call(this);
        }
      });

      var cancelRoute = ImmediateSubAction.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'common.tooltipCancel' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <X size={14} />
              </>
            )
          }
        },
        addHooks: function () {
          ImmediateSubAction.prototype.addHooks.call(this);
        }
      });

      var routesOptions = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: intl.formatMessage({ id: 'map.routesToolbar.tooltipRoutes' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <Icon size={20} icon="Route" />
              </>
            )
          },

          subToolbar: new L.Toolbar2({
            actions: [createNewRouteAction, cancelRoute].filter((action) => action != null)
          })
        },
        disable: function (e) {
          L.Toolbar2.Action.prototype.disable.call(this);
        }
      });

      L.Toolbar2.RoutesToolbar = L.Toolbar2.Control.extend({
        options: {
          actions: [routesOptions]
        }
      });

      var routesToolbar = new L.Toolbar2.RoutesToolbar({ position: position });
      routesToolbar.addTo(container);

      return () => {
        routesToolbar.remove();
      };
    } catch {}
  }, [...listenMapControlChanges]);
  return null;
};

ToolbarRoutesControl.defaultProps = {
  position: 'topleft',
  showRoutes: false,
  setShowRoutes: () => null,
  listenMapControlChanges: []
};

export default ToolbarRoutesControl;
