import * as types from '../types';

export const startFetchingDivisions = (filters = {}) => ({
  type: types.DIVISIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingDivisions = (entities, order, operatorId, organizationId) => ({
  type: types.DIVISIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingDivisions = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.DIVISIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingDivisions = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.DIVISIONS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingDivisions = (entities, order, operatorId, organizationId) => ({
  type: types.DIVISIONS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingDivisions = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.DIVISIONS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingDivision = (division) => ({
  type: types.DIVISION_ADD_STARTED,
  payload: division
});
export const completeAddingDivision = (oldId, division) => ({
  type: types.DIVISION_ADD_COMPLETED,
  payload: { oldId, division }
});
export const failAddingDivision = (oldId, error) => ({
  type: types.DIVISION_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingDivision = (division) => ({
  type: types.DIVISION_EDIT_STARTED,
  payload: division
});
export const completeEditingDivision = (division) => ({
  type: types.DIVISION_EDIT_COMPLETED,
  payload: division
});
export const failEditingDivision = (oldDivision, error) => ({
  type: types.DIVISION_EDIT_FAILED,
  payload: {
    oldDivision,
    error
  }
});

export const startRemovingDivision = (divisionId) => ({
  type: types.DIVISION_REMOVE_STARTED,
  payload: {
    divisionId
  }
});
export const completeRemovingDivision = (divisionId) => ({
  type: types.DIVISION_REMOVE_COMPLETED,
  payload: {
    divisionId
  }
});
export const failRemovingDivision = (divisionId, error) => ({
  type: types.DIVISION_REMOVE_FAILED,
  payload: {
    divisionId,
    error
  }
});

export const selectDivision = (divisionId) => ({
  type: types.DIVISION_SELECTED,
  payload: divisionId
});

export const selectAllDivisions = (
  divisionIds = [] //List of DivisionIds
) => ({
  type: types.DIVISIONS_ALL_SELECTED,
  payload: divisionIds
});

export const deselectDivision = (divisionId) => ({
  type: types.DIVISION_DESELECTED,
  payload: divisionId
});

export const deselectAllDivisions = (
  divisionIds = [] //List of DivisionIds
) => ({
  type: types.DIVISIONS_ALL_DESELECTED,
  payload: divisionIds
});
