import { combineReducers } from 'redux';

import union from 'lodash/union';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansVisits';
import * as unitSelectors from '../../../Units/reducers';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_VISITS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_VISITS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const allById = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_ALL_VISITS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const allOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_ALL_VISITS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingVisits = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_VISITS_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_VISITS_FETCH_COMPLETED:
    case types.WORKPLANS_VISITS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetchingAllVisits = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_ALL_VISITS_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_ALL_VISITS_FETCH_COMPLETED:
    case types.WORKPLANS_ALL_VISITS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingVisits = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_VISITS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_VISITS_FETCH_STARTED:
    case types.WORKPLANS_VISITS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingAllVisits = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_ALL_VISITS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_ALL_VISITS_FETCH_STARTED:
    case types.WORKPLANS_ALL_VISITS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const workplansVisits = combineReducers({
  byId,
  order,
  allById,
  allOrder,
  isFetchingVisits,
  isFetchingAllVisits,
  errorFetchingVisits,
  errorFetchingAllVisits
});

export default workplansVisits;

//Own state
export const getOwnState = (state) => state.workplans.workplansVisits;

//Information
export const getWorkplansVisit = (state, visitId) => {
  const workplanVisit = getOwnState(state).byId[visitId];
  if (workplanVisit) {
    return workplanVisit;
  } else return undefined;
};
export const getWorkplansVisitsList = (state) =>
  getOwnState(state).order.map((id) => getWorkplansVisit(state, id));

export const getWorkplansAllVisit = (state, visitId) => {
  const workplanVisit = getOwnState(state).allById[visitId];
  if (workplanVisit) {
    return workplanVisit;
  } else return undefined;
};
export const getWorkplansAllVisitsList = (state) =>
  getOwnState(state).allOrder.map((id) => getWorkplansAllVisit(state, id));

export const getWorkplansVisitsListByDuration = (state, duration) =>
  getWorkplansVisitsList(state).filter((visit) => {
    return visit.duration === duration && ['V', 'A', 'T'].includes(visit.visitStatus);
  });

export const getWorkplanAllVisitsById = (state, visitId) =>
  getWorkplansAllVisitsList(state).filter((visit) => visit.visitId === visitId);

export const getWorkplansAllVisitsListByFilters = (state, visitId, locationId, visitStatusArray) =>
  getWorkplansAllVisitsList(state).filter(
    (visit) =>
      visit.visitId === visitId &&
      visit.locationId === locationId &&
      visitStatusArray.includes(visit.visitStatus)
  );

//Status of sagas
export const isFetchingWorkplansVisits = (state) => getOwnState(state).isFetchingVisits;
export const isFetchingWorkplansAllVisits = (state) => getOwnState(state).isFetchingAllVisits;

//Errors
export const getFetchingWorkplansVisitsErrors = (state) => getOwnState(state).errorFetchingVisits;
export const getFetchingWorkplansAllVisitsErrors = (state) =>
  getOwnState(state).errorFetchingAllVisits;
