import camelcaseKeys from 'camelcase-keys';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Fragment, useEffect, useRef, useState, useContext } from 'react';
import { Clock, MapPin, Menu, Settings, Terminal, User } from 'react-feather';
import Lightbox from 'react-image-lightbox';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Badge, Col, Label, Media, Row, Spinner, UncontrolledTooltip } from 'reactstrap';
import Icon from 'utility/icomoon';
import AppCollapse from 'views/components/Collapse';
import DefaultButton from 'views/components/DefaultButton';
import Toolbar from 'views/components/Toolbar';
import SlidingPaneForm from 'views/formComponents/SlidingPaneForm';
import { getUnitDeviceActionParametersService } from 'views/screens/Units/services';
import Avatar from '../../../../../@core/components/avatar';
import * as alerts from '../../../../../redux/utils/alerts';
import MediaGallery from '../../../../components/MediaGallery';

import * as unitActions from '../../actions';
import * as unitSelectors from '../../reducers';
import { UNIT_FETCH_INTERVAL } from '../../types';
import { getUnitActionIcon } from '../../utils';
import SlidingPaneUnitHistoricalPositionsReport from '../SlidingPaneUnitHistoricalPositionsReport';
import SlidingPaneUnitHistoricalScriptsReport from '../SlidingPaneUnitHistoricalScriptsReport';
import SlidingPaneStartUnitDowntime from 'views/formComponents/SlidingPaneStartUnitDowntime';
import SlidingPaneShareUnitLocation from '../SlidingPaneShareUnitLocation';
import SlidingPaneDowntimeBinnacle from 'views/formComponents/SlidingPaneDowntimeBinnacle';
import SlidingPanePreviewDriverImages from 'views/formComponents/SlidingPanePreviewDriverImages';
import SlidingPaneUnitImages from '../SlidingPaneUnitImages';
import UnitFuel from '../UnitFuel';
import UnitTemperature from '../UnitTemperature';
import unitPermissions from '../../permissions';
import DefaultSimpleButton from 'views/components/DefaultSimpleButton';
import * as authSelectors from '../../../../../redux/reducers/auth';
import { MainStateType, GetMainStateStyle, GetMainStateById } from 'views/screens/Units/utils';
import ManualOdometerAdjustment, {
  ButtonTypes
} from 'views/formComponents/ManualOdometerAdjustment';
import { isNotNaNOrNullable } from 'utility/Utils';
import ManualHourmeterAdjustment from 'views/formComponents/ManualHourmeterAdjustment';
import { Metrics, searchUseMetric } from 'views/screens/Telemetry/utils';

/* -------------------------------------------------------------------------- */
/*                          SlidingPaneUnitInformation                        */
/* -------------------------------------------------------------------------- */
export default function SlidingPaneUnitInformation({
  closeSlidingPane,
  isSlidingPaneOpen,
  showHistoricalPositions,
  setMapPositionToPositionsBounds = false,
  changeSetMapPositionToUnitsBounds,
  title = '',
  selectedUnit,
  setSelectedUnit,
  selectedUnitRef,
  unitId
}) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;
  const fetchInformationInterval = useRef<any>();
  const latitudeRef = useRef<any>();
  const [showAddImageSlidingPane, setShowAddImageSlidingPane] = useState(false);
  const [showHistPositionsReportSlidingPane, setShowHistPositionsReportSlidingPane] =
    useState(false);
  const [downtimeBinnacleSlideOpen, openDowntimeBinnacleSlide] = useState(false);
  const [showShareUnitLocationSlidingPane, setShowShareUnitLocationSlidingPane] = useState(false);
  const [showHistScriptsReportSlidingPane, setShowHistScriptsReportSlidingPane] = useState(false);
  const [showChangeStatusSlidingPane, setShowChangeStatusPane] = useState(false);
  const [showUnitImagesSlidingPane, setShowUnitImagesSlidingPane] = useState(false);
  const [previewDriverImagesSlideOpen, openPreviewDriverImagesSlide] = useState(false);
  const [isOpenSlidingPaneForm, setOpenSlidingPaneForm] = useState(false);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [slidingPaneProps, setSlidingPaneProps] = useState<any>({ inputs: [] });

  const {
    unit,
    isFetching,
    isAcknowledgeUnitEvent,
    isEditingDowntime,
    store,
    authSystems,
    userPermissions
  } = useSelector((state) => {
    const unit = unitSelectors.getUnit(state, unitId);
    return {
      unit: unitId
        ? {
            ...unit,
            intlLastEvent: intl.formatMessage({ id: `organizations.event${unit?.eventId ?? 0}` })
          }
        : undefined,
      isFetching: unitSelectors.isFetchingUnitInformation(state),
      isAcknowledgeUnitEvent: unitSelectors.isAcknowledgeUnitEvent(state),
      isEditingDowntime: unitSelectors.isEditingUnitDowntime(state),
      store: state,
      authSystems: authSelectors.getAuthSystems(state),
      userPermissions: authSelectors.getAuthUserPermissions(state)
    };
  });

  const permissionState = {
    ViewMetricsMonitor: 'TU001',
    showACCState: userPermissions.includes('TU002'),
    showAppVersion: userPermissions.includes('TU008'),
    showBackupBattery: userPermissions.includes('TU009'),
    showBattery: userPermissions.includes('TU010'),
    showCellNumber: userPermissions.includes('TU011'),
    showCoverTamperState: userPermissions.includes('TU012'),
    showDoorState: userPermissions.includes('TU013'),
    showICCID: userPermissions.includes('TU017'),
    showIsCharging: userPermissions.includes('TU018'),
    showMainVoltage: userPermissions.includes('TU019'),
    showMCC: userPermissions.includes('TU020'),
    showMNC: userPermissions.includes('TU021'),
    showPadlockAuthorizationType: userPermissions.includes('TU023'),
    showPadlockState: userPermissions.includes('TU024'),
    showPLMN: userPermissions.includes('TU025'),
    showSatellites: userPermissions.includes('TU026'),
    showTrailerID: userPermissions.includes('TU028'),
    showDriverID: userPermissions.includes('TU029')
  };

  const indicators = [
    {
      title: 'organizations.event2',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.Battery.BitNumber)
        ? unit?.battery ?? ''
        : '--',
      metric: unit?.battery ? '%' : '',
      icon: 'Battery',
      field: 'Battery',
      useInActive: true,
      useInPeople: true,
      hidden:
        !permissionState.showBattery ||
        !searchUseMetric(unit?.metricsMask, Metrics.Battery.BitNumber)
    },
    {
      title: 'metric.mainVoltage',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.MainVoltage.BitNumber)
        ? unit?.mainVoltage ?? ''
        : '--',
      metric: 'volt',
      icon: 'Lightning',
      sizeIcon: 30,
      field: 'MainVoltage',
      useInVehicles: true,
      hidden:
        !permissionState.showMainVoltage ||
        !searchUseMetric(unit?.metricsMask, Metrics.MainVoltage.BitNumber)
    },
    {
      title: 'metric.isCharging',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.IsCharging.BitNumber)
        ? intl.formatMessage({ id: unit?.isCharging ? 'common.yes' : 'common.no' })
        : '--',
      metric: '',
      icon: 'Alert-350',
      field: 'IsCharging',
      customChartTooltip: 'bool',
      useInActive: true,
      isSelectable: false,
      hidden:
        !permissionState.showIsCharging ||
        !searchUseMetric(unit?.metricsMask, Metrics.IsCharging.BitNumber)
    },
    {
      title: 'metric.BB',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.BackupBattery.BitNumber)
        ? unit?.backupBattery ?? ''
        : '--',
      metric: unit?.backupBattery ? 'volt' : '',
      icon: 'backupEnergy',
      field: 'BackupBattery',
      useInActive: true,
      useInVehicles: true,
      hidden:
        !permissionState.showBackupBattery ||
        !searchUseMetric(unit?.metricsMask, Metrics.BackupBattery.BitNumber)
    },
    {
      title: 'metric.DS',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.DoorState.BitNumber)
        ? intl.formatMessage({ id: unit?.doorState ? 'common.yes' : 'common.no' })
        : '--',
      metric: '',
      icon: 'OpenDoor',
      field: 'DoorState',
      useInActive: true,
      useInVehicles: true,
      isSelectable: false,
      hidden:
        !permissionState.showDoorState ||
        !searchUseMetric(unit?.metricsMask, Metrics.DoorState.BitNumber)
    },
    {
      title: 'metric.CTS',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.CoverTamperState.BitNumber)
        ? intl.formatMessage({
            id: unit?.coverTamperState ? 'common.yes' : 'common.no'
          })
        : '--',
      metric: '',
      field: 'CoverTamperState',
      useInActive: true,
      isSelectable: false,
      hidden:
        !permissionState.showCoverTamperState ||
        !searchUseMetric(unit?.metricsMask, Metrics.CoverTamperState.BitNumber)
    },
    {
      title: 'metric.PLS',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.PadlockState.BitNumber)
        ? intl.formatMessage({
            id: unit?.padlockState ? 'trackingUnits.sat.open' : 'trackingUnits.sat.closed'
          })
        : '--',
      metric: '',
      field: 'PadlockState',
      useInActive: true,
      isSelectable: false,
      hidden:
        !permissionState.showPadlockState ||
        !searchUseMetric(unit?.metricsMask, Metrics.PadlockState.BitNumber)
    },
    {
      title: 'metric.PLA',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.PadlockAuthorizationType.BitNumber)
        ? intl.formatMessage({
            id: unit?.padlockAuthorizationType ? 'common.yes' : 'common.no'
          })
        : '--',
      metric: '',
      field: 'PadlockAuthorizationType',
      useInActive: true,
      isSelectable: false,
      hidden:
        !permissionState.showPadlockAuthorizationType ||
        !searchUseMetric(unit?.metricsMask, Metrics.PadlockAuthorizationType.BitNumber)
    },
    {
      title: 'metric.SAT',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.Satellites.BitNumber)
        ? unit?.satellites ?? ''
        : '--',
      metric: '',
      icon: 'satellite',
      field: 'Satellites',
      useInActive: true,
      useInVehicles: true,
      linealChart: true,
      hidden:
        !permissionState.showSatellites ||
        !searchUseMetric(unit?.metricsMask, Metrics.Satellites.BitNumber)
    },
    {
      title: 'metric.abrev.plmn',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.PLMN.BitNumber)
        ? unit?.plmn ?? ''
        : '--',
      metric: '',
      field: 'PLMN',
      useInActive: true,
      useInVehicles: true,
      hidden:
        !unit?.plmn ||
        !permissionState.showPLMN ||
        !searchUseMetric(unit.metricsMask, Metrics.PLMN.BitNumber)
    },
    {
      title: 'metric.abrev.mcc',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.MCC.BitNumber)
        ? unit?.mcc ?? ''
        : '--',
      metric: '',
      field: 'MCC',
      useInActive: true,
      useInVehicles: true,
      isSelectable: false,
      hidden: !permissionState.showMCC || !searchUseMetric(unit?.metricsMask, Metrics.MCC.BitNumber)
    },
    {
      title: 'metric.abrev.mnc',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.MNC.BitNumber)
        ? unit?.mnc ?? ''
        : '--',
      metric: '',
      field: 'MNC',
      useInActive: true,
      useInVehicles: true,
      isSelectable: false,
      hidden:
        !unit?.mnc ||
        !permissionState.showMNC ||
        !searchUseMetric(unit.metricsMask, Metrics.MNC.BitNumber)
    },
    {
      title: 'metric.CN',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.CellNumber.BitNumber)
        ? unit?.cellNumber ?? ''
        : '--',
      metric: '',
      icon: 'cellular-signal',
      field: 'CellNumber',
      useInActive: true,
      useInVehicles: true,
      hidden:
        !permissionState.showCellNumber ||
        !searchUseMetric(unit?.metricsMask, Metrics.CellNumber.BitNumber)
    },
    {
      title: 'metric.abrev.ICC',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.ICCID.BitNumber)
        ? unit?.iccid ?? ''
        : '--',
      metric: '',
      field: 'ICCID',
      isSelectable: false,
      useInActive: true,
      useInVehicles: true,
      hidden:
        !unit?.iccid ||
        !permissionState.showICCID ||
        !searchUseMetric(unit?.metricsMask, Metrics.ICCID.BitNumber)
    },
    /* indicadores de vehiculos */
    {
      title: 'metric.DI',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.DriverId.BitNumber)
        ? unit?.driverId ?? ''
        : '--',
      metric: '',
      field: 'DriverId',
      isSelectable: false,
      useInActive: false,
      useInVehicles: true,
      hidden:
        !unit?.driverId ||
        !permissionState.showDriverID ||
        !searchUseMetric(unit?.metricsMask, Metrics.DriverId.BitNumber)
    },
    {
      title: 'metric.TID',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.TrailerID.BitNumber)
        ? unit?.trailerId ?? ''
        : '--',
      metric: '',
      field: 'TrailerId',
      isSelectable: false,
      useInActive: false,
      useInVehicles: true,
      hidden:
        !unit?.trailerId ||
        !permissionState.showTrailerID ||
        !searchUseMetric(unit?.metricsMask, Metrics.TrailerID.BitNumber)
    },
    {
      title: 'AppVersion',
      currentStatus: searchUseMetric(unit?.metricsMask, Metrics.AppVersion.BitNumber)
        ? unit?.appVersion ?? ''
        : '--',
      metric: '',
      field: 'AppVersion',
      isSelectable: false,
      useInPeople: true,
      hidden:
        !permissionState.showAppVersion ||
        !searchUseMetric(unit?.metricsMask, Metrics.AppVersion.BitNumber)
    }
  ];

  const availableIndicators =
    unit?.unitTypeId === 2
      ? indicators.filter((indicator) => !indicator?.hidden && indicator.useInActive === true)
      : unit?.unitTypeId === 1
      ? indicators.filter((indicator) => !indicator?.hidden && indicator.useInVehicles === true)
      : unit?.unitTypeId === 0
      ? indicators.filter((indicator) => !indicator?.hidden && indicator.useInPeople === true)
      : [];

  /* 
  useEffect(() => {
    if (unit) unitContext.changeUnitState({ ...unit, isSelected: true });
  }, [isSlidingPaneOpen]); */

  const cleanup = () => {
    if (fetchInformationInterval.current) clearInterval(fetchInformationInterval.current);
  };
  const [showAllButtonsRightToolbar, setShowAllButtonsRightToolbar] = useState(false);

  useEffect(() => {
    return () => cleanup();
  }, []);

  useEffect(() => {
    cleanup();
    if (isSlidingPaneOpen && unit?.unitId && isEditingDowntime === false) {
      dispatch(unitActions.startFetchingUnitInformation(unit?.unitId));
      fetchInformationInterval.current = setInterval(() => {
        dispatch(unitActions.startFetchingUnitInformation(unit?.unitId));
      }, UNIT_FETCH_INTERVAL);
    }
  }, [isSlidingPaneOpen, unit?.unitId, isEditingDowntime]);

  const copyLatLong = (latitude, longitude) => {
    navigator.clipboard.writeText(`${latitude.toFixed(6)},${longitude.toFixed(6)}`);

    alerts.showSuccessMessage({
      success: intl.formatMessage({ id: 'common.copyClipboardSuccessfully' })
    });
  };

  const doAction = (unit, action) => {
    if (action.requireUserConfirmation) {
      alerts.showConfirmationMessage({
        message: intl.formatMessage(
          { id: 'units.userConfirmationMessage' },
          {
            unit: unit.unitName,
            action: intl.formatMessage({
              id: 'units.deviceActionId.' + action.deviceActionId
            })
          }
        ),
        action() {
          dispatch(unitActions.executeDeviceAction(unit.unitId, action.deviceActionId));
        }
      });
    } else {
      if (action.requireParameters) {
        getUnitDeviceActionParametersService({
          unitId: unit.unitId,
          deviceActionId: action.deviceActionId
        }).then((response) => {
          if (response.success) {
            setSlidingPaneProps({
              title: intl.formatMessage({
                id: 'units.deviceActionId.' + action.deviceActionId
              }),
              width: '40rem',
              inputs: response.data.map((result) => {
                const param = camelcaseKeys(result);
                return {
                  type: param.inputType,
                  name: 'input-' + param.deviceActionParameterId,
                  initialValue: param.inputValue,
                  required: param.isRequired,
                  label: param.parameterName,
                  placeholder: param.parameterComments,
                  appendcomponent: param.measurementUnitName,
                  min: param.minValue != null ? param.minValue : undefined,
                  max: param.maxValue != null ? param.maxValue : undefined
                };
              }),
              action: (values) =>
                dispatch(
                  unitActions.executeDeviceAction(
                    unit.unitId,
                    action.deviceActionId,
                    response.data
                      .map((result) => {
                        const param = camelcaseKeys(result);
                        return (
                          param.deviceActionParameterId +
                          '~' +
                          values['input-' + param.deviceActionParameterId]
                        );
                      })
                      .join('^')
                  )
                )
            });
            setOpenSlidingPaneForm(true);
          }
        });
      } else dispatch(unitActions.executeDeviceAction(unit.unitId, action.deviceActionId));
    }
  };

  return (
    <>
      <SlidingPane
        className="custom-sliding-pane"
        overlayClassName={'custom-overlay-sliding-pane-map'}
        isOpen={isSlidingPaneOpen}
        title={title}
        width="40rem"
        onRequestClose={closeSlidingPane}
      >
        {!isFetching && (unit?.images?.length ?? 0) > 0 && (
          <MediaGallery
            height={'20%'}
            heightThumbs={'5%'}
            isRtl={false}
            images={
              unit?.images?.map((image) => ({
                ...image,
                fullImageUrl: image?.imageUrl
              })) ?? []
            }
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            navigation={true}
            pagination={true}
            className={'default-width-image'}
            classNameImg={'img-contain-nowidth'}
          />
        )}
        {/* Toolbar for unit information */}
        {unit?.latitude != null && unit?.longitude != null && (
          <>
            <Row>
              <Col sm="3" className="d-flex mb-0 align-items-center">
                <div className={'d-flex'}>
                  <Label className="label-information">
                    {intl.formatMessage({ id: 'units.id' })}:
                  </Label>
                  <Label className="ml-2 label-value-information">{unit.unitId}</Label>
                </div>
              </Col>
              <Col sm="9">
                <Toolbar
                  className={'mb-0'}
                  buttons={[
                    <DefaultButton
                      id={'setShowAllButtonsRightToolbar'}
                      icon={showAllButtonsRightToolbar ? 'ChevronRight' : 'ChevronLeft'}
                      handleClick={() => {
                        showAllButtonsRightToolbar
                          ? setShowAllButtonsRightToolbar(false)
                          : setShowAllButtonsRightToolbar(true);
                      }}
                      tooltipMessage={intl.formatMessage({
                        id: showAllButtonsRightToolbar
                          ? 'common.hideAllButtons'
                          : 'common.showAllButtons'
                      })}
                    />,
                    <DefaultButton
                      id={'binnacleButton'}
                      icon={'Planning'}
                      color="secondary"
                      permissions={[unitPermissions.downtime]}
                      handleClick={() => {
                        openDowntimeBinnacleSlide(true);
                      }}
                      tooltipMessage={intl.formatMessage({ id: 'trackingUnits.downtimeBinnacle' })}
                      visible={showAllButtonsRightToolbar}
                    />,
                    <DefaultButton
                      id={'historicalScriptsButton'}
                      icon={'noun_service_1318655'}
                      isDisabled={false}
                      handleClick={() => {
                        setShowHistScriptsReportSlidingPane(true);
                      }}
                      tooltipMessage={intl.formatMessage({
                        id: 'units.showHistoricalScriptsReport'
                      })}
                      visible={showAllButtonsRightToolbar}
                    />,
                    <DefaultButton
                      id={'unitImagesAddButton'}
                      icon={'Upload'}
                      handleClick={() => {
                        setShowAddImageSlidingPane(true);
                        setShowUnitImagesSlidingPane(true);
                      }}
                      tooltipMessage={intl.formatMessage({ id: 'units.addImage' })}
                      visible={showAllButtonsRightToolbar}
                    />,
                    <DefaultButton
                      id={'unitImagesButton'}
                      icon={'Image'}
                      isDisabled={false}
                      handleClick={() => {
                        setShowAddImageSlidingPane(false);
                        setShowUnitImagesSlidingPane(true);
                      }}
                      tooltipMessage={intl.formatMessage({ id: 'units.editImages' })}
                      visible={showAllButtonsRightToolbar}
                    />,
                    <DefaultButton
                      id={'shareUnitLocationLinkButton'}
                      icon={'Link'}
                      isDisabled={false}
                      handleClick={() => {
                        setShowShareUnitLocationSlidingPane(true);
                      }}
                      tooltipMessage={intl.formatMessage({ id: 'units.shareLocation' })}
                    />,
                    <DefaultButton
                      id={'historicalPositionsButton'}
                      icon={'Kilometer'}
                      isDisabled={false}
                      handleClick={() => {
                        setShowHistPositionsReportSlidingPane(true);
                      }}
                      tooltipMessage={intl.formatMessage({
                        id: 'units.showHistoricalPositionsReport'
                      })}
                    />
                  ]}
                />
              </Col>
            </Row>

            {/* unit information */}
            <hr className={'full-width-slide-panel'} />
            {unit?.driverId && (
              <>
                <Label className="label-information">
                  <User color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.driver' })}
                </Label>
                <Media className={'align-items-center'}>
                  <Avatar
                    // @ts-ignore unreachable error
                    img={unit?.driverPhoto ? unit?.driverPhoto : undefined}
                    color="light-primary"
                    className={'mr-1'}
                    content={unit.driverName ?? ''}
                    initials
                    onClick={() => setIsLightboxOpen(true)}
                  />
                  <div>
                    <Label className="label-value-information">
                      {!isEmpty(unit.driverName) ? unit.driverName : 'N/A'}
                    </Label>
                    {unit?.driverPhoneNumber &&
                      `${intl.formatMessage({ id: 'units.phone' })}: ${unit?.driverPhoneNumber}`}
                  </div>
                  <div>
                    {(unit?.driverTotalFiles ?? 0) > 0 && (
                      <>
                        <UncontrolledTooltip target={'driverAttachedImagesButton'}>
                          {intl.formatMessage({ id: 'drivers.seeAttachedImages' })}
                        </UncontrolledTooltip>
                        <Icon
                          id={'driverAttachedImagesButton'}
                          icon={'Image'}
                          color={primary}
                          size={18}
                          style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                          onClick={() => openPreviewDriverImagesSlide(true)}
                        />
                      </>
                    )}
                  </div>
                </Media>
                <hr className={'full-width-slide-panel'} />
              </>
            )}
            <Label className="label-information">
              <MapPin color={primary} size={15} style={{ marginRight: '0.2rem' }} />
              {intl.formatMessage({ id: 'units.locationName' })}
            </Label>

            <Label className="label-value-information">
              {!isEmpty(unit.locationName) ? unit.locationName : 'N/A'}
            </Label>
            <hr className={'full-width-slide-panel'} />

            <Row>
              {unit?.latitude != null && unit?.longitude != null && (
                <Col>
                  <UncontrolledTooltip target={'lat'}>
                    {intl.formatMessage({ id: 'common.clickCopyClipboard' })}
                  </UncontrolledTooltip>
                  <div
                    id="lat"
                    onClick={() => {
                      copyLatLong(unit?.latitude, unit?.longitude);
                    }}
                  >
                    <Label className="label-information">
                      <Icon
                        icon={'Target'}
                        color={primary}
                        size={15}
                        style={{ marginRight: '0.2rem' }}
                      />
                      {intl.formatMessage({ id: 'units.latitude' })}
                    </Label>
                    <Label className="label-value-information">{unit?.latitude.toFixed(6)}</Label>
                  </div>
                </Col>
              )}
              {unit?.latitude != null && unit?.longitude != null && (
                <Col className={'border-left'}>
                  <UncontrolledTooltip target={'lon'}>
                    {intl.formatMessage({ id: 'common.clickCopyClipboard' })}
                  </UncontrolledTooltip>
                  <div
                    id="lon"
                    onClick={() => {
                      copyLatLong(unit?.latitude, unit?.longitude);
                    }}
                  >
                    <Label className="label-information">
                      <Icon
                        icon={'Target'}
                        color={primary}
                        size={15}
                        style={{ marginRight: '0.2rem' }}
                      />
                      {intl.formatMessage({ id: 'units.longitude' })}
                    </Label>
                    <Label className="label-value-information">{unit?.longitude.toFixed(6)}</Label>
                  </div>
                </Col>
              )}
              <hr className={'full-width-slide-panel'} />
            </Row>

            <Row>
              <Col>
                <Label className="label-information mr-2">
                  <Settings color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.status' })}
                </Label>
                {isEditingDowntime === true || isFetching === true ? (
                  <Row>
                    <Col sm="6" className={'d-flex justify-content-center mt-50'}>
                      <Spinner color="primary" size="sm" />
                    </Col>
                  </Row>
                ) : (
                  <>
                    <Badge
                      pill
                      className="mt-25"
                      style={GetMainStateStyle(GetMainStateById(unit?.mainStateId ?? -1))}
                    >
                      {unit.mainStateName}
                    </Badge>
                    {authSelectors.getAuthUserHasPermissionsAny(store, [
                      unitPermissions.downtime
                    ]) &&
                      (unit.downtimeReasonId === null || unit.downtimeReasonId === undefined) && (
                        <>
                          <UncontrolledTooltip target={'editUnitMainStateTypeButton'}>
                            {intl.formatMessage({ id: 'units.startDowntime' })}
                          </UncontrolledTooltip>
                          <Icon
                            id={'editUnitMainStateTypeButton'}
                            icon={'ArrowDown'}
                            color={primary}
                            size={17}
                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                            onClick={() => setShowChangeStatusPane(true)}
                          />
                        </>
                      )}
                    {authSelectors.getAuthUserHasPermissionsAny(store, [
                      unitPermissions.downtime
                    ]) &&
                      unit.downtimeReasonId !== undefined &&
                      unit.downtimeReasonId !== null && (
                        <>
                          <UncontrolledTooltip target={'restoreUnitMainStateTypeButton'}>
                            {intl.formatMessage({ id: 'units.endDowntime' })}
                          </UncontrolledTooltip>
                          <Icon
                            id={'restoreUnitMainStateTypeButton'}
                            icon={'ArrowUp'}
                            color={primary}
                            size={15}
                            style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                            onClick={() => dispatch(unitActions.startEndDowntimeUnit(unit?.unitId))}
                          />
                        </>
                      )}
                  </>
                )}
              </Col>
              <Col className={'border-left'}>
                <Label className="label-information">
                  <Icon
                    icon={'Target'}
                    color={primary}
                    size={15}
                    style={{ marginRight: '0.2rem' }}
                  />
                  {intl.formatMessage({ id: 'units.lastEvent' })}
                </Label>
                {unit.eventId != undefined ? (
                  isAcknowledgeUnitEvent ? (
                    <Row>
                      <Col sm="6" className={'d-flex justify-content-center mt-50'}>
                        <Spinner color="primary" size="sm" />
                      </Col>
                    </Row>
                  ) : (
                    <Media id={'lastEventBadge'} className={'d-flex align-items-center'}>
                      {unit?.intlLastEvent?.length >= 15 && (
                        <UncontrolledTooltip target={'lastEventBadge'}>
                          <FormattedMessage id={`organizations.event${unit?.eventId ?? 0}`} />
                        </UncontrolledTooltip>
                      )}
                      <Badge
                        pill
                        className="mt-25 mr-50"
                        style={{
                          color: '#' + (unit.eventTextColor || '000000'),
                          backgroundColor: '#' + unit.eventColor,
                          display: 'inline-block',
                          fontSize: '13px'
                        }}
                      >
                        {unit.eventName || unit.eventId
                          ? `${
                              unit?.intlLastEvent.length >= 15
                                ? unit?.intlLastEvent?.substring(0, 15) + '...'
                                : unit.eventId != null
                                ? unit?.intlLastEvent
                                : ''
                            }`
                          : ''}
                      </Badge>
                      {unit.eventId !== 0 && (
                        <DefaultSimpleButton
                          id={'acknowledge'}
                          color={primary}
                          icon={'RefreshCcw'}
                          tooltipMessage={intl.formatMessage({ id: 'units.acknowledge' })}
                          handleClick={() => {
                            dispatch(unitActions.startAcknowledgeUnitEvent(unit.unitId));
                          }}
                        />
                      )}
                    </Media>
                  )
                ) : null}
              </Col>
            </Row>
            <hr className={'full-width-slide-panel'} />
            <Row>
              <Col>
                <Label className="label-information">
                  <Clock color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.gpsDate' })}
                </Label>
                <Label className="label-value-information">
                  {unit.gpsDate ? moment(unit.gpsDate).format('YYYY-MM-DD HH:mm') : 'N/A'}
                </Label>
              </Col>
              <Col className={'border-left'}>
                <Label className="label-information">
                  <Clock color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.lastCommunicationDate' })}
                </Label>
                <Label className="label-value-information">
                  {unit.lastCommunicationDate
                    ? moment(unit.lastCommunicationDate).format('YYYY-MM-DD HH:mm')
                    : 'N/A'}
                </Label>
              </Col>
            </Row>
            <hr className={'full-width-slide-panel'} />
            <Row>
              <Col>
                <Label className="label-information">
                  <Icon
                    icon={'Frontier'}
                    color={primary}
                    size={15}
                    style={{ marginRight: '0.2rem' }}
                  />
                  {intl.formatMessage({ id: 'units.odometer' })}
                </Label>
                <Label className="label-value-information">
                  {unit.odometerFormat || 'N/A'}{' '}
                  <ManualOdometerAdjustment
                    buttonType={ButtonTypes.Icon}
                    defaultValue={unit.odometer}
                    unitId={unit?.unitId ?? -1}
                  />
                </Label>
              </Col>
              <Col className={'border-left'}>
                <Label className="label-information">
                  <Icon
                    icon={'Frontier'}
                    color={primary}
                    size={15}
                    style={{ marginRight: '0.2rem' }}
                  />
                  {intl.formatMessage({ id: 'units.hourMeter' })}
                </Label>
                <Label className="label-value-information">
                  {unit.hourMeterFormat || 'N/A'}{' '}
                  <ManualHourmeterAdjustment
                    buttonType={ButtonTypes.Icon}
                    defaultValue={unit?.hourMeter}
                    unitId={unit?.unitId ?? -1}
                  />
                </Label>
              </Col>
            </Row>
            <hr className={'full-width-slide-panel'} />
            <Row>
              <Col>
                <Label className="label-information">
                  <Menu color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.privateCode' })}
                </Label>
                <Label className="label-value-information">
                  {!isEmpty(unit.privateCode) ? unit.privateCode : 'N/A'}
                </Label>
              </Col>
              <Col className={'border-left'}>
                <Label className="label-information">
                  <Menu color={primary} size={15} style={{ marginRight: '0.2rem' }} />
                  {intl.formatMessage({ id: 'units.privateCode' }) + ' 2'}
                </Label>
                <Label className="label-value-information">
                  {!isEmpty(unit.privateCode2) ? unit.privateCode2 : 'N/A'}
                </Label>
              </Col>
            </Row>
            <hr className={'full-width-slide-panel'} />
            <Row>
              <Col>
                <Label className="label-information">
                  <Icon
                    icon={'Speedometer'}
                    color={primary}
                    size={17}
                    style={{ marginRight: '0.2rem' }}
                  />
                  {intl.formatMessage({ id: 'units.speed' })}
                </Label>
                <Label className="label-value-information">{unit.speedFormat || 'N/A'}</Label>
              </Col>
              {availableIndicators.map((indicator, i) => (
                <Fragment key={i}>
                  {i % 2 !== 0 && <hr className={'full-width-slide-panel'} />}
                  <Col className={i % 2 === 0 ? 'border-left' : ''}>
                    <Label className="label-information">
                      <Terminal color={primary} size={17} style={{ marginRight: '0.2rem' }} />
                      {intl.formatMessage({ id: indicator.title })}
                    </Label>
                    <Label className="label-value-information">
                      {indicator.currentStatus +
                        ' ' +
                        (indicator.currentStatus != '--' ? indicator.metric : '' || '')}
                    </Label>
                  </Col>
                </Fragment>
              ))}
            </Row>
            <hr className={'full-width-slide-panel'} />
            {(unit?.deviceActions
              ?.filter((action) => action?.deviceActionId)
              ?.filter((action) =>
                authSelectors.getAuthUserHasPermissionsAny(store, [
                  unitPermissions[`deviceAction${action.deviceActionId}`]
                ])
              )?.length ?? 0) > 0 && (
              <>
                <Label className="label-information">
                  <Icon
                    icon={'Lightning'}
                    color={primary}
                    size={22}
                    style={{ marginRight: '0.2rem' }}
                  />
                  {intl.formatMessage({ id: 'units.actions' })}
                </Label>
                <Toolbar
                  position={'left'}
                  buttons={
                    unit?.deviceActions
                      ?.filter((action) => action?.deviceActionId)
                      ?.map((action, i) => (
                        <DefaultButton
                          className={'mt-50'}
                          id={'ActionButton-' + i}
                          icon={getUnitActionIcon(action.deviceActionId)}
                          permissions={[unitPermissions[`deviceAction${action.deviceActionId}`]]}
                          handleClick={() => doAction(unit, action)}
                          tooltipMessage={intl.formatMessage({
                            id: 'units.deviceActionId.' + action.deviceActionId
                          })}
                        />
                      )) ?? []
                  }
                />
                <hr className={'full-width-slide-panel'} />
              </>
            )}
            <AppCollapse
              className={'full-width-slide-panel info-collapse'}
              data={[
                (unit?.deviceMetrics?.filter(
                  (metric) => metric?.measureTypeId && metric.measureTypeId === 3
                )?.length ?? 0) > 0
                  ? {
                      title: intl.formatMessage({ id: 'units.temperatureSensors' }),
                      content: (
                        <UnitTemperature
                          unit={unit}
                          sensors={unit.sensors?.filter((s) => s.measureTypeId === 3)}
                        />
                      )
                    }
                  : undefined,
                (unit?.deviceMetrics?.filter(
                  (metric) => metric?.measureTypeId && metric.measureTypeId === 8
                )?.length ?? 0) > 0
                  ? {
                      title: intl.formatMessage({ id: 'units.gasolineSensors' }),
                      content: (
                        <UnitFuel
                          unit={unit}
                          sensors={unit.sensors?.filter((s) => s?.measureTypeId === 8)}
                          authSystems={authSystems}
                        />
                      )
                    }
                  : undefined,
                (unit?.deviceMetrics?.filter(
                  (metric) => metric?.measureTypeId && metric.measureTypeId === 16
                )?.length ?? 0) > 0
                  ? {
                      title: intl.formatMessage({ id: 'units.pressureSensors' }),
                      content: (
                        <Row>
                          {unit?.deviceMetrics
                            ?.filter(
                              (metric) => metric?.measureTypeId && metric.measureTypeId === 16
                            )
                            ?.map((metric, i) => (
                              <Fragment key={i}>
                                {i % 2 === 0 && i !== 0 && (
                                  <hr className={'full-width-slide-panel'} />
                                )}
                                <Col className={i % 2 !== 0 ? 'border-left' : ''}>
                                  <Label className="label-information">
                                    <Icon
                                      icon={'GasStation'}
                                      color={primary}
                                      size={16}
                                      style={{ marginRight: '0.2rem' }}
                                    />
                                    {metric.metricDescription}
                                  </Label>
                                  <Label className="label-value-information">{`${metric.numberValue} ${metric.measurementUnitName}`}</Label>
                                </Col>
                              </Fragment>
                            ))}
                        </Row>
                      )
                    }
                  : undefined
              ].filter((a) => a)}
            />
          </>
        )}
      </SlidingPane>
      {isLightboxOpen && (
        <Lightbox
          discourageDownloads={false}
          reactModalStyle={{ overlay: { zIndex: 20000 } }}
          imageTitle={unit?.driverName}
          imageCaption={
            <>
              <div>{`${intl.formatMessage({ id: 'units.phone' })}: ${
                unit?.driverPhoneNumber ?? 'N/A'
              }`}</div>
              <div>{`${intl.formatMessage({ id: 'units.bloodType' })}: ${
                unit?.driverBloodType ?? 'N/A'
              }`}</div>
              <div>{`${intl.formatMessage({ id: 'units.licenseNumber' })}: ${
                unit?.driverLicenseNumber ?? 'N/A'
              }`}</div>
              <div>{`${intl.formatMessage({ id: 'units.licenseType' })}: ${
                unit?.driverLicenseType ?? 'N/A'
              }`}</div>
              <div>{`${intl.formatMessage({ id: 'units.licenseExpireDate' })}: ${
                unit?.driverLicenseExpireDate
                  ? moment(unit.driverLicenseExpireDate).format('YYYY-MM-DD')
                  : 'N/A'
              }`}</div>
              <div>{`${intl.formatMessage({ id: 'units.useGlasses' })}: ${intl.formatMessage({
                id: unit?.driverUseGlasses ? 'common.yes' : 'common.no'
              })}`}</div>
              <div>{`${intl.formatMessage({
                id: 'units.bloodSugarProblems'
              })}: ${intl.formatMessage({
                id: unit?.driverBloodSugarProblems ? 'common.yes' : 'common.no'
              })}`}</div>
            </>
          }
          mainSrc={unit?.driverPhoto ?? ''}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      )}
      <SlidingPaneForm
        confirmText={intl.formatMessage({ id: 'common.confirm' })}
        isOpenSlidingPane={isOpenSlidingPaneForm}
        closeSlidingPaneForm={() => setOpenSlidingPaneForm(false)}
        {...slidingPaneProps}
      />
      <SlidingPaneUnitImages
        closeSlidingPane={() => {
          setShowUnitImagesSlidingPane(false);
        }}
        isSlidingPaneOpen={showUnitImagesSlidingPane}
        title={unit?.unitName}
        unit={unit}
        addImageInitial={showAddImageSlidingPane}
      />
      <SlidingPaneUnitHistoricalPositionsReport
        closeSlidingPane={() => {
          setShowHistPositionsReportSlidingPane(false);
        }}
        setMapPositionToPositionsBounds={setMapPositionToPositionsBounds}
        changeSetMapPositionToUnitsBounds={changeSetMapPositionToUnitsBounds}
        isSlidingPaneOpen={showHistPositionsReportSlidingPane}
        showHistoricalPositions={showHistoricalPositions}
        title={intl.formatMessage({ id: 'units.historicalPositionsReport' })}
        selectedUnit={selectedUnit}
        selectedUnitRef={selectedUnitRef}
        setSelectedUnit={setSelectedUnit}
        subtitle={unit?.unitName ?? ''}
        unit={unit}
      />
      <SlidingPaneUnitHistoricalScriptsReport
        closeSlidingPane={() => {
          setShowHistScriptsReportSlidingPane(false);
        }}
        isSlidingPaneOpen={showHistScriptsReportSlidingPane}
        title={intl.formatMessage({ id: 'units.historicalScriptsReport' })}
        subtitle={unit?.unitName ?? ''}
        unit={unit}
      />
      <SlidingPaneShareUnitLocation
        closeSlidingPane={() => {
          setShowShareUnitLocationSlidingPane(false);
        }}
        isSlidingPaneOpen={showShareUnitLocationSlidingPane}
        unit={unit}
      />
      <SlidingPaneStartUnitDowntime
        closeSlidingPane={() => {
          setShowChangeStatusPane(false);
        }}
        isSlidingPaneOpen={showChangeStatusSlidingPane}
        unit={unit}
      />
      <SlidingPaneDowntimeBinnacle
        isOpenSlidingPane={downtimeBinnacleSlideOpen}
        closeSlidingPaneDowntimeBinnacle={() => {
          openDowntimeBinnacleSlide(false);
        }}
        unitId={unit?.unitId}
        unitName={unit?.unitName}
      />
      <SlidingPanePreviewDriverImages
        driver={
          unit !== undefined && unit !== null
            ? { driverId: unit?.driverId, driverName: unit?.driverName }
            : undefined
        }
        isOpenSlidingPane={previewDriverImagesSlideOpen}
        closeSlidingPanePreviewDriverImages={() => {
          openPreviewDriverImagesSlide(false);
        }}
      />
    </>
  );
}
