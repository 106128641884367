import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/route';

export const getRouteAssignmentsListService = async (
  request: any,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getAssignments`, request);
};

export const getAssignedUnitsListService = async (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/getAssignedUnits`, request);
};

export const saveAssignmentService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.unitName}`,
    object: 'routes.assignmentOfUnit',
    action: request.routeAssignmentId ? 'Edit' : 'Create',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/saveAssignment`, request);
};

export const deleteAssignmentService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.unitName}`,
    object: 'routes.assignmentOfUnit',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/deleteAssignment`, request);
};

export type RouteAssignmentsRequest = {
  routeAssignments: string | null;
  completionType: number | null;
  alreadyLeftTheOrigin: boolean | null;
  automaticMonitoringWhenArrivingAtOrigin?: boolean | null;
  assignmentDate?: string | null;
  useUnitPrivateCode?: boolean;
  useRoutePrivateCode?: boolean;
  useCustomerPrivateCode?: boolean;
};

export const importRouteAssignmentsService = async (
  request: RouteAssignmentsRequest,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importAssignments`, request);
};
