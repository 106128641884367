import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import CheckBoxInput from 'views/formComponents/CheckBoxInput';
import DateInput from 'views/formComponents/DateInput';
import DropdownInput from 'views/formComponents/DropdownInput';
import Membership from 'views/formComponents/Membership';
import NumericalInput from 'views/formComponents/NumericalInput';
import SwitchInput from 'views/formComponents/SwitchInput';
import TextInput from 'views/formComponents/TextInput';
import TimeInput from 'views/formComponents/TimeInput';
import * as scheduleActions from 'views/screens/Schedules/actions';
import * as scheduleSelectors from 'views/screens/Schedules/reducers';
import * as utility from '../../../utility/Utils';

const getScheduleTypes = (intl) =>
  [...Array(2).keys()].map((id) => ({
    id: id,
    value: id,
    label: intl.formatMessage({ id: `schedules.scheduleType${id}` })
  }));

const getFrequency = (intl) =>
  [4, 8, 16].map((id, i) => ({
    id: id,
    value: id,
    label: intl.formatMessage({ id: `schedules.frequency${i}` })
  }));

const getRelativeMonth = (intl) =>
  [1, 2, 4, 8, 16].map((id, i) => ({
    id: id,
    value: id,
    label: intl.formatMessage({ id: `schedules.relativeMonth${i}` })
  }));

const getWeekDaysList = (intl) =>
  [...Array(7).keys()].map((id) => ({
    id: id,
    value: id,
    label: intl.formatMessage({ id: `schedules.day${id}` })
  }));

const getWeeklyFrequency = (values) => {
  var result = 0;
  if (values.sunday) result += 1;
  if (values.monday) result += 2;
  if (values.tuesday) result += 4;
  if (values.wednesday) result += 8;
  if (values.thursday) result += 16;
  if (values.friday) result += 32;
  if (values.saturday) result += 64;
  return result;
};

const TriggerSlide = (props) => {
  const { isOpenTriggerPane, closeSchedulePane, initialValues } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const { control, handleSubmit, watch, reset, setValue } = useForm();
  const isNew = useSelector(scheduleSelectors.getSelectedSchedule) ? false : true;

  const formValues = watch();
  const selectedScheduleType = formValues?.scheduleType;
  const selectedFrequency = formValues?.frequencyType;
  const isMonthlyRelative = formValues?.isMonthlyRelative;
  const monday = formValues?.monday;
  const tuesday = formValues?.tuesday;
  const wednesday = formValues?.wednesday;
  const thursday = formValues?.thursday;
  const friday = formValues?.friday;
  const saturday = formValues?.saturday;
  const sunday = formValues?.sunday;

  const saveTrigger = (values) => {
    //Horarios diarios
    //Una única vez: scheduleType === 0
    //Recurrente y diario: scheduleType === 1 && frequencyType === 4
    //Recurrente y semanal: scheduleType === 1 && frequencyType === 8
    //Recurrente y mensual: scheduleType === 1 && frequencyType === 16
    let valuesToDispatch = {
      //Recurrente y diario
      ...values,
      oneTimeTime:
        values.scheduleType !== 0
          ? typeof values.oneTimeTime !== 'string'
            ? values.oneTimeTime
            : `2000-01-01T${values.oneTimeTime.substr(0, 2)}:${values.oneTimeTime.substr(
                3,
                5
              )}:00.000-0600`
          : undefined,
      recurrenceRange:
        values.scheduleType !== 0
          ? [
              values.recurrenceRange[0].replace('T', ' '),
              values.recurrenceRange[1].replace('T', ' ')
            ]
          : undefined
    };

    valuesToDispatch = {
      //Recurrente y diario
      ...valuesToDispatch,
      operatorId: values.membership?.operatorId,
      organizationId: values.membership?.organizationId,
      divisionId: values.membership?.divisionId,
      subdivisionId: values.membership?.subdivisionId,
      scheduleId: isNew ? uuidv4() : initialValues.scheduleId,
      startDate:
        values.scheduleType !== 0
          ? values.recurrenceRange[0].substr(0, 10)
          : `${new Date(values.oneTimeDate).getFullYear()}-${
              new Date(values.oneTimeDate).getMonth() + 1
            }-${new Date(values.oneTimeDate).getDate()}`,
      endDate:
        values.scheduleType === 0
          ? values.startDate
          : values.scheduleType === 1 && values.noEndDate
          ? '2050-01-01'
          : values?.recurrenceRange[1],
      startTime:
        values.scheduleType !== 0
          ? isNew
            ? values.oneTimeTime.getMinutes() < 10
              ? `${values.oneTimeTime.getHours()}:0${values.oneTimeTime.getMinutes()}`
              : `${values.oneTimeTime.getHours()}:${values.oneTimeTime.getMinutes()}`
            : new Date(valuesToDispatch.oneTimeTime).getMinutes() < 10
            ? `${new Date(valuesToDispatch.oneTimeTime).getHours()}:0${new Date(
                valuesToDispatch.oneTimeTime
              ).getMinutes()}`
            : `${new Date(valuesToDispatch.oneTimeTime).getHours()}:${new Date(
                valuesToDispatch.oneTimeTime
              ).getMinutes()}`
          : new Date(values.oneTimeDate).getMinutes() < 10
          ? `${new Date(values.oneTimeDate).getHours()}:0${new Date(
              values.oneTimeDate
            ).getMinutes()}`
          : `${new Date(values.oneTimeDate).getHours()}:${new Date(
              values.oneTimeDate
            ).getMinutes()}`,
      endTime: '23:59:59.000',
      frequencyRecurrenceFactor:
        values.scheduleType === 1
          ? values.frequencyType === 4
            ? values.frequencyDailyInterval ?? 1
            : values.frequencyType === 8
            ? values.frequencyWeeklyInterval ?? 1
            : values.frequencyType === 16
            ? values.frequencyMonthlyInterval ?? 1
            : 0
          : 0,
      frequencyType: values?.isMonthlyRelative === true ? 32 : values?.frequencyType ?? 1,
      frequencyInterval:
        values.scheduleType === 0
          ? 0
          : values.frequencyType === 4
          ? values.frequencyDailyInterval ?? 1
          : values.frequencyType === 8
          ? getWeeklyFrequency(values)
          : values.frequencyType === 16
          ? !values.isMonthlyRelative
            ? values.monthlyDay
            : values.weekDaysList + 1
          : 1,
      frequencyDayType: values.scheduleType === 0 ? 2 : values.frequencyDayType,
      frequencyRelativeInterval:
        values.scheduleType === 1 &&
        values.frequencyType === 16 &&
        values.isMonthlyRelative === true
          ? values.relativeMonth
          : 0
    };
    valuesToDispatch = {
      ...valuesToDispatch,
      endDate:
        valuesToDispatch.scheduleType === 0
          ? moment(valuesToDispatch.endDate).add(1, 'day').format('YYYY-MM-DD')
          : valuesToDispatch.scheduleType === 1 && values.noEndDate
          ? '2050-01-01'
          : valuesToDispatch?.recurrenceRange[1]
    };
    if (isNew) {
      dispatch(scheduleActions.startAddingSchedule(valuesToDispatch));
    } else {
      dispatch(scheduleActions.startEditingSchedule(valuesToDispatch));
    }
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName="custom-overlay-sliding-pane"
      isOpen={isOpenTriggerPane}
      title={intl?.formatMessage({
        id: isNew ? 'schedules.addTrigger' : 'schedules.editTrigger'
      })}
      width={'50rem'}
      onRequestClose={() => {
        reset();
        closeSchedulePane();
      }}
    >
      <Row>
        <Col sm="12">
          <Controller
            control={control}
            name="scheduleName"
            shouldUnregister
            defaultValue={initialValues?.scheduleName}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldstate={fieldState}
                id={'scheduleNameInput'}
                required={true}
                label={<FormattedMessage id="schedules.name" />}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            name="schedulePrivateCode"
            shouldUnregister
            defaultValue={initialValues?.schedulePrivateCode}
            rules={{ required: false }}
            render={({ field, fieldState }) => (
              <TextInput
                field={field}
                fieldstate={fieldState}
                id={'schedulePrivateCodeInput'}
                required={false}
                label={<FormattedMessage id="schedules.privateCode" />}
              />
            )}
          />
        </Col>
        <Membership
          name="membership"
          shouldUnregister
          control={control}
          defaultValue={initialValues?.membership}
          options={{
            useOperator: false,
            useUserOrganization: true,
            useUserDivision: true,
            useUserSubdivision: true,
            setUserOrganization: true,
            setUserDivision: true,
            setUserSubdivision: true,
            showAllDivision: true,
            showAllSubdivision: true,
            colSize: 12
          }}
          isInModal={true}
        />
        {
          //Una vez y recurrente
        }
        <Col sm="12">
          <Controller
            control={control}
            name="scheduleType"
            shouldUnregister
            defaultValue={initialValues?.scheduleType}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <DropdownInput
                field={field}
                fieldstate={fieldState}
                id={'scheduleTypeInput'}
                label={<FormattedMessage id="schedules.scheduleTypeId" />}
                type={'select'}
                options={getScheduleTypes(intl)}
                isInModal={true}
                additionalonchangefunction={(values) => {
                  if (values === 0) setValue('frequencyType', 1);
                  if (values === 1) setValue('frequencyType', 4);
                }}
                required={true}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        {selectedScheduleType === 0 && (
          <Col sm="12">
            <Controller
              control={control}
              name="oneTimeDate"
              shouldUnregister
              defaultValue={initialValues?.oneTimeDate}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <DateInput
                  field={field}
                  fieldstate={fieldState}
                  label={<FormattedMessage id="schedules.oneTimeDate" />}
                  required={true}
                  enableTime={true}
                  onChange={(values) => {
                    setValue('startDate', DateTime.now(values).toISODate());
                    setValue('startTime', DateTime.now(values).toISOTime());
                  }}
                  //initialDate={DateTime.now().toISODate()}
                />
              )}
            />
          </Col>
        )}
        {selectedScheduleType === 1 && (
          <>
            <Col sm="6">
              <Controller
                control={control}
                name="frequencyType"
                shouldUnregister
                defaultValue={initialValues?.frequencyType}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DropdownInput
                    field={field}
                    fieldstate={fieldState}
                    id={'frequencyInput'}
                    label={<FormattedMessage id="schedules.frequency" />}
                    options={getFrequency(intl)}
                    isInModal={true}
                    required={true}
                  />
                )}
              />
            </Col>
            {selectedFrequency === 4 && (
              <Col sm="6">
                <Controller
                  control={control}
                  name="frequencyDailyInterval"
                  shouldUnregister
                  defaultValue={initialValues?.frequencyDailyInterval}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <NumericalInput
                      field={field}
                      fieldstate={fieldState}
                      id={'frequencyDailyIntervalInput'}
                      label={<FormattedMessage id="schedules.frequencyDailyInterval" />}
                      required={true}
                      min={1}
                      max={31}
                    />
                  )}
                />
              </Col>
            )}
            {selectedFrequency === 8 && (
              <Col sm="6">
                <Controller
                  control={control}
                  name="frequencyWeeklyInterval"
                  shouldUnregister
                  defaultValue={initialValues?.frequencyWeeklyInterval}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <NumericalInput
                      field={field}
                      fieldstate={fieldState}
                      id={'frequencyWeeklyIntervalInput'}
                      label={<FormattedMessage id="schedules.frequencyWeeklyInterval" />}
                      required={true}
                      min={1}
                      max={4}
                    />
                  )}
                />
              </Col>
            )}
            {selectedFrequency === 16 && (
              <>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="isMonthlyRelative"
                    shouldUnregister
                    defaultValue={initialValues?.isMonthlyRelative}
                    render={({ field }) => (
                      <SwitchInput
                        field={field}
                        label={<FormattedMessage id="schedules.isMonthlyRelative" />}
                        // defaultChecked={initialValues.isMonthlyRelative}
                      />
                    )}
                  />
                </Col>
              </>
            )}
            <Col sm="12">
              <Controller
                control={control}
                name="oneTimeTime"
                shouldUnregister
                defaultValue={initialValues?.oneTimeTime}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <TimeInput
                    field={field}
                    fieldstate={fieldState}
                    label={<FormattedMessage id="schedules.oneTimeTime" />}
                    required={true}
                    defaultHour={utility.getHourFromStringTime(initialValues?.oneTimeTime)}
                    defaultMinute={utility.getMinutesFromStringTime(initialValues?.oneTimeTime)}
                  />
                )}
              />
            </Col>
            {selectedFrequency === 8 && (
              <>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="monday"
                    shouldUnregister
                    defaultValue={initialValues?.monday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.monday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="tuesday"
                    shouldUnregister
                    defaultValue={initialValues?.tuesday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.tuesday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="wednesday"
                    shouldUnregister
                    defaultValue={initialValues?.wednesday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.wednesday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="thursday"
                    shouldUnregister
                    defaultValue={initialValues?.thursday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.thursday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="friday"
                    shouldUnregister
                    defaultValue={initialValues?.friday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.friday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="saturday"
                    shouldUnregister
                    defaultValue={initialValues?.saturday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.saturday" />}
                      />
                    )}
                  />
                </Col>
                <Col sm="3">
                  <Controller
                    control={control}
                    name="sunday"
                    shouldUnregister
                    defaultValue={initialValues?.sunday}
                    render={({ field }) => (
                      <CheckBoxInput
                        field={field}
                        label={<FormattedMessage id="schedules.sunday" />}
                      />
                    )}
                  />
                </Col>
                {!(monday || tuesday || wednesday || thursday || friday || saturday || sunday) && (
                  <>
                    <div className="invalid-feedback" style={{ display: 'block' }}>
                      {intl?.formatMessage({ id: 'schedules.weekSelectedMessageError' })}
                    </div>
                    <br />
                  </>
                )}
              </>
            )}
            {!isMonthlyRelative && selectedFrequency === 16 && (
              <Col sm="6">
                <Controller
                  control={control}
                  name="monthlyDay"
                  shouldUnregister
                  defaultValue={initialValues?.monthlyDay}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <NumericalInput
                      field={field}
                      fieldstate={fieldState}
                      id={'monthlyDayInput'}
                      label={<FormattedMessage id="schedules.monthlyDay" />}
                      required={true}
                      min={1}
                      max={31}
                    />
                  )}
                />
              </Col>
            )}
            {!isMonthlyRelative && selectedFrequency === 16 && (
              <Col sm="6">
                <Controller
                  control={control}
                  name="frequencyMonthlyInterval"
                  shouldUnregister
                  defaultValue={initialValues?.frequencyMonthlyInterval}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <NumericalInput
                      field={field}
                      fieldstate={fieldState}
                      id={'frequencyMonthlyIntervalInput'}
                      label={<FormattedMessage id="schedules.frequencyMonthlyInterval" />}
                      required={true}
                      min={1}
                      max={12}
                    />
                  )}
                />
              </Col>
            )}
            {isMonthlyRelative && selectedFrequency === 16 && (
              <Col sm="4">
                <Controller
                  control={control}
                  name="relativeMonth"
                  shouldUnregister
                  defaultValue={initialValues?.relativeMonth}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <>
                      <DropdownInput
                        field={field}
                        fieldstate={fieldState}
                        id={'relativeMonthhInput'}
                        label={<FormattedMessage id="schedules.itOcurrs" />}
                        options={getRelativeMonth(intl)}
                        isInModal={true}
                        required={true}
                      />
                    </>
                  )}
                />
              </Col>
            )}
            {isMonthlyRelative && selectedFrequency === 16 && (
              <Col sm="4">
                <Controller
                  control={control}
                  name="weekDaysList"
                  shouldUnregister
                  defaultValue={initialValues?.weekDaysList}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <DropdownInput
                      field={field}
                      fieldstate={fieldState}
                      id={'weekDaysListInput'}
                      label={<FormattedMessage id="schedules.day" />}
                      options={getWeekDaysList(intl)}
                      isInModal={true}
                      required={true}
                    />
                  )}
                />
              </Col>
            )}
            {isMonthlyRelative && selectedFrequency === 16 && (
              <Col sm="4">
                <Controller
                  control={control}
                  name="frequencyMonthlyInterval"
                  shouldUnregister
                  defaultValue={initialValues?.frequencyMonthlyInterval}
                  rules={{ required: true }}
                  render={({ field, fieldState }) => (
                    <NumericalInput
                      field={field}
                      fieldstate={fieldState}
                      id={'frequencyMonthlyIntervalInput'}
                      label={<FormattedMessage id="schedules.frequencyMonthlyInterval" />}
                      required={true}
                      min={1}
                      max={12}
                    />
                  )}
                />
              </Col>
            )}
            <Col sm="12">
              <Controller
                control={control}
                name="recurrenceRange"
                shouldUnregister
                defaultValue={initialValues?.recurrenceRange}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <DateInput
                    field={field}
                    fieldstate={fieldState}
                    showTime={false}
                    label={<FormattedMessage id="schedules.recurrenceRange" />}
                    isRange={true}
                    required={true}
                  />
                )}
              />
            </Col>
            <Col sm="12">
              <Controller
                control={control}
                name="noEndDate"
                shouldUnregister
                defaultValue={initialValues?.noEndDate}
                render={({ field }) => (
                  <CheckBoxInput
                    field={field}
                    label={<FormattedMessage id="schedules.noEndDate" />}
                  />
                )}
              />
            </Col>
          </>
        )}
      </Row>
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <Row className={'footer-slide-pane'}>
        <Col sm="12">
          <Button.Ripple
            className="mr-1 mt-50 mb-50"
            color="primary"
            /*type="submit"*/
            onClick={handleSubmit((values) => {
              if (
                values.scheduleType === 1 &&
                values.frequencyType === 8 &&
                !(
                  values.monday ||
                  values.tuesday ||
                  values.wednesday ||
                  values.thursday ||
                  values.friday ||
                  values.saturday ||
                  values.sunday
                )
              )
                return;
              saveTrigger(values);
              reset();
              closeSchedulePane();
            })}
          >
            <FormattedMessage id="common.save" />
          </Button.Ripple>
          <Button.Ripple
            className="mt-50 mb-50"
            color="light"
            onClick={() => {
              reset();
              closeSchedulePane();
            }}
          >
            <FormattedMessage id="common.cancel" />
          </Button.Ripple>
        </Col>
      </Row>
    </SlidingPane>
  );
};

export default connect(
  (state) => {
    const selectedSchedule = scheduleSelectors.getSelectedSchedule(state);
    return {
      initialValues: selectedSchedule //Valores existentes
        ? {
            scheduleId: selectedSchedule.scheduleId,
            scheduleName: selectedSchedule.scheduleName,
            schedulePrivateCode: selectedSchedule.privateCode,
            scheduleType: selectedSchedule.frequencyType === 1 ? 0 : 1,
            frequencyType:
              selectedSchedule.frequencyType === 32 ? 16 : selectedSchedule.frequencyType,
            membership: {
              organizationId: selectedSchedule.organizationId,
              divisionId: selectedSchedule.divisionId,
              subdivisionId: selectedSchedule.subdivisionId
            },
            oneTimeDate:
              DateTime.fromISO(selectedSchedule.startDate)
                .plus({
                  hour: selectedSchedule.startTime.hours,
                  minute: selectedSchedule.startTime.minutes
                })
                .toString()
                .slice(0, -6) + '-0600',
            oneTimeTime: DateTime.local(
              2000,
              1,
              2,
              selectedSchedule.startTime.hours,
              selectedSchedule.startTime.minutes
            ).toFormat('HH-mm'),
            recurrenceRange: [selectedSchedule.startDate, selectedSchedule.endDate],
            sunday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 0)
                : false,
            monday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 1)
                : false,
            tuesday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 2)
                : false,
            wednesday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 3)
                : false,
            thursday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 4)
                : false,
            friday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 5)
                : false,
            saturday:
              selectedSchedule.frequencyType === 8
                ? utility.isBitSet(selectedSchedule.frequencyInterval, 6)
                : false,
            monthlyDay: selectedSchedule.frequencyInterval,
            frequencyDailyInterval:
              selectedSchedule.frequencyType === 4 ? selectedSchedule.frequencyInterval : 1,
            frequencyWeeklyInterval:
              selectedSchedule.frequencyType === 8 ? selectedSchedule.frequencyRecurrenceFactor : 1,
            frequencyMonthlyInterval: [16, 32].includes(selectedSchedule.frequencyType)
              ? selectedSchedule.frequencyRecurrenceFactor
              : 1,
            isMonthlyRelative: selectedSchedule.frequencyType === 32,
            relativeMonth: selectedSchedule.frequencyRelativeInterval,
            weekDaysList: selectedSchedule.frequencyInterval - 1,
            noEndDate: selectedSchedule.endDate
              ? selectedSchedule.endDate.substr(0, 10) !== '2050-01-01'
                ? false
                : true
              : true
          }
        : //Valores iniciales
          {
            frequencyType: 0,
            frequencyInterval: 1,
            frequencyDayType: 1,
            relativeMonth: 1,
            weekDaysList: 0,
            frequencyDailyInterval: 1,
            frequencyWeeklyInterval: 1,
            frequencyMonthlyInterval: 1,
            monthlyDay: 1
          }
    };
  },
  undefined,
  (stateProps, dispatchProps, ownProps: any) => ({
    ...stateProps,
    ...ownProps
  })
)(TriggerSlide);
