const CatalogIconsOptions = [
  {
    optionId: 1,
    label: 'vehicleMaintenance.icon1',
    iconName: 'Accesories'
  },
  {
    optionId: 2,
    label: 'vehicleMaintenance.icon2',
    iconName: 'Alignment'
  },
  {
    optionId: 3,
    label: 'vehicleMaintenance.icon3',
    iconName: 'Tools'
  },
  {
    optionId: 4,
    label: 'vehicleMaintenance.icon4',
    iconName: 'Lubricant'
  },
  {
    optionId: 5,
    label: 'vehicleMaintenance.icon5',
    iconName: 'SpareParts'
  },
  {
    optionId: 6,
    label: 'vehicleMaintenance.icon6',
    iconName: 'Wheel'
  },
  {
    optionId: 7,
    label: 'vehicleMaintenance.icon7',
    iconName: 'Electromechanical'
  },
  {
    optionId: 8,
    label: 'vehicleMaintenance.icon8',
    iconName: 'Brakes'
  },
  {
    optionId: 9,
    label: 'vehicleMaintenance.icon9',
    iconName: 'Paint'
  },
  {
    optionId: 10,
    label: 'vehicleMaintenance.icon10',
    iconName: 'Fuel'
  },
  {
    optionId: 11,
    label: 'vehicleMaintenance.icon11',
    iconName: 'Scanner'
  },
  {
    optionId: 12,
    label: 'vehicleMaintenance.icon12',
    iconName: 'CarWash'
  },
  {
    optionId: 13,
    label: 'vehicleMaintenance.icon13',
    iconName: 'Tow'
  }
];

export default CatalogIconsOptions;
