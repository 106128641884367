export const WORKMANSHIPS_FETCH_STARTED = 'WORKMANSHIPS_FETCH_STARTED';
export const WORKMANSHIPS_FETCH_COMPLETED = 'WORKMANSHIPS_FETCH_COMPLETED';
export const WORKMANSHIPS_FETCH_FAILED = 'WORKMANSHIPS_FETCH_FAILED';

export const WORKMANSHIPS_PARTIAL_FETCH_STARTED = 'WORKMANSHIPS_PARTIAL_FETCH_STARTED';
export const WORKMANSHIPS_PARTIAL_FETCH_COMPLETED = 'WORKMANSHIPS_PARTIAL_FETCH_COMPLETED';
export const WORKMANSHIPS_PARTIAL_FETCH_FAILED = 'WORKMANSHIPS_PARTIAL_FETCH_FAILED';

export const WORKMANSHIP_ADD_STARTED = 'WORKMANSHIP_ADD_STARTED';
export const WORKMANSHIP_ADD_COMPLETED = 'WORKMANSHIP_ADD_COMPLETED';
export const WORKMANSHIP_ADD_FAILED = 'WORKMANSHIP_ADD_FAILED';

export const WORKMANSHIP_EDIT_STARTED = 'WORKMANSHIP_EDIT_STARTED';
export const WORKMANSHIP_EDIT_COMPLETED = 'WORKMANSHIP_EDIT_COMPLETED';
export const WORKMANSHIP_EDIT_FAILED = 'WORKMANSHIP_EDIT_FAILED';

export const WORKMANSHIP_REMOVE_STARTED = 'WORKMANSHIP_REMOVE_STARTED';
export const WORKMANSHIP_REMOVE_COMPLETED = 'WORKMANSHIP_REMOVE_COMPLETED';
export const WORKMANSHIP_REMOVE_FAILED = 'WORKMANSHIP_REMOVE_FAILED';

export interface Workmanship {
  isSelected: boolean;
  workmanshipId: number;
  description: string;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: null | string;
  subdivisionId: number | null;
  subdivisionName: null | string;
  isConfirmed: boolean;
  tempId?: string;
}
