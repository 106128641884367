export const WORKPLAN_ASSIGNMENT_FETCH_STARTED = 'WORKPLAN_ASSIGNMENT_FETCH_STARTED';
export const WORKPLAN_ASSIGNMENT_FETCH_COMPLETED = 'WORKPLAN_ASSIGNMENT_FETCH_COMPLETED';
export const WORKPLAN_ASSIGNMENT_FETCH_FAILED = 'WORKPLAN_ASSIGNMENT_FETCH_FAILED';

export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_FETCH_COMPLETED';

export const WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_FETCH_COMPLETED';

export const WORKPLAN_ASSIGNMENT_REMOVE_STARTED = 'WORKPLAN_ASSIGNMENT_REMOVE_STARTED';
export const WORKPLAN_ASSIGNMENT_REMOVE_COMPLETED = 'WORKPLAN_ASSIGNMENT_REMOVE_COMPLETED';
export const WORKPLAN_ASSIGNMENT_REMOVE_FAILED = 'WORKPLAN_ASSIGNMENT_REMOVE_FAILED';

export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_STARTED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_STARTED';
export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_COMPLETED';
export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_FAILED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_REMOVE_FAILED';

export const WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_STARTED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_STARTED';
export const WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_COMPLETED';
export const WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_FAILED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_REMOVE_FAILED';

export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_STARTED';
export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_COMPLETED';
export const WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_FAILED =
  'WORKPLAN_ASSIGNMENT_BY_SCHEDULE_EDIT_FAILED';

export const WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_STARTED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_STARTED';
export const WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_COMPLETED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_COMPLETED';
export const WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_FAILED =
  'WORKPLAN_ASSIGNMENT_BY_DATETIME_EDIT_FAILED';
