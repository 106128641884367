import { combineReducers } from 'redux';

import union from 'lodash/union';

import * as types from '../../../types/static-catalogs';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.SENSOR_MODELS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((sensorModelId) => {
        newState[sensorModelId] = {
          isSelected: false,
          ...state[sensorModelId],
          ...entities[sensorModelId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.SENSOR_MODELS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_MODELS_FETCH_STARTED: {
      return true;
    }
    case types.SENSOR_MODELS_FETCH_COMPLETED:
    case types.SENSOR_MODELS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.SENSOR_MODELS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.SENSOR_MODELS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.SENSOR_MODELS_FETCH_STARTED:
    case types.SENSOR_MODELS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const sensorModels = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList
});

export default sensorModels;

//Own state
export const getOwnState = (state) => state.staticCatalogs.sensorModels;

//Information
export const getSensorModel = (state, sensorModelId) => getOwnState(state).byId[sensorModelId];
export const getSensorModelsList = (state) =>
  getOwnState(state).order.map((id) => getSensorModel(state, id));
export const getSensorModelDropdownOption = (state, sensorModelId) => {
  let tz = getSensorModel(state, sensorModelId);
  return tz != undefined
    ? {
        ...tz,
        id: tz.modelId,
        value: tz.modelId,
        label: tz.modelName
      }
    : null;
};
export const getSensorModelsBySensorBrand = (state, brandId) =>
  getSensorModelsList(state).filter((sensorModel) => sensorModel.brandId === brandId);
//Status of sagas
export const isFetchingListSensorModels = (state) => getOwnState(state).isFetchingList;
export const haveSensormodelsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
//Errors
export const getFetchingListSensorModelsErrors = (state) => getOwnState(state).errorFetchingList;
