import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../../types/static-catalogs/brands';
import models from '../../models';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.BRANDS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((brandId) => {
        newState[brandId] = {
          isSelected: false,
          ...entities[brandId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.BRANDS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.BRANDS_FETCH_STARTED: {
      return true;
    }
    case types.BRANDS_FETCH_COMPLETED:
    case types.BRANDS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.BRANDS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.BRANDS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.BRANDS_FETCH_STARTED:
    case types.BRANDS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const brands = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList,
  models
});

export default brands;

//Own state
export const getOwnState = (state) => state.staticCatalogs.brands;

//Information
export const getBrand = (state, brandId) => getOwnState(state).byId[brandId];
export const getBrandsList = (state) => getOwnState(state).order.map((id) => getBrand(state, id));

//Status of sagas
export const isFetchingListBrands = (state) => getOwnState(state).isFetchingList;
export const haveBrandsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;

//Errors
export const getFetchingListBrandsErrors = (state) => getOwnState(state).errorFetchingList;
