import { combineReducers } from 'redux';

import { orderBy, union } from 'lodash';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansReport';
import * as geofenceSelectors from '../../../Geofences/reducers';
import * as unitSelectors from '../../../Units/reducers';
import permissions from '../../permissions';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((visitId) => {
        newState[visitId] = {
          isSelected: false,
          ...state[visitId],
          ...entities[visitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const indicators = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { indicators } = action.payload;
      const newState = { ...indicators };
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const unitsById = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { unitsEntities, unitsOrder } = action.payload;
      const newState = { ...state };
      unitsOrder.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          ...state[unitId],
          ...unitsEntities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const unitsOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { unitsOrder } = action.payload;
      return union(unitsOrder);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const locationsById = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { locationsEntities, locationsOrder } = action.payload;
      const newState = { ...state };
      locationsOrder.forEach((locationId) => {
        newState[locationId] = {
          isSelected: false,
          ...state[locationId],
          ...locationsEntities[locationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const locationsOrder = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      const { locationsOrder } = action.payload;
      return union(locationsOrder);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingReport = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLANS_REPORT_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLANS_REPORT_FETCH_COMPLETED:
    case types.WORKPLANS_REPORT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingReport = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLANS_REPORT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLANS_REPORT_FETCH_STARTED:
    case types.WORKPLANS_REPORT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const workplansReport = combineReducers({
  byId,
  order,
  indicators,
  unitsById,
  unitsOrder,
  locationsById,
  locationsOrder,
  isFetchingReport,
  errorFetchingReport
});

export default workplansReport;

//Own state
export const getOwnState = (state) => state.workplans.workplansReport;

//Information
export const getWorkplansExecution = (state, visitId) => {
  const intl = appIntl();
  const workplanExecution = getOwnState(state).byId[visitId];
  if (workplanExecution) {
    const originGeofence = workplanExecution.originGeofenceId
      ? geofenceSelectors.getGeofence(state, workplanExecution.originGeofenceId)
      : undefined;
    const workGeofence = workplanExecution.workGeofenceId
      ? geofenceSelectors.getGeofence(state, workplanExecution.workGeofenceId)
      : undefined;
    const destinationGeofence = workplanExecution.destinationGeofenceId
      ? geofenceSelectors.getGeofence(state, workplanExecution.destinationGeofenceId)
      : undefined;
    var visitStatusLabel = '';
    switch (workplanExecution.visitStatus) {
      case 'I':
        visitStatusLabel = intl.formatMessage({
          id: 'workplans.incomplete'
        });
        break;
      case 'T':
        visitStatusLabel = intl.formatMessage({
          id: 'workplans.complete'
        });
        break;
      case 'A':
        visitStatusLabel = intl.formatMessage({
          id: 'workplans.active'
        });
        break;
      default:
        visitStatusLabel = '';
        break;
    }
    return {
      ...workplanExecution,
      progress: (workplanExecution.visited / (workplanExecution.assigned || 1)) * 100,
      progressLabel:
        Math.round((workplanExecution.visited / (workplanExecution.assigned || 1)) * 100) + '%',
      sequenceCompliance:
        parseInt(workplanExecution.sequenceCountValid || 0) +
        ' | ' +
        parseInt(workplanExecution.sequenceCountInvalid || 0) +
        ' | ' +
        parseInt(workplanExecution.sequenceCountPending || 0),
      visitStatusLabel,
      originGeofenceName: originGeofence ? originGeofence.geofenceName : '',
      workGeofenceName: workGeofence
        ? workGeofence.geofenceName
        : workplanExecution?.workGeofenceArea
        ? intl.formatMessage({ id: 'workplans.autogeneratedGeofence' })
        : '',
      destinationGeofenceName: destinationGeofence ? destinationGeofence.geofenceName : ''
    };
  } else return undefined;
};
export const getWorkplansExecutionsList = (state) =>
  getOwnState(state).order.map((id) => getWorkplansExecution(state, id));
export const getWorkplansUnit = (state, visitId) => {
  const unit = getOwnState(state).unitsById[visitId];
  return {
    ...unit,
    progress: (unit.totalComplete / (unit.totalComplete + unit.totalIncomplete || 1)) * 100,
    progressLabel:
      Math.round((unit.totalComplete / (unit.totalComplete + unit.totalIncomplete || 1)) * 100) +
      '%'
  };
};
export const getWorkplansUnitsList = (state) =>
  orderBy(
    getOwnState(state).unitsOrder.map((id) => getWorkplansUnit(state, id)),
    'total',
    'desc'
  );
export const getWorkplansLocation = (state, visitId) => getOwnState(state).locationsById[visitId];
export const getWorkplansLocationsList = (state) =>
  orderBy(
    getOwnState(state).locationsOrder.map((id) => getWorkplansLocation(state, id)),
    'total',
    'desc'
  );
export const getIndicators = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  const total =
    indicators.totalComplete + indicators.totalIncomplete > 0
      ? indicators.totalComplete + indicators.totalIncomplete
      : 1;
  const finalIndicators = {
    ...indicators,
    executionPercent: (indicators.totalComplete / total) * 100
  };
  return [
    {
      title: intl.formatMessage({
        id: 'workplans.assignedWorkplans'
      }),
      permissions: [permissions.viewPlansIndicator],
      bigIndicator: finalIndicators.totalAssignedPlans || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.units'
            }) +
            ': ' +
            parseInt(finalIndicators.totalUnits || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.locations'
      }),
      permissions: [permissions.viewLocationsIndicator],
      bigIndicator: finalIndicators.totalLocations || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.notVisited'
            }) +
            ': ' +
            parseInt(finalIndicators.totalIncomplete || 0)
        },
        {
          popoverTitle: intl.formatMessage({
            id: 'workplans.sequenceCompliance'
          }),
          popoverBody: intl.formatMessage({
            id: 'workplans.complianceYesNotPend'
          }),
          info:
            intl.formatMessage({
              id: 'workplans.sequence'
            }) +
            ': ' +
            parseInt(finalIndicators.sequenceCountValid || 0) +
            ' | ' +
            parseInt(finalIndicators.sequenceCountInvalid || 0) +
            ' | ' +
            parseInt(finalIndicators.sequenceCountPending || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.withoutSequence'
            }) +
              ': ' +
              (finalIndicators.totalLocations -
                (finalIndicators.sequenceCountValid +
                  finalIndicators.sequenceCountInvalid +
                  finalIndicators.sequenceCountPending)) || 0
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.confirmed'
            }) +
              ': ' +
              finalIndicators.totalConfirmed || 0
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.visits'
      }),
      permissions: [permissions.viewVisitsIndicator],
      bigIndicator: finalIndicators.totalVisited || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'workplans.onTime'
            }) +
            ': ' +
            parseInt(finalIndicators.totalNormal || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.early'
            }) +
            ': ' +
            parseInt(finalIndicators.totalEarly || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.late'
            }) +
            ': ' +
            parseInt(finalIndicators.totalLate || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'workplans.repeated'
            }) +
            ': ' +
            parseInt(finalIndicators.totalRepeated || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'workplans.percentComplete'
      }),
      permissions: [permissions.viewExecutionIndicator],
      bigIndicator: Math.round(finalIndicators.executionPercent || 0) + '%'
    }
  ];
};
export const getEffectivenessStatus = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  var labels: any[] = [];
  var series: any[] = [];
  if (indicators.totalComplete > 0 || indicators.totalIncomplete > 0 || indicators.totalConfirmed) {
    labels = [
      intl.formatMessage({
        id: 'workplans.visited'
      }),
      intl.formatMessage({
        id: 'workplans.notVisited'
      }),
      intl.formatMessage({
        id: 'workplans.confirmed'
      })
    ];
    series = [
      indicators.totalComplete ?? 0,
      indicators.totalIncomplete ?? 0,
      indicators.totalConfirmed ?? 0
    ];
  }
  return {
    title: intl.formatMessage({
      id: 'workplans.effectiveness'
    }),
    labels: labels,
    series: series
  };
};
export const getArrivalStatus = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  var labels: any[] = [];
  var series: any[] = [];
  if (indicators.totalNormal > 0 || indicators.totalEarly > 0 || indicators.totalLate > 0) {
    labels = [
      intl.formatMessage({
        id: 'workplans.onTime'
      }),
      intl.formatMessage({
        id: 'workplans.early'
      }),
      intl.formatMessage({
        id: 'workplans.late'
      })
    ];
    series = [indicators.totalNormal, indicators.totalEarly, indicators.totalLate];
  }
  return {
    title: intl.formatMessage({
      id: 'workplans.performanceOnArrival'
    }),
    permissions: [permissions.viewArrivalEffectivnessGraph],
    labels: labels,
    series: series
  };
};
export const getDurationStatus = (state) => {
  const intl = appIntl();
  const indicators = getOwnState(state).indicators;
  var labels: any[] = [];
  var series: any[] = [];
  if (
    indicators.totalNormalDuration > 0 ||
    indicators.totalShortDuration > 0 ||
    indicators.totalLongDuration > 0
  ) {
    labels = [
      intl.formatMessage({
        id: 'workplans.normal'
      }),
      intl.formatMessage({
        id: 'workplans.toShortPermanence'
      }),
      intl.formatMessage({
        id: 'workplans.toLongPermanence'
      })
    ];
    series = [
      indicators.totalNormalDuration,
      indicators.totalShortDuration,
      indicators.totalLongDuration
    ];
  }
  return {
    title: intl.formatMessage({
      id: 'workplans.performanceOnPermanence'
    }),
    permissions: [permissions.viewPermanenceEffectivenessGraph],
    labels: labels,
    series: series
  };
};
export const getSlidingPaneInformation = (state, chartId) => {
  const intl = appIntl();
  const colors = ['#2ED573', '#1E90FF', '#FFA502', '#FF4757'];
  const workplansUnits = getWorkplansUnitsList(state);
  const workplansLocations = getWorkplansLocationsList(state);
  const total = workplansUnits.reduce((total, obj) => obj.total + total, 0);
  return {
    indicators: [
      {
        title: intl.formatMessage({
          id: 'workplans.total'
        }),
        size: '4',
        bigIndicator: total || 0
      },
      {
        title: intl.formatMessage({
          id:
            chartId === 'effectivenessStatusChart'
              ? 'workplans.visited'
              : chartId === 'arrivalStatusChart'
              ? 'workplans.onTime'
              : 'workplans.normal'
        }),
        size: '4',
        bigIndicator:
          workplansUnits.reduce(
            (total, obj) =>
              (chartId === 'effectivenessStatusChart'
                ? obj.totalComplete
                : chartId === 'arrivalStatusChart'
                ? obj.totalNormal
                : obj.totalNormalDuration) + total,
            0
          ) || 0,
        details: [
          {
            info:
              Math.round(
                (parseInt(
                  workplansUnits.reduce(
                    (total, obj) =>
                      (chartId === 'effectivenessStatusChart'
                        ? obj.totalComplete
                        : chartId === 'arrivalStatusChart'
                        ? obj.totalNormal
                        : obj.totalNormalDuration) + total,
                    0
                  ) || 0
                ) /
                  (total || 1)) *
                  100
              ) + '%'
          }
        ]
      },
      chartId === 'effectivenessStatusChart'
        ? undefined
        : {
            title: intl.formatMessage({
              id:
                chartId === 'arrivalStatusChart' ? 'workplans.early' : 'workplans.toShortPermanence'
            }),
            size: '4',
            bigIndicator:
              workplansUnits.reduce(
                (total, obj) =>
                  (chartId === 'arrivalStatusChart' ? obj.totalEarly : obj.totalShortDuration) +
                  total,
                0
              ) || 0,
            details: [
              {
                info:
                  Math.round(
                    (parseInt(
                      workplansUnits.reduce(
                        (total, obj) =>
                          (chartId === 'arrivalStatusChart'
                            ? obj.totalEarly
                            : obj.totalShortDuration) + total,
                        0
                      ) || 0
                    ) /
                      (total || 1)) *
                      100
                  ) + '%'
              }
            ]
          },
      chartId === 'effectivenessStatusChart'
        ? undefined
        : {
            title: intl.formatMessage({
              id: chartId === 'arrivalStatusChart' ? 'workplans.late' : 'workplans.toLongPermanence'
            }),
            size: '4',
            bigIndicator:
              workplansUnits.reduce(
                (total, obj) =>
                  (chartId === 'arrivalStatusChart' ? obj.totalLate : obj.totalLongDuration) +
                  total,
                0
              ) || 0,
            details: [
              {
                info:
                  Math.round(
                    (parseInt(
                      workplansUnits.reduce(
                        (total, obj) =>
                          (chartId === 'arrivalStatusChart'
                            ? obj.totalLate
                            : obj.totalLongDuration) + total,
                        0
                      ) || 0
                    ) /
                      (total || 1)) *
                      100
                  ) + '%'
              }
            ]
          },
      {
        title: intl.formatMessage({
          id: 'workplans.notVisited'
        }),
        size: '4',
        bigIndicator:
          workplansUnits.reduce(
            (totalIncomplete, obj) => obj.totalIncomplete + totalIncomplete,
            0
          ) || 0,
        details: [
          {
            info:
              Math.round(
                (parseInt(
                  workplansUnits.reduce(
                    (totalIncomplete, obj) => obj.totalIncomplete + totalIncomplete,
                    0
                  ) || 0
                ) /
                  (total || 1)) *
                  100
              ) + '%'
          }
        ]
      }
    ].filter((indicator) => indicator),
    charts: [
      {
        idChart: 'slidingPaneUnitsChart',
        buttonChartText: 'workplans.units',
        chartType: 'bar',
        colors: chartId === 'effectivenessStatusChart' ? [colors[0], colors[3]] : colors,
        title: intl.formatMessage({
          id: 'workplans.graphGroupedByUnits'
        }),
        horizontal: true,
        stacked: true,
        labels: workplansUnits.map((unit) => unit.unitName),
        series: [
          {
            name: intl.formatMessage({
              id:
                chartId === 'effectivenessStatusChart'
                  ? 'workplans.visited'
                  : chartId === 'arrivalStatusChart'
                  ? 'workplans.onTime'
                  : 'workplans.normal'
            }),
            data: workplansUnits.map((unit) =>
              chartId === 'effectivenessStatusChart'
                ? unit.totalComplete
                : chartId === 'arrivalStatusChart'
                ? unit.totalNormal
                : unit.totalNormalDuration
            )
          },
          chartId === 'effectivenessStatusChart'
            ? undefined
            : {
                name: intl.formatMessage({
                  id:
                    chartId === 'arrivalStatusChart'
                      ? 'workplans.early'
                      : 'workplans.toShortPermanence'
                }),
                data: workplansUnits.map((unit) =>
                  chartId === 'arrivalStatusChart' ? unit.totalEarly : unit.totalShortDuration
                )
              },
          chartId === 'effectivenessStatusChart'
            ? undefined
            : {
                name: intl.formatMessage({
                  id:
                    chartId === 'arrivalStatusChart'
                      ? 'workplans.late'
                      : 'workplans.toLongPermanence'
                }),
                data: workplansUnits.map((unit) =>
                  chartId === 'arrivalStatusChart' ? unit.totalLate : unit.totalLongDuration
                )
              },
          {
            name: intl.formatMessage({
              id: 'workplans.notVisited'
            }),
            data: workplansUnits.map((unit) => unit.totalIncomplete)
          }
        ].filter((serie) => serie)
      },
      {
        idChart: 'slidingPaneLocationsChart',
        buttonChartText: 'workplans.locations',
        chartType: 'bar',
        colors: [colors[0], colors[3]],
        title: intl.formatMessage({
          id: 'workplans.graphGroupedByLocations'
        }),
        horizontal: true,
        stacked: true,
        labels: workplansLocations.map((location) => location.locationName),
        series: [
          {
            name: intl.formatMessage({
              id:
                chartId === 'effectivenessStatusChart'
                  ? 'workplans.visited'
                  : chartId === 'arrivalStatusChart'
                  ? 'workplans.onTime'
                  : 'workplans.normal'
            }),
            data: workplansLocations.map((location) =>
              chartId === 'effectivenessStatusChart'
                ? location.totalComplete
                : chartId === 'arrivalStatusChart'
                ? location.totalNormal
                : location.totalNormalDuration
            )
          },
          chartId === 'effectivenessStatusChart'
            ? undefined
            : {
                name: intl.formatMessage({
                  id:
                    chartId === 'arrivalStatusChart'
                      ? 'workplans.early'
                      : 'workplans.toShortPermanence'
                }),
                data: workplansLocations.map((location) =>
                  chartId === 'arrivalStatusChart'
                    ? location.totalEarly
                    : location.totalShortDuration
                )
              },
          chartId === 'effectivenessStatusChart'
            ? undefined
            : {
                name: intl.formatMessage({
                  id:
                    chartId === 'arrivalStatusChart'
                      ? 'workplans.late'
                      : 'workplans.toLongPermanence'
                }),
                data: workplansLocations.map((location) =>
                  chartId === 'arrivalStatusChart' ? location.totalLate : location.totalLongDuration
                )
              },
          {
            name: intl.formatMessage({
              id: 'workplans.notVisited'
            }),
            data: workplansLocations.map((location) => location.totalIncomplete)
          }
        ].filter((serie) => serie)
      }
    ]
  };
};

//Status of sagas
export const isFetchingWorkplansReport = (state) => getOwnState(state).isFetchingReport;

//Errors
export const getFetchingWorkplansReportErrors = (state) => getOwnState(state).errorFetchingReport;
