import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/routesAssignments';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ROUTES_ASSIGNMENTS_FETCH_COMPLETED: {
      const { orderByDate, entitiesByDate, orderBySchedule, entitiesBySchedule } = action.payload;
      const newState = { ...state };
      orderByDate.forEach((id) => {
        newState[id] = {
          isSelected: false,
          ...state[id],
          ...entitiesByDate[id],
          isConfirmed: true
        };
      });
      orderBySchedule.forEach((id) => {
        newState[id] = {
          isSelected: false,
          ...state[id],
          ...entitiesBySchedule[id],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ROUTES_ASSIGNMENT_EDIT_COMPLETED: {
      const { routeAssignmentId, assignment } = action.payload;
      return {
        ...state,
        [routeAssignmentId]: {
          ...omit(state[routeAssignmentId], ['oldAssignment']),
          ...omit(assignment, ['oldAssignment']),
          isConfirmed: true
        }
      };
    }

    case types.ROUTES_ASSIGNMENTS_IMPORT_COMPLETED: {
      const { orderByDate, entitiesByDate } = action.payload;
      const newState = {};
      orderByDate.forEach((id) => {
        newState[id] = {
          isSelected: false,
          ...state[id],
          ...entitiesByDate[id],
          isConfirmed: true
        };
      });
      return newState;
    }

    //TYPES STARTED EDIT
    case types.ROUTES_ASSIGNMENT_EDIT_STARTED: {
      const assignment = action.payload;
      return {
        ...state,
        [assignment.routeAssignmentId]: {
          ...state[assignment.routeAssignmentId],
          oldAssignment: state[assignment.routeAssignmentId],
          ...omit(assignment, ['routeAssignmentId']),
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED EDIT
    case types.ROUTES_ASSIGNMENT_EDIT_FAILED: {
      const { routeAssignmentId, oldAssignment } = action.payload;
      return {
        ...state,
        [routeAssignmentId]: {
          ...omit(state[routeAssignmentId], ['oldAssignment']),
          ...oldAssignment,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ROUTES_ASSIGNMENT_REMOVE_COMPLETED: {
      const { routeAssignmentId } = action.payload;
      return omit(state, routeAssignmentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH AND REMOVE
    case types.ROUTES_ASSIGNMENTS_FETCH_COMPLETED: {
      const { orderByDate, orderBySchedule } = action.payload;
      return union(orderByDate, orderBySchedule);
    }

    case types.ROUTES_ASSIGNMENTS_IMPORT_COMPLETED: {
      const { orderByDate, orderBySchedule } = action.payload;
      return union(orderByDate);
    }

    case types.ROUTES_ASSIGNMENT_REMOVE_COMPLETED: {
      const { routeAssignmentId } = action.payload;
      return state.filter((routeAssignmentIdState) => routeAssignmentIdState !== routeAssignmentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingAssignments = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_ASSIGNMENTS_FETCH_STARTED: {
      return true;
    }
    case types.ROUTES_ASSIGNMENTS_FETCH_COMPLETED:
    case types.ROUTES_ASSIGNMENTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_ASSIGNMENT_EDIT_STARTED: {
      return true;
    }
    case types.ROUTES_ASSIGNMENT_EDIT_COMPLETED:
    case types.ROUTES_ASSIGNMENT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_ASSIGNMENT_REMOVE_STARTED: {
      return true;
    }
    case types.ROUTES_ASSIGNMENT_REMOVE_COMPLETED:
    case types.ROUTES_ASSIGNMENT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isImportingAssignments = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.ROUTES_ASSIGNMENTS_IMPORT_STARTED: {
      return true;
    }
    case types.ROUTES_ASSIGNMENTS_IMPORT_COMPLETED:
    case types.ROUTES_ASSIGNMENTS_IMPORT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingAssignments = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_ASSIGNMENTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_ASSIGNMENTS_FETCH_STARTED:
    case types.ROUTES_ASSIGNMENTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_ASSIGNMENT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_ASSIGNMENT_EDIT_STARTED:
    case types.ROUTES_ASSIGNMENT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.ROUTES_ASSIGNMENT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ROUTES_ASSIGNMENT_REMOVE_STARTED:
    case types.ROUTES_ASSIGNMENT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const routesAssignments = combineReducers({
  byId,
  order,
  isFetchingAssignments,
  isEditing,
  isRemoving,
  isImportingAssignments,
  errorFetchingAssignments,
  errorEditing,
  errorRemoving
});

export default routesAssignments;

//Own state
export const getOwnState = (state) => state.routes.routesAssignments;

//Information
export const getRoutesAssignment = (state, visitId) => {
  return getOwnState(state).byId[visitId];
};
export const getRoutesAssignmentsList = (state) =>
  getOwnState(state).order.map((id) => getRoutesAssignment(state, id));
export const getRoutesAssignmentsListByType = (state, typeId) =>
  getRoutesAssignmentsList(state).filter((assignment) => assignment.typeId === typeId);

//Status of sagas
export const isFetchingRoutesAssignments = (state) => getOwnState(state).isFetchingAssignments;
export const isEditingRoutesAssignments = (state) => getOwnState(state).isEditing;
export const isRemovingRoutesAssignments = (state) => getOwnState(state).isRemoving;
export const isImportingRouteAssignments = (state) => getOwnState(state).isImportingAssignments;

//Errors
export const getFetchingRoutesAssignmentsErrors = (state) =>
  getOwnState(state).errorFetchingAssignments;
export const getEditingRoutesAssignmentsErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingRoutesAssignmentsErrors = (state) => getOwnState(state).errorRemoving;
