import { Calendar } from 'react-feather';
import Icon from '../../utility/icomoon';
import * as screens from '@core/layouts/components/menu/screens';
import permissions from 'views/screens/Schedules/permissions';
import paths from 'views/screens/Schedules/paths';

export default [
  {
    id: 'schedules',
    title: 'schedules.scheduling',
    icon: <Calendar size={20} />,
    permissions: [permissions.schedule],
    iconName: 'noun_schedule_2522607',
    children: [
      {
        id: 'shiftListScreen',
        title: 'schedules.shift',
        icon: <Icon size={20} icon="turno" />,
        permissions: [permissions.schedule],
        iconName: 'turno',
        navLink: paths.listShift,
        screenId: screens.SHIFT
      },
      {
        id: 'triggerListScreen',
        title: 'schedules.trigger',
        icon: <Icon size={20} icon="disparador" />,
        permissions: [permissions.schedule],
        iconName: 'disparador',
        navLink: paths.listTrigger,
        screenId: screens.TRIGGER
      }
    ]
  }
];
