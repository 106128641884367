import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ASSETS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((assetId) => {
        newState[assetId] = {
          isSelected: false,
          ...state[assetId],
          ...entities[assetId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ASSETS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((assetId) => {
        newState[assetId] = {
          isSelected: false,
          ...state[assetId],
          ...entities[assetId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ASSET_ADD_COMPLETED: {
      const { oldId, asset } = action.payload;
      const newState = omit(state, oldId);
      newState[asset.assetId] = {
        ...state[oldId],
        ...asset,
        isConfirmed: true
      };
      return newState;
    }

    case types.ASSET_EDIT_COMPLETED: {
      const asset = action.payload;
      return {
        ...state,
        [asset.assetId]: {
          ...omit(state[asset.assetId], ['oldAsset']),
          ...omit(asset, ['oldAsset']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.ASSET_ADD_STARTED: {
      const asset = action.payload;
      const newState = { ...state };
      newState[asset.assetId] = {
        isSelected: false,
        ...asset,
        isConfirmed: false
      };
      return newState;
    }
    case types.ASSET_EDIT_STARTED: {
      const asset = action.payload;
      return {
        ...state,
        [asset.assetId]: {
          ...state[asset.assetId],
          oldAsset: state[asset.assetId],
          ...asset,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.ASSET_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.ASSET_EDIT_FAILED: {
      const { oldAsset } = action.payload;
      return {
        ...state,
        [oldAsset.assetId]: {
          ...omit(state[oldAsset.assetId], ['oldAsset']),
          ...oldAsset,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ASSET_REMOVE_COMPLETED: {
      const { assetId } = action.payload;
      return omit(state, assetId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.ASSET_SELECTED: {
      const assetId = action.payload;
      const newState = {
        ...state,
        [assetId]: {
          ...state[assetId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.ASSET_DESELECTED: {
      const assetId = action.payload;
      return {
        ...state,
        [assetId]: {
          ...state[assetId],
          isSelected: false
        }
      };
    }

    case types.ASSETS_ALL_SELECTED: {
      const assetIds = action.payload;
      const newState = { ...state };
      if (assetIds.length == 0) {
        forEach(state, (asset: any, assetId) => {
          newState[assetId] = {
            ...asset,
            isSelected: true
          };
        });
      } else {
        assetIds.forEach((assetId) => {
          newState[assetId] = {
            ...state[assetId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.ASSETS_ALL_DESELECTED: {
      const assetIds = action.payload;
      const newState = { ...state };
      if (assetIds.length == 0) {
        forEach(state, (asset: any, assetId) => {
          newState[assetId] = {
            ...asset,
            isSelected: false
          };
        });
      } else {
        assetIds.forEach((assetId) => {
          newState[assetId] = {
            ...state[assetId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ASSETS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.ASSETS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.ASSET_ADD_COMPLETED: {
      const { oldId, asset } = action.payload;
      return state.map((assetId) => (assetId === oldId ? asset.assetId : assetId));
    }

    case types.ASSET_REMOVE_COMPLETED: {
      const { assetId } = action.payload;
      return state.filter((assetIdState) => assetIdState !== assetId);
    }

    //CASE ADD STARTED
    case types.ASSET_ADD_STARTED: {
      const asset = action.payload;
      return [...state, asset.assetId];
    }

    //CASE ADD FAILED
    case types.ASSET_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((assetIdState) => assetIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ASSETS_FETCH_STARTED: {
      return true;
    }
    case types.ASSETS_FETCH_COMPLETED:
    case types.ASSETS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ASSETS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.ASSETS_PARTIAL_FETCH_COMPLETED:
    case types.ASSETS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.ASSET_ADD_STARTED: {
      return true;
    }
    case types.ASSET_ADD_COMPLETED:
    case types.ASSET_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.ASSET_EDIT_STARTED: {
      return true;
    }
    case types.ASSET_EDIT_COMPLETED:
    case types.ASSET_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.ASSET_REMOVE_STARTED: {
      return true;
    }
    case types.ASSET_REMOVE_COMPLETED:
    case types.ASSET_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ASSETS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ASSETS_FETCH_STARTED:
    case types.ASSETS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ASSETS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ASSETS_PARTIAL_FETCH_STARTED:
    case types.ASSETS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.ASSET_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ASSET_ADD_STARTED:
    case types.ASSET_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.ASSET_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ASSET_EDIT_STARTED:
    case types.ASSET_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.ASSET_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ASSET_REMOVE_STARTED:
    case types.ASSET_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const validIdentifier = (state = true, action) => {
  switch (action.type) {
    case types.ASSET_IDENTIFIER_CHECK_COMPLETED: {
      return action.payload;
    }
    case types.ASSET_IDENTIFIER_CHECK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const validSecuritySealCode = (state = true, action) => {
  switch (action.type) {
    case types.ASSET_SECURITY_SEAL_CODE_CHECK_COMPLETED: {
      return action.payload;
    }
    case types.ASSET_SECURITY_SEAL_CODE_CHECK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const assets = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  validIdentifier,
  validSecuritySealCode
});

export default assets;

//Own state
export const getOwnState = (state) => state.assets;

//Information
export const getAsset = (state, assetId) => getOwnState(state).byId[assetId];
export const getAssetsList = (state) =>
  getOwnState(state)
    .order.map((id) => getAsset(state, id))
    .filter((asset) => asset.assetStatus > 0);

export const getAssetListByMembership = (state, membership) =>
  getAssetsList(state).filter(
    (asset) =>
      membership &&
      (!membership.operatorId || membership.operatorId === asset.operatorId) &&
      (!membership.organizationId || membership.organizationId === asset.organizationId) &&
      (!membership.divisionId || membership.divisionId === asset.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === asset.subdivisionId)
  );

export const getSelectedAssets = (state) => {
  const selectedAssets = getAssetsList(state).filter((asset) => asset.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedAssets.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedAssets.length > 0) return selectedAssets;
};

export const getSelectedAsset = (state) => {
  const selectedAssets = getAssetsList(state).filter((asset) => asset.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedAssets.length === 1) return selectedAssets[0];
  //De lo contrario se devuelve null
  else return null;
};

export const isValidIdentifier = (state) => getOwnState(state).validIdentifier;
export const isValidSecuritySealCode = (state) => getOwnState(state).validSecuritySealCode;

//Status of sagas
export const isPartialFetchingListAssets = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListAssets = (state) => getOwnState(state).isFetchingList;
export const isAddingAsset = (state) => getOwnState(state).isAdding;
export const isEditingAsset = (state) => getOwnState(state).isEditing;
export const isRemovingAsset = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListAssetsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListAssetsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingAssetErrors = (state) => getOwnState(state).errorAdding;
export const getEditingAssetErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingAssetErrors = (state) => getOwnState(state).errorRemoving;
