// @ts-nocheck
import '@geoman-io/leaflet-geoman-free';
import L, { polyline } from 'leaflet';
import 'leaflet-toolbar';
import 'leaflet.icon.glyph';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label } from 'reactstrap';
import simplify from 'simplify-geometry';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { v4 as uuidv4 } from 'uuid';
import {
  getIcon,
  getMarker,
  globalNotificationFrecs,
  routesCheckpointsIcons,
  routesEvents,
  startTimeWindow
} from 'views/screens/Routes/utils';
import { geocodeByLatLng } from 'react-google-places-autocomplete';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as locationSelectors from '../../../../screens/Locations/reducers';
import * as routeActions from '../../../../screens/Routes/actions/routes';
import { getRectBoundsFromLatLng } from '../../utils';
import useGetAlternateRoutes from 'views/screens/Routes/hooks/useGetAlternateRoutes';

export interface MapRouteEditOptions {
  showRoute: boolean;
  route: any;
  routeCheckpoints: any;
  routePaths: any;
  setEditingRouteParent?: any;
}

const getNullIfNaN = (data: unknown): null | number => {
  const converted = Number(data);

  return isNaN(converted) ? null : converted;
};

export const useMapRouteEdit = (props: MapRouteEditOptions) => {
  const { route, routePaths, routeCheckpoints, setEditingRouteParent } = props;
  const { control, handleSubmit, watch, formState } = useForm();
  const intl = useIntl();
  const map = useMap();
  const dispatch = useDispatch();

  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  //States to create new locations
  const isGeneratingRouteRef = React.useRef(false);
  const useCenterRoute = React.useRef(false);
  const [isStartingEdit, setIsStartingEdit] = useState<boolean>(false);
  const [distanceRoute, setDistanceRoute] = useState<any>({ distance: 0 });
  const counterGenerateRouteRef = React.useRef(0);
  const [checkpoints, setCheckpoints] = useState<any[]>([]);
  const checkpointsRef = React.useRef(checkpoints);
  const [paths, setPaths] = useState<any>(null);
  const pathsRef = React.useRef(paths);
  const [drawingRouteCheckpoint, setDrawingRouteCheckpoint] = useState<any>(null);
  const drawingRouteCheckpointRef = React.useRef(drawingRouteCheckpoint);
  const [createdLocations, setCreatedLocations] = useState<any[]>([]);
  const createdLocationsRef = React.useRef(createdLocations);
  const [isOpenSlidingPaneEditRoute, setOpenSlidingPaneEditRoute] = useState(false);
  const [widthSlidingPaneEditRoute, setWidthSlidingPaneEditRoute] = useState('50rem');
  const [isOpenSlidingPaneForm, setOpenSlidingPaneForm] = useState(false);
  const [slidingPaneEditRoutesProps, setSlidingPaneEditRouteProps] = useState<any>({ inputs: [] });
  const [slidingPaneFormProps, setSlidingPaneFormProps] = useState<any>({ inputs: [] });
  const slidingPaneFormPropsRef = React.useRef(slidingPaneFormProps);
  const [editingRoute, setEditingRoute] = useState<any>(undefined);
  const editingRouteRef = React.useRef(editingRoute);
  const [copyPolylineLatLngs, setCopyPolylineLatLngs] = useState<any>({});
  const copyPolylineLatLngsRef = React.useRef(copyPolylineLatLngs);
  const [alternateRoutes, setAlternateRoutes] = useState<any[]>([]);
  const alternateRoutesRef = React.useRef(alternateRoutes);

  const formValues = watch();
  const { locations, parsers, authSystems } = useSelector((state) => ({
    locations: locationSelectors.getLocationsList(state),
    authSystems: authSelectors.getAuthSystems(state),
    parsers: authSelectors.getParsers(state)
  }));

  const { data, isFetching } = useGetAlternateRoutes(editingRoute?.routeId);

  useEffect(() => {
    if (!isFetching) setAlternateRoutes(data);
  }, [isFetching]);

  useEffect(() => {
    editingRouteRef.current = editingRoute;
    if (editingRoute != undefined) {
      editRoute();
    }
  }, [editingRoute]);

  //Se actualiza la distancia Format
  useEffect(() => {
    setDistanceRoute({
      ...distanceRoute,
      distanceFormat: parsers
        .convertMetersToAuthUserDistanceSystem(distanceRoute.distance)
        .toFixed(2)
    });
  }, [distanceRoute?.distance]);

  useEffect(() => {
    deleteObjectsOnMap();
    if (routePaths.length > 0) {
      setIsStartingEdit(true);
      const newLocations = routeCheckpoints
        .filter((checkpoint) => !checkpoint.locationId)
        .map((checkpoint) => ({
          locationId: uuidv4(),
          checkpointId: checkpoint.checkpointId,
          locationName: checkpoint.checkpointName,
          longitude: checkpoint.longitude,
          latitude: checkpoint.latitude,
          radius: checkpoint.radius
        }));
      setCreatedLocations(newLocations);
      setCheckpoints(
        routeCheckpoints.map((checkpoint) => {
          return checkpoint.locationId
            ? checkpoint
            : {
                ...checkpoint,
                locationId: newLocations.filter(
                  (loc) => loc.checkpointId === checkpoint.checkpointId
                )[0].locationId
              };
        })
      );
      setPaths(
        routePaths.map((path, i) => {
          const polyline = path.polyline;
          polyline.addTo(map);
          polyline.on('click', (e) => {
            L.DomEvent.stopPropagation(e);
            editPath(i);
          });
          return {
            ...path,
            secondsSegmentStopTime:
              path?.segmentStopAuthorized && [false, 0].includes(path?.segmentWithoutStopTime)
                ? path?.segmentStopTime * 60
                : undefined,
            secondsSegmentTripMinTime: path?.segmentTripMinTime,
            secondsSegmentTripMaxTime: path?.segmentTripMaxTime,
            polyline
          };
        })
      );
      setDistanceRoute({ ...distanceRoute, distance: route.distance });
    } else {
      setCreatedLocations([]);
      setCheckpoints(routeCheckpoints);
      setPaths(routePaths);
      setDistanceRoute({ ...distanceRoute, distance: 0 });
    }
    setSlidingPaneEditRouteProps({ inputs: [] });
    setEditingRoute(route);
  }, [route]);

  useDeepCompareEffect(() => {
    if (!isGeneratingRouteRef.current) {
      const lastCheckpointsRef = checkpointsRef.current;
      let addedLocation = null;
      if (
        !(
          lastCheckpointsRef.length == checkpoints.length &&
          lastCheckpointsRef.every((element, index) => {
            //Si se agrego o cambio una referencia se guarda la informaccion del checkpoint
            if (
              !checkpoints
                .filter((chk) => chk.locationId)
                .map((chk) => chk.locationId)
                .includes(element.locationId)
            )
              addedLocation = [
                element.checkpointId,
                index === 0 ? 1 : index === checkpoints.length - 1 ? 3 : 2,
                index
              ];

            return (
              element.latitude === checkpoints[index].latitude &&
              element.longitude === checkpoints[index].longitude
            );
          })
        )
      ) {
        //Se eliminan los markers antiguos
        if (!isStartingEdit) deleteObjectsOnMap();
        //Se actualizan los markers
        updateMarkersOnMap();
        //Si se agrego o cambio una referencia se procede a editar el checkpoint
        if (addedLocation) editCheckpoint(...addedLocation);
        //Se actualizan los paths
        if (!isStartingEdit) updatePathsOnMap();
        else setIsStartingEdit(false);
      }
    }
  }, [checkpoints, isGeneratingRouteRef.current]);

  useEffect(() => {
    pathsRef.current = paths;
    //Se centra en el mapa
    if (!isOpenSlidingPaneForm) centerRoute();
    useCenterRoute.current = true;
  }, [paths]);

  useEffect(() => {
    slidingPaneFormPropsRef.current = slidingPaneFormProps;
  }, [slidingPaneFormProps]);

  useEffect(() => {
    copyPolylineLatLngsRef.current = copyPolylineLatLngs;
  }, [copyPolylineLatLngs]);

  useDeepCompareEffect(() => {
    createdLocationsRef.current = createdLocations;
  }, [createdLocations]);

  useEffect(() => {
    drawingRouteCheckpointRef.current = drawingRouteCheckpoint;
  }, [drawingRouteCheckpoint]);

  useEffect(() => {
    alternateRoutesRef.current = alternateRoutes;
  }, [alternateRoutes]);

  const refreshTooltips = (type) => {
    const customTranslation = {
      tooltips: {
        placeMarker: intl.formatMessage({
          id:
            type === 1
              ? 'routes.addingOrigin'
              : type === 3
              ? 'routes.addingDestination'
              : 'routes.addingCheckpoint'
        })
      }
    };
    map.pm.setLang('customTranslation', customTranslation, intl.locale);
  };

  useEffect(() => {
    map.on('pm:create', (e) => {
      if (e.shape == 'Marker' && drawingRouteCheckpointRef.current) {
        setWidthSlidingPaneEditRoute('50rem');
        const { marker } = e;
        const radius = 150;
        const { lat, lng } = marker.getLatLng();
        if (lat && lng) {
          geocodeByLatLng({ lat, lng })
            .then((results) => {
              const routePlace = results.filter((r) => r.types.includes('route'))[0];
              const location = {
                locationId: uuidv4(),
                locationName: routePlace
                  ? routePlace.formatted_address
                  : results[0]?.formatted_address,
                longitude: lng,
                latitude: lat,
                radius,
                radiusFormat: parsers.convertMetersToAuthUserDistance2System(150)
              };
              addCreatedLocationToCheckpoint(marker, location);
            })
            .catch(() => {
              const location = {
                locationId: uuidv4(),
                locationName: intl.formatMessage({ id: 'routes.newCheckPoint' }),
                longitude: lng,
                latitude: lat,
                radius,
                radiusFormat: parsers.convertMetersToAuthUserDistance2System(150)
              };
              addCreatedLocationToCheckpoint(marker, location);
            });
        }
      }
    });
  }, []);

  const deleteObjectsOnMap = () => {
    checkpointsRef.current
      .filter((checkpoint) => checkpoint.marker)
      .map((checkpoint) => {
        if (checkpoint.marker) map.removeLayer(checkpoint.marker);
        if (checkpoint.rectangle) map.removeLayer(checkpoint.rectangle);
      });
    if (paths?.length > 0)
      paths.map((path) => {
        if (path.polyline) map.removeLayer(path.polyline);
      });
  };

  const addNewMarkerOnMap = (index) => {
    setDrawingRouteCheckpoint(checkpoints[index]);
    setWidthSlidingPaneEditRoute('0rem');
    refreshTooltips(index === 0 ? 1 : index === checkpoints.length - 1 ? 3 : 2);
    map.pm.enableDraw('Marker', {
      continueDrawing: false, //Disable After Create
      markerEditable: true,
      markerStyle: {
        icon: getIcon(
          index === 0 ? 1 : index === checkpoints.length - 1 ? 3 : 2,
          index,
          checkpoints[index].iconId
        )
      }
    });
  };

  const updateMarkersOnMap = () => {
    checkpointsRef.current = checkpoints.map((checkpoint, i) => {
      if (checkpoint.latitude != null && checkpoint.longitude != null) {
        const marker = getMarker(
          i === 0 ? 1 : i === checkpoints.length - 1 ? 3 : 2,
          i,
          checkpoint.iconId === 0 ? undefined : checkpoint.iconId,
          L.latLng(checkpoint.latitude, checkpoint.longitude)
        );
        marker.addTo(map);
        marker.on('pm:dragend', (e) => {
          const { lat, lng } = e.target.getLatLng();
          useCenterRoute.current = false;
          setCheckpoints(
            checkpointsRef.current.map((chkpt) =>
              chkpt.checkpointId === checkpoint.checkpointId
                ? {
                    ...chkpt,
                    longitude: lng,
                    latitude: lat
                  }
                : chkpt
            )
          );
        });
        marker.on('click', (e) => {
          setTimeout(() => {
            if (!isGeneratingRouteRef.current)
              editCheckpoint(
                checkpoint.checkpointId,
                i === 0 ? 1 : i === checkpoints.length - 1 ? 3 : 2,
                i
              );
          }, 100);
        });
        marker.pm.enable({});

        const rectangle = L.rectangle(
          getRectBoundsFromLatLng(checkpoint.latitude, checkpoint.longitude, checkpoint.radius),
          { color: primary }
        );
        rectangle.addTo(map);

        return {
          ...checkpoint,
          //Se actualizan los markers
          marker,
          rectangle
        };
      } else return checkpoint;
    });

    //Se centra en el mapa
    centerRoute();

    if (checkpointsRef.current.filter((checkpoint) => checkpoint.marker).length > 1)
      setCheckpoints(checkpointsRef.current);
  };

  const updatePathsOnMap = () => {
    const checkpointsWithLatLng = checkpointsRef.current.filter((checkpoint) => checkpoint.marker);

    if (checkpointsWithLatLng.length > 1) {
      //Se crea el control de las rutas
      const control = L.Routing.control({
        waypoints: checkpointsWithLatLng.map((checkpoint) =>
          L.latLng(checkpoint.latitude, checkpoint.longitude)
        ),
        router:
          counterGenerateRouteRef.current >= 4
            ? L.Routing.google()
            : new L.Routing.OSRMv1({ serviceUrl: 'https://osrm.webtrack.online/route/v1' })
      });

      control.on('routingstart', () => {
        isGeneratingRouteRef.current = true;
      });

      control.on('routingerror', (error) => {
        if (error.error.status !== -3) {
          counterGenerateRouteRef.current = counterGenerateRouteRef.current + 1;
          updatePathsOnMap();
        }
      });

      control.on('routesfound', (waypoints) => {
        isGeneratingRouteRef.current = false;
        if (waypoints?.routes[0].coordinates) {
          const coordinates = waypoints?.routes[0].coordinates;
          const waypointIndices = waypoints?.routes[0].waypointIndices;
          const paths = waypointIndices
            .map((index, i) =>
              i !== waypointIndices.length - 1
                ? simplify(
                    [
                      [
                        checkpointsRef.current.filter(
                          (chk) => chk.latitude != null && chk.longitude != null
                        )[i].latitude,
                        checkpointsRef.current.filter(
                          (chk) => chk.latitude != null && chk.longitude != null
                        )[i].longitude
                      ],
                      ...coordinates
                        .slice(index, waypointIndices[i + 1] + 1)
                        .map((latLng) => [latLng.lat, latLng.lng]),
                      [
                        checkpointsRef.current.filter(
                          (chk) => chk.latitude != null && chk.longitude != null
                        )[i + 1].latitude,
                        checkpointsRef.current.filter(
                          (chk) => chk.latitude != null && chk.longitude != null
                        )[i + 1].longitude
                      ]
                    ],
                    0.0005 //55.5m
                  ).map((point) => L.latLng(point[0], point[1]))
                : undefined
            )
            .filter((path) => path);

          //Se actualizan los paths
          var distance = 0;
          setPaths(
            paths.map((path, i) => {
              const polyline = L.polyline(path, { color: 'blue' });
              polyline.addTo(map);
              polyline.on('click', (e) => {
                L.DomEvent.stopPropagation(e);
                editPath(i);
              });
              const segmentDistance = polyline
                .getLatLngs()
                .map((point, j) =>
                  j === 0 ? 0 : map.distance(polyline.getLatLngs()[j - 1], point)
                )
                .reduce((acc, distance) => acc + distance, 0);
              distance = distance + segmentDistance;
              return {
                polyline,
                segmentDistance
              };
            })
          );
          setDistanceRoute({ ...distanceRoute, distance });
        }
        counterGenerateRouteRef.current = 0;
      });

      //Se genera la ruta
      control.route();
    } else {
      setPaths([]);
      setDistanceRoute({ ...distanceRoute, distance: 0 });
    }
  };

  const updateDistances = () => {
    if (pathsRef.current?.length > 0) {
      var distance = 0;
      setPaths(
        pathsRef.current.map((path) => {
          const segmentDistance = path.polyline
            .getLatLngs()
            .map((point, j) =>
              j === 0 ? 0 : map.distance(path.polyline.getLatLngs()[j - 1], point)
            )
            .reduce((acc, distance) => acc + distance, 0);
          distance = distance + segmentDistance;
          return {
            ...path,
            segmentDistance
          };
        })
      );
      setDistanceRoute({ ...distanceRoute, distance });
    }
  };

  const centerRoute = () => {
    if (useCenterRoute.current && checkpointsRef.current.filter((chk) => chk.marker).length > 0) {
      var latlngPolylines = [];
      if (pathsRef.current?.length > 0)
        pathsRef.current.map((path) =>
          path.polyline.getLatLngs().map((latLng) => latlngPolylines.push([latLng.lat, latLng.lng]))
        );

      const bounds = [
        ...checkpointsRef.current
          .filter((chk) => chk.marker)
          .map((chk) => [chk.latitude, chk.longitude]),
        ...latlngPolylines
      ];

      map.flyToBounds(bounds, {
        paddingBottomRight: [700, 0],
        paddingTopLeft: [0, 70],
        animate: map.getBoundsZoom(bounds, undefined, [700, 70]) !== map.getZoom()
      });
    }
  };

  const editRoute = () => {
    setOpenSlidingPaneEditRoute(false);
    setOpenSlidingPaneForm(false);
    setSlidingPaneEditRouteProps({
      title:
        editingRouteRef.current?.routeId && editingRouteRef.current?.routeName
          ? editingRouteRef.current?.routeName
          : intl.formatMessage({ id: 'routes.new' }),
      inputs: [
        {
          type: 'text',
          name: 'routeName',
          required: true,
          initialValue: editingRouteRef.current?.routeName || undefined,
          label: intl.formatMessage({ id: 'routes.name' })
        },
        {
          type: 'customersDropdown',
          name: 'customerId',
          initialValue: editingRouteRef.current?.customerId || undefined,
          isClearable: true,
          customersFilters: {}
        },
        {
          type: 'duration',
          name: 'completionTime',
          initialValue: editingRouteRef.current?.completionTimeByUser || undefined,
          label: intl.formatMessage({ id: 'routes.completionTime' })
        },
        {
          type: 'select',
          name: 'startTimeWindow',
          required: true,
          initialValue: editingRouteRef.current?.startTimeWindow || '0',
          options: startTimeWindow.map((option) => ({
            ...option,
            label: option.label + intl.formatMessage({ id: 'routes.minutes' })
          })),
          label: intl.formatMessage({ id: 'routes.startTimeWindow' })
        },
        {
          type: 'number',
          name: 'speedLimitFormat',
          initialValue: editingRouteRef.current?.speedLimitFormat || undefined,
          min: 0,
          max: parsers.convertKilometersPerHourToAuthUserSpeedSystem(120),
          metric: 'speed',
          label: intl.formatMessage({ id: 'routes.speedLimit' })
        },
        {
          type: 'text',
          name: 'abbreviation',
          required: true,
          initialValue: editingRouteRef.current?.abbreviation || undefined,
          label: intl.formatMessage({ id: 'routes.abbreviation' })
        },
        {
          type: 'text',
          name: 'privateCode',
          initialValue: editingRouteRef.current?.privateCode || undefined,
          label: intl.formatMessage({ id: 'routes.privateCode' })
        },
        {
          type: 'membership',
          name: 'membership',
          options: {
            colSize: '12',
            useOperator: false,
            useUserOrganization: true,
            useUserDivision: true,
            useUserSubdivision: true,
            setUserOrganization: true,
            setUserDivision: true,
            setUserSubdivision: true,
            showAllDivision: true,
            showAllSubdivision: true
          },
          initialValue: {
            organizationId: editingRouteRef.current?.organizationId || undefined,
            divisionId: editingRouteRef.current?.divisionId || undefined,
            subdivisionId: editingRouteRef.current?.subdivisionId || undefined
          }
        },
        {
          type: 'contactsDropdown',
          defaultContacts: editingRouteRef.current?.globalNotificationEmails || undefined,
          name: 'globalNotificationEmails',
          label: intl.formatMessage({ id: 'routes.globalNotificationEmails' })
        },
        {
          type: 'select',
          name: 'globalNotificationFrec',
          initialValue: editingRouteRef.current?.globalNotificationFrec || undefined,
          options: [
            {
              value: '0',
              label: intl.formatMessage({ id: 'routes.inactive' }),
              label_intl: intl.formatMessage({ id: 'routes.inactive' })
            },
            ...globalNotificationFrecs.map((option) => ({
              ...option,
              label: intl.formatMessage({ id: option.label }),
              label_intl: intl.formatMessage({ id: option.label })
            }))
          ],
          label: intl.formatMessage({ id: 'routes.globalNotificationFrec' }),
          isClearable: true
        },
        {
          type: 'contactsDropdown',
          defaultContacts: editingRouteRef.current?.notificationEmails || undefined,
          name: 'notificationEmails',
          initialFetch: false,
          label: intl.formatMessage({ id: 'routes.notificationEmails' })
        },
        {
          type: 'multiSelect',
          name: 'notificationEvents',
          initialValue: editingRouteRef.current?.notificationEvents || undefined,
          options: routesEvents.map((option) => ({
            ...option,
            label: intl.formatMessage({ id: option.label }),
            label_intl: intl.formatMessage({ id: option.label })
          })),
          label: intl.formatMessage({ id: 'routes.notificationEvents' })
        }
      ],
      action: (values) => {
        const action = editingRouteRef.current.routeId ? 'startEditingRoute' : 'startAddingRoute';
        var distances = [];
        var index = -1;
        var lastPointLatLngs = null;
        var distance = 0;
        dispatch(
          routeActions[action]({
            route: {
              routeId: editingRouteRef.current.routeId || editingRouteRef.current.newRouteId,
              ...values,
              ...values.membership,
              speedLimit: parsers.convertAuthUserSpeedSystemToKilometersPerHour(
                values.speedLimitFormat
              ),
              globalNotificationFrec: values?.globalNotificationFrec || '0',
              completionTimeByUser: values?.completionTime?.totalSeconds || 0,
              path: pathsRef.current
                .map((path, i) => {
                  var segmentDistance = 0;
                  return path.polyline
                    .getLatLngs()
                    .map((point, j) => {
                      const pointDistance = lastPointLatLngs
                        ? map.distance(lastPointLatLngs, point)
                        : 0;
                      lastPointLatLngs = point;
                      segmentDistance = j === 0 ? 0 : segmentDistance + pointDistance;
                      distance = distance + pointDistance;
                      index = index + 1;
                      if (path.polyline.getLatLngs().length - 1 === j)
                        distances.push(segmentDistance);
                      return (
                        index.toString() +
                        ',' +
                        (i + 1).toString() +
                        ',' +
                        point.lat.toString() +
                        ',' +
                        point.lng.toString() +
                        ',' +
                        distance
                      );
                    })
                    .join(';');
                })
                .join(';'),
              distance: distances.reduce((acc, distance) => acc + distance, 0),
              alternateRoutes: alternateRoutesRef.current.map((ar) => ar.routeId).join(',')
            },
            checkpoints: checkpointsRef.current.map((checkpoint, i) => ({
              checkpointId: i,
              routeId: editingRouteRef.current.routeId || editingRouteRef.current.newRouteId,
              locationId: Number.isInteger(checkpoint.locationId) ? checkpoint.locationId : null,
              checkpointName: checkpoint.checkpointName,
              latitude: checkpoint.latitude,
              longitude: checkpoint.longitude,
              radius: checkpoint.radius,
              correlative: i,
              distance:
                i === 0 ? 0 : distances.slice(0, i).reduce((acc, distance) => acc + distance, 0),
              iconId: checkpoint.iconId,
              stopType: checkpoint.stopType,
              stopMinTime: checkpoint.stopMinTime ?? null,
              stopMaxTime: checkpoint.stopMaxTime ?? null,
              segmentDistance: i === 0 ? 0 : getNullIfNaN(distances[i - 1]),
              segmentSpeedLimit:
                i === 0 ? null : getNullIfNaN(pathsRef.current[i - 1]?.segmentSpeedLimit),
              segmentStopAuthorized:
                i === 0 ? null : pathsRef.current[i - 1]?.segmentStopAuthorized,
              secondsSegmentStopTime:
                i === 0 ? null : getNullIfNaN(pathsRef.current[i - 1]?.secondsSegmentStopTime),
              secondsSegmentTripMinTime:
                i === 0 ? null : getNullIfNaN(pathsRef.current[i - 1]?.secondsSegmentTripMinTime),
              secondsSegmentTripMaxTime:
                i === 0 ? null : getNullIfNaN(pathsRef.current[i - 1]?.secondsSegmentTripMaxTime),
              notificationEmails: checkpoint.notificationEmails
            }))
          })
        );
      }
    });
    setOpenSlidingPaneEditRoute(true);
  };

  const addCheckpoint = (index) => {
    setCheckpoints([
      ...checkpoints.slice(0, index + 1),
      { checkpointId: uuidv4() },
      ...checkpoints.slice(index + 1)
    ]);
  };

  const editCheckpoint = (checkpointId, type, index) => {
    const checkpoint = checkpointsRef.current.filter(
      (checkpoint) => checkpoint.checkpointId === checkpointId
    )[0];
    if (!(checkpoint.latitude != null && checkpoint.longitude != null && checkpoint.marker))
      return null;
    map.flyToBounds([[checkpoint.latitude, checkpoint.longitude]], {
      paddingBottomRight: [700, 0],
      paddingTopLeft: [0, 70],
      maxZoom: 15,
      animate: 15 !== map.getZoom()
    });
    const inputCheckpoint =
      type === 2
        ? [
            {
              type: 'select',
              name: 'iconId',
              initialValue: checkpoint.iconId ?? 1,
              options: routesCheckpointsIcons.map((op) => ({
                ...op,
                label: intl.formatMessage({ id: op.label })
              })),
              label: intl.formatMessage({ id: 'routes.checkpointIcon' }),
              additionalonchangefunction: (value) => {
                if (!value && checkpoint.iconId)
                  checkpoint.marker.setIcon(getIcon(type, index, checkpoint.iconId));
                else checkpoint.marker.setIcon(getIcon(type, index, value));
              }
            },
            {
              type: 'contactsDropdown',
              name: 'notificationEmails',
              initialValue: checkpoint.notificationEmails || undefined,
              label: intl.formatMessage({ id: 'routes.notify' })
            },
            {
              type: 'switch',
              name: 'stopType',
              initialValue: checkpoint.stopType || false,
              label: intl.formatMessage({ id: 'routes.stopType' }),
              additionalonchangefunction: (value) => {
                if (slidingPaneFormPropsRef?.current?.inputs)
                  setSlidingPaneFormProps({
                    ...slidingPaneFormPropsRef.current,
                    inputs: value
                      ? [
                          ...slidingPaneFormPropsRef.current.inputs,
                          {
                            type: 'duration',
                            name: 'stopMinTime',
                            initialValue: checkpoint.stopMinTime || undefined,
                            label: intl.formatMessage({ id: 'routes.stopMinTime' })
                          },
                          {
                            type: 'duration',
                            name: 'stopMaxTime',
                            initialValue: checkpoint.stopMaxTime || undefined,
                            label: intl.formatMessage({ id: 'routes.stopMaxTime' })
                          }
                        ]
                      : slidingPaneFormPropsRef.current.inputs.slice(0, 5)
                  });
              }
            }
          ]
        : [];

    setOpenSlidingPaneForm(false);
    setSlidingPaneFormProps({
      title:
        type === 1
          ? intl.formatMessage({ id: 'routes.editOrigin' })
          : type === 2
          ? intl.formatMessage({ id: 'routes.editCheckPoint' })
          : intl.formatMessage({ id: 'routes.editDestination' }),
      inputs: [
        {
          type: 'text',
          name: 'checkpointName',
          required: true,
          initialValue: checkpoint.checkpointName || undefined,
          label:
            type === 1
              ? intl.formatMessage({ id: 'routes.originName' })
              : type === 2
              ? intl.formatMessage({ id: 'routes.checkpointName' })
              : intl.formatMessage({ id: 'routes.destinationName' })
        },
        {
          type: 'number',
          name: 'radiusFormat',
          initialValue: checkpoint.radiusFormat || undefined,
          min: parsers.convertMetersToAuthUserDistance2System(10),
          max: parsers.convertMetersToAuthUserDistance2System(5000),
          metric: 'distance2',
          label: intl.formatMessage({ id: 'routes.radius' }),
          additionalonchangefunction: (value) =>
            checkpoint.rectangle.setBounds(
              getRectBoundsFromLatLng(
                checkpoint.latitude,
                checkpoint.longitude,
                parsers.convertAuthUserDistance2SystemToMeters(value)
              )
            )
        },
        ...inputCheckpoint
      ],
      action: (values) => {
        const radius = parsers.convertAuthUserDistance2SystemToMeters(values.radiusFormat);
        checkpoint.marker.setIcon(getIcon(type, index, values.iconId));
        checkpoint.rectangle.setBounds(
          getRectBoundsFromLatLng(checkpoint.latitude, checkpoint.longitude, radius)
        );
        checkpointsRef.current = checkpointsRef.current.map((chk) =>
          chk.checkpointId === checkpoint.checkpointId
            ? {
                ...chk,
                ...values,
                radius,
                stopMinTime: values.stopType ? values.stopMinTime?.totalSeconds : undefined,
                stopMaxTime: values.stopType ? values.stopMaxTime?.totalSeconds : undefined
              }
            : chk
        );
        setCheckpoints(checkpointsRef.current);
      },
      closeSlidingPaneForm: () => {
        centerRoute();
        checkpoint.marker.setIcon(getIcon(type, index, checkpoint.iconId));
        checkpoint.rectangle.setBounds(
          getRectBoundsFromLatLng(checkpoint.latitude, checkpoint.longitude, checkpoint.radius)
        );
        setOpenSlidingPaneForm(false);
      }
    });
    setOpenSlidingPaneForm(true);
  };

  const deleteCheckpoint = (checkpointId) => {
    if (!formValues.checkpointId) {
      deleteObjectsOnMap();
      setCheckpoints(
        checkpoints.filter((checkpoint) => {
          if (checkpoint.checkpointId === checkpointId && checkpoint.marker)
            setCreatedLocations(
              createdLocations.filter((loc) => loc.locationId !== checkpoint.locationId)
            );
          return checkpoint.checkpointId !== checkpointId;
        })
      );
    }
  };
  const editPath = (index) => {
    if (pathsRef.current) {
      const path = pathsRef.current[index];
      if (path) {
        //Si se estaba editando un tramo se resetea su informacion en el mapa
        if (copyPolylineLatLngsRef.current?.index != undefined) {
          pathsRef.current[copyPolylineLatLngsRef.current.index].polyline.setLatLngs(
            copyPolylineLatLngsRef.current.latLngs
          );
          pathsRef.current[copyPolylineLatLngsRef.current.index].polyline.pm.disable();
          pathsRef.current[copyPolylineLatLngsRef.current.index].polyline.setStyle({
            color: 'blue'
          });
        }
        setCopyPolylineLatLngs({
          latLngs: pathsRef.current[index].polyline.getLatLngs(),
          index: index
        });
        const type = index === pathsRef.current.length - 1 ? 2 : 1;
        path.polyline.pm.enable({ draggable: true });
        path.polyline.setStyle({ color: 'red' });
        map.flyToBounds(path.polyline.getBounds(), {
          paddingBottomRight: [700, 0],
          paddingTopLeft: [0, 70],
          animate:
            map.getBoundsZoom(path.polyline.getBounds(), undefined, [700, 70]) !== map.getZoom()
        });
        setOpenSlidingPaneForm(false);
        setEditPathInputs(
          {
            ...path,
            segmentSpeedLimitFormat: parsers
              .convertKilometersPerHourToAuthUserSpeedSystem(path.segmentSpeedLimit)
              .toFixed(0),
            segmentDistanceFormat: parsers
              .convertMetersToAuthUserDistanceSystem(path.segmentDistance)
              .toFixed(2)
          },
          index,
          type
        );
        setOpenSlidingPaneForm(true);
      }
    }
  };

  const setEditPathInputs = (path, index, type) => {
    setSlidingPaneFormProps({
      title: `${intl.formatMessage({ id: 'routes.editSegment' })} (${
        checkpointsRef.current[index]?.checkpointName
      } - ${checkpointsRef.current[index + 1]?.checkpointName})`,
      inputs: [
        {
          type: 'component',
          component: (
            <FormGroup>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {intl.formatMessage({ id: 'routes.distance' })}
              </Label>
              <Label className="label-value-information">
                {(path?.segmentDistanceFormat || '0') + ' ' + authSystems['distance']}
              </Label>
              <hr className={'full-width-slide-panel'} />
            </FormGroup>
          )
        },
        {
          type: 'number',
          name: 'segmentSpeedLimitFormat',
          initialValue: path?.segmentSpeedLimitFormat || undefined,
          additionalonchangefunction: (value) => {
            setEditPathInputs({ ...path, segmentSpeedLimitFormat: value }, index, type);
          },
          min: 0,
          max: parsers.convertKilometersPerHourToAuthUserSpeedSystem(120),
          metric: 'speed',
          label: intl.formatMessage({ id: 'routes.speedLimit' })
        },
        {
          type: 'duration',
          name: 'segmentTripMinTime',
          additionalonchangefunction: (value) => {
            setEditPathInputs({ ...path, segmentTripMinTime: value }, index, type);
          },
          initialValue: path?.segmentTripMinTime || undefined,
          label: intl.formatMessage({ id: 'routes.segmentTripMinTime' })
        },
        {
          type: 'duration',
          name: 'segmentTripMaxTime',
          additionalonchangefunction: (value) => {
            setEditPathInputs({ ...path, segmentTripMaxTime: value }, index, type);
          },
          initialValue: path?.segmentTripMaxTime || undefined,
          label: intl.formatMessage({ id: 'routes.segmentTripMaxTime' })
        },
        {
          type: 'switch',
          name: 'segmentStopAuthorized',
          initialValue:
            path?.segmentStopAuthorized != undefined ? path?.segmentStopAuthorized : true,
          label: intl.formatMessage({ id: 'routes.segmentStopAuthorized' }),
          additionalonchangefunction: (value) => {
            setEditPathInputs(
              {
                ...path,
                segmentStopAuthorized: value,
                segmentWithoutStopTime: undefined,
                segmentStopTime: undefined
              },
              index,
              type
            );
          }
        },
        path?.segmentStopAuthorized
          ? {
              type: 'switch',
              name: 'segmentWithoutStopTime',
              initialValue:
                path?.segmentWithoutStopTime != undefined ? path?.segmentWithoutStopTime : true,
              label: intl.formatMessage({ id: 'routes.segmentWithoutStopTime' }),
              additionalonchangefunction: (value) => {
                setEditPathInputs(
                  { ...path, segmentWithoutStopTime: value, segmentStopTime: undefined },
                  index,
                  type
                );
              }
            }
          : undefined,
        path?.segmentStopAuthorized && [false, 0].includes(path?.segmentWithoutStopTime)
          ? {
              type: 'number',
              name: 'segmentStopTime',
              additionalonchangefunction: (value) => {
                setEditPathInputs({ ...path, segmentStopTime: value }, index, type);
              },
              initialValue: path?.segmentStopTime || undefined,
              appendcomponent: intl.formatMessage({ id: 'common.minutes' }),
              allowDecimals: true,
              label: intl.formatMessage({ id: 'routes.segmentStopTime' })
            }
          : undefined
      ],
      action: (values) => {
        updateDistances();
        setPaths(
          pathsRef.current.map((path, i) =>
            i === index
              ? {
                  ...path,
                  ...values,
                  secondsSegmentStopTime:
                    values?.segmentStopAuthorized &&
                    [false, 0].includes(values?.segmentWithoutStopTime)
                      ? values?.segmentStopTime * 60
                      : undefined,
                  segmentWithoutStopTime:
                    values?.segmentStopTime > 0 ? values?.segmentWithoutStopTime : true,
                  secondsSegmentTripMinTime: values?.segmentTripMinTime?.totalSeconds || undefined,
                  secondsSegmentTripMaxTime: values?.segmentTripMaxTime?.totalSeconds || undefined,
                  segmentSpeedLimit: parsers
                    .convertAuthUserSpeedSystemToKilometersPerHour(values.segmentSpeedLimitFormat)
                    .toFixed(0)
                }
              : path
          )
        );
      },
      onClose: () => {
        pathsRef.current[index].polyline.setLatLngs(copyPolylineLatLngsRef.current.latLngs);
      },
      closeSlidingPaneForm: () => {
        pathsRef.current[index].polyline.pm.disable();
        pathsRef.current[index].polyline.setStyle({ color: 'blue' });
        updateDistances();
        centerRoute();
        setOpenSlidingPaneForm(false);
        setCopyPolylineLatLngs({});
      }
    });
  };

  const addLocationToCheckpoint = (checkpointId, locationId) => {
    const location = locations.filter((location) => location.locationId === locationId)[0];
    if (location)
      setCheckpoints(
        checkpoints.map((checkpoint) =>
          checkpoint.checkpointId === checkpointId && checkpoint.locationId !== locationId
            ? {
                ...checkpoint,
                googleLocation: false,
                locationId: location.locationId,
                checkpointName: location.locationName,
                longitude: location.longitude,
                latitude: location.latitude,
                radius: location.radius,
                radiusFormat: parsers.convertMetersToAuthUserDistance2System(location.radius)
              }
            : checkpoint
        )
      );
  };

  const addCreatedLocationToCheckpoint = (marker, location) => {
    setCreatedLocations([...createdLocationsRef.current, location]);
    setCheckpoints(
      checkpointsRef.current.map((checkpoint) =>
        checkpoint.checkpointId === drawingRouteCheckpointRef.current.checkpointId
          ? {
              ...checkpoint,
              googleLocation: false,
              locationId: location.locationId,
              checkpointName: location.locationName,
              longitude: location.longitude,
              latitude: location.latitude,
              radius: location.radius,
              radiusFormat: parsers.convertMetersToAuthUserDistance2System(location.radius)
            }
          : checkpoint
      )
    );
    map.removeLayer(marker);
    setDrawingRouteCheckpoint(null);
  };

  const addGoogleLocationToCheckpoint = (checkpointId, location) => {
    if (location) {
      setCheckpoints(
        checkpoints.map((checkpoint) => {
          if (checkpoint.checkpointId === checkpointId) {
            const newLocation = {
              ...checkpoint,
              googleLocation: location.googleLocation,
              locationId: location.locationId,
              checkpointName: location.locationName,
              longitude: location.longitude,
              latitude: location.latitude,
              radius: location.radius,
              radiusFormat: parsers.convertMetersToAuthUserDistance2System(location.radius)
            };
            setCreatedLocations([
              ...createdLocations,
              { ...newLocation, locationName: location.locationName }
            ]);
            return newLocation;
          } else return checkpoint;
        })
      );
    }
  };

  const deleteLocationOfCheckpoint = (checkpointId) => {
    if (!formValues.checkpointId)
      setCheckpoints(
        checkpoints.map((checkpoint) => {
          if (checkpoint.checkpointId === checkpointId) {
            setCreatedLocations(
              createdLocations.filter((loc) => loc.locationId !== checkpoint.locationId)
            );
            if (checkpoint.marker) map.removeLayer(checkpoint.marker);
            return {
              ...checkpoint,
              googleLocation: undefined,
              locationId: undefined,
              checkpointName: undefined,
              marker: undefined,
              longitude: undefined,
              latitude: undefined,
              radius: undefined
            };
          } else return checkpoint;
        })
      );
  };

  return {
    formValues,
    control,
    handleSubmit,
    isGeneratingRouteRef,
    isOpenSlidingPaneEditRoute,
    distanceRoute,
    paths,
    checkpoints,
    setCheckpoints,
    createdLocations,
    addCheckpoint,
    editCheckpoint,
    editPath,
    deleteCheckpoint,
    addNewMarkerOnMap,
    addGoogleLocationToCheckpoint,
    addLocationToCheckpoint,
    deleteLocationOfCheckpoint,
    editingRouteRef,
    deleteObjectsOnMap,
    setOpenSlidingPaneEditRoute,
    setEditingRoute,
    setCreatedLocations,
    setPaths,
    setSlidingPaneEditRouteProps,
    setDistanceRoute,
    slidingPaneEditRoutesProps,
    isOpenSlidingPaneForm,
    setOpenSlidingPaneForm,
    slidingPaneFormProps,
    widthSlidingPaneEditRoute,
    errors: formState.errors,
    isGeneratingRoute: isGeneratingRouteRef.current,
    alternateRoutes,
    setAlternateRoutes
  };
};
