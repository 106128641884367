/* -------------------------------------------------------------------------- */
/*                          JobPriorityDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un JobPriorityDropdownInput

import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { getJobPriorityColor } from '../../utils';
import DropdownInput from '../../../../formComponents/DropdownInput';
import Icon from '../../../../../utility/icomoon';

const JobPriorityDropdownInput = (props) => {
  const {
    forceinitialvalue,
    field,
    additionalonchangefunction = (value) => null,
    disabled
  } = props;
  const intl = useIntl();
  const priorities = Array(3)
    .fill(null)
    .map((_, i) => ({
      id: i + 1,
      value: i + 1,
      label: `jobs.priority.${i + 1}`,
      iconName: 'AlertCircle',
      iconColor: getJobPriorityColor(i + 1)
    }));

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  useEffect(() => {
    if (field.value == null || field.value == undefined || field.value.length == 0) {
      field.onChange(forceinitialvalue);
    }
  }, [field.value]);

  useEffect(() => {
    if (forceinitialvalue) {
      onChangeFinal(forceinitialvalue);
    }
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    field.onChange(value);
    if (additionalonchangefunction) {
      additionalonchangefunction(value);
    }
  };
  return (
    <DropdownInput
      {...omit(props, ['forceinitialvalue'])}
      name="jobPriority"
      id={'jobPriority'}
      key={resetValue}
      isDisabled={disabled}
      label={<FormattedMessage id="jobs.priority" />}
      options={priorities.map((priority) => {
        return {
          ...priority,
          icon: <Icon icon={priority.iconName} color={priority.iconColor} />,
          label: <FormattedMessage id={`jobs.priority.${priority.value}`} />,
          label_intl: intl.formatMessage({ id: 'jobs.priority.' + priority.value })
        };
      })}
    />
  );
};

export default JobPriorityDropdownInput;
