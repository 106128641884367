export const SIMS_FETCH_STARTED = 'SIMS_FETCH_STARTED';
export const SIMS_FETCH_COMPLETED = 'SIMS_FETCH_COMPLETED';
export const SIMS_FETCH_FAILED = 'SIMS_FETCH_FAILED';

export const SIMS_PARTIAL_FETCH_STARTED = 'SIMS_PARTIAL_FETCH_STARTED';
export const SIMS_PARTIAL_FETCH_COMPLETED = 'SIMS_PARTIAL_FETCH_COMPLETED';
export const SIMS_PARTIAL_FETCH_FAILED = 'SIMS_PARTIAL_FETCH_FAILED';

export const SIM_ADD_STARTED = 'SIM_ADD_STARTED';
export const SIM_ADD_COMPLETED = 'SIM_ADD_COMPLETED';
export const SIM_ADD_FAILED = 'SIM_ADD_FAILED';

export const SIM_EDIT_STARTED = 'SIM_EDIT_STARTED';
export const SIM_EDIT_COMPLETED = 'SIM_EDIT_COMPLETED';
export const SIM_EDIT_FAILED = 'SIM_EDIT_FAILED';

export const SIM_REMOVE_STARTED = 'SIM_REMOVE_STARTED';
export const SIM_REMOVE_COMPLETED = 'SIM_REMOVE_COMPLETED';
export const SIM_REMOVE_FAILED = 'SIM_REMOVE_FAILED';

export const SIM_SELECTED = 'SIM_SELECTED';
export const SIMS_ALL_SELECTED = 'SIMS_ALL_SELECTED';
export const SIM_DESELECTED = 'SIM_DESELECTED';
export const SIMS_ALL_DESELECTED = 'SIMS_ALL_DESELECTED';
