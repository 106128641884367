import { SaveCategoryDTO } from 'views/screens/vehicleMaintenance/types';
import * as types from 'views/screens/vehicleMaintenance/types/catalogs/categories';

export const startFetchingCategories = (filters = {}) => ({
  type: types.CATEGORIES_FETCH_STARTED,
  payload: filters
});

export const completeFetchingCategories = (entities, order) => ({
  type: types.CATEGORIES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failFetchingCategories = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.CATEGORIES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingCategories = (entityTypeId) => ({
  type: types.CATEGORIES_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});

export const completePartialFetchingCategories = (entities, order) => ({
  type: types.CATEGORIES_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});

export const failPartialFetchingCategories = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CATEGORIES_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingCategory = (category: SaveCategoryDTO) => ({
  type: types.CATEGORY_ADD_STARTED,
  payload: category
});

export const completeAddingCategory = (id: string, oldId: string, category) => ({
  type: types.CATEGORY_ADD_COMPLETED,
  payload: { id, oldId, category }
});

export const failAddingCategory = (oldId, error) => ({
  type: types.CATEGORY_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingCategory = (category: SaveCategoryDTO) => ({
  type: types.CATEGORY_EDIT_STARTED,
  payload: category
});

export const completeEditingCategory = (category) => ({
  type: types.CATEGORY_EDIT_COMPLETED,
  payload: category
});

export const failEditingCategory = (oldCategory, error) => ({
  type: types.CATEGORY_EDIT_FAILED,
  payload: {
    oldCategory,
    error
  }
});

export const startRemovingCategory = (request: { categoryId: number; categoryName: string }) => ({
  type: types.CATEGORY_REMOVE_STARTED,
  payload: {
    ...request
  }
});

export const completeRemovingCategory = (id) => ({
  type: types.CATEGORY_REMOVE_COMPLETED,
  payload: {
    id
  }
});

export const failRemovingCategory = (id, error) => ({
  type: types.CATEGORY_REMOVE_FAILED,
  payload: {
    id,
    error
  }
});
