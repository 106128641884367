import camelcaseKeys from 'camelcase-keys';
import { normalize } from 'normalizr';
import { select, takeEvery } from 'redux-saga/effects';
import { store } from 'redux/storeConfig/store';
import * as entitySelectors from '../../../Entities/reducers';
import * as actions from '../../actions/report';
import * as selectors from '../../reducers/report';
import * as schemas from '../../schemas';
import * as schemasJobByUser from '../../schemas/jobsByUser';
import { getJobsReportListService } from '../../services/jobs';
import * as types from '../../types/report';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchJobsReportList(action) {
  const filters = action.payload;
  getJobsReportListService(filters, {
    successAction: (response) => {
      const indicators = camelcaseKeys(response.data.indicators);
      const jobsList = response.data.jobsList.map((result) => camelcaseKeys(result));
      const {
        entities: { jobs },
        result: orderJobs //order
      } = normalize(jobsList, schemas.jobs); //normalize data to byId and order
      const jobsByUserList = response.data.jobsByUserList.map((result) => camelcaseKeys(result));
      const {
        entities: { users: jobsByUser },
        result: orderJobsByUser //order
      } = normalize(jobsByUserList, schemasJobByUser.users); //normalize data to byId and order
      store.dispatch(
        actions.completeFetchingJobsReport(indicators, jobs, orderJobs, jobsByUser, orderJobsByUser)
      );
    },
    errorAction: (error) => {
      store.dispatch(actions.failFetchingJobsReport(error));
    }
  });
}

export function* watchFetchJobsReportList() {
  yield takeEvery(types.JOBS_REPORT_FETCH_STARTED, fetchJobsReportList);
}
