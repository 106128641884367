import * as types from '../../types/configurations';

export const startFetchingConfigurations = (filters = {}, brandId, modelId) => ({
  type: types.CONFIGURATIONS_FETCH_STARTED,
  payload: { filters, brandId, modelId }
});
export const completeFetchingConfigurations = (entities, order) => ({
  type: types.CONFIGURATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingConfigurations = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.CONFIGURATIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
