/* -------------------------------------------------------------------------- */
/*                          Componente SlidingPaneForm                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un SlidingPaneForm genérico que se utiliza en muchas pantallas de la aplicación.

import { useState } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { ReactSortable } from 'react-sortablejs';
import { Button, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AddButton from 'views/components/AddButton';
import DeleteButton from 'views/components/DeleteButton';
import Table from 'views/components/Table';
import TabsWrapper from 'views/components/TabsWrapper';
import Toolbar from 'views/components/Toolbar';
import DropdownInput from 'views/formComponents/DropdownInput';
import WebtrackCustomInput from 'views/formComponents/WebtrackCustomInput';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as routeSelectors from '../../../../screens/Routes/reducers/routes';
import RouteItem from '../RouteItem';
import SlidingPaneAddAlternateRoute from '../SlidingPaneAddAlternateRoute';

interface Props {
  routeId: any;
  width: any;
  title: any;
  subtitle: any;
  control: any;
  handleSubmit: any;
  formValues: any;
  errors: any;
  initialTab: number;
  confirmText: any;
  isOpenSlidingPane: any;
  closeSlidingPaneForm: any;
  inputs: any[];
  isLoading: boolean;
  hasButtonSubmit: boolean;
  hasButtonCancel: boolean;
  action: any;
  isGeneratingRoute: any;
  distanceRoute: any;
  paths: any[];
  checkpoints: any[];
  setCheckpoints: any;
  createdLocations: any[];
  addCheckpoint: any;
  editCheckpoint: any;
  editPath: any;
  deleteCheckpoint: any;
  addNewMarkerOnMap: any;
  addGoogleLocationToCheckpoint: any;
  addLocationToCheckpoint: any;
  deleteLocationOfCheckpoint: any;
  alternateRoutes: any;
  setAlternateRoutes: any;
}

const columns = [
  {
    fieldName: 'routeId',
    headerText: 'common.id'
  },
  {
    fieldName: 'routeName',
    headerText: 'routes.name'
  },
  {
    fieldName: 'abbreviation',
    headerText: 'routes.abbreviation'
  },
  {
    fieldName: 'distanceFormat',
    headerText: 'routes.distance',
    metric: 'distance',
    type: 'number',
    formatColumn: 'N2'
  },
  {
    fieldName: 'sourceName',
    headerText: 'routes.origin'
  },
  {
    fieldName: 'targetName',
    headerText: 'routes.destination'
  },
  {
    fieldName: 'checkPoints',
    headerText: 'routes.checkpoints'
  }
];

const SlidingPaneEditRoute = (props: Props) => {
  //const [touched, setTouched] = useState(false);
  const {
    routeId,
    width,
    title,
    subtitle,
    confirmText,
    initialTab,
    isOpenSlidingPane,
    closeSlidingPaneForm,
    inputs,
    hasButtonSubmit,
    hasButtonCancel,
    action,
    isGeneratingRoute,
    handleSubmit,
    control,
    formValues,
    errors,
    isLoading,
    distanceRoute,
    paths,
    checkpoints,
    setCheckpoints,
    createdLocations,
    addCheckpoint,
    editCheckpoint,
    editPath,
    deleteCheckpoint,
    addNewMarkerOnMap,
    addGoogleLocationToCheckpoint,
    addLocationToCheckpoint,
    deleteLocationOfCheckpoint,
    alternateRoutes,
    setAlternateRoutes
  } = props;

  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState<any>(initialTab);
  const [selected, changeSelected] = useState<any[any]>([]);
  const [isOpenSlidingPaneAddAlternateRoute, setSlidingPaneAddAlternateRoute] =
    useState<boolean>(false);

  const { routes, authSystems } = useSelector((state) => ({
    routes: routeSelectors.getRoutesList(state),
    authSystems: authSelectors.getAuthSystems(state)
  }));

  useDeepCompareEffect(() => {
    const errorsKeys = Object.keys(errors);
    if (errorsKeys.length > 0) {
      if (
        selectedTab === 0 &&
        errorsKeys.filter((key) => ['abbreviation', 'routeName'].includes(key)).length === 0
      )
        setSelectedTab(1);
      else if (
        selectedTab === 1 &&
        errorsKeys.filter((key) => key.includes('checkpointId')).length === 0
      )
        setSelectedTab(0);
      else if (
        selectedTab === 2 &&
        errorsKeys.filter((key) => ['abbreviation', 'routeName'].includes(key)).length === 0
      )
        setSelectedTab(1);
      else if (
        selectedTab === 2 &&
        errorsKeys.filter((key) => key.includes('checkpointId')).length === 0
      )
        setSelectedTab(0);
      else setSelectedTab(0);
    }
  }, [Object.keys(errors)]);

  const closeSlidingPane = (isSaving) => {
    closeSlidingPaneForm(isSaving);
  };

  return (
    <>
      <SlidingPane
        className="custom-sliding-pane-form"
        overlayClassName={'custom-overlay-sliding-pane-map'}
        isOpen={isOpenSlidingPane}
        title={title}
        subtitle={subtitle}
        width={width}
        onRequestClose={() => closeSlidingPane(false)}
      >
        <TabsWrapper
          initialActiveTab={selectedTab ?? initialTab}
          activeTab={selectedTab}
          setActiveTab={(tabIndex: number) => {
            setSelectedTab(tabIndex);
          }}
          useCardContent={false}
          tabs={[
            {
              tabId: 0,
              title: 'routes.generalInformation'
            },
            {
              tabId: 1,
              icon: 'Route',
              title: 'routes.routeDesign'
            },
            {
              tabId: 2,
              icon: 'Shuffle',
              title: 'routes.alternateRoutes'
            }
          ]}
          tabsContent={[
            {
              tabId: 0,
              component: (
                <Row className={'d-flex justify-content-center'}>
                  {inputs.map((input, i) => (
                    <Col
                      sm="12"
                      className={input.type === 'membership' ? 'pl-0 pr-0' : ''}
                      key={i.toString()}
                    >
                      {input.subtitle && (
                        <h5 className={i === 0 ? 'text-center' : 'mt-2 text-center'}>
                          {input.subtitle.split('\n').map((i, key) => {
                            return key === 0 ? (
                              <span key={'title-' + key}>{i}</span>
                            ) : (
                              <span key={'title-' + key}>
                                <br />
                                {i}
                              </span>
                            );
                          })}
                        </h5>
                      )}
                      <WebtrackCustomInput key={i} control={control} input={input} />
                    </Col>
                  ))}
                </Row>
              )
            },
            {
              tabId: 1,
              component: (
                <Row>
                  {isLoading ? (
                    <Col sm="12">
                      <hr className={'full-width-slide-panel'} />
                      <div className="d-flex justify-content-center mb-2 mt-2">
                        <Spinner color="primary" />
                      </div>
                      <hr className={'full-width-slide-panel'} />
                    </Col>
                  ) : (
                    <Col sm="12">
                      <FormGroup>
                        <hr className={'full-width-slide-panel'} />
                        <Label className="label-information">
                          {intl.formatMessage({ id: 'routes.distance' })}
                        </Label>
                        <Label className="label-value-information-lowercase">
                          {(distanceRoute?.distanceFormat || '0') + ' ' + authSystems['distance']}
                        </Label>
                        <hr className={'full-width-slide-panel'} />
                      </FormGroup>
                    </Col>
                  )}
                  <Col sm="12">
                    <ReactSortable
                      tag="ul"
                      className="list-group"
                      list={checkpoints}
                      setList={setCheckpoints}
                    >
                      {checkpoints.map((item, index) => (
                        <RouteItem
                          key={index}
                          control={control}
                          formValues={formValues}
                          checkpoints={checkpoints}
                          createdLocations={createdLocations}
                          addCheckpoint={addCheckpoint}
                          editCheckpoint={editCheckpoint}
                          editPath={editPath}
                          deleteCheckpoint={deleteCheckpoint}
                          addNewMarkerOnMap={addNewMarkerOnMap}
                          addGoogleLocationToCheckpoint={addGoogleLocationToCheckpoint}
                          addLocationToCheckpoint={addLocationToCheckpoint}
                          deleteLocationOfCheckpoint={deleteLocationOfCheckpoint}
                          item={item}
                          index={index}
                        />
                      ))}
                    </ReactSortable>
                  </Col>
                </Row>
              )
            },
            {
              tabId: 2,
              component: (
                <>
                  <Toolbar
                    buttons={[
                      <AddButton
                        tooltipMessage={'routes.alternateRoute'}
                        handleClick={() => setSlidingPaneAddAlternateRoute(true)}
                      />,
                      <DeleteButton
                        tooltipMessage={'routes.alternateRoute'}
                        isDisabled={selected.length !== 1}
                        handleClick={() => {
                          setAlternateRoutes(
                            alternateRoutes.filter((route) => route.routeId !== selected[0].routeId)
                          );
                        }}
                      />
                    ]}
                  />
                  <Table
                    columns={columns}
                    enablePersistence={false}
                    data={alternateRoutes}
                    selected={selected}
                    changeSelected={changeSelected}
                  />
                </>
              )
            }
          ]}
        />
        <Row className={'footer-slide-pane'}>
          <Col sm="12 d-flex ">
            {hasButtonSubmit && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="primary"
                /*type="submit"*/
                onClick={handleSubmit((values) => {
                  action(values);
                  closeSlidingPane(true);
                })}
              >
                {confirmText || <FormattedMessage id="common.save" />}
              </Button.Ripple>
            )}
            {hasButtonCancel && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="light"
                onClick={() => closeSlidingPane(false)}
              >
                <FormattedMessage id="common.cancel" />
              </Button.Ripple>
            )}
          </Col>
        </Row>
      </SlidingPane>
      <SlidingPaneAddAlternateRoute
        routeId={routeId}
        route={{
          routeId: 'originalRoute',
          routeName: intl.formatMessage({ id: 'routes.originalRoute' }),
          checkpoints: checkpoints.map((chk, i) =>
            i === 0
              ? { ...chk, segmentPath: [] }
              : {
                  ...chk,
                  segmentPath: paths[i - 1]?.polyline?.getLatLngs()?.map((latlng) => ({
                    latitude: latlng.lat,
                    longitude: latlng.lng
                  }))
                }
          )
        }}
        title={intl.formatMessage({ id: 'routes.alternateRoute' })}
        isGeneratingRoute={isGeneratingRoute}
        alternateRoutes={alternateRoutes}
        setAlternateRoutes={setAlternateRoutes}
        membership={formValues?.membership}
        isOpenSlidingPane={isOpenSlidingPaneAddAlternateRoute}
        closeSlidingPane={() => setSlidingPaneAddAlternateRoute(false)}
        confirmText={intl.formatMessage({ id: 'routes.addAlternateRoute' })}
      />
    </>
  );
};

SlidingPaneEditRoute.defaultProps = {
  inputs: [],
  initialTab: 0,
  hasButtonSubmit: true,
  hasButtonCancel: true
};

export default SlidingPaneEditRoute;
