/* -------------------------------------------------------------------------- */
/*                      Componente SlidingPaneUnitImages                     */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Sidebar para subir una imagen.

import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Form, FormGroup, Row, Spinner } from 'reactstrap';
import DeleteButton from 'views/components/DeleteButton';
import ImageUploader from 'views/formComponents/ImageUploader';
import TextInput from 'views/formComponents/TextInput';
import Toolbar from 'views/components/Toolbar';
import { deleteUnitImage, saveUnitImage } from 'views/screens/Units/services/unitImages';
import * as alerts from '../../../../../redux/utils/alerts';
import CheckboxInput from '../../../../formComponents/CheckBoxInput';
import DefaultButton from '../../../../components/DefaultButton';
import MediaGallery from '../../../../components/MediaGallery';
import TitleH4 from '../../../../components/TitleH4';
import * as unitActions from '../../actions';
import * as unitSelectors from '../../reducers';

/* -------------------------------------------------------------------------- */
/*                         SlidingPaneUnitImages                           */
/* -------------------------------------------------------------------------- */

interface Props {
  closeSlidingPane: Function;
  isSlidingPaneOpen: boolean;
  title: string;
  unit: any;
  addImageInitial: boolean;
}
const SlidingPaneUnitImages = (props: Props) => {
  const { closeSlidingPane, isSlidingPaneOpen, title, unit, addImageInitial } = props;
  const { control, handleSubmit } = useForm();
  const intl = useIntl();
  const dispatch = useDispatch();

  const [isLoadingUnitImages, setIsLoadingUnitImages] = useState<Boolean>(false);
  const [activeSlideImage, setActiveSlideImage] = useState<number>(0);

  const { isFetching } = useSelector((state) => ({
    isFetching: unitSelectors.isFetchingUnitInformation(state)
  }));
  useEffect(() => {
    if (!isFetching) setIsLoadingUnitImages(false);
  }, [isFetching]);

  //addImage to use slide to add image instead of editing
  const [addImage, setAddImage] = useState<boolean>(addImageInitial);
  useEffect(() => {
    setAddImage(addImageInitial);
  }, [addImageInitial]);
  const [uploadImage, setUploadImage] = useState({
    imageName: '',
    imageUrl: ''
  });

  //function to save an image on unitimages table
  const saveUnitImageAsync = async (values) => {
    setIsLoadingUnitImages(true);
    const defaultImageValues = !addImage ? unit.images[activeSlideImage] : {};
    const unitImageSave = {
      unitId: unit.unitId,
      ...defaultImageValues,
      ...values,
      imageUrl: !addImage
        ? defaultImageValues.imageUrl
        : values.imageTypeId == 1
        ? values.fileName
        : values.imageUrl,
      imageName: values.imageName,
      isDefault: values.isDefault
    };
    const response = await saveUnitImage(unitImageSave);
    if (response?.success) dispatch(unitActions.startFetchingUnitInformation(unitImageSave.unitId));
  };

  //function to delete unit image in unitimages table
  const deleteUnitImageAsync = async () => {
    const unitImageDelete = {
      unitId: unit.unitId,
      ...unit.images[activeSlideImage]
    };
    alerts.showDeleteConfirmationMessage({
      action: async () => {
        setIsLoadingUnitImages(true);
        const response = await deleteUnitImage(unitImageDelete);
        if (response?.success)
          dispatch(unitActions.startFetchingUnitInformation(unitImageDelete.unitId));
      },
      objectType: intl.formatMessage({ id: 'units.unitImage' }),
      objectName: unitImageDelete.imageName
    });
  };

  return (
    <SlidingPane
      className="custom-sliding-pane"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isSlidingPaneOpen}
      title={title}
      width="40rem"
      onRequestClose={() => {
        if (closeSlidingPane) closeSlidingPane();
      }}
    >
      {unit.images ? (
        <>
          <TitleH4
            textId={!addImage ? 'units.unitImages' : 'units.newImage'}
            center={true}
            textTransform={'uppercase'}
          />
          {/* Loading locaition images show spinner */}
          {isLoadingUnitImages && (
            <div className="d-flex justify-content-center my-1">
              <Spinner color="primary" />
            </div>
          )}

          {/* Toolbar for editing images */}
          {!isLoadingUnitImages && (
            <>
              {!addImage && (
                <Toolbar
                  buttons={[
                    <DefaultButton
                      id={'unitImagesAddButton'}
                      icon={'Upload'}
                      isDisabled={false}
                      handleClick={() => {
                        setAddImage(true);
                      }}
                      tooltipMessage={intl.formatMessage({ id: 'units.addImage' })}
                    />,
                    unit.images.length > 0 && (
                      <DeleteButton
                        tooltipMessage={'units.image'}
                        isDisabled={false}
                        handleClick={() => {
                          deleteUnitImageAsync();
                        }}
                      />
                    )
                  ]}
                />
              )}
              {/* unitimagesgallery to edit and its respective form  */}
              {unit.images.length > 0 && !addImage && (
                <MediaGallery
                  isRtl={false}
                  images={unit.images.map((image) => ({
                    ...image,
                    fullImageUrl: image?.imageUrl
                  }))}
                  autoplay={false}
                  onSlideChange={(slideId) => {
                    setActiveSlideImage(slideId);
                  }}
                  navigation={true}
                  pagination={true}
                  useThumbs={true}
                  className={'default-width-image'}
                  classNameThumbs={'default-width-image'}
                  heightThumbs={'10%'}
                />
              )}

              {unit.images.length > 0 && !addImage && (
                <Form>
                  <br />
                  <Row>
                    <Col sm={'12'}>
                      <Controller
                        control={control}
                        name="imageName"
                        render={({ field, fieldState }) => (
                          <TextInput
                            field={field}
                            fieldstate={fieldState}
                            id={'imageNameInput'}
                            required={false}
                            forceinitialvalue={
                              !addImage ? unit.images[activeSlideImage].imageName : ''
                            }
                            label={<FormattedMessage id="common.imageName" />}
                          />
                        )}
                      />
                    </Col>
                    <Col sm={'12'}>
                      <Controller
                        control={control}
                        name="isDefault"
                        render={({ field }) => (
                          <CheckboxInput
                            field={field}
                            forceinitialvalue={
                              !addImage ? unit.images[activeSlideImage].isDefault == 1 : false
                            }
                            label={intl.formatMessage({ id: 'common.imageDefault' })}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col sm="12">
                      <FormGroup className="d-flex mb-0">
                        <Button.Ripple
                          className="mr-1"
                          color="primary"
                          /*type="submit"*/
                          onClick={handleSubmit((values) => {
                            saveUnitImageAsync(values);
                          })}
                        >
                          <FormattedMessage id="common.save" />
                        </Button.Ripple>
                        <Button.Ripple
                          color="light"
                          onClick={() => {
                            if (closeSlidingPane) closeSlidingPane();
                          }}
                        >
                          <FormattedMessage id="common.cancel" />
                        </Button.Ripple>
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              )}

              {/* Message to show if there are no images to edit  */}
              {unit.images.length == 0 && !isLoadingUnitImages && !addImage && (
                <div className="misc-inner p-2 p-sm-3">
                  <div className="w-100 text-center">
                    <h2 className="mb-1">
                      <FormattedMessage id="units.noImages"></FormattedMessage>
                    </h2>
                    <p className="mb-2">
                      <FormattedMessage id="units.startUploadingImages"></FormattedMessage>
                    </p>
                    <Button.Ripple
                      color="primary"
                      className="btn-sm-block mb-1"
                      onClick={() => {
                        setAddImage(true);
                      }}
                    >
                      <FormattedMessage id="units.startUploadingImagesButton"></FormattedMessage>
                    </Button.Ripple>
                  </div>
                </div>
              )}

              {/* Image Uploader if addImage is in true */}
              {addImage && (
                <ImageUploader
                  cropShape={'rectangle'} //cropShape
                  showGrid={false} //grid in image
                  aspect={4 / 3} // aspect in image
                  onSuccessChangeFunction={(values) => {}} //Function to do after a succesfull upload
                  onFailureChangeFunction={() => {}} //Function to do after a failure upload
                  otherValues={{ unitId: unit.unitId }}
                  inputs={[
                    {
                      type: 'text',
                      name: 'imageName',
                      label: intl.formatMessage({ id: 'common.imageName' }),
                      required: true,
                      initialValue: ''
                    },
                    {
                      name: 'isDefault',
                      forceinitialvalue: false,
                      label: intl.formatMessage({ id: 'common.imageDefault' }),
                      type: 'checkbox'
                    }
                  ]}
                  onChangeImage={(value) => setUploadImage(value)}
                  functionAfterUpload={(values) => {
                    saveUnitImageAsync(values);
                  }}
                  closeSlidepanel={() => {
                    if (addImageInitial) {
                      closeSlidingPane();
                    } else {
                      setAddImage(false);
                    }
                  }}
                  filesPath={'/gps/'}
                />
              )}
            </>
          )}
          <br />
          {addImage && !addImageInitial && (
            <Row>
              <Col sm="12">
                <FormGroup className="d-flex mb-0">
                  <Button.Ripple
                    color="light"
                    onClick={() => {
                      setAddImage(false);
                    }}
                  >
                    <FormattedMessage id="units.goBackToImages" />
                  </Button.Ripple>
                </FormGroup>
              </Col>
            </Row>
          )}
        </>
      ) : (
        <></>
      )}
    </SlidingPane>
  );
};
SlidingPaneUnitImages.defaultProps = {
  closeSlidingPane: () => null,
  isSlidingPaneOpen: false,
  title: '',
  unit: {},
  submitting: false,
  addImageInitial: false
};

export default SlidingPaneUnitImages;
