export const COUNTRIES_FETCH_STARTED = 'COUNTRIES_FETCH_STARTED';
export const COUNTRIES_FETCH_COMPLETED = 'COUNTRIES_FETCH_COMPLETED';
export const COUNTRIES_FETCH_FAILED = 'COUNTRIES_FETCH_FAILED';

export const COUNTRIES_PARTIAL_FETCH_STARTED = 'COUNTRIES_PARTIAL_FETCH_STARTED';
export const COUNTRIES_PARTIAL_FETCH_COMPLETED = 'COUNTRIES_PARTIAL_FETCH_COMPLETED';
export const COUNTRIES_PARTIAL_FETCH_FAILED = 'COUNTRIES_PARTIAL_FETCH_FAILED';

export const COUNTRY_FETCH_STARTED = 'COUNTRY_FETCH_STARTED';
export const COUNTRY_FETCH_COMPLETED = 'COUNTRY_FETCH_COMPLETED';
export const COUNTRY_FETCH_FAILED = 'COUNTRY_FETCH_FAILED';

export const COUNTRY_SELECTED = 'COUNTRY_SELECTED';
export const COUNTRIES_ALL_SELECTED = 'COUNTRIES_ALL_SELECTED';
export const COUNTRY_DESELECTED = 'COUNTRY_DESELECTED';
export const COUNTRIES_ALL_DESELECTED = 'COUNTRIES_ALL_DESELECTED';
