/* -------------------------------------------------------------------------- */
/*                            Componente TextEditorInput                            */
/* -------------------------------------------------------------------------- */
// Este componente contiene un TextEditorInput genérico que se utiliza en muchas pantallas de la aplicación.
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, Modifier, SelectionState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import React, { useState, useEffect } from 'react';
import {
  Label,
  Input,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledButtonDropdown,
  Badge
} from 'reactstrap';
import _ from 'lodash';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import Icon from 'utility/icomoon';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import invoke from 'lodash/get';
import first from 'lodash/first';
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity';

interface Wildcard {
  id: number;
  key: string;
  label: string;
}

interface WildcardGroup {
  groupId: number;
  groupName: string;
  wildcards: Wildcard[];
}

interface CustomDecorator {
  strategy: any;
  component: any;
}

interface Props {
  id: any;
  field: any;
  fieldstate: any;
  required?: any;
  label?: any;
  type?: any;
  errorMessage?: any;
  forceinitialvalue?: string;
  disabled?: boolean;
  additionalonchangefunction?: Function;
  customDecorators?: CustomDecorator[];
  wildcardGroups?: WildcardGroup[];
}

const TextEditorInput = (props: Props) => {
  const {
    id,
    label,
    required,
    field,
    fieldstate,
    errorMessage,
    forceinitialvalue,
    additionalonchangefunction,
    customDecorators = [],
    wildcardGroups = []
  } = props;

  const intl = useIntl();
  const wildcards =
    wildcardGroups.length > 0
      ? flatten(wildcardGroups.map((wildcardGroup) => wildcardGroup.wildcards))
      : [];

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(
        htmlToDraft(field.value ?? '').contentBlocks,
        htmlToDraft(field.value ?? '').entityMap
      )
    )
  );

  useEffect(() => {
    if (forceinitialvalue) {
      onChangeFinal(forceinitialvalue);
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(forceinitialvalue ?? '').contentBlocks,
            htmlToDraft(forceinitialvalue ?? '').entityMap
          )
        )
      );
    }
  }, [forceinitialvalue]);

  const onChangeFinal = (value) => {
    if (value) {
      field.onChange(value);
      if (additionalonchangefunction) {
        additionalonchangefunction(value);
      }
    }
  };

  const wildcardStrategy = (contentBlock, callback) => {
    const wildcardsRegex = new RegExp(wildcards.map((wildcard) => wildcard.key).join('|'), 'gi');
    const text = contentBlock.getText();
    let matchArr, start;
    while ((matchArr = wildcardsRegex.exec(text)) !== null) {
      start = matchArr.index;
      callback(start, start + matchArr[0].length);
    }
  };

  const WildcardBadge = (props) => {
    return wildcards.filter((wildcard) => wildcard.key === props.children[0].props.text).length >
      0 ? (
      <span style={{ color: 'red' }}>
        <Badge
          pill
          style={{
            display: 'inline-block',
            fontSize: '13px',
            backgroundColor: '#B3B8B4',
            color: 'black'
          }}
        >
          {props.children}
          <Icon
            icon={'X'}
            size={12}
            style={{ cursor: 'pointer', backgroundColor: 'red', marginLeft: '5px' }}
            onClick={(event) => removeWildcardKey(event, props)}
          />
        </Badge>
      </span>
    ) : (
      ''
    );
  };

  const insertWildcardKey = (key) => {
    const contentState = editorState.getCurrentContent();
    const targetRange = editorState.getSelection();
    const newContentState = Modifier.insertText(contentState, targetRange, key);
    setEditorState(EditorState.push(editorState, newContentState));
    EditorState.moveSelectionToEnd(editorState);
  };

  const removeWildcardKey = (event, wildcardProps) => {
    invoke(event, 'stopPropagation');

    const editorStateAux = EditorState.createWithContent(wildcardProps.contentState);
    const startIndex = wildcardProps.start;
    const endIndex = wildcardProps.end;
    const htmlValue = draftToHtml(convertToRaw(editorStateAux.getCurrentContent()));
    const newContent = `${htmlValue
      .replace('<p>', '')
      .replace('</p>', '')
      .substr(0, startIndex)}${htmlValue.replace('<p>', '').replace('</p>', '').substr(endIndex)}`;
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          htmlToDraft(newContent).contentBlocks,
          htmlToDraft(newContent).entityMap
        )
      )
    );
  };

  const decorators =
    wildcards.length > 0
      ? [
          {
            strategy: wildcardStrategy,
            component: WildcardBadge
          }
        ]
      : [];

  return (
    <FormGroup>
      <Label className="form-label" for={id}>
        {label}
        {required && <span className="required">&nbsp;*</span>}
      </Label>
      {wildcardGroups.length > 0 &&
        wildcardGroups.map((wildcardGroup) => (
          <UncontrolledButtonDropdown
            style={{ marginTop: 0, marginRight: '5px', marginBottom: '5px' }}
            key={`WildcardGroup_${wildcardGroup.groupId}`}
          >
            <DropdownToggle color="primary" caret style={{ marginTop: 0 }}>
              {wildcardGroup.groupName}
            </DropdownToggle>
            <DropdownMenu>
              {wildcardGroup.wildcards.map((wildcard) => (
                <DropdownItem
                  style={{ marginTop: 0 }}
                  key={`WildcardGroup_${wildcardGroup.groupId}_Wildcard_$${wildcard.id}`}
                  onClick={(event) => insertWildcardKey(wildcard.key)}
                >
                  {wildcard.label}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ))}
      <InputGroup>
        <Editor
          customDecorators={
            customDecorators.length > 0 ? [...decorators, ...customDecorators] : decorators
          }
          editorState={editorState}
          // editorClassName="rounded-0"
          // toolbarClassName="rounded-0"
          wrapperStyle={{
            width: '100%',
            padding: '0.438rem 1rem',
            backgroundColor: '#ffffff',
            backgroundClip: 'padding-box',
            border: '1px solid #d8d6de',
            borderRadius: '0.357rem'
          }}
          toolbarStyle={{
            width: '100%',
            padding: '0.438rem 1rem',
            backgroundColor: '#ffffff',
            backgroundClip: 'padding-box',
            border: '1px solid #d8d6de',
            borderRadius: '0.357rem'
          }}
          editorStyle={{ border: 'none', overflow: 'hidden' }}
          localization={{
            locale: appIntl().locale
          }}
          // wrapperClassName="toolbar-bottom"
          // toolbar={{
          //   options: ['inline', 'textAlign'],
          //   inline: {
          //     inDropdown: false,
          //     options: ['bold', 'italic', 'underline']
          //   }
          // }}
          onEditorStateChange={(newEditorState) => {
            setEditorState(newEditorState);
          }}
          onBlur={() => {
            const htmlValue = draftToHtml(convertToRaw(editorState.getCurrentContent()));

            onChangeFinal(htmlValue);
          }}
          preserveSelectionOnBlur={true}
        />
      </InputGroup>
      {fieldstate.error && (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      )}
    </FormGroup>
  );
};

TextEditorInput.defaultProps = {};

export default TextEditorInput;
