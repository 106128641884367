import { call, takeEvery, put, select } from 'redux-saga/effects';

import * as actions from '../../actions/workplansExecutionLocations';
import * as types from '../../types/workplansExecutionLocations';
import * as selectors from '../../reducers/workplansExecutionLocations';
import camelcaseKeys from 'camelcase-keys';
import {
  saveWorkplanExecutionLocationService,
  deleteWorkplanExecutionLocationService
} from '../../services/workplans';
import { store } from 'redux/storeConfig/store';

const getWorkplanExecutionLocationId = (workplanExecutionLocation) =>
  `${workplanExecutionLocation.visitId}-${workplanExecutionLocation.locationId}`;

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addWorkplanExecutionLocation(action) {
  const workplanExecutionLocation = action.payload;

  saveWorkplanExecutionLocationService(workplanExecutionLocation, {
    successAction: async (response) => {
      const addedWorkplanExecutionLocation = camelcaseKeys(response.data);
      store.dispatch(
        actions.completeAddingWorkplanExecutionLocation(
          workplanExecutionLocation.workplanExecutionLocationId,
          {
            workplanExecutionLocationId: getWorkplanExecutionLocationId(
              addedWorkplanExecutionLocation
            ),
            ...addedWorkplanExecutionLocation
          }
        )
      );
    },
    errorAction: async (error) => {
      store.dispatch(
        actions.failAddingWorkplanExecutionLocation(workplanExecutionLocation.workplanId, error)
      );
    }
  });
}

export function* watchAddWorkplanExecutionLocationsStarted() {
  yield takeEvery(types.WORKPLANS_EXECUTION_LOCATION_ADD_STARTED, addWorkplanExecutionLocation);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editWorkplanExecutionLocation(action) {
  const workplanExecutionLocation = action.payload;
  const { oldWorkplanExecutionLocation } = yield select(
    selectors.getLocation,
    workplanExecutionLocation.workplanExecutionLocationId
  );

  saveWorkplanExecutionLocationService(workplanExecutionLocation, {
    successAction: async (response) => {
      const editedWorkplanExecutionLocation = camelcaseKeys(response.data);
      store.dispatch(
        actions.completeEditingWorkplanExecutionLocation({
          workplanExecutionLocationId: getWorkplanExecutionLocationId(
            editedWorkplanExecutionLocation
          ),
          ...editedWorkplanExecutionLocation
        })
      );
    },
    errorAction: async (error) => {
      store.dispatch(
        actions.failEditingWorkplanExecutionLocation(oldWorkplanExecutionLocation, error)
      );
    }
  });
}

export function* watchEditWorkplanExecutionLocationsStarted() {
  yield takeEvery(types.WORKPLANS_EXECUTION_LOCATION_EDIT_STARTED, editWorkplanExecutionLocation);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeWorkplanExecutionLocation(action) {
  const workplanExecutionLocation = action.payload;
  const workplanExecutionLocationDeleted = yield select(
    selectors.getLocation,
    getWorkplanExecutionLocationId(workplanExecutionLocation)
  );

  deleteWorkplanExecutionLocationService(
    { ...workplanExecutionLocation, locationName: workplanExecutionLocationDeleted?.locationName },
    {
      successAction: async (response) => {
        store.dispatch(
          actions.completeRemovingWorkplanExecutionLocation(
            getWorkplanExecutionLocationId(workplanExecutionLocation)
          )
        );
      },
      errorAction: async (error) => {
        store.dispatch(
          actions.failRemovingWorkplanExecutionLocation(
            getWorkplanExecutionLocationId(workplanExecutionLocation),
            error
          )
        );
      }
    }
  );
}

export function* watchRemoveWorkplanExecutionLocationStarted() {
  yield takeEvery(
    types.WORKPLANS_EXECUTION_LOCATION_REMOVE_STARTED,
    removeWorkplanExecutionLocation
  );
}
