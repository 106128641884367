// ** Get Bookmarks Array from @fakeDB
import * as navbarTypes from "../../types/navbar";

export const completeFetchingBookmarks = (bookmarks, suggestions) => ({
  type: navbarTypes.GET_BOOKMARKS_FETCH_COMPLETED,
  payload: {
    bookmarks: bookmarks,
    suggestions: suggestions,
  },
});

export const startFetchingBookmarks = () => ({
  type: navbarTypes.GET_BOOKMARKS_FETCH_STARTED,
  payload: {},
});

export const failFetchingBookmarks = () => ({
  type: navbarTypes.GET_BOOKMARKS_FETCH_FAILED,
  payload: {},
});

// ** Update & Get Updated Bookmarks Array
export const updateBookmarkedStarted = (id) => ({
  type: navbarTypes.UPDATE_BOOKMARKED_STARTED,
  payload: { id },
});

// ** Update & Get Updated Bookmarks Array
export const updateBookmarkedCompleted = () => ({
  type: navbarTypes.UPDATE_BOOKMARKED_COMPLETED,
  payload: {},
});

// ** Update & Get Updated Bookmarks Array
export const updateBookmarkedFailed = () => ({
  type: navbarTypes.UPDATE_BOOKMARKED_FAILED,
  payload: {},
});

// ** Handle Bookmarks & Main Search Queries
export const handleSearchQuery = (val) => ({
  type: navbarTypes.HANDLE_SEARCH_QUERY,
  payload: val,
});
