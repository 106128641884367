// @ts-nocheck
// import Control from 'react-leaflet-control';
import '@geoman-io/leaflet-geoman-free';
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet-toolbar';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Eye, EyeOff, MapPin, Plus, X } from 'react-feather';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMap } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { appIntl } from 'utility/context/IntlGlobalProvider';
import { v4 as uuidv4 } from 'uuid';
import SlidingPaneEditLocation from 'views/screens/Locations/views/SlidingPaneEditLocation';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as locationClassesSelectors from '../../../../../redux/reducers/static-catalogs/location-classes';
import * as locationActions from '../../../../../views/screens/Locations/actions';
import locationPermissions from '../../../../../views/screens/Locations/permissions';
import SlidingPaneForm from '../../../../formComponents/SlidingPaneForm';

export interface LocationsToolbarOptions {
  useToolbar: boolean;
  showLocations?: bool;
  setShowLocations?: Function;
  listenMapControlChanges?: any[];
  position?: 'topleft' | 'topright' | 'bottomleft' | 'bottomright';
}

/* -------------------------------------------------------------------------- */
/*                           ToolbarLocationsControl                          */
/* -------------------------------------------------------------------------- */
const ToolbarLocationsControl = (props: LocationsToolbarOptions) => {
  const { showLocations, setShowLocations, position = 'topleft', listenMapControlChanges } = props;
  const context = useLeafletContext();
  const intl = useIntl();
  const { store, parsers } = useSelector((state) => ({
    store: state,
    parsers: authSelectors.getParsers(state)
  }));
  const map = useMap();
  const dispatch = useDispatch();

  //States to create new locations
  const [newMarkers, setNewMarkers] = useState<any[]>([]);
  const newMarkersRef = React.useRef(newMarkers);
  const [isOpenSlidingPaneForm, setOpenSlidingPaneForm] = useState(false);
  const isOpenSlidingPaneFormRef = React.useRef(isOpenSlidingPaneForm);
  const [slidingPaneProps, setSlidingPaneProps] = useState<any>({ inputs: [] });
  const slidingPanePropsRef = React.useRef(slidingPaneProps);
  const [editingLocation, setEditingLocation] = useState<any>(undefined);
  const editingLocationRef = React.useRef(editingLocation);

  useEffect(() => {
    editingLocationRef.current = editingLocation;
    if (editingLocation !== undefined) {
      editLocation();
    }
  }, [editingLocation]);

  useEffect(() => {
    if (editingLocation?.location?.locationClassIconName) {
      editingLocationRef.current.marker._icon.innerHTML = `<div class='marker-pin' style='background-color:$marker-color'></div>
      <i class='icon-${editingLocation?.location?.locationClassIconName}' style='width: 20px;font-size: 20px;color:white;'></i>`;
    }
  }, [editingLocation?.location?.locationClassIconName]);

  useEffect(() => {
    if (editingLocation?.location?.latitude && editingLocation?.location?.longitude) {
      var latLng = L.latLng(
        editingLocation?.location?.latitude,
        editingLocation?.location?.longitude
      );
      editingLocationRef.current.marker.setLatLng(latLng);
      zoomMapToLocation(latLng);
    }
  }, [editingLocation?.location?.latitude, editingLocation?.location?.longitude]);

  useEffect(() => {
    setTimeout(() => {
      try {
        editingLocationRef.current.marker.bindPopup(
          editingLocation?.location?.locationName
            ? editingLocation?.location?.locationName
            : appIntl().formatMessage({ id: 'locations.new' })
        );
        editingLocationRef.current.marker.openPopup();
      } catch {}
    }, 100);
  }, [editingLocation?.location?.locationName]);

  useEffect(() => {
    isOpenSlidingPaneFormRef.current = isOpenSlidingPaneForm;
  }, [isOpenSlidingPaneForm]);

  useEffect(() => {
    slidingPanePropsRef.current = slidingPaneProps;
  }, [slidingPaneProps]);

  useEffect(() => {
    newMarkersRef.current = newMarkers;
  }, [newMarkers]);

  const zoomMapToLocation = (latLng) => {
    const maxZoom = Math.max(15, map.getZoom());
    setTimeout(function () {
      var ll;
      if (latLng instanceof L.LatLng) {
        ll = latLng;
      } else {
        ll = L.latLng(latLng);
      }
      map.flyToBounds([ll], {
        paddingBottomRight: [550, 0],
        paddingTopLeft: [0, 70],
        maxZoom,
        animate: 15 > map.getZoom()
      });
    }, 500);
  };

  useEffect(() => {
    const customTranslation = {
      tooltips: {
        placeMarker: intl.formatMessage({ id: 'locations.addMarker' })
      }
    };
    map.pm.setLang('customTranslation', customTranslation, intl.locale);
  }, [intl.locale]);

  const initialLocationClassIconName = 'Marker1';
  const editLocation = () => {
    const marker = editingLocationRef.current.marker;

    newMarkersRef.current.forEach((m) => {
      m.pm.disable({});
    });

    setOpenSlidingPaneForm(true);

    setTimeout(() => {
      try {
        marker.pm.enable({});
      } catch {}
    }, 100);
    setTimeout(() => {
      try {
        marker.bindPopup(
          marker.options.locationName
            ? marker.options.locationName
            : appIntl().formatMessage({ id: 'locations.new' })
        );
        marker.openPopup();
      } catch {}
    }, 100);
  };

  useDeepCompareEffect(() => {
    const container = context.layerContainer || context.map;
    try {
      var ImmediateSubAction = L.Toolbar2.Action.extend({
        initialize: function (map, myAction) {
          this.map = map;
          this.myAction = myAction;
          L.Toolbar2.Action.prototype.initialize.call(this);
        },
        addHooks: function () {
          this.myAction.disable();
        }
      });

      var createNewLocationAction = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            // html: '<i class="fa fa-plus" uib-tooltip="{{\'views.map.sidebar.Add reference\' | translate}}" tooltip-append-to-body="true" tooltip-placement="right"></i>'
            tooltip: intl.formatMessage({ id: 'map.locationsToolbar.tooltipAddLocation' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <Plus size={14} />
              </>
            )
          }
        },
        addHooks: () => {
          container.pm.enableDraw('Marker', {
            continueDrawing: false, //Disable After Create
            markerEditable: true,
            markerStyle: {
              icon: L.divIcon({
                className: 'custom-div-icon',
                html: `<div  class='marker-pin' style='background-color:${
                  // location.locationClassDefaultColor == null?
                  '$marker-color'
                  // : location.locationClassDefaultColor
                }'></div><i class='icon-${
                  // location.locationClassIconName ??
                  initialLocationClassIconName
                }' style='width: 20px;
              font-size: 20px;color:white;'></i>`,
                iconSize: [30, 42],
                iconAnchor: [15, 42],
                popupAnchor: [0, -35]
              })
            }
          });

          // listen to when drawing elem gets created

          container.on('pm:create', function (e) {
            // setEditingLocation(undefined);
            if (e.shape == 'Marker' && e.marker.options.locationId == null) {
              setOpenSlidingPaneForm(false);
              setSlidingPaneProps({ inputs: [] });
              let marker = e.marker;
              marker.options = {
                ...marker.options,
                locationId: uuidv4(),
                locationName: undefined,
                locationAddress: '',
                latitude: marker.getLatLng().lat,
                longitude: marker.getLatLng().lng,
                radius: 50,
                operatorId: null,
                organizationId: null,
                divisionId: null,
                subdivisionId: null,
                locationClassIconName: 'Marker1',
                locationClassId: 0,
                privateCode: '',
                isConfirmed: false
              };

              marker.on('click', () => {
                // setEditingLocation(undefined);
                // if (
                //   isOpenSlidingPaneFormRef.current &&
                //   editingLocationRef.current.marker.options.locationId == marker.options.locationId
                // ) {
                // } else {
                setOpenSlidingPaneForm(false);
                setSlidingPaneProps({ inputs: [] });
                setEditingLocation({
                  marker: marker,
                  location: marker.options
                });

                // }
              });

              marker.on('pm:remove', (e) => {
                setOpenSlidingPaneForm(false);
                setSlidingPaneProps({ inputs: [] });
              });

              marker.on('pm:drag', (e) => {
                marker.options = {
                  ...editingLocationRef.current.location,
                  latitude: e.target.getLatLng().lat,
                  longitude: e.target.getLatLng().lng
                };
                // setEditingLocation({
                //   marker: marker,
                //   location: {
                //     ...editingLocationRef.current.location,
                //     latitude: e.target.getLatLng().lat,
                //     longitude: e.target.getLatLng().lng
                //   }
                // });
                // zoomMapToLocation(marker.getLatLng());
              });

              marker.on('pm:dragend', (e) => {
                marker.options = {
                  ...editingLocationRef.current.location,
                  latitude: e.target.getLatLng().lat,
                  longitude: e.target.getLatLng().lng
                };
                setEditingLocation({
                  marker: marker,
                  location: {
                    ...editingLocationRef.current.location,
                    latitude: e.target.getLatLng().lat,
                    longitude: e.target.getLatLng().lng
                  }
                });
                zoomMapToLocation(marker.getLatLng());
              });

              setNewMarkers((prevMarkers) => [...prevMarkers, marker]);
              marker.pm.enable({});
              zoomMapToLocation(marker.getLatLng());
              setEditingLocation({
                marker: marker,
                location: marker.options
              });
            }
          });
        }
      });

      var viewLocations = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            tooltip: showLocations
              ? intl.formatMessage({ id: 'map.locationsToolbar.tooltipHideLocations' })
              : intl.formatMessage({ id: 'map.locationsToolbar.tooltipShowLocations' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>{showLocations ? <EyeOff size={14} /> : <Eye size={14} />}</>
            )
          }
        },
        addHooks: () => {
          if (!showLocations) {
            dispatch(locationActions.showAllLocationsInMap());
          } else {
            dispatch(locationActions.hideAllLocationsInMap());
          }
          setShowLocations(!showLocations);
          setOpenSlidingPaneForm(false);
          newMarkersRef.current.forEach((m) => {
            try {
              m.pm.disable({});
              m.closePopup();
            } catch {}
          });
        }
      });

      var cancelLocation = ImmediateSubAction.extend({
        options: {
          toolbarIcon: {
            // html: '<i class="fa fa-times"></i>'
            tooltip: intl.formatMessage({ id: 'common.tooltipCancel' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <X size={14} />
              </>
            )
          }
        },
        addHooks: function () {
          container.pm.disableDraw('Marker');
          ImmediateSubAction.prototype.addHooks.call(this);
        }
      });

      var locationsOptions = L.Toolbar2.Action.extend({
        options: {
          toolbarIcon: {
            // html: '<i class="fa fa-map-marker" uib-tooltip="{{\'views.map.sidebar.Location\' | translate}}" tooltip-append-to-body="true" tooltip-placement="right"></i>'
            tooltip: intl.formatMessage({ id: 'map.locationsToolbar.tooltipLocations' }),
            html: ReactDOMServer.renderToStaticMarkup(
              <>
                <MapPin size={14} />
              </>
            )
          },

          subToolbar: new L.Toolbar2({
            actions: [
              authSelectors.getAuthUserHasPermissionsEvery(store, [
                locationPermissions.listLocationsOnMap
              ])
                ? viewLocations
                : null,
              authSelectors.getAuthUserHasPermissionsEvery(store, [locationPermissions.addLocation])
                ? createNewLocationAction
                : null,

              cancelLocation
            ].filter((action) => action != null)
          })
        },
        disable: function (e) {
          L.Toolbar2.Action.prototype.disable.call(this);
        }
      });

      L.Toolbar2.LocationsToolbar = L.Toolbar2.Control.extend({
        options: {
          actions: [locationsOptions]
        }
      });

      var locationsToolbar = new L.Toolbar2.LocationsToolbar({ position: position });
      locationsToolbar.addTo(container);

      return () => {
        locationsToolbar.remove();
      };
    } catch {}
  }, [listenMapControlChanges]);
  return (
    <>
      <SlidingPaneEditLocation
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneForm}
        closeSlidingPane={() => {
          setOpenSlidingPaneForm(false);
          editingLocationRef.current.marker.pm.disable({});
          newMarkersRef.current.forEach((m, i) => {
            try {
              m.pm.disable({});
              m.closePopup();
            } catch {}
          });
        }}
        isInMap
        title={
          editingLocationRef.current &&
          editingLocationRef.current.location &&
          Number.isInteger(editingLocationRef.current.location.locationId)
            ? editingLocationRef.current.location.locationName
            : intl.formatMessage({ id: 'locations.addLocation' })
        }
        editingLocation={editingLocationRef.current?.location}
        setEditingLocation={(location) =>
          setEditingLocation({ marker: editingLocationRef.current.marker, location })
        }
        onClose={() => {
          map.removeLayer(editingLocationRef.current.marker);
          setEditingLocation(undefined);
        }}
        action={(values) => {
          map.removeLayer(editingLocationRef.current.marker);
          dispatch(
            locationActions.startAddingLocation({
              ...values,
              ...values.membership,
              radius: parsers.convertAuthUserDistance2SystemToMeters(values.radius),
              isShownInMap: true,
              enableDispatch: 0,
              dispatchNotificationFreq: 0,
              dispatchEmails: null,
              dispatchCompletionType: 0,
              locationTypeId: 0,
              locationId: uuidv4()
            })
          );
          setEditingLocation(undefined);
        }}
      />
    </>
  );
};

ToolbarLocationsControl.defaultProps = {
  position: 'topleft',
  showLocations: false,
  setShowLocation: () => null,
  listenMapControlChanges: []
};

export default ToolbarLocationsControl;
