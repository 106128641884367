import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import * as actions from '../actions';
import * as types from '../types';
import * as selectors from '../reducers';
import * as schemas from '../schemas';
import * as entitySelectors from '../../Entities/reducers';
import * as unitSelectors from 'views/screens/Units/reducers';
import * as authSelectors from '../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import {
  getDriversListService,
  saveDriverService,
  deleteDriverService,
  assignDriversMassivelyService
} from '../services/drivers';
import { store } from 'redux/storeConfig/store';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchDriversList(action) {
  const { operatorId: filterOperatorId, organizationId: filterOrganizationId } = action.payload;
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getDriversListService(
    { operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { drivers },
          result //order
        } = normalize(resultData, schemas.drivers); //normalize data to byId and order
        store.dispatch(
          actions.completeFetchingDrivers(drivers, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingDrivers(error));
      }
    }
  );
}

export function* watchFetchDriversList() {
  yield takeEvery(types.DRIVERS_FETCH_STARTED, fetchDriversList);
}

/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchDriversList(action) {
  const {
    entityTypeId,
    operatorId: filterOperatorId,
    organizationId: filterOrganizationId
  } = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  const { operatorId, organizationId } = yield select(authSelectors.getAuthUserMembershipFilters, {
    filterOperatorId,
    filterOrganizationId
  });

  getDriversListService(
    { userFetchDate: date, operatorId, organizationId },
    {
      successAction: (response) => {
        const resultData = response.data.map((result) => camelcaseKeys(result));
        const {
          entities: { drivers },
          result //order
        } = normalize(resultData, schemas.drivers); //normalize data to byId and order
        store.dispatch(
          actions.completePartialFetchingDrivers(drivers, result, operatorId, organizationId)
        );
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingDrivers(error));
      }
    }
  );
}

export function* watchPartialFetchDriversList() {
  yield takeEvery(types.DRIVERS_PARTIAL_FETCH_STARTED, partialFetchDriversList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addDriver(action) {
  const driver = action.payload;
  const person = action.person;
  saveDriverService(
    { ...driver, driverId: null },
    {
      successAction: (response) => {
        const addedDriver = camelcaseKeys(response?.data[0]);
        store.dispatch(actions.completeAddingDriver(driver.driverId, addedDriver));
      },
      errorAction: (error) => {
        store.dispatch(actions.failAddingDriver(driver.driverId, error));
      }
    },
    person
  );
}

export function* watchAddDriversStarted() {
  yield takeEvery(types.DRIVER_ADD_STARTED, addDriver);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editDriver(action) {
  const driver = action.payload;
  const person = action.person;
  const oldDriver = yield select((state) => selectors.getDriver(state, driver.driverId));
  saveDriverService(
    { ...driver },
    {
      successAction: (response) => {
        const editedDriver = camelcaseKeys(response?.data[0]);
        store.dispatch(actions.completeEditingDriver(editedDriver));
      },
      errorAction: (error) => {
        store.dispatch(actions.failEditingDriver(oldDriver, error));
      }
    },
    person
  );
}

export function* watchEditDriversStarted() {
  yield takeEvery(types.DRIVER_EDIT_STARTED, editDriver);
}

/* -------------------------------------------------------------------------- */
/*                        ASSIGN UNASSIGN SENSOR                              */
/* -------------------------------------------------------------------------- */
function* assignSensor(action) {
  const sensor: any = action.payload.sensor;
  const driverId = action.payload.driverId;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/driver/updateSensors`, {
        method: 'POST',
        body: JSON.stringify({ driverId: driverId, sensorId: sensor.sensorId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeDriverAssignSensor(driverId, sensor));
        alerts.showSuccessAssignedMessage({
          object: appIntl().formatMessage({
            id: 'sensors.sensor'
          }),
          name: sensor.sensorName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

function* unassignSensor(action) {
  const sensor: any = action.payload.sensor;
  const driverId = action.payload.driverId;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/driver/deleteSensor`, {
        method: 'POST',
        body: JSON.stringify({ sensorId: sensor.sensorId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeDriverUnassignSensor(driverId, sensor));
        alerts.showSuccessUnassignedMessage({
          object: appIntl().formatMessage({
            id: 'sensors.sensor'
          }),
          name: sensor.sensorName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err?.toString(),
      errorNumber: -1
    };

    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchAssignSensor() {
  yield takeEvery(types.DRIVER_ASSIGN_SENSOR_STARTED, assignSensor);
}

export function* watchUnassignSensor() {
  yield takeEvery(types.DRIVER_UNASSIGN_SENSOR_STARTED, unassignSensor);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeDriver(action) {
  const { driverId } = action.payload;
  const person = action.person;
  const driver = yield select((state) => selectors.getDriver(state, driverId));
  deleteDriverService(
    { ...driver },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingDriver(driverId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingDriver(driverId, error));
      }
    },
    person
  );
}

export function* watchRemoveDriverStarted() {
  yield takeEvery(types.DRIVER_REMOVE_STARTED, removeDriver);
}

/* -------------------------------------------------------------------------- */
/*                                 IMPORT DATA                                */
/* -------------------------------------------------------------------------- */

function* importDriverAndUnitData(action: any) {
  const dataToImport: Array<any> = action.payload;
  if (dataToImport?.length === 0) return;

  function* importDriverAndUnit({ driverId, driverPrivateCode, unitId, unitPrivateCode }) {
    const { driver, unit } = yield select((state) => ({
      driver: selectors.getDriverByIdOrPrivateCode(state, driverId, driverPrivateCode),
      unit: unitSelectors.getUnitByIdOrPrivateCode(state, unitId, unitPrivateCode)
    }));
    return { driver, unit };
  }
  const importedData =
    typeof dataToImport?.map === 'function' ? dataToImport?.map(importDriverAndUnit) : [];
  const newData: Array<any> = [];
  for (let driverAndUnit of importedData) {
    try {
      newData.push(yield driverAndUnit);
    } catch (error) {
      console.warn(error);
    }
  }
  store.dispatch(actions.completeImportDriver(newData));
}

export function* watchStartImportDriverAndUnit() {
  yield takeEvery(types.START_IMPORT_DRIVERS_UNITS_IMPORT, importDriverAndUnitData);
}

/* -------------------------------------------------------------------------- */
/*                            MASSIVE ASSIGNMENT                              */
/* -------------------------------------------------------------------------- */
function* assignDriversToUnits(action: any) {
  const { driversAndUnitsIds, membership } = action.payload;
  // changed data [{driverId, unitId}, {driverId, unitId}] -> 'driverId~unitId^driverId~unitId'
  const data: string = driversAndUnitsIds
    ?.map((d: any) => [d?.driverId, d?.unitId].join('~'))
    .join('^');
  // Validate data is not empty
  if (data?.length < 1) return;

  assignDriversMassivelyService(
    { driversAndUnitsIds: data },
    {
      successAction: (response: any) => {
        const resultData =
          typeof response?.data?.map === 'function'
            ? response?.data?.map((result) => camelcaseKeys(result))
            : undefined;
        store.dispatch(actions.startFetchingDrivers(membership));
        store.dispatch(actions.completeMassiveDriversAssignment(response?.data));
      },
      errorAction: (response: any) => {
        store.dispatch(actions.errorMassiveDriversAssignment(response?.data));
      }
    }
  );
}

export function* watchAssignDriversToUnits() {
  yield takeEvery(types.START_MASSIVE_DRIVERS_ASSIGNMENT, assignDriversToUnits);
}
