import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { v4 as uuidv4 } from 'uuid';
import filter from 'lodash/filter';

import * as operatorSelectors from 'views/screens/Operators/reducers';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import * as types from '../types';
import * as interfaces from 'utility/types';

import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.FORMS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((formId) => {
        newState[formId] = {
          isSelected: false,
          sections: {
            byId: {},
            order: []
          },
          questions: {
            byId: {},
            order: []
          },
          responses: {
            byId: {},
            order: []
          },
          ...entities[formId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.FORMS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((unitId) => {
        newState[unitId] = {
          isSelected: false,
          sections: {
            byId: {},
            order: []
          },
          questions: {
            byId: {},
            order: []
          },
          responses: {
            byId: {},
            order: []
          },
          ...state[unitId],
          ...entities[unitId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.FORM_SECTIONS_FETCH_COMPLETED: {
      const { entities, order, formId } = action.payload;
      const newState = { ...state };
      newState[formId] = {
        ...newState[formId],
        sections: {
          byId: entities,
          order: order
        }
      };
      return newState;
    }

    case types.FORM_QUESTIONS_FETCH_COMPLETED: {
      const { entities, order, formId } = action.payload;
      const newState = { ...state };
      newState[formId] = {
        ...newState[formId],
        questions: {
          byId: entities,
          order: order
        }
      };
      return newState;
    }

    case types.FORM_QUESTIONS_CLEARED: {
      const formId = action.payload;
      const newState = { ...state };
      newState[formId].questions = {
        byId: {},
        order: []
      };
      return newState;
    }

    case types.FORM_EDIT_COMPLETED: {
      const form = action.payload;
      return {
        ...state,
        [form.organizationFormId]: {
          ...omit(state[form.organizationFormId], ['oldForms']),
          ...omit(form, ['oldForms']),
          isConfirmed: true
        }
      };
    }

    case types.FORM_ADD_COMPLETED: {
      const { oldId, form } = action.payload;
      const newState = omit(state, oldId);
      newState[form.organizationFormId] = {
        ...state[oldId],
        ...form,
        isConfirmed: true
      };
      return newState;
    }

    case types.FORM_RESPONSE_FETCH_COMPLETED: {
      const { entities, order, formId } = action.payload;
      const newState = { ...state };
      newState[formId].responses = {
        byId: { ...entities },
        order: order
      };
      return newState;
    }

    case types.FORM_RESPONSES_CLEARED: {
      const formId = action.payload;
      const newState = { ...state };
      newState[formId].responses = {
        byId: {},
        order: []
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.FORM_ADD_STARTED:
    case types.FORM_EXTERNAL_ADD_STARTED: {
      const form = action.payload;
      const newState = { ...state };
      newState[form.organizationFormId] = {
        isSelected: false,
        ...form,
        organizationName: '',
        divisionName: '',
        subdivisionName: '',
        isConfirmed: false
      };
      return newState;
    }
    case types.FORM_EDIT_STARTED:
    case types.FORM_EXTERNAL_EDIT_STARTED: {
      const form = action.payload;
      return {
        ...state,
        [form.organizationFormId]: {
          ...state[form.organizationFormId],
          oldForm: state[form.organizationFormId],
          ...form,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.FORM_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.FORM_EDIT_FAILED: {
      const oldForm = action.payload;
      return {
        ...state,
        [oldForm.organizationFormId]: {
          ...omit(state[oldForm.organizationFormId], ['oldForm']),
          ...oldForm,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.FORM_REMOVE_COMPLETED: {
      const { formId } = action.payload;
      return omit(state, formId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.FORM_SELECTED: {
      const formId = action.payload;
      const newState = {
        ...state,
        [formId]: {
          ...state[formId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.FORM_DESELECTED: {
      const formId = action.payload;
      return {
        ...state,
        [formId]: {
          ...state[formId],
          isSelected: false
        }
      };
    }

    case types.FORMS_ALL_SELECTED: {
      const formIds = action.payload;
      const newState = { ...state };
      if (formIds.length == 0) {
        forEach(state, (form: any, formId) => {
          newState[formId] = {
            ...form,
            isSelected: true
          };
        });
      } else {
        formIds.forEach((formId) => {
          newState[formId] = {
            ...state[formId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.FORMS_ALL_DESELECTED: {
      const formIds = action.payload;
      const newState = { ...state };
      if (formIds.length == 0) {
        forEach(state, (form: any, formId) => {
          newState[formId] = {
            ...form,
            isSelected: false
          };
        });
      } else {
        formIds.forEach((formId) => {
          newState[formId] = {
            ...state[formId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    case types.FORMS_ALL_QUESTIONS_CLEARED: {
      const formIds = action.payload;
      const newState = { ...state };
      if (formIds.length == 0) {
        forEach(state, (form: any, formId) => {
          newState[formId] = {
            ...form,
            questions: {
              byId: {},
              order: []
            }
          };
        });
      } else {
        formIds.forEach((formId) => {
          newState[formId] = {
            ...state[formId],
            questions: {
              byId: {},
              order: []
            }
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.FORMS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.FORMS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.FORM_ADD_COMPLETED: {
      const { oldId, form } = action.payload;
      return state.map((formId) => (formId === oldId ? form.organizationFormId : formId));
    }

    case types.FORM_REMOVE_COMPLETED: {
      const { formId } = action.payload;
      return state.filter((formIdState) => formIdState !== formId);
    }

    //CASE ADD STARTED
    case types.FORM_ADD_STARTED:
    case types.FORM_EXTERNAL_ADD_STARTED: {
      const form = action.payload;
      return [...state, form.organizationFormId];
    }

    //CASE ADD FAILED
    case types.FORM_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((formIdState) => formIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.FORMS_FETCH_STARTED: {
      return true;
    }
    case types.FORMS_FETCH_COMPLETED:
    case types.FORMS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.FORM_SECTIONS_FETCH_STARTED:
    case types.FORM_QUESTIONS_FETCH_STARTED:
    case types.FORM_RESPONSE_FETCH_STARTED: {
      return true;
    }
    case types.FORM_SECTIONS_FETCH_COMPLETED:
    case types.FORM_QUESTIONS_FETCH_COMPLETED:
    case types.FORM_RESPONSE_FETCH_COMPLETED:
    case types.FORM_SECTIONS_FETCH_FAILED:
    case types.FORM_QUESTIONS_FETCH_FAILED:
    case types.FORM_RESPONSE_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.FORM_ADD_STARTED:
    case types.FORM_EXTERNAL_ADD_STARTED: {
      return true;
    }
    case types.FORM_ADD_COMPLETED:
    case types.FORM_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.FORM_EDIT_STARTED:
    case types.FORM_EXTERNAL_EDIT_STARTED: {
      return true;
    }
    case types.FORM_EDIT_COMPLETED:
    case types.FORM_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.FORM_REMOVE_STARTED: {
      return true;
    }
    case types.FORM_REMOVE_COMPLETED:
    case types.FORM_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.FORMS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.FORMS_FETCH_STARTED:
    case types.FORMS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.FORMS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.FORMS_FETCH_STARTED:
    case types.FORMS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.FORM_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.FORM_ADD_STARTED:
    case types.FORM_EXTERNAL_ADD_STARTED:
    case types.FORM_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.FORM_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.FORM_EDIT_STARTED:
    case types.FORM_EXTERNAL_EDIT_STARTED:
    case types.FORM_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.FORM_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.FORM_REMOVE_STARTED:
    case types.FORM_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const sectionsById = (state: { [id: string]: interfaces.Section } = {}, action) => {
  switch (action.type) {
    case types.FORM_SECTION_ADDED: {
      const { sectionType, position, sectionId } = action.payload;
      const newState = { ...state };
      newState[sectionId] = {
        sectionId: sectionId,
        name: appIntl().formatMessage({ id: 'forms.section' }),
        questions: [],
        rows: sectionType === 0 ? [] : [{ rowId: 'initialRow', name: '' }],
        type: sectionType + 1,
        useDropDownRows: false,
        isCollapsed: false
      };
      return newState;
    }
    case types.FORM_SECTION_NAME_EDITED: {
      const { sectionId, sectionName } = action.payload;
      const newState = { ...state };
      newState[sectionId] = {
        ...newState[sectionId],
        name: sectionName
      };
      return newState;
    }
    case types.FORM_SECTION_DELETED: {
      const sectionId = action.payload;
      const newState = { ...state };
      return omit(newState, sectionId);
    }
    case types.FORM_SECTION_COPIED: {
      const { sectionId, newSectionId, newSection } = action.payload;
      const newState = { ...state };
      const sectionToCopy = {
        ...newState[sectionId],
        questions: newState[sectionId].questions.map((question) => ({
          ...question,
          questionId: uuidv4()
        }))
      };
      return {
        ...newState,
        [newSectionId]: { ...sectionToCopy, ...newSection, sectionId: newSectionId }
      };
    }
    case types.FORM_ROW_ADDED: {
      const { sectionId, rowId, name } = action.payload;
      const newState = { ...state };
      newState[sectionId] = {
        ...newState[sectionId],
        rows: [
          ...newState[sectionId].rows,
          {
            rowId,
            name
          }
        ]
      };
      return newState;
    }
    case types.FORM_ROW_NAME_EDITED: {
      const { sectionId, rowId, name } = action.payload;
      const newState = { ...state };
      newState[sectionId] = {
        ...newState[sectionId],
        rows: [
          ...newState[sectionId].rows.map((row) => (row.rowId === rowId ? { ...row, name } : row))
        ]
      };
      return newState;
    }
    case types.FORM_ROW_MOVED: {
      const { sectionId, startingPosition, endingPosition } = action.payload;
      const newRows = [...state[sectionId].rows];
      const [removed] = newRows.splice(startingPosition, 1);
      newRows.splice(endingPosition, 0, removed);
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          rows: newRows
        }
      };
    }
    case types.FORM_ROW_DELETED: {
      const { sectionId, rowId } = action.payload;
      const newState = { ...state };

      newState[sectionId] = {
        ...newState[sectionId],
        rows: [...newState[sectionId].rows.filter((row) => !(row.rowId === rowId))]
      };
      return newState;
    }
    case types.FORM_QUESTION_ADDED: {
      const { sectionId, position, questionType, questionId } = action.payload;
      const newState = { ...state };
      const newQuestions = [
        ...state[sectionId].questions.map((question) => ({ ...question, isCollapsed: true }))
      ];
      let newQuestion = {
        questionId: questionId,
        name: '',
        type: questionType,
        isRequired: false,
        isPrivate: false,
        isCollapsed: false
      };
      let newQuestionA = {
        questionId: questionId,
        name: '',
        type: questionType,
        isRequired: false,
        isPrivate: false,
        isCollapsed: false,
        answers: [{ answerId: 'initialAnswer', answer: '' }]
      };
      if (questionType === 2 || questionType == 3 || questionType == 4) {
        newQuestions.splice(position, 0, newQuestionA);
      } else {
        newQuestions.splice(position, 0, newQuestion);
      }
      newState[sectionId] = {
        ...newState[sectionId],
        questions: newQuestions
      };
      return newState;
    }
    case types.FORM_QUESTION_MOVED: {
      const { sectionId, startingPosition, endingPosition } = action.payload;
      const newQuestions = [...state[sectionId].questions];
      const [removed] = newQuestions.splice(startingPosition, 1);
      newQuestions.splice(endingPosition, 0, removed);
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: newQuestions
        }
      };
    }
    case types.FORM_ANSWER_ADDED: {
      const { sectionId, questionId, answerId, answer } = action.payload;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: state[sectionId].questions.map((question, i) =>
            i === questionId
              ? { ...question, answers: [...(question.answers ?? []), { answerId, answer }] }
              : question
          )
        }
      };
    }
    case types.FORM_ANSWER_MOVED: {
      const { sectionId, questionId, startingPosition, endingPosition } = action.payload;
      const newQuestions = [...state[sectionId].questions];
      const newAnswers = newQuestions[questionId].answers ?? [];
      const [removed] = newAnswers.splice(startingPosition, 1);
      newAnswers.splice(endingPosition, 0, removed);
      newQuestions[questionId].answers = newAnswers;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: newQuestions
        }
      };
    }
    case types.FORM_ANSWER_EDITED: {
      const { sectionId, questionId, position, answer } = action.payload;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: state[sectionId].questions.map((question, i) =>
            i === questionId
              ? {
                  ...question,
                  answers: (question.answers ?? []).map((a, i) =>
                    i === position ? { ...a, answer } : a
                  )
                }
              : question
          )
        }
      };
    }
    case types.FORM_ANSWER_DELETED: {
      const { sectionId, questionId, position } = action.payload;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: state[sectionId].questions.map((question, i) =>
            i === questionId
              ? { ...question, answers: (question.answers ?? []).filter((_, i) => i != position) }
              : question
          )
        }
      };
    }
    case types.FORM_QUESTION_DELETED: {
      const { sectionId, questionId } = action.payload;
      const newQuestions = [...state[sectionId].questions].filter(
        (question) => question.questionId !== questionId
      );
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: newQuestions
        }
      };
    }
    case types.FORM_QUESTION_COPIED: {
      const { sectionId, questionId, newQuestion } = action.payload;
      const newState = { ...state };
      const newQuestions = newState[sectionId]?.questions;
      var oldQuestionIndex = newQuestions.length - 1;
      const oldQuestion = newQuestions.filter((question, i) => {
        if (question.questionId === questionId) {
          oldQuestionIndex = i + 1;
          return true;
        }
        return false;
      })[0];
      newQuestions.splice(oldQuestionIndex, 0, {
        ...oldQuestion,
        ...newQuestion
      });
      return {
        ...newState,
        [sectionId]: {
          ...newState[sectionId],
          questions: newQuestions
        }
      };
    }
    case types.FORM_SECTIONS_SET: {
      const { sectionsOrder, sectionsId } = action.payload;
      forEach(sectionsId, (value, key) => {
        sectionsId[key] = {
          ...sectionsId[key],
          questions: [],
          rows:
            sectionsId[key].sectionTypeId === 1
              ? []
              : sectionsId[key].sectionRows?.split('^')?.map((row) => {
                  const [rowId, name] = row?.split('~');
                  return { rowId, name };
                }),
          type: sectionsId[key].sectionTypeId
        };
      });
      return sectionsId ?? state;
    }
    case types.FORM_SECTION_CHANGE_COLLAPSED: {
      const { sectionId } = action.payload;
      const newState = { ...state };
      newState[sectionId] = {
        ...newState[sectionId],
        isCollapsed: !newState[sectionId].isCollapsed
      };
      return newState;
    }

    case types.FORM_QUESTION_SET: {
      const { sectionId, question } = action.payload;
      const newSection = { ...state };
      const newQuestion = {
        ...question,
        type: question.questionTypeId
      };
      newSection[sectionId].questions = [...newSection[sectionId].questions, newQuestion];
      return newSection;
    }

    case types.FORM_QUESTION_NAME_EDITED: {
      const { sectionId, questionId, questionName } = action.payload;
      const newQuestions = [...state[sectionId].questions];
      newQuestions[questionId].name = questionName;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: newQuestions
        }
      };
    }

    case types.FORM_QUESTION_HEIGHT_EDITED: {
      const { sectionId, questionId, height } = action.payload;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: [
            ...state[sectionId].questions.map((question, i) =>
              i === questionId ? { ...question, height: height } : question
            )
          ]
        }
      };
    }

    case types.FORM_QUESTION_CHANGE_COLLAPSED: {
      const { sectionId, questionId } = action.payload;
      return {
        ...state,
        [sectionId]: {
          ...state[sectionId],
          questions: [
            ...state[sectionId].questions.map((question, i) =>
              question.questionId === questionId
                ? { ...question, isCollapsed: !question.isCollapsed }
                : question
            )
          ]
        }
      };
    }

    default: {
      return state;
    }
  }
};

const sectionsOrder = (state: any[] = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.FORM_SECTION_ADDED: {
      const { sectionType, position, sectionId } = action.payload;
      const newState = [...state];
      newState.splice(position, 0, sectionId);
      return newState;
    }

    case types.FORM_SECTION_MOVED: {
      const { startingPosition, endingPosition } = action.payload;
      const list = [...state];
      const [removed] = list.splice(startingPosition, 1);
      list.splice(endingPosition, 0, removed);
      return list;
    }

    case types.FORM_SECTION_DELETED: {
      const sectionId = action.payload;
      const newState = state.filter((element) => element !== sectionId);
      return newState;
    }

    case types.FORM_SECTION_COPIED: {
      const { sectionId, newSectionId } = action.payload;
      const newState = [...state];
      var oldSectionIndex = newState.length;
      newState.map((id, i) => {
        if (id === sectionId) oldSectionIndex = i + 1;
      });
      newState.splice(oldSectionIndex, 0, newSectionId);
      return newState;
    }

    case types.FORM_SECTIONS_SET: {
      const { sectionsOrder, sectionsId } = action.payload;
      return sectionsOrder ?? state;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const selectedAnswer = (state: any = null, action) => {
  switch (action.type) {
    case types.FORM_ANSWER_SELECTED: {
      const responseId = action.payload;
      return responseId;
    }
    case types.FORM_ANSWER_DESELECTED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const forms = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  sectionsOrder,
  sectionsById,
  selectedAnswer
});

export default forms;

//Own state
export const getOwnState = (state) => state.forms;

//Information
export const getForm = (state, formId) => {
  const form = getOwnState(state)?.byId[formId];
  if (form) {
    const operator = form.operatorId
      ? operatorSelectors.getOperator(state, form.operatorId)
      : undefined;
    const organization = form.organizationId
      ? organizationSelectors.getOrganization(state, form.organizationId)
      : undefined;
    const division = form.divisionId
      ? divisionSelectors.getDivision(state, form.divisionId)
      : undefined;
    const subdivision = form.subdivisionId
      ? subdivisionSelectors.getSubdivision(state, form.subdivisionId)
      : undefined;
    return {
      ...form,
      operatorName: operator?.operatorName,
      organizationName: organization?.organizationName,
      divisionName: division?.divisionName,
      subdivisionName: subdivision?.subdivisionName
    };
  } else return undefined;
};

export const getFormSections = (state, formId) => getForm(state, formId)?.sections;
export const getFormSection = (state, formId, sectionId) =>
  getFormSections(state, formId)?.byId[sectionId];
export const getFormSectionsList = (state, formId) =>
  getFormSections(state, formId)?.order?.map((section) => getFormSection(state, formId, section));

export const getFormQuestions = (state, formId) => getForm(state, formId)?.questions;
export const getFormQuestion = (state, formId, questionId) =>
  getFormQuestions(state, formId).byId[questionId];
export const getFormQuestionsList = (state, formId) =>
  getFormQuestions(state, formId)?.order?.map((question) =>
    getFormQuestion(state, formId, question)
  );

export const getSectionsWithQuestions = (state, formId) =>
  getFormSectionsList(state, formId)?.map((section) => ({
    ...section,
    questions: getFormQuestionsList(state, formId).filter(
      (question) => question.sectionId === section.sectionId
    )
  }));

export const getFormsList = (state) =>
  getOwnState(state)
    .order?.map((id) => getForm(state, id))
    .filter((form) => form.organizationFormStatus > 0);

// Filter items inside list that are undefined
export const getFormsSpecificList = (state, list) =>
  list.map((id) => getForm(state, id)).filter((form) => form !== undefined);
export const getFormsListByOrganization = (state, organizationId) => {
  return getFormsList(state).filter((form) => form.organizationId === organizationId);
};
export const getFormsListByMembership = (state, membership) => {
  return getFormsList(state).filter(
    (form) =>
      membership &&
      (!membership.operatorId || membership.operatorId === form.operatorId) &&
      (!membership.organizationId || membership.organizationId === form.organizationId) &&
      (!membership.divisionId || membership.divisionId === form.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === form.subdivisionId)
  );
};
export const getSelectedForms = (state) => {
  const selectedForms = getFormsList(state).filter((form) => form.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedForms.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedForms.length > 0) return selectedForms;
};

export const getSelectedForm = (state) => {
  const selectedForms = getFormsList(state).filter((form) => form?.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedForms.length === 1) return selectedForms[0];
  //De lo contrario se devuelve null
  else return null;
};

export const getSelectedFormSections = (state) => {
  const selectedForm = getSelectedForm(state);
  return selectedForm?.sections?.order.map((section) => selectedForm?.sections?.byId[section]);
};

export const getSelectedFormQuestions = (state) => {
  const selectedForm = getSelectedForm(state);
  return selectedForm?.questions?.order.map((question) => selectedForm?.questions?.byId[question]);
};

//Formulario para creación de formularios
export const getSection = (state, id) => ({
  ...getOwnState(state).sectionsById[id],
  questions: getOwnState(state).sectionsById[id]?.questions?.map((question) => ({
    ...question,
    allowOtherOption: false,
    type: question?.questionTypeId ?? question?.type
  }))
});
export const getSectionOrder = (state) => getOwnState(state).sectionsOrder;
export const getSections = (state) =>
  getSectionOrder(state).map((section) => getSection(state, section));
export const getQuestions = (state) =>
  getSections(state).map((section) => ({
    sectionId: section.sectionId,
    questions: section.questions.map((question) => ({
      ...question,
      allowOtherOption: false,
      isRequired: false,
      isPrivate: false
    }))
  }));

export const getResponses = (state, formId) =>
  getOwnState(state)?.byId[formId]?.responses?.order?.map(
    (response) => getOwnState(state)?.byId[formId]?.responses?.byId[response]
  );

export const getResponse = (state, formId, responseId) => {
  const response = getOwnState(state)?.byId[formId]?.responses?.byId[responseId];
  return response?.result.map((item) => response.questions[item]);
};

export const getSelectedResponse = (state) => getOwnState(state).selectedAnswer;

export const getSelectedResponseDetails = (state) => {
  const selectedAnswer = getOwnState(state).selectedAnswer;
  const selectedResponses = getSelectedForm(state)?.responses?.byId[selectedAnswer];
  return selectedResponses.result.map((response) => selectedResponses.questions[response]);
};

//Status of sagas
export const isFetchingForm = (state) => getOwnState(state).isFetching;
export const isFetchingListForms = (state) => getOwnState(state).isFetchingList;
export const isAddingForm = (state) => getOwnState(state).isAdding;
export const isEditingForm = (state) => getOwnState(state).isEditing;
export const isRemovingForm = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingFormErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListFormsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingFormErrors = (state) => getOwnState(state).errorAdding;
export const getEditingFormErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingFormErrors = (state) => getOwnState(state).errorRemoving;
