import * as types from '../types';

export const startFetchingOrganizations = (filters = {}) => ({
  type: types.ORGANIZATIONS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingOrganizations = (entities, order) => ({
  type: types.ORGANIZATIONS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingOrganizations = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.ORGANIZATIONS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingOrganizations = (entityTypeId) => ({
  type: types.ORGANIZATIONS_PARTIAL_FETCH_STARTED,
  payload: entityTypeId
});
export const completePartialFetchingOrganizations = (entities, order) => ({
  type: types.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingOrganizations = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.ORGANIZATIONS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingOrganization = (organization, isSelected = false) => ({
  type: types.ORGANIZATION_ADD_STARTED,
  payload: { organization, isSelected }
});
export const completeAddingOrganization = (oldId, organization) => ({
  type: types.ORGANIZATION_ADD_COMPLETED,
  payload: { oldId, organization }
});
export const failAddingOrganization = (oldId, error) => ({
  type: types.ORGANIZATION_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingOrganization = (organization) => ({
  type: types.ORGANIZATION_EDIT_STARTED,
  payload: organization
});
export const completeEditingOrganization = (organization) => ({
  type: types.ORGANIZATION_EDIT_COMPLETED,
  payload: organization
});
export const failEditingOrganization = (oldOrganization, error) => ({
  type: types.ORGANIZATION_EDIT_FAILED,
  payload: {
    oldOrganization,
    error
  }
});

export const startSavingConsolidableEvents = (organization, consolidableEvents) => ({
  type: types.ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_STARTED,
  payload: { organization, consolidableEvents }
});
export const completeSavingConsolidableEvents = (organizationId, consolidableEvents) => ({
  type: types.ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_COMPLETED,
  payload: { organizationId, consolidableEvents }
});
export const failSavingConsolidableEvents = (oldOrganization, error) => ({
  type: types.ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_FAILED,
  payload: {
    oldOrganization,
    error
  }
});

export const startRemovingOrganization = (organizationId) => ({
  type: types.ORGANIZATION_REMOVE_STARTED,
  payload: {
    organizationId
  }
});
export const completeRemovingOrganization = (organizationId) => ({
  type: types.ORGANIZATION_REMOVE_COMPLETED,
  payload: {
    organizationId
  }
});
export const failRemovingOrganization = (organizationId, error) => ({
  type: types.ORGANIZATION_REMOVE_FAILED,
  payload: {
    organizationId,
    error
  }
});

export const selectOrganization = (organizationId) => ({
  type: types.ORGANIZATION_SELECTED,
  payload: organizationId
});

export const selectAllOrganizations = (
  organizationIds = [] //List of OrganizationIds
) => ({
  type: types.ORGANIZATIONS_ALL_SELECTED,
  payload: organizationIds
});

export const deselectOrganization = (organizationId) => ({
  type: types.ORGANIZATION_DESELECTED,
  payload: organizationId
});

export const deselectAllOrganizations = (
  organizationIds = [] //List of OrganizationIds
) => ({
  type: types.ORGANIZATIONS_ALL_DESELECTED,
  payload: organizationIds
});

export const startCheckingCode = (organizationId, code) => ({
  type: types.ORGANIZATION_CODE_CHECK_STARTED,
  payload: {
    organizationId,
    code
  }
});
export const completeCheckingCode = (result) => ({
  type: types.ORGANIZATION_CODE_CHECK_COMPLETED,
  payload: result
});
export const failCheckingCode = (error) => ({
  type: types.ORGANIZATION_CODE_CHECK_FAILED,
  payload: {
    error
  }
});

export const startAssigningCode = (
  operatorId,
  organizationId,
  organizationName,
  registrationCode,
  registrationMails,
  registrationDivisionId,
  registrationSubdivisionId,
  allowedApplications
) => ({
  type: types.ORGANIZATION_CODE_ASSIGN_STARTED,
  payload: {
    operatorId,
    organizationId,
    organizationName,
    registrationCode,
    registrationMails,
    registrationDivisionId,
    registrationSubdivisionId,
    allowedApplications
  }
});
export const completeAssigningCode = (
  organizationId,
  registrationCode,
  allowedApplications,
  registrationDivisionId,
  registrationSubdivisionId
) => ({
  type: types.ORGANIZATION_CODE_ASSIGN_COMPLETED,
  payload: {
    organizationId,
    registrationCode,
    allowedApplications,
    registrationDivisionId,
    registrationSubdivisionId
  }
});
export const failAssigningCode = (error) => ({
  type: types.ORGANIZATION_CODE_ASSIGN_FAILED,
  payload: {
    error
  }
});

export const startAddingOrganizationDowntimeReason = (downtimeReason) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_ADD_STARTED,
  payload: downtimeReason
});
export const completeAddingOrganizationDowntimeReason = (oldId, downtimeReason) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_ADD_COMPLETED,
  payload: { oldId, downtimeReason }
});
export const failAddingOrganizationDowntimeReason = (oldId, error) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingOrganizationDowntimeReason = (downtimeReason) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED,
  payload: downtimeReason
});
export const completeEditingOrganizationDowntimeReason = (downtimeReason) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_EDIT_COMPLETED,
  payload: downtimeReason
});
export const failEditingOrganizationDowntimeReason = (oldDowntimeReason, error) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_EDIT_FAILED,
  payload: {
    oldDowntimeReason,
    error
  }
});

export const startRemovingOrganizationDowntimeReason = (downtimeReason) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED,
  payload: downtimeReason
});
export const completeRemovingOrganizationDowntimeReason = (reasonId) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_REMOVE_COMPLETED,
  payload: {
    reasonId
  }
});
export const failRemovingOrganizationDowntimeReason = (reasonId, error) => ({
  type: types.ORGANIZATION_DOWNTIME_REASON_REMOVE_FAILED,
  payload: {
    reasonId,
    error
  }
});
