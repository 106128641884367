import * as types from '../../types/models';

export const startFetchingModels = (filters = {}, brandId: number | null | undefined = null) => ({
  type: types.MODELS_FETCH_STARTED,
  payload: { filters, brandId }
});
export const completeFetchingModels = (entities, order) => ({
  type: types.MODELS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingModels = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.MODELS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
