import { useEffect, useState } from 'react';
import { getUnitScriptHistory } from '../services/index';
import camelcaseKeys from 'camelcase-keys';
import { ScriptsHistory } from 'views/screens/Reports/views/byUnit/reportScriptHistory/types';
import { useRerender } from 'hooks/useRerender';

const useGetUnitScriptHistory = (
  startDate: string,
  endDate: string,
  unitId: number | undefined,
  additionalValidation?: () => boolean
): {
  scriptHistory: ScriptsHistory[];
  isFetchingScriptHistory: boolean;
  startRefresh(): void;
} => {
  const [scriptHistory, setData] = useState<any>([]);
  const [isFetchingScriptHistory, setIsFetching] = useState<boolean>(false);
  const { rerender: refresh, startRerender: startRefresh } = useRerender();
  useEffect(() => {
    const isValid = typeof additionalValidation === 'function' ? additionalValidation() : true;
    if (startDate && endDate && unitId && isValid) {
      setIsFetching(true);
      getUnitScriptHistory(
        {
          unitId,
          startDate,
          endDate
        },
        {
          errorAction: () => {
            setData([]);
            setIsFetching(false);
          },
          successAction: (response) => {
            if (response && response?.data && response?.data?.length > 0) {
              setData(
                response.data.map((script) => {
                  return camelcaseKeys({
                    ...script
                  });
                })
              );
            } else setData([]);
            setIsFetching(false);
          }
        }
      );
    }
  }, [startDate, endDate, unitId, refresh]);
  return { scriptHistory, isFetchingScriptHistory, startRefresh };
};

export default useGetUnitScriptHistory;
