const permissions = {
  mileageReport: 'CR001',
  hourMetersReport: 'CR002',
  runningTimeReport: 'RU001',
  unitActivity: 'RU002',
  byUnitStopSummaryReport: 'RU005',
  byUnitScriptsHistoryReport: 'RU004',
  consolidatedStopSummaryReport: 'CR008',
  consolidatedOpportunityOfCharge: 'CR009',
  consolidatedUnitActivityReport: 'CR003',
  consolidatedEventActivityReport: 'CR004',
  parkedGeofenceReport: 'RP001',
  fuelSensorReport: 'CR005',
  scheduledReports: 'SR001',
  scheduledReportsCreate: 'SR002',
  scheduledReportsEdit: 'SR003',
  scheduledReportsDelete: 'SR004',
  systemCommunicationsDiagReport: 'AD014',
  systemNotificationsReport: 'AD015',
  accommodationChargesReport: 'AD016',
  administrativeReports: 'RP003',
  byUnitReports: 'RP004',
  consolidatedReports: 'RP005',
  listReports: 'RP006',
  deepSearch: 'RP007',
  consumptionAPIReport: 'AD006',
  readWebEvents: 'WE001',
  markReadedWebEvents: 'WE002',
  consolidatedSummary: 'CR007',
  readAPICost: 'AD017'
};

export default permissions;
