import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/report';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { minsToDurationTimeText } from '../../utils';

const indicators = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      const { indicators } = action.payload;
      return indicators;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((jobId) => {
        newState[jobId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[jobId],
          ...entities[jobId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const byIdUsers = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      const { entitiesByUser, orderByUser } = action.payload;
      const newState = { ...state };
      orderByUser.forEach((jobId) => {
        newState[jobId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[jobId],
          ...entitiesByUser[jobId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const orderUsers = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      const { orderByUser } = action.payload;
      return union(orderByUser);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetching = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_REPORT_FETCH_STARTED: {
      return true;
    }
    case types.JOBS_REPORT_FETCH_COMPLETED:
    case types.JOBS_REPORT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_REPORT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_REPORT_FETCH_STARTED:
    case types.JOBS_REPORT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const jobsReport: any = combineReducers({
  indicators,
  byId,
  order,
  byIdUsers,
  orderUsers,
  isFetching,
  errorFetching
});

const jobsReportPersistedConfig = {
  key: 'jobsReport',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isFetching'],
  whitelist: ['indicators', 'byId', 'order', 'byIdUsers', 'orderUsers']
};

export default persistReducer(jobsReportPersistedConfig, jobsReport);

//Own state
export const getOwnState = (state) => state.jobs.jobsReport;

//Information
export const getJobReport = (state, jobId) => getOwnState(state).byId[jobId];

export const getJobsReportList = (state) =>
  getOwnState(state).order.map((id) => getJobReport(state, id));

export const getJobReportByUsers = (state, jobId, intl) => {
  const user = getOwnState(state).byIdUsers[jobId];
  const progress = (user.jobsFinalized / user.jobsTotal) * 100;
  const progressTime =
    user.jobsRescheduledDuration > 0
      ? (user.jobsTotalExecutionTime / user.jobsRescheduledDuration) * 100
      : 0;
  return {
    ...user,
    progress,
    progressLabel: progress.toFixed(2).toString() + '%',
    progressTime,
    progressTimeLabel: progressTime.toFixed(2).toString() + '%',
    executionTimeFinalizedPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeFinalized / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsActivePercent: (user.jobsActive / user.jobsTotal) * 100,
    executionTimeActivePercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeActive / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsPausedPercent: (user.jobsPaused / user.jobsTotal) * 100,
    executionTimePausedPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimePaused / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsIncompletePercent: (user.jobsIncomplete / user.jobsTotal) * 100,
    executionTimeIncompletePercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeIncomplete / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsScheduledPercent: (user.jobsScheduled / user.jobsTotal) * 100,
    executionTimeScheduledPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeScheduled / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsRescheduledPercent: (user.jobsRescheduled / user.jobsTotal) * 100,
    executionTimeRescheduledPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeRescheduled / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsCancelledPercent: (user.jobsCancelled / user.jobsTotal) * 100,
    executionTimeCancelledPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeCancelled / user.jobsTotalExecutionTime) * 100
        : 0,
    jobsBacklogPercent: (user.jobsBacklog / user.jobsTotal) * 100,
    executionTimeBacklogPercent:
      user.jobsTotalExecutionTime > 0
        ? (user.executionTimeBacklog / user.jobsTotalExecutionTime) * 100
        : 0,
    descriptionProgress: 'ALGO',
    jobsTotalExecutionTimeLabel: minsToDurationTimeText(user.jobsTotalExecutionTime),
    jobsRescheduledDurationLabel: minsToDurationTimeText(user.jobsRescheduledDuration)
  };
};

export const getJobsReportByUsersList = (state, intl) =>
  getOwnState(state).orderUsers.map((id) => getJobReportByUsers(state, id, intl));

export const getIndicators = (state, intl) => {
  const indicators = getOwnState(state).indicators;
  return [
    {
      title: intl.formatMessage({
        id: 'jobs.total'
      }),
      bigIndicator: indicators.jobsTotal || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'jobs.totalTime'
            }) +
            ': ' +
            minsToDurationTimeText(parseInt(indicators.jobsTotalExecutionTime || 0))
        },
        {
          info:
            intl.formatMessage({
              id: 'jobs.rescheduleRequest'
            }) +
            ': ' +
            parseInt(indicators.jobsRescheduled || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'jobs.finalized'
      }),
      bigIndicator: indicators.jobsFinalized || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'jobs.onTime'
            }) +
            ': ' +
            parseInt(indicators.jobsFinalizedOnTime || 0)
        },
        {
          info:
            intl.formatMessage({
              id: 'jobs.late'
            }) +
            ': ' +
            parseInt(indicators.jobsFinalizedLate || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'jobs.incomplete'
      }),
      bigIndicator: indicators.jobsIncomplete || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'jobs.cancelled'
            }) +
            ': ' +
            parseInt(indicators.jobsCancelled || 0)
        }
      ]
    },
    {
      title: intl.formatMessage({
        id: 'jobs.inAction'
      }),
      bigIndicator: indicators.jobsActive || 0,
      details: [
        {
          info:
            intl.formatMessage({
              id: 'jobs.paused'
            }) +
            ': ' +
            parseInt(indicators.jobsPaused || 0)
        }
      ]
    }
  ];
};

export const getBarChartInformationByUser = (state, intl) => {
  const usersList = orderBy(
    getJobsReportByUsersList(state, intl),
    'jobsTotalExecutionTimeAndDuration',
    'desc'
  );
  return {
    subtitle:
      usersList.length > 0 && intl.formatMessage({ id: 'common.total' }) + ': ' + usersList.length,
    labels: usersList.map((u) => u.completeName),
    series: [
      {
        name: intl.formatMessage({
          id: 'jobs.quantity'
        }),
        data: usersList.map((u) => u.jobsTotal)
      },
      {
        name: intl.formatMessage({
          id: 'jobs.time'
        }),
        data: usersList.map((u) => u.jobsTotalExecutionTimeAndDuration),
        tooltipCustomFunction: (val) => minsToDurationTimeText(parseInt(val || 0))
      }
    ]
  };
};

export const getBarChartInformationByTime = (state, intl) => {
  const tasksList = orderBy(getJobsReportList(state), 'totalExecutionTime', 'desc');
  return {
    subtitle:
      tasksList.length > 0 && intl.formatMessage({ id: 'common.total' }) + ': ' + tasksList.length,
    labels: tasksList.map((j) => j.jobTitle),
    series: [
      {
        name: intl.formatMessage({
          id: 'jobs.time'
        }),
        data: tasksList.map((j) => (j.jobStatus === 1 ? (j.totalExecutionTime) : j.totalExecutionTime * 60)), // El API regresa los jobs terminados /60 mientras que los activos no
        tooltipCustomFunction: (val) => minsToDurationTimeText(parseInt(val || 0))
      }
    ]
  };
};

//Status of sagas
export const isFetchingJobsReport = (state) => getOwnState(state).isFetching;

//Errors
export const getPartialFetchingJobsReportErrors = (state) =>
  getOwnState(state).errorPartialFetching;
export const getFetchingJobsReportErrors = (state) => getOwnState(state).errorFetching;
