/* -------------------------------------------------------------------------- */
/*                       Schema Execution Workplans                           */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const workplansVisit = new schema.Entity(
  'workplansVisits',
  {},
  { idAttribute: (value) => `${value.visitId}-${value.locationId}` }
);
export const workplansVisits = new schema.Array(workplansVisit);
