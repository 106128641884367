import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import { persistReducer } from 'redux-persist';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import * as authTypes from '../../../../redux/types/auth';
import * as types from '../types';
import { type } from 'os';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.DRIVERS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((driverId) => {
        newState[driverId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[driverId],
          ...entities[driverId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.DRIVERS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((driverId) => {
        newState[driverId] = {
          isSelected: false,
          isShownInMap: false,
          ...state[driverId],
          ...entities[driverId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.DRIVER_ADD_COMPLETED: {
      const { oldId, driver } = action.payload;
      const newState = omit(state, oldId);
      newState[driver.driverId] = {
        isShownInMap: false,
        ...state[oldId],
        ...driver,
        isConfirmed: true
      };
      return newState;
    }

    case types.DRIVER_EDIT_COMPLETED: {
      const driver = action.payload;
      return {
        ...state,
        [driver.driverId]: {
          isShownInMap: false,
          ...omit(state[driver.driverId], ['oldDriver']),
          ...omit(driver, ['oldDriver']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.DRIVER_ADD_STARTED: {
      const driver = action.payload;
      const newState = { ...state };
      newState[driver.driverId] = {
        isShownInMap: false,
        isSelected: false,
        ...omit(driver, 'files'),
        isConfirmed: false
      };
      return newState;
    }
    case types.DRIVER_EDIT_STARTED: {
      const driver = action.payload;
      return {
        ...state,
        [driver.driverId]: {
          isShownInMap: false,
          ...state[driver.driverId],
          oldDriver: state[driver.driverId],
          ...omit(driver, 'files'),
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.DRIVER_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.DRIVER_EDIT_FAILED: {
      const { oldDriver } = action.payload;
      return {
        ...state,
        [oldDriver.driverId]: {
          isShownInMap: false,
          ...omit(state[oldDriver.driverId], ['oldDriver']),
          ...oldDriver,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.DRIVER_REMOVE_COMPLETED: {
      const { driverId } = action.payload;
      return omit(state, driverId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.DRIVER_SELECTED: {
      const driverId = action.payload;
      const newState = {
        ...state,
        [driverId]: {
          ...state[driverId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.DRIVER_DESELECTED: {
      const driverId = action.payload;
      return {
        ...state,
        [driverId]: {
          ...state[driverId],
          isSelected: false
        }
      };
    }

    case types.DRIVERS_ALL_SELECTED: {
      const driverIds = action.payload;
      const newState = { ...state };
      if (driverIds.length == 0) {
        forEach(state, (driver: any, driverId) => {
          newState[driverId] = {
            ...driver,
            isSelected: true
          };
        });
      } else {
        driverIds.forEach((driverId) => {
          newState[driverId] = {
            ...state[driverId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.DRIVERS_ALL_DESELECTED: {
      const driverIds = action.payload;
      const newState = { ...state };
      if (driverIds.length == 0) {
        forEach(state, (driver: any, driverId) => {
          newState[driverId] = {
            ...driver,
            isSelected: false
          };
        });
      } else {
        driverIds.forEach((driverId) => {
          newState[driverId] = {
            ...state[driverId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.DRIVER_ASSIGN_SENSOR_COMPLETED: {
      const driverId = action.payload.driverId;
      const sensor = action.payload.sensor;
      const newState = {
        ...state,
        [driverId]: {
          ...state[driverId],
          sensorId: sensor.sensorId,
          sensorName: sensor.sensorName
        }
      };
      return newState;
    }

    case types.DRIVER_UNASSIGN_SENSOR_COMPLETED: {
      const driverId = action.payload.driverId;
      return {
        ...state,
        [driverId]: {
          ...state[driverId],
          sensorId: null,
          sensorName: null
        }
      };
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.DRIVERS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.DRIVERS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.DRIVER_ADD_COMPLETED: {
      const { oldId, driver } = action.payload;
      return state.map((driverId) => (driverId === oldId ? driver.driverId : driverId));
    }

    case types.DRIVER_REMOVE_COMPLETED: {
      const { driverId } = action.payload;
      return state.filter((driverIdState) => driverIdState !== driverId);
    }

    //CASE ADD STARTED
    case types.DRIVER_ADD_STARTED: {
      const driver = action.payload;
      return [...state, driver.driverId];
    }

    //CASE ADD FAILED
    case types.DRIVER_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((driverIdState) => driverIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const isAssigningDrivers = (state: any = null, action: any) => {
  switch (action.type) {
    case types.START_MASSIVE_DRIVERS_ASSIGNMENT: {
      return true;
    }
    case types.COMPLETE_MASSIVE_DRIVERS_ASSIGNMENT: {
      return false;
    }
    default: {
      return false;
    }
  }
};

const importDriversAndUnits = (state: any = null, action) => {
  switch (action.type) {
    case types.START_IMPORT_DRIVERS_UNITS_IMPORT: {
      return [];
    }
    case types.COMPLETE_IMPORT_DRIVERS_UNITS_IMPORT: {
      return action.payload ?? [];
    }
    default: {
      return action.payload ?? [];
    }
  }
};
const isImportingDriversAndUnitsList = (state: any = null, action: any) => {
  switch (action.type) {
    case types.START_IMPORT_DRIVERS_UNITS_IMPORT: {
      return true;
    }
    case types.COMPLETE_IMPORT_DRIVERS_UNITS_IMPORT: {
      return false;
    }
    default: {
      return false;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVERS_FETCH_STARTED: {
      return true;
    }
    case types.DRIVERS_FETCH_COMPLETED:
    case types.DRIVERS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVERS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.DRIVERS_PARTIAL_FETCH_COMPLETED:
    case types.DRIVERS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVER_ADD_STARTED: {
      return true;
    }
    case types.DRIVER_ADD_COMPLETED:
    case types.DRIVER_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVER_EDIT_STARTED: {
      return true;
    }
    case types.DRIVER_EDIT_COMPLETED:
    case types.DRIVER_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVER_REMOVE_STARTED: {
      return true;
    }
    case types.DRIVER_REMOVE_COMPLETED:
    case types.DRIVER_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.DRIVERS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DRIVERS_FETCH_STARTED:
    case types.DRIVERS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.DRIVERS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DRIVERS_PARTIAL_FETCH_STARTED:
    case types.DRIVERS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.DRIVER_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DRIVER_ADD_STARTED:
    case types.DRIVER_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.DRIVER_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DRIVER_EDIT_STARTED:
    case types.DRIVER_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.DRIVER_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.DRIVER_REMOVE_STARTED:
    case types.DRIVER_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.DRIVERS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const drivers: any = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  hasAlreadyFetched,
  isImportingDriversAndUnitsList,
  importDriversAndUnits,
  isAssigningDrivers
});

const driversPersistedConfig = {
  key: 'drivers',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isAdding', 'isEditing', 'isRemoving', 'isFetchingList', 'isPartialFetchingList'],
  whitelist: ['byId', 'order', 'importDriversAndUnits']
  // blackList: [],
};

export default persistReducer(driversPersistedConfig, drivers);
// export default drivers;

//Own state
export const getOwnState = (state) => state.drivers;

//Information
export const getDriver = (state, driverId): types.Driver | null =>
  getOwnState(state).byId[driverId] ?? null;

export const getDriversList = (state): types.Driver[] =>
  getOwnState(state)
    .order.map((id) => getDriver(state, id))
    .filter((driver) => driver.driverStatus > 0);

export const getDriversListByMembership = (state, membership) => {
  //Se filtra por membership
  return getDriversList(state).filter(
    (driver) =>
      membership &&
      (!membership.operatorId || membership.operatorId === driver.operatorId) &&
      (!membership.organizationId || membership.organizationId === driver.organizationId) &&
      (!membership.divisionId || membership.divisionId === driver.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === driver.subdivisionId) &&
      (!membership.groupId ||
        driver.groups?.split(',').includes(String(membership.groupId)) ||
        membership.groupId + '' === driver.groups)
  );
};

export const getDriverByPrivateCode = (state: any, driverPrivateCode: string) => {
  return getDriversList(state).find((driver) => driver?.privateCode === driverPrivateCode);
};

export const getDriverByPrivateCodeByMembership = (
  state: any,
  driverPrivateCode: string,
  membership: DefaultFilters
) => {
  return getDriversListByMembership(state, membership).find(
    (driver) => driver?.privateCode === driverPrivateCode
  );
};

export const getSelectedDrivers = (state) => {
  const selectedDrivers = getDriversList(state).filter((driver) => driver.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedDrivers.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedDrivers.length > 0) return selectedDrivers;
};

export const getSelectedDriver = (state) => {
  const selectedDrivers = getDriversList(state).filter((driver) => driver.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedDrivers.length === 1) return selectedDrivers[0];
  //De lo contrario se devuelve null
  else return null;
};

// New list with the drivers info
export const getDriversByListOfIds = (state: any, driversIds: Array<number>) => {
  return driversIds?.map((driverId) => getDriver(state, driverId));
};

export const getDriversByListOfPrivateCodes = (state: any, driversPrivateCodes: Array<string>) => {
  return driversPrivateCodes.map((driverPrivateCode) =>
    getDriverByPrivateCode(state, driverPrivateCode)
  );
};

/**
 * Get Driver by ID or privateCode
 * @param state Redux state
 * @param driverId Driver id
 * @param privateCode Driver private code
 * @returns founded driver or undefined
 */
export const getDriverByIdOrPrivateCode = (state: any, driverId: number, privateCode: string) => {
  if (driverId !== null && driverId !== undefined) {
    const driver = getDriver(state, driverId);
    if (driver) return driver;
  }
  if (privateCode !== null && privateCode !== undefined && !/^\s$/.test(privateCode)) {
    const driver = getDriverByPrivateCode(state, privateCode);
    if (driver) return driver;
  }
};

//Status of sagas
export const isPartialFetchingListDrivers = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListDrivers = (state) => getOwnState(state).isFetchingList;
export const haveDriversBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;
export const isAddingDriver = (state) => getOwnState(state).isAdding;
export const isEditingDriver = (state) => getOwnState(state).isEditing;
export const isRemovingDriver = (state) => getOwnState(state).isRemoving;
export const isCopyingDriver = (state) => getOwnState(state).isCopying;
export const isAssigningGeofenceDriver = (state) => getOwnState(state).isAssigningGeofence;

// importing
export const isImportingDriversAndUnits = (state) =>
  getOwnState(state).isImportingDriversAndUnitsList;
export const importedDriversAndUnits = (state) => getOwnState(state).importDriversAndUnits;

// Massive assignment
export const isAssigningDriversMassively = (state: any) => getOwnState(state).isAssigningDrivers;

//Errors
export const getPartialFetchingListDriversErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListDriversErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingDriverErrors = (state) => getOwnState(state).errorAdding;
export const getEditingDriverErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingDriverErrors = (state) => getOwnState(state).errorRemoving;
