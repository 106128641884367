/* -------------------------------------------------------------------------- */
/*                      Componente SlidepanelLocationInformation                     */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Sidebar para subir una imagen.

import camelcaseKeys from 'camelcase-keys';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SlidingPane from 'react-sliding-pane';
import { FormGroup, Label, Spinner } from 'reactstrap';
import * as alerts from 'redux/utils/alerts';
import DeleteButton from 'views/components/DeleteButton';
import Toolbar from 'views/components/Toolbar';
import { getLocationImages } from 'views/screens/Locations/services/locationImages';
import DefaultButton from '../../../../components/DefaultButton';
import EditButton from '../../../../components/EditButton';
import MapsBasic from '../../../../components/Map';
import MediaGallery from '../../../../components/MediaGallery';
import TitleH4 from '../../../../components/TitleH4';
import SlidingPaneEditLocation from '../../../Locations/views/SlidingPaneEditLocation';
import * as locationSelectors from '../../reducers';
import * as locationActions from '../../actions';
import locationPermissions from '../../permissions';
import SlidepanelLocationImages from '../SlidingpanelLocationImages';

/* -------------------------------------------------------------------------- */
/*                            SlidepanelLocationInformation                           */
/* -------------------------------------------------------------------------- */
export default function SlidepanelLocationInformation({
  closeSlidepanel,
  isSlidepanelOpen,
  isLoading = false,
  title = '',
  isInMap = true,
  location,
  editingLocationParent = undefined,
  setEditingLocationParent = undefined,
  editingLocationParentRef = undefined,
  onEditLocation = (editedLocation) => {}
}) {
  const history = useHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [locationImages, setLocationImages] = useState<any[]>([]);
  const [rerenderMap, setRerenderMap] = useState<Date>(new Date());
  const [isLoadingLocationImages, setIsLoadingLocationImages] = useState<Boolean>(false);
  const [showLocationImagesSlidepanel, setShowLocationImagesSlidepanel] = useState(false);
  const [showAddImageSlidepanel, setShowAddImageSlidepanel] = useState(false);
  const [isOpenSlidingPaneEditLocation, setOpenSlidingPaneEditLocation] = useState(false);

  const [editingLocationLocal, setEditingLocationLocal] = useState<any>(undefined);
  const editingLocationLocalRef = React.useRef(editingLocationLocal);
  const setEditingLocation =
    setEditingLocationParent == null ? setEditingLocationLocal : setEditingLocationParent;
  const editingLocation =
    setEditingLocationParent == null ? editingLocationLocal : editingLocationParent;
  const editingLocationRef =
    setEditingLocationParent == null ? editingLocationLocalRef : editingLocationParentRef;

  const { selectedLocation, isRemovingLocation } = useSelector((state) => ({
    selectedLocation: locationSelectors.getLocation(state, location?.locationId),
    isRemovingLocation: locationSelectors.isRemovingLocation(state)
  }));

  useEffect(() => {
    if (location.locationId && !selectedLocation.locationId && !isRemovingLocation)
      closeSlidepanel();
  }, [isRemovingLocation]);

  useEffect(() => {
    if (editingLocation !== undefined) {
      dispatch(locationActions.hideLocationInMap(editingLocation.locationId));
      setOpenSlidingPaneEditLocation(true);
    }
  }, [editingLocation]);

  useEffect(() => {
    if (location.locationId) {
      setShowLocationImagesSlidepanel(false);
      setShowLocationImagesSlidepanel(false);
      setOpenSlidingPaneEditLocation(false);
    }
  }, [location.locationId]);

  //getLocationImages Async
  const getLocationImagesAsync = async (location) => {
    setIsLoadingLocationImages(true);
    setLocationImages([]);
    const response = await getLocationImages({ locationId: location.locationId });
    setIsLoadingLocationImages(false);
    if (response.success) {
      const images = camelcaseKeys(response.data).map((loc) => {
        return {
          ...loc,
          imagePath: 'locationImages/',
          imageId: loc.locationImageId,
          isDefault: loc.isDefault == null || loc.isDefault == 0 ? false : true
        };
      });
      setLocationImages(images);
    }
  };
  useEffect(() => {
    if (isSlidepanelOpen && location) {
      getLocationImagesAsync(location);
    }
  }, [location, isSlidepanelOpen]);

  useEffect(() => {
    if (isSlidepanelOpen) {
      if (!isInMap) {
        setRerenderMap(new Date());
      }
    }
  }, [isSlidepanelOpen]);

  return (
    <>
      <SlidingPane
        className="custom-sliding-pane"
        overlayClassName={
          isInMap ? 'custom-overlay-sliding-pane-map' : 'custom-overlay-sliding-pane'
        }
        isOpen={isSlidepanelOpen}
        title={title}
        width="40rem"
        onRequestClose={closeSlidepanel}
      >
        <TitleH4 textId={'locations.location'} center={true} textTransform={'uppercase'} />
        {/* loading spiner for location images  */}
        {(isLoadingLocationImages || isLoading) && (
          <div className="d-flex justify-content-center my-1">
            <Spinner color="primary" />
          </div>
        )}
        {/* locationimages gallery */}
        {!isLoadingLocationImages && !isLoading && locationImages.length > 0 && (
          <MediaGallery
            isRtl={false}
            images={locationImages}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            navigation={true}
            pagination={true}
            className={'full-width-slide-panel'}
          />
        )}
        <br />
        {/* Toolbar for locationinformation */}
        {!isLoading && location.latitude != null && location.longitude != null && (
          <>
            <Toolbar
              buttons={[
                <DefaultButton
                  id={'locationImagesAddButton'}
                  icon={'Upload'}
                  handleClick={() => {
                    setShowAddImageSlidepanel(true);
                    setShowLocationImagesSlidepanel(true);
                  }}
                  tooltipMessage={intl.formatMessage({ id: 'locations.addImage' })}
                />,
                <DefaultButton
                  id={'locationImagesButton'}
                  icon={'Image'}
                  isDisabled={false}
                  handleClick={() => {
                    setShowAddImageSlidepanel(false);
                    setShowLocationImagesSlidepanel(true);
                  }}
                  tooltipMessage={intl.formatMessage({ id: 'locations.editImages' })}
                />,
                !isInMap ? (
                  <DefaultButton
                    id={'mapLocationButton'}
                    icon={'Map'}
                    color="info"
                    isDisabled={false}
                    handleClick={() => {
                      history.push({ pathname: '/home' }, { locationsRouteParam: [location] });
                      // dispatch(locationActions.hideAllLocationsInMap());
                      // dispatch(locationActions.showLocationInMap(location.locationId));
                    }}
                    tooltipMessage={intl.formatMessage({ id: 'locations.seeInMap' })}
                  />
                ) : (
                  <EditButton
                    tooltipMessage={'locations.location'}
                    isDisabled={false}
                    handleClick={() => {
                      if (setEditingLocation) setEditingLocation(location);
                    }}
                    permissions={[locationPermissions.editLocation]}
                  />
                ),
                <DeleteButton
                  tooltipMessage={'locations.location'}
                  handleClick={() =>
                    alerts.showDeleteConfirmationMessage({
                      action() {
                        dispatch(locationActions.startRemovingLocation(location.locationId));
                      },
                      objectType: intl.formatMessage({ id: 'locations.location' }),
                      objectName: location.locationName
                    })
                  }
                  permissions={[locationPermissions.removeLocation]}
                />
              ]}
            />

            {/* location information */}
            <FormGroup>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {intl.formatMessage({ id: 'locations.name' })}
              </Label>
              <Label className="label-value-information">{location.locationName ?? 'N/A'}</Label>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'locations.locationAddress' })}
              </Label>
              <Label className="label-value-information">
                {!_.isEmpty(location.locationAddress) ? location.locationAddress : 'N/A'}
              </Label>
              {/* Little Map detail of location */}
              {!isInMap && (
                <MapsBasic
                  mapOptions={{
                    className: '',
                    style: { height: '35vh' },
                    rerenderMap: rerenderMap
                  }}
                  mapLocationsOptions={{
                    hideMarkersByZoom: false,
                    clusterMarkers: false,
                    showLocations: true,
                    setMapPositionToLocationBounds: true,
                    useRectangleBounds: false,
                    useSlidingpanelLocationInformation: false,
                    locations: [location]
                  }}
                ></MapsBasic>
              )}
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'locations.radius' })}
              </Label>
              <Label className="label-value-information">{location.radius ?? 'N/A'}</Label>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {' '}
                {intl.formatMessage({ id: 'locations.latitude' })}
              </Label>
              <Label className="label-value-information">
                {location.latitude != null ? location.latitude.toFixed(8) : 'N/A'}
              </Label>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {intl.formatMessage({ id: 'locations.longitude' })}
              </Label>
              <Label className="label-value-information">
                {location.latitude != null ? location.longitude.toFixed(8) : 'N/A'}
              </Label>
              <hr className={'full-width-slide-panel'} />
              <Label className="label-information">
                {intl.formatMessage({ id: 'locations.privateCode' })}
              </Label>
              <Label className="label-value-information">
                {!_.isEmpty(location.privateCode) ? location.privateCode : 'N/A'}
              </Label>
              <hr className={'full-width-slide-panel'} />
            </FormGroup>
          </>
        )}
      </SlidingPane>
      <SlidepanelLocationImages
        closeSlidepanel={() => {
          setShowLocationImagesSlidepanel(false);
          getLocationImagesAsync(location);
        }}
        isSlidepanelOpen={showLocationImagesSlidepanel}
        title={location.locationName}
        location={location}
        isInMap={isInMap}
        addImageInitial={showAddImageSlidepanel}
      />
      <SlidingPaneEditLocation
        confirmText={intl.formatMessage({ id: 'common.save' })}
        isOpenSlidingPane={isOpenSlidingPaneEditLocation}
        isInMap={isInMap}
        closeSlidingPane={() => {
          setOpenSlidingPaneEditLocation(false);
          if (isInMap) {
            if (editingLocation) {
              dispatch(locationActions.showLocationInMap(editingLocation.locationId));
            }
          }
        }}
        action={(values) => {
          if (editingLocation?.locationId) {
            const updatedLocation = {
              ...editingLocation,
              ...values,
              ...values.membership
            };
            dispatch(locationActions.startEditingLocation(updatedLocation));
            if (onEditLocation) onEditLocation(updatedLocation);
            if (setEditingLocation) setEditingLocation(undefined);
          }
        }}
        onClose={() => {
          if (setEditingLocation) setEditingLocation(undefined);
        }}
        editingLocation={editingLocation}
        setEditingLocation={setEditingLocation}
        title={
          editingLocation
            ? editingLocation.locationId
              ? editingLocation.locationName
              : intl.formatMessage({ id: 'locations.addLocation' })
            : intl.formatMessage({ id: 'locations.addLocation' })
        }
      />
    </>
  );
}
