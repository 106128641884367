export const ORGANIZATIONS_FETCH_STARTED = 'ORGANIZATIONS_FETCH_STARTED';
export const ORGANIZATIONS_FETCH_COMPLETED = 'ORGANIZATIONS_FETCH_COMPLETED';
export const ORGANIZATIONS_FETCH_FAILED = 'ORGANIZATIONS_FETCH_FAILED';

export const ORGANIZATIONS_PARTIAL_FETCH_STARTED = 'ORGANIZATIONS_PARTIAL_FETCH_STARTED';
export const ORGANIZATIONS_PARTIAL_FETCH_COMPLETED = 'ORGANIZATIONS_PARTIAL_FETCH_COMPLETED';
export const ORGANIZATIONS_PARTIAL_FETCH_FAILED = 'ORGANIZATIONS_PARTIAL_FETCH_FAILED';

export const ORGANIZATION_ADD_STARTED = 'ORGANIZATION_ADD_STARTED';
export const ORGANIZATION_ADD_COMPLETED = 'ORGANIZATION_ADD_COMPLETED';
export const ORGANIZATION_ADD_FAILED = 'ORGANIZATION_ADD_FAILED';

export const ORGANIZATION_EDIT_STARTED = 'ORGANIZATION_EDIT_STARTED';
export const ORGANIZATION_EDIT_COMPLETED = 'ORGANIZATION_EDIT_COMPLETED';
export const ORGANIZATION_EDIT_FAILED = 'ORGANIZATION_EDIT_FAILED';

export const ORGANIZATION_REMOVE_STARTED = 'ORGANIZATION_REMOVE_STARTED';
export const ORGANIZATION_REMOVE_COMPLETED = 'ORGANIZATION_REMOVE_COMPLETED';
export const ORGANIZATION_REMOVE_FAILED = 'ORGANIZATION_REMOVE_FAILED';

export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';
export const ORGANIZATIONS_ALL_SELECTED = 'ORGANIZATIONS_ALL_SELECTED';
export const ORGANIZATION_DESELECTED = 'ORGANIZATION_DESELECTED';
export const ORGANIZATIONS_ALL_DESELECTED = 'ORGANIZATIONS_ALL_DESELECTED';

export const ORGANIZATION_CODE_CHECK_STARTED = 'ORGANIZATION_CODE_CHECK_STARTED';
export const ORGANIZATION_CODE_CHECK_COMPLETED = 'ORGANIZATION_CODE_CHECK_COMPLETED';
export const ORGANIZATION_CODE_CHECK_FAILED = 'ORGANIZATION_CODE_CHECK_FAILED';

export const ORGANIZATION_CODE_ASSIGN_STARTED = 'ORGANIZATION_CODE_ASSIGN_STARTED';
export const ORGANIZATION_CODE_ASSIGN_COMPLETED = 'ORGANIZATION_CODE_ASSIGN_COMPLETED';
export const ORGANIZATION_CODE_ASSIGN_FAILED = 'ORGANIZATION_CODE_ASSIGN_FAILED';

export const ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_STARTED =
  'ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_STARTED';
export const ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_COMPLETED =
  'ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_COMPLETED';
export const ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_FAILED =
  'ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_FAILED';

export const ORGANIZATION_DOWNTIME_REASON_ADD_STARTED = 'ORGANIZATION_DOWNTIME_REASON_ADD_STARTED';
export const ORGANIZATION_DOWNTIME_REASON_ADD_COMPLETED =
  'ORGANIZATION_DOWNTIME_REASON_ADD_COMPLETED';
export const ORGANIZATION_DOWNTIME_REASON_ADD_FAILED = 'ORGANIZATION_DOWNTIME_REASON_ADD_FAILED';

export const ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED =
  'ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED';
export const ORGANIZATION_DOWNTIME_REASON_EDIT_COMPLETED =
  'ORGANIZATION_DOWNTIME_REASON_EDIT_COMPLETED';
export const ORGANIZATION_DOWNTIME_REASON_EDIT_FAILED = 'ORGANIZATION_DOWNTIME_REASON_EDIT_FAILED';

export const ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED =
  'ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED';
export const ORGANIZATION_DOWNTIME_REASON_REMOVE_COMPLETED =
  'ORGANIZATION_DOWNTIME_REASON_REMOVE_COMPLETED';
export const ORGANIZATION_DOWNTIME_REASON_REMOVE_FAILED =
  'ORGANIZATION_DOWNTIME_REASON_REMOVE_FAILED';

export interface ReduxOrganization {
  isSelected: boolean;
  organizationId: number;
  organizationName: string;
  contactPhone?: string;
  organizationAddress: string;
  contactName?: string;
  userLicense?: number;
  organizationStatus: number;
  contactEmail?: string;
  privateCode?: string;
  operatorId: number;
  agentId: number;
  operatorName: string;
  themeId?: number;
  alertConsoleActive: number;
  showUsersOnChat: number;
  registrationCode?: string;
  registrationDivisionId?: number;
  registrationSubdivisionId?: number;
  registrationNotificationMails?: string;
  tradeName?: string;
  taxNumber: string;
  allowedApplications?: number;
  currentApplications: number;
  legalRepresentativeId?: number;
  legalRepresentativeName?: string;
  legalRepresentativeIdentification?: string;
  legalRepresentativeAge: number;
  legalRepresentativeNacionality?: string;
  legalRepresentativeAddress: string;
  utcZoneId?: number;
  organizationType: number;
  sacUsers: SacUser[];
  isConfirmed: boolean;
  loginextActive: number;
  loginextEffectiveVisitingTime: number;
  loginextDeliveryDelay: number;
}

export interface SacUser {
  userId: number;
  userName: string;
  completeName: string;
  chatUserTypeId: number;
  organizationId: number;
}
