import split from 'lodash/split';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from '../../../@core/components/avatar';
import * as monitoringUserSelectors from '../../screens/Users/reducers';
import FetchEntities from '../../screens/Entities/views/FetchEntities';
import MultiSelectInput from '../MultiSelectInput';

const UsersDropdown = (props) => {
  const {
    id,
    name,
    forceinitialvalue,
    fetchUsers = true,
    usersFilters,
    label = undefined,
    isInModal = false,
    isMultiple = true,
    isClearable = false,
    ...inputProps
  } = props;
  const intl = useIntl();

  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    inputProps.field.onChange(forceinitialvalue);
    setResetValue(Date.now());
  };

  const { isFetchingListMonitoringUsers, users } = useSelector((state) => ({
    isFetchingListMonitoringUsers: monitoringUserSelectors.isFetchingListMonitoringUsers(state),
    users: monitoringUserSelectors.getMonitoringUsersList(state).map((user) => ({
      id: user.userId,
      value: user.userName,
      label: user.completeName,
      divisionId: user.divisionId,
      subdivisionId: user.subdivisionId
      /*icon: (
        <Avatar
          // @ts-ignore unreachable error
          img={user.userImageUrl ? user.userImageUrl : undefined}
          color="light-primary"
          className={'mr-25'}
          content={user.completeName == null ? '' : user.completeName}
          imgHeight={24}
          imgWidth={24}
          initials
        />
      )*/
    }))
  }));

  //Update reactselect values of  currencies when fetched is finished
  useEffect(() => {
    if (!isFetchingListMonitoringUsers) {
      resetDropdown();
    }
  }, [isFetchingListMonitoringUsers]);

  return (
    <>
      {fetchUsers && <FetchEntities useUsers usersFilters={usersFilters ?? {}} />}
      <MultiSelectInput
        {...inputProps}
        id={id}
        key={resetValue}
        name={name}
        isLoading={isFetchingListMonitoringUsers}
        isClearable={isClearable}
        componentKey={resetValue}
        isInModal={isInModal}
        isMultiple={isMultiple}
        type={'select'}
        options={users
          .filter((user) =>
            usersFilters && usersFilters.divisionId
              ? user.divisionId === usersFilters.divisionId
              : user
          )
          .filter((user) =>
            usersFilters && usersFilters.subdivisionId
              ? user.subdivisionId === usersFilters.subdivisionId
              : user
          )}
        label={
          label ||
          intl.formatMessage({
            id: 'monitoringUsers.user'
          })
        }
        placeholder={intl.formatMessage({ id: 'users.placeholder' })}
      />
    </>
  );
};

export default UsersDropdown;
