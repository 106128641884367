import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';
import { isNullOrUndefined } from 'utility/Utils';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.VEHICLES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = {};
      order.forEach((vehicleId) => {
        newState[vehicleId] = {
          isSelected: false,
          ...state[vehicleId],
          ...entities[vehicleId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.VEHICLES_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((vehicleId) => {
        newState[vehicleId] = {
          isSelected: false,
          ...state[vehicleId],
          ...entities[vehicleId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.VEHICLE_ADD_COMPLETED: {
      const { oldId, vehicle } = action.payload;
      const newState = omit(state, oldId);
      newState[vehicle.vehicleId] = {
        ...state[oldId],
        ...vehicle,
        isConfirmed: true
      };
      return newState;
    }

    case types.VEHICLE_EDIT_COMPLETED: {
      const vehicle = action.payload;
      return {
        ...state,
        [vehicle.vehicleId]: {
          ...omit(state[vehicle.vehicleId], ['oldVehicle']),
          ...omit(vehicle, ['oldVehicle']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.VEHICLE_ADD_STARTED: {
      const vehicle = action.payload;
      const newState = { ...state };
      newState[vehicle.vehicleId] = {
        isSelected: false,
        ...vehicle,
        isConfirmed: false
      };
      return newState;
    }
    case types.VEHICLE_EDIT_STARTED: {
      const vehicle = action.payload;
      return {
        ...state,
        [vehicle.vehicleId]: {
          ...state[vehicle.vehicleId],
          oldVehicle: state[vehicle.vehicleId],
          ...vehicle,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.VEHICLE_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.VEHICLE_EDIT_FAILED: {
      const { oldVehicle } = action.payload;
      return {
        ...state,
        [oldVehicle.vehicleId]: {
          ...omit(state[oldVehicle.vehicleId], ['oldVehicle']),
          ...oldVehicle,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.VEHICLE_REMOVE_COMPLETED: {
      const { vehicleId } = action.payload;
      return omit(state, vehicleId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.VEHICLE_SELECTED: {
      const vehicleId = action.payload;
      const newState = {
        ...state,
        [vehicleId]: {
          ...state[vehicleId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.VEHICLE_DESELECTED: {
      const vehicleId = action.payload;
      return {
        ...state,
        [vehicleId]: {
          ...state[vehicleId],
          isSelected: false
        }
      };
    }

    case types.VEHICLES_ALL_SELECTED: {
      const vehicleIds = action.payload;
      const newState = { ...state };
      if (vehicleIds.length == 0) {
        forEach(state, (vehicle: any, vehicleId) => {
          newState[vehicleId] = {
            ...vehicle,
            isSelected: true
          };
        });
      } else {
        vehicleIds.forEach((vehicleId) => {
          newState[vehicleId] = {
            ...state[vehicleId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.VEHICLES_ALL_DESELECTED: {
      const vehicleIds = action.payload;
      const newState = { ...state };
      if (vehicleIds.length == 0) {
        forEach(state, (vehicle: any, vehicleId) => {
          newState[vehicleId] = {
            ...vehicle,
            isSelected: false
          };
        });
      } else {
        vehicleIds.forEach((vehicleId) => {
          newState[vehicleId] = {
            ...state[vehicleId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.VEHICLES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.VEHICLES_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.VEHICLE_ADD_COMPLETED: {
      const { oldId, vehicle } = action.payload;
      return state.map((vehicleId) => (vehicleId === oldId ? vehicle.vehicleId : vehicleId));
    }

    case types.VEHICLE_REMOVE_COMPLETED: {
      const { vehicleId } = action.payload;
      return state.filter((vehicleIdState) => vehicleIdState !== vehicleId);
    }

    //CASE ADD STARTED
    case types.VEHICLE_ADD_STARTED: {
      const vehicle = action.payload;
      return [...state, vehicle.vehicleId];
    }

    //CASE ADD FAILED
    case types.VEHICLE_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((vehicleIdState) => vehicleIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLES_FETCH_STARTED: {
      return true;
    }
    case types.VEHICLES_FETCH_COMPLETED:
    case types.VEHICLES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLES_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.VEHICLES_PARTIAL_FETCH_COMPLETED:
    case types.VEHICLES_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLE_ADD_STARTED: {
      return true;
    }
    case types.VEHICLE_ADD_COMPLETED:
    case types.VEHICLE_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLE_EDIT_STARTED: {
      return true;
    }
    case types.VEHICLE_EDIT_COMPLETED:
    case types.VEHICLE_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.VEHICLE_REMOVE_STARTED: {
      return true;
    }
    case types.VEHICLE_REMOVE_COMPLETED:
    case types.VEHICLE_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLES_FETCH_STARTED:
    case types.VEHICLES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLES_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLES_PARTIAL_FETCH_STARTED:
    case types.VEHICLES_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLE_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLE_ADD_STARTED:
    case types.VEHICLE_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLE_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLE_EDIT_STARTED:
    case types.VEHICLE_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.VEHICLE_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.VEHICLE_REMOVE_STARTED:
    case types.VEHICLE_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const validCode = (state = true, action) => {
  switch (action.type) {
    case types.VEHICLE_LICENSE_PLATE_CHECK_COMPLETED: {
      return action.payload;
    }
    case types.VEHICLE_LICENSE_PLATE_CHECK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const vehicles = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  validCode
});

export default vehicles;

//Own state
export const getOwnState = (state) => state.vehicles;

//Information
export const getVehicle = (state, vehicleId) => getOwnState(state).byId[vehicleId];
export const getVehiclesList = (state) =>
  getOwnState(state)
    .order.map((id) => getVehicle(state, id))
    .filter((vehicle) => vehicle.vehicleStatus > 0);
export const getVehiclesListAll = (state) =>
  getOwnState(state).order.map((id) => getVehicle(state, id));
export const getVehiclesListByMembership = (state, membership) =>
  getVehiclesList(state).filter(
    (vehicle) =>
      membership &&
      (!membership.operatorId || membership.operatorId === vehicle.operatorId) &&
      (!membership.organizationId || membership.organizationId === vehicle.organizationId) &&
      (!membership.divisionId || membership.divisionId === vehicle.divisionId || isNullOrUndefined(vehicle.divisionId)) &&
      (!membership.subdivisionId || membership.subdivisionId === vehicle.subdivisionId || isNullOrUndefined(vehicle.subdivisionId))
  );
export const isLicenseValid = (state) => getOwnState(state).validCode;

export const getSelectedVehicles = (state) => {
  const selectedVehicles = getVehiclesList(state).filter((vehicle) => vehicle.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedVehicles.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedVehicles.length > 0) return selectedVehicles;
};

export const getSelectedVehicle = (state) => {
  const selectedVehicles = getVehiclesList(state).filter((vehicle) => vehicle.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedVehicles.length === 1) return selectedVehicles[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListVehicles = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListVehicles = (state) => getOwnState(state).isFetchingList;
export const isAddingVehicle = (state) => getOwnState(state).isAdding;
export const isEditingVehicle = (state) => getOwnState(state).isEditing;
export const isRemovingVehicle = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListVehiclesErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListVehiclesErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingVehicleErrors = (state) => getOwnState(state).errorAdding;
export const getEditingVehicleErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingVehicleErrors = (state) => getOwnState(state).errorRemoving;
