const permissions = {
  listForms: 'OF001',
  addForm: 'OF002',
  editForm: 'OF003',
  removeForm: 'OF004',
  fillForm: 'OF006',
  answersFormReport: 'OF005',
  listAllUserAnswersForm: 'OF007'
};

export default permissions;
