import { combineReducers } from 'redux';
import union from 'lodash/union';
import * as types from '../../../types/static-catalogs';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.PAYMENT_METHODS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((paymentMethodId) => {
        newState[paymentMethodId] = {
          isSelected: false,
          ...state[paymentMethodId],
          ...entities[paymentMethodId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.PAYMENT_METHODS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.PAYMENT_METHODS_FETCH_STARTED: {
      return true;
    }
    case types.PAYMENT_METHODS_FETCH_COMPLETED:
    case types.PAYMENT_METHODS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.PAYMENT_METHODS_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.PAYMENT_METHODS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.PAYMENT_METHODS_FETCH_STARTED:
    case types.PAYMENT_METHODS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const paymentMethods = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList
});

export default paymentMethods;

//Own state
export const getOwnState = (state) => state.staticCatalogs.paymentMethods;

//Information
export const getPaymentMethod = (state, paymentMethodId) =>
  getOwnState(state).byId[paymentMethodId];

export const getPaymentMethodDropdownOption = (state, paymentMethodId) => {
  let paymentMethod = getPaymentMethod(state, paymentMethodId);
  return paymentMethod != undefined
    ? {
        ...paymentMethod,
        id: paymentMethod.paymentMethodId,
        value: paymentMethod.paymentMethodId,
        icon: <div className="paymentMethod-flag paymentMethod-flag-eur"></div>,
        label: appIntl().formatMessage({ id: `paymentMethods.${paymentMethod.paymentMethodId}` })
      }
    : null;
};

export const getPaymentMethodsList = (state) =>
  getOwnState(state).order.map((id) => getPaymentMethod(state, id));

//Status of sagas
export const isFetchingListPaymentMethods = (state) => getOwnState(state).isFetchingList;
export const havePaymentMethodsBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;

//Errors
export const getFetchingListPaymentMethodsErrors = (state) => getOwnState(state).errorFetchingList;
