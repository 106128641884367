const paths = {
  reportList: '/reports',
  reportMileage: '/reports/consolidated/mileage',
  reportHourMeters: '/reports/consolidated/hourMeters',
  reportHourOpportunityCharge: '/reports/consolidated/reportHourOpportunityCharge',
  reportRunningTime: '/reports/byUnit/runningTime',
  reportByUnitStopSummary: '/reports/byUnit/stopSummary',
  reportByUnitScriptsHistory: '/reports/byUnit/scriptsHistory',
  reportConsolidatedStopSummary: '/reports/consolidated/stopSummary',
  reportConsolidatedUnitActivity: '/reports/consolidated/unitActivity',
  reportConsolidatedEventActivity: '/reports/consolidated/eventActivity',
  reportParkedGeofence: '/reports/consolidated/cpgeofence',
  reportScheduledReports: '/reports/scheduled_reports',
  reportSystemCommunicationsDiag: '/reports/system/communications_diag',
  reportSystemNotifications: '/reports/system/notifications',
  reportSystemApiConsumption: '/reports/system/apiConsumption',
  reportConsolidatedSummary: '/reports/consolidated/summary'
};

export default paths;
