import { combineReducers } from 'redux';

import union from 'lodash/union';
import omit from 'lodash/omit';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/workplansComments';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.WORKPLAN_COMMENTS_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((jobCommentId) => {
        newState[jobCommentId] = {
          isSelected: false,
          ...state[jobCommentId],
          ...entities[jobCommentId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.WORKPLAN_COMMENT_ADD_COMPLETED: {
      const { oldId, comment } = action.payload;
      const newState = omit(state, oldId);
      newState[comment.jobCommentId] = {
        ...state[oldId],
        ...comment,
        isConfirmed: true
      };
      return newState;
    }

    //TYPES STARTED ADD AND EDIT
    case types.WORKPLAN_COMMENT_ADD_STARTED: {
      const comment = action.payload;
      const newState = { ...state };
      newState[comment.jobCommentId] = {
        isSelected: false,
        ...comment,
        isConfirmed: false
      };
      return newState;
    }

    //TYPES FAILED ADD AND EDIT
    case types.WORKPLAN_COMMENT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    //TYPES REMOVE COMPLETED
    case types.WORKPLAN_COMMENT_REMOVE_COMPLETED: {
      const { jobCommentId } = action.payload;
      return omit(state, jobCommentId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.WORKPLAN_COMMENTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.WORKPLAN_COMMENT_ADD_COMPLETED: {
      const { oldId, comment } = action.payload;
      return state.map((jobCommentId) =>
        jobCommentId === oldId ? comment.jobCommentId : jobCommentId
      );
    }

    case types.WORKPLAN_COMMENT_REMOVE_COMPLETED: {
      const { jobCommentId } = action.payload;
      return state.filter((jobCommentIdState) => jobCommentIdState !== jobCommentId);
    }

    //CASE ADD STARTED
    case types.WORKPLAN_COMMENT_ADD_STARTED: {
      const comment = action.payload;
      return [...state, comment.jobCommentId];
    }

    //CASE ADD FAILED
    case types.WORKPLAN_COMMENT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((jobCommentIdState) => jobCommentIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING
const isFetchingComments = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_COMMENTS_FETCH_STARTED: {
      return true;
    }
    case types.WORKPLAN_COMMENTS_FETCH_COMPLETED:
    case types.WORKPLAN_COMMENTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_COMMENT_ADD_STARTED: {
      return true;
    }
    case types.WORKPLAN_COMMENT_ADD_COMPLETED:
    case types.WORKPLAN_COMMENT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.WORKPLAN_COMMENT_REMOVE_STARTED: {
      return true;
    }
    case types.WORKPLAN_COMMENT_REMOVE_COMPLETED:
    case types.WORKPLAN_COMMENT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingComments = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_COMMENTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_COMMENTS_FETCH_STARTED:
    case types.WORKPLAN_COMMENTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_COMMENT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_COMMENT_ADD_STARTED:
    case types.WORKPLAN_COMMENT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.WORKPLAN_COMMENT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.WORKPLAN_COMMENT_REMOVE_STARTED:
    case types.WORKPLAN_COMMENT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const workplansComments = combineReducers({
  byId,
  order,
  isFetchingComments,
  isAdding,
  isRemoving,
  errorFetchingComments,
  errorAdding,
  errorRemoving
});

export default workplansComments;

//Own state
export const getOwnState = (state) => state.workplans.workplansComments;

//Information
export const getWorkplansComment = (state, jobCommentId) => {
  return getOwnState(state).byId[jobCommentId];
};
export const getWorkplansCommentsList = (state) =>
  getOwnState(state).order.map((id) => getWorkplansComment(state, id));
export const getWorkplansCommentsListByFilters = (state, visitId, locationId) =>
  getWorkplansCommentsList(state).filter(
    (comment) => comment.visitId === visitId && comment.locationId === locationId
  );

//Status of sagas
export const isFetchingWorkplansComments = (state) => getOwnState(state).isFetchingComments;
export const isAddingWorkplansComments = (state) => getOwnState(state).isAdding;
export const isRemovingWorkplansComments = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingWorkplansCommentsErrors = (state) =>
  getOwnState(state).errorFetchingComments;
export const getAddingWorkplansCommentsErrors = (state) => getOwnState(state).errorAdding;
export const getRemovingWorkplansCommentsErrors = (state) => getOwnState(state).errorRemoving;
