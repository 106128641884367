import { Plus, PlusCircle, UploadCloud, List, Shield, FilePlus } from 'react-feather';
import Icon from '../../utility/icomoon';
import paths from 'views/screens/VehicleFuel/paths';
import permissions from 'views/screens/VehicleFuel/permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'vehicleFuel',
    title: 'vehicleFuel.fuel',
    icon: <Icon size={20} icon="Fuel" />,
    iconName: 'Fuel',
    permissions: [],
    children: [
      {
        id: 'vehicleFuelTickets',
        title: 'vehicleFuel.tickets',
        icon: <Icon size={20} icon="Boleta" />,
        iconName: 'Boleta',
        permissions: [permissions.addTicket, permissions.importTicket],
        children: [
          {
            id: 'vehicleFueNewTicket',
            title: 'vehicleFuel.newTicket',
            icon: <FilePlus size={20} />,
            iconName: 'FilePlus',
            permissions: [permissions.addTicket],
            navLink: paths.addTicket,
            screenId: screens.NEW_TICKET
          },
          {
            id: 'vehicleFuelRecordMultipleTickets',
            title: 'vehicleFuel.recordMultipleTickets',
            icon: <Icon icon={'FileVersion'} size={20} />,
            iconName: 'FileVersion',
            permissions: [permissions.recordMultiple],
            navLink: paths.recordMultiple,
            screenId: screens.RECORD_MULTIPLE_TICKETS
          },
          {
            id: 'vehicleFuelImportTicket',
            title: 'vehicleFuel.importTicket',
            iconName: 'UploadCloud',
            icon: <UploadCloud size={20} />,
            permissions: [permissions.importTicket],
            navLink: paths.importTicket,
            screenId: screens.IMPORT_FUEL_TICKETS
          }
        ]
      },
      {
        id: 'vehicleFuelReports',
        title: 'vehicleFuel.reports',
        icon: <PlusCircle size={20} />,
        permissions: [permissions.consolidatedReport, permissions.byUnitReport],
        iconName: 'PlusCircle',
        children: [
          {
            id: 'vehicleConsolidatedReport',
            title: 'vehicleFuel.consolidatedReport',
            icon: <List size={20} />,
            iconName: 'Catalog',
            permissions: [permissions.consolidatedReport],
            navLink: paths.consolidatedReport,
            screenId: screens.FUEL_TICKETS_CONSOLIDATED_REPORT
          },
          {
            id: 'vehicleByUnitReport',
            title: 'vehicleFuel.byUnitReport',
            icon: <List size={20} />,
            iconName: 'Catalog',
            permissions: [permissions.byUnitReport],
            navLink: paths.byUnitReport,
            screenId: screens.FUEL_TICKETS_REPORT_BY_UNIT
          }
        ]
      }
    ]
  }
];
