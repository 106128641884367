import * as types from '../../../types/static-catalogs/sensor-models';

export const startFetchingSensorModels = (sensorBrand) => ({
  type: types.SENSOR_MODELS_FETCH_STARTED,
  payload: sensorBrand
});
export const completeFetchingSensorModels = (entities, order) => ({
  type: types.SENSOR_MODELS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingSensorModels = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SENSOR_MODELS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
