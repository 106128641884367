/* -------------------------------------------------------------------------- */
/*                              Schema PaymentMethods                         */
/* -------------------------------------------------------------------------- */

import { schema } from 'normalizr';

export const paymentMethod = new schema.Entity(
  'paymentMethods',
  {},
  { idAttribute: 'paymentMethodId' }
);
export const paymentMethods = new schema.Array(paymentMethod);
