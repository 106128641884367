import union from 'lodash/union';
import { combineReducers } from 'redux';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import * as types from '../../../types/static-catalogs';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CURRENCIES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((currencyId) => {
        newState[currencyId] = {
          isSelected: false,
          ...state[currencyId],
          ...entities[currencyId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CURRENCIES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CURRENCIES_FETCH_STARTED: {
      return true;
    }
    case types.CURRENCIES_FETCH_COMPLETED:
    case types.CURRENCIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const hasAlreadyFetched = (state = false, action) => {
  switch (action.type) {
    case types.CURRENCIES_FETCH_COMPLETED: {
      return true;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CURRENCIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CURRENCIES_FETCH_STARTED:
    case types.CURRENCIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const currencies = combineReducers({
  byId,
  order,
  isFetchingList,
  hasAlreadyFetched,
  errorFetchingList
});

export default currencies;

//Own state
export const getOwnState = (state) => state.staticCatalogs.currencies;

//Information
export const getCurrency = (state, currencyId): types.Currency | null => {
  const currency = getOwnState(state).byId[currencyId] ?? null;
  return currency;
};

export const getCurrencyDropdownOption = (state, currencyId) => {
  let curr = getCurrency(state, currencyId);
  return curr != undefined
    ? {
        ...curr,
        id: curr.currencyId,
        value: curr.currencyId,
        icon: <div className="currency-flag currency-flag-eur"></div>,
        label: appIntl().formatMessage({ id: `currencies.${curr.currencyId}` })
      }
    : null;
};

export const getCurrenciesList = (state): types.Currency[] =>
  getOwnState(state).order.map((id) => getCurrency(state, id));

//Status of sagas
export const isFetchingListCurrencies = (state) => getOwnState(state).isFetchingList;
export const haveCurrenciesBeenFetched = (state) => getOwnState(state).hasAlreadyFetched;

//Errors
export const getFetchingListCurrenciesErrors = (state) => getOwnState(state).errorFetchingList;
