export const WORKPLANS_EXECUTIONS_FETCH_STARTED = 'WORKPLANS_EXECUTIONS_FETCH_STARTED';
export const WORKPLANS_EXECUTIONS_FETCH_COMPLETED = 'WORKPLANS_EXECUTIONS_FETCH_COMPLETED';
export const WORKPLANS_EXECUTIONS_FETCH_FAILED = 'WORKPLANS_EXECUTIONS_FETCH_FAILED';

export const WORKPLAN_EXECUTION_FETCH_STARTED = 'WORKPLAN_EXECUTION_FETCH_STARTED';
export const WORKPLAN_EXECUTION_FETCH_COMPLETED = 'WORKPLAN_EXECUTION_FETCH_COMPLETED';
export const WORKPLAN_EXECUTION_FETCH_FAILED = 'WORKPLAN_EXECUTION_FETCH_FAILED';

export const WORKPLANS_EXECUTION_CANCEL_STARTED = 'WORKPLANS_EXECUTION_CANCEL_STARTED';
export const WORKPLANS_EXECUTION_CANCEL_COMPLETED = 'WORKPLANS_EXECUTION_CANCEL_COMPLETED';
export const WORKPLANS_EXECUTION_CANCEL_FAILED = 'WORKPLANS_EXECUTION_CANCEL_FAILED';

export const WORKPLANS_EXECUTION_FINALIZE_STARTED = 'WORKPLANS_EXECUTION_FINALIZE_STARTED';
export const WORKPLANS_EXECUTION_FINALIZE_COMPLETED = 'WORKPLANS_EXECUTION_FINALIZE_COMPLETED';
export const WORKPLANS_EXECUTION_FINALIZE_FAILED = 'WORKPLANS_EXECUTION_FINALIZE_FAILED';

export const WORKPLANS_EXECUTION_MANUAL_TRANSIT_STARTED =
  'WORKPLANS_EXECUTION_MANUAL_TRANSIT_STARTED';
export const WORKPLANS_EXECUTION_MANUAL_TRANSIT_COMPLETED =
  'WORKPLANS_EXECUTION_MANUAL_TRANSIT_COMPLETED';
export const WORKPLANS_EXECUTION_MANUAL_TRANSIT_FAILED =
  'WORKPLANS_EXECUTION_MANUAL_TRANSIT_FAILED';

export const WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED = 'WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED';
export const WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED =
  'WORKPLANS_EXECUTION_CHANGE_UNIT_COMPLETED';
export const WORKPLANS_EXECUTION_CHANGE_UNIT_FAILED = 'WORKPLANS_EXECUTION_CHANGE_UNIT_FAILED';

export const WORKPLANS_EXECUTION_ASSIGN_UNIT_STARTED = 'WORKPLANS_EXECUTION_ASSIGN_UNIT_STARTED';
export const WORKPLANS_EXECUTION_ASSIGN_UNIT_COMPLETED =
  'WORKPLANS_EXECUTION_ASSIGN_UNIT_COMPLETED';
export const WORKPLANS_EXECUTION_ASSIGN_UNIT_FAILED = 'WORKPLANS_EXECUTION_ASSIGN_UNIT_FAILED';

export enum COLORS {
  Visited = '#1e90ff',
  Confirmed = '#2ed573',
  NotVisited = '#ff4757',
  Pending = '#ACACAC',
  ArrivalOnTime = '#2ed573',
  ArrivalEarly = '#1e90ff',
  ArrivalLate = '#FFA502',
  PermanenceNormal = '#2ed573',
  PermanenceShort = '#1e90ff',
  PermanenceLong = '#FFA502'
}
