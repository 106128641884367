/* -------------------------------------------------------------------------- */
/*                       Acciones del reductor Entities                       */
/* -------------------------------------------------------------------------- */

import * as types from '../types';
import { defaultEntitiesToFetchAftetLogin } from '../constants';
export const startFetchingEntities = () => ({
  type: types.ENTITIES_FETCH_STARTED,
  payload: {}
});

export const startFetchingEntitiesAfterLogin = (
  entitiesToUpdate: any[] = defaultEntitiesToFetchAftetLogin
) => ({
  type: types.ENTITIES_FETCH_STARTED,
  payload: entitiesToUpdate
});
export const completeFetchingEntities = (entities, order) => ({
  type: types.ENTITIES_FETCH_COMPLETED,
  payload: { entities, order }
});

export const failFetchingEntities = (error) => ({
  type: types.ENTITIES_FETCH_FAILED,
  payload: { error }
});

export const resetIsFetchedEntities = () => ({
  type: types.ENTITIES_RESET_IS_FETCHED,
  payload: {}
});

export const startUpdatingEntities = (entitiesToUpdate: any[] = []) => ({
  type: types.ENTITIES_UPDATE_STARTED,
  payload: entitiesToUpdate
});

export const completeUpdatingEntities = () => ({
  type: types.ENTITIES_UPDATE_COMPLETED
});

export const failUpdatingEntities = (error) => ({
  type: types.ENTITIES_UPDATE_FAILED,
  payload: { error }
});
