export const NOTICES_BY_HOURMETER_FETCH_STARTED = 'NOTICES_BY_HOURMETER_FETCH_STARTED';
export const NOTICES_BY_HOURMETER_FETCH_COMPLETED = 'NOTICES_BY_HOURMETER_FETCH_COMPLETED';
export const NOTICES_BY_HOURMETER_FETCH_FAILED = 'NOTICES_BY_HOURMETER_FETCH_FAILED';

export const NOTICES_BY_HOURMETER_PARTIAL_FETCH_STARTED =
  'NOTICES_BY_HOURMETER_PARTIAL_FETCH_STARTED';
export const NOTICES_BY_HOURMETER_PARTIAL_FETCH_COMPLETED =
  'NOTICES_BY_HOURMETER_PARTIAL_FETCH_COMPLETED';
export const NOTICES_BY_HOURMETER_PARTIAL_FETCH_FAILED =
  'NOTICES_BY_HOURMETER_PARTIAL_FETCH_FAILED';

export const NOTICE_BY_HOURMETER_ADD_STARTED = 'NOTICE_BY_HOURMETER_ADD_STARTED';
export const NOTICE_BY_HOURMETER_ADD_COMPLETED = 'NOTICE_BY_HOURMETER_ADD_COMPLETED';
export const NOTICE_BY_HOURMETER_ADD_FAILED = 'NOTICE_BY_HOURMETER_ADD_FAILED';

export const NOTICE_BY_HOURMETER_EDIT_STARTED = 'NOTICE_BY_HOURMETER_EDIT_STARTED';
export const NOTICE_BY_HOURMETER_EDIT_COMPLETED = 'NOTICE_BY_HOURMETER_EDIT_COMPLETED';
export const NOTICE_BY_HOURMETER_EDIT_FAILED = 'NOTICE_BY_HOURMETER_EDIT_FAILED';

export const NOTICE_BY_HOURMETER_RENEW_STARTED = 'NOTICE_BY_HOURMETER_RENEW_STARTED';
export const NOTICE_BY_HOURMETER_RENEW_COMPLETED = 'NOTICE_BY_HOURMETER_RENEW_COMPLETED';
export const NOTICE_BY_HOURMETER_RENEW_FAILED = 'NOTICE_BY_HOURMETER_RENEW_FAILED';

export const NOTICE_BY_HOURMETER_REMOVE_STARTED = 'NOTICE_BY_HOURMETER_REMOVE_STARTED';
export const NOTICE_BY_HOURMETER_REMOVE_COMPLETED = 'NOTICE_BY_HOURMETER_REMOVE_COMPLETED';
export const NOTICE_BY_HOURMETER_REMOVE_FAILED = 'NOTICE_BY_HOURMETER_REMOVE_FAILED';
