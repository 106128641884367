// ** React Imports
import { useState, createContext } from 'react';

//grid  L10n provider
import { L10n, loadCldr } from '@syncfusion/ej2-base';

// ** Intl Provider Import
import { IntlProvider } from 'react-intl';
import { IntlGlobalProvider, appIntl } from './IntlGlobalProvider';

// ** Core Language Data
import messagesEs from '@assets/data/locales/es.json';
import messagesEn from '@assets/data/locales/en.json';
import messageEsGrid from 'views/components/Table/i18n/es.json';
import messageEsDateTimePicker from 'views/components/TableDateTimePicker/i18n/es.json';

//Syncfusion Globalization
import gregorian from 'cldr-data/main/es-US/ca-gregorian.json';
import numbers from 'cldr-data/main/es-US/numbers.json';
import timeZoneNames from 'cldr-data/main/es-US/timeZoneNames.json';
import numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import weekData from 'cldr-data/supplemental/weekData.json'; // To load the culture based first day of week

// ** User Language Data
import userMessagesEs from '@src/assets/data/locales/es.ts';
import userMessagesEn from '@src/assets/data/locales/en.ts';

// ** Menu msg obj
const menuMessages = {
  es: { ...messagesEs, ...userMessagesEs },
  en: { ...messagesEn, ...userMessagesEn }
};

// ** Create Context
const Context = createContext();

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const localStorageLang = localStorage.getItem('lang') ?? 'es';
  const [locale, setLocale] = useState(localStorageLang);
  const [messages, setMessages] = useState(menuMessages[localStorageLang]);

  // useEffect(() => {
  //   if (appIntl() != null) {
  //     setLocale(appIntl().locale);
  //     setMessages([appIntl().locale]);
  //   }
  // }, []);
  // ** Switches Language
  const switchLanguage = (lang) => {
    setLocale(lang);
    setMessages(menuMessages[lang]);
    //Save language in local storage
    localStorage.setItem('lang', lang);
  };

  // Documentación con todos los prefijos
  // https://ej2.syncfusion.com/react/documentation/grid/global-local/

  loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);

  L10n.load({
    'es-US': { ...messageEsGrid, ...messageEsDateTimePicker }
  });

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider locale={locale} messages={messages} defaultLocale="es">
        <IntlGlobalProvider>{children}</IntlGlobalProvider>
      </IntlProvider>
    </Context.Provider>
  );
};

export { IntlProviderWrapper, Context as IntlContext };
