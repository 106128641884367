/* -------------------------------------------------------------------------- */
/*                               Schema Time Zones                            */
/* -------------------------------------------------------------------------- */

import { schema } from "normalizr";

export const timeZone = new schema.Entity(
  "timeZones",
  {},
  { idAttribute: "zoneId" }
);
export const timeZones = new schema.Array(timeZone);
