import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../redux/sagas/settings/apibaseurl';
import * as actions from '../actions';
import * as quotationActions from '../../Quotations/actions';
import * as contactActions from 'views/screens/Contacts/actions';
import * as types from '../types';
import * as selectors from 'views/screens/Organizations/reducers';
import * as schemas from '../schemas';
import * as contactSchemas from 'views/screens/Contacts/schemas';
import * as organizationUsers from '../../../../redux/schemas/organizations-users';
import * as authSelectors from '../../../../redux/reducers/auth';
import * as entitySelectors from '../../Entities/reducers';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../redux/utils/alerts';
import { appIntl } from '../../../../utility/context/IntlGlobalProvider';
import forEach from 'lodash/forEach';
import groupBy from 'lodash/groupBy';
import chain from 'lodash/chain';
import { store } from 'redux/storeConfig/store';
import {
  deleteOrganizationService,
  getOrganizationsListService,
  saveOrganizationService
} from '../services';

/* -------------------------------------------------------------------------- */
/*                                 FETCH LIST                                 */
/* -------------------------------------------------------------------------- */
function* fetchOrganizationsList(action) {
  getOrganizationsListService(
    {},
    {
      successAction: (response) => {
        const resultData = response.data.organizationList.map((result) => camelcaseKeys(result));
        var usersData = response.data.usersList.map((result) => camelcaseKeys(result));
        const {
          entities: { organizations },
          result //order
        } = normalize(resultData, schemas.organizations); //normalize data to byId and order
        const usersByOrganization = groupBy(usersData, 'organizationId');
        forEach(organizations, (organization) => {
          if (organization.organizationId in usersByOrganization) {
            if (organizations) {
              organizations[organization.organizationId] = {
                ...organizations[organization.organizationId],
                sacUsers: usersByOrganization[organization.organizationId]
              };
            }
          } else {
            if (organizations) {
              organizations[organization.organizationId] = {
                ...organizations[organization.organizationId],
                sacUsers: []
              };
            }
          }
        });
        store.dispatch(actions.completeFetchingOrganizations(organizations, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failFetchingOrganizations(error));
      }
    }
  );
}

export function* watchFetchOrganizationsList() {
  yield takeEvery(types.ORGANIZATIONS_FETCH_STARTED, fetchOrganizationsList);
}
/* -------------------------------------------------------------------------- */
/*                             PARTIAL FETCH LIST                             */
/* -------------------------------------------------------------------------- */
function* partialFetchOrganizationsList(action) {
  const entityTypeId = action.payload;
  const date = yield select(entitySelectors.getUserUpdateByEntityDate, entityTypeId);
  getOrganizationsListService(
    { userFetchDate: date },
    {
      successAction: (response) => {
        const resultData = response.data.organizationList.map((result) => camelcaseKeys(result));
        var usersData = response.data.usersList.map((result) => camelcaseKeys(result));
        const {
          entities: { organizations },
          result //order
        } = normalize(resultData, schemas.organizations); //normalize data to byId and order
        const usersByOrganization = groupBy(usersData, 'organizationId');
        forEach(organizations, (organization) => {
          if (organization.organizationId in usersByOrganization) {
            if (organizations) {
              organizations[organization.organizationId] = {
                ...organizations[organization.organizationId],
                sacUsers: usersByOrganization[organization.organizationId]
              };
            }
          } else {
            if (organizations) {
              organizations[organization.organizationId] = {
                ...organizations[organization.organizationId],
                sacUsers: []
              };
            }
          }
        });
        store.dispatch(actions.completeFetchingOrganizations(organizations, result));
      },
      errorAction: (error) => {
        store.dispatch(actions.failPartialFetchingOrganizations(error));
      }
    }
  );
}

export function* watchPartialFetchOrganizationsList() {
  yield takeEvery(types.ORGANIZATIONS_PARTIAL_FETCH_STARTED, partialFetchOrganizationsList);
}

/* -------------------------------------------------------------------------- */
/*                                     ADD                                    */
/* -------------------------------------------------------------------------- */
function* addOrganization(action) {
  const { organization, isSelected } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/save`, {
        method: 'POST',
        body: JSON.stringify({ ...organization, organizationId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedOrganization = camelcaseKeys(jsonResult.data.organizationData);

        // const organizationContacts = camelcaseKeys(jsonResult.data.contacts);
        // const {
        //   entities: { contacts },
        //   result //order
        // } = normalize(organizationContacts, contactSchemas.contacts); //normalize data to byId and order
        // yield put(contactActions.completePartialFetchingContacts(contacts, result));

        yield put(
          actions.completeAddingOrganization(organization.organizationId, addedOrganization)
        );

        if (organization.quotationId)
          yield put(
            quotationActions.changeQuotationOrganization(
              {
                quotationId: organization.quotationId,
                baseQuotationId: organization.baseQuotationId
              },
              {
                organizationId: addedOrganization?.organizationId,
                organizationName: addedOrganization?.organizationName,
                organizationTaxNumber: addedOrganization?.taxNumber,
                organizationType: addedOrganization?.organizationType
              }
            )
          );

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.organization'
          }),
          name: organization.organizationName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingOrganization(organization.organizationId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingOrganization(organization.organizationId, error));
  }
}

export function* watchAddOrganizationsStarted() {
  yield takeEvery(types.ORGANIZATION_ADD_STARTED, addOrganization);
}

/* -------------------------------------------------------------------------- */
/*                                    EDIT                                    */
/* -------------------------------------------------------------------------- */
function* editOrganization(action) {
  const organization = action.payload;
  const { oldOrganization } = yield select((state) =>
    selectors.getOrganization(state, organization.organizationId)
  );
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/save`, {
        method: 'POST',
        body: JSON.stringify(organization),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedOrganization = camelcaseKeys(jsonResult.data.organizationData);

        // const organizationContacts = camelcaseKeys(jsonResult.data.contacts);
        // const {
        //   entities: { contacts },
        //   result //order
        // } = normalize(organizationContacts, contactSchemas.contacts); //normalize data to byId and order
        // yield put(contactActions.completePartialFetchingContacts(contacts, result));

        yield put(actions.completeEditingOrganization(editedOrganization));
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.organization'
          }),
          name: editedOrganization.organizationName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        yield put(actions.failEditingOrganization(oldOrganization, error));
        // alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    yield put(actions.failEditingOrganization(oldOrganization, error));
    // alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchEditOrganizationsStarted() {
  yield takeEvery(types.ORGANIZATION_EDIT_STARTED, editOrganization);
}

/* -------------------------------------------------------------------------- */
/*                        SAVE CONSOLIDABLE EVENTS                             */
/* -------------------------------------------------------------------------- */
function* saveConsolidableEvents(action) {
  const { organization, consolidableEvents } = action.payload;

  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/organization/SaveConsolidableEvents`, {
        method: 'POST',
        body: JSON.stringify({ organizationId: organization.organizationId, consolidableEvents }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(
          actions.completeSavingConsolidableEvents(organization.organizationId, consolidableEvents)
        );
        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'organizations.consolidableEventsSavingSuccess'
          })
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        const { oldOrganization } = yield select((state) =>
          selectors.getOrganization(state.organizations, organization.organizationId)
        );
        yield put(actions.failSavingConsolidableEvents(oldOrganization, error));
        alerts.showErrorAlertFailChanges({ error });
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };

    const { oldOrganization } = yield select((state) =>
      selectors.getOrganization(state.organizations, organization.organizationId)
    );
    yield put(actions.failSavingConsolidableEvents(oldOrganization, error));
    alerts.showErrorAlertFailChanges({ error });
  }
}

export function* watchSaveConsolidableEventsStarted() {
  yield takeEvery(types.ORGANIZATION_SAVE_CONSOLIDABLE_EVENTS_STARTED, saveConsolidableEvents);
}

/* -------------------------------------------------------------------------- */
/*                                   REMOVE                                   */
/* -------------------------------------------------------------------------- */
function* removeOrganization(action) {
  const { organizationId } = action.payload;
  const organization = yield select((state) => selectors.getOrganization(state, organizationId));
  deleteOrganizationService(
    { ...organization },
    {
      successAction: (response) => {
        store.dispatch(actions.completeRemovingOrganization(organizationId));
      },
      errorAction: (error) => {
        store.dispatch(actions.failRemovingOrganization(organizationId, error));
      }
    }
  );
}

export function* watchRemoveOrganizationStarted() {
  yield takeEvery(types.ORGANIZATION_REMOVE_STARTED, removeOrganization);
}

/* -------------------------------------------------------------------------- */
/*                                 CODE CHECK                                 */
/* -------------------------------------------------------------------------- */
function* checkRegistrationCode(action) {
  const { organizationId, code } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/validateRegistrationCode`, {
        method: 'POST',
        body: JSON.stringify({ code, organizationId }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const validCode = jsonResult.data.RegistrationCodeStatus === 1;
        yield put(actions.completeCheckingCode(validCode));
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
  }
}

export function* watchRegistrationCodeStarted() {
  yield takeEvery(types.ORGANIZATION_CODE_CHECK_STARTED, checkRegistrationCode);
}

/* -------------------------------------------------------------------------- */
/*                                 CODE ASSIGN                                */
/* -------------------------------------------------------------------------- */
function* assigCode(action) {
  const {
    operatorId,
    organizationId,
    organizationName,
    registrationCode,
    registrationMails,
    registrationDivisionId,
    registrationSubdivisionId,
    allowedApplications
  } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/assignCode`, {
        method: 'POST',
        body: JSON.stringify({
          operatorId,
          organizationId,
          organizationName,
          registrationCode,
          registrationMails,
          registrationDivisionId,
          registrationSubdivisionId,
          allowedApplications
        }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(
          actions.completeAssigningCode(
            organizationId,
            registrationCode,
            allowedApplications,
            registrationDivisionId,
            registrationSubdivisionId
          )
        );
        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.organization'
          }),
          name: organizationName
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssigningCode(error));
  }
}

export function* watchCodeAssignStarted() {
  yield takeEvery(types.ORGANIZATION_CODE_ASSIGN_STARTED, assigCode);
}

/* -------------------------------------------------------------------------- */
/*                            ADD DOWNTIME REASON                              */
/* -------------------------------------------------------------------------- */
function* addOrganizationDowntimeReason(action) {
  const downtimeReason = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/saveDowntimeReason`, {
        method: 'POST',
        body: JSON.stringify({ ...downtimeReason, reasonId: null }),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const addedDowntimeReason = camelcaseKeys(jsonResult.data);
        yield put(
          actions.completeAddingOrganizationDowntimeReason(
            downtimeReason.reasonId,
            addedDowntimeReason
          )
        );

        alerts.showSuccessCreatedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.downtimeReason'
          }),
          name: downtimeReason.reasonDescription,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAddingOrganizationDowntimeReason(downtimeReason.reasonId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAddingOrganizationDowntimeReason(downtimeReason.reasonId, error));
  }
}

export function* watchAddOrganizationDowntimeReasonsStarted() {
  yield takeEvery(types.ORGANIZATION_DOWNTIME_REASON_ADD_STARTED, addOrganizationDowntimeReason);
}

/* -------------------------------------------------------------------------- */
/*                            EDIT DOWNTIME REASON                            */
/* -------------------------------------------------------------------------- */
function* editOrganizationDowntimeReason(action) {
  const downtimeReason = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/saveDowntimeReason`, {
        method: 'POST',
        body: JSON.stringify(downtimeReason),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const editedDowntimeReason = camelcaseKeys(jsonResult.data);
        yield put(actions.completeEditingOrganizationDowntimeReason(editedDowntimeReason));

        alerts.showSuccessEditedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.downtimeReason'
          }),
          name: downtimeReason.reasonDescription,
          altText: true
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failEditingOrganizationDowntimeReason(downtimeReason.reasonId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failEditingOrganizationDowntimeReason(downtimeReason.reasonId, error));
  }
}

export function* watchEditOrganizationDowntimeReasonsStarted() {
  yield takeEvery(types.ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED, editOrganizationDowntimeReason);
}

/* -------------------------------------------------------------------------- */
/*                            REMOVE DOWNTIME REASON                          */
/* -------------------------------------------------------------------------- */
function* removeOrganizationDowntimeReason(action) {
  const downtimeReason = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/organization/deleteDowntimeReason`, {
        method: 'POST',
        body: JSON.stringify(downtimeReason),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeRemovingOrganizationDowntimeReason(downtimeReason.reasonId));
        alerts.showSuccessDeletedMessage({
          object: appIntl().formatMessage({
            id: 'organizations.downtimeReason'
          }),
          name: downtimeReason.reasonDescription
        });
      } else {
        const error = {
          errorMessage: jsonResult.message,
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failRemovingOrganizationDowntimeReason(downtimeReason.reasonId, error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: err.toString(),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failRemovingOrganizationDowntimeReason(downtimeReason.reasonId, error));
  }
}

export function* watchRemoveOrganizationDowntimeReasonsStarted() {
  yield takeEvery(
    types.ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED,
    removeOrganizationDowntimeReason
  );
}
