import * as types from '../types';

export const startFetchingSims = (filters = {}) => ({
  type: types.SIMS_FETCH_STARTED,
  payload: { filters }
});
export const completeFetchingSims = (
  entities,
  order,
  operatorId,
  organizationId,
  divisionId = null,
  subdivisionId = null
) => ({
  type: types.SIMS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId,
    divisionId,
    subdivisionId
  }
});
export const failFetchingSims = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SIMS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingSims = (
  entityTypeId,
  operatorId = null,
  organizationId = null,
  divisionId = null,
  subdivisionId = null,
  allowNullOrganizationId
) => ({
  type: types.SIMS_PARTIAL_FETCH_STARTED,
  payload: {
    entityTypeId,
    operatorId,
    organizationId,
    divisionId,
    subdivisionId,
    allowNullOrganizationId
  }
});
export const completePartialFetchingSims = (
  entities,
  order,
  operatorId,
  organizationId,
  divisionId = null,
  subdivisionId = null
) => ({
  type: types.SIMS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId,
    divisionId,
    subdivisionId
  }
});
export const failPartialFetchingSims = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SIMS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingSim = (sim) => ({
  type: types.SIM_ADD_STARTED,
  payload: sim
});
export const completeAddingSim = (oldId, sim) => ({
  type: types.SIM_ADD_COMPLETED,
  payload: { oldId, sim }
});
export const failAddingSim = (oldId, error) => ({
  type: types.SIM_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingSim = (sim) => ({
  type: types.SIM_EDIT_STARTED,
  payload: sim
});
export const completeEditingSim = (sim) => ({
  type: types.SIM_EDIT_COMPLETED,
  payload: sim
});
export const failEditingSim = (oldSim, error) => ({
  type: types.SIM_EDIT_FAILED,
  payload: {
    oldSim,
    error
  }
});

export const startRemovingSim = (simId) => ({
  type: types.SIM_REMOVE_STARTED,
  payload: {
    simId
  }
});
export const completeRemovingSim = (simId) => ({
  type: types.SIM_REMOVE_COMPLETED,
  payload: {
    simId
  }
});
export const failRemovingSim = (simId, error) => ({
  type: types.SIM_REMOVE_FAILED,
  payload: {
    simId,
    error
  }
});

export const selectSim = (simId) => ({
  type: types.SIM_SELECTED,
  payload: simId
});

export const selectAllSims = (
  simIds = [] //List of SimIds
) => ({
  type: types.SIMS_ALL_SELECTED,
  payload: simIds
});

export const deselectSim = (simId) => ({
  type: types.SIM_DESELECTED,
  payload: simId
});

export const deselectAllSims = (
  simIds = [] //List of SimIds
) => ({
  type: types.SIMS_ALL_DESELECTED,
  payload: simIds
});
