export interface Redirect {
  push: (path: string) => void;
  path?: string;
}

export interface WithRedirectMeta {
  redirectMeta: Redirect;
}

export interface WithCallback {
  action: Function;
}

export interface TableRow {
  rowId: string;
  name: string;
}
export interface Question {
  questionId: string;
  name: string;
  type: number;
  isRequired: boolean;
  isPrivate: boolean;
  isCollapsed: boolean;
  answers?: any[];
}
export interface Section {
  sectionId: string;
  name: string;
  questions: Question[];
  rows: TableRow[];
  type: number;
  useDropDownRows: boolean;
  isCollapsed: boolean;
}

export interface Form {
  formId?: number;
  name?: string;
  description?: string;
  sections: Section[];
}

export interface MovableTypes {
  sourceId: String;
  sourceIndex: number;
  destinationId: String;
  destinationIndex: number;
  type: String;
  draggableId: String;
  source: any;
  destination: any;
}

/**
 * @description
 * This interface is used to define the properties of the columns of the table
 * Used types in order to dynamically type depending on the values
 * @example
 * if the header text is defined, the static text should not be defined
 */
export type TableColumn =
  | ({
      fieldName?: string;
      type?: 'dateTime' | 'number' | 'string' | 'boolean' | undefined;
      editType?: 'numericedit' | 'booleanedit' | 'datetimeedit' | 'stringedit' | undefined;
      headerTemplate?: string;
      allowEditing?: boolean;
      formatColumn?: string | undefined;
      metric?: string;
      customKey?: string;
      textAlign?: string;
      hide?: boolean;
      template?: (d: any) => JSX.Element | string;
      columns?: TableColumn[];
      edit?: any;
      editTemplate?: (params: any) => JSX.Element;
      maxWidth?: number;
    } & ( // header text or static text
      | {
          headerText?: string;
        }
      | {
          staticText?: string;
        }
    ) & // if tooltip is false must define a tooltip column
      (
        | {
            hasTooltip?: false;
            tooltipColumn?: never;
          }
        | {
            hasTooltip: true;
            tooltipColumn: string;
          }
      ))
  | undefined;

// number: VALUE
export interface NDict<T> {
  [key: number]: T;
}

// string: VALUE
export interface SDict<T> {
  [key: string]: T;
}

export type Dict<T> = NDict<T> | SDict<T>;

export type NDictionary<T> = { [key: number]: T };
export type SDictionary<T> = { [key: string]: T };
export type Dictionary<T> = NDictionary<T> | SDictionary<T>;

export interface AuthUser {
  username: string;
  permissions: string;
  authUserId: string;
  accessLevelId: string;
  rotateUnitMarkers: string;
  completeName: string;
  subdivisionId: string;
  subdivisionName: string;
  divisionId: string;
  divisionName: string;
  organizationId: string;
  organizationName: string;
  agentId: string;
  agentName: string;
  operatorId: string;
  operatorName: string;
  email: string;
  homeLatitude: string;
  homeLayer: string;
  homeLongitude: string;
  homeZoom: string;
  homeScreenId: string;
  currencyId: string;
  loadUnitsOnMap: string;
  loadGeofencesOnMap: string;
  activateUnitTrackMode: string;
  unitMapLabelAlwaysOn: string;
  locationMapLabelAlwaysOn: string;
  enableLocationClustering: string;
  useBingMaps: string;
  useHereMaps: string;
  useGoogleMaps: string;
  useMapquestMaps: string;
  metricSystemId: string;
  distanceSystem: string;
  capacitySystem: string;
  temperatureSystem: string;
  distance2System: string;
  speedSystem: string;
  fuelSystem: string;
  fuel2System: string;
  volumeSystem: string;
  utcZoneId: string;
  zoneName: string;
  utcOffset: string;
  bingMapsApiKey: string;
  googleMapsApiKey: string;
  hereMapsAppCode: string;
  hereMapsAppId: string;
  mapquestConsumerKey: string;
  imagesUrl: string;
  consoleDefaultSoundId: string;
  exp: number;
}

export type Char =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

export enum OperatingSystem {
  Windows = 'Windows',
  MacOS = 'Mac OS',
  Linux = 'Linux',
  IOS = 'iOS',
  Android = 'Android',
  Unknown = 'Unknown OS'
}

export enum Browser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Unknown = 'Unknown Browser'
}

export enum DeviceType {
  Mobile = 'Mobile',
  Desktop = 'Desktop'
}

export interface DeviceInfo {
  os: OperatingSystem;
  browser: Browser;
  deviceType: DeviceType;
}

export interface KeyboardModifiers {
  ctrl: boolean;
  alt: boolean;
  shift: boolean;
  parsedKey: string | undefined;
}

export const optionKeySpecialCharacters: Record<string, string> = {
  å: 'a',
  '∫': 'b',
  ç: 'c',
  '∂': 'd',
  '´': 'e',
  ƒ: 'f',
  '©': 'g',
  '˙': 'h',
  ˆ: 'i',
  '∆': 'j',
  '˚': 'k',
  '¬': 'l',
  µ: 'm',
  '˜': 'n',
  ø: 'o',
  π: 'p',
  œ: 'q',
  '®': 'r',
  ß: 's',
  '†': 't',
  '¨': 'u',
  '√': 'v',
  '∑': 'w',
  '≈': 'x',
  '¥': 'y',
  '¸': 'z'
};

export const optionShiftSpecialCombinations: Record<string, string> = {
  Å: 'a',
  ı: 'b',
  Ç: 'c',
  Î: 'd',
  '´': 'e',
  Ï: 'f',
  '˝': 'g',
  Ó: 'h',
  ˆ: 'i',
  Ô: 'j',
  '': 'k',
  Ò: 'l',
  Â: 'm',
  '˜': 'n',
  Ø: 'o',
  '"': 'p',
  Œ: 'q',
  '‰': 'r',
  Í: 's',
  ˇ: 't',
  '¨': 'u',
  '◊': 'v',
  '„': 'w',
  '˛': 'x',
  Á: 'y',
  '¸': 'z'
};

export enum PacketType {
  FuelCalibration = 1,
  FuelCalibrationCommand = 2
}

export enum FuelCalibrationCommand {
  SetMinValue = 1,
  SetMaxValue = 2
}

export interface DeviceProgrammerDTO<T> {
  PacketType: PacketType;
  DeviceIdentifier: string;
  PacketData: T;
}

export interface FuelCalibrationValuesPacket {
  Voltage: number;
  IsStable: boolean;
}

export interface FuelCalibrationCommandPacket {
  Command: FuelCalibrationCommand;
  Message?: string;
}

export interface FuelCalibrationCommandResultPacket {
  Command: FuelCalibrationCommand;
  Result?: 0 | 1 | -1; // 1: Success, 0: Error, -1: Busy
}
