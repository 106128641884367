const permissions = {
  addLocation: 'LO001',
  editLocation: 'LO002',
  removeLocation: 'LO003',
  searchLocation: 'LO004',
  listLocations: 'LO005',
  importLocation: 'LO006',
  listPrivateOrganizationLocations: 'LO007',
  listLocationsOnMap: 'LO008',
  searchLocations: 'LO009',
  searchExternalLocations: 'LO010'
};

export default permissions;
