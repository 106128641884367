import * as types from '../types';

export const startFetchingSensors = (filters = {}) => ({
  type: types.SENSORS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingSensors = (entities, order) => ({
  type: types.SENSORS_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingSensors = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SENSORS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingSensors = (filters = {}) => ({
  type: types.SENSORS_PARTIAL_FETCH_STARTED,
  payload: filters
});
export const completePartialFetchingSensors = (entities, order) => ({
  type: types.SENSORS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failPartialFetchingSensors = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.SENSORS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingSensor = (sensorId) => ({
  type: types.SENSOR_FETCH_STARTED,
  payload: sensorId
});

export const completeFetchingSensor = (sensor) => ({
  type: types.SENSOR_FETCH_COMPLETED,
  payload: sensor
});

export const failFetchingSensor = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.SENSOR_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingSensor = (sensor) => ({
  type: types.SENSOR_ADD_STARTED,
  payload: sensor
});
export const completeAddingSensor = (oldId, sensor) => ({
  type: types.SENSOR_ADD_COMPLETED,
  payload: { oldId, sensor }
});
export const failAddingSensor = (oldId, error) => ({
  type: types.SENSOR_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingSensor = (sensor) => ({
  type: types.SENSOR_EDIT_STARTED,
  payload: sensor
});
export const completeEditingSensor = (sensor) => ({
  type: types.SENSOR_EDIT_COMPLETED,
  payload: sensor
});
export const failEditingSensor = (oldSensor, error) => ({
  type: types.SENSOR_EDIT_FAILED,
  payload: {
    oldSensor,
    error
  }
});

export const startRemovingSensor = (sensorId) => ({
  type: types.SENSOR_REMOVE_STARTED,
  payload: {
    sensorId
  }
});
export const completeRemovingSensor = (sensorId) => ({
  type: types.SENSOR_REMOVE_COMPLETED,
  payload: {
    sensorId
  }
});
export const failRemovingSensor = (sensorId, error) => ({
  type: types.SENSOR_REMOVE_FAILED,
  payload: {
    sensorId,
    error
  }
});

export const selectSensor = (sensorId) => ({
  type: types.SENSOR_SELECTED,
  payload: sensorId
});

export const selectAllSensors = (
  sensorIds = [] //List of SensorIds
) => ({
  type: types.SENSORS_ALL_SELECTED,
  payload: sensorIds
});

export const deselectSensor = (sensorId) => ({
  type: types.SENSOR_DESELECTED,
  payload: sensorId
});

export const deselectAllSensors = (
  sensorIds = [] //List of SensorIds
) => ({
  type: types.SENSORS_ALL_DESELECTED,
  payload: sensorIds
});

export const startCheckingCode = (sensorId, identifier) => ({
  type: types.IDENTIFIER_CHECK_STARTED,
  payload: {
    sensorId,
    identifier
  }
});
export const completeCheckingCode = (result) => ({
  type: types.IDENTIFIER_CHECK_COMPLETED,
  payload: result
});
export const failCheckingCode = (error) => ({
  type: types.IDENTIFIER_CHECK_FAILED,
  payload: {
    error
  }
});

export const sensorCalibrationStartStarted = (sensorId) => ({
  type: types.START_SENSOR_CALIBRATION_STARTED,
  payload: {
    sensorId
  }
});
export const sensorCalibrationStartCompleted = (sensorId) => ({
  type: types.START_SENSOR_CALIBRATION_COMPLETED,
  payload: {
    sensorId
  }
});
export const sensorCalibrationStartFail = (sensorId, error) => ({
  type: types.START_SENSOR_CALIBRATION_FAILED,
  payload: {
    sensorId,
    error
  }
});

export const sensorCalibrationFinalizeStarted = (sensorId) => ({
  type: types.FINALIZE_SENSOR_CALIBRATION_STARTED,
  payload: {
    sensorId
  }
});
export const sensorCalibrationFinalizeCompleted = (sensorId) => ({
  type: types.FINALIZE_SENSOR_CALIBRATION_COMPLETED,
  payload: {
    sensorId
  }
});
export const sensorCalibrationFinalizeFail = (sensorId, error) => ({
  type: types.FINALIZE_SENSOR_CALIBRATION_FAILED,
  payload: {
    sensorId,
    error
  }
});
