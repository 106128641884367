/* -------------------------------------------------------------------------- */
/*                          JobStatusDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un JobStatusDropdownInput

import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
// import Icon from '../../../utility/icomoon';
import DropdownInput from '../../../../formComponents/DropdownInput';
import Icon from '../../../../../utility/icomoon';
import { getJobStatusIcon } from '../../utils';

const JobStatusDropdownInput = (props) => {
  const {
    forceinitialvalue = 8,
    field,
    additionalonchangefunction = (value) => null,
    disabled
  } = props;
  const intl = useIntl();
  const statuses = Array(8)
    .fill(null)
    .map((_, i) => ({
      id: i + 1,
      value: i + 1,
      label: `jobs.status.${i + 1}`,
      iconName: undefined
    }));

  return (
    <DropdownInput
      {...omit(props, ['forceinitialvalue'])}
      name="jobStatus"
      id={'jobStatus'}
      isDisabled={disabled}
      label={<FormattedMessage id="jobs.status" />}
      options={statuses.map((status) => {
        return {
          ...status,
          icon: <Icon icon={getJobStatusIcon(status.value)} />,
          label: <FormattedMessage id={`jobs.status.${status.value}`} />,
          label_intl: intl.formatMessage({ id: 'jobs.status.' + status.value })
        };
      })}
    />
  );
};

export default JobStatusDropdownInput;
