import { useEffect, useState } from 'react';
import { Minus } from 'react-feather';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Media, Row } from 'reactstrap';
import useDeepCompareEffect from 'use-deep-compare-effect';
import DefaultSimpleButton from 'views/components/DefaultSimpleButton';
import DropdownInput from 'views/formComponents/DropdownInput';
import MapsBasic from '../..';
import * as routeCheckpointsActions from '../../../../screens/Routes/actions/routeCheckpoints';
import * as routeSelectors from '../../../../screens/Routes/reducers/routes';

interface Props {
  routeId: any;
  route: any;
  title: any;
  confirmText: any;
  isOpenSlidingPane: any;
  closeSlidingPane: any;
  hasButtonSubmit: boolean;
  hasButtonCancel: boolean;
  isGeneratingRoute: boolean;
  alternateRoutes: any;
  setAlternateRoutes: any;
  membership: any;
}

const SlidingPaneAddAlternateRoute = (props: Props) => {
  const {
    routeId,
    route,
    title,
    confirmText,
    isOpenSlidingPane,
    closeSlidingPane,
    hasButtonSubmit,
    hasButtonCancel,
    isGeneratingRoute,
    alternateRoutes,
    setAlternateRoutes,
    membership
  } = props;

  const intl = useIntl();
  const dispatch = useDispatch();
  const { control, handleSubmit, watch } = useForm();
  const formValues = watch();
  const [rerenderMap, setRerenderMap] = useState<Date>(new Date());
  const [showOriginalRoute, setShowOriginalRoute] = useState<boolean>(true);
  const [showAlternateRoute, setShowAlternateRoute] = useState<boolean>(true);

  const { routes, routesOnMap } = useSelector((state) => {
    const selectedRoute = routeSelectors.getRoute(state, formValues?.alternateRoute);
    return {
      routes: routeSelectors.getRoutesListByMembership(state, membership),
      routesOnMap: [
        !isGeneratingRoute && showOriginalRoute ? route : undefined,
        selectedRoute && showAlternateRoute ? { ...selectedRoute, pathColor: 'darkRed' } : undefined
      ].filter((route) => route)
    };
  });
  useEffect(() => {
    if (formValues?.alternateRoute) {
      dispatch(routeCheckpointsActions.startFetchingRouteCheckpoints(formValues?.alternateRoute));
    }
  }, [formValues?.alternateRoute]);

  useDeepCompareEffect(() => {
    setRerenderMap(new Date());
  }, [routesOnMap]);

  return (
    <SlidingPane
      className="custom-sliding-pane-form"
      overlayClassName={'custom-overlay-sliding-pane-map'}
      isOpen={isOpenSlidingPane}
      title={title}
      width={'50rem'}
      onRequestClose={() => closeSlidingPane()}
    >
      <MapsBasic
        mapOptions={{
          className: '',
          style: { height: '40vh' },
          rerenderMap: rerenderMap
        }}
        mapRoutesOptions={
          routesOnMap?.length > 0
            ? {
                showRoutes: true,
                isMapFullScreen: false,
                useSlidingPaneRouteInformation: false,
                routes: routesOnMap
              }
            : {}
        }
        mapObjectsBoundsOptions={{
          setMapPositionToRouteBounds: true,
          routes: routesOnMap,
          rerenderMapObjectBounds: rerenderMap
        }}
      ></MapsBasic>
      <Row className={'mt-2'}>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'alternateRoute'}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <DropdownInput
                field={field}
                fieldstate={fieldState}
                id={'alternateRouteInput'}
                isClearable={false}
                isInModal
                required
                options={routes
                  .filter(
                    (route) =>
                      routeId !== route.routeId &&
                      !alternateRoutes.map((ar) => ar.routeId).includes(route.routeId) &&
                      (!formValues?.membership?.organizationId ||
                        formValues.membership.organizationId === route.organizationId)
                  )
                  .map((route) => ({
                    id: route.routeId,
                    value: route.routeId,
                    label: route.routeName
                  }))}
                label={intl.formatMessage({
                  id: 'routes.alternateRoute'
                })}
                placeholder={intl.formatMessage({ id: 'routes.routePlaceholder' })}
              />
            )}
          />
        </Col>
      </Row>
      <hr />
      <Row className={'mt-1 ml-50 mr-50'}>
        <Col sm="12">
          <Media>
            <Minus color={'blue'} />
            {intl.formatMessage({ id: 'routes.originalRoute' })}
            <DefaultSimpleButton
              className={'ml-50'}
              icon={showOriginalRoute ? 'EyeOff' : 'Eye'}
              color={'blue'}
              handleClick={() => setShowOriginalRoute(!showOriginalRoute)}
              id={'originalRouteEyeButton'}
            />
          </Media>
        </Col>
        <Col sm="12">
          <Media>
            <Minus color={'darkRed'} />
            {intl.formatMessage({ id: 'routes.alternateRoute' })}
            {formValues?.alternateRoute && (
              <DefaultSimpleButton
                className={'ml-50'}
                icon={showAlternateRoute ? 'EyeOff' : 'Eye'}
                color={'darkRed'}
                handleClick={() => setShowAlternateRoute(!showAlternateRoute)}
                id={'alternateRouteEyeButton'}
              />
            )}
          </Media>
        </Col>
      </Row>
      <Row className={'footer-slide-pane'}>
        <Col sm="12 d-flex ">
          {hasButtonSubmit && (
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="primary"
              /*type="submit"*/
              onClick={handleSubmit((values) => {
                setAlternateRoutes([
                  ...alternateRoutes,
                  ...routes.filter((route) => route.routeId === values.alternateRoute)
                ]);
                closeSlidingPane();
              })}
            >
              {confirmText || <FormattedMessage id="common.save" />}
            </Button.Ripple>
          )}
          {hasButtonCancel && (
            <Button.Ripple
              className="mr-1 mt-50 mb-50"
              color="light"
              onClick={() => closeSlidingPane()}
            >
              <FormattedMessage id="common.cancel" />
            </Button.Ripple>
          )}
        </Col>
      </Row>
    </SlidingPane>
  );
};

SlidingPaneAddAlternateRoute.defaultProps = {
  hasButtonSubmit: true,
  hasButtonCancel: true
};

export default SlidingPaneAddAlternateRoute;
