import { call, takeEvery, put, select } from 'redux-saga/effects';

import { normalize } from 'normalizr';
import API_BASE_URL from '../../../../../redux/sagas/settings/apibaseurl';

import * as actions from '../../actions/workplansExecutions';
import * as types from '../../types/workplansExecutions';
import * as selectors from '../../reducers/workplans';
import * as schemas from '../../schemas/workplansExecutions';
import * as schemasWorkplanExecutionLocation from '../../schemas/workplansExecutionLocations';

import * as authSelectors from '../../../../../redux/reducers/auth';
import camelcaseKeys from 'camelcase-keys';
import * as alerts from '../../../../../redux/utils/alerts';
import { useIntl } from 'react-intl';
import { appIntl } from '../../../../../utility/context/IntlGlobalProvider';
import { endDateAdjustment } from 'utility/http/interceptors/interceptor-utils';

/* -------------------------------------------------------------------------- */
/*                            FETCH LIST EXECUTIONS                           */
/* -------------------------------------------------------------------------- */
function* fetchWorkplansExecutions(action) {
  try {
    const filters = action.payload;
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/workplan/getWorkplansExecutions`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment({ ...filters })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const indicators = camelcaseKeys(jsonResult.data.indicators);

        const executionStatus = camelcaseKeys(jsonResult.data.executionStatus);

        const geographicStatus = camelcaseKeys(jsonResult.data.geographicStatus);

        const resultData = jsonResult.data.workplansList.map((result) => camelcaseKeys(result));
        const {
          entities: { workplansExecutions },
          result //order
        } = normalize(resultData, schemas.workplansExecutions); //normalize data to byId and order
        yield put(
          actions.completeFetchingWorkplansExecutions(
            workplansExecutions,
            result,
            indicators,
            executionStatus,
            geographicStatus
          )
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.workplanFetchMonitorMessageError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.workplanFetchMonitorMessageError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingWorkplansExecutions(error));
  }
}

export function* watchFetchWorkplansExecutions() {
  yield takeEvery(types.WORKPLANS_EXECUTIONS_FETCH_STARTED, fetchWorkplansExecutions);
}
/* -------------------------------------------------------------------------- */
/*                               FETCH EXECUTION                              */
/* -------------------------------------------------------------------------- */
function* fetchWorkplanExecution(action) {
  try {
    const workplanExecution = action.payload;
    const isAuth = yield select(authSelectors.isAuthenticated);
    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);
      const response = yield call(fetch, `${API_BASE_URL}/workplan/getWorkplanExecution`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment({ ...workplanExecution })),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        const workplanExecution = camelcaseKeys(jsonResult.data.workplanExecution);

        const resultData = jsonResult.data.locationsList.map((result) => camelcaseKeys(result));
        const {
          entities: { workplanExecutionLocations },
          result //order
        } = normalize(resultData, schemasWorkplanExecutionLocation.workplanExecutionLocations); //normalize data to byId and order

        yield put(
          actions.completeFetchingWorkplanExecution(
            workplanExecution,
            workplanExecutionLocations,
            result
          )
        );
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.fetchWorkplanExecutionError'
          }),
          errorNumber: jsonResult.number
        };
        // alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFetchingWorkplanExecution(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.fetchWorkplanExecutionError'
      }),
      errorNumber: -1
    };
    // alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFetchingWorkplanExecution(error));
  }
}

export function* watchFetchWorkplanExecution() {
  yield takeEvery(types.WORKPLAN_EXECUTION_FETCH_STARTED, fetchWorkplanExecution);
}

/* -------------------------------------------------------------------------- */
/*                          Cancel Workplan Execution                         */
/* -------------------------------------------------------------------------- */
function* cancelWorkplanExecution(action) {
  const workplan = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/cancelWorkplanExecution`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment(workplan)),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeCancelWorkplansExecutions(workplan.visitId, workplan.unitId));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.selectedWorkplanWasCanceled'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.workplanCancelationMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failCancelWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.workplanCancelationMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failCancelWorkplansExecutions(error));
  }
}

export function* watchCancelWorkplanExecution() {
  yield takeEvery(types.WORKPLANS_EXECUTION_CANCEL_STARTED, cancelWorkplanExecution);
}

/* -------------------------------------------------------------------------- */
/*                         Finalize Workplan Execution                        */
/* -------------------------------------------------------------------------- */
function* finalizeWorkplanExecution(action) {
  const workplan = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/finalizeWorkplanExecution`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment(workplan)),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeFinalizeWorkplansExecutions(workplan.visitId, workplan.unitId));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.selectedWorkplanWasFinished'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.workplanFinalizationMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failFinalizeWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.workplanFinalizationMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failFinalizeWorkplansExecutions(error));
  }
}

export function* watchFinalizeWorkplanExecution() {
  yield takeEvery(types.WORKPLANS_EXECUTION_FINALIZE_STARTED, finalizeWorkplanExecution);
}

/* -------------------------------------------------------------------------- */
/*                       Manual Transit Workplan Execution                       */
/* -------------------------------------------------------------------------- */
function* manualTransitWorkplanExecution(action) {
  const newStatus = 3;
  const { visitId, unitId, reason } = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(
        fetch,
        `${API_BASE_URL}/workplan/updateWorkplanExecutionManualTransit`,
        {
          method: 'POST',
          body: JSON.stringify({ visitId, unitId, reason }),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeManualTransitWorkplansExecutions(visitId, unitId));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.workplansManualTransitMessage'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.workplansManualTransitMessageError'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failManualTransitWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.workplansManualTransitMessageError'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failManualTransitWorkplansExecutions(error));
  }
}

export function* watchManualTransitWorkplanExecution() {
  yield takeEvery(types.WORKPLANS_EXECUTION_MANUAL_TRANSIT_STARTED, manualTransitWorkplanExecution);
}

/* -------------------------------------------------------------------------- */
/*                        Change Workplan Execution Unit                      */
/* -------------------------------------------------------------------------- */
function* changeWorkplanExecutionUnit(action) {
  const workplan = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/changeUnit`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment(workplan)),

        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (response.status <= 300 && jsonResult.success) {
        yield put(actions.completeChangeUnitWorkplansExecutions(workplan.visitId, workplan.unitId));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.unitWasSuccessfullyChanged'
          })
        });
      } else {
        const error = {
          errorMessage: appIntl().formatMessage({
            id: 'workplans.unitChangeIsNotPossibleRightNow'
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failChangeUnitWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.unitChangeIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failChangeUnitWorkplansExecutions(error));
  }
}

export function* watchChangeWorkplanExecutionUnit() {
  yield takeEvery(types.WORKPLANS_EXECUTION_CHANGE_UNIT_STARTED, changeWorkplanExecutionUnit);
}

/* -------------------------------------------------------------------------- */
/*                        Assign Workplan Execution Unit                      */
/* -------------------------------------------------------------------------- */
const getDatabaseErrorFromString = (text: string) => {
  const match: any = /(database\.error\.\w+\.\w+)/.exec(text);
  let result: string | undefined = undefined;
  try {
    result = match[1];
  } catch (error) {
    result = undefined;
  }
  return result;
};

function* assignWorkplanExecutionUnit(action) {
  const assignation = action.payload;
  try {
    const isAuth = yield select(authSelectors.isAuthenticated);

    if (isAuth) {
      const token = yield select(authSelectors.getAuthToken);

      const response = yield call(fetch, `${API_BASE_URL}/workplan/assignUnit`, {
        method: 'POST',
        body: JSON.stringify(endDateAdjustment(assignation)),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      const jsonResult = yield response.json();
      if (
        response.status <= 300 &&
        jsonResult.success &&
        jsonResult.data.length === assignation.length
      ) {
        yield put(actions.completeAssignUnitWorkplansExecutions(assignation));

        alerts.showSuccessMessage({
          success: appIntl().formatMessage({
            id: 'workplans.unitWasSuccessfullyAssigned'
          })
        });
      } else {
        const jsonErrorMessage = jsonResult.message;
        const typeJsonErrorMessage = typeof jsonResult.message;
        const errorMessage: string =
          response.status === 500
            ? getDatabaseErrorFromString(jsonResult.message) ??
              'workplans.unitAssignmentIsNotPossibleRightNow'
            : 'workplans.unitAssignmentIsNotPossibleRightNow';
        // Validates status code is 500 server error
        const error: any = {
          errorMessage: appIntl().formatMessage({
            id: errorMessage
          }),
          errorNumber: jsonResult.number
        };
        alerts.showErrorAlertFailChanges({ error });
        yield put(actions.failAssignUnitWorkplansExecutions(error));
      }
    }
  } catch (err) {
    const error = {
      errorMessage: appIntl().formatMessage({
        id: 'workplans.unitAssignmentIsNotPossibleRightNow'
      }),
      errorNumber: -1
    };
    alerts.showErrorAlertFailChanges({ error });
    yield put(actions.failAssignUnitWorkplansExecutions(error));
  }
}

export function* watchAsignWorkplanExecutionUnit() {
  yield takeEvery(types.WORKPLANS_EXECUTION_ASSIGN_UNIT_STARTED, assignWorkplanExecutionUnit);
}
