import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';
import { appIntl } from 'utility/context/IntlGlobalProvider';

export const baseUrl = '/maintenanceLine';

export const getMaintenanceLinesListService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'maintenanceLines.maintenanceLine',
    action: 'Get',
    genderType: 'PluralFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const saveMaintenanceLineService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'maintenanceLines.maintenanceLine',
    action: request.maintenanceLineId ? 'Edit' : 'Create',
    name: request.maintenanceLineName,
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/save`, request);
};

export const deleteMaintenanceLineService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    name: `${request.maintenanceLineName}`,
    object: 'maintenanceLines.maintenanceLine',
    action: 'Delete',
    genderType: 'SingularFemale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/delete`, request);
};

export const copyMaintenanceLineService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  const intl = appIntl();
  httpWebtrack.setConfigMessage({
    name: `${request.maintenanceLineName}`,
    object: 'maintenanceLines.maintenanceLine',
    action: 'Custom',
    genderType: 'SingularFemale',
    customMessage: intl.formatMessage({
      id: 'maintenanceLines.copySuccessful'
    }),
    customMessageError: intl.formatMessage({
      id: 'maintenanceLines.copyError'
    })
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/copy`, request);
};

export const importMaintenanceLinesService = (request: any, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importMaintenanceLines`, request);
};
