// @ts-nocheck
import { useLeafletContext } from '@react-leaflet/core';
import L from 'leaflet';
import 'leaflet.icon.glyph';
import React, { Fragment, useEffect, useState } from 'react';
import { Circle, GeoJSON, Polygon, Tooltip, Rectangle, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import wkx from 'wkx';
import * as geofenceActions from '../../../../screens/Geofences/actions';
import * as geofenceSelectors from '../../../../screens/Geofences/reducers';
import SlidingpanelGeofenceInformation from '../../../../screens/Geofences/views/SlidingpanelGeofenceInformation';
import MarkerClusterGroup from '../MarkerClusterGroup';

export interface MapGeofencesOptions {
  isSelectedSomething?: any;
  setIsSelectedSomething?: Function;
  setMapPositionToGeofenceBounds?: boolean;
  useSlidingpanelGeofenceInformation?: boolean;
  usePopovers?: boolean;
  geofences?: any[];
  selectedGeofenceIds?: any[];
  showGeofences?: boolean;
  clusterMarkers?: boolean;
  isMapFullScreen?: boolean;
  useIsShownGeofence?: boolean; //param only when you have a property in each geofence isShownInMap to show geofences on map
  initialShowSlidingPanelGeofenceInformationOpen?: boolean;
  initialGeofenceInformationOpenId?: any;
  editingGeofenceParent?: any;
  setEditingGeofenceParent?: Function;
  editingGeofenceParentRef?: any;
  startClusteringAt?: number; // receives the zoom level where it starts clustering
}

const WKBtoGeoJSON = (wkt) => {
  // let wkbBuffer = new Buffer(hex);
  let geometry = wkx.Geometry.parse(wkt);

  return geometry.toGeoJSON();
};

export const zoomMapToGeofence = (map, isMapFullScreen, geofence) => {
  if (geofence.shape == 'C') {
    let geofenceGeoJSON = WKBtoGeoJSON(geofence.geofenceWktString);
    let feature = L.latLng(geofenceGeoJSON.coordinates[1], geofenceGeoJSON.coordinates[0]);
    map.flyToBounds(
      feature.toBounds(geofence.radius * 2.5 ?? 300),
      isMapFullScreen
        ? {
            paddingBottomRight: [550, 0],
            paddingTopLeft: [0, 70],
            maxZoom: 15,
            animate: 15 !== map.getZoom()
          }
        : {
            maxZoom: 15,
            animate: 15 !== map.getZoom()
          }
    );
  } else {
    let geofenceGeoJSON = WKBtoGeoJSON(geofence?.geofenceWktString);
    let feature = L.geoJson(geofenceGeoJSON);
    map.flyToBounds(
      feature.getBounds(),
      isMapFullScreen
        ? {
            paddingBottomRight: [550, 0],
            paddingTopLeft: [0, 70],
            maxZoom: 15,
            animate: 15 !== map.getZoom()
          }
        : {
            maxZoom: 15,
            animate: 15 !== map.getZoom()
          }
    );
  }
};

const MapGeofences = (props: MapGeofencesOptions) => {
  const context = useLeafletContext();
  const map = useMapEvents({
    moveend: () => {
      displayGeofences();
    }
  });
  const {
    isSelectedSomething,
    setIsSelectedSomething,
    showGeofences = false,
    useIsShownGeofence = false,
    geofences = [],
    selectedGeofenceIds,
    setMapPositionToGeofenceBounds = false,
    useSlidingpanelGeofenceInformation = true,
    clusterMarkers = false,
    usePopovers,
    isMapFullScreen = false,
    initialShowSlidingPanelGeofenceInformationOpen = false,
    initialGeofenceInformationOpenId,
    editingGeofenceParent,
    setEditingGeofenceParent,
    editingGeofenceParentRef,
    startClusteringAt = undefined
  } = props;
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showGeofenceInformationSlidepanel, setShowGeofenceInformationSlidepanel] = useState(false);
  const [initialGeofenceId, setInitialGeofenceId] = useState<any>();
  const [selectedGeofence, setSelectedGeofence] = useState<any>({});
  const { isEditing, isAdding, mainGeofence } = useSelector((state) => ({
    isEditing: geofenceSelectors.isEditingGeofence(state),
    isAdding: geofenceSelectors.isAddingGeofence(state),
    mainGeofence: geofenceSelectors.getGeofence(
      state,
      editingGeofenceParentRef?.current?.parentGeofenceId
    )
  }));

  useEffect(() => {
    if (mainGeofence?.geofenceId) {
      zoomMapToGeofence(map, isMapFullScreen, mainGeofence);
      dispatch(geofenceActions.showGeofenceInMap(mainGeofence.geofenceId));
      return () => {
        dispatch(geofenceActions.hideGeofenceInMap(mainGeofence.geofenceId));
      };
    }
  }, [mainGeofence?.geofenceId]);

  useEffect(() => {
    if (setIsSelectedSomething) {
      setIsSelectedSomething({
        ...isSelectedSomething,
        geofences: selectedGeofence?.geofenceId != null
      });
    }
  }, [selectedGeofence]);

  useEffect(() => {
    if (!isEditing && selectedGeofence.geofenceId) {
      setSelectedGeofence(geofenceSelectors.getGeofence(store, selectedGeofence.geofenceId));
      setShowGeofenceInformationSlidepanel(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (setMapPositionToGeofenceBounds && map && geofences.length > 0) {
      if (geofences.length == 1) {
        zoomMapToGeofence(map, isMapFullScreen, geofences[0]);
      } else {
        var bounds = [];
        geofences.map((geofence) => {
          let geofenceGeoJSON;

          geofenceGeoJSON = WKBtoGeoJSON(geofence.geofenceWktString);
          if (geofence.shape == 'C') {
            bounds = [
              ...bounds,
              L.latLng(geofenceGeoJSON.coordinates[1], geofenceGeoJSON.coordinates[0])
            ];
          } else if (geofence.shape == 'R') {
            bounds = [
              ...bounds,
              ...geofenceGeoJSON.coordinates[0].map((coords) => L.latLng([coords[1], coords[0]]))
            ];
          } else if (geofence.shape == 'P') {
            bounds = [
              ...bounds,
              ...(geofenceGeoJSON.type == 'LineString'
                ? geofenceGeoJSON.coordinates.map((coords) => L.latLng([coords[1], coords[0]]))
                : geofenceGeoJSON.coordinates[0].map((coords) => L.latLng([coords[1], coords[0]])))
            ];
          } else if (geofence.shape == 'U') {
            bounds = [
              ...bounds,
              _.flatten(
                geofenceGeoJSON.coordinates.map((coordinate) =>
                  coordinate.map((coords) =>
                    coords[1] && coords[0] ? L.latLng([coords[1], coords[0]]) : null
                  )
                )
              )
            ];
          }
        });
        map.fitBounds(
          L.latLngBounds(bounds.filter((bound) => bound != null && bound instanceof L.LatLng))
        );
      }
    }
  }, [geofences.length, setMapPositionToGeofenceBounds]);

  //Open and select geofence if params are set
  useEffect(() => {
    if (initialShowSlidingPanelGeofenceInformationOpen && useSlidingpanelGeofenceInformation) {
      if (initialGeofenceInformationOpenId) {
        const initialGeofenceInformation = geofenceSelectors.getGeofence(
          store,
          initialGeofenceInformationOpenId
        );

        if (
          initialGeofenceId !== initialGeofenceInformationOpenId &&
          initialGeofenceInformation.shape
        ) {
          setInitialGeofenceId(initialGeofenceInformation.geofenceId);
          setSelectedGeofence(initialGeofenceInformation);
          zoomMapToGeofence(map, isMapFullScreen, initialGeofenceInformation);
        }
        setShowGeofenceInformationSlidepanel(true);
      }
    } else {
      setInitialGeofenceId(undefined);
      setSelectedGeofence({});
      setShowGeofenceInformationSlidepanel(false);
    }
  }, [initialGeofenceInformationOpenId]);
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  // Check if you want to cluster markers
  let WrapperMarkerClusterGroup = Fragment;
  const wrapperProps = {};
  if (clusterMarkers) {
    WrapperMarkerClusterGroup = MarkerClusterGroup;
    wrapperProps['disableClusteringAtZoom'] = startClusteringAt;
  }

  const [geofencesBounds, setGeofencesBounds] = useState([]);
  const displayGeofences = () => {
    setGeofencesBounds(
      geofences.filter((geofence) => {
        if (!geofence.geofenceWktString) return false;
        let geofenceGeoJSON = WKBtoGeoJSON(geofence.geofenceWktString);
        let feature = L.geoJson(geofenceGeoJSON);
        return map
          ? map.getBounds().contains(feature.getBounds()) ||
              map.getBounds().overlaps(feature.getBounds())
          : true;
      })
    );
  };
  useDeepCompareEffect(() => {
    displayGeofences();
  }, [showGeofences, isEditing, isAdding, geofences]);

  const onClickGeofence = (e, geofence) => {
    if (editingGeofenceParentRef?.current) {
      dispatch(geofenceActions.showGeofenceInMap(editingGeofenceParentRef?.current.geofenceId));
      if (setEditingGeofenceParent) setEditingGeofenceParent(undefined);
    }

    setSelectedGeofence({ ...geofence, layer: e.target });
    if (useSlidingpanelGeofenceInformation) {
      setShowGeofenceInformationSlidepanel(true);
    }
    zoomMapToGeofence(map, isMapFullScreen, geofence);
  };

  return (
    <>
      {(showGeofences || useIsShownGeofence) && (
        <WrapperMarkerClusterGroup {...wrapperProps}>
          {geofencesBounds.map((geofence) => {
            const geofenceGeoJSON = WKBtoGeoJSON(geofence.geofenceWktString);
            if (
              geofenceGeoJSON.type == 'MultiPolygon' ||
              geofenceGeoJSON.type == 'GeometryCollection'
            ) {
              console.log('geofence = ', geofence.geofenceName);
            }
            return (
              ((showGeofences && !useIsShownGeofence) ||
                (useIsShownGeofence && geofence.isShownInMap)) && (
                <div key={geofence.geofenceId}>
                  {geofence.shape == 'C' && (
                    <>
                      <Circle
                        zIndexOffset={
                          selectedGeofenceIds &&
                          selectedGeofenceIds.length > 0 &&
                          selectedGeofenceIds.includes(geofence.geofenceId)
                            ? 850
                            : 800
                        }
                        center={L.latLng(
                          geofenceGeoJSON.coordinates[1],
                          geofenceGeoJSON.coordinates[0]
                        )}
                        radius={geofence.radius}
                        pathOptions={{
                          color:
                            selectedGeofenceIds?.length > 0 &&
                            !selectedGeofenceIds.includes(geofence.geofenceId)
                              ? 'rgba(128, 128, 128, 0.6)'
                              : geofence.color ?? primary
                        }}
                        eventHandlers={{
                          click: (e) => {
                            onClickGeofence(e, geofence);
                          }
                        }}
                      >
                        {usePopovers && (
                          <Tooltip direction={'top'} offset={[0, 0]}>
                            <b className="text-center" style={{ fontSize: '14px' }}>
                              {geofence.geofenceName}
                            </b>
                          </Tooltip>
                        )}
                      </Circle>
                    </>
                  )}
                  {geofence.shape == 'R' && (
                    <>
                      <Rectangle
                        zIndexOffset={
                          selectedGeofenceIds &&
                          selectedGeofenceIds.length > 0 &&
                          selectedGeofenceIds.includes(geofence.geofenceId)
                            ? 850
                            : 800
                        }
                        bounds={geofenceGeoJSON.coordinates[0].map((coords) => [
                          coords[1],
                          coords[0]
                        ])}
                        pathOptions={{
                          color:
                            selectedGeofenceIds &&
                            selectedGeofenceIds.length > 0 &&
                            !selectedGeofenceIds.includes(geofence.geofenceId)
                              ? 'gray'
                              : geofence.color ?? primary
                        }}
                        eventHandlers={{
                          click: (e) => {
                            onClickGeofence(e, geofence);
                          }
                        }}
                      >
                        {usePopovers && (
                          <Tooltip direction={'top'} offset={[0, 0]}>
                            <b className="text-center" style={{ fontSize: '14px' }}>
                              {geofence.geofenceName}
                            </b>
                          </Tooltip>
                        )}
                      </Rectangle>
                    </>
                  )}

                  {geofence.shape == 'P' &&
                    ['MultiPolygon', 'GeometryCollection'].includes(geofenceGeoJSON.type) && (
                      <>
                        <GeoJSON
                          zIndexOffset={
                            selectedGeofenceIds &&
                            selectedGeofenceIds.length > 0 &&
                            selectedGeofenceIds.includes(geofence.geofenceId)
                              ? 850
                              : 800
                          }
                          data={geofenceGeoJSON}
                          pathOptions={{
                            color:
                              selectedGeofenceIds &&
                              selectedGeofenceIds.length > 0 &&
                              !selectedGeofenceIds.includes(geofence.geofenceId)
                                ? 'gray'
                                : geofence.color ?? primary
                          }}
                          eventHandlers={{
                            click: (e) => {
                              onClickGeofence(e, geofence);
                            }
                          }}
                        >
                          {usePopovers && (
                            <Tooltip direction={'top'} offset={[0, 0]}>
                              <b className="text-center" style={{ fontSize: '14px' }}>
                                {geofence.geofenceName}
                              </b>
                            </Tooltip>
                          )}
                        </GeoJSON>
                      </>
                    )}

                  {geofence.shape == 'P' &&
                    !['MultiPolygon', 'GeometryCollection'].includes(geofenceGeoJSON.type) && (
                      <>
                        <Polygon
                          zIndexOffset={
                            selectedGeofenceIds &&
                            selectedGeofenceIds.length > 0 &&
                            selectedGeofenceIds.includes(geofence.geofenceId)
                              ? 850
                              : 800
                          }
                          positions={
                            geofenceGeoJSON.type == 'LineString'
                              ? geofenceGeoJSON.coordinates.map((coords) => [coords[1], coords[0]])
                              : geofenceGeoJSON.coordinates[0].map((coords) => [
                                  coords[1],
                                  coords[0]
                                ])
                          }
                          pathOptions={{
                            color:
                              selectedGeofenceIds &&
                              selectedGeofenceIds.length > 0 &&
                              !selectedGeofenceIds.includes(geofence.geofenceId)
                                ? 'gray'
                                : geofence.color ?? primary
                          }}
                          eventHandlers={{
                            click: (e) => {
                              onClickGeofence(e, geofence);
                            }
                          }}
                        >
                          {usePopovers && (
                            <Tooltip direction={'top'} offset={[0, 0]}>
                              <b className="text-center" style={{ fontSize: '14px' }}>
                                {geofence.geofenceName}
                              </b>
                            </Tooltip>
                          )}
                        </Polygon>
                      </>
                    )}

                  {geofence.shape == 'U' && (
                    <GeoJSON
                      zIndexOffset={
                        selectedGeofenceIds &&
                        selectedGeofenceIds.length > 0 &&
                        selectedGeofenceIds.includes(geofence.geofenceId)
                          ? 850
                          : 800
                      }
                      // pmIgnore={true}
                      style={{
                        color:
                          selectedGeofenceIds &&
                          selectedGeofenceIds.length > 0 &&
                          !selectedGeofenceIds.includes(geofence.geofenceId)
                            ? 'gray'
                            : geofence.color
                      }}
                      data={geofenceGeoJSON}
                    >
                      {usePopovers && (
                        <Tooltip direction={'top'} offset={[0, 0]}>
                          <b className="text-center" style={{ fontSize: '14px' }}>
                            {geofence.geofenceName}
                          </b>
                        </Tooltip>
                      )}
                    </GeoJSON>
                  )}
                </div>
              )
            );
          })}
        </WrapperMarkerClusterGroup>
      )}

      <SlidingpanelGeofenceInformation
        isLoading={isEditing}
        closeSlidepanel={() => {
          setShowGeofenceInformationSlidepanel(false);
          setSelectedGeofence({});
        }}
        isSlidepanelOpen={showGeofenceInformationSlidepanel}
        title={selectedGeofence.geofenceName}
        geofence={selectedGeofence}
        editingGeofenceParent={editingGeofenceParent}
        editingGeofenceParentRef={editingGeofenceParentRef}
        setEditingGeofenceParent={setEditingGeofenceParent}
      />
    </>
  );
};

MapGeofences.defaultProps = {
  setMapPositionToGeofenceBounds: false,
  useSlidingpanelGeofenceInformation: true,
  geofences: [],
  showGeofences: false,
  clusterMarkers: false,
  isMapFullScreen: false,
  initialShowSlidingPanelGeofenceInformationOpen: false,
  usePopovers: true,
  initialGeofenceInformationOpenId: undefined,
  useIsShownGeofence: false
};

export default MapGeofences;
