export const DRIVERS_FETCH_STARTED = 'DRIVERS_FETCH_STARTED';
export const DRIVERS_FETCH_COMPLETED = 'DRIVERS_FETCH_COMPLETED';
export const DRIVERS_FETCH_FAILED = 'DRIVERS_FETCH_FAILED';

export const DRIVERS_PARTIAL_FETCH_STARTED = 'DRIVERS_PARTIAL_FETCH_STARTED';
export const DRIVERS_PARTIAL_FETCH_COMPLETED = 'DRIVERS_PARTIAL_FETCH_COMPLETED';
export const DRIVERS_PARTIAL_FETCH_FAILED = 'DRIVERS_PARTIAL_FETCH_FAILED';

export const DRIVER_ADD_STARTED = 'DRIVER_ADD_STARTED';
export const DRIVER_ADD_COMPLETED = 'DRIVER_ADD_COMPLETED';
export const DRIVER_ADD_FAILED = 'DRIVER_ADD_FAILED';

export const DRIVER_EDIT_STARTED = 'DRIVER_EDIT_STARTED';
export const DRIVER_EDIT_COMPLETED = 'DRIVER_EDIT_COMPLETED';
export const DRIVER_EDIT_FAILED = 'DRIVER_EDIT_FAILED';

export const DRIVER_REMOVE_STARTED = 'DRIVER_REMOVE_STARTED';
export const DRIVER_REMOVE_COMPLETED = 'DRIVER_REMOVE_COMPLETED';
export const DRIVER_REMOVE_FAILED = 'DRIVER_REMOVE_FAILED';

export const DRIVER_SELECTED = 'DRIVER_SELECTED';
export const DRIVERS_ALL_SELECTED = 'DRIVERS_ALL_SELECTED';
export const DRIVER_DESELECTED = 'DRIVER_DESELECTED';
export const DRIVERS_ALL_DESELECTED = 'DRIVERS_ALL_DESELECTED';

export const START_IMPORT_DRIVERS_UNITS_IMPORT = 'START_IMPORT_DRIVERS_UNITS_IMPORT';
export const COMPLETE_IMPORT_DRIVERS_UNITS_IMPORT = 'COMPLETE_IMPORT_DRIVERS_UNITS_IMPORT';

export const START_MASSIVE_DRIVERS_ASSIGNMENT = 'START_MASSIVE_DRIVERS_ASSIGNMENT';
export const COMPLETE_MASSIVE_DRIVERS_ASSIGNMENT = 'COMPLETE_MASSIVE_DRIVERS_ASSIGNMENT';

export const DRIVER_ASSIGN_SENSOR_STARTED = 'DRIVER_ASSIGN_SENSOR_STARTED';
export const DRIVER_ASSIGN_SENSOR_COMPLETED = 'DRIVER_ASSIGN_SENSOR_COMPLETED';

export const DRIVER_UNASSIGN_SENSOR_STARTED = 'DRIVER_UNASSIGN_SENSOR_STARTED';
export const DRIVER_UNASSIGN_SENSOR_COMPLETED = 'DRIVER_UNASSIGN_SENSOR_COMPLETED';

export interface Driver {
  isSelected: boolean;
  isShownInMap: boolean;
  driverId: number;
  driverName: string;
  driverPhotoUrl: string;
  driverPhoto: null | string;
  driverStatus: number;
  birthDate: string | null;
  identificationNumber: null | string;
  licenseExpireDate: string | null;
  privateCode: null | string;
  licenseType: null | string;
  licenseNumber: null | string;
  dallasKey: null | string;
  bloodType: null | string;
  phoneNumber: null | string;
  useGlasses: number | null;
  bloodSugarProblems: number | null;
  experience: null | string;
  gender: number | null;
  workDate: string | null;
  operatorId: number;
  operatorName: string;
  organizationId: number;
  organizationName: string;
  divisionId: number | null;
  divisionName: string | null;
  subdivisionId: number | null;
  subdivisionName: string | null;
  sensorId: null | number;
  sensorName: null | string;
  totalFiles: number;
  isConfirmed: boolean;
}
