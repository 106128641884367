import paths from './paths';
import permissions from './permissions';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'optimizations',
    title: 'optimizations.optimizations',
    iconName: 'Zap',
    permissions: [permissions.viewOptimizations],
    children: [
      {
        id: 'optimizationsCatalog',
        title: 'sidebar.catalog',
        iconName: 'Catalog',
        permissions: [permissions.viewOptimizations],
        navLink: paths.listOptimization,
        screenId: screens.OPTIMIZATIONS
      }
    ]
  }
];
