import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as authTypes from '../../../../../redux/types/auth';
import * as types from '../../types/board';
import * as organizationSelectors from 'views/screens/Organizations/reducers';
import * as divisionSelectors from 'views/screens/Divisions/reducers';
import * as subdivisionSelectors from 'views/screens/Subdivisions/reducers';
import localforage from 'localforage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';
import { user } from 'redux/schemas/organizations-users';

const byId = (state = {}, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return {};
    }
    //TYPES COMPLETED FETCH
    case types.JOBS_BOARD_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((jobId) => {
        newState[jobId] = {
          isSelected: false,
          isShowInMap: false,
          ...state[jobId],
          ...entities[jobId],
          isConfirmed: true
        };
      });
      return newState;
    }

    //CASE TYPE JOBS CHANGE STATUS
    case types.JOBS_BOARD_CHANGE_STATUS_COMPLETED: {
      const { jobs, jobStatus } = action.payload;
      const newState = { ...state };
      jobs.split('^').forEach((jobId) => {
        newState[jobId] = {
          ...state[jobId],
          jobStatus: jobStatus
        };
      });
      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return [];
    }
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.JOBS_BOARD_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BOARD_FETCH_STARTED: {
      return true;
    }
    case types.JOBS_BOARD_FETCH_COMPLETED:
    case types.JOBS_BOARD_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isChangingStatus = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.JOBS_BOARD_CHANGE_STATUS_STARTED: {
      return true;
    }
    case types.JOBS_BOARD_CHANGE_STATUS_COMPLETED:
    case types.JOBS_BOARD_CHANGE_STATUS_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_BOARD_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_BOARD_FETCH_STARTED:
    case types.JOBS_BOARD_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorChangingStatus = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.JOBS_BOARD_CHANGE_STATUS_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.JOBS_BOARD_CHANGE_STATUS_STARTED:
    case types.JOBS_BOARD_CHANGE_STATUS_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const jobsBoard: any = combineReducers({
  byId,
  order,
  isFetchingList,
  isChangingStatus,
  errorFetchingList,
  errorChangingStatus
});

const jobsBoardPersistedConfig = {
  key: 'jobsBoard',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  blacklist: ['isFetchingList', 'isChangingStatus'],
  whitelist: ['byId', 'order']
};

export default persistReducer(jobsBoardPersistedConfig, jobsBoard);

//Own state
export const getOwnState = (state) => state.jobs.jobsBoard;

//Information
export const getJobBoard = (state, jobId) => {
  const jobBoard = getOwnState(state).byId[jobId];
  if (jobBoard) {
    return {
      ...jobBoard,
      usersArray: jobBoard.users ? jobBoard.users.split(';').map((u) => u.split(',')[1]) : []
    };
  } else return undefined;
};

export const getJobsBoardList = (state) =>
  getOwnState(state)
    .order.map((id) => getJobBoard(state, id))
    .filter((jobBoard) => jobBoard && jobBoard.jobStatus > 0);

export const getJobsBoardListByFilters = (state, filters) => {
  const jobsBoard = getJobsBoardList(state).filter(
    (job) =>
      ((filters?.users?.length > 0 && !filters?.groupUsers)
        ? filters.users.filter((user) => job?.users?.includes(user)).length > 0
        : true) && (filters?.jobTypes?.length > 0 ? filters.jobTypes.includes(job.jobTypeId) : true)
  );
  return filters?.groupUsers
    ? jobsBoard.reduce(
        (arr, item) => [
          ...arr,
          ...item.usersArray
            .filter((user) => ((filters?.users?.length > 0 && !filters?.groupUsers) ? filters.users.includes(user) : true))
            .map((user) => ({ ...item, uniqueUser: user }))
        ],
        []
      )
    : jobsBoard;
};

export const getJobsBoardUsersList = (state) => {
  return getJobsBoardList(state).reduce((arr, item) => {
    if (item.users) {
      const newArr = item.users
        .split(';')
        .map((u) => {
          const [userName, completeName, userImageUrl] = u.split(',');
          if (!arr.map((user) => user.userName).includes(userName))
            return {
              userName,
              completeName,
              userImageUrl
            };
        })
        .filter((user) => user);
      if (newArr.length > 0) return [...arr, ...newArr];
    }
    return arr;
  }, []);
};

//Status of sagas
export const isFetchingListJobsBoard = (state) => getOwnState(state).isFetchingList;
export const isChangingStatusJobBoard = (state) => getOwnState(state).isChangingStatus;

//Errors
export const getFetchingListJobsBoardErrors = (state) => getOwnState(state).errorFetchingList;
export const getChangingStatusJobBoardErrors = (state) => getOwnState(state).errorChangingStatus;
