/* -------------------------------------------------------------------------- */
/*                          GeofenceDropdownInput                             */
/* -------------------------------------------------------------------------- */
// Este componente utiliza DropdownInput para generar un GeofenceDropdownInput
// @ts-nocheck
import { env } from 'env';
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { DefaultRootState } from 'redux/storeConfig/types';
import FetchEntities from 'views/screens/Entities/views/FetchEntities';
import * as geofenceSelectors from 'views/screens/Geofences/reducers';
import wkx from 'wkx';
import DropdownInput from '../DropdownInput';
const logo = require(`@src/assets/images/logo/${env.REACT_APP_FLAVOR}logo.svg`).default;

const GeofencesDropdownInput = (props) => {
  const {
    geofencesFilters,
    useLabel = true,
    showOrganization = false,
    parentGeofences = false,
    subGeofences = false,
    subGeofenceWktString
  } = props;
  const { geofences, isFetching } = useSelector((state: DefaultRootState) => ({
    geofences: parentGeofences
      ? geofenceSelectors.getParentGeofencesList(state).filter((geofence) => {
          if (!geofence.geofenceWktString || !subGeofenceWktString) return false;
          let mainGeofence = L.geoJson(wkx.Geometry.parse(geofence.geofenceWktString).toGeoJSON());
          let subGeofence = L.geoJson(wkx.Geometry.parse(subGeofenceWktString).toGeoJSON());
          return mainGeofence.getBounds().contains(subGeofence.getBounds());
        })
      : subGeofences
      ? geofenceSelectors.getSubGeofencesList(state)
      : geofenceSelectors.getGeofencesList(state),
    isFetching:
      geofenceSelectors.isFetchingListGeofences(state) ||
      geofenceSelectors.isPartialFetchingListGeofences(state)
  }));
  //Reset value with initial when values are loaded from db
  const [resetValue, setResetValue] = useState(Date.now());
  const resetDropdown = () => {
    setResetValue(Date.now());
  };

  //Update reactselect values of  geofences when fetched is finished
  useEffect(() => {
    if (!isFetching) {
      resetDropdown();
    }
  }, [isFetching, props?.options?.length]);

  return (
    <>
      {!props.options && <FetchEntities useGeofences geofencesFilters={geofencesFilters ?? {}} />}
      <DropdownInput
        {...props}
        key={resetValue}
        isLoading={isFetching}
        label={useLabel ? props.label || <FormattedMessage id="geofences.geofence" /> : null}
        options={
          props.options ||
          geofences.map((geofence) => {
            return {
              ...geofence,
              icon: showOrganization ? `${geofence.organizationName}` : undefined,
              id: geofence.geofenceId,
              value: geofence.geofenceId,
              label: geofence.geofenceName,
              label_intl: geofence.geofenceName
            };
          })
        }
        appendcomponent={props.appendcomponent}
      />
    </>
  );
};

export default GeofencesDropdownInput;
