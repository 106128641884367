import { Map } from 'react-feather';
import * as screens from '@core/layouts/components/menu/screens';

export default [
  {
    id: 'map',
    title: 'sidebar.map',
    icon: <Map size={20} />,
    permissions: ['BA001'],
    iconName: 'Map',
    navLink: '/home',
    screenId: screens.MAP
  }
];
