// @ts-nocheck
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useIntl } from 'react-intl';
import { Col, Row, Spinner } from 'reactstrap';

am4core.useTheme(am4themes_animated);

interface Data {
  value: any;
  rawData: any;
  sample: any;
  date: string | Date;
  color?: any;
}

interface Props {
  data: Data[];
  max?: number;
  min?: number;
  fillOpacity?: number;
  bulletRadius?: number;
  upperLimit?: number;
  lowerLimit?: number;
  dataColor?: string;
  showFullChart?: boolean;
  width?: string;
  height?: string;
  axisRanges?: any[];
  titleTooltip: string;
  tooltipText?: string;
  metric?: string;
  isFetching: boolean;
  customColorTooltip?: boolean;
  hoverInterval?: boolean;
  onClick?: Function;
  domRefId?: String;
  tensionX?: number;
}

const ControlChart = ({
  data,
  dataColor,
  width = '100%',
  height = '500px',
  axisRanges,
  isFetching,
  max,
  min,
  fillOpacity = 0,
  bulletRadius = 2,
  showFullChart = false,
  upperLimit,
  lowerLimit,
  titleTooltip,
  tooltipText,
  customColorTooltip = false,
  metric,
  onClick,
  domRefId = 'chartdiv',
  tensionX = 0.8
}: Props) => {
  const chart = useRef<any>(null);
  const [chartIsReady, setChartIsReady] = useState(false);

  const intl = useIntl();
  useLayoutEffect(() => {
    if (chart.current) chart.current.dispose();
    if (!isFetching) {
      let x = am4core.create(domRefId, am4charts.XYChart);
      x.preloader.disabled = true;
      x.dateFormatter.dateFormat = 'yyyy-MM-dd';

      // Create axes
      let dateAxis = x.xAxes.push(new am4charts.DateAxis());
      dateAxis.dateFormats.setKey('day', 'yyyy-MM-dd');
      dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH:mm';
      dateAxis.baseInterval = {
        timeUnit: 'second',
        count: 1
      };
      // Data series
      let valueAxis1 = x.yAxes.push(new am4charts.ValueAxis());
      valueAxis1.title.text = titleTooltip;

      // Create value axis range
      if (max != null) valueAxis1.max = max;
      if (upperLimit != null) {
        let range = valueAxis1.axisRanges.create();
        range.value = upperLimit;
        range.grid.stroke = am4core.color('#396478');
        range.grid.strokeWidth = 1;
        range.grid.strokeOpacity = 1;
        range.grid.strokeDasharray = '8,4';
        range.label.inside = true;
        range.label.text = 'UCL';
        range.label.fill = range.grid.stroke;
        range.label.verticalCenter = 'bottom';
      }

      // Create value axis range
      if (min != null) valueAxis1.min = min;
      /*  if (lowerLimit != null) {
        let range2 = valueAxis1.axisRanges.create();
        range2.value = lowerLimit;
        range2.grid.stroke = am4core.color('#396478');
        range2.grid.strokeWidth = 1;
        range2.grid.strokeOpacity = 1;
        range2.grid.strokeDasharray = '8,4';
        range2.label.inside = true;
        range2.label.text = 'LCL';
        range2.label.fill = range2.grid.stroke;
        range2.label.verticalCenter = 'bottom';
      }
 */
      axisRanges?.map((ar) => {
        let rangeAR = valueAxis1.axisRanges.create();
        rangeAR.value = ar.value;
        rangeAR.grid.stroke = ar?.color ?? am4core.color('#396478');
        rangeAR.grid.strokeWidth = 1;
        rangeAR.grid.strokeOpacity = 1;
        rangeAR.grid.strokeDasharray = '8,4';
        rangeAR.label.inside = true;
        rangeAR.label.text = ar.text;
        rangeAR.label.fill = rangeAR.grid.stroke;
        rangeAR.label.verticalCenter = 'bottom';
      });

      let series1 = x.series.push(new am4charts.LineSeries());
      series1.dataFields.valueY = 'value';
      series1.dataFields.dateX = 'date';
      series1.strokeWidth = 2;
      series1.tensionX = tensionX;
      let bullet = series1.bullets.push(new am4charts.CircleBullet());
      let series2 = [];
      let bullet2 = [];
      let series3 = [];
      let bullet3 = [];
      if (showFullChart) {
        /* inicio serie2 */
        series2 = x.series.push(new am4charts.LineSeries());
        bullet2 = series2.bullets.push(new am4charts.CircleBullet());
        series2.dataFields.valueY = 'rawData';
        series2.dataFields.dateX = 'date';
        series2.strokeWidth = 2;
        series2.tensionX = tensionX;

        bullet2.circle.propertyFields.fill = 'color';
        bullet2.circle.propertyFields.stroke = 'color';

        if (bulletRadius === 7) {
          bullet2.circle.radius = bulletRadius;
          bullet2.circle.propertyFields.radius = 'radius';
          bullet2.circle.strokeWidth = 10;
        } else {
          bullet2.circle.radius = bulletRadius;
          bullet2.circle.propertyFields.radius = 'radius';
          bullet2.circle.radius = 0;
        }

        if (dataColor) {
          series2.fill = '#456645';
          series2.stroke = '#456645';
        } else {
          series2.propertyFields.fill = 'color';
          series2.propertyFields.stroke = 'color';
        }

        series2.yAxis = valueAxis1;
        series2.name = intl.formatMessage({ id: 'telemetry.RawData' });
        series2.fillOpacity = fillOpacity;
        /* fin serie2 */
        /* inicio serie3 */

        series3 = x.series.push(new am4charts.LineSeries());
        bullet3 = series3.bullets.push(new am4charts.CircleBullet());
        series3.dataFields.valueY = 'sample';
        series3.dataFields.dateX = 'date';
        series3.strokeWidth = 0;
        series3.opacity = 0;
        series3.tensionX = 1.8;

        bullet3.circle.propertyFields.fill = 'color';
        bullet3.circle.propertyFields.stroke = 'color';
        bullet3.fill = 'color';
        bullet3.stroke = 'color';
        if (bulletRadius === 7) {
          bullet3.circle.radius = bulletRadius;
          bullet3.circle.propertyFields.radius = 'radius';
          bullet3.circle.propertyFields.fill = 'color';
          bullet3.circle.propertyFields.stroke = 'color';
          bullet3.circle.strokeWidth = 10;
          series3.opacity = 1;
          bullet3.fill = 'color';
          bullet3.stroke = 'color';
        } else {
          bullet3.circle.radius = bulletRadius;
          bullet3.circle.propertyFields.radius = 'radius';
          bullet3.circle.radius = 0;
        }

        series3.yAxis = valueAxis1;
        series3.name = intl.formatMessage({ id: 'telemtry.Samples' });
        series3.fillOpacity = fillOpacity;

        /* fin seri3 */
      }

      bullet.circle.propertyFields.fill = 'color';
      bullet.circle.propertyFields.stroke = 'color';
      if (bulletRadius === 7) {
        bullet.circle.radius = bulletRadius;
        bullet.circle.propertyFields.radius = 'radius';
        bullet.circle.strokeWidth = 10;
        series3.fill = 'color';
        series3.stroke = 'color';
      } else {
        bullet.circle.radius = bulletRadius;
        bullet.circle.propertyFields.radius = 'radius';
        bullet.circle.radius = 0;
        series3.fill = 'color';
        series3.stroke = 'color';
      }

      if (dataColor) {
        series1.fill = dataColor;
        series1.stroke = dataColor;
      } else {
        series1.propertyFields.fill = 'color';
        series1.propertyFields.stroke = 'color';
      }
      series1.yAxis = valueAxis1;
      series1.name = titleTooltip;
      series1.fillOpacity = fillOpacity;
      series1.tooltipText = tooltipText ?? `{name}\n[bold font-size: 20]{valueY}${metric}[/]`;
      series2.tooltipText = `{name}\n[bold font-size: 20]inRaw: {valueY}${metric}[/]`;
      series3.tooltipText = tooltipText;

      if (customColorTooltip && series1.tooltip) {
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.propertyFields.fill = 'color';
        series1.tooltip.label.maxWidth = 330;
        series1.tooltip.label.wrap = true;
      }

      if (customColorTooltip && series1.tooltip && series2.tooltip && series3.tooltip) {
        series1.tooltip.getFillFromObject = false;
        series1.tooltip.background.propertyFields.fill = 'color';
        series1.tooltip.label.maxWidth = 330;
        series1.tooltip.label.wrap = true;
        /* series2 */
        series2.tooltip.getFillFromObject = false;
        series2.tooltip.background.propertyFields.fill = 'color';
        series2.tooltip.label.maxWidth = 330;
        series2.tooltip.label.wrap = true;
        /* series3 */
        series3.tooltip.getFillFromObject = false;
        series3.tooltip.background.propertyFields.fill = 'color';
        series3.tooltip.label.maxWidth = 330;
        series3.tooltip.label.wrap = true;

        if (dataColor !== '#1E90FF') {
          series1.tensionX = 0.7;
          series2.tensionX = 0.7;
        }
      }

      // Add cursor
      x.cursor = new am4charts.XYCursor();
      x.events.on('hit', () => {
        if (onClick) onClick(series1.tooltipDataItem.dataContext);
      });

      // Add cursor
      /* x.cursor = new am4charts.XYCursor();
  x.cursor.behavior = 'selectX';
  x.cursor.xAxis = dateAxis;
  x.cursor.snapToSeries = series1;

  x.cursor.events.on('hit', (event) => {
    const selectedData = event.target.dataItem.dataContext;
    const selectedDate = selectedData.date;
    if (onClick) onClick(selectedDate);
  }); */

      // Add legend
      x.legend = new am4charts.Legend();
      x.legend.position = 'top';

      // Add scrollbar
      x.scrollbarX = new am4charts.XYChartScrollbar();
      x.scrollbarX.series.push(series1);
      if (showFullChart) {
        x.scrollbarX.series.push(series2);
        x.scrollbarX.series.push(series3);
      }
      x.scrollbarX.parent = x.bottomAxesContainer;

      x.data = data;

      chart.current = x;

      return () => {
        chart.current.dispose();
      };
    }
  }, [isFetching, min, max, data?.length]);

  useEffect(() => {
    if (chart.current) {
      setChartIsReady(true);
    }
  }, [chart.current]);

  return isFetching && !chartIsReady ? (
    <Row>
      <Col sm="12" className="grid-spinner">
        <Spinner color="primary" size="lg" />
      </Col>
    </Row>
  ) : (
    <div id={domRefId} style={{ width, height }}></div>
  );
};
export default ControlChart;
