/* -------------------------------------------------------------------------- */
/*                         Componente RadioButtons                            */
/* -------------------------------------------------------------------------- */
// Este componente contiene un Radio Buttons Input genérico que se utiliza en muchas pantallas de la aplicación.
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Col, CustomInput, Label } from 'reactstrap';

interface Props {
  field: any;
  fieldstate: any;
  required?: any;
  errorMessage?: any;
  errorinvalidmessage?: any;
  errorvalidatemessage?: any;
  radioButtons: any[];
  label?: any;
  isInModal?: boolean;
  additionalonchangefunction?: Function;
}

const RadioButtonsInput = (props: Props) => {
  const {
    field,
    fieldstate,
    required,
    errorMessage,
    errorinvalidmessage,
    errorvalidatemessage,
    radioButtons,
    label,
    isInModal,
    additionalonchangefunction
  } = props;

  useEffect(() => {
    if (field.value && additionalonchangefunction) additionalonchangefunction(field.value);
  }, [field.value]);

  return (
    <FormGroup>
      {label && (
        <Label className="form-label">
          {label}
          {required && <span className="required">&nbsp;*</span>}
        </Label>
      )}
      {radioButtons?.map((radioButton, i) => (
        <Col sm="12" className="mb-1" key={i}>
          <CustomInput
            style={isInModal ? { zIndex: 500 } : {}}
            type="radio"
            id={radioButton.value}
            name={field.name}
            inline
            label={radioButton.label}
            checked={field.value == radioButton.value}
            onChange={(event) => {
              field.onChange(event.target.id);
            }}
          />
        </Col>
      ))}
      {fieldstate.error && fieldstate.error.type === 'required' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorMessage || (
            <FormattedMessage id="common.requiredField" />
          )}
        </div>
      ) : fieldstate.error && fieldstate.error.type === 'pattern' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorinvalidmessage || (
            <FormattedMessage id="common.invalidField" />
          )}
        </div>
      ) : fieldstate.error && fieldstate.error.type === 'validate' ? (
        <div className="invalid-feedback" style={{ display: 'block' }}>
          {fieldstate.error?.message || errorvalidatemessage || (
            <FormattedMessage id="common.invalidField" />
          )}
        </div>
      ) : (
        ''
      )}
    </FormGroup>
  );
};

RadioButtonsInput.defaultProps = {
  radioButtons: [],
  boolean: false
};

export default RadioButtonsInput;
