import * as types from '../types';

export const startFetchingProtocols = (filters = {}) => ({
  type: types.PROTOCOLS_FETCH_STARTED,
  payload: filters
});

export const completeFetchingProtocols = (entities, order, operatorId, organizationId) => ({
  type: types.PROTOCOLS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});

export const failFetchingProtocols = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.PROTOCOLS_FETCH_FAILED,
  patload: {
    error
  }
});

export const startPartialFetchingProtocols = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.PROTOCOLS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});

export const completePartialFetchingProtocols = (entities, order, operatorId, organizationId) => ({
  type: types.PROTOCOLS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});

export const failPartialFetchingProtocols = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.PROTOCOLS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingProtocol = (protocol) => ({
  type: types.PROTOCOLS_ADD_STARTED,
  payload: protocol
});
export const completeAddingProtocol = (oldId, protocol) => ({
  type: types.PROTOCOLS_ADD_COMPLETED,
  payload: { oldId, protocol }
});
export const failAddingProtocol = (oldId, error) => ({
  type: types.PROTOCOLS_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingProtocol = (protocol) => ({
  type: types.PROTOCOLS_EDIT_STARTED,
  payload: protocol
});
export const completeEditingProtocol = (protocol) => ({
  type: types.PROTOCOLS_EDIT_COMPLETED,
  payload: protocol
});
export const failEditingProtocol = (oldProtocol, error) => ({
  type: types.PROTOCOLS_EDIT_FAILED,
  payload: {
    oldProtocol,
    error
  }
});

export const startRemovingProtocol = (protocolId) => ({
  type: types.PROTOCOLS_REMOVE_STARTED,
  payload: {
    protocolId
  }
});
export const completeRemovingProtocol = (protocolId) => ({
  type: types.PROTOCOLS_REMOVE_COMPLETED,
  payload: {
    protocolId
  }
});
export const failRemovingProtocol = (protocolId, error) => ({
  type: types.PROTOCOLS_REMOVE_FAILED,
  payload: {
    protocolId,
    error
  }
});

export const selectProtocol = (protocolId) => ({
  type: types.PROTOCOLS_SELECTED,
  payload: protocolId
});

export const selectAllProtocols = (
  ProtocolIds = [] //List of ProtocolIds
) => ({
  type: types.PROTOCOLS_ALL_SELECTED,
  payload: ProtocolIds
});

export const deselectProtocol = (protocolId) => ({
  type: types.PROTOCOLS_DESELECTED,
  payload: protocolId
});

export const deselectAllProtocols = (
  protocolIds = [] //List of ProtocolIds
) => ({
  type: types.PROTOCOLS_ALL_DESELECTED,
  payload: protocolIds
});
