import { combineReducers } from 'redux';
import currencies from './currencies';
import paymentMethods from './payment-methods';
import timeZones from './time-zones';
import countries from './countries';
import providers from './cellular-providers';
import vehicleMakers from './vehicle-makers';
import vehicleModels from './vehicle-models';
import insuranceCompanies from './insurance-companies';
import insuranceAgents from './insurance-agents';
import sensorBrands from './sensor-brands';
import sensorModels from './sensor-models';
import sensorMeasures from './sensor-measures';
import locationClasses from './location-classes';
import brands from './brands';

const staticCatalogs = combineReducers({
  currencies,
  timeZones,
  countries,
  providers,
  vehicleMakers,
  vehicleModels,
  insuranceCompanies,
  insuranceAgents,
  sensorBrands,
  sensorModels,
  sensorMeasures,
  paymentMethods,
  locationClasses,
  brands
});

export default staticCatalogs;

//Own state
export const getOwnState = (state) => state.staticCatalogs;
