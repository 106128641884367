import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.ORGANIZATIONS_FETCH_COMPLETED:
    case types.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((organizationId) => {
        newState[organizationId] = {
          isSelected: false,
          ...state[organizationId],
          ...entities[organizationId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.ORGANIZATION_ADD_COMPLETED: {
      const { oldId, organization } = action.payload;
      const newState = omit(state, oldId);
      newState[organization.organizationId] = {
        ...state[oldId],
        ...organization,
        isConfirmed: true
      };
      return newState;
    }

    case types.ORGANIZATION_EDIT_COMPLETED: {
      const organization = action.payload;
      return {
        ...state,
        [organization.organizationId]: {
          ...omit(state[organization.organizationId], ['oldOrganization']),
          ...omit(organization, ['oldOrganization']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.ORGANIZATION_ADD_STARTED: {
      const { organization, isSelected } = action.payload;
      const newState = { ...state };
      newState[organization.organizationId] = {
        isSelected,
        ...omit(organization, ['quotationId', 'baseQuotationId', 'membership']),
        isConfirmed: false
      };
      return newState;
    }
    case types.ORGANIZATION_EDIT_STARTED: {
      const organization = action.payload;
      return {
        ...state,
        [organization.organizationId]: {
          ...state[organization.organizationId],
          oldOrganization: state[organization.organizationId],
          ...organization,
          isConfirmed: false
        }
      };
    }

    case types.ORGANIZATION_CODE_ASSIGN_COMPLETED: {
      const {
        organizationId,
        registrationCode,
        allowedApplications,
        registrationDivisionId,
        registrationSubdivisionId
      } = action.payload;
      return {
        ...state,
        [organizationId]: {
          ...state[organizationId],
          registrationCode,
          allowedApplications,
          registrationDivisionId,
          registrationSubdivisionId
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.ORGANIZATION_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.ORGANIZATION_EDIT_FAILED: {
      const { oldOrganization } = action.payload;
      return {
        ...state,
        [oldOrganization.organizationId]: {
          ...omit(state[oldOrganization.organizationId], ['oldOrganization']),
          ...oldOrganization,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.ORGANIZATION_REMOVE_COMPLETED: {
      const { organizationId } = action.payload;
      return omit(state, organizationId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.ORGANIZATION_SELECTED: {
      const organizationId = action.payload;
      const newState = {
        ...state,
        [organizationId]: {
          ...state[organizationId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.ORGANIZATION_DESELECTED: {
      const organizationId = action.payload;
      return {
        ...state,
        [organizationId]: {
          ...state[organizationId],
          isSelected: false
        }
      };
    }

    case types.ORGANIZATIONS_ALL_SELECTED: {
      const organizationIds = action.payload;
      const newState = { ...state };
      if (organizationIds.length == 0) {
        forEach(state, (organization: any, organizationId) => {
          newState[organizationId] = {
            ...organization,
            isSelected: true
          };
        });
      } else {
        organizationIds.forEach((organizationId) => {
          newState[organizationId] = {
            ...state[organizationId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.ORGANIZATIONS_ALL_DESELECTED: {
      const organizationIds = action.payload;
      const newState = { ...state };
      if (organizationIds.length == 0) {
        forEach(state, (organization: any, organizationId) => {
          newState[organizationId] = {
            ...organization,
            isSelected: false
          };
        });
      } else {
        organizationIds.forEach((organizationId) => {
          newState[organizationId] = {
            ...state[organizationId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.ORGANIZATIONS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(state, order);
    }

    case types.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.ORGANIZATION_ADD_COMPLETED: {
      const { oldId, organization } = action.payload;
      return state.map((organizationId) =>
        organizationId === oldId ? organization.organizationId : organizationId
      );
    }

    case types.ORGANIZATION_REMOVE_COMPLETED: {
      const { organizationId } = action.payload;
      return state.filter((organizationIdState) => organizationIdState !== organizationId);
    }

    //CASE ADD STARTED
    case types.ORGANIZATION_ADD_STARTED: {
      const { organization } = action.payload;
      return [...state, organization.organizationId];
    }

    //CASE ADD FAILED
    case types.ORGANIZATION_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((organizationIdState) => organizationIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATIONS_FETCH_STARTED: {
      return true;
    }
    case types.ORGANIZATIONS_FETCH_COMPLETED:
    case types.ORGANIZATIONS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATIONS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED:
    case types.ORGANIZATIONS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_ADD_STARTED: {
      return true;
    }
    case types.ORGANIZATION_ADD_COMPLETED:
    case types.ORGANIZATION_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAddingDowntimeReason = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_STARTED: {
      return true;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_COMPLETED:
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_EDIT_STARTED: {
      return true;
    }
    case types.ORGANIZATION_EDIT_COMPLETED:
    case types.ORGANIZATION_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditingDowntimeReason = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED: {
      return true;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_COMPLETED:
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_REMOVE_STARTED: {
      return true;
    }
    case types.ORGANIZATION_REMOVE_COMPLETED:
    case types.ORGANIZATION_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemovingDowntimeReason = (state = false, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED: {
      return true;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_COMPLETED:
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATIONS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATIONS_FETCH_STARTED:
    case types.ORGANIZATIONS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATIONS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATIONS_PARTIAL_FETCH_STARTED:
    case types.ORGANIZATIONS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_ADD_STARTED:
    case types.ORGANIZATION_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAddingDowntimeReason = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_STARTED:
    case types.ORGANIZATION_DOWNTIME_REASON_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_EDIT_STARTED:
    case types.ORGANIZATION_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditingDowntimeReason = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_STARTED:
    case types.ORGANIZATION_DOWNTIME_REASON_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_REMOVE_STARTED:
    case types.ORGANIZATION_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemovingDowntimeReason = (state = null, action) => {
  switch (action.type) {
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_STARTED:
    case types.ORGANIZATION_DOWNTIME_REASON_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const validCode = (state = true, action) => {
  switch (action.type) {
    case types.ORGANIZATION_CODE_CHECK_COMPLETED: {
      return action.payload;
    }
    case types.ORGANIZATION_CODE_CHECK_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const organizations = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isAddingDowntimeReason,
  isEditing,
  isEditingDowntimeReason,
  isRemoving,
  isRemovingDowntimeReason,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorAddingDowntimeReason,
  errorEditing,
  errorEditingDowntimeReason,
  errorRemoving,
  errorRemovingDowntimeReason,
  validCode
});

export default organizations;

//Own state
export const getOwnState = (state) => state.organizations;

//Information
export const getOrganizationOperator = (state, organizationId) =>
  getOwnState(state).byId[organizationId]?.operatorId;
export const getOrganizationOperatorName = (state, organizationId) =>
  getOwnState(state).byId[organizationId]?.operatorName;
export const getOrganization = (state, organizationId) => getOwnState(state).byId[organizationId];
export const getOrganizationName = (state, organizationId) =>
  getOrganization(state, organizationId)?.organizationName;
export const getOrganizationLoginextParams = (state, organizationId) => {
  return {
    organizationId: getOrganization(state, organizationId)?.organizationId,
    loginextEffectiveVisitingTime:
      getOrganization(state, organizationId)?.loginextEffectiveVisitingTime / 60,
    loginextDeliveryDelay: getOrganization(state, organizationId)?.loginextDeliveryDelay / 60
  };
};
export const getOrganizationsList = (state) =>
  getOwnState(state)
    .order.map((id) => getOrganization(state, id))
    .filter((organization) => organization.organizationStatus > 0);
export const getOrganizationsListByMembership = (state, membership) => {
  //Se filtra por operatorId
  if (membership.operatorId)
    return getOrganizationsList(state).filter(
      (organization) => organization.operatorId === membership.operatorId
    );
  //Si no se ha seleccionado un operador se devuelvela lista completa
  return getOrganizationsList(state);
};
export const getOrganizationsListBySelectedOperator = (state, membership) => {
  //Se filtra por operatorId
  if (membership.operatorId)
    return getOrganizationsList(state).filter(
      (organization) => organization.operatorId === membership.operatorId
    );
  //Si no se ha seleccionado un operador se devuelve una lista vacia
  else return [];
};

export const getSelectedOrganizations = (state) => {
  const selectedOrganizations = getOrganizationsList(state).filter(
    (organization) => organization.isSelected
  );
  //Si no se selecciona ninguno devuelve null
  if (selectedOrganizations.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedOrganizations.length > 0) return selectedOrganizations;
};

export const getSelectedOrganization = (state) => {
  const selectedOrganizations = getOrganizationsList(state).filter(
    (organization) => organization.isSelected
  );
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedOrganizations.length === 1) return selectedOrganizations[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListOrganizations = (state) =>
  getOwnState(state).isPartialFetchingList;
export const isFetchingListOrganizations = (state) => getOwnState(state).isFetchingList;
export const isAddingOrganization = (state) => getOwnState(state).isAdding;
export const isEditingOrganization = (state) => getOwnState(state).isEditing;
export const isRemovingOrganization = (state) => getOwnState(state).isRemoving;

export const isAddingOrganizationDowntimeReason = (state) =>
  getOwnState(state).isAddingDowntimeReason;
export const isEditingOrganizationDowntimeReason = (state) =>
  getOwnState(state).isEditingDowntimeReason;
export const isRemovingOrganizationDowntimeReason = (state) =>
  getOwnState(state).isRemovingDowntimeReason;

//Errors
export const getPartialFetchingListOrganizationsErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListOrganizationsErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingOrganizationErrors = (state) => getOwnState(state).errorAdding;
export const getEditingOrganizationErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingOrganizationErrors = (state) => getOwnState(state).errorRemoving;

export const getAddingOrganizationDowntimeReasonErrors = (state) =>
  getOwnState(state).errorAddingDowntimeReason;
export const getEditingOrganizationDowntimeReasonErrors = (state) =>
  getOwnState(state).errorEditingDowntimeReason;
export const getRemovingOrganizationDowntimeReasonErrors = (state) =>
  getOwnState(state).errorRemovingDowntimeReason;

//Valid code
export const isValidCode = (state) => getOwnState(state).validCode;
