import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';
import * as officeSelectors from 'views/screens/Offices/reducers';

import * as types from '../types';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CUSTOMERS_FETCH_COMPLETED:
    case types.CUSTOMERS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((customerId) => {
        newState[customerId] = {
          isSelected: false,
          ...state[customerId],
          ...entities[customerId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.CUSTOMER_ADD_COMPLETED: {
      const { oldId, customer } = action.payload;
      const newState = omit(state, oldId);
      newState[customer.customerId] = {
        ...state[oldId],
        ...customer,
        isConfirmed: true
      };
      return newState;
    }

    case types.CUSTOMER_EDIT_COMPLETED: {
      const { customer } = action.payload;
      return {
        ...state,
        [customer.customerId]: {
          ...omit(state[customer.customerId], ['oldCustomer']),
          ...omit(customer, ['oldCustomer']),
          isConfirmed: true
        }
      };
    }

    //TYPES STARTED ADD AND EDIT
    case types.CUSTOMER_ADD_STARTED: {
      const customer = action.payload;
      const newState = { ...state };
      newState[customer.customerId] = {
        isSelected: false,
        ...customer,
        isConfirmed: false
      };
      return newState;
    }
    case types.CUSTOMER_EDIT_STARTED: {
      const customer = action.payload;
      return {
        ...state,
        [customer.customerId]: {
          ...state[customer.customerId],
          oldCustomer: state[customer.customerId],
          ...customer,
          isConfirmed: false
        }
      };
    }

    //TYPES FAILED ADD AND EDIT
    case types.CUSTOMER_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }
    case types.CUSTOMER_EDIT_FAILED: {
      const { oldCustomer } = action.payload;
      return {
        ...state,
        [oldCustomer.customerId]: {
          ...omit(state[oldCustomer.customerId], ['oldCustomer']),
          ...oldCustomer,
          isConfirmed: true
        }
      };
    }

    //TYPES REMOVE COMPLETED
    case types.CUSTOMER_REMOVE_COMPLETED: {
      const { customerId } = action.payload;
      return omit(state, customerId);
    }

    //TYPES SELECTED AND DESELECTED
    case types.CUSTOMER_SELECTED: {
      const customerId = action.payload;
      const newState = {
        ...state,
        [customerId]: {
          ...state[customerId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.CUSTOMER_DESELECTED: {
      const customerId = action.payload;
      return {
        ...state,
        [customerId]: {
          ...state[customerId],
          isSelected: false
        }
      };
    }

    case types.CUSTOMERS_ALL_SELECTED: {
      const customerIds = action.payload;
      const newState = { ...state };
      if (customerIds.length == 0) {
        forEach(state, (customer: any, customerId) => {
          newState[customerId] = {
            ...customer,
            isSelected: true
          };
        });
      } else {
        customerIds.forEach((customerId) => {
          newState[customerId] = {
            ...state[customerId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.CUSTOMERS_ALL_DESELECTED: {
      const customerIds = action.payload;
      const newState = { ...state };
      if (customerIds.length == 0) {
        forEach(state, (customer: any, customerId) => {
          newState[customerId] = {
            ...customer,
            isSelected: false
          };
        });
      } else {
        customerIds.forEach((customerId) => {
          newState[customerId] = {
            ...state[customerId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CUSTOMERS_FETCH_COMPLETED:
    case types.CUSTOMERS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }

    case types.CUSTOMER_ADD_COMPLETED: {
      const { oldId, customer } = action.payload;
      return state.map((customerId) => (customerId === oldId ? customer.customerId : customerId));
    }

    case types.CUSTOMER_REMOVE_COMPLETED: {
      const { customerId } = action.payload;
      return state.filter((customerIdState) => customerIdState !== customerId);
    }

    //CASE ADD STARTED
    case types.CUSTOMER_ADD_STARTED: {
      const customer = action.payload;
      return [...state, customer.customerId];
    }

    //CASE ADD FAILED
    case types.CUSTOMER_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((customerIdState) => customerIdState !== oldId);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CUSTOMERS_FETCH_STARTED: {
      return true;
    }
    case types.CUSTOMERS_FETCH_COMPLETED:
    case types.CUSTOMERS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isPartialFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CUSTOMERS_PARTIAL_FETCH_STARTED: {
      return true;
    }
    case types.CUSTOMERS_PARTIAL_FETCH_COMPLETED:
    case types.CUSTOMERS_PARTIAL_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case types.CUSTOMER_ADD_STARTED: {
      return true;
    }
    case types.CUSTOMER_ADD_COMPLETED:
    case types.CUSTOMER_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case types.CUSTOMER_EDIT_STARTED: {
      return true;
    }
    case types.CUSTOMER_EDIT_COMPLETED:
    case types.CUSTOMER_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case types.CUSTOMER_REMOVE_STARTED: {
      return true;
    }
    case types.CUSTOMER_REMOVE_COMPLETED:
    case types.CUSTOMER_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CUSTOMERS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CUSTOMERS_FETCH_STARTED:
    case types.CUSTOMERS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorPartialFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CUSTOMERS_PARTIAL_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CUSTOMERS_PARTIAL_FETCH_STARTED:
    case types.CUSTOMERS_PARTIAL_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case types.CUSTOMER_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CUSTOMER_ADD_STARTED:
    case types.CUSTOMER_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case types.CUSTOMER_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CUSTOMER_EDIT_STARTED:
    case types.CUSTOMER_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case types.CUSTOMER_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CUSTOMER_REMOVE_STARTED:
    case types.CUSTOMER_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const customers = combineReducers({
  byId,
  order,
  isPartialFetchingList,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorPartialFetchingList,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving
});

export default customers;

//Own state
export const getOwnState = (state) => state.customers;

//Information
export const getCustomer = (state, customerId: number): types.Customer | undefined => {
  const customer = getOwnState(state).byId[customerId];
  if (customer) {
    const office = officeSelectors.getOffice(state, customer.officeId) ?? {};
    return {
      ...customer,
      ...omit(office, ['privateCode', 'isConfirmed', 'isSelected']),
      officePrivateCode: office?.privateCode
    };
  } else return undefined;
};
export const getCustomersList = (state): types.Customer[] =>
  getOwnState(state)
    .order.map((id) => getCustomer(state, id))
    .filter((customer) => customer.customerStatus > 0);

export const getCustomersListByMembership = (state, membership) => {
  //Se filtra por membership
  return getCustomersList(state).filter(
    (customer) =>
      membership &&
      (!membership.organizationId || membership.organizationId === customer.organizationId) &&
      (!membership.divisionId || membership.divisionId === customer.divisionId) &&
      (!membership.subdivisionId || membership.subdivisionId === customer.subdivisionId)
  );
};

export const getNotAssignedCustomersList = (state, locationId) =>
  getCustomersList(state).filter(
    // @ts-ignore location is saved on "office" not "customer" #TODO: Implement type for office
    (customer) => !customer?.locationId || (locationId && customer?.locationId === locationId)
  );

export const getSelectedCustomers = (state) => {
  const selectedCustomers = getCustomersList(state).filter((customer) => customer.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedCustomers.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedCustomers.length > 0) return selectedCustomers;
};

export const getSelectedCustomer = (state) => {
  const selectedCustomers = getCustomersList(state).filter((customer) => customer.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedCustomers.length === 1) return selectedCustomers[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isPartialFetchingListCustomers = (state) => getOwnState(state).isPartialFetchingList;
export const isFetchingListCustomers = (state) => getOwnState(state).isFetchingList;
export const isAddingCustomer = (state) => getOwnState(state).isAdding;
export const isEditingCustomer = (state) => getOwnState(state).isEditing;
export const isRemovingCustomer = (state) => getOwnState(state).isRemoving;

//Errors
export const getPartialFetchingListCustomersErrors = (state) =>
  getOwnState(state).errorPartialFetchingList;
export const getFetchingListCustomersErrors = (state) => getOwnState(state).errorFetchingList;
export const getAddingCustomerErrors = (state) => getOwnState(state).errorAdding;
export const getEditingCustomerErrors = (state) => getOwnState(state).errorEditing;
export const getRemovingCustomerErrors = (state) => getOwnState(state).errorRemoving;
