import * as types from '../types';

export const startFetchingGroups = (filters = {}) => ({
  type: types.GROUPS_FETCH_STARTED,
  payload: filters
});
export const completeFetchingGroups = (entities, order, operatorId, organizationId) => ({
  type: types.GROUPS_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failFetchingGroups = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.GROUPS_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startPartialFetchingGroups = (
  entityTypeId,
  operatorId = null,
  organizationId = null
) => ({
  type: types.GROUPS_PARTIAL_FETCH_STARTED,
  payload: { entityTypeId, operatorId, organizationId }
});
export const completePartialFetchingGroups = (entities, order, operatorId, organizationId) => ({
  type: types.GROUPS_PARTIAL_FETCH_COMPLETED,
  payload: {
    entities,
    order,
    operatorId,
    organizationId
  }
});
export const failPartialFetchingGroups = (error = { errorMessage: 'Error', errorNumber: 404 }) => ({
  type: types.GROUPS_PARTIAL_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startFetchingAllEntitiesGroups = (groupId) => ({
  type: types.GROUPS_ALL_ENTITIES_FETCH_STARTED,
  payload: groupId
});
export const completeFetchingAllEntitiesGroups = (entities, groupId) => ({
  type: types.GROUPS_ALL_ENTITIES_FETCH_COMPLETED,
  payload: {
    entities,
    groupId
  }
});
export const failFetchingAllEntitiesGroups = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.GROUPS_ALL_ENTITIES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});

export const startAddingGroup = (group, users, userInSession, additionalOnSave?: () => void) => ({
  type: types.GROUP_ADD_STARTED,
  payload: { group, users, additionalOnSave }
});
export const completeAddingGroup = (oldId, group) => ({
  type: types.GROUP_ADD_COMPLETED,
  payload: { oldId, group }
});
export const failAddingGroup = (oldId, error) => ({
  type: types.GROUP_ADD_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startAddingGroupWithEntities = (
  group,
  userInSession,
  entityType,
  entities,
  params
) => ({
  type: types.GROUP_ADD_WITH_ENTITIES_STARTED,
  payload: { group, userInSession, entityType, entities, params }
});
export const completeAddingGroupWithEntities = (oldId, group) => ({
  type: types.GROUP_ADD_WITH_ENTITIES_COMPLETED,
  payload: { oldId, group }
});
export const failAddingGroupWithEntities = (oldId, error) => ({
  type: types.GROUP_ADD_WITH_ENTITIES_FAILED,
  payload: {
    oldId,
    error
  }
});

export const startEditingGroup = (group) => ({
  type: types.GROUP_EDIT_STARTED,
  payload: group
});
export const completeEditingGroup = (group) => ({
  type: types.GROUP_EDIT_COMPLETED,
  payload: group
});
export const failEditingGroup = (oldGroup, error) => ({
  type: types.GROUP_EDIT_FAILED,
  payload: {
    oldGroup,
    error
  }
});

export const startRemovingGroup = (groupId) => ({
  type: types.GROUP_REMOVE_STARTED,
  payload: {
    groupId
  }
});
export const completeRemovingGroup = (groupId) => ({
  type: types.GROUP_REMOVE_COMPLETED,
  payload: {
    groupId
  }
});
export const failRemovingGroup = (groupId, error) => ({
  type: types.GROUP_REMOVE_FAILED,
  payload: {
    groupId,
    error
  }
});

export const startLeavingGroup = (groupId, isAsesor, asesorUsername) => ({
  type: types.GROUP_LEAVE_STARTED,
  payload: {
    groupId,
    isAsesor,
    asesorUsername
  }
});
export const completeLeavingGroup = (groupId, isAsesor, asesorUsername) => ({
  type: types.GROUP_LEAVE_COMPLETED,
  payload: {
    groupId,
    isAsesor,
    asesorUsername
  }
});
export const failLeavingGroup = (groupId, error) => ({
  type: types.GROUP_LEAVE_FAILED,
  payload: {
    groupId,
    error
  }
});

export const startEditingNotificationsConfigurationGroup = (group) => ({
  type: types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_STARTED,
  payload: group
});
export const completeEditingNotificationsConfigurationGroup = (group) => ({
  type: types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_COMPLETED,
  payload: group
});
export const failEditingNotificationsConfigurationGroup = (oldGroup, error) => ({
  type: types.GROUP_EDIT_NOTIFICATIONS_CONFIGURATION_FAILED,
  payload: {
    oldGroup,
    error
  }
});

export const startRemovingEntitiesFromGroup = (group, entityType, entities) => ({
  type: types.GROUP_REMOVE_ENTITIES_STARTED,
  payload: { group, entityType, entities }
});
export const completeRemovingEntitiesFromGroup = (groupId, entityType, entities) => ({
  type: types.GROUP_REMOVE_ENTITIES_COMPLETED,
  payload: { groupId, entityType, entities }
});

export const startAddingEntitiesToGroup = (group, entityType, entities, params) => ({
  type: types.GROUP_ADD_ENTITIES_STARTED,
  payload: { group, entityType, entities, params }
});
export const completeAddingEntitiesToGroup = (group, entityType, entities, params) => ({
  type: types.GROUP_ADD_ENTITIES_COMPLETED,
  payload: { group, entityType, entities, params }
});

export const startEditingUserProfileInGroup = (
  groupId,
  userName,
  userNameInSession,
  profileId
) => ({
  type: types.GROUP_USER_PROFILE_EDIT_STARTED,
  payload: { groupId, userName, userNameInSession, profileId }
});
export const completeEditingUserProfileInGroup = (
  groupId,
  userName,
  userNameInSession,
  profileId
) => ({
  type: types.GROUP_USER_PROFILE_EDIT_COMPLETED,
  payload: { groupId, userName, userNameInSession, profileId }
});

export const selectGroup = (groupId) => ({
  type: types.GROUP_SELECTED,
  payload: groupId
});

export const selectAllGroups = (
  groupIds = [] //List of GroupIds
) => ({
  type: types.GROUPS_ALL_SELECTED,
  payload: groupIds
});

export const deselectGroup = (groupId) => ({
  type: types.GROUP_DESELECTED,
  payload: groupId
});

export const deselectAllGroups = (
  groupIds = [] //List of GroupIds
) => ({
  type: types.GROUPS_ALL_DESELECTED,
  payload: groupIds
});
