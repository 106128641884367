import businessPartnersPaths from 'views/screens/BusinessPartners/paths';
import * as screens from '@core/layouts/components/menu/screens';
import Icon from '../../utility/icomoon';
import { appIntl } from 'utility/context/IntlGlobalProvider';

export default [
  {
    id: 'businessPartner',
    title: 'partners.businessPartner',
    icon: <Icon size={20} icon="Customers" />,
    iconName: 'Customers',
    permissions: [],
    navLink: businessPartnersPaths.editPartner,
    screenId: screens.BUSINESS_PARTNERS
  },
  {
    id: 'webServiceRates',
    title: 'partners.webServiceRates',
    icon: <Icon size={20} icon="WebService" />,
    iconName: 'WebService',
    permissions: [],
    navLink: businessPartnersPaths.webServiceRates,
    screenId: screens.WEB_SERVICE_RATES
  }
];
