const permissions = {
  addRoute: 'RL001',
  editRoute: 'RL002',
  deleteRoute: 'RL003',
  assignRoute: 'RL004',
  deleteRouteAssignment: 'RL005',
  completeRouteAssignment: 'RL007',
  finalizeRouteAssignment: 'RL008',
  cancelRouteAssignment: 'RL009',
  viewRouteRunningGraph: 'RL017',
  viewRouteByStatusGraph: 'RL018',
  viewTotalIndicator: 'RL020',
  viewRunningIndicator: 'RL021',
  viewScheduledIndicator: 'RL022',
  viewDestinationIndicator: 'RL023',
  viewRouteCatalog: 'RL027',
  searchRoutes: 'RL025',
  viewRouteMonitor: 'RL029',
  viewRouteReport: 'RL030',
  viewExtraFieldsInRouteAssignment: 'RL032',
  changeCompletionType: 'RL035',
  shareRouteAssignment: 'RL036',
  importRouteAssignments: 'RL038'
};

export default permissions;
