import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from '../../../types/static-catalogs/countries';
import configurations from '../../configurations';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.COUNTRIES_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((countryId) => {
        newState[countryId] = {
          isSelected: false,
          ...entities[countryId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.COUNTRY_FETCH_COMPLETED: {
      const country = action.payload;
      const newState = { ...state };
      newState[country.countryId] = {
        isSelected: false,
        ...state[country.countryId],
        ...country,
        isConfirmed: true
      };
      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.COUNTRY_SELECTED: {
      const countryId = action.payload;
      const newState = {
        ...state,
        [countryId]: {
          ...state[countryId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.COUNTRY_DESELECTED: {
      const countryId = action.payload;
      return {
        ...state,
        [countryId]: {
          ...state[countryId],
          isSelected: false
        }
      };
    }

    case types.COUNTRIES_ALL_SELECTED: {
      const countryIds = action.payload;
      const newState = { ...state };
      if (countryIds.length == 0) {
        forEach(state, (country: any, countryId) => {
          newState[countryId] = {
            ...country,
            isSelected: true
          };
        });
      } else {
        countryIds.forEach((countryId) => {
          newState[countryId] = {
            ...state[countryId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.COUNTRIES_ALL_DESELECTED: {
      const countryIds = action.payload;
      const newState = { ...state };
      if (countryIds.length == 0) {
        forEach(state, (country: any, countryId) => {
          newState[countryId] = {
            ...country,
            isSelected: false
          };
        });
      } else {
        countryIds.forEach((countryId) => {
          newState[countryId] = {
            ...state[countryId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.COUNTRIES_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.COUNTRY_FETCH_COMPLETED: {
      const country = action.payload;
      return uniq([...state, country.countryId]);
    }
    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.COUNTRIES_FETCH_STARTED: {
      return true;
    }
    case types.COUNTRIES_FETCH_COMPLETED:
    case types.COUNTRIES_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.COUNTRY_FETCH_STARTED: {
      return true;
    }
    case types.COUNTRY_FETCH_COMPLETED:
    case types.COUNTRY_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.COUNTRIES_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.COUNTRIES_FETCH_STARTED:
    case types.COUNTRIES_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.COUNTRY_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.COUNTRY_FETCH_STARTED:
    case types.COUNTRY_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const countries = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  errorFetching,
  errorFetchingList,
  configurations
});

export default countries;

//Own state
export const getOwnState = (state) => state.staticCatalogs.countries;

//Information
export const getCountry = (state, countryId) => getOwnState(state).byId[countryId];
export const getCountriesList = (state) =>
  getOwnState(state).order.map((id) => getCountry(state, id));
export const getSelectedCountries = (state) => {
  const selectedCountries = getCountriesList(state).filter((country) => country.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedCountries.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedCountries.length > 0) return selectedCountries;
};
export const getCountriesFromBrand = (state, brandId) =>
  getCountriesList(state).filter((country) => country.brandId === brandId);

export const getSelectedCountry = (state) => {
  const selectedCountries = getCountriesList(state).filter((country) => country.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedCountries.length === 1) return selectedCountries[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingCountry = (state) => getOwnState(state).isFetching;
export const isFetchingListCountries = (state) => getOwnState(state).isFetchingList;

//Errors
export const getFetchingCountryErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListCountriesErrors = (state) => getOwnState(state).errorFetchingList;
