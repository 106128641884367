import * as types from '../../../types/static-catalogs/insurance-companies';

export const startFetchingInsuranceCompanies = (country) => ({
  type: types.INSURANCE_COMPANIES_FETCH_STARTED,
  payload: country
});
export const completeFetchingInsuranceCompanies = (entities, order) => ({
  type: types.INSURANCE_COMPANIES_FETCH_COMPLETED,
  payload: {
    entities,
    order
  }
});
export const failFetchingInsuranceCompanies = (
  error = { errorMessage: 'Error', errorNumber: 404 }
) => ({
  type: types.INSURANCE_COMPANIES_FETCH_FAILED,
  payload: {
    error //error:{errorMessage:'Error', errorNumber:400}
  }
});
