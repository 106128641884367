import { HttpWebtrack } from 'utility/http';
import { ConfigActions } from '../../../../utility/http/http-events';
import { DefaultFilters } from 'views/screens/Entities/views/FetchEntities';

import configureInterceptors from 'utility/http/interceptors/configure-interceptors';
import { store } from 'redux/storeConfig/store';

export const baseUrl = '/sim';

interface SimFilters {
  operatorId?: number;
  organizationId?: number;
  unitId?: number;
  userFetchDate?: Date;
}

interface ImportSIMsFilters {
  organizationId?: number;
  divisionId?: number;
  subdivisionId?: number;
  sims?: String;
}

export const getSimsListService = (
  request: SimFilters | DefaultFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'sims.sim',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/get`, request);
};

export const getSimsListDropdownService = (request: SimFilters, configActions: ConfigActions) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'sims.sim',
    action: 'Get',
    genderType: 'PluralMale'
  });

  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/dropdown`, request);
};

export const importSIMsService = async (
  request: ImportSIMsFilters,
  configActions: ConfigActions
) => {
  const httpWebtrack = new HttpWebtrack();
  configureInterceptors.ConfigureWebtrackInterceptor(store, httpWebtrack);
  httpWebtrack.setConfigMessage({
    object: 'sims.sim',
    action: 'Create',
    genderType: 'SingularMale'
  });
  httpWebtrack.setConfigActions(configActions);
  return httpWebtrack.post(`${baseUrl}/importSIMs`, request);
};
