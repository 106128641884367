/* -------------------------------------------------------------------------- */
/*                          Componente SlidingPaneEditLocation                          */
/* -------------------------------------------------------------------------- */
// Este componente contiene un SlidingPaneEditLocation genérico que se utiliza en muchas pantallas de la aplicación.
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SlidingPane from 'react-sliding-pane';
import { Button, Col, Row } from 'reactstrap';
import MapsBasic from 'views/components/Map';
import CustomersDropdown from 'views/formComponents/CustomersDropdown';
import LocationClassesDropdownInput from 'views/formComponents/LocationClassesDropdownInput';
import Membership from 'views/formComponents/Membership';
import NumericalInput from 'views/formComponents/NumericalInput';
import OfficesDropdown from 'views/formComponents/OfficesDropdown';
import TextInput from 'views/formComponents/TextInput';
import * as customerSelectors from 'views/screens/Customers/reducers';
import * as authSelectors from '../../../../../redux/reducers/auth';
import * as locationClassesSelectors from '../../../../../redux/reducers/static-catalogs/location-classes';

interface Props {
  title: any;
  isOpenSlidingPane: any;
  closeSlidingPane: any;
  subtitle?: any;
  editingLocation: any;
  setEditingLocation?: Function;
  confirmText?: any;
  isInMap?: boolean;
  width?: string;
  hasButtonSubmit?: boolean;
  hasButtonCancel?: boolean;
  action?: any;
  onClose?: any;
}

const SlidingPaneEditLocation = (props: Props) => {
  //const [touched, setTouched] = useState(false);
  const {
    title,
    subtitle,
    editingLocation,
    setEditingLocation,
    confirmText,
    isOpenSlidingPane,
    closeSlidingPane,
    hasButtonSubmit,
    hasButtonCancel,
    action,
    width,
    onClose,
    isInMap = false
  } = props;

  const { control, handleSubmit, watch } = useForm();
  const [rerenderMap, setRerenderMap] = useState<Date>(new Date());

  const formValues = watch();

  const { store, mapHomeCenter, parsers, customer } = useSelector((state) => ({
    store: state,
    mapHomeCenter: authSelectors.getAuthUserMapHomeCenter(state),
    parsers: authSelectors.getParsers(state),
    customer: customerSelectors.getCustomer(state, formValues?.customerId1)
  }));
  const initialLatitude = parseFloat(mapHomeCenter[0]);
  const initialLongitude = parseFloat(mapHomeCenter[1]);
  const initialRadius = 50;
  const initialLocationClassIconName = 'Marker1';
  const intl = useIntl();
  const [latitude, setLatitude] = useState<number>(initialLatitude);
  const [longitude, setLongitude] = useState<number>(initialLongitude);

  useEffect(() => {
    if (isOpenSlidingPane) {
      if (editingLocation) {
        setLatitude(editingLocation.latitude);
        setLongitude(editingLocation.longitude);
      } else {
        setLatitude(initialLatitude);
        setLongitude(initialLongitude);
      }
    }
  }, [isOpenSlidingPane, editingLocation]);

  useEffect(() => {
    if (
      formValues.latitude != null &&
      formValues.longitude != null &&
      formValues.radius != null &&
      formValues.locationClassId != null
    )
      updateEditingLocation();
  }, [
    formValues.locationName,
    formValues.latitude,
    formValues.longitude,
    formValues.radius,
    formValues.locationClassId
  ]);

  const updateEditingLocation = () => {
    if (setEditingLocation)
      setEditingLocation({
        ...editingLocation,
        ...formValues,
        ...formValues.membership,
        locationClassIconName:
          formValues && formValues.locationClassId
            ? locationClassesSelectors.getLocationClassIconName(store, formValues.locationClassId)
            : initialLocationClassIconName
      });
  };

  const closeSlidingPaneFunction = (saving) => {
    closeSlidingPane();
    if (onClose != null && !saving) onClose(formValues); //Close without saving
  };

  return (
    <SlidingPane
      className="custom-sliding-pane-form"
      overlayClassName={isInMap ? 'custom-overlay-sliding-pane-map' : 'custom-overlay-sliding-pane'}
      isOpen={isOpenSlidingPane}
      title={title}
      subtitle={subtitle}
      width={width || '50rem'}
      onRequestClose={() => closeSlidingPaneFunction(false)}
    >
      <Row className={'d-flex justify-content-center'}>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'locationName'}
            defaultValue={
              editingLocation && editingLocation.locationName
                ? editingLocation.locationName
                : undefined
            }
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextInput
                id={'inputLocationName'}
                field={field}
                fieldstate={fieldState}
                required={true}
                label={intl.formatMessage({ id: 'locations.name' })}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'locationAddress'}
            defaultValue={
              editingLocation && editingLocation.locationAddress
                ? editingLocation.locationAddress
                : undefined
            }
            render={({ field, fieldState }) => (
              <TextInput
                id={'inputLocationAddress'}
                field={field}
                fieldstate={fieldState}
                label={intl.formatMessage({ id: 'locations.locationAddress' })}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'locationClassId'}
            defaultValue={
              editingLocation && editingLocation.locationClassId
                ? editingLocation.locationClassId
                : undefined
            }
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <LocationClassesDropdownInput
                field={field}
                fieldstate={fieldState}
                id={'inputLocationClassId'}
                additionalonchangefunction={(value) => setRerenderMap(new Date())}
                required={true}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'latitude'}
            defaultValue={
              editingLocation && editingLocation.latitude != null
                ? editingLocation.latitude
                : undefined
            }
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                required={true}
                id={'inputLatitude'}
                forceinitialvalue={latitude}
                label={intl.formatMessage({ id: 'locations.latitude' })}
                additionalonchangefunction={(value) => setRerenderMap(new Date())}
                allowUndefined={false}
                useNumberSpinner={true}
                allowDecimals={true}
                decimalsPrecision={100}
                step={1}
                min={-90}
                max={90}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'longitude'}
            defaultValue={
              editingLocation && editingLocation.longitude != null
                ? editingLocation.longitude
                : undefined
            }
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                required={true}
                id={'inputLongitude'}
                forceinitialvalue={longitude}
                label={intl.formatMessage({ id: 'locations.longitude' })}
                additionalonchangefunction={(value) => setRerenderMap(new Date())}
                allowUndefined={false}
                useNumberSpinner={true}
                allowDecimals={true}
                decimalsPrecision={100}
                step={1}
                min={-180}
                max={180}
              />
            )}
          />
        </Col>
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'radius'}
            defaultValue={parsers.convertMetersToAuthUserDistance2System(
              editingLocation && editingLocation.radius ? editingLocation.radius : initialRadius
            )}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <NumericalInput
                field={field}
                fieldstate={fieldState}
                required={true}
                id={'inputLongitude'}
                label={intl.formatMessage({ id: 'locations.radius' })}
                allowUndefined={false}
                useNumberSpinner={true}
                allowDecimals={true}
                additionalonchangefunction={(value) => setRerenderMap(new Date())}
                step={1}
                metric={'distance2'}
                min={parsers.convertMetersToAuthUserDistance2System(10)}
                max={parsers.convertMetersToAuthUserDistance2System(100000)}
              />
            )}
          />
        </Col>
        {!isInMap && (
          <Col sm="12">
            <MapsBasic
              mapOptions={{
                className: '',
                style: { height: '40vh' },
                rerenderMap: rerenderMap
              }}
              mapLocationEditOptions={{
                location: {
                  ...editingLocation,
                  latitude: latitude,
                  longitude: longitude,
                  radius: formValues && formValues.radius ? formValues.radius : initialRadius,
                  locationClassIconName:
                    formValues && formValues.locationClassId
                      ? locationClassesSelectors.getLocationClassIconName(
                          store,
                          formValues.locationClassId
                        )
                      : initialLocationClassIconName
                },
                onLocationDragend: (values) => {
                  setLatitude(values.lat);
                  setLongitude(values.lng);
                },
                showLocation: true,
                useRectangleBounds: true,
                isMapFullScreen: false,
                usePopovers: false
              }}
              rulerControlOptions={{ position: 'bottomleft', useControl: false }}
            ></MapsBasic>
            <br />
          </Col>
        )}
        <Col sm="12">
          <Controller
            control={control}
            shouldUnregister
            name={'privateCode'}
            defaultValue={
              editingLocation && editingLocation.privateCode
                ? editingLocation.privateCode
                : undefined
            }
            render={({ field, fieldState }) => (
              <TextInput
                id={'inputPrivateCode'}
                field={field}
                fieldstate={fieldState}
                label={intl.formatMessage({ id: 'locations.privateCode' })}
              />
            )}
          />
        </Col>
        <Membership
          name={'membership'}
          control={control}
          options={{
            colSize: '12',
            useOperator: false,
            useUserOrganization: true,
            setUserOrganization: true,
            showAllDivision: true,
            showAllSubdivision: true
          }}
          defaultValue={
            editingLocation
              ? {
                  operatorId: editingLocation.operatorId,
                  organizationId: editingLocation.organizationId,
                  divisionId: editingLocation.divisionId,
                  subdivisionId: editingLocation.subdivisionId
                }
              : undefined
          }
        />
        {formValues?.locationClassId === 30 && (
          <Col sm="12">
            <Controller
              control={control}
              shouldUnregister
              name={'customerId1'}
              rules={{ required: true }}
              defaultValue={editingLocation?.customerId}
              render={({ field, fieldState }) => (
                <CustomersDropdown
                  field={field}
                  fieldstate={fieldState}
                  id={'customerId1Input'}
                  required
                  enableSelectLocation
                  isInModal
                  notAssigned
                  locationId={editingLocation?.locationId}
                  customersFilters={formValues?.membership}
                />
              )}
            />
          </Col>
        )}
        {formValues?.locationClassId === 34 && (
          <>
            <Col sm="12">
              <Controller
                control={control}
                shouldUnregister
                name={'customerId2'}
                rules={{ required: true }}
                defaultValue={editingLocation?.customerId}
                render={({ field, fieldState }) => (
                  <CustomersDropdown
                    field={field}
                    fieldstate={fieldState}
                    id={'customerId2Input'}
                    required
                    enableSelectLocation
                    isInModal
                    locationId={editingLocation?.locationId}
                    customersFilters={formValues?.membership}
                  />
                )}
              />
            </Col>
            <Col sm="12">
              <Controller
                control={control}
                shouldUnregister
                name={'officeId'}
                rules={{ required: true }}
                defaultValue={editingLocation?.officeId}
                render={({ field, fieldState }) => (
                  <OfficesDropdown
                    field={field}
                    fieldstate={fieldState}
                    id={'officeIdInput'}
                    isInModal
                    notAssigned
                    required
                    enableSelectLocation
                    locationId={editingLocation?.locationId}
                    customerId={formValues?.customerId2}
                    isMainOffice={0}
                    officesFilters={formValues?.membership}
                  />
                )}
              />
            </Col>
          </>
        )}
        <Row className={'footer-slide-pane'}>
          <Col sm="12 d-flex ">
            {hasButtonSubmit && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="primary"
                /*type="submit"*/
                onClick={handleSubmit((values) => {
                  closeSlidingPaneFunction(true);
                  action({
                    ...values,
                    customerId:
                      values.locationClassId === 30
                        ? values.customerId1
                        : values.locationClassId === 34
                        ? values.customerId2
                        : undefined,
                    officeId:
                      values.locationClassId === 30
                        ? customer?.officeId
                        : values.locationClassId === 34
                        ? values.officeId
                        : undefined,
                    radius: parsers.convertAuthUserDistance2SystemToMeters(values.radius)
                  });
                })}
              >
                {confirmText || <FormattedMessage id="common.save" />}
              </Button.Ripple>
            )}
            {hasButtonCancel && (
              <Button.Ripple
                className="mr-1 mt-50 mb-50"
                color="light"
                onClick={() => closeSlidingPaneFunction(false)}
              >
                <FormattedMessage id="common.cancel" />
              </Button.Ripple>
            )}
          </Col>
        </Row>
      </Row>
    </SlidingPane>
  );
};

SlidingPaneEditLocation.defaultProps = {
  inputs: [],
  useTable: false,
  heightTable: 200,
  hasButtonSubmit: true,
  hasButtonCancel: true,
  columnsTable: [],
  dataTable: []
};

export default SlidingPaneEditLocation;
