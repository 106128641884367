import { combineReducers } from 'redux';

import omit from 'lodash/omit';
import union from 'lodash/union';
import forEach from 'lodash/forEach';
import uniq from 'lodash/uniq';

import * as types from 'views/screens/Contacts/types';
import monitoringUsers from 'views/screens/Users/reducers';
import configurations from 'views/../redux/reducers/configurations';
import * as authTypes from 'views/../redux/types/auth';

const byId = (state = {}, action) => {
  switch (action.type) {
    //TYPES COMPLETED FETCH, ADD AND EDIT
    case types.CONTACTS_FETCH_COMPLETED:
    case types.CONTACTS_PARTIAL_FETCH_COMPLETED: {
      const { entities, order } = action.payload;
      const newState = { ...state };
      order.forEach((contactId) => {
        newState[contactId] = {
          isSelected: false,
          ...entities[contactId],
          isConfirmed: true
        };
      });
      return newState;
    }

    case types.CONTACT_ADD_COMPLETED: {
      const { oldId, contact } = action.payload;
      const newState = omit(state, oldId);
      newState[contact.contactId] = {
        ...state[oldId],
        ...contact,
        isConfirmed: true
      };
      return newState;
    }

    case types.CONTACT_EDIT_COMPLETED: {
      const contact = action.payload;

      const newState = {
        ...state,
        [contact.contactId]: {
          ...omit(state[contact.contactId], ['oldContact']),
          ...omit(contact, ['oldContact']),
          isConfirmed: true,
          isSelected: true
        }
      };

      return contact.originalContactId && contact.originalContactId != contact.contactId
        ? omit(newState, [contact.originalContactId])
        : newState;
    }

    case types.CONTACT_ADD_STARTED: {
      const contact = action.payload;
      const newState = { ...state };
      newState[contact.contactId] = {
        isSelected: contact.isSelected ?? false,
        ...contact,
        isConfirmed: false
      };
      return newState;
    }

    case types.CONTACT_EDIT_STARTED: {
      const contact = action.payload;
      return {
        ...state,
        [contact.contactId]: {
          ...state[contact.contactId],
          oldContact: state[contact.contactId],
          ...contact,
          isConfirmed: false
        }
      };
    }

    case types.CONTACT_ADD_FAILED: {
      const { oldId } = action.payload;
      if (state[oldId]) {
        return omit(state, oldId);
      }
      return state;
    }

    case types.CONTACT_FETCH_COMPLETED: {
      const contact = action.payload;
      const newState = { ...state };
      newState[contact.contactId] = {
        isSelected: false,
        ...state[contact.contactId],
        ...contact,
        isConfirmed: true
      };
      return newState;
    }

    //TYPES SELECTED AND DESELECTED
    case types.CONTACT_SELECTED: {
      const contactId = action.payload;
      const newState = {
        ...state,
        [contactId]: {
          ...state[contactId],
          isSelected: true
        }
      };
      return newState;
    }

    case types.CONTACT_DESELECTED: {
      const contactId = action.payload;
      return {
        ...state,
        [contactId]: {
          ...state[contactId],
          isSelected: false
        }
      };
    }

    case types.CONTACTS_ALL_SELECTED: {
      const contactIds = action.payload;
      const newState = { ...state };
      if (contactIds.length == 0) {
        forEach(state, (contact: any, contactId) => {
          newState[contactId] = {
            ...contact,
            isSelected: true
          };
        });
      } else {
        contactIds.forEach((contactId) => {
          newState[contactId] = {
            ...state[contactId],
            isSelected: true
          };
        });
      }

      return newState;
    }

    case types.CONTACTS_ALL_DESELECTED: {
      const contactIds = action.payload;
      const newState = { ...state };
      if (contactIds.length == 0) {
        forEach(state, (contact: any, contactId) => {
          newState[contactId] = {
            ...contact,
            isSelected: false
          };
        });
      } else {
        contactIds.forEach((contactId) => {
          newState[contactId] = {
            ...state[contactId],
            isSelected: false
          };
        });
      }

      return newState;
    }

    //TYPES REMOVE COMPLETED
    case types.CONTACT_REMOVE_COMPLETED: {
      const { contacts } = action.payload;
      return omit(state, contacts);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

const order = (state = [], action) => {
  switch (action.type) {
    //CASE COMPLETED FETCH, ADD AND REMOVE
    case types.CONTACTS_FETCH_COMPLETED: {
      const { order } = action.payload;
      return union(order);
    }

    case types.CONTACTS_PARTIAL_FETCH_COMPLETED: {
      const { order } = action.payload;
      return uniq([...state, ...order]);
    }
    case types.CONTACT_ADD_COMPLETED: {
      const { oldId, contact } = action.payload;
      return state.map((contactId) => (contactId === oldId ? contact.contactId : contactId));
    }

    //CASE ADD STARTED
    case types.CONTACT_ADD_STARTED: {
      const contact = action.payload;
      return [...state, contact.contactId];
    }

    //CASE ADD FAILED
    case types.CONTACT_ADD_FAILED: {
      const { oldId } = action.payload;
      return state.filter((contactIdState) => contactIdState !== oldId);
    }

    //Edit COMPLETED
    case types.CONTACT_EDIT_COMPLETED: {
      const contact = action.payload;
      return contact.originalContactId && contact.originalContactId != contact.contactId
        ? state.filter((contactIdState) => contact.originalContactId != contactIdState)
        : state;
    }

    //TYPES REMOVE COMPLETED
    case types.CONTACT_REMOVE_COMPLETED: {
      const { contacts } = action.payload;
      return state.filter((contactIdState) => contacts.indexOf(contactIdState) < 0);
    }

    //DEFAULT
    default: {
      return state;
    }
  }
};

// STATES TO KNOW IF IT IS FETCHING, ADDING  OR EDITING
const isFetchingList = (state = false, action) => {
  switch (action.type) {
    case types.CONTACTS_FETCH_STARTED: {
      return true;
    }
    case types.CONTACTS_FETCH_COMPLETED:
    case types.CONTACTS_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case types.CONTACT_FETCH_STARTED: {
      return true;
    }
    case types.CONTACT_FETCH_COMPLETED:
    case types.CONTACT_FETCH_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isAdding = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.CONTACT_ADD_STARTED: {
      return true;
    }
    case types.CONTACT_ADD_COMPLETED:
    case types.CONTACT_ADD_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isEditing = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.CONTACT_EDIT_STARTED: {
      return true;
    }
    case types.CONTACT_EDIT_COMPLETED:
    case types.CONTACT_EDIT_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const isRemoving = (state = false, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return false;
    }
    case types.CONTACT_REMOVE_STARTED: {
      return true;
    }
    case types.CONTACT_REMOVE_COMPLETED:
    case types.CONTACT_REMOVE_FAILED: {
      return false;
    }
    default: {
      return state;
    }
  }
};

const errorFetchingList = (state = null, action) => {
  switch (action.type) {
    case types.CONTACTS_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTACTS_FETCH_STARTED:
    case types.CONTACTS_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorFetching = (state = null, action) => {
  switch (action.type) {
    case types.CONTACT_FETCH_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTACT_FETCH_STARTED:
    case types.CONTACT_FETCH_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorAdding = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.CONTACT_ADD_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTACT_ADD_STARTED:
    case types.CONTACT_ADD_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorEditing = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.CONTACT_EDIT_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTACT_EDIT_STARTED:
    case types.CONTACT_EDIT_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const errorRemoving = (state = null, action) => {
  switch (action.type) {
    case authTypes.AUTHENTICATION_IDENTITY_CLEARED: {
      return null;
    }
    case types.CONTACT_REMOVE_FAILED: {
      const { error } = action.payload;
      return error;
    }
    case types.CONTACT_REMOVE_STARTED:
    case types.CONTACT_REMOVE_COMPLETED: {
      return null;
    }
    default: {
      return state;
    }
  }
};

const contacts = combineReducers({
  byId,
  order,
  isFetching,
  isFetchingList,
  isAdding,
  isEditing,
  isRemoving,
  errorFetching,
  errorFetchingList,
  errorAdding,
  errorEditing,
  errorRemoving,
  configurations
});

const people = combineReducers({
  contacts,
  monitoringUsers
});

export default people;

//Own state
export const getOwnState = (state) => state.people.contacts;

//Information
export const getContact = (state, contactId) => getOwnState(state).byId[contactId];
export const getContactsList = (state) =>
  getOwnState(state).order.map((id) => getContact(state, id));
export const getContactsSpecificList = (state, list) => list.map((id) => getContact(state, id));
export const getSelectedContacts = (state) => {
  const selectedContacts = getContactsList(state).filter((contact) => contact.isSelected);
  //Si no se selecciona ninguno devuelve null
  if (selectedContacts.length === 0) return null;
  //Si se selecciona más de cero se devuelve el arreglo de los seleccionados
  if (selectedContacts.length > 0) return selectedContacts;
};
export const getContactsFromBrand = (state, brandId) =>
  getContactsList(state).filter((contact) => contact.brandId === brandId);

export const getSelectedContact = (state) => {
  const selectedContacts = getContactsList(state).filter((contact) => contact.isSelected);
  //Si se selecciona solo uno devuelve solo el seleccionado
  if (selectedContacts.length === 1) return selectedContacts[0];
  //De lo contrario se devuelve null
  else return null;
};

//Status of sagas
export const isFetchingContact = (state) => getOwnState(state).isFetching;
export const isFetchingListContacts = (state) => getOwnState(state).isFetchingList;
export const isEditingContact = (state) => getOwnState(state).isEditing;
export const isAddingContact = (state) => getOwnState(state).isAdding;
export const isRemovingContact = (state) => getOwnState(state).isRemoving;

//Errors
export const getFetchingContactErrors = (state) => getOwnState(state).errorFetching;
export const getFetchingListContactsErrors = (state) => getOwnState(state).errorFetchingList;
