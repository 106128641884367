// @ts-nocheck
import 'leaflet.icon.glyph';
import { Fragment, useEffect, useState } from 'react';
import { Marker, Polyline, Popup, Rectangle, useMapEvents } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { v4 as uuidv4 } from 'uuid';
import { getIcon, getRouteBounds } from 'views/screens/Routes/utils';
import * as routeCheckpointsActions from '../../../../screens/Routes/actions/routeCheckpoints';
import * as routeActions from '../../../../screens/Routes/actions/routes';
import * as routeSelectors from '../../../../screens/Routes/reducers/routes';
import SlidingPaneRouteInformation from '../../../../screens/Routes/views/SlidingPaneRouteInformation';
import { getRectBoundsFromLatLng } from '../../utils';

export interface MapRoutesOptions {
  isSelectedSomething?: any;
  setIsSelectedSomething?: Function;
  setMapPositionToRouteBounds?: boolean;
  useSlidingPaneRouteInformation?: boolean;
  usePopovers?: boolean;
  routes?: any[];
  showRoutes?: boolean;
  isMapFullScreen?: boolean;
  useIsShownRoute?: boolean; //param only when you have a property in each route isShownInMap to show routes on map
  initialShowSlidingPanelRouteInformationOpen?: boolean;
  initialShowSlidingPanelRouteCreateOpen?: boolean;
  initialShowSlidingPanelRouteEditOpen?: boolean;
  initialRouteInformationOpenId?: any;
  editingRouteParent?: any;
  pathColor?: any;
  setEditingRouteParent?: Function;
}

const MapRoutes = (props: MapRoutesOptions) => {
  const map = useMapEvents({
    moveend: () => {
      displayMarkers();
    }
  });
  const {
    isSelectedSomething,
    setIsSelectedSomething,
    showRoutes = false,
    useIsShownRoute = false,
    routes = [],
    setMapPositionToRouteBounds = false,
    useSlidingPaneRouteInformation = true,
    usePopovers,
    pathColor = null,
    initialShowSlidingPanelRouteInformationOpen = false,
    initialShowSlidingPanelRouteCreateOpen = false,
    initialShowSlidingPanelRouteEditOpen = false,
    initialRouteInformationOpenId,
    editingRouteParent,
    setEditingRouteParent,
    isMapFullScreen = true
  } = props;
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const [showRouteInformationSlidingPane, setShowRouteInformationSlidingPane] = useState(false);
  const [openEditSlidingPane, setOpenEditSlidingPane] = useState(false);
  const [initialRouteId, setInitialRouteId] = useState<any>();
  const [routesBounds, setRoutesBounds] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState<any>({});
  const style = getComputedStyle(document.body);
  const primary = process.env.REACT_APP_PRIMARY_COLOR;

  const { isEditing, isAdding, isFetchingCheckpoints } = useSelector((state) => ({
    isEditing: routeSelectors.isEditingRoute(state),
    isAdding: routeSelectors.isAddingRoute(state),
    isFetchingCheckpoints: routeSelectors.isFetchingListRouteCheckpoints(state)
  }));

  useEffect(() => {
    if (setIsSelectedSomething) {
      setIsSelectedSomething({
        ...isSelectedSomething,
        routes: selectedRoute?.routeId != null
      });
    }
  }, [selectedRoute]);

  const selectRoute = (route) => {
    if (editingRouteParent?.route?.routeId) {
      dispatch(routeActions.showRouteInMap(editingRouteParent.route.routeId));
      if (setEditingRouteParent) setEditingRouteParent(undefined);
    }
    setSelectedRoute(route);
    if (useSlidingPaneRouteInformation) {
      setShowRouteInformationSlidingPane(true);
    }
    zoomMapToRoutes([route]);
  };

  const createRoute = (route) => {
    setShowRouteInformationSlidingPane(false);
    setEditingRouteParent({
      showRoute: true,
      route,
      routeCheckpoints: route.checkpoints,
      routePaths: route.checkpoints
        .filter((chk) => chk.segmentPath.length > 0)
        .map((chk) => {
          return {
            polyline: L.polyline(
              chk.segmentPath.map((path) => [path.latitude, path.longitude]),
              { color: pathColor ?? 'blue' }
            ),
            segmentSpeedLimit: chk.segmentSpeedLimit,
            segmentTripMinTime: chk.segmentTripMinTime,
            segmentTripMaxTime: chk.segmentTripMaxTime,
            segmentStopAuthorized: chk.segmentStopAuthorized,
            segmentStopTime: chk.segmentStopTime,
            segmentDistance: chk.segmentDistance,
            segmentWithoutStopTime: chk.segmentWithoutStopTime
          };
        })
    });
  };

  const zoomMapToRoutes = (routes) => {
    var bounds = [];
    routes.map((route) => (bounds = [...bounds, ...getRouteBounds(route)]));
    if (bounds.length > 0)
      map.flyToBounds(bounds, {
        paddingBottomRight: isMapFullScreen ? [700, 0] : undefined,
        paddingTopLeft: isMapFullScreen ? [0, 70] : undefined,
        animate: map.getBoundsZoom(bounds, undefined, [700, 70]) !== map.getZoom()
      });
  };

  const displayMarkers = () => {
    if (routes.filter((route) => route.checkpoints.length > 0).length > 0)
      setRoutesBounds(
        routes.filter((route) =>
          map
            ? getRouteBounds(route).length > 0 && map.getBounds().intersects(getRouteBounds(route))
            : true
        )
      );
  };

  useEffect(() => {
    if (!isEditing && selectedRoute.routeId) {
      dispatch(routeActions.showRouteInMap(selectedRoute.routeId));
      setSelectedRoute(routeSelectors.getRoute(store, selectedRoute.routeId));
      setShowRouteInformationSlidingPane(true);
    }
  }, [isEditing]);

  useEffect(() => {
    if (editingRouteParent?.route?.routeId) {
      dispatch(routeActions.hideRouteInMap(editingRouteParent.route.routeId));
    }
  }, [editingRouteParent]);

  useDeepCompareEffect(() => {
    if (setMapPositionToRouteBounds && map && routes.length > 0) zoomMapToRoutes();
  }, [routes, setMapPositionToRouteBounds]);

  useEffect(() => {
    if (initialShowSlidingPanelRouteCreateOpen) {
      setShowRouteInformationSlidingPane(false);
      setEditingRouteParent({
        showRoute: true,
        route: { newRouteId: uuidv4() },
        routeCheckpoints: [
          { checkpointId: uuidv4() },
          { checkpointId: uuidv4() },
          { checkpointId: uuidv4() }
        ],
        routePaths: []
      });
    }
  }, [initialShowSlidingPanelRouteCreateOpen]);

  //Open and get route checkpoints if params are set
  useEffect(() => {
    if (initialShowSlidingPanelRouteInformationOpen && useSlidingPaneRouteInformation) {
      if (initialRouteInformationOpenId) {
        dispatch(
          routeCheckpointsActions.startFetchingRouteCheckpoints(initialRouteInformationOpenId)
        );
      }
    }
  }, [initialRouteInformationOpenId]);

  useEffect(() => {
    if (initialShowSlidingPanelRouteInformationOpen && useSlidingPaneRouteInformation) {
      if (initialRouteInformationOpenId && !isFetchingCheckpoints) {
        const initialRouteInformation = routeSelectors.getRoute(
          store,
          initialRouteInformationOpenId
        );
        if (
          initialRouteId !== initialRouteInformationOpenId &&
          initialRouteInformation?.checkpoints?.length > 0
        ) {
          setInitialRouteId(initialRouteInformation.routeId);
          setSelectedRoute(initialRouteInformation);
          zoomMapToRoutes([initialRouteInformation]);
          setShowRouteInformationSlidingPane(true);
          if (initialShowSlidingPanelRouteEditOpen) {
            setOpenEditSlidingPane(true);
          }
        }
      }
    }
  }, [isFetchingCheckpoints]);

  useEffect(() => {
    if (openEditSlidingPane) {
      setOpenEditSlidingPane(false);
      createRoute(selectedRoute);
    }
  }, [selectedRoute]);

  useDeepCompareEffect(() => {
    displayMarkers();
  }, [showRoutes, isEditing, isAdding, routes]);

  return (
    <>
      {(showRoutes || useIsShownRoute) && (
        <Fragment>
          {routesBounds.map(
            (route) =>
              ((showRoutes && !useIsShownRoute) || (useIsShownRoute && route.isShownInMap)) && (
                <div key={route.routeId}>
                  {route.checkpoints.map(
                    (checkpoint, index) =>
                      checkpoint.latitude != null &&
                      checkpoint.longitude != null && (
                        <div key={checkpoint.checkpointId}>
                          <Marker
                            // pmIgnore={true}
                            riseOnHover={true}
                            icon={getIcon(
                              index === 0 ? 1 : index === route.checkpoints.length - 1 ? 3 : 2,
                              index,
                              checkpoint.iconId === 0 ? undefined : checkpoint.iconId,
                              checkpoint.checkpointClassDefaultColor,
                              checkpoint.checkpointTextDefaultColor
                            )}
                            position={[checkpoint.latitude, checkpoint.longitude]}
                            eventHandlers={{
                              click: () => selectRoute(route)
                            }}
                          >
                            {usePopovers && (
                              <Popup>
                                <b className="text-center" style={{ fontSize: '14px' }}>
                                  {checkpoint?.checkpointName ?? route.routeName}
                                </b>
                              </Popup>
                            )}
                          </Marker>
                          {checkpoint.radius && (
                            <Rectangle
                              bounds={getRectBoundsFromLatLng(
                                checkpoint.latitude,
                                checkpoint.longitude,
                                checkpoint.radius
                              )}
                              pathOptions={{ color: primary }}
                            />
                          )}
                          {checkpoint.segmentPath.length > 0 && (
                            <Polyline
                              pathOptions={{ color: route.pathColor ?? pathColor ?? 'blue' }}
                              positions={checkpoint.segmentPath.map((path) => [
                                path.latitude,
                                path.longitude
                              ])}
                              eventHandlers={{
                                click: () => {
                                  setOpenEditSlidingPane(true);
                                  selectRoute(route);
                                }
                              }}
                            >
                              {usePopovers && (
                                <Popup>
                                  <b className="text-center" style={{ fontSize: '14px' }}>
                                    {route.routeName}
                                  </b>
                                </Popup>
                              )}
                            </Polyline>
                          )}
                        </div>
                      )
                  )}
                </div>
              )
          )}
        </Fragment>
      )}

      <SlidingPaneRouteInformation
        isLoading={isEditing}
        closeSlidingPane={() => {
          setShowRouteInformationSlidingPane(false);
          setSelectedRoute({});
        }}
        isOpenSlidingPane={showRouteInformationSlidingPane}
        title={selectedRoute.routeName}
        route={selectedRoute}
        createRoute={createRoute}
      />
    </>
  );
};

MapRoutes.defaultProps = {
  setMapPositionToRouteBounds: false,
  useSlidingPaneRouteInformation: true,
  routes: [],
  showRoutes: false,
  initialShowSlidingPanelRouteInformationOpen: false,
  usePopovers: true,
  initialRouteInformationOpenId: undefined,
  useIsShownRoute: false
};

export default MapRoutes;
