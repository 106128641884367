export const PROVIDERS_FETCH_STARTED2 = 'PROVIDERS_FETCH_STARTED2';
export const PROVIDERS_FETCH_COMPLETED2 = 'PROVIDERS_FETCH_COMPLETED2';
export const PROVIDERS_FETCH_FAILED2 = 'PROVIDERS_FETCH_FAILED2';

export const PROVIDERS_PARTIAL_FETCH_STARTED2 = 'PROVIDERS_PARTIAL_FETCH_STARTED2';
export const PROVIDERS_PARTIAL_FETCH_COMPLETED2 = 'PROVIDERS_PARTIAL_FETCH_COMPLETED2';
export const PROVIDERS_PARTIAL_FETCH_FAILED2 = 'PROVIDERS_PARTIAL_FETCH_FAILED2';

export const PROVIDER_ADD_STARTED = 'PROVIDER_ADD_STARTED';
export const PROVIDER_ADD_COMPLETED = 'PROVIDER_ADD_COMPLETED';
export const PROVIDER_ADD_FAILED = 'PROVIDER_ADD_FAILED';

export const PROVIDER_EDIT_STARTED = 'PROVIDER_EDIT_STARTED';
export const PROVIDER_EDIT_COMPLETED = 'PROVIDER_EDIT_COMPLETED';
export const PROVIDER_EDIT_FAILED = 'PROVIDER_EDIT_FAILED';

export const PROVIDER_REMOVE_STARTED = 'PROVIDER_REMOVE_STARTED';
export const PROVIDER_REMOVE_COMPLETED = 'PROVIDER_REMOVE_COMPLETED';
export const PROVIDER_REMOVE_FAILED = 'PROVIDER_REMOVE_FAILED';
